import { connectionQuery } from './../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiRequestsConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  requestsConnectionQuery, addRequestMutation,
  removeRequestMutation, getRequestByIdQuery,
  updateRequestMutation
} from './request.operations';
import {
  IApiRequestFilter, IApiAddRequestInput, IApiUpdateRequestInput,
  IApiRemoveRequestInput, IApiRequest
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlRequestService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiRequestFilter[], options: IGqlBaseOptions): Observable<IApiRequestsConnection> {
    return this.apollo.query(connectionQuery(requestsConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddRequestInput): Observable<IApiRequest> {
    return this.apollo.mutate({
      mutation: addRequestMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(id: string) {
    return this.apollo.mutate({
      mutation: removeRequestMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateRequestInput): Observable<IApiRequest> {
    return this.apollo.mutate({
      mutation: updateRequestMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getRequestById(id: string): Observable<IApiRequest> {
    return this.apollo.query({
      query: getRequestByIdQuery,
      variables: { id: id }
    }).pipe(
      safeResolve(true)
    );
  }
}
