<div [dialogRef]="dialog" (appClickOutside)="close()">
  <!-- Permissions -->
  <div [appEnforcePermission]="authConfig.investigationPhotos" #investigationPhotos="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.investigationPhotoShareCreate"
    #investigationPhotoShareCreate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.investigationDocumentsListView"
    #investigationDocumentsListView="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.investigationDocumentShareCreate"
    #investigationDocumentShareCreate="appEnforcePermission"></div>
  <!-- Permissions -->
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span class="ml-0">Share Files</span>
      <p class="warning-message mb-0 mt-8" *ngIf="investigation?.nefcoNumber">
        Investigation: #{{investigation?.nefcoNumber}}
      </p>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">
    <h6 class="mb-0">Parties</h6>
    <p class="warning-message">Share files with the following parties</p>
    <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(3,minmax(0,1fr))']">
      <kendo-gridlayout-item *ngIf="investigation.Client">
        <div class="kendo-formfield">
          <p class="mb-0">Client:</p>
          <div class="k-checkbox-wrap">
            <input type="checkbox" kendoCheckBox #client [(ngModel)]="parties.Client" (change)="error = null" />
            <kendo-label [for]="client" class="k-checkbox-label cursor font-weight-600 checkbox-margin mb-0"
              [text]="investigation?.Client | fullName"></kendo-label>
          </div>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item *ngIf="investigation.Insured">
        <div class="kendo-formfield">
          <p class="mb-0">Insured:</p>
          <div class="k-checkbox-wrap">
            <input type="checkbox" kendoCheckBox #insured [(ngModel)]="parties.Insured" (change)="error = null" />
            <kendo-label [for]="insured" class="k-checkbox-label cursor font-weight-600 checkbox-margin mb-0"
              [text]="investigation.Insured | insuredName "></kendo-label>
          </div>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item *ngIf="investigation.BillTo">
        <div class="kendo-formfield">
          <p class="mb-0">Bill To:</p>
          <div class="k-checkbox-wrap">
            <input type="checkbox" kendoCheckBox #billTo [(ngModel)]="parties.BillTo" (change)="error = null" />
            <kendo-label [for]="billTo" class="k-checkbox-label cursor font-weight-600 checkbox-margin mb-0"
              [text]="investigation.BillTo | fullName "></kendo-label>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <!-- Documents Card -->
    <kendo-panelbar [expandMode]="2"
      *ngIf="investigationDocumentsListView?.enabled && investigationDocumentShareCreate?.enabled">
      <kendo-panelbar-item title="Documents" [expanded]="true">
        <ng-template kendoPanelBarContent>
          <div class="p-16-24">
            <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(2,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <h6>
                  Share selected documents:
                </h6>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item *ngIf="documents.length">
                <div class="k-checkbox-wrap">
                  <input type="checkbox" kendoCheckBox #allDocument
                    [checked]="selectedDocuments?.length === documents?.length"
                    (change)="toggleAllDocuments(); error = null" />
                  <kendo-label [for]="allDocument" class="k-checkbox-label cursor font-weight-600 checkbox-margin mb-0"
                    [text]="'Share all documents'"></kendo-label>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
            <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(3,minmax(0,1fr))']">
              <kendo-gridlayout-item *ngFor="let document of documents;let index">
                <div class="k-checkbox-wrap">
                  <input type="checkbox" kendoCheckBox [id]="'doc' + index" [value]="document.id"
                    (change)="toggleDocument(document); error = null"
                    [checked]="selectedDocuments.includes(document.id)" />
                  <kendo-label [for]="'doc' + index"
                    class="k-checkbox-label cursor font-weight-600 checkbox-margin mb-0"
                    [text]="document.title"></kendo-label>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </div>
        </ng-template>
      </kendo-panelbar-item>
    </kendo-panelbar>
    <kendo-panelbar class="mt-16" [expandMode]="2"
      *ngIf="investigationPhotos?.enabled && investigationPhotoShareCreate?.enabled">
      <kendo-panelbar-item title="Photos" [expanded]="true">
        <ng-template kendoPanelBarContent>
          <div class="p-16-24">
            <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(2,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <h6>
                  Share selected photos:
                </h6>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item *ngIf="documents.length">
                <div class="k-checkbox-wrap">
                  <input type="checkbox" kendoCheckBox #allPhotos (change)="toggleAllPhotos($event); error = null"
                    [checked]="allPhotosSelected()" />
                  <kendo-label [for]="allPhotos" class="k-checkbox-label cursor font-weight-600 checkbox-margin mb-0"
                    [text]="'Share all photos'"></kendo-label>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
            <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(3,minmax(0,1fr))']">
              <kendo-gridlayout-item *ngFor="let folder of photoFolders; let i = index">
                <div class="kendo-formfield">
                  <p class="warning-message mt-8">
                    {{folder.name }}
                  </p>
                  <p>
                    <input type="radio" kendoRadioButton [value]="'all'" [name]="'all' + i" #all
                      [(ngModel)]="folder.downloadOption" />
                    <kendo-label class="k-checkbox-label font-weight-600 checkbox-margin" style="margin-bottom: 0"
                      [for]="all" text="All Photos">
                      <kendo-badge class="fw600" position="none" size="small" rounded="full" themeColor="primary">
                        {{folder?.PhotoCount}}
                      </kendo-badge>
                    </kendo-label>
                  </p>
                  <p>
                    <input type="radio" kendoRadioButton [value]="'used'" [name]="'used' + i" #used
                      [(ngModel)]="folder.downloadOption" />
                    <kendo-label class="k-checkbox-label font-weight-600 checkbox-margin" style="margin-bottom: 0"
                      [for]="used" text="Used Photos">
                      <kendo-badge class="fw600" position="none" size="small" rounded="full" themeColor="primary">
                        {{ (folder.Photos | photoInUse : true).length}}
                      </kendo-badge>
                    </kendo-label>
                  </p>
                  <p>
                    <input type="radio" kendoRadioButton [value]="'unused'" #unused [name]="'unused' + i"
                      [(ngModel)]="folder.downloadOption" />
                    <kendo-label class="k-checkbox-label font-weight-600 checkbox-margin" style="margin-bottom: 0"
                      [for]="unused" text="Unused Photos">
                      <kendo-badge class="fw600" position="none" size="small" rounded="full" themeColor="primary">
                        {{ (folder.Photos | photoInUse : false).length}}
                      </kendo-badge>
                    </kendo-label>
                  </p>
                  <p>
                    <input type="radio" kendoRadioButton [value]="'none'" #none [name]="'none' + i"
                      [(ngModel)]="folder.downloadOption" />
                    <kendo-label class="k-checkbox-label font-weight-600 checkbox-margin" style="margin-bottom: 0"
                      [for]="none" text="None"></kendo-label>
                  </p>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </div>
        </ng-template>
      </kendo-panelbar-item>
    </kendo-panelbar>
    <kendo-formerror *ngIf="error" class="mr-16 download-error">{{ error }}</kendo-formerror>
    <kendo-dialog-actions class="schedule-action p-16-24" layout="end">
      <div>
        <button kendoButton [size]="'medium'" themeColor="light" class="kendo-btn" class="mr-16" (click)="close()">
          Cancel
        </button>
        <button kendoButton class="kendo-btn" [size]="'medium'" themeColor="primary" (click)="share()">
          Share
        </button>
      </div>
    </kendo-dialog-actions>
  </div>
</div>
