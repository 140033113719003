import { IApiAdvanceInvestigationReportInput, IApiCreateInvestigationReportInput, IApiComment, IApiDocument, IApiCommentableType, IApiQueryGenerateInvestigationMetricsCsvArgs, IApiInvestigationMetricsFilter, IApiInvestigationMetricsConnection, IApiInvestigationReportStatus } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlCommentService, GqlInvestigationReportService } from "src/app/shared/modules/graphql/services";
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';

export const InvestigationReportStatus = {
  'DRAFT': 'Draft',
  'TECH_REVIEW': "Tech Review",
  'TECH_REVIEW_2': "Tech Review 2",
  'READY_FOR_EDITOR': "Ready For Editor",
  'EDITOR_REVIEW': "Editor Review",
  'CLIENT_REVIEW': "Client Review",
  'FINAL': "Final"
};
@Injectable()
export class InvestigationReportService {
  constructor(
    private reports: GqlInvestigationReportService,
    private comments: GqlCommentService
  ) { }

  public csvReport(input: IApiQueryGenerateInvestigationMetricsCsvArgs): Observable<string> {
    return this.reports.getCsvReport(input);
  }

  public get(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationMetricsConnection> {
    return this.reports.getInvestigationReport(filters, options || new ListPage());
  }

  public create(input: IApiCreateInvestigationReportInput): Observable<IApiDocument> {
    return this.reports.createReport(input);
  }

  public advance(input: IApiAdvanceInvestigationReportInput): Observable<boolean> {
    return this.reports.advanceReport(input);
  }

  public prevStatus({ status, isIncendiary }: { status: IApiInvestigationReportStatus | string, isIncendiary: boolean }): IApiInvestigationReportStatus | String {
    // Filter report status based on if investigation is Incendiary
    const object = isIncendiary ? InvestigationReportStatus : Object.keys(InvestigationReportStatus).filter((key) => key !== IApiInvestigationReportStatus.TechReview_2)?.reduce((cur, key) => { return Object.assign(cur, { [key]: InvestigationReportStatus[key] }) }, {});
    const keys = Object.keys(object)
    const idx = keys.indexOf(status);

    if (idx === 0) return null;

    return InvestigationReportStatus[keys[idx - 1]];
  }

  public nextStatus({ status, isIncendiary }: { status: IApiInvestigationReportStatus | string, isIncendiary: boolean }): IApiInvestigationReportStatus | String {
    // Filter report status based on if investigation is Incendiary
    const object = isIncendiary ? InvestigationReportStatus : Object.keys(InvestigationReportStatus).filter((key) => key !== IApiInvestigationReportStatus.TechReview_2)?.reduce((cur, key) => { return Object.assign(cur, { [key]: InvestigationReportStatus[key] }) }, {});
    const keys = Object.keys(object)
    const idx = keys.indexOf(status);

    if (idx === keys.length - 1) return null;
    return InvestigationReportStatus[keys[idx + 1]];
  }

  public addComment(documentId: string, comment: string): Observable<IApiComment> {
    return this.comments.addComment({
      text: comment,
      commentableId: documentId,
      commentableType: IApiCommentableType.Document
    });
  }
}
