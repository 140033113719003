import { connectionQuery } from './../../helpers.class';
import { IApiAddCompanyInput, IApiCompanyFilterType, IApiUpdateCompanyInput } from './../../types/types';
import { addCompanyMutation, companyConnectionQuery, getCompanyByIdQuery, removeCompanyMutation, updateCompanyMutation } from './company.operations';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IApiCompaniesConnection, IApiCompany, IApiCompanyFilter } from '../../types/types';
import { IGqlBaseOptions } from '../../interfaces/base.interfaces';
import { safeResolve } from "../../rxjs";


@Injectable()
export class GqlCompanyService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getCompanies(filters: IApiCompanyFilter[], options: IGqlBaseOptions): Observable<IApiCompaniesConnection> {
    const viewType = filters.find(obj => obj.type === IApiCompanyFilterType.ViewListView || obj.type === IApiCompanyFilterType.ViewOnlyName) || null;
    return this.apollo.query(connectionQuery(companyConnectionQuery(viewType), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getCompanyById(id: string): Observable<IApiCompany> {
    return this.apollo.query({
      query: getCompanyByIdQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public addCompany(input: IApiAddCompanyInput): Observable<IApiCompany> {
    return this.apollo.mutate({
      mutation: addCompanyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateCompany(input: IApiUpdateCompanyInput): Observable<IApiCompany> {
    return this.apollo.mutate({
      mutation: updateCompanyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeCompany(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeCompanyMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
