import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { addExpenseCategoryMutation, ExpenseCategoryConnectionQuery, removeExpenseCategoryMutation, updateExpenseCategoryMutation } from "./expense-category.operations";
import { IApiAddExpenseCategoryInput, IApiExpenseCategory, IApiExpenseCategoryConnection, IApiExpenseCategoryFilter, IApiUpdateExpenseCategoryInput } from "../../../types/types";

@Injectable()
export class GqlExpenseCategoryService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getExpenseCategories(filters: IApiExpenseCategoryFilter[], options: IGqlBaseOptions): Observable<IApiExpenseCategoryConnection> {
    return this.apollo.query(connectionQuery(ExpenseCategoryConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addExpenseCategory(input: IApiAddExpenseCategoryInput): Observable<IApiExpenseCategory> {
    return this.apollo.mutate({
      mutation: addExpenseCategoryMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public updateExpenseCategory(input: IApiUpdateExpenseCategoryInput): Observable<IApiExpenseCategory> {
    return this.apollo.mutate({
      mutation: updateExpenseCategoryMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeExpenseCategory(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeExpenseCategoryMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
