import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/enums/permissions.enums";

export const evidence: IEnforcePermissionConfig = {
  category: PermissionCategories.EVIDENCE,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [PermissionActions.LIST],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const evidenceView: IEnforcePermissionConfig = {
  category: PermissionCategories.EVIDENCE,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.VIEW],
    Own: [PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const evidenceCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.EVIDENCE,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const evidenceUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.EVIDENCE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const evidenceUpdateOwn: IEnforcePermissionConfig = {
  category: PermissionCategories.EVIDENCE,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const evidenceDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.EVIDENCE,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const evidenceDownload: IEnforcePermissionConfig = {
  category: PermissionCategories.EVIDENCE,
  appliedPermissions: {
    All: [PermissionActions.DOWNLOAD],
    AllAssigned: [],
    Assigned: [PermissionActions.DOWNLOAD],
    Own: [PermissionActions.DOWNLOAD],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const evidenceTransferCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.EVIDENCE_TRANSFER,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const evidenceTransferUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.EVIDENCE_TRANSFER,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const evidenceBilling: IEnforcePermissionConfig = {
  category: PermissionCategories.EVIDENCE_BILLING,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.VIEW],
    Own: [PermissionActions.VIEW]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const evidenceBillingUpdate: IEnforcePermissionConfig = {
  ...evidenceBilling,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE]
  }
};

export const evidenceBillingCreate: IEnforcePermissionConfig = {
  ...evidenceBilling,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE]
  }
};

