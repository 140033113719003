import { IApiInvestigation, IApiInvestigationOrderBy, IApiInvestigationFilter, IApiInvestigationConnection } from './../../modules/graphql/types/types';
import { InvestigationService } from './investigation.service';
import { DataSource } from "@angular/cdk/collections";
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { ApiConnection } from "../../decorators/@ApiConnection.decorator";

export class InvestigationDataSource extends NefcoPagedDataSource<IApiInvestigation, InvestigationService, IApiInvestigationConnection> implements DataSource<IApiInvestigation> {
  @ApiConnection()
  public load(filters: IApiInvestigationFilter[] = []): Promise<IApiInvestigationConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
