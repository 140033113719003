import { IGqlBaseOptions } from './../../../modules/graphql/interfaces/base.interfaces';
import { IApiAddDocumentCategoryInput, IApiDocumentCategory, IApiUpdateDocumentCategoryInput, IApiDocumentCategoryFilter, IApiDocumentCategoryConnection } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { GqlDocumentCategoryService } from '../../../modules/graphql/services';
import { Injectable } from '@angular/core';
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class DocumentCategoryService {

  constructor(
    private documentCategories: GqlDocumentCategoryService,
  ) { }

  public get(filters?: IApiDocumentCategoryFilter[], options?: IGqlBaseOptions): Observable<IApiDocumentCategoryConnection> {
    return this.documentCategories.getCategories(filters, options || new ListPage());
  }

  public add(categoryInput: IApiAddDocumentCategoryInput): Observable<IApiDocumentCategory> {
    return this.documentCategories.addCategory(categoryInput);
  }

  public update(categoryInput: IApiUpdateDocumentCategoryInput): Observable<IApiDocumentCategory> {
    return this.documentCategories.updateCategory(categoryInput);
  }

  public remove(categoryId: string): Observable<boolean> {
    return this.documentCategories.removeCategory(categoryId);
  }
}
