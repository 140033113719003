<section>
  <h4 class="page-title">Manage Investigator And Certifications Type</h4>
  <div class="content-wrapper p-0">
    <kendo-tabstrip #tabstrip [scrollable]="{ buttonScrollSpeed: 500 }" class="bookkeeping-manage-tabs">
      <kendo-tabstrip-tab>
        <ng-template kendoTabTitle>
          <span>Investigators</span>
        </ng-template>
        <ng-template kendoTabContent>
          <div>
            <app-certifications-investigator-list></app-certifications-investigator-list>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab>
        <ng-template kendoTabTitle>
          <span>Certifications Type</span>
        </ng-template>
        <ng-template kendoTabContent>
          <div>
            <app-certifications-list #certList></app-certifications-list>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</section>