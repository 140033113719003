import gql from 'graphql-tag';

const nodeFragment = `
  signedUrl
  key
`;

export const createSignedUrlMutation = gql`mutation createSignedUrl($input: S3FileInput!) {
  createSignedUrl(input: $input) { ${nodeFragment} }
}`;

export const photoDownloadProgress = gql`subscription onPhotoDownloadProgress {
  photoDownloadProgress {
    UserId
    uniqueId
    progress
    zipUrl
    error
  }
}`;

export const createMultipartUploadMutation = gql`mutation createMultipartUpload($input: S3FileInput!) {
  createMultipartUpload(input: $input) { 
    key
    uploadId
  }
}`;

export const getUploadPartUrlMutation = gql`mutation getUploadMultipartPartUrl($input: UploadMultipartPartUrlInput!) {
  getUploadMultipartPartUrl(input: $input) { 
    key
    signedUrl
  }
}`;

export const completeMultipartUploadMutation = gql`mutation completeMultipartUpload($input: CompleteMultipartUploadInput!) {
  completeMultipartUpload(input: $input)
}`;

export const abortMultipartUploadMutation = gql`mutation abortMultipartUpload($input: AbortMultipartUploadInput!) {
  abortMultipartUpload(input: $input)
}`;