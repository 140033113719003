import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  oneDriveItemId
  name
  DocumentTypes {
    id
    name
  }
  createdAt
  updatedAt
`;

export const documentTemplateConnectionQuery = gql`${buildConnectionQuery("DocumentTemplate", "documentTemplateConnection", nodeFragment)}`;

export const addDocumentTemplateMutation = gql`mutation AddDocumentTemplate($input: AddDocumentTemplateInput!) {
  addDocumentTemplate(input: $input) { ${ nodeFragment } }
}`;

export const updateDocumentTemplateMutation = gql`mutation UpdateDocumentTemplate($input: UpdateDocumentTemplateInput!) {
  updateDocumentTemplate(input: $input) { ${ nodeFragment } }
}`;

export const removeDocumentTemplateMutation = gql`mutation RemoveDocumentTemplate($id: String) {
  removeDocumentTemplate(id: $id)
}`;
