import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IApiUserFilter, IApiUsersConnection, IApiUser, IApiUpdateUserInput, IApiUpdateUserProfileInput, IApiAddUserInput, IApiUserSignatureInput, IApiUserSignature } from './../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { IGqlUserAvailabilityInput } from '../../modules/graphql/interfaces/user.interfaces';
import { GqlUserService } from '../../modules/graphql/services/user/user.service';
import { Injectable } from '@angular/core';
import { GqlUserDetailService } from '../../modules/graphql/services/user/user-detail/user-detail.service';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces/base.interfaces';
import { GqlUserAvailabilityService } from '../../modules/graphql/services/user-availability.service';
import { ScheduleInformation } from '@microsoft/microsoft-graph-types';
import { map } from "rxjs/operators";
import { ListPage } from "../../helpers/list-page.class";
import { NefcoDateHelper } from '../../helpers/nefco-date.class';

@Injectable()
export class UserService {

  constructor(
    private userService: GqlUserService,
    private userDetailsService: GqlUserDetailService,
    private userAvailabilityService: GqlUserAvailabilityService
  ) { }

  public get(filters: IApiUserFilter[] = [], options?: IGqlBaseOptions): Observable<IApiUsersConnection> {
    return this.userService.getUsersConnection(filters, options || new ListPage(-1));
  }

  public getById(id: string): Observable<IApiUser> {
    return this.userService.getUserById(id);
  }

  public getByIdWithoutIntestigationRole(id: string): Observable<IApiUser> {
    return this.userService.getUserByIdWithoutIntestigationRole(id);
  }

  public getByIdOnlyNefco(id: string): Observable<IApiUser> {
    return this.userService.getUserByIdOnlyNefco(id);
  }

  public getByIdWithPermissionRoleAndState(id: string): Observable<IApiUser> {
    return this.userService.getByIdWithPermissionRoleAndState(id);
  }

  public addUser(input: IApiAddUserInput) {
    return this.userService.addUser(input);
  }

  public updateUser(user: IApiUpdateUserInput) {
    return this.userService.updateUser(user);
  }

  public updateUserProfile(userProfile: IApiUpdateUserProfileInput) {

    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    if (userProfile?.UserDetail?.startDate) userProfile.UserDetail.startDate = NefcoDateHelper.toUtc(userProfile.UserDetail.startDate, 12);
    if (userProfile?.UserDetail?.endDate) userProfile.UserDetail.endDate = NefcoDateHelper.toUtc(userProfile.UserDetail.endDate, 12);

    return this.userService.updateUserProfile(userProfile);
  }

  public getUsersAvailability(userAvailabilityInput: IGqlUserAvailabilityInput): Observable<ScheduleInformation[]> {
    return this.userAvailabilityService.getUsersAvailability(userAvailabilityInput);
  }

  public getByIdOnlyMsGraphId(id: string): Observable<IApiUser> {
    return this.userService.getByIdOnlyMsGraphId(id);
  }

  public getByIdCelendarViewProfile(id: string): Observable<IApiUser> {
    return this.userService.getByIdCelendarViewProfile(id);
  }

  public addUserSignature(input: IApiUserSignatureInput): Observable<IApiUserSignature> {
    return this.userService.addUserSignature(input);
  }

}
