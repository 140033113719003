<!-- Permissions -->
<div [appEnforcePermission]="authConfig.companies" #companiesList="appEnforcePermission"></div>
<!-- Permissions -->
<div class="kendo-formfield left-search-icon multi">
  <kendo-label [for]="" text="{{label}}"></kendo-label>
  <div style="position: relative;">
    <i class="fa fa-search" aria-hidden="true"></i>
    <kendo-multiselect #multiSelect
      [checkboxes]="true"
      [autoClose]="false"
      [loading]="loading"
      [valuePrimitive]="true"
      [data]="companies"
      [(ngModel)]="selectedValue"
      [rounded]="'full'"
      (valueChange)="selectCompany($event)"
      valueField="id"
      textField="name"
      [tagMapper]="tagMapper"
      [placeholder]="placeholder"
      [fillMode]="'outline'"
      [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}"
      (focus)="onSearchFocus()"
      (filterChange)="onSearchChange($event)"
      [disabled]="disabled || !companiesList?.enabled">
      <ng-template kendoMultiSelectItemTemplate let-dataItem>
        <span style="padding-left: 5px;">{{ dataItem?.name }}</span>
      </ng-template>
      <ng-template kendoMultiSelectNoDataTemplate *ngIf="!searchString?.trim()?.length">
        PLEASE SEARCH SOMETHING...
      </ng-template>
      <ng-template kendoMultiSelectNoDataTemplate *ngIf="searchString?.trim()?.length">
        NO DATA FOUND
      </ng-template>
    </kendo-multiselect>
  </div>
</div>
