<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffViewAll" #adminPerm="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <h4 class="page-title">Quarterly Hour Report</h4>
  <kendo-card width="100%">
    <div class="content-wrapper">
      <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(5,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <app-user-multi-select-kendo label="Staff Member" [userViewFilter]="userViewFilter"
            placeholder="Select Staff Member" [selectedId]="filterByIdValue(filterTypes.Users)" [investigationRole]=""
            [includeInactive]="true"
            (selectionChanged)="setFilters($event, filterTypes.Users)"></app-user-multi-select-kendo>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item colSpan="3" class="mb-0">
          <app-date-range-next-prev-filter-kendo [mode]="'quarter'" [allowChangeFilter]="true"
            [selectedId]="filterValue(filterTypes.WorkDayRange)"
            (dateRangeChange)="setFilters($event, filterTypes.WorkDayRange)"></app-date-range-next-prev-filter-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <div class="pt-0 mb-16">
        <button kendoButton themeColor="light" fillMode="solid" iconClass="fa-solid fa-file-export" (click)="exportCsv()"
          class="mr-10">Export as CSV</button>
      </div>
      <kendo-grid class="report-list quarterly-list td-20-16 odd-even-background"
        [ngClass]="{'report-list': innerWidth <= 1541}" [data]="{
        data: data,
        total: (dataSource?.pageInfo$ | async)?.totalCount
      }" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="
        pageOptions
          ? {
              buttonCount: 6,
              type: 'numeric',
              pageSizes: [5, 10, 25, 100],
              previousNext: true,
              responsive: true,
              position: 'bottom'
            }
          : false
      " (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)"
        [navigatable]="false" [resizable]="true" [scrollable]="true">
        <kendo-grid-column field="USER_NAME" class="id-column" [locked]="innerWidth <= 1541 ? true:false"
          title="Staff Member" [width]="150" [sortable]="true" [resizable]="false" [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a
              *ngIf="adminPerm.enabled; else nonadmin"
              [routerLink]="['/staff/', dataItem?.id]"
              class="text-decoration"
              target="_blank"
            >{{ dataItem?.lastName
              ? dataItem?.lastName + ", " : "" }}
              {{ dataItem?.firstName || "" }}</a>
            <ng-template #nonadmin>
              <span class="font-weight-600">
                {{ dataItem?.lastName ? dataItem?.lastName + ", " : "" }}{{ dataItem?.firstName || "" }}
              </span>
            </ng-template>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="QUARTERLY_GOAL" title="Required Hours (By Quarter)" [width]="200" [sortable]="true"
          [resizable]="false" [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="font-weight-600">
              {{ dataItem?.quarterlyGoal || "-" }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [field]="'TIME_ENTRY_TYPE_TOTAL-' + quarterlyHourReportTypeKeys?.TRAINING"
          [title]="quarterlyHourReportTypeKeys?.TRAINING" [width]="150" [sortable]="true" [resizable]="false"
          [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="sub-grid" [ngClass]="{'defaultRowTitle': rowIndex === 0}">
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.TRAINING]?.hours || "-" }}
              </span>
              <span class="separate-pipe" *ngIf="rowIndex === 0">|</span>
              <span *ngIf="rowIndex !== 0">&nbsp;</span>
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.TRAINING]?.nonBillableHours || "-" }}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [field]="'TIME_ENTRY_TYPE_TOTAL-' + quarterlyHourReportTypeKeys?.FIRE_INVESTIGATION"
          [title]="quarterlyHourReportTypeKeys?.FIRE_INVESTIGATION" [width]="200" [sortable]="true" [resizable]="false"
          [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="sub-grid" [ngClass]="{'defaultRowTitle': rowIndex === 0}">
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.FIRE_INVESTIGATION]?.hours || "-" }}
              </span>
              <span class="separate-pipe" *ngIf="rowIndex === 0">|</span>
              <span *ngIf="rowIndex !== 0">&nbsp;</span>
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.FIRE_INVESTIGATION]?.nonBillableHours || "-" }}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [field]="'TIME_ENTRY_TYPE_TOTAL-' + quarterlyHourReportTypeKeys?.TECH_REVIEWER"
          [title]="quarterlyHourReportTypeKeys?.TECH_REVIEWER" [width]="200" [sortable]="true" [resizable]="false"
          [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="sub-grid" [ngClass]="{'defaultRowTitle': rowIndex === 0}">
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.TECH_REVIEWER]?.hours || "-" }}
              </span>
              <span class="separate-pipe" *ngIf="rowIndex === 0">|</span>
              <span *ngIf="rowIndex !== 0">&nbsp;</span>
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.TECH_REVIEWER]?.nonBillableHours || "-" }}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [field]="'TIME_ENTRY_TYPE_TOTAL-' + quarterlyHourReportTypeKeys?.ELECTRICAL_ENGINEER"
          [title]="quarterlyHourReportTypeKeys?.ELECTRICAL_ENGINEER" [width]="200" [sortable]="true" [resizable]="false"
          [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="sub-grid" [ngClass]="{'defaultRowTitle': rowIndex === 0}">
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.ELECTRICAL_ENGINEER]?.hours || "-" }}
              </span>
              <span class="separate-pipe" *ngIf="rowIndex === 0">|</span>
              <span *ngIf="rowIndex !== 0">&nbsp;</span>
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.ELECTRICAL_ENGINEER]?.nonBillableHours || "-" }}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [field]="'TIME_ENTRY_TYPE_TOTAL-' + quarterlyHourReportTypeKeys?.EVIDENCE_TECHNICIAN"
          [title]="quarterlyHourReportTypeKeys?.EVIDENCE_TECHNICIAN" [width]="200" [sortable]="true" [resizable]="false"
          [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="sub-grid" [ngClass]="{'defaultRowTitle': rowIndex === 0}">
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.EVIDENCE_TECHNICIAN]?.hours || "-" }}
              </span>
              <span class="separate-pipe" *ngIf="rowIndex === 0">|</span>
              <span *ngIf="rowIndex !== 0">&nbsp;</span>
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.EVIDENCE_TECHNICIAN]?.nonBillableHours || "-" }}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [field]="'TIME_ENTRY_TYPE_TOTAL-' + quarterlyHourReportTypeKeys?.HAZMAT"
          [title]="quarterlyHourReportTypeKeys?.HAZMAT" [width]="150" [sortable]="true" [resizable]="false"
          [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="sub-grid" [ngClass]="{'defaultRowTitle': rowIndex === 0}">
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.HAZMAT]?.hours || "-" }}
              </span>
              <span class="separate-pipe" *ngIf="rowIndex === 0">|</span>
              <span *ngIf="rowIndex !== 0">&nbsp;</span>
              <span>
                {{ dataItem[quarterlyHourReportTypeKeys?.HAZMAT]?.nonBillableHours || "-" }}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </kendo-card>
</section>
