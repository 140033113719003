<!-- Permissions -->
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageUpdate" #expenseUnlinkedMileageUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageUpdateOwn" #expenseUnlinkedMileageUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageUpdate" #expenseInvestigationMileageUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageUpdateOwn" #expenseInvestigationMileageUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesUpdate" #expenseInvestigationExpensesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesUpdateOwn" #expenseInvestigationExpensesUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseUpdate" #expenseUnlinkedExpenseUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseUpdateOwn" #expenseUnlinkedExpenseUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageDelete" #expenseUnlinkedMileageDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageDeleteOwn" #expenseUnlinkedMileageDeleteOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageDelete" #expenseInvestigationMileageDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageDeleteOwn" #expenseInvestigationMileageDeleteOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesDelete" #expenseInvestigationExpensesDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesDeleteOwn" #expenseInvestigationExpensesDeleteOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkeExpenseDelete" #expenseUnlinkeExpenseDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkeExpenseDeleteOwn" #expenseUnlinkeExpenseDeleteOwn="appEnforcePermission"></div>
<!-- Permissions -->
<section *ngIf="expenses?.length else noExpenses">
    <kendo-grid
		class="expense-list"
		[data]="{
		data: expenses,
		total: (dataSource?.pageInfo$ | async)?.totalCount
		}"
		[pageSize]="pageOptions?.limit"
		[skip]="pageOptions?.offset"
		[pageable]="{
			buttonCount: 6,
			pageSizes: [5, 10, 25, 100],
			previousNext: true,
			responsive: true,
			position: 'bottom'
		}"
		(pageChange)="pageOptions?.paginate($event, false)"
		[sortable]="true"
		[sort]="sort"
		(sortChange)="sortChange($event)"
    	(selectionChange)="onExpenseCheckboxChange($event)"
		[selectable]="{checkboxOnly: true, mode: 'multiple' }"
		kendoGridSelectBy="id"
		[(selectedKeys)]="selectedExpense"
		>
		<kendo-grid-checkbox-column [width]="5">
			<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
				<ng-container *ngIf="!dataItem?.paidDate">
					<input type="checkbox"
					kendoCheckBox
					[checked]="selectedExpense.includes(dataItem.id)"
					[disabled]="dataItem?.paidDate"
					(click)="onExpenseCheckboxChecked($event?.target?.checked, dataItem)"/>
				</ng-container>
			</ng-template>
		</kendo-grid-checkbox-column>

		<kendo-grid-column
			field="CREATED_AT"
			title="Entry Date"
			[width]="10"
			[sortable]="true"
			class="font-weight600"
			[headerClass]="'sort-header remove-bar'"
		>
			<ng-template kendoGridCellTemplate let-dataItem>
				{{ dataItem?.createdAt | customDate : "MM/dd/yyyy" }}
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column
			field="INVESTIGATION"
			title="Investigation ID"
			[width]="12"
			class="id-column"
			[sortable]="false"
			class="font-weight600"
			[headerClass]="'nocursor'"
		>
			<ng-template kendoGridCellTemplate let-dataItem>
				<a
				target="_blank"
				[routerLink]="['/investigations/', dataItem?.Investigation?.id]"
				tel=""
				class="text-decoration"
				*ngIf="dataItem?.Investigation else Unlinked"
				>
				{{ dataItem?.Investigation?.nefcoNumber || "Unlinked" }}
				</a>
				<ng-template #Unlinked>
					<span>Unlinked</span>
				</ng-template>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column
		field="id"
		title="Expense Item"
		[width]="15"
		[sortable]="false"
		[headerClass]="'nocursor'"
		>
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{dataItem?.ExpenseItem?.name || '-'}}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column
		field="id"
		title="Purpose"
		[width]="15"
		[sortable]="false"
		[headerClass]="'nocursor'"
		>
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{dataItem?.ExpensePurpose?.name || '-'}}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column
		field="id"
		title="Description"
		[width]="15"
		[sortable]="false"
		[headerClass]="'nocursor'"
		>
			<ng-template kendoGridCellTemplate let-dataItem>
				<span class="text-overflow">{{dataItem?.description || '-'}}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column
		field="id"
		title="Receipt"
		[width]="15"
		[sortable]="false"
		class="text-center"
		[headerClass]="'nocursor text-center'"
		>
			<ng-template kendoGridCellTemplate let-dataItem>
				<span class="cursor"
					*ngIf="dataItem?.Document?.id && (auth.isPermissionRoles(['Admin', 'Billing', 'Tech Reviewer']) | async) === true"
					(click)="downloadReciept(dataItem?.Document)">
					<i class="fa-solid fa-receipt"></i>
				</span>
				<span  *ngIf="dataItem?.Document?.id && (auth.isPermissionRoles(['Admin', 'Billing', 'Tech Reviewer']) | async) === false">
					<i class="fa-solid fa-receipt"></i>
				</span>
			</ng-template>
			<ng-template kendoGridFooterTemplate let-column>
				<span class="total-section text-overflow" style="display: flex;
				justify-content: center;">
					Total of all {{(dataSource?.pageInfo$ | async)?.totalCount}} items
				</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column
		field="id"
		title="Cost"
		[width]="9"
		[sortable]="false"
		class="text-right"
		[headerClass]="'nocursor text-right mr-12'"
		>
			<ng-template kendoGridCellTemplate let-dataItem>
				<span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name !== 'Mileage'">${{(dataItem?.billableQuantity + dataItem?.nonBillableQuantity) | number: '1.2-2'}}</span>
				<span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name === 'Mileage'">${{ (dataItem | reimbursementRate)| number: '1.2-2'}}</span>
			</ng-template>
			<ng-template kendoGridFooterTemplate let-column>
				<span style="display: flex;
				justify-content: end;
				padding-right: 8px;" class="total-section">
					${{(expenseTotal.totalExpense) | number: '1.2-2'}}
				</span>
          </ng-template>
		</kendo-grid-column>

		<kendo-grid-column
		field="id"
		title="Authorized"
		[width]="15"
		[sortable]="false"
		[headerClass]="'nocursor'"
		>
		<ng-template kendoGridCellTemplate let-dataItem>
			<!-- <span *ngIf="!dataItem?.authorizedBy && !dataItem?.authorizedDate">-</span> -->
			<span class="text-overflow" *ngIf="dataItem?.authorizedBy">{{dataItem?.authorizedBy}} <br /></span>
			<span *ngIf="dataItem?.authorizedDate" style="color: rgba(1, 24, 98, 0.6)">on {{dataItem?.authorizedDate | customDate : 'MM/dd/yyyy'}}</span>
		</ng-template>
		</kendo-grid-column>

		<kendo-grid-column
        field="EXPENSE_DATE"
        title="Expense Date"
        [width]="12"
        [sortable]="true"
        [headerClass]="'sort-header'"
      	>
			<ng-template kendoGridCellTemplate let-dataItem>
			{{ dataItem?.expenseDate | customDate : "MM/dd/yyyy" }}
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column
		class="text-center pr-15"
		field="PAID_DATE"
		title="Paid"
		[width]="7"
		[sortable]="false"
		[headerClass]="'nocursor text-center pl-0'"
		>
		<ng-template kendoGridCellTemplate let-dataItem>
			<span class="pl-0">
				<i *ngIf=" dataItem?.paidDate" class="right-circle-icon fa-solid fa-circle-check"></i>
				<i *ngIf="!dataItem?.paidDate" class="minus-circle-icon fa-solid fa-circle-minus"></i>
			</span>
		</ng-template>
		</kendo-grid-column>

		<kendo-grid-column
			field="id"
			title="Action"
			[width]="12"
			[sortable]="false"
			[headerClass]="'nocursor'"
		>
			<ng-template kendoGridCellTemplate let-dataItem>
				<button kendoButton class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil"
					(click)="expenseAction(dataItem, actionType.UPDATE);" *ngIf="checkEditPermission(dataItem); else noPermission"
					[disabled]="(dataItem?.paidDate && (auth.isPermissionRoles(['Admin']) | async) === false) || dataItem?.InvoiceItems?.length > 0"></button>
				<ng-template #noPermission>
					<button kendoButton class="mr-8 visibility-hidden" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil"></button>
				</ng-template>
				<!-- <button kendoButton class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-regular fa-copy" (click)="expenseAction(dataItem, actionType.DUPLICATE);"></button> -->
				<button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-trash"
					(click)="deleteExpense(dataItem)" *ngIf="checkDeletePermission(dataItem)"
					[disabled]="(dataItem?.paidDate && (auth.isPermissionRoles(['Admin']) | async) === false) || dataItem?.InvoiceItems?.length > 0"></button>
			</ng-template>
		</kendo-grid-column>

 	</kendo-grid>
</section>
<ng-template #noExpenses>
	<div class="no-expenses" *ngIf="((dataSource?.loading$ | async) === false) && expenses?.length <= 0">
		<h2 class="h2">No Expenses Found</h2>
		<h2 class="h2 small"><small>Please select a different staff member or month</small></h2>
	</div>
</ng-template>