import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { IApiDocumentShareFilter, IApiDocumentSentConnection, IApiDocumentSentFilter } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { GqlDocumentShareService } from '../../../modules/graphql/services';
import { Injectable } from '@angular/core';

@Injectable()
export class SharedReportService {

  constructor(
    private documentShares: GqlDocumentShareService,
  ) { }

  public get(filters: IApiDocumentSentFilter[] = [], options: IGqlBaseOptions): Observable<IApiDocumentSentConnection> { 
    return this.documentShares.getSharedReport(filters, options || new ListPage(-1));
  }
}
