import { IApiEvidence, IApiEvidenceConnection } from './../../modules/graphql/types/types';
import { GqlEvidenceService } from 'src/app/shared/modules/graphql/services/evidence/evidence.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import {
  IApiAddEvidenceInput,
  IApiEvidenceFilter,
  IApiUpdateEvidenceInput,
  IApiRemoveEvidenceInput
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "../../helpers/list-page.class";

@Injectable()
export class EvidenceService {

  constructor(
    private gqlEvidenceService: GqlEvidenceService
  ) { }

  public get(filters?: IApiEvidenceFilter[], options?: IGqlBaseOptions): Observable<IApiEvidenceConnection> {
    return this.gqlEvidenceService.getEvidence(filters, options || new ListPage());
  }

  public getById(id: string): Observable<IApiEvidence> {
    return this.gqlEvidenceService.getById(id);
  }

  public addEvidence(input: IApiAddEvidenceInput) {
    return this.gqlEvidenceService.addEvidence(input);
  }

  public removeEvidence(input: IApiRemoveEvidenceInput) {
    return this.gqlEvidenceService.removeEvidence(input);
  }

  public updateEvidence(input: IApiUpdateEvidenceInput) {
    return this.gqlEvidenceService.updateEvidence(input);
  }

  public generateEvidenceListCSV(filters?: IApiEvidenceFilter[], options?: IGqlBaseOptions): Observable<string> {
    return this.gqlEvidenceService.generateEvidenceListCSV(filters, options || new ListPage());
  }
}
