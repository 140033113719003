import { connectionQuery } from './../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../interfaces/base.interfaces';
import { evidenceConnectionQuery, addEvidenceMutation, removeEvidenceMutation, updateEvidenceMutation, getEvidenceById, generateEvidenceListCSV } from './evidence.operations';
import {
  IApiAddEvidenceInput,
  IApiUpdateEvidenceInput,
  IApiRemoveEvidenceInput,
  IApiEvidenceConnection,
  IApiEvidenceFilter,
  IApiEvidence
} from '../../types/types';

@Injectable()
export class GqlEvidenceService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getEvidence(filters: IApiEvidenceFilter[], options: IGqlBaseOptions): Observable<IApiEvidenceConnection> {
    return this.apollo.query(connectionQuery(evidenceConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getById(id: string): Observable<IApiEvidence> {
    return this.apollo.query({
      query: getEvidenceById,
      variables: {
        id
      }
    }).pipe(
      safeResolve(true)
    );
  }

  public addEvidence(input: IApiAddEvidenceInput) {
    return this.apollo.mutate({
      mutation: addEvidenceMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeEvidence(input: IApiRemoveEvidenceInput) {
    return this.apollo.mutate({
      mutation: removeEvidenceMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateEvidence(input: IApiUpdateEvidenceInput) {
    return this.apollo.mutate({
      mutation: updateEvidenceMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public generateEvidenceListCSV(filters: IApiEvidenceFilter[], options: IGqlBaseOptions): Observable<string> {
    return this.apollo.query(connectionQuery(generateEvidenceListCSV, filters, options)).pipe(
      safeResolve(true)
    );
  }
}

