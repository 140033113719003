<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffContactInformationView"
  #staffContactInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationOwnView"
  #staffContactInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationUpdate"
  #staffContactInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationOwnUpdate"
  #staffContactInformationOwnUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationView"
  #staffEmergencyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationOwnView"
  #staffEmergencyInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationView" #staffUserInformationView="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.staffUserInformationOwnView"
  #staffUserInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationOwnUpdate"
  #staffUserInformationOwnUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffWorkingHoursView" #staffWorkingHoursView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffWorkingHourOwnView" #staffWorkingHourOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationOwnUpdate"
  #staffEmergencyInformationOwnUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUpdateConfig" #staffUpdateConfig="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsList" #staffCertificationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnList" #staffCertificationsOwnList="appEnforcePermission">
</div>
<!-- Permissions -->
<section class="content-wrapper">
  <form userForm="ngForm">
    <kendo-card [width]="'100%'" class="mt-16"
      *ngIf="staffContactInformationView.enabled || (staffContactInformationOwnView.enabled && isOwnerUser)">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">Contact Information</h2>
          <i class="fa fa-pen"
            *ngIf="staffContactInformationUpdate.enabled || (staffContactInformationOwnUpdate.enabled && isOwnerUser)"
            (click)="editUser();"></i>
        </div>
        <kendo-card [width]="'100%'" class="mt-16">
          <kendo-card-body class="inner-card-body p-0 card-detail">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Title
                </span>
                <p class="mt-1">
                  {{ user.title || '-'}}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Microsoft O365 ID
                </span>
                <p class="mt-1">
                  {{ user.msGraphId || '-'}}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Additional Information
                </span>
                <p class="mt-1" [innerHTML]="user.UserDetail?.additionalInformation || '-'">
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Name
                </span>
                <p class="mt-1">
                  {{ user.firstName }} {{ user.middleName }} {{ user.lastName }}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  SMS / Voice Phone Number
                </span>
                <p class="mt-1">
                  {{ (user.twilioPhoneNumber | phoneFormat:'US') || '-'}}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Suffix
                </span>
                <p class="mt-1">
                  {{ user.suffix || '-'}}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Nickname
                </span>
                <p class="mt-1">
                  {{ user.nickname || '-'}}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  TimeZone
                </span>
                <p class="mt-1">
                  {{ user?.timezone || 'America/New_York' }}
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item class="mb-0">
                <span>
                  Position(s)
                </span>
                <ng-container *ngIf="user?.Positions?.length;else showNoValue">
                  <p class="mt-1 mb-0" *ngFor="let position of user.Positions;">
                    {{ position.title }}
                  </p>
                </ng-container>
                <ng-template #showNoValue>-</ng-template>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Email(s)
                </span>
                <ng-container *ngIf="user?.Emails?.length;else showNoValue">
                  <app-email-phone-view *ngFor="let email of user.Emails" [email]="email"></app-email-phone-view>
                </ng-container>
                <ng-template #showNoValue>-</ng-template>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Phone(s)
                </span>
                <ng-container *ngIf="user?.Phones?.length;else showNoValue">
                  <app-email-phone-view *ngFor="let phone of user.Phones" [phone]="phone"></app-email-phone-view>
                </ng-container>
                <ng-template #showNoValue>-</ng-template>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item class="mb-0">
                <span>
                  Addresses
                </span>
                <ng-container *ngIf="user?.Addresses?.length;else showNoValue">
                  <app-address-view *ngFor="let address of user.Addresses" [address]="address"></app-address-view>
                </ng-container>
                <ng-template #showNoValue>-</ng-template>
              </kendo-gridlayout-item>

            </kendo-gridlayout>
          </kendo-card-body>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
    <!-- Emergency Contact Information - Read -->
    <kendo-card [width]="'100%'" class="mt-16"
      *ngIf="staffEmergencyInformationView.enabled || (staffEmergencyInformationOwnView.enabled && isOwnerUser)">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">Emergency Contact Information</h2>
          <div [appEnforcePermission]="authConfig.staffEmergencyInformationUpdate"
            #staffEmergencyInformationUpdate="appEnforcePermission">
            <i class="fa fa-pen"
              *ngIf="staffEmergencyInformationUpdate.enabled  || (staffEmergencyInformationOwnUpdate.enabled && isOwnerUser)"
              (click)="editUser();"></i>
          </div>
        </div>
        <kendo-card [width]="'100%'" class="mt-16">
          <kendo-card-body class="inner-card-body p-0 card-detail">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Name
                </span>
                <p class="mt-1" [innerHTML]="user.UserDetail?.emergencyContactName || '-'">
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Phone
                </span>
                <p class="mt-1" [innerHTML]="user.UserDetail?.emergencyContactInfo || '-'">
                </p>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-card-body>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
    <!-- User Information - Read -->
    <kendo-card [width]="'100%'" class="mt-16"
      *ngIf="staffUserInformationView.enabled || (staffUserInformationOwnView.enabled && isOwnerUser) || staffCertificationsList?.enabled || (staffCertificationsOwnList?.enabled && isOwnerUser)">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">
            User Information
            <span kendoTooltip position="right" style="font-size: 16px;" [tooltipTemplate]="templateInfo" filter="i"
              tooltipClass="tooltipwidthauto">
              <i class="fa-solid fa-bell fa-lg" *ngIf="user?.notificationEnabled"></i>
              <i class="fa-solid fa-bell-slash fa-lg" *ngIf="!user?.notificationEnabled"></i>
            </span>
          </h2>
          <ng-template #templateInfo>
            {{user?.notificationEnabled ? 'Notifications Enabled': 'Notifications Disabled'}}
          </ng-template>
          <div [appEnforcePermission]="authConfig.staffUserInformationUpdate"
            #staffUserInformationUpdate="appEnforcePermission">
            <i class="fa fa-pen"
              *ngIf="staffUserInformationUpdate.enabled || (staffUserInformationOwnUpdate.enabled && isOwnerUser)"
              (click)="editUser();"></i>
          </div>
        </div>
        <kendo-card [width]="'100%'" class="mt-16">
          <kendo-card-body class="inner-card-body p-0 card-detail">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
              <ng-container
                *ngIf="staffUserInformationView.enabled || (staffUserInformationOwnView.enabled && isOwnerUser)">
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    Start Date
                  </span>
                  <p class="mt-1"
                    [innerHTML]="user.UserDetail?.startDate ? (user.UserDetail?.startDate | customDate: 'MM/dd/yyyy') : 'N/A'">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    End Date
                  </span>
                  <p class="mt-1"
                    [innerHTML]="user.UserDetail.endDate ? (user.UserDetail.endDate | customDate: 'MM/dd/yyyy') : 'N/A'">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    Pay Rate
                  </span>
                  <p class="mt-1">
                    {{ ((user.UserDetail?.payRateHourly || 0) | currency) }} <span matSuffix
                      class="font-weight-bold">/hr</span>
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0"
                  *ngIf="staffWorkingHoursView.enabled || (staffWorkingHourOwnView.enabled && isOwnerUser)">
                  <span>
                    Required Hours
                  </span>
                  <p class="mt-1">
                    {{ user.UserDetail?.requiredHoursQuarterly }} <span matSuffix
                      class="font-weight-bold">/Quarter</span>
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    Permission Roles
                  </span>
                  <p class="mt-1">
                    <ng-container *ngFor="let permissionRole of user?.PermissionRoles">
                      <p class="mb-0">{{ permissionRole?.name }}</p>
                    </ng-container>
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0" *ngIf="user?.TechReviewer">
                  <span>
                    Tech Reviewer Assigned
                  </span>
                  <p class="mt-1">
                    {{ user.TechReviewer | fullName }}
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0" *ngIf="user?.AssignedRegionalStaff?.length">
                  <span>
                    Regional Staff Member Assigned
                  </span>
                  <p class="mt-1">
                    <ng-container *ngFor="let staff of user?.AssignedRegionalStaff">
                      <p class="mb-0"> {{ staff | fullName }}</p>
                    </ng-container>
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0" *ngIf="user?.RegionalManager">
                  <span>
                    Regional Manager Assigned
                  </span>
                  <p class="mt-1">
                    {{ user.RegionalManager | fullName }}
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    Status
                  </span>
                  <p class="mt-1" [innerHTML]="user.UserDetail?.status || '-'">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    Full Time
                  </span>
                  <p class="mt-1" [innerHTML]="user.UserDetail?.fullTime ? 'YES' : 'NO'">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    NEFCO Credit Card
                  </span>
                  <p class="mt-1" [innerHTML]="user.UserDetail?.hasNefcoCreditCard ? 'YES' : 'NO'">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    NEFCO Vehicle
                  </span>
                  <p class="mt-1" [innerHTML]="user.UserDetail?.hasNefcoVehicle ? 'YES' : 'NO'">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    Driver's Information
                  </span>
                  <p class="mt-1 mb-0"> Driver's License Number: {{user.UserDetail?.driversLicenseNumber || '-'}}</p>
                  <p> License State: {{ user.UserDetail?.driversLicenseState || '-' }}</p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    Vehicles
                  </span>
                  <p class="mt-1 mb-0" *ngFor="let vehicle of user?.Vehicles;">
                    <span class="font-weight-bold">Make:</span> {{ vehicle.make }}
                    <span class="font-weight-bold">Model:</span> {{ vehicle.model }} <br />
                    <span class="font-weight-bold">Year:</span> {{ vehicle.year }}
                    <span class="font-weight-bold">Vin:</span> {{ vehicle.vinNumber }}
                  </p>
                </kendo-gridlayout-item>
              </ng-container>
              <ng-container
                *ngIf="staffCertificationsList?.enabled || (staffCertificationsOwnList?.enabled && isOwnerUser)">
                <kendo-gridlayout-item class="mb-0">
                  <span>
                    Restrictions
                  </span>
                  <p class="mt-1" *ngIf="!user.Restrictions?.length">None</p>
                  <p class="mt-1 mb-0" *ngFor="let restriction of user.Restrictions">
                    {{ restriction.Type?.title || '-' }}
                  </p>
                </kendo-gridlayout-item>
              </ng-container>
            </kendo-gridlayout>
          </kendo-card-body>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
    <!-- User Signature -->
    <kendo-card [width]="'100%'" class="mt-16">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">Signature</h2>
          <i class="fa fa-pen"
            *ngIf="user?.UserSignature?.length && (staffUpdateConfig?.enabled || staffContactInformationUpdate?.enabled || (staffContactInformationOwnUpdate.enabled && isOwnerUser))"
            (click)="openSinguareModal();"></i>
        </div>
        <kendo-card [width]="'100%'" class="mt-16">
          <kendo-card-body class="inner-card-body p-0" *ngIf="user?.UserSignature?.length else noSignature">
            <img [src]="user?.UserSignature[0].s3Uri | s3" width="250" height="100" />
            <p class="mb-8 mt-8">Last Signature Uploaded: <span>{{user?.UserSignature[0].CreatedBy?.lastName}}
                {{user?.UserSignature[0].CreatedBy?.firstName}}</span></p>
            <p>Last Signature Updated: <span>{{user?.UserSignature[0].updatedAt | customDate: "MM/dd/yyyy"}}</span></p>
          </kendo-card-body>
          <ng-template #noSignature>
            <kendo-card-body class="inner-card-no-data p-0"
              *ngIf="staffUpdateConfig?.enabled || staffContactInformationUpdate?.enabled  || (staffContactInformationOwnUpdate.enabled && isOwnerUser)">
              <h2>No Signature Found</h2>
              <p>Upload an image of your signature</p>
              <button kendoButton themeColor="primary" fillMode="solid" (click)="openSinguareModal()">Upload
                Signature</button>
            </kendo-card-body>
          </ng-template>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
  </form>
</section>

<kendo-dialog #signatureDialog *ngIf="isSignatureModalOpen" class="signature-modal" (close)="openSinguareModal(false);" 
[minWidth]="651" [width]="651" [dialogRef]="signatureDialog" (appClickOutside)="openSinguareModal(false);">
  <kendo-dialog-titlebar>
    <div class="modal-title">
      <span>{{user?.UserSignature?.length ? 'Edit Signature' : 'Add Signature'}}</span>
    </div>
  </kendo-dialog-titlebar>

  <div class="model-body pt-16 pb-16">
    <form #receivedDateForm="ngForm">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item class="signature-section mb-0">
          <div class="outer-style">
            <img [src]="(uploadedSignature || user?.UserSignature[0].s3Uri) | s3" width="250" height="100"
              *ngIf="uploadedSignature || user?.UserSignature?.length else noSignature" />
            <ng-template #noSignature>
              <p class="mb-0">No Signature Found</p>
            </ng-template>
          </div>
          <div>
            <h3 class="mb-16">Upload an image of your signature</h3>
            <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="fileSelector();">
                Upload Signature
            </button>
            <app-kendo-alert [showIcon]="true"
              [message]="'Uploading a different signature will overwrite the existing one.'"></app-kendo-alert>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </div>

  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="openSinguareModal(false)">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" [disabled]="!uploadedSignature"
      (click)="saveSignature()">
      Save Edits
    </button>
  </kendo-dialog-actions>
</kendo-dialog>