import { connectionQuery } from './../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../interfaces/base.interfaces';
import { addShippingCarrierMutation, ShippingCarrierConnectionQuery, removeShippingCarrierMutation, updateShippingCarrierMutation } from "./shipping-carrier.operations";
import { IApiAddShippingCarrierInput, IApiShippingCarrier, IApiShippingCarrierConnection, IApiShippingCarrierFilter, IApiUpdateShippingCarrierInput } from "../../types/types";

@Injectable()
export class GqlShippingCarrierService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getShippingCarriers(filters: IApiShippingCarrierFilter[], options: IGqlBaseOptions): Observable<IApiShippingCarrierConnection> {
    return this.apollo.query(connectionQuery(ShippingCarrierConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addShippingCarrier(input: IApiAddShippingCarrierInput): Observable<IApiShippingCarrier> {
    return this.apollo.mutate({
      mutation: addShippingCarrierMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public updateShippingCarrier(input: IApiUpdateShippingCarrierInput): Observable<IApiShippingCarrier> {
    return this.apollo.mutate({
      mutation: updateShippingCarrierMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeShippingCarrier(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeShippingCarrierMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
