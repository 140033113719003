<div class="notification-error">
    <div class="message-wrapper">
      <div class="icon" *ngIf="showIcon">
        <i
          [class]="iconClass"
          aria-hidden="true"
        ></i>
      </div>
      <div class="message">
        <span>{{ message }}</span>
      </div>
    </div>
    <div class="link" *ngIf="routeLink">
      <a [routerLink]="routeLink" class="kendo-anchor-tag" (click)="linkClick()">{{
        routerLinkLabel
      }}</a>
    </div>
    <div class="icon" *ngIf="showCloseIcon" (click)="closeIcon.emit()">
      <i class="fa-solid fa-xmark"></i>
    </div>
</div>