import { connectionQuery } from './../../../helpers.class';
import { IApiDocumentCategoryConnection, IApiDocumentCategoryFilter } from '../../../types/types';
import { IApiUpdateDocumentCategoryInput, IApiAddDocumentCategoryInput } from '../../../types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { IApiDocumentCategory } from "../../../types/types";
import { IGqlBaseOptions } from "../../../interfaces";
import { documentCategoryConnectionQuery, addDocumentCategoryMutation, updateDocumentCategoryMutation, removeDocumentCategoryMutation } from "./document-category.operations";
import { safeResolve } from "../../../rxjs";

@Injectable()
export class GqlDocumentCategoryService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getCategories(filters: IApiDocumentCategoryFilter[], options: IGqlBaseOptions): Observable<IApiDocumentCategoryConnection> {
    return this.apollo.query(connectionQuery(documentCategoryConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addCategory(input: IApiAddDocumentCategoryInput): Observable<IApiDocumentCategory> {
    return this.apollo.mutate({
      mutation: addDocumentCategoryMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateCategory(input: IApiUpdateDocumentCategoryInput): Observable<IApiDocumentCategory> {
    return this.apollo.mutate({
      mutation: updateDocumentCategoryMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeCategory(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeDocumentCategoryMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}