import { IApiEvidenceStorageLocation, IApiEvidenceStorageLocationConnection, IApiEvidenceStorageLocationFilter } from './../../../modules/graphql/types/types';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { EvidenceStorageLocationService } from "./evidence-storage-location.service";
import { NefcoPagedDataSource } from '../../../helpers/nefco-data-source.class';

export class EvidenceStorageLocationDataSource extends NefcoPagedDataSource<IApiEvidenceStorageLocation, EvidenceStorageLocationService, IApiEvidenceStorageLocationConnection> implements DataSource<IApiEvidenceStorageLocation> {
  @ApiConnection()
  public load(filters: IApiEvidenceStorageLocationFilter[] = []): Promise<IApiEvidenceStorageLocationConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
