import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IApiInvestigationMetricsFilter, IApiQueryGenerateInvestigationAndEvidenceCsvArgs } from '../../../types/types';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import {  generateEvidenceReportQuery, getEvidenceReportQuery } from "./evidence-report.operations";
import { IApiInvestigationMetricsConnection } from 'src/app/shared/modules/graphql/types/types';
import { IGqlBaseOptions } from '../../../interfaces';
import { connectionQuery } from '../../../helpers.class';

@Injectable()
export class GqlEvidencenReportService {
  public constructor(
    private apollo: Apollo
  ) { }



  public getCsvReport(input?: IApiQueryGenerateInvestigationAndEvidenceCsvArgs): Observable<string> {
    return this.apollo.query({
      query: generateEvidenceReportQuery,
      variables: { ...input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getEvidenceReport(filters: IApiInvestigationMetricsFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationMetricsConnection> {
    return this.apollo.query(connectionQuery(getEvidenceReportQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

}
