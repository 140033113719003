import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IApiEvidenceStatusType, IApiExpense, IApiInvestigation } from 'src/app/shared/modules/graphql/types/types';

@Component({
  selector: 'app-evidence-table',
  templateUrl: './evidence-table.component.html',
  styleUrls: ['./evidence-table.component.scss']
})
export class EvidenceTableComponent {

  public _investigation: IApiInvestigation;

  get investigation() {
    return this._investigation;
  }
  
  @Input() set investigation(val) {
    if (val) {
      val = {
        ...val,
        Evidence: val.Evidence.filter(i => i.InvoiceItems.length === 0 && i.Status === IApiEvidenceStatusType.Stored)
      }
    }
    this._investigation = val;
  }

  @Input() public evidenceExpenses: IApiExpense[];
  @Input() pageTitle: string = 'Evidence';
  @Output() addEvidenceExpense = new EventEmitter();

  public sort: SortDescriptor[] = [{
    field: '',
    dir: 'desc'
  }];

  constructor() {
  }

  public sortField = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e?.[0]?.dir === 'asc') {
      return this._investigation?.Evidence?.sort((a, b) => {
        return <any>new Date(b.createdAt) - <any>new Date(a.createdAt);
      });
    } else if (e?.[0]?.dir === 'desc') {
      return this._investigation?.Evidence?.sort((a, b) => {
        return <any>new Date(a.createdAt) - <any>new Date(b.createdAt);
      });
    } else {
      return this._investigation?.Evidence?.sort((a, b) => (a.evidenceNumber > b.evidenceNumber) ? 1 : -1);
    }
  }

  public addEvidence() {
    this.addEvidenceExpense.emit();
  }

}
