<section>
  <!-- Permissions -->
  <div [appEnforcePermission]="authConfig.contactsProfileContactInformationViewAssigned"
    #contactsProfileContactInformationViewAssigned="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.contactsProfileCompanyInformationView"
    #contactsProfileCompanyInformationView="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.contactsInvestigations" #contactsInvestigations="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.contactsDelete" #contactsDelete="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.contactsCreateConfig" #contactsCreateConfig="appEnforcePermission"></div>
  <!-- Permissions -->
  <h4 class="page-title">Contacts</h4>
  <div class="content-wrapper">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
      <ng-container *ngIf="!config.hideLists">
        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label [text]="'Position'"></kendo-label>
            <kendo-dropdownlist #type [data]="positions" textField="name" valueField="id" [valuePrimitive]="true"
              fillMode="outline" [filterable]="false" [defaultItem]="{ id: null, name: 'Select a Position' }"
              (selectionChange)="onPositionChange($event)"
              [popupSettings]="{ appendTo: 'component', animate: false, width: 250 }">
              <ng-template kendoDropDownListGroupTemplate let-groupName>
                <strong>{{ groupName }}</strong>
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.id" class="placeholder">
                  {{ dataItem.name }}
                </span>
                <span *ngIf="dataItem?.id" class="not-placeholder">
                  {{ dataItem.name }}
                </span>
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <app-company-select-single-kendo [label]="'Company'"
            (selectionChanged)="onCompanyChange($event)"></app-company-select-single-kendo>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="First Name"></kendo-label>
            <kendo-textbox [(ngModel)]="firstName" (appAfterValueChanged)="onSearchFirstNameChange()"
              placeholder="Search for a First Name" fillMode="outline" [clearButton]="true"></kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Last Name"></kendo-label>
            <kendo-textbox [(ngModel)]="lastName" (appAfterValueChanged)="onSearchLastNameChange()"
              placeholder="Search for a Last Name" fillMode="outline" [clearButton]="true"></kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </ng-container>
      <kendo-gridlayout-item>
        <kendo-formfield class="left-search-icon">
          <kendo-label text="Search"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox [(ngModel)]="search" (appAfterValueChanged)="onSearchChange()" placeholder="Search"
              fillMode="outline" [clearButton]="true">
            </kendo-textbox>
          </div>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [colSpan]="3" class="filter-actions right-group align-items-end">
        <kendo-buttongroup selection="single" name="status" class="ml-3">
          <button kendoButton [toggleable]="true" [value]="null" [selected]="selectedStatus === null"
            (click)="onActivityChange(null)" title="All">All</button>
          <button kendoButton [toggleable]="true" [value]="'true'" [selected]="selectedStatus === 'true'"
            (click)="onActivityChange('true')" title="Active">Active</button>
          <button kendoButton [toggleable]="true" [value]="'false'" [selected]="selectedStatus === 'false'"
            (click)="onActivityChange('false')" title="Inactive">Inactive</button>
        </kendo-buttongroup>
        <kendo-button *ngIf="contactsCreateConfig?.enabled && !config.hideAddContact" kendoButton class="ml-3"
          [themeColor]="'primary'" fillMode="solid" iconClass="fas fa-plus" (click)="addContactModal()">
          New Contact</kendo-button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-grid class="permission-role-list" [data]="{
      data: contacts,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }" (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)">
      <kendo-grid-column field="LAST_NAME" title="Name" [width]="10" class="id-column" [sortable]="true"
        class="font-weight600" [headerClass]="'sort-header'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a [routerLink]="['/contacts', dataItem.id]" appEventLink class="text-decoration word-break"
            *ngIf="(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled) else contactNameView">
            {{dataItem?.lastName ? (dataItem?.lastName + ', ' + dataItem?.firstName) : dataItem?.firstName}}
          </a>
          <ng-template #contactNameView>
            <span
              [innerHTML]="dataItem?.lastName ? (dataItem?.lastName + ', ' + dataItem?.firstName) : dataItem?.firstName"></span>
          </ng-template>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="POSITION" title="Position" [width]="10" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [innerHTML]="dataItem?.Role?.name || '-'"></span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="PHONE" title="Phone" [width]="10" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem?.Phones?.length; else noValue">
            <app-email-phone-view *ngFor="let phone of dataItem?.Phones | primarySort"
              [phone]="phone"></app-email-phone-view>
          </ng-container>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="EMAIL" title="Email" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem?.Emails?.length; else noValue">
            <app-email-phone-view *ngFor="let email of dataItem?.Emails" [email]="email"></app-email-phone-view>
          </ng-container>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="EMAIL" title="Email" [width]="45" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridHeaderTemplate>
          <kendo-gridlayout class="custom-table-header" [rows]="['auto']" [gap]="{rows: 5, cols: 0}"
            [cols]="['repeat(6,minmax(0,1fr))']">
            <kendo-gridlayout-item class="custom-header-item" [colSpan]="2">
              Company
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="custom-header-item" [colSpan]="2">
              Branch
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="custom-header-item">
              Start Date
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="custom-header-item">
              End Date
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem?.BranchAssignments?.length;else noRowValue">

            <kendo-gridlayout *ngFor="let branch of dataItem?.BranchAssignments"
              [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(6,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-8" [colSpan]="2">
                {{ branch?.Branch?.Company?.name || '-' }}
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-8" [colSpan]="2">
                {{ branch?.Branch?.name || '-' }}
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-8">
                {{ branch.startDate ? (branch.startDate | customDate: 'MM/dd/yyyy') : "N/A"}}
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-8">
                {{ branch.endDate ? (branch.endDate | customDate: 'MM/dd/yyyy') : "N/A"}}
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>
          <ng-template #noRowValue>
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}"
              [cols]="['repeat(6,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-8" [colSpan]="2">
                -
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-8" [colSpan]="2">
                -
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-8">
                -
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-8">
                -
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-template>
        </ng-template>
      </kendo-grid-column>
      <!-- Action -->
      <kendo-grid-column field="action" title="Actions" [width]="5" [sortable]="false" [headerClass]="'nocursor'"
        *ngIf="contactsDelete?.enabled">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-dropdownbutton [data]="actionItems" themeColor="primary" fillMode="link"
            buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis" (itemClick)="removeContact(dataItem)"
            [popupSettings]="{
       appendTo: 'component',
       animate: false,
       width: 250,
       popupClass: 'grid-action-items-menu'
     }">
          </kendo-dropdownbutton>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</section>
<ng-template #noValue>
  <span>-</span>
</ng-template>
