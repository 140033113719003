import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { Observable } from "rxjs";
import { IApiCompaniesConnection, IApiCompanyFilter, IApiContactRole, IApiContactRoleFilter, IApiPhoneType, IApiPhoneTypeFilter, IApiRiskType } from 'src/app/shared/modules/graphql/types/types';
import { IGqlBaseOptions } from '../../interfaces';
import { GetPublicContactRole, phoneTypeQuery, riskTypeQuery, getUserByEmailQuery, companyConnectionQuery, getUserDetailsEmailQuery, createInvestigationQuery, getCurrentContactQuery, createUserAndResetPasswordQuery, resetPasswordQuery, getCurrentContactByIdQuery } from "./portal.operations";
import { safeResolve } from "../../rxjs";
import { connectionQuery } from '../../helpers.class';

@Injectable()
export class GqlPortalService {

  public constructor(
    private apollo: Apollo
  ) { }

  public getPublicRoles(filters: IApiContactRoleFilter[] = [], options: IGqlBaseOptions = {}): Observable<IApiContactRole> {
    return this.apollo.query(connectionQuery(GetPublicContactRole, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getRiskType(): Observable<IApiRiskType[]> {
    return this.apollo.query({ query: riskTypeQuery }).pipe(safeResolve(true));
  }

  public getPhoneTypes(filters: IApiPhoneTypeFilter[], options: IGqlBaseOptions): Observable<IApiPhoneType[]> {
    return this.apollo.query(connectionQuery(phoneTypeQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public checkClientExist(email: string, details: boolean = false): Observable<any> {
    return this.apollo.query({
      query: details ? getUserDetailsEmailQuery : getUserByEmailQuery,
      variables: { email }
    }).pipe(safeResolve(true));
  }

  public createInvestigation(data): Observable<any> {
    return this.apollo.mutate({
      mutation: createInvestigationQuery,
      variables: data
    }).pipe(
      safeResolve(true)
    );
  }



  public getCompanies(filters: IApiCompanyFilter[], options: IGqlBaseOptions): Observable<IApiCompaniesConnection> {
    return this.apollo.query(connectionQuery(companyConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getCurrentContact(email: string): Observable<any> {
    return this.apollo.query({ query : getCurrentContactQuery, variables: { email } });
  }


  public getCurrentContactById(id: string): Observable<any> {
    return this.apollo.query({ query : getCurrentContactByIdQuery, variables: { id } });
  }

  public createUserAndResetPassword(email: string): Observable<any> {
    return this.apollo.query({ query : createUserAndResetPasswordQuery, variables: { email } });
  }

  public resetPassword(email: string): Observable<any> {
    return this.apollo.query({ query : resetPasswordQuery, variables: { email } });
  }


}
