import { GqlExpenseService } from '../../modules/graphql/services/expenses/expense.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces/base.interfaces';
import { ListPage } from "../../helpers/list-page.class";
import { IApiAddExpenseInput, IApiExpense, IApiExpenseConnection, IApiExpenseFilter, IApiExpenseMileageTotal, IApiExpenseReportTotal, IApiGenerateReportXmlInput, IApiMarkAllExpensesAsPaid, IApiMarkSelectedExpensesAsPaid, IApiUpdateExpenseInput } from "../../modules/graphql/types/types";
import { IApiExpenseSubmitReport } from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class ExpenseService {

  constructor(
    private gqlExpenseService: GqlExpenseService
  ) { }

  public get(filters?: IApiExpenseFilter[], options?: IGqlBaseOptions): Observable<IApiExpenseConnection> {
    return this.gqlExpenseService.getExpenses(filters, options || new ListPage());
  }

  public add(input: IApiAddExpenseInput): Observable<IApiExpense> {
    return this.gqlExpenseService.addExpense(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlExpenseService.removeExpense(id);
  }

  public update(input: IApiUpdateExpenseInput): Observable<IApiExpense> {
    return this.gqlExpenseService.updateExpense(input);
  }

  public getExpenseMileageTotal(filters?: IApiExpenseFilter[], options?: IGqlBaseOptions): Observable<IApiExpenseMileageTotal> {
    return this.gqlExpenseService.getExpenseMileageTotal(filters, options || new ListPage());
  }

  public getBookkeepingExpenseReportTotal(filters?: IApiExpenseFilter[], options?: IGqlBaseOptions): Observable<IApiExpenseReportTotal> {
    return this.gqlExpenseService.getBookkeepingExpenseReportTotal(filters, options || new ListPage());
  }

  public getExpenseReportTotal(filters?: IApiExpenseFilter[], options?: IGqlBaseOptions): Observable<IApiExpenseReportTotal> {
    return this.gqlExpenseService.getExpenseReportTotal(filters, options || new ListPage());
  }

  public getExpenseReportTotalAll(filters?: IApiExpenseFilter[], options?: IGqlBaseOptions): Observable<string> {
    return this.gqlExpenseService.getExpenseReportTotalAll(filters, options || new ListPage());
  }

  public expenseSubmitReport(input?: IApiExpenseSubmitReport): Observable<IApiExpenseSubmitReport> {
    return this.gqlExpenseService.expenseSubmitReport(input);
  }

  public markSelectedPaid(input?: IApiMarkSelectedExpensesAsPaid): Observable<boolean>{
    return this.gqlExpenseService.markSelectedPaid(input);
  }

  public markAllSelectedAsPaid(input?: IApiMarkAllExpensesAsPaid): Observable<boolean>{
    return this.gqlExpenseService.markAllSelectedAsPaid(input);
  }

  public generateReportXML(input: IApiGenerateReportXmlInput): Observable<string>{
    return this.gqlExpenseService.generateReportXML(input);
  }
}
