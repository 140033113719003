import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  createdAt
  updatedAt
  Category {
    id
    name
  }
`;

export const billingRuleTypesConnectionQuery = gql`${buildConnectionQuery("BillingRuleType", "billingRuleTypeConnection", nodeFragment)}`;

export const addBillingRuleType = gql`mutation addBillingRule($input: AddBillingRuleInput!) {
  addBillingRule(input: $input) { ${nodeFragment} }
  }`;

export const removeBillingRuleTypeMutation = gql`mutation removeBillingRule($input: RemoveBillingRuleInput!) {
  removeBillingRule(input: $input) { ${nodeFragment} }
}`;

export const updateBillingRuleTypeMutation = gql`mutation updateBillingRule($input: UpdateBillingRuleInput!) {
  updateBillingRule(input: $input) { ${nodeFragment} }
}`;

export const getBillingRuleTypeByIdQuery = gql`query getBillingRuleById($id: ID!) {
  getBillingRuleById(id: $id) {${nodeFragment}}
}`;
