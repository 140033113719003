<section class="intake-page content-part">
  <div class="intake-container">
    <kendo-card class="intake-card" width="100%">
      <h4 class="page-lable">Submit a New Assignment</h4>
      <p class="mb-24">
        Use this form to create a new assignment for NEFCO and it’s investigators. Once your assignment has been reviewed, a member of our team will be in touch to discuss the next steps.
      </p>
      <form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
        <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="asterisk" [for]="email" text="Your Email Address"></kendo-label>
              <kendo-textbox (valueChange)="showAlert = false" #email formControlName="email" fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
              <span class="email-hint">Your email address must use a company email address that is for
                your sole use.</span>
              <kendo-formerror>
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="form?.controls?.email?.errors?.required">Email cannot be left blank</span>
                <span *ngIf="form?.controls?.email?.errors?.email">Invalid email address</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <div class="kendo-alert-wrapper">
            <app-kendo-alert [showIcon]="false" *ngIf="showAlert" [message]="
          'We see that you may have had an account with our previous system. Please click “Next” to reset your password in our new system.'
        "></app-kendo-alert>
          </div>
          <div class="">
            <button type="submit" kendoButton class="btn-primary login-portal" themeColor="primary" fillMode="solid">
              Next
            </button>
          </div>
        </kendo-gridlayout>
      </form>
    </kendo-card>
  </div>
</section>
