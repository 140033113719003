<!-- Permissions -->
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageCreate" #expenseUnlinkedMileageCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageCreate" #expenseInvestigationMileageCreate="appEnforcePermission"></div>
<!-- Permissions -->
<section class="template-form">
  <form #form="ngForm">
    <!-- {{mileage | json}} -->
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
      [cols]="!investigationView ? ['repeat(2,minmax(0,1fr))']: ['repeat(1,minmax(0,1fr))']">
      <kendo-gridlayout-item *ngIf="!investigationView">
        <kendo-formfield class="kendo-formfield">
          <kendo-label [ngClass]="unlinked || editQtyOnly ? '' : 'asterisk'" [for]="invlist"
            text="Investigation ID"></kendo-label>
          <kendo-dropdownlist #invlist [data]="investigations" textField="nefcoNumber" valueField="id"
            fillMode="outline" [valuePrimitive]="true" [defaultItem]="{id: null, nefcoNumber: 'Select Investigation'}"
            name="investigationId"
            [popupSettings]="{appendTo: 'component', animate: false, popupClass: 'nodata', width: 250 }"
            [(ngModel)]="mileage.InvestigationId" #investigationId="ngModel"
            [disabled]="expenseInvestigationMileageCreate?.enabled ? (unlinked || editQtyOnly) : true"
            required>
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.nefcoNumber || dataItem.id}} </span>
              <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.nefcoNumber || dataItem.id}} </span>
            </ng-template>
            <ng-template kendoDropDownListNoDataTemplate>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror *ngIf="!investigationId?.valid && (investigationId?.dirty || investigationId?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="investigationId?.errors?.required">
              Investigation id cannot be left blank
            </span>
          </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield class="kendo-formfield">
          <div style="margin-top: 6px;">
            <input type="checkbox" kendoCheckBox #unlinkedCheck [value]="false" [(ngModel)]="unlinked"
              [disabled]="(!expenseUnlinkedMileageCreate?.enabled || !expenseInvestigationMileageCreate?.enabled) ? true : false"
              [ngModelOptions]="{standalone: true}" />
            <kendo-label class="k-checkbox-filter-label ml-12 mr-24" [for]="unlinkedCheck"
              text="Unlinked"></kendo-label>
          </div>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item *ngIf="investigationView && modalView">
        <kendo-formfield class="kendo-formfield">
          <kendo-label [for]="investigationId" text="Investigation ID"></kendo-label>
          <kendo-textbox name="investigationId" fillMode="outline" [disabled]="true" [(ngModel)]="nefcoNumber"
            #investigationId="ngModel">
          </kendo-textbox>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label class="asterisk" text="Date"></kendo-label>
          <kendo-datepicker id="expenseDate" name="expenseDate" calendarType="classic" [fillMode]="'outline'"
            placeholder="month/day/year" format="MM/dd/yyyy" [(ngModel)]="mileage.expenseDate"
            formatPlaceholder="formatPattern" #expenseDate="ngModel" [disabled]="editQtyOnly" [required]="true"
            [disabledDates]="disabledDates"></kendo-datepicker>
          <kendo-formerror *ngIf="!expenseDate?.valid && (expenseDate?.dirty || expenseDate?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="expenseDate?.errors?.required || expenseDate?.errors?.minError || expenseDate?.errors?.maxError">
              Date cannot be left blank
            </span>
            <span *ngIf="expenseDate?.errors?.disabledDate">
              Date cannot be future
            </span>
          </kendo-formerror>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <kendo-formfield class="kendo-formfield">
          <kendo-label class="asterisk" [for]="purposeFor" text="Purpose"></kendo-label>
          <kendo-dropdownlist #purposeFor [data]="expensePurposes" textField="name" valueField="id" fillMode="outline"
            [valuePrimitive]="true" [defaultItem]="{id: null, name: 'Select purpose'}" name="purpose"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
            [(ngModel)]="mileage.ExpensePurposeId" #purpose="ngModel" required>
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.name}} </span>
              <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.name}} </span>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror *ngIf="!purpose?.valid && (purpose?.dirty || purpose?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="purpose?.errors?.required">
              Purpose cannot be left blank
            </span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
      [cols]="[(unlinked === false || investigationView) ? 'repeat(3,minmax(0,1fr))' : 'repeat(2,minmax(0,1fr))']">
      <kendo-gridlayout-item >
        <kendo-formfield class="kendo-formfield">
          <kendo-label [ngClass]="'asterisk'" text="Type"></kendo-label>
          <kendo-dropdownlist
            [data]="typeArray"
            textField="name" valueField="value" fillMode="outline" name="type" [valuePrimitive]="true"
            [defaultItem]="{value: null, name: 'Type'}"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" [(ngModel)]="mileage.nefcoVehicle"
            #type="ngModel" required>
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="dataItem?.value === null" class="placeholder"> {{ dataItem?.name}} </span>
              <span *ngIf="dataItem?.value !== null" class="not-placeholder"> {{ dataItem?.name}} </span>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror *ngIf="!type?.valid && (type?.dirty || type?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="type?.errors?.required">
              Type cannot be left blank
            </span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item *ngIf="unlinked === false || investigationView">
        <kendo-formfield class="kendo-formfield">
          <kendo-label [text]="'Billable Mile Qty'"></kendo-label>
          <div class="numeric-text-suffix">
            <kendo-numerictextbox placeholder="Enter miles" name="billableQuantity" fillMode="outline"
              [autoCorrect]="false" [(ngModel)]="mileage.billableQuantity" #billableQuantity="ngModel">
            </kendo-numerictextbox>
          </div>
          <kendo-formerror *ngIf="!billableQuantity?.valid && (billableQuantity?.dirty || billableQuantity?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="billableQuantity?.errors?.required">
              Billable mile qty cannot be left blank
            </span>
            <span *ngIf="billableQuantity?.errors?.minError">
              Billable mile qty cannot be left blank
            </span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <kendo-formfield class="kendo-formfield">
          <kendo-label [text]="'Non-Billable Mile Qty'"></kendo-label>
          <div class="numeric-text-suffix">
            <kendo-numerictextbox placeholder="Enter miles" name="nonBillableQuantity" fillMode="outline"
              [autoCorrect]="false" [(ngModel)]="mileage.nonBillableQuantity" #nonBillableQuantity="ngModel">
            </kendo-numerictextbox>
          </div>
          <kendo-formerror
            *ngIf="!nonBillableQuantity?.valid && (nonBillableQuantity?.dirty || nonBillableQuantity?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="nonBillableQuantity?.errors?.required">
              Non billable mile qty cannot be left blank
            </span>
            <span *ngIf="nonBillableQuantity?.errors?.minError">
              Non billable mile qty cannot be left blank
            </span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label class="asterisk" text="Description"></kendo-label>
          <kendo-textarea placeholder="Enter description" fillMode="outline" name="description" class="min-height-96"
            resizable="both" #description="ngModel" [(ngModel)]="mileage.description" [required]="true"
            [disabled]="editQtyOnly" appNoWhiteSpace></kendo-textarea>
          <kendo-formerror *ngIf="!description?.valid && (description?.dirty || description?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="description?.errors?.required">Description cannot be left blank</span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <hr *ngIf="mileage?.id && investigationView">

    <kendo-gridlayout-item *ngIf="mileage?.id && investigationView">
      <kendo-formfield class="kendo-formfield">
        <div style="margin-bottom: 6px;">
          <input type="checkbox" kendoCheckBox #paidCheck [value]="false" [(ngModel)]="paidCheckbox"
            [ngModelOptions]="{standalone: true}" (ngModelChange)="changePaid($event)"/>
          <kendo-label class="k-checkbox-filter-label ml-12 mr-24" [for]="paidCheck" text="Paid"></kendo-label>
        </div>
      </kendo-formfield>
      <div class="kendo-formfield" *ngIf="paidCheckbox">
        <kendo-label class="asterisk" text="Paid Date"></kendo-label>
        <kendo-datepicker id="paidDate" name="paidDate" calendarType="classic" [fillMode]="'outline'"
          placeholder="month/day/year" format="MM/dd/yyyy" [(ngModel)]="mileage.paidDate"
          formatPlaceholder="formatPattern" #paidDate="ngModel" [required]="true"></kendo-datepicker>
        <kendo-formerror *ngIf="!paidDate?.valid && (paidDate?.dirty || paidDate?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="paidDate?.errors?.required || paidDate?.errors?.minError || paidDate?.errors?.maxError">
            Paid Date cannot be left blank
          </span>
          <span *ngIf="paidDate?.errors?.disabledDate">
            Paid Date cannot be future
          </span>
        </kendo-formerror>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
      *ngIf="!modalView">
      <kendo-gridlayout-item>
        <!-- Keep empty for box size -->
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <!-- Keep empty for box size -->
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </form>
</section>
<ng-container *ngIf="!modalView">
  <kendo-card-footer class="footer-kendo">
    <button kendoButton themeColor="primary" fillMode="solid" (click)="submit();">Add</button>
  </kendo-card-footer>
</ng-container>
