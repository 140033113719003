import { tap, filter, switchMap } from 'rxjs/operators';
import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IFindInvestigatorModalData, InvestigationFindInvestigatorModalKendoComponent } from '../investigation-find-investigator-modal-kendo/investigation-find-investigator-modal-kendo.component';
import { MatDialog } from '@angular/material/dialog';
import { InvestigationRoleService, InvestigationService, InvestigationStaffService } from 'src/app/shared/services';
import { IRoleSelectConfig } from 'src/app/shared/components/role-select/role-select.component';
import {
  IApiAddress, IApiInvestigationStatus, IApiInvestigationRole,
  IApiInvestigationStaff, IApiInvestigationStaffFilterType, IApiInvestigationRoleNames, IApiInvestigation, IApiUpdateInvestigationInput, IApiUser, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUserFilterType,
} from 'src/app/shared/modules/graphql/types/types';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  investigationAssignStaffCreate
} from "src/app/shared/helpers/auth-config/investigations.config";
@Component({
  selector: 'app-investigation-assignments-container',
  templateUrl: './investigation-assignments-container.component.html',
  styleUrls: ['./investigation-assignments-container.component.scss']
})
export class InvestigationAssignmentsContainerComponent {
  public authConfig = {
    investigationAssignStaffCreate
  };
  private _staff: IApiInvestigationStaff[] = [];
  @Input() public set staff(val: IApiInvestigationStaff[]) {
    this._staff = val;
  }
  public get staff(): IApiInvestigationStaff[] {
    return this._staff;
  }

  @Input() address: IApiAddress;
  @Input() investigation: IApiInvestigation;
  @Input() clientId: string;
  @Output() investigationStatusChange = new EventEmitter<IApiInvestigationStatus>();
  @Output() updated = new EventEmitter<void>();

  public get assignedInvestigators(): IApiInvestigationStaff[] {
    return this._staff.filter(({ Role: { title } }) => title === IApiInvestigationRoleNames.Investigator);
  }

  public get assignedTechReviewers(): IApiInvestigationStaff[] {
    return this._staff.filter(({ Role: { title } }) => title === IApiInvestigationRoleNames.TechReviewer);
  }

  public get assignedOther(): IApiInvestigationStaff[] {
    return this._staff.filter(({ Role: { title } }) => title !== IApiInvestigationRoleNames.TechReviewer && title !== IApiInvestigationRoleNames.Investigator);
  }

  public get roleNames() {
    return IApiInvestigationRoleNames;
  }

  // table configs
  public investigatorColumns = ["name", "primary", "requested", "actions"];
  public techReviewerColumns = ["name", "primary", "actions"];
  public otherStaffColumns = ["name", "role", "actions"];

  public roleSelectConfig: IRoleSelectConfig = {
    placeholderText: 'Investigator Position',
    labelText: '',
    excludedRoleNames: [IApiInvestigationRoleNames.TechReviewer, IApiInvestigationRoleNames.Investigator]
  };

  public roles: IApiInvestigationRole[];
  public userViewFilter = IApiUserFilterType.ViewStaffUser;

  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private notifications: NotificationsService,
    private investigationService: InvestigationService,
    private _investigationStaffService: InvestigationStaffService,
    private _investigationRoleService: InvestigationRoleService,
  ) {
    this._investigationRoleService.investigationRoles.subscribe(roles => this.roles = roles);
  }

  public findStaff(title: string) {
    const data: IFindInvestigatorModalData = {
      lossDate: this.investigation.lossDate,
      address: this.address,
      investigationId: this.investigation.id,
      role: this.roles.find(({ title: _title }) => _title === title),
      clientId: this.clientId,
      isFirstAssignment: !this.investigation.InvestigationStaff?.length,
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationFindInvestigatorModalKendoComponent,
      width: "88%",
      height: "80%",
    });
    const dialogInstance = dialog.content
      .instance as InvestigationFindInvestigatorModalKendoComponent;
    dialogInstance.data = data;
    dialog.result.pipe(filter((result) => !!result)).subscribe((res) => {
      if (res === true) {
        this.getStaff();
        this.updated.emit();
      }
    });
  }

  // public findStaff(title: string) {
  //   const data: IFindInvestigatorModalData = {
  //     address: this.address,
  //     investigationId: this.investigation.id,
  //     role: this.roles.find(({ title: _title }) => _title === title),
  //     clientId: this.clientId,
  //     isFirstAssignment: !this.investigation.InvestigationStaff?.length,
  //   };

  //   this.dialog.open(InvestigationFindInvestigatorModalComponent, {
  //     width: '88%',
  //     height: '80%',
  //     data
  //   }).afterClosed().pipe(
  //     filter(result => !!result)
  //   ).subscribe(() => {
  //     this.getStaff();
  //     this.updated.emit();
  //   });
  // }

  public getStaff() {
    return this._investigationStaffService.get([{ type: IApiInvestigationStaffFilterType.Investigation, value: JSON.stringify([this.investigation.id]) }]).pipe(
      unwrapConnection(),
    ).subscribe((r) => this._staff = r);
  }

  public addStaff(user: IApiUser, roleName: string = IApiInvestigationRoleNames.TechReviewer): boolean {
    const { id: UserId, TechReviewerId  } = user;
    if (!UserId) return false;

    const { id: RoleId } = this.roles.find(({ title }) => title === roleName);
    this._investigationStaffService.add({
      UserId,
      InvestigationId: this.investigation.id,
      RoleId,
      TechReviewerId: TechReviewerId,
      isPrimary: this.staff.find(i => i.Role.title === IApiInvestigationRoleNames.TechReviewer) ? false : true
    }).pipe(
      switchMap((result) => {
        return this.investigationService.update({
          id: this.investigation.id,
          History: {
            InvestigationId: this.investigation.id,
            updateCategory: IApiInvestigationUpdateCategories.Staff,
            updateType: IApiInvestigationUpdateTypes.Create,
            comment: `Staff member ${result.User.firstName} ${result.User.lastName} added to investigation with role ${IApiInvestigationRoleNames.TechReviewer}`
          }
        });
      }),
      this.notifications.alertPipe("Staff successfully assigned."),
      this.notifications.catchAlertPipe("Error assigning staff."),
      tap(() => this.updated.emit())
    ).subscribe(() => this.getStaff());

    return true;
  }

}
