import { IEnforcePermissionConfig } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/enums/permissions.enums";

export const photosAllAssigned: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PHOTOS,
  appliedPermissions: {
    All: [PermissionActions.VIEW, PermissionActions.LIST, PermissionActions.UPDATE, PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
};