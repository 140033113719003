import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { addCertificationTypeMutation, certificationTypeConnectionQuery, removeCertificationTypeMutation, updateCertificationTypeMutation } from "./certification-type.operations";
import { IApiAddCertificationTypeInput, IApiCertificationType, IApiCertificationTypeConnection, IApiCertificationTypeFilter, IApiUpdateCertificationTypeInput } from "../../../types/types";

@Injectable()
export class GqlCertificationTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getCertificationTypes(filters: IApiCertificationTypeFilter[], options: IGqlBaseOptions): Observable<IApiCertificationTypeConnection> {
    return this.apollo.query(connectionQuery(certificationTypeConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addCertificationType(input: IApiAddCertificationTypeInput): Observable<IApiCertificationType> {
    return this.apollo.mutate({
      mutation: addCertificationTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public updateCertificationType(input: IApiUpdateCertificationTypeInput): Observable<IApiCertificationType> {
    return this.apollo.mutate({
      mutation: updateCertificationTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeCertificationType(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeCertificationTypeMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
