import gql from 'graphql-tag';
import { connectionInputDefs } from '../../helpers.class';
import { buildConnectionQuery } from "../../helpers.class";
import { IApiExpenseFilterType } from '../../types/types';

const nodeFragment = `
  id
  expenseDate
  billableQuantity
  nonBillableQuantity
  nefcoVehicle
  outOfPocket
  authorizedBy
  authorizedDate
  description
  status
  ExpenseItem {
    id
    name
    value
    reimbursementRate
    isQuantity
    isFlatRate
    qbAccount
    ExpenseReimbursementRate {
      reimbursementRate
      year
    }
  }
  ExpensePurpose {
    id
    name
  }
  Investigation {
    id
    nefcoNumber
    Evidence {
      id
      evidenceNumber
      description
      takenFrom
      createdAt
      Comments {
        id
        text
        CreatedBy {
          id
          firstName
          lastName
        }
      }
      SubService {
        id
      }
      EvidenceStorageLocation {
        id
        name
        Phone {
          id
          number
        }
        Address {
          id
          Type {
            id
            name
          }
          address1
          city
          state
          postal
        }
      }
      Transfers {
        id
        trackingNumber
        comment
        createdAt
        FromLocation {
          id
          name
        }
        ToLocation {
          id
          name
        }
        ShippingCarrier {
          id
          name
        }
        ShippingDocument {
          id
        }
      }
      Photos {
        id
      }
      Status
      StatusHistory {
        id
        name
        createdAt
      }
    }
    EvidenceBilling {
      id
      palletCount
      override
      billable
      rate
      startDate
      lastExpensed
      currentExpensed
      notes
      BillingFrequency {
        id
        name
      }
    }
    Client {
      id
      firstName
      middleName
      lastName
      suffix
      BillingAddress {
        id
        isPrimary
        Type {
          id
          name
        }
        address1
        address2
        address3
        city
        state
        postal
      }
      Branches {
        id
        name
        isPrimary
        Company {
          id
          name
          billingInstructions
          MainOffice {
            id
            name
            additionalInformation
            Addresses {
              id
              TypeId
              Type {
                id
                name
              }
              isPrimary
              address1
              address2
              address3
              city
              state
              postal
              country
            }
          }
        }
      }
      Addresses {
        id
        isPrimary
        address1
        address2
        address3
        city
        state
        postal
        country
      }
    }
    ClientBranch {
      id
      name
      BillingAddress {
        id
        isPrimary
        Type {
          id
          name
        }
        address1
        address2
        address3
        city
        state
        postal
      }
      Addresses {
        id
        isPrimary
        Type {
          id
          name
        }
        address1
        address2
        address3
        city
        state
        postal
      }
      Company {
        id
        name
        billingInstructions
        qbListId
      }
    }
    BillTo {
      id
      firstName
      middleName
      lastName
      suffix
      BillingAddress {
        id
        isPrimary
        Type {
          id
          name
        }
        address1
        address2
        address3
        city
        state
        postal
      }
      Branches {
        id
        name
        isPrimary
        Company {
          id
          name
          billingInstructions
        }
      }
      Addresses {
        id
        isPrimary
        address1
        address2
        address3
        city
        state
        postal
        country
      }
    }
    BillToBranch {
      id
      name
      BillingAddress {
        id
        isPrimary
        Type {
          id
          name
        }
        address1
        address2
        address3
        city
        state
        postal
      }
      Addresses {
        id
        isPrimary
        Type {
          id
          name
        }
        address1
        address2
        address3
        city
        state
        postal
      }
      Company {
        id
        name
        billingInstructions
        qbListId
      }
    }
  }
  User {
    id
    firstName
    lastName
  }
  Document {
    id
    title
    uri
    s3Uri
  }
  InvoiceItems {
    id
    invoiceableId
  }
  paidDate
  createdAt
  updatedAt
`;

const expenseMileageTotalQuery = `
billableExpense
nonBillableExpense
totalExpense
billableMileage
nonBillableMileage
totalMileage
`;

const expenseReportTotalQuery = `
billableExpense
nonBillableExpense
totalExpense
`;

const bookkeepingReportExpenses = `
  id
  expenseDate
  billableQuantity
  nonBillableQuantity
  nefcoVehicle
  outOfPocket
  authorizedBy
  authorizedDate
  description
  status
  paidDate
  createdAt
  updatedAt
  ExpenseItem {
    id
    name
    value
    reimbursementRate
    isQuantity
    ExpenseReimbursementRate {
      reimbursementRate
      year
    }
  }
  ExpensePurpose {
    id
    name
  }
  User {
    id
    firstName
    lastName
  }
  Document {
    id
    title
    uri
    s3Uri
  }
  InvoiceItems {
    id
    invoiceableId
  }
  Investigation {
    id
    nefcoNumber
  }
`;

export const ExpenseConnectionQuery = (view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiExpenseFilterType.ViewBookkeepingView:
        return gql`${buildConnectionQuery("Expense", "bookkeepingExpenseConnection", nodeFragment)}`;
      case IApiExpenseFilterType.ViewBookkeepingReport:
        return gql`${buildConnectionQuery("Expense", "expenseConnection", bookkeepingReportExpenses)}`;
      default:
        return gql`${buildConnectionQuery("Expense", "expenseConnection", nodeFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("Expense", "expenseConnection", nodeFragment)}`;
  }
};

export const getExpenseMileageTotal = gql`query expenseMileageTotal(
  $filters: [ExpenseFilter],
  $orderBy: ExpenseOrderBy,
  ${connectionInputDefs}
  ) {
    expenseMileageTotal(
    first: $first
    last: $last
    before: $before
    after: $after
    filters: $filters
    orderBy: $orderBy
    sortOrder: $sortOrder
  ) {
     ${expenseMileageTotalQuery}
  }
  }`;

export const expenseReportTotal = gql`query expenseReportTotal(
  $filters: [ExpenseFilter],
  $orderBy: ExpenseOrderBy,
  ${connectionInputDefs}
  ) {
    expenseReportTotal(
    first: $first
    last: $last
    before: $before
    after: $after
    filters: $filters
    orderBy: $orderBy
    sortOrder: $sortOrder
  ) {
     ${expenseReportTotalQuery}
  }
}`;

export const bookkeepingExpenseReportTotal = gql`query bookkeepingExpenseReportTotal(
  $filters: [ExpenseFilter],
  $orderBy: ExpenseOrderBy,
  ${connectionInputDefs}
  ) {
    expenseReportTotal(
    first: $first
    last: $last
    before: $before
    after: $after
    filters: $filters
    orderBy: $orderBy
    sortOrder: $sortOrder
  ) {
     ${expenseReportTotalQuery}
  }
}`;

export const getExpenseReportTotalAll = gql`query GetExpenseReportTotal(
  $filters: [UserFilter],
  ${connectionInputDefs}
  ) {
    getExpenseReportTotal(
      first: $first
      last: $last
      before: $before
      after: $after
      filters: $filters
      sortOrder: $sortOrder
  )
}`;

export const getExpenseByIdQuery = gql`query getExpenseById($id: String!) {
  getExpenseById(id: $id) { ${nodeFragment} }
}`;

export const addExpenseMutation = gql`mutation AddExpense($input: AddExpenseInput!) {
  addExpense(input: $input) { ${nodeFragment} }
}`;

export const expenseSubmitReportMutation = gql`mutation ExpenseSubmitReport($input: ExpenseSubmitReport!) {
  expenseSubmitReport(input: $input)
}`;

export const updateExpenseMutation = gql`mutation UpdateExpense($input: UpdateExpenseInput!) {
  updateExpense(input: $input) { ${nodeFragment} }
}`;

export const removeExpenseMutation = gql`mutation RemoveExpense($input: ID!) {
  removeExpense(input: $input)
}`;

export const markSelectedAsPaidMutation = gql`mutation MarkSelectedAsPaid($input: MarkSelectedExpensesAsPaid!) {
  markSelectedAsPaid(input: $input)
}`;

export const markAllSelectedAsPaidMutation = gql`mutation MarkAllAsPaid($input: MarkAllExpensesAsPaid!) {
  markAllAsPaid(input: $input)
}`;

export const generateReportXMLQuery = gql`query GenerateReportXML($input: generateReportXMLInput!) {
  generateReportXML(input: $input)
}`;
