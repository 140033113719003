<div [dialogRef]="dialog" (appClickOutside)="dialog.close(false)">
  <kendo-dialog-titlebar (close)="dialog.close(false)">
    <div class="modal-title">
      <span>Evidence #: {{ evidence.evidenceNumber | evidenceNumber }}</span>
    </div>
  </kendo-dialog-titlebar>
  <section class="p-0">
    <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(3,minmax(0,1fr))']">
      <kendo-gridlayout-item colSpan="3">
        <p class="kendo-caption"><small>Storage Location</small></p>
        <p class="kendo-caption-body">{{evidence?.EvidenceStorageLocation?.name || '-'}}</p>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <p class="kendo-caption"><small>Company Name/Address</small></p>
        <p class="kendo-caption-body">{{evidence?.EvidenceStorageLocation?.companyName || '-'}}</p>
        <app-address-view [address]="evidence.EvidenceStorageLocation.Address"></app-address-view>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <p class="kendo-caption"><small>Contact/Phone</small></p>
        <p class="kendo-caption-body">
          <app-email-phone-view [phone]="evidence.EvidenceStorageLocation.Phone"></app-email-phone-view>
        </p>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <p class="kendo-caption"><small>Additional Details</small></p>
        <p class="kendo-caption-body">{{evidence?.EvidenceStorageLocation?.additionalDetails || '-'}}</p>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <p class="kendo-caption"><small>Evidence Shipping Receipt(s):</small></p>
        <p class="kendo-caption-body">
          <ng-container *ngFor="let transfer of evidence?.Transfers">
            <a class="linkmd" *ngIf="transfer?.ShippingDocument?.id" href="{{transfer?.ShippingDocument?.s3Uri | s3}}"
              target="_blank">{{transfer?.createdAt | customDate: 'MM-dd-yyyy'}} - From {{transfer?.FromLocation?.name}} to From
              {{transfer?.ToLocation?.name}}</a>
          </ng-container>
        </p>
        <p class="kendo-caption-body" [innerHTML]="evidence?.EvidenceStorageLocation?.companyName || '-'"></p>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-card width="100%" class="mb-24">
      <kendo-card-body class="p-0">
        <kendo-panelbar [expandMode]="2">
          <kendo-panelbar-item title="Item History" [expanded]="true">
            <ng-template kendoPanelBarContent>
              <kendo-grid class="evidence-item-history-list" [data]="evidence.StatusHistory" [sortable]="false" [navigatable]="false">
                <kendo-grid-column title="Date/Time" [width]="15" [sortable]="false" [headerClass]="'nocursor'"
                class="id-column font-weight600">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span [innerHTML]="dataItem.createdAt | customDate: 'MM-dd-yyyy, h:mm a'"></span>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="Type" [width]="8" [sortable]="false" [headerClass]="'nocursor'">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span [innerHTML]="(dataItem?.name || '-') | titlecase"></span>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="Comment" [width]="30" [sortable]="false" [headerClass]="'nocursor'">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span [innerHTML]="dataItem?.comment || '-'"></span>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="User" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span [innerHTML]="(dataItem?.CreatedBy) | fullName"></span>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </kendo-card-body>
    </kendo-card>
    <kendo-card width="100%" class="mb-24">
      <kendo-card-body class="p-0">
        <kendo-panelbar [expandMode]="2">
          <kendo-panelbar-item title="Comment Log" [expanded]="true">
            <ng-template kendoPanelBarContent>
              <app-comment-log-table [comments]="evidence.Comments"></app-comment-log-table>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </kendo-card-body>
    </kendo-card>

    <form #commentForm="ngForm">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']" class="mb-16 p-12">
          <kendo-gridlayout-item>
            <kendo-formfield>
                <kendo-label class="asterisk" text="Comment"></kendo-label>
                <kendo-textarea name="commentName" #commentName="ngModel" [(ngModel)]="comment" 
                placeholder="Enter Comment" fillMode="outline"
                class="min-height-96" resizable="both" [required]="true" appNoWhiteSpace></kendo-textarea>
                <kendo-formerror *ngIf="commentName?.errors">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="commentName?.errors.required">Comment cannot be left blank</span>
                </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="text-right">
            <button kendoButton [size]="'medium'" themeColor="light"  (click)="dialogRef.close(false)">
              Cancel
            </button>
            <button kendoButton [size]="'medium'" themeColor="primary" class="ml-16" (click)="addComment()">
                Add Comment
            </button>
          </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </section>
</div>