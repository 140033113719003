import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import moment from "moment";
@Component({
  selector: 'app-bookkeeping-expense-report-filter',
  templateUrl: './bookkeeping-expense-report-filter.component.html',
  styleUrls: ['./bookkeeping-expense-report-filter.component.scss']
})
export class BookkeepingExpenseReportFilterComponent {

  @Input() isUsers: boolean = false;
  @Input() isMarkSelectedAsPaid: boolean = false;
  @Input() public selectedTab: number;

  @Output() dateChange = new EventEmitter<string>();
  @Output() dateRangeTypeChange = new EventEmitter<string>();
  @Output() userChange = new EventEmitter<string[]>();
  @Output() markSelectedPaid = new EventEmitter<boolean>();
  @Output() markAllAsPaid = new EventEmitter<boolean>();
  @Output() downloadXlsx = new EventEmitter<boolean>();

  private _selectedUsers: string[] = [];
  public columnDisplay = [`repeat(${2},minmax(0,1fr))`];
  private _selectedMonth: string;
  public get selectedMonth() {
    return this._selectedMonth;
  }

  public set selectedMonth(val: string) {
    this._selectedMonth = val;
    this.dateChange.emit(val);
  }

  public get selectedUsers() {
    return this._selectedUsers;
  }

  public set selectedUsers(val) {
    this._selectedUsers = val;
    this.userChange.emit(val);
  }
  public userViewFilter = IApiUserFilterType.ViewStaffUser;

  public filterOptions = [{ text: "Entry Date", slug: "ENTRY_DATE" }, { text: "Expense Date", slug: "EXPENSE_DATE" }];

  public _currentFilter = 'ENTRY_DATE';

  public set currentFilter(val: string) {
    this._currentFilter = val;
  }

  public get currentFilter() {
    return this._currentFilter;
  }

  constructor() { }

  setSelectedUser(users) {
    this.selectedUsers = users || [];
  }

  public dateRangeChange(value) {
    this.selectedMonth = value;
  }

  public markSelectedPaidAction() {
    this.markSelectedPaid.emit(true);
  }

  public markAllAsPaidAction() {
    this.markAllAsPaid.emit(true);
  }

  public downloadXlsxAction() {
    this.downloadXlsx.emit(true);
  }

  public filterChange(event) {
    this.dateRangeTypeChange.emit(event?.slug);
  }
}
