import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appEventLink]'
})
export class EventLinkDirective {
  @HostBinding('attr.href') role: SafeUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) {
    this.role = this.sanitizer.bypassSecurityTrustUrl("javascript:void(0)");
  }
}