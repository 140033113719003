import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { safeResolve } from '../../../rxjs';

import {
  IApiAddInvestigationMarkCloseInput
} from '../../../types/types';
import { addInvestigationMarkCloseMutation } from "./investigation-mark-close.operations";

@Injectable()
export class GqlInvestigationMarkCloseService {
  public constructor(
    private apollo: Apollo
  ) { }

  public addInvestigationMarkClose(input: IApiAddInvestigationMarkCloseInput) {
    return this.apollo.mutate({
      mutation: addInvestigationMarkCloseMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
