import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../../modules/graphql/interfaces';
import { GqlCertificationTypeService } from "src/app/shared/modules/graphql/services";
import { IApiAddCertificationTypeInput, IApiCertificationTypeConnection, IApiCertificationTypeFilter, IApiUpdateCertificationTypeInput } from "src/app/shared/modules/graphql/types/types";
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class CertificationTypeService {

  constructor(
    private gqlCertificationTypeService: GqlCertificationTypeService
  ) { }

  public get(filters?: IApiCertificationTypeFilter[], options?: IGqlBaseOptions): Observable<IApiCertificationTypeConnection> {
    return this.gqlCertificationTypeService.getCertificationTypes(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddCertificationTypeInput) {
    return this.gqlCertificationTypeService.addCertificationType(input);
  }

  public remove(id: string) {
    return this.gqlCertificationTypeService.removeCertificationType(id);
  }

  public update(input: IApiUpdateCertificationTypeInput) {
    return this.gqlCertificationTypeService.updateCertificationType(input);
  }

}
