import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { IApiExpenseTypeConnection, IApiExpenseTypeFilter } from "../../../types/types";
import { ExpenseTypeConnectionQuery } from "./expense-type.operations";

@Injectable()
export class GqlExpenseTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getExpenseTypes(filters: IApiExpenseTypeFilter[], options: IGqlBaseOptions): Observable<IApiExpenseTypeConnection> {
    return this.apollo.query(connectionQuery(ExpenseTypeConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }
}
