import gql from 'graphql-tag';

export const investigationMetricsTechReviewerConnection = gql`query InvestigationMetricsTechReviewerConnection($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  investigationMetricsTechReviewerConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    pageInfo {
      limit
      offset
      __typename
    }
    totalCount
    edges {
      cursor
      node {
        id
        firstName
        middleName
        lastName
        data
      }
      __typename
    }
    __typename
  }
}`
