import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  canToggleVisibility
  RequiredDocuments {
    id
  }
  ServiceType {
    id
    name
  }
  SubServices {
    id
  }
  createdAt
  updatedAt
`;

export const subServiceTypesConnectionQuery = gql`${buildConnectionQuery("SubServiceType", "subServiceTypesConnection", nodeFragment)}`;

export const addSubServiceTypeMutation = gql`mutation createSubServiceType($input: AddSubServiceTypeInput!) {
  addSubServiceType(input: $input) { ${nodeFragment} }
  }`;

export const removeSubServiceTypeMutation = gql`mutation removeSubServiceType($input: RemoveSubServiceTypeInput!) {
  removeSubServiceType(input: $input) { ${nodeFragment} }
}`;

export const updateSubServiceTypeMutation = gql`mutation updateSubServiceType($input: UpdateSubServiceTypeInput!) {
  updateSubServiceType(input: $input) { ${nodeFragment} }
}`;

export const getSubServiceTypeByIdQuery = gql`query getSubServiceTypeById($id: ID!) {
  getSubServiceTypeById(id: $id) {${nodeFragment}}
}`;
