import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IApiQuarterlyUtilizationReportConnection, IApiQuarterlyUtilizationReportFilter } from "../../../types/types";
import { IGqlBaseOptions } from "../../../interfaces";
import { quarterlyUtilizationReportConnectionQuery } from "./quarterly-utilization-report.operations";
import { connectionQuery } from "../../../helpers.class";

@Injectable()
export class GqlQuarterlyUtilizationReportService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiQuarterlyUtilizationReportFilter[], options: IGqlBaseOptions): Observable<IApiQuarterlyUtilizationReportConnection> {
    return this.apollo.query(connectionQuery(quarterlyUtilizationReportConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

}
