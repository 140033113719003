import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  createdAt
  updatedAt
`;

export const ExpenseTypeConnectionQuery = gql`${buildConnectionQuery("ExpenseType", "expenseTypeConnection", nodeFragment)}`;

export const getExpenseTypeByIdQuery = gql`query getExpenseTypeById($id: String!) {
  getExpenseTypeById(id: $id) { ${ nodeFragment } }
}`;

export const addExpenseTypeMutation = gql`mutation AddExpenseType($input: AddExpenseTypeInput!) {
  addExpenseType(input: $input) { ${ nodeFragment } }
}`;

export const updateExpenseTypeMutation = gql`mutation UpdateExpenseType($input: UpdateExpenseTypeInput!) {
  updateExpenseType(input: $input) { ${ nodeFragment } }
}`;

export const removeExpenseTypeMutation = gql`mutation RemoveExpenseType($id: ID!) {
  removeExpenseType(id: $id)
}`;