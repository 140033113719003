<kendo-card class="{{'kendocard'}}" [width]="'100%'">
    <kendo-card-header class="card-header">
        <div class="header-main align-items-center">
            <div>
                <h3 class="mb-0 ml-8">{{pageTitle}}</h3>
            </div>
            <button
                kendoButton
                [size]="'medium'"
                themeColor="light"
                fillMode="solid"
                iconClass="fas fa-plus"
                class="mr-8"
                (click)='addEvidence()' [disabled]="(evidenceExpenses?.length && evidenceExpenses?.[0]?.InvoiceItems?.length) || evidenceExpenses?.length > 1"
            >
                Create Evidence Expense
            </button>
        </div>
    </kendo-card-header>
    <kendo-card-body class="pt-0 pb-0">
        <!-- TODO: may be it display in future -->
        <!-- <p class="muted" *ngIf="evidenceExpenses.length">Initial evidence expense was created on {{ evidenceExpenses[0].expenseDate | customDate: 'MM/dd/yyyy' }}.</p>
        <p class="muted ml-1" *ngIf="evidenceExpenses.length && evidenceExpenses[0].InvoiceItems.length">Initial evidence expense was invoiced on {{ evidenceExpenses[0].InvoiceItems[0].createdAt | customDate: 'MM/dd/yyyy' }}.</p> -->
        <kendo-grid
            class="hours-list"
            [data]="investigation?.Evidence"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortField($event)"
        >

            <!-- date -->
            <kendo-grid-column
                field="CREATED_AT"
                title="Date"
                [width]="15"
                [sortable]="true"
                class="font-weight600"
                [headerClass]="'sort-header'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span>{{(dataItem?.createdAt | customDate : "MM/dd/yyyy") || '-'}}</span>
                </ng-template>
            </kendo-grid-column>

            <!-- Description -->
            <kendo-grid-column
                field="description"
                title="Description"
                [width]="20"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="text-overflow">{{dataItem?.description || '-'}}</span>
                </ng-template>
            </kendo-grid-column>

            <!-- Pallets -->
            <kendo-grid-column
                field="User"
                title="Pallets"
                [width]="15"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{_investigation?.EvidenceBilling?.palletCount}}
                </ng-template>
            </kendo-grid-column>

            <!-- Entered By -->
            <kendo-grid-column
                field="User"
                title="Entered By"
                [width]="18"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{(dataItem?.CreatedBy | fullName) || '-'}}
                </ng-template>
            </kendo-grid-column>

             <!-- Rate -->
             <kendo-grid-column
                field="ExpensePurpose"
                title="Rate"
                [width]="15"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="">
                        {{_investigation?.EvidenceBilling?.rate}}
                    </span>
                </ng-template>
            </kendo-grid-column>

            <!-- Billable -->
            <kendo-grid-column
                field="BILL"
                title="Billable"
                [width]="10"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{_investigation?.EvidenceBilling?.billable ? "Y" : ""}}
                </ng-template>
            </kendo-grid-column>

            <!-- Schedule -->
            <kendo-grid-column
                field="NON_BILL"
                title="Schedule"
                [width]="15"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{_investigation?.EvidenceBilling?.BillingFrequency.name}}
                </ng-template>
            </kendo-grid-column>

            <!-- Invoice # -->
            <kendo-grid-column
                field="description"
                title="Invoice #"
                [width]="15"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span >{{dataItem?.invoice_num || '-'}}</span>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridNoRecordsTemplate>
                <p class="no-record">No Evidence</p>
            </ng-template>
        </kendo-grid>
    </kendo-card-body>
</kendo-card>
