import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICalendarEvent } from '../calendar-view.component';

@Component({
  selector: 'app-calendar-detail-modal',
  templateUrl: './calendar-detail-modal.component.html',
  styleUrls: ['./calendar-detail-modal.component.scss']
})
export class CalendarDetailModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public event: ICalendarEvent,
    public dialogRef: MatDialogRef<CalendarDetailModalComponent>
  ) { }

}
