import { connectionQuery } from './../../../helpers.class';
import { photoThumbnailConnectionQuery, addPhotoThumbnailMutation, updatePhotoThumbnailMutation, removePhotoThumbnailMutation, uploadPhotoThumbnailMutation } from './photo-thumbnail.operations';
import { IApiAddPhotoThumbnailInput, IApiPhotoThumbnail, IApiPhotoThumbnailFilter, IApiUpdatePhotoThumbnailInput } from './../../../types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { IGqlBaseOptions } from "../../../interfaces";
import { safeResolve } from "../../../rxjs";

@Injectable()
export class GqlPhotoThumbnailService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getPhotoThumbnails(filters: IApiPhotoThumbnailFilter[], options: IGqlBaseOptions): Observable<IApiPhotoThumbnail[]> {
    return this.apollo.query(connectionQuery(photoThumbnailConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addPhotoThumbnail(input: IApiAddPhotoThumbnailInput): Observable<IApiPhotoThumbnail> {
    return this.apollo.mutate({
      mutation: addPhotoThumbnailMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public uploadPhotoThumbnail(fileInput: File, input: IApiAddPhotoThumbnailInput): Observable<IApiPhotoThumbnail> {
    return this.apollo.mutate({
      mutation: uploadPhotoThumbnailMutation,
      variables: { 
        fileInput,
        input
      },
      context: {
        useMultipart: true
      }
    }).pipe(
      safeResolve(true)
    );
  }

  public updatePhotoThumbnail(input: IApiUpdatePhotoThumbnailInput): Observable<IApiPhotoThumbnail> {
    return this.apollo.mutate({
      mutation: updatePhotoThumbnailMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removePhotoThumbnail(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removePhotoThumbnailMutation,
      variables: {
        id
      }
    }).pipe(
      safeResolve(true)
    );
  }
}