import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  isDisabled
  method
  PermissionRole {
    id
    name
  }
  User {
    id
    firstName
    lastName
    email
  }
  NotificationType {
    id
  }
  createdAt
  updatedAt
`;

export const notificationMethodSettingsConnectionQuery = gql`${buildConnectionQuery("NotificationMethodSetting", "notificationMethodSettingsConnection", nodeFragment)}`;

export const addNotificationMethodSettingMutation = gql`mutation createNotificationMethodSetting($input: AddNotificationMethodSettingInput!) {
  addNotificationMethodSetting(input: $input) { ${nodeFragment} }
  }`;

export const removeNotificationMethodSettingMutation = gql`mutation removeNotificationMethodSetting($input: RemoveNotificationMethodSettingInput!) {
  removeNotificationMethodSetting(input: $input) { ${nodeFragment} }
}`;

export const updateNotificationMethodSettingMutation = gql`mutation updateNotificationMethodSetting($input: UpdateNotificationMethodSettingInput!) {
  updateNotificationMethodSetting(input: $input) { ${nodeFragment} }
}`;

export const updateV2NotificationMethodSettingMutation = gql`mutation updateNotificationMethodSettingV2($input: UpdateNotificationMethodSettingInputV2!) {
  updateNotificationMethodSettingV2(input: $input)
}`;

export const getNotificationMethodSettingByIdQuery = gql`query getNotificationMethodSettingById($id: ID!) {
  getNotificationMethodSettingById(id: $id) {${nodeFragment}}
}`;
