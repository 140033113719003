import { connectionQuery } from './../../../helpers.class';
import { IApiDocumentSentConnection, IApiDocumentSentFilter, IApiDocumentShareConnection, IApiDocumentZipDownload } from './../../../types/types';
import { IApiUpdateDocumentShareInput, IApiAddDocumentShareInput, IApiDocumentShareFilter } from '../../../types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { IApiDocumentShare } from "../../../types/types";
import { IGqlBaseOptions } from "../../../interfaces";
import { documentShareConnectionQuery, addDocumentShareMutation, updateDocumentShareMutation, removeDocumentShareMutation, documentZipDownloadMutation, sharedReportConnectionQuery, refreshInvestigationDocumentShareSubscription } from "./document-share.operations";
import { safeResolve } from "../../../rxjs";

@Injectable()
export class GqlDocumentShareService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getShares(filters: IApiDocumentShareFilter[], options: IGqlBaseOptions): Observable<IApiDocumentShareConnection> {
    return this.apollo.query(connectionQuery(documentShareConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getSharedReport(filters: IApiDocumentSentFilter[], options: IGqlBaseOptions): Observable<IApiDocumentSentConnection> {
    return this.apollo.query(connectionQuery(sharedReportConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addShare(input: IApiAddDocumentShareInput): Observable<IApiDocumentShare> {
    return this.apollo.mutate({
      mutation: addDocumentShareMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateShare(input: IApiUpdateDocumentShareInput): Observable<IApiDocumentShare> {
    return this.apollo.mutate({
      mutation: updateDocumentShareMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeShare(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeDocumentShareMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public downloadZip(input: IApiDocumentZipDownload): Observable<string> {
    return this.apollo.mutate({
      mutation: documentZipDownloadMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public refreshInvestigationDocumentShare(): Observable<string> {
    return this.apollo.subscribe({
      query: refreshInvestigationDocumentShareSubscription,
    }).pipe(
      safeResolve(true)
    );
  }
}
