
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlContactService } from 'src/app/shared/modules/graphql/services/contact/contact.service';
import {
  IApiAddContactInput, IApiContact, IApiRemoveContactInput,
  IApiUpdateContactInput, IApiContactFilter, IApiContactConnection, IApiEmailContactInput, IApiUpdateAndReviewContactInput, IApiRejectContactInput
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "../../helpers/list-page.class";
import { NefcoDateHelper } from '../../helpers/nefco-date.class';


@Injectable()
export class ContactService {

  constructor(
    private gqlContactService: GqlContactService
  ) { }

  public get(filters: IApiContactFilter[] = [], options?: IGqlBaseOptions): Observable<IApiContactConnection> {
    return this.gqlContactService.get(filters, options || new ListPage());
  }

  public add(input: IApiAddContactInput) {
    return this.gqlContactService.add(input);
  }

  public remove(input: IApiRemoveContactInput) {
    return this.gqlContactService.remove(input);
  }

  public update(input: IApiUpdateContactInput) {
    return this.gqlContactService.update({
      ...input,
      BranchAssignments: input?.BranchAssignments ?
        input.BranchAssignments.map((obj) => {
          return {
            ...obj,
            startDate: obj?.startDate ? NefcoDateHelper.toUtc(obj.startDate, 12) : null,
            endDate: obj?.endDate ? NefcoDateHelper.toUtc(obj.endDate, 12) : null
          };
        }) : null,
    AddBranch: input?.AddBranch ? {
        ...input.AddBranch,
        startDate: input.AddBranch?.startDate ? NefcoDateHelper.toUtc(input.AddBranch.startDate, 12) : null,
        endDate: input.AddBranch?.endDate ? NefcoDateHelper.toUtc(input.AddBranch.endDate, 12) : null    
      } : null
    });
  }

  public getById(id: string): Observable<IApiContact> {
    return this.gqlContactService.getContactById(id);
  }
  
  public email(input: IApiEmailContactInput, fileInput: File = null) {
    return this.gqlContactService.email(input, fileInput);
  }

  public approved(input: IApiUpdateAndReviewContactInput) {
    return this.gqlContactService.approved(input);
  }

  public reject(input: IApiRejectContactInput) {
    return this.gqlContactService.reject(input);
  }

  public searchBy(filters: IApiContactFilter[] = [], options?: IGqlBaseOptions): Observable<IApiContactConnection> {
    return this.gqlContactService.searchBy(filters, options || new ListPage());
  }
}
