import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsTechReviewerConnection } from 'src/app/shared/modules/graphql/types/types';
import { GqlInvestigationMetricsTechReviewerService } from 'src/app/shared/modules/graphql/services/reports/investigation-metrics/investigation-metrics-tech-reviewer/investigation-metrics-tech-reviewer.service';

@Injectable()
export class InvestigationMetricsTechReviewerService {
  constructor(
    private reports: GqlInvestigationMetricsTechReviewerService
  ) { }

  public get(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationMetricsTechReviewerConnection> {
    return this.reports.get(filters, options || new ListPage());
  }

}
