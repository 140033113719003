import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IGqlPermissionSet } from './modules/graphql/services/authorization/authorization.interfaces';
import { PermissionCategories } from './modules/graphql/enums/permissions.enums';
import { ElementRef } from '@angular/core';
import { pipe, MonoTypeOperatorFunction, of, from } from 'rxjs';
import { map, switchMap, mergeMap, startWith, tap } from 'rxjs/operators';
import { isEqual } from "lodash";
import { AssignedDelegate } from "./types/permissions.types";
import { fromEvent } from "rxjs";
import { IApiEffectivePermissionCategory } from "./modules/graphql/types/types";

// RxJs pipeable operator for shuffling an array
export function shuffle(): MonoTypeOperatorFunction<any> {
  return pipe(
    map((results: any[]) => {
      return !results ? [] : results.map((a) => [Math.random(), a]).sort((a, b) => a[0] - b[0]).map((a) => a[1]);
    })
  );
}

export function flattenArrays(): MonoTypeOperatorFunction<any> {
  return pipe(
    map((results: any[][]) => {
      return results.reduce((prev, curr) => {
        return [
          ...prev,
          ...curr
        ];
      }, []);
    })
  );
}

export function distinctAgainst(delegate = () => null): MonoTypeOperatorFunction<any> {
  return pipe(
    switchMap(val => {
      const result = delegate();
      if (!!result && isEqual(val, result)) {
        return of();
      }
      // not equal - keep passing down the chain
      return of(val);
    })
  );
}

export function hasCategoryPermission(
  category: PermissionCategories,
  actions: IGqlPermissionSet,
  assignmentDelegate?: AssignedDelegate
): MonoTypeOperatorFunction<any> {
  // subset of keys that need to be checked for security to pass
  const checkCategories = Object.keys(actions).filter(k => actions[k].length);

  return pipe(
    // if we have an assignment delegate, wait for it to resolve
    mergeMap((pResponse) => {
      return (assignmentDelegate ? from(assignmentDelegate(category, actions)) : of(true)).pipe(
        map((assignedAccess: boolean) => {
          return {
            permissions: pResponse as IApiEffectivePermissionCategory[],
            assignedAccess
          };
        })
      );
    }),
    map(({ permissions, assignedAccess }) => {

      return checkCategories.reduce((prev, scope) => {
        if (prev) return true;

        const filtered = permissions.filter(({ name }) => name === category);
        const mapped = filtered.flatMap(({ EffectivePermissionScopes }) => EffectivePermissionScopes);
        const reFiltered = mapped.filter(({ name }) => name === scope);
        const reMapped = reFiltered.flatMap(({ EffectivePermissionActions }) => EffectivePermissionActions);
        return assignedAccess && !! // Only look for current category
           // Peel out permission scopes
           // Only look for the scope we are comparing
           // Peel out permission actions
          reMapped.find(({ name }) => actions[scope].includes(name)); // Check if the actions include the value to be checked
      }, false);
    })
  );
}

// RxJs pipeable operator for removing __typename,
export function removeTypename(): MonoTypeOperatorFunction<any> {
  return pipe(
    map((result: any) => JSON.parse(JSON.stringify(result, (k, v) => (k === "__typename") ? undefined : v)))
  );
}

export function unwrapConnection(): MonoTypeOperatorFunction<any> {
  return pipe(
    map((result: any) => {
      const { edges } = result;
      return edges.map(e => e.node);
    })
  );
}

export class ElementRefEventListener {
  private _listener$;
  public get listener$() {
    return this._listener$;
  }
  constructor(element: ElementRef, event = "keyup", debounce = 400, startWithValue?: string) {
    this._listener$ = fromEvent<any>(element.nativeElement, event).pipe(
      map(({ target }) => target.value),
      (startWithValue ? startWith(startWithValue) : tap()),
      debounceTime(debounce),
      distinctUntilChanged()
    );
  }
}
