import { map } from 'rxjs/operators';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { InvestigationRoleService } from '../../services';
import { IApiInvestigationRole } from './../../modules/graphql/types/types';

export interface IRoleSelectConfig {
  placeholderText: string;
  labelText: string;
  excludedRoleNames?: string[];
  filteredRoleNames?: string[];
}

@UntilDestroy()
@Component({
  selector: 'app-role-select',
  templateUrl: './role-select.component.html',
  styleUrls: ['./role-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleSelectComponent),
      multi: true
    }
  ]
})
export class RoleSelectComponent implements OnInit, ControlValueAccessor {

  @Input() config: IRoleSelectConfig;

  public roleId = '';
  public roles: IApiInvestigationRole[] = [];

  public get selectedRole(): IApiInvestigationRole {
    return this.roles.find(({ id }) => id === this.roleId);
  }

  constructor(private _roleService: InvestigationRoleService) { }

  ngOnInit(): void {
    this._roleService.investigationRoles.pipe(
      untilDestroyed(this),
      // note - tried using switchAll() and toArray() pattern but for some reason it wouldn't emit - going back to regular map/filter method
      map(data => data.sort(({ title: titleA }, { title: titleB }) => titleA < titleB ? -1 : 1).filter(({ title }) => (
        (!this.config.excludedRoleNames || !this.config.excludedRoleNames.includes(title)) &&
        (!this.config.filteredRoleNames || this.config.filteredRoleNames.includes(title))
      )))
    ).subscribe((roles) => this.roles = roles);
  }

  public handleSelectionChange(event) {
    this.roleId = event.value;
    this._propagateChange(this.roleId);
  }

  private _propagateChange = (_: any) => { };

  // Control Value accessor members
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.roleId = value;
    }
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }

}
