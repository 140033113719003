<kendo-grid class="evidence-item-comments-list" [data]="comments" [sortable]="false" [navigatable]="false">
  <kendo-grid-column title="Date/Time" [width]="15" [sortable]="false" [headerClass]="'nocursor'"
  class="id-column font-weight600">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [innerHTML]="dataItem.createdAt | customDate: 'MM-dd-yyyy, h:mm a'"></span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Comment" [width]="40" [sortable]="false" [headerClass]="'nocursor'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [innerHTML]="dataItem?.text || '-'"></span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="User" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [innerHTML]="(dataItem?.CreatedBy) | fullName"></span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>