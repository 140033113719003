<kendo-drawer #drawerDataRequest [width]="-1" [mini]="false" [mode]="'overlay'" [position]="'end'" [animation]="true">
  <ng-template kendoDrawerTemplate>
    <section class="content-part safe-area-padding-top">
      <div class="header mb-16">
        <span>
          <h6>Data Request</h6>
          <p> Investigation ID: {{investigation?.nefcoNumber}}</p>
        </span>
        <button class="close-action" kendoButton themeColor="primary" fillMode="link"
          [iconClass]="drawerDataRequest.expanded && 'fa-solid fa-times fa-xl'" (click)="close()"></button>
      </div>
      <hr class="divider-line mb-16" />
      <form #requestForm="ngForm">
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label text="Request Type" class="asterisk"></kendo-label>
              <kendo-dropdownlist [data]="requestTypes" name="RequestTypeId" #RequestTypeId="ngModel"
                [(ngModel)]="request.RequestTypeId" textField="name" valueField="id" fillMode="outline"
                [valuePrimitive]="true" [defaultItem]="{id: null, name: 'Select Request Type'}" [required]="true"
                [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250
              }">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="!dataItem?.id" class="placeholder">
                    {{ dataItem?.name }}
                  </span>
                  <span *ngIf="dataItem?.id" class="not-placeholder">
                    {{ dataItem?.name }}
                  </span>
                </ng-template>
              </kendo-dropdownlist>
              <kendo-formerror *ngIf="RequestTypeId?.errors && (RequestTypeId?.dirty || RequestTypeId?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="RequestTypeId?.errors.required">Request type cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="asterisk" text="Authorized By"></kendo-label>
              <kendo-textbox name="authorizedBy" #authorizedBy="ngModel" placeholder="Enter authorized by"
                [(ngModel)]="request.authorizedBy" fillMode="outline" [clearButton]="false" [required]="true"
                appNoWhiteSpace></kendo-textbox>
              <kendo-formerror *ngIf="authorizedBy?.invalid && (authorizedBy?.dirty || authorizedBy?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="authorizedBy?.errors?.required">Authorized by cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="asterisk" text="Reason"></kendo-label>
              <kendo-textarea name="notes" #notes="ngModel" [(ngModel)]="request.notes"
                placeholder="Please provide a brief explanation about this request." fillMode="outline"
                class="min-height-96" resizable="both" [required]="true" appNoWhiteSpace></kendo-textarea>
              <kendo-formerror *ngIf="notes?.errors">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="notes?.errors.required">Reason cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
        <kendo-gridlayout class="pl-16 pr-16 text-center" [rows]="['auto']" [gap]="{rows: 16, cols: 16}"
          [cols]="['repeat(1,minmax(0,1fr))']" style="position: absolute; left:0; right:0; bottom: 0;z-index: 1;">
          <hr class="divider-line" />
          <kendo-gridlayout-item class="mb-48">
            <kendo-button kendoButton themeColor="primary" type="submit" fillMode="solid" size="large" style="width: 100%;" (click)="submit()" >
              Add Request
            </kendo-button>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </form>
    </section>
  </ng-template>
</kendo-drawer>
