import gql from 'graphql-tag';

export const quarterlyHourReportConnectionQuery = gql`query QuarterlyHourReportConnection($filters: [QuarterlyUtilizationReportFilter], $orderBy: QuarterlyUtilizationReportOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  quarterlyHourReportConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    pageInfo {
      limit
      offset
    }
    totalCount
    edges {
      cursor
      node {
        id
        firstName
        lastName
        quarterlyGoal
        TimeEntry {
          hours
          nonBillableHours
          name
        }
      }
    }
  }
}`;

export const generateQuarterlyHourReportCSV = gql`query QuarterlyHourReportCSV($filters: [QuarterlyUtilizationReportFilter], $orderBy: QuarterlyUtilizationReportOrderBy,   $sortOrder: SortOrder) { quarterlyHourReportCSV(filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder)  }`
