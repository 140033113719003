<div class="kendo-formfield">
  <kendo-label *ngIf="label" [text]="label"></kendo-label>
  <kendo-dropdownlist
    #lossState
    [data]="stateList"
    textField="text"
    valueField="value"
    [valuePrimitive]="true"
    [(ngModel)]="selectedId"
    fillMode="outline"
    [disabled]="disabled"
    [filterable]="false"
    [defaultItem]="{ value: undefined, text: placeholder, type: '' }"
    (selectionChange)="select($event)"
    [popupSettings]="{ appendTo: 'component', animate: false, width: 250 }"
  >
    <ng-template kendoDropDownListGroupTemplate let-groupName>
      <strong>{{ groupName }}</strong>
    </ng-template>
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span *ngIf="!dataItem?.value" class="placeholder">
        {{ dataItem.text }}
      </span>
      <span *ngIf="dataItem?.value" class="not-placeholder">
        {{ dataItem.text }}
      </span>
    </ng-template>
  </kendo-dropdownlist>
</div>
