import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EvidenceReportComponent } from './evidence-report.component';

const routes: Routes = [
  {
    path: "",
    component: EvidenceReportComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EvidenceReportModuleRouter { }
