import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IApiInvestigationMetricsClientCompanyConnection, IApiInvestigationMetricsFilter } from '../../../../types/types';
import { IGqlBaseOptions } from '../../../../interfaces';
import { connectionQuery } from '../../../../helpers.class';
import { investigationMetricsClientCompanyConnection } from './investigation-metrics-client-company.operations';

@Injectable()
export class GqlInvestigationMetricsClientCompanyService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiInvestigationMetricsFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationMetricsClientCompanyConnection> {
    return this.apollo.query(connectionQuery(investigationMetricsClientCompanyConnection, filters, options)).pipe(
      safeResolve(true)
    );
  }

}
