import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListPage } from '../../helpers/list-page.class';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces';
import { GqlPortalService } from '../../modules/graphql/services/portal';
import { IApiCompaniesConnection, IApiCompanyFilter, IApiContactRole, IApiContactRoleFilter, IApiPhoneType, IApiPhoneTypeFilter, IApiRiskType } from '../../modules/graphql/types/types';
import { IApiClientUserDetail } from 'src/app/shared/modules/graphql/types/types';

export type IApiClientExist = {
  __typename?: string;
  email?: string;
  emailableId?: string;
  emailableType?: string;
};

@Injectable()
export class PortalService {
    constructor(
        private portalService: GqlPortalService
      ) { }

    public getPublicContactRoles(filters: IApiContactRoleFilter[], options?: IGqlBaseOptions): Observable<IApiContactRole> {
      return this.portalService.getPublicRoles(filters, options || new ListPage(-1));
    }

    public getRiskType(): Observable<IApiRiskType[]> {
      return this.portalService.getRiskType();
    }

    public getContactType(filters: IApiPhoneTypeFilter[] = [], options?: IGqlBaseOptions): Observable<IApiPhoneType[]> {
      return this.portalService.getPhoneTypes(filters, options || new ListPage(-1) );
    }

    public checkClientExist(email: string, details?: boolean): Observable<IApiClientUserDetail> {
      return this.portalService.checkClientExist(email, details);
    }

    public createIntake(data: any): Observable<any> {
      return this.portalService.createInvestigation(data);
    }

    public getCompanies(filters: IApiCompanyFilter[] = [], options?: IGqlBaseOptions): Observable<IApiCompaniesConnection> {
      return this.portalService.getCompanies(filters, options || new ListPage(-1));
    }

    public getCurrentContact(email: string): Observable<any> {
      return this.portalService.getCurrentContact(email);
    }

    public getCurrentContactById(id: string): Observable<any> {
      return this.portalService.getCurrentContactById(id);
    }

    public createUserAndResetPassword(email: string): Observable<any> {
      return this.portalService.createUserAndResetPassword(email);
    }

    public resetPassword(email: string): Observable<any> {
      return this.portalService.resetPassword(email);
    }

}
