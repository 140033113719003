<!-- Permissions -->
<div [appEnforcePermission]="authConfig.companies" #companies="appEnforcePermission"></div>
<!-- Permissions -->
<div class="kendo-formfield left-search-icon">
    <kendo-label [for]="" text="{{label}}"></kendo-label>
    <div style="position: relative;">
      <i class="fa fa-search" aria-hidden="true"></i>
      <kendo-autocomplete
        #autoCompleteCompany
        [data]="companyList"
        [loading]="loading"
        [value]="selected?.name"
        valueField="id"
        fillMode="outline"
        [filterable]="true"
        (valueChange)="selectCompany($event)"
        (filterChange)="onSearchChange($event)"
        [placeholder]="placeholder"
        [disabled]="disabled || !companies?.enabled"
        [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
      >
        <ng-template kendoAutoCompleteItemTemplate let-dataItem>
          <span>{{ dataItem?.name }}</span>
        </ng-template>
      </kendo-autocomplete>
    </div>
</div>
