import { Component, OnInit } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-assignment-sent',
  templateUrl: './assignment-sent.component.html',
  styleUrls: ['./assignment-sent.component.scss']
})
export class AssignmentSentComponent extends DialogContentBase {

  constructor(
    public dialog: DialogRef
  ) { 
    super(dialog);
  }

  public onClose(text: string): void {
    this.dialog.close(text);
  }
}
