import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IApiAbortMultipartUploadInput, IApiCompleteMultipartUploadInput, IApiCreateMultipartResponse, IApiS3FileInput, IApiSignedUrlResponse, IApiUploadMultipartPartUrlInput } from 'src/app/shared/modules/graphql/types/types';
import { abortMultipartUploadMutation, completeMultipartUploadMutation, createMultipartUploadMutation, createSignedUrlMutation, getUploadPartUrlMutation, photoDownloadProgress } from './s3.operations';

@Injectable()
export class GqlS3Service {
  public constructor(
    private apollo: Apollo
  ) { }

  public createSignedUrlMutation(input: IApiS3FileInput): Observable<IApiSignedUrlResponse> {
    return this.apollo.mutate({
      mutation: createSignedUrlMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public photoDownloadProgress(): Observable<any> {
    return this.apollo.subscribe({
      query: photoDownloadProgress,
    }).pipe(
      safeResolve(true)
    );
  }

  public createMultipartUpload(input: IApiS3FileInput): Observable<IApiCreateMultipartResponse> {
    return this.apollo.mutate({
      mutation: createMultipartUploadMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getUploadMultipartPartUrl(input: IApiUploadMultipartPartUrlInput): Observable<IApiSignedUrlResponse> {
    return this.apollo.mutate({
      mutation: getUploadPartUrlMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public completeMultipartUpload(input: IApiCompleteMultipartUploadInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: completeMultipartUploadMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public abortMultipartUpload(input: IApiAbortMultipartUploadInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: abortMultipartUploadMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
