import gql from "graphql-tag";
import { buildConnectionQuery } from "../../../helpers.class";

export const nodeFragment = `
  id
  name
`;

export const certificationAgencyConnectionQuery = gql`${buildConnectionQuery("CertificationAgency", "certificationAgencyConnection", nodeFragment)}`;

export const getCertificationAgencyByIdQuery = gql`query getCertificationAgencyById($id: String!) {
  getCertificationAgencyById(id: $id) { ${ nodeFragment } }
}`;

export const addCertificationAgencyMutation = gql`mutation AddCertificationAgency($input: AddCertificationAgencyInput!) {
  addCertificationAgency(input: $input) { ${ nodeFragment } }
}`;

export const updateCertificationAgencyMutation = gql`mutation UpdateCertificationAgency($input: UpdateCertificationAgencyInput!) {
  updateCertificationAgency(input: $input) { ${ nodeFragment } }
}`;

export const removeCertificationAgencyMutation = gql`mutation RemoveCertificationAgency($id: ID!) {
  removeCertificationAgency(id: $id)
}`;