import { Component, Input } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import * as allIcons from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-kendo-model',
  templateUrl: './kendo-model.component.html',
  styleUrls: ['./kendo-model.component.scss']
})
export class KendoModelComponent extends DialogContentBase {

  @Input() title: string = 'Go to Home';
  @Input() bodyMessage: string = 'Are you sure you want to leave this page? Any changes made to the investigation form will not be saved.';
  @Input() leftBtn: string = 'No';
  @Input() rightBtn: string = 'Yes';
  @Input() minWidth: number = 550;
  @Input() width: number = 550;
  @Input() headerIcon: boolean = true;
  @Input() subTitle: string = '';
  public icons = allIcons;


  constructor(
    public dialog: DialogRef
  ) {
    super(dialog);
  }

  public close(text: string) {
    const status_ = text === 'yes' ? true : false;
    this.dialog.close(status_);
  }

}
