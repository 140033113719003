<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationStatusListView" #investigationStatusListView="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div class="d-flex justify-content-between" *ngIf="config.type === type.readyToBill">
    <mat-radio-group [(ngModel)]="billingStatus" (ngModelChange)="billingStatusChange($event)">
      <mat-radio-button class="px-2" [value]="billingStatusOpts.NoReport">Ready To Bill / No Report</mat-radio-button>
      <mat-radio-button class="px-2" [value]="billingStatusOpts.HasReport">Ready To Bill / Has Report</mat-radio-button>
      <mat-radio-button class="px-2" [value]="billingStatusOpts.Unbilled">Ongoing Unbilled</mat-radio-button>
    </mat-radio-group>
    <p class="mr-4">Investigations Ready to Bill: <span class="font-weight-bold"> {{ readyToBillTotalResults }} </span> </p>
  </div>
  <!-- Change filter to filter card and filter triggered by button press -->
  <!-- Defer loading until after parent initalized  -->
  <!-- <app-investigations-filter-card (filters)="filterInvestigations($event)" [userViewFilter]="apiUserFilterType" [selectLists]="{riskTypes: riskTypes}" *ngIf="(config.type === type.investigations) && loaded">
  </app-investigations-filter-card> -->


  <div class="filter-controls" *ngIf="config.type !== type.investigations && loaded">
    <div *ngIf="(config.type !== type.pending) && (config.type !== type.user) && (config.type !== type.contact)">
      <app-user-select [userViewFilter]="userViewFilter" label="Staff Member" placeholder="Search ..." (selectionChanged)="setUser($event?.id)"></app-user-select>
    </div>
    <div *ngIf="config.type === type.contact">
      <mat-form-field appearance="legacy">
        <mat-label>Contact Role</mat-label>
        <mat-select [value]="status" (selectionChange)="setContactRole($event)">
          <mat-option>All</mat-option>
          <mat-option *ngFor="let role of contactRoles" [value]="role">
            {{ role }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="(config.type === type.user || config.type !== type.readyToBill) && (config.type !== type.progress) && (config.type !== type.review) && (config.type !== type.pending) && (config.type !== type.contact)">
      <mat-form-field appearance="legacy">
        <mat-label>Status</mat-label>
        <mat-select [value]="status" (selectionChange)="setStatus($event)" [disabled]="!investigationStatusListView?.enabled">
          <mat-option>All</mat-option>
          <mat-option *ngFor="let status of InvestigationStatuses | keyvalue" [value]="status.value">
            {{ status.key }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-container *ngIf="(config.type !== type.user) && (config.type !== type.contact)">
      <div>
        <mat-form-field appearance="legacy">
          <mat-label>Risk Type</mat-label>
          <mat-select (selectionChange)="setRiskType($event)">
            <mat-option>All</mat-option>
            <mat-option *ngFor="let riskType of riskTypes" [value]="riskType.name">
              {{riskType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="(config.type !== type.readyToBill) && (config.type !== type.review)">
        <mat-form-field appearance="legacy">
          <mat-label>Priority</mat-label>
          <mat-select (selectionChange)="setPriority($event)">
            <mat-option>All</mat-option>
            <mat-option [value]="InvestigationPriorities.LOW">Low</mat-option>
            <mat-option [value]="InvestigationPriorities.MEDIUM">Medium</mat-option>
            <mat-option [value]="InvestigationPriorities.HIGH">High</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- TODO: Add Service Status Filter -->
      <!-- <div *ngIf="config.type === type.progress">
        <mat-form-field appearance="legacy">
          <mat-label>Service Status</mat-label>
          <mat-select (selectionChange)="setServiceStatus($event)">
            <mat-option>All</mat-option>
            <mat-option *ngFor="let serviceStatus of serviceStatuses" [value]="serviceStatus.name">
              {{serviceStatus.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </ng-container>

    <div *ngIf="config.type !== type.pending">
      <mat-form-field class="search" appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" #search (appDebouncedInputChange)="searchChange($event)" name="search" />
        <button mat-icon-button disableRipple matSuffix (click)="searchChange(null); search.value = null;">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <!-- Show Comments -->
  <div class="col-12 mb-2">
    <mat-checkbox *ngIf="config.type === tableType.ongoing || config.type === tableType.progress" [(ngModel)]="primaryInvestigator" (change)="setUser(userId)" class="mr-3">Show Primary Only</mat-checkbox>
    <mat-slide-toggle *ngIf="displayCommentToggle" [(ngModel)]="showComments" name="showComments">Show Comments</mat-slide-toggle>
  </div>

  <!-- <ng-template #investigationsList> -->
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="pageOptions.sort($event)" matSortDirection="asc" matSortActive="createdAt" multiTemplateDataRows>
    <ng-container matColumnDef="indicator">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" align="center">
        <div class="red-circle-icon" *ngIf="row?.InvestigationSource?.name === 'Online Intake Form' && row?.firstViewDate === null">
          <i class="fa-solid fa-circle-dot"></i>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef [mat-sort-header]="orderByOptions.CreatedAt">Created</th>
      <td mat-cell *matCellDef="let row">
        <div class="created-at">
          <!-- Stop propagation to not trigger the expandable content -->
          <ng-container *ngIf="config.type !== type.readyToBill; else billIcons">
            <button mat-icon-button [matMenuTriggerFor]="alertMenu" (click)="$event.stopPropagation()">
              <mat-icon [color]="row.alerts && 'red'">notification_important</mat-icon>
            </button>
            <mat-menu #alertMenu>
              <p class="alert-menu-header">Alerts</p>
              <p mat-menu-item class="alert-menu-content" *ngFor="let alert of row.alerts">
                <mat-icon>notification_important</mat-icon>
                {{ alert.name }}
              </p>
              <!-- Mock content below -->
              <p mat-menu-item class="alert-menu-content">
                <mat-icon>notification_important</mat-icon>
                A New Alert!
              </p>
              <p mat-menu-item class="alert-menu-content">
                <mat-icon>notification_important</mat-icon>
                An Additional Alert!
              </p>
            </mat-menu>
          </ng-container>
          <ng-template #billIcons>
            <mat-icon color="primar" *ngIf="row?.LinkedInvestigations.length">link</mat-icon>
            <mat-icon color="primar" *ngIf="row?.Documents.length">description</mat-icon>
          </ng-template>
          <span>{{row.createdAt | customDate:'short'}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="nefcoNumber">
      <th mat-header-cell *matHeaderCellDef>ID#</th>
      <td mat-cell *matCellDef="let row" align="center">
        <a target="_blank" [routerLink]="['/investigations/', row.id]" tel="" *ngIf="contactView else showNefcoNumber">
          <p data-detector="false">{{ row?.nefcoNumber || '-- Edit --' }}</p>
        </a>
        <ng-template #showNefcoNumber>{{row?.nefcoNumber || '-- Edit --'}}</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="priority">
      <th mat-header-cell [mat-sort-header]="orderByOptions.Priority" *matHeaderCellDef>Priority</th>
      <td mat-cell *matCellDef="let row">{{row.Priority?.name}}</td>
    </ng-container>

    <ng-container matColumnDef="lossDate">
      <th mat-header-cell *matHeaderCellDef [mat-sort-header]="orderByOptions.LossDate">Loss Date</th>
      <td mat-cell *matCellDef="let row" [innerHTML]="row.lossDate | customDate: 'MMM d, y': true"></td>
    </ng-container>

    <ng-container matColumnDef="riskType">
      <th mat-header-cell *matHeaderCellDef>Risk Type</th>
      <td mat-cell *matCellDef="let row">{{row.RiskType?.name}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let row">
        {{row.status}}
      </td>
    </ng-container>

    <ng-container matColumnDef="followUp">
      <th mat-header-cell *matHeaderCellDef>Follow Up</th>
      <td mat-cell *matCellDef="let row" class="text-center">
        <mat-icon>done</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="investigationLocation">
      <th mat-header-cell *matHeaderCellDef>Investigation Location</th>
      <td mat-cell *matCellDef="let row">
        {{row.InvestigationAddress?.address1}}<br />
        {{row.InvestigationAddress?.city}} {{row.InvestigationAddress?.state }},
        {{row.InvestigationAddress?.postal}}
      </td>
    </ng-container>

    <ng-container matColumnDef="policyClaimNumbers">
      <th mat-header-cell *matHeaderCellDef>Policy/Claim #</th>
      <td mat-cell *matCellDef="let row">
        <span class="font-weight-bold">Policy#:</span> {{row?.Detail?.policyNumber}}<br />
        <span class="font-weight-bold">Claim#:</span> {{row?.Detail?.claimNumber}}
      </td>
    </ng-container>

    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef [hidden]="!showComments">Comments</th>
      <td mat-cell *matCellDef="let row" [hidden]="!showComments">
        <!-- Show only first 5 comments -->
        <table mat-table [dataSource]="row?.Comments.slice(0, 5)" class="my-2">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Date/Time </th>
            <td mat-cell *matCellDef="let comment;">{{ comment.createdAt | customDate: 'MM-dd-yyyy, h:mm a' }}</td>
          </ng-container>
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Comment </th>
            <td mat-cell *matCellDef="let comment;">{{ comment.text}}</td>
          </ng-container>
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef class="font-weight-bold text-left"> User </th>
            <td class="text-left" mat-cell *matCellDef="let comment;">{{ comment.CreatedBy | fullName }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="commentLogColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: commentLogColumns;"></tr>
        </table>
      </td>
    </ng-container>

    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef>Insured</th>
      <td mat-cell *matCellDef="let row">
        <span class="font-weight-bold" *ngIf="row.Insured?.contactName">Insured Name:</span> {{row.Insured?.insuredName}}<br />
        <span class="font-weight-bold">Name:</span> {{row.Insured?.insuredName || row.Insured?.contactName}}<br />
        <span class="font-weight-bold" *ngIf="row.Insured?.title">Relationship/Title:</span> {{row.Insured?.title}}<br />
        <app-email-phone-view [phone]="(row.Insured?.Phones | primaryAssociation)"></app-email-phone-view>
      </td>
    </ng-container>

    <ng-container matColumnDef="billTo">
      <th mat-header-cell *matHeaderCellDef>Bill To</th>
      <td mat-cell *matCellDef="let row">
        {{ row.BillTo | fullName }}
        <app-address-view *ngIf="row?.BillToBranch; else BillToAddress" [address]="row.BillToBranch?.BillingAddress"></app-address-view>
        <ng-template #BillToAddress><app-address-view [address]="row?.BillTo?.BillingAddress"></app-address-view></ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>Client</th>
      <td mat-cell *matCellDef="let row">
        <span class="font-weight-bold">Name:</span> {{row.Client | fullName}}<br />
        <span class="font-weight-bold">Company:</span> {{row.ClientBranch?.Company?.name}}<br />
        <span class="font-weight-bold">Branch:</span> {{row.ClientBranch?.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="contactRole">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.Client?.id === this.config?.contactId">Client<br></ng-container>
        <ng-container *ngIf="row.Insured?.id === this.config?.contactId">Insured<br></ng-container>
        <ng-container *ngIf="row.SceneContact?.id === this.config?.contactId">Scene Contact<br></ng-container>
        <ng-container *ngIf="row.BillTo?.id === this.config?.contactId">Bill To<br></ng-container>
        <ng-container *ngFor="let party of row?.Parties">
          <ng-container *ngIf="party.Contact?.id === this.config?.contactId">{{ party.Role?.name }}<br></ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="assigned">
      <th mat-header-cell *matHeaderCellDef>Assigned</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.InvestigationStaff">
          <span class="d-block" *ngFor="let staff of orderInvestigationStaff(row.InvestigationStaff); let i = index;">{{ i + 1 }}. {{ staff.User | fullName }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- In Progress Investigation Table -->
    <ng-container matColumnDef="progress" *ngIf="config.type === type.progress">
      <th mat-header-cell *matHeaderCellDef>Progress</th>
      <td mat-cell *matCellDef="let row">
        {{servicesCount(row.Services)}}%
        <mat-progress-bar mode="determinate" value="{{servicesCount(row.Services)}}" color="accent"></mat-progress-bar>
      </td>
    </ng-container>
    <!-- Reports Rows -->
    <ng-container matColumnDef="review">
      <th mat-header-cell *matHeaderCellDef width="50%">
        <table>
          <thead>
            <tr class="text-left">
              <th width="30%">Report</th>
              <th width="30%">Created By</th>
              <th width="30%">Submitted</th>
              <th width="10%" class="text-center">Actions</th>
            </tr>
          </thead>
        </table>
      </th>
      <td mat-cell *matCellDef="let row">
        <table class="review-table">
          <tbody>
            <tr *ngFor="let doc of row.Documents" class="text-left">
              <td width="30%">
                {{ doc.Type.name }}
              </td>
              <td width="30%">
                {{ doc.CreatedBy | fullName }}
              </td>
              <td width="30%">
                {{ (doc.updatedAt || doc.createdAt) | customDate : 'MM-dd-yyyy' }}
              </td>
              <td width="10%" class="text-center">
                <ng-container [ngSwitch]="config.type">
                  <ng-container *ngSwitchCase="type.review">
                    <button mat-icon-button [matMenuTriggerFor]="reviewActionMenu" (click)="$event.stopPropagation()">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #reviewActionMenu>
                      <button mat-menu-item (click)="indicateCause($event, row, doc)">Indicate Cause</button>
                      <button mat-menu-item (click)="sendBack($event, row, doc)">Send Back</button>
                      <button mat-menu-item (click)="approveAsFinal($event, row, doc)">Approve as Final</button>
                      <button mat-menu-item (click)="unassign($event, row, doc)">Unassign</button>
                    </mat-menu>
                  </ng-container>
                  <!-- <button mat-button color="primary" *ngSwitchCase="type.readyForEditor" (click)="assignToMe($event, row, doc)">Assign to me</button> -->
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="text-center">Actions</th>
      <td mat-cell *matCellDef="let row">
        <ng-container [ngSwitch]="config.type">
          <div *ngSwitchCase="type.assigned" class="d-flex align-items-center justify-content-end">
            <button mat-button mat-icon-button class="d-inline-block" (click)="openStaffSidebar(row)">
              <mat-icon color="accent">calendar_today</mat-icon>
            </button>
            <button mat-button color="accent" class="d-inline-block break-spaces" (click)="moveToInProgress($event, row)">
              Move to In Progress
            </button>
          </div>
          <div *ngSwitchCase="type.progress">
            <!-- TODO disable button if investigation is not ready to go to billing -->
            <div>
              <button mat-raised-button color="primary" [disabled]="" class="action-button-text" (click)="sendToBilling($event, row)">
                <span class="d-block">Send to Billing</span>
              </button>
            </div>
          </div>
          <div *ngSwitchCase="type.readyToBill">
            <!-- TODO disable button if investigation is not ready to go to billing -->
            <button mat-icon-button [matMenuTriggerFor]="billActionMenu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #billActionMenu>
              <button mat-menu-item *ngIf="false" (click)="closeInvestigation($event, row)">Close</button> <!-- TODO enabled when invoice has been sent to quickbooks -->
              <button mat-menu-item (click)="changeToOngoing(row)">Change to Ongoing</button>
              <button mat-menu-item (click)="putOnHold(row)">Hold for Billing</button>
              <button mat-menu-item (click)="moveToInProgress($event, row)"> Move to In Progress</button>
            </mat-menu>
            <mat-icon *ngIf="hasQuickBookError(row.id)" color="error">error</mat-icon>
          </div>
          <div *ngSwitchCase="type.ongoing">
            <button mat-button (click)="closeInvestigation($event, row)">Close</button>
          </div>
          <div *ngSwitchCase="type.pending">
            <button mat-button [routerLink]="['/investigations', row.id]">View Details</button>
          </div>
        </ng-container>
      </td>
    </ng-container>
    <!-- Expandable Data Row for In Progress-->
    <ng-container matColumnDef="expandedDetail" *ngIf="config.type === type.progress || config.type === type.readyToBill">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="inv-expanded-bg text-left">
        <div class="inv-expanded-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
          <div class="content-wrapper" [ngSwitch]="config.type">
            <!-- reports input will be removed once data is available inside the dataset -->
            <ng-container *ngSwitchCase="type.progress">
              <ng-container *ngTemplateOutlet="progressInfo;"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="type.readyToBill">
              <ng-container *ngTemplateOutlet="billInfo;"></ng-container>
            </ng-container>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <ng-container *ngIf="config.type === type.progress || config.type === type.readyToBill; else standardRow">
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="inv-expanded-row" [class.highlight]="config.type === type.readyToBill && element.onHold" [class.inv-expanded-detail-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element; allExpandedInvoices();">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="inv-expanded-row"></tr>
    </ng-container>
    <ng-template #standardRow>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </ng-template>
  </table>
  <div class="no-results" *ngIf="!content">
    There are no results to display.
  </div>
  <mat-paginator #paginator [length]="pageOptions?.totalCount" [showFirstLastButtons]="true" [pageSize]="pageOptions?.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)" *ngIf="config.type !== type.contact">
  </mat-paginator>
</section>
<!-- </ng-template> -->

<!-- Bill Info -->
<ng-template #billInfo>
  <div *ngIf="expandedElement">
    <mat-card class="mat-elevation-z3">
      <div class="d-flex flex-row-reverse bill-action align-items-center">
        <button color="primary" mat-raised-button (click)="createInvoice($event, expandedElement)">Create Invoice</button>
      </div>
      <!-- Billing Data -->
      <h6 class="mt-5">Time &amp; Expenses</h6>
      <table mat-table [dataSource]="allExpandedExpenses(expandedElement)">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.createdAt | customDate: 'MM/dd/yyyy'"></td>
        </ng-container>
        <ng-container matColumnDef="staff">
          <th mat-header-cell *matHeaderCellDef>Staff</th>
          <td mat-cell *matCellDef="let row">
            {{ row.User | fullName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef>Item</th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.__typename === 'TimeEntry'; else expenseCell">
            </span>
            <ng-template #expenseCell>
              <span>{{ row.ExpenseItem?.name }}</span>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="purpose">
          <th mat-header-cell *matHeaderCellDef>Purpose</th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.__typename === 'TimeEntry'; else expenseCell">{{ row.Type.name }}</span>
            <ng-template #expenseCell>
              <span>{{ row.ExpensePurpose?.name }}</span>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="billableQuantity">
          <th mat-header-cell *matHeaderCellDef>Billable</th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.__typename === 'TimeEntry'; else expenseCell">
              {{ row.hours }}hrs
            </span>
            <ng-template #expenseCell>
              <span>{{ row.ExpenseItem?.name === 'Mileage' ? mileage(row.billableQuantity) : ((row.billableQuantity || 0) | currency) }}</span>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="nonBillableQuantity">
          <th mat-header-cell *matHeaderCellDef>Non-Billable</th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.__typename === 'TimeEntry'; else expenseCell">
            </span>
            <ng-template #expenseCell>
              <span>{{ row.ExpenseItem?.name === 'Mileage' ? mileage(row.nonBillableQuantity) : ((row.nonBillableQuantity || 0) | currency) }}</span>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.__typename === 'TimeEntry'; else expenseCell">
              {{ row.description }}
            </span>
            <ng-template #expenseCell>
              <span>{{ row.description }}</span>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="paid">
          <th mat-header-cell *matHeaderCellDef>Paid</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="">
            <span>{{ row.paidDate ? (row.paidDate | customDate: 'MM/dd/yyyy') : 'N/A' }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="billable">
          <th mat-header-cell *matHeaderCellDef>Billable</th>
          <td mat-cell *matCellDef="let row">
            <div class="d-flex">
              <!-- {{ row.billable ? 'Y' : 'N' }} TODO what is this? theres a billable and nonBillable amount on the expense item but nothing to indicate if overall its billable? -->
              <button *ngIf="row?.InvoiceItems.length" mat-icon-button (click)="viewInvoice(row)">
                <mat-icon color="primary">receipt_long_black</mat-icon>
              </button>
              <button mat-icon-button (click)="editExpense(row, $event, expandedElement)">
                <mat-icon>border_color</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="billingColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: billingColumns;"></tr>
      </table>

      <!-- Evidence Data -->
      <h6 class="mt-5">Evidence</h6>
      <div class="d-flex align-items-end">
        <p class="muted" *ngIf="evidenceExpenses.length">Initial evidence expense was created on {{ evidenceExpenses[0].expenseDate | customDate: 'MM/dd/yyyy' }}.</p>
        <p class="muted ml-1" *ngIf="evidenceExpenses.length && evidenceExpenses[0].InvoiceItems.length">Initial evidence expense was invoiced on {{ evidenceExpenses[0].InvoiceItems[0].createdAt | customDate: 'MM/dd/yyyy' }}.</p>
        <button mat-raised-button color="primary" class="mb-3 ml-auto" (click)='addEvidenceExpense()' [disabled]="(evidenceExpenses.length && evidenceExpenses[0].InvoiceItems.length) || evidenceExpenses.length > 1">CREATE FIRST EVIDENCE EXPENSE</button>
      </div>
      <table mat-table [dataSource]="expandedElement.Evidence" *ngIf="expandedElement?.EvidenceBilling" class="mt-1">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Arrival Date</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.createdAt | customDate: 'MM/dd/yyyy'"></td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.description"></td>
        </ng-container>
        <!-- <ng-container matColumnDef="pallets">
          <th mat-header-cell *matHeaderCellDef>Pallets</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.palletCount"></td>
        </ng-container> -->
        <ng-container matColumnDef="enteredBy">
          <th mat-header-cell *matHeaderCellDef>Entered By</th>
          <td mat-cell *matCellDef="let row">
            {{ row.CreatedBy | fullName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="rate">
          <th mat-header-cell *matHeaderCellDef>Rate</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="expandedElement.EvidenceBilling.rate"></td>
        </ng-container>
        <ng-container matColumnDef="schedule">
          <th mat-header-cell *matHeaderCellDef>Schedule</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="expandedElement.EvidenceBilling.BillingFrequency.name"></td>
        </ng-container>
        <ng-container matColumnDef="billable">
          <th mat-header-cell *matHeaderCellDef>Billable</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="expandedElement.EvidenceBilling.billable ? 'Y' : 'N'"></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="evidenceColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: evidenceColumns;"></tr>
      </table>

      <!-- Failed Invoice Data -->
      <h6 class="mt-5">Invoices, Failed &amp; Pending</h6>
      <table mat-table matSort [dataSource]="invoices">

        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
          <mat-cell *matCellDef="let entry"> {{ entry.createdAt | customDate: 'MM/dd/yyyy' }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="desc">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
          <mat-cell *matCellDef="let entry"> {{entry.details}} </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
          <mat-cell *matCellDef="let entry"> {{ calculateAmount(entry) }} </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="invoice_num">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Invoice # </mat-header-cell>
          <mat-cell *matCellDef="let entry"> {{ entry.invoiceNumber }} </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="qbErrors">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Import Errors </mat-header-cell>
          <mat-cell *matCellDef="let entry">
            <mat-accordion>
              <mat-expansion-panel *ngFor="let error of entry.QbErrors">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <strong [innerHtml]="error.statusCode"></strong>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p [innerHtml]="error.statusMessage"></p>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="qbSubmitted">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Exported? </mat-header-cell>
          <mat-cell *matCellDef="let entry">
            <strong *ngIf="entry.qbExported && !entry.QbErrors.length" class="text-success">Complete</strong>
            <strong *ngIf="(!entry.qbExported && !entry.qbTicketId) || (!entry.qbExported && entry.qbTicketId && !entry.QbErrors.length)" class="text-warning">Pending</strong>
            <strong *ngIf="entry.qbTicketId && entry.QbErrors.length" class="text-danger">Failed</strong>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef mat-sort-header> &nbsp; </mat-header-cell>
          <mat-cell *matCellDef="let entry">
            <ng-container *ngIf="!entry.qbExported">
              <button mat-icon-button (click)="resubmitInvoice(entry)">
                <mat-icon>refresh</mat-icon>
              </button>
              <button mat-icon-button (click)="openQbDialog(entry)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="deleteInvoice(entry)">
                <mat-icon>delete</mat-icon>
              </button>
            </ng-container>
            <button mat-icon-button (click)="openQbDialog(entry, true)">
              <mat-icon color="accent">list_alt</mat-icon>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <!-- Header and Row Declarations -->
        <mat-header-row *matHeaderRowDef="invoiceColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: invoiceColumns"></mat-row>
        <mat-footer-row class="totals-row" mat-footer-row *matFooterRowDef="invoiceColumns"></mat-footer-row>
      </table>
    </mat-card>
  </div>
</ng-template>

<!-- Progress Info -->
<ng-template #progressInfo>
  <div class="row d-flex content-wrapper align-items-stretch" *ngIf="expandedElement">
    <div class="col-4">
      <mat-card class="text-center h-100 p-5">
        <mat-card-content>
          <h4 class="mb-3">Services</h4>
          <mat-form-field>
            <mat-label>Assigned Staff</mat-label>
            <!-- Initialize with the first user in the list -->
            <mat-select (selectionChange)="filterServiceList($event.value)" [value]="expandedElement.InvestigationStaff[0].User.id">
              <mat-option *ngFor="let user of expandedElement.InvestigationStaff" [value]="user.User.id">
                {{ user.User | fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Service List -->
          <div class="text-left" *ngFor="let service of expandedElementFilteredServices">
            <mat-checkbox class="d-block" (change)="serviceCheckboxClicked($event, service)" [checked]="calcServiceComplete(service)">
              {{ service.Type.name }}
            </mat-checkbox>
            <!-- SubService List -->
            <ul class="unstyled-list" *ngIf="((service?.SubServices[0].Type.name !== service.Type.name && service?.SubServices.length === 1) || (service?.SubServices.length > 1))">
              <li *ngFor="let subService of service.SubServices">
                <mat-checkbox class="d-block" (change)="subServiceCheckboxClicked($event, subService.id)" [checked]="subService.isComplete">
                  {{ subService.Type.name }}
                </mat-checkbox>
              </li>
            </ul>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-8">
      <mat-card class="h-100 text-left p-5">
        <mat-card-content>
          <h4 class="mb-3">Reports</h4>
          <div class="row report-header">
            <div class="col-3">
              <p class="font-weight-bold">Report Name</p>
            </div>
            <div class="col-3">
              <p class="font-weight-bold">Status</p>
            </div>
            <div class="col-2">
              <p class="font-weight-bold">Updated</p>
            </div>
            <div class="col-3">
              <p class="font-weight-bold">Assigned To</p>
            </div>
          </div>
          <div class="row d-flex align-items-center report-info">
            <ng-container *ngFor="let doc of expandedElementReports">
              <div class="col-3">
                <p [innerHTML]="doc.title"></p>
              </div>
              <div class="col-3">
                <p>
                  <app-report-status-icon [snapshot]="doc?.History.slice(-1).pop()"></app-report-status-icon>
                </p>
              </div>
              <div class="col-2">
                <p [innerHTML]="doc.updatedAt | customDate: 'MM/dd/yyyy'">
                </p>
              </div>
              <div class="col-2">
                <p> {{ doc.CreatedBy | fullName }} </p>
              </div>
              <div class="col-2">
                <button mat-icon-button (click)="showLaunchReport(doc)" target="_blank">
                  <mat-icon color="accent">link</mat-icon>
                </button>
                <button mat-icon-button (click)="openReport(doc)">
                  <mat-icon color="accent">open_in_new</mat-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>
