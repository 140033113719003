<section class="content-part" id="investigation-metrics">
    <kendo-tabstrip #tabstrip [scrollable]="true" class="investigation-matrics-tabs" (tabSelect)="changeTab($event)">
        <kendo-tabstrip-tab [title]="TABS.BASIC" [selected]="selectedTab === TABS.BASIC">
            <ng-template kendoTabTitle>
                <span>Basic Charts</span>
            </ng-template>
            <ng-template kendoTabContent>
                <app-mobile-investigation-metrics-basic [tabIndex]="0"></app-mobile-investigation-metrics-basic>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="TABS.STATE" [selected]="selectedTab === TABS.STATE">
            <ng-template kendoTabTitle>
                <span>State</span>
            </ng-template>
            <ng-template kendoTabContent>
                <app-mobile-investigation-metrics-state-component [tabIndex]="1"></app-mobile-investigation-metrics-state-component>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</section>