<div [dialogRef]="dialog" (appClickOutside)="dialog.close(false)">
  <!-- Permissions -->
  <div [appEnforcePermission]="authConfig.companies" #companies="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.contactsCreateConfig" #contactsCreateConfig="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.contactsProfileContactInformationUpdate" #contactsProfileContactInformationUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesCreateConfig" #companiesCreateConfig="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.contactsProfileCompanyInformationUpdate" #contactsProfileCompanyInformationUpdate="appEnforcePermission"></div>
  <!-- Permissions -->
  <kendo-dialog-titlebar (close)="dialog.close(false)">
    <div class="modal-title">
      <span>Contact Information</span>
    </div>
  </kendo-dialog-titlebar>
  <section class="section-content">
    <form #contactForm="ngForm">
      <!-- Message -->
      <p *ngIf="data?.message" [innerHTML]="data?.message"></p>
      <!-- CONTACT INFO -->
      <section *ngIf="contactsProfileContactInformationUpdate?.enabled || contactsCreateConfig?.enabled">
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label [ngClass]="'asterisk'" text="Role"></kendo-label>
              <kendo-dropdownlist [data]="roles" textField="name" valueField="id" fillMode="outline" [valuePrimitive]="false"
                [(ngModel)]="contact.Role" [ngModelOptions]="{standalone: true}" name="role"
                (ngModelChange)="contact.companyNotRequired = $event.isPrivate" [disabled]="disableRoleSelect"
                [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" #role="ngModel" required
                [defaultItem]="!contact.Role?.id ? {id: null, name: 'Select Role'} : null">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span [ngClass]="dataItem?.id ? 'not-placeholder' : 'placeholder'"> {{ dataItem?.name}} </span>
                </ng-template>
              </kendo-dropdownlist>
              <kendo-formerror *ngIf="!role?.valid && (role?.dirty || role?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="role?.errors?.required">
                  Role cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item *ngIf="!contact.Role?.isPrivate">
            <kendo-formfield>
              <kendo-label text=""></kendo-label>
              <div class="k-checkbox-wrap">
                <input #notRequired kendoCheckBox type="checkbox" name="notRequired" [(ngModel)]="contact.companyNotRequired" />
                <kendo-label [for]="notRequired" class="k-checkbox-label font-weight-600 checkbox-margin"
                  text="Company Not Required"></kendo-label>
              </div>
            </kendo-formfield>
          </kendo-gridlayout-item>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
          <kendo-gridlayout-item *ngIf="isInsured()">
            <kendo-formfield>
              <kendo-label class="asterisk" text="Insured Name"></kendo-label>
              <kendo-textbox appNoWhiteSpace name="name" fillMode="outline" #insuredName="ngModel" [clearButton]="false"
                placeholder="Enter insured name" [(ngModel)]="contact.insuredName" required [maxlength]="255">
              </kendo-textbox>
              <kendo-formerror
                *ngIf="!insuredName?.valid && (insuredName?.dirty || insuredName?.touched) && insuredName?.errors?.required">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span>
                  Insured name cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="asterisk" text="First Name"></kendo-label>
              <kendo-textbox appNoWhiteSpace name="firstName" fillMode="outline" #firstName="ngModel" [clearButton]="false"
                placeholder="Enter First Name" [(ngModel)]="contact.firstName" required [maxlength]="255">
              </kendo-textbox>
              <kendo-formerror
                *ngIf="!firstName?.valid && (firstName?.dirty || firstName?.touched) && firstName?.errors?.required">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span>
                  First Name cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label text="Middle Name"></kendo-label>
              <kendo-textbox appNoWhiteSpace name="middleName" fillMode="outline" #middleName="ngModel" [clearButton]="false"
                placeholder="Enter Middle Name" [(ngModel)]="contact.middleName" [maxlength]="255">
              </kendo-textbox>
              <kendo-formerror
                *ngIf="!middleName?.valid && (middleName?.dirty || middleName?.touched) && middleName?.errors?.required">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span>
                  Middle Name cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="asterisk" text="Last Name"></kendo-label>
              <kendo-textbox appNoWhiteSpace name="lastName" fillMode="outline" #lastName="ngModel" [clearButton]="false"
                placeholder="Enter Last Name" [(ngModel)]="contact.lastName" required [maxlength]="255">
              </kendo-textbox>
              <kendo-formerror
                *ngIf="!lastName?.valid && (lastName?.dirty || lastName?.touched) && lastName?.errors?.required">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span>
                  Last Name cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label text="Suffix"></kendo-label>
              <kendo-textbox appNoWhiteSpace name="suffix" fillMode="outline" #suffix="ngModel" [clearButton]="false"
                placeholder="Enter Suffix" [(ngModel)]="contact.suffix" [maxlength]="255">
              </kendo-textbox>
              <kendo-formerror
                *ngIf="!suffix?.valid && (suffix?.dirty || suffix?.touched) && suffix?.errors?.required">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span>
                  Suffix cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label [text]="isInsured() ? 'Relationship / Title' : 'Nickname'"></kendo-label>
              <kendo-textbox appNoWhiteSpace name="suffix" fillMode="outline" #nickName="ngModel" [clearButton]="false"
                placeholder="Enter Nickname" [(ngModel)]="contact.nickName" [maxlength]="255">
              </kendo-textbox>
              <kendo-formerror
                *ngIf="!nickName?.valid && (nickName?.dirty || nickName?.touched) && nickName?.errors?.required">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span>
                  Nickname cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label [text]="'Quickbooks List Id'"></kendo-label>
              <kendo-textbox appNoWhiteSpace name="qbListId" fillMode="outline" #qbListId="ngModel" [clearButton]="false"
                placeholder="Enter Quickbooks List Id" [(ngModel)]="contact.qbListId" [maxlength]="255">
              </kendo-textbox>
              <kendo-formerror *ngIf="!qbListId?.valid && (qbListId?.dirty || qbListId?.touched) && qbListId?.errors?.required">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span>
                  Quickbooks List Id cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
          <ng-container *ngFor="let phone of contact.Phones; let i = index;">
            <app-phone class="mb-3" [(ngModel)]="contact.Phones[i]" [name]="'phone' + i" (isPrimary)="isPrimaryToggle($event, i)"
            (remove)="removePhone(i)"></app-phone>
          </ng-container>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
          <ng-container *ngFor="let email of contact.Emails; let i = index;">
            <app-email class="mb-3" [(ngModel)]="contact.Emails[i]" [name]="'email' + i" (remove)="removeEmail(i)"></app-email>
          </ng-container>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
          <ng-container *ngFor="let address of contact.Addresses; let i = index;">
            <app-address [(ngModel)]="contact.Addresses[i]" [address]="contact.Addresses[i]" [name]="'address' + i"
              [config]="{ privateContactType: contact.Role?.isPrivate, columnCount: 2, showRemove: true }"
              (addressChange)="contact.Addresses[i] = $event" (remove)="removeAddress(i)"></app-address>
          </ng-container>
        </kendo-gridlayout>
        
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
          <kendo-gridlayout-item>
            <button kendoButton class="mr-24" themeColor="primary" fillMode="link" iconClass="fas fa-plus" (click)="addPhone();">Add Phone</button>
            <button kendoButton class="mr-24" themeColor="primary" fillMode="link" iconClass="fas fa-plus" (click)="addEmail();">Add Email</button>
            <button kendoButton themeColor="primary" fillMode="link" iconClass="fas fa-plus" (click)="addAddress();">Add Address</button>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </section>

      <!-- Select Company / Branch -->
      <section *ngIf="!contact.companyNotRequired && (companies?.enabled && (companiesCreateConfig?.enabled || contactsProfileCompanyInformationUpdate?.enabled))">
        <section *ngIf="companies?.enabled">
          <h3>Select Company / Branch</h3>
          <div class="filters">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <app-state-select-kendo [label]="'State'" [placeholder]="'All'" (selectionChanged)="setStateFilter($event)">
                </app-state-select-kendo>
              </kendo-gridlayout-item>
              
              <kendo-gridlayout-item colSpan="2">
                <div class="kendo-formfield left-search-icon">
                  <kendo-label text=""></kendo-label>
                  <div style="position: relative;">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <kendo-textbox
                      name="company"
                      [(ngModel)]="filters.company"
                      (valueChange)="setCompanyFilter()"
                      placeholder="Search by company"
                      fillMode="outline"
                      [clearButton]="false">
                    </kendo-textbox>
                  </div>
                </div>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item class="text-right">
                <kendo-label text=""></kendo-label>
                <div>
                  <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="search()">Apply Filters</kendo-button>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </div>

          <kendo-grid class="company-branch-list-contact border-none-tr-td"
          *ngIf="!selectedBranch; else branchList"
          [data]="{
            data: (this.dataSource.contents$ | async) || [],
            total: (dataSource?.pageInfo$ | async)?.totalCount
          }">
          
            <kendo-grid-column
            field="Company"
            title="Company"
            [width]="20"
            [resizable]="false"
            [sortable]="false"
            [headerClass]="'no-cursor'"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.name || '-' }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            field="Branch"
            title="Branch / Phone Number"
            [width]="80"
            [resizable]="false"
            [sortable]="false"
            [headerClass]="'no-cursor'"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
              <table>
                <tr *ngFor="let branch of dataItem.Branches">
                  <td width="30%">
                    <ng-container *ngIf="branch.Addresses?.length else noValue">
                      <div class="d-flex-left-align" *ngFor="let address of branch.Addresses">
                        <button class="mr-16" kendoButton themeColor="primary" fillMode="link" iconClass="fas fa-plus"
                          (click)="selectBranch(branch)"></button>
                        <span>{{ address.city }}, {{ address.state }}</span>
                      </div>
                    </ng-container>
                  </td>
                  <td width="70%">
                    <div class="d-flex-left-align">
                      <ng-container *ngIf="branch.Phones?.length else noValue">
                        <app-email-phone-view class="table-phone" *ngFor="let phone of branch.Phones" [phone]="phone"></app-email-phone-view>
                      </ng-container>
                    </div>
                  </td>
                </tr>
              </table>
            </ng-template>
            </kendo-grid-column>
          </kendo-grid>    
        </section>

        <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']" *ngIf="companiesCreateConfig?.enabled">
          <kendo-gridlayout-item>
            <button kendoButton class="mr-24" themeColor="primary" fillMode="link" iconClass="fas fa-plus" (click)="addNewCompany();">Add New Company</button>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </section>

      <ng-template #branchList>
        <ng-container *ngIf="selectedBranch">
          <div class="d-flex-end">
            <button kendoButton themeColor="secondary" fillMode="link" iconClass="fa-solid fa-circle-xmark fa-lg"
              (click)="selectedBranch = null"></button>
          </div>
          <ng-container *ngTemplateOutlet="branchCard; context: { assignment: selectedBranch }"></ng-container>
        </ng-container>
      </ng-template>
      <ng-container *ngFor="let assignment of contact.BranchAssignments">
        <ng-container *ngTemplateOutlet="branchCard; context: { assignment: assignment }"></ng-container>
      </ng-container>
    </form>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="dialog.close()"
      *ngIf="!this.data?.disableCancel">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="save()"
      [disabled]="!hasCompany() || !contactForm?.valid">Save
    </button>
  </kendo-dialog-actions>
</div>
<ng-template #branchCard let-assignment="assignment">

  <kendo-card [width]="'100%'">
    <kendo-card-body>
      
      <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <h6 class="kendo-caption"><small>Company Name</small></h6>
          <h6 class="kendo-caption-body">
            <a class="linkmd" (click)="dialogRef.close()"
              [routerLink]="['/admin', 'companies', 'management', assignment.Branch.Company.id]" [queryParams]="{tab: 'company'}">{{
              assignment.Branch.Company.name }}</a>
          </h6>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <h6 class="kendo-caption"><small>Branch Name</small></h6>
          <h6 class="kendo-caption-body">
            <a class="linkmd" (click)="dialogRef.close()"
              [routerLink]="['/admin', 'companies', 'management', assignment.Branch.Company.id]"
              [queryParams]="{tab: 'branches', branchId: assignment.Branch.id}">{{ assignment.Branch.name }}</a>
          </h6>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <h6 class="kendo-caption"><small>Primary Address: ({{ (assignment.Branch.Addresses | primaryAssociation)?.Type?.name ? (assignment.Branch.Addresses | primaryAssociation)?.Type?.name : 'N/A' }})</small></h6>
          <h6 class="kendo-caption-body">
            <ng-container *ngIf="assignment.Branch.Addresses?.length else noValue">
              <app-address-view class="mb-3" [address]="(assignment.Branch.Addresses | primaryAssociation)"></app-address-view>
            </ng-container>
          </h6>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <h6 class="kendo-caption"><small>Phones</small></h6>
          <h6 class="kendo-caption-body">
            <ng-container *ngIf="assignment.Branch.Phones?.length else noValue">
              <app-email-phone-view class="mb-3" *ngFor="let companyPhone of assignment.Branch.Phones;" [phone]="companyPhone"></app-email-phone-view>
            </ng-container>
          </h6>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <h6 class="kendo-caption"><small>Emails</small></h6>
          <h6 class="kendo-caption-body">
            <ng-container *ngIf="assignment.Branch.Emails?.length else noValue">
              <app-email-phone-view class="mb-3" *ngFor="let companyEmail of assignment.Branch.Emails;" [email]="companyEmail"></app-email-phone-view>
            </ng-container>
          </h6>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <h6 class="kendo-caption"><small>Start Date / End Date</small></h6>
          <div style="display: flex; justify-content: space-between;">
            <div class="kendo-formfield" style="width: 160px;">
              <kendo-datepicker
                  id="startDate"
                  name="startDate"
                  #startDate="ngModel"
                  calendarType="classic"
                  [fillMode]="'outline'"
                  placeholder="Start"
                  format="MM/dd/yyyy"
                  [(ngModel)]="assignment.startDate"
                  formatPlaceholder="formatPattern"
                  required="true"
              ></kendo-datepicker>
              <kendo-formerror *ngIf="!startDate?.valid && (startDate?.dirty || startDate?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="startDate?.errors?.required">
                  StartDate cannot be left blank
                </span>
                <span *ngIf="!startDate?.errors?.required && startDate?.errors">
                  StartDate is invalid
                </span>
              </kendo-formerror>
            </div>
            <div class="kendo-formfield" style="width: 160px;">
              <kendo-datepicker
                  id="endDate"
                  name="endDate"
                  #endDate="ngModel"
                  calendarType="classic"
                  [fillMode]="'outline'"
                  placeholder="End"
                  format="MM/dd/yyyy"
                  [(ngModel)]="assignment.endDate"
                  formatPlaceholder="formatPattern"
              ></kendo-datepicker>
              <kendo-formerror *ngIf="!endDate?.valid && (endDate?.dirty || endDate?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="endDate?.errors?.required">
                  EndDate cannot be left blank
                </span>
                <span *ngIf="!endDate?.errors?.required && endDate?.errors">
                  EndDate is invalid
                </span>
              </kendo-formerror>
            </div>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </kendo-card-body>
  </kendo-card>
</ng-template>
<ng-template #noValue>
  <p>-</p>
</ng-template>