<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationCreate" #investigationCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationView" #staffContactInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationOwnView" #staffContactInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationView" #staffUserInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationOwnView" #staffUserInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationView" #staffEmergencyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationOwnView" #staffEmergencyInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.downloadMobileApplicationAll" #downloadMobileApplicationAll="appEnforcePermission"></div>
<!-- Permissions -->

<section class="d-flex justify-content-between header">
  <img class="img-fluid align-self-center" src="assets/logo.png" />
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <div class="d-flex justify-content-center align-items-center">
        <!-- Change this to the real first and last name once we have that information-->
        <button mat-button [matMenuTriggerFor]="logoutMenu" xPosition="after" *ngIf="user">
          <span> Welcome, {{ user | fullName }} </span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </div>
    </div>
    <mat-menu #logoutMenu>
      <button mat-menu-item type="button" *ngIf="staffContactInformationView?.enabled
      || staffUserInformationView?.enabled
      || staffEmergencyInformationView?.enabled
      || staffContactInformationOwnView?.enabled
      || staffContactInformationOwnView?.enabled
      || staffUserInformationOwnView?.enabled
      || staffEmergencyInformationOwnView?.enabled" [routerLink]="['/profile', user?.id]">Profile</button>
      <button mat-menu-item type="button" class="float-right" [routerLink]="['/admin/download']" *ngIf="downloadMobileApplicationAll?.enabled" [hidden]="!user">Download Mobile App</button>
      <button mat-menu-item type="button" class="float-right" (click)="logout()" [hidden]="!user">Logout</button>
    </mat-menu>
    <!-- <button mat-icon-button (click)="newNotifs = false" [matMenuTriggerFor]="notificationMenu">
      <mat-icon [ngStyle]="!newNotifs ? {'color': 'rgb(33,37,41)'}: {'color':'red'}" [matBadge]="notifications.length" matBadgeColor="rgb(33,37,41)" [matBadgeHidden]="!notifications.length">notification_important</mat-icon>
    </button>
    <mat-menu #notificationMenu>
      <div class="d-flex menu-header px-3 py-2 justify-content-center align-items-center">
        <h6 class="flex-2 text-right">Notifications</h6>
        <div class="flex-1 text-right">
          <button mat-icon-button (click)="goToNotifications(true)">
            <mat-icon>settings</mat-icon>
          </button>
        </div>
      </div>
      <span mat-menu-item *ngFor="let alert of showNotifications" (click)="goToNotifications(false)">
        <app-alert-icon [alert]="alert.severity"></app-alert-icon>
        {{ alert.title }}
      </span>
      <div mat-menu-item (click)="goToNotifications(false)" class="view-notifications d-flex py-3 justify-content-center align-items-center">
        <span>View All</span>
      </div>
    </mat-menu> -->
    <div class="notifications">
      <span class="unread" *ngIf="notifications?.length"></span>
      <kendo-button class="toggle-notification" #anchor (click)="toggle(!show)" kendoButton themeColor="base" fillMode="link" size="medium"
        iconClass="fa-solid fa-bell fa-xl">
      </kendo-button>
      <kendo-popup
        #popup
        [anchor]="anchor.element"
        [anchorAlign]="anchorAlign"
        [popupAlign]="popupAlign"
        *ngIf="show"
      >
        <div class="notifications-content">
          <kendo-gridlayout
            *ngIf="!loading"
            [rows]="['auto']"
            [cols]="['100%']"
          >
            <kendo-gridlayout-item class="mb-0" *ngIf="notifications?.length">
              <kendo-listview
                [data]="notifications"
                class="notifications-list"
                containerClass="k-d-flex k-flex-col k-flex-nowrap"
              >
                  <ng-template kendoListViewHeaderTemplate>
                    <div class="notification-header">
                      <span>Notifications ({{getCount}} unread)</span>
                      <!-- <i class="close-notification fa-solid fa-xmark fa-lg" (click)="toggle(!show)"></i> -->
                    </div>
                  </ng-template>
                  <ng-template
                    kendoListViewItemTemplate
                    let-dataItem="dataItem"
                    let-isLast="isLast"
                  >
                    <div class="content-details">
                      <div class="content">
                        <div>
                          <i [class]="getIcon(dataItem?.Type?.entity)"></i>
                          <a href="javascript:void(0)" (click)="viewPage(dataItem)" [innerHTML]="dataItem?.message"></a>
                        </div>
                        <!-- START UNREAD -->
                        <span class="unread" kendoTooltip
                        tooltipClass="tooltipwidthauto"
                        position="left"
                        title="Mark as read"
                        (click)="markNotificationsAsReadOrUnread($event, true, dataItem?.id)"
                        *ngIf="!dataItem?.viewed"><i class="fa-solid fa-circle fa-2xs read-unread"></i></span>
                        <!-- END UNREAD -->
                         <!-- START READ -->
                        <span class="read" kendoTooltip
                        tooltipClass="tooltipwidthauto"
                        position="left"
                        title="Mark as Unread"
                        (click)="markNotificationsAsReadOrUnread($event, false, dataItem?.id)"
                        *ngIf="dataItem?.viewed"><i class="fa-solid fa-check read-unread"></i></span>
                        <!-- END READ -->
                      </div>
                      <div class="sub-content">
                        <div class="name-page">{{dataItem?.Sender ? (dataItem?.Sender | fullName) : ''}}<span class="color-hide" *ngIf="dataItem?.Sender">&nbsp;|&nbsp;</span>{{dataItem?.Type?.entity | quiet}}</div>
                        <div class="time">{{dataItem?.createdAt | customDate : "MM/dd/yyyy"}} at {{dataItem?.createdAt | customDate : "h:mm a"}}</div>
                      </div>
                    </div>
                    <div class="content-info" *ngIf="isLast">
                      <span><i class="fa-solid fa-flag"></i></span>
                      <p>That’s all your most recent notifications. To view all your notifications, click on “View All” below.</p>
                    </div>
                  </ng-template>
                  <ng-template kendoListViewFooterTemplate>
                    <div class="notification-footer">
                      <button
                        kendoButton
                        class="mr-24"
                        themeColor="primary"
                        fillMode="link"
                        (click)="redirecToList()"
                      >
                      View All
                      </button>
                    </div>
                  </ng-template>
              </kendo-listview>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0" *ngIf="!notifications?.length">
              <kendo-listview
                [data]="[{id : null}]"
                [height]="220"
                class="notifications-list no-notifications"
                containerClass="k-d-flex k-flex-col k-flex-nowrap"
              >
                  <ng-template kendoListViewHeaderTemplate>
                    <div class="notification-header">
                      <span>Notifications</span>
                      <!-- <i class="close-notification fa-solid fa-xmark fa-lg" (click)="toggle(!show)"></i> -->
                    </div>
                  </ng-template>
                  <ng-template
                    kendoListViewItemTemplate
                    let-dataItem="dataItem"
                  >
                    <div class="content-details">
                      <div class="no-notification">
                        <span>0 Unread Notifications</span>
                        <p class="mb-0">You’re all caught up! Looks like you don’t have any unread notifications.</p>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template kendoListViewFooterTemplate>
                    <div class="notification-footer">
                      <button
                        kendoButton
                        class="mr-24"
                        themeColor="primary"
                        fillMode="link"
                        [routerLink]="['/notifications']"
                        (click)="redirecToList()"
                      >
                      View All
                      </button>
                    </div>
                  </ng-template>
              </kendo-listview>
            </kendo-gridlayout-item>
            </kendo-gridlayout>
            <kendo-gridlayout *ngIf="loading"
                [rows]="['auto']"
                [cols]="['100%']"
              >
                <kendo-gridlayout-item class="mb-0">
                  <div  class="k-loading-panel">
                    <div class="k-loading-panel-mask"></div>
                    <div class="k-loading-panel-wrapper">
                      <kendo-loader
                        type="infinite-spinner"
                        themeColor="primary"
                        size="large"
                      >
                      </kendo-loader>
                      <div class="k-loading-panel-text">Loading notifications</div>
                    </div>
                  </div>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>
      </kendo-popup>
    </div>
    <button mat-icon-button routerLink="/time/management">
      <mat-icon>schedule</mat-icon>
    </button>
    <div>
      <button mat-icon-button (click)="showSearch = !showSearch">
        <mat-icon>search</mat-icon>
      </button>
      <!-- Lower Content -->
      <ng-container *ngIf="showSearch">
        <mat-form-field>
          <mat-label>Nefco # Search</mat-label>
          <input matInput id="nefcoSearch" [(ngModel)]="nefcoNumberSearch" (ngModelChange)="onChangeUpdateFiltes()" (keydown.enter)="goToInvestigations()" />
          <button mat-icon-button disableRipple matSuffix (click)="nefcoNumberSearch = null; goToInvestigations()">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="ml-2">
          <mat-label>Policy / Claim # Search</mat-label>
          <input matInput id="claimSearch" [(ngModel)]="policyClaimNumberSearch" (ngModelChange)="onChangeUpdateFiltes()" (keydown.enter)="goToInvestigations()" />
          <button mat-icon-button disableRipple matSuffix (click)="policyClaimNumberSearch = null; goToInvestigations()">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </ng-container>
    </div>
    <button mat-button class="investigation-button ml-3" routerLink="/admin/investigations/create" *ngIf="investigationCreate.enabled">
      Create Investigation
    </button>
  </div>
</section>
