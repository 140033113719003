import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IApiInvestigationMetricsBillToCompanyConnection, IApiInvestigationMetricsFilter } from '../../../../types/types';
import { IGqlBaseOptions } from '../../../../interfaces';
import { investigationMetricsBillToCompanyConnection } from './investigation-metrics-billto-company.operations';
import { connectionQuery } from '../../../../helpers.class';

@Injectable()
export class GqlInvestigationMetricsBillToCompanyService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiInvestigationMetricsFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationMetricsBillToCompanyConnection> {
    return this.apollo.query(connectionQuery(investigationMetricsBillToCompanyConnection, filters, options)).pipe(
      safeResolve(true)
    );
  }

}
