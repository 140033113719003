import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { safeResolve } from "../../rxjs";
import { IApiAddEmailInput, IApiEmail, IApiRemoveEmailInput } from '../../types/types';
import { addEmailMutation, removeEmailMutation } from './email.operation';

@Injectable()
export class GqlEmailService {
  public constructor(
    private apollo: Apollo
  ) { }

  public addEmail(input: IApiAddEmailInput): Observable<IApiEmail> {
    return this.apollo.mutate({
      mutation: addEmailMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public deleteEmail(input: IApiRemoveEmailInput) {
    return this.apollo.mutate({
      mutation: removeEmailMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

}