import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { updateEvidenceBillingMutation } from './evidence-billing.operations';
import {
  IApiEvidenceBillingFilter,
  IApiAddEvidenceBillingInput,
  IApiEvidenceBillingConnection,
  IApiUpdateEvidenceBillingInput,
  IApiRemoveEvidenceBillingInput,
  IApiEvidenceBilling
} from 'src/app/shared/modules/graphql/types/types';
import {
  evidenceBillingConnectionQuery,
  addEvidenceBillingMutation,
  removeEvidenceBillingMutation
} from './evidence-billing.operations';

@Injectable()
export class GqlEvidenceBillingService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getEvidenceBilling(filters: IApiEvidenceBillingFilter[], options: IGqlBaseOptions): Observable<IApiEvidenceBillingConnection> {
    return this.apollo.query(connectionQuery(evidenceBillingConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addEvidenceBilling(input: IApiAddEvidenceBillingInput): Observable<IApiEvidenceBilling> {
    return this.apollo.mutate({
      mutation: addEvidenceBillingMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeEvidenceBilling(input: IApiRemoveEvidenceBillingInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeEvidenceBillingMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateEvidenceBilling(input: IApiUpdateEvidenceBillingInput): Observable<IApiEvidenceBilling> {
    return this.apollo.mutate({
      mutation: updateEvidenceBillingMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
