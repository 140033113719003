<div [dialogRef]="dialog" (appClickOutside)="dialog.close(false)">
  <kendo-dialog-titlebar (close)="dialog.close(false)">
    <div class="modal-title">
      <span>Assign Staff to Permission Role</span>
    </div>
  </kendo-dialog-titlebar>
  <section>
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon">
          <kendo-label text=""></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox
              name="search"
              [(ngModel)]="searchValue"
              (appDebouncedInputChange)="load(searchValue)"
              (ngModelChange)="!searchValue ? load(null) : null"
              placeholder="Search"
              fillMode="outline"
              [clearButton]="true">
            </kendo-textbox>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
      <kendo-gridlayout-item  *ngFor="let user of selectedUsers">
        <kendo-chip class="fw600 mr-16" [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'"
        [removable]="true" (remove)="removeUser(user)">
        {{ user.lastName }}, {{ user.firstName }}
      </kendo-chip>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-grid
      class="assigned-staff-to-permission-role-list"
      [data]="{
        data: (dataSource?.contents$ | async) || [],
        total: (dataSource?.pageInfo$ | async)?.totalCount
      }"
      [pageSize]="pageOptions?.limit"
      [skip]="pageOptions?.offset"
      [pageable]="{
        buttonCount: 6,
        type: 'numeric',
        pageSizes: [5, 10, 25, 100],
        previousNext: true,
        responsive: true,
        position: 'bottom'
      }"
      (pageChange)="pageOptions?.paginate($event)"
      [sortable]="true"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      [navigatable]="false"
    >

      <kendo-grid-column
        field="Select"
        title="Select"
        [width]="5"
        [sortable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <input 
          type="checkbox"
          kendoCheckBox
          (change)="toggleUser(dataItem)" 
          [checked]="!!selected[dataItem.id]" />
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="orderByOptions.Lastname"
        title="Name"
        [width]="15"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.lastName}}, {{dataItem.firstName}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="'Positions'"
        title="Position(s)"
        [width]="10"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem?.Positions?.length else noValue">
            <p *ngFor="let role of dataItem?.Positions" [innerHTML]="role.title | quiet"></p>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="'Location'"
        title="Location"
        [width]="15"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{getStateName((dataItem?.Addresses | primaryAssociation)?.state)?.text || '-'}}
        </ng-template>
      </kendo-grid-column>

      
      <kendo-grid-column
        [field]="orderByOptions.Email"
        title="Email"
        [width]="15"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ (dataItem.Emails | primaryAssociation)?.address || '-'}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="'PermissionRole'"
        title="Permission Role"
        [width]="10"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.PermissionRoles?.length else noValue">
            <p *ngFor="let role of dataItem.PermissionRoles">{{ role.name }}</p>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="'Phone'"
        title="Phone"
        [width]="10"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ (dataItem.Phones | primaryAssociation)?.number || '-'}}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </section>

  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="dialog.close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="assignUsers()"
      [disabled]="!selectedUsers.length">Assign Users
    </button>
  </kendo-dialog-actions>
</div>
<ng-template #noValue>
  <p>-</p>
</ng-template>