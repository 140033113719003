export enum CompanyTypes {
  INSURANCE_COMPANY = "INSURANCE_COMPANY",
  LAW_FIRM = "LAW_FIRM",
}

export enum CompanySortFields {
  LOSS_DATE = "LOSS_DATE",
  CREATED_AT = "CREATED_AT",
  NAME = "NAME"
}

export enum CompanyFilter {
  STATE = "STATE",
  COMPANY_TYPE = "COMPANY_TYPE"
}
