import { SharedReportService } from './shared-report.service';
import { ApiConnection } from '../../../decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiDocumentSentFilter, IApiDocumentShare } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { IApiDocumentSentRecords } from 'src/app/components/shared/investigations/investigation-documents/investigation-documents.component';

export class SharedReportDataSource extends NefcoPagedDataSource<IApiDocumentSentRecords, SharedReportService> implements DataSource<IApiDocumentSentRecords> {
  @ApiConnection()
  public load(filters?: IApiDocumentSentFilter[]): Promise<any> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
