import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlBillingRuleTypeService } from 'src/app/shared/modules/graphql/services';
import { IApiAddBillingRuleInput, IApiAddBillingRuleTypeInput, IApiBillingRuleType, IApiBillingRuleTypeConnection, IApiBillingRuleTypeFilter, IApiUpdateBillingRuleInput } from 'src/app/shared/modules/graphql/types/types';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';

@Injectable()
export class BillingRuleTypeService {
  constructor(private service: GqlBillingRuleTypeService) { }

  public get(filters: IApiBillingRuleTypeFilter[] = [], options?: IGqlBaseOptions): Observable<IApiBillingRuleTypeConnection> {
    return this.service.get(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddBillingRuleTypeInput): Observable<IApiBillingRuleType> {
    return this.service.add(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.service.remove(id);
  }

  public update(input: IApiUpdateBillingRuleInput): Observable<IApiBillingRuleType> {
    return this.service.update(input);
  }

  public getById(id: string): Observable<IApiBillingRuleType> {
    return this.service.getById(id);
  }
}
