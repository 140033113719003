import { IApiAddress, IApiContact, IApiUpdateAddressInput, IApiUpdateInvestigationInput } from './../../../../shared/modules/graphql/types/types';
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { filter } from 'rxjs/operators';
import { RiskTypesService, ContactService, InvestigationService } from 'src/app/shared/services';
import { InvestigationLossAddressCheckTableComponent } from "../investigation-loss-address-check-table/investigation-loss-address-check-table.component";
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: "app-investigation-loss-address-check-modal",
  templateUrl: "./investigation-loss-address-check-modal.component.html",
  styleUrls: ["./investigation-loss-address-check-modal.component.scss"],
})
export class InvestigationLossAddressCheckModalComponent extends DialogContentBase implements OnInit {

  @ViewChild("lossAddressTable") lossAddressTable: InvestigationLossAddressCheckTableComponent;
  @Input() public data: IApiUpdateInvestigationInput;
  public riskTypeName: string;
  public insured: IApiContact;
  public selectedInvestigationId: string;

  /* Markers */
  private _markers: [number, number, string][];
  public get markers(): [number, number, string][] {
    return this._markers;
  }
  public set markers(value) {
    this._markers = value;
  }

  constructor(
    private riskTypesService: RiskTypesService,
    private contacts: ContactService,
    private investigationService: InvestigationService,
    public dialogRef: DialogRef,
  ) {
    super(dialogRef)
  }

  ngOnInit() {
    this.riskTypesService.riskTypeById(this.data.RiskTypeId).pipe(filter(r => !!r)).subscribe((riskType) => {
      this.riskTypeName = riskType.name;
    });

    if (this.data.InsuredId) this.contacts.getById(this.data.InsuredId).pipe(filter(r => !!r)).subscribe((contact) => this.insured = contact);
  }

  public accept() {
    if (this.data.id) {
      const savedModel = {
        id: this.data.id,
        LossAddress: this.data.LossAddress,
        conflictCheckRun: true
      };
      // Format Addresses, else throwing error
      delete (savedModel.LossAddress as IApiAddress)?.Type;

      this.investigationService.update(savedModel as IApiUpdateInvestigationInput).subscribe(() => {
        this.dialogRef.close({ lossAddressTable: this.lossAddressTable.investigations, conflictCheckRun: true, lossAddress: savedModel.LossAddress });
      });
    } else {
      this.dialogRef.close({ lossAddressTable: this.lossAddressTable.investigations, conflictCheckRun: true, lossAddress: this.data.LossAddress });
    }
  }

  public reject() {
    this.dialogRef.close(null);
  }

}
