import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { investigationStaffConnectionQuery, addInvestigationStaffMutation, removeInvestigationStaffMutation, assignInvestigatorMutation, updateInvestigationStaffMutation, sendSMSToInvestigatorMutation } from './investigation-staff.operations';
import { IApiAssignInvestigatorInput, IApiInvestigationStaffConnection, IApiSendSmsToInvestigator, IApiSendSmsToInvestigatorInput } from '../../../types/types';

import {
  IApiInvestigationStaffFilter,
  IApiAddInvestigationStaffInput,
  IApiUpdateInvestigationStaffInput
} from '../../../types/types';
import { safeResolve } from '../../../rxjs';

@Injectable()
export class GqlInvestigationStaffService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getInvestigationStaff(filters: IApiInvestigationStaffFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationStaffConnection> {
    return this.apollo.query(connectionQuery(investigationStaffConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addInvestigationStaff(input: IApiAddInvestigationStaffInput) {
    return this.apollo.mutate({
      mutation: addInvestigationStaffMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeInvestigationStaff(id: string) {
    return this.apollo.mutate({
      mutation: removeInvestigationStaffMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateInvestigationStaff(input: IApiUpdateInvestigationStaffInput) {
    return this.apollo.mutate({
      mutation: updateInvestigationStaffMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public assignInvestigator(input: IApiAssignInvestigatorInput) {
    return this.apollo.mutate({
      mutation: assignInvestigatorMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public sendSMSToInvestigator(input: IApiSendSmsToInvestigatorInput): Observable<IApiSendSmsToInvestigator> {
    return this.apollo.mutate({
      mutation: sendSMSToInvestigatorMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
