<kendo-formfield>
  <kendo-dropdownlist [data]="priorities" name="InvestigationPriorityId" #type="ngModel" [(ngModel)]="priorityId" (valueChange)="handleSelectionChange($event)"
    [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
    [defaultItem]="{id: null, name: 'Select Priority'}"
    [popupSettings]="{appendTo: 'component', animate: false}">
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem.name }} </span>
    </ng-template>
  </kendo-dropdownlist>
</kendo-formfield>
