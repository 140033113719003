import { connectionQuery } from './../../helpers.class';
import { ExpenseConnectionQuery, addExpenseMutation, removeExpenseMutation, updateExpenseMutation, getExpenseMileageTotal, expenseReportTotal, getExpenseReportTotalAll, expenseSubmitReportMutation, markAllSelectedAsPaidMutation, markSelectedAsPaidMutation, generateReportXMLQuery, bookkeepingExpenseReportTotal } from './expense.operations';
import { IApiAddExpenseInput, IApiExpense, IApiExpenseFilter, IApiExpenseFilterType, IApiExpenseMileageTotal, IApiExpenseReportTotal, IApiGenerateReportXmlInput, IApiMarkAllExpensesAsPaid, IApiMarkSelectedExpensesAsPaid, IApiUpdateExpenseInput } from './../../types/types';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../interfaces/base.interfaces';
import { IApiExpenseConnection } from "../../types/types";
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { IApiExpenseSubmitReport } from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlExpenseService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getExpenses(filters: IApiExpenseFilter[], options: IGqlBaseOptions): Observable<IApiExpenseConnection> {
    const viewType = filters?.find(obj => obj.type === IApiExpenseFilterType.ViewBookkeepingView || obj.type === IApiExpenseFilterType.ViewBookkeepingReport);
    return this.apollo.query(connectionQuery(ExpenseConnectionQuery(viewType), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getExpenseMileageTotal(filters: IApiExpenseFilter[], options: IGqlBaseOptions): Observable<IApiExpenseMileageTotal> {
    return this.apollo.query(connectionQuery(getExpenseMileageTotal, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getBookkeepingExpenseReportTotal(filters: IApiExpenseFilter[], options: IGqlBaseOptions): Observable<IApiExpenseReportTotal> {
    return this.apollo.query(connectionQuery(bookkeepingExpenseReportTotal, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getExpenseReportTotal(filters: IApiExpenseFilter[], options: IGqlBaseOptions): Observable<IApiExpenseReportTotal> {
    return this.apollo.query(connectionQuery(expenseReportTotal, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getExpenseReportTotalAll(filters: IApiExpenseFilter[], options: IGqlBaseOptions): Observable<string> {
    return this.apollo.query(connectionQuery(getExpenseReportTotalAll, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addExpense(input: IApiAddExpenseInput): Observable<IApiExpense> {

    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    if (input?.expenseDate) input.expenseDate = NefcoDateHelper.toUtc(input.expenseDate, 12);
    if (input?.authorizedDate) input.authorizedDate = NefcoDateHelper.toUtc(input.authorizedDate, 12);

    return this.apollo.mutate({
      mutation: addExpenseMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public expenseSubmitReport(input: IApiExpenseSubmitReport): Observable<IApiExpenseSubmitReport> {

    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    return this.apollo.mutate({
      mutation: expenseSubmitReportMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeExpense(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeExpenseMutation,
      variables: { input: id }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateExpense(input: IApiUpdateExpenseInput): Observable<IApiExpense> {

    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    if (input?.expenseDate) input.expenseDate = NefcoDateHelper.toUtc(input.expenseDate, 12);
    if (input?.authorizedDate) input.authorizedDate = NefcoDateHelper.toUtc(input.authorizedDate, 12);

    return this.apollo.mutate({
      mutation: updateExpenseMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public markSelectedPaid(input?: IApiMarkSelectedExpensesAsPaid): Observable<boolean> {
    return this.apollo.mutate({
      mutation: markSelectedAsPaidMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public markAllSelectedAsPaid(input?: IApiMarkAllExpensesAsPaid): Observable<boolean> {
    return this.apollo.mutate({
      mutation: markAllSelectedAsPaidMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public generateReportXML(input?: IApiGenerateReportXmlInput): Observable<string> {
    return this.apollo.query({
      query: generateReportXMLQuery,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
