import { IApiEvidenceBilling, IApiEvidenceBillingConnection } from './../../../modules/graphql/types/types';
import { GqlEvidenceBillingService } from 'src/app/shared/modules/graphql/services/evidence/evidence-billing/evidence-billing.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import {
  IApiAddEvidenceBillingInput,
  IApiEvidenceBillingFilter,
  IApiUpdateEvidenceBillingInput,
  IApiRemoveEvidenceBillingInput
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';

@Injectable()
export class EvidenceBillingService {

  constructor(
    private gqlEvidenceBillingService: GqlEvidenceBillingService
  ) { }

  public get(filters?: IApiEvidenceBillingFilter[], options?: IGqlBaseOptions): Observable<IApiEvidenceBillingConnection> {
    return this.gqlEvidenceBillingService.getEvidenceBilling(filters, options || new ListPage());
  }

  public add(input: IApiAddEvidenceBillingInput): Observable<IApiEvidenceBilling> {

    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    if (input?.startDate) input.startDate = NefcoDateHelper.toUtcStartOfDay(input.startDate);
    
    return this.gqlEvidenceBillingService.addEvidenceBilling(input);
  }

  public remove(input: IApiRemoveEvidenceBillingInput): Observable<boolean> {
    return this.gqlEvidenceBillingService.removeEvidenceBilling(input);
  }

  public update(input: IApiUpdateEvidenceBillingInput): Observable<IApiEvidenceBilling> {
    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    if (input?.startDate) input.startDate = NefcoDateHelper.toUtcStartOfDay(input.startDate);

    return this.gqlEvidenceBillingService.updateEvidenceBilling(input);
  }

}
