import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IApiQuarterlyHourReportConnection, IApiQuarterlyUtilizationReportFilter, IApiQueryQuarterlyHourReportCsvArgs } from "../../../types/types";
import { IGqlBaseOptions } from "../../../interfaces";
import { generateQuarterlyHourReportCSV, quarterlyHourReportConnectionQuery } from "./quarterly-hour-report.operations";
import { connectionQuery } from "../../../helpers.class";

@Injectable()
export class GqlQuarterlyHourReportService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiQuarterlyUtilizationReportFilter[], options: IGqlBaseOptions): Observable<IApiQuarterlyHourReportConnection> {
    return this.apollo.query(connectionQuery(quarterlyHourReportConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public csvReport(input: IApiQueryQuarterlyHourReportCsvArgs): Observable<string>{
    return this.apollo.query({
      query: generateQuarterlyHourReportCSV,
      variables: { ...input }
    }).pipe(
      safeResolve(true)
    );
  }

}
