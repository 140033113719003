<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>{{ data?.id ? "Edit" : "Add" }} Setting</span>
    </div>
  </kendo-dialog-titlebar>
  <section class="section-content">
    <form #form="ngForm" name="form">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Key"></kendo-label>
            <kendo-textbox id="key" appNoWhiteSpace name="key" fillMode="outline" #key="ngModel" [clearButton]="false"
              placeholder="Enter Key" [(ngModel)]="model.key" required cdkFocusInitial [disabled]="data?.id ? true : false"></kendo-textbox>
            <kendo-formerror *ngIf="!key?.valid && (key?.dirty || key?.touched) && key?.errors?.required">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span>
                Key cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Value"></kendo-label>
            <kendo-textbox appNoWhiteSpace name="Value" fillMode="outline" #value="ngModel" [clearButton]="false"
              placeholder="Enter Value" [(ngModel)]="model.value" required cdkFocusInitial></kendo-textbox>
            <kendo-formerror *ngIf="!value?.valid && (value?.dirty || value?.touched) && value?.errors?.required">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span>
                Value cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSubmit()"
      [disabled]="!form.valid">Save
    </button>
  </kendo-dialog-actions>
</div>
