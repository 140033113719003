import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { CompanyService } from 'src/app/shared/services/company/company.service';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { StateService } from 'src/app/shared/services/state.service';
import { IApiCompany, IApiCompanyFilter, IApiCompanyFilterType, IApiCompanyOrderBy, IApiCompanyType, IApiCompanyTypeOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { CompanyDataSource } from 'src/app/shared/services/company/company.datasource';
import { CompanyTypeService } from 'src/app/shared/services/company/company-type/company-type.service';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { CreateUpdateContactComponent, ICreateUpdateContactData } from '../../contacts/create-update-contact/create-update-contact.component';
import { CompanyCreateUpdateComponent } from '../company-create-update/company-create-update.component';
import {
  companiesCreateConfig,
  companiesViewAll,
  companiesBranchesList,
  companiesBranchesView,
  companiesBranchesCreate,
  companiesDelete
} from "src/app/shared/helpers/auth-config/companies.config";
import { filter } from 'rxjs/operators';
import { CompanyBranchCreateModalComponent, ICompanyBranchCreateUpdateData } from '../company-branch-create-modal/company-branch-create-modal.component';
import { SortDescriptor } from '@progress/kendo-data-query';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/shared/services';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import _ from 'lodash';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {

  public authConfig = {
    companiesCreateConfig,
    companiesViewAll,
    companiesBranchesList,
    companiesBranchesView,
    companiesBranchesCreate,
    companiesDelete
  };

  public sortHeaders = IApiCompanyOrderBy;

  private _dataSource: CompanyDataSource;

  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  private _filters: IApiCompanyFilter[] = [];

  public companyTypes: IApiCompanyType[];
  public displayedColumns = [
    "NAME", "TYPE", "BRANCHES", "ACTION" // "branch", "address", "phone", "more"
  ];

  public searchValue = "";

  public companies: IApiCompany[] = [];

  public sort: SortDescriptor[] = [{
    field: 'NAME',
    dir: 'asc'
  }];

  public actionItems: any = [];

  constructor(
    private companyService: CompanyService,
    private loader: LoaderService,
    private notifications: NotificationsService,
    public stateService: StateService,
    private router: Router,
    private companyTypeService: CompanyTypeService,
    private authService: AuthService,
    private dialogService: DialogService
  ) { }

  public ngOnInit() {

    this.companyTypeService.get().pipe(unwrapConnection()).subscribe((types) => this.companyTypes = types);

    this._dataSource = new CompanyDataSource(this.companyService);
    this.loader.attachObservable(this._dataSource.loading$);
    this._dataSource.applyFilter(IApiCompanyFilterType.ViewListView, "true");
    this._dataSource.listPage.orderBy = IApiCompanyOrderBy.Name;
    this.dataSource?.contents$.subscribe((res) => {
      this.companies = res;
    });
    this.load();

    forkJoin([
      this.authService.hasCategoryPermission(this.authConfig.companiesViewAll.category, this.authConfig.companiesViewAll.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.companiesBranchesList.category, this.authConfig.companiesBranchesList.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.companiesBranchesCreate.category, this.authConfig.companiesBranchesCreate.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.companiesDelete.category, this.authConfig.companiesDelete.appliedPermissions)
    ]).subscribe(([view, list, create, del]) => {
      if (view) this.actionItems.push({ text: "View Company Info" });
      if (list) this.actionItems.push({ text: "View Branches" });
      if (create) this.actionItems.push({ text: "Add Branch" });
      this.actionItems.push({ text: "View Contacts" });
      this.actionItems.push({ text: "Add Contacts" });
      if (del) this.actionItems.push({ text: "Delete" });
    });
  }

  public setState(event) {
    this.dataSource.applyFilter(IApiCompanyFilterType.State, event || null);
    this.load();
  }

  public setCompanyType(event) {
    this.dataSource.applyFilter(IApiCompanyFilterType.CompanyType, event?.id || null);
    this.load();
  }

  public gotoCreateCompany() {
    const dialog: DialogRef = this.dialogService.open({
      content: CompanyCreateUpdateComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) ? false : !!v;
        }),
      )
      .subscribe(() => this.load());
  }

  public addBranch(company) {
    const dialog: DialogRef = this.dialogService.open({
      content: CompanyBranchCreateModalComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });
    const data: ICompanyBranchCreateUpdateData = {
      companyId: company.id,
      companyName: company.name
    }
    const dialogInstance = dialog.content.instance as CompanyBranchCreateModalComponent;
    dialogInstance.data = data;
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) ? false : !!v;
        }),
      )
      .subscribe(() => this.load());
  }

  public addContact() {
    // TODO send company company data to add the contact to the selected company?
    const data: ICreateUpdateContactData = {
      companyRoles: true
    };
    const dialog: DialogRef = this.dialogService.open({
      content: CreateUpdateContactComponent,
      width: '80%',
      maxWidth: '90%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as CreateUpdateContactComponent;
    dialogInstance.data = data;
    dialog.result
      .pipe(filter((v) => !_.isEmpty(v)))
      .subscribe(() => { });
  }

  public onSearchChange() {
    this.dataSource.applyFilter(IApiCompanyFilterType.Search, this.searchValue || null);
    this.load();
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'NAME';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public deleteCompany(company) {
    this.notifications.kendoConfirm(
      'Are you sure you want to delete this company?',
      "Confirm Delete",
      "No",
      "Yes",
      550
    ).subscribe((res) => {
      if (res) {
        this.loader.show$(this.companyService.remove(company?.id))
          .subscribe(res => {
            this.notifications.notify("Company deleted successfully!");
            this.load();
          }, (err) => {
            if (err?.message?.includes('You cannot delete this company as it is associated with contacts or investigations.')) {
              this.notifications.kendoConfirm(
                err?.message,
                "Error Deleting The Company",
                "Cancel",
                "Okay",
                550
              ).subscribe((res) => {
                if (res) {
                  this.load();
                }
              });
            } else {
              this.notifications.alert(err?.message);
            }
          });
      }
    });
  }

  public itemSelected(event, item) {
    switch (event?.text) {
      case "View Company Info":
        this.router.navigate(['/admin/companies/management', item.id])
        break;
      case "View Branches":
        this.router.navigate(['/admin/companies/management', item.id], { queryParams: { tab: 'branches' } });
        break;
      case "Add Branch":
        this.addBranch(item);
        break;
      case "View Contacts":
        this.router.navigate(['/admin', 'companies', 'management', item.id], { queryParams: { tab: 'contacts' } });
        break;
      case "Add Contacts":
        this.addContact();
        break;
      case "Delete":
        this.deleteCompany(item)
        break;
    }
  }

}
