import { Component, forwardRef, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges } from '@angular/core';
import { IApiCompany, IApiCompanyFilterType, IApiEvidenceFilterType } from '../../modules/graphql/types/types';
import { CompanyService, EvidenceService } from 'src/app/shared/services';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { unwrapConnection } from '../../rxjs.pipes';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { debounceTime, map } from 'rxjs/operators';
import {
  evidence
} from "src/app/shared/helpers/auth-config/evidence.config";
@Component({
  selector: 'app-uenumber-multi-select-kendo',
  templateUrl: './uenumber-multi-select-kendo.component.html',
  styleUrls: ['./uenumber-multi-select-kendo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UenumberMultiSelectKendoComponent),
      multi: true
    }
  ]
})
export class UenumberMultiSelectKendoComponent implements OnInit, OnChanges  {
  public authConfig = {
    evidence
  };
  @ViewChild("multiSelect", { static: false }) public multiSelect: MultiSelectComponent;
  @Input() placeholder = "Select UE #";
  @Input() label = "";
  @Input() disabled = false;
  @Input() selectedId: Array<string> = [];

  public ueNumbers: IApiCompany[];

  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public loading: boolean = false;
  public searchString: string = '';
  public selectedValue = [];
  private selectedUeNumber = [];

  constructor(
    private evidenceService: EvidenceService
  ) { }

  ngOnInit(): void {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    } else {
      const filters = [{
        type: IApiEvidenceFilterType.Ue,
        value: JSON.stringify(this.selectedId)
      }];
      this.loading = true;
      this.evidenceService.get(filters).pipe(
        debounceTime(800),
        unwrapConnection()
      ).subscribe(results => {
        this.loading = false;
        this.selectedUeNumber = results;
        this.ueNumbers = this.selectedUeNumber;
        this.selectedValue = this.selectedId
      });
    }
  }
  public ngOnChanges() {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
      this.selectedUeNumber = [];
    }
  }


  public selectCompany(selected: any) {
    this.selectedUeNumber = this.ueNumbers.filter(usr => selected?.includes(usr.id));
    this.selectionChanged.emit(this.selectedValue);
    this._propagateChange(this.selectedValue);
  }

  public onSearchFocus(event){
    if(this.selectedUeNumber){
      this.ueNumbers = this.selectedUeNumber;
    }
  }

  public onSearchChange(search: string) {
    this.searchString = search?.trim();
    this.multiSelect.toggle(false);
    if (this.selectedUeNumber) {
      this.ueNumbers = this.selectedUeNumber;
    } else {
      this.ueNumbers = [];
    }
    if ((search?.trim())?.length < 3) return;

    const filters = [{
      type: IApiEvidenceFilterType.Ue,
      value: search?.trim()
    }];

    this.loading = true;
    this.multiSelect.toggle(false)
    this.evidenceService.get(filters).pipe(
      debounceTime(800),
      unwrapConnection()
    ).subscribe(results => {
      this.loading = false;
      this.multiSelect.toggle(true);
      const newResult = results?.filter((res) => !this.selectedValue?.includes(res.id))
      this.ueNumbers = [...this.selectedUeNumber, ...newResult];
    });
  }

  private _propagateChange = (_: any) => { };

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

}
