<!-- Permissions -->
<div [appEnforcePermission]="authConfig.evidence" #evidence="appEnforcePermission"></div>
<!-- Permissions -->
<div class="kendo-formfield left-search-icon multi">
  <kendo-label [for]="" text="{{label}}"></kendo-label>
  <div style="position: relative;">
    <i class="fa fa-search" aria-hidden="true"></i>
    <kendo-multiselect #multiSelect 
      [checkboxes]="true" 
      [autoClose]="false" 
      [loading]="loading" 
      [valuePrimitive]="true"
      [data]="ueNumbers" 
      [(ngModel)]="selectedValue" 
      [rounded]="'full'" 
      (valueChange)="selectCompany($event)" 
      valueField="id"
      textField="ueNumber"
      [tagMapper]="tagMapper"
      [placeholder]="placeholder" 
      [fillMode]="'outline'"
      [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}" 
      (filterChange)="onSearchChange($event)"
      (focus)="onSearchFocus($event)"
      [disabled]="!evidence?.enabled">
      <ng-template kendoMultiSelectItemTemplate let-dataItem>
        <span style="padding-left: 5px;">{{ dataItem?.ueNumber }}</span>
      </ng-template>
      <ng-template kendoMultiSelectNoDataTemplate *ngIf="!searchString?.trim()?.length">
        PLEASE SEARCH SOMETHING...
      </ng-template>
      <ng-template kendoMultiSelectNoDataTemplate *ngIf="searchString?.trim()?.length">
        NO DATA FOUND
      </ng-template>
    </kendo-multiselect>
  </div>
</div>
