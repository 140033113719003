import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { IInvestigationTableConfig, TableType } from '../../investigations-table-view/investigations-table/investigations-table.component';
import { EnforcePermissionDisplayModes } from './../../../../shared/directives/enforce-permission.directive';
import { IEnforcePermissionConfig } from "src/app/shared/directives/enforce-permission.directive";
import { PermissionCategories, PermissionActions, PermissionScopes } from "src/app/shared/modules/graphql/enums/permissions.enums";
import { AuthService, DocumentService, InvestigationOnSceneService, InvestigationService, InvestigationStaffService, RequestService } from 'src/app/shared/services';
import {
  dashboardInvestigationsPending,
  dashboardInvestigationsAssigned,
  dashboardInvestigationsInProgress,
  dashboardInvestigationsOngoing,
  dashboardInvestigationsReadyToBill,
  dashboardPendingRequests,
  dashboardReportsInEditorReview,
  dashboardReportsInTechReview,
  dashboardReportsReadyForEditor,
  dashboardInvestigatorsOnScene
} from "../../../../shared/helpers/auth-config/dashboard.config";
import { IApiDocumentFilterType, IApiInvestigationFilterType, IApiInvestigationOnSceneFilterType, IApiInvestigationReportStatus, IApiInvestigationStatusNames, IApiRequestFilterType } from 'src/app/shared/modules/graphql/types/types';
import { Columns, IInvestigationTableConfig, TableType } from '../../investigations-table-view/investigations-table-new/investigations-table-new.component';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

export enum TABS {
  "PENDING_REQUESTS" = "PENDING_REQUESTS",
  "PENDING_INVESTIGATION" = "PENDING_INVESTIGATION",
  "ON_SCENE" = "ON_SCENE",
  "ASSIGNED" = "ASSIGNED",
  "FOR_EDITOR" = "FOR_EDITOR",
  "EDITOR_REVIEW" = "EDITOR_REVIEW",
  "TECH_REVIEW" = "TECH_REVIEW",
  "IN_PROGRESS" = "IN_PROGRESS",
  "TO_BILL" = "TO_BILL",
  "ON_GOING" = "ON_GOING"
};
@UntilDestroy()
@Component({
  selector: 'app-dashboard-tabs',
  templateUrl: './dashboard-tabs.component.html',
  styleUrls: ['./dashboard-tabs.component.scss']
})
export class DashboardTabsComponent implements OnInit, AfterViewInit {
  public authConfig = {
    dashboardInvestigationsPending,
    dashboardInvestigationsAssigned,
    dashboardInvestigationsInProgress,
    dashboardInvestigationsOngoing,
    dashboardInvestigationsReadyToBill,
    dashboardPendingRequests,
    dashboardReportsInEditorReview,
    dashboardReportsInTechReview,
    dashboardReportsReadyForEditor,
    dashboardInvestigatorsOnScene
  };

  // Variables for badges - changed via event emitter on child component
  public assignedInvestigationsTotal: number;
  public pendingRequestsTotal: number;
  public investigationsPendingTotal: number;
  public investigatorsOnSiteTotal: number;
  public investgationsInProgressTotal: number;
  public investigationsReadyToBillTotal: number;
  public investigationsOngoingTotal: number;
  public techReviewTotal: number;
  public editorReportsTotal: number;
  public editorReviewTotal: number;

  // SAMPLE Permission Enforcement directive configuration
  public permissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION,
    appliedPermissions: {
      All: [PermissionActions.VIEW],
      AllAssigned: [PermissionActions.VIEW],
      Assigned: [],
      Own: []
    },
    assignedDelegate: (category, actions) => {
      // TODO - remove, just for interim documentation
      console.log("Checking assignment:", category, actions);
      return Promise.resolve(true);
    },
    displayMode: EnforcePermissionDisplayModes.StandAlone
  };

  // Configs for Investigations Table
  public progressConfig: IInvestigationTableConfig = {
    type: TableType.progress,
    viewType: IApiInvestigationStatusNames.InProgress,
    displayedColumns:
      [
        Columns.createdAt, Columns.nefcoNumber, Columns.priority, Columns.lossDate, Columns.riskType,
        Columns.investigationLocation, Columns.contact, Columns.client, Columns.assigned, Columns.progress, Columns.actions, Columns.comments
      ],
    filterNumber: 6
  };

  public assignedConfig: IInvestigationTableConfig = {
    type: TableType.assigned,
    viewType: IApiInvestigationStatusNames.Assigned,
    displayedColumns: [
      Columns.createdAt, Columns.nefcoNumber, Columns.priority, Columns.lossDate, Columns.riskType,
      Columns.investigationLocation, Columns.contact, Columns.client, Columns.assigned, Columns.actions
    ],
    startWithCount: false
  };

  public readyToBillConfig: IInvestigationTableConfig = {
    type: TableType.readyToBill,
    viewType: IApiInvestigationStatusNames.ReadyToBill,
    displayedColumns: [
      Columns.createdAt, Columns.nefcoNumber, Columns.lossDate, Columns.riskType,
      Columns.investigationLocation, Columns.contact, Columns.billTo, Columns.client, Columns.assigned, Columns.status, Columns.actions

    ],
    filterNumber: 5
  };
  public ongoingConfig: IInvestigationTableConfig = {
    type: TableType.ongoing,
    viewType: IApiInvestigationStatusNames.Ongoing,
    displayedColumns: [
      Columns.createdAt, Columns.nefcoNumber, Columns.priority, Columns.lossDate, Columns.riskType,
      Columns.investigationLocation, Columns.contact, Columns.client, Columns.status, Columns.followUp, Columns.actions, Columns.comments
    ],
    filterNumber: 7
  };

  public pendingConfig: IInvestigationTableConfig = {
    type: TableType.pending,
    viewType: IApiInvestigationStatusNames.Pending,
    displayedColumns: [
      Columns.indicator,
      Columns.createdAt, Columns.priority, Columns.lossDate, Columns.riskType,
      Columns.investigationLocation, Columns.contact, Columns.client, Columns.actions, Columns.comments,
      Columns.conflicts
    ],
    filterNumber: 5
  };

  public reportTechReviewConfig: IInvestigationTableConfig = {
    type: TableType.review,
    viewType: IApiInvestigationStatusNames.Assigned,
    displayedColumns: [
      Columns.createdAt, Columns.nefcoNumber, Columns.priority, Columns.investigationLocation, Columns.client, Columns.review
    ]
  };


  // Configs for Reports Tables
  public readyForEditorConfig = {
    type: IApiInvestigationReportStatus.ReadyForEditor,
    viewType: IApiInvestigationReportStatus.ReadyForEditor,
    displayedColumns: [
      Columns.createdAt, Columns.nefcoNumber, Columns.priority, Columns.investigationLocation, Columns.client, Columns.review, "createdBy", "submitted", Columns.actions
    ]
  };

  public techConfigReports = {
    type: IApiInvestigationReportStatus.TechReview,
    viewType: IApiInvestigationReportStatus.TechReview, // Report view type
    displayedColumns: [
      Columns.createdAt, Columns.nefcoNumber, Columns.priority, Columns.investigationLocation, Columns.client, Columns.review, "createdBy", "submitted", Columns.assigned, Columns.actions
    ]
  };

  public editorReviewConfigReports = {
    type: IApiInvestigationReportStatus.EditorReview,
    viewType: IApiInvestigationReportStatus.EditorReview, // Report view type
    displayedColumns: [
      Columns.createdAt, Columns.nefcoNumber, Columns.priority, Columns.investigationLocation, Columns.client, Columns.review, "createdBy", "submitted", Columns.editor, Columns.actions
    ]
  };
  @ViewChild('tabstrip') public tabstrip: TabStripComponent;
  public selectedTab = '';
  public TABS = TABS;
  public alreadyInIt = false;

  constructor(
    private auth: AuthService,
    private investigationService: InvestigationService,
    private documentService: DocumentService,
    private investigationOnSceneService: InvestigationOnSceneService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private requestService: RequestService
  ) { }
  
  async ngOnInit() {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).pipe(
      tap()
    ).subscribe((params) => {
    const { tab } = params;
    if (!tab) return;
    this.selectedTab = tab;
    if (!this.alreadyInIt) {
    // Pending Requests
    this.auth.hasCategoryPermission(dashboardPendingRequests.category, dashboardPendingRequests.appliedPermissions).subscribe(r => {
      if (r) {
        this.requestService.get([
          {type: IApiRequestFilterType.Incomplete, value: "COMPLETE"}
        ], {}).subscribe(({ totalCount }) => {
          this.pendingRequestsTotal = totalCount;
        })
      }
    });

    // Pending Investigations
    this.auth.hasCategoryPermission(dashboardInvestigationsPending.category, dashboardInvestigationsPending.appliedPermissions).subscribe(r => {
      if (r) {
        this.investigationService.get([
          { type: IApiInvestigationFilterType.CountOnly, value: 'true' },
          { type: IApiInvestigationFilterType.Status, value: IApiInvestigationStatusNames.Pending }
        ], {}).subscribe(({ totalCount }) => {
          this.investigationsPendingTotal = totalCount
        });
      }
    });

    // On Scene
    this.auth.hasCategoryPermission(dashboardInvestigatorsOnScene.category, dashboardInvestigatorsOnScene.appliedPermissions).subscribe(r => {
      if (r) {
        this.investigationOnSceneService.get([
          { type: IApiInvestigationOnSceneFilterType.CountOnly, value: 'true' },
          { type: IApiInvestigationOnSceneFilterType.ScheduledDate, value: JSON.stringify({ startDate: moment().startOf('day'), endDate: moment().endOf('day') }) },
        ], {}).subscribe(({ totalCount }) => this.investigatorsOnSiteTotal = totalCount);
      }
    });

    // Assigned
    this.auth.hasCategoryPermission(dashboardInvestigationsAssigned.category, dashboardInvestigationsAssigned.appliedPermissions).subscribe(r => {
      if (r) {
        this.investigationService.get([
          { type: IApiInvestigationFilterType.CountOnly, value: 'true' },
          { type: IApiInvestigationFilterType.Status, value: IApiInvestigationStatusNames.Assigned }
        ], {}).subscribe(({ totalCount }) => this.assignedInvestigationsTotal = totalCount);
      }
    });

    // For Editor
    this.auth.hasCategoryPermission(dashboardReportsReadyForEditor.category, dashboardReportsReadyForEditor.appliedPermissions).subscribe(r => {
      if (r) {
        this.documentService.get([
          { type: IApiDocumentFilterType.Status, value: IApiInvestigationReportStatus.ReadyForEditor },
          { type: IApiDocumentFilterType.InvestigationReport, value: 'true' },
          { type: IApiDocumentFilterType.CountOnly, value: 'true' }
        ], {}).subscribe(({ totalCount }) => this.editorReportsTotal = totalCount);
      }
    });

    // Editor Review
    this.auth.hasCategoryPermission(dashboardReportsInEditorReview.category, dashboardReportsInEditorReview.appliedPermissions).subscribe(r => {
      if (r) {
        this.documentService.get([
          { type: IApiDocumentFilterType.Status, value: IApiInvestigationReportStatus.EditorReview },
          { type: IApiDocumentFilterType.Assigned, value: 'true' },
          { type: IApiDocumentFilterType.InvestigationReport, value: 'true' },
          { type: IApiDocumentFilterType.CountOnly, value: 'true' }
        ], {}).subscribe(({ totalCount }) => this.editorReviewTotal = totalCount);
      }
    });

    // Tech Review
    this.auth.hasCategoryPermission(dashboardReportsInTechReview.category, dashboardReportsInTechReview.appliedPermissions).subscribe(r => {
      if (r) {
        this.documentService.get([
          { type: IApiDocumentFilterType.Status, value: IApiInvestigationReportStatus.TechReview },
          { type: IApiDocumentFilterType.AssignedNotEditor, value: 'true' },
          { type: IApiDocumentFilterType.InvestigationReport, value: 'true' },
          { type: IApiDocumentFilterType.CountOnly, value: 'true' }
        ], {}).subscribe(({ totalCount }) => this.techReviewTotal = totalCount);
      }
    });

    // In Progress
    this.auth.hasCategoryPermission(dashboardInvestigationsInProgress.category, dashboardInvestigationsInProgress.appliedPermissions).subscribe(r => {
      if (r) {
        this.investigationService.get([
          { type: IApiInvestigationFilterType.CountOnly, value: 'true' },
          { type: IApiInvestigationFilterType.Status, value: IApiInvestigationStatusNames.InProgress }
        ], {}).subscribe(({ totalCount }) => this.investgationsInProgressTotal = totalCount);
      }
    });

    // To Bill
    this.auth.hasCategoryPermission(dashboardInvestigationsReadyToBill.category, dashboardInvestigationsReadyToBill.appliedPermissions).subscribe(r => {
      if (r) {
        this.investigationService.get([
          { type: IApiInvestigationFilterType.CountOnly, value: 'true' },
          { type: IApiInvestigationFilterType.Status, value: IApiInvestigationStatusNames.ReadyToBill }
        ], {}).subscribe(({ totalCount }) => this.investigationsReadyToBillTotal = totalCount);
      }
    });

    // Ongoing
    this.auth.hasCategoryPermission(dashboardInvestigationsOngoing.category, dashboardInvestigationsOngoing.appliedPermissions).subscribe(r => {
      if (r) {
        this.investigationService.get([
          { type: IApiInvestigationFilterType.CountOnly, value: 'true' },
          { type: IApiInvestigationFilterType.Status, value: IApiInvestigationStatusNames.Ongoing }
        ], {}).subscribe(({ totalCount }) => this.investigationsOngoingTotal = totalCount);
      }
    });

    }
    this.alreadyInIt = true;
    });

    // Do this in this component to prevent extraneous network requests for the check permission calls
    // Calculate what columns should be hidden based on permissions
    const removeCols: string[] = [];
    // hide assigned column if no permissions - can't just hide the column as it throws an error since it can't find a column that was defined in the columns array
    if (!(await this.auth.checkPermission(PermissionScopes.ALL_ASSIGNED, PermissionActions.VIEW, PermissionCategories.INVESTIGATION))) removeCols.push(Columns.assigned);
    this.progressConfig.displayedColumns = this.progressConfig.displayedColumns.filter((c) => !removeCols.includes(c));
    this.assignedConfig.displayedColumns = new Array(...this.assignedConfig.displayedColumns.filter((c) => !removeCols.includes(c)));
    this.readyToBillConfig.displayedColumns = this.readyToBillConfig.displayedColumns.filter((c) => !removeCols.includes(c));
    this.ongoingConfig.displayedColumns = this.ongoingConfig.displayedColumns.filter((c) => !removeCols.includes(c));
    this.pendingConfig.displayedColumns = this.pendingConfig.displayedColumns.filter((c) => !removeCols.includes(c));
    this.readyForEditorConfig.displayedColumns = this.pendingConfig.displayedColumns.filter((c) => !removeCols.includes(c));
    this.reportTechReviewConfig.displayedColumns = this.pendingConfig.displayedColumns.filter((c) => !removeCols.includes(c));

  }

  public ngAfterViewInit() {
    if (!this.selectedTab) {
      Promise.resolve(null).then(() => this.tabstrip.selectTab(0));
    }
  }

  public changeTab(event: SelectEvent) {
    this.selectedTab = event?.title || '';
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: {tab: this.selectedTab}}); 
  }
}
