<section>
  <h4 class="page-title" *ngIf="selectableMode">Select services</h4>
  <ng-container *ngIf="selectableMode">
    <kendo-chip *ngFor="let sel of selection.selected" class="mr-16 mb-16 fw600" [size]="'medium'" [rounded]="'full'"
      [fillMode]="'solid'" [themeColor]="'base'" [removable]="true" (remove)="removeChip(sel)">
      {{sel?.name}}
    </kendo-chip>
  </ng-container>
  <kendo-grid class="certifications-certification-type-list mt-16" [data]="content" [pageable]="false" [sortable]="false" [navigatable]="false"
    [selectable]="selectableMode ? { checkboxOnly: true, mode: 'multiple' } : false">
    <kendo-grid-checkbox-column [width]="2" showSelectAll="true" *ngIf="selectableMode">
      <ng-template kendoGridHeaderTemplate="" let-dataItem let-rowIndex="rowIndex">
        <input type="checkbox" kendoCheckBox (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected" [indeterminate]="selection.hasValue() && !isAllSelected" />
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <input type="checkbox" kendoCheckBox (click)="$event.stopPropagation()"
          (change)="$event ? selectRow(dataItem) : null" [checked]="selection.isSelected(dataItem)" />
      </ng-template>
    </kendo-grid-checkbox-column>
    <kendo-grid-column field="TITLE" title="Service Type Name" [width]="15" [sortable]="false"
      [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem?.name || '-' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="SERVICE_TYPE" title="Services Included" [width]="15" [sortable]="false"
    [headerClass]="'nocursor'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="mb-0" *ngFor="let service of dataItem?.SubServiceTypes" [innerHTML]="service.name"></p>
    </ng-template>
  </kendo-grid-column>
    <!-- No record -->
    <ng-template kendoGridNoRecordsTemplate>
      <p class="mt-24 mb-24">There are no results to display.</p>
    </ng-template>
  </kendo-grid>
  <kendo-gridlayout *ngIf="selectableMode" class="mt-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
    [cols]="['repeat(1,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-16 text-right">
      <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="done.emit([])">
        Cancel
      </button>
      <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="save()">
        Save
      </button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</section>
