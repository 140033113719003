import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { SharedComponentModule } from '../shared/shared-component.module';
import { MobileAppRoutingModule } from './mobile-app-routing.module';
import { MobileDashboardComponent } from './mobile-dashboard/mobile-dashboard.component';
import { MobileInvestigationListComponent } from './mobile-investigation-list/mobile-investigation-list.component';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';
import { MobileDataRequestFormComponent } from './mobile-data-request-form/mobile-data-request-form.component';
import { MobileTimeAndExpenseComponent } from './mobile-time-and-expense/mobile-time-and-expense.component';
import { MobileHoursFormComponent } from './mobile-hours-form/mobile-hours-form.component';
import { MobileExpenseFormComponent } from './mobile-expense-form/mobile-expense-form.component';
import { MobileMileageFormComponent } from './mobile-mileage-form/mobile-mileage-form.component';
import { MobileTimeAndExpenseTabsComponent } from './mobile-time-and-expense-tabs/mobile-time-and-expense-tabs.component';
import { MobileTimeExpDateKendoFilterComponent } from './mobile-time-exp-date-kendo-filter/mobile-time-exp-date-kendo-filter.component';
import { MobileInvestigationDetailComponent } from './mobile-investigation-detail/mobile-investigation-detail.component';
import { MobileInvestigationLogTabsComponent } from './mobile-investigation-log-tabs/mobile-investigation-log-tabs.component';
import { MobileAddCommentModalComponent } from './mobile-add-comment-modal/mobile-add-comment-modal.component';
import { MobileScheduleOnSceneExamComponent } from './mobile-schedule-on-scene-exam/mobile-schedule-on-scene-exam.component';
import { MobileKendoConfirmComponent } from './mobile-kendo-confirm/mobile-kendo-confirm.component';
import { MobileChangeOnSceneStatusComponent } from './mobile-change-on-scene-status/mobile-change-on-scene-status.component';
import { MobileFeatureUnavailableComponent } from './mobile-feature-unavailable/mobile-feature-unavailable.component';
import { MobileInvestigationMetricsTabsComponent } from './mobile-investigation-metrics-tabs/mobile-investigation-metrics-tabs.component';
import { MobileInvestigationMetricsBasicComponent } from './mobile-investigation-metrics-basic/mobile-investigation-metrics-basic.component';
import { MobileInvestigationMetricsTableComponent } from './mobile-investigation-metrics-table/mobile-investigation-metrics-table.component';
import { MobileInvestigationMetricsFiltersComponent } from './mobile-investigation-metrics-filters/mobile-investigation-metrics-filters.component';
import { MobileInvestigationMetricsStateComponentComponent } from './mobile-investigation-metrics-state-component/mobile-investigation-metrics-state-component.component';
import { MobileForceUpdateComponent } from './mobile-force-update/mobile-force-update.component';


@NgModule({
  declarations: [
    MobileDashboardComponent,
    MobileInvestigationListComponent,
    MobileLoginComponent,
    MobileDataRequestFormComponent,
    MobileTimeAndExpenseComponent,
    MobileHoursFormComponent,
    MobileExpenseFormComponent,
    MobileMileageFormComponent,
    MobileTimeAndExpenseTabsComponent,
    MobileTimeExpDateKendoFilterComponent,
    MobileInvestigationDetailComponent,
    MobileInvestigationLogTabsComponent,
    MobileAddCommentModalComponent,
    MobileScheduleOnSceneExamComponent,
    MobileKendoConfirmComponent,
    MobileChangeOnSceneStatusComponent,
    MobileFeatureUnavailableComponent,
    MobileInvestigationMetricsTabsComponent,
    MobileInvestigationMetricsBasicComponent,
    MobileInvestigationMetricsTableComponent,
    MobileInvestigationMetricsFiltersComponent,
    MobileInvestigationMetricsStateComponentComponent,
    MobileForceUpdateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MobileAppRoutingModule,
    SharedComponentModule,
  ],
  exports: [
    SharedComponentModule
  ]
})
export class MobileAppModule { }
