import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IApiDocument, IApiCreateInvestigationReportInput, IApiAdvanceInvestigationReportInput, IApiQueryGenerateInvestigationMetricsCsvArgs, IApiInvestigationMetricsFilter } from '../../../types/types';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { advanceInvestigationReportMutation, createInvestigationReportMutation, generateInvestigationReportQuery, getInvestigationReportQuery } from "./investigation-report.operations";
import { IApiInvestigationMetricsConnection } from 'src/app/shared/modules/graphql/types/types';
import { IGqlBaseOptions } from '../../../interfaces';
import { connectionQuery } from '../../../helpers.class';

@Injectable()
export class GqlInvestigationReportService {
  public constructor(
    private apollo: Apollo
  ) { }

  public createReport(input: IApiCreateInvestigationReportInput): Observable<IApiDocument> {
    return this.apollo.mutate({
      mutation: createInvestigationReportMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public advanceReport(input: IApiAdvanceInvestigationReportInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: advanceInvestigationReportMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getCsvReport(input?: IApiQueryGenerateInvestigationMetricsCsvArgs): Observable<string> {
    return this.apollo.query({
      query: generateInvestigationReportQuery,
      variables: { ...input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getInvestigationReport(filters: IApiInvestigationMetricsFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationMetricsConnection> {
    return this.apollo.query(connectionQuery(getInvestigationReportQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

}
