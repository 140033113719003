import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/enums/permissions.enums";


export const investigation: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [PermissionActions.LIST],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.VIEW],
    Own: [PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};


export const investigationCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationClose: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_CLOSED,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReject: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REJECT,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReceivedDateUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_RECEIVED_DATE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationInformationListView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationInformationUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPhotos: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PHOTOS,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPhotosDownload: IEnforcePermissionConfig = {
  ...investigationPhotos,
  appliedPermissions: {
    All: [PermissionActions.DOWNLOAD],
    AllAssigned: [],
    Assigned: [PermissionActions.DOWNLOAD],
    Own: [PermissionActions.DOWNLOAD],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPhotosCreate: IEnforcePermissionConfig = {
  ...investigationPhotos,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE]
  }
};

export const investigationPhotosUpdate: IEnforcePermissionConfig = {
  ...investigationPhotos,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE]
  },
  displayMode: EnforcePermissionDisplayModes.StandAlone
};

export const investigationPhotosDelete: IEnforcePermissionConfig = {
  ...investigationPhotos,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationServices: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_SERVICES,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationServicesCreate: IEnforcePermissionConfig = {
  ...investigationServices,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationServicesCreateOwn: IEnforcePermissionConfig = {
  ...investigationServices,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationServicesUpdate: IEnforcePermissionConfig = {
  ...investigationServices,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationServicesDelete: IEnforcePermissionConfig = {
  ...investigationServices,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};


export const investigationRequests: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REQUESTS,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationRequestsCreate: IEnforcePermissionConfig = {
  ...investigationRequests,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationRequestsUpdate: IEnforcePermissionConfig = {
  ...investigationRequests,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationRequestsDelete: IEnforcePermissionConfig = {
  ...investigationRequests,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationScheduledDateViewAll: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_SCHEDULED_DATE,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationScheduledDateView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_SCHEDULED_DATE,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationScheduledDateUpdateAll: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_SCHEDULED_DATE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationScheduledDateUpdateOwn: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_SCHEDULED_DATE,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPartiesList: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PARTIES,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [PermissionActions.LIST],
    Assigned: [],
    Own: [PermissionActions.LIST],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPartiesListView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PARTIES,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPartiesCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PARTIES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPartiesUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PARTIES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPartiesDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PARTIES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationBillingInformationView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_BILLING_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.VIEW],
    Own: [PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationBillingInformationUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_BILLING_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationLinkedInvestigationView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_LINKED_INVESTIGATION,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.VIEW],
    Own: [PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationLinkedInvestigationUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_LINKED_INVESTIGATION,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationVehiclesListView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_VEHICLES,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationVehiclesCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_VEHICLES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationVehiclesUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_VEHICLES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationVehiclesDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_VEHICLES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};


export const investigationAssignStaffListView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_ASSIGNMENT,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationAssignStaffCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_ASSIGNMENT,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationAssignStaffUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_ASSIGNMENT,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationAssignStaffDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_ASSIGNMENT,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationStatusList: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_STATUS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [PermissionActions.LIST],
    Assigned: [],
    Own: [PermissionActions.LIST],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationStatusListView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_STATUS,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationStatusCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_STATUS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationStatusUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_STATUS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationStatusDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_STATUS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};


export const investigationCauseListView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_CAUSE,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationCauseCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_CAUSE,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationCauseUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_CAUSE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationCauseDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_CAUSE,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPhotoShareListView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PHOTO_SHARE,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPhotoShareCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PHOTO_SHARE,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPhotoShareUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PHOTO_SHARE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationPhotoShareDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_PHOTO_SHARE,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationDocumentsListView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_DOCUMENTS,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationDocumentsCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_DOCUMENTS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationDocumentsUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_DOCUMENTS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationDocumentsDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_DOCUMENTS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationDocumentsDownload: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_DOCUMENTS,
  appliedPermissions: {
    All: [PermissionActions.DOWNLOAD],
    AllAssigned: [],
    Assigned: [PermissionActions.DOWNLOAD],
    Own: [PermissionActions.DOWNLOAD],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationDocumentShareListView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_DOCUMENTS_SHARE,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST, PermissionActions.VIEW],
    Own: [PermissionActions.LIST, PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationDocumentShareCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_DOCUMENTS_SHARE,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationDocumentShareUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_DOCUMENTS_SHARE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationDocumentShareDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_DOCUMENTS_SHARE,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportsList: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REPORTS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [PermissionActions.LIST],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportsView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REPORTS,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.VIEW],
    Own: [PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportsCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REPORTS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportsUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REPORTS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportsDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REPORTS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportsDeleteOwn: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REPORTS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.DELETE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportsInTechReviewUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REPORTS_TECH_REVIEW,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportsInEditorReviewUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REPORTS_EDITOR_REVIEW,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportsSnapshotsDownload: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REPORTS_SNAPSHOTS,
  appliedPermissions: {
    All: [PermissionActions.DOWNLOAD],
    AllAssigned: [],
    Assigned: [PermissionActions.DOWNLOAD],
    Own: [PermissionActions.DOWNLOAD],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationCommentsView: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_COMMENTS,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.VIEW],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationCommentsCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_COMMENTS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportsAssignEditorUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.INVESTIGATION_REPORTS_ASSIGN_EDITOR,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: [PermissionActions.UPDATE],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};
