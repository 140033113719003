import { Component, Input, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import * as allIcons from '@progress/kendo-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-kendo-confirm',
  templateUrl: './mobile-kendo-confirm.component.html',
  styleUrls: ['./mobile-kendo-confirm.component.scss']
})
export class MobileKendoConfirmComponent extends DialogContentBase {

  @Input() title: string = 'Go to Home';
  @Input() bodyMessage: string = 'Are you sure you want to leave this page? Any changes made to the investigation form will not be saved.';
  @Input() leftBtn: string = 'No';
  @Input() rightBtn: string = 'Yes';
  @Input() minWidth: number = 550;
  @Input() width: number = 550;
  @Input() headerIcon: boolean = true;
  @Input() subTitle: string = '';
  @Input() isClosable: boolean = true;
  public icons = allIcons;


  constructor(
    public dialog: DialogRef
  ) {
    super(dialog);
  }

  public close(text: string) {
    if (!this.isClosable) {
      if (Capacitor.getPlatform() === 'android') {
        window?.open(`${environment.cloudFrontS3}/ANDROID/NEFCO.apk`, '_blank');
      } else if (Capacitor.getPlatform() === 'ios') {
        window?.open('itms-beta://testflight.apple.com/apps/com.nefco.app', '_blank');
      }
      return;
    }
    const status_ = text === 'yes' ? true : false;
    this.dialog.close(status_);
  }


}
