import { Component, OnInit } from "@angular/core";
import { combineLatest } from "rxjs";
import { IApiSettings, IApiSettingsFilterType } from "src/app/shared/modules/graphql/types/types";
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { unwrapConnection } from "src/app/shared/rxjs.pipes";
import { AuthService, SettingsService } from "src/app/shared/services";

@Component({
  selector: "app-mobile-force-update",
  templateUrl: "./mobile-force-update.component.html",
  styleUrls: ["./mobile-force-update.component.scss"],
})
export class MobileForceUpdateComponent implements OnInit {
  settings: IApiSettings[] = [];
  constructor(
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.authService.authenticatedUser,
      this.settingsService.get([
        {
          type: IApiSettingsFilterType.Keys,
          value: JSON.stringify(["APP_RELEASED_VERSION", "APP_RELEASED_BUILD"]),
        },
      ]).pipe(unwrapConnection()),
    ]).subscribe(([u, settings]) => {
      this.settings = settings;
      const version = this.settings.find(
        (item) => item.key === "APP_RELEASED_VERSION"
      );
      const build = this.settings.find(
        (item) => item.key === "APP_RELEASED_BUILD"
      );
      this.notificationsService
        .kendoMobileConfirm(
          version?.value && build?.value
            ? `Update Avilable ${version?.value || ""} (${build?.value || ""})!`
            : `Update Avilable`,
          "Attention!",
          "",
          "Update Now",
          "100%",
          true,
          "",
          "p-16",
          false
        )
        .subscribe();
    });
  }
}
