import { connectionQuery } from './../../../helpers.class';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { IGqlBaseOptions } from '../../../interfaces';
import { safeResolve } from '../../../rxjs';
import { IApiEmailType, IApiEmailTypeFilter } from '../../../types/types';
import { emailTypeQuery } from './email-types.operation';

@Injectable()
export class GqlEmailTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getEmailTypes(filters: IApiEmailTypeFilter[], options: IGqlBaseOptions): Observable<IApiEmailType[]> {
    return this.apollo.query(connectionQuery(emailTypeQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }
}