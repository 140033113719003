import { EnforcePermissionDisplayModes } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/constants/permission.constants";

export const companyInvestigationAndEvidenceReport = {
    category: PermissionCategories.COMPANY_INVESTIGATION_AND_EVIDENCE_REPORT,
    appliedPermissions: {
      All: [PermissionActions.LIST],
      AllAssigned: [],
      Assigned: [],
      Own: []
    },
    displayMode: EnforcePermissionDisplayModes.Hidden
};

export const companyInvestigationReport = {
    category: PermissionCategories.COMPANY_INVESTIGATION_REPORT,
    appliedPermissions: {
      All: [PermissionActions.LIST],
      AllAssigned: [],
      Assigned: [],
      Own: []
    },
    displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationTimelogPacing = {
    category: PermissionCategories.INVESTIGATION_TIMELOG_PACING,
    appliedPermissions: {
      All: [PermissionActions.LIST],
      AllAssigned: [],
      Assigned: [],
      Own: []
    },
    displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationReportTimelog = {
  category: PermissionCategories.INVESTIGATION_REPORT_TIMELOG,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const quarterlyUtilizationReport = {
  category: PermissionCategories.QUARTERLY_UTILIZATION_REPORT,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const quarterlyHourReport = {
  category: PermissionCategories.QUARTERLY_HOUR_REPORT,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const reportSentMetric = {
  category: PermissionCategories.REPORT_SENT_METRIC,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationMetricBasicCharts = {
  category: PermissionCategories.INVESTIGATION_METRICS_BASIC_CHARTS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationMetricInvestigator = {
  category: PermissionCategories.INVESTIGATION_METRICS_INVESTIGATOR,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationMetricState = {
  category: PermissionCategories.INVESTIGATION_METRICS_STATE,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationMetricBillToCompany = {
  category: PermissionCategories.INVESTIGATION_METRICS_BILL_TO_COMPANY,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationMetricClientCompany = {
  category: PermissionCategories.INVESTIGATION_METRICS_CLIENT_COMPANY,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationMetricTechReviewer = {
  category: PermissionCategories.INVESTIGATION_METRICS_TECH_REVIEWER,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationMetricContact = {
  category: PermissionCategories.INVESTIGATION_METRICS_CONTACT,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationMetricRisktype = {
  category: PermissionCategories.INVESTIGATION_METRICS_RISK_TYPE,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationMetricDetermination = {
  category: PermissionCategories.INVESTIGATION_METRICS_DETERMINATION,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const investigationMetricDocusketchRequested = {
  category: PermissionCategories.INVESTIGATION_METRICS_DOCUSKETCH_REQUESTED,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

