import { DataSource } from "@angular/cdk/collections";
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { InvestigationMetricsBasicService } from './investigation-metrics-basic.service';
import { IApiInvestigationMetrics, IApiInvestigationMetricsBasicConnection, IApiInvestigationMetricsFilter, IApiInvestigationMetricsState } from "src/app/shared/modules/graphql/types/types";

export class InvestigationMetricsBasicDataSource extends NefcoPagedDataSource<IApiInvestigationMetricsState, InvestigationMetricsBasicService, IApiInvestigationMetricsBasicConnection> implements DataSource<IApiInvestigationMetricsState> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsBasicConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
