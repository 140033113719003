import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  trackingNumber
  comment
  FromLocation {
    id
  }
  ToLocation {
    id
  }
  ShippingCarrier {
    id
    name
  }
  ShippingDocument {
    id
  }
  Evidence {
    id
  }
  createdAt
  updatedAt
`;

export const evidenceTransferConnectionQuery = gql`${buildConnectionQuery("EvidenceTransfer", "evidenceTransferConnection", nodeFragment)}`;

export const addEvidenceTransferMutation = gql`mutation createEvidenceTransfer($input: AddEvidenceTransferInput!) {
  addEvidenceTransfer(input: $input) { ${ nodeFragment } }
}`;

export const removeEvidenceTransferMutation = gql`mutation removeEvidenceTransfer($input: RemoveEvidenceTransferInput!) {
  removeEvidenceTransfer(input: $input) { ${ nodeFragment } }
}`;

export const updateEvidenceTransferMutation = gql`mutation updateEvidenceTransfer($input: UpdateEvidenceTransferInput!) {
  updateEvidenceTransfer(input: $input) { ${ nodeFragment } }
}`;