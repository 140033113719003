<h1 mat-dialog-title>
  Why are you modifying this Investigation?
</h1>
<hr />
<div mat-dialog-content>
  <form #form="ngForm" class="d-flex flex-column">
    <ng-container *ngIf="!noteOnly">
      <mat-form-field>
        <mat-label>Category:</mat-label>
        <mat-select name="category" [(ngModel)]="model.updateCategory" cdkFocusInitial>
          <mat-option *ngFor="let opt of categories" [value]="opt" [innerHTML]="opt | titlecase" required></mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Type:</mat-label>
        <mat-select name = "type" [(ngModel)]="model.updateType">
          <mat-option *ngFor="let opt of types" [value]="opt" [innerHTML]="opt | titlecase" required></mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <mat-form-field>
      <mat-label>Reason for this change:</mat-label>
      <textarea matInput name="notes" [(ngModel)]="model.comment" rows="6" required appNoWhiteSpace></textarea>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="d-flex flex-row-reverse">
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">Done</button> 
  <button mat-raised-button class="mr-2" [mat-dialog-close]="null" *ngIf="!noteOnly">I'm not done editing ...</button>
</div>