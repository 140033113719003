<kendo-formfield class="left-search-icon multi">
    <kendo-label *ngIf="label" [text]="label"></kendo-label>
    <div style="position: relative;">
        <i class="fa fa-search" aria-hidden="true"></i>
        <kendo-multiselect #multiSelect 
            [checkboxes]="true" 
            [autoClose]="false" 
            [valuePrimitive]="true"
            [data]="stateList" 
            [(ngModel)]="selectedValue"
            [rounded]="'full'" 
            (valueChange)="searchSelect($event)" 
            valueField="value"
            textField="text"
            [tagMapper]="tagMapper"
            [placeholder]="placeholder"
            [disabled]="disabled" 
            [fillMode]="'outline'"
            [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}">
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
            <span style="padding-left: 5px;">{{ dataItem?.text }}</span>
            </ng-template>
        </kendo-multiselect>
    </div>
</kendo-formfield>