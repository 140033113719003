// import { IApiInvestigation, IApiInvestigationOrderBy, IApiInvestigationFilter, IApiInvestigationConnection } from './../../modules/graphql/types/types';
import { DataSource } from "@angular/cdk/collections";
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { InvestigationReportService } from './investigation-report.service';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { IApiInvestigationMetrics, IApiInvestigationMetricsConnection } from "src/app/shared/modules/graphql/types/types";
import { IApiInvestigationMetricsFilter } from "src/app/shared/modules/graphql/types/types";

export class InvestigationReportDataSource extends NefcoPagedDataSource<IApiInvestigationMetrics, InvestigationReportService, IApiInvestigationMetricsConnection> implements DataSource<IApiInvestigationMetrics> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
