import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";
import { IApiEvidenceStorageLocationFilterType } from '../../../types/types';

const detailsFragment = `
  hasDetails
  additionalDetails
  isNefco
  contactName
  createdAt
  updatedAt
`;

const transfersFragment = `
  Transfers {
    id
    trackingNumber
    comment
    FromLocation {
      id
      name
      type
      isActive
      additionalDetails
      isNefco
      contactName
      companyName
    }
    ToLocation {
      id
      name
      type
      isActive
      additionalDetails
      isNefco
      contactName
      companyName
    }
    ShippingCarrier {
      id
      name
    }
    ShippingDocument {
      id
    }
    createdAt
    updatedAt
  }
`;

const addressesFragment = `
  Address {
    id
    Type {
      id
    }
    address1
    address2
    address3
    city
    state
    postal
  }
`;

const phonesFragment = `
  Phone {
    id
    number
    Type {
      id
      name
    }
  }
`;

const templateFragment = `
  id
  name
  type
  isActive
  companyName
  {{ DETAILS }}
  {{ TRANSFERS }}
  {{ ADDRESSES }}
  {{ PHONES }}
`;

const detailsRegex = /\{\{\s?DETAILS\s?\}\}/g;
const transfersRegex = /\{\{\s?TRANSFERS\s?\}\}/g;
const addressesRegex = /\{\{\s?ADDRESSES\s?\}\}/g;
const phonesRegex = /\{\{\s?PHONES\s?\}\}/g;

const nodeFragment = templateFragment
  .replace(detailsRegex, detailsFragment)
  .replace(transfersRegex, transfersFragment)
  .replace(addressesRegex, addressesFragment)
  .replace(phonesRegex, phonesFragment);

const listViewFragment = templateFragment
  .replace(detailsRegex, "")
  .replace(transfersRegex, "")
  .replace(addressesRegex, "")
  .replace(phonesRegex, "");

const listWithoutTransfers = templateFragment
  .replace(detailsRegex, detailsFragment)
  .replace(transfersRegex, "")
  .replace(addressesRegex, addressesFragment)
  .replace(phonesRegex, phonesFragment);


// export const getEvidenceStorageLocationsQuery = gql`${buildConnectionQuery("EvidenceStorageLocation", "evidenceStorageLocationConnection", nodeFragment)}`;

export const getEvidenceStorageLocationsQuery = (view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiEvidenceStorageLocationFilterType.ViewListView:
        return gql`${buildConnectionQuery("EvidenceStorageLocation", "evidenceStorageLocationConnection", listViewFragment)}`;
      case IApiEvidenceStorageLocationFilterType.ViewWithoutTransfers:
        // return gql`${buildConnectionQuery("EvidenceStorageLocation", "evidenceStorageLocationConnection", nodeFragment)}`;
        return gql`${buildConnectionQuery("EvidenceStorageLocation", "evidenceStorageLocationConnection", listWithoutTransfers)}`;

      default:
        return gql`${buildConnectionQuery("EvidenceStorageLocation", "evidenceStorageLocationConnection", nodeFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("EvidenceStorageLocation", "evidenceStorageLocationConnection", nodeFragment)}`;
  }
};

export const addEvidenceStorageLocationMutation = gql`mutation createEvidenceStorageLocation($input: AddEvidenceStorageLocationInput!) {
  addEvidenceStorageLocation(input: $input) { ${nodeFragment} }
}`;

export const toggleEvidenceStorageLocationActivationMutation = gql`mutation toggleEvidenceStorageLocationActivation($id: ID!) {
  toggleEvidenceStorageLocationActivation(id: $id)
}`;

export const removeEvidenceStorageLocationMutation = gql`mutation removeEvidenceStorageLocation($id: ID!) {
  removeEvidenceStorageLocation(id: $id)
}`;

export const updateEvidenceStorageLocation = gql`mutation updateEvidenceStorageLocation($input: UpdateEvidenceStorageLocationInput!) {
  updateEvidenceStorageLocation(input: $input) { ${nodeFragment} }
}`;


