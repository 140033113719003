import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { updateEvidenceStatusMutation } from './evidence-status.operations';
import {
  IApiEvidenceStatusFilter,
  IApiAddEvidenceStatusInput,
  IApiEvidenceStatusConnection,
  IApiUpdateEvidenceStatusInput,
  IApiRemoveEvidenceStatusInput
} from 'src/app/shared/modules/graphql/types/types';
import {
  evidenceStatusConnectionQuery,
  addEvidenceStatusMutation,
  removeEvidenceStatusMutation
} from './evidence-status.operations';

@Injectable()
export class GqlEvidenceStatusService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getEvidenceStatus(filters: IApiEvidenceStatusFilter[], options: IGqlBaseOptions): Observable<IApiEvidenceStatusConnection> {
    return this.apollo.query(connectionQuery(evidenceStatusConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addEvidenceStatus(input: IApiAddEvidenceStatusInput) {
    return this.apollo.mutate({
      mutation: addEvidenceStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeEvidenceStatus(input: IApiRemoveEvidenceStatusInput) {
    return this.apollo.mutate({
      mutation: removeEvidenceStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateEvidenceStatus(input: IApiUpdateEvidenceStatusInput) {
    return this.apollo.mutate({
      mutation: updateEvidenceStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
