import gql from 'graphql-tag';

export const generateEvidenceReportQuery = gql`query GenerateInvestigationAndEvidenceCSV($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $sortOrder: SortOrder) {
  generateInvestigationAndEvidenceCSV(filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) 
}`;

export const getEvidenceReportQuery = gql`query InvestigationMetricsConnection($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  investigationMetricsConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    pageInfo {
      limit
      offset
      __typename
    }
    totalCount
    edges {
      cursor
      node {
        id
        nefcoNumber
        status
        lossDate
        Insured {
          id
          insuredName
          Phones {
              id
              isPrimary
              number
              phoneableType
          }
          __typename
        }
        receivedDate
        RiskType {
          id
          name
          __typename
        }
        LossAddress {
          id
          isPrimary
          address1
          address2
          address3
          city
          state
          postal
          country
          __typename
        }
        Detail {
          id
          policyNumber
          claimNumber
          __typename
        }
        BillTo {
            id
            firstName
            middleName
            lastName   
            __typename
        }
        Evidence {
      id
      evidenceNumber
      description
      EvidenceStorageLocation {
        name
        Address {
          city
        }
      }
      Status
      StatusHistory {
        id
        name
        createdAt
        __typename
      }
      __typename
    }
       
        __typename
      }
      __typename
    }
    __typename
  }
}`;
