<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationClose" #investigationClose="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contacts" #contactsPerm="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationStatusList" #investigationStatusListPerm="appEnforcePermission"></div>
<!-- Permissions -->
<!-- Card used for filtering via a filter button instead of onchange -->
<div
  class="page-filters"
  [appEnforcePermission]="permissionConfig"
  #perms="appEnforcePermission"
>

<kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
  <!--START Search Company Filter -->
  <kendo-gridlayout-item>
    <!-- <app-company-select-single-kendo
      label="Search"
      placeholder="Search companies"
      [companyId]="filterValue(filterTypes.Company)"
      (selectionChanged)="setCompanySearch($event?.id)"
    >
    </app-company-select-single-kendo> -->
    <kendo-gridlayout-item>
      <app-company-select-kendo
        label="Search"
        placeholder="Search companies"
        [includeInactive]="true"
        [selectedId]="filterCompaniesValue(filterTypes.Companies)"
        (selectionChanged)="setCompanySearch($event)"
      ></app-company-select-kendo>
    </kendo-gridlayout-item>
  </kendo-gridlayout-item>
  <!-- END Search Company Filter -->

  <!--START Search Staff member Filter -->
  <kendo-gridlayout-item>
    <app-user-select-kendo
      label=""
      placeholder="Search staff member"
      [userViewFilter]="userViewFilter"
      [userId]="filterValue(filterTypes.User)"
      [includeInactive]="true"
      (selectionChanged)="setUser($event?.id)"
    ></app-user-select-kendo>
  </kendo-gridlayout-item>
  <!-- END Search Staff member Filter -->

  <!-- START Contact Filter -->
  <kendo-gridlayout-item>
    <div class="kendo-formfield left-search-icon">
        <kendo-label [for]="autoCompleteContact" text=""></kendo-label>
        <div style="position: relative;">
          <i class="fa fa-search" aria-hidden="true"></i>
          <kendo-autocomplete
            #autoCompleteContact
            [data]="contactsList"
            [value]="selectedContactName?.itemFullName || ''"
            valueField="id"
            fillMode="outline"
            [filterable]="true"
            (valueChange)="setContact($event)"
            (filterChange)="handleSearchFilter($event)"
            placeholder="Search contacts"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
            [disabled]="!contactsPerm?.enabled"
          >
            <ng-template kendoAutoCompleteItemTemplate let-dataItem>
              <span>{{ dataItem?.itemFullName }}</span>
            </ng-template>
          </kendo-autocomplete>
        </div>
    </div>
  </kendo-gridlayout-item>
  <!-- END Contact Filter -->

  <!-- START Loss city Filter -->
  <kendo-gridlayout-item>
    <div class="kendo-formfield left-search-icon">
        <kendo-label text=""></kendo-label>
        <div style="position: relative;">
          <i class="fa fa-search" aria-hidden="true"></i>
          <kendo-textbox
            [value]="filterValue(filterTypes.LossCity) || ''"
            (valueChange)="setFilters($event, filterTypes.LossCity)"
            placeholder="Search loss city"
            fillMode="outline"
            [clearButton]="false">
          </kendo-textbox>
        </div>
    </div>
  </kendo-gridlayout-item>
  <!-- END Loss city Filter -->

  <!-- START Filters / Clear -->
  <kendo-gridlayout-item class="filter-actions">
    <div class="comments-filter mr-16">
      <kendo-label text=""></kendo-label>
      <div class="comment-toggle-group">
        <kendo-button kendoButton [themeColor]="showComments === true ? 'light' : 'base'" fillMode="solid" iconClass="fa-solid fa-comment" (click)="isCommentShow(true)"></kendo-button>
        <kendo-button kendoButton [themeColor]="showComments === false ? 'light' : 'base'" fillMode="solid" iconClass="fa-solid fa-comment-slash" (click)="isCommentShow(false)"></kendo-button>
      </div>
    </div>
    <div class="applied-filter mr-16">
      <kendo-label text=""></kendo-label>
      <div>
        <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="filterTable()">Apply Filters</kendo-button>
      </div>
    </div>
    <div class="clear-filter mt-5">
      <kendo-label text=""></kendo-label>
      <div>
        <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearFilter()">Clear All</kendo-button>
      </div>
    </div>
  </kendo-gridlayout-item>
  <!-- END Filters / Clear -->

  <!-- START Insured Name Filter -->
  <kendo-gridlayout-item>
    <app-insured-multi-select-kendo
    label="Insured Name"
    placeholder="Search insured name"
    [selectedId]="filterPartiesValue(filterTypes.Insured)"
    (selectionChanged)="setInsuredSearch($event)"
    ></app-insured-multi-select-kendo>
  </kendo-gridlayout-item>
  <!-- END Insured Name Filter -->

  <!-- START State Filter -->
  <kendo-gridlayout-item>
    <div class="kendo-formfield">
        <kendo-label [for]="lossState" text="Loss State"></kendo-label>
        <kendo-dropdownlist #lossState [data]="groupedDataStates" textField="text"
            valueField="value" [valuePrimitive]="true" [value]="filterValue(filterTypes.LossState)"
            fillMode="outline" [filterable]="false" (filterChange)="handleFilter($event)"
            [defaultItem]="{value: undefined, text: 'All States', type: ''}" (selectionChange)="setLossState($event)"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
            <ng-template kendoDropDownListGroupTemplate let-groupName>
                <strong>{{ groupName }}</strong>
            </ng-template>
            <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.text }} </span>
                <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.text }} </span>
            </ng-template>
        </kendo-dropdownlist>
    </div>
  </kendo-gridlayout-item>
  <!-- END  State Filter -->

  <!-- START Risk Type Filter -->
  <kendo-gridlayout-item>
    <div class="kendo-formfield">
        <kendo-label [for]="dropdownlist" text="Risk Type"></kendo-label>
        <kendo-dropdownlist #dropdownlist [data]="riskTypes" textField="name" valueField="name" fillMode="outline"
            [valuePrimitive]="true" [defaultItem]="{name: 'All Types' }" [value]="filterValue(filterTypes.RiskType)"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" (selectionChange)="setRiskType($event)">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem?.name === 'All Types'" class="placeholder"> {{ dataItem?.name }} </span>
                <span *ngIf="dataItem?.name !== 'All Types'" class="not-placeholder"> {{ dataItem?.name }} </span>
            </ng-template>
        </kendo-dropdownlist>
    </div>
  </kendo-gridlayout-item>
  <!-- END Risk Type Filter -->

  <!-- START Source Filter -->
  <kendo-gridlayout-item>
    <div class="kendo-formfield">
        <kendo-label [for]="dropdownlist" text="Source"></kendo-label>
        <kendo-dropdownlist #dropdownlist [data]="investigationSource" textField="name" valueField="name" fillMode="outline"
            [valuePrimitive]="true" [defaultItem]="{name: 'All Sources' }" [value]="filterValue(filterTypes.InvestigationSource)"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" (selectionChange)="setInvestigationSource($event)">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem?.name === 'All Sources'" class="placeholder"> {{ dataItem?.name }} </span>
                <span *ngIf="dataItem?.name !== 'All Sources'" class="not-placeholder"> {{ dataItem?.name }} </span>
            </ng-template>
        </kendo-dropdownlist>
    </div>
  </kendo-gridlayout-item>
  <!-- END Source Filter -->

</kendo-gridlayout>

<div class="d-flex filter-checkboxes">
  <div class="d-flex align-items-center">
    <input type="checkbox" #showAll kendoCheckBox
      [checked]="filterValue(filterTypes.ActiveAndClosed)"
      (change)="setActiveAndClosed($event?.target)"/>
    <kendo-label
      class="k-checkbox-filter-label ml-12 mr-24"
      [for]="showAll"
      text="Show All"
    ></kendo-label>
  </div>

  <div class="d-flex align-items-center">
    <input type="checkbox" #Ongoing kendoCheckBox
      [checked]="filterValue(filterTypes.IsOngoing)"
      (change)="setIsOngoing($event?.target)"/>
    <kendo-label
      class="k-checkbox-filter-label ml-12 mr-24"
      [for]="Ongoing"
      text="Ongoing"
    ></kendo-label>
  </div>

  <div class="d-flex align-items-center">
    <input type="checkbox" #Incendiary kendoCheckBox
      [checked]="filterValue(filterTypes.IsIncendiary)"
      (change)="setIsIncendiary($event?.target)"/>
    <kendo-label
      class="k-checkbox-filter-label ml-12 mr-24"
      [for]="Incendiary"
      text="Incendiary"
    ></kendo-label>
  </div>

  <div class="d-flex align-items-center">
    <input type="checkbox" #onHold kendoCheckBox
      [checked]="filterValue(filterTypes.IsOnHold)"
      (change)="setIsOnHold($event?.target)"/>
    <kendo-label
      class="k-checkbox-filter-label ml-12 mr-24"
      [for]="onHold"
      text="On Hold"
    ></kendo-label>
  </div>

  <div class="d-flex align-items-center">
    <input type="checkbox" #Cancelled kendoCheckBox
      [checked]="filterValue(filterTypes.IsDeleted)"
      (change)="setCancelled($event?.target)"/>
    <kendo-label
      class="k-checkbox-filter-label ml-12 mr-24"
      [for]="Cancelled"
      text="Cancelled"
    ></kendo-label>
  </div>
</div>
<hr class="divider-line-checkbox" />

<kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
  <kendo-gridlayout-item>
    <kendo-formfield class="kendo-formfield">
          <kendo-label #advancedFilter text="Advanced filters"></kendo-label>
          <kendo-multiselect
            #advancedFilter
            [checkboxes]="true"
            [autoClose]="false"
            textField="text" 
            valueField="val"
            [valuePrimitive]="true"
            [data]="availableFilters"
            [rounded]="'full'"
            [(ngModel)]="advancedFilters"
            [placeholder]="'Select advanced filters'"
            [fillMode]="'outline'"
            [tagMapper]="tagMapper"
            [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}"
            (valueChange)="advancedFilterValueChange($event)"
          >
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <span style="padding-left: 5px;">{{ dataItem?.text }}</span>
            </ng-template>
          </kendo-multiselect>
      </kendo-formfield>
  </kendo-gridlayout-item>
</kendo-gridlayout>

<form #filterForm="ngForm">
<kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']" *ngIf="advancedFilters?.length">
  <ng-container *ngFor="let filter of advancedFiltersSorted">
  <kendo-gridlayout-item>
    <kendo-card class="advanced-filter" width="100%">
      <kendo-card-header>
        <h3 class="kendo-h3">{{getAdvancedFilterText(filter)}}</h3>
        <i class="fa-solid fa-xmark" (click)="removeFilterCard(filter)"></i>
      </kendo-card-header>
      <kendo-card-body>
        <ng-container [ngSwitch]="filter">
          <!-- Cause -->
          <ng-container *ngSwitchCase="filterTypes.Cause">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['100%']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label [for]="dropdownlist" text="Cause"></kendo-label>
                  <kendo-dropdownlist #cdropdownlist [data]="investigationCauses | keyvalue" textField="value" valueField="value"  fillMode="outline"
                      [valuePrimitive]="true" [defaultItem]="{key: undefined, value: 'All'}" [value]="filterValue(filter)"
                      [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
                      (selectionChange)="setFilters($event?.value === 'All' ? undefined : $event?.value , filter)">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                          <span *ngIf="dataItem?.value === 'All'" class="placeholder"> {{ dataItem?.value }} </span>
                          <span *ngIf="dataItem?.value !== 'All'" class="not-placeholder"> {{ dataItem?.value }} </span>
                      </ng-template>
                  </kendo-dropdownlist>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>

          <!-- CreatedAt -->
          <ng-container *ngSwitchCase="filterTypes.CreatedAt">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label class="asterisk" text="Start Date"></kendo-label>
                  <kendo-datepicker
                      id="startDate"
                      name="startDate"
                      calendarType="classic"
                      [fillMode]="'outline'"
                      placeholder="Enter date"
                      format="MM/dd/yyyy"
                      [max]="today"
                      [ngClass]="{'ng-invalid ng-touched': (!startDate?.valid || dateFilter.startDate > dateFilter.endDate) && submitted}"
                      [(ngModel)]="dateFilter.startDate"
                      (valueChange)="setDateFilter($event, 'startDate')"
                      formatPlaceholder="formatPattern"
                      required="true"
                      #startDate="ngModel"
                  ></kendo-datepicker>
                  <kendo-formerror *ngIf="!startDate?.valid && (startDate?.dirty || startDate?.touched || submitted)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="startDate?.errors?.required">
                      StartDate cannot be left blank
                    </span>
                    <span *ngIf="!startDate?.errors?.required && startDate?.errors">
                      StartDate is invalid
                    </span>
                  </kendo-formerror>
                  <kendo-formerror *ngIf="dateFilter.startDate > dateFilter.endDate  && submitted">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span>
                      StartDate cannot be greater then EndDate
                    </span>
                  </kendo-formerror>
                </div>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label class="asterisk" text="End Date"></kendo-label>
                  <kendo-datepicker
                      id="endDate"
                      name="endDate"
                      calendarType="classic"
                      [fillMode]="'outline'"
                      placeholder="Enter date"
                      [max]="today"
                      [ngClass]="{'ng-invalid ng-touched': !endDate?.valid && submitted}"
                      format="MM/dd/yyyy"
                      [(ngModel)]="dateFilter.endDate"
                      (valueChange)="setDateFilter($event, 'endDate')"
                      formatPlaceholder="formatPattern"
                      required="true"
                      #endDate="ngModel"
                  ></kendo-datepicker>
                  <kendo-formerror *ngIf="!endDate?.valid && (endDate?.dirty || endDate?.touched || submitted)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="endDate?.errors?.required">
                      EndDate cannot be left blank
                    </span>
                    <span *ngIf="!endDate?.errors?.required && endDate?.errors">
                      StartDate is invalid
                    </span>
                  </kendo-formerror>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>

          <!-- CreatedBy -->
          <ng-container *ngSwitchCase="filterTypes.CreatedBy">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['100%']">
              <kendo-gridlayout-item>
                    <app-user-select-kendo
                      label="Created By"
                      placeholder="Enter name"
                      [userViewFilter]="userViewFilter"
                      [validate]="true"
                      [formSubmitted]="submitted"
                      [userId]="filterValue(filter)"
                      [includeInactive]="true"
                      (selectionChanged)="setFilters($event?.id, filter)"
                  ></app-user-select-kendo>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>

          <!-- Document -->
          <ng-container *ngSwitchCase="filterTypes.Document">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                    <kendo-label [for]="documentType" text="Document Type"></kendo-label>
                    <kendo-dropdownlist #documentType [data]="documentTypes" textField="name" valueField="id"  fillMode="outline"
                        [valuePrimitive]="true" [defaultItem]="{id: null, name: 'All'}" [value]="documentFilter?.document || null"
                        [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required
                        (selectionChange)="setDocumentFilter(!$event?.id ? undefined : $event?.id , 'document')">
                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span *ngIf="dataItem?.name === 'All'" class="placeholder"> {{ dataItem?.name }} </span>
                            <span *ngIf="dataItem?.name !== 'All'" class="not-placeholder"> {{ dataItem?.name }} </span>
                        </ng-template>
                    </kendo-dropdownlist>
                  </div>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item style="position: relative;">
                <div class="kendo-formfield">
                  <kendo-label text=""></kendo-label>
                  <div style="position: absolute; bottom: 0">
                    <input type="checkbox" #Shared kendoCheckBox
                      name="show-shared-only"
                      [(ngModel)]="documentFilter.shared"
                      (change)="setDocumentFilter($event?.target?.checked, 'shared')"/>
                    <kendo-label
                      class="k-checkbox-filter-label ml-12 mr-24"
                      [for]="Shared"
                      text="Show Shared Only"
                    ></kendo-label>
                  </div>
                </div>
              </kendo-gridlayout-item>

              <ng-container *ngIf="isReport">
                <kendo-gridlayout-item>
                  <div class="kendo-formfield">
                    <kendo-label [for]="reportStatusSelect" text="Report Status"></kendo-label>
                    <kendo-dropdownlist #reportStatusSelect [data]="reportStatuses | keyvalue" textField="value" valueField="value"  fillMode="outline"
                        [valuePrimitive]="true" [defaultItem]="{key: null, value: 'All'}" [value]="documentFilter?.status || null"
                        [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
                        (selectionChange)="setDocumentFilter($event?.value !== 'All' ? $event?.value : undefined , 'status')">
                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span *ngIf="dataItem?.value === 'All'" class="placeholder"> {{ dataItem?.value }} </span>
                            <span *ngIf="dataItem?.value !== 'All'" class="not-placeholder"> {{ dataItem?.value }} </span>
                        </ng-template>
                    </kendo-dropdownlist>
                  </div>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                  <app-user-select-kendo
                    label="Search for a user"
                    [disabled]="!documentFilter?.status"
                    placeholder="Enter name"
                    [userViewFilter]="userViewFilter"
                    [userId]="documentFilter.user"
                    [includeInactive]="true"
                    (selectionChanged)="setDocumentFilter($event?.id, 'user')"
                    [investigationRole]="getDocumentSelectInvestigationRole(documentFilter?.status)"
                  ></app-user-select-kendo>
                </kendo-gridlayout-item>
              </ng-container>
            </kendo-gridlayout>
          </ng-container>

          <!-- InvestigationRole -->
          <ng-container *ngSwitchCase="filterTypes.InvestigationRole">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label [for]="reportStatusSelect" text="Investigation Role"></kendo-label>
                  <kendo-dropdownlist #reportStatusSelect [data]="investigationRoles" textField="title" valueField="id"  fillMode="outline"
                    [valuePrimitive]="true" [defaultItem]="{id: null, title: 'All'}" [value]="filterValue(filter)"
                    [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
                    (selectionChange)="setFilters($event?.id ? $event?.id : undefined , filter)">
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span *ngIf="dataItem?.title === 'All'" class="placeholder"> {{ dataItem?.title }} </span>
                        <span *ngIf="dataItem?.title !== 'All'" class="not-placeholder"> {{ dataItem?.title }} </span>
                    </ng-template>
                  </kendo-dropdownlist>
                </div>
            </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>

          <!-- Party -->
          <ng-container *ngSwitchCase="filterTypes.Party">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label [for]="Role" text="Role"></kendo-label>
                  <kendo-dropdownlist #Role [data]="contactRoles" textField="name" valueField="id"  fillMode="outline"
                    [valuePrimitive]="true" [defaultItem]="{id: null, name: 'All'}" [(ngModel)]="selectedRoleParty"
                    [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" name="contactRoles" >
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span *ngIf="dataItem?.name === 'All'" class="placeholder"> {{ dataItem?.name }} </span>
                        <span *ngIf="dataItem?.name !== 'All'" class="not-placeholder"> {{ dataItem?.name }} </span>
                    </ng-template>
                  </kendo-dropdownlist>
                </div>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item>
                <app-contact-select-kendo
                  label=""
                  [disabled]="!selectedRoleParty"
                  placeholder="Search contacts"
                  [contactId]="filterValue(filter)"
                  [contactRole]="selectedRoleParty"
                  (selectionChanged)="setFilters($event?.id, filter)"
                ></app-contact-select-kendo>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>

          <!-- Priority -->
          <ng-container *ngSwitchCase="filterTypes.Priority">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label [for]="Priority" text="Priority"></kendo-label>
                  <kendo-dropdownlist #Priority [data]="InvestigationPriorities | keyvalue" textField="value" valueField="value"  fillMode="outline"
                    [valuePrimitive]="true" [defaultItem]="{key: null, value: 'All'}" [value]="filterValue(filter)"
                    [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
                    (selectionChange)="setFilters($event?.value === 'All' ? undefined : $event?.value, filter)">
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span *ngIf="dataItem?.value === 'All'" class="placeholder"> {{ dataItem?.value }} </span>
                        <span *ngIf="dataItem?.value !== 'All'" class="not-placeholder"> {{ dataItem?.value }} </span>
                    </ng-template>
                  </kendo-dropdownlist>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>

          <!-- Service -->
          <ng-container *ngSwitchCase="filterTypes.Service">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label [for]="Service" text="Service"></kendo-label>
                  <kendo-dropdownlist #Service [data]="serviceTypeList" textField="name" valueField="id"  fillMode="outline"
                      [valuePrimitive]="true" [defaultItem]="{id: null, name: 'All'}" [value]="serviceFilter.service || null"
                      [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required
                      (selectionChange)="setServiceFilter($event?.id, 'service')">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                          <span *ngIf="dataItem?.name === 'All'" class="placeholder"> {{ dataItem?.name }} </span>
                          <span *ngIf="dataItem?.name !== 'All'" class="not-placeholder"> {{ dataItem?.name }} </span>
                      </ng-template>
                  </kendo-dropdownlist>
                </div>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item style="position: relative;">
                <div class="kendo-formfield">
                  <kendo-label text=""></kendo-label>
                  <div style="position: absolute; bottom: 0">
                    <input type="checkbox" #ServiceCheckbox kendoCheckBox
                      [disabled]="!serviceFilter?.service"
                      name="serviceStatus"
                      [(ngModel)]="serviceFilter.complete"
                      (change)="setServiceFilter($event?.target?.checked, 'complete')"/>
                    <kendo-label
                      class="k-checkbox-filter-label ml-12 mr-24"
                      [for]="ServiceCheckbox"
                      text="Status"
                    ></kendo-label>
                  </div>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <app-user-select-kendo
                  label="Search for a user"
                  placeholder="Enter name"
                  [userViewFilter]="userViewFilter"
                  [userId]="serviceFilter.user"
                  (selectionChanged)="setServiceFilter($event?.id, 'user')"
                ></app-user-select-kendo>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>

          <!-- Status -->
          <ng-container *ngSwitchCase="filterTypes.Status">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label [for]="Status" text="Status"></kendo-label>
                  <kendo-dropdownlist #Status [data]="investigationStatuses | keyvalue" textField="value" valueField="value"  fillMode="outline"
                      [valuePrimitive]="true" [defaultItem]="{key: null, value: 'All'}" [value]="filterValue(filter) || null"
                      [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required
                      (selectionChange)="setFilters($event?.value !== 'All' ? $event?.value : undefined , filter)" [disabled]="!investigationStatusListPerm?.enabled">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                          <span *ngIf="dataItem?.value === 'All'" class="placeholder"> {{ dataItem?.value }} </span>
                          <span *ngIf="dataItem?.value !== 'All'" class="not-placeholder"> {{ dataItem?.value }} </span>
                      </ng-template>
                  </kendo-dropdownlist>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>

          <!-- ServiceStatus -->
          <ng-container *ngSwitchCase="filterTypes.ServiceStatus">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label [for]="serviceStatus" text="Service"></kendo-label>
                  <kendo-dropdownlist #serviceStatus [data]="serviceTypeList" textField="name" valueField="id"  fillMode="outline"
                      [valuePrimitive]="true" [defaultItem]="{id: null, name: 'All'}" [value]="serviceStatusFilter.service || null"
                      [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required
                      (selectionChange)="setServiceStatusFilter($event?.id, 'service')">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                          <span *ngIf="dataItem?.name === 'All'" class="placeholder"> {{ dataItem?.name }} </span>
                          <span *ngIf="dataItem?.name !== 'All'" class="not-placeholder"> {{ dataItem?.name }} </span>
                      </ng-template>
                  </kendo-dropdownlist>
                </div>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item style="position: relative;">
                <div class="kendo-formfield">
                  <kendo-label text=""></kendo-label>
                  <div style="position: absolute; bottom: 0">
                    <input type="checkbox" #serviceStatusCheckbox
                      kendoCheckBox
                      [disabled]="!serviceStatusFilter?.service"
                      name="serviceWithStatus"
                      [(ngModel)]="serviceStatusFilter.complete"
                      (change)="setServiceStatusFilter($event?.target?.checked, 'complete')"/>
                    <kendo-label
                      class="k-checkbox-filter-label ml-12 mr-24"
                      [for]="serviceStatusCheckbox"
                      text="Status"
                    ></kendo-label>
                  </div>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>

          <!-- Street Address -->
          <ng-container *ngSwitchCase="filterTypes.StreetAddress">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield left-search-icon">
                  <kendo-label text="Street Address"></kendo-label>
                  <div style="position: relative;">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <kendo-textbox
                      [value]="filterValue(filterTypes.StreetAddress) || ''"
                      (valueChange)="setFilters($event, filterTypes.StreetAddress)"
                      placeholder="Enter street address"
                      fillMode="outline"
                      [clearButton]="false">
                    </kendo-textbox>
                  </div>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </ng-container>

        </ng-container>
      </kendo-card-body>
      </kendo-card>
    </kendo-gridlayout-item>
    </ng-container>
</kendo-gridlayout>
</form>
</div>
