import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {

  public settings = {
    title: "Heads up!",
    message: "",
    material_ligature: "info",
    buttons: []
  };

  constructor(
      public dialogRef: MatDialogRef<NotificationModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    // now that we have a dialogRef, setup the default Ok button with a call back to close
    this.settings.buttons.push({
        caption: "Ok",
        callback: () => { this.dialogRef.close(true); },
      material_ligature: ""
    });

    // overwrite defaults with data passed into the constructor
    this.settings = { ...this.settings, ...data};
  }

  ngOnInit() {

    setTimeout(() => {
      const elem = document.getElementsByClassName("notifications-button");
      ((elem as any).item(elem.length - 1)).focus();
    }, 500);
  }

}
