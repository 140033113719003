import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiAddInvestigationHistoryInput, IApiUpdateInvestigationInput } from './../../../../shared/modules/graphql/types/types';
import { Component, Inject } from '@angular/core';

@Component({
  templateUrl: './investigation-history-modal.component.html',
  styleUrls: ['./investigation-history-modal.component.scss']
})
export class InvestigationHistoryModalComponent {

  public categories = Object.values(IApiInvestigationUpdateCategories);
  public types = Object.values(IApiInvestigationUpdateTypes);

  public model: IApiAddInvestigationHistoryInput;
  public noteOnly = false;

  constructor(
    public dialogRef: MatDialogRef<InvestigationHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      investigation: IApiUpdateInvestigationInput, 
      selectedCategory: IApiInvestigationUpdateCategories, 
      selectedType: IApiInvestigationUpdateTypes,
      noteOnly: boolean,
    }
  ) {
    const { selectedCategory, selectedType, investigation: { id }, noteOnly = false } = data;
    this.model = {
      InvestigationId: id,
      updateCategory: selectedCategory,
      updateType: selectedType,
      comment: null
    };
    this.noteOnly = noteOnly;
  }

  save() {
    this.dialogRef.close(this.model);
  }

}
