import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "src/app/shared/shared.module";
import {
  NotificationsManagementComponent,
} from './';

@NgModule({
  declarations: [NotificationsManagementComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class NotificationsModule { }
