import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsDeterminationConnection, IApiInvestigationMetricsDetermination } from 'src/app/shared/modules/graphql/types/types';
import { InvestigationMetricsDeterminationService } from './investigation-metrics-determination.service';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class InvestigationMetricsDeterminationDatasource extends NefcoPagedDataSource<IApiInvestigationMetricsDetermination, InvestigationMetricsDeterminationService, IApiInvestigationMetricsDeterminationConnection> implements DataSource<IApiInvestigationMetricsDetermination> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsDeterminationConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
