<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationDocumentShareListView"
  #investigationDocumentShareListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentsListView"
  #investigationDocumentsListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentShareCreate"
  #investigationDocumentShareCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentsCreate"
  #investigationDocumentsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentsDelete"
  #investigationDocumentsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentsDownload"
  #investigationDocumentsDownload="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationReportsList" #investigationReportsList="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationReportsView" #investigationReportsView="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationReportsCreate" #investigationReportsCreate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationReportsUpdate" #investigationReportsUpdate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationReportsAssignEditorUpdate"
  #investigationReportsAssignEditorUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationReportsInTechReviewUpdate"
  #investigationReportsInTechReviewUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationReportsInEditorReviewUpdate"
  #investigationReportsInEditorReviewUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationReportsDelete" #investigationReportsDelete="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationReportsDeleteOwn"
  #investigationReportsDeleteOwn="appEnforcePermission"></div>
<!-- Permissions -->
<section class="mat-typography document-section">

  <!-- Shared Documents -->
  <kendo-panelbar *ngIf="investigationDocumentShareListView?.enabled">
    <kendo-panelbar-item [expanded]="true">
      <ng-template kendoPanelBarItemTitle>
        <h3 class="mb-0">Shared Documents</h3>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <div class="pl-24 pr-24">
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <div class="kendo-formfield left-search-icon">
                <kendo-label text=""></kendo-label>
                <div style="position: relative;">
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <kendo-textbox placeholder="Search by document name" fillMode="outline" [(ngModel)]="searchTerm"
                    [clearButton]="false">
                  </kendo-textbox>
                </div>
              </div>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
          <kendo-grid class="shared-document-list"
            [navigatable]="false" [data]="{
              data: sharedDocs,
              total: (dataSourceShared?.pageInfo$ | async)?.totalCount
            }"
            [pageSize]="pageOptionsShared?.limit"
            [skip]="pageOptionsShared?.offset"
            [pageable]=" pageOptionsShared
              ? {
                  buttonCount: 6,
                  type: 'numeric',
                  pageSizes: [5, 10, 25, 100],
                  previousNext: true,
                  responsive: true,
                  position: 'bottom'
                }
              : false
          "(pageChange)="pageChangeShared($event)"
          [sortable]="true"
          [sort]="sortShared"
          (sortChange)="sortChangeShared($event)"
          [navigatable]="false"
          [resizable]="true"
          [scrollable]="true">

            <kendo-grid-column field="CREATED_AT" title="Date Uploaded" [width]="10" [headerClass]="'sort-header'"
              class="font-weight600">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.createdAt | customDate: 'MM/dd/yyyy' }}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Document Name" [width]="30" [sortable]="false" [headerClass]="'nocursor'"
            class="font-weight600">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem?.Document?.s3Uri && !dataItem?.Document?.uploadError"><a target="_blank" [href]="dataItem?.Document?.s3Uri | s3">{{ dataItem.Document.title || '-' }}</a></span>
                <span *ngIf="dataItem?.Document?.uploadError">{{ dataItem.Document?.uploadError || '-' }}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Shared" [width]="30" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ (dataItem.User | fullName) || '-' }}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Parties" [width]="30" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <section class="d-block" *ngIf="dataItem.Client">
                  Client: {{ dataItem.Client | fullName }}
                </section>
                <section class="d-block" *ngIf="dataItem.BillTo">
                  BillTo: {{ dataItem.BillTo | fullName }}
                </section>
                <section class="d-block" *ngIf="dataItem.Insured">
                  Insured: {{ dataItem.Insured | insuredName }}
                </section>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>

  <!-- Shared Reports -->
  <kendo-panelbar class="mt-16" *ngIf="investigationDocumentShareListView?.enabled">
    <kendo-panelbar-item [expanded]="true">
      <ng-template kendoPanelBarItemTitle>
        <h3 class="mb-0">Shared Reports</h3>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <div class="pl-24 pr-24">
          <kendo-grid class="shared-reports" [navigatable]="false" [data]="{
                data: sharedReportList,
                total: (dataSource?.pageInfo$ | async)?.totalCount
              }" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="
                pageOptions
                  ? {
                      buttonCount: 6,
                      type: 'numeric',
                      pageSizes: [5, 10, 25, 100],
                      previousNext: true,
                      responsive: true,
                      position: 'bottom'
                    }
                  : false
              " (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)"
            [navigatable]="false" [resizable]="true" [scrollable]="true">
            <!-- Send Date -->
            <kendo-grid-column field="SEND_DATE" title="Send Date" [width]="15" [headerClass]="'sort-header'"
              class="font-weight600">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem?.sendDate | customDate : "MM/dd/yyyy"}}
              </ng-template>
            </kendo-grid-column>
            <!-- Client (Contact) -->
            <kendo-grid-column field="NAME" title="Client (Contact)" [width]="15" [sortable]="true"
              class="font-weight600" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="">
                  {{ dataItem?.name}}<br />
                </span>
                <span class="phone-number" *ngIf="dataItem?.companyName">{{ dataItem?.companyName }}</span>
              </ng-template>
            </kendo-grid-column>

            <!-- Name -->
            <kendo-grid-column field="NAME" title="Name" [width]="20" class="font-weight600 text-decoration"
              [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem?.Document?.title || dataItem?.DocumentType?.name || '' }}
              </ng-template>
            </kendo-grid-column>

            <!-- Note -->
            <kendo-grid-column field="NAME" title="Note" [width]="25" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem?.note}}
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>

  <!-- Combine to PDF Button -->
  <div class="actionWrapper reverse mt-30">
    <kendo-button kendoButton [disabled]="checkedDownloads.length + checkedUploads.length + checkedReports.length < 2"
      themeColor="primary" fillMode="solid" size="medium" (click)="templateDownload()">combine to pdf</kendo-button>
    <kendo-button kendoButton [disabled]="checkedDownloads.length + checkedUploads.length + checkedReports.length < 1"
      class="mr-16" themeColor="primary" fillMode="solid" size="medium" (click)="markAsSent()"
      *ngIf="investigationDocumentShareCreate?.enabled">Mark as Sent</kendo-button>
  </div>

  <!-- Downloads -->
  <kendo-panelbar class="mt-16">
    <kendo-panelbar-item [expanded]="true">
      <ng-template kendoPanelBarItemTitle>
        <h3 class="mb-0">Downloads</h3>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <div class="pl-24 pr-24">
          <kendo-grid class="document-download-list" [selectable]="{ checkboxOnly: true, mode: 'multiple' }"
            [data]="downloadTypes" [sortable]="true" [navigatable]="false">
            <kendo-grid-checkbox-column [width]="5" [showSelectAll]="true">
              <ng-template kendoGridHeaderTemplate="" let-dataItem let-rowIndex="rowIndex">
                <input type="checkbox" kendoCheckBox [checked]="
                downloadTypes.length &&
                downloadTypes.length === checkedDownloads.length
              " (change)="checkAll($event.target, downloadTypes)" />
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <input type="checkbox" kendoCheckBox [checked]="dataItem.checked"
                  (change)="dataItem.checked = !dataItem.checked" />
              </ng-template>
            </kendo-grid-checkbox-column>
            <kendo-grid-column title="Document Name" [width]="90" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem.name || '-' }}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Action" [width]="5" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <button *ngIf="investigationDocumentsDownload?.enabled" kendoButton themeColor="solid" fillMode="link"
                  iconClass="fa fa-download" (click)="templateDownload(dataItem.id)"></button>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>

  <!-- Uploads -->
  <kendo-panelbar class="mt-16">
    <kendo-panelbar-item [expanded]="true">
      <ng-template kendoPanelBarItemTitle>
        <h3 class="mb-0">Uploads</h3>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <div class="pl-24 pr-24">
          <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
            [cols]="['repeat(6,minmax(0,1fr))']" *ngIf="investigationDocumentsCreate?.enabled">
            <kendo-gridlayout-item>
              <div class="kendo-formfield">
                <kendo-label [for]="documentType" text="Document Type"></kendo-label>
                <kendo-dropdownlist #documentType [data]="uploadTypes" textField="name" valueField="id"
                  fillMode="outline" [valuePrimitive]="true" [defaultItem]="{ id: null, name: 'Document Type' }"
                  [(ngModel)]="selectedUploadTypeId" (valueChange)="autofillDocTitle()" [popupSettings]="{
                    appendTo: 'root',
                    animate: false,
                    width: 250
                  }">
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="!dataItem?.id" class="placeholder">
                      {{ dataItem.name }}
                    </span>
                    <span *ngIf="dataItem?.id" class="not-placeholder">
                      {{ dataItem.name }}
                    </span>
                  </ng-template>
                  <!-- <ng-template kendoDropDownListNoDataTemplate> </ng-template> -->
                </kendo-dropdownlist>
              </div>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [colSpan]="'2'">
              <div class="kendo-formfield">
                <kendo-label class="asterisk" text="Document Title"></kendo-label>
                <kendo-textbox placeholder="Enter document title" fillMode="outline" #docTitle [(ngModel)]="docTitleValue"
                  [ngModelOptions]="{standalone: true}" required>
                </kendo-textbox>
              </div>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="button-grid-item">
              <button kendoButton *ngIf="selectedUploadTypeId && docTitleValue" themeColor="primary" fillMode="solid" class="uppercase" (click)="fileSelector()">select file</button>
              <button kendoButton *ngIf="!(selectedUploadTypeId && docTitleValue)" [disabled]="true" themeColor="primary" fillMode="solid" class="uppercase">select file</button>
            </kendo-gridlayout-item>
          </kendo-gridlayout>

          <kendo-grid class="document-upload-list" [data]="{
            data: uploads,
            total: (dataSourceUpload?.pageInfo$ | async)?.totalCount}"
            [sortable]="true"
            [navigatable]="false"
            [pageSize]="pageOptionsUpload?.limit"
            [skip]="pageOptionsUpload?.offset"
            [pageable]="pageOptionsUpload
              ? {
                buttonCount: 6,
                type: 'numeric',
                pageSizes: [5, 10, 25, 100],
                previousNext: true,
                responsive: true,
                position: 'bottom'
              }
            : false
          "(pageChange)="pageChangeUpload($event)"
          [selectable]="{ checkboxOnly: true, mode: 'multiple' }"
          [sortable]="true"
          [sort]="sortUpload"
          (sortChange)="sortChangeUpload($event)"
          >
            <kendo-grid-checkbox-column [width]="5" [showSelectAll]="true">
              <ng-template kendoGridHeaderTemplate="" let-dataItem let-rowIndex="rowIndex">
                <input type="checkbox" kendoCheckBox [checked]="uploads.length && uploads.length === checkedUploads.length
                "(change)="checkAll($event.target, uploads)" />
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <input type="checkbox" kendoCheckBox [checked]="dataItem.checked"
                  (change)="dataItem.checked = !dataItem.checked" />
              </ng-template>
            </kendo-grid-checkbox-column>

            <kendo-grid-column title="Date Uploaded" field="CREATED_AT" [width]="10" [sortable]="true" class="font-weight600"
              [headerClass]="'sort-header'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.createdAt | customDate: 'MM/dd/yyyy' }}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Document Type" [width]="30" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.Type?.name || '-' }}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Document Name" [width]="30" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ (dataItem.caption || dataItem.title || dataItem.uri) || '-' }}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Uploaded By" [width]="30" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span> {{ dataItem?.CreatedBy | fullName }} </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Actions" [width]="10" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <button *ngIf="investigationDocumentsDownload?.enabled" kendoButton themeColor="solid" fillMode="link"
                  iconClass="fa fa-download" (click)="openInNewTab(dataItem?.s3Uri)"></button>
                <button (click)="deleteUpload(dataItem.id)" *ngIf="investigationDocumentsDelete?.enabled" kendoButton
                  themeColor="solid" fillMode="link" iconClass="fa fa-trash"></button>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>

  <!-- Reports -->
  <kendo-panelbar class="mt-16" *ngIf="investigationReportsList?.enabled">
    <kendo-panelbar-item [expanded]="true">
      <ng-template kendoPanelBarItemTitle>
        <h3 class="mb-0">Reports</h3>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <div class="pl-24 pr-24">
          <div class="report-options" *ngIf="investigationReportsCreate?.enabled">
            <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(6,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label [for]="reportType" text="Report Type"></kendo-label>
                  <kendo-dropdownlist #reportType [data]="reportTypes" textField="name" valueField="id"
                    fillMode="outline" [valuePrimitive]="true" [defaultItem]="{ id: null, name: 'Select report type' }"
                    [(ngModel)]="selectedReportTypeId"
                    [popupSettings]="{
                      appendTo: 'root',
                      animate: false,
                      width: 250
                    }">
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span *ngIf="!dataItem?.id" class="placeholder">
                        {{ dataItem.name }}
                      </span>
                      <span *ngIf="dataItem?.id" class="not-placeholder">
                        {{ dataItem.name }}
                      </span>
                    </ng-template>
                    <ng-template kendoDropDownListNoDataTemplate> </ng-template>
                  </kendo-dropdownlist>
                </div>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="button-grid-item">
                <div>
                  <kendo-button kendoButton themeColor="primary" [disabled]="!selectedReportTypeId" fillMode="solid"
                    size="medium" (click)="createReport()">Create Report</kendo-button>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </div>

          <kendo-grid class="document-report-list" [data]="reports" [pageable]="false" [sortable]="false"
            [navigatable]="false" [selectable]="{ checkboxOnly: true, mode: 'multiple' }" kendoGridSelectBy="id">
            <kendo-grid-checkbox-column [width]="5" [showSelectAll]="true">
              <ng-template kendoGridHeaderTemplate="" let-dataItem let-rowIndex="rowIndex">
                <input type="checkbox" kendoCheckBox [checked]="
                finalReports.length &&
                finalReports.length === checkedReports.length
              " (click)="checkAll($event.target, finalReports)" />
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <ng-container *ngIf="dataItem?.Status.status === IInvestigationReportStatus.Final">
                  <input type="checkbox" kendoCheckBox [checked]="dataItem.checked"
                    (change)="dataItem.checked = !dataItem.checked" />
                </ng-container>
              </ng-template>
            </kendo-grid-checkbox-column>
            <kendo-grid-column field="DOCUMENT_NAME" title="Document Name" [width]="20" class="id-column"
              [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <a appEventLink class="text-decoration" (click)="showLaunchReport(dataItem)"
                  [innerHTML]="dataItem.title" *ngIf="investigationReportsView?.enabled else viewTitle"></a>
                <ng-template #viewTitle>
                  {{dataItem.title}}
                </ng-template>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="CREATED_BY" title="Created By" [width]="20" [sortable]="false"
              [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.CreatedBy | fullName }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="STATUS" title="Status" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <app-report-status-icon-kendo
                  [snapshot]="dataItem?.History.slice(-1).pop()"></app-report-status-icon-kendo>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="LAST_UPDATE" title="Last Update" [width]="20" [sortable]="false"
              [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem?.updatedAt | customDate : "MM/dd/yyyy" }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="UPDATED_BY" title="Updated By" [width]="20" [sortable]="false"
              [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem?.History.slice(-1).pop().CreatedBy | fullName }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="EDITOR" title="Editor" [width]="27" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-gridlayout
                  *ngIf="investigationReportsAssignEditorUpdate?.enabled && [IInvestigationReportStatus.Final, IInvestigationReportStatus.ReadyForEditor, IInvestigationReportStatus.EditorReview, IInvestigationReportStatus.ClientReview].includes(dataItem?.Status.status); else textEditorDiv"
                  style="padding-right: 5px;" [rows]="['auto']" [cols]="['100%']">
                  <kendo-gridlayout-item class="mb-0">
                    <div class="editor-select">
                      <app-user-select-kendo label="" [userViewFilter]="userViewFilter" placeholder="Search editor"
                        [includeInactive]="false" [userId]="dataItem?.Editor?.id" [investigationRole]="userRoleName"
                        (selectionChanged)="updateEditorInReport($event, dataItem)"></app-user-select-kendo>
                    </div>
                  </kendo-gridlayout-item>
                </kendo-gridlayout>
                <ng-template #textEditorDiv> {{(dataItem?.Editor | fullName) || '-'}}</ng-template>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="ACTIONS" title="Actions" [width]="20" [sortable]="false"
              [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="action-span">
                  <a class="mr-16" (click)="showLaunchReport(dataItem)" target="_blank"
                    *ngIf="investigationReportsView?.enabled">
                    <i class="fa fa-link"></i>
                  </a>
                  <a class="mr-16" (click)="openReport(dataItem)" target="_blank"
                    *ngIf="(investigationReportsInTechReviewUpdate?.enabled && [IInvestigationReportStatus.TechReview, IInvestigationReportStatus.TechReview_2].includes(dataItem?.Status.status))
                    || (investigationReportsInEditorReviewUpdate?.enabled && [IInvestigationReportStatus.EditorReview, IInvestigationReportStatus.ClientReview].includes(dataItem?.Status.status))
                    || (![IInvestigationReportStatus.TechReview, IInvestigationReportStatus.TechReview_2, IInvestigationReportStatus.EditorReview, IInvestigationReportStatus.ClientReview].includes(dataItem?.Status.status) && investigationReportsUpdate?.enabled)">
                    <i class="fa fa-external-link"></i>
                  </a>
                  <a (click)="deleteDraft(dataItem)" target="_blank"
                    *ngIf="[IInvestigationReportStatus.Draft].includes(dataItem?.Status.status) && (investigationReportsDelete?.enabled || (investigationReportsDeleteOwn?.enabled && dataItem?.CreatedBy?.id === user?.id))">
                    <i class="fa-solid fa-trash"></i>
                  </a>
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</section>
