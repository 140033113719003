import { IGqlBaseOptions } from './../../../modules/graphql/interfaces/base.interfaces';
import { IApiUpdateDocumentTypeInput, IApiDocumentType, IApiAddDocumentTypeInput, IApiDocumentTypeFilter, IApiDocumentTypeConnection } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { GqlDocumentTypeService } from '../../../modules/graphql/services';
import { Injectable } from '@angular/core';
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class DocumentTypeService {

  constructor(
    private documentTypes: GqlDocumentTypeService,
  ) { }

  public get(filters?: IApiDocumentTypeFilter[], options?: IGqlBaseOptions): Observable<IApiDocumentTypeConnection> { 
    return this.documentTypes.getTypes(filters, options || new ListPage());
  }

  public add(typeInput: IApiAddDocumentTypeInput): Observable<IApiDocumentType> {
    return this.documentTypes.addType(typeInput);
  }

  public update(typeInput: IApiUpdateDocumentTypeInput): Observable<IApiDocumentType> {
    return this.documentTypes.updateType(typeInput);
  }

  public remove(typeId: string): Observable<boolean> {
    return this.documentTypes.removeType(typeId);
  }
}
