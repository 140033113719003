import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  Evidence {
    id
  }
  comment
  requiresFollowup
  CreatedBy {
    id
    firstName
    lastName
  }
  createdAt
  updatedAt
`;

export const evidenceStatusConnectionQuery = gql`${buildConnectionQuery("EvidenceStatus", "evidenceStatusConnection", nodeFragment)}`;

export const addEvidenceStatusMutation = gql`mutation createEvidenceStatus($input: AddEvidenceStatusInput!) {
  addEvidenceStatus(input: $input) { ${ nodeFragment } }
}`;

export const removeEvidenceStatusMutation = gql`mutation removeEvidenceStatus($input: RemoveEvidenceStatusInput!) {
  removeEvidenceStatus(input: $input) { ${ nodeFragment } }
}`;

export const updateEvidenceStatusMutation = gql`mutation updateEvidenceStatus($input: UpdateEvidenceStatusInput!) {
  updateEvidenceStatus(input: $input) { ${ nodeFragment } }
}`;
