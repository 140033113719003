<kendo-card class="intake-card" width="100%">
    <h1>Loss Information</h1>
    <p>Provide information about the contents of the assignment. Please provide as much detail as you can regarding the
        location and type of loss. Also provide the details of the fire marshal who attended the scene to ensure we can
        contact them to get more details.</p>
    <form [formGroup]="lossInformation" autocomplete="off">

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['42%', '16%' ,'42%']">
            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label [for]="claimNumber" text="Claim Number">
                        <span class="tooltip-with-icon" kendoTooltip position="top"
                        title="Full claim number provided by your insurer.">
                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                        </span>
                    </kendo-label>
                    <kendo-textbox #claimNumber formControlName="claimNumber" placeholder="Enter claim number"
                        fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.claimNumber?.errors?.required">Claim number cannot be left blank</span>
                        <span *ngIf="lossInformation?.controls?.claimNumber?.errors?.atLeastOneRequired">Please fill out
                            either the claim or policy number</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="claim-or-policy">
                <strong>or</strong>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label [for]="policyNumber" text="Policy Number">
                        <span class="tooltip-with-icon" kendoTooltip position="top"
                        title="Active policy number provided by your insurer.">
                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                        </span>
                    </kendo-label>
                    <kendo-textbox #policyNumber formControlName="policyNumber" placeholder="Enter policy number"
                        fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.policyNumber?.errors?.required">Policy number cannot be left blank</span>
                        <span *ngIf="lossInformation?.controls?.policyNumber?.errors?.atLeastOneRequired">Please fill
                            out either the claim or policy number</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>
        </kendo-gridlayout>

        <hr />

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 17}" [cols]="['50%', '50%']">
            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label class="asterisk" text="Loss Date"></kendo-label>
                    <kendo-datepicker
                        calendarType="classic"
                        [fillMode]="'outline'"
                        formControlName="lossDate"
                        placeholder="Enter date"
                        format="MM/dd/yyyy"
                        formatPlaceholder="formatPattern"
                        [disabledDates]="disabledDates"
                    ></kendo-datepicker>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.lossDate?.errors?.required">Loss Date cannot be left
                            blank</span>
                        <span *ngIf="lossInformation?.controls?.lossDate?.errors?.disabledDate">Loss Date
                            cannot be future</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label text="Loss Time"></kendo-label>
                    <kendo-timepicker formControlName="lossTime" format="hh:mm a" placeholder="Enter time" #lossTime></kendo-timepicker>
                    <kendo-formhint>(ie, HH:MM)</kendo-formhint>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.lossTime?.errors?.required">Loss Time cannot be left blank</span>
                        <span *ngIf="lossInformation?.controls?.lossTime?.errors?.invalidTime">Invalid Loss Time</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [cols]="['100%']" class="loss-type">
            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label class="asterisk" text="Risk Type"></kendo-label>
                    <ng-container *ngFor="let item of riskTypeList">
                        <kendo-button [selected]="lossInformation?.controls?.RiskTypeId?.value === item?.id" fillMode="outline"
                            size="small" [imageUrl]="getIcon(item?.name)" (click)="setLossTpe(item?.id, item?.name)">{{item?.name}}</kendo-button>
                    </ng-container>
                </kendo-formfield>
                <kendo-formerror *ngIf="lossInformation?.controls?.RiskTypeId?.touched && lossInformation?.controls?.RiskTypeId?.errors?.required" style="margin-top: 0">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="lossInformation?.controls?.RiskTypeId?.errors?.required">Risk Type cannot be left
                        blank</span>
                </kendo-formerror>
            </kendo-gridlayout-item>
        </kendo-gridlayout>

        <ng-container
            *ngIf="selectedLossType === 'Auto' || selectedLossType === 'Boat' || selectedLossType === 'Heavy Equipment'">

            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 26}" [cols]="['47%', '47%']">
                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label [for]="vehicleMake" text="Vehicle Make"></kendo-label>
                        <kendo-textbox #vehicleMake formControlName="vehicleMake" placeholder="Enter make"
                            fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                        <kendo-formerror>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="lossInformation?.controls?.vehicleMake?.errors?.required">Vehicle Make cannot
                                be
                                left blank</span>
                        </kendo-formerror>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label [for]="vehicleModel" text="Vehicle Model"></kendo-label>
                        <kendo-textbox #vehicleModel formControlName="vehicleModel" placeholder="Enter model"
                            fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                        <kendo-formerror>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="lossInformation?.controls?.vehicleModel?.errors?.required">Vehicle Model cannot
                                be
                                left blank</span>
                        </kendo-formerror>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label [for]="vehicleYear" text="Vehicle Year"></kendo-label>
                        <kendo-dropdownlist #vehicleYear [data]="yearListFiltered" textField="text" valueField="value"
                            formControlName="vehicleYear" [valuePrimitive]="true" fillMode="outline"
                            [filterable]="false" (filterChange)="handleYearFilter($event)" [defaultItem]="{value: '', text: 'Select year'}"
                            [popupSettings]="{appendTo: 'component', animate: false}">
                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.text }} </span>
                                <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.text }} </span>
                            </ng-template>
                        </kendo-dropdownlist>
                        <kendo-formerror>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="lossInformation?.controls?.vehicleYear?.errors?.required">Vehicle Year cannot
                                be
                                left blank</span>
                        </kendo-formerror>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label [for]="vehicleVinOrOtherId" text="Vehicle VIN or Other ID"></kendo-label>
                        <kendo-textbox #vehicleVinOrOtherId formControlName="vehicleVinOrOtherId"
                            placeholder="Vehicle VIN or other ID" fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                        <kendo-formerror>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="lossInformation?.controls?.vehicleVinOrOtherId?.errors?.required">Vehicle VIN
                                or
                                Other ID cannot be left blank</span>
                        </kendo-formerror>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>

            <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label [for]="vehicleStock" text="Vehicle Stock"></kendo-label>
                        <kendo-textbox #vehicleStock formControlName="vehicleStock" placeholder="Enter stock"
                            fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                        <kendo-formerror>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="lossInformation?.controls?.vehicleStock?.errors?.required">Vehicle Stock cannot
                                be
                                left blank</span>
                        </kendo-formerror>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>

            <hr />

            <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label class="font-weight-600 mb-15 asterisk"
                            text="Is the vehicle, boat, heavy equipment still at the same location where the loss occurred?"></kendo-label>
                        <div class="kendo-radio">
                            <input type="radio" id="yes" kendoRadioButton formControlName="sameLocation" [value]="true"
                                (change)="changedSameLocationVehicle(true)" />
                            <label class="k-radio-label mr-24" for="yes">Yes</label>
                            <input type="radio" id="no" kendoRadioButton formControlName="sameLocation" [value]="false"
                                (change)="changedSameLocationVehicle(false)" />
                            <label class="k-radio-label" for="no">No</label>
                        </div>
                        <kendo-formerror>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="lossInformation?.controls?.sameLocation?.errors?.required">Same location where
                                the loss occurred cannot be
                                left blank</span>
                        </kendo-formerror>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </ng-container>

        <ng-container *ngIf="lossInformation?.controls?.sameLocation?.value === false">

            <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label class="asterisk" [for]="lossVehicleAddress" text="Vehicle Address"></kendo-label>
                        <kendo-textbox #lossVehicleAddress formControlName="lossVehicleAddress"
                            placeholder="Enter address" fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                        <kendo-formerror>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="lossInformation?.controls?.lossVehicleAddress?.errors?.required">Vehicle
                                Address cannot be left blank</span>
                        </kendo-formerror>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>

            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 26}" [cols]="['47%', '47%']">
                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label class="asterisk" [for]="lossVehicleCity" text="City"></kendo-label>
                        <kendo-textbox #lossVehicleCity formControlName="lossVehicleCity" placeholder="Enter city"
                            fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                        <kendo-formerror>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="lossInformation?.controls?.lossVehicleCity?.errors?.required">City cannot be
                                left blank</span>
                        </kendo-formerror>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label class="asterisk" [for]="lossVehicleState" text="State"></kendo-label>
                        <kendo-dropdownlist #lossVehicleState [data]="groupedDataStates" textField="text"
                            valueField="value" formControlName="lossVehicleState" [valuePrimitive]="true"
                            fillMode="outline" [filterable]="false" (filterChange)="handleFilter($event)"
                            [defaultItem]="{value: '', text: 'Select state', type: ''}"
                            [popupSettings]="{appendTo: 'component', animate: false}">
                            <ng-template kendoDropDownListGroupTemplate let-groupName>
                                <strong>{{ groupName }}</strong>
                            </ng-template>
                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.text }} </span>
                                <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.text }} </span>
                            </ng-template>
                        </kendo-dropdownlist>
                        <kendo-formerror>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="lossInformation?.controls?.lossVehicleState?.errors?.required">State cannot be
                                left blank</span>
                        </kendo-formerror>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>

            <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label class="asterisk" [for]="lossVehicleZipcode" text="Zip Code"></kendo-label>
                        <kendo-textbox #lossVehicleZipcode formControlName="lossVehicleZipcode"
                            placeholder="Enter zip code" fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                        <kendo-formerror>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="lossInformation?.controls?.lossVehicleZipcode?.errors?.required">Zip Code
                                cannot be left blank</span>
                        </kendo-formerror>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>

            <hr />

        </ng-container>

        <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label class="asterisk" [for]="lossAddress" text="Loss Address"></kendo-label>
                    <kendo-textbox #lossAddress formControlName="lossAddress" placeholder="Enter address"
                        fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.lossAddress?.errors?.required">Loss Address cannot be
                            left blank</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 26}" [cols]="['47%', '47%']">
            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label class="asterisk" [for]="lossCity" text="Loss City"></kendo-label>
                    <kendo-textbox #lossCity formControlName="lossCity" placeholder="Enter city" fillMode="outline"
                        [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.lossCity?.errors?.required">Loss City cannot be left
                            blank</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label class="asterisk" [for]="lossState" text="Loss State"></kendo-label>
                    <kendo-dropdownlist #lossState [data]="groupedDataStates" textField="text" valueField="value"
                        formControlName="lossState" [valuePrimitive]="true" fillMode="outline" [filterable]="false"
                        (filterChange)="handleFilter($event)" [defaultItem]="{value: '', text: 'Select state', type: ''}"
                        [popupSettings]="{appendTo: 'component', animate: false}">
                        <ng-template kendoDropDownListGroupTemplate let-groupName>
                            <strong>{{ groupName }}</strong>
                        </ng-template>
                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.text }} </span>
                            <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.text }} </span>
                        </ng-template>
                    </kendo-dropdownlist>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.lossState?.errors?.required">Loss State cannot be left
                            blank</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label class="asterisk" [for]="lossZipCode" text="Loss Zip Code"></kendo-label>
                    <kendo-textbox #lossZipCode formControlName="lossZipcode" placeholder="Enter zip code"
                        fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.lossZipcode?.errors?.required">Loss Zip Code cannot be
                            left blank</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 26}" [cols]="['47%', '47%']">
            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label [for]="publicAdjuster" text="Public Adjuster"></kendo-label>
                    <kendo-textbox #publicAdjuster formControlName="publicAdjuster" placeholder="Enter name"
                        fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.publicAdjuster?.errors?.required">Public Adjuster cannot
                            be left blank</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label [for]="adjusterPhoneNumber" text="Phone Number"></kendo-label>
                    <kendo-maskedtextbox #adjusterPhoneNumber formControlName="adjusterPhoneNumber"
                        placeholder="Enter phone number" fillMode="outline" mask="(000) 000-0000"></kendo-maskedtextbox>
                    <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.adjusterPhoneNumber?.errors?.required">Phone number cannot be left blank</span>
                        <span *ngIf="lossInformation?.controls?.adjusterPhoneNumber?.errors?.patternError">Invalid phone number</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label [for]="fireMarshal" text="Fire Marshal"></kendo-label>
                    <kendo-textbox #fireMarshal formControlName="fireMarshal" placeholder="Enter name"
                        fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.fireMarshal?.errors?.required">Fire Marshal cannot be
                            left blank</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item>
                <kendo-formfield>
                    <kendo-label [for]="marshalPhoneNumber" text="Phone Number"></kendo-label>
                    <kendo-maskedtextbox #marshalPhoneNumber formControlName="marshalPhoneNumber"
                        placeholder="Enter phone number" fillMode="outline" mask="(000) 000-0000"></kendo-maskedtextbox>
                    <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
                    <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="lossInformation?.controls?.marshalPhoneNumber?.errors?.required">Phone number cannot be left blank</span>
                        <span *ngIf="lossInformation?.controls?.marshalPhoneNumber?.errors?.patternError">Invalid phone number</span>
                    </kendo-formerror>
                </kendo-formfield>
            </kendo-gridlayout-item>
        </kendo-gridlayout>
    </form>
</kendo-card>
