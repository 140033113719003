import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-can-we-help-you',
  templateUrl: './can-we-help-you.component.html',
  styleUrls: ['./can-we-help-you.component.scss']
})
export class CanWeHelpYouComponent {

  constructor() { }

}
