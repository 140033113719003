import { IApiUser, IApiUserOrderBy, IApiUserFilter } from './../../modules/graphql/types/types';
import { UserService } from './user.service';
import { DataSource } from "@angular/cdk/collections";
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { ApiConnection } from "../../decorators/@ApiConnection.decorator";

export class UserDataSource extends NefcoPagedDataSource<IApiUser, UserService> implements DataSource<IApiUser> {
  @ApiConnection()
  public load(filters: IApiUserFilter[] = []): Promise<any> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
