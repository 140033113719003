import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({ name: 'alphaIndex' })
export class AlphabeticalIndexPipe implements PipeTransform {
  transform(index: number): string {
    // convert 0 based index enumeration to it's alphabetical equivalent
    // using Number.prototype.toString()
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toString
    return (index + 10).toString(36).toUpperCase();
  }
}
