import { IApiShippingCarrier, IApiShippingCarrierConnection, IApiShippingCarrierFilter } from './../../modules/graphql/types/types';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { ShippingCarrierService } from "./shipping-carrier.service";
import { NefcoPagedDataSource } from '../../helpers/nefco-data-source.class';

export class ShippingCarrierDataSource extends NefcoPagedDataSource<IApiShippingCarrier, ShippingCarrierService, IApiShippingCarrierConnection> implements DataSource<IApiShippingCarrier> {
  @ApiConnection()
  public load(filters: IApiShippingCarrierFilter[] = []): Promise<IApiShippingCarrierConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
