import { Component, OnInit } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { isArray } from 'lodash';
import moment from 'moment';
import { quarterlyHourReportTypeKeys } from 'src/app/shared/helpers/helper';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiQuarterlyUtilizationReportFilter, IApiQuarterlyUtilizationReportFilterType, IApiQuarterlyUtilizationReportOrderBy, IApiSortOrder, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { DownloadHelper } from 'src/app/shared/services';
import { QuarterlyHourReportDataSource } from 'src/app/shared/services/reports/quarterly-hour-report/quarterly-hour-report.datasource';
import { QuarterlyHourReportService } from 'src/app/shared/services/reports/quarterly-hour-report/quarterly-hour-report.service';
import { staffViewAll } from 'src/app/shared/helpers/auth-config/staff.config';

@Component({
  selector: 'app-quarterly-hour-report',
  templateUrl: './quarterly-hour-report.component.html',
  styleUrls: ['./quarterly-hour-report.component.scss']
})
export class QuarterlyHourReportComponent implements OnInit {
  
  public authConfig = {
    staffViewAll,
  };

  private _filters: IApiQuarterlyUtilizationReportFilter[] = [];
  public filterTypes = IApiQuarterlyUtilizationReportFilterType;

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  public userViewFilter = IApiUserFilterType.ViewStaffUser;

  private _dataSource: QuarterlyHourReportDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public data = [];
  public sort: SortDescriptor[] = [
    {
      field: IApiQuarterlyUtilizationReportOrderBy.UserName,
      dir: "asc",
    },
  ];

  currentQuarter = {
    startDate: NefcoDateHelper.toUtcStartOfDay(moment().startOf("quarter").toDate(), true),
    endDate: NefcoDateHelper.toUtcStartOfDay(moment().endOf("quarter").toDate(), false),
  }
  public innerWidth: any;

  public quarterlyHourReportTypeKeys = quarterlyHourReportTypeKeys;

  constructor(
    private loaderService: LoaderService,
    private quarterlyHourReportService: QuarterlyHourReportService,
    private notifications: NotificationsService,
    private downloadHelper: DownloadHelper
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.loaderService.isMatLoader = false;
    this.dataSource = new QuarterlyHourReportDataSource(this.quarterlyHourReportService);
    // Table Initialization / Setup
    this.loaderService.attachObservable(this.dataSource.loading$);
    this.dataSource.listPage.orderBy = IApiQuarterlyUtilizationReportOrderBy.UserName;

    this.dataSource.applyFilter(IApiQuarterlyUtilizationReportFilterType.WorkDayRange, JSON.stringify(this.currentQuarter));
    this.dataSource?.contents$.subscribe((res) => {
      this.data = res;
      this.data = this.data?.map(record => {
        return {
          ...record,
          [quarterlyHourReportTypeKeys.TRAINING]: record?.TimeEntry?.find(time => time?.name === quarterlyHourReportTypeKeys.TRAINING),
          [quarterlyHourReportTypeKeys.FIRE_INVESTIGATION]: record?.TimeEntry?.find(time => time?.name === quarterlyHourReportTypeKeys.FIRE_INVESTIGATION),
          [quarterlyHourReportTypeKeys.TECH_REVIEWER]: record?.TimeEntry?.find(time => time?.name === quarterlyHourReportTypeKeys.TECH_REVIEWER),
          [quarterlyHourReportTypeKeys.ELECTRICAL_ENGINEER]: record?.TimeEntry?.find(time => time?.name === quarterlyHourReportTypeKeys.ELECTRICAL_ENGINEER),
          [quarterlyHourReportTypeKeys.EVIDENCE_TECHNICIAN]: record?.TimeEntry?.find(time => time?.name === quarterlyHourReportTypeKeys.EVIDENCE_TECHNICIAN),
          [quarterlyHourReportTypeKeys.HAZMAT]: record?.TimeEntry?.find(time => time?.name === quarterlyHourReportTypeKeys.HAZMAT)
        }
      })
      if (this.data?.length) {
        const defaultRow = {
          [quarterlyHourReportTypeKeys.TRAINING]: { hours: 'Bill', nonBillableHours: 'Unbill' },
          [quarterlyHourReportTypeKeys.FIRE_INVESTIGATION]: { hours: 'Bill', nonBillableHours: 'Unbill' },
          [quarterlyHourReportTypeKeys.TECH_REVIEWER]: { hours: 'Bill', nonBillableHours: 'Unbill' },
          [quarterlyHourReportTypeKeys.ELECTRICAL_ENGINEER]: { hours: 'Bill', nonBillableHours: 'Unbill' },
          [quarterlyHourReportTypeKeys.EVIDENCE_TECHNICIAN]: { hours: 'Bill', nonBillableHours: 'Unbill' },
          [quarterlyHourReportTypeKeys.HAZMAT]: { hours: 'Bill', nonBillableHours: 'Unbill' }
        };
        this.data = [
          defaultRow,
          ...this.data
        ];
      }
    });
    this.load();
  }

  public setFilters(value: string | undefined, type: IApiQuarterlyUtilizationReportFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value
    }] : filtersCopy;
    this.applyFilter();
  }

  public filterValue(filter: IApiQuarterlyUtilizationReportFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
        ? value
        : null;
  }

  public filterByIdValue(filter: IApiQuarterlyUtilizationReportFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }

  public sortChange = (e) => {
    this.sort = e;
    this.dataSource.applyFilter(IApiQuarterlyUtilizationReportFilterType.TimeEntryTypeTotal, null);
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field?.split('-') ? e?.[0]?.field?.split('-')[0] : e?.[0]?.field;
      if (e?.[0]?.field?.split('-')[1]) {
        this.dataSource.applyFilter(IApiQuarterlyUtilizationReportFilterType.TimeEntryTypeTotal, e?.[0]?.field?.split('-')[1]);
      }
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiQuarterlyUtilizationReportOrderBy.UserName;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  };

  public pageChange(event) {
    this.pageOptions?.paginate(event);
  }

  private load(): void {
    this.dataSource.load();
  }


  applyFilter() {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.filters?.map(filter => this.dataSource.applyFilter(filter?.type, filter?.value));
    if (!this.filters?.find(filter => filter.type === IApiQuarterlyUtilizationReportFilterType.WorkDayRange)?.value) {
      return
    }
    this.dataSource.pagingReset();
    this.load();
  }

  clearFilter() {
    this.filters = [];
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.applyFilter(IApiQuarterlyUtilizationReportFilterType.WorkDayRange, JSON.stringify(this.currentQuarter));
    this.dataSource.pagingReset();
    this.load();
  }

  exportCsv() {
    const params = {
      filters: this.dataSource?.lastFilters,
      orderBy: this.dataSource?.listPage?.orderBy,
      sortOrder: this.dataSource?.listPage?.sortOrder === 'ASC' ? IApiSortOrder.Asc : IApiSortOrder.Desc,
    };
    // download investigation report
    this.loaderService.show$(this.quarterlyHourReportService.csvReport(params).pipe(
      this.notifications.catchAlertPipe("Error downloading report; please try again"),
    )).subscribe((res) => {
      if (res) {
        this.downloadHelper.download(res, 'Quarterly_hour_report.csv');
      }
    });
  }
}
