import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  details
  ExpenseCategories {
    id
    name
  }
  active
  createdAt
  updatedAt
`;

export const ExpensePurposeConnectionQuery = gql`${buildConnectionQuery("ExpensePurpose", "expensePurposeConnection", nodeFragment)}`;

export const getExpensePurposeByIdQuery = gql`query getExpensePurposeById($id: String!) {
  getExpensePurposeById(id: $id) { ${ nodeFragment } }
}`;

export const addExpensePurposeMutation = gql`mutation AddExpensePurpose($input: AddExpensePurposeInput!) {
  addExpensePurpose(input: $input) { ${ nodeFragment } }
}`;

export const updateExpensePurposeMutation = gql`mutation UpdateExpensePurpose($input: UpdateExpensePurposeInput!) {
  updateExpensePurpose(input: $input) { ${ nodeFragment } }
}`;

export const toggleExpensePurposeActivationMutation = gql`mutation ToggleExpensePurposeActivation($id: ID!) {
  toggleExpensePurposeActivation(id: $id)
}`;

export const removeExpensePurposeMutation = gql`mutation RemoveExpensePurpose($id: ID!) {
  removeExpensePurpose(id: $id)
}`;