<section class="mat-typography">
  <!-- Modal Header -->
  <div class="header">
    <div class="row">
      <div class="col-12 mb-3">
        <h2 class="text-center">Download Documents</h2>
        <h2 class="text-center">Investigation #{{ data.investigation.nefcoNumber }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-6 mb-3">
        <mat-checkbox color="primary" (change)="checkAll($event); error = null;" [checked]="allSelected()">Download all documents</mat-checkbox>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <!-- Modal Body -->
  <div class="body">
    <div class="row">

      <div class="col-12 my-3">
        <mat-label class="font-italic">Download selected documents:</mat-label>
      </div>

      <!-- "Upload" Documents -->
      <div class="col-12 pb-3">
        <mat-label>Uploads</mat-label>
      </div>
      <div class="col-4" *ngFor="let upload of uploads">
        <mat-checkbox color="primary" [checked]="upload.download" (change)="upload.download = !upload.download; error = null">{{ upload.title }}</mat-checkbox>
      </div>

      <!-- "Report" Documents -->
      <div class="col-12 py-3">
        <mat-label>Reports</mat-label>
      </div>
      <div class="col-4" *ngFor="let report of reports">
        <mat-checkbox color="primary" [checked]="report.download" (change)="report.download = !report.download; error = null">{{ report.title }}</mat-checkbox>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <!-- Download Documents Button -->
  <div class="row">
    <div class="col-auto my-3">
      <p>{{ error }}</p>
    </div>
    <div class="col my-3 d-flex flex-row-reverse">
      <button color="primary" mat-raised-button (click)="download()">DOWNLOAD</button>
      <button mat-button (click)="this.dialogRef.close()">Cancel</button>
    </div>
  </div>

</section>
