import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';

import {
  CertificationsManagementComponent,
  CertificationSidebarComponent,
  CertificationsInvestigatorListComponent,
  CertificationCreateModalComponent
} from './';


@NgModule({
  declarations: [
    CertificationsManagementComponent,
    CertificationSidebarComponent,
    CertificationsInvestigatorListComponent,
    CertificationCreateModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class CertificationsModule {}
