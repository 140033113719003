import { Pipe, PipeTransform } from '@angular/core';
import { IContactBranch } from '../interfaces/contact.interfaces';

@Pipe({ name: 'activeBranch' })
export class ActiveBranchPipe implements PipeTransform {
    transform(contactBranches: IContactBranch[]): IContactBranch | null {
        const isStartDateGreater = (accumulator, contactBranch) => (!contactBranch.endDate && contactBranch.startDate > (accumulator ? accumulator.startDate : 0));
        const reducer = (accumulator, contactBranch) => isStartDateGreater(accumulator, contactBranch) ? contactBranch : accumulator;
        return contactBranches.reduce(reducer, null);
    }
}

@Pipe({ name: 'contactCompany' })
export class ContactCompanyPipe implements PipeTransform {
    transform(contactBranch: IContactBranch | undefined): string {
        return contactBranch ? contactBranch.Branch.Company.name : "N/A";
    }
}

@Pipe({ name: 'branchName' })
export class BranchNamePipe implements PipeTransform {
    transform(contactBranch: IContactBranch | undefined): string {
        return contactBranch ? contactBranch.Branch.name : "N/A";
    }
}