import { switchMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { Component, OnInit } from '@angular/core';
import { ExpensePurposeService } from 'src/app/shared/services/expenses/expense-purpose/expense-purpose.service';
import { ExpensePurposeDataSource } from 'src/app/shared/services/expenses/expense-purpose/expense-purpose.datasource';
import { ExpensePurposeModalComponent } from '../expense-purpose-modal/expense-purpose-modal.component';
import { IApiExpensePurpose, IApiExpensePurposeOrderBy } from "src/app/shared/modules/graphql/types/types";
import { manageExpensePurposesCreate, manageExpensePurposesUpdate, manageExpensePurposesDelete, manageExpensePurposesDeactivate } from "../../../../shared/helpers/auth-config/manage.config";
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { AuthService } from 'src/app/shared/services';
import { forkJoin } from 'rxjs';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import _ from 'lodash';

@Component({
  selector: 'app-bookkeeping-expense-purposes',
  templateUrl: './bookkeeping-expense-purposes.component.html',
  styleUrls: ['./bookkeeping-expense-purposes.component.scss']
})
export class BookkeepingExpensePurposesComponent implements OnInit {

  public authConfig = {
    manageExpensePurposesCreate,
    manageExpensePurposesUpdate,
    manageExpensePurposesDelete,
    manageExpensePurposesDeactivate
  }

  private _dataSource: ExpensePurposeDataSource;
  public get dataSource(): ExpensePurposeDataSource {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public sort: SortDescriptor[] = [
    {
      field: IApiExpensePurposeOrderBy.Name,
      dir: "asc",
    },
  ];
  data: IApiExpensePurpose[] = [];
  public actionItem: { text: string }[] = [];
  constructor(
    private loader: LoaderService,
    private notifications: NotificationsService,
    private expensePurposeService: ExpensePurposeService,
    private authService: AuthService,
    private dialogService: DialogService) { }

  public parseExpenseCategories(categories) {
    return categories.map((k) => k.name).join(", ");
  }

  public add() {
    const dialog: DialogRef = this.dialogService.open({
      content: ExpensePurposeModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as ExpensePurposeModalComponent;
    dialogInstance.data = null;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return !_.isEmpty(v);
      })
    ).subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

  public edit(data: IApiExpensePurpose) {
    const dialog: DialogRef = this.dialogService.open({
      content: ExpensePurposeModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as ExpensePurposeModalComponent;
    dialogInstance.data = data;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return !_.isEmpty(v);
      })
    ).subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

  public toggleActivation({ id }) {
    this.expensePurposeService.toggleExpensePurposeActivation(id).subscribe(() => this.load());
  }

  public delete(purposeId: string) {
    this.notifications.kendoConfirm("Really delete this Expense Purpose?", "Confirm Delete").pipe(
      filter(v => !!v),
      switchMap(() => this.expensePurposeService.remove(purposeId))
    ).pipe(
      this.notifications.catchAlertPipe("Error removing Purpose."),
      this.notifications.snackbarPipe("Purpose deleted")
    ).subscribe(() => this.load());
  }

  ngOnInit() {
    // create the dataSource
    this._dataSource = new ExpensePurposeDataSource(this.expensePurposeService);
    this.loader.attachObservable(this._dataSource.loading$);

    forkJoin([
      this.authService.hasCategoryPermission(this.authConfig.manageExpensePurposesUpdate.category, this.authConfig.manageExpensePurposesUpdate.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.manageExpensePurposesDeactivate.category, this.authConfig.manageExpensePurposesDeactivate.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.manageExpensePurposesDelete.category, this.authConfig.manageExpensePurposesDelete.appliedPermissions)
    ]).subscribe(([up, acDa, del]) => {
      if (up) {
        this.actionItem.push({text: 'Update'});
      }
      if (acDa) {
        this.actionItem.push({text: 'Activate'});
        this.actionItem.push({text: 'Deactivate'});
      }
      if (del) {
        this.actionItem.push({text: 'Delete'});
      }
    });

    // populate the data
    this.load();

    this.dataSource?.contents$.subscribe((res) => {
      this.data = res;
    });
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load([]);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiExpensePurposeOrderBy.Name;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  };

  getFilteredActionItems(dataItem: IApiExpensePurpose) {
    return this.actionItem.filter(item => dataItem.active && item.text !== 'Activate' || !dataItem.active && item.text !== 'Deactivate' );
  }

  public itemSelected(action: { text: string }, dataItem: IApiExpensePurpose) {
    switch (action.text) {
      case "Update":
        this.edit(dataItem);
        break;
      case "Delete":
        this.delete(dataItem.id);
        break;
      case "Activate":
      case "Deactivate":
        this.toggleActivation(dataItem);
        break;
      default:
        break;
    }
  }
}
