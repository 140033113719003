import { Component, Input, OnInit } from '@angular/core';
import { IApiDocumentSnapshot } from '../../modules/graphql/types/types';
import { InvestigationReportStatus } from '../../services/investigation/investigation-report/investigation-report.service';

@Component({
  selector: 'app-report-status-icon-kendo',
  templateUrl: './report-status-icon-kendo.component.html',
  styleUrls: ['./report-status-icon-kendo.component.scss']
})
export class ReportStatusIconKendoComponent {

  @Input() snapshot: IApiDocumentSnapshot;

  public InvestigationReportStatus = InvestigationReportStatus;

  public get approved(): boolean {
    if (!this.snapshot || !this.snapshot.ApprovalHistory || !this.snapshot.ApprovalHistory.length) return false;
    return this.snapshot.ApprovalHistory[0].isApproved;
  }

  constructor() { }

}
