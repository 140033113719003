import { Observable } from 'rxjs';
import { GqlEmailService } from "../../modules/graphql/services";
import { Injectable } from '@angular/core';
import { IApiAddEmailInput, IApiEmail, IApiRemoveEmailInput } from "../../modules/graphql/types/types";

@Injectable()
export class EmailService {

  constructor(
    private emails: GqlEmailService,
  ) { }

  public add(input: IApiAddEmailInput): Observable<IApiEmail> {
    return this.emails.addEmail(input);
  }

  public remove(input: IApiRemoveEmailInput) {
      return this.emails.deleteEmail(input);
  }

}
