import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiAddBillingRuleCategoryInput, IApiBillingRuleCategory, IApiBillingRuleCategoryConnection, IApiBillingRuleCategoryFilter, IApiUpdateBillingRuleCategoryInput } from 'src/app/shared/modules/graphql/types/types';

import {
  billingRuleCategoryConnectionQuery,
  addBillingRuleCategory,
  updateBillingRuleCategoryMutation,
  removeBillingRuleCategoryMutation,
  getBillingRuleCategoryByIdQuery
} from "./billing-rule-category.operation";

@Injectable()
export class GqlBillingRuleCategoryService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiBillingRuleCategoryFilter[], options: IGqlBaseOptions): Observable<IApiBillingRuleCategoryConnection> {
    return this.apollo.query(connectionQuery(billingRuleCategoryConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddBillingRuleCategoryInput) {
    return this.apollo.mutate({
      mutation: addBillingRuleCategory,
      variables: { input }
    }).pipe(safeResolve(true));
  }

  public remove(id: string) {
    return this.apollo.mutate({
      mutation: removeBillingRuleCategoryMutation,
      variables: { id }
    }).pipe(safeResolve(true));
  }

  public update(input: IApiUpdateBillingRuleCategoryInput) {
    return this.apollo.mutate({
      mutation: updateBillingRuleCategoryMutation,
      variables: { input }
    }).pipe(safeResolve(true));
  }

  public getById(id: string): Observable<IApiBillingRuleCategory> {
    return this.apollo.query({
      query: getBillingRuleCategoryByIdQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
