import gql from "graphql-tag";
import { buildConnectionQuery } from "../../helpers.class";
import { IApiCompanyFilterType } from "../../types/types";

const contactsFragment = `
  ContactAssignments {
    isPrimary
    startDate
    endDate
    Contact {
      id
      firstName
      lastName
      suffix
      nickName
      jobTitle
      Phones {
        id
        isPrimary
        number
        extension
        Type {
          id
          name
        }
      }
      Emails {
        id
        isPrimary
        address
        Type {
          id
          name
        }
      }
      Addresses {
        id
        Type {
          id
          name
        }
        isPrimary
        address1
        address2
        address3
        city
        state
        postal
        country
      }
      Role {
        id
        name
      }
    }
  }
`;

const billingFragment = `
  FlatRates {
    id
    value
    Risk {
      id
      name
    }
  }
  BillingRules {
    id
    value
    notes
    TimeEntryType {
      id
      name
    }
    ExpenseItem {
      id
      name
    }
  }
`;

const certificationsFragment = `
  RequiredCertifications {
    id
    title
    state
    notes
  }
`;

const excludedStaffFragment = `
  ExcludedStaff {
    id
    email
    firstName
    lastName
  }
`;

const contactsIdFragment = `
  Contacts {
    id
  }
`;

const servicesFragment = `
  RequiredServices {
    id
    name
  }
`;

const investigationsFragment = `
  Investigations {
    id
  }
`;

const mainOfficeFragment = `
MainOffice {
  id
  name
  additionalInformation
  Addresses {
    id
    TypeId
    Type {
      id
      name
    }
    isPrimary
    address1
    address2
    address3
    city
    state
    postal
    country
  }
  Phones {
    id
    isPrimary
    number
    extension
    Type {
      id
      name
    }
  }
  Emails {
    id
    isPrimary
    address
    Type {
      id
      name
    }
  }
  {{ CONTACT_ASSN }}
}
`;

const branchesFragment = `
Branches {
  id
  name
  Company {
    id
    name
  }
  Addresses {
    id
    Type {
      id
      name
    }
    isPrimary
    address1
    address2
    address3
    city
    state
    postal
    country
  }
  Phones {
    id
    isPrimary
    number
    extension
    Type {
      id
      name
    }
  }
  Emails {
    id
    isPrimary
    address
    Type {
      id
      name
    }
  }
  {{ CONTACT_ASSN }}
}
`;

const companyType = `
Type {
  id
  name
}
`;

const templateFragment = `
  id
  name
  description
  additionalInfo
  billingInstructions
  qbListId
  enterPropertyEnabled
  recordedInterviewNotification
  {{ COMPANYTYPE }}
  {{ MAILOFFICE }}
  {{ BRANCHES }}
  {{ BILLING }}
  {{ CERTIFICATIONS }}
  {{ EXCLUDEDSTAFF }}
  {{ CONTACTSID }}
  {{ SERVICES }}
  {{ INVESTIGATIONS }}
`;

const contactRegex = /\{\{\s?CONTACT_ASSN\s?\}\}/g;
const billingRegex = /\{\{\s?BILLING\s?\}\}/g;
const certificationsRegex = /\{\{\s?CERTIFICATIONS\s?\}\}/g;
const excludedStaffRegex = /\{\{\s?EXCLUDEDSTAFF\s?\}\}/g;
const contactsIdRegex = /\{\{\s?CONTACTSID\s?\}\}/g;
const servicesRegex = /\{\{\s?SERVICES\s?\}\}/g;
const investigationsRegex = /\{\{\s?INVESTIGATIONS\s?\}\}/g;
const mainOfficeRegex = /\{\{\s?MAILOFFICE\s?\}\}/g;
const branchesRegex = /\{\{\s?BRANCHES\s?\}\}/g;
const companyTypeRegex = /\{\{\s?COMPANYTYPE\s?\}\}/g;

const nodeFragment = templateFragment
  .replace(companyTypeRegex, companyType)
  .replace(mainOfficeRegex, mainOfficeFragment)
  .replace(branchesRegex, branchesFragment)
  .replace(contactRegex, contactsFragment)
  .replace(billingRegex, billingFragment)
  .replace(certificationsRegex, certificationsFragment)
  .replace(excludedStaffRegex, excludedStaffFragment)
  .replace(contactsIdRegex, contactsIdFragment)
  .replace(servicesRegex, servicesFragment)
  .replace(investigationsRegex, investigationsFragment);

const listFragment = templateFragment
  .replace(companyTypeRegex, companyType)
  .replace(mainOfficeRegex, mainOfficeFragment)
  .replace(branchesRegex, branchesFragment)
  .replace(contactRegex, "")
  .replace(billingRegex, billingFragment)
  .replace(certificationsRegex, certificationsFragment)
  .replace(excludedStaffRegex, excludedStaffFragment)
  .replace(contactsIdRegex, contactsIdFragment)
  .replace(servicesRegex, servicesFragment)
  .replace(investigationsRegex, investigationsFragment);

const listViewFragment = templateFragment
  .replace(companyTypeRegex, companyType)
  .replace(mainOfficeRegex, mainOfficeFragment)
  .replace(branchesRegex, branchesFragment)
  .replace(contactRegex, "")
  .replace(billingRegex, "")
  .replace(certificationsRegex, "")
  .replace(excludedStaffRegex, "")
  .replace(contactsIdRegex, "")
  .replace(servicesRegex, "")
  .replace(investigationsRegex, "");

const listNameViewFragment = templateFragment
  .replace(companyTypeRegex, "")
  .replace(mainOfficeRegex, "")
  .replace(branchesRegex, "")
  .replace(contactRegex, "")
  .replace(billingRegex, "")
  .replace(certificationsRegex, "")
  .replace(excludedStaffRegex, "")
  .replace(contactsIdRegex, "")
  .replace(servicesRegex, "")
  .replace('additionalInfo', "")
  .replace('billingInstructions', "")
  .replace('qbListId', "")
  .replace('description', "")
  .replace(investigationsRegex, "");

export const companyConnectionQuery = (view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiCompanyFilterType.ViewListView:
        return gql`${buildConnectionQuery("Company", "companiesConnection", listViewFragment)}`;

      case IApiCompanyFilterType.ViewOnlyName:
        return gql`${buildConnectionQuery("Company", "companiesConnection", listNameViewFragment)}`;

      default:
        return gql`${buildConnectionQuery("Company", "companiesConnection", listFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("Company", "companiesConnection", listFragment)}`;
  }
};

export const getCompanyByIdQuery = gql`query getCompanyById($id: ID!) {
  getCompanyById(id: $id) {
    ${nodeFragment}
  }
}`;

export const addCompanyMutation = gql`mutation AddCompany($input: AddCompanyInput!) {
  addCompany(input: $input) { ${nodeFragment} }
}`;

export const updateCompanyMutation = gql`mutation UpdateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) { ${nodeFragment} }
}`;

export const removeCompanyMutation = gql`mutation RemoveCompany($id: ID!) {
  removeCompany(id: $id)
}`;
