<section>
  <!-- Permissions -->
  <div [appEnforcePermission]="authConfig.companiesBranchesView" #companiesBranchesView="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesBranchesDelete" #companiesBranchesDelete="appEnforcePermission">
  </div>

  <!-- Permissions -->
  <kendo-grid class="pl-24 pr-24 permission-role-list" [data]="{
    data: branches,
    total: (dataSource?.pageInfo$ | async)?.totalCount
  }" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="{
    buttonCount: 6,
    type: 'numeric',
    pageSizes: [5, 10, 25, 100],
    previousNext: true,
    responsive: true,
    position: 'bottom'
  }" (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)">
    <kendo-grid-column field="NAME" title="Name" [width]="25" class="id-column" [sortable]="true" class="font-weight600"
      [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/admin','companies', 'management', company.id, 'branch', dataItem.id]" appEventLink
          class="text-decoration word-break" *ngIf="companiesBranchesView?.enabled else branchName">
          {{dataItem?.name}}
        </a>
        <ng-template #branchName>{{dataItem.name}}</ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="ADDRESS" title="Address" [width]="25" [sortable]="false"
      [headerClass]="'no-cursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.Addresses?.length else noData">
          <app-address-view *ngFor="let address of dataItem?.Addresses" [address]="address"></app-address-view>
        </ng-container>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="PHONE" title="Phone" [width]="25" [sortable]="false"
      [headerClass]="'no-cursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.Phones?.length else noData">
          <app-email-phone-view *ngFor="let phone of dataItem?.Phones" [phone]="phone"></app-email-phone-view>
        </ng-container>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="CONTACTS" title="Contacts" [width]="20" [sortable]="false"
      [headerClass]="'no-cursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.Contacts?.length else noData">
          <span> {{dataItem?.Contacts?.length || '-'}}</span>
        </ng-container>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="id" title="Actions" [width]="5" [sortable]="false" [headerClass]="'nocursor'"
      *ngIf="companiesBranchesDelete?.enabled">
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-dropdownbutton [data]="[{ text: 'Delete'}]" themeColor="primary" fillMode="link"
          buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis" (itemClick)="deleteBranch(dataItem)"
          [popupSettings]="{
         appendTo: 'component',
         animate: false,
         width: 250,
         popupClass: 'grid-action-items-menu'
       }">
        </kendo-dropdownbutton>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</section>
<ng-template #noData>
  <span>-</span>
</ng-template>
