import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlNotificationService } from 'src/app/shared/modules/graphql/services/notification/notification.service';
import {
  IApiNotification, IApiUpdateNotificationInput, IApiNotificationFilter, IApiNotificationsConnection, IApiMarkNotificationsAsReadOrUnreadInput
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "../../helpers/list-page.class";


@Injectable()
export class UserNotificationService {

  constructor(
    private gqlNotificationService: GqlNotificationService
  ) { }

  public get(filters?: IApiNotificationFilter[], options?: IGqlBaseOptions): Observable<IApiNotificationsConnection> {
    return this.gqlNotificationService.get(filters, options || new ListPage());
  }

  public update(input: IApiUpdateNotificationInput): Observable<IApiNotification> {
    return this.gqlNotificationService.update(input);
  }

  public getById(id: string): Observable<IApiNotification> {
    return this.gqlNotificationService.getById(id);
  }

  public markNotificationsAsReadOrUnread(input: IApiMarkNotificationsAsReadOrUnreadInput): Observable<boolean> {
    return this.gqlNotificationService.markNotificationsAsReadOrUnread(input);
  }
  
  public remove(id: string): Observable<boolean> {
    return this.gqlNotificationService.remove(id);
  }
}
