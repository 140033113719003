import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PortalGuard } from "./portal.guard";
import { PortalMainComponent } from "./portal-main/portal-main.component";
import { IntakeFormComponent } from "./intake-form/intake-form.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "@auth0/auth0-angular";
import { PortalAuthGuard } from "src/app/shared/services/authorization/portal.auth.service";
import { TypoGraphyComponent } from "../shared/typo-graphy/typo-graphy.component";
import { AlertMessageComponent } from "./alert-message/alert-message.component";

const routes: Routes = [
  {
    path: "",
    component: PortalMainComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "alert",
        component: AlertMessageComponent,
      },
      {
        path: "typography",
        component: TypoGraphyComponent,
      },
      // {
      //     path: "assignments",
      //     canActivate: [PortalGuard],
      //     children: [
      //         {
      //             path: "list",
      //             component: AssignmentListComponent
      //         }
      //     ]
      // },
      // Should be left unprotected so it can be publicly exposed
      {
        path: "intake",
        canActivate: [PortalAuthGuard],
        component: IntakeFormComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PortalModuleRouter {}
