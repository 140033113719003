<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staff" #staff="appEnforcePermission"></div>
<!-- Permissions -->
<div class="kendo-formfield left-search-icon">
    <kendo-label [for]="" text="{{label}}" [ngClass]="{'asterisk':  validate}"></kendo-label>
    <div style="position: relative;">
      <i class="fa fa-search" aria-hidden="true"></i>
      <kendo-autocomplete
        #autoCompleteUser
        [data]="users"
        [loading]="loading"
        [value]="selectedUserName"
        valueField="id"
        fillMode="outline"
        [filterable]="true"
        (valueChange)="selectUser($event)"
        (filterChange)="onSearchChange($event)"
        [placeholder]="placeholder"
        [disabled]="disabled || !staff?.enabled"
        [popupSettings]="{appendTo: 'root', animate: false, width: 250 }"
        [ngClass]="{'ng-invalid ng-touched': !selectedUserName && formSubmitted && validate}"
        (blur)="validate && formSubmitted = true"
      >
        <ng-template kendoAutoCompleteItemTemplate let-dataItem>
          <span>{{ dataItem?.itemFullName }}</span>
        </ng-template>
      </kendo-autocomplete>
    </div>
    <kendo-formerror *ngIf="!selectedUserName && formSubmitted && validate">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      <span *ngIf="!selectedUserName">
        Created by cannot be left blank
      </span>
    </kendo-formerror>
</div>
