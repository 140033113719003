<kendo-dialog
  #rejectReactivateDialog
  *ngIf="opened"
  [minWidth]="minWidth"
  [width]="width"
  [dialogRef]="rejectReactivateDialog" 
  (appClickOutside)="close()"
>
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <img src="{{ headerIcon }}" />
      <span class="ml-8">{{
        mode === "reject" ? "Reject Investigation" : "Reactivate Investigation"
      }}</span>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">
    <p class="warning-message mb-16 mt-16">
      {{
        mode === "reject"
          ? "Are you sure you want to reject this investigation? Once this investigation is rejected, you will no longer have access to any associated reports, documents, photos, services or requests."
          : "Are you sure you want to reactivate this investigation? Once this investigation is reactivated, you will have access to any associated reports, documents, photos, services or requests."
      }}
    </p>
    <hr />
    <form [formGroup]="form" autocomplete="off">
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              [for]="description"
              text="Reason For This Change"
              class="asterisk"
            ></kendo-label>
            <kendo-textarea
              #description
              rows="5"
              formControlName="description"
              placeholder="Enter reason"
              fillMode="outline"
              class="min-height-96"
              resizable="both"
            ></kendo-textarea>
            <kendo-formerror
              *ngIf="form?.controls?.description?.errors?.required"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span>Reason cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </div>
  <kendo-dialog-actions layout="end">
    <button
      kendoButton
      [size]="'medium'"
      themeColor="light"
      class="kendo-btn"
      class="mr-16"
      (click)="close()"
    >
      {{ mode === "reject" ? "No, Don’t Reject" : "No, Don’t Reactivate" }}
    </button>
    <button
      kendoButton
      class="kendo-btn"
      [size]="'medium'"
      themeColor="primary"
      class="mr-16"
      (click)="submit()"
    >
      {{ mode === "reject" ? "Yes, Reject" : "Yes, Reactivate" }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
