<!-- Permissions -->
<div [appEnforcePermission]="authConfig.evidence" #evidence="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceDownload" #evidenceDownload="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceView" #evidenceView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceCreate" #evidenceCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceUpdate" #evidenceUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceUpdateOwn" #evidenceUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceDelete" #evidenceDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceTransferCreate" #evidenceTransferCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceTransferUpdate" #evidenceTransferUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceBilling" #evidenceBilling="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceBillingUpdate" #evidenceBillingUpdate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <section *ngIf="evidence?.enabled">
  <!-- ButtonHeader -->
  <h2 *ngIf="ueNumber">UE Number: {{ ueNumber }}</h2>
  <div class="d-flex evidenceHeader">
    <!-- actionMenu -->
    <div class="flex-grow-1">
      <ng-container  *ngIf="evidenceTransferCreate?.enabled
      || evidenceDownload?.enabled
      || evidenceDelete?.enabled
      || evidenceUpdate?.enabled
      || evidenceUpdateOwn?.enabled">
      <ng-template #selectItems>
        <button mat-raised-button color="primary" (click)="pleaseSelect()">
          Actions
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </ng-template>
      <button mat-raised-button color="primary" [matMenuTriggerFor]="actionMenu" *ngIf="selectedEvidenceItems.length > 0; else selectItems">
        Actions
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #actionMenu="matMenu">
        <button mat-menu-item (click)="openTransferModal()" *ngIf="evidenceTransferCreate?.enabled">Transfer</button>
        <button mat-menu-item (click)="downloadDisposalLetter()" *ngIf="evidenceDownload?.enabled">Download Disposal Letter</button>
        <button mat-menu-item (click)="deleteItems(selectedEvidenceItems)" *ngIf="evidenceDelete?.enabled">Delete</button>
        <ng-container *ngIf="selectedEvidenceItems.length && (evidenceUpdate?.enabled || evidenceUpdateOwn?.enabled)">
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="setSelectedStatus(evidenceStatusType.DisposalRequested)">Move to Disposal Requested</button>
          <button mat-menu-item (click)="setSelectedStatus(evidenceStatusType.AwaitingDisposal)">Move to Awaiting Disposal</button>
          <button mat-menu-item (click)="setSelectedStatus(evidenceStatusType.Disposed)">Move to Disposed</button>
        </ng-container>
      </mat-menu>
      </ng-container>
    </div>

    <!-- Spacing -->
    <div class="flexSpacer">

    </div>

    <!-- addEvidenceItem -->
    <div class="flex-grow-1" *ngIf="evidenceCreate?.enabled">
      <button mat-raised-button [disabled]="hasNewItem" class="rounded-button" (click)="addItem()">
        <mat-icon color="accent">add</mat-icon>
        Add Item
      </button>
    </div>

    <!-- viewPhotos -->
    <div class="flex-grow-1">
      <button mat-raised-button [disabled]="hasNewItem" class="rounded-button" (click)="viewPhotos()">
        <mat-icon color="accent">camera_alt</mat-icon>
        View Photos
      </button>
    </div>

    <!-- DownloadMenu -->
    <div class="flex-grow-1 text-right">
      <button mat-raised-button color="primary" [matMenuTriggerFor]="downloadMenu" *ngIf="evidenceDownload?.enabled">
        Download
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #downloadMenu="matMenu">
        <!-- TODO add click events -->
        <button mat-menu-item (click)="downloadEvidenceForm()">Download Item List</button>
        <button mat-menu-item (click)="evidenceHistoryAction()">Download Evidence History</button>
        <button mat-menu-item (click)="downloadPhotos(false)">Download Hi-res Photos</button>
        <button mat-menu-item (click)="downloadPhotos(true)">Download Low-res Photos</button>
        <button mat-menu-item (click)="printPhotos()">Print Photo Sheet</button>
      </mat-menu>
    </div>

  </div><!-- /ButtonHeader -->

  <!-- EvidenceLoop -->
  <div class="container-fluid evidenceRow" *ngFor="let evidenceItem of evidenceItems; let i = index;">
    <div class="row align-items-center">

      <!-- evidenceNumber -->
      <div class="col-md-1">
        <mat-checkbox [(ngModel)]="evidenceItem.isSelected" *ngIf="evidenceItem.id && ((isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || evidenceUpdate?.enabled)">
          {{ evidenceItem.evidenceNumber | evidenceNumber}}</mat-checkbox>
      </div>

      <!-- description -->
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input matInput #description [(ngModel)]="evidenceItem.description" (ngModelChange)="updateValidation(i, $event, 'descriptionError')"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))">
        </mat-form-field>
        <span class="danger-color" *ngIf="evidenceItem?.descriptionError">Description field is required</span>
      </div>

      <!-- takenFrom -->
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Taken From</mat-label>
          <input matInput [(ngModel)]="evidenceItem.takenFrom" (ngModelChange)="updateValidation(i, $event, 'takenFromError')"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))">
        </mat-form-field>
        <span class="danger-color" *ngIf="evidenceItem?.takenFromError">Taken From field is required</span>
      </div>

      <!-- Status -->
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]="evidenceItem.Status" (ngModelChange)="updateValidation(i, $event, 'statusError')"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))">
            <mat-option *ngFor="let status of statusTypes" [value]="status" [innerHTML]="status | titlecase"></mat-option>
          </mat-select>
        </mat-form-field>
        <span class="danger-color" *ngIf="evidenceItem?.statusError">Status field is required</span>
      </div>

      <!-- evidenceStorageLocation -->
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Location</mat-label>
          <mat-select [(ngModel)]="evidenceItem.EvidenceStorageLocation.id" [name]="'storageLocationId' + i" (ngModelChange)="updateValidation(i, $event, 'evidenceStorageLocationError')"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))">
            <mat-option *ngFor="let location of evidenceStorageLocations" [value]="location.id">
              {{ location.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span class="danger-color" *ngIf="evidenceItem?.evidenceStorageLocationError">Location field is required</span>
      </div>

      <div class="col-md-1">
        <mat-form-field>
          <mat-label>Section</mat-label>
          <input matInput [(ngModel)]="evidenceItem.section"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))">
        </mat-form-field>
      </div>

      <div class="col-md-1">
        <mat-form-field>
          <mat-label>Row</mat-label>
          <input matInput [(ngModel)]="evidenceItem.row"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))">
        </mat-form-field>
      </div>

      <!-- Action Buttons -->
      <div class="col-md-1 text-right">
        <button [hidden]="!evidenceItem?.CurrentTransfer" mat-icon-button (click)="receiveItem(evidenceItem)" *ngIf="evidenceTransferUpdate?.enabled">
          <mat-icon color="accent">next_plan</mat-icon>
        </button>
        <button [hidden]="!evidenceItem.id" mat-icon-button (click)="openSidebar(evidenceItem)" *ngIf="evidenceView?.enabled">
          <mat-icon color="accent">open_in_new</mat-icon>
        </button>
        <button [hidden]="evidenceItem.id" mat-icon-button (click)="cancelItem(evidenceItem)">
          <mat-icon color="accent">close</mat-icon>
        </button>
        <button mat-icon-button [disabled]="disableCheckMark" (click)="saveItem(i, evidenceItem)"
          *ngIf="evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled)">
          <mat-icon color="accent">check</mat-icon>
        </button>
      </div>

    </div>
  </div><!-- /EvidenceLoop -->
  </section>
  <!-- Evidence Billing Card -->
  <mat-card class="mt-4" *ngIf="evidenceBilling?.enabled">
    <mat-card-content class="container-fluid">
      <div class="d-flex justify-content-end">
        <ng-container *ngIf="evidenceBillingUpdate?.enabled">
          <button mat-icon-button *ngIf="evidenceBillingEditMode; else evidenceBillingEditButton" (click)="evidenceBillingEditMode = false">
            <mat-icon color="accent">cancel</mat-icon>
          </button>
          <ng-template #evidenceBillingEditButton>
            <button mat-icon-button (click)="evidenceBillingEditMode = true">
              <mat-icon color="accent">border_color</mat-icon>
            </button>
          </ng-template>
        </ng-container>
      </div>
      <form #evidenceBillingForm="ngForm">
        <div class="row">
          <div class="col-2 borderRight">
            <h4>Evidence Billing</h4>
            <mat-form-field>
              <mat-label># of Pallets</mat-label>
              <input class="numeric-input" matInput [(ngModel)]="investigation.EvidenceBilling.palletCount" name="palletCount"
                type="number" min="0" required (ngModelChange)="palletChange.next()" [disabled]="!evidenceBillingUpdate?.enabled">
            </mat-form-field>
          </div>
          <div class="col-10 billingInfo">
            <ng-container *ngIf="evidenceBillingEditMode; else billingView">
              <p>Billing Override Information</p>
              <div class="row">
                <div class="col-12">
                  <mat-radio-group [(ngModel)]="investigation.EvidenceBilling.billable" required name="billable">
                    <mat-radio-button [value]="true" [checked]="true">Billable</mat-radio-button>
                    <mat-radio-button [value]="false" class="pl-3">Non-Billable</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-2">
                  <mat-form-field>
                    <mat-label>Bill Rate</mat-label>
                    <input matInput [(ngModel)]="investigation.EvidenceBilling.rate" required name="rate" type="number" />
                  </mat-form-field>
                </div>
                <div class="col-3">
                  <mat-form-field>
                    <mat-label>Billing Schedule</mat-label>
                    <mat-select [(ngModel)]="investigation.EvidenceBilling.BillingFrequency.id" name="billingFrequency" required>
                      <mat-option *ngFor="let freq of billingOptions" [value]="freq.id">{{ freq.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <app-datepicker [(ngModel)]="investigation.EvidenceBilling.startDate" name="billingDate" [label]="'Billing Date'" [required]="true"></app-datepicker>
                </div>
                <div class="col-2">
                  <app-datepicker [(ngModel)]="investigation.EvidenceBilling.currentExpensed" name="currentExpensed" [label]="'Current Bill'" [required]="false"></app-datepicker>
                </div>
                <div class="col-5">
                  <mat-form-field appearance="outline">
                    <mat-label>Notes</mat-label>
                    <input matInput [(ngModel)]="investigation.EvidenceBilling.notes" name="notes" required />
                  </mat-form-field>
                </div>
              </div>
            </ng-container>
            <ng-template #billingView>
              <p>Billing Override Information</p>
              <div class="row">
                <div class="col-2">
                  <p>Bill Rate</p>
                  <p class="muted" [innerHTML]="investigation.EvidenceBilling?.rate"></p>
                </div>
                <div class="col-2">
                  <p>Billing Schedule</p>
                  <p class="muted" [innerHTML]="investigation.EvidenceBilling?.BillingFrequency?.name"></p>
                </div>
                <div class="col-2">
                  <p>Billing Date</p>
                  <p class="muted" [innerHTML]="investigation.EvidenceBilling?.startDate ? (investigation.EvidenceBilling?.startDate | customDate: 'MM/dd/yyyy') : '' "></p>
                </div>
                <div class="col-2">
                  <p>Last Billed Date</p>
                  <p class="muted" [innerHTML]="investigation.EvidenceBilling?.currentExpensed ? (investigation.EvidenceBilling?.currentExpensed | customDate: 'MM/dd/yyyy') : '' "></p>
                </div>
                <div class="col-2">
                  <p>Previous Billed Date</p>
                  <p class="muted" [innerHTML]="investigation.EvidenceBilling?.lastExpensed ? (investigation.EvidenceBilling?.lastExpensed | customDate: 'MM/dd/yyyy') : '' "></p>
                </div>
                <div class="col-2">
                  <p>Next Bill</p>
                  <p class="muted" [innerHTML]="getNextBill()"></p>
                </div>
                <div class="col-2">
                  <p>Billable</p>
                  <p class="muted" [innerHTML]="investigation.EvidenceBilling?.billable ? 'Yes' : 'No'"></p>
                </div>
                <div class="col-4">
                  <p>Notes</p>
                  <p class="muted" [innerHTML]="investigation.EvidenceBilling?.notes"></p>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-end" *ngIf="evidenceBillingEditMode">
        <button mat-raised-button color="primary" [disabled]="!evidenceBillingForm.valid || !evidenceBillingForm.form.dirty" (click)="saveEvidenceBilling()">Save</button>
      </div>
    </mat-card-content>
  </mat-card><!-- /Evidence Billing Card -->
</section>