import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { GqlInvestigationMetricsInvestigatorService } from 'src/app/shared/modules/graphql/services';
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsInvestigatorConnection } from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class InvestigationMetricsInvestigatorService {
  constructor(
    private reports: GqlInvestigationMetricsInvestigatorService
  ) { }

  public get(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationMetricsInvestigatorConnection> {
    return this.reports.get(filters, options || new ListPage());
  }

}
