import { connectionQuery } from './../../../helpers.class';
import { InvoiceItemConnectionQuery } from './invoice-item.operations';
import { IApiInvoiceItem, IApiInvoiceItemFilter } from './../../../types/types';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { IApiInvoiceItemConnection } from "../../../types/types";

@Injectable()
export class GqlInvoiceItemService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getInvoiceItems(filters: IApiInvoiceItemFilter[], options: IGqlBaseOptions): Observable<IApiInvoiceItemConnection> {
    return this.apollo.query(connectionQuery(InvoiceItemConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }
}
