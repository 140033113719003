<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span class="ml-0">Download Photos</span>
      <div class="modal-subtitle" *ngIf="investigation?.nefcoNumber">
        Investigation: {{investigation?.nefcoNumber}}
      </div>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">
    <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }"
      [cols]="['repeat(1,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <div class="k-checkbox-wrap">
            <input type="checkbox" kendoCheckBox #downloadAll id="downloadAll" (change)="checkAll($event); error = null;"
              [checked]="allSelected()" />
            <kendo-label [for]="downloadAll" class="k-checkbox-label font-weight-600 checkbox-margin"
              style="margin-bottom: 0" text="Download all photos"></kendo-label>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <hr class="mt-0" />
    <p class="warning-message mt-8">
      Download selected photos:
    </p>
    <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(3,minmax(0,1fr))']">
      <kendo-gridlayout-item *ngFor="let photosFolder of photosFolders;let i = index">
        <div class="kendo-formfield">
          <p class="warning-message mt-8">
            {{photosFolder.name }}
          </p>
          <p>
            <input type="radio" kendoRadioButton [value]="'all'" [name]="'all' + i" #all
              [(ngModel)]="photosFolder.downloadOption" />
            <kendo-label class="k-checkbox-label font-weight-600 checkbox-margin" style="margin-bottom: 0" [for]="all"
              text="All Photos"></kendo-label>
          </p>
          <p>
            <input type="radio" kendoRadioButton [value]="'used'" [name]="'used' + i" #used
              [(ngModel)]="photosFolder.downloadOption" />
            <kendo-label class="k-checkbox-label font-weight-600 checkbox-margin" style="margin-bottom: 0" [for]="used"
              text="Used Photos"></kendo-label>
          </p>
          <p>
            <input type="radio" kendoRadioButton [value]="'unused'" #unused [name]="'unused' + i"
              [(ngModel)]="photosFolder.downloadOption" />
            <kendo-label class="k-checkbox-label font-weight-600 checkbox-margin" style="margin-bottom: 0"
              [for]="unused" text="Unused Photos"></kendo-label>
          </p>
          <p>
            <input type="radio" kendoRadioButton [value]="'none'" #none [name]="'none' + i"
              [(ngModel)]="photosFolder.downloadOption" />
            <kendo-label class="k-checkbox-label font-weight-600 checkbox-margin" style="margin-bottom: 0" [for]="none"
              text="None"></kendo-label>
          </p>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-formerror *ngIf="error" class="mr-16 download-error">{{ error }}</kendo-formerror>
  </div>
  <kendo-dialog-actions class="schedule-action p-16-24" layout="end">
    <div>
      <button kendoButton [size]="'medium'" themeColor="light" class="kendo-btn mr-16" (click)="close()">
        Cancel
      </button>
      <button kendoButton class="kendo-btn mr-16" [disabled]="downloading" [size]="'medium'" themeColor="primary"
        (click)="download()">
        High Resolution Download
      </button>
      <button kendoButton class="kendo-btn" [disabled]="downloading" [size]="'medium'" themeColor="primary"
        (click)="download(true)">
        Download
      </button>
    </div>
  </kendo-dialog-actions>
</div>
