import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModuleRouter } from './portal.router';
import { SharedModule } from 'src/app/shared/shared.module';
import { PortalGuard } from "./portal.guard";
import { PortalMainComponent } from './portal-main/portal-main.component';
import { PortalHeaderComponent } from "./portal-header/portal-header.component";

import {
  LocalStorageService,
  PortalUserService,
  PortalRequestService,
  PortalAuthService,
  PortalAssignmentService
} from "../../shared/services/index";
import { SharedComponentModule } from '../shared/shared-component.module';
import { IntakeFormComponent } from './intake-form/intake-form.component';
import { YourCompanyContactInformationComponent } from './intake-form/your-company-contact-information/your-company-contact-information.component';
import { LossInformationComponent } from './intake-form/loss-information/loss-information.component';
import { InsuredInformationComponent } from './intake-form/insured-information/insured-information.component';
import { LossDetailsSpecialInstructionsComponent } from './intake-form/loss-details-special-instructions/loss-details-special-instructions.component';
import { CreatePasswordComponent } from './intake-form/create-password/create-password.component';
import { CanWeHelpYouComponent } from './intake-form/can-we-help-you/can-we-help-you.component';
import { AssignmentSentComponent } from './assignment-sent/assignment-sent.component';
import { LoginComponent } from './login/login.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';


@NgModule({
  declarations: [
    PortalMainComponent,
    PortalHeaderComponent,
    IntakeFormComponent,
    YourCompanyContactInformationComponent,
    LossInformationComponent,
    InsuredInformationComponent,
    LossDetailsSpecialInstructionsComponent,
    CreatePasswordComponent,
    CanWeHelpYouComponent,
    AssignmentSentComponent,
    LoginComponent,
    AlertMessageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PortalModuleRouter,
    SharedComponentModule,
  ],
  providers: [
    PortalGuard,
    LocalStorageService,
    PortalUserService,
    PortalRequestService,
    PortalAuthService,
    PortalAssignmentService,
  ],
  exports: [
    SharedComponentModule,
  ]
})
export class PortalModule { }
