import gql from 'graphql-tag';

export const getInvestigationMetricsStateQuery = gql`query InvestigationMetricsStateConnection($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  InvestigationMetricsStateConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    pageInfo {
      limit
      offset
    }
    totalCount
    edges {
      cursor
      node {
        state
        data
      }
    }
  }
}`;
