import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IApiContact } from 'src/app/shared/modules/graphql/types/types';
import { Columns, IInvestigationTableConfig, TableType } from '../../investigations-table-view/investigations-table/investigations-table.component';
import {
  contactsProfileCompanyInformationView,
  contactsProfileContactInformationViewAssigned,
  contactsInvestigations,
  contactsInvestigationsView,
} from "src/app/shared/helpers/auth-config/contacts.config";

@Component({
  templateUrl: './contact-management.component.html',
  styleUrls: ['./contact-management.component.scss']
})
export class ContactManagementComponent implements OnInit {
  public permissionConfig = {
    contactsProfileCompanyInformationView,
    contactsProfileContactInformationViewAssigned,
    contactsInvestigations,
    contactsInvestigationsView
  };

  public contactConfig: IInvestigationTableConfig = {
    type: TableType.contact,
    displayedColumns: [Columns.createdAt, Columns.nefcoNumber, Columns.contactRole, Columns.priority, Columns.lossDate, Columns.riskType,
      Columns.investigationLocation, Columns.status],
    contactId: ""
  };

  public contact: IApiContact;
  public selectedTab = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  public ngOnInit() {
    this.contact = this.route.snapshot.data.contact;
    this.contactConfig.contactId = this.contact.id;
  }

  public goBack() {
    this.router.navigate(["admin", "contacts", "list"]);
  }

}
