import { GqlExpenseApplicationService } from '../../../modules/graphql/services/expenses/expense-application/expense-application.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../../modules/graphql/interfaces/base.interfaces';
import { IApiExpenseApplicationConnection, IApiExpenseApplicationFilter } from "../../../modules/graphql/types/types";
import { ListPage } from "../../../helpers/list-page.class";

@Injectable()
export class ExpenseApplicationService {

  constructor(
    private gqlExpenseApplicationService: GqlExpenseApplicationService
  ) { }

  public get(filters?: IApiExpenseApplicationFilter[], options?: IGqlBaseOptions): Observable<IApiExpenseApplicationConnection> {
    return this.gqlExpenseApplicationService.getExpenseApplications(filters, options || new ListPage());
  }

}
