import { IApiEvidenceBillingFrequency, IApiEvidenceBillingFrequencyConnection, IApiEvidenceBillingFrequencyFilter } from './../../../modules/graphql/types/types';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { EvidenceBillingFrequencyService } from "./evidence-billing-frequency.service";
import { NefcoPagedDataSource } from '../../../helpers/nefco-data-source.class';

export class EvidenceBillingFrequencyDataSource extends NefcoPagedDataSource<IApiEvidenceBillingFrequency, EvidenceBillingFrequencyService, IApiEvidenceBillingFrequencyConnection> implements DataSource<IApiEvidenceBillingFrequency> {
  @ApiConnection()
  public load(filters: IApiEvidenceBillingFrequencyFilter[] = []): Promise<IApiEvidenceBillingFrequencyConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
