<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staff" #staffList="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-formfield class="kendo-formfield left-search-icon multi">
  <kendo-label *ngIf="label" [for]="" text="{{label}}"></kendo-label>
  <div style="position: relative;">
    <i class="fa fa-search" aria-hidden="true"></i>
    <kendo-multiselect #multiSelect [checkboxes]="true" [autoClose]="false" [loading]="loading" [valuePrimitive]="true"
      [data]="users" [(ngModel)]="selectedValue" [rounded]="'full'" (valueChange)="selectUser($event)" valueField="id"
      textField="itemFullName" [tagMapper]="tagMapper" [placeholder]="placeholder" [fillMode]="'outline'"
      [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}" (filterChange)="onSearchChange($event)"
      (focus)="onSearchFocus($event)" [disabled]="disabled || !staffList?.enabled">
      <ng-template kendoMultiSelectItemTemplate let-dataItem>
        <span style="padding-left: 5px;">{{ dataItem?.itemFullName }}</span>
      </ng-template>
      <ng-template kendoMultiSelectNoDataTemplate *ngIf="!searchString?.trim()?.length">
        PLEASE SEARCH SOMETHING...
      </ng-template>
      <ng-template kendoMultiSelectNoDataTemplate *ngIf="searchString?.trim()?.length">
        NO DATA FOUND
      </ng-template>
    </kendo-multiselect>
  </div>
</kendo-formfield>
