import { IApiUserOrderBy, IApiDocument, IApiDocumentFilter, IApiDocumentOrderBy } from './../../modules/graphql/types/types';
import { DataSource } from "@angular/cdk/collections";
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { ApiConnection } from "../../decorators/@ApiConnection.decorator";
import { DocumentService } from './document.service';

export class DocumentDataSource extends NefcoPagedDataSource<IApiDocument, DocumentService> implements DataSource<IApiDocument> {
  @ApiConnection()
  public load(filters: IApiDocumentFilter[] = []): Promise<any> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
