import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  DocumentTypes {
    id
    name
    Template {
      id
      oneDriveItemId
    }
  }
`;

export const documentCategoryConnectionQuery = gql`${buildConnectionQuery("DocumentCategory", "documentCategoryConnection", nodeFragment)}`;

export const addDocumentCategoryMutation = gql`mutation AddDocumentCategory($input: AddDocumentCategoryInput!) {
  addDocumentCategory(input: $input) { ${nodeFragment} }
}`;

export const updateDocumentCategoryMutation = gql`mutation UpdateDocumentCategory($input: UpdateDocumentCategoryInput!) {
  updateDocumentCategory(input: $input) { ${nodeFragment} }
}`;

export const removeDocumentCategoryMutation = gql`mutation RemoveDocumentCategory($id: String) {
  removeDocumentCategory(id: $id)
}`;