export enum ExpenseApplicationFilterType {
  NAME
}

export enum ExpenseApplicationOrderByType {
  CREATED_AT
}

export enum ExpenseCategoryFilterType {
  PURPOSE
}

export enum ExpenseCategoryOrderByType {
  NAME = "name",
  CREATED_AT = "createdAt"
}

export enum ExpenseFilterType {
  INVOICE = "INVOICE",
  USER = "USER",
  OUT_OF_POCKET = "OUT_OF_POCKET",
  EXPENSE_ITEM = "EXPENSE_ITEM",
  EXPENSE_PURPOSE = "EXPENSE_PURPOSE",
  INVESTIGATION = "INVESTIGATION",
  EXPENSE_MONTH = "EXPENSE_MONTH",
  EXPENSE_WEEK = "EXPENSE_WEEK"
}

export enum ExpenseItemFilterType {
  CATEGORY,
  TYPE
}

export enum ExpenseItemOrderByType {
  CREATED_AT
}

export enum ExpenseOrderByType {
  CREATED_AT = "CREATED_AT",
  EXPENSE_DATE = "EXPENSE_DATE"
}

export enum ExpenseTypeFilterType {
  NAME
}

export enum ExpenseTypeOrderByType {
  CREATED_AT
}

export enum ExpensePurposeFilterType {
  NAME
}

export enum ExpensePurposeOrderByType {
  CREATED_AT
}

export enum ExpenseActionType {
  ADD_MILEAGE = "ADD_MILEAGE",
  ADD_EXPENSE = "ADD_EXPENSE",
  ADD_TIME = "ADD_TIME",
  UPDATE = "UPDATE",
  DUPLICATE = "DUPLICATE"
}

