import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { IApiUpdateDocumentShareInput, IApiDocumentShare, IApiAddDocumentShareInput, IApiDocumentShareFilter, IApiDocumentShareConnection, IApiDocumentZipDownload } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { GqlDocumentShareService } from '../../../modules/graphql/services';
import { Injectable } from '@angular/core';

@Injectable()
export class DocumentShareService {

  constructor(
    private documentShares: GqlDocumentShareService,
  ) { }

  public get(filters: IApiDocumentShareFilter[] = [], options: IGqlBaseOptions): Observable<IApiDocumentShareConnection> {
    return this.documentShares.getShares(filters, options || new ListPage(-1));
  }

  public add(typeInput: IApiAddDocumentShareInput): Observable<IApiDocumentShare> {
    return this.documentShares.addShare(typeInput);
  }

  public update(typeInput: IApiUpdateDocumentShareInput): Observable<IApiDocumentShare> {
    return this.documentShares.updateShare(typeInput);
  }

  public remove(typeId: string): Observable<boolean> {
    return this.documentShares.removeShare(typeId);
  }

  public downloadZip(typeInput: IApiDocumentZipDownload): Observable<string> {
    return this.documentShares.downloadZip(typeInput);
  }

  public refreshInvestigationDocumentShare(): Observable<string> {
    return this.documentShares.refreshInvestigationDocumentShare();
  }
}
