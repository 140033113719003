import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { GqlAddressTypeService } from 'src/app/shared/modules/graphql/services/address/address-type/address-type.graphql';
import { IApiAddressTypeFilter } from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class AddressTypeService {

  constructor(
    private addressTypes: GqlAddressTypeService,
  ) { }

    public get(filters?: IApiAddressTypeFilter[], options?: IGqlBaseOptions) {
        return this.addressTypes.getAddressTypes(filters, options || new ListPage());
    }

}
