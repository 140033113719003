import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlInvestigationSourceService, GqlInvestigationStaffService } from 'src/app/shared/modules/graphql/services';
import { IApiInvestigationStaffConnection, IApiAssignInvestigatorInput, IApiInvestigationStaff, IApiInvestigationSource } from '../../../modules/graphql/types/types';
import {
  IApiAddInvestigationStaffInput,
  IApiInvestigationStaffFilter,
  IApiUpdateInvestigationStaffInput
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";


@Injectable()
export class InvestigationSourceService {

  constructor(
    private gqlInvestigationSourceService: GqlInvestigationSourceService
  ) { }

  public get(): Observable<IApiInvestigationSource[]> {
    return this.gqlInvestigationSourceService.getInvestigationSource();
  }

}
