import { IApiUser, IApiUpdateUserInput, IApiAddUserDetailInput, IApiRemoveUserDetailInput } from './../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlUserDetailService } from '../../modules/graphql/services/user/user-detail/user-detail.service';
import { NefcoDateHelper } from '../../helpers/nefco-date.class';

@Injectable()
export class UserDetailService {

  constructor(
    private userDetailsService: GqlUserDetailService,
  ) { }


  public addUserDetail(input: IApiAddUserDetailInput): Observable<IApiUser> {

    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    if (input?.startDate) input.startDate = NefcoDateHelper.toUtc(input.startDate, 12);
    if (input?.endDate) input.endDate = NefcoDateHelper.toUtc(input.endDate, 12);
    
    return this.userDetailsService.addUserDetail(input);
  }

  public updateUserDetail(input: IApiUpdateUserInput): Observable<IApiUser> {
    return this.userDetailsService.updateUserDetail(input);
  }

  public removeUserDetail(input: IApiRemoveUserDetailInput) {
    return this.userDetailsService.removeUserDetail(input);
  }

}
