import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiServiceStatusConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  serviceStatusConnectionQuery, addServiceStatusMutation,
  removeServiceStatusMutation, getServiceStatusByIdQuery,
  updateServiceStatusMutation
} from './service-status.operations';
import {
  IApiServiceStatusFilter, IApiAddServiceStatusInput, IApiUpdateServiceStatusInput,
  IApiRemoveServiceStatusInput, IApiServiceStatus
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlServiceStatusService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiServiceStatusFilter[], options: IGqlBaseOptions): Observable<IApiServiceStatusConnection> {
    return this.apollo.query(connectionQuery(serviceStatusConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddServiceStatusInput): Observable<IApiServiceStatus> {
    return this.apollo.mutate({
      mutation: addServiceStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(input: IApiRemoveServiceStatusInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeServiceStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateServiceStatusInput): Observable<IApiServiceStatus> {
    return this.apollo.mutate({
      mutation: updateServiceStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getServiceStatusById(id: string): Observable<IApiServiceStatus> {
    return this.apollo.query({
      query: getServiceStatusByIdQuery,
      variables: { id: id }
    }).pipe(
      safeResolve(true)
    );
  }
}
