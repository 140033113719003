import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContactResolver, InvestigationResolver } from './../../shared/route.resolvers';
import { InvestigationManagementComponent } from "../shared/investigations";
import { ContactManagementComponent } from "../shared/contacts";
import { StaffManagementComponent } from "../shared/staff";
import { DashboardTabsComponent } from "../shared/dashboard/dashboard-tabs/dashboard-tabs.component";
import { TimeAndExpManagementComponent } from "../shared/time-and-exp/index";
import { NotificationsManagementComponent } from "../shared/notifications/index";
import { StaffProfileComponent } from "../../shared/components/staff-profile/staff-profile.component";
import { EvidenceListComponent } from '../shared/evidence';
import { PermissionGuard, MultiPermissionGuard } from 'src/app/shared/route.guards';
import { evidence } from "src/app/shared/helpers/auth-config/evidence.config";
import {
  contactsProfileContactInformationViewAssigned,
  contactsProfileCompanyInformationView,
  contactsInvestigations,
} from "src/app/shared/helpers/auth-config/contacts.config";
import {
  staffViewAll,
  staffContactInformationView,
  staffContactInformationOwnView,
  staffUserInformationView,
  staffUserInformationOwnView,
  staffEmergencyInformationView,
  staffEmergencyInformationOwnView,
  staffInvestigationsView,
  staffCertificationsList,
  staffCertificationsOwnList,
  staffInvestigationsList,
  staffInvestigationsOwnList,
} from "src/app/shared/helpers/auth-config/staff.config";
import { investigationView } from "src/app/shared/helpers/auth-config/investigations.config";
const routes: Routes = [
  {
    path: "",
    component: DashboardTabsComponent
  },
  {
    path: "contacts/:contactId",
    component: ContactManagementComponent,
    resolve: {
      contact: ContactResolver
    },
    canActivate: [MultiPermissionGuard],
    data: [contactsProfileContactInformationViewAssigned,
      contactsProfileCompanyInformationView,
      contactsInvestigations,
    ],
  },
  {
    path: "investigations/:investigationId",
    component: InvestigationManagementComponent,
    resolve: {
      investigation: InvestigationResolver,
    },
    canActivate: [PermissionGuard],
    data: investigationView,
  },
  {
    path: "notifications",
    component: NotificationsManagementComponent
  },
  {
    path: "staff/:id",
    component: StaffManagementComponent,
    canActivate: [MultiPermissionGuard],
    data: [staffContactInformationView,
      staffContactInformationOwnView,
      staffUserInformationView,
      staffUserInformationOwnView,
      staffEmergencyInformationView,
      staffEmergencyInformationOwnView,
      staffCertificationsList,
      staffCertificationsOwnList,
      staffInvestigationsList,
      staffInvestigationsOwnList,
    ],
  },
  {
    path: "time",
    children: [
      {
        path: "management",
        component: TimeAndExpManagementComponent
      }
    ]
  },
  {
    path: "profile/:id",
    component: StaffProfileComponent,
    canActivate: [MultiPermissionGuard],
    data: [staffContactInformationView,
      staffContactInformationOwnView,
      staffUserInformationView,
      staffUserInformationOwnView,
      staffEmergencyInformationView,
      staffEmergencyInformationOwnView,
      staffInvestigationsView
    ],
  },
  {
    path: "evidence",
    children: [
      {
        path: "list",
        component: EvidenceListComponent,
        canActivate: [PermissionGuard],
        data: evidence,
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HighOrderComponentModuleRouter { }
