import { switchMap } from 'rxjs/operators';
import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { filter } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { Component, OnInit } from '@angular/core';
import { TimeEntryTypeService } from 'src/app/shared/services/time-entry/time-entry-type/time-entry-type.service';
import { TimeEntryTypeDataSource } from 'src/app/shared/services/time-entry/time-entry-type/time-entry-type.datasource';
import { TimeEntryTypeModalComponent } from '../time-entry-type-modal/time-entry-type-modal.component';
import { manageTimeEntryTypesCreate, manageTimeEntryTypesUpdate, manageTimeEntryTypesDelete, manageTimeEntryTypesDeactivate } from "../../../../shared/helpers/auth-config/manage.config";
import { SortDescriptor } from '@progress/kendo-data-query';
import { IApiTimeEntryType, IApiTimeEntryTypeOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { AuthService } from 'src/app/shared/services';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { forkJoin } from 'rxjs';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import _ from 'lodash';

@Component({
  selector: 'app-bookkeeping-time-types',
  templateUrl: './bookkeeping-time-types.component.html',
  styleUrls: ['./bookkeeping-time-types.component.scss']
})
export class BookkeepingTimeTypesComponent implements OnInit {
  public authConfig = {
    manageTimeEntryTypesCreate,
    manageTimeEntryTypesUpdate,
    manageTimeEntryTypesDelete,
    manageTimeEntryTypesDeactivate
  }

  private _dataSource: TimeEntryTypeDataSource;
  public get dataSource(): TimeEntryTypeDataSource {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public sort: SortDescriptor[] = [
    {
      field: IApiTimeEntryTypeOrderBy.Name,
      dir: "asc",
    },
  ];
  public data: IApiTimeEntryType[];
  public actionItem: { text: string }[] = [];
  constructor(
    private loader: LoaderService,
    private notifications: NotificationsService,
    private timeEntryTypeService: TimeEntryTypeService,
    private authService: AuthService,
    private dialogService: DialogService
  ) { }

  public add() {
    const dialog: DialogRef = this.dialogService.open({
      content: TimeEntryTypeModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as TimeEntryTypeModalComponent;
    dialogInstance.data = null;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return !_.isEmpty(v);
      })
    ).subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

  public edit(data: IApiTimeEntryType) {
    const dialog: DialogRef = this.dialogService.open({
      content: TimeEntryTypeModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as TimeEntryTypeModalComponent;
    dialogInstance.data = data;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return !_.isEmpty(v);
      })
    ).subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

  public toggleActivation(typeId: string) {
    this.timeEntryTypeService.toggleActivation(typeId).subscribe(() => this.load());
  }

  public delete(typeId: string) {
    this.notifications.kendoConfirm("Really delete this time entry type?", "Confirm Delete").pipe(
      filter(v => !!v),
      switchMap(() => this.timeEntryTypeService.remove(typeId))
    ).pipe(
      this.notifications.catchAlertPipe("Error deleting time entry type."),
      this.notifications.snackbarPipe("Time entry type deleted.")
    ).subscribe(() => this.load());
  }

  ngOnInit() {
    this._dataSource = new TimeEntryTypeDataSource(this.timeEntryTypeService);
    this.loader.attachObservable(this.dataSource.loading$);

    forkJoin([
      this.authService.hasCategoryPermission(this.authConfig.manageTimeEntryTypesUpdate.category, this.authConfig.manageTimeEntryTypesUpdate.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.manageTimeEntryTypesDeactivate.category, this.authConfig.manageTimeEntryTypesDeactivate.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.manageTimeEntryTypesDelete.category, this.authConfig.manageTimeEntryTypesDelete.appliedPermissions)
    ]).subscribe(([up, acDa, del]) => {
      if (up) {
        this.actionItem.push({text: 'Update'});
      }
      if (acDa) {
        this.actionItem.push({text: 'Activate'});
        this.actionItem.push({text: 'Deactivate'});
      }
      if (del) {
        this.actionItem.push({text: 'Delete'});
      }
    });

    this.load();

    this.dataSource?.contents$.subscribe((res) => {
      this.data = res;
    });
  }

  public load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiTimeEntryTypeOrderBy.Name;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  };

  getFilteredActionItems(dataItem: IApiTimeEntryType) {
    return this.actionItem.filter(item => dataItem.activated && item.text !== 'Activate' || !dataItem.activated && item.text !== 'Deactivate' );
  }

  public itemSelected(action: { text: string }, dataItem: IApiTimeEntryType) {
    switch (action.text) {
      case "Update":
        this.edit(dataItem);
        break;
      case "Delete":
        this.delete(dataItem.id);
        break;
      case "Activate":
      case "Deactivate":
        this.toggleActivation(dataItem.id);
        break;
      default:
        break;
    }
  }
}
