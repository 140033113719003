import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlNotificationTypeService } from 'src/app/shared/modules/graphql/services/notification/notification-type/notification-type.service';
import {
  IApiNotificationType, IApiNotificationTypeFilter, IApiNotificationTypesConnection
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";


@Injectable()
export class UserNotificationTypeService {

  constructor(
    private gqlNotificationTypeService: GqlNotificationTypeService
  ) { }

  public get(filters: IApiNotificationTypeFilter[] = [], options?: IGqlBaseOptions): Observable<IApiNotificationTypesConnection> {
    return this.gqlNotificationTypeService.get(filters, options || new ListPage(-1));
  }

  public getById(id: string): Observable<IApiNotificationType> {
    return this.gqlNotificationTypeService.getById(id);
  }
}
