import { DataSource } from "@angular/cdk/collections";
import { IApiBranch, IApiBranchConnection, IApiBranchFilter, IApiBranchOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { ApiConnection } from '../../decorators/@ApiConnection.decorator';
import { BranchService } from "./branch.service";

export class BranchDataSource extends NefcoPagedDataSource<IApiBranch, BranchService, IApiBranchConnection> implements DataSource<IApiBranch> {
  @ApiConnection()
  public load(filters?: IApiBranchFilter[]): Promise<IApiBranchConnection> {
    return this.service.get(filters, this.listPage).toPromise();
    
  }
}
