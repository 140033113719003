import { PipeTransform, Pipe } from '@angular/core';
import { IAddressPlace } from '../interfaces/address.interfaces';
import { IBranch, IBranchAddress } from '../interfaces/branch.interfaces';

@Pipe({ name: 'addressFormat' })
export class AddressPipe implements PipeTransform {
    transform(address: IAddressPlace): string {
        return (`${address.address1}<br />${address?.address2}<br />${address.city} ${address.state}, ${address.postal}`).replace('null', '').replace('<br /><br />', '<br />');
    }
}

@Pipe({ name: 'addressBranchFormat' })
export class AddressBranchPipe implements PipeTransform {
  transform(branch: IBranch): string {
    const branchAddress: IBranchAddress = branch.Addresses.find(address => {
      if (address.isPrimary) {
        return address;
      }
    });
    if (branchAddress) {
      const address = branchAddress.Address;
      if (address) {
        return `${address.address1}<br />${address.city} ${address.state}, ${address.postal}`;
      }
    }
  }
}
