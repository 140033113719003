import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const nodeFragment = `
  id
  notes
  authorizedBy
  dueDate
  Type {
    id
    name
  }
  Investigation {
    id
  }
  AssignedUsers {
    id
    firstName
    lastName
  }
  Status
  StatusHistory {
    id
    name
    comment
    CreatedBy {
      id
      firstName
      lastName
    }
    createdAt
  }
  SubServices {
    id
    notes
    isComplete
    isVisible
    Type {
      id
      name
    }
    AssignedUser {
      id
      firstName
      lastName
    }
    createdAt
    updatedAt
  }
  createdAt
  updatedAt
`;

export const servicesConnectionQuery = gql`${buildConnectionQuery("Service", "servicesConnection", nodeFragment)}`;

export const addServiceMutation = gql`mutation createService($input: AddServiceInput!) {
  addService(input: $input) { ${nodeFragment} }
  }`;

export const removeServiceMutation = gql`mutation removeService($id: ID!) {
  removeService(id: $id)
}`;

export const updateServiceMutation = gql`mutation updateService($input: UpdateServiceInput!) {
  updateService(input: $input) { ${nodeFragment} }
}`;

export const getServiceByIdQuery = gql`query getServiceById($id: ID!) {
  getServiceById(id: $id) {${nodeFragment}}
}`;
