import gql from 'graphql-tag';

export const getInvestigationEditorTimelogQuery = gql`query InvestigationEditorTimelogConnection($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  investigationEditorTimelogConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    pageInfo {
      limit
      offset
      __typename
    }
    totalCount
    edges {
      cursor
      node {
        id 
        firstName
        middleName
        lastName
        data
      }
    }
  }
}`;

export const getInvestigationEditorTimelogTotal = gql`query InvestigationEditorTimelogAverageConnection($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  investigationEditorTimelogAverageConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    onSceneToReportsUploaded
    onSceneToReportSent
  }
}`;
