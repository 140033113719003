<!-- Permissions -->
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursList" #timeEntryInvestigationHoursList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryUnlinkedHoursList" #timeEntryUnlinkedHoursList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseList" #expenseUnlinkedExpenseList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageList" #expenseUnlinkedMileageList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageList" #expenseInvestigationMileageList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesList" #expenseInvestigationExpensesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseOutOfPocketList" #expenseOutOfPocketList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseReportNefcoCardList" #expenseReportNefcoCardList="appEnforcePermission"></div>
<!-- Permissions -->
<section>
    <div class="page-header">
        <h4 class="page-title mb-0">Time and Expenses</h4>
        <div>
            <kendo-button
              kendoButton
              [themeColor]="'light'"
              fillMode="solid"
              iconClass="fa-solid fa-arrow-up-right-from-square"
              (click)="viewCalendar()"
              style="display: flex; flex-direction: row-reverse;"
            >View Calendar</kendo-button>
        </div>
    </div>
    <kendo-tabstrip #tabstrip [scrollable]="false" style="background-color: #FFFFFF;" class="time-expense-tabs">
        <kendo-tabstrip-tab [selected]="selectedTab === 'MyHours'" *ngIf="timeEntryUnlinkedHoursList?.enabled || timeEntryInvestigationHoursList?.enabled">
            <ng-template kendoTabTitle>
                <span>My Hours</span>
            </ng-template>
            <ng-template kendoTabContent>
                <app-time-exp-hours-kendo [userId]="userId" [investigations]="investigations"></app-time-exp-hours-kendo>
            </ng-template>
        </kendo-tabstrip-tab>
        
        <kendo-tabstrip-tab [selected]="selectedTab === 'MyExpenses'" *ngIf="expenseUnlinkedExpenseList?.enabled || expenseUnlinkedMileageList?.enabled || expenseInvestigationMileageList?.enabled || expenseInvestigationExpensesList?.enabled">
            <ng-template kendoTabTitle>
                <span>My Expenses</span>
            </ng-template>
            <ng-template kendoTabContent>
                <app-time-exp-expenses [userId]="userId" [investigations]="investigations" [expenseItems]="expenseItems"></app-time-exp-expenses>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [selected]="selectedTab === 'OutofPocketExpenseReport'" *ngIf="expenseOutOfPocketList?.enabled">
            <ng-template kendoTabTitle>
                <span>Out of Pocket Expense Report</span>
            </ng-template>
            <ng-template kendoTabContent>
                <app-oop-expense-report [expenseItems]="expenseItems" [investigations]="investigations" [documentTypes]="documentTypes"></app-oop-expense-report>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [selected]="selectedTab === 'NEFCOCardExpenseReport'" *ngIf="expenseReportNefcoCardList?.enabled">
            <ng-template kendoTabTitle>
                <span>NEFCO Card Expense Report</span>
            </ng-template>
            <ng-template kendoTabContent>
                <app-nefco-card-expense-report [expenseItems]="expenseItems" [investigations]="investigations" [documentTypes]="documentTypes"></app-nefco-card-expense-report>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</section>