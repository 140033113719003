import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const nodeFragment = `
  id
  name
  Transfers {
    id
    trackingNumber
  }
  createdAt
  updatedAt
`;

export const ShippingCarrierConnectionQuery = gql`${buildConnectionQuery("ShippingCarrier", "shippingCarrierConnection", nodeFragment)}`;

export const getShippingCarrierByIdQuery = gql`query getShippingCarrierById($id: String!) {
  getShippingCarrierById(id: $id) { ${ nodeFragment } }
}`;

export const addShippingCarrierMutation = gql`mutation AddShippingCarrier($input: AddShippingCarrierInput!) {
  addShippingCarrier(input: $input) { ${ nodeFragment } }
}`;

export const updateShippingCarrierMutation = gql`mutation UpdateShippingCarrier($input: UpdateShippingCarrierInput!) {
  updateShippingCarrier(input: $input) { ${ nodeFragment } }
}`;

export const removeShippingCarrierMutation = gql`mutation RemoveShippingCarrier($id: ID!) {
  removeShippingCarrier(id: $id)
}`;