<hr />
<form autocomplete="off" [formGroup]="ClientInvestigationNote">
  <div class="d-flex">
    <h1>Your Company & Contact Information</h1>
    <ng-template #contacttemplate>
      If you see issues related to your profile data, please email
      <span class="underline">services@nefco.net</span> or call
      <span class="underline">800-675-8500</span> for assistance.
    </ng-template>
    <div
      kendoTooltip
      [tooltipTemplate]="contacttemplate"
      tooltipClass="contact-tooltip"
      filter=".fa-circle-info"
      tooltipWidth="284"
      position="bottom"
    >
      <i class="fa-solid fa-circle-info"></i>
    </div>
  </div>
  <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label
          class="asterisk"
          [for]="firstName"
          text="Your Name"
        ></kendo-label>
        <kendo-textbox
          #firstName
          formControlName="firstName"
          placeholder="Enter Name"
          [disabled]="isCurrentContact"
          fillMode="outline"
          [clearButton]="true"
          appSkipClearButtonTabindex
        ></kendo-textbox>
        <kendo-formerror>
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span
            *ngIf="
              ClientInvestigationNote?.controls?.firstName?.errors?.required
            "
            >Your Name cannot be left blank</span
          >
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <kendo-gridlayout
    [rows]="['auto']"
    [gap]="{ rows: 0, cols: 24 }"
    [cols]="['47%', '47%']"
  >
    <kendo-gridlayout-item>
      <kendo-formfield
        [formGroup]="ClientInvestigationNote.controls.ContactPhone"
      >
        <kendo-label
          [class.asterisk]="
            ClientInvestigationNote?.controls?.ContactPhone?.controls?.extension
              ?.value
          "
          [for]="contactNumber"
          text="Contact Number"
        ></kendo-label>
        <kendo-maskedtextbox
          #contactNumber
          formControlName="number"
          [disabled]="isCurrentContact"
          fillMode="outline"
          placeholder="Enter contactnumber"
          mask="(000) 000-0000"
        ></kendo-maskedtextbox>
        <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
        <kendo-formerror>
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span
            *ngIf="
              ClientInvestigationNote?.controls?.ContactPhone?.controls?.number
                ?.errors?.required
            "
            >Phone number cannot be left blank</span
          >
          <span
            *ngIf="
              ClientInvestigationNote?.controls?.ContactPhone?.controls?.number
                ?.errors?.patternError ||
              ClientInvestigationNote?.controls?.ContactPhone?.controls?.number
                ?.errors?.pattern
            "
            >Invalid phone number</span
          >
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-formfield
          [formGroup]="ClientInvestigationNote.controls.ContactPhone"
        >
          <kendo-label [for]="extension" text="Extension"></kendo-label>
          <kendo-textbox
            #extension
            [disabled]="isCurrentContact"
            formControlName="extension"
            (valueChange)="setContactPhoneValidator('extension', $event)"
            placeholder="Enter Extension"
            fillMode="outline"
            [clearButton]="true"
            appSkipClearButtonTabindex
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.ContactPhone?.controls
                  ?.extension?.errors?.required
              "
              >Extension cannot be left blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label
          class="asterisk"
          [for]="jobTitle"
          text="Job Title"
        ></kendo-label>
        <kendo-textbox
          #jobTitle
          [disabled]="isCurrentContact"
          formControlName="jobTitle"
          placeholder="Enter job title"
          [maxlength]="255"
          fillMode="outline"
          [clearButton]="false"
        ></kendo-textbox>
        <kendo-formerror>
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span
            *ngIf="
              ClientInvestigationNote?.controls?.jobTitle?.errors?.required
            "
            >Job Title cannot be left blank</span
          >
          <span
            *ngIf="
              ClientInvestigationNote?.controls?.jobTitle?.errors?.maxlength
            "
            >Job Title is allowed up to 255 characters</span
          >
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label
          class="asterisk"
          [for]="companyList"
          text="Company Type"
        ></kendo-label>
        <kendo-dropdownlist
          #companyList
          [data]="companyTypes"
          textField="name"
          valueField="id"
          [disabled]="isCurrentContact"
          fillMode="outline"
          [valuePrimitive]="true"
          (valueChange)="companyTypeChange()"
          [defaultItem]="{ id: '', name: 'Select company type' }"
          [popupSettings]="{ appendTo: 'component', animate: false }"
          formControlName="companyTypeId"
        >
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="!dataItem?.id" class="placeholder">
              {{ dataItem.name }}
            </span>
            <span *ngIf="dataItem?.id" class="not-placeholder">
              {{ dataItem.name }}
            </span>
          </ng-template>
        </kendo-dropdownlist>
        <kendo-formerror>
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span
            *ngIf="
              ClientInvestigationNote?.controls?.companyTypeId?.errors?.required
            "
            >Company type cannot be left blank</span
          >
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <ng-container
    *ngIf="showCompanyInformation"
    [formGroup]="ClientInvestigationNote?.controls?.company"
  >
    <hr />
    <h3 class="page-sub-label">Enter Company Information</h3>
    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="companyName"
            text="Company Name"
          ></kendo-label>
          <kendo-textbox
            #companyName
            formControlName="companyName"
            [disabled]="isCurrentContact"
            placeholder="Enter company Name"
            fillMode="outline"
            [clearButton]="true"
            appSkipClearButtonTabindex
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.company?.controls
                  ?.companyName?.errors?.required
              "
              >Company Name cannot be left blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="companyAddress"
            [text]="'Address'"
          ></kendo-label>
          <kendo-textbox
            #companyAddress
            formControlName="companyAddress"
            [disabled]="isCurrentContact"
            placeholder="Enter address"
            fillMode="outline"
            [clearButton]="true"
            appSkipClearButtonTabindex
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.company?.controls
                  ?.companyAddress?.errors?.required
              "
              >Address cannot be left blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-gridlayout
      [rows]="['auto']"
      [gap]="{ rows: 5, cols: 24 }"
      [cols]="['47%', '47%']"
    >
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="companyCity"
            [text]="'City'"
          ></kendo-label>
          <kendo-textbox
            #companyCity
            formControlName="companyCity"
            [disabled]="isCurrentContact"
            placeholder="Enter city"
            fillMode="outline"
            [clearButton]="true"
            appSkipClearButtonTabindex
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.company?.controls
                  ?.companyCity?.errors?.required
              "
              >City cannot be left blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="companyState"
            [text]="'State'"
          ></kendo-label>
          <kendo-dropdownlist
            #companyState
            [data]="groupedDataStates"
            textField="text"
            valueField="value"
            [disabled]="isCurrentContact"
            formControlName="companyState"
            [valuePrimitive]="true"
            fillMode="outline"
            [filterable]="false"
            (filterChange)="handleFilter($event)"
            [defaultItem]="{ value: '', text: 'Select state', type: '' }"
            [popupSettings]="{ appendTo: 'component', animate: false }"
          >
            <ng-template kendoDropDownListGroupTemplate let-groupName>
              <strong>{{ groupName }}</strong>
            </ng-template>
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="!dataItem?.value" class="placeholder">
                {{ dataItem.text }}
              </span>
              <span *ngIf="dataItem?.value" class="not-placeholder">
                {{ dataItem.text }}
              </span>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.company?.controls
                  ?.companyState?.errors?.required
              "
              >State cannot be left blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="companyZip"
            [text]="'Zip Code'"
          ></kendo-label>
          <kendo-textbox
            #companyZip
            [disabled]="isCurrentContact"
            formControlName="companyZip"
            placeholder="Enter zip code"
            fillMode="outline"
            [clearButton]="true"
            appSkipClearButtonTabindex
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.company?.controls?.companyZip
                  ?.errors?.required
              "
            >
              Zip code cannot be left blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item
        *ngIf="
          (isCurrentContact &&
            ClientInvestigationNote?.controls?.company?.controls?.companyPhone
              ?.value) ||
          this.isCurrentContact === false
        "
      >
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="companyPhone"
            [text]="'Phone Number'"
          ></kendo-label>
          <kendo-maskedtextbox
            #companyPhone
            [disabled]="isCurrentContact"
            formControlName="companyPhone"
            placeholder="Enter phone number"
            fillMode="outline"
            mask="(000) 000-0000"
          ></kendo-maskedtextbox>
          <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.company?.controls
                  ?.companyPhone?.errors?.required
              "
            >
              Phone number cannot be left blank</span
            >
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.company?.controls
                  ?.companyPhone?.errors?.patternError
              "
              >Invalid phone number</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </ng-container>
  <ng-container *ngIf="showInsuranceCarrier">
    <hr />
    <h3 class="page-sub-label">Enter Insurance Carrier Information</h3>
    <kendo-gridlayout
      [rows]="['auto']"
      [gap]="{ rows: 5, cols: 24 }"
      [cols]="['67%', '27%']"
    >
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="insuranceCarrierName"
            text="Insurance Carrier"
          ></kendo-label>
          <kendo-textbox
            #insuranceCarrierName
            formControlName="insuranceCarrierName"
            placeholder="Enter Name"
            fillMode="outline"
            [disabled]="
              isCurrentContact &&
              ClientInvestigationNote.get('companyTypeText')?.value ===
                'Insurance Company'
            "
            [clearButton]="true"
            appSkipClearButtonTabindex
            clearButtonIcon="k-icon k-i-close-circle"
            (valueChange)="clearInsuredCarrier()"
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.insuranceCarrierName?.errors
                  ?.required
              "
              >Insurance Carrier cannot be left blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-label>&nbsp;</kendo-label>
        <button
          kendoButton
          size="large"
          themeColor="base"
          [disabled]="
            isCurrentContact &&
            ClientInvestigationNote.get('companyTypeText')?.value ===
              'Insurance Company'
          "
          fillMode="solid"
          (click)="findCarrier()"
        >
          Find a Carrier
        </button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-gridlayout
      [rows]="['auto']"
      [cols]="['100%']"
      *ngIf="!isHiddenCarrier && isSearching"
    >
      <kendo-gridlayout-item *ngIf="carrierList?.length > 0">
        <kendo-listview
          [data]="carrierList"
          [height]="384"
          class="carrier-list"
          containerClass="k-d-flex k-flex-col k-flex-nowrap"
        >
          <ng-template kendoListViewHeaderTemplate>
            <div class="header">
              We have found {{ carrierList?.length }} matches
            </div>
          </ng-template>
          <ng-template
            kendoListViewItemTemplate
            let-dataItem="dataItem"
            let-isFirst="isFirst"
          >
            <div class="content-details">
              <div class="content" (click)="selectedCarrier(dataItem)">
                {{ dataItem?.name }}, {{ dataItem?.branchName }}
              </div>
              <div class="sub-content" (click)="selectedCarrier(dataItem)">
                {{ dataItem?.address1 ? dataItem?.address1 + "," : "" }}
                {{ dataItem?.address2 ? dataItem?.address2 + "," : "" }}
                {{ dataItem?.city ? dataItem?.city + "," : "" }}
                {{
                  dataItem?.postal && dataItem?.state
                    ? dataItem?.state + " " + dataItem?.postal + ","
                    : ""
                }}
                {{ dataItem?.country ? dataItem?.country : "" }}
              </div>
            </div>
          </ng-template>
          <ng-template kendoListViewFooterTemplate>
            <div class="footer">
              <button
                kendoButton
                class="mr-24"
                icon="plus-circle"
                themeColor="primary"
                fillMode="link"
                (click)="selectedCarrier()"
              >
                Carrier Not Listed? Add New Carrier
              </button>
            </div>
          </ng-template>
        </kendo-listview>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item *ngIf="carrierList?.length === 0">
        <div class="no-carrier-record">
          <h5>We found 0 matches</h5>
          <p>
            We could not find any records for this insurance carrier. Click on
            the add new button below to add a new carrier.
          </p>
          <button
            kendoButton
            themeColor="light"
            fillMode="solid"
            [disabled]="
              isCurrentContact &&
              ClientInvestigationNote.get('companyTypeText')?.value ===
                'Insurance Company'
            "
            icon="plus-circle"
            (click)="selectedCarrier()"
          >
            Add New Carrier
          </button>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="address"
            [text]="newCarrier ? 'Carrier Address' : 'Address'"
          ></kendo-label>
          <kendo-textbox
            #address
            formControlName="address"
            placeholder="Enter address"
            [disabled]="
              isCurrentContact &&
              ClientInvestigationNote.get('companyTypeText')?.value ===
                'Insurance Company'
            "
            fillMode="outline"
            [clearButton]="true"
            appSkipClearButtonTabindex
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.address?.errors?.required
              "
              >{{ newCarrier ? "Carrier address" : "Address" }} cannot be left
              blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-gridlayout
      [rows]="['auto']"
      [gap]="{ rows: 5, cols: 24 }"
      [cols]="['47%', '47%']"
    >
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="city"
            [text]="newCarrier ? 'Carrier City' : 'City'"
          ></kendo-label>
          <kendo-textbox
            #city
            formControlName="city"
            placeholder="Enter city"
            [disabled]="
              isCurrentContact &&
              ClientInvestigationNote.get('companyTypeText')?.value ===
                'Insurance Company'
            "
            fillMode="outline"
            [clearButton]="true"
            appSkipClearButtonTabindex
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="ClientInvestigationNote?.controls?.city?.errors?.required"
              >{{ newCarrier ? "Carrier city" : "City" }} cannot be left
              blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="state"
            [text]="newCarrier ? 'Carrier State' : 'State'"
          ></kendo-label>
          <kendo-dropdownlist
            #state
            [data]="groupedDataStates"
            textField="text"
            valueField="value"
            formControlName="state"
            [disabled]="
              isCurrentContact &&
              ClientInvestigationNote.get('companyTypeText')?.value ===
                'Insurance Company'
            "
            [valuePrimitive]="true"
            fillMode="outline"
            [filterable]="false"
            (filterChange)="handleFilter($event)"
            [defaultItem]="{ value: '', text: 'Select state', type: '' }"
            [popupSettings]="{ appendTo: 'component', animate: false }"
          >
            <ng-template kendoDropDownListGroupTemplate let-groupName>
              <strong>{{ groupName }}</strong>
            </ng-template>
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="!dataItem?.value" class="placeholder">
                {{ dataItem.text }}
              </span>
              <span *ngIf="dataItem?.value" class="not-placeholder">
                {{ dataItem.text }}
              </span>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="ClientInvestigationNote?.controls?.state?.errors?.required"
              >{{ newCarrier ? "Carrier state" : "State" }} cannot be left
              blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="zip"
            [text]="newCarrier ? 'Carrier Zip Code' : 'Zip Code'"
          ></kendo-label>
          <kendo-textbox
            #zip
            formControlName="zip"
            placeholder="Enter zip code"
            [disabled]="
              isCurrentContact &&
              ClientInvestigationNote.get('companyTypeText')?.value ===
                'Insurance Company'
            "
            fillMode="outline"
            [clearButton]="true"
            appSkipClearButtonTabindex
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="ClientInvestigationNote?.controls?.zip?.errors?.required"
              >{{ newCarrier ? "Carrier Zip code" : "Zip code" }} cannot be left
              blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item
        *ngIf="
          ClientInvestigationNote?.controls?.phone?.value ||
          (newCarrier && ClientInvestigationNote?.controls?.phone?.value === '')
        "
      >
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="phone"
            [text]="newCarrier ? 'Carrier Phone Number' : 'Phone Number'"
          ></kendo-label>
          <kendo-maskedtextbox
            #phone
            formControlName="phone"
            placeholder="Enter phone number"
            fillMode="outline"
            [disabled]="
              isCurrentContact &&
              ClientInvestigationNote.get('companyTypeText')?.value ===
                'Insurance Company'
            "
            mask="(000) 000-0000"
          ></kendo-maskedtextbox>
          <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="ClientInvestigationNote?.controls?.phone?.errors?.required"
              >{{ newCarrier ? "Carrier phone" : "Phone" }} number cannot be
              left blank</span
            >
            <span
              *ngIf="
                ClientInvestigationNote?.controls?.phone?.errors?.patternError
              "
              >Invalid phone number</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item
        *ngIf="
          ClientInvestigationNote?.controls?.extension?.value ||
          (newCarrier &&
            ClientInvestigationNote?.controls?.extension?.value === '')
        "
      >
        <kendo-formfield>
          <kendo-label [for]="carrierextension" text="Extension"></kendo-label>
          <kendo-textbox
            #carrierextension
            formControlName="extension"
            placeholder="Enter Extension"
            fillMode="outline"
            [disabled]="
              isCurrentContact &&
              ClientInvestigationNote.get('companyTypeText')?.value ===
                'Insurance Company'
            "
            [clearButton]="false"
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span
              *ngIf="
                ClientInvestigationNote.controls?.extension?.errors?.required
              "
              >Extension cannot be left blank</span
            >
            <span
              *ngIf="
                ClientInvestigationNote.controls?.extension?.errors?.numberOnly
              "
              >Please enter only number</span
            >
            <span
              *ngIf="
                !ClientInvestigationNote.controls?.extension?.errors
                  ?.required &&
                !ClientInvestigationNote.controls?.extension?.errors
                  ?.numberOnly &&
                ClientInvestigationNote.controls?.extension?.errors?.maxlength
              "
              >Extension should be maximum
              {{
                ClientInvestigationNote.controls?.extension?.errors?.maxlength
                  ?.requiredLength
              }}
              number.</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </ng-container>
  <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
    <kendo-formfield>
      <div class="k-checkbox-wrap">
        <input
          #contactIsAdjuster
          type="checkbox"
          kendoCheckBox
          formControlName="contactIsAdjuster"
        />
        <kendo-label
          [for]="contactIsAdjuster"
          class="k-checkbox-label font-weight-600 checkbox-margin"
          style="margin-bottom: 0"
          text="I am the adjuster"
        ></kendo-label>
      </div>
    </kendo-formfield>
  </kendo-gridlayout>
</form>
