import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { IApiEmail, IApiEmailType } from 'src/app/shared/modules/graphql/types/types';
import { removeTypename, unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { EmailTypeService } from 'src/app/shared/services/emails';

export interface IEmailInputConfig {
  hidePrimary: boolean;
  hideType: boolean;
  static?: boolean;
}

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailComponent),
      multi: true
    }
  ]
})
export class EmailComponent implements OnInit {

  @Input() public config: IEmailInputConfig = {
    hidePrimary: false,
    hideType: false
  };

  @Input() public name;
  @Output() remove = new EventEmitter<boolean>();
  private _value: IApiEmail = {
    id: null,
    isPrimary: false,
    address: null,
    Type: {
      id: "",
      name: ""
    },
    TypeId: null
  };

  public set value(val: IApiEmail) {
    if (val) {
      this._value = val;
      this.onChange(val);
    }
  }

  public get value() {
    return this._value;
  }

  // fetch email types or use enum
  public emailTypes: IApiEmailType[] = [];

  constructor(
    private emailTypeService: EmailTypeService
  ) { }


  onChange: any = (): void => { };
  onTouch: any = (): void => { };

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
      this.value.TypeId = this.value.Type ? this.value.Type.id : null;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public selectCompare(option, selection) {
    return option.id === selection.id;
  }

  ngOnInit() {
    this.emailTypeService.get().pipe(
      unwrapConnection(),
      removeTypename()
    ).subscribe((types) => {
      this.emailTypes = types;
      if (!this.value?.Type?.id) this.value.Type = types[0];
    });
  }

}
