  <h3>Upload Photos</h3>
  <mat-dialog-content>
      <div class="d-flex">
        <p>Which folder would you like to upload to?</p>
      </div>
      <div class="d-flex flex-column">
        <mat-radio-group class="d-flex flex-column" [(ngModel)]="selectedFolder">
          <mat-radio-button *ngFor="let folder of data" [value]="folder">
            {{ folder.name }}
          </mat-radio-button>
          <mat-radio-button [value]="{id: null, name: null}">
            Other
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field *ngIf="selectedFolder && !selectedFolder?.id">
        <mat-label>Folder Name</mat-label>
        <input matInput name="newFolderName" type="text" [(ngModel)]="newFolderName" required>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="accent" [mat-dialog-close]>Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!selectedFolder || (!selectedFolder?.id && newFolderName.length < 3)" (click)="selectFolder()" >Upload Photos</button>
  </mat-dialog-actions>