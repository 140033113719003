<!-- Permissions -->
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseCreate" #expenseUnlinkedExpenseCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesCreate" #expenseInvestigationExpensesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageCreate" #expenseInvestigationMileageCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageCreate" #expenseUnlinkedMileageCreate="appEnforcePermission"></div>
<!-- Permissions -->
<section class="content-wrapper pl-0 pr-0 pb-0">
  <app-bookkeeping-expense-report-filter [selectedTab]="selectedTab" [isMarkSelectedAsPaid]="isMarkSelectedAsPaid" [isUsers]="users?.length" (userChange)="userChange($event)" (dateChange)="dateChange($event)" (markSelectedPaid)="markSelectedPaid()" (markAllAsPaid)="markAllAsPaid()" (downloadXlsx)="downloadXlsx()" (dateRangeTypeChange)="dateRangeTypeChange($event)"></app-bookkeeping-expense-report-filter>
  <section *ngIf="users?.length else noRecords" class="tab-partition">
      <kendo-card width="100%" class="mb-16">
          <kendo-card-header style="padding: 5px 16px;">
              <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
                  <kendo-gridlayout-item class="select-All mb-0">
                      <kendo-formfield>
                          <div class="k-checkbox-wrap select-All">
                              <input
                                  #selectAllChecked
                                  type="checkbox"
                                  kendoCheckBox
                                  (change)="pageSelectAllChange($event)"
                                  [indeterminate]="selectAllInter"
                                  [(ngModel)]="selectAll"
                                  [ngModelOptions]="{standalone: true}"
                              />
                              <kendo-label
                              [for]="selectAllChecked"
                              class="k-checkbox-label font-weight-600 checkbox-margin ml-0"
                              style="margin-bottom: 0"
                              text="Select All"
                              ></kendo-label>
                          </div>
                      </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item class="report-total mb-0">
                      <div>
                          <span class="total-expenses">Total Expenses: ${{reportTotal | number: '1.2-2'}}</span> <br/>
                          <span class="total-selected">Total Selected: ${{selectedTotal | number: '1.2-2'}}</span>
                      </div>
                  </kendo-gridlayout-item>
              </kendo-gridlayout>
          </kendo-card-header>
      </kendo-card>

      <kendo-card width="100%" *ngFor="let user of users;" class="mb-16">
          <kendo-card-header>
              <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['98%', '1%']">
                  <kendo-gridlayout-item class="expence-left-section mb-0">
                  <div>
                      <div class="user-details">
                          <input type="checkbox"
                              kendoCheckBox
                              [(ngModel)]="userSelection[user?.id]"
                              [indeterminate]="userExpenseSelection[user.id]?.length && !userSelection[user?.id]"
                              (change)="userSelectionChange($event, user.id)"
                              />
                          <span class="user-name">{{user?.lastName}}, {{user?.firstName}}</span>
                      </div>
                      <div class="user-sub-details">
                          <span class="mr-16">
                              <span *ngFor="let position of user?.Positions | slice: 0 : 1" [class]="'position'">{{position.title}},</span> <span>Part Time</span>
                              <span
                                  kendoTooltip
                                  position="bottom"
                                  *ngIf="user?.Positions?.length > 1"
                                  [tooltipTemplate]="assignTooltip"
                                  filter="a[href]"
                                  tooltipClass="tooltipwidth200"
                                  style="margin-left: 4px">
                                  <a href="javascript:void(0)" class="more-assigne primary-color"
                                      >+{{ user?.Positions?.length - 1 }}</a
                                  >
                                  <ng-template #assignTooltip>
                                      <span
                                      class="dflex"
                                      *ngFor="let position of user?.Positions| slice : 1 : user?.Positions?.length">
                                          {{ position?.title }}
                                      </span>
                                  </ng-template>
                              </span>
                          </span>
                          <span class="mr-16">|</span>
                          <span class="mr-16"><i *ngIf="user?.UserDetail?.hasNefcoCreditCard else showXMark" class="fa-solid fa-circle-check"></i>NEFCO Card</span>
                          <span class="mr-16"><i *ngIf="user?.UserDetail?.hasNefcoVehicle else showXMark" class="fa-solid fa-circle-check"></i>NEFCO Vehicle</span>
                          <ng-template #showXMark>
                              <i class="fa-solid fa-circle-xmark"></i>
                          </ng-template>
                      </div>
                  </div>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item style="display: flex; justify-content: flex-end;" class="mb-0" *ngIf="addButtonPermisison()">
                      <kendo-dropdownbutton
                          [data]="selectedTab === 1 ? [{text: 'Add Expense'}] : addEntryMenu"
                          themeColor="light"
                          fillMode="solid"
                          buttonClass="grid-action-items"
                          iconClass="fa-regular fa-plus"
                          (itemClick)="itemSelected($event, user)"
                          [popupSettings]="{
                              appendTo: 'component',
                              animate: false,
                              width: 250,
                              popupClass: 'grid-action-items-menu mt-12'
                          }"
                          >
                          Add Entry
                      </kendo-dropdownbutton>
                  </kendo-gridlayout-item>
              </kendo-gridlayout>

          </kendo-card-header>
          <kendo-card-body class="pt-0 pb-0 pl-16 pr-16">
              <app-expense-report-details [config]="adminConfig" [userId]="user.id" (expenseSelectionChange)="updateExpenseUserSelection($event, user.id)"
              [(reloadUserId)]="reloadUserId" [expenseFilters]="expenseFilters" [expenseItems]="expenseItems"
              [documentTypes]="documentTypes" [userSelect]="userSelection[user?.id]" (selection)="selection($event)"  [(selectedExpenseTotal)]="selectedExpenseTotal" (reloadTotalChange)="reloadTotalChange($event)">
              </app-expense-report-details>
          </kendo-card-body>
      </kendo-card>

      <kendo-datapager
          [pageSize]="pageOptions?.limit"
          [skip]="pageOptions?.offset"
          [total]="(dataSource?.pageInfo$ | async)?.totalCount"
          (pageChange)="pageChange($event)"
          class="custom-paginate"
      >
          <ng-template kendoDataPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
              <div class="k-pager-numbers-wrap">
                  <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
                  <kendo-datapager-numeric-buttons [buttonCount]="5"></kendo-datapager-numeric-buttons>
                  <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
              </div>
              <kendo-datapager-info></kendo-datapager-info>
              <kendo-datapager-page-sizes [pageSizes]="[5, 10]"></kendo-datapager-page-sizes>
          </ng-template>
      </kendo-datapager>
  </section>
  <ng-template #noRecords>
    <section class="tab-partition">
      <div class="text-center" *ngIf="((dataSource?.loading$ | async) === false) && users?.length <= 0">No records available.</div>
    </section>
  </ng-template>

</section>