import { gql } from 'apollo-angular';

export const nodeFragment = `
    id
    Type {
        id
        name
        createdAt
        updatedAt
    }
    isPrimary
    address
    emailableType
    emailableId
    createdAt
    updatedAt
`;

export const addEmailMutation = gql`mutation Email($input: AddEmailInput!) {
    addEmail(input: $input) { ${nodeFragment} }
  }`;

export const removeEmailMutation = gql`mutation Email($input: RemoveEmailInput) {
    removeEmail(input: $input) { ${nodeFragment} }
}`;