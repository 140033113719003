import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './loader.component';
import { LoaderService } from './loader.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@NgModule({
  declarations: [
    LoaderComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    IndicatorsModule,
  ],
  exports: [
    LoaderComponent
  ]
})
export class LoaderModule {
  static forRoot(): ModuleWithProviders<LoaderModule> {
    return {
      ngModule: LoaderModule,
      providers: [
        LoaderService
      ]
    };
  }
}