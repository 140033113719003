import { DataSource } from "@angular/cdk/collections";
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { IApiQuarterlyUtilizationReport, IApiQuarterlyUtilizationReportConnection, IApiQuarterlyUtilizationReportFilter } from "src/app/shared/modules/graphql/types/types";
import { QuarterlyUtilizationReportService } from "./quaterly-utilization-report.service";

export class QuarterlyUtilizationReportDataSource extends NefcoPagedDataSource<IApiQuarterlyUtilizationReport, QuarterlyUtilizationReportService, IApiQuarterlyUtilizationReportConnection> implements DataSource<IApiQuarterlyUtilizationReport> {
  @ApiConnection()
  public load(filters: IApiQuarterlyUtilizationReportFilter[] = []): Promise<IApiQuarterlyUtilizationReportConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
