import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { GqlInvestigationMetricsDocusketchRequestedService } from 'src/app/shared/modules/graphql/services';
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsDocusketchRequestedConnection } from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class InvestigationMetricsDocusketchRequestedService {
  constructor(
    private gqlInvestigationMetricsDocusketchRequestedService: GqlInvestigationMetricsDocusketchRequestedService
  ) { }

  public get(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationMetricsDocusketchRequestedConnection> {
    return this.gqlInvestigationMetricsDocusketchRequestedService.get(filters, options || new ListPage());
  }

}
