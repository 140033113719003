import { GqlInvoiceService } from '../../modules/graphql/services/invoice';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces/base.interfaces';
import { ListPage } from "../../helpers/list-page.class";
import { IApiAddInvoiceInput, IApiInvoice, IApiInvoiceConnection, IApiInvoiceFilter, IApiUpdateInvoiceInput } from "../../modules/graphql/types/types";
import { NefcoDateHelper } from '../../helpers/nefco-date.class';

@Injectable()
export class InvoiceService {

  constructor(
    private gqlInvoiceService: GqlInvoiceService
  ) { }

  public get(filters?: IApiInvoiceFilter[], options?: IGqlBaseOptions): Observable<IApiInvoiceConnection> {
    return this.gqlInvoiceService.getInvoices(filters, options || new ListPage());
  }

  public add(input: IApiAddInvoiceInput): Observable<IApiInvoice> {
    return this.gqlInvoiceService.addInvoice({ 
      ...input, 
      InvoiceLines: input?.InvoiceLines 
        ? input.InvoiceLines.map(obj => { 
          return {...obj, createdAt: NefcoDateHelper.toUtc(obj.createdAt, 12) };
        })
        : null 
      });
  }

  public update(input: IApiUpdateInvoiceInput): Observable<IApiInvoice> {
    return this.gqlInvoiceService.updateInvoice({
      ...input, 
      InvoiceLines: input?.InvoiceLines 
        ? input.InvoiceLines.map(obj => {
          return {...obj, createdAt: NefcoDateHelper.toUtc(obj.createdAt, 12) };
        })
        : null 
    });
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlInvoiceService.removeInvoice(id);
  }

  public resubmit(id: string): Observable<boolean> {
    return this.gqlInvoiceService.resubmitInvoice(id);
  }
  
}
