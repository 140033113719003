import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlInvestigationDetailService } from 'src/app/shared/modules/graphql/services';
import { IApiInvestigationDetailConnection } from '../../../modules/graphql/types/types';

import {
  IApiAddInvestigationDetailInput,
  IApiInvestigationDetailFilter,
  IApiUpdateInvestigationDetailInput,
  IApiRemoveInvestigationDetailInput
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";


@Injectable()
export class InvestigationDetailService {

  constructor(
    private gqlInvestigationDetailService: GqlInvestigationDetailService
  ) { }

  public get(filters: IApiInvestigationDetailFilter[] = [], options?: IGqlBaseOptions): Observable<IApiInvestigationDetailConnection> {
    return this.gqlInvestigationDetailService.getInvestigationDetail(filters, options || new ListPage());
  }

  public add(input: IApiAddInvestigationDetailInput) {
    return this.gqlInvestigationDetailService.addInvestigationDetail(input);
  }

  public remove(input: IApiRemoveInvestigationDetailInput) {
    return this.gqlInvestigationDetailService.removeInvestigationDetail(input);
  }

  public update(input: IApiUpdateInvestigationDetailInput) {
    return this.gqlInvestigationDetailService.updateInvestigationDetail(input);
  }

}
