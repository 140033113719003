import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';
import {
  investigationMetricBasicCharts,
  investigationMetricInvestigator,
  investigationMetricState,
  investigationMetricBillToCompany,
  investigationMetricClientCompany,
  investigationMetricTechReviewer,
  investigationMetricContact,
  investigationMetricRisktype,
  investigationMetricDetermination,
  investigationMetricDocusketchRequested
} from "src/app/shared/helpers/auth-config/reports.config";

@UntilDestroy()
@Component({
  selector: 'app-investigation-metrics',
  templateUrl: './investigation-metrics.component.html',
  styleUrls: ['./investigation-metrics.component.scss']
})
export class InvestigationMetricsComponent implements AfterViewInit {
  public authConfig = {
    investigationMetricBasicCharts,
    investigationMetricInvestigator,
    investigationMetricState,
    investigationMetricBillToCompany,
    investigationMetricClientCompany,
    investigationMetricTechReviewer,
    investigationMetricContact,
    investigationMetricRisktype,
    investigationMetricDetermination,
    investigationMetricDocusketchRequested
  };
  @ViewChild("tabstrip") public tabstrip: TabStripComponent;

  selectedTab: number = 0;

  constructor() {}

  onTabChanged(event: SelectEvent) {
    this.selectedTab = parseInt(event?.title);
  }

  public ngAfterViewInit() {
    Promise.resolve(null).then(() => this.tabstrip.selectTab(0));
  }
}
