<form appDebouncedChange>
  <kendo-formfield>
    <kendo-label *ngIf="config?.labelText" [text]="config.labelText"></kendo-label>
    <kendo-dropdownlist [data]="roles" name="role" #role="ngModel"
    [(ngModel)]="roleId" textField="title" valueField="id" fillMode="outline" [valuePrimitive]="true"
      [defaultItem]="{id: null, title: 'Add Other Staff'}" [popupSettings]="{
      appendTo: 'component',
      animate: false,
      width: 250
    }">
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        <span *ngIf="!dataItem?.id" class="placeholder">
          {{ dataItem?.title | quiet}}
        </span>
        <span *ngIf="dataItem?.id" class="not-placeholder">
          {{ dataItem?.title | quiet}}
        </span>
      </ng-template>
    </kendo-dropdownlist>
  </kendo-formfield>
</form>
