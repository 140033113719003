<kendo-card class="intake-card" width="100%">
  <h1>Insured Information</h1>
  <p>Detail the type of insurance and type of ownership of the loss that covers this assignment.</p>
  <form [formGroup]="insuredInformation" autocomplete="off">

    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item>
          <kendo-formfield>
              <kendo-label class="font-weight-600 mb-15 asterisk" text="Select Type"></kendo-label>
              <div class="kendo-radio">
                  <input type="radio" id="Individual" kendoRadioButton formControlName="insuredType" [value]="true"
                      (change)="changedInsuredType(true)" />
                  <label class="k-radio-label mr-24" for="Individual">Individual</label>
                  <input type="radio" id="Business" kendoRadioButton formControlName="insuredType" [value]="false"
                      (change)="changedInsuredType(false)" />
                  <label class="k-radio-label" for="Business">Business</label>
              </div>
              <kendo-formerror>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="insuredInformation?.controls?.insuredType?.errors?.required">Select Type cannot be left blank</span>
              </kendo-formerror>
          </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <ng-container *ngIf="insuredInformation?.controls?.insuredType?.value === true">
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
            <kendo-formfield>
                <kendo-label class="asterisk" [for]="insuredName" text="Insured Name"></kendo-label>
                <kendo-textbox #insuredName formControlName="insuredName"
                    placeholder="Enter name" fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                <kendo-formerror>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="insuredInformation?.controls?.insuredName?.errors?.required">Insured Name cannot be left blank</span>
                </kendo-formerror>
            </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <ng-container *ngIf="insuredInformation?.controls?.insuredType?.value === false">
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
            <kendo-formfield>
                <kendo-label class="asterisk" [for]="companyName" text="Company Name"></kendo-label>
                <kendo-textbox #companyName formControlName="companyName"
                    placeholder="Enter name" fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                <kendo-formerror>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="insuredInformation?.controls?.companyName?.errors?.required">Company Name cannot be left blank</span>
                </kendo-formerror>
            </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
            <kendo-formfield>
                <kendo-label class="asterisk" [for]="contactName" text="Contact Name"></kendo-label>
                <kendo-textbox #contactName formControlName="contactName"
                    placeholder="Enter name" fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                <kendo-formerror>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="insuredInformation?.controls?.contactName?.errors?.required">Contact Name cannot be left blank</span>
                </kendo-formerror>
            </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <hr />

    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item>
          <kendo-formfield>
              <kendo-label class="font-weight-600 mb-15 asterisk" text="Is the named insured the building/vehicle/heavy equipment owner, tenant, or other?"></kendo-label>
              <div class="kendo-radio">
                  <input type="radio" id="Owner" kendoRadioButton formControlName="nickName" [value]="'Owner'"
                      (change)="changedInsuredIs('Owner')" />
                  <label class="k-radio-label mr-24" for="Owner">Owner</label>
                  <input type="radio" id="Tenant" kendoRadioButton formControlName="nickName" [value]="'Tenant'"
                      (change)="changedInsuredIs('Tenant')" />
                  <label class="k-radio-label mr-24" for="Tenant">Tenant</label>
                  <input type="radio" id="Other" kendoRadioButton formControlName="nickName" [value]="'Other'"
                      (change)="changedInsuredIs('Other')" />
                  <label class="k-radio-label" for="Other">Other</label>
              </div>
              <kendo-formerror>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="insuredInformation?.controls?.nickName?.errors?.required">Is the name insured cannot be left blank</span>
              </kendo-formerror>
          </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <ng-container *ngIf="insuredInformation?.controls?.nickName?.value === 'Other'">
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
            <kendo-formfield>
                <kendo-label class="asterisk" [for]="other" text="Other"></kendo-label>
                <kendo-textbox #other formControlName="other"
                    placeholder="Enter name" fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                <kendo-formerror>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="insuredInformation?.controls?.other?.errors?.required">Other cannot be left blank</span>
                </kendo-formerror>
            </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <ng-container *ngFor="let control of  additionalContactsFieldAsFormArray.controls ;let i = index;"
      formArrayName='additionalContacts'>
      <kendo-gridlayout class="mb-0" [rows]="['auto']" [gap]="{rows: 0, cols: 24}" [cols]="['47%', '38%', '4%']">
        <kendo-gridlayout-item>
          <kendo-formfield [formGroupName]="i">
            <kendo-label class="asterisk" [for]="phoneNumber" text="Phone Number"></kendo-label>
            <kendo-maskedtextbox #phoneNumber formControlName="phoneNumber" fillMode="outline"
              placeholder="Enter phone number" mask="(000) 000-0000"></kendo-maskedtextbox>
            <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="control?.controls?.phoneNumber?.errors?.required">Phone number cannot be left blank</span>
              <span *ngIf="control?.controls?.phoneNumber?.errors?.patternError">Invalid phone number</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
    
        <kendo-gridlayout-item>
          <kendo-formfield [formGroupName]="i">
            <kendo-label class="asterisk" [for]="typeId" text="Type"></kendo-label>
            <kendo-dropdownlist #typeId [data]="phoneTypes" textField="name" valueField="id" fillMode="outline"
              [valuePrimitive]="true" [defaultItem]="{id: '', name: 'Select Type' }"
              [popupSettings]="{appendTo: 'component', animate: false}" formControlName="typeId">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem.name }} </span>
                <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="control?.controls?.typeId?.errors?.required">Type cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
    
        <kendo-gridlayout-item class="remove">
          <span *ngIf="i > 0" class="k-icon k-i-minus-outline" (click)="removeaddAdditionalContact(i)"></span>
        </kendo-gridlayout-item>
    
        <kendo-gridlayout-item>
          <kendo-formfield [formGroupName]="i">
            <kendo-label [for]="extension" text="Extension"></kendo-label>
            <kendo-textbox #extension formControlName="extension" placeholder="Enter Extension" fillMode="outline"
              [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="control?.controls?.extension?.errors?.required">Extension cannot be left blank</span>
              <span *ngIf="control?.controls?.extension?.errors?.numberOnly">Please enter only number</span>
              <span *ngIf="!control?.controls?.extension?.errors?.required 
              && !control?.controls?.extension?.errors?.numberOnly 
              && control?.controls?.extension?.errors?.maxlength">Extension should be maximum
                {{control?.controls?.extension?.errors?.maxlength?.requiredLength}} number.</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <kendo-gridlayout>
      <kendo-gridlayout-item>
        <button kendoButton class="mr-24" icon="plus-circle" themeColor="primary" fillMode="link"
          (click)="addAdditionalContactToList()">Add Additional Contact Number</button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <hr />

    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item>
          <kendo-formfield>
              <kendo-label class="font-weight-600 mb-15 asterisk" text="Is the insured address the same as the loss address?"></kendo-label>
              <div class="kendo-radio">
                  <input type="radio" id="Yes" kendoRadioButton formControlName="insuredSameAsLossAddress" [value]="true"
                      (change)="changedinsuredSameAsLossAddress(true)" />
                  <label class="k-radio-label mr-24" for="Yes">Yes</label>
                  <input type="radio" id="No" kendoRadioButton formControlName="insuredSameAsLossAddress" [value]="false"
                      (change)="changedinsuredSameAsLossAddress(false)" />
                  <label class="k-radio-label" for="No">No</label>
              </div>
              <kendo-formerror>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="insuredInformation?.controls?.insuredSameAsLossAddress?.errors?.required">Select Same as loss cannot be left blank</span>
              </kendo-formerror>
          </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <ng-container *ngIf="!insuredInformation?.controls?.insuredSameAsLossAddress?.value">
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
          <kendo-gridlayout-item>
              <kendo-formfield>
                  <kendo-label class="asterisk" [for]="insuredAddress" text="Address"></kendo-label>
                  <kendo-textbox #insuredAddress formControlName="insuredAddress" placeholder="Enter address"
                      fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                  <kendo-formerror>
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="insuredInformation?.controls?.insuredAddress?.errors?.required">Address cannot be left blank</span>
                  </kendo-formerror>
              </kendo-formfield>
          </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 26}" [cols]="['47%', '47%']">
          <kendo-gridlayout-item>
              <kendo-formfield>
                  <kendo-label class="asterisk" [for]="insuredCity" text="City"></kendo-label>
                  <kendo-textbox #insuredCity formControlName="insuredCity" placeholder="Enter city" fillMode="outline"
                      [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                  <kendo-formerror>
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="insuredInformation?.controls?.insuredCity?.errors?.required">City cannot be left blank</span>
                  </kendo-formerror>
              </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item>
              <kendo-formfield>
                  <kendo-label class="asterisk" [for]="insuredState" text="State"></kendo-label>
                  <kendo-dropdownlist #insuredState [data]="groupedDataStates" textField="text" valueField="value"
                      formControlName="insuredState" [valuePrimitive]="true" fillMode="outline" [filterable]="false" 
                      (filterChange)="handleFilter($event)" [defaultItem]="{value: '', text: 'Select state', type: ''}"
                      [popupSettings]="{appendTo: 'component', animate: false}">
                      <ng-template kendoDropDownListGroupTemplate let-groupName>
                          <strong>{{ groupName }}</strong>
                      </ng-template>
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.text }} </span>
                        <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.text }} </span>               
                    </ng-template>
                  </kendo-dropdownlist>
                  <kendo-formerror>
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="insuredInformation?.controls?.insuredState?.errors?.required">State cannot be left blank</span>
                  </kendo-formerror>
              </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <kendo-formfield>
                <kendo-label class="asterisk" [for]="insuredZipcode" text="Zip Code"></kendo-label>
                <kendo-textbox #insuredZipcode formControlName="insuredZipcode" placeholder="Enter zip code"
                    fillMode="outline" [clearButton]="true" appSkipClearButtonTabindex></kendo-textbox>
                <kendo-formerror>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="insuredInformation?.controls?.insuredZipcode?.errors?.required">Zip Code cannot be left blank</span>
                </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <kendo-formfield>
                <kendo-label class="asterisk" [for]="insuredContactNumber" text="Phone Number"></kendo-label>
                <kendo-maskedtextbox #insuredContactNumber formControlName="insuredContactNumber"
                    placeholder="Enter phone number" fillMode="outline" mask="(000) 000-0000"></kendo-maskedtextbox>
                <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
                <kendo-formerror>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="insuredInformation?.controls?.insuredContactNumber?.errors?.required">Phone number cannot be left blank</span>
                    <span *ngIf="insuredInformation?.controls?.insuredContactNumber?.errors?.patternError">Invalid phone number</span>
                </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>
  </form>
</kendo-card>