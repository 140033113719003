<kendo-formfield class="kendo-formfield">
  <kendo-label class="asterisk" text="Priority"></kendo-label>
  <kendo-dropdownlist [data]="priorities" fillMode="outline" textField="name" valueField="id"
    #priorityField="ngModel" name="priorityId" [valuePrimitive]="true" [defaultItem]="{name: 'All', id: null}" [(ngModel)]="priorityId"
    (valueChange)="handleSelectionChange($event)"
    [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required>
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.name }} </span>
      <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.name }} </span>
    </ng-template>
  </kendo-dropdownlist>
  <kendo-formerror *ngIf="!priorityField?.valid && (priorityField?.dirty || priorityField?.touched)">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <span *ngIf="priorityField?.errors?.required">
      Priority cannot be left blank
    </span>
  </kendo-formerror>
</kendo-formfield>
