
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiQuarterlyUtilizationReportFilter, IApiQuarterlyUtilizationReportConnection } from 'src/app/shared/modules/graphql/types/types';
import { GqlQuarterlyUtilizationReportService } from 'src/app/shared/modules/graphql/services/reports/quarterly-utilization-report/quarterly-utilization-report.service';

@Injectable()
export class QuarterlyUtilizationReportService {
  constructor(
    private gqlQuarterlyUtilizationReportService: GqlQuarterlyUtilizationReportService
  ) { }

  public get(filters?: IApiQuarterlyUtilizationReportFilter[], options?: IGqlBaseOptions): Observable<IApiQuarterlyUtilizationReportConnection> {
    return this.gqlQuarterlyUtilizationReportService.get(filters, options || new ListPage());
  }

}
