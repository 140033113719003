<!-- Permissions -->
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesEntryListHours" #bookKeepingTimeExpensesEntryListHours="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesEntryListExpensesMileage" #bookKeepingTimeExpensesEntryListExpensesMileage="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesHoursAccountSummary" #bookKeepingTimeExpensesHoursAccountSummary="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesExpensesMileageAccountSummary" #bookKeepingTimeExpensesExpensesMileageAccountSummary="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageUpdate" #expenseUnlinkedMileageUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageUpdateOwn" #expenseUnlinkedMileageUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageUpdate" #expenseInvestigationMileageUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageUpdateOwn" #expenseInvestigationMileageUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesUpdate" #expenseInvestigationExpensesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesUpdateOwn" #expenseInvestigationExpensesUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseUpdate" #expenseUnlinkedExpenseUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseUpdateOwn" #expenseUnlinkedExpenseUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryUnlinkedHoursUpdate" #timeEntryUnlinkedHoursUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryUnlinkedHoursUpdateOwn" #timeEntryUnlinkedHoursUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursUpdate" #timeEntryInvestigationHoursUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursUpdateOwn" #timeEntryInvestigationHoursUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursCreate" #timeEntryInvestigationHoursCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryUnlinkedHoursCreate" #timeEntryUnlinkedHoursCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageCreate" #expenseUnlinkedMileageCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageCreate" #expenseInvestigationMileageCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseCreate" #expenseUnlinkedExpenseCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesCreate" #expenseInvestigationExpensesCreate="appEnforcePermission"></div>
<!-- Permissions -->
<h4>Time and Expenses</h4>
<kendo-tabstrip (tabSelect)="onTabSelect($event)">
  <kendo-tabstrip-tab
    title="Entry List"
    [selected]="currentActiveTab === 'entryList'"
    icon="fa-solid fa-user"
    *ngIf="bookKeepingTimeExpensesEntryListHours?.enabled || bookKeepingTimeExpensesEntryListExpensesMileage?.enabled"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <ng-container
          *ngTemplateOutlet="kendoFilters; context: { view: 'summary' }"
        ></ng-container>
        <ng-container *ngIf="displayDataType === dataTypesOpts.hours">
          <ng-container
            *ngTemplateOutlet="
              tableData;
              context: {
                view: 'entry',
                columns: entryColumns,
                tableList: timeData,
                pagination: pageOptionsTime,
                data: null
              }
            "
          >
          </ng-container>
        </ng-container>
        <ng-container *ngIf="displayDataType === dataTypesOpts.expenses">
          <ng-container
            *ngTemplateOutlet="
              tableData;
              context: {
                view: 'entry',
                columns: entryColumns,
                tableList: expensesData,
                pagination: pageOptions,
                data: null
              }
            "
          >
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab 
    [selected]="currentActiveTab === 'summary'"  
    title="Account Summary"
    *ngIf="bookKeepingTimeExpensesHoursAccountSummary?.enabled || bookKeepingTimeExpensesExpensesMileageAccountSummary?.enabled"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <ng-container
          *ngTemplateOutlet="kendoFilters; context: { view: 'summary' }"
        ></ng-container>
        <ng-container *ngIf="displayDataType === dataTypesOpts.hours">
          <ng-container
            *ngTemplateOutlet="
              accountSummarytableData;
              context: {
                view: 'summary',
                columns: summaryColumns,
                tableList: timeEntryTypeSummary,
                sortAccountSummarySort: sortAccountSummarySort
              }
            "
          >
          </ng-container>
        </ng-container>
        <ng-container *ngIf="displayDataType === dataTypesOpts.expenses">
          <ng-container
            *ngTemplateOutlet="
              accountSummarytableData;
              context: {
                view: 'summary',
                columns: summaryColumns,
                tableList: expenseItemSummary,
                sortAccountSummarySort: sortAccountSummarySort
              }
            "
          >
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<ng-template #kendoFilters let-view="view">
  <div class="time-expenses-filter">
    <kendo-gridlayout-item>
      <div class="kendo-formfield">
        <div class="kendo-radio">
          <span class="mr-16 view-lable">View</span>
          <ng-container *ngIf="getListPermission('hours')">
            <input
              type="radio"
              id="NoReport"
              name="NoReport"
              [(ngModel)]="displayDataType"
              kendoRadioButton
              [value]="dataTypesOpts.hours"
              (change)="viewChange(dataTypesOpts.hours)"
            />
            <label class="k-radio-label mr-24" for="NoReport">Hours</label>
          </ng-container>
          <ng-container *ngIf="getListPermission('expenses')">
            <input
              type="radio"
              id="HasReport"
              name="NoReport"
              [(ngModel)]="displayDataType"
              kendoRadioButton
              [value]="dataTypesOpts.expenses"
              (change)="viewChange(dataTypesOpts.expenses)"
            />
            <label class="k-radio-label mr-24" for="HasReport"
              >Expenses & Mileage</label
            >
          </ng-container>
        </div>
      </div>
    </kendo-gridlayout-item>
    <div class="time-filters">
      <form #form="ngForm">
        <kendo-gridlayout
          [rows]="['auto']"
          [gap]="{ rows: 6, cols: 16 }"
          [cols]="filterColumns"
        >
          <!--START Search Filter -->
          <ng-container *ngIf="currentActiveTab === 'entryList'">
            <kendo-gridlayout-item>
              <div class="kendo-formfield left-search-icon">
                <kendo-label [for]="searchtextbox" text="Search"></kendo-label>
                <div style="position: relative">
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <kendo-textbox
                    #searchtextbox
                    [(ngModel)]="searchValue"
                    placeholder="Search"
                    fillMode="outline"
                    [clearButton]="false"
                    [ngModelOptions]="{ standalone: true }"
                  >
                  </kendo-textbox>
                </div>
              </div>
            </kendo-gridlayout-item>
          </ng-container>
          <!-- END Search Filter -->

          <!--START Search by staff member Filter -->
          <kendo-gridlayout-item>
            <app-user-select-kendo
              label="Staff Member"
              [userViewFilter]="userViewFilter"
              placeholder="Search by staff member"
              [userId]="selectedStaff"
              [includeInactive]="false"
              (selectionChanged)="setSelectedStaff($event?.id)"
            ></app-user-select-kendo>
          </kendo-gridlayout-item>
          <!-- END Search by staff member Filter -->

          <!--START date Filter -->
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label
                class="{{ entryEndDate || entryStartDate ? 'asterisk' : '' }}"
                text="Entry Date"
              ></kendo-label>
              <kendo-datepicker
                name="entryStart"
                #entryStart="ngModel"
                calendarType="classic"
                [fillMode]="'outline'"
                placeholder="Select date"
                format="MM/dd/yyyy"
                [(ngModel)]="entryStartDate"
                formatPlaceholder="formatPattern"
                [required]="entryEndDate || entryStartDate"
              ></kendo-datepicker>
              <kendo-formerror
                *ngIf="
                  entryStart?.errors?.required &&
                  (entryStart?.touched || entryStart?.dirty)
                "
              >
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span> Entry Date cannot be left blank </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label
                class="{{ entryEndDate || entryStartDate ? 'asterisk' : '' }}"
                text="End Date"
              ></kendo-label>
              <kendo-datepicker
                calendarType="classic"
                [fillMode]="'outline'"
                [(ngModel)]="entryEndDate"
                name="entryEnd"
                #entryEnd="ngModel"
                placeholder="Select date"
                format="MM/dd/yyyy"
                [required]="entryEndDate || entryStartDate"
                formatPlaceholder="formatPattern"
              ></kendo-datepicker>
              <!-- [disabledDates]="disabledDates" -->
              <kendo-formerror
                *ngIf="
                  entryEnd?.errors?.required &&
                  (entryEnd?.touched || entryEnd?.dirty)
                "
              >
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span>End Date cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <!-- END date Filter -->

          <!-- start work day filter -->
          <kendo-gridlayout-item *ngIf="displayDataType === dataTypesOpts.hours">
            <kendo-formfield>
              <kendo-label
                class="{{ workEndDate || workStartDate ? 'asterisk' : '' }}"
                text="Work Date Start"
              ></kendo-label>
              <kendo-datepicker
                name="workStart"
                #workStart="ngModel"
                calendarType="classic"
                [fillMode]="'outline'"
                placeholder="Select date"
                format="MM/dd/yyyy"
                [(ngModel)]="workStartDate"
                formatPlaceholder="formatPattern"
                [required]="workEndDate || workStartDate"
              ></kendo-datepicker>
              <kendo-formerror
                *ngIf="
                  workStart?.errors?.required &&
                  (workStart?.touched || entryStart?.dirty)
                "
              >
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span> Work Date Start cannot be left blank </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item *ngIf="displayDataType === dataTypesOpts.hours">
            <kendo-formfield>
              <kendo-label
                class="{{ workEndDate || workStartDate ? 'asterisk' : '' }}"
                text="Work End Date"
              ></kendo-label>
              <kendo-datepicker
                calendarType="classic"
                [fillMode]="'outline'"
                [(ngModel)]="workEndDate"
                name="workEnd"
                #workEnd="ngModel"
                placeholder="Select date"
                format="MM/dd/yyyy"
                [required]="workEndDate || workStartDate"
                formatPlaceholder="formatPattern"
              ></kendo-datepicker>
              <!-- [disabledDates]="disabledDates" -->
              <kendo-formerror
                *ngIf="
                  workEnd?.errors?.required &&
                  (workEnd?.touched || workEnd?.dirty)
                "
              >
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span>Work Date End cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <!-- end work day filter -->
          <!--Create unlinked entry -->
          <kendo-gridlayout-item>
            <div class="kendo-formfield">
              <kendo-label text=""></kendo-label>
              <div *ngIf="currentActiveTab === 'entryList' && 
                (expenseInvestigationExpensesCreate?.enabled || 
                expenseUnlinkedExpenseCreate?.enabled ||
                expenseInvestigationMileageCreate?.enabled ||
                expenseUnlinkedMileageCreate?.enabled ||
                timeEntryInvestigationHoursCreate?.enabled ||
                timeEntryUnlinkedHoursCreate?.enabled
                )"
              >
                <kendo-dropdownbutton
                  [data]="addEntryMenu"
                  themeColor="light"
                  fillMode="solid"
                  buttonClass="grid-action-items"
                  iconClass="fa-solid fa-plus"
                  (itemClick)="itemSelected($event)"
                  [popupSettings]="{
                    appendTo: 'component',
                    animate: false,
                    width: 250,
                    popupClass: 'grid-action-items-menu mt-12'
                  }"
                >
                  Add Entry
                </kendo-dropdownbutton>
              </div>
              <div *ngIf="currentActiveTab === 'summary'">
                <kendo-button
                  kendoButton
                  themeColor="light"
                  fillMode="solid"
                  iconClass="fa-solid fa-file-export"
                  class="mr-10"
                  (click)="exportAsCSV()"
                  >Export as CSV</kendo-button
                >
              </div>
            </div>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item [colSpan]="colSpan">
            <div class="dflex filter-clear">
              <div class="applied-filter mr-16">
                <kendo-label text=""></kendo-label>
                <div>
                  <kendo-button
                    kendoButton
                    themeColor="primary"
                    fillMode="solid"
                    size="medium"
                    (click)="loadAll()"
                    >Apply Filters</kendo-button
                  >
                </div>
              </div>
              <div class="clear-filter mt-5">
                <kendo-label text=""></kendo-label>
                <div>
                  <kendo-button
                    kendoButton
                    themeColor="primary"
                    fillMode="link"
                    size="medium"
                    (click)="clearFilters()"
                    >Clear All</kendo-button
                  >
                </div>
              </div>
            </div>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </form>
    </div>
    <hr class="divider-line-checkbox" />

    <kendo-gridlayout
      [rows]="['auto']"
      [gap]="{ rows: 5, cols: 16 }"
      [cols]="['repeat(5,minmax(0,1fr))']"
    >
      <kendo-gridlayout-item>
        <kendo-formfield class="kendo-formfield">
          <kendo-label #advancedFilter text="Advanced filters"></kendo-label>
          <kendo-multiselect
            #advancedFilter
            [checkboxes]="true"
            [autoClose]="false"
            [valuePrimitive]="true"
            [data]="availableFilters"
            [rounded]="'full'"
            [(ngModel)]="advancedFilters"
            [placeholder]="'Select advanced filters'"
            [fillMode]="'outline'"
            [tagMapper]="tagMapper"
            [kendoDropDownFilter]="{
              caseSensitive: false,
              operator: 'startsWith'
            }"
            (valueChange)="advancedFilterValueChange($event)"
          >
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <span style="padding-left: 5px">{{ dataItem }}</span>
            </ng-template>
          </kendo-multiselect>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <form #filterForm="ngForm">
      <kendo-gridlayout
        [rows]="['auto']"
        [gap]="{ rows: 5, cols: 16 }"
        [cols]="['repeat(3,minmax(0,1fr))']"
        *ngIf="advancedFilters?.length"
      >
        <ng-container *ngFor="let filter of advancedFilters">
          <kendo-gridlayout-item>
            <kendo-card class="advanced-filter-time-expenses" width="100%">
              <kendo-card-header class="f-card-header">
                <h3 class="kendo-h3 mr-16">
                  {{ filter === filterTypes.Type ? "Purpose" : filter }}
                </h3>
                <ng-container [ngSwitch]="filter">
                  <!-- paid -->
                  <ng-container *ngSwitchCase="filterTypes.Paid">
                    <div class="kendo-formfield select-drop-down">
                      <kendo-dropdownlist
                        [data]="dropDownOption"
                        textField="title"
                        valueField="id"
                        fillMode="outline"
                        [valuePrimitive]="true"
                        [defaultItem]="{ id: null, title: 'None' }"
                        [(ngModel)]="selectedPaid"
                        name="selectedPaid"
                        [popupSettings]="{
                          appendTo: 'component',
                          animate: false,
                          width: 250
                        }"
                      >
                        <ng-template
                          kendoDropDownListValueTemplate
                          let-dataItem
                        >
                          <span
                            *ngIf="dataItem?.title === 'None'"
                            class="placeholder"
                          >
                            {{ dataItem?.title }}
                          </span>
                          <span
                            *ngIf="dataItem?.title !== 'None'"
                            class="not-placeholder"
                          >
                            {{ dataItem?.title }}
                          </span>
                        </ng-template>
                      </kendo-dropdownlist>
                    </div>
                  </ng-container>

                  <!-- invoiced -->
                  <ng-container *ngSwitchCase="filterTypes.Invoiced">
                    <div class="kendo-formfield select-drop-down">
                      <kendo-dropdownlist
                        class="drop-down"
                        [data]="dropDownOption"
                        textField="title"
                        valueField="id"
                        fillMode="outline"
                        [valuePrimitive]="true"
                        [defaultItem]="{ id: null, title: 'None' }"
                        [(ngModel)]="selectedInvoiced"
                        name="selectedInvoiced"
                        [popupSettings]="{
                          appendTo: 'component',
                          animate: false,
                          width: 250
                        }"
                      >
                        <ng-template
                          kendoDropDownListValueTemplate
                          let-dataItem
                        >
                          <span
                            *ngIf="dataItem?.title === 'None'"
                            class="placeholder"
                          >
                            {{ dataItem?.title }}
                          </span>
                          <span
                            *ngIf="dataItem?.title !== 'None'"
                            class="not-placeholder"
                          >
                            {{ dataItem?.title }}
                          </span>
                        </ng-template>
                      </kendo-dropdownlist>
                    </div>
                  </ng-container>
                </ng-container>
                <i
                  class="fa-solid fa-xmark"
                  (click)="removeFilterCard(filter)"
                ></i>
              </kendo-card-header>
              <kendo-card-body>
                <ng-container [ngSwitch]="filter">
                  <!-- paid -->
                  <ng-container *ngSwitchCase="filterTypes.Paid">
                    <kendo-gridlayout
                      [rows]="['auto']"
                      [gap]="{ rows: 5, cols: 16 }"
                      [cols]="['repeat(2,minmax(0,1fr))']"
                    >
                      <kendo-gridlayout-item>
                        <div class="kendo-formfield">
                          <kendo-label
                            class="{{
                              paidStartDate || paidEndDate ? 'asterisk' : ''
                            }}"
                            text="Start Date"
                          ></kendo-label>
                          <kendo-datepicker
                            id="startDate"
                            name="startDate"
                            calendarType="classic"
                            [fillMode]="'outline'"
                            placeholder="Select date"
                            format="MM/dd/yyyy"
                            [max]="today"
                            [ngClass]="{
                              'ng-invalid ng-touched':
                                (!startDate?.valid ||
                                  paidStartDate > paidEndDate) &&
                                submitted
                            }"
                            [(ngModel)]="paidStartDate"
                            formatPlaceholder="formatPattern"
                            required="{{
                              paidStartDate || paidEndDate ? true : false
                            }}"
                            [disabled]="selectedPaid === null"
                            #startDate="ngModel"
                          ></kendo-datepicker>
                          <kendo-formerror
                            *ngIf="
                              (paidStartDate || paidEndDate) &&
                              !startDate?.valid &&
                              (startDate?.dirty ||
                                startDate?.touched ||
                                submitted)
                            "
                          >
                            <i
                              class="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>
                            <span *ngIf="startDate?.errors?.required">
                              Start Date cannot be left blank
                            </span>
                            <span
                              *ngIf="
                                !startDate?.errors?.required &&
                                startDate?.errors
                              "
                            >
                              Start Date is invalid
                            </span>
                          </kendo-formerror>
                          <kendo-formerror
                            *ngIf="paidStartDate > paidEndDate && submitted"
                          >
                            <i
                              class="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>
                            <span>
                              Start Date cannot be greater then EndDate
                            </span>
                          </kendo-formerror>
                        </div>
                      </kendo-gridlayout-item>

                      <kendo-gridlayout-item>
                        <div class="kendo-formfield">
                          <kendo-label
                            class="{{
                              paidStartDate || paidEndDate ? 'asterisk' : ''
                            }}"
                            text="End Date"
                          ></kendo-label>
                          <kendo-datepicker
                            id="endDate"
                            name="endDate"
                            calendarType="classic"
                            [fillMode]="'outline'"
                            placeholder="Select date"
                            [max]="today"
                            [ngClass]="{
                              'ng-invalid ng-touched':
                                !endDate?.valid && submitted
                            }"
                            format="MM/dd/yyyy"
                            [(ngModel)]="paidEndDate"
                            formatPlaceholder="formatPattern"
                            required="{{
                              paidStartDate || paidEndDate ? true : false
                            }}"
                            [disabled]="selectedPaid === null"
                            #endDate="ngModel"
                          ></kendo-datepicker>
                          <kendo-formerror
                            *ngIf="
                              (paidStartDate || paidEndDate) &&
                              !endDate?.valid &&
                              (endDate?.dirty || endDate?.touched || submitted)
                            "
                          >
                            <i
                              class="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>
                            <span *ngIf="endDate?.errors?.required">
                              End Date cannot be left blank
                            </span>
                            <span
                              *ngIf="
                                !endDate?.errors?.required && endDate?.errors
                              "
                            >
                              Start Date is invalid
                            </span>
                          </kendo-formerror>
                        </div>
                      </kendo-gridlayout-item>
                    </kendo-gridlayout>
                  </ng-container>

                  <!-- invoiced -->
                  <ng-container *ngSwitchCase="filterTypes.Invoiced">
                    <kendo-gridlayout
                      [rows]="['auto']"
                      [gap]="{ rows: 5, cols: 16 }"
                      [cols]="['repeat(2,minmax(0,1fr))']"
                    >
                      <kendo-gridlayout-item>
                        <div class="kendo-formfield">
                          <kendo-label
                            class="{{
                              invoicedStartDate || invoicedEndDate
                                ? 'asterisk'
                                : ''
                            }}"
                            text="Start Date"
                          ></kendo-label>
                          <kendo-datepicker
                            name="invoicedStart"
                            calendarType="classic"
                            [fillMode]="'outline'"
                            placeholder="Select date"
                            format="MM/dd/yyyy"
                            [max]="today"
                            [ngClass]="{
                              'ng-invalid ng-touched':
                                (!invoicedStart?.valid ||
                                  invoicedStartDate > invoicedEndDate) &&
                                submitted
                            }"
                            [(ngModel)]="invoicedStartDate"
                            formatPlaceholder="formatPattern"
                            required="{{
                              invoicedStartDate || invoicedEndDate
                                ? true
                                : false
                            }}"
                            [disabled]="selectedInvoiced === null"
                            #invoicedStart="ngModel"
                          ></kendo-datepicker>
                          <kendo-formerror
                            *ngIf="
                              (invoicedStartDate || invoicedEndDate) &&
                              !invoicedStart?.valid &&
                              (invoicedStart?.dirty ||
                                invoicedStart?.touched ||
                                submitted)
                            "
                          >
                            <i
                              class="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>
                            <span *ngIf="invoicedStart?.errors?.required">
                              Start Date cannot be left blank
                            </span>
                            <span
                              *ngIf="
                                !invoicedStart?.errors?.required &&
                                invoicedStart?.errors
                              "
                            >
                              Start Date is invalid
                            </span>
                          </kendo-formerror>
                          <kendo-formerror
                            *ngIf="
                              invoicedStartDate > invoicedEndDate && submitted
                            "
                          >
                            <i
                              class="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>
                            <span>
                              Start Date cannot be greater then EndDate
                            </span>
                          </kendo-formerror>
                        </div>
                      </kendo-gridlayout-item>

                      <kendo-gridlayout-item>
                        <div class="kendo-formfield">
                          <kendo-label
                            class="{{
                              invoicedStartDate || invoicedEndDate
                                ? 'asterisk'
                                : ''
                            }}"
                            text="End Date"
                          ></kendo-label>
                          <kendo-datepicker
                            name="invoicedEnd"
                            calendarType="classic"
                            [fillMode]="'outline'"
                            placeholder="Select date"
                            [max]="today"
                            [ngClass]="{
                              'ng-invalid ng-touched':
                                !invoicedEnd?.valid && submitted
                            }"
                            format="MM/dd/yyyy"
                            [(ngModel)]="invoicedEndDate"
                            formatPlaceholder="formatPattern"
                            required="{{
                              invoicedStartDate || invoicedEndDate
                                ? true
                                : false
                            }}"
                            [disabled]="selectedInvoiced === null"
                            #invoicedEnd="ngModel"
                          ></kendo-datepicker>
                          <kendo-formerror
                            *ngIf="
                              (invoicedStartDate || invoicedEndDate) &&
                              !invoicedEnd?.valid &&
                              (invoicedEnd?.dirty ||
                                invoicedEnd?.touched ||
                                submitted)
                            "
                          >
                            <i
                              class="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>
                            <span *ngIf="invoicedEnd?.errors?.required">
                              End Date cannot be left blank
                            </span>
                            <span
                              *ngIf="
                                !invoicedEnd?.errors?.required &&
                                invoicedEnd?.errors
                              "
                            >
                              StartDate is invalid
                            </span>
                          </kendo-formerror>
                        </div>
                      </kendo-gridlayout-item>
                    </kendo-gridlayout>
                  </ng-container>

                  <!-- type -->
                  <ng-container *ngSwitchCase="filterTypes.Type">
                    <kendo-gridlayout
                      [rows]="['auto']"
                      [gap]="{ rows: 5, cols: 16 }"
                      [cols]="['repeat(1,minmax(0,1fr))']"
                    >
                      <kendo-gridlayout-item>
                        <div class="kendo-formfield">
                          <kendo-label
                            [for]="typeSelect"
                            text="Type"
                          ></kendo-label>
                          <kendo-dropdownlist
                            #typeSelect
                            [data]="typeListData"
                            textField="name"
                            valueField="id"
                            fillMode="outline"
                            [valuePrimitive]="true"
                            [defaultItem]="{ id: null, name: 'Select type' }"
                            [(ngModel)]="selectedType"
                            name="selectedType"
                            [popupSettings]="{
                              appendTo: 'component',
                              animate: false,
                              width: 250
                            }"
                          >
                            <ng-template
                              kendoDropDownListValueTemplate
                              let-dataItem
                            >
                              <span
                                *ngIf="dataItem?.name === 'Select type'"
                                class="placeholder"
                                >{{ dataItem?.name }}</span
                              >
                              <span
                                *ngIf="dataItem?.name !== 'Select type'"
                                class="not-placeholder"
                              >
                                {{ dataItem?.name }}
                              </span>
                            </ng-template>
                          </kendo-dropdownlist>
                        </div>
                      </kendo-gridlayout-item>
                    </kendo-gridlayout>
                  </ng-container>
                </ng-container>
              </kendo-card-body>
            </kendo-card>
          </kendo-gridlayout-item>
        </ng-container>
      </kendo-gridlayout>
    </form>
  </div>
</ng-template>

<ng-template
  #tableData
  let-view="view"
  let-columns="columns"
  let-tableList="tableList"
  let-data="data"
  let-pagination="pagination"
>
  <kendo-grid
    class="hours-list"
    [data]="{
      data: tableList,
      total: pagination?.totalCount
    }"
    [pageSize]="pagination?.limit"
    [skip]="pagination?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageChange($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
  >
    <!-- staff -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('STAFF') > -1"
      field="User"
      title="Staff"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/staff', dataItem?.User?.id]">{{
          (dataItem?.User | fullName) || "-"
        }}</a>
      </ng-template>
    </kendo-grid-column>

    <!-- ID# -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('investigation') > -1"
      field="INVESTIGATION"
      title="ID#"
      [width]="13"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          *ngIf="dataItem?.Investigation; else unlinked"
          [routerLink]="['/investigations', dataItem?.Investigation?.id]"
          [queryParams]="{ tab: 'expenses' }"
          [innerHtml]="dataItem?.Investigation?.nefcoNumber"
        ></a>
        <ng-template #unlinked>Unlinked</ng-template>
      </ng-template>
    </kendo-grid-column>

    <!-- Type -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('type') > -1"
      field="TYPE"
      title="Type"
      [width]="20"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="">
          {{ dataItem.ExpenseItem?.name || dataItem?.Type?.name }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <!-- Billable -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('billable') > -1"
      field="BILL"
      title="Billable"
      [width]="9"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.billableQuantity || dataItem?.hours || 0 }}
      </ng-template>
    </kendo-grid-column>

    <!-- Non-Billable -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('nonBillable') > -1"
      field="NON_BILL"
      title="Non-Billable"
      [width]="9"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.nonBillableQuantity || dataItem?.nonBillableHours || 0 }}
      </ng-template>
    </kendo-grid-column>

    <!-- Description -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('description') > -1"
      field="description"
      title="Description"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="text-overflow">{{ dataItem?.description || "-" }}</span>
      </ng-template>
    </kendo-grid-column>

    <!-- Entry -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('EXPENSE_DATE') > -1"
      field="EXPENSE_DATE"
      title="Entry"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="">{{
          dataItem.createdAt | customDate : "MM/dd/yyyy"
        }}</span>
      </ng-template>
    </kendo-grid-column>

    <!-- Paid -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('paid') > -1"
      field="PAID_DATE"
      title="Paid"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ (dataItem?.paidDate | customDate : "MM/dd/yyyy") || "-" }}
      </ng-template>
    </kendo-grid-column>

    <!-- Invoiced -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('INVOICE') > -1"
      field="PAID_DATE"
      title="Invoiced"
      [width]="9"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <i
          *ngIf="!isInvoiced(dataItem, false); else grayIcon"
          class="right-circle-icon fa-solid fa-circle-check"
        ></i>
        <ng-template #grayIcon>
          <i class="minus-circle-icon fa-solid fa-circle-minus"></i>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <!-- action -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('edit') > -1"
      field="id"
      title="Action"
      [width]="9"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoButton
          class="mr-8"
          themeColor="secondary"
          fillMode="link"
          iconClass="fa-solid fa-pencil"
          (click)="editRecord(dataItem, $event)"
          *ngIf="isInvoiced(dataItem)"
        ></button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoGridNoRecordsTemplate>
      <p>No Record Found.</p>
    </ng-template>
  </kendo-grid>
</ng-template>

<!-- Account Summary table -->
<ng-template
  #accountSummarytableData
  let-view="view"
  let-columns="columns"
  let-tableList="tableList"
  let-sortAccountSummarySort="sortAccountSummarySort"
>
  <kendo-grid
    class="hours-list"
    [data]="tableList"
    [sortable]="true"
    [sort]="sortAccountSummarySort"
    (sortChange)="sortChangeAccountSummary($event)"
    [navigatable]="false"
  >
    <!-- Type -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('type') > -1"
      field="NAME"
      title="Type"
      [width]="20"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="">
          {{ dataItem?.name }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <!-- Billable -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('billable') > -1"
      field="BILL"
      title="Billable"
      [width]="9"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{
          dataItem?.summary?.billableQuantity || dataItem?.summary?.hours || 0
        }}
      </ng-template>
    </kendo-grid-column>

    <!-- Non-Billable -->
    <kendo-grid-column
      *ngIf="columns?.indexOf('nonBillable') > -1"
      field="NON_BILL"
      title="Non-Billable"
      [width]="9"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{
          dataItem?.summary?.nonBillableQuantity ||
            dataItem?.summary?.nonBillableHours ||
            0
        }}
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate>
      <p>No Record Found.</p>
    </ng-template>
  </kendo-grid>
</ng-template>