import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { investigationPriorityConnectionQuery, addInvestigationPriorityMutation, removeInvestigationPriorityMutation, updateInvestigationPriorityMutation } from './investigation-priority.operations';
import { IApiInvestigationPriorityConnection } from '../../../types/types';
import { safeResolve } from '../../../rxjs';

import {
  IApiInvestigationPriorityFilter,
  IApiAddInvestigationPriorityInput,
  IApiUpdateInvestigationPriorityInput,
  IApiRemoveInvestigationPriorityInput
} from '../../../types/types';

@Injectable()
export class GqlInvestigationPriorityService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getInvestigationPriority(filters: IApiInvestigationPriorityFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationPriorityConnection> {
    return this.apollo.query(connectionQuery(investigationPriorityConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addInvestigationPriority(input: IApiAddInvestigationPriorityInput) {
    return this.apollo.mutate({
      mutation: addInvestigationPriorityMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeInvestigationPriority(input: IApiRemoveInvestigationPriorityInput) {
    return this.apollo.mutate({
      mutation: removeInvestigationPriorityMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateInvestigationPriority(input: IApiUpdateInvestigationPriorityInput) {
    return this.apollo.mutate({
      mutation: updateInvestigationPriorityMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
