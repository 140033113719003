<form [formGroup]="clientForm">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']">
    <!-- client -->
    <kendo-gridlayout-item>
      <div class="title-div">
        <p>
          Client:
        </p>
      </div>
      <ng-container *ngIf="!f.Client.value; else clientCard">
        <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="selectClient()">Select a
          Client</kendo-button>
      </ng-container>
      <ng-template #clientCard>
        <div class="align-baseline">
          <kendo-button kendoButton themeColor="primary" fillMode="link" class="mr-2" size="medium"
            (click)="removeClient()" [iconClass]="'fa-solid fa-circle-minus fa-lg'"></kendo-button>
          <kendo-gridlayout style="width: 100%;" [rows]="['auto']" [gap]="{rows: 5, cols: 0}"
            [cols]="['repeat(2,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <span>
                Contact
              </span>
              <p>
                {{ f.Client.value | fullName }}
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
              <span>
                Company
              </span>
              <p>
                {{ f?.ClientBranch?.value ? f?.ClientBranch?.value?.Company?.name : "N/A" }}
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
              <span>
                Branch
              </span>
              <p>
                {{ f?.ClientBranch?.value ? f?.ClientBranch?.value?.name : "N/A" }}
              </p>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </div>
      </ng-template>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <div class="title-div">
        <p>
          Bill To:
        </p>
        <span class="k-checkbox-wrap">
          <input #isBillToClientCheck type="checkbox" kendoCheckBox formControlName="isBillToClient" />
          <kendo-label [for]="isBillToClientCheck" class="k-checkbox-label font-weight-600 checkbox-margin"
            style="margin-bottom: 0" text="Client"></kendo-label>
        </span>
      </div>
      <ng-container *ngIf="!f?.BillTo?.value; else setBillTo">
        <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="selectBillTo()">Select
          a
          Client</kendo-button>
      </ng-container>
      <ng-template #setBillTo>
        <div class="align-baseline">
          <kendo-button kendoButton themeColor="primary" class="mr-2" fillMode="link" size="medium"
            (click)="removeBillTo()" [iconClass]="'fa-solid fa-circle-minus fa-lg'"></kendo-button>

          <kendo-gridlayout style="width: 100%;" [rows]="['auto']" [gap]="{rows: 5, cols: 0}"
            [cols]="['repeat(2,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <span>
                Contact
              </span>
              <p>
                {{ f?.BillTo?.value | fullName }}
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
              <span>
                Company
              </span>
              <p>
                {{ f?.BillToBranch?.value ? f?.BillToBranch.value?.Company?.name : "N/A" }}
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
              <span>
                Branch
              </span>
              <p>
                {{ f?.BillToBranch?.value ? f?.BillToBranch?.value?.name : "N/A" }}
              </p>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </div>
      </ng-template>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <div class="title-div">
        <p>
          Billing Rules:
        </p>
        <span class="k-checkbox-wrap">
          <input #clientBill color="accent" type="checkbox" kendoCheckBox [checked]="isClientBilling"
            (change)="isClientBilling = $event.checked" />
          <kendo-label [for]="clientBill" class="k-checkbox-label font-weight-600 checkbox-margin"
            style="margin-bottom: 0" text="Client"></kendo-label>
        </span>
      </div>
      <ng-container *ngIf="!f.Company?.value; else billingRulesCompany">
        <div style="width: 300px;">
          <app-company-select-single-kendo (selectionChanged)="selectBillingRulesCompany($event)"
            placeholder="Search for a Company"></app-company-select-single-kendo>
        </div>
      </ng-container>
      <ng-template #billingRulesCompany>
        <div class="align-baseline">
          <kendo-button kendoButton themeColor="primary" class="mr-2" fillMode="link" size="medium"
            (click)="removeBillingRulesCompany()" [iconClass]="'fa-solid fa-circle-minus fa-lg'"></kendo-button>
          <kendo-gridlayout style="width: 100%;" [rows]="['auto']" [gap]="{rows: 5, cols: 0}"
            [cols]="['repeat(1,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <span>
                Company
              </span>
              <p>
                {{ f.Company?.value?.name }}
              </p>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </div>
      </ng-template>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</form>
