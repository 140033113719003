import { ICertification } from './../../../../../interfaces/certification.interface';
import gql from "graphql-tag";
import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { addCertificationAgencyMutation, certificationAgencyConnectionQuery, removeCertificationAgencyMutation, updateCertificationAgencyMutation } from "./certification-agency.operations";
import { IApiAddCertificationAgencyInput, IApiCertificationAgency, IApiCertificationAgencyConnection, IApiCertificationAgencyFilter, IApiUpdateCertificationAgencyInput } from "../../../types/types";

@Injectable()
export class GqlCertificationAgencyService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getCertificationAgencies(filters: IApiCertificationAgencyFilter[], options: IGqlBaseOptions): Observable<IApiCertificationAgencyConnection> {
    return this.apollo.query(connectionQuery(certificationAgencyConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  // TODO - this needs to be refactored to the current patterns
  public saveCertification(document: ICertification) {
    return this.apollo.mutate({
      mutation: gql`mutation saveCertification($input: SaveCertificationInput!) {
          saveCertification(input: $input) {
            id,
            title,
            state,
            requiresRenewal,
            CertificationAgencyId,
            notes
          }
        }`,
      variables: { input: document }
    }).pipe(
      safeResolve(true)
    );
  }

  public addCertificationAgency(input: IApiAddCertificationAgencyInput): Observable<IApiCertificationAgency> {
    return this.apollo.mutate({
      mutation: addCertificationAgencyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public updateCertificationAgency(input: IApiUpdateCertificationAgencyInput): Observable<IApiCertificationAgency> {
    return this.apollo.mutate({
      mutation: updateCertificationAgencyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeCertificationAgency(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeCertificationAgencyMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
