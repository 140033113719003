import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  TimeEntryTypeId
  TimeEntryType{
    id
    name
  }
  AssignedUserCount
  EnabledNotifications
`;

export const PermissionRoleConnectionQuery = gql`${buildConnectionQuery("PermissionRole", "PermissionRoleConnection", nodeFragment)}`;

export const getPermissionRoleByIdQuery = gql`query getPermissionRoleById($id: String!) {
  getPermissionRoleById(id: $id) { ${ nodeFragment }
    AssignedPermissions {
      id
      Category {
        id
        name
      }
      Scope {
        id
        name
      }
      Action {
        id
        name
      }
    }
    AssignedUsers {
      id
      email
      firstName
      lastName
      Roles {
        id
        name
      }
      Positions {
        id
        title
      }
      Addresses {
        id
        state
        isPrimary
        address1
        address2
        address3
        Type {
          id
          name
        }
      }
      Emails {
        id
        address
        isPrimary
        Type {
          id
          name
        }
      }
      Phones {
        id
        number
        isPrimary
        Type {
          id
          name
        }
      }
    }
    EnabledNotifications
  }
}`;

export const addPermissionRoleMutation = gql`mutation AddPermissionRole($input: AddPermissionRoleInput!) {
  addPermissionRole(input: $input) { ${ nodeFragment } }
}`;

export const updatePermissionRoleMutation = gql`mutation UpdatePermissionRole($input: UpdatePermissionRoleInput!) {
  updatePermissionRole(input: $input) { ${ nodeFragment } }
}`;

export const removePermissionRoleMutation = gql`mutation RemovePermissionRole($id: ID!) {
  removePermissionRole(id: $id)
}`;

export const assignPermissionRolePermissionMutation = gql`mutation AssignPermissionRolePermission($input: AssignPermissionRolePermissionInput!) {
  assignPermissionRolePermission(input: $input)
}`;

export const assignPermissionRoleUserMutation = gql`mutation AssignPermissionRoleUser($input: AssignPermissionRoleUserInput!) {
  assignPermissionRoleUser(input: $input)
}`;

export const removePermissionRolePermissionMutation = gql`mutation RemovePermissionRolePermission($id: ID!) {
  removePermissionRolePermission(id: $id)
}`;

export const removePermissionRoleUserMutation = gql`mutation RemovePermissionRoleUser($input: RemovePermissionRoleUserInput!) {
  removePermissionRoleUser(input: $input)
}`;
