import { DataSource } from "@angular/cdk/collections";
import { IApiCertificationType, IApiCertificationTypeConnection, IApiCertificationTypeFilter } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { CertificationTypeService } from './certification-type.service';

export class CertificationTypeDataSource extends NefcoPagedDataSource<IApiCertificationType, CertificationTypeService, IApiCertificationTypeConnection> implements DataSource<IApiCertificationType> {
  @ApiConnection()
  public load(filters: IApiCertificationTypeFilter[] = []): Promise<IApiCertificationTypeConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
