<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageUserRolePermissionsList" #manageUserRolePermissionsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRolePermissionsCreate" #manageUserRolePermissionsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRolePermissionsDelete" #manageUserRolePermissionsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleStaffMembersCreate" #manageUserRoleStaffMembersCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleStaffMembersList" #manageUserRoleStaffMembersList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleStaffMembersDelete" #manageUserRoleStaffMembersDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffViewAll" #adminPerm="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleNotificationsList" #manageUserRoleNotificationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleNotificationsView" #manageUserRoleNotificationsView="appEnforcePermission"></div>
<!-- Permissions -->
<section >
  <div class="page-title">
    <p><a class="fs-12" [routerLink]="['/admin', 'permissions', 'list']">User Roles</a><span> / User Roles: {{role?.name}}</span></p>
    <h4 class="page-title mt-24 mb-24">User Roles: {{role?.name}}</h4>
  </div>
  
  <div class="tabs">
    <div class="button-container" *ngIf="selectedTab === 1 && manageUserRoleStaffMembersCreate?.enabled">
      <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="showAssignUsers()">
        Add Staff Members
      </kendo-button>
    </div>
    <div class="content-wrapper p-0">
    <kendo-tabstrip class="user-role-tabs"  #tabstrip [scrollable]="false" (tabSelect)="onTabChanged($event)">
      <kendo-tabstrip-tab [selected]="selectedTab === 0" *ngIf="manageUserRolePermissionsList?.enabled">
          <ng-template kendoTabTitle>
            <span>Permissions</span>
            <kendo-badge
                class="fw600 ml-8"
                position="none"
                size="large"
                rounded="full"
                themeColor="primary"
            >
                {{role?.AssignedPermissions.length || 0}}
            </kendo-badge>
        </ng-template>
        <ng-template kendoTabContent>
          <section class="tab-content">
            <ng-container *ngTemplateOutlet="permissionsList"></ng-container>
          </section>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [selected]="selectedTab === 1" *ngIf="manageUserRoleStaffMembersList?.enabled">
        <ng-template kendoTabTitle>
          <span>Staff Members</span>
          <kendo-badge
              class="fw600 ml-8"
              position="none"
              size="large"
              rounded="full"
              themeColor="primary"
          >
              {{role?.AssignedUsers.length || 0}}
          </kendo-badge>
        </ng-template>
        <ng-template kendoTabContent>
          <section class="tab-content">
            <ng-container *ngTemplateOutlet="staffList"></ng-container>
          </section>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [selected]="selectedTab === 2" *ngIf="manageUserRoleNotificationsList?.enabled && manageUserRoleNotificationsView?.enabled">
        <ng-template kendoTabTitle>
          <span>Notifications</span>
          <kendo-badge
              class="fw600 ml-8"
              position="none"
              size="large"
              rounded="full"
              themeColor="primary"
          >
              {{role?.EnabledNotifications.length || 0}}
          </kendo-badge>
        </ng-template>
        <ng-template kendoTabContent>
          <section class="tab-content">
            <app-notification-settings (reload)="refresh()"></app-notification-settings>
          </section>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
    </div>
  </div>

  <ng-template #permissionsList>
    <kendo-card [width]="'100%'" class="mb-3 p-24" *ngIf="manageUserRolePermissionsCreate?.enabled">
      <kendo-card-body class="p-0">
        <p class="font-weight-bold">Add Permissions to User Role</p>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label [for]="category" text="Category"></kendo-label>
              <kendo-dropdownlist #category [data]="(categories$ | async)" textField="name" valueField="id" [valuePrimitive]="false"
                [(ngModel)]="selectedCategory" fillMode="outline" [filterable]="false"
                [defaultItem]="{id: null, name: 'All Category'}"
                [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem.name }} </span>
                  <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem.name }} </span>
                </ng-template>
              </kendo-dropdownlist>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label [for]="scope" text="Scope"></kendo-label>
              <kendo-dropdownlist #scope [data]="selectedCategory?.AvailableScopes" textField="name" valueField="id" [valuePrimitive]="false"
                [(ngModel)]="selectedScope" fillMode="outline" [filterable]="false"
                [defaultItem]="{id: null, name: 'All Scope'}" [disabled]="!selectedCategory"
                [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem.name }} </span>
                  <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem.name }} </span>
                </ng-template>
              </kendo-dropdownlist>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label [for]="action" text="Action"></kendo-label>
              <kendo-dropdownlist #action [data]="filteredActions()" textField="name" valueField="id" [valuePrimitive]="false"
                [(ngModel)]="selectedAction" fillMode="outline" [filterable]="false"
                [defaultItem]="{id: null, name: 'All Action'}" [disabled]="!selectedScope"
                [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem.name }} </span>
                  <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem.name }} </span>
                </ng-template>
              </kendo-dropdownlist>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item [hidden]="!selectedAction">
            <kendo-label text=""></kendo-label>
            <div>
              <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="addPermission()">Add Permission</kendo-button>
            </div>
          </kendo-gridlayout-item>
        </kendo-gridlayout>

        <kendo-grid
          class="permission-list-grid"
          [data]="permissions"
          [pageable]="false"
          [sortable]="false"
          [navigatable]="false"
        >
          <kendo-grid-column
            field="Category"
            title="Category"
            [width]="20"
            [sortable]="false"
            [headerClass]="'nocursor'"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem?.category }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="Scope"
            title="Scope"
            [width]="5"
            [sortable]="false"
            [headerClass]="'nocursor'"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem?.scope }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="Actions"
            title="Actions"
            [width]="40"
            [sortable]="false"
            [headerClass]="'nocursor'"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-chip class="fw600 mr-16" [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'"
                [removable]="manageUserRolePermissionsDelete?.enabled" (remove)="removePermission(action.id)"
                *ngFor="let action of dataItem.actions">
                {{ action.name }}
              </kendo-chip>
            </ng-template>
          </kendo-grid-column>

        </kendo-grid>
      </kendo-card-body>
    </kendo-card>
  </ng-template>

  <ng-template #contacts let-data="data">
    <section class="mt-1 mb-1">
      <strong class="d-block">{{ data.Type.name }}:</strong>
      <span *ngIf="data.number; else email" [innerHTML]="data.number"></span>
      <ng-template #email>
        <a href="mailto:{{ data.address }}" [innerHTML]="data.address"></a>
      </ng-template>
    </section>
  </ng-template>

  <ng-template #staffList>
    <kendo-grid
      class="staff-list-grid"
      [data]="role?.AssignedUsers"
      [pageable]="false"
      [sortable]="false"
      [navigatable]="false"
    >
      <kendo-grid-column
        field="Name"
        title="Name"
        [width]="20"
        [sortable]="false"
        [class]="'id-column'"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoButton
            [ngClass]="'mr-16'"
            themeColor="primary"
            fillMode="link"
            [size]="'medium'"
            (click)="removeStaff(dataItem?.id)"
            iconClass="fa-solid fa-circle-minus"
            *ngIf="manageUserRoleStaffMembersDelete?.enabled"
          ></button>

          <a class="text-decoration" *ngIf="adminPerm?.enabled else nonadmin" [routerLink]="['/staff', dataItem.id]"
            target="_blank">{{ dataItem.lastName && dataItem.firstName ?  dataItem.lastName + ',' + dataItem.firstName : dataItem.firstName}}</a>
          <ng-template #nonadmin>
            {{ dataItem.lastName && dataItem.firstName ?  dataItem.lastName + ',' + dataItem.firstName : dataItem.firstName}}
          </ng-template>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Position"
        title="Position"
        [width]="20"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.Positions?.length else noValue">
            <p *ngFor="let role of dataItem.Positions" [innerHTML]="role.title | quiet"></p>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Location"
        title="Location"
        [width]="20"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <p [innerHTML]="getStateName((dataItem.Addresses | primaryAssociation)?.state)?.text || '-'"></p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Phone"
        title="Phone"
        [width]="20"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.Phones?.length else noValue">
            <section *ngFor="let phone of dataItem.Phones">
              <ng-container *ngTemplateOutlet="contacts; context: { data: phone }"></ng-container>
            </section>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Email"
        title="Email"
        [width]="20"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.Emails?.length else noValue">
            <section *ngFor="let email of dataItem.Emails">
              <ng-container *ngTemplateOutlet="contacts; context: { data: email }"></ng-container>
            </section>
          </ng-container>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </ng-template>
  <ng-template #noValue>
    <p>-</p>
  </ng-template>
</section>