import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, ExpenseItemService, InvestigationService, DocumentTypeService } from 'src/app/shared/services';
import { IApiInvestigation, IApiInvestigationFilterType, IApiExpenseItem, IApiDocumentType, IApiExpenseItemOrderBy } from "src/app/shared/modules/graphql/types/types";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { MatDialog } from '@angular/material/dialog';
import { CalendarProfileSidebarComponent } from '../../../../shared/components/calendar-profile-sidebar/calendar-profile-sidebar.component';
import { expenseReportNefcoCardList, expenseOutOfPocketList, expenseInvestigationExpensesList, expenseInvestigationMileageList, expenseUnlinkedMileageList, expenseUnlinkedExpenseList, timeEntryInvestigationHoursList, timeEntryUnlinkedHoursList } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-time-and-exp-management',
  templateUrl: './time-and-exp-management.component.html',
  styleUrls: ['./time-and-exp-management.component.scss']
})
export class TimeAndExpManagementComponent implements OnInit, AfterViewInit {

  @ViewChild("timeEntryInvestigationHoursList") timeEntryInvestigationHoursListPermission;
  @ViewChild("timeEntryUnlinkedHoursList") timeEntryUnlinkedHoursListPermission;
  @ViewChild("expenseUnlinkedExpenseList") expenseUnlinkedExpenseListPermission;
  @ViewChild("expenseUnlinkedMileageList") expenseUnlinkedMileageListPermission;
  @ViewChild("expenseInvestigationMileageList") expenseInvestigationMileageListPermission;
  @ViewChild("expenseInvestigationExpensesList") expenseInvestigationExpensesListPermission;
  @ViewChild("expenseOutOfPocketList") expenseOutOfPocketListPermission;
  @ViewChild("expenseReportNefcoCardList") expenseReportNefcoCardListPermission;

  public authConfig = {
    timeEntryInvestigationHoursList,
    timeEntryUnlinkedHoursList,
    expenseUnlinkedExpenseList,
    expenseUnlinkedMileageList,
    expenseInvestigationMileageList,
    expenseInvestigationExpensesList,
    expenseOutOfPocketList,
    expenseReportNefcoCardList
  }
  public user: any;
  public userId: string;
  private _expenseItems: IApiExpenseItem[] = null;
  private _investigations: IApiInvestigation[] = null;
  public documentTypes: IApiDocumentType[];

  public get expenseItems() {
    return this._expenseItems;
  }
  public set expenseItems(value: IApiExpenseItem[]) {
    this._expenseItems = value;
  }

  public get investigations() {
    return this._investigations;
  }
  public set investigations(value: IApiInvestigation[]) {
    this._investigations = value;
  }
  public selectedTab: string = 'MyHours';

  constructor(
    private auth: AuthService,
    private expenseItemService: ExpenseItemService,
    private documentTypeService: DocumentTypeService,
    private investigationService: InvestigationService,
    private dialogService: DialogService,
  ) {
    // Expense Items
    this.expenseItemService.get([], { limit: -1, sortOrder: SortOrder.ASC, orderBy: IApiExpenseItemOrderBy.Name }).pipe(
      unwrapConnection()
    ).subscribe(items => this.expenseItems = items);

    // Document Types
    this.documentTypeService.get([], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(
      unwrapConnection(),
    ).subscribe((result) => {
      this.documentTypes = result;
    });
  }

  public viewCalendar() {
    try {
      const dialog: DialogRef = this.dialogService.open({
        content: CalendarProfileSidebarComponent,
        width: "55%",
        height: "100vh",
        cssClass: 'right-position calendar-sidebar',
        preventAction: (ev) => {
          return ev !== 'closed' as any;
        },
      });
      const dialogInstance = dialog.content.instance as CalendarProfileSidebarComponent;
      dialogInstance.data = {
        id: null,
        User: this.user,
      };
      dialog.result.subscribe((result: DialogCloseResult) => {
      })
    } catch (e) {
      console.log(e);
    }

  }

  ngOnInit() {
    this.auth.authenticatedUser.subscribe((user) => {
      this.user = user;
      this.userId = user.id;
    });

    this.investigationService
      .get(
        [
          { type: IApiInvestigationFilterType.User, value: this.userId },
          {
            type: IApiInvestigationFilterType.ViewOnlyNefconumber,
            value: "true",
          },
        ],
        { limit: -1 }
      )
      .pipe(unwrapConnection())
      .subscribe((results) => {
        if (results && results?.length) {
          results.map((obj) => {
            obj["name"] = obj?.nefcoNumber ? obj.nefcoNumber : obj.id;
          });
        }
        this.investigations = results;
      });
  }

  ngAfterViewInit() {
    if (this.timeEntryInvestigationHoursListPermission?.enabled || this.timeEntryUnlinkedHoursListPermission?.enabled) {
      this.selectedTab = 'MyHours';
    } else if (this.expenseUnlinkedExpenseListPermission?.enabled || this.expenseUnlinkedMileageListPermission?.enabled || this.expenseInvestigationMileageListPermission?.enabled|| this.expenseInvestigationExpensesListPermission?.enabled) {
      this.selectedTab = 'MyExpenses';
    } else if (this.expenseOutOfPocketListPermission?.enabled) {
      this.selectedTab = 'OutofPocketExpenseReport';
    } else if (this.expenseReportNefcoCardListPermission?.enabled) {
      this.selectedTab = 'NEFCOCardExpenseReport';
    }
  }

}
