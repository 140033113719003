import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { GqlCommentService } from 'src/app/shared/modules/graphql/services';
import { IApiCommentableType, IApiInvestigation } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';

@Component({
  selector: 'app-mobile-add-comment-modal',
  templateUrl: './mobile-add-comment-modal.component.html',
  styleUrls: ['./mobile-add-comment-modal.component.scss']
})
export class MobileAddCommentModalComponent extends DialogContentBase implements OnInit {

  @ViewChild('commentForm', { static: false }) commentForm: NgForm;
  @Input() data: IApiInvestigation;
  public comment = "";

  constructor(
    public dialog: DialogRef,
    private loaderService: LoaderService,
    private commentService: GqlCommentService,
  ) { 
    super(dialog);
  }

  ngOnInit(): void {
    console.log()
  }

  public close() {
    this.dialog.close();
  }

  addComment() {
    if (this.commentForm?.invalid) {
      this.commentForm.form.markAllAsTouched();
      return;
    };

    this.loaderService
      .show$(
        this.commentService.addComment({
          commentableId: this.data.id,
          commentableType: IApiCommentableType.Investigation,
          text: this.comment,
        })
      )
      .subscribe((res) => {
        this.dialog.close(res);
      });
  }
}
