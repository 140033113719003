import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { IApiContact } from 'src/app/shared/modules/graphql/types/types';
import { ContactService } from 'src/app/shared/services';
import { CreateUpdateContactComponent } from '../create-update-contact/create-update-contact.component';
import {  companiesViewAll, companiesBranchesView } from 'src/app/shared/helpers/auth-config/companies.config';
import {
  contactsProfileCompanyInformationView,
  contactsProfileContactInformationViewAssigned,
  contactsProfileContactInformationUpdate,
  contactsProfileCompanyInformationUpdate,
} from "src/app/shared/helpers/auth-config/contacts.config";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import _ from 'lodash';
@UntilDestroy()
@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {
  public permissionConfig = {
    contactsProfileCompanyInformationView,
    contactsProfileContactInformationViewAssigned,
    contactsProfileContactInformationUpdate,
    contactsProfileCompanyInformationUpdate,
    companiesViewAll,
    companiesBranchesView
  };

  public contact: IApiContact;

  // Form options to be fetched
  public roles: any = [];

  constructor(
    public route: ActivatedRoute,
    public contactService: ContactService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.contact = this.route.snapshot.data.contact;
  }

  private load() {
    this.contactService.getById(this.contact.id).toPromise().then((contact) => this.contact = contact);
  }

  public editContact() {
    const dialog: DialogRef = this.dialogService.open({
      content: CreateUpdateContactComponent,
      width: '80%',
      maxWidth: '90%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as CreateUpdateContactComponent;
    dialogInstance.data = { contactId: this.contact.id };
    dialog.result
      .pipe(filter((v) => !_.isEmpty(v)))
      .subscribe((result: DialogCloseResult) => {
        if (result) {
          this.load()
        }
      });
  }

  public getPrimary(data: any[]) {
    return data?.find((v) => v.isPrimary) || {};
  }

}
