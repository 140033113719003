import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiInvestigationEditorTimelogAverage, IApiInvestigationEditorTimelogConnection, IApiInvestigationMetricsFilter } from 'src/app/shared/modules/graphql/types/types';
import { GqlInvestigationReportTimelogService } from 'src/app/shared/modules/graphql/services/reports';


@Injectable()
export class InvestigationReportTimelogService {
  constructor(
    private reports: GqlInvestigationReportTimelogService
  ) { }

  public get(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationEditorTimelogConnection> {
    return this.reports.get(filters, options || new ListPage());
  }

  public getTotal(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationEditorTimelogAverage> {
    return this.reports.getTotal(filters, options || new ListPage());
  }

}
