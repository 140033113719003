import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ExpenseService, InvestigationService } from 'src/app/shared/services';
import { ExpenseDataSource, ExpenseItemService } from 'src/app/shared/services/expenses';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { IApiExpense, IApiExpenseFilterType, IApiExpenseItemOrderBy, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { map, switchMap } from 'rxjs/operators';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { SelectionModel } from '@angular/cdk/collections';
import { InvestigationTimeAndExpQuickbooksModalComponent } from '../../investigations';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import * as dayjs from 'dayjs';
import { bookKeepingInvoiceListCreate } from 'src/app/shared/helpers/auth-config/bookkeeping.config';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-bookkeeping-evidence-billing',
  templateUrl: './bookkeeping-evidence-billing.component.html',
  styleUrls: ['./bookkeeping-evidence-billing.component.scss'],
  // Allows triggering of expanding row
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BookkeepingEvidenceBillingComponent implements OnInit {

  public authConfig = {
    bookKeepingInvoiceListCreate
  };

  // Main Table parameters
  public displayedColumns = ["CREATED_AT", "invId", "EXPENSE_DATE", "schedule", "pallets", "billingRate", "rate", "billTo", "client", "action"];
  public expandedElement = null; // Add interface for data element

  // For table inside expanded row
  public expandedRowCols = ["id", "description", "location", "arrival"];

  public searchValue: string = null;

  // Selected Values
  public selectedBillTo = null;
  public selectedClient = null;
  public selectedInvestigation = null;
  public selectedCreator = null;

  // Select Options Lists
  public billToList = [];
  public clients = [];
  public investigations = [];
  public creators = [];

  public selection = new SelectionModel(true);

  public filterOptions = IApiExpenseFilterType;

  private _dataSource: ExpenseDataSource;
  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public selectedTab = 0;
  public expenses: IApiExpense[] = [];
  public sort: SortDescriptor[] = [{
    field: 'CREATED_AT',
    dir: 'asc'
  }];

  constructor(
    private expenseService: ExpenseService,
    private investigationService: InvestigationService,
    private loader: LoaderService,
    private expenseItemService: ExpenseItemService,
    private dialogService: DialogService,
  ) { }

  public createInvoice(event: MouseEvent, expense: IApiExpense) {
    event.stopPropagation();
    // Need full investigation for modal
    // Find investigation, then replace expenses with evidence expense only
    this.investigationService.getById(expense.Investigation.id).pipe(
      switchMap((investigation) => {
        const dialog: DialogRef = this.dialogService.open({
          content: InvestigationTimeAndExpQuickbooksModalComponent,
          width: "90%",
          autoFocusedElement: 'invoiceNumber',
          preventAction: (ev) => {
            return ev !== ("closed" as any);
          },
        });
    
        const dialogInstance = dialog.content.instance as InvestigationTimeAndExpQuickbooksModalComponent;
        dialogInstance.data = {
          // Had to do this way because overwriting the expense with the row data was causing values to not be passed through
          investigation: { ...investigation, Expenses: [investigation.Expenses.find((obj) => obj.id === expense.id)] }
        }
        return dialog.result;
      }),
    ).subscribe(() => this.dataSource.load());
  }

  public filterTable(type: IApiExpenseFilterType, value: string) {
    this.dataSource.applyFilter(type, value);
    this.dataSource.load();
  }

  public tabsChanged(event: SelectEvent) {
    this.selectedTab = event.index;
    // Reset selection between tab changes
    this.selection.clear();
    if (event.index === 0) {
      this.dataSource.applyFilter(IApiExpenseFilterType.IsInvoiced, "false");
      this.dataSource.removeFilter(IApiExpenseFilterType.InvoicedDate);
    } else {
      this.dataSource.applyFilter(IApiExpenseFilterType.IsInvoiced, "true");
      this.dataSource.applyFilter(IApiExpenseFilterType.InvoicedDate, NefcoDateHelper.dateFilterString(dayjs().subtract(7, 'days').toDate(), dayjs().toDate()));
    }
    this.dataSource.load();
  }

  ngOnInit() {
    this._dataSource = new ExpenseDataSource(this.expenseService);
    this.loader.attachObservable(this.dataSource.loading$);
    this.expenseItemService.get([], { limit: -1, orderBy: IApiExpenseItemOrderBy.CreatedAt, sortOrder: SortOrder.DESC }).pipe(
      unwrapConnection(),
      map((items) => items.find(({ name }) => name.toLowerCase().includes('annual evidence storage')))
    ).subscribe((item) => {
      this.dataSource.applyFilter(IApiExpenseFilterType.EvidenceBillingItem, 'true');
      this.dataSource.applyFilter(IApiExpenseFilterType.ExpenseItem, item?.id);
      this.dataSource.applyFilter(IApiExpenseFilterType.IsInvoiced, "false");
      this.dataSource.load();
    });

    this.dataSource?.contents$.subscribe((res) => {
      this.expenses = res;
    });
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'CREATED_AT';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.dataSource.load();
  }

}
