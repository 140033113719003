<div [dialogRef]="dialog" (appClickOutside)="close()">
  <!-- Modal Header -->
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>{{data?.id ? 'Edit' : 'Add'}} Document Type</span>
    </div>
  </kendo-dialog-titlebar>
  <section>
    <form #form="ngForm">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Document Type"></kendo-label>
            <kendo-textbox name="documentType" #documentType="ngModel" [(ngModel)]="model.name"
              placeholder="Document Type" fillMode="outline" appNoWhiteSpace [clearButton]="false" [required]="true"
              cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror *ngIf="documentType?.invalid && (documentType?.dirty || documentType?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="documentType?.errors.required">Document Type
                cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Default Title"></kendo-label>
            <kendo-textbox name="defaultTitle" #defaultTitle="ngModel" [(ngModel)]="model.defaultTitle"
              placeholder="Default Title" fillMode="outline" appNoWhiteSpace [clearButton]="false" [required]="true"
              cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror *ngIf="defaultTitle?.invalid && (defaultTitle?.dirty || defaultTitle?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="defaultTitle?.errors.required">Default
                Title cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Category"></kendo-label>
            <kendo-dropdownlist [data]="documentCategories" name="category" #category="ngModel"
              [(ngModel)]="model.CategoryId" [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
              [defaultItem]="{id: null, name: 'Select Category'}"
              [popupSettings]="{appendTo: 'component', animate: false}" required>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!category?.valid && (category?.dirty || category?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="category?.errors?.required">
                Category cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="o365 Template"></kendo-label>
            <kendo-dropdownlist [data]="documentTemplates" name="template" #category="ngModel"
              [(ngModel)]="model.TemplateId" [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
              [defaultItem]="{id: null, name: 'Select Template'}"
              [popupSettings]="{appendTo: 'component', animate: false}">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item *ngIf="model.TemplateId === 'other'">
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Template Name"></kendo-label>
            <kendo-textbox name="templateName" #templateName="ngModel" [(ngModel)]="model.templateName"
              placeholder="Template Name" fillMode="outline" appNoWhiteSpace [clearButton]="false" [required]="true"
              cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror *ngIf="templateName?.invalid && (templateName?.dirty || templateName?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="templateName?.errors.required">Template name
                cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item *ngIf="model.TemplateId === 'other'">
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Template OneDrive Id"></kendo-label>
            <kendo-textbox name="templateOneDriveId" #templateOneDriveId="ngModel" [(ngModel)]="model.TemplateId"
              placeholder="Template OneDrive Id" fillMode="outline" appNoWhiteSpace [clearButton]="false"
              [required]="true" cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror
              *ngIf="templateOneDriveId?.invalid && (templateOneDriveId?.dirty || templateOneDriveId?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="templateOneDriveId?.errors.required">Template OneDrive Id
                cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [colSpan]="2">
          <kendo-formfield>
            <kendo-label text="Comments"></kendo-label>
            <kendo-textarea name="comments" #comments="ngModel" [(ngModel)]="model.notes" placeholder="Comments"
              fillMode="outline" class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
            <kendo-formerror *ngIf="comments?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="comments?.errors.required">Comments cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" [disabled]="!form.valid" class="mr-16"
      (click)="onSubmit()">
      {{ data?.id ? "Save" :
      "Insert" }}
    </button>
  </kendo-dialog-actions>
</div>
