import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { BookkeepingManagementComponent, BookkeepingPayrollEntryListComponent } from './';
import { BookkeepingExpenseCategoriesComponent } from './bookkeeping-expense-categories/bookkeeping-expense-categories.component';
import { BookkeepingExpenseItemsComponent } from './bookkeeping-expense-items/bookkeeping-expense-items.component';
import { BookkeepingExpensePurposesComponent } from './bookkeeping-expense-purposes/bookkeeping-expense-purposes.component';
import { BookkeepingTimeTypesComponent } from './bookkeeping-time-types/bookkeeping-time-types.component';
import { BookkeepingInvoiceListComponent } from './bookkeeping-invoice-list/bookkeeping-invoice-list.component';
import { BookkeepingPayrollManagementComponent } from './bookkeeping-payroll-management/bookkeeping-payroll-management.component';
import { BookkeepingPayrollReviewComponent } from './bookkeeping-payroll-review/bookkeeping-payroll-review.component';
import { BookkeepingExpenseReportsComponent } from './bookkeeping-expense-reports/bookkeeping-expense-reports.component';
import { BookkeepingEvidenceBillingComponent } from './bookkeeping-evidence-billing/bookkeeping-evidence-billing.component';
import { BookkeepingTimeAndExpensesComponent } from './bookkeeping-time-and-expenses/bookkeeping-time-and-expenses.component';
import { ExpenseCategoryModalComponent } from './expense-category-modal/expense-category-modal.component';
import { ExpenseItemModalComponent } from './expense-item-modal/expense-item-modal.component';
import { ExpensePurposeModalComponent } from './expense-purpose-modal/expense-purpose-modal.component';
import { TimeEntryTypeModalComponent } from './time-entry-type-modal/time-entry-type-modal.component';
import { UnlinkedEntryModalComponent } from "./bookkeeping-unlinked-entry/bookkeeping-unlinked-entry-modal.component";
import { BookkeepingExpenseReportFilterComponent } from './bookkeeping-expense-report-filter/bookkeeping-expense-report-filter.component';
import { BookkeepingExpenseReportsTabsComponent } from './bookkeeping-expense-reports/bookkeeping-expense-reports-tabs/bookkeeping-expense-reports-tabs.component';
import { BookkeepingPayrollFilterComponent } from './bookkeeping-payroll-filter/bookkeeping-payroll-filter.component';
import { TimeKendoCardComponent } from './time-kendo-card/time-kendo-card.component';


@NgModule({
  declarations: [
    BookkeepingPayrollEntryListComponent,
    TimeEntryTypeModalComponent,
    ExpenseCategoryModalComponent,
    ExpenseItemModalComponent,
    ExpensePurposeModalComponent,
    BookkeepingManagementComponent,
    BookkeepingExpenseCategoriesComponent,
    BookkeepingExpenseItemsComponent,
    BookkeepingExpensePurposesComponent,
    BookkeepingTimeTypesComponent,
    BookkeepingInvoiceListComponent,
    BookkeepingPayrollManagementComponent,
    BookkeepingPayrollReviewComponent,
    BookkeepingExpenseReportsComponent,
    BookkeepingEvidenceBillingComponent,
    BookkeepingTimeAndExpensesComponent,
    UnlinkedEntryModalComponent,
    BookkeepingExpenseReportFilterComponent,
    BookkeepingExpenseReportsTabsComponent,
    BookkeepingPayrollFilterComponent,
    TimeKendoCardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class BookkeepingModule { }
