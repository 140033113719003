<div [dialogRef]="dialog" (appClickOutside)="onCancelClick()">
  <kendo-dialog-titlebar (close)="onCancelClick()">
    <div class="modal-title">
      <span>{{data?.id ? 'Update' : 'Create'}} Permission Role</span>
    </div>
  </kendo-dialog-titlebar>
  
  <section>
    <form #frm="ngForm">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Role Name"></kendo-label>
            <kendo-textbox
              name="name"
              #name="ngModel"
              [(ngModel)]="role.name"
              placeholder="Role Name"
              fillMode="outline"
              appNoWhiteSpace
              [clearButton]="false"
              [required]="true"
              [minlength]="3"
              cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror *ngIf="name?.invalid && (name?.dirty || name?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="name?.errors.required">Role Name cannot be left blank</span>
              <span *ngIf="!name?.errors?.required && name?.errors?.minlength">Role Name should be minimum 3 characters.</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Default Time Entry Type"></kendo-label>
            <kendo-dropdownlist [data]="timeEntryTypes" name="type" #type="ngModel" [(ngModel)]="role.TimeEntryTypeId"
              [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
              [defaultItem]="{id: null, name: 'Select Default Time Entry Type'}" 
              [popupSettings]="{appendTo: 'component', animate: false}">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!type?.valid && (type?.dirty || type?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="type?.errors?.required">
                Default Time Entry Type cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </section>

  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="onCancelClick()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSubmit()">
      Save
    </button>
  </kendo-dialog-actions>
</div>