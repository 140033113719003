import { IApiAddPhotoThumbnailInput, IApiPhotoThumbnail, IApiUpdatePhotoThumbnailInput, IApiPhotoThumbnailFilter } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlPhotoThumbnailService } from "src/app/shared/modules/graphql/services";

@Injectable()
export class PhotoThumbnailService {

  constructor(
    private photoThumbnails: GqlPhotoThumbnailService,
  ) { }

  public get(filters: IApiPhotoThumbnailFilter[] = [], options = {}): Observable<IApiPhotoThumbnail[]> {
    return this.photoThumbnails.getPhotoThumbnails(filters, options);
  }

  public add(input: IApiAddPhotoThumbnailInput): Observable<IApiPhotoThumbnail> {
    return this.photoThumbnails.addPhotoThumbnail(input);
  }

  public upload(fileInput: File, input: IApiAddPhotoThumbnailInput): Observable<IApiPhotoThumbnail> {
    return this.photoThumbnails.uploadPhotoThumbnail(fileInput, input);
  }

  public update(input: IApiUpdatePhotoThumbnailInput): Observable<IApiPhotoThumbnail> {
    return this.photoThumbnails.updatePhotoThumbnail(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.photoThumbnails.removePhotoThumbnail(id);
  }
}
