import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiInvestigationMetricsDeterminationConnection, IApiInvestigationMetricsFilter } from 'src/app/shared/modules/graphql/types/types';
import { GqlInvestigationMetricsDeterminationService } from 'src/app/shared/modules/graphql/services';

@Injectable()
export class InvestigationMetricsDeterminationService {
  constructor(
    private reports: GqlInvestigationMetricsDeterminationService
  ) { }

  public get(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationMetricsDeterminationConnection> {
    return this.reports.get(filters, options || new ListPage());
  }

}
