import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { GqlCompanyService } from '../../modules/graphql/services/company/company.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IApiAddCompanyInput, IApiCompaniesConnection, IApiCompany, IApiCompanyFilter, IApiUpdateCompanyInput } from '../../modules/graphql/types/types';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces/base.interfaces';

@Injectable()
export class CompanyService {

  constructor(
    private companyService: GqlCompanyService
  ) {}

  public get(filters: IApiCompanyFilter[] = [], options?: IGqlBaseOptions): Observable<IApiCompaniesConnection> {
    return this.companyService.getCompanies(filters, options || new ListPage(-1));
  }

  public getById(id: string): Observable<IApiCompany> {
    return this.companyService.getCompanyById(id);
  }

  public add(input: IApiAddCompanyInput): Observable<IApiCompany> {
    return this.companyService.addCompany(input);
  }

  public update(input: IApiUpdateCompanyInput): Observable<IApiCompany> {
    return this.companyService.updateCompany(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.companyService.removeCompany(id);
  }

}
