import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";
import { IApiPhotoFolderFilterType } from '../../../types/types';

const nodeFragment = `
  id
  name
  createdAt
  Photos {
    id
    uri
    s3Uri
    lowResUri
    lowResS3Uri
    width
    height
    fileSizeKB
    fileName
    caption
    isEvidence
    isUsed
    position
    createdAt
  }
  Investigation {
    id
    nefcoNumber
  }
  User {
    id
  }
  SubService {
    id
  }
`;

const viewListFragment = `
  id
  name
  createdAt
  PhotoCount
`;

export const photoFolderConnectionQuery = (view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiPhotoFolderFilterType.ViewList:
        return gql`${buildConnectionQuery("PhotoFolder", "photoFolderConnection", viewListFragment)}`;

      default:
        return  gql`${buildConnectionQuery("PhotoFolder", "photoFolderConnection", nodeFragment)}`;
    }
  } else {
    return  gql`${buildConnectionQuery("PhotoFolder", "photoFolderConnection", nodeFragment)}`;
  }
};


export const addPhotoFolderMutation = gql`mutation AddPhotoFolder($input: AddPhotoFolderInput!) {
  addPhotoFolder(input: $input) { ${ nodeFragment } }
}`;

export const updatePhotoFolderMutation = gql`mutation UpdatePhotoFolder($input: UpdatePhotoFolderInput!) {
  updatePhotoFolder(input: $input) { ${ nodeFragment } }
}`;

export const removePhotoFolderMutation = gql`mutation RemovePhotoFolder($id: String) {
  removePhotoFolder(id: $id)
}`;
