import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { updateEvidenceTransferMutation } from './evidence-transfer.operations';
import {
  IApiEvidenceTransferFilter,
  IApiAddEvidenceTransferInput,
  IApiEvidenceTransferConnection,
  IApiUpdateEvidenceTransferInput,
  IApiRemoveEvidenceTransferInput
} from 'src/app/shared/modules/graphql/types/types';
import {
  evidenceTransferConnectionQuery,
  addEvidenceTransferMutation,
  removeEvidenceTransferMutation
} from './evidence-transfer.operations';

@Injectable()
export class GqlEvidenceTransferService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getEvidenceTransfer(filters: IApiEvidenceTransferFilter[], options: IGqlBaseOptions): Observable<IApiEvidenceTransferConnection> {
    return this.apollo.query(connectionQuery(evidenceTransferConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addEvidenceTransfer(input: IApiAddEvidenceTransferInput) {
    return this.apollo.mutate({
      mutation: addEvidenceTransferMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeEvidenceTransfer(input: IApiRemoveEvidenceTransferInput) {
    return this.apollo.mutate({
      mutation: removeEvidenceTransferMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateEvidenceTransfer(input: IApiUpdateEvidenceTransferInput) {
    return this.apollo.mutate({
      mutation: updateEvidenceTransferMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
