import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { SafeArea } from 'capacitor-plugin-safe-area';

export enum TABS {
  "BASIC" = "BASIC",
  "STATE" = "STATE"
};

@Component({
  selector: 'app-mobile-investigation-metrics-tabs',
  templateUrl: './mobile-investigation-metrics-tabs.component.html',
  styleUrls: ['./mobile-investigation-metrics-tabs.component.scss']
})
export class MobileInvestigationMetricsTabsComponent implements OnInit, AfterViewInit {

  public selectedTab = TABS.BASIC as string;
  public TABS = TABS;

  constructor() { }

  ngOnInit(): void {
    console.log();
  }

  public ngAfterViewInit(): void {
    this.setSafeAreaView();
  }

  public setSafeAreaView() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (Capacitor.getPlatform() === 'android') {
        /* investigation-metrics */
        const element = document.getElementById('investigation-metrics');
        element.style.top = `${(16 + 38 + 16 + 1)}px`;
        element.style.position = `relative`;
      } else if (Capacitor.getPlatform() === 'ios') {
        /* investigation-metrics */
        const element = document.getElementById('investigation-metrics')
        element.style.top = `${(insets.top + 38 + 16 + 1)}px`;
        element.style.position = `relative`;
      } else {
        /* search header */
        const element = document.getElementById('investigation-metrics')
        element.style.top = `${(16 + 38 + 16 + 1)}px`;
        element.style.position = `relative`;
      }
    });
  }

  public changeTab(event: SelectEvent | null, scroll = true) {
    if (scroll) window?.scrollTo(0, 0);
    this.selectedTab = event?.title || this.selectedTab;
    switch (this.selectedTab) {
      case TABS.BASIC:
        break;
      case TABS.STATE:
        break;
      default:
        break;
    }
  }
}
