import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { RiskTypesService } from 'src/app/shared/services';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IApiRiskType } from "../../modules/graphql/types/types";

@UntilDestroy()
@Component({
  selector: 'app-risk-types-select',
  templateUrl: './risk-types.component.html',
  styleUrls: ['./risk-types.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RiskTypesComponent),
      multi: true
    }
  ]
})
export class RiskTypesComponent implements OnInit, ControlValueAccessor {

  @Input() riskTypeId = '';
  @Input() isMat = true;
  public riskTypes: IApiRiskType[];

  constructor(private riskTypeService: RiskTypesService) { }

  ngOnInit() {
    this.riskTypeService.riskTypes().pipe(
      untilDestroyed(this)
    ).subscribe((riskTypes) => this.riskTypes = riskTypes);
  }

  public handleSelectionChange(event) {
    this.riskTypeId = this.isMat ? event.value : event;
    this._propagateChange(this.riskTypeId);
  }

  private _propagateChange = (_: any) => { };

  // Control Value accessor members
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.riskTypeId = value;
    }
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }

}
