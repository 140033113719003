import { IApiSettings, IApiSettingsConnection, IApiSettingsFilter } from '../../modules/graphql/types/types';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { SettingsService } from "./settings.service";
import { NefcoPagedDataSource } from '../../helpers/nefco-data-source.class';

export class SettingsDataSource extends NefcoPagedDataSource<IApiSettings, SettingsService, IApiSettingsConnection> implements DataSource<IApiSettings> {
  @ApiConnection()
  public load(filters: IApiSettingsFilter[] = []): Promise<IApiSettingsConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
