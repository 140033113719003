import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, EMPTY, Observable, of } from 'rxjs';
import { share, scan, catchError, filter } from 'rxjs/operators';

type AccumulatorFunc<T = any> = (currentState: T) => T;

export enum StatePersistTypes {
  NONE,
  SESSION,
  FOREVER
}

export interface IStateManagerModel<T> {
  accumulator: Subject<AccumulatorFunc<T>>;
  value: ReplaySubject<T>;
  storage: Storage | null;
}

interface IStateManagerModelCollection {
  [name: string]: IStateManagerModel<any>;
}

@Injectable({
  providedIn: 'root'
})
export class StateManagerService {
  private _models: IStateManagerModelCollection = {};

  public create<T = any>(modelName: string, modelValue: T = null, persist: StatePersistTypes = StatePersistTypes.NONE, overwrite = true): ReplaySubject<T> {
    if (this._models[modelName] && !overwrite) throw new Error('duplicate model name');

    let startVal = modelValue;
    let storage = null;

    if (persist) {
      storage = persist === StatePersistTypes.SESSION ? sessionStorage : localStorage;
      startVal = JSON.parse(storage.getItem(modelName)) || startVal;
    }

    const newModel: IStateManagerModel<T> = {
      accumulator: new Subject<AccumulatorFunc<T>>(),
      value: new ReplaySubject<T>(1),
      storage
    };

    newModel.accumulator.pipe(
      // keep listening for emissions and combine into a state object
      scan((currentState: T, actionMethod: AccumulatorFunc<T>) => ({ ...currentState, ...actionMethod(currentState) }), startVal),
      // new subscribers get last emitted accumulated state
      share(),
      catchError(err => {
        console.error(err);
        return EMPTY;
      }),
    ).subscribe(state => {
      newModel.value.next(state);
      if (persist) newModel.storage.setItem(modelName, JSON.stringify(state));
    });

    this._models[modelName] = newModel;

    // optionally trigger the first value
    if (modelValue) this.update(modelName, () => modelValue);

    return this.getModel<T>(modelName).value;
  }

  private getModel<T>(modelName: string): IStateManagerModel<T> {
    if (!this._models[modelName]) throw new Error('no model by that name exists');
    return this._models[modelName];
  }

  public value<T>(modelName: string): Observable<T | null> {
    return this.getModel<T>(modelName).value.pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      }),
      filter(model => model !== undefined)
    );
  }

  public hasModel(modelName: string): boolean {
    try {
      return !!this.getModel(modelName);
    }
    catch (exc) {
      return false;
    }
  }

  public delete(modelNames: string | string[] | 'all' = 'all'): void {
    if (Array.isArray(modelNames)) {
      modelNames.forEach(modelName => this.delete(modelName));
    }
    else if (modelNames === 'all') {
      this.delete(Object.keys(this._models));
    }
    else {
      const { storage = null } = this._models[modelNames];
      if (storage) storage.removeItem(modelNames);

      delete this._models[modelNames];
    }
  }

  public update(modelName: string, action: AccumulatorFunc): void {
    this.getModel(modelName).accumulator.next(action);
  }
}
