import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  notes
  isVisible
  isComplete
  Type {
    id
    name
  }
  AssignedUser {
    id
    firstName
    lastName
  }
  Service {
    id
  }
  createdAt
  updatedAt
`;

export const subServicesConnectionQuery = gql`${buildConnectionQuery("SubService", "subServicesConnection", nodeFragment)}`;

export const addSubServiceMutation = gql`mutation createSubService($input: AddSubServiceInput!) {
  addSubService(input: $input) { ${nodeFragment} }
  }`;

export const removeSubServiceMutation = gql`mutation removeSubService($id: ID!) {
  removeSubService(id: $id)
}`;

export const updateSubServiceMutation = gql`mutation updateSubService($input: UpdateSubServiceInput!) {
  updateSubService(input: $input) { ${nodeFragment} }
}`;

export const getSubServiceByIdQuery = gql`query getSubServiceById($id: ID!) {
  getSubServiceById(id: $id) {${nodeFragment}}
}`;
