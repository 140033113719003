import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TimeEntryService, TimeEntryTypeService } from 'src/app/shared/services';
import { UserService } from '../../../../shared/services/user/user.service';
import { IPayPeriod } from '../../../../shared/interfaces/time-entry.interfaces';
import { UserDataSource } from 'src/app/shared/services/user';
import { IApiTimeEntryFilterType, IApiUser, IApiUserFilterType, IApiUserOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import dayjs from 'dayjs';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { SelectionModel } from '@angular/cdk/collections';
import { IPayRollDay } from '../time-kendo-card/time-kendo-card.component';

@UntilDestroy()
@Component({
  selector: 'app-bookkeeping-payroll-review',
  templateUrl: './bookkeeping-payroll-review.component.html',
  styleUrls: ['./bookkeeping-payroll-review.component.scss']
})
export class BookkeepingPayrollReviewComponent implements OnInit {
  // For child component for "select all"
  private _selectAllEntries: boolean;
  @Input() set selectAllEntries(val) {
    this._selectAllEntries = val;
    this._selectAllEntriesChanged.emit(this._selectAllEntries);
  }
  get selectAllEntries() {
    return this._selectAllEntries;
  }

  // For this component
  @Output() payTimeEntries = new EventEmitter<IPayRollDay>();
  @Output() selectReset = new EventEmitter<boolean>();
  @Output() _selectAllEntriesChanged = new EventEmitter<boolean>();

  private pageLoaded = false;

  private _users: IApiUser[];

  public get users() {
    return this._users;
  }

  private _dataSource: UserDataSource;

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  private _payPeriod: IPayPeriod;
  @Input() set payPeriod(val) {
    this._payPeriod = val;
    this.paginationReset();
    this.load();
  }

  get payPeriod() {
    return this._payPeriod;
  }

  private _userId: string;
  @Input() set userId(val) {
    this._userId = val;
    this.paginationReset();
    this.load();
  }

  get userId() {
    return this._userId;
  }

  private _reload: Date;
  @Input() set reload(val) {
    this._reload = val;
    this.load();
  }

  get reload() {
    return this._reload;
  }

  public selectAll = false;

  public toggleSelected(event) {
    this.payTimeEntries.emit(event);
    event.selected ? this.selectedTimeEntries.select(event) : this.selectedTimeEntries.deselect(event);
  }

  public selectResetEvent() {
    this.selectReset.emit(true);
  }

  private paginationReset() {
    this._dataSource.pagingReset();
  }

  private getPayPeriod(date: Date) {
    return NefcoDateHelper.payPeriodbyPaidDate(date);
  }

  // Time Totals
  private _timeTotals = [];
  public get timeTotals() {
    return this._timeTotals;
  }
  public selectedTimeEntries = new SelectionModel<IPayRollDay>(true, []);
  public showViewTotalDetails = false;

  constructor(
    private timeEntryService: TimeEntryService,
    private userService: UserService,
    private loader: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    // Paginate Users
    this._dataSource = new UserDataSource(this.userService);
    this.loader.attachObservable(this._dataSource.loading$);
    this.dataSource.applyFilter(IApiUserFilterType.IsInactive, 'true');
    this.dataSource.applyFilter(IApiUserFilterType.ViewPayrollReport, 'true');
    this.dataSource.listPage.orderBy = IApiUserOrderBy.Lastname;
  }

  ngOnInit() {
    this.dataSource.contents$.pipe(untilDestroyed(this)).subscribe((data: unknown) => {
      this._users = data as IApiUser[];
    });
    this.route.queryParams.pipe(
      untilDestroyed(this),
      take(1),
    ).subscribe((params) => {
      const {
        offset = 0,
        page = 0,
        limit = 5,
        date = null
      } = params;

      this.pageOptions.limit = parseInt(limit, 10);
      this.pageOptions.offset = parseInt(offset, 10);
      this.pageOptions.currentPageIndex = parseInt(page, 10);

      const foundPayPeriod = this.getPayPeriod(date ? new Date(date) : new Date(this.payPeriod.payDate));
      this.dataSource.applyFilter(IApiUserFilterType.Payroll, `${foundPayPeriod.start},${foundPayPeriod.end},${foundPayPeriod.payDate}`);

      this.dataSource.load().then(() => {
        this.calculateTotals();
      });
    });

    this.pageLoaded = true;
  }

  public queryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        offset: this.pageOptions.offset,
        limit: this.pageOptions.limit,
        page: this.pageOptions.currentPageIndex,
        date: this.payPeriod.payDate.toISOString()
      },
      queryParamsHandling: 'merge'
    });
  }

  private load() {
    // This prevents multiple loads as page is initiating and wiping out query params
    if (!this.pageLoaded) return;
    if (this.payPeriod) this.dataSource.applyFilter(IApiUserFilterType.Payroll, `${this.payPeriod.start},${this.payPeriod.end},${this.payPeriod.payDate}`);
    this.userId ? this.dataSource.applyFilter(IApiUserFilterType.UserId, this.userId) : this.dataSource.removeFilter(IApiUserFilterType.UserId);
    this.queryParams();
    this.dataSource.load().then(() => {
      this.calculateTotals();
    });
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public get overallTimeTotal() {
    if (this._timeTotals.length) {
      return this._timeTotals.reduce((prev, curr) => {
        prev.hoursPaid += curr.hoursPaid;
        prev.hoursUnpaid += curr.hoursUnpaid;
        prev.hours += curr.hours;
        return prev;
      }, { hoursPaid: 0, hoursUnpaid: 0, hours: 0 });
    }
    else return {};
  }

  public get getSelectedTotal(): any {
    if (this.selectedTimeEntries.selected.length) {
      return this.selectedTimeEntries.selected.reduce((prev, curr) => {
        prev.totalPaid += curr.totalPaid;
        prev.totalUnpaid += curr.totalUnpaid;
        return prev;
      }, { totalPaid: 0, totalUnpaid: 0 });
    } else {
      return {};
    }
  }

  private calculateTotals() {

    const timeEntryFilters = [];
    const results = [];

    // Apply needed filters
    if (this.payPeriod) timeEntryFilters.push({ type: IApiTimeEntryFilterType.Payroll, value: `${this.payPeriod.start},${this.payPeriod.end},${this.payPeriod.payDate}` });
    if (this.userId) timeEntryFilters.push({ type: IApiTimeEntryFilterType.Employee, value: this.userId });
    timeEntryFilters.push({ type: IApiTimeEntryFilterType.ViewPayrollTotal, value: "true" });
    timeEntryFilters.push({ type: IApiTimeEntryFilterType.PayRollPaidDateLocal, value: dayjs(this.payPeriod.payDate).format('MM/DD/YYYY') });
    // Grab the data
    this.loader.show$(this.timeEntryService.geTtimeEntryTotalByType(timeEntryFilters, { sortOrder: SortOrder.ASC, limit: -1 })).subscribe((entries) => {
      this._timeTotals = Object.values(entries?.summary);
    });
  }

  public viewTotalDetailsOpen(open: boolean): void {
    this.showViewTotalDetails = open;
    if (this.showViewTotalDetails) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }
}
