
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';

import { GqlInvestigationStatusService } from 'src/app/shared/modules/graphql/services';
import {
  IApiAddInvestigationStatusInput,
  IApiInvestigationStatusFilter,
  IApiUpdateInvestigationStatusInput,
  IApiRemoveInvestigationStatusInput,
  IApiInvestigationStatus,
  IApiInvestigationStatusNames
} from 'src/app/shared/modules/graphql/types/types';
import { switchMap, first } from 'rxjs/operators';
import { IApiInvestigationStatusConnection } from '../../../modules/graphql/types/types';


@Injectable()
export class InvestigationStatusService {

  constructor(
    private gqlInvestigationStatusService: GqlInvestigationStatusService
  ) { }

  // public statuses(): Observable<IApiInvestigationStatus[]> {
  //   return this.gqlInvestigationStatusService.statuses$;
  // }

  // public statusByName(name: IApiInvestigationStatusNames): Observable<IApiInvestigationStatus> {
  //   return this.statuses().pipe(
  //     // switchMap to stream of statuses
  //     switchMap(statuses => from(statuses)),
  //     first(status => status.name === name),
  //   );
  // }

  public getInvestigationStatus(filters: IApiInvestigationStatusFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationStatusConnection> {
    return this.gqlInvestigationStatusService.getInvestigationStatus(filters, options);
  }

  public addInvestigationStatus(input: IApiAddInvestigationStatusInput) {
    return this.gqlInvestigationStatusService.addInvestigationStatus(input);
  }

  public removeInvestigationStatus(input: IApiRemoveInvestigationStatusInput) {
    return this.gqlInvestigationStatusService.removeInvestigationStatus(input);
  }

  public updateInvestigationStatus(input: IApiUpdateInvestigationStatusInput) {
    return this.gqlInvestigationStatusService.updateInvestigationStatus(input);
  }

}
