import { INefcoPagedDataSource } from './nefco-data-source.interfaces';
import { ListPage } from './list-page.class';
import { startWith, map, tap } from 'rxjs/operators';
import { DataSource } from "@angular/cdk/collections";
import { Observable, ReplaySubject } from "rxjs";
import { IApiPageInfo } from "../modules/graphql/types/types";

export abstract class NefcoDataSource<T> implements DataSource<T> {

  private loadingSubject = new ReplaySubject<boolean>(1);
  protected dataSubject = new ReplaySubject<T[]>(1);
  protected pageInfoSubject = new ReplaySubject<{ totalCount: number, info: IApiPageInfo }>(1);

  private _isLoading = false;

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public set isLoading(value: boolean) {
    this._isLoading = value;
    this.loadingSubject.next(value);
  }

  public set data(value: T[]) {
    this.dataSubject.next(value);
  }

  public get contents$(): Observable<T[]> {
    return this.dataSubject.pipe(
      this._transform ? map(data => this._transform(data)) : tap()
    );
  }

  public get pageInfo$() {
    return this.pageInfoSubject.pipe(
      startWith({
        totalCount: 0,
        info: {
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: '',
          endCursor: ''
        }
      })
    );
  }

  public get loading$() {
    return this.loadingSubject;
  }

  private _transform: (results: T[]) => any[] = null;

  constructor(transform?: (results: T[]) => any[]) {
    if (transform) this._transform = transform;
  }

  connect(): Observable<T[]> {
    return this.contents$.pipe(
      startWith([])
    );
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.pageInfoSubject.complete();
    this.loadingSubject.complete();
  }
}

export abstract class NefcoPagedDataSource<T, A = any, B = any> extends NefcoDataSource<T> implements INefcoPagedDataSource {
  public service: A;
  protected _listPage: ListPage;
  public get listPage() {
    return this._listPage;
  }

  protected _lastFilters: any[] = [];
  public get lastFilters() {
    return this._lastFilters;
  }

  constructor(public _service: A, transform?: (results: T[]) => any[]) {
    super(transform);

    this.service = _service;

    this._listPage = new ListPage();

    this.pageInfo$.subscribe(p => {
      this.listPage.totalCount = p.totalCount;
      this.listPage.endCursor = p.info.endCursor;
      this.listPage.startCursor = p.info.startCursor;
    });

    this.listPage.paginate$.subscribe(() => this.load());
  }

  public applyFilter(filterType: any, value: string | null = null): void {
    this.removeFilter(filterType);
    if (value !== null) this._lastFilters.push({ type: filterType, value: value?.trim() });
  }

  public removeFilter(filterType: any): void {
    this._lastFilters = (this._lastFilters || []).filter(f => f.type !== filterType);
  }

  public load(filters?: any[]): Promise<B | any> {
    // retain for future calls w/o filters ^^
    if (filters) this._lastFilters = filters;

    return Promise.resolve();
  }

  public pagingReset() {
    this.listPage.currentPageIndex = 0;
  }

  public updateTotalCount(value: number) {
    this.listPage.totalCount = value;
  }
}
