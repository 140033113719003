import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "src/app/shared/shared.module";
import { DashboardModalModule } from "../dashboard-modal/dashboard-modal.module";

import {
  DashboardTabsComponent,
  DashboardReportsTableComponent,
  DashboardRequestsTableComponent,
  DashboardInvestigatorsTableComponent,
  DashboardAssignedTableComponent,
} from "./index";


@NgModule({
  declarations: [
    DashboardTabsComponent,
    DashboardReportsTableComponent,
    DashboardRequestsTableComponent,
    DashboardInvestigatorsTableComponent,
    DashboardAssignedTableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModalModule,
  ],
})
export class DashboardModule { }
