import { IApiEvidenceStatusConnection } from './../../../modules/graphql/types/types';
import { GqlEvidenceStatusService } from 'src/app/shared/modules/graphql/services/evidence/evidence-status/evidence-status.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import {
  IApiAddEvidenceStatusInput,
  IApiEvidenceStatusFilter,
  IApiUpdateEvidenceStatusInput,
  IApiRemoveEvidenceStatusInput
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class EvidenceStatusService {

  constructor(
    private gqlEvidenceStatusService: GqlEvidenceStatusService
  ) { }

  public get(filters?: IApiEvidenceStatusFilter[], options?: IGqlBaseOptions): Observable<IApiEvidenceStatusConnection> {
    return this.gqlEvidenceStatusService.getEvidenceStatus(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddEvidenceStatusInput) {
    return this.gqlEvidenceStatusService.addEvidenceStatus(input);
  }

  public remove(input: IApiRemoveEvidenceStatusInput) {
    return this.gqlEvidenceStatusService.removeEvidenceStatus(input);
  }

  public update(input: IApiUpdateEvidenceStatusInput) {
    return this.gqlEvidenceStatusService.updateEvidenceStatus(input);
  }

}
