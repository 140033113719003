import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
id
uri
scale
fileSizeKB
Photo {
  id
  uri
  width
  height
  fileSizeKB
  fileName
  caption
  isEvidence
  Folder {
    id
    name
  }
}`;

export const photoThumbnailConnectionQuery = gql`${buildConnectionQuery("PhotoThumbnail", "photoThumbnailConnection", nodeFragment)}`;

export const addPhotoThumbnailMutation = gql`mutation AddPhotoThumbnail($input: AddPhotoThumbnailInput!) {
  addPhotoThumbnail(input: $input) { ${ nodeFragment } }
}`;

export const uploadPhotoThumbnailMutation = gql`mutation UploadPhotoThumbnail($fileInput: Upload!, $input: AddPhotoThumbnailInput) {
  uploadPhotoThumbnail(fileInput: $fileInput, input: $input)
}`;

export const updatePhotoThumbnailMutation = gql`mutation UpdatePhotoThumbnail($input: UpdatePhotoThumbnailInput!) {
  updatePhotoThumbnail(input: $input) { ${ nodeFragment } }
}`;

export const removePhotoThumbnailMutation = gql`mutation RemovePhotoThumbnail($id: String) {
  removePhotoThumbnail(id: $id)
}`;