import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiSubServiceTypesConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  subServiceTypesConnectionQuery, addSubServiceTypeMutation,
  removeSubServiceTypeMutation, getSubServiceTypeByIdQuery,
  updateSubServiceTypeMutation
} from './sub-service-type.operations';
import {
  IApiSubServiceTypeFilter, IApiAddSubServiceTypeInput, IApiUpdateSubServiceTypeInput,
  IApiSubServiceType
} from 'src/app/shared/modules/graphql/types/types';
import { subServicesConnectionQuery } from "../sub-service/sub-service.operations";

@Injectable()
export class GqlSubServiceTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiSubServiceTypeFilter[], options: IGqlBaseOptions): Observable<IApiSubServiceTypesConnection> {
    return this.apollo.query(connectionQuery(subServicesConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddSubServiceTypeInput): Observable<IApiSubServiceType> {
    return this.apollo.mutate({
      mutation: addSubServiceTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeSubServiceTypeMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateSubServiceTypeInput): Observable<IApiSubServiceType> {
    return this.apollo.mutate({
      mutation: updateSubServiceTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getSubServiceTypeById(id: string): Observable<IApiSubServiceType> {
    return this.apollo.query({
      query: getSubServiceTypeByIdQuery,
      variables: { id: id }
    }).pipe(
      safeResolve(true),
    );
  }
}
