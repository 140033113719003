import { IApiPermissionCategory } from './../../types/types';
import { PermissionCategoriesQuery } from './permissions.operations';

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { safeResolve } from "../../rxjs";

@Injectable()
export class GqlPermissionCategoryService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getPermissionCategories(): Observable<IApiPermissionCategory[]> 
  {
    return this.apollo.query({
      query: PermissionCategoriesQuery,
      variables: {
        last: -1
      }
    }).pipe(
      safeResolve(true),
      map(records => records.sort((a, b) => a.name < b.name ? -1 : 1))
    );
  }
}