import { Component, Input, OnInit } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { keyDescOrder } from 'src/app/shared/helpers/helper';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { InvestigationMetricsTechReviewerService } from 'src/app/shared/services';
import { InvestigationMetricsTechReviewerDatasource } from 'src/app/shared/services/reports/investigation-metrics/investigation-metrics-tech-reviewer/investigation-metrics-tech-reviewer.datasource';

@Component({
  selector: 'app-investigation-metrics-tech-reviewer',
  templateUrl: './investigation-metrics-tech-reviewer.component.html',
  styleUrls: ['./investigation-metrics-tech-reviewer.component.scss']
})
export class InvestigationMetricsTechReviewerComponent implements OnInit {

  private _dataSource: InvestigationMetricsTechReviewerDatasource;
  public set dataSource(val) {
    this._dataSource = val;
  }
  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public sort: SortDescriptor[] = [{
    field: IApiInvestigationMetricsOrderBy.UserName,
    dir: 'asc'
  }];

  public tableData = [];
  public keyDescOrder = keyDescOrder;

  @Input() tabIndex;

  constructor(
    private loaderService: LoaderService,
    private investigationMetricsInvestigationService: InvestigationMetricsTechReviewerService
  ) { }

  ngOnInit(): void {
    this.loaderService.isMatLoader = false;
    this.dataSource = new InvestigationMetricsTechReviewerDatasource(this.investigationMetricsInvestigationService);
    // Table Initialization / Setup
    this.loaderService.attachObservable(this.dataSource.loading$);

    this.dataSource?.contents$.subscribe((res) => {
      this.tableData = res;
      this.tableData = this.tableData?.map((row) => {
        return {
          ...row,
          data: JSON.parse(row?.data)
        }
      })
    });
    this.dataSource.listPage.orderBy = IApiInvestigationMetricsOrderBy.UserName;
    this.load();
  }

  public load() {
    this.dataSource.load();
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiInvestigationMetricsOrderBy.UserName;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  };

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  applyFilter(filters: IApiInvestigationMetricsFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    filters?.map(filter => this.dataSource.applyFilter(filter?.type, filter?.value));
    this.dataSource.pagingReset();
    this.load();
  }

  clearFilter(filters: IApiInvestigationMetricsFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.pagingReset();
    this.load();
  }


}
