import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import gql from 'graphql-tag';

export const connectionInputDefs =
  `$first: Int,
  $last: Int,
  $before: ID,
  $after: ID,
  $sortOrder: SortOrder`;

export function buildConnectionOperation(connectionName: string, dataNodeTemplate: string) {
  return `${connectionName}(
    first: $first
    last: $last
    before: $before
    after: $after
    filters: $filters
    orderBy: $orderBy
    sortOrder: $sortOrder
  ) {
    pageInfo {
      limit
      offset
    }
    totalCount
    edges {
      cursor
      node { ${dataNodeTemplate} }
    }
  }`;
}

export function buildConnectionQuery(prefix, connectionName, dataNodeTemplate: string): any {
  return `query ${prefix}Connection(
  $filters: [${prefix}Filter],
  $orderBy: ${prefix}OrderBy,
  ${connectionInputDefs}
  ) {
    ${buildConnectionOperation(connectionName, dataNodeTemplate)}
  }`;
}

export function buildWithoutConnectionQuery(prefix, dbPrefix, connectionName, dataNodeTemplate: string): any {
  return `query ${prefix}(
  $filters: [${dbPrefix}Filter],
  $orderBy: ${dbPrefix}OrderBy,
  ${connectionInputDefs}
  ) {
    ${buildConnectionOperation(connectionName, dataNodeTemplate)}
  }`;
}

export function buildWithoutConnectionQueryAndReturn(prefix, dbPrefix, connectionName): any {
  return `query ${prefix}(
  $filters: [${dbPrefix}Filter],
  $orderBy: ${dbPrefix}OrderBy,
  ${connectionInputDefs}
  ) {
    ${connectionName}(
      first: $first
      last: $last
      before: $before
      after: $after
      filters: $filters
      orderBy: $orderBy
      sortOrder: $sortOrder
    )
  }`;
}

export const connectionQuery = (queryTemplate, filters: any[], options: IGqlBaseOptions): any => (
  {
    query: queryTemplate,
    variables: {
      filters,
      orderBy: options.orderBy,
      sortOrder: options.sortOrder,
      first: options.offset || 0,
      last: options.limit || 25,
      before: options.before,
      after: options.after
    }
  }
);
