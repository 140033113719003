import { AfterViewInit, Component, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { DateRangePopupComponent, DateRangeService } from '@progress/kendo-angular-dateinputs';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsFilterType, IApiInvestigationRoleNames, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { isArray } from 'lodash';

@Component({
  selector: 'app-investigation-metrics-filters',
  templateUrl: './investigation-metrics-filters.component.html',
  styleUrls: ['./investigation-metrics-filters.component.scss']
})
export class InvestigationMetricsFiltersComponent implements AfterViewInit {
  @ViewChild('startDateRange', { static: false }) startDateRange: any;
  @ViewChild('endDateRange', { static: false }) endDateRange: any;
  @ViewChild("dateRangePicker", { read: DateRangeService }) public service: DateRangeService;
  @ViewChild("endDateInput") endDateInput: any;
  @ViewChild("startDateInput") startDateInput: any;
  @ViewChild("dateRangePickerPopup", { static: true }) public dateRangePickerPopup: DateRangePopupComponent;

  @Output() clearFilter = new EventEmitter<IApiInvestigationMetricsFilter[]>();
  @Output() applyFilter = new EventEmitter<IApiInvestigationMetricsFilter[]>();
  @Input() investigationFilter: boolean = false;
  @Input() editorFilter: boolean = false;

  private _filters: IApiInvestigationMetricsFilter[] = [];
  public filterTypes = IApiInvestigationMetricsFilterType;

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  public range = { startDate: null, endDate: null };
  public userRoleName = IApiInvestigationRoleNames;
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public showAllFilters = false;
  constructor(
  ) { }

  ngAfterViewInit() {
    this.service.activeRangeEnd$.subscribe((data) => {
      if (data === "start") {
        setTimeout(() => {
          if (this.range.startDate) {
            const dates = {
              startDate: NefcoDateHelper.toUtcStartOfDay(this.range.startDate, true),
              endDate: NefcoDateHelper.toUtcStartOfDay(this.range.endDate, false),
            };
            this.setFilters(
              JSON.stringify({
                startDate: dates.startDate,
                endDate: dates.endDate,
              }),
              IApiInvestigationMetricsFilterType.ReceivedDate
            );
            this.dateRangePickerPopup.toggle(false);
          }
        }, 100);
      }
    });
  }

  public updateDateFilters() {
    const startDate = this.startDateInput?.value;
    const endDate = this.endDateInput?.value;

    if (startDate && endDate) {
      const dates = {
        startDate: NefcoDateHelper.toUtcStartOfDay(startDate, true),
        endDate: NefcoDateHelper.toUtcStartOfDay(endDate, false),
      };
      this.setFilters(
        JSON.stringify({
          startDate: dates.startDate,
          endDate: dates.endDate,
        }),
        IApiInvestigationMetricsFilterType.ReceivedDate
      );
    }
  }

  public focusDateInput(start = true) {
    if (this.startDateRange && start) {
      setTimeout(() => {
        this.startDateRange?.focus();
      }, 1);
    } else {
      setTimeout(() => {
        this.endDateRange?.focus();
      }, 1);
    }
  }

  public setFilters(value: string | undefined, type: IApiInvestigationMetricsFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value
    }] : filtersCopy;
  }

  public filterValue(filter: IApiInvestigationMetricsFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
      ? value
      : null;
  }

  public appliedFilters(): void {
    this.applyFilter.emit(this.filters);
  }

  public clearAll(): void {
    this.filters = [];
    this.range = { startDate: null, endDate: null };
    this.clearFilter.emit(this.filters);
  }

  public filterByIdValue(filter: IApiInvestigationMetricsFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }


  public setVal(event: any, type: IApiInvestigationMetricsFilterType) {
    let val = null;
    if (event?.length > 0) {
      val = JSON.stringify(event);
    }
    this.setFilters(val, type);
  }
}
