import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  invoiceableId
  Invoice {
    id
    Investigation {
      id
      nefcoNumber
      BillTo {
        id
        firstName
        middleName
        lastName
        suffix
      }
      Client {
        id
        firstName
        middleName
        lastName
        suffix
      }
      Company {
        id
        name
        Branches {
          id
          name
          isPrimary
        }
      }
    }
    InvoiceLines {
      id
      quantity
      description
      expenseType
      rate
      qbAccount
      createdAt
      staffNames
      displayOrder
    }
    QbErrors {
      statusCode
      statusSeverity
      statusMessage
      createdAt
    }
    invoiceNumber
    billDate
    details
    qbExported
    qbTicketId
    createdBy {
      firstName
      lastName
    }
    createdById
    createdAt
    updatedAt
  }
  invoiceableType
  InvoiceId
  createdAt
  updatedAt
  TimeEntry {
    id
    Type {
      id
      name
    }
    workday
    paidDate
    hours
    nonBillableHours
    description
    Investigation {
      id
    }
    User {
      id
      firstName
      lastName
    }
    Status {
      id
      name
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
  Expense {
    id
    expenseDate
    billableQuantity
    nonBillableQuantity
    nefcoVehicle
    outOfPocket
    authorizedBy
    authorizedDate
    description
    Investigation {
      id
    }
    ExpenseItem {
      id
      name
    }
    ExpensePurpose {
      id
      name
    }
    User {
      id
      firstName
      lastName
    }
    Document {
      id
      title
      uri
    }
    paidDate
    createdAt
    updatedAt
  }
`;

export const InvoiceItemConnectionQuery = gql`${buildConnectionQuery("InvoiceItem", "InvoiceItemConnection", nodeFragment)}`;

export const getInvoiceItemByIdQuery = gql`query getInvoiceItemById($id: String!) {
  getInvoiceItemById(id: $id) { ${ nodeFragment } }
}`;