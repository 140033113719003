export enum TimeEntryStatusFilterType {
  STATUS
}

export enum TimeEntryStatusNames {
  PENDING,
  APPROVED,
  INVOICED
}

export enum TimeEntryStatusOrderByType {
  CREATED_AT
}

export enum TimeEntryTypeAppliesToNames {
  INVESTIGATION = "Investigation",
  UNLINKED = "Unlinked",
  BOTH = "Both"
}

export enum TimeEntryTypeFilterType {
  APPLIES_TO = "APPLIES_TO"
}

export enum TimeEntryTypeOrderByType {
  CREATED_AT,
  APPLIES_TO
}

export enum TimeEntryFilterType {
  STATUS = "STATUS",
  EMPLOYEE = "EMPLOYEE",
  WORKDAY = "WORKDAY",
  INVESTIGATION = "INVESTIGATION",
  WEEK = "WEEK",
  PAY_PERIOD = "PAY_PERIOD",
  DATE_RANGE = "DATE_RANGE"
}

export enum TimeEntryOrderByType {
  CREATED_AT = "CREATED_AT",
  WORKDAY = "WORKDAY",
  EMPLOYEE = "EMPLOYEE",
}
