<kendo-gridlayout class="p-16" [rows]="['auto']" [gap]="{rows: 16, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0" *ngIf="showSelection">
        <kendo-formfield class="kendo-formfield">
            <kendo-dropdownlist [data]="filterOptions" textField="text" valueField="slug" fillMode="outline"
                [valuePrimitive]="true" name="filterType" (selectionChange)="filterChange($event)"
                [(ngModel)]="currentFilter"
                [popupSettings]="{appendTo: 'component', animate: false, popupClass: 'nodata', width: 250 }">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span> {{ dataItem?.text}} </span>
                </ng-template>
                <ng-template kendoDropDownListNoDataTemplate>
                </ng-template>
            </kendo-dropdownlist>
        </kendo-formfield>
    </kendo-gridlayout-item>
    <hr class="divider-line" *ngIf="showSelection" />
    <kendo-gridlayout-item class="month-view mb-0" *ngIf="currentFilter !== 'DATE_RANGE'">
        <button kendoButton class="arrow-button"
            *ngIf="currentFilter === 'EXPENSE_WEEK' || currentFilter === 'EXPENSE_MONTH'" themeColor="light"
            fillMode="solid" [iconClass]="'fa-solid fa-angle-left'" (click)="changeWeekMonth('backward')"></button>
        <div class="week-ending" *ngIf="currentFilter === 'EXPENSE_WEEK' || currentFilter === 'EXPENSE_MONTH'">
            <ng-container *ngIf="mode==='week'">
                Week Ending: {{ selectedWeek | amDateFormat: 'MM/DD/YYYY' }}
            </ng-container>
            <ng-container *ngIf="mode==='week' && getRange">
                Week Ending: 1
            </ng-container>
            <ng-container *ngIf="mode==='month'">
                Month Ending: {{ getRange ? (selectedMonthRange?.startDate | amDateFormat: 'MMMM YYYY') :
                (selectedMonth |
                amDateFormat: 'MM/DD/YYYY') }}
            </ng-container>
        </div>
        <button kendoButton class="arrow-button"
            *ngIf="currentFilter === 'EXPENSE_WEEK' || currentFilter === 'EXPENSE_MONTH'" themeColor="light"
            fillMode="solid" [iconClass]="'fa-solid fa-angle-right'" (click)="changeWeekMonth('forward')"></button>
    </kendo-gridlayout-item>
</kendo-gridlayout>
<kendo-gridlayout class="p-16 pt-0" *ngIf="currentFilter === 'DATE_RANGE'" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
    [cols]="['repeat(2,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0">
        <div class="kendo-formfield">
            <kendo-datepicker id="startDate" name="startDate" calendarType="classic" [fillMode]="'outline'"
                placeholder="Start date" format="MM/dd/yyyy" (valueChange)="customRangeChange()" [max]="range.endDate"
                [ngClass]="{'ng-invalid ng-touched': (!startDate?.valid && (startDate?.dirty || startDate?.touched))}"
                [(ngModel)]="range.startDate" formatPlaceholder="formatPattern" required="true"
                #startDate="ngModel"></kendo-datepicker>
            <kendo-formerror *ngIf="!startDate?.valid && (startDate?.dirty || startDate?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="startDate?.errors?.required">
                    Start date cannot be left blank
                </span>
                <span *ngIf="startDate?.errors?.maxError">
                    Start date should be less than end date
                </span>
            </kendo-formerror>
        </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item  class="mb-0">
        <div class="kendo-formfield">
            <kendo-datepicker id="endDate" name="endDate" calendarType="classic" [fillMode]="'outline'"
                placeholder="End date" [min]="range.startDate" (valueChange)="customRangeChange()"
                [ngClass]="{'ng-invalid ng-touched': !endDate?.valid && (endDate?.dirty || endDate?.touched)}"
                format="MM/dd/yyyy" [(ngModel)]="range.endDate" formatPlaceholder="formatPattern" required="true"
                #endDate="ngModel"></kendo-datepicker>
            <kendo-formerror *ngIf="!endDate?.valid && (endDate?.dirty || endDate?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="endDate?.errors?.required">
                    End date cannot be left blank
                </span>
                <span *ngIf="endDate?.errors?.minError">
                    End date should be greater than start date
                </span>
            </kendo-formerror>
        </div>
    </kendo-gridlayout-item>
</kendo-gridlayout>
