<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationVehiclesListView"
  #investigationVehiclesListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationVehiclesCreate"
  #investigationVehiclesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationAssignStaffListView"
  #investigationAssignStaffListView="appEnforcePermission"></div>
<!-- Permissions -->
<app-breadcrumbs></app-breadcrumbs>
<section>
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0">
      <h4 class="page-title"> {{ id ? "Modify" : "Create" }} Investigation {{investigation?.nefcoNumber ? ( '#' +
        investigation?.nefcoNumber): ''}}
      </h4>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="text-right mb-0">
      <button kendoButton themeColor="light" [disabled]="!id" routerLink="/investigations/{{ id }}"
        [ngClass]="{ 'investigation-button-disabled': !id }" fillMode="solid">Go To Investigation Detail</button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <div class="content-wrapper mt-12">
    <form #form="ngForm" appDebouncedChange [appDirtyFormNotify]="form">
      <kendo-gridlayout *ngIf="(param?.editInfo && id)" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
        [cols]="['repeat(3,minmax(0,1fr))']">
        <!-- recievedDate-->
        <kendo-gridlayout-item class="mb-0">
          <span class="display-block card-detail">
            <p class="mb-0">Status: &nbsp;{{ investigation.status }}</p>
          </span>
          <p class="date-time secondary">
            Received:
            {{ model?.receivedDate | customDate : "MM-dd-yyyy hh:mm:ss a" }}
          </p>
          <p *ngIf="investigation.deletedAt" class="date-time">
            Cancelled:
            {{ investigation.deletedAt | customDate : "MM-dd-yyyy hh:mm:ss a" }}
          </p>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="mb-0">
          <app-investigation-priorities-select name="priorities" [(ngModel)]="model.PriorityId"
            required></app-investigation-priorities-select>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="mb-0 right-grid">
          <kendo-formfield>
            <kendo-label>
              <kendo-switch [(ngModel)]="model.onHold" class="mr-5" name="onHold" title="On Hold"></kendo-switch>
              On Hold
            </kendo-label>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label>
              <kendo-switch [(ngModel)]="isRejected" [checked]="isRejected" (valueChange)="openRejectReactivateModel()"
                class="mr-5" name="isRejected" title="Case Rejected"></kendo-switch>
              Case Rejected
            </kendo-label>
          </kendo-formfield>
          <ng-container *ngIf="id; else failedIcon">
            <i class="fa-solid fa-circle-check fa-lg" style="color:#4b8df9"></i>
            {{ model["updatedAt"] | customDate : "MM-dd-yyyy hh:mm:ss a" }}
          </ng-container>
          <ng-template #failedIcon>
            <span>
              <i class="fa-solid fa-triangle-exclamation fa-lg" style="color: red;"></i>
              Not Saved
            </span>
          </ng-template>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-card [ngStyle]="{'display': (param?.editInfo && id) ? 'block' : 'none'}" [width]="'100%'" class="mt-16">
        <kendo-card-body>
          <div class="section-header">
            <h2>Investigation Information</h2>
          </div>
          <section>
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
              <!-- LEFT -->
              <kendo-gridlayout-item>
                <!-- client and billing info-->
                <app-investigation-client [investigation]="this.baseModel" (selected)="setClientInfo($event)"
                  [resetForm]="resetForm"></app-investigation-client>
                <input type="hidden" name="clientId" [(ngModel)]="this.model.ClientId" />
                <input type="hidden" name="ClientBranchId" [(ngModel)]="this.model.ClientBranchId" />
                <input type="hidden" name="billToId" [(ngModel)]="this.model.BillToId" required />
                <input type="hidden" name="billToBranchId" [(ngModel)]="this.model.BillToBranchId" />
                <input type="hidden" name="companyId" [(ngModel)]="this.model.CompanyId" />
                <kendo-gridlayout class="mt-12" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
                  [cols]="['repeat(2,minmax(0,1fr))']">
                  <kendo-gridlayout-item>
                    <!-- lossDate -->
                    <kendo-formfield>
                      <kendo-label text="Loss Date"></kendo-label>
                      <kendo-datepicker calendarType="classic" [fillMode]="'outline'" [name]="'lossDate'"
                        [(ngModel)]="lossDate" placeholder="Enter date" format="MM/dd/yyyy"
                        formatPlaceholder="formatPattern"></kendo-datepicker>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <!-- lossTime -->
                    <kendo-formfield>
                      <kendo-label text="Loss Time"></kendo-label>
                      <kendo-timepicker format="hh:mm a" [name]="'lossTime'" [disabled]="!lossDate"
                        [(ngModel)]="lossTime" placeholder="Loss time"></kendo-timepicker>
                      <kendo-formhint>(ie, HH:MM)</kendo-formhint>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label [for]="description" text="Description"></kendo-label>
                      <kendo-textarea #description="ngModel" [(ngModel)]="model.Detail.riskDescription"
                        name="description" placeholder="Risk description" fillMode="outline"></kendo-textarea>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <app-risk-types-select name="riskTypeId" [(ngModel)]="model.RiskTypeId"
                      [isMat]="false"></app-risk-types-select>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label text="Policy #"></kendo-label>
                      <kendo-textbox name="policyNumber" #policyNumber="ngModel" name="policyNumber"
                        [(ngModel)]="model.Detail.policyNumber" placeholder="Enter policy #" fillMode="outline"
                        resizable="both" appNoWhiteSpace></kendo-textbox>
                      <span class="text-danger" *ngIf="!model.Detail.policyNumber && !model.Detail.claimNumber">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span>
                          A Policy # OR Claim # must be entered
                        </span>
                      </span>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label text="Claim #"></kendo-label>
                      <kendo-textbox name="claimNumber" #claimNumber="ngModel" name="claimNumber"
                        [(ngModel)]="model.Detail.claimNumber" placeholder="Enter claim #" fillMode="outline"
                        resizable="both" appNoWhiteSpace></kendo-textbox>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <app-notes name="detailNotes" title="Investigation Notes:"
                      [(ngModel)]="model.Detail.notes">message</app-notes>
                  </kendo-gridlayout-item>
                </kendo-gridlayout>
              </kendo-gridlayout-item>
              <!-- Right -->
              <kendo-gridlayout-item>
                <app-address-autocomplete *ngIf="!!mapsLoaded" [address]="model.LossAddress"
                  (setAddress)="setLossAddress($event)" (setTimezone)="setTimezone($event)" [loadAPI]="false"
                  [resetForm]="resetForm"></app-address-autocomplete>
                <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label class="asterisk" [for]="timezone" text="Timezone"></kendo-label>
                      <kendo-dropdownlist #timezone [data]="timeZones" name="timezone" textField="key"
                        valueField="value" [valuePrimitive]="true" [(ngModel)]="model.timezone" fillMode="outline"
                        [defaultItem]="{value: '', key: 'Select timezone'}"
                        [popupSettings]="{appendTo: 'component', animate: false}">
                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                          <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.key }} </span>
                          <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.key }} </span>
                        </ng-template>
                      </kendo-dropdownlist>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <div class="notes-button">
                      <p class="mb-0">
                        <span class="font-weight-bold">Note:</span> Mapping features
                        only apply when the address search is used. Custom addresses
                        will not return proximity results.
                      </p>
                      <button kendoButton
                        [iconClass]="addressCheckComplete ? 'fa-solid fa-circle-check fa-lg': 'fa-solid fa-triangle-exclamation fa-lg'"
                        (click)="checkLossAddress()" [disabled]="!model.LossAddress || !model.LossAddress.longitude"
                        [themeColor]="addressCheckComplete ? 'primary' : 'error'" fillMode="solid"
                        (click)="enableAddParty = true">Check</button>
                    </div>
                  </kendo-gridlayout-item>
                  <!-- Investigation Address / Scene Exam Address -->
                  <kendo-gridlayout-item>
                    <div style="display: flex;">
                      <p> Scene Exam Address:</p>
                      <span class="k-checkbox-wrap ml-16">
                        <input #isLossSameAddress type="checkbox" kendoCheckBox name="isLossSameAddress"
                          [(ngModel)]="isInvestigationAddressSameAsLoss" />
                        <kendo-label [for]="isLossSameAddress" class="k-checkbox-label font-weight-600"
                          style="margin-bottom: 0" text="Same as loss address"></kendo-label>
                      </span>
                    </div>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <ng-container *ngIf="!isInvestigationAddressSameAsLoss">
                      <app-address-autocomplete *ngIf="!!mapsLoaded" [address]="model.InvestigationAddress"
                        (setAddress)="setInvestigationAddress($event)" [loadAPI]="false"></app-address-autocomplete>
                      <br />
                    </ng-container>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <div style="display: flex;">
                      <p> Docusketch Imaging:</p>
                      <span class="k-checkbox-wrap ml-16">
                        <input #docusketch type="checkbox" kendoCheckBox name="docusketch"
                          [(ngModel)]="model.docusketchRequested" />
                        <kendo-label [for]="docusketch" class="k-checkbox-label font-weight-600"
                          style="margin-bottom: 0" text="Does the client require Docusketch imaging?"></kendo-label>
                      </span>
                    </div>
                    <kendo-formhint class="mt-0" style="margin-left: 164px">* Additional fees apply</kendo-formhint>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item *ngIf="model?.docusketchRequested">
                    <div style="display: flex;">
                      <p> Please Indicate:</p>
                      <span class="k-checkbox-wrap ml-34">
                        <input #docusketch type="checkbox" kendoCheckBox name="isXactimate"
                          [(ngModel)]="model.isXactimate" />
                        <kendo-label [for]="docusketch" class="k-checkbox-label font-weight-600"
                          style="margin-bottom: 0" text="Xactimate"></kendo-label>
                      </span>
                      <span class="k-checkbox-wrap ml-16">
                        <input #symbility type="checkbox" kendoCheckBox name="isSymbility"
                          [(ngModel)]="model.isSymbility" />
                        <kendo-label [for]="symbility" class="k-checkbox-label font-weight-600" style="margin-bottom: 0"
                          text="Symbility"></kendo-label>
                      </span>
                    </div>
                    <kendo-formhint
                      *ngIf="(!model.isXactimate && !model.isSymbility) || (model.isXactimate && model.isSymbility)"
                      class="mt-0 text-danger" style="margin-left: 164px">Please fill out either the Xactimate or
                      Symbility</kendo-formhint>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-textarea #specialInstructions="ngModel" [(ngModel)]="model.Detail.specialInstructions"
                        name="specialInstructions" placeholder="Enter special billing instructions" rows="3"
                        fillMode="outline"></kendo-textarea>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                </kendo-gridlayout>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </section>
        </kendo-card-body>
      </kendo-card>
      <!-- Investigation Billing Information -->
      <kendo-card *ngIf="(param?.editBillingInfo || param?.addBillingInfo) && id" [width]="'100%'"
        class="plain-kendo-card mt-16">
        <kendo-card-body>
          <div class="section-header">
            <h2>Investigation Billing Information</h2>
          </div>
          <section>
            <form #bForm="ngForm" class="d-flex flex-row pt-2 clearfix" [appDirtyFormNotify]="bForm">
              <kendo-card-body>
                <h3 class="mb-24">Client</h3>
                <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
                  <kendo-gridlayout-item>
                    <kendo-formfield class="kendo-formfield">
                      <kendo-label class="asterisk" [text]="'Budget Amount'"></kendo-label>
                      <div class="numeric-text-suffix">
                        <i class="fa-solid fa-dollar-sign"></i>
                        <kendo-numerictextbox placeholder="0.00" name="budgetAmount" fillMode="outline" required="true"
                          [autoCorrect]="false" [(ngModel)]="model.Detail.budgetAmount" #budgetAmount="ngModel">
                        </kendo-numerictextbox>
                      </div>
                      <kendo-formerror *ngIf="!budgetAmount?.valid && (budgetAmount?.dirty || budgetAmount?.touched)">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="budgetAmount?.errors?.required">
                          Budget Amount cannot be left blank
                        </span>
                        <span *ngIf="budgetAmount?.errors?.minError">
                          Budget Amount cannot be left blank
                        </span>
                      </kendo-formerror>
                    </kendo-formfield> </kendo-gridlayout-item>
                  <kendo-gridlayout-item [colSpan]="2">
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label class="asterisk" text="Billing Instructions"></kendo-label>
                      <kendo-textarea placeholder="Enter Billing Instructions" fillMode="outline"
                        name="billingInstructions" class="min-height-96" resizable="both" #billingInstructions="ngModel"
                        [(ngModel)]="model.billingInstructions" [required]="true" appNoWhiteSpace></kendo-textarea>
                      <kendo-formerror
                        *ngIf="!billingInstructions?.valid && (billingInstructions?.dirty || billingInstructions?.touched)">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="billingInstructions?.errors?.required">Billing instructions cannot be left
                          blank</span>
                      </kendo-formerror>
                    </kendo-formfield> </kendo-gridlayout-item>
                </kendo-gridlayout>
                <kendo-gridlayout> <app-notes name="billingNotes" title="Billing Instructions Notes:"
                    [(ngModel)]="model.Detail.billingInstructionNotes"
                    (ngModelChange)="save()">message</app-notes></kendo-gridlayout>
              </kendo-card-body>
            </form>
          </section>
        </kendo-card-body>
      </kendo-card>
      <!-- Linked Investigations Card -->
      <kendo-card *ngIf="(param?.editLinkedInvestigation || param?.addLinkedInvestigation) && id" [width]="'100%'"
        class="plain-kendo-card mt-16">
        <kendo-card-body>
          <div class="section-header">
            <h2>Linked Investigations</h2>
            <button kendoButton iconClass="fa-solid fa-location-dot" themeColor="light" fillMode="solid"
              (click)="checkLossAddress()">Proximity Check</button>
          </div>
          <section>
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="p-15" *ngFor="
                let linkedInvestigation of baseModel.LinkedInvestigations;
                let i = index
              ">
                  <kendo-chip class="fw600 mr-16" [size]="'large'" [rounded]="'full'" [fillMode]="'solid'"
                    [themeColor]="'base'" [removable]="true" (remove)="unlinkInvestigation(i)">
                    {{ linkedInvestigation.nefcoNumber || linkedInvestigation.id }}
                  </kendo-chip>
                </div>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item>
                <div class="kendo-formfield" style="width: 350px;">
                  <kendo-label text="Investigation #"></kendo-label>
                  <kendo-textbox name="investigationLink" #linkCtl fillMode="outline"></kendo-textbox>
                </div>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item>
                <button kendoButton iconClass="fa-solid fa-plus" themeColor="primary" fillMode="link"
                  (click)="linkInvestigation(linkCtl)" [disabled]="!linkCtl.value?.length">Add Linked
                  Investigation</button>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item>
                <app-notes name="linkedNotes" title="Linked Investigation Notes:"
                  [(ngModel)]="model.Detail.linkedInvestigationNotes" (ngModelChange)="save()">message</app-notes>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </section>
        </kendo-card-body>
      </kendo-card>
      <!-- Vehicles Card -->
      <kendo-card *ngIf="(param?.editVehicles || param?.addVehicles) && id && investigationVehiclesListView?.enabled"
        class="plain-kendo-card mt-16" [width]="'100%'">
        <kendo-card-body *ngIf="id; else vehicleMoreInfo">
          <div class="section-header">
            <h2>Vehicles</h2>
          </div>
          <section>
            <ng-container *ngFor="let vehicle of vehicles; let i = index">
              <h3 class="mb-1 mt-2">Vehicle {{ i + 1 }}:</h3>
              <app-investigation-vehicle [investigationId]="id" [vehicle]="vehicle"
                (changed)="loadVehicles()"></app-investigation-vehicle>
            </ng-container>
            <ng-container *ngIf="investigationVehiclesCreate?.enabled">
              <br />
              <h3 class="mb-1 mt-3 mr-3">New Vehicle:</h3>
              <app-investigation-vehicle [investigationId]="id" (changed)="loadVehicles()"></app-investigation-vehicle>
            </ng-container>
            <app-notes name="vehicleNotes" title="Vehicle Notes:" [(ngModel)]="model.Detail.vehicleNotes"
              (ngModelChange)="save()">message</app-notes>

          </section>
        </kendo-card-body>
        <ng-template #vehicleMoreInfo>
          <h2>More Detail Needed</h2>
          <p>
            To add vehicles more primary information is needed above. This
            section will automatically become available.
          </p>
        </ng-template>
      </kendo-card>
      <!-- Parties Card -->
      <ng-container *ngIf="id && param?.editParties"><kendo-card class="plain-kendo-card mt-16" [width]="'100%'">
          <kendo-card-body>
            <app-investigation-party [investigation]="investigation"></app-investigation-party>
            <app-notes name="partyNotes" title="Party Notes:" [(ngModel)]="model.Detail.partyNotes"
              (ngModelChange)="save()">message</app-notes>
          </kendo-card-body>
        </kendo-card>
      </ng-container>
      <!-- Investigation Staff -->
      <kendo-card *ngIf="id && param?.editStaff && investigationAssignStaffListView?.enabled"
        class="plain-kendo-card mt-16" [width]="'100%'">
        <kendo-card-body>
          <div class="section-header">
            <h2>Staff</h2>
          </div>
          <section>
            <app-investigation-assignments-container [staff]="baseModel?.InvestigationStaff"
              [address]="model.LossAddress" [investigation]="investigation" [clientId]="model.ClientId"
              (updated)="reloadInvestigation()"></app-investigation-assignments-container>
            <div class="mt-16">
              <app-notes name="staffNotes" title="Investigation Staff Notes:" [(ngModel)]="model.Detail.staffNotes"
                (ngModelChange)="save()">message</app-notes>
            </div>
          </section>
        </kendo-card-body>
      </kendo-card>
    </form>
  </div>
</section>
<footer>
  <div class="right">
    <button kendoButton class="mr-24" themeColor="primary" fillMode="link" (click)="clearPage()"
      *ngIf="(param?.editInfo || param.editBillingInfo || param.addBillingInfo) && id">Clear Page</button>
    <button kendoButton class="mr-16" themeColor="light" fillMode="solid" routerLink="/investigations/{{ id }}"
      *ngIf="(param?.editInfo || param.editBillingInfo || param.addBillingInfo) && id">Discard Edits</button>
    <button kendoButton class="mr-16" themeColor="primary" fillMode="solid" (click)="save(true)"
      [disabled]="validateForm" *ngIf="param?.editInfo && id">Save Edit</button>
    <!-- save button for billing information form -->
    <button kendoButton class="mr-16" themeColor="primary" fillMode="solid" (click)=" saveBillingDetail()"
      [disabled]="validateBForm" *ngIf="(param.editBillingInfo || param.addBillingInfo) && id">Save
      Edit</button>
  </div>
</footer>
<app-reject-reactivate-modal *ngIf="investigationRejectReactivateModal" [opened]="investigationRejectReactivateModal"
  [mode]="investigationRejectReactivateModalMode" [id]="id" [headerIcon]="'assets/svg/warning.svg'"
  (closeModel)="investigationRejectReactivateModalResponse($event)"></app-reject-reactivate-modal>
