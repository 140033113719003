import { Component, Inject, Input, OnInit } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { IApiAddInvestigationHistoryInput, IApiAddInvestigationInput, IApiInvestigation, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUpdateInvestigationInput } from 'src/app/shared/modules/graphql/types/types';

@Component({
  selector: 'app-investigation-history-kendo-modal',
  templateUrl: './investigation-history-kendo-modal.component.html',
  styleUrls: ['./investigation-history-kendo-modal.component.scss']
})
export class InvestigationHistoryKendoModalComponent extends DialogContentBase implements OnInit {
  public categories = Object.values(IApiInvestigationUpdateCategories);
  public types = Object.values(IApiInvestigationUpdateTypes);

  public model: IApiAddInvestigationHistoryInput;
  public noteOnly = false;

  @Input() data: {
    investigation: IApiUpdateInvestigationInput | IApiInvestigation,
    selectedCategory: IApiInvestigationUpdateCategories,
    selectedType: IApiInvestigationUpdateTypes,
    noteOnly?: boolean,
  }

  constructor(
    public dialogRef: DialogRef,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    if (this.data) {


      const { selectedCategory, selectedType, investigation: { id }, noteOnly = false } = this.data;
      this.model = {
        InvestigationId: id,
        updateCategory: selectedCategory,
        updateType: selectedType,
        comment: null
      };
      this.noteOnly = noteOnly;
    }
  }

  save() {
    this.dialogRef.close(this.model);
  }

  close(){
    this.dialog.close();
  }
}
