import { Observable, Subject, from, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlS3Service } from 'src/app/shared/modules/graphql/services';
import {
  IApiSignedUrlResponse,
  IApiS3FileInput,
  IApiUploadTypes,
  IApiCreateMultipartResponse,
  IApiUploadMultipartPartUrlInput,
  IApiCompleteMultipartUploadInput,
  IApiAbortMultipartUploadInput,
} from 'src/app/shared/modules/graphql/types/types';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SafeUrl } from '@angular/platform-browser';

interface CropFileInterface { file: File };
export interface FileInterface {
  id: string;
  file: File;
  objectURL: string;
  preview: SafeUrl;
  cropFile: ImageCroppedEvent & CropFileInterface;
  isImage: boolean;
  width: number | null;
  height: number | null;
  key?: string;
  signedUrl?: string;
  errorMessage?: string | null | undefined;
};

export interface uploadFilesPayLoad {
  type: IApiUploadTypes,
  InvestigationId: string | null,
  FolderId: string | null,
  UserId?: string | null,
  files: FileInterface[],
  maxParallelUploads: number,
}

export interface inputDataFileSelect {
  type: IApiUploadTypes,
  InvestigationId?: string | null,
  FolderId?: string | null,
  UserId?: string | null,
  restrictions?: string[],
  restrictionsSize?: { zip?: number, video?: number }[],
  multiple?: boolean,
  limit?: number,
}

@Injectable()
export class S3Service {

  readonly PART_SIZE = 100 * 1024 * 1024; // 100MB part size
  constructor(
    private gqlS3Service: GqlS3Service
  ) { }

  public createSignedUrlMutation(input: IApiS3FileInput): Observable<IApiSignedUrlResponse> {
    return this.gqlS3Service.createSignedUrlMutation(input).pipe(
      catchError(error => {
        console.error('Error creating signed URL', error);
        return throwError(error);
      })
    );
  }

  public createMultipartUpload(input: IApiS3FileInput): Observable<IApiCreateMultipartResponse> {
    return this.gqlS3Service.createMultipartUpload(input);
  }

  public getUploadMultipartPartUrl(input: IApiUploadMultipartPartUrlInput): Observable<IApiSignedUrlResponse> {
    return this.gqlS3Service.getUploadMultipartPartUrl(input);
  }

  public completeMultipartUpload(input: IApiCompleteMultipartUploadInput): Observable<boolean> {
    return this.gqlS3Service.completeMultipartUpload(input);
  }

  public abortMultipartUpload(input: IApiAbortMultipartUploadInput): Observable<boolean> {
    return this.gqlS3Service.abortMultipartUpload(input);
  }
  
  public fileProgressSubject = new Subject<{id: string, percentage: number}>();

  private uploadFilePart(file: Blob, signedUrl: string, progressSubject: Subject<number>): Observable<unknown> | Observable<HttpResponse<any>> {
    return new Observable(observer => {
      const req = new XMLHttpRequest();
      req.open('PUT', signedUrl);
      req.setRequestHeader('Content-Type', file.type);
      req.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          progressSubject.next(progress);
        }
      };

      req.onload = () => {
        observer.next(new HttpResponse({
          body: req.response,
          status: req.status,
          statusText: req.statusText,
          url: req.responseURL,
          headers: new HttpHeaders(req.getAllResponseHeaders().split('\r\n').reduce((acc, current) => {
            const [key, value] = current.split(': ');
            if (key && value) {
              acc[key] = value;
            }
            return acc;
          }, {} as { [key: string]: string }))
        }));
        observer.complete();
      };

      req.onerror = () => observer.error(req.response);
      req.onabort = () => observer.error(req.response);
      req.send(file);
    });
  }

  public async uploadFileMultiPart(file: FileInterface, key : string, uploadId: string) {
    const parts = [];
    let partNumber = 1;
    let totalProgress = 0;
    const totalParts = Math.ceil(file.file.size / this.PART_SIZE);
    const progressPerParts = 100 / totalParts;

    for (let start = 0; start < file.file.size; start += this.PART_SIZE) {
      const progressSubject = new Subject<number>();
      progressSubject.subscribe((progress) => {
        if (progress) {
          const partProgress = (progress * progressPerParts) / 100;
          this.fileProgressSubject.next({id: file.id, percentage: (totalProgress + partProgress)})
        }
      });
      const end = Math.min(start + this.PART_SIZE, file.file.size);
      const filePart = file.file.slice(start, end);

      const inputGetUploadPart: IApiUploadMultipartPartUrlInput = {
        key,
        partNumber,
        uploadId
      };

      try {
        const uploadPartUrlResponse = await this.getUploadMultipartPartUrl(inputGetUploadPart).toPromise();
        const uploadPartUrl = uploadPartUrlResponse.signedUrl;
        const uploadPartResponse = await this.uploadFilePart(filePart, uploadPartUrl, progressSubject).toPromise() as HttpResponse<any>;
        const eTag = uploadPartResponse.headers.get('ETag');

        parts.push({ PartNumber: partNumber, ETag: eTag});
        totalProgress += progressPerParts;
        this.fileProgressSubject.next({id: file.id, percentage: totalProgress});

        partNumber++;
      } catch (error) {
        console.error(`Error uploading part ${partNumber} for file ${file.id}`, error);
        file.key = null;
        this.abortMultipartUpload({ key, uploadId }).toPromise();
        throw error;
      }

    }
    return this.completeMultipartUpload({key, uploadId, parts}).toPromise();
  }

  photoDownloadProgress(): Observable<any> {
    return this.gqlS3Service.photoDownloadProgress();
  }

  private uploadSingleFile(file: FileInterface, payload: uploadFilesPayLoad): Observable<any> {
    const s3FileInput: IApiS3FileInput = {
      type: payload.type,
      InvestigationId: payload.InvestigationId,
      FolderId: payload.FolderId,
      UserId: payload.UserId,
      ContentType: file.cropFile?.file.type || file.file.type,
      fileName: file.cropFile?.file.name || file.file.name,
    };

    if (file?.file?.size < this.PART_SIZE) {
      return this.createSignedUrlMutation(s3FileInput).pipe(
        mergeMap(({ key, signedUrl }) => {
          const progressSubject = new Subject<number>();
          progressSubject.subscribe((progress) => {
            if (progress) {
              this.fileProgressSubject.next({id: file.id, percentage: progress})
            }
          });
          return from(this.uploadFilePart(file.file, signedUrl, progressSubject)).pipe(
          tap(() => file.key = key),
          catchError((err) => {
            file.key = null;
            console.error('Error uploading file', err);
            return throwError(err);
          })
        )})
      );
    } else {
      return this.createMultipartUpload(s3FileInput).pipe(
        mergeMap(({ key, uploadId }) => from(this.uploadFileMultiPart(file, key, uploadId)).pipe(
          tap(() => file.key = key),
          catchError((err) => {
            file.key = null;
            console.error('Error uploading file', err);
            this.abortMultipartUpload({key, uploadId}).toPromise();
            return throwError(err);
          })
        ))
      );
    }

  }


  // Parallel upload with a limit
  uploadFiles(payload: uploadFilesPayLoad): Observable<any> {
    return from(payload.files).pipe(
      mergeMap(file => this.uploadSingleFile(file, payload), payload.maxParallelUploads),
      catchError((error) => {
        console.error('Error in file upload process', error);
        return throwError(error);
      })
    );
  }
}
