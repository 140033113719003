import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { NotificationsService } from "./modules/notifications/notifications.service";
import { Observable, combineLatest } from 'rxjs';
import { AuthService } from './services';
import { map } from 'rxjs/operators';

export interface CanEditComponentDeactivate {
  param: any;
  validateForm: boolean;
  validateBForm: boolean;
  save: any;
  saveBillingDetail: any;
}
@Injectable()
export class CanDeactivateEditFormDirty implements CanDeactivate<CanEditComponentDeactivate> {
  constructor(private notifications: NotificationsService) { }

  async canDeactivate(
    component: CanEditComponentDeactivate
  ): Promise<boolean> {
    if (this.notifications.dirtyForms) {
      const isConfirmed = await this.notifications
        .kendoConfirm(
          "You are about to leave this page without saving. All edits will be lost. Are you sure you want to leave without saving?",
          "Unsaved Edits",
          "Leave Without Saving",
          "Save Edit"
        )
        .toPromise();
      if ((component?.param?.addBillingInfo || component?.param?.editBillingInfo) && isConfirmed && !component.validateBForm) {
        component.saveBillingDetail();
      } else if(!component.validateForm && isConfirmed && component.param?.editInfo) {
        component.save(true);
      }
      return !isConfirmed
    } else {
      return true;
    }
  }
}


export interface CanAddComponentDeactivate {
  validateForm: boolean;
  save: any;
}
@Injectable()
export class CanDeactivateAddFormDirty implements CanDeactivate<CanAddComponentDeactivate> {
  constructor(private notifications: NotificationsService) { }

  async canDeactivate(
    component: CanAddComponentDeactivate
  ): Promise<boolean> {
    if (this.notifications.dirtyForms) {
      const isConfirmed = await this.notifications
        .kendoConfirm(
          "You are about to leave this page without saving. All edits will be lost. Are you sure you want to leave without saving?",
          "Unsaved Edits",
          "Leave Without Saving",
          "Save Edit"
        )
        .toPromise();
      if (!component.validateForm && isConfirmed) {
        component.save(true);
      }
      return !isConfirmed
    } else {
      return true;
    }
  }
}
@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permission = route?.routeConfig?.data || null;
    if (permission) {
      return this.auth.hasCategoryPermission(permission?.category, permission?.appliedPermissions)
        .pipe(map(p => {
          if (p) return true
          else this.router.navigate(['/admin/access-restricted']); return false;
        }));
    } else {
      return true;
    }
  }
}
@Injectable()
export class MultiPermissionGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permission: any[] = route?.routeConfig?.data || null as any;
    if (permission && permission?.length) {
      const body = permission.map(item => this.auth.hasCategoryPermission(item.category, item.appliedPermissions));
      return combineLatest(body)
        .pipe(map(p => {
          if (p.some(i => i)) return true
          else this.router.navigate(['/admin/access-restricted']); return false;
        }));
    } else {
      return true;
    }
  }
}
