import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

export const nodeFragment = `
  id
  title
  issuedAt
  expiresAt
  status
  Type {
    id
    title
    state
    requiresRenewal
    isRestriction
    notes
    CertificationAgency {
      id
      name
    }                      
  }
  Comments {
    id
    text
    createdAt
    updatedAt
    CreatedBy {
      id
      firstName
      lastName
    }
  }
  Document {
    id
    caption
    title
    uri
    s3Uri
  }
  User {
    id
    firstName
    lastName
    Addresses {
      id
      isPrimary
      state
      address1
      address2
      address3
      city
      postal
    }
    Phones {
      id
      number
      isPrimary
      Type {
        id
        name
      }
    }
  }
`;

export const CertificationConnectionQuery = gql`${buildConnectionQuery("Certification", "certificationConnection", nodeFragment)}`;

export const getCertificationByIdQuery = gql`query getCertificationById($id: String!) {
  getCertificationById(id: $id) { ${nodeFragment} }
}`;

export const addCertificationMutation = gql`mutation AddCertification($input: AddCertificationInput!) {
  addCertification(input: $input) { ${nodeFragment} }
}`;

export const updateCertificationMutation = gql`mutation UpdateCertification($input: UpdateCertificationInput!) {
  updateCertification(input: $input) { ${nodeFragment} }
}`;

export const removeCertificationMutation = gql`mutation RemoveCertification($id: ID!) {
  removeCertification(id: $id)
}`;