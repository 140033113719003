import { buildConnectionOperation, buildConnectionQuery, connectionInputDefs } from './../../helpers.class';
import gql from 'graphql-tag';
import { IApiContactFilterType } from '../../types/types';

const userFragment = `
  id
  email
  firstName
  lastName
  nickName
`;
const phoneFragment = `
  id
  isPrimary
  number
  extension
  Type {
    id
    name
  }
`;

const emailFragment = `
  id
  isPrimary
  address
  Type {
    id
    name
  }
`;

const addressFragment = `
  id
  isPrimary
  address1
  address2
  address3
  city
  state
  postal
  country
  Type {
    id
    name
  }
`;

const addressesFragment = `
  Addresses { ${addressFragment} }
`;

const branchesFragment = `
  Branches {
    id
    name
    Company {
      id
      name
    }
  }
`;

const branchAssignmentsFragment = `
  BranchAssignments {
    isPrimary
    startDate
    endDate
    BranchId
    ContactId
    Branch {
      id
      name
      Company {
        id
        name
        Type {
          id
          name
        }
      }
      Addresses {
        id
        isPrimary
        address1
        address2
        address3
        city
        state
        postal
        country
      }
      Phones {
        id
        number
        isPrimary
        extension
      }
    }
  }
`;

const extrasFragment = `
  Branches {
    id
    name
    additionalInformation
    Company {
      id
      name
    }
    Phones { ${phoneFragment} }
    Addresses { ${addressFragment} }
    Emails { ${emailFragment} }
    Company {
      id
      name
    }
  }
`;

const templateFragment = `
  id
  firstName
  middleName
  lastName
  insuredName
  nickName
  suffix
  independentAdjuster
  companyNotRequired
  thirdPartyAdministrator
  additionalInformation
  qbListId
  Role {
    id
    name
  }
  Phones { ${phoneFragment} }
  Emails { ${emailFragment} }
  claimNumber
  policyNumber
  createdAt
  updatedAt
  {{ ADDRESSES }}
  {{ BRANCHES }}
  {{ BRANCHASSIGNMENTS }}
  {{ EXTRAS }}
`;


const templateFragmentSearch = `
  id
  firstName
  middleName
  lastName
  insuredName
  Emails { ${emailFragment} }
  BranchAssignments {
    isPrimary
    startDate
    endDate
    Branch {
      id
      Company {
        id
        name
      }
    }
  }
`;

const extrasRegex = /\{\{\s?EXTRAS\s?\}\}/g;
const addressesRegex = /\{\{\s?ADDRESSES\s?\}\}/g;
const branchesRegex = /\{\{\s?BRANCHES\s?\}\}/g;
const branchAssignmentsRegex = /\{\{\s?BRANCHASSIGNMENTS\s?\}\}/g;

const nodeFragment = templateFragment
  .replace(extrasRegex, extrasFragment)
  .replace(addressesRegex, addressesFragment)
  .replace(branchesRegex, branchesFragment)
  .replace(branchAssignmentsRegex, branchAssignmentsFragment);

// NOTE: same for now until Branches/BranchAssignments can be correctly cosolidated w/o risk of regression
const listFragment = templateFragment
  .replace(extrasRegex, extrasFragment)
  .replace(addressesRegex, addressesFragment)
  .replace(branchesRegex, branchesFragment)
  .replace(branchAssignmentsRegex, branchAssignmentsFragment);

const listViewFragment = templateFragment
  .replace(extrasRegex, "")
  .replace(addressesRegex, "")
  .replace(branchesRegex, "")
  .replace(branchAssignmentsRegex, branchAssignmentsFragment);

export const contactsConnectionQuery = (view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiContactFilterType.ViewListView:
        return gql`${buildConnectionQuery("Contact", "contactsConnection", listViewFragment)}`;

      default:
        return gql`${buildConnectionQuery("Contact", "contactsConnection", listFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("Contact", "contactsConnection", listFragment)}`;
  }
};

export const addContactMutation = gql`mutation createContact($input: AddContactInput!) {
  addContact(input: $input) { ${nodeFragment} }
  }`;

export const removeContactMutation = gql`mutation removeContact($input: RemoveContactInput!) {
  removeContact(input: $input){ success }
}`;

export const updateContactMutation = gql`mutation updateContact($input: UpdateContactInput!) {
  updateContact(input: $input) { ${nodeFragment} }
}`;

export const getContactByIdQuery = gql`query getContactById($id: ID!) {
  getContactById(id: $id) {${nodeFragment}}
}`;

export const emailContactMutation = gql`mutation emailContact($input: EmailContactInput, $fileInput: Upload) {
  emailContact(input: $input, fileInput: $fileInput)
}`;

export const approvedMutation = gql`mutation UpdateAndReviewContact($input: UpdateAndReviewContactInput!) {
  updateAndReviewContact(input: $input) {
    id
    isActive
  }
}`;

export const rejectMutation = gql`mutation RejectContact($input: RejectContactInput!) {
  rejectContact(input: $input) {
    success
  }
}`;

export const contactsConnectionQuerySearch = () => {
  return gql`${buildConnectionQuery("Contact", "contactsConnection", templateFragmentSearch)}`;
};
