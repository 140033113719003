import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GqlInvestigationOnSceneService } from 'src/app/shared/modules/graphql/services';
import { IApiInvestigationStaff, IApiChangeOnSceneStatusInput, IApiInvestigationOnSceneFilter, IApiInvestigationOnSceneConnection, IApiUpdateInvestigationOnSceneScheduledDate } from '../../../modules/graphql/types/types';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';


@Injectable()
export class InvestigationOnSceneService {

  constructor(
    private gqlInvestigationOnSceneService: GqlInvestigationOnSceneService
  ) { }

  public get(filters: IApiInvestigationOnSceneFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationOnSceneConnection> {
    return this.gqlInvestigationOnSceneService.getInvestigationOnScene(filters, options);
  }

  public changeOnSceneStatus(input: IApiChangeOnSceneStatusInput): Observable<boolean> {
    return this.gqlInvestigationOnSceneService.changeOnSceneStatus(input);
  }

  public updateInvestigationOnSceneScheduledDate(input: IApiUpdateInvestigationOnSceneScheduledDate): Observable<boolean> {
    return this.gqlInvestigationOnSceneService.updateInvestigationOnSceneScheduledDate(input);
  }
}
