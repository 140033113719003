import { DataSource } from "@angular/cdk/collections";
import { IApiInvestigationOnScene, IApiInvestigationOnSceneConnection, IApiInvestigationOnSceneFilter } from './../../../modules/graphql/types/types';
import { InvestigationOnSceneService } from './investigation-on-scene.service';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { ApiConnection } from '../../../decorators/@ApiConnection.decorator';
import { IGqlBaseOptions } from "src/app/shared/modules/graphql/interfaces";
import { ListPage } from "src/app/shared/helpers/list-page.class";

export class InvestigationOnSceneDataSource extends NefcoPagedDataSource<IApiInvestigationOnScene, InvestigationOnSceneService, IApiInvestigationOnSceneConnection> implements DataSource<IApiInvestigationOnScene> {
  @ApiConnection()
  public load(filters: IApiInvestigationOnSceneFilter[] = []): Promise<IApiInvestigationOnSceneConnection> {
    return this.service.get(filters, this.listPage || new ListPage(-1)).toPromise();
  }
}
