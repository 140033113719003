import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiShippingCarrier, IApiEvidenceStorageLocation, IApiUpdateEvidenceTransferInput, IApiShippingCarrierOrderBy, IApiEvidenceStorageLocationOrderBy, IApiEvidence, IApiDocumentType, IApiDocument, IApiEvidenceStorageLocationFilterType, IApiUploadTypes } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { EvidenceNumberPipe } from 'src/app/shared/pipes';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { ShippingCarrierService, EvidenceStorageLocationService, EvidenceTransferService, DocumentTypeService, DocumentService } from 'src/app/shared/services';
import { EvidenceStorageLocationModalComponent } from '../../evidence/evidence-storage-location-modal/evidence-storage-location-modal.component';
import { DialogCloseResult, DialogContentBase, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FileSelectModalComponent } from 'src/app/shared/components/file-select-modal/file-select-modal.component';
import { imageMimeType, pdfMimeType } from 'src/app/shared/helpers/helper';
import { FileInterface } from 'src/app/shared/services/s3/s3-service';

export interface IEvidenceTransferModalData {
  evidence: IApiEvidence[];
  receiveTransfer?: boolean;
}

@Component({
  selector: 'app-investigation-evidence-transfer-modal',
  templateUrl: './investigation-evidence-transfer-modal.component.html',
  styleUrls: ['./investigation-evidence-transfer-modal.component.scss'],
  providers: [EvidenceNumberPipe]
})
export class InvestigationEvidenceTransferModalComponent extends DialogContentBase implements OnInit {
  @Input() public data: IEvidenceTransferModalData;
  public carriers: IApiShippingCarrier[] = [];
  public locations: IApiEvidenceStorageLocation[] = [];
  public documentTypes: IApiDocumentType[] = [];
  public uploadedDoc: IApiDocument = null;

  public transfer: IApiUpdateEvidenceTransferInput = {
    id: null,
    trackingNumber: "",
    comment: "",
    ShippingCarrierId: null,
    DocumentId: null,
    EvidenceIds: null,
    FromLocationId: null,
    ToLocationId: null,
    receivedDate: null,
    receivedComment: "",
    evidenceSection: null,
    evidenceRow: null
  };

  public get evidenceIds() {
    return this.data?.evidence?.map((e) => this.evidenceNumber.transform(e.evidenceNumber))?.join(", ");
  }

  public get nefcoNumber() {
    return this.data?.evidence[0]?.Investigation?.nefcoNumber;
  }

  public get currentLocation() {
    if (!this.locations?.length) return null;
    return this.locations?.find(({ id }) => id === this.transfer.FromLocationId)?.name;
  }

  constructor(
    private carrierService: ShippingCarrierService,
    private evidenceLocationService: EvidenceStorageLocationService,
    private evidenceTransfer: EvidenceTransferService,
    private loader: LoaderService,
    private evidenceNumber: EvidenceNumberPipe,
    private documentTypeService: DocumentTypeService,
    private documentService: DocumentService,
    private notifications: NotificationsService,
    private dialogService: DialogService,
    public dialogRef: DialogRef
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.hydrate();
    if (this.data?.evidence?.length) {
      this.transfer.FromLocationId = this.data.evidence[0].EvidenceStorageLocation.id;
      this.transfer.EvidenceIds = this.data.evidence.map(({ id }) => id);
    }

    // set defaults if recieveTransfer
    if (this.data?.receiveTransfer) {
      const { CurrentTransfer: { id, FromLocation, ShippingCarrier, ShippingDocument, ToLocation, comment, trackingNumber } } = this.data.evidence[0];
      this.transfer = {
        id,
        trackingNumber,
        comment,
        ShippingCarrierId: ShippingCarrier.id,
        DocumentId: ShippingDocument ? ShippingDocument.id : null,
        EvidenceIds: [this.data.evidence[0].id],
        FromLocationId: FromLocation.id,
        ToLocationId: ToLocation.id,
        receivedDate: new Date(),
        evidenceSection: null,
        evidenceRow: null
      };

      this.uploadedDoc = ShippingDocument;
    }
  }

  private hydrate() {
    this.loader.show$(
      forkJoin([
        this.documentTypeService.get([], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(unwrapConnection()),
        this.carrierService.get([], { orderBy: IApiShippingCarrierOrderBy.Name, sortOrder: SortOrder.ASC, limit: -1 }).pipe(unwrapConnection()),
        this.evidenceLocationService.get([{type: IApiEvidenceStorageLocationFilterType.ViewListView, value: 'true'}], { orderBy: IApiEvidenceStorageLocationOrderBy.Name, sortOrder: SortOrder.ASC, limit: -1 }).pipe(unwrapConnection())
      ])).subscribe(([documentTypes, carriers, locations]) => {
        this.documentTypes = documentTypes;
        this.carriers = carriers;
        this.locations = locations;
        this.locations.push({id: undefined, name: 'Other'} as any);
      });
  }

  public saveTransfer(): void {
    if (!this.transfer.id) {
      delete this.transfer.id;
      delete this.transfer.evidenceSection;
      delete this.transfer.evidenceRow;
      delete this.transfer.receivedDate;
    }
  
    this.loader.show$(
      this.transfer?.id ? this.evidenceTransfer.update(this.transfer) : this.evidenceTransfer.add(this.transfer)
    ).subscribe((val) => {
      this.dialogRef.close(val);
    });
  }

  public async onUploadSuccess(files: FileInterface[]) {
    try {
      const doc = await this.loader.show$(
        forkJoin(
          files.map(({ key }) => {
            return this.documentService.add({
              uri: '',
              s3Uri: key,
              title: `Evidence Shipping Receipt`,
              caption: ``,
              InvestigationId: this.data?.evidence[0]?.Investigation?.id,
              TypeId: this.documentTypes.find(({ name }) => name === "Evidence Shipping Receipt")?.id
            });
          })
        )
      ).toPromise();
      this.uploadedDoc = doc[0] as IApiDocument;
      this.transfer.DocumentId = this.uploadedDoc.id;
      this.notifications.notify("Upload successful!");
    }
    catch (exc) {
      this.notifications.snackbarError("Error linking uploaded document. Please try again.");
    }
  }

  public fileSelector() {
    const dialog: DialogRef = this.dialogService.open({
      content: FileSelectModalComponent,
      width: 600,
      maxWidth: 600,
      maxHeight: 670,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as FileSelectModalComponent;
    dialogInstance.data = {
      InvestigationId: this.data?.evidence[0]?.Investigation?.id,
      FolderId: null,
      UserId: null,
      type: IApiUploadTypes.Evidence,
      restrictions: [...imageMimeType, ...pdfMimeType],
      multiple: false
    };
    dialog.result.subscribe((res: any) => {
      if (res?.length && res) {
        this.onUploadSuccess(res);
      }
    });
  }

  public removeDocument() {
    this.notifications.confirm("Are you sure you want to remove this document?").afterClosed().pipe(
      filter((v) => !!v),
      switchMap(() => {
        return this.loader.show$(
          this.documentService.remove(this.uploadedDoc.id).pipe(
            this.notifications.catchAlertPipe("Error deleting document"),
          )
        );
      })
    ).subscribe(() => {
      this.uploadedDoc = null;
      this.notifications.notify("Document removed");
    });
  }

  public createStorageLocation() {
    const dialog: DialogRef = this.dialogService.open({
      content: EvidenceStorageLocationModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result === true) this.hydrate();
    });
  }

}
