import { Observable } from 'rxjs';
import { GqlPhoneService } from "../../modules/graphql/services";
import { Injectable } from '@angular/core';
import { IApiAddPhoneInput, IApiPhone, IApiRemovePhoneInput } from "../../modules/graphql/types/types";

@Injectable()
export class PhoneService {

  constructor(
    private phones: GqlPhoneService,
  ) { }

  public add(input: IApiAddPhoneInput): Observable<IApiPhone> {
    return this.phones.addPhone(input);
  }

  public remove(input: IApiRemovePhoneInput) {
      return this.phones.removePhone(input);
  }
}
