<kendo-drawer #drawer [width]="-1" [mini]="false" [mode]="'overlay'" [expanded]="true" [position]="'end'" [animation]="true">
    <ng-template kendoDrawerTemplate>
        <section class="content-part safe-area-padding-top">
            <div class="header m-16">
                <span>
                    <h6>Investigation Log</h6>
                    <p> Investigation ID: {{investigation?.nefcoNumber || 'Unassigned'}}</p>
                </span>
                <button class="close-action" kendoButton themeColor="light" fillMode="solid"
                    [iconClass]="drawer.expanded && 'fa-solid fa-close'" (click)="close()"></button>
            </div>

            <hr class="divider-line" />

            <kendo-tabstrip #tabstrip [scrollable]="true" class="investigation-logs-tabs" (tabSelect)="changeTab($event)">
                <kendo-tabstrip-tab [title]="TABS.COMMENT" [selected]="selectedTab === TABS.COMMENT">
                    <ng-template kendoTabTitle>
                        <span>Comment Log</span>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div class="p-16">
                            <kendo-button kendoButton themeColor="primary" fillMode="solid" size="large" (click)="addComment()" 
                            [iconClass]="'fa fa-plus'" style="width: 100%;">
                            Add Comment
                            </kendo-button>
                        </div>
                        <div class="list p-16" style="min-height: calc(100vh - 196px);">
                            <p class="mt-24 mb-24 text-center" *ngIf="investigation?.Comments?.length === 0">There are no results to display.</p>

                            <ng-container *ngFor="let item of investigation?.Comments">
                                <kendo-card class="mb-16" [width]="'100%'">
                                    <kendo-card-body>
                                        <div class="column">
                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>Comment</small></h6>
                                                <h6 class="kendo-caption-body">
                                                    <small>{{item.showMore ? item.text : (item.text | slice: 0: 200)}}<span *ngIf="!item.showMore && item.text?.length >= 200">...</span></small>
                                                    <label class="show-more-less mb-0" *ngIf="item.text?.length >= 200" (click)="item.showMore = !item.showMore">{{ item?.showMore ? "Show Less" : "Show More"}}</label>
                                                </h6>
                                            </div>

                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>Date/Time</small></h6>
                                                <h6 class="kendo-caption-body">{{item?.createdAt | customDate: 'MM/dd/yyyy'}} at {{item?.createdAt | customDate: 'hh:mm a'}}</h6>
                                            </div>

                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>User</small></h6>
                                                <h6 class="kendo-caption-body">{{item?.CreatedBy | fullName}}</h6>
                                            </div>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </ng-container>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab [title]="TABS.HISTORY" [selected]="selectedTab === TABS.HISTORY">
                    <ng-template kendoTabTitle>
                        <span>History Log</span>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div class="list p-16"  style="min-height: calc(100vh - 122px);">
                            <p class="mt-8 mb-24 text-center" *ngIf="investigation?.History?.length === 0">There are no results to display.</p>
                            <ng-container *ngFor="let item of investigation?.History">
                                <kendo-card class="mb-16" [width]="'100%'">
                                    <kendo-card-body>
                                        <div class="column">
                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>Comment</small></h6>
                                                <h6 class="kendo-caption-body">
                                                    <small>{{item.showMore ? item.comment : (item.comment | slice: 0: 200)}}<span *ngIf="!item.showMore && item.comment?.length >= 200">...</span></small>
                                                    <label class="show-more-less mb-0" *ngIf="item.comment?.length >= 200" (click)="item.showMore = !item.showMore">{{ item?.showMore ? "Show Less" : "Show More"}}</label>
                                                </h6>
                                            </div>

                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>Date/Time</small></h6>
                                                <h6 class="kendo-caption-body">{{item?.createdAt | customDate: 'MM/dd/yyyy'}} at {{item?.createdAt | customDate: 'hh:mm a'}}</h6>
                                            </div>

                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>Category</small></h6>
                                                <h6 class="kendo-caption-body">{{item?.updateCategory | quiet}}</h6>
                                            </div>

                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>Type</small></h6>
                                                <h6 class="kendo-caption-body">{{item?.updateType | quiet}}</h6>
                                            </div>
                                            
                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>User</small></h6>
                                                <h6 class="kendo-caption-body">{{item?.CreatedBy | fullName}}</h6>
                                            </div>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </ng-container>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab [title]="TABS.REQUEST" [selected]="selectedTab === TABS.REQUEST">
                    <ng-template kendoTabTitle>
                        <span>Request Log</span>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div class="list p-16"  style="min-height: calc(100vh - 122px);">
                            <p class="mt-8 mb-24 text-center" *ngIf="investigation?.Requests?.length === 0">There are no results to display.</p>
                            <ng-container *ngFor="let item of investigation?.Requests">
                                <kendo-card class="mb-16" [width]="'100%'">
                                    <kendo-card-body>
                                        <div class="column">
                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>Comment</small></h6>
                                                <h6 class="kendo-caption-body">
                                                    <small>{{item.showMore ? item?.RecentHistory.comment : (item?.RecentHistory.comment | slice: 0: 200)}}<span *ngIf="!item.showMore && item?.RecentHistory.comment?.length >= 200">...</span></small>
                                                    <label class="show-more-less mb-0" *ngIf="item?.RecentHistory.comment?.length >= 200" (click)="item.showMore = !item.showMore">{{ item?.showMore ? "Show Less" : "Show More"}}</label>
                                                </h6>
                                            </div>

                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>Date/Time</small></h6>
                                                <h6 class="kendo-caption-body">{{item?.createdAt | customDate: 'MM/dd/yyyy'}} at {{item?.createdAt | customDate: 'hh:mm a'}}</h6>
                                            </div>

                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>Type</small></h6>
                                                <h6 class="kendo-caption-body">{{item?.RequestType?.name | quiet}}</h6>
                                            </div>

                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>Status</small></h6>
                                                <h6 class="kendo-caption-body">{{item?.RecentHistory?.name | quiet}}</h6>
                                            </div>

                                            <div class="equal">
                                                <h6 class="kendo-caption"><small>User</small></h6>
                                                <h6 class="kendo-caption-body">{{item?.CreatedBy | fullName}}</h6>
                                            </div>
                                        </div>
                                    </kendo-card-body>
                                </kendo-card>
                            </ng-container>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>
            </kendo-tabstrip>
        </section>

    </ng-template>
</kendo-drawer>