import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlInvestigationPriorityService } from 'src/app/shared/modules/graphql/services/investigation';
import {
  IApiAddInvestigationPriorityInput,
  IApiInvestigationPriorityFilter,

  IApiRemoveInvestigationPriorityInput, IApiUpdateInvestigationPriorityInput
} from 'src/app/shared/modules/graphql/types/types';
import { IApiInvestigationPriorityConnection } from '../../../modules/graphql/types/types';



@Injectable()
export class InvestigationPriorityService {

  constructor(
    private gqlInvestigationPriorityService: GqlInvestigationPriorityService
  ) { }

  public get(filters?: IApiInvestigationPriorityFilter[], options: IGqlBaseOptions = new ListPage()): Observable<IApiInvestigationPriorityConnection> {
    return this.gqlInvestigationPriorityService.getInvestigationPriority(filters, options);
  }

  public add(input: IApiAddInvestigationPriorityInput) {
    return this.gqlInvestigationPriorityService.addInvestigationPriority(input);
  }

  public remove(input: IApiRemoveInvestigationPriorityInput) {
    return this.gqlInvestigationPriorityService.removeInvestigationPriority(input);
  }

  public update(input: IApiUpdateInvestigationPriorityInput) {
    return this.gqlInvestigationPriorityService.updateInvestigationPriority(input);
  }

}
