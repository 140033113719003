import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  appliesTo
  nonStandard
  additionalDetails
  activated
  qbAccount
  createdAt
  updatedAt
  rate
  isFlatRate
  summary
`;

export const TimeEntryTypeConnectionQuery = (includeSummary = false) => gql`${buildConnectionQuery("TimeEntryType", "timeEntryTypeConnection", includeSummary ? nodeFragment : nodeFragment.replace("summary", ""))}`;

export const getTimeEntryTypeByIdQuery = gql`query getTimeEntryTypeById($id: String!) {
  getTimeEntryTypeById(id: $id) { ${ nodeFragment } }
}`;

export const addTimeEntryTypeMutation = gql`mutation AddTimeEntryType($input: AddTimeEntryTypeInput!) {
  addTimeEntryType(input: $input) { ${ nodeFragment } }
}`;

export const updateTimeEntryTypeMutation = gql`mutation UpdateTimeEntryType($input: UpdateTimeEntryTypeInput!) {
  updateTimeEntryType(input: $input) { ${ nodeFragment } }
}`;

export const removeTimeEntryTypeMutation = gql`mutation RemoveTimeEntryType($id: ID!) {
  removeTimeEntryType(id: $id)
}`;

export const toggleTimeEntryTypeActivationMutation = gql`mutation ToggleTimeEntryTypeActivation($id: ID!) {
  toggleTimeEntryTypeActivation(id: $id)
}`;

export const accountSummaryHoursReport = gql`
  query AccountSummaryHoursReport(
    $filters: [TimeEntryTypeFilter]
    $orderBy: TimeEntryTypeOrderBy
    $first: Int
    $last: Int
    $before: ID
    $after: ID
    $sortOrder: SortOrder
  ) {
    accountSummaryHoursReport(
      first: $first
      last: $last
      before: $before
      after: $after
      filters: $filters
      orderBy: $orderBy
      sortOrder: $sortOrder
    )
  }
`;