<kendo-dialog-titlebar (close)="onClose('closed')" class="hide-titlebar-actions">
    <div class="modal-title">
      <kendo-icon name="check-circle" size="large" themeColor="success"></kendo-icon>
      <span class="ml-8">Assignment Sent</span>
    </div>
</kendo-dialog-titlebar>
<P>
    Your assignment has been sent and received by the NEFCO team. We will review and conflict check the assignment
    as
    next steps and our team will be in touch. Please look for emails from noreply@nefco.net regarding this
    assignment.
    <br />
    <br />
    Need to speak with someone or give us further information about this assignment? Email <a
        href="mailto: services@nefco.net" class="kendo-anchor-tag">services@nefco.net</a> or call
    <a href="tel:+8006758500" class="kendo-anchor-tag">(800) 675-8500</a>
</P>
<kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-16" (click)="onClose('submitted')">Submit Another Investigation</button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onClose('go-back')">Go Back to Home</button>
</kendo-dialog-actions>