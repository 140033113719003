import { connectionQuery } from './../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiServicesConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  servicesConnectionQuery, addServiceMutation,
  removeServiceMutation, getServiceByIdQuery,
  updateServiceMutation
} from './service.operations';
import {
  IApiServiceFilter, IApiAddServiceInput, IApiUpdateServiceInput,
  IApiRemoveServiceInput, IApiService
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlServiceService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiServiceFilter[], options: IGqlBaseOptions): Observable<IApiServicesConnection> {
    return this.apollo.query(connectionQuery(servicesConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddServiceInput): Observable<IApiService> {
    return this.apollo.mutate({
      mutation: addServiceMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(id: string) {
    return this.apollo.mutate({
      mutation: removeServiceMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateServiceInput): Observable<IApiService> {
    return this.apollo.mutate({
      mutation: updateServiceMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getServiceById(id: string): Observable<IApiService> {
    return this.apollo.query({
      query: getServiceByIdQuery,
      variables: { id: id }
    }).pipe(
      safeResolve(true)
    );
  }
}
