import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  Request {
    id
  }
  createdAt
  updatedAt
`;

export const requestStatusConnectionQuery = gql`${buildConnectionQuery("RequestStatus", "requestStatusConnection", nodeFragment)}`;

export const addRequestStatusMutation = gql`mutation addRequestStatus($input: AddRequestStatusInput!) {
  addRequestStatus(input: $input) { ${nodeFragment} }
  }`;

export const removeRequestStatusMutation = gql`mutation removeRequestStatus($input: RemoveRequestStatusInput!) {
  removeRequestStatus(input: $input) { ${nodeFragment} }
}`;

export const updateRequestStatusMutation = gql`mutation updateRequestStatus($input: UpdateRequestStatusInput!) {
  updateRequestStatus(input: $input) { ${nodeFragment} }
}`;

export const getRequestStatusByIdQuery = gql`query getRequestStatusById($id: ID!) {
  getRequestStatusById(id: $id) {${nodeFragment}}
}`;


