import { connectionQuery } from './../../helpers.class';
import { IApiPhotoConnection, IApiPositionPhotoInput, IApiReplaceLowResPhotosInput } from './../../types/types';
import { photoConnectionQuery, addPhotoMutation, updatePhotoMutation, removePhotoMutation, positionPhotoMutation, uploadLowResPhotoMutation, replaceLowResPhotoMutation, addPhotoBulkMutation, updatePhotoBulkMutation, removePhotoBulkMutation } from './photo.operations';
import { IApiAddPhotoInput, IApiPhoto, IApiPhotoFilter, IApiUpdatePhotoInput } from '../../types/types';

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import { IGqlBaseOptions } from "../../interfaces";
import { safeResolve } from "../../rxjs";

@Injectable()
export class GqlPhotoService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getPhotos(filters: IApiPhotoFilter[], options: IGqlBaseOptions): Observable<IApiPhotoConnection> {
    return this.apollo.query(connectionQuery(photoConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addPhoto(input: IApiAddPhotoInput): Observable<IApiPhoto> {
    return this.apollo.mutate({
      mutation: addPhotoMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public addPhotoBulk(input: IApiAddPhotoInput[]): Observable<boolean> {
    return this.apollo.mutate({
      mutation: addPhotoBulkMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updatePhoto(input: IApiUpdatePhotoInput): Observable<IApiPhoto> {
    return this.apollo.mutate({
      mutation: updatePhotoMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updatePhotoBulk(input: IApiUpdatePhotoInput[]): Observable<boolean> {
    return this.apollo.mutate({
      mutation: updatePhotoBulkMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public uploadLowResPhoto(fileInput: File, input: IApiAddPhotoInput): Observable<IApiPhoto> {
    return this.apollo.mutate({
      mutation: uploadLowResPhotoMutation,
      variables: { 
        fileInput,
        input
      },
      context: {
        useMultipart: true
      }
    }).pipe(
      safeResolve(true)
    );
  }

  public replaceLowResPhotos(input: IApiReplaceLowResPhotosInput) {
    return this.apollo.mutate({
      mutation: replaceLowResPhotoMutation,
      variables: { input }
    });
  }

  public positionPhoto(input: IApiPositionPhotoInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: positionPhotoMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removePhoto(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removePhotoMutation,
      variables: {
        id
      }
    }).pipe(
      safeResolve(true)
    );
  }

  public removePhotoBulk(ids: string[]): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removePhotoBulkMutation,
      variables: {
        ids
      }
    }).pipe(
      safeResolve(true)
    );
  }
}