import { connectionQuery } from './../../../helpers.class';
import { photoFolderConnectionQuery, addPhotoFolderMutation, updatePhotoFolderMutation, removePhotoFolderMutation } from './photo-folder.operations';
import { IApiAddPhotoFolderInput, IApiPhotoFolder, IApiPhotoFolderFilter, IApiPhotoFolderFilterType, IApiUpdatePhotoFolderInput } from './../../../types/types';

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { IGqlBaseOptions } from "../../../interfaces";
import { safeResolve } from "../../../rxjs";

@Injectable()
export class GqlPhotoFolderService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getPhotoFolders(filters: IApiPhotoFolderFilter[], options: IGqlBaseOptions): Observable<IApiPhotoFolder[]> {
    const viewType = filters.find(obj => obj.type === IApiPhotoFolderFilterType.ViewList) || null;
    return this.apollo.query(connectionQuery(photoFolderConnectionQuery(viewType), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addPhotoFolder(input: IApiAddPhotoFolderInput): Observable<IApiPhotoFolder> {
    return this.apollo.mutate({
      mutation: addPhotoFolderMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updatePhotoFolder(input: IApiUpdatePhotoFolderInput): Observable<IApiPhotoFolder> {
    return this.apollo.mutate({
      mutation: updatePhotoFolderMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removePhotoFolder(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removePhotoFolderMutation,
      variables: {
        id
      }
    }).pipe(
      safeResolve(true)
    );
  }
}
