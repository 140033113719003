<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffContactInformationView" #staffContactInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationOwnView" #staffContactInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationView" #staffEmergencyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationOwnView" #staffEmergencyInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationView" #staffUserInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationOwnView" #staffUserInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsList" #staffCertificationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffInvestigationsList" #staffInvestigationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsCreate" #staffCertificationsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnList" #staffCertificationsOwnList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffInvestigationsOwnList" #staffInvestigationsOwnList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnCreate" #staffCertificationsOwnCreate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
    <!-- Main header section -->
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item class="mb-0">
            <h4 class="page-title">{{ staffName ? staffName : 'New Staff Member'}}</h4>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="text-right mb-0">
            <button kendoButton themeColor="light" (click)="back()" fillMode="solid"
            [iconClass]="'fa-solid fa-arrow-left-long fa-xl'">BACK</button>
        </kendo-gridlayout-item>
    </kendo-gridlayout>
    
    <!-- Tabs -->
    <div class="content-wrapper tabs">
        <div class="buttonContainer" *ngIf="staffCertificationsCreate.enabled || (staffCertificationsOwnCreate.enabled && isOwnerUser)">
            <button kendoButton themeColor="primary" (click)="addCertification()" fillMode="solid"
            [iconClass]="'fa fa-plus'">Add Certification</button>
        </div>
        <kendo-tabstrip class="staff-manage-tabs" #tabstrip [scrollable]="false" (tabSelect)="onTabChanged($event);">
            <kendo-tabstrip-tab [selected]="managementTabIndex === 0" [title]="'profile'"
            *ngIf="staffContactInformationView.enabled
                || staffContactInformationOwnView?.enabled
                || staffEmergencyInformationView?.enabled
                || staffEmergencyInformationOwnView?.enabled
                || staffUserInformationView?.enabled
                || staffUserInformationOwnView?.enabled">
                <ng-template kendoTabTitle>
                    <span>PROFILE</span>
                </ng-template>
                <ng-template kendoTabContent>
                <div>
                    <app-staff-profile *ngIf="managementTabIndex === 0" (staffName)="staffName = $event"></app-staff-profile>
                </div>
                </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [selected]="managementTabIndex === 1" [title]="'certifications'"
            *ngIf="staffCertificationsList.enabled || (staffCertificationsOwnList.enabled && isOwnerUser)">
                <ng-template kendoTabTitle>
                  <span>CERTIFICATIONS</span>
                </ng-template>
                <ng-template kendoTabContent>
                  <div>
                    <app-staff-certifications *ngIf="managementTabIndex === 1" #staffCert></app-staff-certifications>
                  </div>
                </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [selected]="managementTabIndex === 2" [title]="'investigations'" 
            *ngIf="(staffInvestigationsList.enabled || (staffInvestigationsOwnList.enabled && isOwnerUser)) && userConfig">
                <ng-template kendoTabTitle>
                  <span>INVESTIGATIONS</span>
                </ng-template>
                <ng-template kendoTabContent>
                  <div>
                    <app-investigations-table-new *ngIf="managementTabIndex === getCurrentTabIndex" [config]="userConfig"></app-investigations-table-new>
                  </div>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>
</section>
