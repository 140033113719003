import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiAddTimeEntryTypeInput, IApiTimeEntryTypeConnection, IApiUpdateTimeEntryTypeInput } from './../../../modules/graphql/types/types';
import { GqlTimeEntryTypeService } from '../../../modules/graphql/services/time-entry/time-entry-type/time-entry-type.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../../modules/graphql/interfaces/base.interfaces';
import { IApiTimeEntryTypeFilter } from "src/app/shared/modules/graphql/types/types";

@Injectable()
export class TimeEntryTypeService {

  constructor(
    private gqlTimeEntryTypeService: GqlTimeEntryTypeService
  ) { }

  public get(filters: IApiTimeEntryTypeFilter[] = [], options?: IGqlBaseOptions): Observable<IApiTimeEntryTypeConnection> {
    return this.gqlTimeEntryTypeService.getTimeEntryTypes(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddTimeEntryTypeInput) {
    return this.gqlTimeEntryTypeService.addTimeEntryType(input);
  }

  public update(input: IApiUpdateTimeEntryTypeInput) {
    return this.gqlTimeEntryTypeService.updateTimeEntryType(input);
  }

  public remove(id: string) {
    return this.gqlTimeEntryTypeService.removeTimeEntryType(id);
  }
  
  public toggleActivation(id: string) {
    return this.gqlTimeEntryTypeService.toggleTimeEntryTypeActivation(id);
  }

  public accountSummaryHoursReport(filters?: IApiTimeEntryTypeFilter[], options?: IGqlBaseOptions): Observable<string> {
    return this.gqlTimeEntryTypeService.accountSummaryHoursReport(filters, options || new ListPage(-1));
  }

}
