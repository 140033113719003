<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>{{model?.id ? 'Update' : 'Create'}} Expense Report Category</span>
    </div>
  </kendo-dialog-titlebar>
  
  <section>
    <form #form="ngForm" (submit)="onSubmit()">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Expense Report Category Name"></kendo-label>
            <kendo-textbox
              name="categoryName"
              #categoryName="ngModel"
              [(ngModel)]="model.name"
              placeholder="Expense Report Category Name"
              fillMode="outline"
              appNoWhiteSpace
              [clearButton]="false"
              [required]="true">
            </kendo-textbox>
            <kendo-formerror *ngIf="categoryName?.invalid && (categoryName?.dirty || categoryName?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="categoryName?.errors.required">Expense Report Category Name cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Purposes"></kendo-label>
            <kendo-multiselect 
            #purpose="ngModel" 
            [(ngModel)]="model.ExpensePurposeIds"
            name="purpose"
            [data]="expensePurposes"
            [checkboxes]="true"
            [valuePrimitive]="true"
            [autoClose]="false"
            textField="name" 
            valueField="id" 
            fillMode="outline"
            [tagMapper]="tagMapper"
            [placeholder]="'Select Purpose'"
            [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}"
            required>
              <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <span style="padding-left: 5px;">{{ dataItem?.name }}</span>
              </ng-template>
            </kendo-multiselect>
            <kendo-formerror *ngIf="!purpose?.valid && (purpose?.dirty || purpose?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="purpose?.errors?.required">
                Purpose cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Additional Details"></kendo-label>
            <kendo-textarea name="details" #details="ngModel" [(ngModel)]="model.details" 
            placeholder="Additional Details" fillMode="outline"
            class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
            <kendo-formerror *ngIf="details?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="details?.errors.required">Additional Details cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

    </form>
  </section>

  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSubmit()">
      Save
    </button>
  </kendo-dialog-actions>
</div>