<!-- Permissions -->
<div [appEnforcePermission]="permissionConfig.contactsProfileCompanyInformationView"
  #contactsProfileCompanyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsProfileContactInformationViewAssigned"
  #contactsProfileContactInformationViewAssigned="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsProfileContactInformationUpdate"
  #contactsProfileContactInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsProfileCompanyInformationUpdate"
  #contactsProfileCompanyInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.companiesViewAll" #companiesViewAll="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.companiesBranchesView" #companiesBranchesView="appEnforcePermission">
</div>
<!-- Permissions -->
<section class="content-wrapper">
  <button kendoButton themeColor="primary" class="right-edit" fillMode="link" iconClass="fa fa-pen right-edit"
    (click)="editContact();"
    *ngIf="contactsProfileContactInformationUpdate?.enabled || contactsProfileCompanyInformationUpdate?.enabled"></button>

  <kendo-card *ngIf="contactsProfileContactInformationViewAssigned?.enabled" [width]="'100%'" class="mt-16">
    <kendo-card-body>
      <div class="section-header">
        <h2 class="mb-0">Contact Information</h2>
      </div>
      <kendo-card [width]="'100%'" class="mt-16">
        <kendo-card-body class="p-0 inner-card-body card-detail">
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
            <kendo-gridlayout-item *ngIf="contact?.insuredName" class="mb-0">
              <span>
                Insured Name
              </span>
              <p class="mt-1">
                {{ contact.insuredName || '-'}}
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Name
              </span>
              <p class="mt-1">
                {{ (contact | fullName) || '-'}}
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Role
              </span>
              <p class="mt-1">
                {{ (contact?.Role?.name) || '-'}}
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Phones
              </span>
              <ng-container *ngIf="contact?.Phones?.length else noData">
                <app-email-phone-view *ngFor="let phone of contact?.Phones" [phone]="phone"></app-email-phone-view>
              </ng-container>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Emails
              </span>
              <ng-container *ngIf="contact?.Emails?.length else noData">
                <app-email-phone-view *ngFor="let email of contact?.Emails" [email]="email"></app-email-phone-view>
              </ng-container>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Address
              </span>
              <ng-container *ngIf="contact?.Addresses?.length else noData">
                <app-address-view *ngFor="let address of contact?.Addresses" [address]="address"></app-address-view>
              </ng-container>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Quickbooks List Id
              </span>
              <p class="mt-1">
                {{ contact?.qbListId || '-'}}
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Additional Information
              </span>
              <p class="mt-1">
                {{ contact?.additionalInformation || '-'}}
              </p>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </kendo-card-body>
      </kendo-card>
    </kendo-card-body>
  </kendo-card>
  <!-- Contact Information -->
  <!-- Branch Assignments -->
  <kendo-card *ngIf="contactsProfileCompanyInformationView?.enabled" [width]="'100%'" class="mt-16">
    <kendo-card-body>
      <div class="section-header">
        <h2 class="mb-0">Company Information</h2>
      </div>
      <kendo-card *ngFor="let branch of contact.BranchAssignments | sortByDate: 'endDate';" [width]="'100%'"
        class="mt-16">
        <kendo-card-body class="p-0 inner-card-body card-detail">
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
            <kendo-gridlayout-item class="mb-0">
              <span>
                Company Name
              </span>
              <p class="mt-1">
                <a [routerLink]="['/admin', 'companies', 'management', branch?.Branch?.Company?.id]"
                  class="text-decoration" [queryParams]="{tab: 'company'}"
                  *ngIf="companiesViewAll?.enabled else companyView"> {{
                  branch?.Branch?.Company?.name }} </a>
                <ng-template #companyView>
                  {{ branch?.Branch?.Company?.name }}
                </ng-template>
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Branch
              </span>
              <p class="mt-1">
                <a [routerLink]="['/admin', 'companies', 'management', branch?.Branch?.Company?.id]"
                  class="text-decoration" [queryParams]="{tab: 'branches', branchId: branch.Branch.id}"
                  *ngIf="companiesBranchesView?.enabled else branchView"> {{ branch.Branch.name }} </a>
                <ng-template #branchView>
                  {{branch?.Branch?.name}}
                </ng-template>
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Start Date
              </span>
              <p class="mt-1" [innerHTML]="(branch.startDate | customDate: 'MM/dd/yyyy') || '-'">
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Primary Address ({{getPrimary(branch?.Branch?.Addresses)?.Type?.name || '-'}})
              </span>
              <ng-container *ngIf="getPrimary(branch?.Branch?.Addresses) else noData">
                <app-address-view [address]="getPrimary(branch?.Branch?.Addresses)"></app-address-view>
              </ng-container>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Primary Phone
              </span>
              <ng-container *ngIf="getPrimary(branch?.Branch?.Phones) else noData">
                <app-email-phone-view [phone]="getPrimary(branch?.Branch?.Phones)"></app-email-phone-view>
              </ng-container>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                End Date
              </span>
              <p class="mt-1" [innerHTML]="branch.endDate? (branch.endDate | customDate: 'MM/dd/yyyy') : 'N/A'">
              </p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <span>
                Primary Email
              </span>
              <ng-container *ngIf="getPrimary(branch?.Branch?.Emails) else noData">
                <app-email-phone-view [email]="getPrimary(branch?.Branch?.Emails)"></app-email-phone-view>
              </ng-container>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </kendo-card-body>
      </kendo-card>
    </kendo-card-body>
  </kendo-card>
</section>
<ng-template #noData>
  <p class="mt-1">-</p>
</ng-template>
