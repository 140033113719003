import { AfterContentInit, AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, CanDeactivateService } from './shared/services';
import { DOCUMENT } from '@angular/common';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  public title = 'nefco-extranet';
  public portal = null;
  private observer: MutationObserver;
  public isMobile = Capacitor.isNativePlatform();
  public isAndroid = Capacitor.getPlatform() === 'android';
  public isIos = Capacitor.getPlatform() === 'ios';
  constructor(
    private router: Router,
    public authService: AuthService,
    private el: ElementRef,
    private renderer: Renderer2,
    private canDeactivateService: CanDeactivateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.portal = event.url.toLowerCase().includes("portal") || event.url.toLowerCase().includes("reset-password");
        this.observer = new MutationObserver(() => {
          this.setDialogClassOnBody();
        });
        setTimeout(() => {
          this.observeChanges();
        }, 1);
      }
    });
  }

  private setDialogClassOnBody() {
    const hasDialog = this?.el?.nativeElement.querySelector('.k-dialog-wrapper');
    if (hasDialog) {
      this.renderer.addClass(this.document.body, 'kendo-dialog-open');
    } else {
      this.renderer.removeClass(this.document.body, 'kendo-dialog-open');
    }
  }

  private observeChanges() {
    const config = { childList: true };
    const targetNode = this.document.getElementById('dialog-div'); // dialog div
    if (targetNode) {
      this.observer.observe(targetNode, config);
    } else {
      console.log('Target node not found for observation.');
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivateService.getCanDeactivate()) {
      $event.returnValue = true;
    }
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

}
