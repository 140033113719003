import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlRequestTypeService } from 'src/app/shared/modules/graphql/services';
import {
  IApiAddRequestTypeInput, IApiRequestType, IApiRemoveRequestTypeInput,
  IApiUpdateRequestTypeInput, IApiRequestTypeFilter, IApiRequestTypesConnection
} from 'src/app/shared/modules/graphql/types/types';


@Injectable()
export class RequestTypeService {

  constructor(
    private gqlRequestTypeService: GqlRequestTypeService
  ) { }

  public get(filters: IApiRequestTypeFilter[] = [], options?: IGqlBaseOptions): Observable<IApiRequestTypesConnection> {
    return this.gqlRequestTypeService.get(filters, options || new ListPage(-1));
  }

  public list(filters?: IApiRequestTypeFilter[], options?: IGqlBaseOptions): Observable<IApiRequestType[]> {
    return this.get(filters, options).pipe(
      map(results => results.edges.map(e => e.node))
    );
  }

  public add(input: IApiAddRequestTypeInput): Observable<IApiRequestType> {
    return this.gqlRequestTypeService.add(input);
  }

  public remove(input: IApiRemoveRequestTypeInput): Observable<boolean> {
    return this.gqlRequestTypeService.remove(input);
  }

  public update(input: IApiUpdateRequestTypeInput): Observable<IApiRequestType> {
    return this.gqlRequestTypeService.update(input);
  }

  public getRequestTypeById(id: string): Observable<IApiRequestType> {
    return this.gqlRequestTypeService.getRequestTypeById(id);
  }
}
