import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-kendo-alert",
  templateUrl: "./kendo-alert.component.html",
  styleUrls: ["./kendo-alert.component.scss"],
})
export class KendoAlertComponent {
  constructor() { }

  @Input() message!: String;
  @Input() routeLink!: Array<any>;
  @Input() routerLinkLabel!: String;
  @Input() iconClass: string = 'fa fa-lg fa-exclamation-triangle warning';
  @Input() showIcon: boolean = true;
  @Input() showCloseIcon: boolean = false;

  @Output() linkClicked = new EventEmitter();
  @Output() closeIcon = new EventEmitter();

  public linkClick(): void {
    this.linkClicked.emit();
  }

}
