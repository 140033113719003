<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffViewAll" #staffViewAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsList" #staffCertificationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffInvestigationsList" #staffInvestigationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnList" #staffCertificationsOwnList="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.staffInvestigationsOwnList" #staffInvestigationsOwnList="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationView"
  #staffEmergencyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationOwnView"
  #staffEmergencyInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationView" #staffUserInformationView="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.staffUserInformationOwnView"
  #staffUserInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationOwnView"
  #staffContactInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationView"
  #staffContactInformationView="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <h4 *ngIf="!config.permissionsView" class="page-title">Staff {{config.selectableMode ? 'Selection' : ''}}</h4>
  <div class="content-wrapper">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(7,minmax(0,1fr))']" *ngIf="!config.permissionsView">
      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label text="Position"></kendo-label>
          <kendo-dropdownlist [data]="selectOpts.positions" textField="title" valueField="title" fillMode="outline"
            [valuePrimitive]="true" [defaultItem]="{ title: 'All Position' }"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
            (selectionChange)="onPositionChange($event)">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span [ngClass]="dataItem?.title === 'All Position' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.title | quiet }} </span>
            </ng-template>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span [ngClass]="dataItem?.title === 'All Position' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.title | quiet }} </span>
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </kendo-gridlayout-item>

      <ng-container *ngIf="!config.selectableMode">
      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label text="Full/Part Time"></kendo-label>
          <kendo-dropdownlist [data]="[{key: 'Full Time', value: 'true'}, {key: 'Part Time', value: 'false'}]" textField="key" valueField="value" fillMode="outline"
            [valuePrimitive]="true" [defaultItem]="{ key: 'All', value: null }"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
            (selectionChange)="onTimeChange($event)">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span [ngClass]="dataItem?.key === 'All' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.key }} </span>
            </ng-template>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span [ngClass]="dataItem?.key === 'All' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.key }} </span>
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label text="Status"></kendo-label>
          <kendo-dropdownlist [data]="selectOpts.statuses | keyvalue" [(ngModel)]="statusFilter" textField="key" valueField="value" fillMode="outline"
            [valuePrimitive]="true" [defaultItem]="{ key: 'All', value: null }" 
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
            (selectionChange)="onStatusChange($event)">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span [ngClass]="dataItem?.key === 'All' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.key | quiet }} </span>
            </ng-template>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span [ngClass]="dataItem?.key === 'All' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.key | quiet }} </span>
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-state-select-kendo [label]="'State'" [placeholder]="'Select state'"
        (selectionChanged)="onStateChange($event)"></app-state-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label text="Certification"></kendo-label>
          <kendo-dropdownlist [data]="selectOpts.certifications" textField="title" valueField="id" fillMode="outline"
            [valuePrimitive]="true" [defaultItem]="{ title: 'All', id: null }" 
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
            (selectionChange)="onCertificationChange($event)">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span [ngClass]="dataItem?.title === 'All' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.title | quiet }} </span>
            </ng-template>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span [ngClass]="dataItem?.title === 'All' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.title | quiet }} </span>
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </kendo-gridlayout-item>
      </ng-container>

      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon">
          <kendo-label text="Search"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox
              name="search"
              [(ngModel)]="search"
              (appDebouncedInputChange)="onSearchChange()"
              (ngModelChange)="!search ? onSearchChange() : null"
              placeholder="Search"
              fillMode="outline"
              [clearButton]="true">
            </kendo-textbox>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item  *ngIf="!config.selectableMode" class="text-right">
        <div>
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button [appEnforcePermission]="authConfig.staffCreateConfig" 
            kendoButton themeColor="primary" fillMode="solid" size="medium" [iconClass]="'fa fa-plus'"
            (click)="createStaff()">New Staff</kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']"
      *ngIf="config.selectableMode">
      <kendo-gridlayout-item *ngFor="let sel of selection.selected">
        <kendo-chip class="fw600 mr-16" [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'"
          [removable]="true" (remove)="removeChip(sel)" style="width: 180px;">
          <span class="text-overflow">{{ sel | fullName }}</span>
        </kendo-chip>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    
    <kendo-grid
      class="staff-list-grid"
      [data]="{
        data: (dataSource?.contents$ | async) || [],
        total: (dataSource?.pageInfo$ | async)?.totalCount
      }"
      [pageSize]="pageOptions?.limit"
      [skip]="pageOptions?.offset"
      [pageable]="{
        buttonCount: 6,
        type: 'numeric',
        pageSizes: [5, 10, 25, 100],
        previousNext: true,
        responsive: true,
        position: 'bottom'
      }"
      (pageChange)="pageOptions?.paginate($event)"
      [sortable]="true"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      [navigatable]="false"
    >

      <kendo-grid-column field="Select" title="Select" [width]="5" [sortable]="false" *ngIf="config.selectableMode">
        <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
          <input type="checkbox" class="mr-16" kendoCheckBox (change)="masterToggle()"
            [checked]="selection.hasValue() && isAllSelected" [indeterminate]="selection.hasValue() && !isAllSelected" />
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" class="mr-16" kendoCheckBox (click)="$event.stopPropagation()"
            (change)="$event?.target?.checked ? selection.select(dataItem) : selection.deselect(dataItem)" [checked]="selection.isSelected(dataItem)" />
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="orderByOptions.Lastname" title="Name" [width]="15" [sortable]="true"
        [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span style="display: flex;">
              <span kendoTooltip position="right" [tooltipTemplate]="templateInfo" filter="i" tooltipClass="tooltipwidthauto"
              *ngIf="staffUserInformationView?.enabled && !config.selectableMode">
              <i class="fa-solid fa-bell fa-lg mr-8" style="cursor: pointer;" *ngIf="dataItem?.notificationEnabled"></i>
              <i class="fa-solid fa-bell-slash fa-lg mr-5" style="cursor: pointer;" *ngIf="!dataItem?.notificationEnabled"></i>
            </span>
            <ng-template #templateInfo>
              {{dataItem?.notificationEnabled ? 'Notifications Enabled': 'Notifications Disabled'}}
            </ng-template>
  
            <a class="text-decoration" [routerLink]="['/staff', dataItem.id]" *ngIf="profileView(dataItem.id); else nameOnly">{{dataItem |
              fullName}}</a>
            <ng-template #nameOnly>
              {{ dataItem | fullName }}
            </ng-template>
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="'Positions'" title="Position(s)" [width]="10" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.Positions?.length else noValue">
            <div class="mt-5" *ngFor="let position of dataItem.Positions" [innerHTML]="position.title | quiet"></div>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="'Address'" title="Address" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.Addresses?.length else noValue">
            <div class="mt-2" *ngFor="let address of dataItem.Addresses">
              {{address.Type.name}}: {{address.address1}}<br />
              {{address.city}}, {{address.state}}<br />
              {{address.postal}}, {{address.country}}
            </div>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="PHONE" title="Phone" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem?.Phones?.length; else noValue">
            <app-email-phone-view *ngFor="let phone of dataItem?.Phones | primarySort"
              [phone]="phone"></app-email-phone-view>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="EMAIL" title="Email" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem?.Emails?.length; else noValue">
            <app-email-phone-view *ngFor="let email of dataItem?.Emails" [email]="email"></app-email-phone-view>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="Certifications" title="Certifications" [width]="15" [sortable]="false" [headerClass]="'nocursor'"
      *ngIf="!config.selectableMode">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.Certifications?.length; else noValue">
            <div class="mt-5" *ngFor="let cert of dataItem.Certifications" [innerHTML]="cert?.Type?.title"></div>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="action" title="Actions" [width]="5" [sortable]="false" [headerClass]="'nocursor'" 
      *ngIf="!config.selectableMode">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container
            *ngIf="profileView(dataItem.id) || (staffCertificationsList.enabled || (staffCertificationsOwnList.enabled && authenticatedUserId === dataItem.id)) 
            || (staffInvestigationsList.enabled || (staffInvestigationsOwnList.enabled && authenticatedUserId === dataItem.id))">
            <kendo-dropdownbutton 
              [data]="filteredActionItems(dataItem)" 
              themeColor="primary" 
              fillMode="link"
              buttonClass="grid-action-items" 
              iconClass="fa-solid fa-ellipsis" 
              (itemClick)="itemSelected($event, dataItem)"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250,
                popupClass: 'grid-action-items-menu'
              }">
            </kendo-dropdownbutton>
          </ng-container>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>

    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']" *ngIf="config.selectableMode">
      <kendo-gridlayout-item class="text-right mt-16">
        <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="done.emit([])">
          Cancel
        </button>
        <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="save()">
          Save
        </button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <ng-template #noValue>
      <span>-</span>
    </ng-template>
  </div>
</section>
