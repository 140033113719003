import gql from 'graphql-tag';
import { connectionInputDefs } from '../../helpers.class';
import { buildConnectionQuery } from "../../helpers.class";
import { IApiTimeEntryFilterType } from '../../types/types';

const nodeFragment = `
  id
  Type {
    id
    isFlatRate
    name
    nonStandard
    qbAccount
    rate
    additionalDetails
    appliesTo
    createdAt
  }
  workday
  hours
  nonBillableHours
  description
  paidDate
  Investigation {
    id
    nefcoNumber
  }
  User {
    id
    firstName
    lastName
  }
  Status {
    id
    name
    createdAt
    updatedAt
  }
  InvoiceItems {
    id
    invoiceableId
  }
  createdAt
  updatedAt
`;

const ViewPayrollTotalFragment = `
  id
  Type {
    name
  }
  hours
  nonBillableHours
  paidDate
`;

const timeEntryTotalQuery = `
billableHours
nonBillableHours
totalHours
`;

const timeEntryTotalByTypeQuery = `
  summary {
    name
    hours
    hoursPaid
    hoursUnpaid
  }
`;


export const TimeEntryConnectionQuery = (isNode = false, view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiTimeEntryFilterType.ViewPayrollTotal:
        return gql`${buildConnectionQuery("TimeEntry", "timeEntryConnection", ViewPayrollTotalFragment)}`;
      case IApiTimeEntryFilterType.ViewBookkeepingView:
        return gql`${buildConnectionQuery("TimeEntry", "bookkeepingTimeEntryConnection", nodeFragment)}`;
      default:
        return gql`${buildConnectionQuery("TimeEntry", "timeEntryConnection", nodeFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("TimeEntry", "timeEntryConnection", nodeFragment)}`;
  }
};

export const getTimeEntryByIdQuery = gql`query getTimeEntryById($id: String!) {
  getTimeEntryById(id: $id) { ${nodeFragment} }
}`;

export const addTimeEntryMutation = gql`mutation AddTimeEntry($input: AddTimeEntryInput!) {
  addTimeEntry(input: $input) { ${nodeFragment} }
}`;

export const updateTimeEntryMutation = gql`mutation UpdateTimeEntry($input: UpdateTimeEntryInput!) {
  updateTimeEntry(input: $input) { ${nodeFragment} }
}`;

export const removeTimeEntryMutation = gql`mutation RemoveTimeEntry($id: ID!) {
  removeTimeEntry(id: $id)
}`;

export const approveTimeEntryMutation = gql`mutation ApproveTimeEntry($id: ID!) {
  approveTimeEntry(id: $id) { ${nodeFragment} }
}`;

export const markAllPaidTimeEntryMutation = gql`mutation MarkAllPaidTimeEntry($input: MarkAllPaidTimeEntryInput!) {
  markAllPaid(input: $input)
}`;

export const getTimeEntryTotal = gql`query TimeEntryTotal(
  $filters: [TimeEntryFilter],
  $orderBy: TimeEntryOrderBy,
  ${connectionInputDefs}
  ) {
    timeEntryTotal(
    first: $first
    last: $last
    before: $before
    after: $after
    filters: $filters
    orderBy: $orderBy
    sortOrder: $sortOrder
  ) {
     ${timeEntryTotalQuery}
  }
  }`;

export const timeEntryTotalByType = gql`query TimeEntryTotalByType(
  $filters: [TimeEntryFilter],
  $orderBy: TimeEntryOrderBy,
  ${connectionInputDefs}
  ) {
    timeEntryTotalByType(
    first: $first
    last: $last
    before: $before
    after: $after
    filters: $filters
    orderBy: $orderBy
    sortOrder: $sortOrder
  ) {
     ${timeEntryTotalByTypeQuery}
  }
  }`;
