import { filter, switchMap } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { Component, OnInit } from '@angular/core';

import { ExpenseCategoryDataSource } from 'src/app/shared/services/expenses';
import { ExpenseCategoryService } from 'src/app/shared/services/expenses/expense-category/expense-category.service';
import { ExpenseCategoryModalComponent } from '../expense-category-modal/expense-category-modal.component';
import { IApiExpenseCategory, IApiExpenseCategoryOrderBy } from "src/app/shared/modules/graphql/types/types";
import { manageExpenseReportCategoriesCreate, manageExpenseReportCategoriesUpdate, manageExpenseReportCategoriesDelete } from "../../../../shared/helpers/auth-config/manage.config";
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import _ from 'lodash';

@Component({
  selector: 'app-bookkeeping-expense-categories',
  templateUrl: './bookkeeping-expense-categories.component.html',
  styleUrls: ['./bookkeeping-expense-categories.component.scss']
})
export class BookkeepingExpenseCategoriesComponent implements OnInit {

  public authConfig = {
    manageExpenseReportCategoriesCreate,
    manageExpenseReportCategoriesUpdate,
    manageExpenseReportCategoriesDelete
  }

  private _dataSource: ExpenseCategoryDataSource;
  public get dataSource(): ExpenseCategoryDataSource {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public sort: SortDescriptor[] = [
    {
      field: IApiExpenseCategoryOrderBy.Name,
      dir: "asc",
    },
  ];
  public data: IApiExpenseCategory[];
  
  constructor(
    private loader: LoaderService,
    private notifications: NotificationsService,
    private expenseCategoryService: ExpenseCategoryService,
    private dialogService: DialogService) { }

  public addCategory() {
    const dialog: DialogRef = this.dialogService.open({
      content: ExpenseCategoryModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as ExpenseCategoryModalComponent;
    dialogInstance.data = null;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return _.isEmpty(v) ? false : !!v;
      })
    )
    .subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

  public editCategory(category: IApiExpenseCategory) {
    const dialog: DialogRef = this.dialogService.open({
      content: ExpenseCategoryModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as ExpenseCategoryModalComponent;
    dialogInstance.data = category;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return !_.isEmpty(v);
      })
    )
    .subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

  public deleteCategory(categoryId: string) {
    this.notifications.kendoConfirm("Are you sure you want to remove this Expense Category?", "Confirm delete").pipe(
      filter(v => !!v),
      switchMap(() => this.loader.show$(this.expenseCategoryService.remove(categoryId))),
      this.notifications.catchAlertPipe("Error removing Category."),
      this.notifications.snackbarPipe("Category deleted")
    ).subscribe(() => this.load());
  }

  ngOnInit() {
    // create the dataSource
    this._dataSource = new ExpenseCategoryDataSource(this.expenseCategoryService);
    this.loader.attachObservable(this._dataSource.loading$);
    this._dataSource.listPage.orderBy = IApiExpenseCategoryOrderBy.Name;
    this._dataSource.listPage.sortOrder = SortOrder.ASC;

    // populate the data
    this.load();

    this.dataSource?.contents$.subscribe((res) => {
      this.data = res;
    });

  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load([]);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiExpenseCategoryOrderBy.Name;
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  public itemSelected(action: { text: string }, dataItem: IApiExpenseCategory) {
    switch (action.text) {
      case "Update":
        this.editCategory(dataItem);
        break;
      case "Delete":
        this.deleteCategory(dataItem?.id);
        break;
      default:
        break;
    }
  }
}
