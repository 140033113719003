import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiInvestigationMetricsFilter, IApiInvestigationEditorTimelogConnection, IApiInvestigationEditorTimelog } from 'src/app/shared/modules/graphql/types/types';
import { InvestigationReportTimelogService } from './investigation-report-timelog.service';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class InvestigationReportTimelogDatasource extends NefcoPagedDataSource<IApiInvestigationEditorTimelog, InvestigationReportTimelogService, IApiInvestigationEditorTimelogConnection> implements DataSource<IApiInvestigationEditorTimelog> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationEditorTimelogConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
