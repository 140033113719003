<section>
    <h4 class="page-title">Access Restricted</h4>
    <div class="content-wrapper p-0">
        <div class="data-block">
            <h2>Access Restricted</h2>
            <p>You do not have access to this page. <br /> Please contact your NEFCO administrator for more information.</p>
            <kendo-button
                kendoButton
                themeColor="primary"
                fillMode="solid"
                size="medium"
                (click)="goToDashboard()"
                >Go Back to Dashboard</kendo-button
            >
        </div>
    </div>
</section>