import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CarriersListComponent } from './carriers-list/carriers-list.component';
import { CarriersManageModalComponent } from './carriers-manage-modal/carriers-manage-modal.component';


@NgModule({
  declarations: [
    CarriersListComponent,
    CarriersManageModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class CarriersModule {}
