<section *ngIf="company">
  <!-- Permissions -->
  <div [appEnforcePermission]="authConfig.companiesUpdateConfig" #companiesUpdateConfig="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesBillingRulesView" #companiesBillingRulesView="appEnforcePermission">
  </div>
  <div [appEnforcePermission]="authConfig.companiesEligeabilityView" #companiesEligeabilityView="appEnforcePermission">
  </div>
  <div [appEnforcePermission]="authConfig.companiesRequiredServicesView"
    #companiesRequiredServicesView="appEnforcePermission"></div>
  <!-- Permissions -->
  <section class="content-wrapper">
    <!-- Company Information -->
    <i class="fa fa-pen right-edit" *ngIf="companiesUpdateConfig?.enabled" (click)="editCompany();"></i>
    <kendo-card [width]="'100%'" class="mt-16">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">{{company?.name || '-'}}</h2>
        </div>
        <kendo-card *ngIf="!toggleCompanyInformation" [width]="'100%'" class="mt-16">
          <kendo-card-body class="p-0 inner-card-body card-detail">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Type
                </span>
                <p class="mt-1">
                  {{ company?.Type?.name || '-'}}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Description
                </span>
                <p class="mt-1">
                  {{ company?.description || '-'}}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Additional Information
                </span>
                <p class="mt-1" [innerHTML]="company?.additionalInfo || '-'">
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Permission to Enter Property Notification
                </span>
                <p class="mt-1">
                  {{ company.enterPropertyEnabled }}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Recorded interview notification
                </span>
                <p class="mt-1">
                  {{company.recordedInterviewNotification || '-'}}
                </p>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-card-body>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
    <!-- Billing Information -->
    <kendo-card [width]="'100%'" class="mt-16" *ngIf="companiesBillingRulesView?.enabled">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">Billing Information</h2>
        </div>
        <kendo-card [width]="'100%'" class="mt-16">
          <kendo-card-body class="p-0 inner-card-body card-detail">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Quickbooks List Id
                </span>
                <p class="mt-1">
                  {{ company.qbListId || '-'}}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Fee Schedule Overrides
                </span>
                <p class="mt-1">
                  <ng-container *ngIf="company.BillingRules?.length else noData">
                    <kendo-gridlayout *ngFor="let rule of company.BillingRules" [rows]="['auto']"
                      [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
                      <kendo-gridlayout-item class="mb-0">
                        <span>
                          Category
                        </span>
                        <p class="mt-1">
                          {{rule?.ExpenseItem?.id ? 'Expense' : 'Hours'}}
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <span>
                          Type
                        </span>
                        <p class="mt-1">
                          {{rule?.ExpenseItem?.id ? rule?.ExpenseItem?.name : rule?.TimeEntryType?.name}}
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <span>
                          Rate
                        </span>
                        <p class="mt-1">
                          {{rule.value | currency: 'USD'}}
                        </p>
                      </kendo-gridlayout-item>
                    </kendo-gridlayout>
                  </ng-container>
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Flat Rates for Standard Investigations
                </span>
                <p class="mt-1">
                  <ng-container *ngIf="company.FlatRates?.length else noData">
                    <kendo-gridlayout *ngFor="let flatRate of company?.FlatRates" [rows]="['auto']"
                      [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
                      <kendo-gridlayout-item class="mb-0">
                        <span>
                          Risk Type
                        </span>
                        <p class="mt-1">
                          {{flatRate?.Risk?.name || '-'}}
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <span>
                          Rate
                        </span>
                        <p class="mt-1">
                          {{flatRate.value | currency: 'USD'}}
                        </p>
                      </kendo-gridlayout-item>
                    </kendo-gridlayout>
                  </ng-container>
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Special Billing Instructions
                </span>
                <p class="mt-1">
                  {{ company?.billingInstructions || '-'}}
                </p>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-card-body>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
    <!-- Eligibility Information -->
    <kendo-card *ngIf="companiesEligeabilityView?.enabled" [width]="'100%'" class="mt-16">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">Eligibility Information</h2>
        </div>
        <kendo-card [width]="'100%'" class="mt-16">
          <kendo-card-body class="p-0 inner-card-body card-detail">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Certifications required by this company
                </span>
                <ng-container *ngIf="company.RequiredCertifications?.length else noData">
                  <p class="mt-1 mb-0" *ngFor="let cert of company.RequiredCertifications" [innerHTML]="cert.title">
                  </p>
                </ng-container>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Staff excluded from assginment
                </span>
                <ng-container *ngIf="company.ExcludedStaff?.length else noData">
                  <p class="mt-1 mb-0" *ngFor="let staff of company.ExcludedStaff" [innerHTML]="staff | fullName">
                  </p>
                </ng-container>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-card-body>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
    <!-- Services -->
    <kendo-card *ngIf="companiesRequiredServicesView?.enabled" [width]="'100%'" class="mt-16">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">Services</h2>
        </div>
        <kendo-card [width]="'100%'" class="mt-16">
          <kendo-card-body class="p-0 inner-card-body card-detail">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Services required by this company
                </span>
                <ng-container *ngIf="company.RequiredServices?.length else noData">
                  <p class="mt-1 mb-0" *ngFor="let service of company.RequiredServices;" [innerHTML]="service.name">
                  </p>
                </ng-container>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-card-body>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
    <!-- Main Office -->
    <kendo-card [width]="'100%'" class="mt-16">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">Main Office: {{company.MainOffice?.name}}</h2>
        </div>
        <kendo-card [width]="'100%'" class="mt-16">
          <kendo-card-body class="p-0 inner-card-body card-detail">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Address
                </span>
                <ng-container *ngIf="company.MainOffice?.Addresses?.length else noData">
                  <app-address-view *ngFor="let address of company.MainOffice?.Addresses"
                    [address]="address"></app-address-view>
                </ng-container>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Phone
                </span>
                <ng-container *ngIf="company.MainOffice?.Phones?.length else noData">
                  <app-email-phone-view *ngFor="let phone of company.MainOffice?.Phones"
                    [phone]="phone"></app-email-phone-view>
                </ng-container>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Email
                </span>
                <ng-container *ngIf="company.MainOffice?.Emails?.length else noData">
                  <app-email-phone-view *ngFor="let email of company.MainOffice?.Emails"
                    [email]="email"></app-email-phone-view>
                </ng-container>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
            <kendo-card *ngFor="let assignment of company.MainOffice?.ContactAssignments" [width]="'100%'" class="mt-16">
              <kendo-card-body class="p-0 inner-card-body card-detail">
                <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
                  <kendo-gridlayout-item class="mb-0">
                    <span>
                      Contact
                    </span>
                    <p class="mt-1">
                      <a [routerLink]="['/contacts', assignment?.Contact?.id]" class="text-decoration word-break"
                        [innerHTML]="assignment?.Contact | fullName"></a>
                    </p>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item class="mb-0">
                    <span>
                      Role
                    </span>
                    <p class="mt-1" [innerHTML]="assignment?.Contact?.Role?.name"></p>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item class="mb-0">
                    <span>
                      Phones
                    </span>
                    <ng-container *ngIf="assignment?.Contact?.Phones?.length else noData">
                      <app-email-phone-view *ngFor="let phone of assignment?.Contact?.Phones"
                        [phone]="phone"></app-email-phone-view>
                    </ng-container>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item class="mb-0">
                    <span>
                      Phones
                    </span>
                    <ng-container *ngIf="assignment?.Contact?.Emails?.length else noData">
                      <app-email-phone-view *ngFor="let email of assignment?.Contact?.Emails"
                        [email]="email"></app-email-phone-view>
                    </ng-container>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item class="mb-0">
                    <span>
                      Start Date
                    </span>
                    <p class="mt-1" [innerHTML]="assignment?.startDate | customDate: 'MM/dd/yyyy'"></p>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item class="mb-0">
                    <span>
                      End Date
                    </span>
                    <p class="mt-1"
                      [innerHTML]="assignment.endDate ? (assignment.endDate | customDate: 'MM/dd/yyyy') : 'N/A'"></p>
                  </kendo-gridlayout-item>
                </kendo-gridlayout>
              </kendo-card-body>
            </kendo-card>
          </kendo-card-body>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
  </section>

</section>
<ng-template #noData>
  <p class="mt-1">-</p>
</ng-template>
