import { LoaderService } from './../../../../shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, switchMap, debounceTime, tap } from 'rxjs/operators';
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from "@angular/forms";
import { IApiAddVehicleInput, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUpdateVehicleInput, IApiVehicle } from "src/app/shared/modules/graphql/types/types";
import { InvestigationService, VehicleService } from "src/app/shared/services";
import { DebouncedChangeDirective } from "src/app/shared/directives/debounced-change.directive";
import {
  investigationVehiclesCreate,
  investigationVehiclesUpdate,
  investigationVehiclesDelete,
} from "src/app/shared/helpers/auth-config/investigations.config";
@UntilDestroy()
@Component({
  selector: 'app-investigation-vehicle',
  templateUrl: './investigation-vehicle.component.html',
  styleUrls: ['./investigation-vehicle.component.scss']
})
export class InvestigationVehicleComponent {

  public authConfig = {
    investigationVehiclesCreate,
    investigationVehiclesUpdate,
    investigationVehiclesDelete,
  }
  private _investigationId: string;
  @Input() public set investigationId(value: string) {
    // TODO - use ReplaySubject here and mergeMap in form subscription to allow adding/editing before having an id
    // then updating when id is available
    this._investigationId = value;
  }
  public get investigationId(): string {
    return this._investigationId;
  }

  @Input() vehicle?: IApiVehicle;

  @Output() changed = new EventEmitter<IApiVehicle | null>();

  private get inputObject(): any {
    const { make, year, model, vinNumber, stockNumber } = this.vehicle;

    return {
      make,
      year: year.toString(),
      model,
      vinNumber,
      stockNumber
    };
  }
  private get insertObject(): IApiAddVehicleInput {
    return {
      InvestigationId: this.investigationId,
      ...this.inputObject
    };
  }

  private get updateObject(): IApiUpdateVehicleInput {
    return {
      id: this.vehicle.id,
      ...this.inputObject
    };
  }

  @ViewChild(DebouncedChangeDirective) formChanges;
  @ViewChild("form") form: NgForm;

  constructor(
    private vehicles: VehicleService,
    private notifications: NotificationsService,
    private loader: LoaderService,
    private investigationService: InvestigationService
  ) {
    if (!this.vehicle) this.initModel();
  }

  private initModel() {
    this.vehicle = {
      id: "",
      make: "",
      year: null,
      model: "",
      vinNumber: "",
      stockNumber: ""
    };

    if (this.form) this.form.resetForm();
  }

  public save() {
    this.loader.show$(
      !this.vehicle.id ? this.create() : this.update()
    ).subscribe(data => this.changed.emit(data));
  }

  private create() {
    return this.vehicles.create(this.insertObject).pipe(
      switchMap(() => {
        return this.investigationService.update({
          id: this.investigationId,
          History: {
            InvestigationId: this.investigationId,
            updateCategory: IApiInvestigationUpdateCategories.Vehicles,
            updateType: IApiInvestigationUpdateTypes.Create,
            comment: `Vehicle with VIN ${this.insertObject.vinNumber} added to investigation`
          }
        });
      }),
      // empty so the error can be shown to the user - i.e. if vin already exists
      this.notifications.catchAlertPipe(),
      tap(() => this.initModel())
    );
  }

  private update() {
    return this.vehicles.update(this.updateObject).pipe(
      switchMap(() => {
        return this.investigationService.update({
          id: this.investigationId,
          History: {
            InvestigationId: this.investigationId,
            updateCategory: IApiInvestigationUpdateCategories.Vehicles,
            updateType: IApiInvestigationUpdateTypes.Update,
            comment: `Vehicle with VIN ${this.insertObject.vinNumber} updated on investigation`
          }
        });
      }),
      this.notifications.catchAlertPipe()
    );
  }

  public delete() {
    if (!this.vehicle || !this.vehicle.id) return;

    this.notifications.confirm("Really delete this vehicle?").afterClosed().pipe(
      filter(v => !!v),
      switchMap(() => this.vehicles.delete(this.vehicle.id)),
      switchMap(() => {
        return this.investigationService.update({
          id: this.investigationId,
          History: {
            InvestigationId: this.investigationId,
            updateCategory: IApiInvestigationUpdateCategories.Vehicles,
            updateType: IApiInvestigationUpdateTypes.Delete,
            comment: `Vehicle with VIN ${this.insertObject.vinNumber} removed from investigation`
          }
        });
      }),
      this.notifications.catchAlertPipe("Error deleting Vehicle")
    ).subscribe(() => this.changed.emit(null));
  }

}
