import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsModule } from './contacts/contacts.module';
import { InvestigationsModule } from './investigations/investigations.module';
import { SharedModule } from '../../shared/shared.module';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { CompaniesModule } from "./companies/companies.module";
import { CertificationsModule } from "./certifications/certifications.module";
import { StaffModule } from './staff/staff.module';
import { BookkeepingModule } from './bookkeeping/bookkeeping.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { TimeAndExpModule } from "./time-and-exp/time-and-exp.module";
import { NotificationsModule } from "./notifications/notifications.module";
import { DocumentsModule } from './documents/documents.module';
import { CarriersModule } from './carriers/carriers.module';
import { EvidenceModule } from './evidence/evidence.module';
import { TypoGraphyComponent } from './typo-graphy/typo-graphy.component';
import { SettingsModule } from './settings/settings.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    TypoGraphyComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContactsModule,
    CompaniesModule,
    CertificationsModule,
    CarriersModule,
    InvestigationsModule,
    DocumentsModule,
    StaffModule,
    BookkeepingModule,
    DashboardModule,
    EvidenceModule,
    TimeAndExpModule,
    NotificationsModule,
    SettingsModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    ContactsModule,
    ContactsModule,
    CarriersModule,
    CompaniesModule,
    CertificationsModule,
    InvestigationsModule,
    StaffModule,
    BookkeepingModule,
    DashboardModule,
    EvidenceModule,
    TimeAndExpModule,
    NotificationsModule,
    SettingsModule,
  ]
})
export class SharedComponentModule { }
