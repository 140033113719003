import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { addTimeEntryTypeMutation, removeTimeEntryTypeMutation, TimeEntryTypeConnectionQuery, updateTimeEntryTypeMutation, toggleTimeEntryTypeActivationMutation, accountSummaryHoursReport } from "./time-entry-type.operations";
import { IApiAddTimeEntryTypeInput, IApiTimeEntry, IApiTimeEntryTypeConnection, IApiTimeEntryTypeFilter, IApiTimeEntryTypeFilterType, IApiUpdateTimeEntryTypeInput } from "../../../types/types";

@Injectable()
export class GqlTimeEntryTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getTimeEntryTypes(filters: IApiTimeEntryTypeFilter[], options: IGqlBaseOptions): Observable<IApiTimeEntryTypeConnection> {
    const includeSummary = filters.find(obj => (obj.type === IApiTimeEntryTypeFilterType.Summary));
    return this.apollo.query(connectionQuery(TimeEntryTypeConnectionQuery(includeSummary ? true : false), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addTimeEntryType(input: IApiAddTimeEntryTypeInput): Observable<IApiTimeEntry> {
    return this.apollo.mutate({
      mutation: addTimeEntryTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public updateTimeEntryType(input: IApiUpdateTimeEntryTypeInput): Observable<IApiTimeEntry> {
    return this.apollo.mutate({
      mutation: updateTimeEntryTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeTimeEntryType(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeTimeEntryTypeMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public toggleTimeEntryTypeActivation(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: toggleTimeEntryTypeActivationMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public accountSummaryHoursReport(filters: IApiTimeEntryTypeFilter[], options: IGqlBaseOptions): Observable<string> {
    return this.apollo.query(connectionQuery(accountSummaryHoursReport, filters, options)).pipe(
      safeResolve(true)
    );
  }
}
