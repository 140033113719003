import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, Input, OnInit } from '@angular/core';
import { IApiAddEvidenceStorageLocationInput, IApiAddressType, IApiEvidenceStorageLocation, IApiEvidenceStorageLocationType, IApiPhoneType, IApiUpdateEvidenceStorageLocationInput } from 'src/app/shared/modules/graphql/types/types';
import { AddressTypeService, PhoneTypeService } from "src/app/shared/services";
import { EvidenceStorageLocationService } from 'src/app/shared/services/evidence';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  templateUrl: './evidence-storage-location-modal.component.html',
  styleUrls: ['./evidence-storage-location-modal.component.scss']
})
export class EvidenceStorageLocationModalComponent extends DialogContentBase implements OnInit {
  @Input() public data: IApiEvidenceStorageLocation
  public locationTypeNames = IApiEvidenceStorageLocationType;

  public phoneTypes: IApiPhoneType[];
  public addressTypes: IApiAddressType[];

  public model: IApiAddEvidenceStorageLocationInput | IApiUpdateEvidenceStorageLocationInput;

  constructor(
    private notifications: NotificationsService,
    private evidenceStorageLocationService: EvidenceStorageLocationService,
    public dialogRef: DialogRef,
    public _phoneTypes: PhoneTypeService,
    public _addressTypes: AddressTypeService,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    const {
      id = "",
      name = "",
      type = "STORAGE",
      isActive = true,
      additionalDetails = "",
      isNefco = false,
      contactName = "",
      companyName = "",
      Address = null,
      Phone = null,
    } = this.data || {};

    const {
      Type: AddressType,
      address1,
      address2,
      address3,
      city,
      state,
      postal,
      country = "United States"
    } = Address || {};

    const {
      Type: PhoneType,
      label = "Main",
      number
    } = Phone || {};

    this.model = {
      name,
      type,
      isActive,
      additionalDetails,
      isNefco,
      contactName,
      companyName,
      Address: {
        TypeId: AddressType ? AddressType.id : null,
        address1: address1 || '',
        address2: address2 || '',
        address3: address3 || '',
        city: city || '',
        state: state || '',
        postal: postal || '',
        country
      },
      Phone: {
        TypeId: PhoneType ? PhoneType.id : null,
        label,
        number: number || ''
      }
    };

    this._phoneTypes.get().pipe(
      unwrapConnection()
    ).subscribe(types => {
      this.phoneTypes = types;
      if (!this.model.Phone.TypeId) this.model.Phone.TypeId = types.find(({ name: _name }) => _name === "Work").id;
    });

    this._addressTypes.get().pipe(
      unwrapConnection()
    ).subscribe(types => {
      this.addressTypes = types;
      if (!Address) this.model.Address.TypeId = types.find(({ name: _name }) => _name === "Shipping").id;
    });
  }

  onSubmit() {
    const { id = null } = this.data || {};
    const data = { ...this.model };
    if (!data.Phone.number) {
      delete data.Phone;
    }
    if (id) this.save(data);
    else this.add(data);
  }

  close(result = false): void {
    this.dialogRef.close(result);
  }

  private save(data: any) {
    this.evidenceStorageLocationService.update({
      id: this.data.id,
      ...data,
      hasDetails: data.hasDetails ? 1 : 0
    }).pipe(
      this.notifications.catchAlertPipe("Error updating Evidence Storage Location")
    ).subscribe((res) => this.dialogRef.close(true));
  }

  private add(data: any) {
    this.evidenceStorageLocationService.add({ ...data, hasDetails: data.hasDetails ? 1 : 0, isActive: true } as IApiAddEvidenceStorageLocationInput).pipe(
      this.notifications.catchAlertPipe("Error adding Evidence Storage Location")
    ).subscribe((res) => this.dialogRef.close(true));
  }

}
