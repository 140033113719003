import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DrawerComponent, SelectEvent } from '@progress/kendo-angular-layout';
import { IApiInvestigation } from 'src/app/shared/modules/graphql/types/types';
import { MobileAddCommentModalComponent } from '../mobile-add-comment-modal/mobile-add-comment-modal.component';
import { filter } from 'rxjs/operators';
import _ from 'lodash';

export enum TABS {
  "COMMENT" = "COMMENT",
  "HISTORY" = "HISTORY",
  "REQUEST" = "REQUEST"
};

@Component({
  selector: "app-mobile-investigation-log-tabs",
  templateUrl: "./mobile-investigation-log-tabs.component.html",
  styleUrls: ["./mobile-investigation-log-tabs.component.scss"],
})
export class MobileInvestigationLogTabsComponent implements OnInit {
  @ViewChild("drawer") drawer: DrawerComponent;

  private _investigation: IApiInvestigation;
  public set investigation(val: IApiInvestigation) {
    this._investigation = val;
  }
  public get investigation(): IApiInvestigation {
    return this._investigation;
  }
  public TABS = TABS;
  public selectedTab = TABS.COMMENT as string;
  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
  ) {
    this.investigation = this.activatedRoute.snapshot.data.investigation;
  }

  ngOnInit(): void {
    console.log();
  }

  addComment() {
    const dialog: DialogRef = this.dialogService.open({
      content: MobileAddCommentModalComponent,
      width: "92%",
      cssClass: "add-comment",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as MobileAddCommentModalComponent;
    dialogInstance.data = this.investigation;
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) ? false : !!v;
        })
      )
      .subscribe((res: any) => {
        if (res?.id) {
          this.investigation.Comments.unshift(res);
        }
      });
  }

  public close(): void {
    this.drawer.toggle(false);
    this.addBodyClass(false);
    this.location.back();
  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove("kendo-dialog-open");
    }
  }

  public changeTab(event: SelectEvent = { title: this.selectedTab } as any) {
    window?.scrollTo(0, 0);
    this.selectedTab = event?.title || "";
  }
  
}
