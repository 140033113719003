import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ExpenseItemService, ExpenseCategoryService, ExpenseApplicationService, ExpenseTypeService } from 'src/app/shared/services';
import { IApiAddExpenseItemInput, IApiExpenseApplication, IApiExpenseCategory, IApiExpenseItem, IApiExpenseType, IApiUpdateExpenseItemInput } from "src/app/shared/modules/graphql/types/types";
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { forkJoin } from 'rxjs';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NgForm } from '@angular/forms';

@Component({
  templateUrl: './expense-item-modal.component.html',
  styleUrls: ['./expense-item-modal.component.scss']
})
export class ExpenseItemModalComponent extends DialogContentBase implements OnInit {
  @ViewChild('form', { static: false }) form: NgForm;
  @Input() data: IApiExpenseItem;

  public expenseCategories: IApiExpenseCategory[];
  public expenseApplications: IApiExpenseApplication[];
  public expenseTypes: IApiExpenseType[];

  public model: IApiUpdateExpenseItemInput = {
    id: "",
    name: "",
    ExpenseCategoryId: "",
    ExpenseTypeId: "",
    ExpenseApplicationId: "",
    description: '',
    active: false
  };

  constructor(
    private expenseItemService: ExpenseItemService,
    private expenseApplicationService: ExpenseApplicationService,
    private expenseCategoryService: ExpenseCategoryService,
    private expenseTypeService: ExpenseTypeService,
    private notifications: NotificationsService,
    private loaderService: LoaderService,
    public dialog: DialogRef,  
  ) {
    super(dialog);
  }

  private save() {
    this.loaderService.show$(this.expenseItemService.update({
      ...this.model,
      id: this.data.id,
      name: this.model.name?.trim(),
      description: this.model.description?.trim()
    })).pipe(
      this.notifications.catchAlertPipe("Error updating expense item."),
      this.notifications.catchAlertPipe("Expense item updated.")
    ).subscribe((res) => this.dialog.close(res));
  }

  private add() {
    const createData = { ...this.model };
    delete createData.id;
    this.loaderService.show$(this.expenseItemService.add(createData as IApiAddExpenseItemInput)).pipe(
      this.notifications.catchAlertPipe("Error adding expense item."),
      this.notifications.snackbarPipe("Expense item created.")
    ).subscribe((res) => this.dialog.close(res));
  }

  onSubmit() {
    if (this.form?.invalid) {
      this.form.form.markAllAsTouched();
      return;
    };
    const { id = null } = this.data || {};
    if (id) this.save();
    else this.add();
  }

  close() {
    this.dialog.close();
  }

  ngOnInit() {
    const {
      id = "",
      name = "",
      ExpenseCategory: { id: ExpenseCategoryId = "" } = {},
      ExpenseType: { id: ExpenseTypeId = "" } = {},
      AppliesTo: { id: ExpenseApplicationId = "" } = {},
      description = ''
    } = this.data || {};

    this.loaderService.show$(forkJoin([
      this.expenseCategoryService.get().pipe(unwrapConnection()),
      this.expenseApplicationService.get().pipe(unwrapConnection()),
      this.expenseTypeService.get().pipe(unwrapConnection())
    ])).subscribe(([expenseCategories, expenseApplications, expenseTypes]) => {
      this.expenseCategories = expenseCategories;
      this.expenseApplications = expenseApplications;
      this.expenseTypes = expenseTypes;

      this.model = {
        id,
        name,
        ExpenseCategoryId,
        ExpenseTypeId,
        ExpenseApplicationId,
        description,
        active: !id
      };
    });

  }
}
