import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { manageCertificationsCreate } from "../../../../shared/helpers/auth-config/manage.config";
import { TabStripComponent } from '@progress/kendo-angular-layout';


@Component({
  templateUrl: './certifications-management.component.html',
  styleUrls: ['./certifications-management.component.scss']
})
export class CertificationsManagementComponent implements AfterViewInit  {
  @ViewChild('tabstrip') public tabstrip: TabStripComponent;

  public authConfig = {
    manageCertificationsCreate
  }

  constructor() { }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => this.tabstrip.selectTab(0));
  }
}
