import { unwrapConnection } from "src/app/shared/rxjs.pipes";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from "@angular/core";
import { IInvestigation } from "src/app/shared/interfaces/investigation.interfaces";
import { TimeEntryTypeService } from "../../../../shared/services/time-entry/time-entry-type/time-entry-type.service";
import {
  IApiTimeEntryType,
  IApiUpdateTimeEntryInput,
} from "src/app/shared/modules/graphql/types/types";
import { NgForm } from "@angular/forms";
import { untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime } from "rxjs/operators";
import { NefcoDateHelper } from "src/app/shared/helpers/nefco-date.class";
import { MatCheckboxChange } from "@angular/material/checkbox";
import moment from "moment";
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { IApiInvestigationStaffMember } from "../../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component";
import { ExpenseActionType } from "src/app/shared/modules/graphql/enums/expense.enums";
import { timeEntryInvestigationHoursCreate, timeEntryInvestigationHoursList, timeEntryUnlinkedHoursCreate } from "src/app/shared/helpers/auth-config/time-expenses.config";
import { AuthService, UserService } from "src/app/shared/services";
import { AutoCompleteComponent } from "@progress/kendo-angular-dropdowns";
@Component({
  selector: "app-ind-time-form-kendo",
  templateUrl: "./ind-time-form-kendo.component.html",
  styleUrls: ["./ind-time-form-kendo.component.scss"],
})
export class IndTimeFormKendoComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("autoCompleteTypeId", { static: false }) public autoCompleteTypeId: AutoCompleteComponent;

  public authConfig = {
    timeEntryUnlinkedHoursCreate,
    timeEntryInvestigationHoursList,
    timeEntryInvestigationHoursCreate
  }

  private _unlinked = false;

  private _timeEntry: IApiUpdateTimeEntryInput = {
    id: null,
    TypeId: null,
    workday: new Date(),
    hours: null,
    nonBillableHours: null,
    UserId: null,
    InvestigationId: null,
    description: "",
    paidDate: null,
  };

  private customTechDescription =
    "Technical Review in accordance with NFPA 921 section 4.6.2 Technical Review";

  public get timeEntry() {
    return this._timeEntry;
  }

  @Input() public set timeEntry(val) {
    this._timeEntry = val;
    if (val?.paidDate) {
      this._timeEntry.paidDate = NefcoDateHelper.toLocal(
        this._timeEntry.paidDate
      );
      this.paidCheckbox = true;
    }
    if (!val.InvestigationId && val.id) {
      this._unlinked = true;
    }
  }

  @ViewChild("timeEntryTypeForm") form: NgForm;
  @ViewChild("timeEntryInvestigationHoursCreate") timeEntryInvestigationHoursCreatePermission;
  @ViewChild("timeEntryUnlinkedHoursCreate") timeEntryUnlinkedHoursCreatePermission;

  @Input() actionType: ExpenseActionType;
  @Input() investigations: IInvestigation[];
  @Input() investigationStaffList: IApiInvestigationStaffMember[];
  @Input() investigationView = false;
  @Input() adminView = false;
  @Input() modalView = false;
  @Input() editQtyOnly = false;
  @Input() UserId = '';
  @Output() formSubmitted = new EventEmitter<IApiUpdateTimeEntryInput>();
  // For embedded views
  @Output() timeChange = new EventEmitter<IApiUpdateTimeEntryInput>();

  public timeEntryTypes: IApiTimeEntryType[] = [];
  private timeEntryTypesCopy: IApiTimeEntryType[] = [];
  public selectedTypeName:string;
  public typeIdsearch: string;
  ngAfterViewInit(): void {
    if (this.actionType === "ADD_TIME") {
      this._unlinked = false;
    }
    if (!this.timeEntryInvestigationHoursCreatePermission?.enabled || !this.timeEntryUnlinkedHoursCreatePermission?.enabled) {
      if (!this.timeEntryInvestigationHoursCreatePermission?.enabled && this.timeEntryUnlinkedHoursCreatePermission?.enabled) {
        this._unlinked = true;
      }
    }
    this.form.valueChanges
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.timeChange.emit(this.timeEntry);
      });
  }

  public disabledDates = (date: Date): boolean => {
    return moment(date).isAfter();
  };

  ngOnInit() {
    this.getTimeEntryTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.UserId?.currentValue) {
      this.getStaffWithPermissionRole(changes.UserId?.currentValue);
    }
  }

  public getStaffWithPermissionRole(id) {
    this.loader.show$(this.userService.getByIdWithPermissionRoleAndState(id)).subscribe(user => {
      this.setDefaultTimeEntryTypeForUser(user);
    })
  }

  public get unlinked(): boolean {
    return this._unlinked;
  }

  public set unlinked(val: boolean) {
    this._unlinked = val;
    this.timeEntry.TypeId = null;
    this.selectedTypeName = '';
    if (val === true) {
      this.timeEntry.InvestigationId = null;
      this.timeEntry.hours = 0;
    } else {
      this.timeEntry.hours = 0;
    }
  }

  // Visible Time Entry Types
  // public get visibletimeEntryTypes() {
  //   if (this.timeEntryTypes) {
  //     return this.timeEntryTypes.filter(
  //       (item) =>
  //         item?.appliesTo !== (this.unlinked ? "Investigation" : "Unlinked")
  //     );
  //   } else {
  //     return [];
  //   }
  // }

  public togglePaid({ checked }: MatCheckboxChange) {
    if (checked)
      this._timeEntry.paidDate = NefcoDateHelper.currentPayPeriod().payDate;
    else this._timeEntry.paidDate = null;
  }

  public isPaidDate(date: Date) {
    return NefcoDateHelper.isPaidDate(date);
  }

  public paidCheckbox: boolean = false;

  constructor(
    private timeEntryTypeService: TimeEntryTypeService,
    private loader: LoaderService,
    private auth: AuthService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
  ) { }

  public onSubmit() {
    if (!this.isFormValid()) {
      return;
    }
    const timeEntry = {
      ...this.timeEntry,
      hours: this.timeEntry.hours || 0,
      nonBillableHours: this.timeEntry.nonBillableHours || 0,
    };
    this.formSubmitted.emit(timeEntry);
    this.form.form.reset();
    this.timeEntry.description = "";
  }

  // Set custom description on type change
  public typeChange(selectedId: string) {
    this.cdr.detectChanges();
    this.autoCompleteTypeId?.toggle(false);
    this._timeEntry.TypeId = selectedId || null;
    const foundType = this.timeEntryTypes.find(({ id }) => id === this._timeEntry.TypeId);
    this.selectedTypeName = foundType?.name || '';
    if (!this.timeEntry.id) {
      if (
        foundType?.name === "Investigation Hours: Tech Review Engineer" ||
        foundType?.name === "Investigation Hours: Tech Review Investigator"
      ) {
        this._timeEntry.description = this.customTechDescription;
      } else {
        this._timeEntry.description = "";
      }
    }
  }

  private getTimeEntryTypes() {
    this.loader
      .show$(this.timeEntryTypeService.get([]))
      .pipe(unwrapConnection())
      .subscribe((results) => {
        this.timeEntryTypes = results;
        this.timeEntryTypesCopy = results;
        if (!this.adminView)
          this.setDefaultTimeEntryTypeForLogin()
        if (this.timeEntry?.TypeId)
          this.typeChange(this.timeEntry.TypeId);
      });
  }

  sortTimeEntries(userRoleEntries){
    this.timeEntryTypes = this.timeEntryTypesCopy;
    if(userRoleEntries?.length){
      const userRoleEntriesArray = this.timeEntryTypes?.filter(entry => userRoleEntries?.includes(entry.name)) || [];
      const tempTimeEntries = this.timeEntryTypes?.filter(entry => !userRoleEntries?.includes(entry.name)) || [];
      this.timeEntryTypes = [...userRoleEntriesArray, ...tempTimeEntries];
    }
  }

  setDefaultTimeEntryTypeForUser(u) {
    const userEntryType = u?.PermissionRoles[0]?.TimeEntryType;
    const states = u?.States;
    const userRoleEntries = u?.PermissionRoles?.map(role => role?.TimeEntryType?.name);
    this.sortTimeEntries(userRoleEntries);
    if (!this._timeEntry?.id && userEntryType?.id) {
      const match = this.findMatchingString(userEntryType?.name, this.timeEntryTypes?.filter(type => type.id !== userEntryType?.id)?.map(type => type.name));
      if (states?.includes('CA') && match) {
        const matchType = this.timeEntryTypes?.find(t => t.name === match);
        this._timeEntry.TypeId = matchType?.id;
        this.typeChange(this._timeEntry.TypeId);
      } else {
        this._timeEntry.TypeId = userEntryType?.id;
        this.typeChange(this._timeEntry.TypeId);
      }
    }
  }

  public setDefaultTimeEntryTypeForLogin() {
    this.auth.authenticatedUser.subscribe((u) => {
      this.setDefaultTimeEntryTypeForUser(u);
    });
  }

  public isFormValid(): boolean {
    this.form.control.markAllAsTouched();
    return this.form.form.valid;
  }

  public changePaid(e) {
    if (!e) {
      this._timeEntry.paidDate = null;
    }
  }

  private findMatchingString(type, types) {
    const words = new Set(type?.split(' '));

    for (const str of types) {
      const strWords = new Set(str.split(' '));

      const isMatch = [...words].every(word => strWords.has(word));

      if (isMatch) {
        return str;
      }
    }

    return null;
  }

}
