import { ApiConnection } from '../../decorators/@ApiConnection.decorator';
import { InvoiceService } from './invoice.service';
import { DataSource } from "@angular/cdk/collections";
import { IApiInvoice, IApiInvoiceConnection, IApiInvoiceFilter } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class InvoiceDataSource extends NefcoPagedDataSource<IApiInvoice, InvoiceService, IApiInvoiceConnection> implements DataSource<IApiInvoice> {
  @ApiConnection()
  public load(filters?: IApiInvoiceFilter[]): Promise<IApiInvoiceConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
