import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  Requests {
    id
  }
  createdAt
  updatedAt
`;

export const requestTypesConnectionQuery = gql`${buildConnectionQuery("RequestType", "requestTypesConnection", nodeFragment)}`;

export const addRequestTypeMutation = gql`mutation createRequestType($input: AddRequestTypeInput!) {
  addRequestType(input: $input) { ${nodeFragment} }
  }`;

export const removeRequestTypeMutation = gql`mutation removeRequestType($input: RemoveRequestTypeInput!) {
  removeRequestType(input: $input) { ${nodeFragment} }
}`;

export const updateRequestTypeMutation = gql`mutation updateRequestType($input: UpdateRequestTypeInput!) {
  updateRequestType(input: $input) { ${nodeFragment} }
}`;

export const getRequestTypeByIdQuery = gql`query getRequestTypeById($id: ID!) {
  getRequestTypeById(id: $id) {${nodeFragment}}
}`;
