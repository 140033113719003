import { AdminModuleRouter } from './admin.router';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedComponentModule } from "../shared/shared-component.module";
import { PermissionsModule } from "./permissions/permissions.module";
import { DocumentTemplatingComponent } from './document-templating/document-templating.component';
import { DownloadMobileAppComponent } from './download-mobile-app/download-mobile-app.component';

@NgModule({
  declarations: [
    DocumentTemplatingComponent,
    DownloadMobileAppComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedComponentModule,
    AdminModuleRouter,
    PermissionsModule,
    ReactiveFormsModule
  ],
  exports: [
    SharedComponentModule
  ]
})
export class AdminModule { }
