import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { GqlInvestigationRoleService } from '../../../modules/graphql/services/investigation/investigation-role/investigation-role.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { IApiInvestigationRole, IApiInvestigationRoleNames } from '../../../modules/graphql/types/types';
import { ReplaySubject } from "rxjs";

@Injectable()
export class InvestigationRoleService {
  private _roles$ = new ReplaySubject<IApiInvestigationRole[]>(1);

  constructor(
    private roleService: GqlInvestigationRoleService
  ) {
    // Included limit to get all of the roles. Otherwise, only returns the first 25.
    this.roleService.getInvestigationRoles([], { limit: -1 }).pipe(
      unwrapConnection()
    ).subscribe(roles => this._roles$.next(roles));
  }

  public get investigationRoles(): Observable<IApiInvestigationRole[]> {
    return this._roles$;
  }

  public investigationRoleByName(roleName: IApiInvestigationRoleNames): Observable<IApiInvestigationRole> {
    return this.investigationRoles.pipe(
      map(investigationRoles => investigationRoles.find(r => r.title === roleName)),
    );
  }

  public investigationRoleById(roleId: string): Observable<IApiInvestigationRole> {
    return this.investigationRoles.pipe(
      map(investigationRoles => investigationRoles.find(r => r.id === roleId)),
    );
  }

}
