<h3>Email</h3>
<kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
  <kendo-gridlayout-item *ngIf="!config.hideType">
    <kendo-formfield>
      <kendo-label [ngClass]="'asterisk'" text="Type"></kendo-label>
      <kendo-dropdownlist [data]="emailTypes" textField="name" valueField="id" fillMode="outline"
        [valuePrimitive]="false" [(ngModel)]="value.Type" [ngModelOptions]="{standalone: true}" [name]="'type' + name"
        [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" #type="ngModel" required
        [disabled]="config.static">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <span [ngClass]="dataItem?.id ? 'not-placeholder' : 'placeholder'"> {{ dataItem?.name}} </span>
        </ng-template>
      </kendo-dropdownlist>
      <kendo-formerror *ngIf="!type?.valid && (type?.dirty || type?.touched)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span *ngIf="type?.errors?.required">
          Type cannot be left blank
        </span>
      </kendo-formerror>
    </kendo-formfield>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item [colSpan]="2">
    <kendo-formfield>
      <kendo-label class="asterisk" text="Email"></kendo-label>
      <kendo-textbox appNoWhiteSpace appEmailValidator [name]="'email' + name" fillMode="outline" #email="ngModel" [clearButton]="false"
        placeholder="Enter Email" [(ngModel)]="value.address" [maxlength]="255" [disabled]="config.static" required="true">
      </kendo-textbox>
      <kendo-formerror *ngIf="!email?.valid && (email?.dirty || email?.touched)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span *ngIf="email?.errors?.required">
          Email cannot be left blank
        </span>
        <span *ngIf="email?.errors?.email">
          A valid email must be entered
        </span>
      </kendo-formerror>
    </kendo-formfield>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item *ngIf="!config.hidePrimary">
    <kendo-formfield class="remove">
      <kendo-label text=""></kendo-label>
      <div class="k-checkbox-wrap">
        <input #notRequired kendoCheckBox type="checkbox" [name]="'isPrimary' + name" [(ngModel)]="value.isPrimary" />
        <kendo-label [for]="notRequired" class="k-checkbox-label font-weight-600 checkbox-margin"
          text="Primary"></kendo-label>
      </div>
      <div>
        <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-circle-xmark fa-lg"
          (click)="remove.emit(true)"></button>
      </div>
    </kendo-formfield>
  </kendo-gridlayout-item>
</kendo-gridlayout>
