<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>{{model?.id ? 'Update' : 'Create'}} Time Entry Type</span>
    </div>
  </kendo-dialog-titlebar>

  <section>
    <form #form="ngForm" (submit)="onSubmit()">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Time Entry Type Name"></kendo-label>
            <kendo-textbox
              name="timeEntryTypeName"
              #timeEntryTypeName="ngModel"
              [(ngModel)]="model.name"
              placeholder="Time Entry Type Name"
              fillMode="outline"
              appNoWhiteSpace
              [clearButton]="false"
              [required]="true"
              cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror *ngIf="timeEntryTypeName?.invalid && (timeEntryTypeName?.dirty || timeEntryTypeName?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="timeEntryTypeName?.errors.required">Time entry type name cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Applies to"></kendo-label>
            <kendo-dropdownlist [data]="timeEntryTypeAppliesTo" name="AppliesTo" #AppliesTo="ngModel" [(ngModel)]="model.appliesTo"
              [valuePrimitive]="true" fillMode="outline" textField="key" valueField="value"
              [defaultItem]="{key: 'Select Applies to', value: null}" [popupSettings]="{appendTo: 'component', animate: false}" required>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.value ? 'not-placeholder': 'placeholder'"> {{ dataItem.key }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!AppliesTo?.valid && (AppliesTo?.dirty || AppliesTo?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="AppliesTo?.errors?.required">
                Applies to cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Quick Book Account Name"></kendo-label>
            <kendo-textbox
              name="qbAccount"
              #qbAccount="ngModel"
              [(ngModel)]="model.qbAccount"
              placeholder="Quick Book Account Name"
              fillMode="outline"
              appNoWhiteSpace
              [clearButton]="false"
              [required]="false"
              cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror *ngIf="qbAccount?.invalid && (qbAccount?.dirty || qbAccount?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="qbAccount?.errors.required">Quick book account name cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item style="display: flex; align-items: center; justify-content: left;">
          <kendo-formfield>
            <div class="k-checkbox-wrap">
              <input
                #isNonStandard
                type="checkbox"
                kendoCheckBox
                name="nonstandard"
                [(ngModel)]="model.nonStandard"
              />
              <kendo-label
                [for]="isNonStandard"
                class="k-checkbox-label font-weight-600 checkbox-margin"
                style="margin-bottom: 0"
                text="Non-standard hours type"
              ></kendo-label>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="2">
          <kendo-formfield>
            <kendo-label text="Additional Details"></kendo-label>
            <kendo-textarea name="details" #details="ngModel" [(ngModel)]="model.additionalDetails" 
            placeholder="Additional Details" fillMode="outline"
            class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
            <kendo-formerror *ngIf="details?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="details?.errors.required">Additional Details cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </section>

  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSubmit()">
      Save
    </button>
  </kendo-dialog-actions>
</div>
