<!-- Permissions -->

<!-- START MANAGE -->
<div [appEnforcePermission]="authConfig.manage" #manage="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpenseReportCategoriesList" #manageExpenseReportCategoriesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpenseItemsList" #manageExpenseItemsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpensePurposesList" #manageExpensePurposesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageTimeEntryTypesList" #manageTimeEntryTypesList="appEnforcePermission"></div>
<!-- END MANAGE -->

<!-- START BOOKKEEPING -->
<div [appEnforcePermission]="authConfig.bookKeepingEvidenceBilling" #bookKeepingEvidenceBilling="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesEntryListHours" #bookKeepingTimeExpensesEntryListHours="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesEntryListExpensesMileage" #bookKeepingTimeExpensesEntryListExpensesMileage="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesHoursAccountSummary" #bookKeepingTimeExpensesHoursAccountSummary="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesExpensesMileageAccountSummary" #bookKeepingTimeExpensesExpensesMileageAccountSummary="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingInvoiceList" #bookKeepingInvoiceList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingPayroll" #bookKeepingPayroll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingExpenseReports" #bookKeepingExpenseReports="appEnforcePermission"></div>
<!-- END BOOKKEEPING -->

<!-- START INVESTIGATION -->
<div [appEnforcePermission]="authConfig.investigation" #investigation="appEnforcePermission"></div>
<!-- END INVESTIGATION -->

<!-- START REPORTS -->
<div [appEnforcePermission]="authConfig.companyInvestigationAndEvidenceReport" #companyInvestigationAndEvidenceReport="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companyInvestigationReport" #companyInvestigationReport="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationTimelogPacing" #investigationTimelogPacing="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationReportTimelog" #investigationReportTimelog="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.quarterlyUtilizationReport" #quarterlyUtilizationReport="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.quarterlyHourReport" #quarterlyHourReport="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.reportSentMetric" #reportSentMetric="appEnforcePermission"></div>

<!-- START REPORT METRICS-->
<div [appEnforcePermission]="authConfig.investigationMetricBasicCharts" #investigationMetricBasicCharts="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricInvestigator" #investigationMetricInvestigator="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricState" #investigationMetricState="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricBillToCompany" #investigationMetricBillToCompany="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricClientCompany" #investigationMetricClientCompany="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricTechReviewer" #investigationMetricTechReviewer="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricContact" #investigationMetricContact="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricRisktype" #investigationMetricRisktype="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricDetermination" #investigationMetricDetermination="appEnforcePermission"></div>
<!-- END REPORT METRICS-->
<!-- END REPORTS -->

<!-- START EVIDENCE-->
<div [appEnforcePermission]="authConfig.evidence" #evidence="appEnforcePermission"></div>
<!-- END EVIDENCE-->

<!-- START CONTACTS-->
<div [appEnforcePermission]="authConfig.companies" #companies="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contacts" #contacts="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staff" #staff="appEnforcePermission"></div>
<!-- START CONTACTS-->

<!-- Permissions -->

<nav class="nav" *ngIf="user && !portal">
  <a routerLink="/">Dashboard</a>
  <a [matMenuTriggerFor]="investigationsMenu" *ngIf="investigation?.enabled
  || investigationMetricBasicCharts?.enabled
  || investigationMetricInvestigator?.enabled
  || investigationMetricState?.enabled
  || investigationMetricBillToCompany?.enabled
  || investigationMetricClientCompany?.enabled
  || investigationMetricTechReviewer?.enabled
  || investigationMetricContact?.enabled
  || investigationMetricRisktype?.enabled
  || investigationMetricDetermination?.enabled
  || companyInvestigationAndEvidenceReport?.enabled
  || companyInvestigationReport?.enabled
  || investigationReportTimelog?.enabled
  || investigationTimelogPacing?.enabled
  || quarterlyUtilizationReport?.enabled
  || quarterlyHourReport?.enabled
  || reportSentMetric?.enabled">Investigations</a>
  <a routerLink="/evidence/list" *ngIf="evidence?.enabled">Evidence</a>
  <a [matMenuTriggerFor]="bookkeepingMenu" *ngIf="bookKeepingEvidenceBilling?.enabled
  || bookKeepingTimeExpensesEntryListHours?.enabled
  || bookKeepingTimeExpensesEntryListExpensesMileage?.enabled
  || bookKeepingTimeExpensesHoursAccountSummary?.enabled
  || bookKeepingTimeExpensesExpensesMileageAccountSummary?.enabled
  || bookKeepingInvoiceList?.enabled
  || bookKeepingPayroll?.enabled
  || bookKeepingExpenseReports?.enabled">Bookkeeping</a>
  <a [matMenuTriggerFor]="contactsMenu" *ngIf="companies?.enabled || contacts?.enabled || staff?.enabled">Contacts</a>
  <a [matMenuTriggerFor]="manageMenu" *ngIf="manage?.enabled">Manage</a>
</nav>

<mat-menu #investigationsMenu="matMenu">
  <button *ngIf="investigation?.enabled" mat-menu-item routerLink="/admin/investigations/list">Investigations List</button>
    <button *ngIf="investigationMetricBasicCharts?.enabled
      || investigationMetricInvestigator?.enabled
      || investigationMetricState?.enabled
      || investigationMetricBillToCompany?.enabled
      || investigationMetricClientCompany?.enabled
      || investigationMetricTechReviewer?.enabled
      || investigationMetricContact?.enabled
      || investigationMetricRisktype?.enabled
      || investigationMetricDetermination?.enabled
      || companyInvestigationAndEvidenceReport?.enabled
      || companyInvestigationReport?.enabled
      || investigationReportTimelog?.enabled
      || investigationTimelogPacing?.enabled
      || quarterlyUtilizationReport?.enabled
      || quarterlyHourReport?.enabled
      || reportSentMetric?.enabled" mat-menu-item [matMenuTriggerFor]="levelOne">Metrics</button>
    <mat-menu #levelOne="matMenu" >
      <button mat-menu-item routerLink="/report/evidence" *ngIf="companyInvestigationAndEvidenceReport?.enabled">Company & Evidence Report</button>
      <button mat-menu-item routerLink="/report/investigations" *ngIf="companyInvestigationReport?.enabled">Company Investigation Report</button>
      <button mat-menu-item routerLink="/report/investigations-metrics"
      *ngIf="investigationMetricBasicCharts?.enabled
      || investigationMetricInvestigator?.enabled
      || investigationMetricState?.enabled
      || investigationMetricBillToCompany?.enabled
      || investigationMetricClientCompany?.enabled
      || investigationMetricTechReviewer?.enabled
      || investigationMetricContact?.enabled
      || investigationMetricRisktype?.enabled
      || investigationMetricDetermination?.enabled"
      >Investigation Metrics</button>
      <button mat-menu-item routerLink="/report/investigations-report-timelog" *ngIf="investigationReportTimelog?.enabled">Investigation Report Timelog</button>
      <button mat-menu-item routerLink="/report/report-timelog" *ngIf="investigationTimelogPacing?.enabled">Report Timelog</button>
      <button mat-menu-item routerLink="/report/quarterly-utilization-report" *ngIf="quarterlyUtilizationReport?.enabled">Quarterly Utilization Report</button>
      <button mat-menu-item routerLink="/report/quarterly-hour-report" *ngIf="quarterlyHourReport?.enabled">Quarterly Hour Report</button>
      <button mat-menu-item routerLink="/report/report-sent-metric" *ngIf="reportSentMetric?.enabled">Report Sent Metric</button>
    </mat-menu>
</mat-menu>

<mat-menu #bookkeepingMenu="matMenu">
  <button mat-menu-item routerLink="/admin/bookkeeping/evidence/billing" *ngIf="bookKeepingEvidenceBilling?.enabled">Evidence Billing</button>
  <button mat-menu-item routerLink="/admin/bookkeeping/time" *ngIf="bookKeepingTimeExpensesEntryListHours?.enabled
    || bookKeepingTimeExpensesEntryListExpensesMileage?.enabled
    || bookKeepingTimeExpensesHoursAccountSummary?.enabled
    || bookKeepingTimeExpensesExpensesMileageAccountSummary?.enabled">Time &amp; Expenses</button>
  <button mat-menu-item routerLink="/admin/bookkeeping/invoice/list" *ngIf="bookKeepingInvoiceList?.enabled">Invoice List</button>
  <button mat-menu-item routerLink="/admin/bookkeeping/payroll" *ngIf="bookKeepingPayroll?.enabled">Payroll</button>
  <button mat-menu-item routerLink="/admin/bookkeeping/expenses" *ngIf="bookKeepingExpenseReports?.enabled">Expense Reports</button>
</mat-menu>

<mat-menu #contactsMenu="matMenu">
  <button mat-menu-item routerLink="/admin/companies/list" *ngIf="companies?.enabled">Companies</button>
  <button mat-menu-item routerLink="/admin/contacts/list" *ngIf="contacts?.enabled">Contacts</button>
  <button mat-menu-item routerLink="/admin/staff/list" *ngIf="staff?.enabled">Staff</button>
</mat-menu>

<mat-menu #manageMenu="matMenu">
  <button mat-menu-item routerLink="/admin/settings" *ngIf="manageExpenseReportCategoriesList?.enabled || manageExpenseItemsList?.enabled || manageExpensePurposesList?.enabled  || manageTimeEntryTypesList?.enabled">Settings</button>
  <button mat-menu-item routerLink="/admin/bookkeeping/management" *ngIf="manageExpenseReportCategoriesList?.enabled || manageExpenseItemsList?.enabled || manageExpensePurposesList?.enabled  || manageTimeEntryTypesList?.enabled">Bookkeeping</button>
  <button mat-menu-item routerLink="/admin/carriers/list" [appEnforcePermission]="authConfig.manageCarriersList">Carriers</button>
  <button mat-menu-item routerLink="/admin/certifications/manage" [appEnforcePermission]="authConfig.manageCertificationsList">Certifications</button>
  <button mat-menu-item routerLink="/admin/documents/list" [appEnforcePermission]="authConfig.manageDocumentTypes">Document Types</button>
  <button mat-menu-item routerLink="/admin/evidence/manage" [appEnforcePermission]="authConfig.manageEvidenceLocationsList">Evidence Locations</button>
  <button mat-menu-item routerLink="/admin/permissions/list" [appEnforcePermission]="authConfig.manageUserRolesList">Permissions</button>
  <button mat-menu-item routerLink="/admin/templating" [appEnforcePermission]="authConfig.manageDocumentTemplatingView">Document Templating</button>
</mat-menu>
