import { ApiConnection } from '../../decorators/@ApiConnection.decorator';
import { TimeEntryService } from './time-entry.service';
import { DataSource } from "@angular/cdk/collections";
import { IApiTimeEntry, IApiTimeEntryConnection, IApiTimeEntryFilter } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class TimeEntryDataSource extends NefcoPagedDataSource<IApiTimeEntry, TimeEntryService, IApiTimeEntryConnection> implements DataSource<IApiTimeEntry> {
  @ApiConnection()
  public load(filters?: IApiTimeEntryFilter[]): Promise<IApiTimeEntryConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
