import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-feature-unavailable',
  templateUrl: './mobile-feature-unavailable.component.html',
  styleUrls: ['./mobile-feature-unavailable.component.scss']
})
export class MobileFeatureUnavailableComponent {

  constructor(private location: Location) { }
  back(){
    this.location.back();
  }
}
