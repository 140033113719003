import { IApiUser } from './../../../../shared/modules/graphql/types/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from './../../../../shared/services/authorization/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { IApiDocument } from "src/app/shared/modules/graphql/types/types";
import { DownloadHelper } from 'src/app/shared/services';

@UntilDestroy()
@Component({
  selector: 'app-investigation-report-o365-launch',
  templateUrl: './investigation-report-o365-launch.component.html',
  styleUrls: ['./investigation-report-o365-launch.component.scss']
})
export class InvestigationReportO365LaunchComponent {

  public user: IApiUser;
  public cookieUrl = '';

  constructor(
    private auth: AuthService,
    private dialogRef: MatDialogRef<InvestigationReportO365LaunchComponent>,
    @Inject(MAT_DIALOG_DATA) public report: IApiDocument,
    private downloadHelper: DownloadHelper,
  ) {
    // iFrame URL to pass info into Office o365 addin for cookie
    this.cookieUrl = `https://d2urwrrqk4nn6k.cloudfront.net/cookiepass.html?docId=${report?.id}&investId=${report?.Investigation?.id}&nefcoNum=${report?.Investigation?.nefcoNumber}`;
    this.auth.authenticatedUser.pipe(
      untilDestroyed(this),
    ).subscribe(user => this.user = user);
   }

  public launchReport() {
    window.open(this.report.uri);
    this.dialogRef.close();
  }

}
