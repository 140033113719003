import { connectionQuery } from './../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { updateVehicleMutation } from './vehicle.operations';
import {
  IApiVehicleFilter,
  IApiAddVehicleInput,
  IApiVehicleConnection,
  IApiUpdateVehicleInput,
  IApiVehicle
} from 'src/app/shared/modules/graphql/types/types';
import {
  vehicleConnectionQuery,
  addVehicleMutation,
  removeVehicleMutation
} from './vehicle.operations';

@Injectable()
export class GqlVehicleService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getVehicles(filters: IApiVehicleFilter[], options: IGqlBaseOptions): Observable<IApiVehicleConnection> {
    return this.apollo.query(connectionQuery(vehicleConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addVehicle(input: IApiAddVehicleInput): Observable<IApiVehicle> {
    return this.apollo.mutate({
      mutation: addVehicleMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeVehicle(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeVehicleMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateVehicle(input: IApiUpdateVehicleInput): Observable<IApiVehicle> {
    return this.apollo.mutate({
      mutation: updateVehicleMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
