import { GqlInvestigationTemplateDownloadService } from '../../../modules/graphql/services/investigation/investigation-template-downloads/investigation-template-download.service';
import { IApiTemplateDownloadGenerateInput, IApiPhotoDownloadInput, IApiEvidenceListDownloadByIdInput, IApiPayrollDownloadInput } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class InvestigationTemplatDownloadService {

  constructor(
    private templates: GqlInvestigationTemplateDownloadService
  ) { }

  public generateTemplateDownload(generateInput: IApiTemplateDownloadGenerateInput): Observable<string> {
    return this.templates.generateTemplateDownload(generateInput);
  }

  public generatePhotoDownload(photoDownloadInput: IApiPhotoDownloadInput): Observable<string> {
    return this.templates.generatePhotoDownload(photoDownloadInput);
  }
  public generateEvidenceListCSV(investigationId: string): Observable<string> {
    return this.templates.generateEvidenceListCSV(investigationId);
  }
  public generateEvidenceListCSVbyId(generateInput: IApiEvidenceListDownloadByIdInput): Observable<string> {
    return this.templates.generateEvidenceListCSVbyId(generateInput);
  }
  public generatePayrollListCSV(generateInput: IApiPayrollDownloadInput): Observable<string> {
    return this.templates.generatePayrollListCSV(generateInput);
  }
  public templateData(): Observable<string> {
    return this.templates.templateData();
  }
}
