import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/enums/permissions.enums";

export const companies: IEnforcePermissionConfig = {
  category: PermissionCategories.COMPANIES,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const companiesViewAll: IEnforcePermissionConfig = {
  category: PermissionCategories.COMPANIES,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const companiesCreateConfig = {
  ...companies,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const companiesUpdateConfig = {
  ...companiesViewAll,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  }
};

export const companiesBillingRulesView = {
  category: PermissionCategories.COMPANIES_BILLING_RULES,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesBillingRulesCreate = {
  category: PermissionCategories.COMPANIES_BILLING_RULES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesBillingRulesUpdate = {
  category: PermissionCategories.COMPANIES_BILLING_RULES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesBillingRulesDelete = {
  category: PermissionCategories.COMPANIES_BILLING_RULES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesEligeabilityView = {
  category: PermissionCategories.COMPANIES_ELIGIBILITY,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesEligeabilityCreate = {
  category: PermissionCategories.COMPANIES_ELIGIBILITY,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesEligeabilityUpdate = {
  category: PermissionCategories.COMPANIES_ELIGIBILITY,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesEligeabilityDelete = {
  category: PermissionCategories.COMPANIES_ELIGIBILITY,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesRequiredServicesView = {
  category: PermissionCategories.COMPANIES_REQUIRED_SERVICES,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesRequiredServicesCreate = {
  category: PermissionCategories.COMPANIES_REQUIRED_SERVICES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesRequiredServicesUpdate = {
  category: PermissionCategories.COMPANIES_REQUIRED_SERVICES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesRequiredServicesDelete = {
  category: PermissionCategories.COMPANIES_REQUIRED_SERVICES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesBranchesList = {
  category: PermissionCategories.COMPANIES_BRANCHES,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesBranchesView = {
  category: PermissionCategories.COMPANIES_BRANCHES,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesBranchesCreate = {
  category: PermissionCategories.COMPANIES_BRANCHES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesBranchesUpdate = {
  category: PermissionCategories.COMPANIES_BRANCHES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesBranchesDelete = {
  category: PermissionCategories.COMPANIES_BRANCHES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};

export const companiesDelete = {
  category: PermissionCategories.COMPANIES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
};
