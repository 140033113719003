<section>
  <h4>Payroll</h4>
  <mat-tab-group (selectedIndexChange)="selectedTabIndex = $event">
    <mat-tab label="Payroll Review">
      <ng-template matTabContent>
        <div class="content">
          <app-bookkeeping-payroll-filter [payPeriod]="payPeriod" [maxDate]="maxDate"
            (dateRangeChange)="onDateRangeChanged($event)" (userSelectionChange)="userSelectionChange($event)"
            (downloadCsv)="downloadCsv($event)" (markAsPaid)="markAsPaid()" [selectedTimeEntries]="selectedTimeEntries"
            (markAllAsPaid)="markAllAsPaid()" [selectedTab]="'payroll-review'"></app-bookkeeping-payroll-filter>
          <app-bookkeeping-payroll-review [userId]="selectedUser" [payPeriod]="payPeriod" [reload]="reloadChild"
            [(selectAllEntries)]="selectAllEntries" (payTimeEntries)="toggleSelected($event)"
            (selectReset)="selectReset()" (_selectAllEntriesChanged)="_selectAllEntriesChanged($event)"></app-bookkeeping-payroll-review>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Payroll Entry List">
      <ng-template matTabContent>
        <div class="content">
          <app-bookkeeping-payroll-filter [payPeriod]="payPeriod" [maxDate]="maxDate"
            (dateRangeChange)="onDateRangeChanged($event)" (userSelectionChange)="userSelectionChange($event)"
            (downloadCsv)="downloadCsv($event)" (markAsPaid)="markAsPaid()" [selectedTimeEntries]="selectedTimeEntries"
            (markAllAsPaid)="markAllAsPaid()" [selectedTab]="'payroll-entry-list'"></app-bookkeeping-payroll-filter>
          <app-bookkeeping-payroll-entry-list [userId]="selectedUser" [payPeriod]="payPeriod" [reload]="reloadChild"></app-bookkeeping-payroll-entry-list>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</section>