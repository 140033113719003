import { Component, Input } from '@angular/core';
import { IApiAddress } from '../../modules/graphql/types/types';

@Component({
  selector: 'app-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent {

  @Input() address: IApiAddress;

  constructor() { }

}
