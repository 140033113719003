<kendo-card class="intake-card" width="100%">
  <h1>Loss Details/Special Instructions</h1>
  <p>Let us know about any other details that you have not entered already regarding your new assignment.</p>
  <form [formGroup]="lossDetailsSpecialInstructions" autocomplete="off">
    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label [for]="description" text="Description"></kendo-label>
          <kendo-textarea #description formControlName="description" placeholder="Enter description" fillMode="outline"
            class="min-height-96" resizable="both"></kendo-textarea>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="lossDetailsSpecialInstructions?.controls?.description?.errors?.required">Description cannot be
              left blank</span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item class="docusketch">
          <kendo-formfield>
              <kendo-label class="font-weight-600 mb-15 asterisk" text="Would you like Docusketch 3D imaging on this claim (sketch if needed compatible with Xactimate & Symbility)?">
                <kendo-formhint>* Additional fees apply</kendo-formhint>
              </kendo-label>

              <div class="kendo-radio">
                  <input type="radio" id="Yes-for-docusketch" kendoRadioButton formControlName="docusketchRequested" [value]="true" />
                  <label class="k-radio-label mr-24" for="Yes-for-docusketch">Yes</label>
                  <input type="radio" id="No-for-docusketch" kendoRadioButton formControlName="docusketchRequested" [value]="false" />
                  <label class="k-radio-label" for="No-for-docusketch">No</label>
              </div>
              <kendo-formerror>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="lossDetailsSpecialInstructions?.controls?.docusketchRequested?.errors?.required">Please select if docusketch 3D imaging is required or not</span>
              </kendo-formerror>
          </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-gridlayout [rows]="['auto']" [cols]="['50%', '50%']" *ngIf="lossDetailsSpecialInstructions?.controls['docusketchRequested'].value">
      <kendo-gridlayout-item class="d-flex">
        <kendo-formfield>
          <div class="k-checkbox-wrap">
            <input
              #isXactimate
              type="checkbox"
              kendoCheckBox
              formControlName="isXactimate"
            />
            <kendo-label
              [for]="isXactimate"
              class="k-checkbox-label font-weight-600 checkbox-margin"
              style="margin-bottom: 0"
              text="Xactimate"
            ></kendo-label>
          </div>
            <kendo-formerror *ngIf="lossDetailsSpecialInstructions?.controls?.isXactimate?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="lossDetailsSpecialInstructions?.controls?.isXactimate?.errors?.required">Xactimate cannot be left blank</span>
              <span *ngIf="lossDetailsSpecialInstructions?.controls?.isXactimate?.errors?.atLeastOneRequired">Please fill out
                  either the Xactimate or Symbility</span>
            </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="d-flex">
        <kendo-formfield>
          <div class="k-checkbox-wrap">
            <input
              #isSymbility
              type="checkbox"
              kendoCheckBox
              formControlName="isSymbility"
            />
            <kendo-label
              [for]="isSymbility"
              class="k-checkbox-label font-weight-600 checkbox-margin"
              style="margin-bottom: 0"
              text="Symbility"
            ></kendo-label>
          </div>
            <kendo-formerror *ngIf="lossDetailsSpecialInstructions?.controls?.isSymbility?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="lossDetailsSpecialInstructions?.controls?.isSymbility?.errors?.required">Symbility cannot be left blank</span>
              <span *ngIf="lossDetailsSpecialInstructions?.controls?.isSymbility?.errors?.atLeastOneRequired">Please fill out
                  either the Xactimate or Symbility</span>
            </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </form>
</kendo-card>
