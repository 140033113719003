import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const nodeFragment = `
  id
  key
  value
  createdAt
  updatedAt
`;

export const SettingsConnectionQuery = gql`${buildConnectionQuery("Settings", "settingsConnection", nodeFragment)}`;

export const addSettingsMutation = gql`mutation AddSettings($input: AddSettingsInput!) {
  addSettings(input: $input) { ${ nodeFragment } }
}`;

export const updateSettingsMutation = gql`mutation UpdateSettings($input: UpdateSettingsInput!) {
  updateSettings(input: $input) { ${ nodeFragment } }
}`;

export const removeSettingsMutation = gql`mutation RemoveSettings($id: ID!) {
  removeSettings(id: $id)
}`;