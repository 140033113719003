import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";
import { IApiUserFilterType } from '../../types/types';

const userDetailFragment = `
  UserDetail {
    id
    requiredHoursQuarterly
    remainingHoursQuarterly
    additionalInformation
    startDate
    endDate
    hasNefcoVehicle
    hasNefcoCreditCard
    vehicleRegistrationState
    vehicleInsuranceCompany
    emergencyContactName
    emergencyContactInfo
    payRateHourly
    status
    fullTime
    driversLicenseNumber
    driversLicenseState

  }
`;

const timeEntriesFragment = `
  TimeEntries {
    id
    Type {
      id
      name
      appliesTo
      nonStandard
      additionalDetails
      qbAccount
      activated
      createdAt
      rate
      isFlatRate
    }
    InvoiceItems {
      id
    }
    Investigation {
      id
      nefcoNumber
    }
    workday
    hours
    nonBillableHours
    description
    Status {
      id
      name
      createdAt
      updatedAt
    }
    paidDate
    createdAt
    updatedAt
  }
`;

const investigationRolesFragment = `
  InvestigationRoles {
    User {
      id
      firstName
      lastName
      nefcoUserId
    }
    Role {
      id
      title
    }
  }
`;

const permissionRolesFragment = `
  PermissionRoles {
    id
    name
  }
`;

const assignedStaffFragment = `
  AssignedStaff {
    id
    firstName
    lastName
  }
`;

const certificationsFragment = `
  Certifications {
    id
    title
    issuedAt
    expiresAt
    status
    CertificationTypeId
    Type {
      id
      title
      state
      requiresRenewal
      isRestriction
      notes
        CertificationAgency {
          id
          name
        }
    }
    Comments {
      id
      text
      createdAt
      CreatedBy {
        id
        firstName
        lastName
      }
    }
  }
`;

const restrictionsFragment = `
  Restrictions {
    id
    title
    issuedAt
    expiresAt
    status
    CertificationTypeId
    Type {
      id
      title
      state
      requiresRenewal
      isRestriction
      notes
        CertificationAgency {
          id
          name
        }
    }
    Comments {
      id
      text
      createdAt
      CreatedBy {
        id
        firstName
        lastName
      }
    }
  }
`;

const vehiclesFragment = `
  Vehicles {
    id
    make
    model
    year
    vinNumber
    stockNumber
    insuranceCompany
    policyNumber
  }
`;

const onlyUserWithNefoFragment = `
  id
  firstName
  lastName
  middleName
  UserDetail {
    id
    hasNefcoVehicle
    hasNefcoCreditCard
  }
`;

const onlyUserFragment = `
  id
  firstName
  lastName
  middleName
`;

const templateFragment = `
  id
  msGraphId
  msGraphToken
  email
  firstName
  lastName
  middleName
  title
  suffix
  nickname
  createdAt
  updatedAt
  twilioPhoneNumber
  TechReviewerId
  timezone
  notificationEnabled
  Positions {
    id
    title
  }
  Addresses {
    id
    state
    address1
    address2
    address3
    city
    country
    postal
    isPrimary
    Type {
      id
      name
    }
    latitude
    longitude
  }
  Emails {
    id
    address
    isPrimary
    Type {
      id
      name
    }
  }
  Phones {
    id
    number
    extension
    isPrimary
    Type {
      id
      name
    }
  }
  TechReviewer {
    id
    firstName
    lastName
  }
  RegionalManagerId
  AssignedRegionalStaff {
    id
    firstName
    lastName
  }
  RegionalManager {
    id
    firstName
    lastName
  }
  UserSignature {
    id
    url
    s3Uri
    CreatedBy {
      id
      lastName
      firstName
    }
    createdAt
    updatedAt
  }
  {{ USERDETAIL }}
  {{ INVESTIGATIONROLES }}
  {{ PERMISSIONROLES }}
  {{ CERTIFICATIONS }}
  {{ RESTRICTIONS }}
  {{ VEHICLES }}
  {{ ASSIGNEDSTAFF }}
  {{ TIMEENTRIES }}
`;

const getByIdWithPermissionRoleAndStateFragment = `
id
PermissionRoles {
  id
  name
  TimeEntryType{
    id
    name
  }
}
States
`;

const userDetailRegex = /\{\{\s?USERDETAIL\s?\}\}/g;
const investigationRolesRegex = /\{\{\s?INVESTIGATIONROLES\s?\}\}/g;
const permissionRolesRegex = /\{\{\s?PERMISSIONROLES\s?\}\}/g;
const certificationsRegex = /\{\{\s?CERTIFICATIONS\s?\}\}/g;
const restrictionsRegex = /\{\{\s?RESTRICTIONS\s?\}\}/g;
const vehiclesRegex = /\{\{\s?VEHICLES\s?\}\}/g;
const assignedStaffRegex = /\{\{\s?ASSIGNEDSTAFF\s?\}\}/g;
const timeEntriesRegex = /\{\{\s?TIMEENTRIES\s?\}\}/g;

const nodeFragment = templateFragment
  .replace(userDetailRegex, userDetailFragment)
  .replace(investigationRolesRegex, investigationRolesFragment)
  .replace(permissionRolesRegex, permissionRolesFragment)
  .replace(certificationsRegex, certificationsFragment)
  .replace(restrictionsRegex, restrictionsFragment)
  .replace(vehiclesRegex, vehiclesFragment)
  .replace(assignedStaffRegex, assignedStaffFragment)
  .replace(timeEntriesRegex, timeEntriesFragment);

const withoutIntestigationRoleFragment = templateFragment
  .replace(userDetailRegex, userDetailFragment)
  .replace(investigationRolesRegex, "")
  .replace(permissionRolesRegex, permissionRolesFragment)
  .replace(certificationsRegex, certificationsFragment)
  .replace(restrictionsRegex, restrictionsFragment)
  .replace(vehiclesRegex, vehiclesFragment)
  .replace(assignedStaffRegex, assignedStaffFragment)
  .replace(timeEntriesRegex, "");

const payrollFragment = templateFragment
  .replace(userDetailRegex, userDetailFragment)
  .replace(investigationRolesRegex, "")
  .replace(permissionRolesRegex, permissionRolesFragment)
  .replace(certificationsRegex, certificationsFragment)
  .replace(restrictionsRegex, restrictionsFragment)
  .replace(vehiclesRegex, vehiclesFragment)
  .replace(assignedStaffRegex, assignedStaffFragment)
  .replace(timeEntriesRegex, timeEntriesFragment);

const listViewFragment = templateFragment
  .replace(userDetailRegex, '')
  .replace(investigationRolesRegex, '')
  .replace(permissionRolesRegex, '')
  .replace(certificationsRegex, certificationsFragment)
  .replace(restrictionsRegex, '')
  .replace(vehiclesRegex, '')
  .replace(assignedStaffRegex, '')
  .replace(timeEntriesRegex, '');

const assignmentViewFragment = templateFragment
  .replace(userDetailRegex, userDetailFragment)
  .replace(investigationRolesRegex, '')
  .replace(permissionRolesRegex, '')
  .replace(certificationsRegex, '')
  .replace(restrictionsRegex, restrictionsFragment)
  .replace(vehiclesRegex, '')
  .replace(assignedStaffRegex, '')
  .replace(timeEntriesRegex, '');

const ViewAssignmentStaffRole = templateFragment
  .replace(userDetailRegex, '')
  .replace(investigationRolesRegex, '')
  .replace(permissionRolesRegex, permissionRolesFragment)
  .replace(certificationsRegex, '')
  .replace(restrictionsRegex, '')
  .replace(vehiclesRegex, '')
  .replace(assignedStaffRegex, '')
  .replace(timeEntriesRegex, '')
  .replace('suffix', '')
  .replace('Restrictions', '')
  .replace('createdAt', '')
  .replace('nickname', '')
  .replace('title', '')
  .replace('updatedAt', '')
  .replace('twilioPhoneNumber', '')
  .replace('msGraphToken', '')
  .replace('TechReviewerId', '');

const bookkeepingReportFragment = `
  id
  firstName
  lastName
  Positions {
    title
  }
  UserDetail{
    hasNefcoCreditCard
    hasNefcoVehicle
    fullTime
  }
`;

const payrollReportFragment = `
  id
  firstName
  lastName
  Positions {
    title
  }
  UserDetail{
    hasNefcoCreditCard
    hasNefcoVehicle
    fullTime
  }
  TimeEntries {
    id
    Type {
      id
      name
    }
    Investigation {
      id
      nefcoNumber
    }
    workday
    hours
    nonBillableHours
    description
    paidDate
    createdAt
    updatedAt
  }
  Addresses {
    id
    state
    Type {
      id
      name
    }
  }
`;

const celendarViewProfileFragment = templateFragment
  .replace(userDetailRegex, userDetailFragment)
  .replace(investigationRolesRegex, permissionRolesFragment)
  .replace(permissionRolesRegex, '')
  .replace(certificationsRegex, '')
  .replace(restrictionsRegex, restrictionsFragment)
  .replace(vehiclesRegex, vehiclesFragment)
  .replace(assignedStaffRegex, '')
  .replace(timeEntriesRegex, '');

const profileViewFragment = templateFragment
  .replace(userDetailRegex, userDetailFragment)
  .replace(investigationRolesRegex, "")
  .replace(permissionRolesRegex, permissionRolesFragment)
  .replace(certificationsRegex, certificationsFragment)
  .replace(restrictionsRegex, restrictionsFragment)
  .replace(vehiclesRegex, vehiclesFragment)
  .replace(assignedStaffRegex, assignedStaffFragment)
  .replace(timeEntriesRegex, "");

export const UserConnectionQuery = (view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiUserFilterType.Payroll:
        return gql`${buildConnectionQuery("User", "usersConnection", payrollFragment)}`;

      case IApiUserFilterType.ViewListView:
        return gql`${buildConnectionQuery("User", "usersConnection", listViewFragment)}`;

      case IApiUserFilterType.ViewAssignmentView:
        return gql`${buildConnectionQuery("User", "usersConnection", assignmentViewFragment)}`;

      case IApiUserFilterType.ViewAssignmentStaffRole:
        return gql`${buildConnectionQuery("User", "usersConnection", ViewAssignmentStaffRole)}`;

      case IApiUserFilterType.ViewStaffUser:
        return gql`${buildConnectionQuery("User", "usersConnection", onlyUserFragment)}`;

      case IApiUserFilterType.ViewBookkeepingReport:
        return gql`${buildConnectionQuery("User", "usersConnection", bookkeepingReportFragment)}`;
      case IApiUserFilterType.ViewPayrollReport:
        return gql`${buildConnectionQuery("User", "usersConnection", payrollReportFragment)}`;

      default:
        return gql`${buildConnectionQuery("User", "usersConnection", nodeFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("User", "usersConnection", nodeFragment)}`;
  }
};

export const getUserByIdQuery = gql`query getUserById($id: ID!) {
  getUserById(id: $id) {
    ${nodeFragment}
  }
}`;

export const getUserByIdWithoutIntestigationRoleQuery = gql`query getUserById($id: ID!) {
  getUserById(id: $id) {
    ${withoutIntestigationRoleFragment}
  }
}`;

export const getUserByIdNefcoPermissionQuery = gql`query getUserById($id: ID!) {
  getUserById(id: $id) {
    ${onlyUserWithNefoFragment}
  }
}`;

export const addUserMutation = gql`mutation addUser($input: AddUserInput!) {
  addUser(input: $input) { ${nodeFragment} }
}`;

export const updateUserMutation = gql`mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) { ${nodeFragment} }
}`;

export const updateUserProfileMutation = gql`mutation updateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) { ${profileViewFragment} }
}`;

export const getByIdOnlyMsGraphIdQuery = gql`query getUserById($id: ID!) {
  getUserById(id: $id) {
    id
    msGraphId
    timezone
  }
}`;

export const getByIdCelendarViewProfileQuery = gql`query getUserById($id: ID!) {
  getUserById(id: $id) {
    ${celendarViewProfileFragment}
  }
}`;

export const addUserSignature = gql`mutation addUserSignature($input: UserSignatureInput!) {
  addUserSignature(input: $input) {
    id
    url
    s3Uri
    CreatedBy {
      id
      firstName
      lastName
    }
    createdAt
    updatedAt
  }
}`;

export const getByIdWithPermissionRoleAndStateQuery = gql`query getUserById($id: ID!) {
  getUserById(id: $id) {
    ${getByIdWithPermissionRoleAndStateFragment}
  }
}`;
