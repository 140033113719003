import { AuthService } from '../../../../shared/services/authorization/auth.service';
import { map, tap, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Component, Input, EventEmitter, Output, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IApiInvestigation } from 'src/app/shared/modules/graphql/types/types';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiExpenseItemOrderBy, IApiExpensePurpose, IApiUpdateExpenseInput, IApiUserDetail } from 'src/app/shared/modules/graphql/types/types';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { ExpenseItemService, ExpensePurposeService, UserService } from 'src/app/shared/services';
import moment from 'moment';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { ExpenseActionType } from 'src/app/shared/modules/graphql/enums/expense.enums';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { expenseInvestigationMileageCreate, expenseUnlinkedMileageCreate } from 'src/app/shared/helpers/auth-config/time-expenses.config';

interface IAddUpdateExpenseInput extends IApiUpdateExpenseInput {
  InvestigationId?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-ind-mileage-form-kendo',
  templateUrl: './ind-mileage-form-kendo.component.html',
  styleUrls: ['./ind-mileage-form-kendo.component.scss']
})
export class IndMileageFormKendoComponent implements AfterViewInit {

  public authConfig = {
    expenseInvestigationMileageCreate,
    expenseUnlinkedMileageCreate
  }

  @ViewChild("form") form: NgForm;
  @ViewChild("expenseInvestigationMileageCreate") expenseInvestigationMileageCreatePermission;
  @ViewChild("expenseUnlinkedMileageCreate") expenseUnlinkedMileageCreatePermission;

  @Input() investigations: IApiInvestigation[];
  @Input() investigationView = false;
  @Input() adminView = false;
  @Input() modalView = false;
  @Input() editQtyOnly = false;
  @Input() nefcoNumber = '';
  @Input() actionType: ExpenseActionType;

  @Output() formSubmitted = new EventEmitter<IAddUpdateExpenseInput>();
  // for embedded use
  @Output() mileageChange = new EventEmitter<IAddUpdateExpenseInput>();

  private _mileage: IAddUpdateExpenseInput = {
    id: null,
    expenseDate: new Date(),
    billableQuantity: null,
    nefcoVehicle: null,
    outOfPocket: 0,
    ExpenseItemId: null,
    ExpensePurposeId: null,
    InvestigationId: null,
    description: 'Mileage'
  };

  private _userDetail: IApiUserDetail;

  // User Detail
  public get userDetail(): IApiUserDetail {
    return this._userDetail;
  }

  public set userDetail(val: IApiUserDetail) {
    this._userDetail = val;
  }

  private _userId: string;

  // UserId
  public get userId() {
    return this._userId;
  }

  @Input() public set userId(val: string) {
    if (val) {
      this.loader.show$(this.userService.getByIdOnlyNefco(val)).subscribe(({ UserDetail, id }) => {
        this._userDetail = UserDetail;
        this.mileage.UserId = id;
        if (UserDetail?.hasNefcoVehicle) {
          this.typeArray = [{value: 1, name: 'NEFCO Vehicle'}, {value: 0, name: 'My Vehicle'},{value: 2, name: 'Vendor/Other'}];
        } else {
          this.typeArray = [{value: 0, name: 'My Vehicle'},{value: 2, name: 'Vendor/Other'}];
          if (this.mileage.nefcoVehicle === 1) {
            this.mileage.nefcoVehicle = 0;
          }
        }
      });
    } else {
      this.loader.show();
      this.auth.authenticatedUser.subscribe(({ UserDetail, id }) => {
        this.loader.hide();
        this._userDetail = UserDetail;
        this.mileage.UserId = id;
        if (UserDetail?.hasNefcoVehicle) {
          this.typeArray = [{value: 1, name: 'NEFCO Vehicle'}, {value: 0, name: 'My Vehicle'},{value: 2, name: 'Vendor/Other'}];
        } else {
          this.typeArray = [{value: 0, name: 'My Vehicle'},{value: 2, name: 'Vendor/Other'}];
          if (this.mileage.nefcoVehicle === 1) {
            this.mileage.nefcoVehicle = 0;
          }
        }
      });
    }
    this._userId = val;
  }

  @Input() public set mileage(val) {
    // this.checkUserNefcoCardVehicle(val?.UserId);
    this._mileage = val;
    if (!val.id && !val.description) {
      // Default description for mileage
      this._mileage.description = this.customMileageDescription;
    }
    if (val?.paidDate) {
      this._mileage.paidDate = NefcoDateHelper.toLocal(
        this._mileage.paidDate
      );
      this.paidCheckbox = true;
    }
  }

  public get mileage() {
    return this._mileage;
  }

  private _unlinked = false;

  public get unlinked(): boolean {
    return this._unlinked;
  }

  public set unlinked(val: boolean) {
    this._unlinked = val;
    if (val === true) {
      this.mileage.InvestigationId = null;
      this.mileage.billableQuantity = null;
    }
  }

  public modelName = "";
  public types = [];

  private _expensePurposes = [];

  public set expensePurposes(val) {
    this._expensePurposes = val;
  }

  public get expensePurposes(): IApiExpensePurpose[] {
    return this._expensePurposes;
  }

  private customMileageDescription = 'Mileage';
  public paidCheckbox: boolean = false;
  public typeArray: Array<{name?: string; value?: number}> = [];

  constructor(
    private expensePurposeService: ExpensePurposeService,
    private expenseItemService: ExpenseItemService,
    private auth: AuthService,
    public loader: LoaderService,
    private userService: UserService
  ) {
    this.expenseItemService.get([], { limit: -1, sortOrder: SortOrder.ASC, orderBy: IApiExpenseItemOrderBy.Name }).pipe(unwrapConnection()).subscribe((expenseItems) => {
      const mileageExpense = expenseItems.find(({ name }) => name === "Mileage");
      this._expensePurposes = mileageExpense?.ExpenseCategory?.ExpensePurposes;
      if (!this._mileage.ExpenseItemId) this._mileage.ExpenseItemId = mileageExpense.id;
    });
  }

  ngAfterViewInit(): void {
    if (this.mileage.InvestigationId || this.actionType === "ADD_MILEAGE") {
      this._unlinked = false;
    } else {
      this._unlinked = true;
    }
    if (!this.expenseInvestigationMileageCreatePermission?.enabled || !this.expenseUnlinkedMileageCreatePermission?.enabled) {
      if (!this.expenseInvestigationMileageCreatePermission?.enabled && this.expenseUnlinkedMileageCreatePermission?.enabled) {
        this._unlinked = true;
      }
    }
    this.form.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(300)
    ).subscribe(() => {
      this.mileageChange.emit(this.mileage);
    });
  }

  public disabledDates = (date: Date): boolean => {
    return moment(date).isAfter();
  };

  public submit() {
    if (!this.isFormValid()) {
      return;
    }
    const data = {
      ...this.mileage,
      billableQuantity: this.mileage.billableQuantity || 0,
      nonBillableQuantity: this.mileage.nonBillableQuantity || 0,
    };
    this.formSubmitted.emit(data);
    this.form.form.reset();
    // Default description for mileage
    this.form.form.get('description').setValue(this.customMileageDescription);
  }

  public isFormValid(): boolean {
    this.form.control.markAllAsTouched();
    return this.form.valid;
  }

  public changePaid(e) {
    if (!e) {
      this._mileage.paidDate = null;
    }
  }

  public checkUserNefcoCardVehicle(val) {
    if (val) {
      this.loader.show$(this.userService.getByIdOnlyNefco(val)).subscribe(({ UserDetail }) => {
        if (UserDetail?.hasNefcoVehicle) {
          this.typeArray = [{value: 1, name: 'NEFCO Vehicle'}, {value: 0, name: 'My Vehicle'},{value: 2, name: 'Vendor/Other'}];
        } else {
          this.typeArray = [{value: 0, name: 'My Vehicle'},{value: 2, name: 'Vendor/Other'}];
        }
      });
    } else {
      this.loader.show();
      this.auth.authenticatedUser.subscribe(({ UserDetail }) => {
        this.loader.hide();
        if (UserDetail?.hasNefcoVehicle) {
          this.typeArray = [{value: 1, name: 'NEFCO Vehicle'}, {value: 0, name: 'My Vehicle'},{value: 2, name: 'Vendor/Other'}];
        } else {
          this.typeArray = [{value: 0, name: 'My Vehicle'},{value: 2, name: 'Vendor/Other'}];
        }
      });
    }
  }

}
