import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const nodeFragment = `
  id
  title
  message
  status
  viewed
  redirectUrl
  Type {
    entity
  }
  Sender {
    id
    firstName
    lastName
    email
  }
  createdAt
  updatedAt
`;

export const notificationsConnectionQuery = gql`${buildConnectionQuery("Notification", "notificationsConnection", nodeFragment)}`;

export const addNotificationMutation = gql`mutation createNotification($input: AddNotificationInput!) {
  addNotification(input: $input) { ${nodeFragment} }
  }`;

export const removeNotificationMutation = gql`mutation removeNotification($id: ID!) {
  deleteNotification(id: $id)
}`;

export const updateNotificationMutation = gql`mutation updateNotification($input: UpdateNotificationInput!) {
  updateNotification(input: $input) { ${nodeFragment} }
}`;

export const getNotificationByIdQuery = gql`query getNotificationById($id: ID!) {
  getNotificationById(id: $id) {${nodeFragment}}
}`;

export const NotificationsSubscription = gql`subscription onNotificationAdded {
  notificationAdded
}`;

export const markNotificationsAsReadOrUnread = gql`mutation markNotificationsAsReadOrUnread($input: MarkNotificationsAsReadOrUnreadInput!) {
  markNotificationsAsReadOrUnread(input: $input)
}`;
