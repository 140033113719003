import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: "[appEmailValidator]",
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }]
})
export class EmailValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    var emailRegexp = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && control.value.trim().length > 0 && !emailRegexp.test(control.value)) {
      return { email: true };
    }
    return null;
  }
}
