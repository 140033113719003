import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  description
  descriptionRequired
  active
  value
  reimbursementRate
  isFlatRate
  summary
  qbAccount
  AppliesTo {
    id
    name
  }
  ExpenseCategory {
    id
    name
    ExpensePurposes {
      id
      name
    }
  }
  ExpenseType{
    id
    name
  }
  createdAt
  updatedAt
`;

export const ExpenseItemConnectionQuery = (includeSummary = false) => gql`${buildConnectionQuery("ExpenseItem", "expenseItemConnection", includeSummary ? nodeFragment : nodeFragment.replace("summary", ""))}`;

export const getExpenseItemByIdQuery = gql`query getExpenseItemById($id: String!) {
  getExpenseItemById(id: $id) { ${ nodeFragment } }
}`;

export const addExpenseItemMutation = gql`mutation AddExpenseItem($input: AddExpenseItemInput!) {
  addExpenseItem(input: $input) { ${ nodeFragment } }
}`;

export const updateExpenseItemMutation = gql`mutation UpdateExpenseItem($input: UpdateExpenseItemInput!) {
  updateExpenseItem(input: $input) { ${ nodeFragment } }
}`;

export const activateExpenseItemMutation = gql`mutation ActivateExpenseItem($id: ID!) {
  activateExpenseItem(id: $id)
}`;

export const deactivateExpenseItemMutation = gql`mutation DeactivateExpenseItem($id: ID!) {
  deactivateExpenseItem(id: $id)
}`;

export const removeExpenseItemMutation = gql`mutation RemoveExpenseItem($id: ID!) {
  removeExpenseItem(id: $id)
}`;

export const accountSummaryExpenseReport = gql`
  query accountSummaryExpenseReport(
    $filters: [ExpenseItemFilter]
    $orderBy: ExpenseItemOrderBy
    $first: Int
    $last: Int
    $before: ID
    $after: ID
    $sortOrder: SortOrder
  ) {
    accountSummaryExpenseReport(
      first: $first
      last: $last
      before: $before
      after: $after
      filters: $filters
      orderBy: $orderBy
      sortOrder: $sortOrder
    )
  }
`;