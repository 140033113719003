<kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(' + config.columnCount + ',minmax(0,1fr))']">
  <kendo-gridlayout-item *ngIf="!config.hideType">
    <kendo-formfield>
      <kendo-label class="asterisk" text="Type"></kendo-label>
      <kendo-dropdownlist [data]="addressTypes" name="addressType" #addressType="ngModel" [(ngModel)]="value.TypeId"
        textField="name" valueField="id" fillMode="outline" [valuePrimitive]="true" [name]="'type' + name"
        [defaultItem]="{id: null, name: 'Select Address Type'}" [required]="config.hideType ? false : true"
        [disabled]="config.static" [popupSettings]="{
          appendTo: 'component',
          animate: false,
          width: 250
        }">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <span *ngIf="!dataItem?.id" class="placeholder">
            {{ dataItem?.name }}
          </span>
          <span *ngIf="dataItem?.id" class="not-placeholder">
            {{ dataItem?.name }}
          </span>
        </ng-template>
      </kendo-dropdownlist>
      <kendo-formerror *ngIf="addressType?.errors && (addressType?.dirty || addressType?.touched)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span *ngIf="addressType?.errors.required">Address type cannot be left blank</span>
      </kendo-formerror>
    </kendo-formfield>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item *ngIf="!config.hideType">
  </kendo-gridlayout-item>

  <kendo-gridlayout-item>
    <kendo-formfield>
      <kendo-label class="asterisk"
        [text]="config.privateContactType ? 'Address (or Department)' : 'Address'"></kendo-label>
      <kendo-textbox [name]="'address1' + name" #address1="ngModel" [(ngModel)]="value.address1" fillMode="outline" placeholder="Enter Address"
        [clearButton]="false" [required]="true" [maxlength]="255" [disabled]="config.static"
        appNoWhiteSpace></kendo-textbox>
      <kendo-formerror *ngIf="address1?.errors && (address1?.dirty || address1?.touched)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span *ngIf="address1?.errors?.required">Address1 cannot be left blank</span>
      </kendo-formerror>
    </kendo-formfield>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item *ngIf="config.hideAddress2">
  </kendo-gridlayout-item>

  <kendo-gridlayout-item *ngIf="!config.hideAddress2">
    <kendo-formfield>
      <kendo-label [text]="'Address2'"></kendo-label>
      <kendo-textbox [name]="'address2' + name" #address2="ngModel" [(ngModel)]="value.address2" fillMode="outline" placeholder="Enter Address2"
        [clearButton]="false" [maxlength]="255" [disabled]="config.static"
        appNoWhiteSpace></kendo-textbox>
      <kendo-formerror *ngIf="address2?.errors && (address2?.dirty || address2?.touched)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span *ngIf="address2?.errors?.required">Address2 cannot be left blank</span>
      </kendo-formerror>
    </kendo-formfield>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item>
    <kendo-formfield>
      <kendo-label class="asterisk" [text]="'City'"></kendo-label>
      <kendo-textbox [name]="'city' + name" #city="ngModel" [(ngModel)]="value.city" fillMode="outline" placeholder="Enter City" [clearButton]="false"
        [required]="true" [maxlength]="255" [disabled]="config.static" appNoWhiteSpace></kendo-textbox>
      <kendo-formerror *ngIf="city?.errors && (city?.dirty || city?.touched)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span *ngIf="city?.errors?.required">City cannot be left blank</span>
      </kendo-formerror>
    </kendo-formfield>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item>
    <app-states-select-form-kendo [stateValue]="value.state" [(ngModel)]="value.state" [name]="'state' + name"
      [disabled]="config.static"></app-states-select-form-kendo>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item>
    <kendo-formfield>
      <kendo-label class="asterisk" [text]="'Zip Code'"></kendo-label>
      <kendo-textbox [name]="'investigationZipCode' + name" #postal="ngModel" [(ngModel)]="value.postal" fillMode="outline" [clearButton]="false" placeholder="Enter Zip Code"
        [required]="true" [maxlength]="255" [disabled]="config.static" appNoWhiteSpace></kendo-textbox>
      <kendo-formerror *ngIf="postal?.errors && (postal?.dirty || postal?.touched)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span *ngIf="postal?.errors?.required">Zip code cannot be left blank</span>
      </kendo-formerror>
    </kendo-formfield>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item *ngIf="config?.showRemove">
    <div class="kendo-formfield remove">
      <kendo-label text=""></kendo-label>
      <div>
        <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-circle-xmark fa-lg"
            (click)="remove.emit(true)"></button>
      </div>
    </div>
  </kendo-gridlayout-item>
</kendo-gridlayout>
