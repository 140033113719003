import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiInvestigationMetricsClientContact, IApiInvestigationMetricsClientContactConnection, IApiInvestigationMetricsFilter } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { InvestigationMetricsContactService } from './investigation-metrics-contact.service';

export class InvestigationMetricsContactDatasource extends NefcoPagedDataSource<IApiInvestigationMetricsClientContact, InvestigationMetricsContactService, IApiInvestigationMetricsClientContactConnection> implements DataSource<IApiInvestigationMetricsClientContact> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsClientContactConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
