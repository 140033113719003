<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffCertificationsDelete" #staffCertificationsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsUpdate" #staffCertificationsUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnUpdate" #staffCertificationsOwnUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnDelete" #staffCertificationsOwnDelete="appEnforcePermission"></div>
<!-- Permissions -->
 <section class="content-wrapper">
    <p class="text-center" *ngIf="!certifications?.length">There are no results to display.</p>
    <kendo-card *ngFor="let cert of certifications; let i = index;" [width]="'100%'" class="mb-16">
        <kendo-card-header class="section-header">
            <h2 class="mb-0" [innerHTML]="cert.Type.title"></h2>
            <div>
                <button class="mr-8" kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-trash"
                (click)="deleteStaffCertification(trackEdit[i].id);" title="delete"
                *ngIf="staffCertificationsDelete.enabled  || (staffCertificationsOwnDelete.enabled && isOwnerUser)"></button>
            
                <button kendoButton themeColor="primary" fillMode="link" iconClass="fa-solid fa-circle-xmark"
                (click)="trackEdit[i].edit = false" title="delete" 
                *ngIf="trackEdit[i].edit; else editIcon"></button>
            
                <ng-template #editIcon>
                    <button kendoButton themeColor="primary" fillMode="link" iconClass="fa-solid fa-pen"
                    (click)="trackEdit[i].edit = true" title="delete"
                    *ngIf="staffCertificationsUpdate.enabled  || (staffCertificationsOwnUpdate.enabled && isOwnerUser)"></button>
                </ng-template>
            </div>
        </kendo-card-header>
        
        <kendo-card-body class="p-0 inner-card-body">
            <ng-container *ngIf="!trackEdit[i].edit; else editMode">
                <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']" class="card-detail">
                    <kendo-gridlayout-item>
                        <span>Issue Date</span>
                        <p [innerHTML]="cert.issuedAt | customDate: 'MM/dd/yyyy'"></p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <span>Expiration Date</span>
                        <p [innerHTML]="cert.expiresAt | customDate: 'MM/dd/yyyy'"></p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <span>Status</span>
                        <p [innerHTML]="cert.status || '-'"></p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <span>Issuing Agency</span>
                        <p [innerHTML]="cert.Type?.CertificationAgency?.name || '-'"></p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <span>State</span>
                        <p [innerHTML]="cert.Type?.state || 'N/A'"></p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <span>Download Cerification</span>
                        <p>
                            <a *ngIf="cert.Document else noValue" [href]="cert.Document?.s3Uri | s3" target="_blank">{{ cert.Document?.title }}</a>
                        </p>
                    </kendo-gridlayout-item>
                </kendo-gridlayout>
            </ng-container>
            
            <!-- Edit Mode -->
            <ng-template #editMode>
                <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
                    <kendo-gridlayout-item>
                        <kendo-formfield>
                            <kendo-label class="asterisk" text="Issue Date"></kendo-label>
                            <kendo-datepicker
                                name="issueDate"
                                #issueDate="ngModel"
                                [(ngModel)]="cert.issuedAt"
                                calendarType="classic"
                                [fillMode]="'outline'"
                                placeholder="Enter date"
                                format="MM/dd/yyyy"
                                formatPlaceholder="formatPattern"
                                [required]="true"
                                (ngModelChange)="updateCertification(cert)"
                            ></kendo-datepicker>
                            <kendo-formerror *ngIf="!issueDate?.valid && (issueDate?.dirty || issueDate?.touched)">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                <span *ngIf="issueDate?.errors?.required">
                                    Issue Date cannot be left blank
                                </span>
                            </kendo-formerror>
                        </kendo-formfield>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <kendo-formfield>
                            <kendo-label class="asterisk" text="Expiration Date"></kendo-label>
                            <kendo-datepicker
                                id="expireDate"
                                name="expireDate"
                                [(ngModel)]="cert.expiresAt"
                                #expireDate="ngModel"
                                calendarType="classic"
                                [fillMode]="'outline'"
                                placeholder="Enter date"
                                format="MM/dd/yyyy"
                                formatPlaceholder="formatPattern"
                                [required]="true"
                                (ngModelChange)="updateCertification(cert)"
                            ></kendo-datepicker>
                            <kendo-formerror *ngIf="!expireDate?.valid && (expireDate?.dirty || expireDate?.touched)">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                <span *ngIf="expireDate?.errors?.required">
                                    Expiration Date cannot be left blank
                                </span>
                            </kendo-formerror>
                        </kendo-formfield>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <kendo-formfield>
                            <kendo-label class="asterisk" text="Status"></kendo-label>
                            <ng-container *ngIf="!cert.Type?.isRestriction else noValue" >
                                <kendo-dropdownlist [data]="statuses | keyvalue" textField="key" valueField="value" fillMode="outline"
                                [valuePrimitive]="true" name="status" [defaultItem]="{key: 'Select Status', value: null}"
                                [(ngModel)]="cert.status" #status="ngModel" [required]="true" (ngModelChange)="updateCertification(cert)"
                                [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
                                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                                        <span [ngClass]="!dataItem?.value ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.key }} </span>
                                    </ng-template>
                                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                                        <span [ngClass]="!dataItem?.value ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.key }} </span>
                                    </ng-template>
                                </kendo-dropdownlist>
                                <kendo-formerror *ngIf="!status?.valid && (status?.dirty || status?.touched)">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    <span *ngIf="status?.errors?.required">
                                        Status cannot be left blank
                                    </span>
                                </kendo-formerror>
                            </ng-container>
                        </kendo-formfield>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <kendo-formfield *ngIf="cert.Type?.CertificationAgency?.name">
                            <kendo-label text="Issuing Agency"></kendo-label>
                            <kendo-dropdownlist [data]="[{value: cert.Type.CertificationAgency.name}]" textField="value" valueField="value"
                                fillMode="outline" [valuePrimitive]="true" name="issuingAgency" [(ngModel)]="cert.Type.CertificationAgency.name"
                                #issuingAgency="ngModel" [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" [disabled]="true">
                            </kendo-dropdownlist>
                        </kendo-formfield>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <kendo-formfield *ngIf="cert.Type?.state">
                            <kendo-label text="Issuing Agency"></kendo-label>
                            <kendo-dropdownlist [data]="[{value: cert.Type.state}]" textField="value" valueField="value"
                                fillMode="outline" [valuePrimitive]="true" name="state" [(ngModel)]="cert.Type.state"
                                #state="ngModel" [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" [disabled]="true">
                            </kendo-dropdownlist>
                        </kendo-formfield>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <ng-container *ngIf="cert.Document; else upload">
                            <kendo-chip class="fw600" style="margin-top: 20px; width: 260px;" [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'"
                            [removable]="true" (remove)="removeDocument(cert)">
                            <a class="text-overflow" [href]="cert.Document?.s3Uri | s3" target="_blank">{{ cert.Document?.title }}</a>
                            </kendo-chip>
                        </ng-container>
                        <ng-template #upload>
                            <button kendoButton class="mt-16" themeColor="primary" fillMode="link" iconClass="fa-solid fa-cloud-arrow-up"
                                (click)="fileSelector(cert)">
                                Upload Certification
                            </button>
                        </ng-template>
                    </kendo-gridlayout-item>
                </kendo-gridlayout>
            </ng-template>
                
            <!-- Comment Log -->
            <kendo-panelbar [expandMode]="">
                <kendo-panelbar-item title="Comment Log" [expanded]="false">
                    <ng-template kendoPanelBarContent>
                        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
                            <kendo-gridlayout-item class="mt-16">
                                <kendo-grid
                                    class="certifications-sidebar-comment-list mt-0 m-16"
                                    [data]="cert.Comments"
                                    [pageable]="false"
                                    [navigatable]="false"
                                >
                                    <kendo-grid-column
                                    field="DATE"
                                    title="Date"
                                    [width]="10"
                                    [sortable]="false"
                                    [headerClass]="'nocursor'"
                                    >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span [innerHTML]="dataItem.createdAt | customDate: 'MM-dd-yyyy'"></span>
                                    </ng-template>
                                    </kendo-grid-column>
                        
                                    <kendo-grid-column
                                    field="COMMENT"
                                    title="Comment"
                                    [width]="30"
                                    [sortable]="false"
                                    [headerClass]="'nocursor'"
                                    >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span [innerHTML]="dataItem.text"></span>
                                    </ng-template>
                                    </kendo-grid-column>
                        
                                    <kendo-grid-column
                                    field="USER"
                                    title="User"
                                    [width]="15"
                                    [sortable]="false"
                                    [headerClass]="'nocursor'"
                                    >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span>{{dataItem.CreatedBy | fullName : true}}</span>
                                    </ng-template>
                                    </kendo-grid-column>
                                    
                                    <!-- No record -->
                                    <ng-template kendoGridNoRecordsTemplate>
                                        <p class="mt-24 mb-24 text-center">here are no results to display.</p>
                                    </ng-template>
                                </kendo-grid>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mt-16 mr-24">
                                <kendo-formfield>
                                    <kendo-label text="Comment"></kendo-label>
                                    <kendo-textarea name="Comments" #comments="ngModel" [(ngModel)]="comment" placeholder="Comments" fillMode="outline"
                                      class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
                                    <kendo-formerror *ngIf="comments?.errors">
                                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                      <span *ngIf="comments?.errors.required">Comments cannot be left blank</span>
                                    </kendo-formerror>
                                </kendo-formfield>
                                <button kendoButton [size]="'medium'" themeColor="primary" class="mt-16" style="float: right;"
                                    [disabled]="!comment?.trim()" (click)="addNewComment(cert, comment?.trim())">Add Comment</button>
                            </kendo-gridlayout-item>
                        </kendo-gridlayout>
                    </ng-template>
                </kendo-panelbar-item>
            </kendo-panelbar>
        </kendo-card-body>
    </kendo-card>
</section>
<ng-template #noValue>
    <p>-</p>
</ng-template>