import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IApiAddInvestigationInput, IApiInvestigation } from 'src/app/shared/modules/graphql/types/types';
import { IApiInvestigationFilterType, IApiUpdateInvestigationInput } from './../../../../shared/modules/graphql/types/types';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InvestigationService } from 'src/app/shared/services';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';

export interface ICheckableInvestigation extends IApiInvestigation {
  selected?: boolean;
}

@Component({
  selector: 'app-investigation-loss-address-check-table',
  templateUrl: './investigation-loss-address-check-table.component.html',
  styleUrls: ['./investigation-loss-address-check-table.component.scss']
})
export class InvestigationLossAddressCheckTableComponent implements OnInit {
  private _input: IApiAddInvestigationInput | IApiUpdateInvestigationInput;
  @Input() public set input(val: IApiAddInvestigationInput | IApiUpdateInvestigationInput) {
    this._input = val;
  }
  public get input(): IApiAddInvestigationInput | IApiUpdateInvestigationInput {
    if (!this._input) return { id: "" };
    return this._input;
  }

  @Output() markersChanged = new EventEmitter<[number, number, string][]>();
  @Output() rowClick = new EventEmitter<string>();

  public get lossAddressLat(): number {
    return this.input?.LossAddress?.latitude;
  }

  public get lossAddressLon(): number {
    return this.input?.LossAddress?.longitude;
  }

  public get investigationId(): string {
    return (this.input as IApiUpdateInvestigationInput).id;
  }

  public selectedRow: ICheckableInvestigation;
  public set selectedInvestigationId(val: string) {
    this.selectedRow = this._dataSource.find(({ id }) => id === val);
  }

  private _dataSource: ICheckableInvestigation[];
  public investigations: ICheckableInvestigation[] = [];
  public lossCheckColumns = ["date", "id", "location", "type", "insured", "link"];

  constructor(
    private investigationService: InvestigationService,
    private notificationService: NotificationsService,
    private loader: LoaderService
  ) {
  }

  public get dataSource(): ICheckableInvestigation[] {
    return this._dataSource;
  }

  ngOnInit() {
    const conditions = [
      {
        type: IApiInvestigationFilterType.IsProximal,
        value: JSON.stringify([this.lossAddressLat, this.lossAddressLon])
      },
      {
        type: IApiInvestigationFilterType.ViewProximityCheck,
        value: "true"
      }
    ];
    this.loader.isMatLoader = false;
    if (this.investigationId) conditions.push({
      type: IApiInvestigationFilterType.Exclude,
      // value: JSON.stringify([parseFloat(this.lossAddressLat.toFixed(5)), parseFloat(this.lossAddressLon.toFixed(5))]) // our precision needs to match the db, which is 5 decimal places
      value: JSON.stringify([null, null, this.investigationId])
    });

    this.loader.show$(this.investigationService.get(conditions).pipe(
      unwrapConnection()
    )).subscribe(results => {
      this.investigations = results.map((o) => {
        o.selected = true;
        o.selected = (this.input.LinkedInvestigationIds || []).includes(o.id);

        return o;
      });
      this._dataSource = this.investigations;

      const markers = this.investigations.map(i => [i.LossAddress.latitude, i.LossAddress.longitude, i.id]) as [number, number, string][];
      this.markersChanged.emit(markers);
    });
  }

  public openInvestigation(row) {
    window.open(`/investigations/${row.id}`);
  }

  public linkInvestigation(id: string) {
    this.investigationService.update({ id: this.investigationId, LinkedInvestigationIds: [id] }).pipe(
      this.notificationService.catchAlertPipe("Unable to link investigation.")
    ).subscribe((r) => this.notificationService.notify("Investigation linked."));
  }
}
