<form [formGroup]="addressForm">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
    <kendo-gridlayout-item [colSpan]="2">
      <div class="kendo-formfield checkbox-field">
        <div>
          <input 
          #isCustom
          type="checkbox"
          kendoCheckBox
          [(ngModel)]="isCustomAddress" 
          [checked]="isCustomAddress" 
          [ngModelOptions]="{standalone: true}"
          (change)="manualAddress($event?.target)" />
          <kendo-label
            class="k-checkbox-filter-label ml-8"
            [for]="isCustom"
            text="Custom Address"
          ></kendo-label>
          <kendo-formhint>Only use "custom address" if the search does not return the correct address and you would like to manually enter an address.</kendo-formhint>
        </div>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [colSpan]="2">
      <div class="kendo-formfield left-search-icon">
        <div style="position: relative;">
          <i class="fa fa-search" aria-hidden="true"></i>
          <kendo-textbox
            #addresstext
            formControlName="addressSearch" 
            (valueChange)="onAddressTextChange($event)"
            placeholder="Address Search"
            fillMode="outline"
            [clearButton]="true">
          </kendo-textbox>
        </div>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [colSpan]="2" *ngIf="!!showType">
      <kendo-formfield>
        <kendo-label text="Type"></kendo-label>
        <kendo-dropdownlist [data]="addressTypes" #TypeId formControlName="TypeId"
          textField="name" valueField="id" fillMode="outline" [valuePrimitive]="true"
          [popupSettings]="{
            appendTo: 'component',
            animate: false,
            width: 250
          }">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span [ngClass]="dataItem?.id ? 'not-placeholder' : 'placeholder'">
              {{ dataItem?.name }}
            </span>
          </ng-template>
        </kendo-dropdownlist>
      </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label [ngClass]="isCustomAddress ? 'asterisk' : ''" [text]="'Address 1'"></kendo-label>
        <kendo-textbox formControlName="address1" fillMode="outline" placeholder="Enter Address 1"
          [clearButton]="false" [maxlength]="255"
          appNoWhiteSpace></kendo-textbox>
        <kendo-formerror *ngIf="addressForm.get('address1').invalid && (addressForm.get('address1').dirty || addressForm.get('address1').touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="addressForm.get('address1')?.errors?.required">Address 1 cannot be left blank</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label [text]="'Address 2'"></kendo-label>
        <kendo-textbox formControlName="address2" fillMode="outline" placeholder="Enter Address 2"
          [clearButton]="false" [maxlength]="255"
          appNoWhiteSpace></kendo-textbox>
        <kendo-formerror *ngIf="addressForm.get('address2').invalid && (addressForm.get('address2').dirty || addressForm.get('address2').touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="addressForm.get('address2')?.errors?.required">Address 2 cannot be left blank</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label [ngClass]="isCustomAddress ? 'asterisk' : ''" [text]="'City'"></kendo-label>
        <kendo-textbox formControlName="city" fillMode="outline" placeholder="Enter City"
          [clearButton]="false" [maxlength]="255"
          appNoWhiteSpace></kendo-textbox>
        <kendo-formerror *ngIf="addressForm.get('city').invalid && (addressForm.get('city').dirty || addressForm.get('city').touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="addressForm.get('city')?.errors?.required">City cannot be left blank</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label
          [ngClass]="isCustomAddress ? 'asterisk' : ''"
          [text]="'State'"
        ></kendo-label>
        <kendo-dropdownlist
          [data]="groupedDataStates"
          textField="text"
          valueField="value"
          formControlName="state"
          [valuePrimitive]="true"
          fillMode="outline"
          [filterable]="false"
          (filterChange)="handleFilter($event)"
          [defaultItem]="{ value: '', text: 'Select state', type: '' }"
          [popupSettings]="{ appendTo: 'component', animate: false }"
        >
          <ng-template kendoDropDownListGroupTemplate let-groupName>
            <strong>{{ groupName }}</strong>
          </ng-template>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span [ngClass]="dataItem?.value ? 'not-placeholder' : 'placeholder'">
              {{ dataItem.text }}
            </span>
          </ng-template>
        </kendo-dropdownlist>
        <kendo-formerror *ngIf="addressForm.get('state').invalid && (addressForm.get('state').dirty || addressForm.get('state').touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="addressForm.get('state')?.errors?.required">State cannot be left blank</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label [ngClass]="isCustomAddress ? 'asterisk' : ''" [text]="'Postal'"></kendo-label>
        <kendo-textbox formControlName="postal" fillMode="outline" placeholder="Enter Postal"
          [clearButton]="false" (change)="zipChanged($event)"
          appNoWhiteSpace></kendo-textbox>
        <kendo-formerror *ngIf="addressForm.get('postal').invalid && (addressForm.get('postal').dirty || addressForm.get('postal').touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="addressForm.get('postal')?.errors?.required">Postal cannot be left blank</span>
          <span *ngIf="addressForm.get('postal')?.errors?.pattern">Postal should be valid</span>
          <span *ngIf="addressForm.get('postal')?.errors?.minlength">Postal should be minimum 5 number.</span>
          <span *ngIf="addressForm.get('postal')?.errors?.maxlength">Postal should be maximum 5 number.</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item *ngIf="showRemove">
      <div class="kendo-formfield remove">
        <kendo-label text=""></kendo-label>
        <div>
          <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-circle-xmark fa-lg"
              (click)="remove.emit(true)"></button>
        </div>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</form>
