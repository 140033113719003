import { ApiConnection } from '../../../decorators/@ApiConnection.decorator';
import { PermissionRoleService } from './permission-role.service';
import { DataSource } from "@angular/cdk/collections";
import { IApiPermissionRole, IApiPermissionRoleFilter } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class PermissionRoleDataSource extends NefcoPagedDataSource<IApiPermissionRole, PermissionRoleService> implements DataSource<IApiPermissionRole> {
  @ApiConnection()
  public load(filters?: IApiPermissionRoleFilter[]): Promise<any> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
