import { Component, Input, OnInit } from '@angular/core';
import { IApiInvestigation, IApiInvestigationRoleNames, IApiInvestigationStaff, IApiUser } from '../../modules/graphql/types/types';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { AuthService } from '../../services';
import { tap } from 'rxjs/operators';
import { investigationScheduledDateViewAll } from '../../helpers/auth-config/investigations.config';

@Component({
  selector: 'app-calendar-profile-sidebar',
  templateUrl: './calendar-profile-sidebar.component.html',
  styleUrls: ['./calendar-profile-sidebar.component.scss']
})
export class CalendarProfileSidebarComponent extends DialogContentBase implements OnInit {
  public authConfig = {
    investigationScheduledDateViewAll,
  }

  @Input() public data: IApiInvestigation | IApiInvestigationStaff;
  @Input() public from: string = 'DASHBOARD_ASSIGNED';

  public currentUser = null;
  public investigator = null;
  public staffList = [];
  constructor(
    private auth: AuthService,
    public dialog: DialogRef,
  ) {
    super(dialog);
  }

  close(result = false) {
    this.dialog.close(result)
  }

  ngOnInit() {
    this.getAuthenticated().subscribe(() => {
      this.auth.hasCategoryPermission(this.authConfig.investigationScheduledDateViewAll.category, this.authConfig.investigationScheduledDateViewAll.appliedPermissions).subscribe(res => {
        if (res) {
          if (this.data.hasOwnProperty("InvestigationStaff")) {
            this.investigator = (this.data as any).InvestigationStaff.find((e) => (e.Role.title === IApiInvestigationRoleNames.Investigator && e.isPrimary === true))?.User || (this.data as any).InvestigationStaff.find((e) => (e.Role.title === IApiInvestigationRoleNames.Investigator))?.User;
          } else {
            this.investigator = (this.data as any)?.User;
          }
        } else {
          this.investigator = this.currentUser;
        }
      });
      this.staffList = this.staffSort((this.data as any)?.InvestigationStaff);
    });
  }

  private getAuthenticated(): Observable<IApiUser> {
    return this.auth.authenticatedUser.pipe(
      tap((user) => this.currentUser = user)
    );
  }

  public staffSort(list) {
    if (!list || !list.length) return [];
    let list_ = [];
    list_ = list?.filter((user) => user?.Role?.title === IApiInvestigationRoleNames.Investigator)?.map(staff => staff.User);
    list_.map(a => {
      a['userName'] = `${a?.firstName} ${a?.lastName}`;
    });
    return list_.sort((a, b) => a?.lastName > b?.lastName ? 1 : -1);
  };

  public changeInvestigator(event) {
    this.investigator = event;
  }
}
