<section>
  <h4 mat-dialog-title>{{ settings.title }}</h4>
  <div mat-dialog-content>
    <p>
      <span class="material-icons" *ngIf="settings.material_ligature">{{ settings.material_ligature }}</span>
      <span [innerHTML]="settings.message"></span>
    </p>
  </div>
  <hr class="mt-3" />
  <div mat-dialog-actions>
    <button mat-raised-button class="notifications-button" *ngFor="let btn of settings.buttons; let i = index" (click)="btn.callback()" color="{{ btn.color }}" [ngClass]="{'transparent': ((i+1) % 2)}">
      <span class="material-icons" *ngIf="btn.material_ligature">{{ btn.material_ligature }}</span>
      {{ btn.caption }}
    </button>
  </div>
</section>
