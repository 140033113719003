<div [dialogRef]="dialog" (appClickOutside)="close()">
<kendo-dialog-titlebar (close)="close()">
  <div class="modal-title">
    <ng-container [ngSwitch]="selectedExpenseType">
      <ng-container *ngSwitchCase="formExpenseType.MILEAGE">
        <span>{{ expense.id || time.id ? "EDIT Mileage Entry" : (data?.actionType === actionType.DUPLICATE) ? "Add Mileage from Copy" : "Add Mileage" }} </span>
      </ng-container>
      <ng-container *ngSwitchCase="formExpenseType.EXPENSE">
        <span>{{ expense.id || time.id ? "Edit Expense Entry" : (data?.actionType === actionType.DUPLICATE) ? "Add Expense from Copy" : "Add Expense" }}  </span>
      </ng-container>
      <ng-container *ngSwitchCase="formExpenseType.HOURS">
        <span>{{ expense.id || time.id ? "Edit Hours Entry" : "Add Hours" }}  </span>
      </ng-container>
    </ng-container>
    <div class="modal-subtitle" *ngIf="data?.actionType !== actionType.DUPLICATE">
      {{ data.nefcoNumber ? 'ID #: ' + data.nefcoNumber : "Unlinked" }}
    </div>
  </div>

</kendo-dialog-titlebar>
<section class="section-content">
  <form #form="ngForm" name="form">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">

      <kendo-gridlayout-item *ngIf="data?.showStaffMember">
        <app-user-select label="Staff Member" placeholder="Search ..." [user]="findUser(expense.UserId) || user" [staticUsers]="users" (selectionChanged)="expense.UserId = $event?.id" [disabled]="data.editQtyOnly || !data.adminView"></app-user-select>
      </kendo-gridlayout-item>

      <!-- new staff member drop down -->
      <ng-container *ngIf="data?.showStaffMemberNew">
        <kendo-gridlayout-item class="mb-0 mt-8">
          <div class="kendo-formfield">
            <kendo-label class="asterisk" text="Staff Member"></kendo-label>
            <kendo-dropdownlist
              name="staffMemberSelect"
              #staffMemberSelect="ngModel"
              [data]="investigationStaffList"
              textField="userName"
              valueField="userId"
              fillMode="outline"
              [valuePrimitive]="true"
              [(ngModel)]="staffMember"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250
              }"
              (selectionChange)="setUser($event?.userId)"
              required
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem?.userName === 'Select staff'" class="placeholder">
                  {{ dataItem?.userName }}
                </span>
                <span
                  *ngIf="dataItem?.userName !== 'Select staff'"
                  class="not-placeholder"
                >
                  {{ dataItem?.userName }}
                </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!staffMemberSelect?.valid && (staffMemberSelect?.dirty || staffMemberSelect?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="staffMemberSelect?.errors?.required">
                Staff member cannot be left blank
              </span>
            </kendo-formerror>
          </div>
        </kendo-gridlayout-item>
        <hr class="line-staff"/>
      </ng-container>

      <!-- new staff member autocomplete drop down -->
      <ng-container *ngIf="data?.showStaffMemberAutoComplete">
        <kendo-gridlayout-item class="mb-0 mt-8">
          <kendo-formfield>
            <app-user-select-kendo
              label="Staff Member"
              [userViewFilter]="userViewFilter"
              [user]="user"
              [userId]="user?.id"
              placeholder="Search staff"
              [includeInactive]="false"
              (selectionChanged)="setUser($event?.id)"
            ></app-user-select-kendo>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <hr class="line-staff"/>
      </ng-container>

      <kendo-gridlayout-item>
        <mat-form-field *ngIf="!data.expense.id && !data.time.id && data?.actionType !== actionType.DUPLICATE && data?.actionType !== actionType.ADD_EXPENSE && data?.actionType !== actionType.ADD_MILEAGE && data?.actionType !== actionType.ADD_TIME" class="ml-3">
          <mat-label>Type</mat-label>
          <mat-select [(ngModel)]="selectedExpenseType" name="selectedExpenseType">
            <mat-option value="{{ formExpenseType.MILEAGE }}">Mileage</mat-option>
            <mat-option value="{{ formExpenseType.EXPENSE }}">Expense</mat-option>
            <mat-option value="{{ formExpenseType.HOURS }}">Hours</mat-option>
          </mat-select>
        </mat-form-field>
      </kendo-gridlayout-item>

    </kendo-gridlayout>
    <ng-container [ngSwitch]="selectedExpenseType">
      <ng-container *ngSwitchCase="formExpenseType.MILEAGE">
        <!-- <app-ind-mileage-form #mileageForm [mileage]="expense" [investigationView]="true" [adminView]="data.adminView" [editQtyOnly]="data.editQtyOnly" [modalView]="true" (mileageChange)="expense = $event"></app-ind-mileage-form> -->
        <app-ind-mileage-form-kendo #mileageForm [mileage]="expense" [investigationView]="investigationView" [adminView]="data.adminView" [userId]="staffMember"
          [investigations]="investigations" [editQtyOnly]="data.editQtyOnly" [nefcoNumber]="data?.nefcoNumber" [modalView]="true" (mileageChange)="expense = $event" [actionType]="data.actionType"></app-ind-mileage-form-kendo>
      </ng-container>
      <ng-container *ngSwitchCase="formExpenseType.EXPENSE">
        <!-- <app-ind-expense-form #expenseForm [expense]="expense" [investigationView]="true" [adminView]="data.adminView" [userId]="expense.UserId" [editQtyOnly]="data.editQtyOnly" [modalView]="true" (expenseChange)="expense = $event"></app-ind-expense-form> -->
        <app-ind-expense-form-kendo #expenseForm [expense]="expense" [investigationView]="investigationView" [adminView]="data.adminView"
          [userId]="staffMember" [s3Doc]="s3Doc" [editQtyOnly]="data.editQtyOnly" [modalView]="true" [investigations]="investigations"
          [expenseItems]="expenseItems" [excludeExpenseItems]="excludeExpenseItems" (expenseChange)="expense = $event" [nefcoNumber]="data?.nefcoNumber" (s3DocChange)="s3Doc = $event" [actionType]="data.actionType"></app-ind-expense-form-kendo>
      </ng-container>
      <ng-container *ngSwitchCase="formExpenseType.HOURS">
        <app-ind-time-form-kendo #timeForm [timeEntry]="time" [UserId]="staffMember" [investigationView]="investigationView" [adminView]="data.adminView" [editQtyOnly]="data.editQtyOnly"
        [investigations]="investigations" [modalView]="true" (timeChange)="time = $event"></app-ind-time-form-kendo>
      </ng-container>
    </ng-container>
  </form>
</section>
<kendo-dialog-actions layout="end">
  <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
    Cancel
  </button>
  <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="saveEntry()">
    Save
  </button>
</kendo-dialog-actions>
</div>