import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlRiskTypesService } from '../modules/graphql/services';
import { first, switchAll } from 'rxjs/operators';
import { IApiRiskType } from "../modules/graphql/types/types";

@Injectable()
export class RiskTypesService {

  constructor(
    private gqlRiskTypeService: GqlRiskTypesService
  ) {}

  public riskTypes(): Observable<IApiRiskType[]> {
    return this.gqlRiskTypeService.riskTypes$;
  }
  
  public riskTypeById(id: string): Observable<IApiRiskType> {
    return this.riskTypes().pipe(
      switchAll(),
      first(riskType => riskType.id === id),
    );
  }

}
