import { IState } from 'src/app/shared/interfaces/state.interfaces';
import { Injectable } from '@angular/core';
import * as allStates from 'src/assets/states.json';

@Injectable({
  providedIn: 'root'
})

export class StateService {
  public get allStates(): IState[] {
    return (allStates as any).default;
  }
}
