import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobileDashboardComponent } from './mobile-dashboard/mobile-dashboard.component';
import { MobileInvestigationListComponent } from './mobile-investigation-list/mobile-investigation-list.component';
import { MobileAuthGuard } from './mobile-auth.guard';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';
import { MobileDataRequestFormComponent } from './mobile-data-request-form/mobile-data-request-form.component';
import { MobileTimeAndExpenseTabsComponent } from './mobile-time-and-expense-tabs/mobile-time-and-expense-tabs.component';
import { MobileTimeAndExpenseComponent } from './mobile-time-and-expense/mobile-time-and-expense.component';
import { MobileInvestigationDetailComponent } from './mobile-investigation-detail/mobile-investigation-detail.component';
import { PermissionGuard } from 'src/app/shared/route.guards';
import { InvestigationResolver } from 'src/app/shared/route.resolvers';
import { investigationView } from 'src/app/shared/helpers/auth-config/investigations.config';
import { MobileInvestigationLogTabsComponent } from './mobile-investigation-log-tabs/mobile-investigation-log-tabs.component';
import { MobileScheduleOnSceneExamComponent } from './mobile-schedule-on-scene-exam/mobile-schedule-on-scene-exam.component';
import { MobileFeatureUnavailableComponent } from './mobile-feature-unavailable/mobile-feature-unavailable.component';
import { MobileInvestigationMetricsTabsComponent } from './mobile-investigation-metrics-tabs/mobile-investigation-metrics-tabs.component';
import { MobileForceUpdateComponent } from './mobile-force-update/mobile-force-update.component';

const routes: Routes = [
  { path: "", redirectTo: 'admin/investigation-list', pathMatch: 'full' },
  {
    path: "login",
    component: MobileLoginComponent
  },
  {
    path: "admin",
    canActivate: [MobileAuthGuard],
    component: MobileDashboardComponent,
    children: [
      {
        path: "investigation-list",
        component: MobileInvestigationListComponent
      },
      {
        path: "time-and-expense-tabs",
        component: MobileTimeAndExpenseTabsComponent
      },
      {
        path: "investigation-metrics-tabs",
        component: MobileInvestigationMetricsTabsComponent
      },
      {
        path: "feature-unavailable",
        component: MobileFeatureUnavailableComponent
      },
      {
        path: 'force-update',
        component: MobileForceUpdateComponent,
      }
    ]
  },
  {
    path: "data-request",
    canActivate: [MobileAuthGuard],
    component: MobileDataRequestFormComponent
  },
  {
    path: "schedule",
    canActivate: [MobileAuthGuard],
    component: MobileScheduleOnSceneExamComponent
  },
  {
    path: "time-and-expense",
    canActivate: [MobileAuthGuard],
    component: MobileTimeAndExpenseComponent
  },
  {
    path: "investigations/:investigationId",
    component: MobileInvestigationDetailComponent,
    canActivate: [MobileAuthGuard, PermissionGuard],
    resolve: {
      investigation: InvestigationResolver,
    },
    data: investigationView,
  },
  {
    path: "investigations-log/:investigationId",
    component: MobileInvestigationLogTabsComponent,
    canActivate: [MobileAuthGuard],
    resolve: {
      investigation: InvestigationResolver,
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MobileAppRoutingModule { }
