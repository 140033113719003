import { Component } from '@angular/core';
import { SharedService } from 'src/app/common/shared.service';
import { PortalAuthService } from 'src/app/shared/services/portal-services';

@Component({
  selector: 'app-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.scss']
})
export class PortalHeaderComponent {
  
  public get name(): string {
    return `${this.auth.user.firstName} ${this.auth.user.lastName}`;
  }

  constructor(
    public auth: PortalAuthService,
    public sharedService: SharedService,
  ) { }

  public logout() {
    this.auth.logout();
  }

  public goToHome() {
    this.sharedService.sendClickEvent();
  }  

}
