import { connectionQuery } from './../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiMarkNotificationsAsReadOrUnreadInput, IApiNotificationsConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  notificationsConnectionQuery, getNotificationByIdQuery, updateNotificationMutation, NotificationsSubscription, removeNotificationMutation, markNotificationsAsReadOrUnread
} from './notification.operations';
import {
  IApiNotificationFilter, IApiUpdateNotificationInput, IApiNotification
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlNotificationService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiNotificationFilter[], options: IGqlBaseOptions): Observable<IApiNotificationsConnection> {
    return this.apollo.query(connectionQuery(notificationsConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateNotificationInput): Observable<IApiNotification> {
    return this.apollo.mutate({
      mutation: updateNotificationMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeNotificationMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public getById(id: string): Observable<IApiNotification> {
    return this.apollo.query({
      query: getNotificationByIdQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public markNotificationsAsReadOrUnread(input: IApiMarkNotificationsAsReadOrUnreadInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: markNotificationsAsReadOrUnread,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
