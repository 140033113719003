<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>{{model?.id ? 'Update' : 'Create'}} Expense Item</span>
    </div>
  </kendo-dialog-titlebar>
  
  <section>
    <form #form="ngForm" (submit)="onSubmit()">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Expense Item Name"></kendo-label>
            <kendo-textbox
              name="expenseItemName"
              #expenseItemName="ngModel"
              [(ngModel)]="model.name"
              placeholder="Expense Item Name"
              fillMode="outline"
              appNoWhiteSpace
              [clearButton]="false"
              [required]="true"
              cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror *ngIf="expenseItemName?.invalid && (expenseItemName?.dirty || expenseItemName?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="expenseItemName?.errors.required">Expense Item Name cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Category"></kendo-label>
            <kendo-dropdownlist [data]="expenseCategories" name="category" #category="ngModel"
              [(ngModel)]="model.ExpenseCategoryId" [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
              [defaultItem]="{id: null, name: 'Select Category'}" [popupSettings]="{appendTo: 'component', animate: false}"
              required>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!category?.valid && (category?.dirty || category?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="category?.errors?.required">
                Category cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Type"></kendo-label>
            <kendo-dropdownlist [data]="expenseTypes" name="type" #type="ngModel" [(ngModel)]="model.ExpenseTypeId"
              [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
              [defaultItem]="{id: null, name: 'Select Type'}" [popupSettings]="{appendTo: 'component', animate: false}" required>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!type?.valid && (type?.dirty || type?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="type?.errors?.required">
                Type cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Applies to"></kendo-label>
            <kendo-dropdownlist [data]="expenseApplications" name="AppliesTo" #AppliesTo="ngModel" [(ngModel)]="model.ExpenseApplicationId"
              [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
              [defaultItem]="{id: null, name: 'Select Applies to'}" [popupSettings]="{appendTo: 'component', animate: false}" required>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!AppliesTo?.valid && (AppliesTo?.dirty || AppliesTo?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="AppliesTo?.errors?.required">
                Applies to cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="2">
          <kendo-formfield>
            <kendo-label text="Additional Details"></kendo-label>
            <kendo-textarea name="details" #details="ngModel" [(ngModel)]="model.description" 
            placeholder="Additional Details" fillMode="outline"
            class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
            <kendo-formerror *ngIf="details?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="details?.errors.required">Additional Details cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </section>
  
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSubmit()">
      Save
    </button>
  </kendo-dialog-actions>
</div>