import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlCertificationAgencyService } from '../../../modules/graphql/services';
import { ICertification } from '../../../interfaces/certification.interface';
import { IApiCertificationAgencyConnection } from '../../../modules/graphql/types/types';

@Injectable()
export class CertificationAgencyService {
  constructor(private gqlCertificationAgencyService: GqlCertificationAgencyService) {}

  public get(): Observable<IApiCertificationAgencyConnection> {
    return this.gqlCertificationAgencyService.getCertificationAgencies([], new ListPage(-1));
  }

  public update(document: ICertification) {
    return this.gqlCertificationAgencyService.saveCertification(document);
  }
}
