<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageExpenseReportCategoriesList" #manageExpenseReportCategoriesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpenseItemsList" #manageExpenseItemsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpensePurposesList" #manageExpensePurposesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageTimeEntryTypesList" #manageTimeEntryTypesList="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <h4 class="page-title">Manage Bookkeeping</h4>
  <div class="content-wrapper p-0">
    <kendo-tabstrip #tabstrip [scrollable]="{ buttonScrollSpeed: 500 }" class="bookkeeping-manage-tabs">
      <kendo-tabstrip-tab *ngIf="manageExpenseReportCategoriesList?.enabled">
        <ng-template kendoTabTitle>
          <span>Expense Report Categories</span>
        </ng-template>
        <ng-template kendoTabContent>
          <div>
              <app-bookkeeping-expense-categories></app-bookkeeping-expense-categories>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      
      <kendo-tabstrip-tab  *ngIf="manageExpenseItemsList?.enabled">
        <ng-template kendoTabTitle>
          <span>Expense Items</span>
        </ng-template>
        <ng-template kendoTabContent>
          <div>
            <app-bookkeeping-expense-items></app-bookkeeping-expense-items>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab *ngIf="manageExpensePurposesList?.enabled">
        <ng-template kendoTabTitle>
          <span>Expense Purposes</span>
        </ng-template>
        <ng-template kendoTabContent>
          <div>
            <app-bookkeeping-expense-purposes></app-bookkeeping-expense-purposes>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab *ngIf="manageTimeEntryTypesList?.enabled">
        <ng-template kendoTabTitle>
          <span>Time Entry Types</span>
        </ng-template>
        <ng-template kendoTabContent>
          <div>
            <app-bookkeeping-time-types></app-bookkeeping-time-types>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</section>