<!-- Permissions -->
<div [appEnforcePermission]="authConfig.companiesBranchesUpdate" #companiesBranchesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsCreateConfig" #contactsCreateConfig="appEnforcePermission"></div>
<!-- Permissions -->

<section *ngIf="company && branch">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0">
      <h4 class="page-title mb-0" [innerHTML]="company.name"></h4>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="text-right mb-0">
      <button kendoButton themeColor="light" (click)="goBack()" fillMode="solid" class="mr-2"
        [iconClass]="'fa-solid fa-arrow-left-long fa-xl'">BACK</button>
      <button kendoButton themeColor="primary" (click)="addContact()" fillMode="solid"
        *ngIf="contactsCreateConfig?.enabled" [iconClass]="'fa-solid fa-plus'">Add Contact</button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <section class="content-wrapper">
    <kendo-card *ngIf="branch" [width]="'100%'" class="mt-16">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">Branch: {{branch?.name || '-'}}</h2>
          <i class="fa fa-pen right-edit" *ngIf="companiesBranchesUpdate?.enabled" (click)="editBranch();"></i>
        </div>
        <kendo-card [width]="'100%'" class="mt-16">
          <kendo-card-body class="p-0 inner-card-body card-detail">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Branch Name
                </span>
                <p class="mt-1">
                  {{ branch.name || '-'}}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Addresses
                </span>
                <ng-container *ngIf="branch?.Addresses?.length else noData">
                  <app-address-view *ngFor="let address of branch.Addresses" [address]="address"></app-address-view>
                </ng-container>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Phones
                </span>
                <ng-container *ngIf="branch.Phones?.length else noData">
                  <app-email-phone-view *ngFor="let phone of branch.Phones" [phone]="phone"></app-email-phone-view>
                </ng-container>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Emails
                </span>
                <ng-container *ngIf="branch.Emails?.length else noData">
                  <app-email-phone-view *ngFor="let email of branch.Emails" [email]="email"></app-email-phone-view>
                </ng-container>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Additional Information
                </span>
                <p class="mt-1">
                  {{branch.additionalInformation || '-'}}
                </p>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-card-body>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
    <kendo-card [width]="'100%'" class="mt-16" *ngIf="branch?.ContactAssignments?.length">
      <kendo-card-body>
        <div class="section-header">
          <h2 class="mb-0">Contacts</h2>
        </div>
        <kendo-card *ngFor="let contact of branch.ContactAssignments" [width]="'100%'" class="mt-16">
          <kendo-card-body class="p-0 inner-card-body card-detail">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Contact
                </span>
                <p class="mt-1">
                  <a [routerLink]="['/contacts', contact?.Contact?.id]" class="text-decoration">{{ contact?.Contact |
                    fullName }}</a>
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Role
                </span>
                <p class="mt-1">
                  {{contact?.Contact?.Role?.name || '-'}}
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Primary Phone
                </span>
                <ng-container *ngIf="contact?.Contact?.Phones?.length else noData">
                  <app-email-phone-view [phone]="getPrimary(contact?.Contact?.Phones)"></app-email-phone-view>
                </ng-container>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Primary Email
                </span>
                <ng-container *ngIf="contact?.Contact?.Emails?.length else noData">
                  <app-email-phone-view [email]="getPrimary(contact?.Contact?.Emails)"></app-email-phone-view>
                </ng-container>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Office Phone
                </span>
                <ng-container *ngIf="contact?.Contact?.Phones?.length else noData">
                  <app-email-phone-view [phone]="getOffice(contact?.Contact?.Phones)"></app-email-phone-view>
                </ng-container>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Office Email
                </span>
                <ng-container *ngIf="contact?.Contact?.Emails?.length else noData">
                  <app-email-phone-view [email]="getOffice(contact?.Contact?.Emails)"></app-email-phone-view>
                </ng-container>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  Start Date
                </span>
                <p class="mt-1" [innerHTML]="(contact.startDate | customDate: 'MM/dd/yyyy') || '-'">
                </p>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item class="mb-0">
                <span>
                  End Date
                </span>
                <p class="mt-1" [innerHTML]="(contact.endDate | customDate: 'MM/dd/yyyy') || '-'">
                </p>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-card-body>
        </kendo-card>
      </kendo-card-body>
    </kendo-card>
  </section>
</section>
<ng-template #noData>
  <p class="mt-1">-</p>
</ng-template>
