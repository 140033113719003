import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const nodeFragment = `
  id
  isPrimary
  address1
  address2
  address3
  city
  state
  postal
  country
  longitude
  latitude
  createdAt
  updatedAt
`;

export const addressConnectionQuery = gql`${buildConnectionQuery("Address", "addressConnection", nodeFragment)}`;

export const addAddressMutation = gql`mutation createAddress($input: AddAddressInput!) {
  addAddress(input: $input) { ${nodeFragment} }
}`;

export const removeAddressMutation = gql`mutation removeAddress($input: RemoveAddressInput!) {
  removeAddress(input: $input) {
    success
  }
}`;

export const updateAddressMutation = gql`mutation updateAddress($input: UpdateAddressInput!) {
  updateAddress(input: $input) { ${nodeFragment} }
}`;
