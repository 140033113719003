import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DrawerComponent } from '@progress/kendo-angular-layout';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { expenseInvestigationMileageCreate, expenseUnlinkedMileageCreate } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiAddExpenseInput, IApiExpenseItemOrderBy, IApiExpensePurpose, IApiInvestigation, IApiInvestigationFilterType, IApiInvestigationOrderBy, IApiUpdateExpenseInput, IApiUserDetail } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { AuthService, ExpenseItemService, ExpenseService, InvestigationService } from 'src/app/shared/services';

interface IAddUpdateExpenseInput extends IApiUpdateExpenseInput {
  InvestigationId?: string;
};

@Component({
  selector: 'app-mobile-mileage-form',
  templateUrl: './mobile-mileage-form.component.html',
  styleUrls: ['./mobile-mileage-form.component.scss']
})
export class MobileMileageFormComponent implements OnInit, AfterViewInit {
  public authConfig = {
    expenseInvestigationMileageCreate,
    expenseUnlinkedMileageCreate
  }

  @ViewChild('drawer') drawer: DrawerComponent;
  @ViewChild("form") form: NgForm;
  @ViewChild("expenseInvestigationMileageCreate") expenseInvestigationMileageCreatePermission;
  @ViewChild("expenseUnlinkedMileageCreate") expenseUnlinkedMileageCreatePermission;

  @Output() stepTo = new EventEmitter<number>();
  @Output() reloadPage = new EventEmitter<boolean>(false);
  @Input() fromTab = false;
  @Input() goBackOnSubmit = false;
  @Input() set investigation(val: IApiInvestigation) {
    if (val?.id) {
      this.investigationView = true;
      this.mileage.InvestigationId = val.id;
      this.nefcoNumber = val?.nefcoNumber;
    } else {
      this.unlinked = false;
    }
  };

  investigations: IApiInvestigation[];
  investigationView = false;
  nefcoNumber = '';

  private _mileage: IAddUpdateExpenseInput = {
    id: null,
    expenseDate: new Date(),
    billableQuantity: null,
    nefcoVehicle: null,
    outOfPocket: 0,
    ExpenseItemId: null,
    ExpensePurposeId: null,
    InvestigationId: null,
    description: 'Mileage'
  };

  private _userDetail: IApiUserDetail;

  // User Detail
  public get userDetail(): IApiUserDetail {
    return this._userDetail;
  }

  public set userDetail(val: IApiUserDetail) {
    this._userDetail = val;
  }

  private _userId: string;

  // UserId
  public get userId() {
    return this._userId;
  }

  public set mileage(val) {
    // this.checkUserNefcoCardVehicle(val?.UserId);
    this._mileage = val;
    if (!val.id && !val.description) {
      // Default description for mileage
      this._mileage.description = this.customMileageDescription;
    }
    if (val?.paidDate) {
      this._mileage.paidDate = NefcoDateHelper.toLocal(
        this._mileage.paidDate
      );
      this.paidCheckbox = true;
    }
  }

  public get mileage() {
    return this._mileage;
  }

  private _unlinked = false;

  public get unlinked(): boolean {
    return this._unlinked;
  }

  public set unlinked(val: boolean) {
    this._unlinked = val;
    if (val === true) {
      this.mileage.InvestigationId = null;
      this.mileage.billableQuantity = null;
    }
  }

  private _expensePurposes = [];

  public set expensePurposes(val) {
    this._expensePurposes = val;
  }

  public get expensePurposes(): IApiExpensePurpose[] {
    return this._expensePurposes;
  }

  private customMileageDescription = 'Mileage';
  public paidCheckbox: boolean = false;
  public typeArray: Array<{name?: string; value?: number}> = [];

  constructor(
    private location: Location,
    private cdr: ChangeDetectorRef,
    private expenseItemService: ExpenseItemService,
    private authService: AuthService,
    public loaderService: LoaderService,
    private investigationService: InvestigationService,
    private expenseService: ExpenseService,
    private notificationService: NotificationsService,
  ) { }

  ngOnInit(): void {
   this.authService.authenticatedUser.subscribe(({ UserDetail, id }) => {
      this._userId = id;
      this._userDetail = UserDetail;
      this.mileage.UserId = id;
      if (UserDetail?.hasNefcoVehicle) {
        this.typeArray = [{value: 1, name: 'NEFCO Vehicle'}, {value: 0, name: 'My Vehicle'},{value: 2, name: 'Vendor/Other'}];
      } else {
        this.typeArray = [{value: 0, name: 'My Vehicle'},{value: 2, name: 'Vendor/Other'}];
        if (this.mileage.nefcoVehicle === 1) {
          this.mileage.nefcoVehicle = 0;
        }
      }
      const ob = [
        this.expenseItemService.get([], { limit: -1, sortOrder: SortOrder.ASC, orderBy: IApiExpenseItemOrderBy.Name }).pipe(
          unwrapConnection()
        )
      ];
      if (!this.investigationView) {
        ob.push(
          this.investigationService
          .get(
            [
              { type: IApiInvestigationFilterType.User, value: this._userId },
              {
                type: IApiInvestigationFilterType.ViewOnlyNefconumber,
                value: "true",
              },
            ],
            { limit: -1, sortOrder: SortOrder.DESC, orderBy: IApiInvestigationOrderBy.CreatedAt}
          )
          .pipe(unwrapConnection()))
      }
      this.loaderService.show$(forkJoin(ob)).subscribe(([expenseItems, investigations]) => {
        const mileageExpense = expenseItems.find(({ name }) => name === "Mileage");
        this._expensePurposes = mileageExpense?.ExpenseCategory?.ExpensePurposes;
        if (!this._mileage.ExpenseItemId) this._mileage.ExpenseItemId = mileageExpense.id;
        if (investigations && investigations?.length) {
          investigations = investigations?.filter(i => i.nefcoNumber);
          investigations.map((obj) => {
            obj["name"] = obj?.nefcoNumber ? obj.nefcoNumber : obj.id;
          });
        }
        this.investigations = investigations || [];
      });
    });
  }

  public backToStepOne() {
    this.drawer.toggle(false);
    this.stepTo.emit(1);
    this.addBodyClass(false);
  }

  public close(): void {
    this.drawer.toggle(false);
    if (this.goBackOnSubmit) {
      this.location.back();
    } else {
      this.stepTo.emit(1);
      this.reloadPage.emit(true);
    }
    this.addBodyClass(false);
  }

  ngAfterViewInit() {
    this.drawer.toggle(true);
    this.addBodyClass(true);
    if (!this.expenseInvestigationMileageCreatePermission?.enabled || !this.expenseUnlinkedMileageCreatePermission?.enabled) {
      if (!this.expenseInvestigationMileageCreatePermission?.enabled && this.expenseUnlinkedMileageCreatePermission?.enabled) {
        this._unlinked = true;
      }
    }
    this.cdr.detectChanges();
  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }

  public disabledDates = (date: Date): boolean => {
    return moment(date).isAfter();
  };

  public onSubmit() {
    if (!this.isFormValid()) {
      return;
    }
    const data = {
      ...this.mileage,
      billableQuantity: this.mileage.billableQuantity || 0,
      nonBillableQuantity: this.mileage.nonBillableQuantity || 0,
    };
    if (!data?.id) delete data.id;
    this.loaderService.show$(
      this.expenseService.add(data as IApiAddExpenseInput).pipe(
        this.notificationService.snackbarPipe("Mileage item added."),
        this.notificationService.snackbarErrorPipe("Error adding mileage item.")
      )
    ).subscribe(() => {
      this.form.form.reset();
      // Default description for mileage
      this.form.form.get('description').setValue(this.customMileageDescription);
      this.close();
    });
  }

  public isFormValid(): boolean {
    this.form.control.markAllAsTouched();
    return this.form.valid;
  }

  public changePaid(e) {
    if (!e) {
      this._mileage.paidDate = null;
    }
  }
}
