<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationRequestsUpdate" #investigationRequestsUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationRequestsDelete" #investigationRequestsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentsCreate" #investigationDocumentsCreate="appEnforcePermission"></div>
<!-- Permissions -->
<section class="mat-typography">

  <!-- Reusable card for each service -->
  <mat-card class="mainCard" *ngFor="let request of requests">
    <!-- Card header options -->
    <div class="flexContainer cardHeader">
      <div class="equal">
        <h3 class="mat-h3" [innerHTML]="request.RequestType.name"></h3>
      </div>
      <div class="textRight">
        <button mat-raised-button color="primary" class="mt-2 mr-2" (click)="openUpdateRequest(request)" *ngIf="investigationRequestsUpdate?.enabled">Update</button>
        <button mat-icon-button (click)="removeRequest(request.id, {id: request.Investigation.id})" *ngIf="investigationRequestsDelete?.enabled">
          <mat-icon color="warn">delete_forever</mat-icon>
        </button>
      </div>
    </div>

    <!-- Request Data -->
    <div class="flexContainer">

      <!-- createdAt -->
      <div class="equal">
        <p class="mat-caption">Submitted:</p>
        <p [innerHTML]="request.createdAt | customDate: 'MM/dd/yyyy, h:mm a'"></p>
      </div>

      <!-- CreatedBy -->
      <div class="equal">
        <p class="mat-caption">Submitted by:</p>
        <p [innerHTML]="request.CreatedBy | fullName"></p>
      </div>

      <!-- AuthorizedBy -->
      <div class="equal">
        <p class="mat-caption">Authorized by:</p>
        <p [innerHTML]="request.authorizedBy"></p>
      </div>

      <!-- Description -->
      <div class="flex3">
        <p class="mat-caption">Description:</p>
        <p [innerHTML]="request.notes"></p>
      </div>

      <!-- Status -->
      <div class="equal textRight">
        <p class=" mat-caption">STATUS</p>
        <p [innerHTML]="request.Status"></p>
      </div>
    </div>

    <!-- Request History -->

    <table mat-table [dataSource]="request.StatusHistory" class="mat-elevation-z4">

      <!-- Updated (actually createdAt) -->
      <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef>Updated</th>
        <td mat-cell *matCellDef="let history" [innerHTML]="history.createdAt | customDate: 'MM/dd/yyyy, h:mm a'"></td>
      </ng-container>

      <!-- UpdatedBy (actually CreatedBy) -->
      <ng-container matColumnDef="updatedBy">
        <th mat-header-cell *matHeaderCellDef>Updated By</th>
        <td mat-cell *matCellDef="let history" [innerHTML]="history.CreatedBy | fullName"></td>
      </ng-container>

      <!-- Comment -->
      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef>Comment</th>
        <td mat-cell *matCellDef="let history" [innerHTML]="history.comment"></td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let history" [innerHTML]="history.name"></td>
      </ng-container>

      <!-- column headers -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <!-- Add Comment Button-->
    <button mat-icon-button (click)="openUpdateRequest(request)" *ngIf="investigationDocumentsCreate?.enabled">
      <mat-icon color="accent">comment</mat-icon>
    </button>
  </mat-card>

  <!-- No Results-->
  <mat-card class="mainCard" *ngIf="!requests">
    <div class="flexContainer center">
      <h3 class="">There are no requests for this investigation.</h3>
    </div>
  </mat-card>
</section>
