import { Component } from '@angular/core';

@Component({
  selector: 'app-evidence-management',
  templateUrl: './evidence-management.component.html',
  styleUrls: ['./evidence-management.component.scss']
})
export class EvidenceManagementComponent{

  constructor() { }

}
