import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, Inject, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AuthService, InvestigationService, ServiceService, ServiceTypeService } from 'src/app/shared/services';
import { IApiServiceType, IApiAddServiceInput, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUpdateInvestigationInput, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { InvestigationHistoryModalComponent } from './../investigation-history-modal/investigation-history-modal.component';
import {
  investigationServicesCreate,
  investigationServicesCreateOwn,
} from "src/app/shared/helpers/auth-config/investigations.config";

@Component({
  templateUrl: './investigation-add-service-modal.component.html',
  styleUrls: ['./investigation-add-service-modal.component.scss']
})
export class InvestigationAddServiceModalComponent {

  public authConfig = {
    investigationServicesCreate,
    investigationServicesCreateOwn
  };

  @ViewChild("investigationServicesCreate") investigationServicesCreatePerm;
  @ViewChild("investigationServicesCreateOwn") investigationServicesCreateOwnPerm;
  /**
   * ID of the service to update
   */
  private _id: "";
  public get serviceId() { return this._id; }
  public set serviceId(val) { this._id = val; }

  /**
   * service data
   */
  private _service: IApiAddServiceInput;
  public get service() {
    return this._service;
  }
  public set service(val) { this._service = val; }

  public serviceTypes: IApiServiceType[] = [];
  private user: IApiUser;
  constructor(
    private serviceTypesService: ServiceTypeService,
    private serviceService: ServiceService,
    public dialogRef: MatDialogRef<InvestigationAddServiceModalComponent>,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private investigationService: InvestigationService,
    private dialog: MatDialog,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Find logged in user
    this.auth.authenticatedUser.pipe(
      take(1)
    ).subscribe(user => this.user = user);

    // this._service = data || {};
    this._service = {
      TypeId: null,
      InvestigationId: data.Investigation.id,
      AssignedUserIds: null
    };

    // load service types
    this.loader.show$(this.serviceTypesService.get()).pipe(
      unwrapConnection(),
      filter(r => !!r),
      this.notifications.catchAlertPipe("Error loading ServiceTypes")
    ).subscribe((res) => {
      this.serviceTypes = res.sort((a, b) => a.name > b.name ? 1 : -1);
    });
  }

  public onSubmit() {
    if (this._id) {
      this.save();
    } else {
      this.add();
    }
  }

  public onCancelClick(): void {
    this.dialogRef.close();
  }

  /**
   * Updates the current record
   */
  private save() {
    this.loader.show$(this.serviceService.update({ id: this._id, ...this._service })).pipe(
      this.notifications.alertPipe("Service saved successfully"),
      this.notifications.catchAlertPipe("Error updating service; please try again")
    ).subscribe((res) => {
      this.dialogRef.close(res);
    });
  }

  // Sending back to parent component to save to capture investigation history
  private add() {
    this.dialogRef.close({...this._service, AssignedUserIds: [this._service.AssignedUserIds]}); // AssignedUserIds needs to be an array
  }

  get getStaff() {
    const own = this.investigationServicesCreateOwnPerm?.enabled;
    const all = this.investigationServicesCreatePerm?.enabled;
    if (own && !all) {
      return this.data.Investigation.InvestigationStaff.filter(item => item.User.id === this.user.id);
    } else {
      return this.data.Investigation.InvestigationStaff;
    }
  }

}
