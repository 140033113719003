import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { safeResolve } from '../../../rxjs';
import {
  IApiAddInvestigationPartyInput, IApiInvestigationPartyConnection, IApiInvestigationPartyFilter, IApiUpdateInvestigationPartyInput
} from '../../../types/types';
import { addInvestigationPartyMutation, investigationPartyConnectionQuery, removeInvestigationPartyMutation } from './investigation-party.operations';


@Injectable()
export class GqlInvestigationPartyService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getInvestigationParties(filters: IApiInvestigationPartyFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationPartyConnection> {
    return this.apollo.query(connectionQuery(investigationPartyConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addInvestigationParty(input: IApiAddInvestigationPartyInput) {
    return this.apollo.mutate({
      mutation: addInvestigationPartyMutation,
      variables: { input }
    }).pipe(safeResolve(true));
  }

  public removeInvestigationParty(id: string) {
    return this.apollo.mutate({
      mutation: removeInvestigationPartyMutation,
      variables: { id }
    }).pipe(safeResolve(true));
  }

  public updateInvestigationParty(input: IApiUpdateInvestigationPartyInput) {
    return this.apollo.mutate({
      mutation: gql`mutation updateInvestigationParty($input: UpdateInvestigationPartyInput!) {
        updateInvestigationParty(input: $input) {
          id
          createdAt
          updatedAt
        }
      }`,
      variables: { input }
    }).pipe(safeResolve(true));
  }
}
