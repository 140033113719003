<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>Add Contact</span>
      <div class="modal-subtitle" [innerHTML]="data.company.name"></div>
    </div>
  </kendo-dialog-titlebar>
  <h6>Add To Branch</h6>
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label text="Branch Name"></kendo-label>
        <kendo-dropdownlist [data]="data.company.Branches" name="branchName" #branchName="ngModel"
          [(ngModel)]="selectedBranch" textField="name" valueField="id" fillMode="outline" [valuePrimitive]="true"
          [defaultItem]="{id: null, name: 'Select Branch'}" [popupSettings]="{
          appendTo: 'component',
          animate: false,
          width: 250
        }">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="!dataItem?.id" class="placeholder">
              {{ dataItem?.name }}
            </span>
            <span *ngIf="dataItem?.id" class="not-placeholder">
              {{ dataItem?.name }}
            </span>
          </ng-template>
        </kendo-dropdownlist>
      </kendo-formfield>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <kendo-card class="m-2" width="100%">
    <kendo-card-header>
      Select Existing Contact
    </kendo-card-header>
    <kendo-card-body>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Search" [for]="search"></kendo-label>
            <kendo-textbox name="search" placeholder="Search" #search [(ngModel)]="searchValue" fillMode="outline"
              [clearButton]="true">
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="State"></kendo-label>
            <kendo-dropdownlist [data]="stateService?.allStates" name="stateField" #stateField="ngModel"
              [(ngModel)]="state" textField="text" valueField="value" fillMode="outline" [valuePrimitive]="true"
              [defaultItem]="{value: null, text: 'Select State'}" [popupSettings]="{
              appendTo: 'component',
              animate: false,
              width: 250
            }">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.value" class="placeholder">
                  {{ dataItem?.text }}
                </span>
                <span *ngIf="dataItem?.value" class="not-placeholder">
                  {{ dataItem?.text }}
                </span>
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <app-company-select-single-kendo placeholder="Search for a Company"
            (selectionChanged)="onCompanyChange($event)"></app-company-select-single-kendo>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="search-btn">
          <button kendoButton themeColor="primary" (click)="load()" fillMode="solid" class="mr-2"
            [iconClass]="'fa-solid fa-search'">Search</button>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-grid class="permission-role-list" [data]="{
        data: contacts,
        total: (dataSource?.pageInfo$ | async)?.totalCount
      }" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="{
        buttonCount: 6,
        type: 'numeric',
        pageSizes: [5, 10, 25, 100],
        previousNext: true,
        responsive: true,
        position: 'bottom'
      }" (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)">
        <kendo-grid-column field="ICON" title="" [width]="10" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton themeColor="primary" fillMode="link" iconClass="fas fa-plus"
              (click)="selectContact(dataItem)"></button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="NAME" title="Name" [width]="15" [sortable]="false" class="font-weight600"
          [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem | fullName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="POSITION" title="Position/Company" [width]="20" [sortable]="false"
          [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="dataItem?.BranchAssignments?.length;else noData">
              <span *ngFor="let assignment of dataItem?.BranchAssignments"
                [innerHTML]="assignment?.Branch?.Company?.name || '-'">
              </span>
            </ng-container>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="BRANCH" title="Branch" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="dataItem?.BranchAssignments?.length;else noData">
              <span *ngFor="let branch of dataItem?.BranchAssignments" [innerHTML]="branch?.Branch?.name || '-'"></span>
            </ng-container>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="ROLES" title="Roles" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [innerHTML]="dataItem.Role.name || '-'"></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="EMAILS" title="Emails" [width]="25" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="dataItem.Emails?.length;else noData">
              <app-email-phone-view *ngFor="let email of dataItem.Emails" [email]="email"></app-email-phone-view>
            </ng-container>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="PHONES" title="Phones" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="dataItem.Phones?.length;else noData">
              <app-email-phone-view *ngFor="let phone of dataItem.Emails?.length" [phone]="phone"></app-email-phone-view>
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
        <button style="margin-top: 16px !important;" kendoButton themeColor="primary" (click)="createNewContact()" fillMode="link" class="mt-2"
        [iconClass]="'fa-solid fa-plus'">Create New Contact</button>
    </kendo-card-body>
  </kendo-card>
  <div class="d-flex justify-content-end">
    <div>
      <button kendoButton themeColor="light" (click)="close(false)" fillMode="solid" class="mt-2">Cancel</button>
    </div>
  </div>
</div>
<ng-template #noData>-</ng-template>
