<section>
    <div class="page-header">
        <h4 class="mb-0 page-title">Settings</h4>
        <!-- NOT NEEDED -->
        <!-- <div>
            <kendo-button kendoButton [themeColor]="'primary'" fillMode="solid" iconClass="fas fa-plus" (click)="edit()">
                New Setting
            </kendo-button>
        </div> -->
    </div>
    <kendo-grid class="settings-list pl-16 pr-16" 
        [data]="{
            data: (dataSource?.contents$ | async) || [],
            total: (dataSource?.pageInfo$ | async)?.totalCount
        }" 
        [pageSize]="pageOptions?.limit" 
        [skip]="pageOptions?.offset" 
        [pageable]="{
            buttonCount: 6,
            type: 'numeric',
            pageSizes: [5, 10, 25, 100],
            previousNext: true,
            responsive: true,
            position: 'bottom'
        }" 
        (pageChange)="pageChange($event)" 
        [sortable]="true" 
        [sort]="sort" 
        (sortChange)="sortChange($event)">
        <kendo-grid-column field="Key" title="Key" [width]="50" class="id-column" [sortable]="false" class="font-weight600"
            [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="text-overflow">
                    <a (click)="edit(dataItem)">
                        {{dataItem?.key}}
                    </a>
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="Value" title="Value" [width]="50" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="text-overflow">
                    {{dataItem?.value}}
                </span>
            </ng-template>
        </kendo-grid-column>

        <!-- Action -->
        <kendo-grid-column field="id" title="Actions" [width]="10" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-dropdownbutton [data]="[{ text: 'Edit' }]" themeColor="primary" fillMode="link" buttonClass="grid-action-items"
                iconClass="fa-solid fa-ellipsis" (itemClick)="itemSelected($event, dataItem)" [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250,
                popupClass: 'grid-action-items-menu'
            }">
            </kendo-dropdownbutton>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</section>