import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { InvestigationRoleService } from '../../services';
import { IApiInvestigationRole } from '../../modules/graphql/types/types';

@Component({
  selector: 'app-staff-type-multi-select-kendo',
  templateUrl: './staff-type-multi-select-kendo.component.html',
  styleUrls: ['./staff-type-multi-select-kendo.component.scss']
})
export class StaffTypeMultiSelectKendoComponent implements OnInit, OnChanges {

  @Input() placeholder = "All States";
  @Input() label = "";
  @Input() disabled = false;
  @Input() selectedId: Array<string> = [];
  @Input() excludeTitles: Array<string> = [];

  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public selectedValue = [];
  public positions: Array<IApiInvestigationRole>;
  constructor(
    private investigationRoles: InvestigationRoleService,
  ) {
  }

  ngOnInit(): void {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    }
    this.investigationRoles.investigationRoles.subscribe((roles) => {
      this.positions = this.excludeTitles?.length ? roles?.filter((role)=> !this.excludeTitles?.includes(role?.title)) : roles;
    });
  }

  public ngOnChanges() {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    }
  }

  public searchSelect($event) {
    this.selectedId = $event;
    this.selectionChanged.emit(this.selectedId);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }
}
