import { PermissionsResolver } from './shared/route.resolvers';
import { HighOrderComponentModule } from './components/high-order/high-order-component.module';
import { AdminModule } from './components/admin/admin.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalModule } from './components/portal/portal.module';

import { AuthGuard } from '@auth0/auth0-angular';
import { ReportModule } from './components/report/report.module';
import { ForgotPasswordModule } from './components/forgot-password/forgot-password.module';
import { Capacitor } from '@capacitor/core';
import { MobileAppModule } from './components/mobile-app/mobile-app.module';

const isMobile = Capacitor.isNativePlatform();
const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    resolve: [PermissionsResolver],
    runGuardsAndResolvers: "always",
    loadChildren: () => HighOrderComponentModule
  },
  {
    path: "admin",
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    resolve: [PermissionsResolver],
    loadChildren: () => AdminModule
  },
  {
    path: "report",
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    resolve: [PermissionsResolver],
    loadChildren: () => ReportModule
  },
  {
    path: "portal",
    loadChildren: () => PortalModule
  },
  {
    path: "reset-password",
    loadChildren: () => ForgotPasswordModule
  }
];

const mobileRoutes: Routes = [
  {
    path: "",
    loadChildren: () => MobileAppModule
  }
];
@NgModule({
  imports: [RouterModule.forRoot(isMobile ? mobileRoutes : routes, { onSameUrlNavigation: "reload", relativeLinkResolution: 'legacy', scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
