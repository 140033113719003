import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DrawerComponent } from '@progress/kendo-angular-layout';
import { Location } from '@angular/common';
import { IApiAddRequestInput, IApiInvestigation, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiRequestType, IApiUpdateInvestigationInput } from 'src/app/shared/modules/graphql/types/types';
import { InvestigationService, RequestService, RequestTypeService } from 'src/app/shared/services';
import { filter, switchMap } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NgForm } from '@angular/forms';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';

@Component({
  selector: 'app-mobile-data-request-form',
  templateUrl: './mobile-data-request-form.component.html',
  styleUrls: ['./mobile-data-request-form.component.scss']
})
export class MobileDataRequestFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('drawerDataRequest') drawerDataRequest: DrawerComponent;
  @ViewChild('requestForm') requestForm: NgForm;
  public investigation: IApiInvestigation;
  public requestTypes: IApiRequestType[];

  private _request: IApiAddRequestInput = {
    InvestigationId: null,
    RequestTypeId: null,
    notes: null,
    authorizedBy: null
  };

  public get request() {
    return this._request;
  }

  public set request(val) {
    this._request = val;
  }

  constructor(
    private router: Router,
    private location: Location,
    private requestTypeService: RequestTypeService,
    private requestService: RequestService,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private investigationService: InvestigationService,
    private cdr: ChangeDetectorRef,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const data = this.router.getCurrentNavigation()?.extras?.state?.data
        if (data?.id) {
          this.investigation = data;
        }
      }
    });
  }

  ngOnInit(): void {
    this.loader.displayText = 'Loading...';
    this.loader.show$(this.requestTypeService.list([], {}).pipe(
      filter(r => !!r),
    ))
      .subscribe(response => {
        this.requestTypes = response;
      })
  }

  ngAfterViewInit() {
    this.drawerDataRequest.toggle();
    this.addBodyClass(true);
    this.cdr.detectChanges();
  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }

  close() {
    this.drawerDataRequest.toggle();
    this.addBodyClass(false);
    this.location.back();
  }


  submit() {
    if (!this.investigation?.id) {
      this.notifications.notify("Investigation not found!", { icon: false })
      return;
    }
    if (this.requestForm.invalid) {
      Object.keys(this.requestForm.controls).forEach(key => {
        this.requestForm.controls[key].markAllAsTouched();
      });
      return;
    }

    const input = {
      RequestTypeId: this.request.RequestTypeId,
      InvestigationId: this.investigation?.id,
      authorizedBy: this.request.authorizedBy,
      notes: this.request.notes
    };
    const investigationUpdate: IApiUpdateInvestigationInput = {
      id: this.investigation.id,
      History: {
        InvestigationId: this.investigation.id,
        comment: "Investigation Request Added",
        updateCategory: IApiInvestigationUpdateCategories.Requests,
        updateType: IApiInvestigationUpdateTypes.Create
      }
    }

    this.loader.show$(this.investigationService.update(investigationUpdate as IApiUpdateInvestigationInput).pipe(
      switchMap(() => this.requestService.add(input)),
      this.notifications.snackbarPipe("Data requested added."),
      this.notifications.snackbarErrorPipe("Error adding data request.")
    )).subscribe((res) => {
      this.location.back();
      this.addBodyClass(false);
    });
  }

  public ngOnDestroy(): void {
    this.addBodyClass(false);
  }
}
