import gql from 'graphql-tag';

export const investigationMetricsClientContactConnection = gql`query InvestigationMetricsClientContactConnection($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
investigationMetricsClientContactConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder)
  {
    pageInfo
    {
      limit
      offset
    }
    totalCount
    edges
    {
      cursor
      node
      {
        id
        firstName
        lastName
        companyName
        companyId
        data
      }
    }
  }
}  `
