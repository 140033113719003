<section>
<div class="page-header">
    <h1 class="mb-0">Investigation Timelog</h1>
</div>
</section>

<section class="time-log-report">
    <app-investigation-metrics-filters
      (applyFilter)="applyFilter($event)"
      (clearFilter)="applyFilter($event)"
      [investigationFilter]="true"
    ></app-investigation-metrics-filters>
    <hr/>
    <div class="total-record">Total Records: {{(dataSource?.pageInfo$ | async)?.totalCount}}</div>
    <kendo-grid
        class="timelog-list"
        [data]="{
        data: tableData,
        total: (dataSource?.pageInfo$ | async)?.totalCount
        }"
        [pageSize]="pageOptions?.limit"
        [skip]="pageOptions?.offset"
        [pageable]="{
        buttonCount: 6,
        type: 'numeric',
        pageSizes: [5, 10, 25, 100],
        previousNext: true,
        responsive: true,
        position: 'bottom'
        }"
        (pageChange)="pageChange($event)"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [navigatable]="false"
    >
    
        <!-- ID -->
        <kendo-grid-column
            field="NEFCO_NUMBER"
            title="ID#"
            [width]="20"
            class="id-column"
            [headerClass]="'sort-header'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <a
                    target="_blank"
                    [routerLink]="['/investigations/', dataItem?.id]"
                    tel=""
                    class="text-decoration"
                >
                {{ dataItem?.nefcoNumber || "Unassigned" }}
                </a>
            </ng-template>
        </kendo-grid-column>

        <!-- Received Date -->
        <kendo-grid-column
            field="RECEIVED_DATE"
            title="Received Date"
            [width]="20"
            [sortable]="true"
            [headerClass]="'sort-header'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{
                dataItem?.receivedDate | customDate : ("MM/dd/yyyy")
              }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Investigator -->
        <kendo-grid-column
            field="INVESTIGATOR"
            title="Investigator"
            [width]="25"
            [sortable]="true"
            [headerClass]="'sort-header'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.investigator || "-" }}</span>
            </ng-template>
        </kendo-grid-column>
        
        <!-- Tech Reviewer -->
        <kendo-grid-column
            field="TECH_REVIEWER"
            title="Tech Reviewer"
            [width]="25"
            [sortable]="true"
            [headerClass]="'sort-header'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.techReviewer || "-" }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Until On Scene -->
        <kendo-grid-column
            field="UNTIL_ON_SCENE"
            title="Until On Scene"
            [width]="20"
            [sortable]="true"
            [headerClass]="'sort-header'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.untilOnScene || "-" }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Until First Mileage Entered -->
        <kendo-grid-column
            field="UNTIL_FIRST_MILEAGE_ENTERED"
            title="Until First Mileage Entered"
            [width]="25"
            [sortable]="true"
            [headerClass]="'sort-header'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.untilFirstMileageEntered || "-" }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- On Scene to Notes Uploaded -->
        <kendo-grid-column
            field="ON_SCENE_TO_NOTES_UPLOADED"
            title="On Scene to Notes Uploaded"
            [width]="25"
            [sortable]="true"
            [headerClass]="'sort-header'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.onSceneToNotesUploaded || "-" }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- On Scene to Photos Uploaded -->
        <kendo-grid-column
            field="ON_SCENE_TO_PHOTOS_UPLOADED"
            title="On Scene to Photos Uploaded"
            [width]="25"
            [sortable]="true"
            [headerClass]="'sort-header'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.onSceneToPhotosUploaded || "-" }}</span>
            </ng-template>
        </kendo-grid-column>
      
          <ng-template kendoGridNoRecordsTemplate>
            <p class="mt-24 mb-24">There are no results to display.</p>
          </ng-template>
    </kendo-grid>
</section>
  