import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { map, tap } from "rxjs/operators";
import { IGqlUserAvailabilityResponse, IGqlUserAvailabilityInput } from '../interfaces';
import { ScheduleInformation } from "@microsoft/microsoft-graph-types";
import { Observable } from 'rxjs';
import { safeResolve } from "../rxjs";

@Injectable()
export class GqlUserAvailabilityService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getUsersAvailability(userAvailabilityInput: IGqlUserAvailabilityInput): Observable<ScheduleInformation[]> {
    const q = gql`query availabilityByUsers($input: UserAvailabilityInput!) {
         availabilityByUsers(input: $input)
      }`;

    return this.apollo.query<IGqlUserAvailabilityResponse[]>({ query: q, variables: { input: userAvailabilityInput } }).pipe(
      safeResolve(true)
    );
  }
}
