import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js/min';

import { IBranch } from '../interfaces/branch.interfaces';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {
  transform(phoneValue: number | string, country: string): any {
    try {
      const phoneNumber = parsePhoneNumberFromString(phoneValue + '', country as CountryCode);
      return phoneNumber.formatNational();
    } catch (error) {
      return phoneValue;
    }
  }
}

@Pipe({
  name: 'phoneBranchFormat'
})
export class PhoneBranchFormatPipe implements PipeTransform {
  transform(branch: IBranch): string {
    const primaryPhone = branch.Phones.find(phone => {
      if (phone.isPrimary) {
        return phone;
      }
    });
    const phoneNumber = parsePhoneNumberFromString(primaryPhone.Phone.number, 'US');
    return phoneNumber.formatNational();
  }
}
