import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBranchOptions } from '../../modules/graphql/interfaces';
import { IBranch, IBranchResults } from '../../interfaces/branch.interfaces';
import { IApiAddBranchInput, IApiBranch, IApiBranchConnection, IApiBranchFilter, IApiUpdateBranchInput } from '../../modules/graphql/types/types';
import { GqlBranchService } from '../../modules/graphql/services';

@Injectable()
export class BranchService {
  constructor(private service: GqlBranchService) {}

  public get(filters: IApiBranchFilter[] = [], options?: IGqlBranchOptions): Observable<IApiBranchConnection> {
    return this.service.get(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddBranchInput): Observable<IApiBranch> {
    return this.service.add(input);
  }

  public update(input: IApiUpdateBranchInput): Observable<IApiBranch> {
    return this.service.update(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.service.remove(id);
  }

  public getById(id: string): Observable<IApiBranch> {
    return this.service.getById(id);
  }
}
