import { connectionQuery } from './../../../helpers.class';
import { PermissionRoleConnectionQuery, addPermissionRoleMutation, updatePermissionRoleMutation, removePermissionRoleMutation, getPermissionRoleByIdQuery, assignPermissionRolePermissionMutation, removePermissionRolePermissionMutation, removePermissionRoleUserMutation, assignPermissionRoleUserMutation } from './permission-role.operations';
import { IApiAddPermissionRoleInput, IApiPermissionRole, IApiPermissionRoleFilter, IApiUpdatePermissionRoleInput, IApiPermissionRoleConnection, IApiAssignPermissionRolePermissionInput, IApiPermissionRolePermission, IApiRemovePermissionRoleUserInput, IApiAssignPermissionRoleUserInput } from './../../../types/types';

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import { IGqlBaseOptions } from "../../../interfaces";
import { safeResolve } from "../../../rxjs";

@Injectable()
export class GqlPermissionRoleService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getPermissionRoles(filters: IApiPermissionRoleFilter[], options?: IGqlBaseOptions): Observable<IApiPermissionRoleConnection> {
    return this.apollo.query(connectionQuery(PermissionRoleConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }
  public getPermissionRoleById(id: string): Observable<IApiPermissionRole> {
    return this.apollo.query({
      query: getPermissionRoleByIdQuery,
      variables: {
        id
      }
    }).pipe(
      safeResolve(true)
    );
  }

  public addPermissionRole(input: IApiAddPermissionRoleInput): Observable<IApiPermissionRole> {
    return this.apollo.mutate({
      mutation: addPermissionRoleMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updatePermissionRole(input: IApiUpdatePermissionRoleInput): Observable<IApiPermissionRole> {
    return this.apollo.mutate({
      mutation: updatePermissionRoleMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removePermissionRole(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removePermissionRoleMutation,
      variables: {
        id
      }
    }).pipe(
      safeResolve(true)
    );
  }

  public assignPermission(input: IApiAssignPermissionRolePermissionInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: assignPermissionRolePermissionMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public assignUser(input: IApiAssignPermissionRoleUserInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: assignPermissionRoleUserMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removePermission(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removePermissionRolePermissionMutation,
      variables: {
        id
      }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeUser(input: IApiRemovePermissionRoleUserInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removePermissionRoleUserMutation,
      variables: {
        input
      }
    }).pipe(
      safeResolve(true)
    );
  }
}
