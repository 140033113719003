import { GqlExpenseCategoryService } from '../../../modules/graphql/services/expenses/expense-category/expense-category.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../../modules/graphql/interfaces/base.interfaces';
import { IApiAddExpenseCategoryInput, IApiExpenseCategory, IApiExpenseCategoryConnection, IApiExpenseCategoryFilter, IApiUpdateExpenseCategoryInput } from "src/app/shared/modules/graphql/types/types";
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class ExpenseCategoryService {

  constructor(
    private gqlExpenseCategoryService: GqlExpenseCategoryService
  ) { }

  public get(filters?: IApiExpenseCategoryFilter[], options?: IGqlBaseOptions): Observable<IApiExpenseCategoryConnection> {
    return this.gqlExpenseCategoryService.getExpenseCategories(filters, options || new ListPage());
  }

  public add(input: IApiAddExpenseCategoryInput): Observable<IApiExpenseCategory> {
    return this.gqlExpenseCategoryService.addExpenseCategory(input);
  }

  public update(input: IApiUpdateExpenseCategoryInput): Observable<IApiExpenseCategory> {
    return this.gqlExpenseCategoryService.updateExpenseCategory(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlExpenseCategoryService.removeExpenseCategory(id);
  }
}
