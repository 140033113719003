import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
    id
    notes
    partyNotes
    vehicleNotes
    linkedInvestigationNotes
    billingInstructionNotes
    staffNotes
    riskDescription
    evidenceGatheringComplete
    specialInstructions
    budgetAmount
    otherContactName
    otherContactPhone
    cause
    specialInstructions
    policyNumber
    claimNumber
  `;

export const investigationDetailConnectionQuery = gql`${buildConnectionQuery("InvestigationDetail", "investigationDetailConnection", nodeFragment)}`;

export const addInvestigationDetailMutation = gql`mutation AddInvestigationDetail($input: AddInvestigationDetailInput!) {
  addInvestigationDetail(input: $input) { ${nodeFragment} }
}`;

export const updateInvestigationDetailMutation = gql`mutation UpdateInvestigationDetail($input: UpdateInvestigationDetailInput!) {
  updateInvestigationDetail(input: $input) { ${nodeFragment} }
}`;

export const removeInvestigationDetailMutation = gql`mutation RemoveInvestigationDetail($id: ID!) {
  removeInvestigationDetail(id: $id)
}`;
