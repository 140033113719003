import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlSubServiceService } from 'src/app/shared/modules/graphql/services';
import {
  IApiAddSubServiceInput, IApiSubService, IApiRemoveSubServiceInput,
  IApiUpdateSubServiceInput, IApiSubServiceFilter, IApiSubServicesConnection
} from 'src/app/shared/modules/graphql/types/types';


@Injectable()
export class SubServiceService {

  constructor(
    private gqlSubServiceService: GqlSubServiceService
  ) { }

  public get(filters: IApiSubServiceFilter[], options?: IGqlBaseOptions): Observable<IApiSubServicesConnection> {
    return this.gqlSubServiceService.get(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddSubServiceInput): Observable<IApiSubService> {
    return this.gqlSubServiceService.add(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlSubServiceService.remove(id);
  }

  public update(input: IApiUpdateSubServiceInput): Observable<IApiSubService> {
    return this.gqlSubServiceService.update(input);
  }

  public getSubServiceById(id: string): Observable<IApiSubService> {
    return this.gqlSubServiceService.getSubServiceById(id);
  }
}
