import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { keyDescOrder } from 'src/app/shared/helpers/helper';
import { StateService } from 'src/app/shared/services';
import { staffViewAll } from 'src/app/shared/helpers/auth-config/staff.config';

@Component({
  selector: 'app-investigation-metrics-table',
  templateUrl: './investigation-metrics-table.component.html',
  styleUrls: ['./investigation-metrics-table.component.scss']
})
export class InvestigationMetricsTableComponent {

  public authConfig = {
    staffViewAll,
  };
  @Input() tabIndex: number;

  public _data = [];
  @Input() set data(val) {
    this._data = val;
  }
  get data() {
    return this._data;
  }

  @Input() dataSource;
  @Input() pageOptions;
  @Input() sort;

  @Output() pageChange = new EventEmitter<any>();
  @Output() sortChange = new EventEmitter<SortDescriptor[]>();
  public keyDescOrder = keyDescOrder;

  constructor(
    private stateService: StateService,
  ) { }

  public getStateName(stateCode: string) {
    return this.stateService.allStates.find(i => i.value === stateCode);
  }

}
