import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IApiChangeOnSceneStatusInput, IApiInvestigationOnSceneConnection, IApiInvestigationOnSceneFilter, IApiUpdateInvestigationOnSceneScheduledDate } from "../../../types/types";
import { UpdateInvestigationOnSceneScheduledDateMutation, changeOnSceneStatusMutation, investigationOnSceneConnectionQuery } from "./investigation-on-scene.operations";
import { safeResolve } from "../../../rxjs";
import { IGqlBaseOptions } from "../../../interfaces";
import { connectionQuery } from "../../../helpers.class";

@Injectable()
export class GqlInvestigationOnSceneService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getInvestigationOnScene(filters: IApiInvestigationOnSceneFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationOnSceneConnection> {
    return this.apollo.query(connectionQuery(investigationOnSceneConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public changeOnSceneStatus(input: IApiChangeOnSceneStatusInput) {
    return this.apollo.mutate({
      mutation: changeOnSceneStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateInvestigationOnSceneScheduledDate(input: IApiUpdateInvestigationOnSceneScheduledDate) {
    return this.apollo.mutate({
      mutation: UpdateInvestigationOnSceneScheduledDateMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
