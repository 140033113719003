import { connectionQuery } from './../../helpers.class';
import { InvoiceConnectionQuery, addInvoiceMutation, resubmitInvoiceMutation, removeInvoiceMutation, updateInvoiceMutation } from './invoice.operations';
import { IApiAddInvoiceInput, IApiInvoice, IApiInvoiceFilter, IApiUpdateInvoiceInput } from './../../types/types';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../interfaces/base.interfaces';
import { IApiInvoiceConnection } from "../../types/types";

@Injectable()
export class GqlInvoiceService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getInvoices(filters: IApiInvoiceFilter[], options: IGqlBaseOptions): Observable<IApiInvoiceConnection> {
    return this.apollo.query(connectionQuery(InvoiceConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addInvoice(input: IApiAddInvoiceInput): Observable<IApiInvoice> {
    return this.apollo.mutate({
      mutation: addInvoiceMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateInvoice(input: IApiUpdateInvoiceInput): Observable<IApiInvoice> {
    return this.apollo.mutate({
      mutation: updateInvoiceMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeInvoice(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeInvoiceMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public resubmitInvoice(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: resubmitInvoiceMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
