import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsManagementComponent } from './permissions-management/permissions-management.component';
import { PermissionsRolesListComponent } from './permissions-roles-list/permissions-roles-list.component';
import { SharedModule } from "src/app/shared/shared.module";
import { PermissionRoleModalComponent } from "./permission-role-modal/permission-role-modal.component";
import { PermissionRoleAssignModalComponent } from "./permission-role-assign-modal/permission-role-assign-modal.component";
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

@NgModule({
  declarations: [
    PermissionsManagementComponent, 
    PermissionsRolesListComponent,
    PermissionRoleModalComponent,
    PermissionRoleAssignModalComponent,
    NotificationSettingsComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class PermissionsModule { }
