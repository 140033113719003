import { AuthService } from '../../../../shared/services/authorization/auth.service';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IInvestigation } from 'src/app/shared/interfaces/investigation.interfaces';
import { ExpenseItemService, UserService } from 'src/app/shared/services';
import { IApiAddExpenseInput, IApiExpenseItem, IApiExpensePurpose, IApiUpdateExpenseInput, IApiUploadTypes, IApiUserDetail } from 'src/app/shared/modules/graphql/types/types';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import moment from 'moment';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { ExpenseActionType } from 'src/app/shared/modules/graphql/enums/expense.enums';
import { expenseUnlinkedExpenseCreate, expenseInvestigationExpensesCreate } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FileSelectModalComponent } from 'src/app/shared/components/file-select-modal/file-select-modal.component';
import { imageMimeType, pdfMimeType } from 'src/app/shared/helpers/helper';
import { FileInterface } from 'src/app/shared/services/s3/s3-service';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';

type S3DocType = {
  key: string,
  filename: string,
  isNew?: boolean,
};

interface IAddExpenseInputandReceipt extends IApiUpdateExpenseInput {
  Receipt?: {
    key: string,
    filename: string
  };
}

@UntilDestroy()
@Component({
  selector: 'app-ind-expense-form-kendo',
  templateUrl: './ind-expense-form-kendo.component.html',
  styleUrls: ['./ind-expense-form-kendo.component.scss']
})
export class IndExpenseFormKendoComponent implements AfterViewInit {
  @ViewChild("autoCompleteExpenseItemId", { static: false }) public autoCompleteExpenseItemId: AutoCompleteComponent;

  public authConfig = {
    expenseUnlinkedExpenseCreate,
    expenseInvestigationExpensesCreate
  }

  @ViewChild("expenseForm") form: NgForm;
  @ViewChild("expenseUnlinkedExpenseCreate") expenseUnlinkedExpenseCreatePermission;
  @ViewChild("expenseInvestigationExpensesCreate") expenseInvestigationExpensesCreatePermission;

  private _expense: IApiUpdateExpenseInput = {
    id: null,
    expenseDate: new Date(),
    billableQuantity: null,
    ExpenseItemId: null,
    ExpensePurposeId: null,
    outOfPocket: 1,
    authorizedBy: null,
    InvestigationId: null,
  };

  private _expenseItems: IApiExpenseItem[] = null;
  public get expenseItems() {
    return this._expenseItems;
  }
  @Input() public set expenseItems(val: IApiExpenseItem[]) {
    this._expenseItems = val;
    if (this._expenseItems?.length && this._expense.ExpenseItemId) {
      this.typeChange(this._expense.ExpenseItemId)
    }
  }

  public get expense() {
    return this._expense;
  }

  @Input() actionType: ExpenseActionType;
  @Input() public set expense(val) {
    this._expense = val;
    if (val?.paidDate) {
      this._expense.paidDate = NefcoDateHelper.toLocal(
        this._expense.paidDate
      );
      this.paidCheckbox = true;
    }
  }
  @Input() investigations: IInvestigation[];
  @Input() investigationView = false;
  @Input() adminView = false;
  @Input() modalView = false;
  @Input() editQtyOnly = false;
  @Input() nefcoNumber = '';
  @Input() excludeExpenseItems: boolean = true;
  @Output() formSubmitted = new EventEmitter<IAddExpenseInputandReceipt>();
  // For embedded views
  @Output() expenseChange = new EventEmitter<IAddExpenseInputandReceipt>();
  @Output() s3DocChange = new EventEmitter<S3DocType>();

  /** PRIVATE */
  private _id = '';
  private _unlinked = false;
  private _userDetail: IApiUserDetail;
  private _userId: string;

  /** PUBLIC */
  public paidCheckbox: boolean = false;
  public typeArray: Array<{name?: string; value?: number}> = [];

  public get unlinked(): boolean {
    return this._unlinked;
  }
  public set unlinked(val: boolean) {
    this._unlinked = val;
    // Reset expense items on unlink change
    this.expense.ExpenseItemId = null;
    this.selectedTypeName = '';
    if (val === true) {
      this.expense.InvestigationId = null;
      this.expense.billableQuantity = 0;
    } else {
      this.expense.billableQuantity = 0;
    }
  }

  // Visible Expense Items
  // public get visibleExpenseItems() {
  //   if (this.expenseItems) {
  //     return this.expenseItems.filter((item) => item?.AppliesTo?.name !== (this.unlinked ? "Investigation" : "Unlinked") && item.name !== 'Mileage');
  //   }
  //   else {
  //     return [];
  //   }
  // }

  public get id(): string {
    return this._id;
  }
  public set id(val: string) {
    this._id = val;
  }

  public get expensePurposes(): IApiExpensePurpose[] {
    return this.expenseItems?.find(expenseItem => expenseItem.id === this.expense.ExpenseItemId)?.ExpenseCategory?.ExpensePurposes || [];
  }

  public get requiresAuthorization(): boolean {
    if (!this.expense.billableQuantity && !this.expense.nonBillableQuantity && !this.expense.authorizedBy && !this.expense.authorizedDate) return false;
    return (this.expense.billableQuantity + this.expense.nonBillableQuantity) > 100;
  }

  public get requiresAuthorizationFilled(): boolean {
    if (this.expense.authorizedBy && this.expense.authorizedDate) return false;
    return (!this.expense.authorizedBy || !this.expense.authorizedDate);
  }

  // Uploaded S3 Doc
  @Input() public s3Doc: S3DocType = {
    key: null,
    filename: null,
    isNew: false
  };

  public isFormValid(): boolean {
    this.form.control.markAllAsTouched();
    return this.form.form.valid;
  }

  // User Detail
  public get userDetail(): IApiUserDetail {
    return this._userDetail;
  }

  public set userDetail(val: IApiUserDetail) {
    this._userDetail = val;
  }

  // UserId
  public get userId() {
    return this._userId;
  }

  @Input() public set userId(val: string) {
    if (val) {
      this.loader.show$(this.userService.getByIdOnlyNefco(val)).subscribe(({ UserDetail, id }) => {
        this._userDetail = UserDetail;
        this.expense.UserId = id;
        if (this.expense?.outOfPocket === undefined || this.expense.outOfPocket === null) {
          this.expense.outOfPocket = UserDetail?.hasNefcoCreditCard ? 0 : 1;
        }
        if (UserDetail?.hasNefcoCreditCard) {
          this.typeArray = [{value: 1, name: 'Out of Pocket'}, {value: 0, name: 'NEFCO Card'}, {value: 2, name: 'Vendor/Other'}];
        } else {
          this.typeArray = [{value: 1, name: 'Out of Pocket'}, {value: 2, name: 'Vendor/Other'}];
          this.expense.outOfPocket = 1;
        }
      });
    } else {
      this.loader.show();
      this.auth.authenticatedUser.subscribe(({ UserDetail, id }) => {
        this.loader.hide();
        this._userDetail = UserDetail;
        this.expense.UserId = id;
        if (this.expense?.outOfPocket === undefined || this.expense.outOfPocket === null) {
          this.expense.outOfPocket = UserDetail?.hasNefcoCreditCard ? 0 : 1;
        }
        if (UserDetail?.hasNefcoCreditCard) {
          this.typeArray = [{value: 1, name: 'Out of Pocket'}, {value: 0, name: 'NEFCO Card'}, {value: 2, name: 'Vendor/Other'}];
        } else {
          this.typeArray = [{value: 1, name: 'Out of Pocket'}, {value: 2, name: 'Vendor/Other'}];
          this.expense.outOfPocket = 1;
        }
      });
    }
    this._userId = val;
  }
  public selectedTypeName:string;
  public typeIdsearch: string;
  constructor(
    private expenseItemService: ExpenseItemService,
    public loader: LoaderService,
    private auth: AuthService,
    private userService: UserService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
    if (this.expense.InvestigationId || this.actionType === "ADD_EXPENSE" || this.investigationView === true) {
      this._unlinked = false;
    } else {
      this._unlinked = true;
    }
    if (!this.expenseInvestigationExpensesCreatePermission?.enabled || !this.expenseUnlinkedExpenseCreatePermission?.enabled) {
      if (!this.expenseInvestigationExpensesCreatePermission?.enabled && this.expenseUnlinkedExpenseCreatePermission?.enabled) {
        this._unlinked = true;
      }
    }
    if (this.adminView) {
      this.form.form.valueChanges.pipe(
        untilDestroyed(this)
      ).subscribe(() => {
        this.expenseChange.emit(this.expense);
      });
    }
  }

  public disabledDates = (date: Date): boolean => {
    return moment(date).isAfter();
  };

  public onSubmit() {
    if (!this.isFormValid()) {
      return;
    }
    const { key, filename } = this.s3Doc;
    const data = {
      ...this.expense,
      Receipt: { key, filename },
      billableQuantity: this.expense.billableQuantity || 0,
      nonBillableQuantity: this.expense.nonBillableQuantity || 0,
    };
    this.formSubmitted.emit(data);
    this.s3Doc = { key: null, filename: null };
    this.form.form.reset();
  }

  public uploadSuccess(files : FileInterface[]) {
    const {key, file} = files[0];
    this.s3Doc = { key: key, filename: file.name };
    this.s3DocChange.emit({ ...this.s3Doc, isNew: true });
  }

  public removeDocument() {
    this.s3Doc = { key: null, filename: null }
    this.s3DocChange.emit({ ...this.s3Doc, isNew: true });
  }

  // Set custom description on type change
  public typeChange(selectedId: string) {
    this.cdr.detectChanges();
    this.autoCompleteExpenseItemId?.toggle(false);
    this._expense.ExpenseItemId = selectedId || null;
    this.selectedTypeName = this.expenseItems.find(({ id }) => id === this._expense.ExpenseItemId)?.name || '';
  }


  public resetPurposes() {
    this.expense.ExpensePurposeId = null;
  }

  public changedBillableNonBillableField() {
    if ((this.expense.billableQuantity + this.expense.nonBillableQuantity) < 100) {
      this.expense.authorizedBy = '';
      this.expense.authorizedDate = null;
    }
  }

  public changePaid(e) {
    if (!e) {
      this._expense.paidDate = null;
    }
  }

  public fileSelector() {
    const dialog: DialogRef = this.dialogService.open({
      content: FileSelectModalComponent,
      width: 600,
      maxWidth: 600,
      maxHeight: 670,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as FileSelectModalComponent;
    dialogInstance.data = {
      InvestigationId: this.unlinked ? null : this.expense.InvestigationId,
      FolderId: null,
      UserId: this.unlinked ? this.expense.UserId : null,
      type: this.unlinked ? IApiUploadTypes.ExpensesUnlinked : IApiUploadTypes.Expenses,
      restrictions: [...imageMimeType, ...pdfMimeType],
      multiple: false
    };
    dialog.result.subscribe((res: any) => {
      if (res?.length && res) {
        this.uploadSuccess(res);
      }
    });
  }
}
