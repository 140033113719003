<div [dialogRef]="dialog" (appClickOutside)="dialog.close(false)">
  <kendo-dialog-titlebar (close)="dialog.close(false)">
    <div class="modal-title">
      <span>{{data?.evidence?.length > 1 ? 'Transfer Items' : 'Transfer Item'}} {{evidenceIds}}</span>
      <div class="modal-subtitle" >
        Investigation # {{ nefcoNumber || 'Unassigned' }}
      </div>
    </div>
  </kendo-dialog-titlebar>
  <section>
    <form #transferForm="ngForm">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
        
        <kendo-gridlayout-item [colSpan]="2">
          <div class="kendo-formfield">
            <kendo-label class="asterisk" text="Transfer From"></kendo-label>
            <kendo-textbox name="transferFrom" [value]="currentLocation || '-'"
              placeholder="Transfer From" fillMode="outline" cdkFocusInitial [disabled]="true">
            </kendo-textbox>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="2">
          <kendo-formfield>
            <kendo-label class="asterisk" text="Transfer To"></kendo-label>
            <kendo-dropdownlist [data]="locations" name="transferTo" #transferTo="ngModel"
              [(ngModel)]="transfer.ToLocationId" [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
              [defaultItem]="{id: null, name: 'Select Transfer To'}" (ngModelChange)="$event === undefined ? createStorageLocation() : null"
              [popupSettings]="{appendTo: 'component', animate: false}" required [disabled]="this.data?.receiveTransfer">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!transferTo?.valid && (transferTo?.dirty || transferTo?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="transferTo?.errors?.required">
                Transfer To cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="2">
          <kendo-formfield>
            <kendo-label text="Carrier"></kendo-label>
            <kendo-dropdownlist [data]="carriers" name="shippingCarrier" #shippingCarrier="ngModel"
              [(ngModel)]="transfer.ShippingCarrierId" [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
              [defaultItem]="{id: null, name: 'Select Carrier'}" [popupSettings]="{appendTo: 'component', animate: false}"
              [disabled]="this.data?.receiveTransfer">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!shippingCarrier?.valid && (shippingCarrier?.dirty || shippingCarrier?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="shippingCarrier?.errors?.required">
                Carrier cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="2">
          <kendo-formfield>
            <kendo-label text="Tracking Number"></kendo-label>
            <kendo-textbox [(ngModel)]="transfer.trackingNumber" name="trackingNumber" #trackingNumber="ngModel"
              placeholder="Tracking Number" fillMode="outline" [disabled]="this.data?.receiveTransfer">
            </kendo-textbox>
            <kendo-formerror *ngIf="!trackingNumber?.valid && (trackingNumber?.dirty || trackingNumber?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="trackingNumber?.errors?.required">
                Tracking Number cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="4">
          <kendo-formfield>
            <kendo-label text="Comments"></kendo-label>
            <kendo-textarea name="comments" #comments="ngModel" [(ngModel)]="transfer.comment" placeholder="Comments"
              fillMode="outline" class="min-height-96" resizable="both" appNoWhiteSpace
              [disabled]="this.data?.receiveTransfer"></kendo-textarea>
            <kendo-formerror *ngIf="comments?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="comments?.errors.required">Comments cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="4">
          <ng-container  *ngIf="!uploadedDoc && !this.data?.receiveTransfer; else docUploaded">
            <button kendoButton class="mr-24" themeColor="primary" fillMode="link" iconClass="fa-solid fa-cloud-arrow-up"
            (click)="fileSelector()">
              Upload shipping receipt
            </button>
          </ng-container>
          <ng-template #docUploaded>
            <kendo-chip class="mr-16 fw600" [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'"
            [removable]="true" (remove)="removeDocument()" [disabled]="this.data?.receiveTransfer" *ngIf="uploadedDoc">
              <a href="{{uploadedDoc.s3Uri | s3}}" target="_blank" [innerHTML]="uploadedDoc.title"></a>
            </kendo-chip>
          </ng-template>
        </kendo-gridlayout-item>
        <ng-container *ngIf="this.data?.receiveTransfer">
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label text="Section"></kendo-label>
              <kendo-textbox [(ngModel)]="transfer.evidenceSection" name="section" #section="ngModel"
                placeholder="Section" fillMode="outline" appNoWhiteSpace>
              </kendo-textbox>
              <kendo-formerror *ngIf="!section?.valid && (section?.dirty || section?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="section?.errors?.required">
                  Section cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>

            <kendo-formfield>
              <kendo-label text="Row"></kendo-label>
              <kendo-textbox [(ngModel)]="transfer.evidenceRow" name="row" #row="ngModel"
                placeholder="Row" fillMode="outline" appNoWhiteSpace>
              </kendo-textbox>
              <kendo-formerror *ngIf="!row?.valid && (row?.dirty || row?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="row?.errors?.required">
                  Row cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>

            <kendo-formfield>
              <kendo-label class="asterisk" text="Scheduled Date"></kendo-label>
              <kendo-datetimepicker calendarType="classic" [fillMode]="'outline'"
                name="recivedDate'" #recivedDate="ngModel"
                [(ngModel)]="transfer.receivedDate" placeholder="Enter date" format="MM/dd/yyyy hh:mm a"
                required="true" formatPlaceholder="formatPattern"></kendo-datetimepicker>
              <kendo-formerror>
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="!recivedDate?.valid && (recivedDate?.dirty || recivedDate?.touched) && recivedDate.errors.required">
                  Date cannot be left blank
                </span>
                <span *ngIf="!recivedDate?.valid && (recivedDate?.dirty || recivedDate?.touched) && recivedDate.errors.minError">
                  Date should be in the past
                </span>
              </kendo-formerror>
            </kendo-formfield>

          </kendo-gridlayout-item>

          <!-- <kendo-gridlayout-item>
            <ngx-timepicker-field [disabled]="!transfer.receivedDate" [(ngModel)]="receivedTime" [controlOnly]="true" [ngModelOptions]="{standalone: true}"></ngx-timepicker-field>
          </kendo-gridlayout-item> -->

          <kendo-gridlayout-item [colSpan]="4">
            <kendo-formfield>
              <kendo-label text="Received Comments"></kendo-label>
              <kendo-textarea name="commentReceived" #commentReceived="ngModel" [(ngModel)]="transfer.comment" placeholder="Received Comments"
                fillMode="outline" class="min-height-96" resizable="both" appNoWhiteSpace
                [disabled]="this.data?.receiveTransfer"></kendo-textarea>
              <kendo-formerror *ngIf="commentReceived?.errors">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="commentReceived?.errors.required">Received comments cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
        </ng-container>
      </kendo-gridlayout>
    </form>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="dialog.close(false)">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="saveTransfer()">
      {{this.data?.receiveTransfer ? 'Receive' : 'Save'}} Transfer
    </button>
  </kendo-dialog-actions>
</div>