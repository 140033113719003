import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const nodeFragment = `
  id
  model
  make
  year
  vinNumber
  stockNumber
  insuranceCompany
  policyNumber
  Investigation {
    id
  }
  User {
    id
  }
`;

export const vehicleConnectionQuery = gql`${buildConnectionQuery("Vehicle", "vehicleConnection", nodeFragment)}`;

export const getVehicleByIdQuery = gql`query getVehicleById($id: ID!) {
  getVehicleById(id: $id) {
    ${nodeFragment}
  }
}`;

export const addVehicleMutation = gql`mutation createVehicle($input: AddVehicleInput!) {
  addVehicle(input: $input) { ${nodeFragment} }
}`;

export const removeVehicleMutation = gql`mutation removeVehicle($id: ID!) {
  removeVehicle(id: $id)
}`;

export const updateVehicleMutation = gql`mutation updateVehicle($input: UpdateVehicleInput!) {
  updateVehicle(input: $input) { ${nodeFragment} }
}`;
