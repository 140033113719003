import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { CertificationService, DocumentService, DocumentTypeService, DownloadHelper } from 'src/app/shared/services';
import { IApiCertification, IApiCertificationFilterType, IApiCertificationOrderBy, IApiCertificationStatusType, IApiCommentableType, IApiDocument, IApiDocumentType, IApiDocumentTypeOrderBy, IApiUploadTypes, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { GqlCommentService } from 'src/app/shared/modules/graphql/services';
import { switchMap, tap, filter } from 'rxjs/operators';
import { DialogContentBase, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FileSelectModalComponent } from 'src/app/shared/components/file-select-modal/file-select-modal.component';
import { imageMimeType, pdfMimeType } from 'src/app/shared/helpers/helper';
import { FileInterface } from 'src/app/shared/services/s3/s3-service';

export interface ICertificationSidebarData {
  certification: IApiCertification | null;
  config: {
    singleInvestigator: IApiUser | null;
  };
}

@UntilDestroy()
@Component({
  templateUrl: './certification-sidebar.component.html',
  styleUrls: ['./certification-sidebar.component.scss']
})
export class CertificationSidebarComponent extends DialogContentBase implements OnInit {

  @Input() public data: ICertificationSidebarData;
  public comment: string = null;
  public fileToUpload: string = null;
  public startDate: Date = null;
  public expirationDate: Date = null;

  public documentTypes: IApiDocumentType[] = [];
  public userCertifications: IApiCertification[] = [];
  public statuses = IApiCertificationStatusType;
  public displayedColumns = ["date", "comment", "user"];

  constructor(
    private certificationService: CertificationService,
    private documentService: DocumentService,
    private documentTypeService: DocumentTypeService,
    private notification: NotificationsService,
    private loader: LoaderService,
    private commentService: GqlCommentService,
    private dialogService: DialogService,
    private downloadHelper: DownloadHelper,
    public dialogRef: DialogRef,
  ) {
    super(dialogRef);
  }

  private getCertifications() {
    return this.certificationService.get([{ type: IApiCertificationFilterType.User, value: this.data?.config.singleInvestigator.id }]).pipe(
      unwrapConnection(),
      tap((certs) => this.userCertifications = certs)
    );
  }

  private get certificationTypeId() {
    return this.data?.certification?.id || null;
  }

  public isPanelExpanded(index: number) {
    if (index === 0 || this.data?.config?.singleInvestigator) return true;
    return false;
  }

  private _updateCertification(cert: IApiCertification): Observable<IApiCertification> {
    const updateCert = {
      ...cert,
      TypeId: cert.Type.id,
      User: this.data?.config?.singleInvestigator ? this.data.config.singleInvestigator.id : cert.User.id,
      DocumentId: cert.Document?.id || null,
    };
    delete updateCert.__typename;
    delete updateCert.Type;
    delete updateCert.Document;
    delete updateCert.Comments;
    delete updateCert.CertificationTypeId;
    return this.certificationService.update(updateCert as any).pipe(
      this.notification.catchAlertPipe("Unable to update this certification, please try again."),
      tap(() => this.notification.notify("Certification updated successfully!")),
      tap(() => this.loadData())
    );
  }

  public updateCertification(cert: IApiCertification): Subscription {
    return this._updateCertification(cert).subscribe();
  }

  public fileSelector(cert: IApiCertification) {
    const dialog: DialogRef = this.dialogService.open({
      content: FileSelectModalComponent,
      width: 600,
      maxWidth: 600,
      maxHeight: 670,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as FileSelectModalComponent;
    dialogInstance.data = {
      InvestigationId: null,
      FolderId: null,
      UserId: this.data?.config?.singleInvestigator ? this.data.config.singleInvestigator.id : cert.User.id,
      type: IApiUploadTypes.Certificates,
      restrictions: [...imageMimeType, ...pdfMimeType],
      multiple: false
    };
    dialog.result.subscribe((res: any) => {
      if (res?.length && res) {
        this.onUploadSuccess(res, cert);
      }
    });
  }

  public onUploadSuccess( filesUploaded: FileInterface[] , cert: IApiCertification) {
    const type = this.documentTypes.find(({ name }) => name === "Certification");
    if (!type) {
      this.notification.alert("Unable to find the correct document type. Please try again");
    }
    this.loader.show$(
      forkJoin(
        filesUploaded.map(({ key }) => {
          return this.documentService.add({
            uri: '',
            s3Uri: key,
            title: `${cert.Type.title}-${cert.User.firstName}-${cert.User.lastName}`,
            caption: ``,
            InvestigationId: null,
            TypeId: type.id
          });
        })
      ).pipe(
        switchMap((docs) => {
          if (!cert.Document) {
            cert.Document = {
              id: null,
              title: null,
              uri: null,
              s3Uri: null,
              caption: null,
            };
          }
          cert.Document.id = (docs[0] as IApiDocument)?.id;
          return this._updateCertification(cert);
        }),
        this.notification.catchAlertPipe("Error linking uploaded document. Please try again."),
        tap(() => this.loadData())
      )
    ).subscribe();
  }

  public removeDocument(cert: IApiCertification) {
    this.documentService.remove(cert.Document.id).pipe(
      this.notification.catchAlertPipe("Unable to delete document, please try again."),
    ).subscribe(() => {
      this.notification.notify("Document removed successfully!");
      this.loadData();
    });
  }

  public addNewComment(cert: IApiCertification, comment: string) {
    this.loader.show$(this.commentService.addComment({ commentableId: cert.id, commentableType: IApiCommentableType.Certification, text: comment }))
      .subscribe(() => {
        this.comment = '';
        this.notification.notify("Comment added!");
        this.loadData();
      });
  }

  private loadData() {
    const filter = { type: IApiCertificationFilterType.Type, value: this.certificationTypeId };
    if (!this.certificationTypeId) {
      filter.type = IApiCertificationFilterType.Id;
      filter.value = this.userCertifications[0].id;
    }
    this.loader.show$(forkJoin([
      this.documentTypeService.get([], { orderBy: IApiDocumentTypeOrderBy.Name, sortOrder: SortOrder.ASC, limit: -1 }).pipe(unwrapConnection()),
      this.certificationService.get([filter], { sortOrder: SortOrder.ASC, orderBy: IApiCertificationOrderBy.User, limit: -1 }).pipe(unwrapConnection()),
    ])).subscribe(([docTypes, certs]) => {
      this.documentTypes = docTypes;
      this.userCertifications = certs;
      this.userCertifications.map((item) => {
        item.issuedAt = new Date(item.issuedAt);
        item.expiresAt = new Date(item.expiresAt);
        return item;
      });
    });
  }

  public deleteStaffCertification(id: string) {
    this.notification.kendoConfirm("Really remove this certification?", "Are you sure?").pipe(
      filter(response => !!response),
      switchMap(() =>
        this.loader.show$(this.certificationService.remove(id))
      )
    ).pipe(
      this.notification.catchAlertPipe("Error removing Certification; please try again"),
      this.notification.snackbarPipe("Certification removed successfully!")
    ).subscribe(() => {
      if (this.data.config.singleInvestigator) {
        this.dialogRef.close(true);
      } else {
        if (this.userCertifications?.length === 1) {
          this.dialogRef.close(true);
        } else {
          this.loadData()
        }
      }
    }, () => {
      if (this.data.config.singleInvestigator) {
        this.dialogRef.close(true);
      } else {
        if (this.userCertifications?.length === 1) {
          this.dialogRef.close(true);
        } else {
          this.loadData()
        }
      }
    });
  }

  public donwloadCertificate(url: string) {
    this.downloadHelper.openURL(url);
  }

  ngOnInit() {
    this.loader.show$(this.documentTypeService.get([], { orderBy: IApiDocumentTypeOrderBy.Name, sortOrder: SortOrder.ASC, limit: -1 })).pipe(
      unwrapConnection()
    ).subscribe((data) => {
      this.documentTypes = data
      // only run if not a single investigator view
      if (this.data?.config?.singleInvestigator) {
        this.userCertifications = this.data.config?.singleInvestigator?.Certifications;
        this.userCertifications.map((item) => {
          item.issuedAt = new Date(item.issuedAt);
          item.expiresAt = new Date(item.expiresAt);
          return item;
        });
      } else {
        this.loadData();
      }
    });
  }

}
