import { connectionQuery } from './../../helpers.class';
import { IGqlBaseOptions } from './../../interfaces/base.interfaces';
import { IApiAddDocumentSentInput, IApiDocument, IApiDocumentConnection, IApiDocumentFilter, IApiDocumentFilterType, IApiUpdateDocumentInput } from '../../types/types';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IApiAddDocumentInput } from "../../types/types";
import { addDocumentMutation, updateDocumentMutation, removeDocumentMutation, documentConnectionQuery, sentDocumentMutation } from "./document.operations";
import { safeResolve } from "../../rxjs";

@Injectable()
export class GqlDocumentService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getDocuments(filters: IApiDocumentFilter[], options: IGqlBaseOptions): Observable<IApiDocumentConnection> {
    const viewType = filters.find(obj => 
      obj.type === IApiDocumentFilterType.ViewEditorReview || 
      obj.type === IApiDocumentFilterType.ViewTechReview ||
      obj.type === IApiDocumentFilterType.ViewReadyforeditor
    );

    return this.apollo.query(connectionQuery(documentConnectionQuery(viewType), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addDocument(input: IApiAddDocumentInput): Observable<IApiDocument> {
    return this.apollo.mutate({
      mutation: addDocumentMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public sentDocument(input: IApiAddDocumentSentInput): Observable<IApiDocument> {
    return this.apollo.mutate({
      mutation: sentDocumentMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateDocument(input: IApiUpdateDocumentInput): Observable<IApiDocument> {
    return this.apollo.mutate({
      mutation: updateDocumentMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeDocument(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeDocumentMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
