<kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
  <kendo-gridlayout-item>
    <div class="kendo-formfield left-search-icon">
      <kendo-label text="Search"></kendo-label>
      <div style="position: relative;">
        <i class="fa fa-search" aria-hidden="true"></i>
        <kendo-textbox name="search" [(ngModel)]="search" (valueChange)="searchChanged()" placeholder="Search"
          fillMode="outline" [clearButton]="true">
        </kendo-textbox>
      </div>
    </div>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item>
    <div class="kendo-formfield">
        <kendo-label [for]="state" text="State"></kendo-label>
        <kendo-dropdownlist #state [data]="groupedDataStates" textField="text" valueField="value" [valuePrimitive]="true"
          fillMode="outline" [filterable]="false" (filterChange)="handleFilter($event)"
          [defaultItem]="{value: undefined, text: 'All States', type: ''}" (selectionChange)="stateChange($event)"
          [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
          <ng-template kendoDropDownListGroupTemplate let-groupName>
            <strong>{{ groupName }}</strong>
          </ng-template>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.text }} </span>
            <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.text }} </span>
          </ng-template>
        </kendo-dropdownlist>
    </div>
  </kendo-gridlayout-item>

  <ng-container *ngIf="!certView">
    <kendo-gridlayout-item>
      <div class="kendo-formfield">
        <kendo-label [for]="certification" text="Certification"></kendo-label>
        <kendo-dropdownlist #certification [data]="certificationTypes" textField="title" valueField="id" [valuePrimitive]="true"
          fillMode="outline" [defaultItem]="{id: null, title: 'All Certifications'}"
          (selectionChange)="certificationChange($event)"
          [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span [ngClass]="dataItem?.id ? 'not-placeholder' : 'placeholder'"> {{ dataItem.title }} </span>
          </ng-template>
        </kendo-dropdownlist>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <div class="kendo-formfield">
        <kendo-label [for]="status" text="Status"></kendo-label>
        <kendo-dropdownlist #status [data]="statuses | keyvalue" textField="value" valueField="value" [valuePrimitive]="true"
          fillMode="outline" [defaultItem]="{value: 'All Status'}"
          (selectionChange)="statusChange($event?.value !== 'All Status' ? $event.value : null)"
          [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span [ngClass]="dataItem?.value !== 'All Status' ? 'not-placeholder' : 'placeholder'"> {{ dataItem.value | quiet }} </span>
          </ng-template>
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <span> {{ dataItem.value | quiet }} </span>
          </ng-template>
        </kendo-dropdownlist>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <kendo-label text=""></kendo-label>
      <div class="kendo-formfield">
        <div class="k-checkbox-wrap">
          <input
            #includeRestriction
            type="checkbox"
            kendoCheckBox
            name="isRestriction"
            [checked]="true"
            (change)="restrictionChange($event.target)"
          />
          <kendo-label
            [for]="includeRestriction"
            class="k-checkbox-label font-weight-600 checkbox-margin"
            style="margin-bottom: 0"
            text="Include Restrictions"
          ></kendo-label>
        </div>
      </div>
    </kendo-gridlayout-item>
  </ng-container>
</kendo-gridlayout>