import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";
import { IApiContactRoleFilterType } from '../../../types/types';

const contactsFragment = `
  Contacts {
    id
    firstName
    lastName
  }
`;

const templateFragment = `
  id
  name
  isPrivate
  ContactType {
    id
    name
  }
  createdAt
  updatedAt
  {{ CONTACTS }}
`; 

const contactsRegex = /\{\{\s?CONTACTS\s?\}\}/g;

const nodeFragment = templateFragment
  .replace(contactsRegex, contactsFragment);

const listViewFragment = templateFragment
  .replace(contactsRegex, "");

export const contactRolesConnectionQuery = (view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiContactRoleFilterType.ViewListView:
        return gql`${buildConnectionQuery("ContactRole", "contactRoleConnection", listViewFragment)}`;
        
      default:
        return gql`${buildConnectionQuery("ContactRole", "contactRoleConnection", nodeFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("ContactRole", "contactRoleConnection", nodeFragment)}`;
  }
};

export const addContactRoleMutation = gql`mutation createContactRole($input: AddContactRoleInput!) {
  addContactRole(input: $input) { ${nodeFragment} }
  }`;

export const removeContactRoleMutation = gql`mutation removeContactRole($input: RemoveContactRoleInput!) {
  removeContactRole(input: $input) { ${nodeFragment} }
}`;

export const updateContactRoleMutation = gql`mutation updateContactRole($input: UpdateContactRoleInput!) {
  updateContactRole(input: $input) { ${nodeFragment} }
}`;

export const getContactRoleByIdQuery = gql`query getContactRoleById($id: ID!) {
  getContactRoleById(id: $id) {${nodeFragment}}
}`;
