import { GqlEvidenceStorageLocationService } from 'src/app/shared/modules/graphql/services/evidence/evidence-storage-location/evidence-storage-location.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import {
  IApiAddEvidenceStorageLocationInput,
  IApiEvidenceStorageLocationFilter,
  IApiUpdateEvidenceStorageLocationInput,
  IApiEvidenceStorageLocationConnection
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class EvidenceStorageLocationService {

  constructor(
    private gqlEvidenceStorageLocationService: GqlEvidenceStorageLocationService
  ) { }

  public get(filters?: IApiEvidenceStorageLocationFilter[], options?: IGqlBaseOptions): Observable<IApiEvidenceStorageLocationConnection> {
    return this.gqlEvidenceStorageLocationService.getEvidenceStorageLocations(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddEvidenceStorageLocationInput) {
    return this.gqlEvidenceStorageLocationService.addEvidenceStorageLocation(input);
  }

  public toggleActivation(id: string) {
    return this.gqlEvidenceStorageLocationService.toggleEvidenceStorageLocationActivation(id);
  }

  public remove(id: string) {
    return this.gqlEvidenceStorageLocationService.removeEvidenceStorageLocation(id);
  }

  public update(input: IApiUpdateEvidenceStorageLocationInput) {
    return this.gqlEvidenceStorageLocationService.updateEvidenceStorageLocation(input);
  }

}
