import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";
import { IApiDocumentFilterType } from '../../types/types';

const userFragment = `
  id
  firstName
  lastName
  email
`;

const investigationStaffFragment = `
  InvestigationStaff {
    id
    isPrimary
    isDefault
    clientRequested
    createdAt
    User {
      id
      firstName
      lastName
    }
    Role {
      id
      title
    }
  }
`;

const historyFragment = `
  History {
    id
    comment
    uri
    s3Uri
    status
    createdAt
    ApprovalHistory {
      id
      isApproved
    }
    CreatedBy { ${userFragment} }
  }
`;

const commentsFragment = `
  Comments {
    id
    text
    CreatedBy { ${userFragment} }
    UpdatedBy { ${userFragment} }
    createdAt
    updatedAt
  }
`;

const statusFragment = `
  Status {
    id
    status
    createdAt
  }
`;

const riskTypeFragment = `
  RiskType {
    id
    name
  }
`;

const emailsFragment = `
  Emails {
    id
    address
    isPrimary
    Type {
      id
      name
    }
  }
`;

const miscFragment = `
  UpdatedBy { ${userFragment} }
  DocumentCollection { id }
  Shares { id }
`;

const templateFragment = `
  id
  title
  caption
  uri
  s3Uri
  Type {
    id
    name
    Category {
      id
      name
    }
  }
  CreatedBy { ${userFragment} }
  Editor { ${userFragment} }
  Investigation {
    {{ INVESTIGATION_STAFF }}
    id
    nefcoNumber
    Priority {
      id
      name
    }
    {{ RISK_TYPE }}
    Client {
      id
      firstName
      lastName
      {{ EMAILS }}
    }
    ClientBranch {
      id
      name
      Company {
        id
        name
      }
    }
    LossAddress {
      id
      address1
      address2
      address3
      city
      state
      postal
      country
    }
  }
  {{ STATUS }}
  {{ HISTORY }}
  {{ COMMENTS }}
  {{ MISC }}
  createdAt
  updatedAt
`;

const investigationStaffRegex = /\{\{\s?INVESTIGATION_STAFF\s?\}\}/g;
const historyRegex = /\{\{\s?HISTORY\s?\}\}/g;
const commentsRegex = /\{\{\s?COMMENTS\s?\}\}/g;
const statusRegex = /\{\{\s?STATUS\s?\}\}/g;
const riskTypeRegex = /\{\{\s?RISK_TYPE\s?\}\}/g;
const emailsRegex = /\{\{\s?EMAILS\s?\}\}/g;
const miscRegex = /\{\{\s?MISC\s?\}\}/g;

const nodeFragment = templateFragment
  .replace(investigationStaffRegex, "")
  .replace(historyRegex, historyFragment)
  .replace(commentsRegex, commentsFragment)
  .replace(statusRegex, statusFragment)
  .replace(riskTypeRegex, riskTypeFragment)
  .replace(emailsRegex, emailsFragment)
  .replace(miscRegex, miscFragment);

const listReadyForEditorFragment = templateFragment
  .replace(investigationStaffRegex, investigationStaffFragment)
  .replace(historyRegex, "")
  .replace(commentsRegex, "")
  .replace(statusRegex, statusFragment)
  .replace(riskTypeRegex, "")
  .replace(emailsRegex, "")
  .replace(miscRegex, "");

const listTechReviewFragment = templateFragment
  .replace(investigationStaffRegex, investigationStaffFragment)
  .replace(historyRegex, "")
  .replace(commentsRegex, "")
  .replace(statusRegex, statusFragment)
  .replace(riskTypeRegex, "")
  .replace(emailsRegex, "")
  .replace(miscRegex, "");

const listEditorReview = templateFragment
  .replace(investigationStaffRegex, investigationStaffFragment)
  .replace(historyRegex, "")
  .replace(commentsRegex, "")
  .replace(statusRegex, statusFragment)
  .replace(riskTypeRegex, "")
  .replace(emailsRegex, "")
  .replace(miscRegex, "");

export const documentConnectionQuery = (view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiDocumentFilterType.ViewReadyforeditor:
        return gql`${buildConnectionQuery("Document", "documentConnection", listReadyForEditorFragment)}`;

      case IApiDocumentFilterType.ViewEditorReview:
        return gql`${buildConnectionQuery("Document", "documentConnection", listEditorReview)}`;

      case IApiDocumentFilterType.ViewTechReview:
        return gql`${buildConnectionQuery("Document", "documentConnection", listTechReviewFragment)}`;

      default:
        return gql`${buildConnectionQuery("Document", "documentConnection", nodeFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("Document", "documentConnection", nodeFragment)}`;
  }
};

export const addDocumentMutation = gql`mutation AddDocument($input: AddDocumentInput!) {
  addDocument(input: $input) {
    ${nodeFragment}
  }
}`;

export const sentDocumentMutation = gql`mutation AddDocumentSent($input: AddDocumentSentInput!) {
  addDocumentSent(input: $input)
}`;

export const updateDocumentMutation = gql`mutation UpdateDocument($input: UpdateDocumentInput!) {
  updateDocument(input: $input) {
    ${nodeFragment}
  }
}`;

export const removeDocumentMutation = gql`mutation RemoveDocument($id: ID!) {
  removeDocument(id: $id)
}`;
