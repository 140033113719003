<section class="page-section">
  <kendo-card class="pt-48 pb-48 ml-16 mr-16" [width]="''">
    <kendo-card-body class="text-center no-results-found">
      <h6 class="mb-16">Feature unavailable</h6>
      <p class="warning">This feature is not available for you. If you have any questions, please contact a NEFCO admin.
      </p>
      <button style="width: 100%;" kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="back()" type="button">Go
        Back</button>
    </kendo-card-body>
  </kendo-card>
</section>
