<section>
  <div class="page-header"><h1 class="mb-0">Expense Reports</h1></div>
</section>

<kendo-tabstrip  #tabstrip [scrollable]="false" style="background-color: #ffffff" class="time-expense-tabs" (tabSelect)="onTabChanged($event)">
    <kendo-tabstrip-tab [selected]="selectedTab === 0">
        <ng-template kendoTabTitle><span>Out of Pocket</span></ng-template>
        <ng-template kendoTabContent>
            <app-bookkeeping-expense-reports  *ngIf="selectedTab === 0" [(selectedTab)]="selectedTab"></app-bookkeeping-expense-reports>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab>
        <ng-template kendoTabTitle>
            <span>NEFCO Card</span>
          </ng-template>
        <ng-template kendoTabContent>
            <app-bookkeeping-expense-reports *ngIf="selectedTab === 1" [(selectedTab)]="selectedTab"></app-bookkeeping-expense-reports>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab>
        <ng-template kendoTabTitle>
            <span>Vendor/Other</span>
          </ng-template>
        <ng-template kendoTabContent>
            <app-bookkeeping-expense-reports *ngIf="selectedTab === 2" [(selectedTab)]="selectedTab"></app-bookkeeping-expense-reports>
        </ng-template>
    </kendo-tabstrip-tab>
</kendo-tabstrip>