<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffViewAll" #adminPerm="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageCertificationsUpdate" #manageCertificationsUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageCertificationsCreate" #manageCertificationsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageCertificationsDelete" #manageCertificationsDelete="appEnforcePermission"></div>
<!-- Permissions -->
<section class="content-wrapper">
  <kendo-gridlayout *ngIf="manageCertificationsCreate?.enabled" [rows]="['auto']"
  [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
  <kendo-gridlayout-item class="text-right mb-0">
    <kendo-button class="mr-16" kendoButton themeColor="primary" iconClass="fa fa-plus" fillMode="solid" size="medium"
      (click)="addEdit(null)">
      New Certification Type
    </kendo-button>
  </kendo-gridlayout-item>
</kendo-gridlayout>
  <app-certifications-filter
    #certificationFilter
    (filterChange)="filtersChange($event)"
    [certView]="false"
  ></app-certifications-filter>

  <kendo-grid
    class="certifications-investigators-list mt-16"
    [data]="{
      data: data,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageOptions?.paginate($event);"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
  >
    <kendo-grid-column
      field="USER"
      title="Name"
      [width]="15"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          *ngIf="adminPerm.enabled else nonadmin"
          [routerLink]="['/staff/', dataItem.User?.id]"
          tel=""
          class="text-decoration"
        >
          {{ dataItem?.User | fullName: true }}
        </a>
        <ng-template #nonadmin>
          <span>{{ dataItem?.User | fullName: true }}</span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="STATE"
      title="State"
      [width]="10"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHTML]="getStateName(dataItem?.Type?.state)"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="PHONE"
      title="Phone"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-email-phone-view [phone]="dataItem?.User?.Phones | primaryAssociation"></app-email-phone-view>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="COMMENT"
      title="Latest Comment"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="text-overflow" [innerHTML]="getLatestComment(dataItem?.Comments)"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="certification"
      title="Certification"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>
        {{ dataItem.Type?.title }}
          <span class="font-weight-bold" *ngIf="dataItem.Type?.isRestriction"
            >(restriction)</span
          >
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="EXPIRES_AT"
      title="Expiration"
      [width]="10"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="dflex">
          <i *ngIf="dataItem | showCertificationError" class="fa-solid fa-circle-exclamation" style="color:#f31700;"></i>
          <i *ngIf="dataItem | showCertificationError : 6 : 0" class="fa-solid fa-triangle-exclamation" style="color:#ffc000;"></i>
          <span class="ml-8">{{ dataItem.expiresAt | customDate : "MM-dd-yyyy" }}</span>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="STATUS"
      title="Status"
      [width]="10"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ (dataItem.status | quiet) || "N/A" }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Actions"
      [width]="8"
      [sortable]="false"
      [headerClass]="'nocursor'"
      *ngIf="actionItem?.length"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-dropdownbutton
          [data]="actionItem"
          themeColor="primary" fillMode="link" buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis"
          (itemClick)="itemSelected($event, dataItem)"
          [popupSettings]="{
            appendTo: 'component',
            animate: false,
            width: 250,
            popupClass: 'grid-action-items-menu'
          }">
        </kendo-dropdownbutton>
      </ng-template>
    </kendo-grid-column>

    <!-- No record -->
    <ng-template kendoGridNoRecordsTemplate>
      <p class="mt-24 mb-24">There are no results to display.</p>
    </ng-template>
  </kendo-grid>
</section>
