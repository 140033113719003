import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { InvestigationOnSceneStatus } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import { IApiChangeOnSceneStatusInput, IApiInvestigation, IApiInvestigationOnScene } from 'src/app/shared/modules/graphql/types/types';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { InvestigationOnSceneService } from 'src/app/shared/services';

@Component({
  selector: 'app-mobile-change-on-scene-status',
  templateUrl: './mobile-change-on-scene-status.component.html',
  styleUrls: ['./mobile-change-on-scene-status.component.scss']
})
export class MobileChangeOnSceneStatusComponent extends DialogContentBase {

  @ViewChild('filterForm', { static: false }) filterForm: NgForm;
  @Input() onScene: IApiInvestigationOnScene | null = null;
  @Input() investigation: IApiInvestigation = null;
  public showDialogStatusChange: boolean = false;
  public selectedStatus: string = '';

  public selected: string[] = [];
  public investigationOnSceneStatusAll: { text: InvestigationOnSceneStatus, value: InvestigationOnSceneStatus | null }[] = [
    { text: InvestigationOnSceneStatus.EN_ROUTE, value: InvestigationOnSceneStatus.EN_ROUTE },
    { text: InvestigationOnSceneStatus.ON_SCENE, value: InvestigationOnSceneStatus.ON_SCENE },
    { text: InvestigationOnSceneStatus.OFF_SCENE, value: InvestigationOnSceneStatus.OFF_SCENE },
    { text: InvestigationOnSceneStatus.SCHEDULED, value: InvestigationOnSceneStatus.SCHEDULED }
  ];
  public onSceneList: IApiInvestigationOnScene[] = [];
  get saveStatusData(): IApiChangeOnSceneStatusInput {
    let data = {
      data: [
        {
          InvestigationId: this.investigation.id,
          UserId: this.onScene.UserId,
          scheduledDate: this.onScene.scheduledDate
        },
      ],
      status: this.selectedStatus,
    };
    return data;
  };


  constructor(
    private investigationOnSceneService: InvestigationOnSceneService,
    private notifications: NotificationsService,
    public dialog: DialogRef
  ) {
    super(dialog)
   }

  public saveStatus(): void {
    this.filterForm.form.markAllAsTouched();
    if (this.filterForm?.form?.invalid) {
      return;
    }
    this.investigationOnSceneService.changeOnSceneStatus(this.saveStatusData).pipe(
      this.notifications.snackbarPipe(`Scene status has been updated on ID: ${this.investigation.nefcoNumber}.`),
      this.notifications.snackbarErrorPipe("Error updating status")
    ).subscribe(() => {
      this.close(true);
    });
  }

  public close(result = false){
    this.dialog.close(result);
  }

}
