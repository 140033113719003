import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';

dayjs.extend(timezone);
dayjs.extend(utc);

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements OnInit{

  initialVal = true;
  convertToLocal = true;

  constructor() { }

  private _value: Date;

  @Input() set value(val) {

    // Only convert if initialVal OR lossDate is enabled
    if (this.initialVal || ( this.convertToLocal && this.isLossDate )) {
      this._value = NefcoDateHelper.fromEstToLocal(val);
      this.initialVal = false;
    }

    else {
      this._value = val;
    }

    // Reset convertToLocal so we can control from this.onLossDateChange(), lossDate only
    this.convertToLocal = true;

    // Change detection
    this.onChange(this._value);
    this.onTouch(this._value);
  }

  get value() {
    return this._value;
  }

  @Input() disabled = false;
  @Input() required = false;
  @Input() startView = 'month';
  @Input() label = 'Date';
  @Input() isLossDate = false;

  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  private onChange: any = (): void => {};
  private onTouch: any = (): void => {};

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  ngOnInit(){
    this.initialVal = false;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public onDateChange() {
    this.changed.emit(true);
  }

  public onLossDateChange({value}) {
    // Set convertToLocal = false value here, when a user manually picks a date because date is UTC
    // Set value here instead of binding to [(ngModel)], use [ngModel] instead
    // That way, it will see convertToLocal = false and not convert
    this.convertToLocal = false;
    this.value = value;
    this.changed.emit(true);
  }
}
