import { DataSource } from "@angular/cdk/collections";
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { IApiDocumentSentFilter, IApiReportSent, IApiReportSentConnection } from "src/app/shared/modules/graphql/types/types";
import { ReportSentMetricService } from "./report-sent-metric.service";

export class ReportSentMetricDataSource extends NefcoPagedDataSource<IApiReportSent, ReportSentMetricService, IApiReportSentConnection> implements DataSource<IApiReportSent> {
  @ApiConnection()
  public load(filters: IApiDocumentSentFilter[] = []): Promise<IApiReportSentConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
