export const environment = {
  production: true,
  http_debug: false,
  graphql: {
    endpoint: "https://extranet3-api.nefco.net/graphql",
    socket_endpoint: "wss://extranet3-api.nefco.net/subscriptions",
    token: "8b326dfdg714sbff03da723f34h57567821"
  },
  google_maps: {
    api_key: "AIzaSyCrgs9yCnvmMJR5Bj-FOKbkkVoCq_YHR14"
  },
  auth0: {
    domain: "atom-group.auth0.com",
    clientId: "IMH6TvDuqUUJUSvWHe6IZBvT578bB2ju"
  },
  notifyTimeoutMs: 5000,
  portal: {
    base_url: "https://extranet3-api.nefco.net",
    contactEmail: "noreply@nefco.net"
  },
  AirBreakProjectId: 483244,
  AirBreakProjectAPIKey: 'e16f75fc9c4a6e0fb8dc9642adfa31cc',
  env: 'PROD',
  cloudFrontS3: "https://d13176vfi277y.cloudfront.net", /* DISPLAY PHOTO */
  resizeApiURL: 'https://mq5vdb2v62.execute-api.us-east-1.amazonaws.com', /* RESIZE PHOTO */
};
