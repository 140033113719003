import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
    id
    markClosed
    Investigation {
      id
    }
    User {
      id
    }
  `;

export const addInvestigationMarkCloseMutation = gql`mutation AddInvestigationMarkClose($input: AddInvestigationMarkCloseInput!) {
  addInvestigationMarkClose(input: $input) { ${nodeFragment} }
}`;

