import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { AuthService } from '../../authorization';
import { IApiAssignPermissionRolePermissionInput, IApiAssignPermissionRoleUserInput } from './../../../modules/graphql/types/types';
import { IApiAddPermissionRoleInput, IApiPermissionRole, IApiUpdatePermissionRoleInput, IApiPermissionRoleFilter, IApiPermissionRoleConnection, IApiRemovePermissionRoleUserInput } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlPermissionRoleService } from "src/app/shared/modules/graphql/services";

@Injectable()
export class PermissionRoleService {

  constructor(
    private PermissionRoles: GqlPermissionRoleService,
    private auth: AuthService
  ) { }

  public get(filters: IApiPermissionRoleFilter[] = [], options?: IGqlBaseOptions): Observable<IApiPermissionRoleConnection> {
    return this.PermissionRoles.getPermissionRoles(filters, options || new ListPage(-1));
  }

  public getById(id: string): Observable<IApiPermissionRole> {
    return this.PermissionRoles.getPermissionRoleById(id);
  }

  public add(input: IApiAddPermissionRoleInput): Observable<IApiPermissionRole> {
    return this.PermissionRoles.addPermissionRole(input);
  }

  public update(input: IApiUpdatePermissionRoleInput): Observable<IApiPermissionRole> {
    return this.PermissionRoles.updatePermissionRole(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.PermissionRoles.removePermissionRole(id);
  }

  public assignPermission(input: IApiAssignPermissionRolePermissionInput): Observable<boolean> {
    return this.PermissionRoles.assignPermission(input).pipe(
      this.auth.loadPipe()
    );
  }

  public assignUser(input: IApiAssignPermissionRoleUserInput): Observable<boolean> {
    return this.PermissionRoles.assignUser(input).pipe(
      this.auth.loadPipe()
    );
  }

  public removePermission(id: string): Observable<boolean> {
    return this.PermissionRoles.removePermission(id).pipe(
      this.auth.loadPipe()
    );
  }

  public removeUser(input: IApiRemovePermissionRoleUserInput): Observable<boolean> {
    return this.PermissionRoles.removeUser(input).pipe(
      this.auth.loadPipe()
    );
  }
}
