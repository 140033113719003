import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { StateService } from '../../services';
import { IState } from '../../interfaces/state.interfaces';
import _ from 'lodash';

@Component({
  selector: 'app-state-multi-select-kendo',
  templateUrl: './state-multi-select-kendo.component.html',
  styleUrls: ['./state-multi-select-kendo.component.scss']
})
export class StateMultiSelectKendoComponent implements OnInit, OnChanges {

  @Input() placeholder = "All States";
  @Input() label = "";
  @Input() disabled = false;
  @Input() selectedId: Array<string> = [];

  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public selectedValue = [];
  public stateList: Array<IState>;
  constructor(
    private stateService: StateService,
  ) { 
    this.stateList = this.stateService.allStates;
  }

  ngOnInit(): void {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!_.isEqualWith(this.selectedValue, changes?.selectedId?.currentValue)) {
      this.selectedId = changes?.selectedId?.currentValue || [];
        if (this.selectedId?.length) {
          this.selectedValue = this.selectedId
        } else {
          this.selectedValue = [];
        }
    }
  }

  public searchSelect($event) {
    this.selectedId = $event;
    this.selectionChanged.emit(this.selectedId);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

}
