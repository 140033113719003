<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationAssignStaffCreate"
  #investigationAssignStaffCreate="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-card width="100%">
  <kendo-card-body class="card-detail">
    <div class="section-header">
      <h6> Investigators</h6>
      <button kendoButton class="ml-16" iconClass="fa-solid fa-location-dot" themeColor="light" fillMode="solid"
        *ngIf="investigationAssignStaffCreate?.enabled" [disabled]="!roles"
        (click)="findStaff(roleNames.Investigator)">Find an Investigator</button>
    </div>
    <section>
      <app-investigation-assignments-table [title]="'INVESTIGATOR'" [columnsToDisplay]="investigatorColumns" [data]="assignedInvestigators"
        [investigationId]="investigation.id" (updated)="this.updated.emit()"
        (deleted)="getStaff()"></app-investigation-assignments-table>
    </section>
  </kendo-card-body>
</kendo-card>

<kendo-card width="100%" class="mt-12">
  <kendo-card-body class="card-detail">
    <div class="section-header">
      <h6> Tech Reviewers</h6>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <app-user-select-kendo [userViewFilter]="userViewFilter" #tselect class="d-inline-block ml-2"
            style="width: 250px;" placeholder="Search ..."
            (selectionChanged)="addStaff($event) && tselect.selectUser(null)"
            investigationRole="TECH_REVIEWER"></app-user-select-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </div>
    <section>
      <app-investigation-assignments-table [title]="'TECH_REVIEWER'" [columnsToDisplay]="techReviewerColumns" [data]="assignedTechReviewers"
        [investigationId]="investigation.id" (updated)="this.updated.emit()"
        (deleted)="getStaff()"></app-investigation-assignments-table>
    </section>
  </kendo-card-body>
</kendo-card>

<kendo-card width="100%" class="mt-12">
  <kendo-card-body class="card-detail">
    <div class="section-header" *ngIf="investigationAssignStaffCreate?.enabled">
      <h6>Experts/Other</h6>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item class="section-header mb-0">
          <app-role-select class="d-inline-block ml-2" #otherSelect [config]="roleSelectConfig"></app-role-select>
          <button kendoButton class="ml-16" iconClass="fa-solid fa-location-dot" themeColor="light" fillMode="solid"
            [disabled]="!otherSelect.roleId || !roles" (click)="findStaff(otherSelect.selectedRole.title)">Find Staff</button>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </div>
    <section>
      <app-investigation-assignments-table [title]="'OTHER'" [columnsToDisplay]="otherStaffColumns" [data]="assignedOther"
        [investigationId]="investigation.id" (deleted)="getStaff()"></app-investigation-assignments-table>
    </section>
  </kendo-card-body>
</kendo-card>
