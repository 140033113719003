<!-- Permissions -->
<div [appEnforcePermission]="authConfig.dashboardPendingRequests" #dashboardPendingRequests="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardInvestigationsPending" #dashboardInvestigationsPending="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardInvestigatorsOnScene" #dashboardInvestigatorsOnScene="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardInvestigationsAssigned" #dashboardInvestigationsAssigned="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardReportsReadyForEditor" #dashboardReportsReadyForEditor="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardReportsInEditorReview" #dashboardReportsInEditorReview="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardReportsInTechReview" #dashboardReportsInTechReview="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardInvestigationsInProgress" #dashboardInvestigationsInProgress="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardInvestigationsReadyToBill" #dashboardInvestigationsReadyToBill="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardInvestigationsOngoing" #dashboardInvestigationsOngoing="appEnforcePermission"></div>
<!-- Permissions -->
<section>
    <h4 class="page-title">Dashboard</h4>
    <div class="content-wrapper p-0">
        <kendo-tabstrip #tabstrip [scrollable]="{ buttonScrollSpeed: 500 }" class="dashboard-tabs" (tabSelect)="changeTab($event)">
            <kendo-tabstrip-tab [title]="TABS.PENDING_REQUESTS" [selected]="selectedTab === TABS.PENDING_REQUESTS" *ngIf="dashboardPendingRequests.enabled">
                <!-- ADMIN ONLY -->
                    <ng-template kendoTabTitle>
                        <span>Pending Requests</span>
                        <kendo-badge
                            *ngIf="pendingRequestsTotal >= 0"
                            class="fw600 ml-8"
                            position="none"
                            size="large"
                            rounded="full"
                            themeColor="primary"
                        >
                            {{pendingRequestsTotal}}
                        </kendo-badge>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div>
                            <!-- Pending Requests -->
                            <app-dashboard-requests-table #pendingRequests (total)="pendingRequestsTotal = $event"></app-dashboard-requests-table>
                        </div>
                    </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab [title]="TABS.PENDING_INVESTIGATION" [selected]="selectedTab === TABS.PENDING_INVESTIGATION"  *ngIf="dashboardInvestigationsPending.enabled">
                    <!-- ADMIN ONLY -->
                    <ng-template kendoTabTitle>
                        <span>Pending Investigations</span>
                        <kendo-badge
                            *ngIf="investigationsPendingTotal >= 0"
                            class="fw600 ml-8"
                            position="none"
                            size="large"
                            rounded="full"
                            themeColor="primary"
                        >
                            {{investigationsPendingTotal}}
                        </kendo-badge>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div>
                            <!-- Pending Investigations -->
                            <app-investigations-table-new #pendingTable [config]="pendingConfig" (total)="investigationsPendingTotal = $event"></app-investigations-table-new>
                        </div>
                    </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [title]="TABS.ON_SCENE" [selected]="selectedTab === TABS.ON_SCENE" *ngIf="dashboardInvestigatorsOnScene?.enabled">
                    <!-- ADMIN ONLY -->
                    <ng-template kendoTabTitle>
                        <span>On Scene</span>
                        <kendo-badge
                            *ngIf="investigatorsOnSiteTotal >= 0"
                            class="fw600 ml-8"
                            position="none"
                            size="large"
                            rounded="full"
                            themeColor="primary"
                        >
                            {{investigatorsOnSiteTotal}}
                        </kendo-badge>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div>
                            <!-- Investigators On Scene -->
                            <app-dashboard-investigators-table #onSceneTable (total)="investigatorsOnSiteTotal = $event"></app-dashboard-investigators-table>
                        </div>
                    </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [title]="TABS.ASSIGNED" [selected]="selectedTab === TABS.ASSIGNED" *ngIf="dashboardInvestigationsAssigned?.enabled">
                    <!-- ADMIN ONLY -->
                    <ng-template kendoTabTitle>
                        <span>Assigned</span>
                        <kendo-badge
                            *ngIf="assignedInvestigationsTotal >= 0"
                            class="fw600 ml-8"
                            position="none"
                            size="large"
                            rounded="full"
                            themeColor="primary"
                        >
                            {{assignedInvestigationsTotal}}
                        </kendo-badge>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div>
                            <!-- <app-investigations-table #assignedTable [config]="assignedConfig" (total)="assignedInvestigationsTotal = $event"></app-investigations-table> -->
                            <app-dashboard-assigned-table #assignedTable [config]="assignedConfig" (total)="assignedInvestigationsTotal = $event"></app-dashboard-assigned-table>
                        </div>
                    </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [title]="TABS.FOR_EDITOR" [selected]="selectedTab === TABS.FOR_EDITOR" *ngIf="dashboardReportsReadyForEditor?.enabled">
                    <!-- Editor Only -->
                    <ng-template kendoTabTitle>
                        <span>For Editor</span>
                        <kendo-badge
                            *ngIf="editorReportsTotal >= 0"
                            class="fw600 ml-8"
                            position="none"
                            size="large"
                            rounded="full"
                            themeColor="primary"
                        >
                            {{editorReportsTotal}}
                        </kendo-badge>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div>
                            <!-- Reports Ready For Editor-->
                            <app-dashboard-reports-table (total)="editorReportsTotal = $event" [config]="readyForEditorConfig" #editorReportsTable></app-dashboard-reports-table>
                            <!-- <app-investigations-table #editorReportsTable (total)="editorReportsTotal = $event" [config]="readyForEditorConfig"></app-investigations-table> -->
                        </div>
                    </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [title]="TABS.EDITOR_REVIEW" [selected]="selectedTab === TABS.EDITOR_REVIEW" *ngIf="dashboardReportsInEditorReview?.enabled">
                    <!-- Editor Only -->
                    <ng-template kendoTabTitle>
                        <span>Editor Review</span>
                        <kendo-badge
                            *ngIf="editorReviewTotal >= 0"
                            class="fw600 ml-8"
                            position="none"
                            size="large"
                            rounded="full"
                            themeColor="primary"
                        >
                            {{editorReviewTotal}}
                        </kendo-badge>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div>
                            <!-- Reports In Editor Review -->
                            <app-dashboard-reports-table #editorReviewTable (total)="editorReviewTotal = $event" [config]="editorReviewConfigReports"></app-dashboard-reports-table>
                            <!-- <app-investigations-table #editorReviewTable (total)="editorReviewTotal = $event" [config]="editorReviewConfigReports"></app-investigations-table> -->
                        </div>
                    </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [title]="TABS.TECH_REVIEW" [selected]="selectedTab === TABS.TECH_REVIEW" *ngIf="dashboardReportsInTechReview?.enabled">
                    <!-- Editor Only -->
                    <ng-template kendoTabTitle>
                        <span>Tech Review</span>
                        <kendo-badge
                            *ngIf="techReviewTotal >= 0"
                            class="fw600 ml-8"
                            position="none"
                            size="large"
                            rounded="full"
                            themeColor="primary"
                        >
                            {{techReviewTotal}}
                        </kendo-badge>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div>
                            <!-- Reports In Editor Review -->
                            <app-dashboard-reports-table #techReviewTable (total)="techReviewTotal = $event" [config]="techConfigReports"></app-dashboard-reports-table>
                            <!-- <app-investigations-table #techReviewTable (total)="techReviewTotal = $event" [config]="techConfigReports"></app-investigations-table> -->
                        </div>
                    </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [title]="TABS.IN_PROGRESS" [selected]="selectedTab === TABS.IN_PROGRESS" *ngIf="dashboardInvestigationsInProgress?.enabled">
                    <ng-template kendoTabTitle>
                        <span>In Progress</span>
                        <kendo-badge
                            *ngIf="investgationsInProgressTotal >= 0"
                            class="fw600 ml-8"
                            position="none"
                            size="large"
                            rounded="full"
                            themeColor="primary"
                        >
                            {{investgationsInProgressTotal}}
                        </kendo-badge>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div>
                            <!-- Investigations In progress -->
                            <app-investigations-table-new #progressTable [config]="progressConfig" (total)="investgationsInProgressTotal = $event"></app-investigations-table-new>
                        </div>
                    </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [title]="TABS.TO_BILL" [selected]="selectedTab === TABS.TO_BILL" *ngIf="dashboardInvestigationsReadyToBill?.enabled">
                    <!-- Editor And Admin -->
                    <ng-template kendoTabTitle>
                        <span>To Bill</span>
                        <kendo-badge
                            *ngIf="investigationsReadyToBillTotal >= 0"
                            class="fw600 ml-8"
                            position="none"
                            size="large"
                            rounded="full"
                            themeColor="primary"
                        >
                            {{investigationsReadyToBillTotal}}
                        </kendo-badge>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div>
                            <!-- Investigations Ready To Bill -->
                            <app-investigations-table-new #readyToBillTable [config]="readyToBillConfig" (total)="investigationsReadyToBillTotal = $event"></app-investigations-table-new>
                        </div>
                    </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [title]="TABS.ON_GOING" [selected]="selectedTab === TABS.ON_GOING" *ngIf="dashboardInvestigationsOngoing?.enabled">
                    <ng-template kendoTabTitle>
                        <span>Ongoing</span>
                        <kendo-badge
                            *ngIf="investigationsOngoingTotal >= 0"
                            class="fw600 ml-8"
                            position="none"
                            size="large"
                            rounded="full"
                            themeColor="primary"
                        >
                            {{investigationsOngoingTotal}}
                        </kendo-badge>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <div>
                            <!-- Investigations Ongoing -->
                            <app-investigations-table-new #ongoingTable [config]="ongoingConfig" (total)="investigationsOngoingTotal = $event"></app-investigations-table-new>
                        </div>
                    </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>
</section>