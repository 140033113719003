import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { safeResolve } from "../../rxjs";
import { IApiAddPhoneInput, IApiPhone, IApiRemovePhoneInput } from '../../types/types';
import { addPhoneMutation, removePhoneMutation } from './phone.operation';

@Injectable()
export class GqlPhoneService {
  public constructor(
    private apollo: Apollo
  ) { }

  public addPhone(input: IApiAddPhoneInput): Observable<IApiPhone> {
    return this.apollo.mutate({
      mutation: addPhoneMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removePhone(input: IApiRemovePhoneInput) {
    return this.apollo.mutate({
      mutation: removePhoneMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

}