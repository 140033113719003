import { connectionQuery } from '../../../helpers.class';
import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { investigationStaffConnectionQuery, addInvestigationStaffMutation, removeInvestigationStaffMutation, assignInvestigatorMutation, updateInvestigationStaffMutation } from './../investigation-staff/investigation-staff.operations';
import { IApiAssignInvestigatorInput, IApiInvestigationSource, IApiInvestigationStaffConnection } from '../../../types/types';

import {
  IApiInvestigationStaffFilter,
  IApiAddInvestigationStaffInput,
  IApiUpdateInvestigationStaffInput
} from '../../../types/types';
import { safeResolve } from '../../../rxjs';

@Injectable()
export class GqlInvestigationSourceService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getInvestigationSource(): Observable<IApiInvestigationSource[]> {
    const q = gql`query getInvestigationSource {
      getInvestigationSource {
        id
        name
      }
    }`;
    return this.apollo.query({ query: q }).pipe(
      safeResolve(true)
    );
  }
}
