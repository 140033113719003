import { Component } from '@angular/core';
import { IInvestigationTableConfig, TableType, Columns } from "../../investigations-table-view/investigations-table/investigations-table.component";

@Component({
  selector: 'app-investigation-list',
  templateUrl: './investigation-list.component.html',
  styleUrls: ['./investigation-list.component.scss']
})
export class InvestigationListComponent {

  public tableConfig: IInvestigationTableConfig = {
    type: TableType.investigations,
    displayedColumns: [Columns.nefcoNumber, Columns.priority, Columns.lossDate, Columns.riskType, Columns.lossAddress, Columns.contact, Columns.client, Columns.assigned, Columns.status, Columns.comments, Columns.actions],
    viewType: null,
    startWithCount: false
  };

  constructor() { }

}
