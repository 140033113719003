import { Component, OnInit } from '@angular/core';
import { InvestigationTemplatDownloadService } from 'src/app/shared/services';

@Component({
  selector: 'app-document-templating',
  templateUrl: './document-templating.component.html',
  styleUrls: ['./document-templating.component.scss']
})
export class DocumentTemplatingComponent implements OnInit {

  constructor(
    private templateService: InvestigationTemplatDownloadService
  ) { }

  public investigation: any;

  ngOnInit(): void {
    this.templateService.templateData().subscribe((d) => {
      this.investigation = JSON.stringify(JSON.parse(d), null, 2);
    });
  }

}
