<kendo-card class="intake-card" width="100%">
  <h1>Password</h1>
  <p>Set a password so that we can store your contact information to create new assignments faster in the future.</p>
  <ul class="password-strength">
    <li>At least 8 characters</li>
    <li>At least 1 capital letter</li>
    <li>At least 1 lowercase letter</li>
    <li>At least 1 special character</li>
  </ul>
  <form [formGroup]="createPassword" autocomplete="off">
    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item class="mb-24">
        <kendo-formfield>
          <kendo-label class="asterisk" [for]="newPassword" text="New Password"></kendo-label>
          <div class="icon-input">
            <kendo-textbox
              #newPassword
              formControlName="newPassword"
              fillMode="outline"
              [clearButton]="false"
              placeholder="Enter password"
              class="password-field"
            ></kendo-textbox>
            <img *ngIf="isPasswordVisible" (click)="hideShowPassword('password')" class="eye-img cursor" src="/assets/svg/eye.svg" alt="eye" />
            <img *ngIf="!isPasswordVisible" (click)="hideShowPassword('password')" class="eye-img cursor" src="/assets/svg/hide-eye.svg" alt="hide-eye" />
          </div>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="createPassword?.controls?.newPassword?.errors?.required">Password can not be left blank</span>
            <span *ngIf="createPassword?.controls?.newPassword?.errors?.patternNotMatch">Password pattern does not match</span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label class="asterisk" [for]="confirmPassword" text="Confirm Password"></kendo-label>
          <div class="icon-input">
            <kendo-textbox
              #confirmPassword
              formControlName="confirmPassword"
              fillMode="outline"
              [clearButton]="false"
              placeholder="Enter password"
            ></kendo-textbox>
            <img *ngIf="isConPasswordVisible" (click)="hideShowPassword('confirm')" class="eye-img cursor" src="/assets/svg/eye.svg" alt="eye" />
            <img *ngIf="!isConPasswordVisible" (click)="hideShowPassword('confirm')" class="eye-img cursor" src="/assets/svg/hide-eye.svg" alt="hide-eye" />
          </div>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="createPassword?.controls?.confirmPassword?.errors?.required">Confirm password can not be left blank</span>
            <span *ngIf="createPassword?.controls?.confirmPassword?.errors?.passwordNotMatch">Your passwords do not match</span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </form>
</kendo-card>