import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit, AfterViewInit {

  @Input() formGroupName: string;
  createPassword: FormGroup;
  @ViewChild('newPassword') public passwordTextbox: TextBoxComponent;
  @ViewChild('confirmPassword') public confirmPasswordTextbox: TextBoxComponent;
  public isPasswordVisible: boolean = false;
  public isConPasswordVisible: boolean = false;

  constructor(
    private rootFormGroup: FormGroupDirective
  ) { }

  ngOnInit(): void {
    this.createPassword = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.createPassword.addValidators(passwordVerify('newPassword'));
    this.createPassword.addValidators(passwordMatch('newPassword', 'confirmPassword'));
  }

  ngAfterViewInit() {
    this.hidePassword();
  }

  public hidePassword() {
    if (this.passwordTextbox) {
      this.passwordTextbox.input.nativeElement.type = "password";
      this.isPasswordVisible = false;
    }
    if (this.confirmPasswordTextbox) {
      this.confirmPasswordTextbox.input.nativeElement.type = "password";
      this.isConPasswordVisible = false;
    }
  }

  public hideShowPassword(type) {
    if (type === 'password') {
      if (this.passwordTextbox) {
        this.isPasswordVisible = !this.isPasswordVisible;
        this.passwordTextbox.input.nativeElement.type = this.isPasswordVisible ? "text" : "password";
      }
    } else {
      if (this.confirmPasswordTextbox) {
        this.isConPasswordVisible = !this.isConPasswordVisible;
        this.confirmPasswordTextbox.input.nativeElement.type = this.isConPasswordVisible ? "text" : "password";
      }
    }
  }

}

export const passwordVerify = (password: string) => {
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[-!$%^&*()_+|~='"\\`{}\[\]:\/;<>?,.@#])[0-9A-Za-z\d-!$%^&*()_+|~='"\\`{}\[\]:\/;<>?,.@#]{8,}$/;
  return (formGroup: FormGroup) => {
    const passwordControl = formGroup.controls[password];
    if (passwordControl.value && pattern.test(passwordControl.value)) {
      passwordControl.setErrors(null);
      return null;
    }
    if (passwordControl.value && !pattern.test(passwordControl.value)) {
      passwordControl.setErrors({ patternNotMatch: true });
    } else {
      passwordControl.setErrors({ required: true });
    }
  };
};

export const passwordMatch = (password: string, confirmPassword: string) => {
  return (formGroup: FormGroup) => {
    const passwordControl = formGroup.controls[password];
    const conPasswordControl = formGroup.controls[confirmPassword];
    if (passwordControl.value && (conPasswordControl.value === passwordControl.value)) {
      conPasswordControl.setErrors(null);
      return null;
    }
    if (conPasswordControl.value && (conPasswordControl.value !== passwordControl.value)) {
      conPasswordControl.setErrors({ passwordNotMatch: true });
    } else {
      conPasswordControl.setErrors({ required: true });
    }
  };
};
