import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const nodeFragment = `
  id
  uri
  s3Uri
  lowResUri
  width
  height
  fileSizeKB
  fileName
  caption
  isEvidence
  isUsed
  position
  createdAt
  Folder {
    id
    name
  }
  User {
    id
  }
`;

export const photoConnectionQuery = gql`${buildConnectionQuery("Photo", "photoConnection", nodeFragment)}`;

export const addPhotoMutation = gql`mutation AddPhoto($input: AddPhotoInput!) {
  addPhoto(input: $input) { ${nodeFragment} }
}`;

export const addPhotoBulkMutation = gql`mutation AddPhotoBulk($input: [AddPhotoInput]!) {
  addPhotoBulk(input: $input)
}`;

export const updatePhotoMutation = gql`mutation UpdatePhoto($input: UpdatePhotoInput!) {
  updatePhoto(input: $input) { ${nodeFragment} }
}`;

export const updatePhotoBulkMutation = gql`mutation UpdatePhotoBulk($input: [UpdatePhotoInput]!) {
  updatePhotoBulk(input: $input)
}`;

export const uploadLowResPhotoMutation = gql`mutation UploadLowResPhoto($fileInput: Upload!, $input: AddPhotoInput) {
  uploadLowResPhoto(fileInput: $fileInput, input: $input) { ${nodeFragment} }
}`;

export const positionPhotoMutation = gql`mutation PositionPhoto($input: PositionPhotoInput!) {
  positionPhoto(input: $input)
}`;

export const removePhotoMutation = gql`mutation RemovePhoto($id: ID!) {
  removePhoto(id: $id)
}`;

export const removePhotoBulkMutation = gql`mutation RemovePhotoBulk($ids: [ID]!) {
  removePhotoBulk(ids: $ids)
}`;

export const replaceLowResPhotoMutation = gql`mutation replaceLowResPhoto($input: ReplaceLowResPhotosInput!) {
replaceLowResPhotos(input: $input)
}`;