<section class="header safe-area-set" [ngStyle]="expanded ? {'visibility': 'hidden'} : {'visibility': 'visible'}">
    <img class="logo" src="assets/mobile-logo.svg" />
    <div>
      <span *ngIf="currentUser?.firstName" class="warning mr-8">Welcome Back,<span class="font-weight-500"> {{currentUser?.firstName || ''}}</span></span>
      <button kendoButton themeColor="primary" fillMode="link"
          [iconClass]="drawer.expanded ? 'fa-solid fa-times fa-xl' : 'fa-solid fa-bars'"
          (click)="drawer.toggle(); addBodyClass(); setSafeAreaView();"></button>
    </div>
</section>
<kendo-drawer-container>
    <kendo-drawer #drawer [items]="items" [mini]="false" [mode]="'overlay'" [(expanded)]="expanded"
        (select)="onSelect($event); drawer.toggle(); addBodyClass(false); expanded = false; setSafeAreaView();" [animation]="true">
        <ng-template kendoDrawerHeaderTemplate>
          <section class="header-drawer safe-area-set">
            <img class="logo" src="assets/mobile-logo.svg" />
            <div>
              <span *ngIf="currentUser?.firstName" class="warning mr-8">Welcome Back,<span class="font-weight-500"> {{currentUser?.firstName || ''}}</span></span>
              <button kendoButton themeColor="primary" fillMode="link"
                [iconClass]="drawer.expanded && 'fa-solid fa-times fa-xl'"
                (click)="drawer.toggle(); addBodyClass(false); expanded = false; setSafeAreaView();"></button>
            </div>
          </section>
        </ng-template>
        <ng-template kendoDrawerItemTemplate let-item>
            <i *ngIf="item.icon" [ngClass]="item.icon"></i>
            <span *ngIf="expanded">{{ item.text }}</span>
        </ng-template>
        <ng-template kendoDrawerFooterTemplate>
          <p class="info mb-0" *ngIf="sharedService.getAppInfo$() | async as appInfo"><strong>Version</strong> : {{appInfo?.version}} ({{appInfo?.build}})</p>
        </ng-template>
    </kendo-drawer>

    <kendo-drawer-content>
        <router-outlet></router-outlet>
    </kendo-drawer-content>
</kendo-drawer-container>
