import { map, switchMap, tap } from 'rxjs/operators';
import { of, pipe } from "rxjs";
import { MonoTypeOperatorFunction } from 'rxjs';
import { throwError } from "rxjs";

export function safeResolve(unwrapResponse = false): MonoTypeOperatorFunction<any> {
  return pipe(
    switchMap((result: any) => result.errors ? throwError(result.errors) : of(result)),
    !unwrapResponse ? tap() : map(({ data = null, ...result}) => {
      if (!data) return result;
      
      const keys = Object.keys(data);
      return keys.length ? data[keys[0]] : result;
    })
  );
}