import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { DrawerComponent } from '@progress/kendo-angular-layout';
import { IApiInvestigation } from 'src/app/shared/modules/graphql/types/types';

@Component({
  selector: "app-mobile-time-and-expense",
  templateUrl: "./mobile-time-and-expense.component.html",
  styleUrls: ["./mobile-time-and-expense.component.scss"],
})
export class MobileTimeAndExpenseComponent implements AfterViewInit, OnDestroy {
  @ViewChild("drawer") drawer: DrawerComponent;
  @ViewChild("timeAndExpenseForm") form: NgForm;
  public investigation: IApiInvestigation;
  public expenseTypeOption = [
    { text: "My Hour", val: "MY-HOUR" },
    { text: "My Expenses", val: "MY-EXPENSES" },
    { text: "My Mileage", val: "MY-MILEAGE" },
  ];
  public expenseTypeSelected = null;
  public step = 1;

  public isFormValid(): boolean {
    this.form.control.markAllAsTouched();
    return this.form.form.valid;
  }

  constructor(private router: Router, private location: Location, private cdr: ChangeDetectorRef) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const data = this.router.getCurrentNavigation()?.extras?.state?.data;
        if (data?.id) {
          this.investigation = data;
        }
      }
    });
  }

  public ngAfterViewInit() {
    this.drawer.toggle(true);
    this.addBodyClass(true);
    this.cdr.detectChanges();
  }

  public close() {
    this.drawer.toggle(false);
    this.addBodyClass(false);
    this.location.back();
  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove("kendo-dialog-open");
    }
  }

  public next() {
    if (!this.isFormValid()) {
      return;
    }
    this.drawer.toggle(false);
    this.step = 2;
  }

  public stepTo($event) {
    this.step = $event;
    setTimeout(() => {
      this.drawer.toggle(true);
    }, 100);
  }

  public ngOnDestroy(): void {
    this.addBodyClass(false);
  }
}
