import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces/base.interfaces';
import { IApiAddSettingsInput, IApiSettings, IApiSettingsConnection, IApiSettingsFilter, IApiUpdateSettingsInput } from "src/app/shared/modules/graphql/types/types";
import { ListPage } from "src/app/shared/helpers/list-page.class";
import { GqlSettingsService } from "../../modules/graphql/services";

@Injectable()
export class SettingsService {

  constructor(
    private gqlSettingsService: GqlSettingsService
  ) { }

  public get(filters?: IApiSettingsFilter[], options?: IGqlBaseOptions): Observable<IApiSettingsConnection> {
    return this.gqlSettingsService.getAll(filters, options || new ListPage());
  }

  public add(input: IApiAddSettingsInput): Observable<IApiSettings> {
    return this.gqlSettingsService.add(input);
  }

  public update(input: IApiUpdateSettingsInput): Observable<IApiSettings> {
    return this.gqlSettingsService.update(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlSettingsService.remove(id);
  }
}
