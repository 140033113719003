import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  createdAt
  updatedAt
`;

export const evidenceBillingFrequencyConnectionQuery = gql`${buildConnectionQuery("EvidenceBillingFrequency", "evidenceBillingFrequencyConnection", nodeFragment)}`;

export const addEvidenceBillingFrequencyMutation = gql`mutation createEvidenceBillingFrequency($input: AddEvidenceBillingFrequencyInput!) {
  addEvidenceBillingFrequency(input: $input) { ${ nodeFragment } }
}`;

export const removeEvidenceBillingFrequencyMutation = gql`mutation removeEvidenceBillingFrequency($input: RemoveEvidenceBillingFrequencyInput!) {
  removeEvidenceBillingFrequency(input: $input) { ${ nodeFragment } }
}`;

export const updateEvidenceBillingFrequencyMutation = gql`mutation updateEvidenceBillingFrequency($input: UpdateEvidenceBillingFrequencyInput!) {
  updateEvidenceBillingFrequency(input: $input) { ${ nodeFragment } }
}`;
