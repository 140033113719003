<section class="intake-page content-part">
    <div class="intake-container-reset-password">
        <kendo-card class="reset-password-card" width="100%">
            <div class="header-logo">
                <img class="img-fluid align-self-center" src="assets/intake-logo.png" />
            </div>
            <hr kendoCardSeparator class="logo-separator"/>
            <h4 class="page-lable text-center">Reset Your Password</h4>
            <p class="mb-24 text-center">
                Please enter your email address. We will send you an email to reset your password.
            </p>
            <form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
                <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
                    <kendo-gridlayout-item>
                        <kendo-formfield>
                            <kendo-label class="asterisk" [for]="email" text="Email Address"></kendo-label>
                            <kendo-textbox #email formControlName="email" fillMode="outline" [clearButton]="true" placeholder="Enter email address"></kendo-textbox>
                            <kendo-formerror>
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                <span *ngIf="form?.controls?.email?.errors?.required">Email cannot be left blank</span>
                                <span *ngIf="form?.controls?.email?.errors?.email">Invalid email address</span>
                                <span *ngIf="form?.controls?.email?.errors?.notExist">This email address dose not exist, please try another one.</span>
                            </kendo-formerror>
                        </kendo-formfield>
                    </kendo-gridlayout-item>
                    <div class="">
                        <button type="submit" kendoButton class="btn-primary login-portal" themeColor="primary" fillMode="solid">
                            Send Email
                        </button>
                        <button type="button" (click)="backToLogin()" kendoButton class="login-portal mt-12 width100" themeColor="light" fillMode="solid">
                            Back
                        </button>
                    </div>
                </kendo-gridlayout>
            </form>
        </kendo-card>
    </div>
  </section>
  