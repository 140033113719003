import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  additionalInformation
  emergencyContactName
  emergencyContactInfo
  payRateHourly
  requiredHoursQuarterly
  remainingHoursQuarterly
  status
  fullTime
  hasNefcoVehicle
  hasNefcoCreditCard
  driversLicenseNumber
  driversLicenseState
  vehicleRegistrationState
  vehicleInsuranceCompany
  startDate
  endDate
  createdAt
  updatedAt
`;
export const userDetailConnectionQuery = gql`${buildConnectionQuery("UserDetail", "userDetailConnection", nodeFragment)}`;

export const addUserDetailMutation = gql`mutation createUserDetail($input: AddUserDetailInput!) {
  addUserDetail(input: $input) { ${nodeFragment} }
}`;
export const updateUserDetailMutation = gql`mutation crupdateUserDetail($input: AddUserDetailInput!) {
  updateUserDetail(input: $input) { ${nodeFragment} }
}`;

export const removeUserDetailMutation = gql`mutation removeUserDetail($input: RemoveUserDetailInput!) {
  removeUserDetail(input: $input) {
    success
  }
}`;
