import { DataSource } from "@angular/cdk/collections";
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { IApiInvestigationMetricsDocusketchRequested, IApiInvestigationMetricsDocusketchRequestedConnection, IApiInvestigationMetricsFilter } from "src/app/shared/modules/graphql/types/types";
import { InvestigationMetricsDocusketchRequestedService } from "./investigation-metrics-docusketch-requested.service";

export class InvestigationMetricsDocusketchRequestedDataSource extends NefcoPagedDataSource<IApiInvestigationMetricsDocusketchRequested, InvestigationMetricsDocusketchRequestedService, IApiInvestigationMetricsDocusketchRequestedConnection> implements DataSource<IApiInvestigationMetricsDocusketchRequested> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsDocusketchRequestedConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
