import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  createdAt
  updatedAt
  CreatedBy {
    id
    firstName
    lastName
    email
  }
  ApprovalHistory {
    id
  }
  comment
  s3Uri
  uri
  status
  Document {
    id
  }

`;

export const documentSnapshotConnectionQuery = gql`${buildConnectionQuery("DocumentSnapshot", "documentSnapshotConnection", nodeFragment)}`;

export const addDocumentSnapshotMutation = gql`mutation AddDocumentSnapshot($input: AddDocumentSnapshotInput!) {
  addDocumentSnapshot(input: $input) { ${nodeFragment} }
}`;

export const updateDocumentSnapshotMutation = gql`mutation UpdateDocumentSnapshot($input: UpdateDocumentSnapshotInput!) {
  updateDocumentSnapshot(input: $input) { ${nodeFragment} }
}`;

export const removeDocumentSnapshotMutation = gql`mutation RemoveDocumentSnapshot($id: String) {
  removeDocumentSnapshot(id: $id)
}`;
