import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IApiRiskType } from './../types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { take, map } from 'rxjs/operators';
import gql from 'graphql-tag';
import { Observable, ReplaySubject } from 'rxjs';
import { IRiskTypeResults } from '../interfaces/risk-types.interfaces';

@Injectable()
export class GqlRiskTypesService {
  private _riskTypes$ = new ReplaySubject<IApiRiskType[]>(1);

  private _query = gql`query RiskTypes {
    riskTypes {
      id
      name
    }
  }`;

  public constructor(private apollo: Apollo) {
    this.apollo.query({
      query: this._query,
    }).pipe(
      safeResolve(true)
    ).subscribe(riskTypes => this._riskTypes$.next(riskTypes));
  }

  public get riskTypes$(): Observable<IApiRiskType[]> {
    return this._riskTypes$;
  }
}