import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { manageExpenseReportCategoriesList, manageExpenseItemsList, manageExpensePurposesList, manageTimeEntryTypesList } from "../../../../shared/helpers/auth-config/manage.config";
import { TabStripComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-bookkeeping-management',
  templateUrl: './bookkeeping-management.component.html',
  styleUrls: ['./bookkeeping-management.component.scss']
})
export class BookkeepingManagementComponent implements AfterViewInit {

  @ViewChild('tabstrip') public tabstrip: TabStripComponent;
  
  public authConfig = {
    manageExpenseReportCategoriesList,
    manageExpenseItemsList,
    manageExpensePurposesList,
    manageTimeEntryTypesList,
  }

  constructor() { }

  public ngAfterViewInit() {
    Promise.resolve(null).then(() => this.tabstrip.selectTab(0));
  }

}
