<!-- Permissions -->
<div [appEnforcePermission]="permissionConfig.contactsProfileCompanyInformationView"
  #contactsProfileCompanyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsProfileContactInformationViewAssigned"
  #contactsProfileContactInformationViewAssigned="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsInvestigations" #contactsInvestigations="appEnforcePermission">
</div>
<div [appEnforcePermission]="permissionConfig.contactsInvestigationsView"
  #contactsInvestigationsView="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0">
      <h4 class="page-title" [innerHTML]="contact | fullName"></h4>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="text-right mb-0">
      <button kendoButton themeColor="light" (click)="goBack()" fillMode="solid"
        [iconClass]="'fa-solid fa-arrow-left-long fa-xl'">BACK</button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <div class="content-wrapper">
    <div class="tabs">
      <kendo-tabstrip #tabstrip [scrollable]="{ buttonScrollSpeed: 500 }" class="bookkeeping-manage-tabs">
        <kendo-tabstrip-tab [selected]="selectedTab === 0">
          <ng-template kendoTabTitle>
            <span>PROFILE</span>
          </ng-template>
          <ng-template kendoTabContent>
            <div
              *ngIf="contactsProfileCompanyInformationView?.enabled || contactsProfileContactInformationViewAssigned?.enabled">
              <app-contact-detail></app-contact-detail>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [selected]="selectedTab === 1" *ngIf="contactsInvestigations?.enabled">
          <ng-template kendoTabTitle>
            <span>INVESTIGATIONS</span>
          </ng-template>
          <ng-template kendoTabContent>
            <div>
              <app-investigations-table-new [config]="contactConfig" #investigationTable></app-investigations-table-new>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </div>
</section>
