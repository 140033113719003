import gql from 'graphql-tag';

export const reportSentMetricReportConnectionQuery = gql`query ReportSentConnection($filters: [DocumentSentFilter], $orderBy: DocumentSentOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  reportSentConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    pageInfo
      {
        limit
        offset
      }
      totalCount
      edges
      {
        cursor
        node
        {
        id
        sendDate
        count
        }
      }
    }
  }
`;
