import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "src/app/shared/shared.module";

import {
  ContactDetailComponent,
  ContactManagementComponent,
} from "./";
import { DashboardModule } from '../dashboard/dashboard.module';
import { CompaniesModule } from '../companies/companies.module';
import { CreateUpdateContactComponent } from './create-update-contact/create-update-contact.component';

@NgModule({
  declarations: [
    ContactDetailComponent,
    ContactManagementComponent,
    CreateUpdateContactComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule,
    CompaniesModule

  ],
  exports: [
    ContactDetailComponent,
    ContactManagementComponent
  ]
})
export class ContactsModule { }
