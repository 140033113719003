import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PortalAuthService } from './portal-auth.service';
import { RequestOptions } from 'http';

@Injectable()
export class PortalRequestService {
    constructor(
        private http: HttpClient,
        private auth: PortalAuthService
    ) { }

    private baseUrl = environment.portal.base_url;


    private get options() {
        return {
            headers: {
                Authorization: `Bearer ${this.auth.user.token}`
            }
        };
    }

    private mergeOptions(extras: RequestOptions) {
        // Intentionally want to override any extras that conflict with our necessary headers/options
        return { ...extras, ...this.options };
    }

    public unprotectedGet(url: string, options?: RequestOptions) {
        return this.http.post(`${this.baseUrl}${url}`, options);
    }

    public get(url: string, options?: RequestOptions) {
        return this.http.post(`${this.baseUrl}${url}`, this.mergeOptions(options));
    }

    public put(url: string, payload: any, options?: RequestOptions) {
        return this.http.post(`${this.baseUrl}${url}`, payload, this.mergeOptions(options));
    }

    public delete(url: string, options?: RequestOptions) {
        return this.http.delete(`${this.baseUrl}${url}`, this.mergeOptions(options));
    }

    public post(url: string, payload: any, options?: RequestOptions) {
        return this.http.post(`${this.baseUrl}${url}`, payload, this.mergeOptions(options));
    }



}
