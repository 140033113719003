<!-- For all other dates -->
<mat-form-field class="datePicker" *ngIf="!isLossDate">

  <mat-label> {{ label }} </mat-label>

  <input *ngIf="required; else notRequired" matInput name="datePickerForm" [matDatepicker]="picker" [(ngModel)]="value" [disabled]="disabled" (dateChange)="onDateChange()" required>
  <ng-template #notRequired>
    <input matInput name="datePickerForm" [matDatepicker]="picker" [(ngModel)]="value" [disabled]="disabled" (dateChange)="onDateChange()">
  </ng-template>
  
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker startView="{{ startView }}"></mat-datepicker>
</mat-form-field>

<!-- For the Loss Date -->
<mat-form-field class="datePicker" *ngIf="isLossDate">
  <mat-label> {{ label }} </mat-label>

  <input *ngIf="required; else notRequired" matInput name="datePickerForm" [matDatepicker]="picker" [ngModel]="value" [disabled]="disabled" (dateChange)="onLossDateChange($event)" required>
  <ng-template #notRequired>
    <input matInput name="datePickerForm" [matDatepicker]="picker" [ngModel]="value" [disabled]="disabled" (dateChange)="onLossDateChange($event)">
  </ng-template>
  
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker startView="{{ startView }}"></mat-datepicker>
</mat-form-field>
