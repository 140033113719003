<div [dialogRef]="dialog" (appClickOutside)="close()">
<div [appEnforcePermission]="authConfig.investigationScheduledDateViewAll"
  #investigationScheduledDateViewAll="appEnforcePermission"></div>
<kendo-dialog-titlebar (close)="close()">
  <div class="modal-title" *ngIf="staffList?.length === 0 || !investigationScheduledDateViewAll?.enabled">
    <span>{{ investigator?.firstName }} {{ investigator?.lastName }}</span>
  </div>
  <kendo-gridlayout *ngIf="staffList?.length && investigationScheduledDateViewAll?.enabled" [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }"
    [cols]="['repeat(1,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0">
      <div class="kendo-formfield">
        <kendo-label text="Staff Member"></kendo-label>
        <kendo-dropdownlist class="ml-12" [data]="staffList" textField="userName" valueField="id"
          (selectionChange)="changeInvestigator($event)" fillMode="outline" [value]="investigator" [popupSettings]="{
          appendTo: 'component',
          animate: false,
          width: 250
        }" required>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="dataItem?.userName === 'Select staff member'" class="placeholder">
              {{ dataItem?.userName }}
            </span>
            <span *ngIf="dataItem?.userName !== 'Select staff member'" class="not-placeholder">
              {{ dataItem?.userName }}
            </span>
          </ng-template>
        </kendo-dropdownlist>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</kendo-dialog-titlebar>
<section class="p-0">
  <kendo-tabstrip class="profile-sidebar-tabs" [tabAlignment]="'justify'">
    <kendo-tabstrip-tab [selected]="true">
      <ng-template kendoTabTitle>
        <div class="tab-title-div">
          <i class="fa-solid fa-calendar"></i>
          <span>Calendar</span>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <kendo-card width="100%" class="mt-8">
          <app-calendar-view [investigator]="investigator" [from]="from" [investigation]="data"></app-calendar-view>
        </kendo-card>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab>
      <ng-template kendoTabTitle>
        <div class="tab-title-div">
          <i class="fa-solid fa-user"></i>
          <span>Profile</span>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <kendo-card width="100%" class="mt-8" *ngIf="investigator?.id">
          <app-staff-profile [userId]="investigator.id"></app-staff-profile>
        </kendo-card>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</section>
</div>