<!-- Permissions -->
<div [appEnforcePermission]="authConfig.bookKeepingInvoiceListCreate"
  #bookKeepingInvoiceListCreate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(1,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0">
      <h4 class="page-title">Evidence Billing</h4>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <div class="content-wrapper">
    <div class="tabs">
      <kendo-tabstrip (tabSelect)="tabsChanged($event)" #tabstrip [scrollable]="{ buttonScrollSpeed: 500 }"
        class="bookkeeping-manage-tabs">
        <kendo-tabstrip-tab [selected]="selectedTab === 0">
          <ng-template kendoTabTitle>
            <span>TO BE INVOICED</span>
          </ng-template>
          <ng-template kendoTabContent>
            <ng-container *ngTemplateOutlet="tableArea; context: {invoiced: false}">
            </ng-container>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [selected]="selectedTab === 1">
          <ng-template kendoTabTitle>
            <span>RECENTLY INVOICED</span>
          </ng-template>
          <ng-template kendoTabContent>
            <ng-container *ngTemplateOutlet="tableArea; context: {invoiced: true}"></ng-container>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </div>
</section>
<ng-template #tableArea let-invoiced="invoiced">
  <section class="p-24">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <app-contact-select-kendo (selectionChanged)="filterTable(filterOptions.EvidenceBillingBillto, $event?.id)"
          placeholder="Search for bill to"></app-contact-select-kendo>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <app-contact-select-kendo (selectionChanged)="filterTable(filterOptions.EvidenceBillingClient, $event?.id)"
          placeholder="Search for client"></app-contact-select-kendo>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item *ngIf="invoiced">
        <app-user-select-kendo [userViewFilter]="userViewFilter"
          (selectionChanged)="filterTable(filterOptions.EvidenceBillingCreatedby, $event?.id)"
          placeholder="Search for created by"></app-user-select-kendo>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label [text]="''"></kendo-label>
          <div>
            <kendo-textbox [(ngModel)]="searchValue"
              (appAfterValueChanged)="filterTable(filterOptions.EvidenceBillingSearch, $event)" placeholder="Search"
              name="search" fillMode="outline" [clearButton]="true"></kendo-textbox>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-grid class="bookkeeping-evidence-billing-list" [data]="{
      data: expenses,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }" (pageChange)="pageChange($event)" [sortable]="true" [kendoGridExpandDetailsBy]="'id'" [sort]="sort"
      (sortChange)="sortChange($event)">
      <kendo-grid-column field="CREATED_AT" title="Bill Date" [width]="7" class="id-column" [sortable]="true"
        [headerClass]="'sort-header'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.createdAt | customDate: 'MM/dd/yyyy' }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="NEFCO_NUMBER" title="NEFCO #" [width]="7" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a [routerLink]="['/investigations/', dataItem.Investigation.id]" target="_blank"
            class="text-decoration word-break" [innerHTML]="dataItem?.Investigation?.nefcoNumber"></a> </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="EXPENSE_DATE" title="Last Bill" [width]="10" class="id-column" [sortable]="true"
        [headerClass]="'sort-header'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem?.Investigation?.EvidenceBilling?.lastExpensed | customDate: 'MM/dd/yyyy' }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="SCHEDULE" title="Schedule" [width]="7" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem?.Investigation?.EvidenceBilling?.BillingFrequency?.name || '-'}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="PALLETS" title="Pallets" [width]="5" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem?.Investigation?.EvidenceBilling?.palletCount || '-'}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="RATE" title="Rate" [width]="5" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem?.Investigation?.EvidenceBilling?.rate || '-'}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="BILLABLE" title="Billable" [width]="5" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem?.billableQuantity || '-'}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="BILL_TO" title="Bill To" [width]="20" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.Investigation.BillTo | fullName }}<br />
          <app-address-view *ngIf="dataItem?.Investigation?.BillToBranch; else BillToAddress"
            [address]="dataItem?.Investigation?.BillToBranch?.BillingAddress"></app-address-view>
          <ng-template #BillToAddress><app-address-view
              [address]="dataItem?.Investigation?.BillTo?.BillingAddress"></app-address-view></ng-template>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="CLIENT" title="Client" [width]="20" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.Investigation.Client | fullName }} <br />
          <app-address-view *ngIf="dataItem?.Investigation?.ClientBranch; else ClientAddress"
            [address]="dataItem?.Investigation?.ClientBranch?.Addresses | primaryAssociation"></app-address-view>
          <ng-template #ClientAddress><app-address-view
              [address]="dataItem?.Investigation?.Client?.Addresses | primaryAssociation"></app-address-view></ng-template>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="ACTION" title="" [width]="10" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton themeColor="primary" (click)="createInvoice($event, dataItem)" fillMode="solid"
            *ngIf="!invoiced && bookKeepingInvoiceListCreate?.enabled">Create Invoice</button>
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridDetailTemplate let-mainDataItem>
        <kendo-grid [data]="mainDataItem.Investigation?.Evidence" [pageable]="false" [scrollable]="'none'"
          [className]="'detail-items'">
          <kendo-grid-column field="EVIDENCE" title="Evidence #" [width]="30" [sortable]="false"
            [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <a [routerLink]="['/investigations/', mainDataItem.Investigation.id]" [queryParams]="{tab: 'evidence'}"
                appEventLink class="text-decoration word-break" [innerHTML]="dataItem.evidenceNumber"></a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="Description" title="Description" [width]="50" [sortable]="false"
            [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem?.description || '-' }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="Location" title="Location" [width]="50" [sortable]="false"
            [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem?.EvidenceStorageLocation?.name || '-' }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="ARRIVAL" title="Arrival Date" [width]="50" [sortable]="false"
            [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem?.createdAt | customDate: 'MM/dd/yyyy' }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-grid>
  </section>
</ng-template>
