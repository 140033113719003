import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgModule, ModuleWithProviders } from "@angular/core";
import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { ApolloLink } from "apollo-link";
import { InMemoryCache, split } from "@apollo/client/core";
import { setContext } from "apollo-link-context";
import { onError } from 'apollo-link-error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import * as Services from "./services";

import { AuthService } from '@auth0/auth0-angular';
import { NotificationsService } from '../notifications/notifications.service';
import { AirbrakeErrorHandler } from 'src/app/airbrake-error-handler';
import { getMainDefinition } from '@apollo/client/utilities';

const appAuth = setContext((operation, context) => {
  return {
    headers: {
      Authorization: environment.graphql.token,
    },
  };
});

const userAuth = (auth: AuthService) => setContext(async () => {
  const token = await auth.idTokenClaims$.pipe(take(1)).toPromise();

  return {
    headers: {
      Authorization: token ? `Bearer ${token?.__raw}` : '',
      "apollo-require-preflight": "true"
    },
  };
});

const errorHandler = (authService: AuthService, notifications: NotificationsService) => onError(({ graphQLErrors, networkError, response, operation, forward }) => {
  const airbrakeErrorHandler = new AirbrakeErrorHandler();
  if (graphQLErrors && !environment.production) {
    graphQLErrors.forEach(({ message, path }) => {
      console.log(`[GraphQL error]: Message: ${message} - Path: ${path}`);
      airbrakeErrorHandler.handleError(`[GraphQL error]: Message: ${message} - Path: ${path}`);
    });

    // response is by-ref; set the data here if there is an Authentication error (null otherwise, causes JS errors)
    if (graphQLErrors.find(e => e.extensions.code === "UNAUTHENTICATED")) response.data = {};
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    airbrakeErrorHandler.handleError(`[Network error]: ${JSON.stringify(networkError)}`);
  }

  if ((networkError as any)?.error?.errors?.find((err) => err?.extensions?.code?.toLowerCase() === 'unauthenticated')) {
    notifications.error(`Unable to find the user you've logged in with. Has it been added to Extranet?`).afterClosed().subscribe(() => {
      authService.logout();
    });
  }
});

export function createApollo(httpLink: HttpLink, authService: AuthService, notifications: NotificationsService) {

  const http = httpLink.create({ uri: environment.graphql.endpoint });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: environment.graphql.socket_endpoint,
    }),
  );
  const mix: any = ApolloLink.from([
    appAuth,
    userAuth(authService),
    errorHandler(authService, notifications),
    http,
  ]);
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    mix,
  )

  return {
    link: link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none',
      }
    }
  };
}

@NgModule({
  exports: [HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [
        HttpLink,
        AuthService,
        NotificationsService
      ],
    },
  ],
})
export class GraphQLModule {
  static forRoot(): ModuleWithProviders<GraphQLModule> {
    return {
      ngModule: GraphQLModule,
      providers: [
        ...Object.keys(Services).map(s => Services[s])
      ]
    };
  }
}
