<h3>Phone</h3>
<kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
  <kendo-gridlayout-item *ngIf="!config.hideType">
    <kendo-formfield>
      <kendo-label [ngClass]="'asterisk'" text="Type"></kendo-label>
      <kendo-dropdownlist [data]="phoneTypes" textField="name" valueField="id" fillMode="outline"
        [valuePrimitive]="false" [(ngModel)]="value.Type" [ngModelOptions]="{standalone: true}" [name]="'type' + name"
        [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" #type="ngModel" required
        [disabled]="config.static">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <span [ngClass]="dataItem?.id ? 'not-placeholder' : 'placeholder'"> {{ dataItem?.name}} </span>
        </ng-template>
      </kendo-dropdownlist>
      <kendo-formerror *ngIf="!type?.valid && (type?.dirty || type?.touched)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span *ngIf="type?.errors?.required">
          Type cannot be left blank
        </span>
      </kendo-formerror>
    </kendo-formfield>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item>
    <kendo-formfield>
      <kendo-label [ngClass]="'asterisk'" text="Phone Number"></kendo-label>
      <kendo-maskedtextbox #phoneNumber="ngModel" [name]="'number' + name" [(ngModel)]="value.number" fillMode="outline"
        placeholder="Enter Phone Number" mask="(000) 000-0000" required
        [disabled]="config.static"></kendo-maskedtextbox>
      <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
      <kendo-formerror *ngIf="!phoneNumber?.valid && (phoneNumber?.dirty || phoneNumber?.touched)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span *ngIf="phoneNumber?.errors?.required">
          Phone Number cannot be left blank
        </span>
        <span *ngIf="phoneNumber?.errors?.patternError || phoneNumber?.errors?.pattern">
          Invalid phone number
        </span>
      </kendo-formerror>
    </kendo-formfield>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item>
    <kendo-formfield>
      <kendo-label text="Ext"></kendo-label>
      <kendo-textbox appNoWhiteSpace [name]="'ext' + name" fillMode="outline" #ext="ngModel" [clearButton]="false"
        placeholder="Enter Ext" [(ngModel)]="value.extension" [maxlength]="255" [disabled]="config.static">
      </kendo-textbox>
      <kendo-formerror *ngIf="!ext?.valid && (ext?.dirty || ext?.touched) && ext?.errors?.required">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span>
          Ext cannot be left blank
        </span>
      </kendo-formerror>
    </kendo-formfield>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item *ngIf="!config.hidePrimary">
    <kendo-formfield class="remove">
      <kendo-label text=""></kendo-label>
      <div class="k-checkbox-wrap">
        <input #notRequired kendoCheckBox type="checkbox" [name]="'isPrimary' + name" [(ngModel)]="value.isPrimary"
          (change)="togglePrimary($event.target)" />
        <kendo-label [for]="notRequired" class="k-checkbox-label font-weight-600 checkbox-margin"
          text="Primary"></kendo-label>
      </div>
      <div>
        <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-circle-xmark fa-lg"
          (click)="remove.emit(true)"></button>
      </div>
    </kendo-formfield>
  </kendo-gridlayout-item>
</kendo-gridlayout>