<div class="date-section">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
    [cols]="allowChangeFilter ? ['repeat(2,minmax(0,1fr))'] : ['repeat(1,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0" *ngIf="allowChangeFilter">
      <div class="kendo-formfield">
        <kendo-dropdownlist [data]="filterOptions" textField="text" valueField="slug" fillMode="outline"
          [valuePrimitive]="true" name="filterType" (selectionChange)="filterChange($event)" [value]="currentFilter"
          style="width: 100%;"
          [popupSettings]="{appendTo: 'component', animate: false, popupClass: 'nodata', width: 250 }">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span class="placeholder"> {{ dataItem?.text}} </span>
          </ng-template>
          <ng-template kendoDropDownListNoDataTemplate>
          </ng-template>
        </kendo-dropdownlist>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="mb-0 flex-display">
      <section class="month-view" *ngIf="currentFilter === 'QUARTERLY'">
        <div (click)="changeRange('backward')">
          <i class="fa-solid fa-angle-left"></i>
        </div>
        <div class="week-ending">
          <ng-container *ngIf="mode === 'quarter'">
            Current Quarter :
            {{ selectedDateRange?.startDate | amDateFormat : "MM/DD/YYYY" }} -
            {{
            selectedDateRange?.endDate | amDateFormat : "MM/DD/YYYY"
            }}</ng-container>
        </div>
        <div (click)="changeRange('forward')" [ngClass]="{ 'disabled': noNextRange }">
          <i class="fa-solid fa-angle-right"></i>
        </div>
      </section>
      <section class="month-view" *ngIf="currentFilter === 'MONTH'">
        <div (click)="changeRange('backward')">
          <i class="fa-solid fa-angle-left"></i>
        </div>
        <div class="week-ending">
          <ng-container *ngIf="mode === 'month'">
            {{ selectedDateRange?.startDate | amDateFormat: 'MMMM YYYY' }}
          </ng-container>
        </div>
        <div (click)="changeRange('forward')" [ngClass]="{ 'disabled': noNextRange }">
          <i class="fa-solid fa-angle-right"></i>
        </div>
      </section>
      <div class="kendo-formfield date-range-wrapper">
        <kendo-daterange #dateRange [ngStyle]=" {'display':  currentFilter === 'DATE_RANGE'  ? 'block' :'none'}">
          <kendo-dateinput #startDateRange (click)="focusDateInput(true)" calendarType="classic" required="true" class="mr-16" placeholder="Start Date" [fillMode]="'outline'"
            name="startDate" #startDate="ngModel" kendoDateRangeStartInput [(ngModel)]="range.startDate"
            (ngModelChange)="updateDateFilters($event)"></kendo-dateinput>
          <kendo-dateinput #endDateRange (click)="focusDateInput(false)" calendarType="classic" required="true" class="mr-16" placeholder="End Date" [fillMode]="'outline'"
            name="endDate" #endDate="ngModel" kendoDateRangeEndInput [(ngModel)]="range.endDate"
            (ngModelChange)="updateDateFilters($event)"></kendo-dateinput>
          <kendo-daterange-popup #dateRangePopup autoCorrectOn="blur"></kendo-daterange-popup>
          <kendo-formerror *ngIf="(startDate?.errors?.required && (startDate?.touched || startDate?.dirty)) || endDate?.errors?.required && (endDate?.touched || endDate?.dirty)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span>Date range is required</span>
          </kendo-formerror>
        </kendo-daterange>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</div>
