import { gql } from 'apollo-angular';

export const nodeFragment = `
    id
    Type {
        id
        name
        createdAt
        updatedAt
    }
    isPrimary
    number
    extension
    label
    phoneableId
    phoneableType
    createdAt
    updatedAt
`;

export const addPhoneMutation = gql`mutation Phone($input: AddPhoneInput!) {
    addPhone(input: $input) { ${ nodeFragment } }
  }`;

export const removePhoneMutation = gql`mutation Phone($input: RemovePhoneInput) {
    removePhone(input: $input) { ${nodeFragment} }
}`;