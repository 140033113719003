<!-- Permissions -->
<div [appEnforcePermission]="authConfig.expenseReportNefcoCardCreate" #expenseReportNefcoCardCreate="appEnforcePermission"></div>
<!-- Permissions -->
<section class="pl-24 pr-24">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']" class="mt-16 mb-16">
      <kendo-gridlayout-item class="month-view mb-0">
        <div (click)="onMonthDecrease()">
          <i class="fa-solid fa-angle-left"></i>
        </div>
        <div class="title">
          {{selectedMonth?.startDate | amDateFormat: 'MMMM YYYY'}}
        </div>
        <div (click)="onMonthIncrease()" [ngClass]="{ 'disabled': noNextRange }">
          <i class="fa-solid fa-angle-right"></i>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="flex-right mb-0">
          <kendo-dropdownbutton *ngIf="expenseReportNefcoCardCreate?.enabled"
                class="mr-16"
                [data]="[{text: 'Add Expense'}] "
                themeColor="light"
                fillMode="solid"
                buttonClass="grid-action-items"
                iconClass="fa-regular fa-plus"
                (itemClick)="itemSelected($event)"
                [popupSettings]="{
                  appendTo: 'component',
                  animate: false,
                  width: 250,
                  popupClass: 'grid-action-items-menu mt-12'
                }"
              >
              Add Entry
          </kendo-dropdownbutton>
          <button kendoButton themeColor="primary" (click)="submitReport()" fillMode="solid">Submit Report</button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <hr kendoCardSeparator />
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" class="expense-counter-wrapper" [cols]="['repeat(3,minmax(0,1fr))']" class="mt-8 mb-8">
      <kendo-gridlayout-item class="mb-0">
        <div class="expense-div">
          <div>
            <h4>Total Expenses</h4>
            <p>${{(expenseTotal?.totalExpense | number : '1.2-2') || 0}}</p>
          </div>
          <div>
            <h4>Billable Expenses</h4>
            <p>${{(expenseTotal?.billableExpense | number : '1.2-2') || 0}}</p>
          </div>
          <div>
            <h4>Non-Billable Expenses</h4>
            <p>${{(expenseTotal?.nonBillableExpense | number : '1.2-2') || 0}}</p>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <hr kendoCardSeparator />
    <app-expense-items-view [tab]="'NEFCO_CARD_EXPENSE_REPORT'" [expenses]="expenses" [dataSource]="dataSource" [pageOptions]="pageOptions"  [expenseItems]="expenseItems" [investigations]="investigations" [config]="config" (reload)="getExpenses();"></app-expense-items-view>
</section>