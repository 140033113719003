import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiRequestTypesConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  requestTypesConnectionQuery, addRequestTypeMutation,
  removeRequestTypeMutation, getRequestTypeByIdQuery,
  updateRequestTypeMutation
} from './request-type.operations';
import {
  IApiRequestTypeFilter, IApiAddRequestTypeInput, IApiUpdateRequestTypeInput,
  IApiRemoveRequestTypeInput, IApiRequestType
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlRequestTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiRequestTypeFilter[], options: IGqlBaseOptions): Observable<IApiRequestTypesConnection> {
    return this.apollo.query(connectionQuery(requestTypesConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddRequestTypeInput): Observable<IApiRequestType> {
    return this.apollo.mutate({
      mutation: addRequestTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(input: IApiRemoveRequestTypeInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeRequestTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateRequestTypeInput): Observable<IApiRequestType> {
    return this.apollo.mutate({
      mutation: updateRequestTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getRequestTypeById(id: string): Observable<IApiRequestType> {
    return this.apollo.query({
      query: getRequestTypeByIdQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
