import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { tap, filter } from 'rxjs/operators';
import { IApiNotification, IApiNotificationFilterType, IApiNotificationOrderBy } from './../../../../shared/modules/graphql/types/types';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthService, InvestigationService, LocalStorageService, UserNotificationService } from "src/app/shared/services";
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { investigationCreate } from "src/app/shared/helpers/auth-config/investigations.config";
import {
  staffContactInformationView,
  staffContactInformationOwnView,
  staffUserInformationView,
  staffUserInformationOwnView,
  staffEmergencyInformationView,
  staffEmergencyInformationOwnView,
  downloadMobileApplicationAll,
} from "src/app/shared/helpers/auth-config/staff.config";
import { PermissionActions } from 'src/app/shared/modules/graphql/enums/permissions.enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnforcePermissionDisplayModes } from 'src/app/shared/directives/enforce-permission.directive';
import { Align } from '@progress/kendo-angular-popup';
import { getNotificationIcon } from 'src/app/shared/helpers/helper';
import { UserNotificationDataSource } from 'src/app/shared/services/user-notification';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { SharedService } from 'src/app/common/shared.service';
@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public authConfig = {
    investigationCreate,
    staffContactInformationView,
    staffContactInformationOwnView,
    staffUserInformationView,
    staffUserInformationOwnView,
    staffEmergencyInformationView,
    staffEmergencyInformationOwnView,
    downloadMobileApplicationAll
  };

  @ViewChild("anchor", { read: ElementRef }) public anchor: ElementRef;
  @ViewChild("popup", { read: ElementRef }) public popup: ElementRef;
  @HostListener("document:keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.toggle(false);
    }
  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target)) {
      this.toggle(false);
    }
  }

  public show = false;
  public anchorAlign: Align = { horizontal: "right", vertical: "bottom" };
  public popupAlign: Align = { horizontal: "right", vertical: "top" };

  public notify = [];

  private _dataSource: UserNotificationDataSource;

  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  private _notifications: IApiNotification[] = [];

  public get notifications(): IApiNotification[] {
    return this._notifications;
  }
  public set notifications(val: IApiNotification[]) {
    this._notifications = val;
  }

  public get showNotifications(): IApiNotification[] {
    return this._notifications.slice(0, 5);
  }

  public user: any;
  public portalActive = false;

  public newNotifs = false;

  public showSearch = false;
  public nefcoNumberSearch = "";
  public policyClaimNumberSearch = "";
  public loading = true;

  constructor(
    private auth: AuthService,
    private notificationService: NotificationsService,
    private userNotificationService: UserNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private investigationService: InvestigationService,
    private local: LocalStorageService,
    public loader: LoaderService,
    private sharedService: SharedService,
  ) { }

  ngOnInit() {
    this.auth.authenticatedUser.subscribe(user => {
      this.user = user
      this._dataSource = new UserNotificationDataSource(
        this.userNotificationService
      );
      // this.loader.attachObservable(this._dataSource.loading$);

      this._dataSource.applyFilter(IApiNotificationFilterType.IsViewed, 'false');
      this._dataSource.listPage.orderBy = IApiNotificationOrderBy.CreatedAt;
      this._dataSource.listPage.sortOrder = SortOrder.DESC;
      this._dataSource.listPage.limit = 20;

      // populate the data
      this.load();
    });
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.nefcoNumberSearch = params?.NEFCO_NUMBER || '';
      this.policyClaimNumberSearch = params?.POLICY_CLAIM_NUMBER || '';
    });
  }

  public load() {
    this.loading = true;
    this.dataSource.load().then(() => {
      this.loading = false;
      this.dataSource.contents$.subscribe((res) => {
        this.notifications = res;
      });
    });
  }

  public get getCount() {
    return this.pageOptions?.totalCount - this.notifications.filter(item => item.viewed === true).length;
  }

  public viewPage(data: IApiNotification) {
    this.toggle(false);
    this.userNotificationService.markNotificationsAsReadOrUnread({ids: [data?.id], viewed: true}).subscribe();
    return this.router.navigateByUrl(data?.redirectUrl);
  }

  public markNotificationsAsReadOrUnread(event, read = true, id = null) {
    event.stopPropagation();
    this.notifications.map(item => {
      item.viewed = (item?.id === id) ? read : item?.viewed;
      return item;
    });
    this.userNotificationService.markNotificationsAsReadOrUnread({ids: [id], viewed: read}).subscribe();
  }

  // public goToNotifications(settings: boolean): void {
  //   settings ? this.router.navigate(["/notifications"], { queryParams: { tab: 'settings' } }) : this.router.navigate(["/notifications"]);
  // }

  public goToInvestigations() {
    // Tried to clear URL parameters from here, but it wasn't working
    // Setting '' instead as value
    const params = {
      queryParams: {
        NEFCO_NUMBER: this.nefcoNumberSearch?.trim() || '',
        POLICY_CLAIM_NUMBER: this.policyClaimNumberSearch?.trim() || ''
      }
    };
    this.onChangeUpdateFiltes();
    this.router.navigate(["/admin/investigations/list"], { relativeTo: this.route, ...params });
  }

  /* On enter OR Change value update localStorage value to get and set in investigaiton list */
  public onChangeUpdateFiltes(): void {
    if (this.sharedService.getLocalStorageByKey('INVESTIGATION_LIST')?.length > 0) {
      const setCurrentSearchInStorage = this.sharedService.getLocalStorageByKey('INVESTIGATION_LIST');
      for (const key in setCurrentSearchInStorage) {
        if (setCurrentSearchInStorage[key].type === 'NEFCO_NUMBER') {
          setCurrentSearchInStorage[key].value = this.nefcoNumberSearch?.trim() || "";
        }
        if (setCurrentSearchInStorage[key].type === 'POLICY_CLAIM_NUMBER') {
          setCurrentSearchInStorage[key].value = this.policyClaimNumberSearch?.trim() || "";
        }
      };
      return this.sharedService.setLocalStorageByKey('INVESTIGATION_LIST', setCurrentSearchInStorage);
    } else {
      const setCurrentSearchInStorage = [
        { type: "NEFCO_NUMBER", value: this.nefcoNumberSearch?.trim() || "" },
        { type: "POLICY_CLAIM_NUMBER", value: this.policyClaimNumberSearch?.trim() || "" },
      ];
      return this.sharedService.setLocalStorageByKey('INVESTIGATION_LIST', setCurrentSearchInStorage);
    }
  }

  public logout() {
    this.notificationService.confirm("Really log out?").afterClosed().pipe(
      filter(r => !!r)
    ).subscribe(() => {
      this.local.removePortalEmail();
      this.auth.logout();
      this.sharedService.clearLocalStorage();
    });
  }

  public toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
    if (this.show) this.load()
  }

  private contains(target: EventTarget): boolean {
    return (
      this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }

  public getIcon = (key) => {
    return getNotificationIcon(key);
  };

  public redirecToList() {
    this.router.navigate(['/notifications']);
    this.toggle(false);
  }

}
