import { IApiPhotoConnection, IApiReplaceLowResPhotosInput } from './../../modules/graphql/types/types';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiAddPhotoInput, IApiPhoto, IApiUpdatePhotoInput, IApiPhotoFilter, IApiPositionPhotoInput } from '../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlPhotoService } from "src/app/shared/modules/graphql/services";

@Injectable()
export class PhotoService {

  constructor(
    private photos: GqlPhotoService,
  ) { }

  public get(filters: IApiPhotoFilter[] = [], options?: IGqlBaseOptions): Observable<IApiPhotoConnection> {
    return this.photos.getPhotos(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddPhotoInput): Observable<IApiPhoto> {
    return this.photos.addPhoto(input);
  }

  public addBulk(input: IApiAddPhotoInput[]): Observable<boolean> {
    return this.photos.addPhotoBulk(input);
  }

  public update(input: IApiUpdatePhotoInput): Observable<IApiPhoto> {
    return this.photos.updatePhoto(input);
  }

  public updateBulk(input: IApiUpdatePhotoInput[]): Observable<boolean> {
    return this.photos.updatePhotoBulk(input);
  }

  public uploadLowRes(fileInput: File, input: IApiAddPhotoInput): Observable<IApiPhoto> {
    return this.photos.uploadLowResPhoto(fileInput, input);
  }
  public replaceLowResPhotos(input: IApiReplaceLowResPhotosInput) {
    return this.photos.replaceLowResPhotos(input);
  }

  public position(input: IApiPositionPhotoInput): Observable<boolean> {
    return this.photos.positionPhoto(input);
  }

  public delete(id: string): Observable<boolean> {
    return this.photos.removePhoto(id);
  }

  public deleteBulk(ids: string[]): Observable<boolean> {
    return this.photos.removePhotoBulk(ids);
  }
}
