import { IApiAuthorizationResponse } from './../../types/types';
import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { safeResolve } from "../../rxjs";

@Injectable()
export class GqlAuthorizationService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get Authorization(): Observable<IApiAuthorizationResponse> {
    const q = gql`query Authorization {
      authorization {
        User {
          id
          firstName
          lastName
          msGraphId
          msGraphToken
          email
          UserDetail {
            id
            requiredHoursQuarterly
            remainingHoursQuarterly
            additionalInformation
            startDate
            endDate
            hasNefcoVehicle
            hasNefcoCreditCard
            vehicleRegistrationState
            vehicleInsuranceCompany
            emergencyContactName
            emergencyContactInfo
            payRateHourly
            status
            fullTime
            driversLicenseNumber
            driversLicenseState
          }
          PermissionRoles {
            id
            name
            TimeEntryType{
              id
              name
            }
          }
          Positions {
            id
            title
          }
          States
        }
        EffectivePermissions {
          id
          name
          EffectivePermissionScopes {
            id
            name
            EffectivePermissionActions {
              id
              name
            }
          }
        }
      }
    }`;

    return this.apollo.query({ query: q, fetchPolicy: 'no-cache' }).pipe(
      safeResolve(),
      map(({ data }: any) => data.authorization)
    );
  }
}
