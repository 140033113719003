import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "src/app/shared/shared.module";

import {
  StaffManagementComponent,
  StaffCertificationsComponent
} from './';
import { CreateUpdateStaffComponent } from './create-update-staff/create-update-staff.component';
import { StaffAddCertificationModalComponent } from './staff-add-certification-modal/staff-add-certification-modal.component';

@NgModule({
  declarations: [StaffManagementComponent, StaffCertificationsComponent, CreateUpdateStaffComponent, StaffAddCertificationModalComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class StaffModule { }
