<!-- Permissions -->
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursListOwnAssign" #timeEntryInvestigationHoursListOwnAssign="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageListOwnAssign" #expenseInvestigationMileageListOwnAssign="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesListOwnAssign" #expenseInvestigationExpensesListOwnAssign="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursListAll" #timeEntryInvestigationHoursListAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesListAll" #expenseInvestigationExpensesListAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageListAll" #expenseInvestigationMileageListAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursCreateOwnAssign" #timeEntryInvestigationHoursCreateOwnAssign="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesCreate" #expenseInvestigationExpensesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageCreateOwnAssign" #expenseInvestigationMileageCreateOwnAssign="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursCreate" #timeEntryInvestigationHoursCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageCreate" #expenseInvestigationMileageCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesCreate" #expenseInvestigationExpensesCreate="appEnforcePermission"></div>
<!-- Permissions -->
<!-- All Permissions That Are Not "ALL" -->
<div class="time-exp-header">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']" class="header-raw">
    <kendo-gridlayout-item class="mb-0 time-exp-title" >
        <h3 *ngIf="(timeEntryInvestigationHoursListOwnAssign?.enabled || timeEntryInvestigationHoursCreateOwnAssign?.enabled || timeEntryInvestigationHoursListAll?.enabled)" class="mb-0 ml-24">Time & Expense</h3>
        <p class="ml-24 mt-5">{{ investigation?.billingInstructions || '' }}</p>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</div>
<ng-template #nonAdmin>
  <hr/>
  <section class="template-form">
    <!-- Hours -->
    <section *ngIf="timeEntryInvestigationHoursListOwnAssign?.enabled || timeEntryInvestigationHoursCreateOwnAssign?.enabled">
      <kendo-gridlayout class="panel-header" [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item style="background:#FFFFFF;">
          <h2 class="ml-24">Hours</h2>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item class="mb-0" *ngIf="timeEntryInvestigationHoursCreateOwnAssign?.enabled"
          style="background: linear-gradient(0deg, rgba(1, 24, 98, 0.02), rgba(1, 24, 98, 0.02)), #FFFFFF; padding: 10px">
          <kendo-card class="kendocard">
            <kendo-card-header class="pagetitle">
              Add Hours
            </kendo-card-header>
            <kendo-card-body>
              <app-ind-time-form-kendo [investigationView]="true" (formSubmitted)="addTime($event)"
                [timeEntry]="timeEntry">
              </app-ind-time-form-kendo>
            </kendo-card-body>
          </kendo-card>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="mb-0">
          <div class="equal staff" [appEnforcePermission]="authConfig.expenseInvestigationExpensesAllAssigned"
          #allOnAssign="appEnforcePermission">
          <mat-form-field appearance="legacy">
            <mat-label>Staff</mat-label>
            <mat-select [value]="staffMember" (selectionChange)="setUser($event)">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let user of staffSort(investigation?.InvestigationStaff)" [value]="user.User.id">
                {{user.User | fullName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
          <app-time-exp-hours-table-kendo *ngIf="timeEntryInvestigationHoursListOwnAssign?.enabled" [investigationId]="investigation?.id" [investigationView]="true" [refresh]="refreshHour"
            [employeeFilter]="employeeFilter" (changedFilter)="changedFilter($event)" [displayAllOption]="true"></app-time-exp-hours-table-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </section>

    <!-- Mileage -->
    <section *ngIf="expenseInvestigationMileageListOwnAssign?.enabled || expenseInvestigationMileageCreateOwnAssign?.enabled">
      <kendo-gridlayout class="panel-header" [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item style="background:#FFFFFF;">
          <h2 class="ml-24">Mileage</h2>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item *ngIf="expenseInvestigationMileageCreateOwnAssign?.enabled" class="mb-0" style="background: linear-gradient(0deg, rgba(1, 24, 98, 0.02), rgba(1, 24, 98, 0.02)), #FFFFFF; padding: 10px">
          <kendo-card class="kendocard">
            <kendo-card-header class="pagetitle">
              Add Mileage
            </kendo-card-header>
            <kendo-card-body>
              <app-ind-mileage-form-kendo #timeExpenseForm [investigationView]="true" [mileage]="mileageForm"
                [investigations]="investigations" [userId]="staffMember" (formSubmitted)="addExpense($event, true)">
              </app-ind-mileage-form-kendo>
            </kendo-card-body>
          </kendo-card>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="mb-0">
          <div class="equal staff" [appEnforcePermission]="authConfig.expenseInvestigationExpensesAllAssigned"
            #allOnAssign="appEnforcePermission">
            <mat-form-field appearance="legacy">
              <mat-label>Staff</mat-label>
              <mat-select [value]="staffMember" (selectionChange)="setUser($event)">
                <mat-option>All</mat-option>
                <mat-option *ngFor="let user of staffSort(investigation?.InvestigationStaff)" [value]="user.User.id">
                  {{user.User | fullName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <app-time-exp-expenses-table-kendo *ngIf="expenseInvestigationMileageListOwnAssign?.enabled" [expenseItems]="expenseItems" [investigationView]="true" [investigationId]="investigation?.id"
            [isMileage]="true" [userId]="''" [reloadExpense]="reloadExpense" [rangerAndWeekFilters]="rangerAndWeekFilters"></app-time-exp-expenses-table-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </section>

    <!-- Expenses -->
    <section *ngIf="expenseInvestigationExpensesListOwnAssign?.enabled || expenseInvestigationExpensesCreate?.enabled">
      <kendo-gridlayout class="panel-header" [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item style="background:#FFFFFF;">
          <h2 class="ml-24">Expenses</h2>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item *ngIf="expenseInvestigationExpensesCreate?.enabled" style="background: linear-gradient(0deg, rgba(1, 24, 98, 0.02), rgba(1, 24, 98, 0.02)), #FFFFFF; padding: 8px">
          <kendo-card class="kendocard">
            <kendo-card-header class="pagetitle">
              Add Expense
            </kendo-card-header>
            <kendo-card-body>
              <app-ind-expense-form-kendo #timeExpenseForm [investigationView]="true" [expense]="expenseEntry"
                [expenseItems]="expenseItems" [investigations]="investigations" [userId]="staffMember"
                (formSubmitted)="addExpense($event)">
              </app-ind-expense-form-kendo>
            </kendo-card-body>
          </kendo-card>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <div class="equal staff" [appEnforcePermission]="authConfig.expenseInvestigationExpensesAllAssigned"
            #allOnAssign="appEnforcePermission">
            <mat-form-field appearance="legacy">
              <mat-label>Staff</mat-label>
              <mat-select [value]="staffMember" (selectionChange)="setUser($event)">
                <mat-option>All</mat-option>
                <mat-option *ngFor="let user of staffSort(investigation?.InvestigationStaff)" [value]="user.User.id">
                  {{user.User | fullName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <app-time-exp-expenses-table-kendo *ngIf="expenseInvestigationExpensesListOwnAssign?.enabled" [expenseItems]="expenseItems" [investigationView]="true" [investigationId]="investigation?.id"
            [isMileage]="false" [userId]="''" [reloadExpense]="reloadExpense" [rangerAndWeekFilters]="rangerAndWeekFilters"></app-time-exp-expenses-table-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </section>
  </section>
</ng-template>

<!-- Permision "ALL" Section -->
<!-- All only -->
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesAll" #adminPerm="appEnforcePermission">
  <section class="mat-typography p-4" *ngIf="adminPerm.enabled; else nonAdmin">

    <!-- Create Invoice Button -->
    <div class="my-3">
      <div class="dflex justify-content-end align-items-center">
        <span class="selectedrecord mr-16" *ngIf="totalselectedRaw > 0">
          {{totalselectedRaw}} {{totalselectedRaw > 1 ? 'entries' : 'entry'}} selected
        </span>
        <button
          kendoButton
          [size]="'medium'"
          themeColor="primary"
          fillMode="solid"
          iconClass="fas fa-plus"
          class="mr-8"
          (click)='openQbDialog()'
          [disabled]="totalselectedRaw === 0"
        >
        Create Invoice
        </button>
      </div>
    </div>

    <!-- Outstanding Entries -->
    <!-- Header Row  -->
    <div class="">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']" class="header-raw">
        <div class="dflex align-items-center">
          <h3 *ngIf="timeEntryInvestigationHoursListAll?.enabled" class="mb-0 ml-24">Outstanding Entries</h3>
        </div>
        <kendo-gridlayout-item class="filter-actions mb-8 mt-8" >
          <div class="kendo-formfield width250 mr-24">
            <kendo-dropdownlist
              [data]="staffSort(investigation?.InvestigationStaff)"
              textField="userName"
              valueField="userId"
              fillMode="outline"
              [valuePrimitive]="true"
              [defaultItem]="{ userId: null, userName: 'Select staff' }"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250
              }"
              (selectionChange)="setUser($event?.userId)"
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem?.userName === 'Select staff'" class="placeholder">
                  {{ dataItem?.userName }}
                </span>
                <span
                  *ngIf="dataItem?.userName !== 'Select staff'"
                  class="not-placeholder"
                >
                  {{ dataItem?.userName }}
                </span>
              </ng-template>
            </kendo-dropdownlist>
          </div>
          <!-- Show Date Entered Button -->
          <div class="d-flex align-items-center mr-24 width300">
            <input
              type="checkbox"
              #showAll
              kendoCheckBox
              [(ngModel)]="showDateEntered"
              [ngModelOptions]="{ standalone: true }"
            />
            <kendo-label
              class="k-checkbox-filter-label ml-12"
              [for]="showAll"
              text="Show Date Entered"
            ></kendo-label>
          </div>
          <!-- Add Entry Button -->
          <div class="mr-24" *ngIf="timeEntryInvestigationHoursCreate?.enabled || expenseInvestigationMileageCreate?.enabled || expenseInvestigationExpensesCreate?.enabled">
            <kendo-dropdownbutton
              [data]="addEntryMenu"
              themeColor="light"
              fillMode="solid"
              buttonClass="grid-action-items"
              iconClass="fa-regular fa-plus"
              (itemClick)="itemSelected($event)"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250,
                popupClass: 'grid-action-items-menu mt-12'
              }"
            >
              Add Entry
            </kendo-dropdownbutton>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </div>

     <!-- Outstanding Entries Table -->
    <app-outstanding-admin
      *ngIf="timeEntryInvestigationHoursListAll?.enabled"
			[timeVisible]="timeVisible"
      [showDateEntered]="showDateEntered"
			[pageTitle]="'Hours'"
			[pageOptions]="timePageOptions"
			[clearSelection]="clearSelection"
			[(selectedTime)]="selectedTime"
			[type]="'time'"
			(sortChange)="sortChange($event)"
			(pageChange)="pageChange($event)"
			(removeEntry)="removeEntry($event)"
			(editEntry)="editTime($event)"
		>
    </app-outstanding-admin>
    <app-outstanding-admin
      *ngIf="expenseInvestigationMileageListAll?.enabled || expenseInvestigationExpensesListAll?.enabled"
      [timeVisible]="expensesVisible"
      [showDateEntered]="showDateEntered"
      [clearSelection]="clearSelection"
      [(selectedExpenses)]="selectedExpenses"
      [pageTitle]="'Expenses & Mileage'"
      [pageOptions]="expensesPageOptions"
      [type]="'expenses-mileage'"
      (sortChange)="sortExpenceChange($event)"
      (pageChange)="pageExpenceChange($event)"
      (removeEntry)="removeEntry($event)"
      (editEntry)="editExpense($event)"
    ></app-outstanding-admin>

    <!-- Evidence Section -->
    <div class="mt-24">
      <app-evidence-table
        [investigation]="investigation"
        [evidenceExpenses]="evidenceExpenses"
        [pageTitle]="'Evidence'"
        (addEvidenceExpense)="addEvidenceExpense()"
      >
      </app-evidence-table>
    </div>

    <br>

    <!-- Outstanding Entries -->
    <!-- Header Row  -->
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2>Invoiced Entries</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- Invoices Table -->
      <h4>Invoices</h4>
      <div class="flexContainer">
        <table mat-table matSort [dataSource]="invoices">

          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry.createdAt | customDate: 'MM/dd/yyyy' }} </mat-cell>
            <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="desc">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{entry.details}} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ calculateAmount(entry) | currency }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="invoicedBy">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Invoiced by </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{entry?.createdBy?.firstName}} {{entry?.createdBy?.lastName}}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="invoice_num">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Invoice # </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry.invoiceNumber }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="qbErrors">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Import Errors </mat-header-cell>
            <mat-cell *matCellDef="let entry">
              <mat-accordion>
                <mat-expansion-panel *ngFor="let error of entry.QbErrors">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <strong [innerHtml]="error.statusCode"></strong>
                    </mat-panel-title>
                    <!-- <mat-panel-description></mat-panel-description> -->
                  </mat-expansion-panel-header>
                  <p [innerHtml]="error.statusMessage"></p>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="qbSubmitted">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Exported? </mat-header-cell>
            <mat-cell *matCellDef="let entry">
              <strong *ngIf="entry.qbExported && !entry.QbErrors.length" class="text-success">Complete</strong>
              <strong
                *ngIf="(!entry.qbExported && !entry.qbTicketId) || (!entry.qbExported && entry.qbTicketId && !entry.QbErrors.length)"
                class="text-warning">Pending</strong>
              <strong *ngIf="entry.qbTicketId && entry.QbErrors.length" class="text-danger">Failed</strong>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef mat-sort-header> &nbsp; </mat-header-cell>
            <mat-cell *matCellDef="let entry">
              <ng-container *ngIf="!entry.qbExported">
                <button mat-icon-button (click)="resubmitInvoice(entry)">
                  <mat-icon>refresh</mat-icon>
                </button>
                <button mat-icon-button (click)="openQbDialog(entry)">
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-container>
              <button mat-icon-button (click)="deleteInvoice(entry)">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-icon-button (click)="openQbDialog(entry, true)">
                <mat-icon color="accent">list_alt</mat-icon>
              </button>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <!-- Header and Row Declarations -->
          <mat-header-row *matHeaderRowDef="invoiceColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: invoiceColumns"></mat-row>
          <mat-footer-row class="totals-row" mat-footer-row *matFooterRowDef="invoiceColumns"></mat-footer-row>
        </table>

        <!-- Invoices Paginator -->
        <mat-paginator [length]="invoicesPageOptions.totalCount" [pageSize]="invoicesPageOptions.limit"
          [pageSizeOptions]="[5, 10, 25, 100]" (page)="invoicesPageOptions.paginate($event)"></mat-paginator>
      </div>

      <div>
        <mat-form-field appearance="legacy">
          <mat-label>Staff</mat-label>
          <mat-select [value]="invoiceStaffMember"
            (selectionChange)="filterInvoice(IApiInvoiceItemFilterType.Staff, $event.value)">
            <mat-option>All</mat-option>
            <mat-option *ngFor="let user of staffSort(investigation?.InvestigationStaff)" [value]="user.User.id">
              {{user.User | fullName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="ml-3">
          <mat-label>Type / Expense</mat-label>
          <mat-select (selectionChange)="filterInvoice(IApiInvoiceItemFilterType.Type, $event.value)">
            <mat-option [value]="null">All</mat-option>
            <mat-option *ngFor="let type of timeExpenseTypeFilter" [value]="type.id">
              {{type.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="ml-3">
          <mat-label>Invoice #</mat-label>
          <input matInput [(ngModel)]="invoiceNumFilter" name="invoiceNumber"
            (appDebouncedInputChange)="filterInvoice(IApiInvoiceItemFilterType.Invoice, $event)" />
        </mat-form-field>
      </div>

      <h4>Invoiced Items</h4>
      <div class="flexContainer">
        <table mat-table matSort [dataSource]="invoiceItems">

          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{entry?.Expense ? (entry?.Expense?.expenseDate | customDate:
              'MM/dd/yyyy' ) : (entry?.TimeEntry?.workday | customDate: 'MM/dd/yyyy')}}</mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="staff">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Staff </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry?.Expense ? (entry?.Expense?.User | fullName) :
              (entry?.TimeEntry?.User | fullName) }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Type/Expense </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry?.Expense ? (entry?.Expense?.ExpenseItem.name) :
              (entry?.TimeEntry?.Type.name) }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="purpose">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Purpose </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry?.Expense ? (entry?.Expense?.ExpensePurpose?.name) : "" }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="billable">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Billable </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry?.Expense ? (entry?.Expense?.billableQuantity) :
              (entry?.TimeEntry?.hours) }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="non_billable">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Non-Billable </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry?.Expense ? (entry?.Expense?.nonBillableQuantity) :
              (entry?.TimeEntry?.nonBillableHours) }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry?.Expense ? (entry?.Expense?.description) :
              (entry?.TimeEntry?.description) }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="paid">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Paid </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry?.Expense ? (entry?.Expense?.paidDate | customDate: 'MM/dd/yyyy')
              : (entry?.TimeEntry?.paidDate ? (entry?.TimeEntry?.paidDate | customDate: 'MM/dd/yyyy') : '') }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="approval">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Approval </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry?.Expense ? (entry?.Expense?.authorizedDate) : "" }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="invoice_num">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Invoice # </mat-header-cell>
            <mat-cell *matCellDef="let entry"> {{ entry?.Invoice?.invoiceNumber }} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
            <mat-cell *matCellDef="let entry">
              <button mat-icon-button (click)="openDialog(entry?.Expense?.id ? entry.Expense : entry.TimeEntry, true)">
                <mat-icon color="accent">edit</mat-icon>
              </button>
              <button mat-icon-button (click)="openQbDialog(entry?.Invoice, true)">
                <mat-icon color="accent">list_alt</mat-icon>
              </button>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <!-- Header and Row Declarations -->
          <mat-header-row *matHeaderRowDef="invoiceItemColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: invoiceItemColumns"></mat-row>
          <mat-footer-row class="totals-row" mat-footer-row *matFooterRowDef="invoiceItemColumns"></mat-footer-row>
        </table>

        <!-- Invoiced Items Paginator -->
        <mat-paginator [length]="invoiceItemsPageOptions.totalCount" [pageSize]="invoiceItemsPageOptions.limit"
          [pageSizeOptions]="[5, 10, 25, 100]" (page)="invoiceItemsPageOptions.paginate($event)"></mat-paginator>
      </div>
    </mat-expansion-panel>
  </section>
</div>