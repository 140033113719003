import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiAddTimeEntryInput, IApiApproveTimeEntryInput, IApiMarkAllPaidTimeEntryInput, IApiTimeEntryConnection, IApiTimeEntryFilter, IApiTimeEntryTotal, IApiTimeEntryTotalByTypeSummary, IApiUpdateTimeEntryInput } from './../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces/base.interfaces';
import { GqlTimeEntryService } from "../../modules/graphql/services";

@Injectable()
export class TimeEntryService {

  constructor(
    private gqlTimeEntryService: GqlTimeEntryService
  ) { }

  public get(filters: IApiTimeEntryFilter[], options?: IGqlBaseOptions): Observable<IApiTimeEntryConnection> {
    return this.gqlTimeEntryService.getTimeEntries(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddTimeEntryInput) {
    return this.gqlTimeEntryService.addTimeEntry(input);
  }

  public approve(input: IApiApproveTimeEntryInput) {
    return this.gqlTimeEntryService.approveTimeEntry(input);
  }

  public update(input: IApiUpdateTimeEntryInput) {
    return this.gqlTimeEntryService.updateTimeEntry(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlTimeEntryService.removeTimeEntry(id);
  }

  public markAllPaid(input: IApiMarkAllPaidTimeEntryInput): Observable<boolean> {
    return this.gqlTimeEntryService.markAllPaid(input);
  }


  public getTimeEntryTotal(filters?: IApiTimeEntryFilter[], options?: IGqlBaseOptions): Observable<IApiTimeEntryTotal> {
    return this.gqlTimeEntryService.getTimeEntryTotal(filters, options || new ListPage());
  }

  public geTtimeEntryTotalByType(filters?: IApiTimeEntryFilter[], options?: IGqlBaseOptions): Observable<IApiTimeEntryTotalByTypeSummary> {
    return this.gqlTimeEntryService.geTtimeEntryTotalByType(filters, options || new ListPage());
  }

}
