import { Injectable } from '@angular/core';
import { GqlInvestigationMarkCloseService } from 'src/app/shared/modules/graphql/services';
import { IApiAddInvestigationMarkCloseInput } from 'src/app/shared/modules/graphql/types/types';


@Injectable()
export class InvestigationMarkCloseService {

  constructor(
    private gqlInvestigationMarkCloseService: GqlInvestigationMarkCloseService
  ) { }

  public add(input: IApiAddInvestigationMarkCloseInput) {
    return this.gqlInvestigationMarkCloseService.addInvestigationMarkClose(input);
  }

}
