<div [dialogRef]="dialog" (appClickOutside)="close(false)">
  <kendo-dialog-titlebar>
    <div class="modal-title ml-8">
      <span>{{data?.title}}</span>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">
    <kendo-gridlayout>
      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-textarea name="notes" #notes="ngModel" [(ngModel)]="data.notes" placeholder="Enter Comment"
            fillMode="outline" rows="6" resizable="both" appNoWhiteSpace></kendo-textarea>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </div>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-16" (click)="close(false)">Cancel</button>
    <button kendoButton *ngIf="modified" [size]="'medium'" themeColor="secondary" class="mr-16" (click)="revert()">Undo</button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="close(data.notes)">Save</button>
  </kendo-dialog-actions>
</div>
