<div class="container-fluid typo content-part">
<!-- Headings -->

<kendo-card width="100%">
<h1>Heading 1 <small>Small Heading 1</small></h1>
<!-- <h1 class="darkhead">Heading 1 <small>Small Heading 1</small></h1> -->

<h2>Heading 2 <small>Small Heading 2</small></h2>
<!-- <h2 class="darkhead">Heading 2 <small>Small Heading 2</small></h2> -->

<h6>Heading 6 <small>Small Heading 6</small></h6>
<h6 class="fs-12">Small Heading <small>Smaller Heading</small></h6>

<!-- <h6 class="darkhead">Heading 6 <small>Small Heading 6</small></h6> -->
<hr />
<h1>Hyperlinks</h1>
<a>Default Hyperlink</a>
<br />
<a class="linkmd">Hyperlink medium</a>
<br />
<a class="linkmd"><u>Hyperlink medium with underline</u></a>
<br />
<a class="linksm">Hyperlink small</a>
<br />
<a class="linksm"><u>Hyperlink small with underline</u></a>

<hr />

<!--Paragraphs-->
<p>
  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <b>
    Lorem Ipsum has been the industry's standard dummy text ever since the
    1500s,</b
  >
  when an unknown printer took a galley of type and scrambled it to make a
  type specimen book. It has survived not only five centuries, but also the
  leap into electronic typesetting, remaining essentially unchanged. It was
  popularised in the 1960s with the release of Letraset sheets containing
  Lorem Ipsum passages, and more recently with desktop publishing software
  like Aldus PageMaker including versions of Lorem Ipsum.
</p>
<br />
<p class="secondary">
  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <b>
    Lorem Ipsum has been the industry's standard dummy text ever since the
    1500s,</b
  >
  when an unknown printer took a galley of type and scrambled it to make a
  type specimen book. It has survived not only five centuries, but also the
  leap into electronic typesetting, remaining essentially unchanged. It was
  popularised in the 1960s with the release of Letraset sheets containing
  Lorem Ipsum passages, and more recently with desktop publishing software
  like Aldus PageMaker including versions of Lorem Ipsum.
</p>
</kendo-card>

<kendo-card width="100%">
<h1>Buttons</h1>
<br />
<div class="d-flex align-center">
  <button kendoButton [size]="'medium'" themeColor="primary" class="mr-10">
    Primary Button
  </button>

  <button
    kendoButton
    [size]="'medium'"
    themeColor="primary"
    iconClass="fas fa-plus"
    class="mr-10"
  >
    Icon Primary Button
  </button>

  <button kendoButton themeColor="light" fillMode="solid" class="mr-10">
    Secondary Button
  </button>

  <button
    kendoButton
    themeColor="light"
    fillMode="solid"
    iconClass="fas fa-plus"
    class="mr-10"
  >
    Icon Secondary Button
  </button>

  <button
    kendoButton
    themeColor="light"
    fillMode="solid"
    iconClass="fa-solid fa-arrow-up-right-from-square"
    class="mr-10"
  >
    Secondary Button Icon
  </button>
</div>

<br />
<div class="d-flex align-center">
  <button
    kendoButton
    themeColor="error"
    fillMode="solid"
    iconClass="fas fa-plus"
    class="mr-10"
  >
    Error Button
  </button>

  <button
    kendoButton
    size="large"
    themeColor="base"
    fillMode="solid"
    class="mr-10"
  >
    Light Secondary Button
  </button>

  <button
    kendoButton
    themeColor="base"
    fillMode="solid"
    icon="plus-circle"
    class="mr-10"
  >
    Light Secondary Button
  </button>

  <button
    kendoButton
    icon="plus-circle"
    themeColor="primary"
    fillMode="link"
    class="mr-10"
  >
    Text Button
  </button>

  <button kendoButton themeColor="primary" fillMode="link" class="mr-10">
    Icon Text Button
  </button>
</div>
</kendo-card>

<kendo-card width="100%" class="form-fields">
<h1>Form Fields</h1>
<kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
  <kendo-gridlayout-item class="d-flex">
    <kendo-formfield class="flex-1">
      <kendo-label class="asterisk" [for]="firstName" text="Input label"
        ><span
          class="tooltip-with-icon"
          kendoTooltip
          position="top"
          title="Full claim number provided by your insurer. If unknown add a '-'"
        >
          <i class="fa fa-question-circle" aria-hidden="true"></i> </span
      ></kendo-label>
      <kendo-textbox
        #firstName
        formControlName="firstName"
        placeholder="Helper text"
        fillMode="outline"
      ></kendo-textbox>
    </kendo-formfield>
  </kendo-gridlayout-item>
  <kendo-gridlayout-item class="d-flex">
    <kendo-formfield class="flex-1">
      <kendo-label class="asterisk" text="Dropdown List"></kendo-label>
      <kendo-dropdownlist
        textField="text"
        valueField="value"
        formControlName="lossState"
        fillMode="outline"
        [data]="areaList"
        [popupSettings]="{ appendTo: 'component', animate: false }"
        show
      >
        <ng-template kendoDropDownListGroupTemplate let-groupName>
          <strong>{{ groupName }}</strong>
        </ng-template>
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <span class="placeholder"> Dropdown placeholder </span>
        </ng-template>
      </kendo-dropdownlist>
    </kendo-formfield>
    <kendo-formfield class="flex-1">
      <kendo-label
        class="asterisk"
        [for]="firstName"
        text="Input label"
      ></kendo-label>
      <kendo-datepicker
        calendarType="classic"
        [fillMode]="'outline'"
        placeholder="Enter date"
        format="mm/dd/yyyy"
        formatPlaceholder="formatPattern"
      ></kendo-datepicker>
      <!-- <kendo-calendar
activeView="decade"
[(value)]="value"
></kendo-calendar> -->
    </kendo-formfield>
  </kendo-gridlayout-item>
  <kendo-gridlayout-item class="d-flex">
    <kendo-formfield class="flex-1">
      <kendo-label [for]="firstName" text="Input label"></kendo-label>
      <kendo-textbox
        #firstName
        formControlName="firstName"
        placeholder="Helper text"
        fillMode="outline"
      ></kendo-textbox>
      <span
        _ngcontent-vfh-c980=""
        id="kendo-hint-0"
        class="k-form-hint kendo-formhint k-text-start ng-star-inserted"
        >Your email address must use a corporate email address that is for
        your sole use.</span
      >
    </kendo-formfield>

    <kendo-formfield class="flex-1 k-form-field-error">
      <kendo-label
        class="asterisk"
        [for]="firstName"
        text="Input label"
      ></kendo-label>
      <kendo-textbox
        #firstName
        formControlName="firstName"
        placeholder="Helper text"
        fillMode="outline"
        class="ng-invalid"
      ></kendo-textbox>
      <span role="alert" class="k-form-error k-text-start"
        ><i aria-hidden="true" class="fas fa-exclamation-triangle"></i
        ><span class="ng-star-inserted">Error Text</span></span
      >
    </kendo-formfield>

    <kendo-formfield class="flex-1">
      <kendo-label
        class="asterisk"
        [for]="firstName"
        text="Input label"
      ></kendo-label>
      <kendo-textbox
        #firstName
        formControlName="firstName"
        placeholder="Helper text"
        fillMode="outline"
        class="ng-invalid"
      ></kendo-textbox>
    </kendo-formfield>
  </kendo-gridlayout-item>
  <kendo-gridlayout-item class="d-flex">
    <kendo-formfield class="flex-1">
      <kendo-label class="asterisk" [for]="firstName" text="Input label">
      </kendo-label>
      <kendo-textbox
        #firstName
        formControlName="firstName"
        placeholder="Helper text"
        fillMode="outline"
      ></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield class="flex-1">
      <kendo-label
        class="asterisk"
        [for]="firstName"
        text="Input label"
      ></kendo-label>
      <kendo-textbox
        #firstName
        formControlName="firstName"
        placeholder="Helper text"
        fillMode="outline"
      ></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield class="flex-1">
      <kendo-label
        class="asterisk"
        [for]="firstName"
        text="Input label"
      ></kendo-label>
      <kendo-textbox
        #firstName
        formControlName="firstName"
        placeholder="Helper text"
        fillMode="outline"
      ></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield class="flex-1">
      <kendo-label
        class="asterisk"
        [for]="firstName"
        text="Input label"
      ></kendo-label>
      <kendo-textbox
        #firstName
        formControlName="firstName"
        placeholder="Helper text"
        fillMode="outline"
      ></kendo-textbox>
    </kendo-formfield>
  </kendo-gridlayout-item>
</kendo-gridlayout>
</kendo-card>
<kendo-card width="100%">
<h1>Badges</h1>
<hr />
<kendo-gridlayout
  [rows]="['auto']"
  [gap]="{ rows: 5, cols: 16 }"
  [cols]="['auto']"
>
  <h5>Small Badges</h5>
  <kendo-gridlayout-item>
    <kendo-badge
      class="fw600 mr-16 Low"
      position="none"
      size="small"
      rounded="full"
      themeColor="tertiary"
      >Low</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 Medium"
      position="none"
      size="small"
      rounded="full"
      themeColor="info"
    >
      <i class="fa fa-plus" aria-hidden="true"></i> &nbsp;&nbsp;
      Primary</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 high"
      position="none"
      size="small"
      rounded="full"
      themeColor="success"
      >Success</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 high"
      position="none"
      size="small"
      rounded="full"
      themeColor="error"
      >Error</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 high"
      position="none"
      size="small"
      rounded="full"
      themeColor="warning"
      >Warning</kendo-badge
    >
  </kendo-gridlayout-item>
  <h5>Medium Badges</h5>
  <kendo-gridlayout-item>
    <kendo-badge
      class="fw600 mr-16 Low"
      position="none"
      size="medium"
      rounded="full"
      themeColor="tertiary"
      >Low</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 Medium"
      position="none"
      size="medium"
      rounded="full"
      themeColor="info"
    >
      <i class="fa fa-plus" aria-hidden="true"></i> &nbsp;
      Primary</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 high"
      position="none"
      size="medium"
      rounded="full"
      themeColor="success"
      >Success</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 high"
      position="none"
      size="medium"
      rounded="full"
      themeColor="error"
      >Error</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 high"
      position="none"
      size="medium"
      rounded="full"
      themeColor="warning"
      >Warning</kendo-badge
    >
  </kendo-gridlayout-item>
  <h5>Large Badges</h5>
  <kendo-gridlayout-item>
    <kendo-badge
      class="fw600 mr-16 Low"
      position="none"
      size="large"
      rounded="full"
      themeColor="tertiary"
      >Low</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 Medium"
      position="none"
      size="large"
      rounded="full"
      themeColor="info"
    >
      <i class="fa fa-plus" aria-hidden="true"></i> &nbsp;
      Primary</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 high"
      position="none"
      size="large"
      rounded="full"
      themeColor="success"
      >Success</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 high"
      position="none"
      size="large"
      rounded="full"
      themeColor="error"
      >Error</kendo-badge
    >
    <kendo-badge
      class="fw600 mr-16 high"
      position="none"
      size="large"
      rounded="full"
      themeColor="warning"
      >Warning</kendo-badge
    >
  </kendo-gridlayout-item>
</kendo-gridlayout>
</kendo-card>
<kendo-card width="100%">
<h1>Tab States</h1>
<kendo-tabstrip (tabSelect)="onTabSelect($event)">
  <kendo-tabstrip-tab
    title="Paris"
    [selected]="true"
    icon="fa-solid fa-user"
  >
    <ng-template kendoTabContent>
      <div class="content mt-24">
        <h2>Tabstrip Header</h2>
        <p>Tabstrip content</p>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="New York City">
    <ng-template kendoTabContent>
      <div class="content">
        <h2>Tabstrip Header</h2>
        <p>Tabstrip content</p>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Tallinn">
    <ng-template kendoTabContent>
      <div class="content">
        <h2>Tabstrip Header</h2>
        <p>Tabstrip content</p>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="London">
    <ng-template kendoTabContent>
      <div class="content">
        <h2>Tabstrip Header</h2>
        <p>Tabstrip content</p>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
</kendo-card>
<!-- <kendo-card width="100%"> -->
<kendo-grid
  [kendoGridBinding]="gridView"
  kendoGridSelectBy="id"
  [pageSize]="20"
  [pageable]="true"
  [sortable]="true"
  [height]="500"
  class="rcheck"
>
<kendo-grid-checkbox-column
    [width]="45"
    [resizable]="false"
    [columnMenu]="false"
    [showSelectAll]="true"
></kendo-grid-checkbox-column>
<kendo-grid-column field="date" title="Date" [width]="160">
</kendo-grid-column>
  
  <kendo-grid-column field="idd" title="ID" [width]="160">
    <u></u>
  </kendo-grid-column>
  <kendo-grid-column field="full_name" title="Contact Name" [width]="220">
  </kendo-grid-column>
  <kendo-grid-column field="location" title="Location" [width]="230">
  </kendo-grid-column>
  <kendo-grid-column field="assigned" title="Assigned" [width]="240">
  </kendo-grid-column>
  
  
  <kendo-grid-column
    field="is_online"
    title="Status"
    [width]="120"
    [class]="{'text-left': true}"
    [resizable]="false"
    filter="boolean"
>
  <ng-template kendoGridCellTemplate let-dataItem>
    <kendo-badge
    class="fw600 mr-16 Low"
    position="none"
    size="small"
    rounded="full"
    themeColor="tertiary"
    *ngIf="dataItem.is_online === true"
    >Low</kendo-badge
  >
    <kendo-badge
    class="fw600 mr-16 High"
    position="none"
    size="small"
    rounded="full"
    themeColor="error"
    *ngIf="dataItem.is_online === false"
    >High</kendo-badge
    >
  </ng-template>
</kendo-grid-column>
<kendo-grid-column
  field="action"
  title="Action"
  [width]="150"
  [sortable]="false"
  [headerClass]="'nocursor'"
>
<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
  <button
  kendoButton
  themeColor="primary"
  fillMode="link"
  
>
  Button
</button>
</ng-template>
</kendo-grid-column>
<kendo-grid-column
  field="icon"
  title="Icon Only"
  [width]="80"
  [sortable]="false"
  [headerClass]="'nocursor'"
 
>
<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
  <i class="fa-sharp fa-solid fa-circle-check success"  kendoTooltip position="bottom" title="mark as Complete"  tooltipClass="tooltipwidthauto"></i>
</ng-template>
</kendo-grid-column>
<kendo-grid-column
  field="action"
  title="Action"
  [width]="200"
  [sortable]="false"
  [headerClass]="'nocursor'"
>
<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
  <button kendoButton kendoTooltip position="top" title="Edit"  tooltipClass="tooltipwidthauto" class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil"> </button>
  <button kendoButton kendoTooltip position="left" title="Copy" tooltipClass="tooltipwidthauto" class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-regular fa-copy"></button>
  <button kendoButton kendoTooltip position="right" title="Delete" tooltipClass="tooltipwidthauto" themeColor="error" fillMode="link" iconClass="fa-solid fa-trash"></button>
</ng-template>
</kendo-grid-column>
<kendo-grid-column field="Action" title="Action" [sortable]="false" [headerClass]="'nocursor'">
  <ng-template kendoGridCellTemplate let-dataItem>
    <kendo-dropdownbutton
      [data]="[{text: 'Menu 1'}, {text: 'Menu 2'}, {text: 'Menu 3'}, {text: 'Menu 4'}]"
      themeColor="primary"
      fillMode="link"
      buttonClass="grid-action-items"
      iconClass="fa-solid fa-ellipsis"
      (itemClick)="itemSelected($event, dataItem)"
      [popupSettings]="{
        appendTo: 'component',
        animate: false,
        width: 250,
        popupClass: 'grid-action-items-menu'
      }"
    >
    </kendo-dropdownbutton>
  </ng-template>
</kendo-grid-column>
</kendo-grid>
<!-- </kendo-card> -->
</div>
