import { IApiPermissionCategory, } from '../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlPermissionCategoryService } from "src/app/shared/modules/graphql/services";

@Injectable()
export class PermissionCategoryService {

  constructor(
    private categories: GqlPermissionCategoryService,
  ) { }

  public get(): Observable<IApiPermissionCategory[]> {
    return this.categories.getPermissionCategories();
  }
}
