import { connectionQuery } from './../../../helpers.class';
import { IApiDocumentTemplateConnection } from './../../../types/types';
import { IApiUpdateDocumentTemplateInput, IApiAddDocumentTemplateInput, IApiDocumentTemplateFilter } from '../../../types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import { IApiDocumentTemplate } from "../../../types/types";
import { IGqlBaseOptions } from "../../../interfaces";

import { documentTemplateConnectionQuery, addDocumentTemplateMutation, updateDocumentTemplateMutation, removeDocumentTemplateMutation } from "./document-template.operations";
import { safeResolve } from "../../../rxjs";

@Injectable()
export class GqlDocumentTemplateService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getTemplates(filters: IApiDocumentTemplateFilter[], options: IGqlBaseOptions): Observable<IApiDocumentTemplateConnection> {
    return this.apollo.query(connectionQuery(documentTemplateConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addTemplate(input: IApiAddDocumentTemplateInput): Observable<IApiDocumentTemplate> {
    return this.apollo.mutate({
      mutation: addDocumentTemplateMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateTemplate(input: IApiUpdateDocumentTemplateInput): Observable<IApiDocumentTemplate> {
    return this.apollo.mutate({
      mutation: updateDocumentTemplateMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeTemplate(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeDocumentTemplateMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
