import { GqlAddressService } from 'src/app/shared/modules/graphql/services/address/address.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IAddressResults } from 'src/app/shared/interfaces/address.interfaces';
import {
  IApiAddAddressInput,
  IApiAddressFilter,
  IApiUpdateAddressInput,
  IApiRemoveAddressInput,
  IApiRemoveAddressPayload
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class AddressService {

  constructor(
    private gqlAddressService: GqlAddressService
  ) { }

  public getAddress(filters: IApiAddressFilter[], options: IGqlBaseOptions): Observable<IAddressResults> {
    return this.gqlAddressService.getAddresses(filters, options);
  }

  public addAddress(input: IApiAddAddressInput) {
    return this.gqlAddressService.addAddress(input);
  }

  public removeAddress(input: IApiRemoveAddressInput) {
    return this.gqlAddressService.removeAddress(input);
  }

  public updateAddress(input: IApiUpdateAddressInput) {
    return this.gqlAddressService.updateAddress(input);
  }

}
