import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import {
  IApiAddCertificationInput,
  IApiCertificationFilter,
  IApiUpdateCertificationInput,
  IApiCertificationConnection
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";
import { GqlCertificationService } from "../../modules/graphql/services";
import { NefcoDateHelper } from '../../helpers/nefco-date.class';

@Injectable()
export class CertificationService {

  constructor(
    private gqlCertificationService: GqlCertificationService
  ) { }

  public get(filters?: IApiCertificationFilter[], options?: IGqlBaseOptions): Observable<IApiCertificationConnection> {
    return this.gqlCertificationService.getCertifications(filters, options || new ListPage());
  }

  public add(input: IApiAddCertificationInput) {

    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    if (input?.issuedAt) input.issuedAt = NefcoDateHelper.toUtc(input.issuedAt, 12);
    if (input?.expiresAt) input.expiresAt = NefcoDateHelper.toUtc(input.expiresAt, 12);

    return this.gqlCertificationService.addCertification(input);
  }

  public remove(id: string) {
    return this.gqlCertificationService.removeCertification(id);
  }

  public update(input: IApiUpdateCertificationInput) {

    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    if (input?.issuedAt) input.issuedAt = NefcoDateHelper.toUtc(input.issuedAt, 12);
    if (input?.expiresAt) input.expiresAt = NefcoDateHelper.toUtc(input.expiresAt, 12);

    return this.gqlCertificationService.updateCertification(input);
  }

}
