import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IApiInvestigationDetailCause } from '../../modules/graphql/types/types';
import _ from 'lodash';

@Component({
  selector: 'app-causes-multi-select-kendo',
  templateUrl: './causes-multi-select-kendo.component.html',
  styleUrls: ['./causes-multi-select-kendo.component.scss']
})
export class CausesMultiSelectKendoComponent implements OnInit, OnChanges {

  @Input() placeholder = "Select cause";
  @Input() label = "Cause of Fire";
  @Input() disabled = false;
  @Input() selectedId: Array<string> = [];

  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public selectedValue = [];
  public investigationCauses = IApiInvestigationDetailCause;
  constructor() {}

  ngOnInit(): void {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!_.isEqualWith(this.selectedValue, changes?.selectedId?.currentValue)) {
      this.selectedId = changes?.selectedId?.currentValue || [];
        if (this.selectedId?.length) {
          this.selectedValue = this.selectedId
        } else {
          this.selectedValue = [];
        }
    }
  }

  public searchSelect($event) {
    this.selectedId = $event;
    this.selectionChanged.emit(this.selectedId);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

}
