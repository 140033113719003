<!-- Permissions -->
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursUpdateAll" #timeEntryInvestigationHoursUpdateAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursDeleteAll" #timeEntryInvestigationHoursDeleteAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageDeleteAll" #expenseInvestigationMileageDeleteAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageUpdateAll" #expenseInvestigationMileageUpdateAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesDeleteAll" #expenseInvestigationExpensesDeleteAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesUpdateAll" #expenseInvestigationExpensesUpdateAll="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-card class="{{'kendocard ' + (type === 'time' ? 'time-table' : 'expence-table')}}" [width]="'100%'">
    <kendo-card-header class="card-header">
        <h6 class="mb-0 ml-8">{{pageTitle}}</h6>
    </kendo-card-header>
    <kendo-card-body class="pt-0 pb-0">
        <kendo-grid
            class="hours-list"
            [data]="{
                data: _timeVisible,
                total: pageOptions?.totalCount
            }"
            [pageSize]="pageOptions?.limit"
            [skip]="pageOptions?.offset"
            [pageable]="{
            buttonCount: 6,
            type: 'numeric',
            pageSizes: [5, 10, 25, 100],
            previousNext: true,
            responsive: true,
            position: 'bottom'
            }"
            (pageChange)="pageUpdate($event)"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortField($event)"
            (selectionChange)="onExpenseCheckboxChange($event)"
            [selectable]="{checkboxOnly: true, mode: 'multiple' }"
            kendoGridSelectBy
            [(selectedKeys)]="kendoGridRowSelection"
        >
            <kendo-grid-checkbox-column 
                [width]="8" 
                [showSelectAll]="true" 
                [headerClass]="'checkbox-header'">
            </kendo-grid-checkbox-column>
            <!-- date -->
            <kendo-grid-column
                field="{{type === 'time' ? 'WORKDAY' : 'EXPENSE_DATE'}}"
                title="Date"
                [width]="15"
                [sortable]="true"
                class="font-weight600"
                [headerClass]="'sort-header remove-bar'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="type === 'time'">{{(dataItem?.workday | customDate : "MM/dd/yyyy") || '-'}}</span>
                    <span *ngIf="type === 'expenses-mileage'">{{(dataItem?.expenseDate | customDate : "MM/dd/yyyy") || '-'}}</span>
                </ng-template>
            </kendo-grid-column>

            <!-- staff -->
            <kendo-grid-column
                field="User"
                title="Staff"
                [width]="15"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span>{{(dataItem?.User  | fullName) || '-' }}</span>
                </ng-template>
            </kendo-grid-column>

            <!-- Time/Expense -->
            <kendo-grid-column
                field="User"
                title="Time/Expense"
                [width]="20"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="type === 'time'">
                        {{dataItem?.Type?.name || '-' }}
                    </span>
                    <span *ngIf="type === 'expenses-mileage'">
                        {{dataItem?.ExpenseItem?.name || '-' }}
                    </span>
                </ng-template>
            </kendo-grid-column>

             <!-- Purspose -->
             <kendo-grid-column
                *ngIf="type === 'expenses-mileage'"
                field="ExpensePurpose"
                title="Purpose"
                [width]="20"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="text-overflow">
                        {{dataItem.ExpensePurpose?.name || '-' }}
                    </span>
                </ng-template>
            </kendo-grid-column>

            <!-- Billable -->
            <kendo-grid-column
                field="BILL"
                title="Billable"
                [width]="12"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="type === 'time'">{{dataItem?.hours ? (dataItem?.hours | number: '1.2-2') : '-'}}</span>
                    <span class="" *ngIf="dataItem?.ExpenseItem?.name !== 'Mileage' && type === 'expenses-mileage'">
                        <ng-container *ngIf="!dataItem?.ExpenseItem?.isQuantity && dataItem?.billableQuantity">$</ng-container>{{dataItem?.billableQuantity ? (dataItem?.billableQuantity | number: '1.2-2') : '-'}}
                    </span>
                    <span class="" *ngIf="dataItem?.ExpenseItem?.name === 'Mileage' && type === 'expenses-mileage'">
                        {{dataItem?.billableQuantity ? (dataItem?.billableQuantity | number: '1.2-2') : '-'}}
                    </span>
                </ng-template>
            </kendo-grid-column>

            <!-- Non-Billable -->
            <kendo-grid-column
                field="NON_BILL"
                title="Non-Billable"
                [width]="12"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="type === 'time'">{{dataItem?.nonBillableHours ? (dataItem?.nonBillableHours | number: '1.2-2') : '-'}}</span>
                    <span class="" *ngIf="dataItem?.ExpenseItem?.name !== 'Mileage' && type === 'expenses-mileage'">
                        <ng-container *ngIf="!dataItem?.ExpenseItem?.isQuantity && dataItem?.nonBillableQuantity">$</ng-container>{{dataItem?.nonBillableQuantity ?  (dataItem?.nonBillableQuantity | number: '1.2-2') : '-' }}
                    </span>
                    <span class="" *ngIf="dataItem?.ExpenseItem?.name === 'Mileage' && type === 'expenses-mileage'">
                        {{dataItem?.nonBillableQuantity ? (dataItem?.nonBillableQuantity | number: '1.2-2') : '-' }}
                    </span>
                </ng-template>
            </kendo-grid-column>

            <!-- Description -->
            <kendo-grid-column
                field="description"
                title="Description"
                [width]="20"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="text-overflow">{{dataItem?.description || '-'}}</span>
                </ng-template>
            </kendo-grid-column>

            <!-- Paid Date -->
            <kendo-grid-column
                field="PAID_DATE"
                title="Paid Date"
                [width]="15"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ (dataItem?.paidDate | customDate : "MM/dd/yyyy") || '-' }}
                </ng-template>
            </kendo-grid-column>

            <!-- Approval -->
            <kendo-grid-column
                *ngIf="type === 'expenses-mileage'"
                field="Approval"
                title="Approval"
                [width]="20"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="">
                        {{ (dataItem?.authorizedBy) || '-'}}<br/>
                    </span>
                    <span class="phone-number" *ngIf="dataItem?.authorizedDate">
                        on {{ (dataItem?.authorizedDate | customDate : "MM/dd/yyyy") }}
                    </span>
                        
                </ng-template>
            </kendo-grid-column>

            <!-- action -->
            <kendo-grid-column
                field="id"
                title="Action"
                [width]="15"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <button kendoButton class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil" 
                    (click)="editTime(dataItem);" *ngIf="checkEditPermission(dataItem)"></button>
                <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-trash" 
                    (click)="deleteTime(dataItem)" *ngIf="checkDeletePermission(dataItem)"></button>
            </ng-template>
            </kendo-grid-column>

            <!-- Date Entered -->
            <kendo-grid-column
                *ngIf="showDateEntered"
                field="id"
                title="Date Entered"
                [width]="20"
                [sortable]="false"
                [headerClass]="'nocursor'"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.createdAt | customDate: 'MM/dd/yyyy' }}
            </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridNoRecordsTemplate>
                <p>No Record Found.</p>
            </ng-template>
        </kendo-grid>
    </kendo-card-body>
</kendo-card>