import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateService {
  private canDeactivate: boolean = true;

  setCanDeactivate(canDeactivate: boolean) {
    this.canDeactivate = canDeactivate;
  }

  getCanDeactivate(): boolean {
    return this.canDeactivate;
  }
}
