import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";
import { IApiDocument, IApiDocumentSnapshot } from '../modules/graphql/types/types';

@Pipe({name: 'documentSnapshot'})
export class DocumentSnapshotPipe implements PipeTransform {

  private sort(val: IApiDocument) {
    const history = val.History.length ? val.History.sort((a, b) => moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1)[0] : {} as any;
    return { ...val, History: history };
  }

  transform(value: IApiDocument | IApiDocument[]): IApiDocument | IApiDocument[] {
    if (!value) return;
    if (Array.isArray(value)) return value.map((d) => this.sort(d));
    else return this.sort(value);
  }
}