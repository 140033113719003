import { connectionQuery } from './../../helpers.class';
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { IApiUser, IApiUsersConnection, IApiDistanceOptions, IApiUserFilter, IApiAddUserInput, IApiUpdateUserProfileInput, IApiUpdateUserInput, IApiUserFilterType, IApiUserSignatureInput, IApiUserSignature } from 'src/app/shared/modules/graphql/types/types';
import { IGqlBaseOptions } from '../../interfaces';
import { getUserByIdQuery, updateUserMutation, UserConnectionQuery, updateUserProfileMutation, addUserMutation, getUserByIdNefcoPermissionQuery, getByIdOnlyMsGraphIdQuery, getByIdCelendarViewProfileQuery, getUserByIdWithoutIntestigationRoleQuery, addUserSignature, getByIdWithPermissionRoleAndStateQuery } from "./user.operations";
import { safeResolve } from "../../rxjs";

@Injectable()
export class GqlUserService {

  public constructor(
    private apollo: Apollo
  ) { }

  public getUsersConnection(filters: IApiUserFilter[] = [], options: IGqlBaseOptions = {}): Observable<IApiUsersConnection> {
    const viewType = filters.find(obj => obj.type === IApiUserFilterType.ViewBookkeepingReport || obj.type === IApiUserFilterType.ViewListView || obj.type === IApiUserFilterType.ViewAssignmentView || obj.type === IApiUserFilterType.ViewAssignmentStaffRole || obj.type === IApiUserFilterType.Payroll || obj.type === IApiUserFilterType.ViewStaffUser || obj.type === IApiUserFilterType.ViewPayrollReport) || null;
    const filter = filters.filter(a => a.type !== IApiUserFilterType.ViewAssignmentStaffRole && a.type !== IApiUserFilterType.ViewStaffUser);
    return this.apollo.query(connectionQuery(UserConnectionQuery(viewType), filter, options)).pipe(
      safeResolve(true)
    );
  }

  public getUserByIdWithoutIntestigationRole(id: string): Observable<IApiUser> {
    return this.apollo.query({
      query: getUserByIdWithoutIntestigationRoleQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public getUserById(id: string): Observable<IApiUser> {
    return this.apollo.query({
      query: getUserByIdQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public getUserByIdOnlyNefco(id: string): Observable<IApiUser> {
    return this.apollo.query({
      query: getUserByIdNefcoPermissionQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public addUser(input: IApiAddUserInput) {
    return this.apollo.mutate({
      mutation: addUserMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateUser(input: IApiUpdateUserInput) {
    return this.apollo.mutate({
      mutation: updateUserMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateUserProfile(input: IApiUpdateUserProfileInput) {
    return this.apollo.mutate({
      mutation: updateUserProfileMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getByIdOnlyMsGraphId(id: string): Observable<IApiUser> {
    return this.apollo.query({
      query: getByIdOnlyMsGraphIdQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public getByIdCelendarViewProfile(id: string): Observable<IApiUser> {
    return this.apollo.query({
      query: getByIdCelendarViewProfileQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public addUserSignature(input: IApiUserSignatureInput): Observable<IApiUserSignature> {
    return this.apollo.mutate({
      mutation: addUserSignature,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getByIdWithPermissionRoleAndState(id: string): Observable<IApiUser> {
    return this.apollo.query({
      query: getByIdWithPermissionRoleAndStateQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

}
