import { connectionQuery } from './../../../helpers.class';
import { IApiDocumentSnapshotConnection } from './../../../types/types';
import { IApiUpdateDocumentSnapshotInput, IApiAddDocumentSnapshotInput, IApiDocumentSnapshotFilter } from '../../../types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { IApiDocumentSnapshot } from "../../../types/types";
import { IGqlBaseOptions } from "../../../interfaces";
import { documentSnapshotConnectionQuery, addDocumentSnapshotMutation, updateDocumentSnapshotMutation, removeDocumentSnapshotMutation } from "./document-snapshot.operations";
import { safeResolve } from "../../../rxjs";

@Injectable()
export class GqlDocumentSnapshotService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getSnapshots(filters: IApiDocumentSnapshotFilter[], options: IGqlBaseOptions): Observable<IApiDocumentSnapshotConnection> {
    return this.apollo.query(connectionQuery(documentSnapshotConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addSnapshot(input: IApiAddDocumentSnapshotInput): Observable<IApiDocumentSnapshot> {
    return this.apollo.mutate({
      mutation: addDocumentSnapshotMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateSnapshot(input: IApiUpdateDocumentSnapshotInput): Observable<IApiDocumentSnapshot> {
    return this.apollo.mutate({
      mutation: updateDocumentSnapshotMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeSnapshot(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeDocumentSnapshotMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
