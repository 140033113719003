import gql from "graphql-tag";
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  isIntake
`;

export const companyTypeConnectionQuery = gql`${buildConnectionQuery("CompanyType", "companyTypeConnection", nodeFragment)}`;

export const addCompanyTypeMutation = gql`mutation AddCompanyType($input: AddCompanyInput!) {
  addCompanyType(input: $input) { ${nodeFragment} }
}`;

export const updateCompanyTypeMutation = gql`mutation UpdateCompanyType($input: UpdateCompanyInput!) {
  updateCompanyType(input: $input) { ${nodeFragment} }
}`;

export const removeCompanyTypeMutation = gql`mutation RemoveCompanyType($id: String!) {
  removeCompanyType(input: $input) { ${nodeFragment} }
}`;
