import { Pipe, PipeTransform } from "@angular/core";
import { IApiExpense, IApiExpenseItem, IApiTimeEntryType } from "../modules/graphql/types/types";

@Pipe({
  name: "visibleExpenseItems"
})
export class VisibleExpenseItemsPipe implements PipeTransform {
  transform(expenseItems: IApiExpenseItem[], unlinked: boolean, exclude: boolean = true, searchKeyword: string = ''): IApiExpenseItem[] {
    if (expenseItems?.length) {
      const excludeNames = ['Mileage', 'Annual Evidence Storage', 'Photographs', 'Lightening Strike Report'];
      expenseItems = exclude ? expenseItems.filter((item) => item?.AppliesTo?.name !== (unlinked ? "Investigation" : "Unlinked") && !excludeNames?.includes(item?.name) && item.active === true) : expenseItems.filter((item) => item?.AppliesTo?.name !== (unlinked ? "Investigation" : "Unlinked") && item.name !== 'Mileage' && item.active === true);
      if (searchKeyword) {
        const regex = /:\s(?:\(CA\)\s)?(.*)/;
        return expenseItems.filter((item) => {
          const text = item['name'] || '';
          const match = text.match(regex);
          if (match) {
            /* search by after colon OR colon (CA) */
            const partAfterColon = match[1] || '';
            return partAfterColon.trim().toLowerCase().startsWith(searchKeyword?.trim().toLowerCase());
          } else {
            /* search with startsWith */
            return text.trim().toLowerCase().startsWith(searchKeyword?.trim().toLowerCase());
          }
        });
      }  else {
        /* default */
        return expenseItems;
      }
    } else {
      return [];
    }
  }
}


@Pipe({
  name: "visibleTimeEntryItems"
})
export class VisibleTimeEntryItemsPipe implements PipeTransform {
  transform(timeEntryItems: IApiTimeEntryType[], unlinked: boolean, searchKeyword: string): IApiTimeEntryType[] {
    if (timeEntryItems?.length) {
      if (searchKeyword) {
        const regex = /:\s(?:\(CA\)\s)?(.*)/;
        return timeEntryItems.filter((item) => {
          const text = item['name'] || '';
          const match = text.match(regex);
          if (match) {
            /* search by after colon OR colon (CA) */
            const partAfterColon = match[1] || '';
            return partAfterColon.trim().toLowerCase().startsWith(searchKeyword?.trim().toLowerCase()) && item?.appliesTo !== (unlinked ? "Investigation" : "Unlinked") && item.name !== 'Mileage' && item.activated === true;
          } else {
            /* search with startsWith */
            return text.trim().toLowerCase().startsWith(searchKeyword?.trim().toLowerCase()) && item?.appliesTo !== (unlinked ? "Investigation" : "Unlinked") && item.name !== 'Mileage' && item.activated === true;
          }
        });
      } else {
        /* default */
        return timeEntryItems.filter((item) => item?.appliesTo !== (unlinked ? "Investigation" : "Unlinked") && item.name !== 'Mileage' && item.activated === true);
      }
    } else {
      return [];
    }
  }
}

@Pipe({
  name: "reimbursementRate"
})
export class ReimbursementRatePipe implements PipeTransform {
  transform(expense: IApiExpense): any {
    const year = new Date(expense?.expenseDate).getFullYear();
    const rate = year >= 2023 ? (expense?.ExpenseItem?.ExpenseReimbursementRate?.find(rates => rates.year === year) || expense?.ExpenseItem?.ExpenseReimbursementRate?.find(rates => rates.year === year - 1)) : expense?.ExpenseItem?.ExpenseReimbursementRate?.find(rates => rates.year === 2023);
    return (expense?.billableQuantity + expense?.nonBillableQuantity) * rate?.reimbursementRate;
  }
}
