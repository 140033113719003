import { connectionQuery } from './../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../interfaces/base.interfaces';
import { addTimeEntryMutation, TimeEntryConnectionQuery, removeTimeEntryMutation, updateTimeEntryMutation, approveTimeEntryMutation, markAllPaidTimeEntryMutation, getTimeEntryTotal, timeEntryTotalByType } from "./time-entry.operations";
import { IApiAddTimeEntryInput, IApiApproveTimeEntryInput, IApiMarkAllPaidTimeEntryInput, IApiTimeEntry, IApiTimeEntryConnection, IApiTimeEntryFilter, IApiTimeEntryFilterType, IApiTimeEntryTotal, IApiTimeEntryTotalByType, IApiTimeEntryTotalByTypeSummary, IApiUpdateTimeEntryInput } from "../../types/types";
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';

dayjs.extend(timezone);
dayjs.extend(utc);

@Injectable()
export class GqlTimeEntryService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getTimeEntries(filters: IApiTimeEntryFilter[] = [], options: IGqlBaseOptions): Observable<IApiTimeEntryConnection> {
    const viewType = filters?.find(obj => obj.type === IApiTimeEntryFilterType.ViewPayrollTotal || obj.type === IApiTimeEntryFilterType.ViewBookkeepingView);
    return this.apollo.query(connectionQuery(TimeEntryConnectionQuery(false, viewType), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addTimeEntry(input: IApiAddTimeEntryInput): Observable<IApiTimeEntry> {

    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    if (input?.workday) input.workday = NefcoDateHelper.toUtc(input.workday, 12);

    return this.apollo.mutate({
      mutation: addTimeEntryMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public updateTimeEntry(input: IApiUpdateTimeEntryInput): Observable<IApiTimeEntry> {

    // Look for input dates here instead of setting within call because we want to exlcude the property if it wasn't passed in the call
    if (input?.workday) input.workday = NefcoDateHelper.toUtc(input.workday, 12);

    return this.apollo.mutate({
      mutation: updateTimeEntryMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeTimeEntry(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeTimeEntryMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public approveTimeEntry(input: IApiApproveTimeEntryInput): Observable<IApiTimeEntry> {
    return this.apollo.mutate({
      mutation: approveTimeEntryMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public markAllPaid(input: IApiMarkAllPaidTimeEntryInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: markAllPaidTimeEntryMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public getTimeEntryTotal(filters: IApiTimeEntryFilter[], options: IGqlBaseOptions): Observable<IApiTimeEntryTotal> {
    return this.apollo.query(connectionQuery(getTimeEntryTotal, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public geTtimeEntryTotalByType(filters: IApiTimeEntryFilter[], options: IGqlBaseOptions): Observable<IApiTimeEntryTotalByTypeSummary> {
    return this.apollo.query(connectionQuery(timeEntryTotalByType, filters, options)).pipe(
      safeResolve(true)
    );
  }
}
