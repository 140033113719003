import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlServiceStatusService } from 'src/app/shared/modules/graphql/services';
import {
  IApiAddServiceStatusInput, IApiServiceStatus, IApiRemoveServiceStatusInput,
  IApiUpdateServiceStatusInput, IApiServiceStatusFilter, IApiServiceStatusConnection
} from 'src/app/shared/modules/graphql/types/types';


@Injectable()
export class ServiceStatusService {

  constructor(
    private gqlServiceStatusService: GqlServiceStatusService
  ) { }

  public get(filters: IApiServiceStatusFilter[] = [], options?: IGqlBaseOptions): Observable<IApiServiceStatusConnection> {
    return this.gqlServiceStatusService.get(filters, options || new ListPage(-1));
  }

  public getById(id: string): Observable<IApiServiceStatus> {
    return this.gqlServiceStatusService.getServiceStatusById(id);
  }

  public add(input: IApiAddServiceStatusInput): Observable<IApiServiceStatus> {
    return this.gqlServiceStatusService.add(input);
  }

  public remove(input: IApiRemoveServiceStatusInput): Observable<boolean> {
    return this.gqlServiceStatusService.remove(input);
  }

  public update(input: IApiUpdateServiceStatusInput): Observable<IApiServiceStatus> {
    return this.gqlServiceStatusService.update(input);
  }
}
