import { SharedComponentModule } from './../shared/shared-component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighOrderComponentModuleRouter } from './high-order-component.router';
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    SharedComponentModule,
    HighOrderComponentModuleRouter,

  ],
  exports: [
    SharedComponentModule
  ]
})
export class HighOrderComponentModule { }
