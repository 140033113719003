import { IApiExpensePurpose, IApiExpensePurposeConnection, IApiExpensePurposeFilter } from './../../../modules/graphql/types/types';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { ExpensePurposeService } from "./expense-purpose.service";
import { NefcoPagedDataSource } from '../../../helpers/nefco-data-source.class';

export class ExpensePurposeDataSource extends NefcoPagedDataSource<IApiExpensePurpose, ExpensePurposeService, IApiExpensePurposeConnection> implements DataSource<IApiExpensePurpose> {
  @ApiConnection(-1)
  public load(filters: IApiExpensePurposeFilter[] = []): Promise<IApiExpensePurposeConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}