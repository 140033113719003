<section>
  <!-- Permissions -->
  <div [appEnforcePermission]="authConfig.companiesViewAll" #companiesViewAll="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesCreateConfig" #companiesCreateConfig="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesBranchesList" #companiesBranchesList="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesBranchesView" #companiesBranchesView="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesBranchesCreate" #companiesBranchesCreate="appEnforcePermission">
  </div>
  <div [appEnforcePermission]="authConfig.companiesDelete" #companiesDelete="appEnforcePermission"></div>
  <!-- Permissions -->
  <h4 class="page-title">Companies</h4>
  <div class="content-wrapper">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(6,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label [text]="'Type'"></kendo-label>
          <kendo-dropdownlist #type [data]="companyTypes" textField="name" valueField="id" [valuePrimitive]="true"
            fillMode="outline" [filterable]="false" [defaultItem]="{ id: '', name: 'Select type' }"
            (selectionChange)="setCompanyType($event)"
            [popupSettings]="{ appendTo: 'component', animate: false, width: 250 }">
            <ng-template kendoDropDownListGroupTemplate let-groupName>
              <strong>{{ groupName }}</strong>
            </ng-template>
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="!dataItem?.id" class="placeholder">
                {{ dataItem.name }}
              </span>
              <span *ngIf="dataItem?.id" class="not-placeholder">
                {{ dataItem.name }}
              </span>
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <app-state-select-kendo [label]="'State'" [placeholder]="'Select state'"
          (selectionChanged)="setState($event)"></app-state-select-kendo>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-formfield class="left-search-icon">
          <kendo-label [for]="search" text="Search"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox #search [(ngModel)]="searchValue" (ngModelChange)="onSearchChange()" placeholder="Search"
              fillMode="outline" [clearButton]="true">
            </kendo-textbox>
          </div>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [colSpan]="3" class="filter-actions-grid">
        <kendo-button *ngIf="companiesCreateConfig?.enabled" kendoButton [themeColor]="'primary'" fillMode="solid"
          iconClass="fas fa-plus" (click)="gotoCreateCompany()">
          New Company</kendo-button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </div>
  <kendo-grid class="pl-24 pr-24 permission-role-list" [data]="{
    data: companies,
    total: (dataSource?.pageInfo$ | async)?.totalCount
  }" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="{
    buttonCount: 6,
    type: 'numeric',
    pageSizes: [5, 10, 25, 100],
    previousNext: true,
    responsive: true,
    position: 'bottom'
  }" (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)">
    <kendo-grid-column field="NAME" title="Name" [width]="10" class="id-column" [sortable]="true" class="font-weight600"
      [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/admin/companies/management', dataItem.id]" appEventLink class="text-decoration word-break"
          *ngIf="companiesViewAll?.enabled else onlyName">
          {{dataItem?.name}}
        </a>
        <ng-template #onlyName>
          <span [innerHTML]="dataItem?.name"></span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="TYPE" title="Type" [width]="10" class="id-column" [sortable]="false"
      [headerClass]="'no-cursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHTML]="dataItem?.Type?.name || '-'"></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="BRANCHES" title="Branch" [width]="70" class="id-column" [sortable]="false"
      [headerClass]="'no-cursor'">
      <ng-template kendoGridHeaderTemplate>
        <table class="text-left">
          <thead class="custom-table-header">
            <tr>
              <th width="30%">Branch</th>
              <th width="30%" class="pr-2">Address</th>
              <th width="30%">Phone Number</th>
              <th width="10%"></th>
            </tr>
          </thead>
        </table>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <table class="text-left no-border">
          <tbody>
            <tr *ngFor="let branch of dataItem.Branches">
              <td width="30%">
                <a *ngIf="companiesBranchesView?.enabled; else branchName" class="text-decoration word-break"
                  [routerLink]="['/admin/companies/management', dataItem.id, 'branch', branch.id]">
                  {{branch.name}}
                </a>
                <ng-template #branchName>
                  {{branch?.name}}
                </ng-template>
              </td>
              <td width="30%" class="pr-2">
                <app-address-view *ngFor="let address of branch.Addresses" [address]="address"></app-address-view>
              </td>
              <td width="30%">
                <app-email-phone-view *ngFor="let phone of branch.Phones | primarySort"
                  [phone]="phone"></app-email-phone-view>
              </td>
              <td width="10%"></td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </kendo-grid-column>
    <!-- Action -->
    <kendo-grid-column field="id" title="Actions" [width]="10" [sortable]="false" [headerClass]="'nocursor'"
      *ngIf="companiesViewAll?.enabled || companiesBranchesList?.enabled || companiesBranchesCreate?.enabled ||companiesDelete?.enabled">
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-dropdownbutton [data]="actionItems" themeColor="primary" fillMode="link" buttonClass="grid-action-items"
          iconClass="fa-solid fa-ellipsis" (itemClick)="itemSelected($event, dataItem)" [popupSettings]="{
         appendTo: 'component',
         animate: false,
         width: 250,
         popupClass: 'grid-action-items-menu'
       }">
        </kendo-dropdownbutton>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</section>
