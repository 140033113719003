import { IApiEvidence, IApiEvidenceConnection, IApiEvidenceFilter } from './../../modules/graphql/types/types';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { EvidenceService } from "./evidence.service";
import { NefcoPagedDataSource } from '../../helpers/nefco-data-source.class';

export class EvidenceDataSource extends NefcoPagedDataSource<IApiEvidence, EvidenceService, IApiEvidenceConnection> implements DataSource<IApiEvidence> {
  @ApiConnection()
  public load(filters: IApiEvidenceFilter[] = []): Promise<IApiEvidenceConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
