import { GqlInvoiceItemService } from '../../../modules/graphql/services/invoice';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../../modules/graphql/interfaces/base.interfaces';
import { ListPage } from "../../../helpers/list-page.class";
import { IApiInvoiceItemConnection, IApiInvoiceItemFilter } from "../../../modules/graphql/types/types";

@Injectable()
export class InvoiceItemService {

  constructor(
    private gqlInvoiceItemService: GqlInvoiceItemService
  ) { }

  public get(filters?: IApiInvoiceItemFilter[], options?: IGqlBaseOptions): Observable<IApiInvoiceItemConnection> {
    return this.gqlInvoiceItemService.getInvoiceItems(filters, options || new ListPage());
  }
  
}
