import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiContactRoleConnection, IApiContactRoleFilterType } from 'src/app/shared/modules/graphql/types/types';
import {
  contactRolesConnectionQuery, addContactRoleMutation,
  removeContactRoleMutation, getContactRoleByIdQuery,
  updateContactRoleMutation
} from './contact-role.operations';
import {
  IApiContactRoleFilter, IApiAddContactRoleInput, IApiUpdateContactRoleInput, IApiContactRole
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlContactRoleService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiContactRoleFilter[], options: IGqlBaseOptions): Observable<IApiContactRoleConnection> {
    const viewType = filters.find(obj => obj.type === IApiContactRoleFilterType.ViewListView) || null;
    return this.apollo.query(connectionQuery(contactRolesConnectionQuery(viewType), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddContactRoleInput): Observable<IApiContactRole> {
    return this.apollo.mutate({
      mutation: addContactRoleMutation,
      variables: { input }
    }).pipe(safeResolve(true));
  }

  public remove(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeContactRoleMutation,
      variables: { id }
    }).pipe(safeResolve(true));
  }

  public update(input: IApiUpdateContactRoleInput): Observable<IApiContactRole> {
    return this.apollo.mutate({
      mutation: updateContactRoleMutation,
      variables: { input }
    }).pipe(safeResolve(true));
  }

  public getContactRoleById(id: string): Observable<IApiContactRole> {
    return this.apollo.query({
      query: getContactRoleByIdQuery,
      variables: { id }
    }).pipe(
      safeResolve(true),
    );
  }
}
