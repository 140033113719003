import { gql } from 'apollo-angular';
import { buildConnectionQuery, buildWithoutConnectionQuery } from "../../helpers.class";

const getpublicRoleFragment = `
  id
  name
`;

export const phoneTypeConnectionFragment = `
  id
  name
`;

const getExistClientFragment = `
  contact
  clientDetail{
    email
    email_verified
    username
    phone_number
    phone_verified
    user_id
    picture
    name
    nickname
    blocked
    given_name
    family_name
  }
`;

export const riskTypeQuery = gql`query RiskTypes {
  riskTypes {
    id
    name
  }
}`;

const companiesConnectionFragment = `
  id
  name
  Branches {
    id
    name
    startDate
    Addresses {
      id
      address1
      address2
      city
      state
      country
      postal
      isPrimary
      createdAt
      updatedAt
    }
    Phones {
      id
      isPrimary
      number
      extension
    }
  }
`;

const createInvestigationFragment = `
Contact {
  id
  firstName
  lastName
  jobTitle
  Role {
  id
  name
  __typename
  }
  Phones {
  id
  isPrimary
  number
  extension
  Type {
      id
      name
      __typename
  }
  __typename
  }
  Emails {
  id
  isPrimary
  address
  Type {
      id
      name
      __typename
  }
  __typename
  }
  createdAt
  updatedAt
  Addresses {
  id
  isPrimary
  address1
  address2
  address3
  city
  state
  postal
  country
  Type {
      id
      name
      __typename
  }
  __typename
  }
  Branches {
  id
  name
  Company {
      id
      name
      __typename
  }
  __typename
  }
  BranchAssignments {
  isPrimary
  startDate
  endDate
  BranchId
  ContactId
  Branch {
      id
      name
      Company {
      id
      name
      __typename
      }
      __typename
  }
  __typename
  }
  Branches {
  id
  name
  additionalInformation
  Company {
      id
      name
      __typename
  }
  Phones {
      id
      isPrimary
      number
      extension
      Type {
      id
      name
      __typename
      }
      __typename
  }
  Addresses {
      id
      isPrimary
      address1
      address2
      address3
      city
      state
      postal
      country
      Type {
      id
      name
      __typename
      }
      __typename
  }
  Emails {
      id
      isPrimary
      address
      Type {
      id
      name
      __typename
      }
      __typename
  }
  Company {
      id
      name
      __typename
  }
  __typename
  }
  __typename
}
ClientInvestigationNote {
  id
  insuranceCarrierName
  contactIsAdjuster
  ContactId
  InvestigationId
  createdAt
  updatedAt
}
AuthCreated
`;

export const GetPublicContactRole = gql`${buildWithoutConnectionQuery("GetPublicContactRole", "ContactRole", "getPublicContactRole", getpublicRoleFragment)}`;

export const phoneTypeQuery = gql`${buildConnectionQuery("PhoneType", "phoneTypeConnection", phoneTypeConnectionFragment)}`;

export const getUserByEmailQuery = gql`query getClientUserDetail($email: String!) {
  getClientUserDetail(email: $email) {
    contact
    clientDetail{
     email
    }
  }
}`;

export const getUserDetailsEmailQuery = gql`query getClientUserDetail($email: String!) {
  getClientUserDetail(email: $email) {
    ${getExistClientFragment}
  }
}`;

export const createInvestigationQuery = gql`mutation CreatIntake($input: AddIntakeInput!) {
  createIntake(input: $input) {
    ${createInvestigationFragment}
  }
}`;

export const companyConnectionQuery = gql`
  ${buildWithoutConnectionQuery(
    "getInsuranceCompanies",
    "Company",
    "getInsuranceCompanies",
    companiesConnectionFragment
  )}
`;

export const createUserAndResetPasswordQuery = gql`query createUserAndResetPassword($email: String!) {
  createUserAndResetPassword(email: $email)

}
`;

export const resetPasswordQuery = gql`query resetPassword($email: String!) {
  resetPassword(email: $email)
}
`;

export const getCurrentContactQuery = gql`query getCurrentContact($email: String!) {
  getCurrentContact(email: $email) {
    Contact {
      id
      firstName
      lastName
      jobTitle
      Emails {
        id
        Type {
          id
          name
        }
        TypeId
        isPrimary
        address
        emailableId
        emailableType
      }
      Phones {
        id
        number
        isPrimary
        extension
      }
      insuredName
      Role {
        id
        name
        ContactType {
          id
          name
        }
        isPrivate
      }
      Branches {
        id
        name
        additionalInformation
        Company {
          id
          name
          Type {
            id
            name
          }
        }
        Addresses {
          id
          isPrimary
          address1
          address2
          address3
          city
          state
          postal
          country
        }
        Phones {
          id
          number
          isPrimary
          extension
        }
        isPrimary
      }
      BranchAssignments {
        isPrimary
        startDate
        endDate
        createdAt
        updatedAt
        BranchId
        ContactId
        Branch {
          id
          name
          additionalInformation
          Company {
            id
            name
            Type {
              id
              name
            }
          }
          Addresses {
            id
            isPrimary
            address1
            address2
            address3
            city
            state
            postal
            country
          }
          Phones {
            id
            number
            isPrimary
            extension
          }
          isPrimary
      }
        Contact {
          id
        }
      }
    }
    ClientInvestigationNote {
      id
      insuranceCarrierName
      contactIsAdjuster
      address
      state
      city
      zip
      phone
      extension
      ContactId
      InvestigationId
    }
    AuthCreated,
    company{
        id
        name
        Branches {
        id
        name
        additionalInformation
        Addresses {
          id
          isPrimary
          address1
          address2
          address3
          city
          state
          postal
          country
        }
        Phones {
          id
          number
          isPrimary
          extension
        }
        isPrimary
      }
        MainOffice {
        id
        name
        additionalInformation
        Addresses {
          id
          isPrimary
          address1
          address2
          address3
          city
          state
          postal
          country
        }
        Phones {
          id
          number
          isPrimary
          extension
        }
        isPrimary
      }
    }
  }
}
`;

export const getCurrentContactByIdQuery = gql`query getCurrentContactById($id: ID!) {
  getCurrentContactById(id: $id) {
    Contact {
      id
      firstName
      lastName
      jobTitle
      Emails {
        id
        Type {
          id
          name
        }
        TypeId
        isPrimary
        address
        emailableId
        emailableType
      }
      Phones {
        id
        number
        isPrimary
        extension
      }
      insuredName
      Role {
        id
        name
        ContactType {
          id
          name
        }
        isPrivate
      }
      Branches {
        id
        name
        additionalInformation
        Company {
          id
          name
          Type {
            id
            name
          }
        }
        Addresses {
          id
          isPrimary
          address1
          address2
          address3
          city
          state
          postal
          country
        }
        Phones {
          id
          number
          isPrimary
          extension
        }
        isPrimary
      }
      BranchAssignments {
        isPrimary
        startDate
        endDate
        createdAt
        updatedAt
        BranchId
        ContactId
        Branch {
          id
          name
          additionalInformation
          Company {
            id
            name
            Type {
              id
              name
            }
          }
          Addresses {
            id
            isPrimary
            address1
            address2
            address3
            city
            state
            postal
            country
          }
          Phones {
            id
            number
            isPrimary
            extension
          }
          isPrimary
      }
        Contact {
          id
        }
      }
    }
    ClientInvestigationNote {
      id
      insuranceCarrierName
      contactIsAdjuster
      address
      state
      city
      zip
      phone
      extension
      ContactId
      InvestigationId
    }
    AuthCreated,
    company{
        id
        name
        Branches {
        id
        name
        additionalInformation
        Addresses {
          id
          isPrimary
          address1
          address2
          address3
          city
          state
          postal
          country
        }
        Phones {
          id
          number
          isPrimary
          extension
        }
        isPrimary
      }
        MainOffice {
        id
        name
        additionalInformation
        Addresses {
          id
          isPrimary
          address1
          address2
          address3
          city
          state
          postal
          country
        }
        Phones {
          id
          number
          isPrimary
          extension
        }
        isPrimary
      }
    }
  }
}
`;
