import { Pipe, PipeTransform } from '@angular/core';
import { IApiEvidence } from '../modules/graphql/types/types';

@Pipe({
  name: 'evidenceItems'
})
export class EvidenceItemsPipe implements PipeTransform {

  transform(items: IApiEvidence[], ...args: unknown[]): unknown {
    return items.length === 1 ? "1 Item" : items.length + " Items";
  }
  
}

@Pipe({
  name: 'evidenceLocation'
})
export class EvidenceLocationPipe implements PipeTransform {

  transform(items: IApiEvidence[], ...args: unknown[]): unknown {
    return items.every((obj) => obj.EvidenceStorageLocation.name === items[0].EvidenceStorageLocation.name) ? items[0].EvidenceStorageLocation.name : "Multiple";
  }
  
}

@Pipe({
  name: 'evidenceStatus'
})
export class EvidenceStatusPipe implements PipeTransform {

  transform(items: IApiEvidence[], ...args: unknown[]): unknown {
    return items.every((obj) => obj.Status === items[0].Status) ? items[0].Status : "Multiple";
  }
  
}

@Pipe({
  name: 'evidenceArrivalDate'
})
export class EvidenceArrivalDatePipe implements PipeTransform {

  transform(items: IApiEvidence[], ...args: unknown[]): unknown {

    // Formats date to return if all are the same
    const firstDate = items[0].createdAt.split(/[T-]/);
    const formattedDate = `${firstDate[1]}-${firstDate[2]}-${firstDate[0]}`;

    // Only compares mm/dd/yyyy, not timestamp
    return items.every((obj) => obj.createdAt.split('T')[0] === items[0].createdAt.split('T')[0]) ? formattedDate : "Multiple";
  }
  
}

@Pipe({
  name: 'evidenceTransferDate'
})
export class EvidenceTransferDatePipe implements PipeTransform {

  transform(items: IApiEvidence[], ...args: unknown[]): unknown {
 
    const allTransfers = [];

    // Maps the latest "transfer" date to allTransfers
    items.map((obj) => {
      obj.Transfers.length > 0 ? allTransfers.push(obj.Transfers[0].createdAt.split('T')[0]) : allTransfers.push(null);
    });

    // Are the items all the same?
    const same = allTransfers.every((arr) => arr === allTransfers[0]);

    if (same && allTransfers[0] === null ) {
      return "--";
    }

    else if (same) {
      const firstDate = allTransfers[0].split(/[T-]/);
      return `${firstDate[1]}-${firstDate[2]}-${firstDate[0]}`;     
    }

    else {
      return "Multiple"; 
    }

  }
  
}
