<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageExpenseReportCategoriesCreate" #manageExpenseReportCategoriesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpenseReportCategoriesUpdate" #manageExpenseReportCategoriesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpenseReportCategoriesDelete" #manageExpenseReportCategoriesDelete="appEnforcePermission"></div>
<!-- Permissions -->
<section>

  <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']" *ngIf="manageExpenseReportCategoriesCreate?.enabled">
    <kendo-gridlayout-item class="text-right mb-0">
      <kendo-button class="mr-16" kendoButton themeColor="primary" iconClass="fa fa-plus" fillMode="solid" size="medium" (click)="addCategory()">
        New Category
      </kendo-button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  
  <kendo-grid
    class="expense-report-category-list mt-16"
    [data]="{
      data: data,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageOptions?.paginate($event);"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
  >
    <kendo-grid-column
      field="NAME"
      title="Category"
      [width]="15"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          *ngIf="manageExpenseReportCategoriesUpdate?.enabled else onlyName"
          (click)="editCategory(dataItem)"
          tel=""
          class="text-decoration"
        >
          {{ dataItem?.name }}
        </a>
        <ng-template #onlyName>
          {{dataItem.name}}
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="Purposes"
      title="Purposes"
      [width]="25"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngFor="let p of dataItem.ExpensePurposes" class="purposes" [innerHTML]="p.name"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="Details"
      title="Details"
      [width]="25"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.details}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Action"
      [width]="8"
      [sortable]="false"
      [headerClass]="'nocursor'"
      *ngIf="manageExpenseReportCategoriesUpdate?.enabled || manageExpenseReportCategoriesDelete?.enabled"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-dropdownbutton
          [data]="manageExpenseReportCategoriesUpdate?.enabled && manageExpenseReportCategoriesDelete?.enabled ? [{ text: 'Update' },{ text: 'Delete' }] : manageExpenseReportCategoriesUpdate?.enabled ? [{ text: 'Edit' }] : [{text: 'Delete'}]"
          themeColor="primary" fillMode="link" buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis"
          (itemClick)="itemSelected($event, dataItem)" 
          [popupSettings]="{
            appendTo: 'component',
            animate: false,
            width: 250,
            popupClass: 'grid-action-items-menu'
          }">
        </kendo-dropdownbutton>
      </ng-template>
    </kendo-grid-column>
    
    <!-- No record -->
    <ng-template kendoGridNoRecordsTemplate>
      <p class="mt-24 mb-24">There are no results to display.</p>
    </ng-template>
  </kendo-grid>
</section>