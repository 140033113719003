import { Component, OnInit, Input } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiInvestigationFilterType, IApiInvestigationMetricsFilter, IApiInvestigationMetricsFilterType, IApiInvestigationMetricsOrderBy, IApiInvestigationMetricsState } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { InvestigationMetricsBasicDataSource, InvestigationMetricsBasicService } from 'src/app/shared/services/reports';

@Component({
  selector: "app-investigation-metrics-basic",
  templateUrl: "./investigation-metrics-basic.component.html",
  styleUrls: ["./investigation-metrics-basic.component.scss"],
})
export class InvestigationMetricsBasicComponent implements OnInit {
  @Input() tabIndex: number;
  private _dataSource: InvestigationMetricsBasicDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public data: IApiInvestigationMetricsState[] = [];
  public sort: SortDescriptor[] = [
    {
      field: IApiInvestigationMetricsOrderBy.ReceivedDate,
      dir: "desc",
    },
  ];
  private userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


  constructor(
    private investigationMetricsBasicService: InvestigationMetricsBasicService,
    private loaderService: LoaderService
  ) {
    this.dataSource = new InvestigationMetricsBasicDataSource(
      this.investigationMetricsBasicService
    );
    this.loaderService.attachObservable(this.dataSource.loading$);
    this.dataSource.listPage.orderBy = IApiInvestigationMetricsOrderBy.ReceivedDate;
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
  }

  ngOnInit(): void {
    this.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiInvestigationMetricsOrderBy.ReceivedDate;
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  private load(): void {
    this.dataSource.load().then(() => {
      this.dataSource?.contents$.subscribe((res) => {
        this.data = res.map(item => {
          return {
            ...item,
            data: JSON.parse(item?.data)
          }
        });
      });
    });
  }

  public applyFilter(filters: IApiInvestigationMetricsFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    filters?.map(filter => this.dataSource.applyFilter(filter?.type, filter?.value));
    this.dataSource.pagingReset();
    this.load();
  }

  public clearFilter(filters: IApiInvestigationMetricsFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.pagingReset();
    this.load();
  }
}
