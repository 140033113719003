import { Component } from '@angular/core';

@Component({
  selector: 'app-portal-main',
  templateUrl: './portal-main.component.html',
  styleUrls: ['./portal-main.component.scss']
})
export class PortalMainComponent {

  constructor() { }

}
