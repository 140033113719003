import gql from 'graphql-tag';

export const getInvestigationTimelogQuery = gql`query InvestigationTimelogConnection($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  investigationTimelogConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    pageInfo {
      limit
      offset
      __typename
    }
    totalCount
    edges {
      cursor
      node {
        id 
        nefcoNumber
        investigator
        techReviewer
        receivedDate
        untilOnScene
        untilFirstMileageEntered
        onSceneToNotesUploaded
        onSceneToPhotosUploaded

      }
      __typename
    }
    __typename
  }
}`;
