import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/enums/permissions.enums";

// Bookeeping, payroll
export const bookKeepingPayroll: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_PAYROLL,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Bookkeeping, invoice list
export const bookKeepingInvoiceList: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_INVOICE_LIST,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const bookKeepingInvoiceListCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_INVOICE_LIST,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const bookKeepingInvoiceListUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_INVOICE_LIST,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const bookKeepingInvoiceListDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_INVOICE_LIST,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Bookeeping, expense reports
export const bookKeepingExpenseReports: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_EXPENSE_REPORT,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Bookeeping, time & Expenses, Entry list, hours
export const bookKeepingTimeExpensesEntryListHours: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_TIME_AND_EXPENSE_ENTRY_LIST_HOURS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Bookeeping, time & Expenses, Entry list, expenses and mileage
export const bookKeepingTimeExpensesEntryListExpensesMileage: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_TIME_AND_EXPENSE_ENTRY_LIST_EXPENSE_AND_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Bookeeping, time & expenses, hours, account summary
export const bookKeepingTimeExpensesHoursAccountSummary: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_TIME_AND_EXPENSE_ACCOUNT_SUMMARY_HOURS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Bookeeping, time & expenses, expenses and mileage, account summary
export const bookKeepingTimeExpensesExpensesMileageAccountSummary: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_TIME_AND_EXPENSE_ACCOUNT_SUMMARY_EXPENSE_AND_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Bookkeeping, evidence billing
export const bookKeepingEvidenceBilling: IEnforcePermissionConfig = {
  category: PermissionCategories.BOOKKEEPING_EVIDENCE_BILLING,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};