import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Capacitor } from '@capacitor/core';
import { IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { investigationMetricBasicCharts, investigationMetricState } from 'src/app/shared/helpers/auth-config/reports.config';
import { forkJoin } from 'rxjs';
import { SharedService } from 'src/app/common/shared.service';

@Component({
  selector: "app-mobile-dashboard",
  templateUrl: "./mobile-dashboard.component.html",
  styleUrls: ["./mobile-dashboard.component.scss"],
})
export class MobileDashboardComponent implements OnInit, AfterViewInit {
  public permissionConfig = {
    investigationMetricBasicCharts,
    investigationMetricState
  };
  public expanded = false;
  public loggedIn = false;

  public items = [
    {
      text: "Home",
      icon: "fa-solid fa-house",
      path: "/admin/investigation-list",
      selected: true,
    },
    {
      text: "Time and Expenses",
      icon: "fa-solid fa-dollar",
      path: "/admin/time-and-expense-tabs",
      selected: false,
    },
    {
      text: "Reports",
      icon: "fa-solid fa-chart-simple",
      path: "/admin/investigation-metrics-tabs",
      selected: false,
    },
    {
      text: "Logout",
      icon: "fa-solid fa-arrow-right-from-bracket",
      path: null,
      selected: false,
    }
  ];
  private permissionForReports = false;
  public currentUser: IApiUser = null;

  constructor(
    private router: Router,
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    public sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.auth.authenticatedUser.subscribe((u) => {
      this.currentUser = u;
      forkJoin([
        this.auth.hasCategoryPermission(this.permissionConfig.investigationMetricBasicCharts.category, this.permissionConfig.investigationMetricBasicCharts.appliedPermissions),
        this.auth.hasCategoryPermission(this.permissionConfig.investigationMetricState.category, this.permissionConfig.investigationMetricState.appliedPermissions)
      ]).subscribe(([basic, state]) => {
        if (basic && state) {
          this.permissionForReports = true;
        } else {
          this.permissionForReports = false;
        }
      })
    });
  }

  public ngAfterViewInit(): void {
    this.setSafeAreaView();
  }

  public setSafeAreaView() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (Capacitor.getPlatform() === 'android') {
      } else if (Capacitor.getPlatform() === 'ios') {
        const safeAreaSetArray = document.getElementsByClassName("safe-area-set");
        for (let i = 0; i < safeAreaSetArray.length; i++) {
          const element = safeAreaSetArray.item(i);
          if (!element.classList.contains("safe-area-padding-top")) {
            element.classList.add("safe-area-padding-top");
          }
        }
      } else { }
      this.cdr.detectChanges();
    });
  }

  public onSelect(ev: DrawerSelectEvent): void {
    if (ev.item.text === 'Logout') {
      this.notificationsService
        .kendoMobileConfirm(
          "Are you sure you want to log out?",
          "Log Out",
          "No, Don’t Log Out",
          "Yes, Log Out",
          "100%",
          true,
          "",
          "p-16",
          true
        )
        .subscribe((result) => {
          if (result === true) {
            this.auth.mobileLogout();
          }
          this.items = this.items.map((item) => ({
            ...item,
            selected: item.path === this.router.url || (item.path === '/admin/investigation-metrics-tabs' && this.router.url === '/admin/feature-unavailable')
          }));
        });
    } else if (ev.item.text === 'Reports' && this.permissionForReports === false) {
      this.router.navigate(['/admin/feature-unavailable']);
    } else {
      this.router.navigate([ev.item.path]);
    }
  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }
}
