import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlBillingRuleCategoryService } from 'src/app/shared/modules/graphql/services';
import { IApiAddBillingRuleInput, IApiAddBillingRuleCategoryInput, IApiBillingRuleCategory, IApiBillingRuleCategoryConnection, IApiBillingRuleCategoryFilter, IApiUpdateBillingRuleInput } from 'src/app/shared/modules/graphql/types/types';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';

@Injectable()
export class BillingRuleCategoryService {
  constructor(private service: GqlBillingRuleCategoryService) { }

  public get(filters: IApiBillingRuleCategoryFilter[] = [], options?: IGqlBaseOptions): Observable<IApiBillingRuleCategoryConnection> {
    return this.service.get(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddBillingRuleCategoryInput): Observable<IApiBillingRuleCategory> {
    return this.service.add(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.service.remove(id);
  }

  public update(input: IApiUpdateBillingRuleInput): Observable<IApiBillingRuleCategory> {
    return this.service.update(input);
  }

  public getById(id: string): Observable<IApiBillingRuleCategory> {
    return this.service.getById(id);
  }
}
