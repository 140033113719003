<div [dialogRef]="dialog" (appClickOutside)="close()">
    <kendo-dialog-titlebar (close)="close()">
        <div class="modal-title">
            <span>Add Comment</span>
        </div>
    </kendo-dialog-titlebar>
    <section>
        <form #commentForm="ngForm">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
                <kendo-gridlayout-item>
                    <kendo-formfield>
                        <kendo-label class="asterisk" text="Comment"></kendo-label>
                        <kendo-textarea name="commentName" #commentName="ngModel" [(ngModel)]="comment" 
                        placeholder="Enter Comment" fillMode="outline"
                        class="min-height-96" resizable="both" [required]="true" appNoWhiteSpace></kendo-textarea>
                        <kendo-formerror *ngIf="commentName?.errors">
                          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                          <span *ngIf="commentName?.errors.required">Comment cannot be left blank</span>
                        </kendo-formerror>
                      </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </form>
    </section>
    <kendo-dialog-actions layout="end" class="p-24">
        <button kendoButton [size]="'medium'" themeColor="primary" class="mb-16" style="width: 100%;" (click)="addComment()">
            Add Comment
        </button>
        <button kendoButton [size]="'medium'" themeColor="light" style="width: 100%;" (click)="close()">
            Cancel
        </button>
    </kendo-dialog-actions>
</div>