<div [dialogRef]="dialog" (appClickOutside)="dialog.close(false)">
  <!-- Permissions -->
  <div [appEnforcePermission]="authConfig.staffContactInformationUpdate" #staffContactInformationUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.staffUserInformationUpdate" #staffUserInformationUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.staffEmergencyInformationUpdate" #staffEmergencyInformationUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.staffWorkingHoursUpdate" #staffWorkingHoursUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.staffContactInformationOwnUpdate" #staffContactInformationOwnUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.staffEmergencyInformationOwnUpdate" #staffEmergencyInformationOwnUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.staffUserInformationOwnUpdate" #staffUserInformationOwnUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.staffWorkingHoursOwnUpdate" #staffWorkingHoursOwnUpdate="appEnforcePermission"></div>
  <!-- Permissions -->
  <section>
    <!-- CONTACT INFO -->
    <form #userForm="ngForm" [appMatchPassword]="['tPassword', 'vPassword']">
      <!-- --------------------------------------------------- -->
      <!-- Contact Information -->
      <!-- --------------------------------------------------- -->
      <kendo-card class="mb-16" *ngIf="staffContactInformationUpdate?.enabled || (staffContactInformationOwnUpdate?.enabled && isOwnerUser)" [width]="'100%'">
        <kendo-card-header>
          <h4 class="mb-0">Contact Information</h4>
        </kendo-card-header>
        <kendo-card-body>
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(6,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label text="Title"></kendo-label>
                <kendo-textbox appNoWhiteSpace name="title" fillMode="outline" #title="ngModel" [clearButton]="false"
                  placeholder="Enter Title" [(ngModel)]="user.title" [maxlength]="255">
                </kendo-textbox>
                <kendo-formerror
                  *ngIf="!title?.valid && (title?.dirty || title?.touched) && title?.errors?.required">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span>
                    Title cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label class="asterisk" text="First Name"></kendo-label>
                <kendo-textbox appNoWhiteSpace name="firstName" fillMode="outline" #firstName="ngModel" [clearButton]="false"
                  placeholder="Enter First Name" [(ngModel)]="user.firstName" [maxlength]="255" required>
                </kendo-textbox>
                <kendo-formerror
                  *ngIf="!firstName?.valid && (firstName?.dirty || firstName?.touched) && firstName?.errors?.required">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span>
                    First Name cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label text="Middle Name"></kendo-label>
                <kendo-textbox appNoWhiteSpace name="middleName" fillMode="outline" #middleName="ngModel" [clearButton]="false"
                  placeholder="Enter Middle Name" [(ngModel)]="user.middleName" [maxlength]="255">
                </kendo-textbox>
                <kendo-formerror
                  *ngIf="!middleName?.valid && (middleName?.dirty || middleName?.touched) && middleName?.errors?.required">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span>
                    Middle Name cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label class="asterisk" text="Last Name"></kendo-label>
                <kendo-textbox appNoWhiteSpace name="lastName" fillMode="outline" #lastName="ngModel" [clearButton]="false"
                  placeholder="Enter Last Name" [(ngModel)]="user.lastName" [maxlength]="255" required>
                </kendo-textbox>
                <kendo-formerror
                  *ngIf="!lastName?.valid && (lastName?.dirty || lastName?.touched) && lastName?.errors?.required">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span>
                    Last Name cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label text="Suffix"></kendo-label>
                <kendo-textbox appNoWhiteSpace name="suffix" fillMode="outline" #suffix="ngModel" [clearButton]="false"
                  placeholder="Enter Suffix" [(ngModel)]="user.suffix" [maxlength]="255">
                </kendo-textbox>
                <kendo-formerror
                  *ngIf="!suffix?.valid && (suffix?.dirty || suffix?.touched) && suffix?.errors?.required">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span>
                    Suffix cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label text="Nickname"></kendo-label>
                <kendo-textbox appNoWhiteSpace name="nickname" fillMode="outline" #nickname="ngModel" [clearButton]="false"
                  placeholder="Enter Nickname" [(ngModel)]="user.nickname" [maxlength]="255">
                </kendo-textbox>
                <kendo-formerror
                  *ngIf="!nickname?.valid && (nickname?.dirty || nickname?.touched) && nickname?.errors?.required">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span>
                    Nickname cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item [colSpan]="3">
              <kendo-formfield>
                <kendo-label text="Microsoft O365 ID"></kendo-label>
                <kendo-textbox appNoWhiteSpace name="msGraphId" fillMode="outline" #msGraphId="ngModel" [clearButton]="false"
                  placeholder="Enter Microsoft O365 ID" [(ngModel)]="user.msGraphId" [maxlength]="255">
                </kendo-textbox>
                <kendo-formerror
                  *ngIf="!msGraphId?.valid && (msGraphId?.dirty || msGraphId?.touched) && msGraphId?.errors?.required">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span>
                    Microsoft O365 ID cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item [colSpan]="3">
              <kendo-formfield>
                <kendo-label [ngClass]="'asterisk'" text="SMS/Voice Phone Number"></kendo-label>
                <kendo-maskedtextbox #twilioPhoneNumber="ngModel" [name]="'twilioPhoneNumber'" [(ngModel)]="user.twilioPhoneNumber" fillMode="outline"
                  placeholder="Enter SMS/Voice Phone Number" mask="(000) 000-0000" required></kendo-maskedtextbox>
                <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
                <kendo-formerror *ngIf="!twilioPhoneNumber?.valid && (twilioPhoneNumber?.dirty || twilioPhoneNumber?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="twilioPhoneNumber?.errors?.required">
                    SMS/Voice Phone Number cannot be left blank
                  </span>
                  <span *ngIf="twilioPhoneNumber?.errors?.patternError || twilioPhoneNumber?.errors?.pattern">
                    Invalid SMS/Voice Phone Number
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item [colSpan]="3">
              <kendo-formfield>
                <kendo-label class="asterisk" [for]="email" text="Application E-Mail"></kendo-label>
                <kendo-textbox
                  appNoWhiteSpace
                  appEmailValidator
                  name="email"
                  #email="ngModel"
                  [(ngModel)]="user.email"
                  fillMode="outline"
                  [clearButton]="false"
                  [required]="true"
                ></kendo-textbox>
                <kendo-formhint>
                  <span *ngIf="user?.id">PLEASE NOTE: changing this e-mail will require the user to re-verify
                    their e-mail to log into this application.
                  </span>
                  <span *ngIf="!user?.id">This must be unique, no emails can be repeated between users for this entry.</span>
                </kendo-formhint>
                <kendo-formerror *ngIf="!email?.valid && (email?.dirty || email?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="email?.errors?.required">
                    Application e-mail cannot be left blank
                  </span>
                  <span *ngIf="email?.errors?.email">
                    A valid application e-mail must be entered
                  </span>
                </kendo-formerror>
              </kendo-formfield>
  
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item [colSpan]="3">
              <kendo-formfield>
                <kendo-label text="Timezone"></kendo-label>
                <kendo-dropdownlist #timezone="ngModel" [data]="timeZones" name="timezone" textField="key" valueField="value"
                  [valuePrimitive]="true" [(ngModel)]="user.timezone" fillMode="outline"
                  [defaultItem]="{value: '', key: 'Select timezone'}" [popupSettings]="{appendTo: 'component', animate: false}">
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span [ngClass]="dataItem?.value ? 'not-placeholder' : 'placeholder'"> {{ dataItem.key }} </span>
                  </ng-template>
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="!timezone?.valid && (timezone?.dirty || timezone?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="timezone?.errors?.required">
                    Timezone cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item [colSpan]="3" *ngIf="!user.id">
              <kendo-formfield>
                <kendo-label class="asterisk" text="Temporary Password"></kendo-label>
                <kendo-textbox #passwordType appNoWhiteSpace name="tPassword" fillMode="outline" #tPassword="ngModel" [clearButton]="false"
                  placeholder="Enter Temporary Password" [(ngModel)]="password" required [maxlength]="255"
                  pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,22}$">
                </kendo-textbox>
                <kendo-formhint>Password needs to be at least 10 characters long, include (at least) one special character
                  (&#64;$!%*#?&) and one number.
                </kendo-formhint>
                <kendo-formerror *ngIf="!tPassword?.valid && (tPassword?.dirty || tPassword?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="tPassword?.errors?.required">
                    Temporary password cannot be left blank
                  </span>
                  <span *ngIf="tPassword?.errors?.pattern?.requiredPattern">
                    Password needs to be at least 10 characters long, include (at least) one special character
                  (&#64;$!%*#?&) and one number.
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item [colSpan]="3" *ngIf="!user.id">
              <kendo-formfield>
                <kendo-label class="asterisk" text="Confirm Temporary Password"></kendo-label>
                <kendo-textbox #passwordConfirmType appNoWhiteSpace name="vPassword" fillMode="outline" #vPassword="ngModel" [clearButton]="false"
                  placeholder="Enter Verify Password" [(ngModel)]="verifyPassword" required [maxlength]="255">
                </kendo-textbox>
                <kendo-formerror *ngIf="!vPassword?.valid && (vPassword?.dirty || vPassword?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="vPassword?.errors?.required">
                    Temporary password cannot be left blank
                  </span>
                  <span *ngIf="vPassword?.errors?.passwordMismatch">
                    Passwords must match
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
  
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
            <ng-container *ngFor="let phone of user.Phones; let i = index;">
              <app-phone class="mb-3" [(ngModel)]="user.Phones[i]" [name]="'phone' + i" (isPrimary)="isPrimaryToggle($event, i)"
              (remove)="removePhone(i)"></app-phone>
            </ng-container>
  
            <kendo-gridlayout-item [colSpan]="2">
              <button kendoButton class="mr-24" themeColor="primary" fillMode="link" iconClass="fas fa-plus" (click)="addPhone();">Add Phone</button>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
  
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
            <ng-container *ngFor="let email of user.Emails; let i = index;">
              <app-email class="mb-3" [(ngModel)]="user.Emails[i]" [name]="'email' + i" (remove)="removeEmail(i)"></app-email>
            </ng-container>
  
            <kendo-gridlayout-item [colSpan]="2">
              <button kendoButton class="mr-24" themeColor="primary" fillMode="link" iconClass="fas fa-plus" (click)="addEmail();">Add Email</button>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
  
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
            <ng-container *ngFor="let address of user.Addresses; let i = index; trackBy: trackByIndex">
              <app-address-autocomplete *ngIf="!!mapsLoaded" [address]="user.Addresses[i]"
                (setAddress)="setAddress($event, i)" [loadAPI]="false" [showType]="true" [showRemove]="true"
                defaultType="Home" (remove)="removeAddress(i)"></app-address-autocomplete>
            </ng-container>
            <kendo-gridlayout-item [colSpan]="2">
              <button kendoButton themeColor="primary" fillMode="link" iconClass="fas fa-plus" (click)="addAddress();">Add Address</button>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
          
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label text="Additional Information"></kendo-label>
                <kendo-textarea name="additionalInformation" #additionalInformation="ngModel"
                  [(ngModel)]="user.UserDetail.additionalInformation" placeholder="Enter Additional Information" fillMode="outline"
                  class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
                <kendo-formerror *ngIf="additionalInformation?.errors">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="additionalInformation?.errors.required">Additional Information cannot be left blank</span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
  
        </kendo-card-body>
      </kendo-card>
      <!-- --------------------------------------------------- -->
      <!-- Emergency Contact Information -->
      <!-- --------------------------------------------------- -->
      <kendo-card class="mb-16" *ngIf="staffEmergencyInformationUpdate?.enabled || (staffEmergencyInformationOwnUpdate?.enabled && isOwnerUser)" [width]="'100%'">
        <kendo-card-header>
          <h4 class="mb-0">Emergency Contact Information</h4>
        </kendo-card-header>
        <kendo-card-body>
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label text="Name"></kendo-label>
                <kendo-textbox appNoWhiteSpace name="emergencyContactName" fillMode="outline" #emergencyContactName="ngModel" [clearButton]="false"
                  placeholder="Enter Name" [(ngModel)]="user.UserDetail.emergencyContactName" [maxlength]="255">
                </kendo-textbox>
                <kendo-formerror
                  *ngIf="!emergencyContactName?.valid && (emergencyContactName?.dirty || emergencyContactName?.touched) && emergencyContactName?.errors?.required">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span>
                    Name cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label text="Phone"></kendo-label>
                <kendo-maskedtextbox #emergencyContactInfo="ngModel" [name]="'emergencyContactInfo'" [(ngModel)]="user.UserDetail.emergencyContactInfo" fillMode="outline"
                  placeholder="Enter Phone" mask="(000) 000-0000"></kendo-maskedtextbox>
                <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
                <kendo-formerror *ngIf="!emergencyContactInfo?.valid && (emergencyContactInfo?.dirty || emergencyContactInfo?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="emergencyContactInfo?.errors?.required">
                    Phone cannot be left blank
                  </span>
                  <span *ngIf="emergencyContactInfo?.errors?.patternError || emergencyContactInfo?.errors?.pattern">
                    Invalid Phone
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </kendo-card-body>
      </kendo-card>
      <!-- --------------------------------------------------- -->
      <!-- User Information -->
      <!-- --------------------------------------------------- -->
      <kendo-card *ngIf="staffUserInformationUpdate?.enabled || (staffUserInformationOwnUpdate?.enabled && isOwnerUser)" [width]="'100%'">
        <kendo-card-header>
          <h4 class="mb-0">User Information</h4>
        </kendo-card-header>
        <kendo-card-body>
  
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(6,minmax(0,1fr))']">
            <kendo-gridlayout-item>
              <div class="kendo-formfield">
                <kendo-label class="asterisk" text="Start Date"></kendo-label>
                <kendo-datepicker id="startDate" name="startDate" #startDate="ngModel" [fillMode]="'outline'" placeholder="Start date"
                  [ngClass]="{'ng-invalid ng-touched': (!startDate?.valid && (startDate?.dirty || startDate?.touched))}" format="MM/dd/yyyy"
                  [(ngModel)]="user.UserDetail.startDate" formatPlaceholder="formatPattern" required="true"
                  calendarType="classic"></kendo-datepicker>
                <kendo-formerror *ngIf="!startDate?.valid && (startDate?.dirty || startDate?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="startDate?.errors?.required">
                    Start date cannot be left blank
                  </span>
                </kendo-formerror>
              </div>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item>
              <div class="kendo-formfield">
                <kendo-label text="Recived Date (End)"></kendo-label>
                <kendo-datepicker id="endDate" name="endDate" #endDate="ngModel" [fillMode]="'outline'" placeholder="End date"
                  [ngClass]="{'ng-invalid ng-touched': !endDate?.valid && (endDate?.dirty || endDate?.touched)}" format="MM/dd/yyyy"
                  [(ngModel)]="user.UserDetail.endDate" formatPlaceholder="formatPattern"
                  calendarType="classic" [min]="user.UserDetail.startDate"></kendo-datepicker>
                <kendo-formerror *ngIf="!endDate?.valid && (endDate?.dirty || endDate?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="endDate?.errors?.required">
                    End date cannot be left blank
                  </span>
                  <span *ngIf="endDate?.errors?.minError">
                    End date should be greater than start date
                  </span>
                </kendo-formerror>
              </div>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item [colSpan]="2">
              <kendo-formfield>
                <kendo-label text="Status"></kendo-label>
                <div class="kendo-radio">
                    <input kendoRadioButton type="radio" id="active" name="userStatus" [(ngModel)]="user.UserDetail.status" [value]="userDetailStatus.Active" />
                    <label class="k-radio-label mr-24" for="active">Active</label>
                    <input kendoRadioButton type="radio" id="on-leave" name="userStatus" [(ngModel)]="user.UserDetail.status" [value]="userDetailStatus.OnLeave" />
                    <label class="k-radio-label mr-24" for="on-leave">On Leave</label>
                    <input kendoRadioButton type="radio" id="inactive" name="userStatus" [(ngModel)]="user.UserDetail.status" [value]="userDetailStatus.Inactive" />
                    <label class="k-radio-label" for="inactive">In Active</label>
                </div>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item [colSpan]="2" [rowSpan]="5">
              <kendo-formfield>
                <kendo-label text="Restrictions"></kendo-label>
                <kendo-multiselect 
                  #restriction="ngModel" 
                  [(ngModel)]="selectedRestrictions"
                  name="restriction"
                  [data]="restrictions"
                  [checkboxes]="true"
                  [valuePrimitive]="true"
                  [autoClose]="false"
                  textField="title" 
                  valueField="id" 
                  fillMode="outline"
                  [tagMapper]="tagMapper"
                  [placeholder]="'Select Restrictions'"
                  [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}">
                  <ng-template kendoMultiSelectItemTemplate let-dataItem>
                    <span style="padding-left: 5px;">{{ dataItem?.title }}</span>
                  </ng-template>
                </kendo-multiselect>
              </kendo-formfield>
  
              <p *ngFor="let selectedRestriction of selectedRestrictions; let first = first;"
                [ngClass]="first ? 'mt-16': ''" [innerHtml]="selectedTitle(selectedRestriction, restrictions)"></p>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label [text]="'Pay Rate'"></kendo-label>
                <div class="numeric-text-suffix">
                  <kendo-numerictextbox placeholder="Enter Pay Rate" name="rate" fillMode="outline" [autoCorrect]="false"
                    [(ngModel)]="user.UserDetail.payRateHourly" #rate="ngModel">
                  </kendo-numerictextbox>
                </div>
                <kendo-formhint>$ / hr</kendo-formhint>
              </kendo-formfield>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item>
              <ng-container *ngIf="staffWorkingHoursUpdate?.enabled || (staffWorkingHoursOwnUpdate?.enabled && isOwnerUser)">
                <kendo-formfield>
                  <kendo-label class="asterisk" [text]="'Required Hours'"></kendo-label>
                  <div class="numeric-text-suffix">
                    <kendo-numerictextbox placeholder="Enter Required Hours" name="requiredHour" fillMode="outline" [autoCorrect]="false"
                      [(ngModel)]="user.UserDetail.requiredHoursQuarterly" #requiredHour="ngModel" required>
                    </kendo-numerictextbox>
                  </div>
                  <kendo-formhint>$ / quarter</kendo-formhint>
                  <kendo-formerror *ngIf="!requiredHour?.valid && (requiredHour?.dirty || requiredHour?.touched)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="requiredHour?.errors?.required">
                      Required hour cannot be left blank
                    </span>
                  </kendo-formerror>
                </kendo-formfield>
              </ng-container>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item [colSpan]="2">
              <kendo-formfield>
                <kendo-label text="Full Time"></kendo-label>
                <div class="kendo-radio">
                    <input kendoRadioButton type="radio" id="yes" name="isFulltime" [(ngModel)]="user.UserDetail.fullTime" [value]="true" />
                    <label class="k-radio-label mr-24" for="yes">Yes</label>
                    <input kendoRadioButton type="radio" id="no" name="isFulltime" [(ngModel)]="user.UserDetail.fullTime" [value]="false" />
                    <label class="k-radio-label" for="no">No</label>
                </div>
              </kendo-formfield>
            </kendo-gridlayout-item>
            
            <kendo-gridlayout-item [colSpan]="2">
              <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Permission Role"></kendo-label>
                    <kendo-multiselect 
                      #permissionRole="ngModel" 
                      [(ngModel)]="PermissionIds"
                      name="permissionRole"
                      [data]="permRoles"
                      [checkboxes]="true"
                      [valuePrimitive]="true"
                      [autoClose]="false"
                      textField="name" 
                      valueField="id" 
                      fillMode="outline"
                      [tagMapper]="tagMapper"
                      [placeholder]="'Select Permission Role'"
                      [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}"
                      [required]="true">
                      <ng-template kendoMultiSelectItemTemplate let-dataItem>
                        <span style="padding-left: 5px;">{{ dataItem?.name }}</span>
                      </ng-template>
                    </kendo-multiselect>
                  </kendo-formfield>
                  <kendo-formerror *ngIf="!permissionRole?.valid && (permissionRole?.dirty || permissionRole?.touched)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="permissionRole?.errors?.required">
                      Permission role cannot be left blank
                    </span>
                  </kendo-formerror>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item  *ngIf="user.RegionalManagerId || user.Positions?.filter(regionalOrInvestigatorFilter).length > 0">
                  <app-user-select-kendo [userViewFilter]="userViewFilter" label="Assign Regional Manager" placeholder="Search ..."
                    [userId]="user.RegionalManagerId" (selectionChanged)="this.regionalManagerId = $event?.id"
                    [excludeLoginUserId]="this.data?.userId"></app-user-select-kendo>
                </kendo-gridlayout-item>
  
                <kendo-gridlayout-item *ngIf="user.Positions?.filter(investigatorFilter).length > 0">
                  <p class="mb-2">Assigned Staff For Tech Reviewer: {{ (assignedStaff?.length || user.AssignedStaff?.length) || 0 }} </p>
                  <button kendoButton [size]="'medium'" [themeColor]="'primary'" [fillMode]="'link'" [iconClass]="'fa fa-plus'"
                    (click)="assignStaff()">
                    Assign Staff
                  </button>
                </kendo-gridlayout-item>
  
                <kendo-gridlayout-item *ngIf="user.Positions?.filter(regionalManagerFilter).length > 0">
                  <p class="mb-2">Assigned Staff For Regional Manager: {{ (assignedRegionalStaff?.length || user.AssignedRegionalStaff?.length) || 0 }} </p>
                  <button kendoButton [size]="'medium'" [themeColor]="'primary'" [fillMode]="'link'" [iconClass]="'fa fa-plus'"
                  (click)="assignStaffToRegionalManager()">
                  Assign Staff
                </button>
                </kendo-gridlayout-item>
              </kendo-gridlayout>
            </kendo-gridlayout-item>
  
            <kendo-gridlayout-item [colSpan]="2">
              <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label text=""></kendo-label>
                    <div class="k-checkbox-wrap mb-16">
                      <input
                        #creditCard
                        type="checkbox"
                        kendoCheckBox
                        name="hasCreditCard"
                        [(ngModel)]="user.UserDetail.hasNefcoCreditCard"
                      />
                      <kendo-label
                        [for]="creditCard"
                        class="k-checkbox-label font-weight-600 checkbox-margin"
                        style="margin-bottom: 0"
                        [text]="'NEFCO Credit Card'"
                      ></kendo-label>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
      
                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label text=""></kendo-label>
                    <div class="k-checkbox-wrap mb-16">
                      <input
                        #vehicle
                        type="checkbox"
                        kendoCheckBox
                        name="hasVehicle"
                        [(ngModel)]="user.UserDetail.hasNefcoVehicle"
                      />
                      <kendo-label
                        [for]="vehicle"
                        class="k-checkbox-label font-weight-600 checkbox-margin"
                        style="margin-bottom: 0"
                        [text]="'NEFCO Vehicle'"
                      ></kendo-label>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
  
                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label text="Drivers License"></kendo-label>
                    <kendo-textbox name="driversLicenseNumber" #driversLicenseNumber="ngModel" [(ngModel)]="user.UserDetail.driversLicenseNumber"
                      placeholder="Drivers License" fillMode="outline" appNoWhiteSpace [clearButton]="false" [required]="false" pattern="^[a-zA-Z0-9]*$">
                    </kendo-textbox>
                    <kendo-formerror *ngIf="driversLicenseNumber?.invalid && (driversLicenseNumber?.dirty || driversLicenseNumber?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="driversLicenseNumber?.errors?.required">Drivers License cannot be left blank</span>
                      <span *ngIf="!driversLicenseNumber?.errors?.required && driversLicenseNumber?.errors?.pattern ">Invalid Drivers License</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>
  
                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label [text]="'Licensed State'"></kendo-label>
                    <kendo-dropdownlist
                      [data]="groupedDataStates"
                      name="driversLicenseState"
                      textField="text"
                      valueField="value"
                      [(ngModel)]="user.UserDetail.driversLicenseState"
                      [valuePrimitive]="true"
                      fillMode="outline"
                      [filterable]="false"
                      (filterChange)="handleFilter($event)"
                      [defaultItem]="{ value: '', text: 'Select Licensed State', type: '' }"
                      [popupSettings]="{ appendTo: 'component', animate: false, width: 250 }"
                    >
                      <ng-template kendoDropDownListGroupTemplate let-groupName>
                        <strong>{{ groupName }}</strong>
                      </ng-template>
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span [ngClass]="dataItem?.value ? 'not-placeholder' : 'placeholder'">
                          {{ dataItem.text }}
                        </span>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>
  
                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label [text]="'Registered State'"></kendo-label>
                    <kendo-dropdownlist
                      [data]="groupedDataStates"
                      name="vehicleRegistrationState"
                      textField="text"
                      valueField="value"
                      [(ngModel)]="user.UserDetail.vehicleRegistrationState"
                      [valuePrimitive]="true"
                      fillMode="outline"
                      [filterable]="false"
                      (filterChange)="handleFilter($event)"
                      [defaultItem]="{ value: '', text: 'Select Registered State', type: '' }"
                      [popupSettings]="{ appendTo: 'component', animate: false, width: 250 }"
                    >
                      <ng-template kendoDropDownListGroupTemplate let-groupName>
                        <strong>{{ groupName }}</strong>
                      </ng-template>
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span [ngClass]="dataItem?.value ? 'not-placeholder' : 'placeholder'">
                          {{ dataItem.text }}
                        </span>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>
  
                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label text="Insurance Company"></kendo-label>
                    <kendo-textbox name="vehicleInsurance" #vehicleInsurance="ngModel" [(ngModel)]="user.UserDetail.vehicleInsuranceCompany"
                      placeholder="Insurance Company" fillMode="outline" appNoWhiteSpace [clearButton]="false" [required]="false">
                    </kendo-textbox>
                    <kendo-formerror *ngIf="vehicleInsurance?.invalid && (vehicleInsurance?.dirty || vehicleInsurance?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="vehicleInsurance?.errors?.required">Insurance Company cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>

                <ng-container *ngFor="let vehicle of user.Vehicles; let i = index;">
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label class="asterisk" text="Vehicle Make"></kendo-label>
                      <kendo-textbox name="vehicleMake{{i}}" #vehicleMake="ngModel" [(ngModel)]="vehicle.make"
                        placeholder="Vehicle Make" fillMode="outline" appNoWhiteSpace [required]="true" [clearButton]="false">
                      </kendo-textbox>
                      <kendo-formerror *ngIf="vehicleMake?.invalid && (vehicleMake?.dirty || vehicleMake?.touched)">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="vehicleMake?.errors?.required">Vehicle Make cannot be left blank</span>
                      </kendo-formerror>
                    </kendo-formfield>
                  </kendo-gridlayout-item>

                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label class="asterisk" text="Vehicle Model"></kendo-label>
                      <kendo-textbox name="vehicleModel{{i}}" #vehicleModel="ngModel" [(ngModel)]="vehicle.model"
                        placeholder="Vehicle Model" fillMode="outline" appNoWhiteSpace [required]="true" [clearButton]="false">
                      </kendo-textbox>
                      <kendo-formerror *ngIf="vehicleModel?.invalid && (vehicleModel?.dirty || vehicleModel?.touched)">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="vehicleModel?.errors?.required">Vehicle Model cannot be left blank</span>
                      </kendo-formerror>
                    </kendo-formfield>
                  </kendo-gridlayout-item>

                  <kendo-gridlayout-item [colSpan]="2">
                    <kendo-formfield>
                      <kendo-label class="asterisk" text="VIN"></kendo-label>
                      <kendo-textbox name="vehicleVin{{i}}" #vehicleVin="ngModel" [(ngModel)]="vehicle.vinNumber"
                        placeholder="VIN" fillMode="outline" appNoWhiteSpace [required]="true" [clearButton]="false">
                      </kendo-textbox>
                      <kendo-formerror *ngIf="vehicleVin?.invalid && (vehicleVin?.dirty || vehicleVin?.touched)">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="vehicleVin?.errors?.required">VIN cannot be left blank</span>
                      </kendo-formerror>
                    </kendo-formfield>
                  </kendo-gridlayout-item>

                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label class="asterisk" text="Year"></kendo-label>
                      <kendo-textbox name="vehicleYear{{i}}" #vehicleYear="ngModel" [(ngModel)]="vehicle.year" placeholder="Year"
                        fillMode="outline" appNoWhiteSpace [required]="true" [maxlength]="4" [clearButton]="false"
                        pattern="^[0-9]*$">
                      </kendo-textbox>
                      <kendo-formerror *ngIf="vehicleYear?.invalid && (vehicleYear?.dirty || vehicleYear?.touched)">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="vehicleYear?.errors?.required">Year cannot be left blank</span>
                        <span *ngIf="!vehicleYear?.errors?.required && vehicleYear?.errors?.pattern">Invalid Year</span>
                        <span *ngIf="!vehicleYear?.errors?.required && vehicleYear?.errors?.maxlength">Year be maximum 4 number.</span>
                      </kendo-formerror>
                    </kendo-formfield>
                  </kendo-gridlayout-item>

                  <kendo-gridlayout-item>
                    <div class="kendo-formfield remove">
                      <kendo-label text=""></kendo-label>
                      <div>
                        <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-circle-xmark fa-lg"
                            (click)="removeVehicle(i)"></button>
                      </div>
                    </div>
                  </kendo-gridlayout-item>
                </ng-container>
  
                <kendo-gridlayout-item [colSpan]="2">
                  <button kendoButton [size]="'medium'" [themeColor]="'primary'" [fillMode]="'link'" [iconClass]="'fa fa-plus'"
                    (click)="addVehicle()">
                    Add Vehicle
                  </button>
                </kendo-gridlayout-item>
              </kendo-gridlayout>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </kendo-card-body>
      </kendo-card>
    </form>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="dialog.close(false)">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onUserSubmit()"
      [disabled]="!validForm()">Save
    </button>
  </kendo-dialog-actions>
</div>