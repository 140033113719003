import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  requiresFollowUp
  comment
  User {
    id
    firstName
    lastName
  }
  Investigation {
    id
  }

`;

export const investigationStatusConnectionQuery = gql`${buildConnectionQuery("InvestigationStatus", "investigationStatusConnection", nodeFragment)}`;

export const addInvestigationStatusMutation = gql`mutation AddInvestigationStatus($input: AddInvestigationStatusInput!) {
  addInvestigationStatus(input: $input) { ${nodeFragment} }
}`;

export const updateInvestigationStatusMutation = gql`mutation UpdateInvestigationStatus($input: UpdateInvestigationStatusInput!) {
  updateInvestigationStatus(input: $input) { ${nodeFragment} }
}`;

export const removeInvestigationStatusMutation = gql`mutation RemoveInvestigationStatus($id: ID!) {
  removeInvestigationStatus(id: $id)
}`;
