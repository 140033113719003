import { GqlExpenseItemService } from '../../../modules/graphql/services/expenses/expense-item/expense-item.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../../modules/graphql/interfaces/base.interfaces';
import { IApiAddExpenseItemInput, IApiExpenseItem, IApiExpenseItemConnection, IApiExpenseItemFilter, IApiUpdateExpenseItemInput } from "../../../modules/graphql/types/types";
import { ListPage } from "src/app/shared/helpers/list-page.class";


@Injectable()
export class ExpenseItemService {

  constructor(
    private gqlExpenseItemService: GqlExpenseItemService
  ) { }

  public get(filters: IApiExpenseItemFilter[] = [], options?: IGqlBaseOptions): Observable<IApiExpenseItemConnection> {
    return this.gqlExpenseItemService.getExpenseItems(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddExpenseItemInput): Observable<IApiExpenseItem> {
    return this.gqlExpenseItemService.addExpenseItem(input);
  }

  public update(input: IApiUpdateExpenseItemInput): Observable<IApiExpenseItem> {
    return this.gqlExpenseItemService.updateExpenseItem(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlExpenseItemService.removeExpenseItem(id);
  }

  public activate(id: string): Observable<boolean> {
    return this.gqlExpenseItemService.activateExpenseItem(id);
  }

  public deactivate(id: string): Observable<boolean> {
    return this.gqlExpenseItemService.deactivateExpenseItem(id);
  }

  public accountSummaryExpenseReport(filters?: IApiExpenseItemFilter[], options?: IGqlBaseOptions): Observable<string> {
    return this.gqlExpenseItemService.accountSummaryExpenseReport(filters, options || new ListPage(-1));
  }

}
