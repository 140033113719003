import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { investigationStatusConnectionQuery, addInvestigationStatusMutation, removeInvestigationStatusMutation, updateInvestigationStatusMutation } from './investigation-status.operations';
import { IApiInvestigationStatusConnection } from '../../../types/types';
import { safeResolve } from '../../../rxjs';

import {
  IApiInvestigationStatusFilter,
  IApiAddInvestigationStatusInput,
  IApiUpdateInvestigationStatusInput,
  IApiRemoveInvestigationStatusInput,
} from '../../../types/types';

@Injectable()
export class GqlInvestigationStatusService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getInvestigationStatus(filters: IApiInvestigationStatusFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationStatusConnection> {
    return this.apollo.query(connectionQuery(investigationStatusConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addInvestigationStatus(input: IApiAddInvestigationStatusInput) {
    return this.apollo.mutate({
      mutation: addInvestigationStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeInvestigationStatus(input: IApiRemoveInvestigationStatusInput) {
    return this.apollo.mutate({
      mutation: removeInvestigationStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateInvestigationStatus(input: IApiUpdateInvestigationStatusInput) {
    return this.apollo.mutate({
      mutation: updateInvestigationStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
