<section>
  <h4 class="mb-8">All Notifications</h4>
  <p class="mb-24">Notifications automatically expire after 30 days.</p>
  <kendo-card [width]="'100%'" *ngIf="!notifications?.length && !this.loader?.active">
    <kendo-card-body class='no-notifications'>
      <h6>No Notifications</h6>
      <p>You’re all caught up! Looks like you don’t have any notifications.</p>
      <button [routerLink]="['/']" kendoButton [size]="'medium'" themeColor="primary" fillMode="solid">Go Back to
        Dashboard</button>
    </kendo-card-body>
  </kendo-card>

  <kendo-card [width]="'100%'" *ngIf="notifications?.length">
    <kendo-card-header style="padding: 16px 24px;">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item class="mb-0 action-header">
          <h2 class="mb-0">Unread ({{getCount}})</h2>
          <div class="pt-0" style="display: flex; align-items: center">
            <h6 class="mb-0 mr-16">{{selection.selected?.length}} items selected</h6>
            <button class="mr-16" kendoButton [size]="'medium'" themeColor="light" fillMode="solid"
              [disabled]="!selection?.selected?.length" (click)="markNotificationsAsReadOrUnread(false)">
              Mark as Unread
            </button>
            <button kendoButton [size]="'medium'" themeColor="light" fillMode="solid"
              [disabled]="!selection?.selected?.length" (click)="markNotificationsAsReadOrUnread(true)">
              Mark as Read</button>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </kendo-card-header>

    <kendo-card-body style="padding: 24px;">
      <div class="kendo-formfield">
        <div class="k-checkbox-wrap select-All mb-12">
          <input #selectAllChecked type="checkbox" kendoCheckBox (click)="handleMasterToggle()"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" />
          <kendo-label [for]="selectAllChecked" class="k-checkbox-label font-weight-600 checkbox-margin ml-0"
            style="margin-bottom: 0" text="Select All"></kendo-label>
        </div>
      </div>

      <ng-container *ngFor="let notification of notifications;">
        <kendo-card [width]="'100%'" class="card-row">
          <kendo-card-header style="padding: 16px 24px;">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
              <kendo-gridlayout-item class="mb-0">
                <div class="content-details">
                  <div class="content">
                    <div>
                      <div class="kendo-formfield mr-12">
                        <div class="k-checkbox-wrap select-All">
                          <input type="checkbox" kendoCheckBox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(notification) : null"
                            [checked]="selection.isSelected(notification)" />
                        </div>
                      </div>
                      <i [class]="getIcon(notification?.Type?.entity)"></i>
                      <a href="javascript:void(0)" (click)="viewPage(notification)" [innerHTML]="notification?.message"></a>
                    </div>
                    <div class="name-page">
                      {{notification?.Sender ? (notification?.Sender | fullName) : ''}} <span class="color-hide" *ngIf="notification?.Sender">&nbsp;|&nbsp;</span>
                      {{notification?.Type?.entity | quiet}}
                    </div>
                  </div>
                  <div class="sub-content">
                    <div class="time">{{notification?.createdAt | customDate: 'MM/dd/yyyy'}} at {{notification?.createdAt
                      | customDate: 'h:mm a'}}</div>
                    <span *ngIf="!notification?.viewed"><i class="fa-solid fa-circle fa-2xs"></i></span> <!-- UNREAD -->
                    <span *ngIf="notification?.viewed"><i class="fa-solid fa-check"></i></span> <!-- READ -->
                  </div>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-card-header>
        </kendo-card>
      </ng-container>

      <kendo-datapager [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset"
        [total]="(dataSource?.pageInfo$ | async)?.totalCount" (pageChange)="pageChange($event)" class="custom-paginate"
        style="border: none;
          padding-right: 24px;">
        <ng-template kendoDataPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
          <div class="k-pager-numbers-wrap">
            <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
            <kendo-datapager-numeric-buttons [buttonCount]="5"></kendo-datapager-numeric-buttons>
            <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
          </div>
          <kendo-datapager-info></kendo-datapager-info>
          <kendo-datapager-page-sizes [pageSizes]="[5, 10, 25, 100]"></kendo-datapager-page-sizes>
        </ng-template>
      </kendo-datapager>
    </kendo-card-body>
  </kendo-card>
</section>