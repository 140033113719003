<!-- Permissions -->
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseCreate" #expenseUnlinkedExpenseCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesCreate" #expenseInvestigationExpensesCreate="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-drawer #drawer [width]="-1" [mini]="false" [mode]="'overlay'" [position]="'end'" [animation]="true">
    <ng-template kendoDrawerTemplate>
        <section class="content-part safe-area-padding-top">
            <div class="header mb-16"  *ngIf="fromTab else noTab">
                <h6 class="mb-0 notab">Add Expenses</h6>
                <button class="close-action" kendoButton themeColor="primary" fillMode="link"
                [iconClass]="drawer.expanded ? 'fa-solid fa-times fa-xl' : 'fa-solid fa-bars'"
                (click)="drawer.toggle(false); backToStepOne()"></button>
            </div>
            <ng-template #noTab>
                <div class="header mb-16">
                    <button class="back-action" kendoButton themeColor="base" fillMode="link"
                        [iconClass]="drawer.expanded && 'fa-solid fa-arrow-left-long fa-lg'" (click)="backToStepOne()"></button>
                    <h6 class="mb-0">My Expenses</h6>
                </div>
            </ng-template>
            <hr class="divider-line mb-16" />
            <section class="template-form">
                <h6 class="form-title mb-24" *ngIf="!fromTab">Add Expense</h6>
                <form #expenseForm="ngForm" style="margin-bottom: 110px;">
                    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
                    <kendo-gridlayout-item *ngIf="!investigationView">
                        <kendo-formfield class="kendo-formfield">
                        <kendo-label [ngClass]="unlinked ? '' : 'asterisk'" [for]="invlist"
                            text="Investigation ID"></kendo-label>
                        <kendo-dropdownlist #invlist [data]="investigations" textField="nefcoNumber" valueField="id"
                            fillMode="outline" [valuePrimitive]="true" [defaultItem]="{id: null, nefcoNumber: 'Select Investigation'}"
                            name="investigationId"
                            [popupSettings]="{appendTo: 'component', animate: false, popupClass: 'nodata', width: 250 }"
                            [(ngModel)]="expense.InvestigationId" #investigationId="ngModel"
                            [disabled]="expenseInvestigationExpensesCreate?.enabled ? (unlinked) : true"
                            required>
                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.nefcoNumber || dataItem.id}} </span>
                            <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.nefcoNumber || dataItem.id}} </span>
                            </ng-template>
                            <ng-template kendoDropDownListNoDataTemplate>
                            </ng-template>
                        </kendo-dropdownlist>
                        <kendo-formerror *ngIf="!investigationId?.valid && (investigationId?.dirty || investigationId?.touched)">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="investigationId?.errors?.required">
                            Investigation id cannot be left blank
                            </span>
                        </kendo-formerror>
                        </kendo-formfield>
                        <kendo-formfield class="kendo-formfield">
                        <div style="margin-top: 6px;">
                            <input type="checkbox" kendoCheckBox #unlinkedCheck
                            [value]="false"
                            [(ngModel)]="unlinked"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="(!expenseUnlinkedExpenseCreate?.enabled || !expenseInvestigationExpensesCreate?.enabled) ? true : false"/>
                            <kendo-label class="ml-12 mr-24 k-checkbox-filter-label" [for]="unlinkedCheck"
                            text="Unlinked"></kendo-label>
                        </div>
                        </kendo-formfield>
                    </kendo-gridlayout-item>
                    <kendo-gridlayout-item *ngIf="investigationView">
                        <kendo-formfield class="kendo-formfield">
                        <kendo-label [for]="investigationId" text="Investigation ID"></kendo-label>
                        <kendo-textbox name="investigationId" fillMode="outline" [disabled]="true" [(ngModel)]="nefcoNumber"
                            #investigationId="ngModel">
                        </kendo-textbox>
                        </kendo-formfield>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <div class="kendo-formfield">
                        <kendo-label class="asterisk" text="Date"></kendo-label>
                        <kendo-datepicker id="expenseDate" name="expenseDate" calendarType="classic" [fillMode]="'outline'"
                            placeholder="month/day/year" format="MM/dd/yyyy" [(ngModel)]="expense.expenseDate"
                            formatPlaceholder="formatPattern" #expenseDate="ngModel" [required]="true"
                            [disabledDates]="disabledDates"></kendo-datepicker>
                        <kendo-formerror *ngIf="!expenseDate?.valid && (expenseDate?.dirty || expenseDate?.touched)">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span
                            *ngIf="expenseDate?.errors?.required || expenseDate?.errors?.minError || expenseDate?.errors?.maxError">
                            Date cannot be left blank
                            </span>
                            <span *ngIf="expenseDate?.errors?.disabledDate">
                            Date cannot be future
                            </span>
                        </kendo-formerror>
                        </div>
                    </kendo-gridlayout-item>
                    </kendo-gridlayout>

                    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
                    <kendo-gridlayout-item>
                        <kendo-formfield class="left-search-icon">
                        <kendo-label [ngClass]="'asterisk'" [for]="expenseItem" text="Expense Item"></kendo-label>
                        <div style="position: relative;">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <kendo-autocomplete
                            #autoCompleteExpenseItemId
                            #expenseItem="ngModel"
                            [(ngModel)]="selectedTypeName"
                            name="expenseItem"
                            (valueChange)="typeChange($event); typeIdsearch = ''; resetPurposes();"
                            [filterable]="true"
                            (filterChange)="typeIdsearch = $event; !(typeIdsearch) ? autoCompleteExpenseItemId?.toggle(true) : false "
                            [data]="expenseItems | visibleExpenseItems : unlinked: false : typeIdsearch"
                            textField="name"
                            valueField="id"
                            fillMode="outline"
                            [placeholder]="'Select expense item'"
                            (click)="autoCompleteExpenseItemId?.toggle(true)"
                            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
                            required>
                            <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                <span class="not-placeholder"> {{ dataItem?.name}} </span>
                            </ng-template>
                            </kendo-autocomplete>
                        </div>
                        <kendo-formerror *ngIf="!expenseItem?.valid && (expenseItem?.dirty || expenseItem?.touched)">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="expenseItem?.errors?.required">
                            Expense item cannot be left blank
                            </span>
                        </kendo-formerror>
                        </kendo-formfield>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <kendo-formfield class="kendo-formfield">
                        <kendo-label class="asterisk" [for]="purposeFor" text="Purpose"></kendo-label>
                        <kendo-dropdownlist #purposeFor [data]="expensePurposes" textField="name" valueField="id" fillMode="outline"
                            [valuePrimitive]="true" [defaultItem]="{id: null, name: 'Select purpose'}" name="purpose"
                            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
                            [(ngModel)]="expense.ExpensePurposeId" #purpose="ngModel" [disabled]="!expense.ExpenseItemId" required>
                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.name}} </span>
                            <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.name}} </span>
                            </ng-template>
                        </kendo-dropdownlist>
                        <kendo-formerror *ngIf="!purpose?.valid && (purpose?.dirty || purpose?.touched)">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="purpose?.errors?.required">
                            Purpose cannot be left blank
                            </span>
                        </kendo-formerror>
                        </kendo-formfield>
                    </kendo-gridlayout-item>
                    </kendo-gridlayout>

                    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
                    [cols]="['repeat(1,minmax(0,1fr))']">
                    <kendo-gridlayout-item >
                        <kendo-formfield class="kendo-formfield">
                        <kendo-label [ngClass]="'asterisk'" text="Type"></kendo-label>
                        <kendo-dropdownlist
                            [data]="typeArray"
                            textField="name" valueField="value" fillMode="outline" name="type" [valuePrimitive]="true"
                            [defaultItem]="{value: null, name: 'Type'}"
                            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" [(ngModel)]="expense.outOfPocket"
                            #type="ngModel" required>
                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span *ngIf="dataItem?.value === null" class="placeholder"> {{ dataItem?.name}} </span>
                            <span *ngIf="dataItem?.value !== null" class="not-placeholder"> {{ dataItem?.name}} </span>
                            </ng-template>
                        </kendo-dropdownlist>
                        <kendo-formerror *ngIf="!type?.valid && (type?.dirty || type?.touched)">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="type?.errors?.required">
                            Type cannot be left blank
                            </span>
                        </kendo-formerror>
                        </kendo-formfield>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item *ngIf="unlinked === false || investigationView">
                        <kendo-formfield class="kendo-formfield">
                        <kendo-label [text]="'Billable Cost'"></kendo-label>
                        <div class="numeric-text-suffix">
                            <i class="fa-solid fa-dollar-sign"></i>
                            <kendo-numerictextbox placeholder="Enter cost" name="billableQuantity" fillMode="outline"
                            [autoCorrect]="false" [(ngModel)]="expense.billableQuantity"
                            (ngModelChange)="changedBillableNonBillableField()"
                            [ngClass]="billableQuantity?.valid && requiresAuthorization && requiresAuthorizationFilled ? 'warning-field' : ''"
                            #billableQuantity="ngModel">
                            </kendo-numerictextbox>
                        </div>
                        <kendo-formerror *ngIf="!billableQuantity?.valid && (billableQuantity?.dirty || billableQuantity?.touched)">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="billableQuantity?.errors?.required">
                            Billable cost cannot be left blank
                            </span>
                            <span *ngIf="billableQuantity?.errors?.minError">
                            Billable cost cannot be left blank
                            </span>
                        </kendo-formerror>
                        <div *ngIf="requiresAuthorization && billableQuantity?.valid && requiresAuthorizationFilled"
                            class="warning-field-message">
                            <i class="fa-solid fa-triangle-exclamation"></i>
                            <span>Authorization needed</span>
                        </div>
                        </kendo-formfield>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <kendo-formfield class="kendo-formfield">
                        <kendo-label [text]="'Non-Billable Cost'"></kendo-label>
                        <div class="numeric-text-suffix">
                            <i class="fa-solid fa-dollar-sign"></i>
                            <kendo-numerictextbox placeholder="Enter cost" name="nonBillableQuantity" fillMode="outline"
                            [autoCorrect]="false" [(ngModel)]="expense.nonBillableQuantity"
                            (ngModelChange)="changedBillableNonBillableField()"
                            [ngClass]="(nonBillableQuantity?.valid  && requiresAuthorization && requiresAuthorizationFilled) ? 'warning-field' : ''"
                            #nonBillableQuantity="ngModel">
                            </kendo-numerictextbox>
                        </div>
                        <kendo-formerror
                            *ngIf="!nonBillableQuantity?.valid && (nonBillableQuantity?.dirty || nonBillableQuantity?.touched)">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="nonBillableQuantity?.errors?.required">
                            Non-billable cost cannot be left blank
                            </span>
                            <span *ngIf="nonBillableQuantity?.errors?.minError">
                            Non-billable cost cannot be left blank
                            </span>
                        </kendo-formerror>
                        <div *ngIf="requiresAuthorization && nonBillableQuantity?.valid && requiresAuthorizationFilled"
                            class="warning-field-message">
                            <i class="fa-solid fa-triangle-exclamation"></i>
                            <span>Authorization needed</span>
                        </div>
                        </kendo-formfield>
                    </kendo-gridlayout-item>
                    </kendo-gridlayout>

                    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
                    *ngIf="requiresAuthorization && requiresAuthorizationFilled">
                    <kendo-gridlayout-item>
                        <div class="notification-warning-kendo">
                        <div class="icon">
                            <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i>
                        </div>
                        <div class="message">
                            <span>Your expense is over $100. Please add the staff member who authorized this expense.</span>
                        </div>
                        </div>
                    </kendo-gridlayout-item>
                    </kendo-gridlayout>

                    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
                    *ngIf="requiresAuthorization">
                    <kendo-gridlayout-item>
                        <kendo-formfield class="kendo-formfield">
                        <kendo-label class="asterisk" [text]="'Authorized By'"></kendo-label>
                        <kendo-textbox placeholder="Enter who authorized this expense" fillMode="outline" name="authorizedBy"
                            #authorizedBy="ngModel" [clearButton]="true" [(ngModel)]="expense.authorizedBy"
                            [disabled]="!requiresAuthorization" [required]="true"></kendo-textbox>
                        <kendo-formerror *ngIf="!authorizedBy?.valid && (authorizedBy?.dirty || authorizedBy?.touched)">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="authorizedBy?.errors?.required">Authorized by cannot be left blank</span>
                        </kendo-formerror>
                        </kendo-formfield>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                        <kendo-formfield class="kendo-formfield">
                        <kendo-label class="asterisk" text="Date of Authorization"></kendo-label>
                        <kendo-datepicker id="dateOfAuthorization" name="dateOfAuthorization" calendarType="classic"
                            [fillMode]="'outline'" placeholder="Enter date" format="MM/dd/yyyy" [(ngModel)]="expense.authorizedDate"
                            formatPlaceholder="formatPattern" #dateOfAuthorization="ngModel" [disabled]="!requiresAuthorization"
                            [required]="true"></kendo-datepicker>
                        <kendo-formerror
                            *ngIf="!dateOfAuthorization?.valid && (dateOfAuthorization?.dirty || dateOfAuthorization?.touched)">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="dateOfAuthorization?.errors?.required">
                            Date of authorization cannot be left blank
                            </span>
                        </kendo-formerror>
                        </kendo-formfield>
                    </kendo-gridlayout-item>
                    </kendo-gridlayout>

                    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
                    <kendo-gridlayout-item>
                        <kendo-formfield>
                        <kendo-label class="asterisk" text="Description"></kendo-label>
                        <kendo-textarea placeholder="Enter description" fillMode="outline" name="description" class="min-height-96"
                            resizable="both" #description="ngModel" [(ngModel)]="expense.description" [required]="true"
                            appNoWhiteSpace></kendo-textarea>
                        <kendo-formerror *ngIf="!description?.valid && (description?.dirty || description?.touched)">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span *ngIf="description?.errors?.required">Description cannot be left blank</span>
                        </kendo-formerror>
                        </kendo-formfield>
                    </kendo-gridlayout-item>
                    </kendo-gridlayout>

                    <kendo-gridlayout-item *ngIf="expense?.id && investigationView">
                    <kendo-formfield class="kendo-formfield">
                        <div style="margin-bottom: 6px;">
                        <input type="checkbox" kendoCheckBox #paidCheck [value]="false" [(ngModel)]="paidCheckbox"
                            [ngModelOptions]="{standalone: true}" (ngModelChange)="changePaid($event)"/>
                        <kendo-label class="ml-12 mr-24 k-checkbox-filter-label" [for]="paidCheck" text="Paid"></kendo-label>
                        </div>
                    </kendo-formfield>
                    <div class="kendo-formfield" *ngIf="paidCheckbox">
                        <kendo-label class="asterisk" text="Paid Date"></kendo-label>
                        <kendo-datepicker id="paidDate" name="paidDate" calendarType="classic" [fillMode]="'outline'"
                        placeholder="month/day/year" format="MM/dd/yyyy" [(ngModel)]="expense.paidDate"
                        formatPlaceholder="formatPattern" #paidDate="ngModel" [required]="true"></kendo-datepicker>
                        <kendo-formerror *ngIf="!paidDate?.valid && (paidDate?.dirty || paidDate?.touched)">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="paidDate?.errors?.required || paidDate?.errors?.minError || paidDate?.errors?.maxError">
                            Paid Date cannot be left blank
                        </span>
                        <span *ngIf="paidDate?.errors?.disabledDate">
                            Paid Date cannot be future
                        </span>
                        </kendo-formerror>
                    </div>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout class="pl-16 pr-16 text-center" [rows]="['auto']" [gap]="{rows: 16, cols: 16}"
                        [cols]="['repeat(1,minmax(0,1fr))']"
                        style="position: absolute; left:0; right:0; bottom: 0; z-index: 1; background: white;">
                        <hr class="divider-line" />
                        <kendo-gridlayout-item class="mb-48">
                            <kendo-button kendoButton themeColor="primary" type="submit" fillMode="solid" size="large" style="width: 100%;"
                                (click)="onSubmit()">
                                Add Expense
                            </kendo-button>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </form>
            </section>
        </section>
    </ng-template>
</kendo-drawer>
