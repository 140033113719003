<section class="pb-2 py-2">
  <ng-container [ngSwitch]="data.dialogType">
    <h3 class="text-center" *ngSwitchCase="dialogTypes.INVESTIGATION_COMMENT">Investigation Report</h3>
    <h3 class="text-center" *ngSwitchCase="dialogTypes.SENDBACK">Send Back Report</h3>
    <h3 class="text-center" *ngSwitchCase="dialogTypes.INDICATE_CAUSE">Indicate Cause</h3>
    <h3 class="text-center" *ngSwitchCase="dialogTypes.APPROVE">Approve Report</h3>
    <h3 class="text-center" *ngSwitchCase="dialogTypes.ASSIGN">Assign Investigation Report</h3>
    <h3 class="text-center" *ngSwitchCase="dialogTypes.APPROVE_REPORT">Approve Investigation Report</h3>
    <h3 class="text-center" *ngSwitchCase="dialogTypes.REQUEST_UPDATE">Update Request</h3>
    <h3 class="text-center" *ngSwitchCase="dialogTypes.UNASSIGN">Unassign Document</h3>
  </ng-container>
  <h5 class="muted text-center mb-3">Investigation #{{ data.nefcoNumber }}</h5>
  <ng-container [ngSwitch]="data.dialogType">
    <p class="mt-4" *ngSwitchCase="'assign'">Please confirm the assignment of this report.</p>
    <p class="mt-4" *ngSwitchCase="'approve report'">Please confirm the assignment of this report.</p>
    <p class="mt-4" *ngSwitchCase="dialogTypes.UNASSIGN">Please provide a comment for unassigning this document.</p>
  </ng-container>
  <!-- Comment Date and Name -->
  <div class="row" *ngIf="data.dialogType === 'investigation comment'; else formInputs">
    <div class="col-4">
      <p class="muted">Entered by:</p>
      <p> {{ data.assignedUser | fullName }} </p>
    </div>
    <div class="col-4">
      <p class="muted">Date:</p>
      <p [innerHTML]="data.updatedAt | customDate: 'MM/dd/yyyy'"></p>
    </div>
  </div>
  <div class="col-4">
    <p class="muted">Date:</p>
    <p [innerHTML]="data.updatedAt | customDate: 'MM/dd/yyyy'"></p>
  </div>
  <!-- Form input -->
  <ng-template #formInputs>
    <mat-form-field *ngIf="investigationDetail; else causeLoader">
      <mat-label>Cause of Fire</mat-label>
      <mat-select [value]="investigationDetail?.cause" (selectionChange)="cause = $event.value">
        <mat-option *ngFor="let c of causes | keyvalue" [innerHTML]="c.value" [value]="c.value"></mat-option>
      </mat-select>
    </mat-form-field>
    <ng-template #causeLoader>
      <mat-spinner diameter="48"></mat-spinner>
    </ng-template>
    <p *ngIf="data.dialogType === dialogTypes.SENDBACK">Please provide an explanation for sending this report back</p>
  </ng-template>
  <ng-container *ngIf="data.dialogType === dialogTypes.REQUEST_UPDATE">
    <ng-container *ngTemplateOutlet="requestUpdate"></ng-container>
  </ng-container>
  <ng-container *ngIf="data.dialogType === dialogTypes.INVESTIGATION_COMMENT; else commentInput">
    <p class="muted">Comments</p>
    <p [innerHTML]="data.comment"></p>
    <div class="d-flex flex-row-reverse">
      <button mat-button class="blue-action-button" (click)="closeModal(true)">Ok</button>
    </div>
  </ng-container>

  <!-- Comment information -->
  <ng-template #commentInput>
    <div class="w-100 comment-form">
      <mat-form-field appearance="outline">
        <mat-label>Comments</mat-label>
        <textarea matInput [(ngModel)]="comment"></textarea>
      </mat-form-field>
    </div>
    <div class="d-flex flex-row-reverse">
      <ng-container [ngSwitch]="data.dialogType">
        <button mat-button class="blue-action-button" [disabled]="!comment" (click)="advanceReport(true)" *ngSwitchCase="dialogTypes.SENDBACK">Send
          Back</button>
        <button mat-button class="blue-action-button"[disabled]="!comment" *ngSwitchCase="dialogTypes.INDICATE_CAUSE" (click)="indicateCause()">Save</button>
        <button mat-button class="blue-action-button" [disabled]="!comment" *ngSwitchCase="dialogTypes.APPROVE" (click)="advanceReport()">Approve Report</button>
        <button mat-button class="blue-action-button" [disabled]="!comment" *ngSwitchCase="dialogTypes.ASSIGN" (click)="advanceReport()">Assign
          Report</button>
        <button mat-button class="blue-action-button" [disabled]="!comment" *ngSwitchCase="dialogTypes.APPROVE_REPORT" (click)="advanceReport()">Approve
          Report</button>
        <button mat-button class="blue-action-button" [disabled]="!comment" *ngSwitchCase="dialogTypes.APPROVE_FINAL" (click)="advanceReport()">Approve
          Report</button>
        <button mat-button class="blue-action-button" [disabled]="!comment" *ngSwitchCase="dialogTypes.REQUEST_UPDATE" (click)="updateRequest()">Update Request</button>
        <button mat-button class="blue-action-button" [disabled]="!comment" *ngSwitchCase="dialogTypes.UNASSIGN" (click)="advanceReport(true)">Unassign</button>
      </ng-container>
      <button class="mr-3" mat-button (click)="closeModal(false)">Cancel</button>
    </div>
  </ng-template>
</section>

<ng-template #requestUpdate>
  <div class="row">
    <div class="col-4">
      <p class="muted caps">Request Type:</p>
      <p>{{ data.request.type }}</p>
    </div>
    <div class="col-4">
      <mat-form-field>
        <mat-label>Request Status</mat-label>
        <mat-select>
          <mat-option *ngFor="let status of statuses" [value]="status.id" [innerHTML]="status.title">
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <p class="muted">Submitted:</p>
      <p [innerHTML]="data.submitted | amDateFormat: 'MM/DD/YY h:mm A'"></p>
    </div>
    <div class="col-4">
      <p class="muted">Submitted By:</p>
      <p> {{ data.submittedBy.firstName }} {{ data.submittedBy.lastName }}</p>
    </div>
    <div class="col-4">
      <p class="muted">Authorized By:</p>
      <p [innerHTML]="data.authorizedBy"></p>
    </div>
    <p class="muted">Description</p>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="font-weight-bold">Request History</mat-expansion-panel-header>
      <table mat-table [dataSource]="data.requestHistory" class="mt-3">
        <ng-container matColumnDef="updated">
          <th mat-header-cell *matHeaderCellDef>Updated</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.updated | amDateFormat: 'MM/DD/YY h:mm A'"></td>
        </ng-container>
        <ng-container matColumnDef="updatedBy">
          <th mat-header-cell *matHeaderCellDef>Updated By</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.updatedBy"></td>
        </ng-container>
        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef>Comment</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.comment"></td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.status"></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-expansion-panel>
  </div>
</ng-template>