import { GqlExpensePurposeService } from '../../../modules/graphql/services/expenses/expense-purpose/expense-purpose.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../../modules/graphql/interfaces/base.interfaces';
import { IApiAddExpensePurposeInput, IApiExpensePurpose, IApiExpensePurposeConnection, IApiExpensePurposeFilter, IApiUpdateExpensePurposeInput } from "src/app/shared/modules/graphql/types/types";
import { ListPage } from "src/app/shared/helpers/list-page.class";


@Injectable()
export class ExpensePurposeService {
  constructor(
    private gqlExpensePurposeService: GqlExpensePurposeService
  ) { }

  public get(filters?: IApiExpensePurposeFilter[], options?: IGqlBaseOptions): Observable<IApiExpensePurposeConnection> {
    return this.gqlExpensePurposeService.getExpensePurposes(filters, options || new ListPage());
  }

  public add(input: IApiAddExpensePurposeInput): Observable<IApiExpensePurpose> {
    return this.gqlExpensePurposeService.addExpensePurpose(input);
  }

  public update(input: IApiUpdateExpensePurposeInput): Observable<IApiExpensePurpose> {
    return this.gqlExpensePurposeService.updateExpensePurpose(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlExpensePurposeService.removeExpensePurpose(id);
  }

  public toggleExpensePurposeActivation(id: string): Observable<boolean> {
    return this.gqlExpensePurposeService.toggleExpensePurposeActivation(id);
  }
}
