import { Component, OnInit, Output, EventEmitter, Input, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IUser } from 'src/app/shared/interfaces/user.interfaces';
import { InvestigationPriorities, InvestigationRiskTypes } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import { MatSelectChange } from '@angular/material/select';
import { InvestigationStatuses } from "src/app/shared/modules/graphql/enums/investigation.enums";
import { StateService } from 'src/app/shared/services/state.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IApiContact, IApiContactFilterType, IApiContactOrderBy, IApiContactRole, IApiContactRoleOrderBy, IApiInvestigationDetailCause, IApiInvestigationFilter, IApiInvestigationFilterType, IApiInvestigationRole, IApiInvestigationStatusNames, IApiService, IApiServiceOrderBy, IApiServiceStatus, IApiServiceStatusOrderBy, IApiServiceType, IApiServiceTypeOrderBy, IApiDocumentType, IApiDocumentTypeOrderBy, IApiInvestigationReportStatus, IApiInvestigationRoleNames, IApiContactRoleFilterType, IApiInvestigationSource, IApiServiceTypeFilterType, IApiDocumentTypeFilterType, IApiRiskType } from "src/app/shared/modules/graphql/types/types";
import { EnforcePermissionDirective, IEnforcePermissionConfig } from 'src/app/shared/directives/enforce-permission.directive';
import { PermissionActions, PermissionCategories } from 'src/app/shared/modules/graphql/enums/permissions.enums';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';
import { ContactRoleService, ContactService, DocumentTypeService, InvestigationRoleService, InvestigationSourceService, RiskTypesService, ServiceService, ServiceStatusService, ServiceTypeService } from 'src/app/shared/services';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { tap, map } from 'rxjs/operators';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { IState } from 'src/app/shared/interfaces/state.interfaces';
import { groupBy } from '@progress/kendo-data-query';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import {
  investigationClose,
  investigationStatusList
} from "src/app/shared/helpers/auth-config/investigations.config";
import {
  contacts
} from "src/app/shared/helpers/auth-config/contacts.config";
import { SharedService } from 'src/app/common/shared.service';
export interface ISelectLists {
  // users: IUser[];
  riskTypes: InvestigationRiskTypes;
}

@UntilDestroy()
@Component({
  selector: 'app-investigations-filter-card',
  templateUrl: './investigations-filter-card.component.html',
  styleUrls: ['./investigations-filter-card.component.scss']
})
export class InvestigationsFilterCardComponent implements OnInit, AfterViewInit {
  public authConfig = {
    investigationClose,
    investigationStatusList,
    contacts,
  };
  @ViewChild("investigationClose") investigationClosePerm;

  @ViewChild("autoCompleteContact", { static: false }) public autoCompleteContact: AutoCompleteComponent;
  @ViewChild('filterForm', { static: false }) filterForm: NgForm;

  public riskTypes: IApiRiskType[] = [];
  @Input() userViewFilter: string;
  @Input() showComments: boolean;
  @Output() commentToggle = new EventEmitter<boolean>();
  @Output() filters = new EventEmitter<IApiInvestigationFilter[]>();
  private _filters: IApiInvestigationFilter[] = [];
  public staffMember = "";
  public riskType = "";
  public lossState = "";
  public isOngoing: boolean;
  public isOnHold: boolean;
  public isIncendiary: boolean;
  public cancelled = false;
  public ongoing = false;
  public investigationStatuses = IApiInvestigationStatusNames;
  public submitted = false;

  public searchContacts = "";
  public searchCompanies = "";
  public searchInsured = "";
  public InvestigationPriorities = InvestigationPriorities;
  public investigationRoles: IApiInvestigationRole[] = [];
  public serviceStatuses: IApiServiceStatus[] = [];
  public serviceTypeList: IApiServiceType[] = [];
  public filterTypes = IApiInvestigationFilterType;
  public contactRoles: IApiContactRole[] = [];
  public contacts: IApiContact[] = [];
  public investigationCauses = IApiInvestigationDetailCause;
  public documentTypes: IApiDocumentType[] = [];
  public reportStatuses = IApiInvestigationReportStatus;
  public today: Date = new Date()
  private _availableFilters: { text: string; val: IApiInvestigationFilterType} [] = [
    { text:'Investigation Role', val: IApiInvestigationFilterType.InvestigationRole},
    { text:'Service', val: IApiInvestigationFilterType.Service},
    { text:'Priority', val: IApiInvestigationFilterType.Priority},
    { text:'Status', val: IApiInvestigationFilterType.Status},
    { text:'Service Status', val: IApiInvestigationFilterType.ServiceStatus},
    { text:'Document', val: IApiInvestigationFilterType.Document},
    { text:'Created At', val: IApiInvestigationFilterType.CreatedAt},
    { text:'Created By', val: IApiInvestigationFilterType.CreatedBy},
    { text:'Party', val: IApiInvestigationFilterType.Party},
    { text:'Cause', val: IApiInvestigationFilterType.Cause},
    { text: 'Street Address', val: IApiInvestigationFilterType.StreetAddress}
  ];

  private defaults = {
    dateFilter: {
      startDate: "",
      endDate: ""
    },
    serviceStatusFilter: {
      service: "",
      complete: false
    },
    serviceFilter: {
      service: "",
      complete: false,
      user: ""
    },
    documentFilter: {
      document: "",
      status: "",
      user: "",
      shared: false
    }
  };

  public dateFilter: Record<string, any> = { ...this.defaults.dateFilter };

  public serviceStatusFilter: Record<string, any> = { ...this.defaults.serviceFilter };

  public serviceFilter: Record<string, any> = { ...this.defaults.serviceFilter };

  public documentFilter: Record<string, any> = { ...this.defaults.documentFilter };

  public get isReport() {
    return this.documentTypes.find((e) => e.id === this.documentFilter.document)?.Category?.name === "Reports";
  }

  public get availableFilters() {
    return this._availableFilters.sort((a, b) => a.val > b.val ? 1 : -1);
  }

  public advancedFilters: IApiInvestigationFilterType[] = [];

  public get advancedFiltersSorted() {
    return this.advancedFilters.sort((a, b) => a < b ? -1 : 1);
  }

  // Toggle filter select visibility
  public showFilterSelect = false;

  public permissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION,
    appliedPermissions: {
      All: [PermissionActions.VIEW],
      AllAssigned: [PermissionActions.VIEW],
      Assigned: [PermissionActions.VIEW],
      Own: [PermissionActions.VIEW]
    }
  };

  public showAllPermissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION_CLOSED,
    appliedPermissions: {
      All: [PermissionActions.VIEW],
      AllAssigned: [],
      Assigned: [],
      Own: []
    }
  };

  private firstLoad = true;

  public filterValue(filter: IApiInvestigationFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this._filters.find(({ type }) => type === filter)?.value;
    return (value === "true" || value === "false") ? JSON.parse(value) : value;
  }

  public filterCompaniesValue(filter: IApiInvestigationFilterType) {
    const value = this._filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }

  public filterPartiesValue(filter: IApiInvestigationFilterType) {
    const value = this._filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }

  public stateList: Array<IState>;
  public stateFiltered: Array<IState>;
  public groupedDataStates: any;

  public investigationSource: IApiInvestigationSource[] = [];

  public contactsList: any[] = [];
  public selectedContactName: any = '';
  public selectedRoleParty = null;
  constructor(
    public stateService: StateService,
    private router: Router,
    private route: ActivatedRoute,
    private roles: InvestigationRoleService,
    private serviceTypes: ServiceTypeService,
    private contactRoleService: ContactRoleService,
    private documentTypeService: DocumentTypeService,
    private investigationSourceService: InvestigationSourceService,
    private contactService: ContactService,
    private sharedService: SharedService,
    private riskTypeService: RiskTypesService
  ) {
  }


  async ngOnInit() {
    this.stateList = this.stateService.allStates;
    this.stateFiltered = this.stateService.allStates;
    this.groupedDataStates = groupBy(this.stateFiltered, [
      { field: "type" },
    ]);

    const queryParams = this.sharedService.getLocalStorageByKey('INVESTIGATION_LIST')?.reduce((p, { type, value }) => {
      p[type] = value;
      return p;
    }, {});

    await this.router.navigate([], { relativeTo: this.route, queryParams});
    
    // Get by nefco number
    this.route.queryParams.pipe(untilDestroyed(this)).pipe(
      tap()
    ).subscribe((queryParams) => {
      this.advancedFilters = [];
      Object.keys(queryParams).forEach((k: IApiInvestigationFilterType) => {
        if (Object.values(IApiInvestigationFilterType).includes(k)) {
          const value = queryParams[k];
          this._filters.push({ type: k, value });

          switch (k) {
            case IApiInvestigationFilterType.CreatedAt:
              const createdAt = JSON.parse(value);
              this.dateFilter = { startDate: new Date(createdAt.startDate), endDate: new Date(createdAt.endDate) };
              break;
            case IApiInvestigationFilterType.Document:
              this.documentFilter = JSON.parse(value);
              break;
            case IApiInvestigationFilterType.ServiceStatus:
              this.serviceStatusFilter = JSON.parse(value);
              break;
            case IApiInvestigationFilterType.Service:
              this.serviceFilter = JSON.parse(value);
              break;
            case IApiInvestigationFilterType.Companies:
              let values = '';
              if (value?.length > 0) {
                values = JSON.parse(JSON.stringify(value));
              }
              this.searchCompanies = values;
              break;
            case IApiInvestigationFilterType.Insured:
              let valuesParties = '';
              if (valuesParties?.length > 0) {
                valuesParties = JSON.parse(JSON.stringify(valuesParties));
              }
              this.searchInsured = valuesParties;
              break;
            case IApiInvestigationFilterType.ContactSearch:
              this.searchContacts = value;
              break;
            case IApiInvestigationFilterType.PolicyClaimNumber:
              const removeFilters = this._filters.filter((obj) => obj.type !== IApiInvestigationFilterType.PolicyClaimNumber);
              this._filters = removeFilters;
              this._filters.push({ type: IApiInvestigationFilterType.PolicyClaimNumber, value });
              break;
            case IApiInvestigationFilterType.Contact:
              this.contactService.getById(value).subscribe((contact) => {
                this.contactsList = [{...contact, itemFullName: `${contact.firstName} ${contact.lastName}`}];
                this.selectedContactName = {...contact, itemFullName: `${contact?.firstName} ${contact?.lastName}`};
              });
            default:
              this.setFilters(value, k);
          }
          // Only add to advanced filter cards if its within our list
          if (this._availableFilters.find((e) => e.val === k)) this.advancedFilters.push(k as IApiInvestigationFilterType);
        }

      });

      if (this.advancedFilters.length) this.showFilterSelect = true;
      // if (nefcoNumber) this._filters.push({ type: IApiInvestigationFilterType.NefcoNumber, value: nefcoNumber });
      // if (nefcoNumber || this.firstLoad) {
      //   this.firstLoad = false;
      // }
      this.filterTable();
    });
    // Get roles
    this.roles.investigationRoles.subscribe((roles) => this.investigationRoles = roles);
    // Get service types
    this.serviceTypes.get([{ type: IApiServiceTypeFilterType.ViewListName, value: "true" }], { limit: -1, sortOrder: SortOrder.ASC, orderBy: IApiServiceTypeOrderBy.Name }).pipe(
      unwrapConnection(),
    ).subscribe((types) => this.serviceTypeList = types);
    // Get contact roles
    this.contactRoleService.get([{ type: IApiContactRoleFilterType.ViewListView, value: 'true' }], { limit: -1, sortOrder: SortOrder.ASC, orderBy: IApiContactRoleOrderBy.Name }).pipe(
      unwrapConnection()
    ).subscribe((roles) => this.contactRoles = roles);
    // Get document types
    this.documentTypeService.get([{ type: IApiDocumentTypeFilterType.ViewListName, value: "true" }], { limit: -1, orderBy: IApiDocumentTypeOrderBy.Name, sortOrder: SortOrder.ASC }).pipe(
      unwrapConnection()
    ).subscribe((types) => this.documentTypes = types);
    // Get Investigation Source
    this.investigationSourceService.get().subscribe((sources) => this.investigationSource = sources);
    // Get Risk Types
    this.riskTypeService.riskTypes().subscribe((types) => this.riskTypes = types);
  }

  ngAfterViewInit(): void {
      /* Close Investigation permission to filter status */
      if (!this.investigationClosePerm?.enabled) {
        const { Closed, ...rest } = this.investigationStatuses;
        this.investigationStatuses = rest as any;
      }
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 2 ? tags : [tags];
  }

  public handleFilter(value) {
    this.stateFiltered = this.stateList.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.groupedDataStates = groupBy(this.stateFiltered, [
      { field: "type" },
    ]);
  }

  public handleSearchFilter(value) {
    if (value.length >= 3) {
      const filters = [{
        type: IApiContactFilterType.ViewListView,
        value: 'true'
      }, {
        type: IApiContactFilterType.FullName,
        value: value,
      }];

      this.autoCompleteContact.loading = true;
      this.autoCompleteContact.toggle(false);
      this.contactsList = [];
      this.contactService.searchBy(filters)
        .pipe(
          unwrapConnection(),
          map((item) => {
            const newContact = item.map((i) => {
              return {
                ...i,
                itemFullName: `${i.firstName} ${i.lastName}`
              }
            })
            return newContact;
          })
        ).subscribe(results => {
          this.contactsList = results
          this.autoCompleteContact.loading = false
          this.autoCompleteContact.toggle(true);
        });
    } else {
      this.autoCompleteContact.toggle(false);
    }
  }

  public setUser(userId?: string) {
    this.setFilters(userId, IApiInvestigationFilterType.User);
  }

  public setRiskType(event: any): void {
    this.setFilters(event.name === 'All Types' ? undefined : event.name, IApiInvestigationFilterType.RiskType);
  }

  public setInvestigationSource(event: any): void {
    this.setFilters(event.name === 'All Sources' ? undefined : event.name, IApiInvestigationFilterType.InvestigationSource);
  }

  public setLossState(event: any): void {
    this.setFilters(event.value, IApiInvestigationFilterType.LossState);
  }

  public setContact(event: any): void {
    this.selectedContactName = this.contactsList.find(item => item.id === event);
    this.setFilters(this.selectedContactName ? event : undefined, IApiInvestigationFilterType.Contact);
  }

  public setIsOnHold(event) {
    this.setCheckedFilters(event?.checked, IApiInvestigationFilterType.IsOnHold);
  }

  public setIsIncendiary(event) {
    this.setCheckedFilters(event?.checked, IApiInvestigationFilterType.IsIncendiary);
  }

  public setIsOngoing(event) {
    this.setCheckedFilters(event?.checked, IApiInvestigationFilterType.IsOngoing);
  }

  public setCancelled(event: MatCheckboxChange) {
    this.setCheckedFilters(event?.checked, IApiInvestigationFilterType.IsDeleted);
  }

  public setActiveAndClosed(event: MatCheckboxChange) {
    this.setCheckedFilters(event?.checked, IApiInvestigationFilterType.ActiveAndClosed);
  }

  public filterTable() {
    this.submitted = true;
    if (this.filterForm?.invalid) {
      return;
    }
    if (this.filterTypes.CreatedAt && (this.dateFilter.startDate > this.dateFilter.endDate)) {
      return
    }
    this.updateHeaderFilterOnChange();
    this.updateQueryParams();
    this.filters.emit(this._filters);
  }

  /* get updated localStorage value and set filter in investigaiton list */
  public updateHeaderFilterOnChange(): void {
    if (this.sharedService.getLocalStorageByKey('INVESTIGATION_LIST')?.length > 0 && this._filters.length > 0) {
      const setCurrentSearchInStorage = this.sharedService.getLocalStorageByKey('INVESTIGATION_LIST');
      for (const key in setCurrentSearchInStorage) {
        if (setCurrentSearchInStorage[key].type === IApiInvestigationFilterType.NefcoNumber) {
          const index = this._filters?.findIndex(item => item.type === IApiInvestigationFilterType.NefcoNumber);
          if (index === -1) {
            this._filters.push({type: IApiInvestigationFilterType.NefcoNumber, value: setCurrentSearchInStorage[key].value}) 
          } else {
            this._filters[index].value =  setCurrentSearchInStorage[key].value;
          }
        }
        if (setCurrentSearchInStorage[key].type === IApiInvestigationFilterType.PolicyClaimNumber) {
          const index = this._filters?.findIndex(item => item.type === IApiInvestigationFilterType.PolicyClaimNumber);
          if (index === -1) {
            this._filters.push({type: IApiInvestigationFilterType.PolicyClaimNumber, value: setCurrentSearchInStorage[key].value}) 
          } else {
            this._filters[index].value =  setCurrentSearchInStorage[key].value;
          }
        }
      };
    }
  }

  public setDateFilter(value: Date, prop: "startDate" | "endDate") {
    this.dateFilter[prop] = moment(value).format("YYYY-MM-DD HH:mm:ss");
    this.setFilters(JSON.stringify({ startDate: this.dateFilter.startDate, endDate: this.dateFilter.endDate }), IApiInvestigationFilterType.CreatedAt);
  }

  public setServiceStatusFilter(value: any, prop: "service" | "complete") {
    this.serviceStatusFilter[prop] = value;
    this.setFilters(JSON.stringify(this.serviceStatusFilter), IApiInvestigationFilterType.ServiceStatus);
  }

  public setServiceFilter(value: any, prop: "service" | "complete" | "user") {
    this.serviceFilter[prop] = value;
    this.setFilters(JSON.stringify(this.serviceFilter), IApiInvestigationFilterType.Service);
  }

  public setDocumentFilter(value: string, prop: "document" | "status" | "user" | "shared") {
    this.documentFilter[prop] = value;
    this.setFilters(JSON.stringify(this.documentFilter), IApiInvestigationFilterType.Document);
  }

  public setContactSearch() {
    this.setFilters(this.searchContacts, IApiInvestigationFilterType.ContactSearch);
  }

  public setCompanySearch(event) {
    this.searchCompanies = null;
    if (event?.length > 0) {
      this.searchCompanies = JSON.stringify(event);
    }
    this.setFilters(this.searchCompanies, IApiInvestigationFilterType.Companies);
  }

  public setInsuredSearch(event) {
    this.searchInsured = null;
    if (event?.length > 0) {
      this.searchInsured = JSON.stringify(event);
    }
    this.setFilters(this.searchInsured, IApiInvestigationFilterType.Insured);
  }

  public getDocumentSelectInvestigationRole(status: IApiInvestigationReportStatus): IApiInvestigationRoleNames | null {
    switch (status) {
      case IApiInvestigationReportStatus.Draft:
        return IApiInvestigationRoleNames.Investigator;
      case IApiInvestigationReportStatus.TechReview:
        return IApiInvestigationRoleNames.TechReviewer;
      case IApiInvestigationReportStatus.EditorReview:
        return IApiInvestigationRoleNames.Editor;
      default:
        return null;
    }
  }

  public async clearFilter() {
    this.staffMember = "";
    this.riskType = "";
    this.lossState = "";
    this.isOngoing = false;
    this.isIncendiary = false;
    this.isOnHold = false;
    this._filters = [];
    this.advancedFilters = [];
    this.searchContacts = "";
    this.searchCompanies = "";
    this.selectedContactName = "";
    this.dateFilter = { ...this.defaults.dateFilter };
    this.documentFilter = { ...this.defaults.documentFilter };
    this.serviceStatusFilter = { ...this.defaults.serviceStatusFilter };
    this.serviceFilter = { ...this.defaults.serviceFilter };
    this.searchInsured = '';
    this.submitted = false;
    this.filterTable();
  }

  private resetComplexFilter(filter: IApiInvestigationFilterType) {
    // Necessary in order to forget stored values when filters are removed
    // Prevents stale values from persisting
    switch (filter) {
      case IApiInvestigationFilterType.CreatedAt:
        this.dateFilter = { ...this.defaults.dateFilter };
        break;
      case IApiInvestigationFilterType.Document:
        this.documentFilter = { ...this.defaults.documentFilter };
        break;
      case IApiInvestigationFilterType.ServiceStatus:
        this.serviceStatusFilter = { ...this.defaults.serviceStatusFilter };
        break;
      case IApiInvestigationFilterType.Service:
        this.serviceFilter = { ...this.defaults.serviceFilter };
        break;
      case IApiInvestigationFilterType.Companies:
        this.searchCompanies = '';
        break;
      case IApiInvestigationFilterType.ContactSearch:
        this.searchContacts = '';
        break;
    }
  }

  public removeFilterCard(filter: IApiInvestigationFilterType) {
    this._filters = this._filters.filter((item) => item.type !== filter);
    this.advancedFilters = this.advancedFilters.filter((e) => e !== filter);
    this.resetComplexFilter(filter);
  }

  private setCheckedFilters(value: boolean, type: IApiInvestigationFilterType) {
    const filtersCopy = this._filters.filter(f => f.type !== type);
    this._filters = value ? [...filtersCopy, {
      type: type,
      value: value.toString()
    }] : filtersCopy;
  }

  private updateQueryParams() {
    this.sharedService.setLocalStorageByKey('INVESTIGATION_LIST', this._filters);
    const queryParams = this.sharedService.getLocalStorageByKey('INVESTIGATION_LIST')?.reduce((p, { type, value }) => {
      p[type] = value;
      return p;
    }, {});
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  public setFilters(value: string | undefined, type: IApiInvestigationFilterType) {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this._filters.filter(f => f.type !== type);
    this._filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: value
    }] : filtersCopy;
    // this.updateQueryParams();
  }

  public isCommentShow(show: boolean) {
    this.commentToggle.emit(show);
  }

  public advancedFilterValueChange(e: string[] = []) {
    this.submitted = false;
  }

  public getAdvancedFilterText(type: IApiInvestigationFilterType) {
    return this.availableFilters.find(item => item.val === type)?.text || 'Set Text';
  }

}
