import { ApiConnection } from '../../../decorators/@ApiConnection.decorator';
import { InvoiceItemService } from './invoice-item.service';
import { DataSource } from "@angular/cdk/collections";
import { IApiInvoiceItem, IApiInvoiceItemConnection, IApiInvoiceItemFilter } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class InvoiceItemDataSource extends NefcoPagedDataSource<IApiInvoiceItem, InvoiceItemService, IApiInvoiceItemConnection> implements DataSource<IApiInvoiceItem> {
  @ApiConnection()
  public load(filters?: IApiInvoiceItemFilter[]): Promise<IApiInvoiceItemConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
