import { IApiRequest, IApiRequestOrderBy, IApiRequestFilter } from './../../modules/graphql/types/types';
import { RequestService } from './request.service';
import { DataSource } from "@angular/cdk/collections";
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { ApiConnection } from "../../decorators/@ApiConnection.decorator";

export class RequestDataSource extends NefcoPagedDataSource<IApiRequest, RequestService> implements DataSource<IApiRequest> {
  @ApiConnection()
  public load(filters: IApiRequestFilter[] = []): Promise<any> {
    if (!this.listPage.orderBy) this.listPage.orderBy = IApiRequestOrderBy.CreatedAt;
    return this.service.get(filters, this.listPage).toPromise();
  }
}
