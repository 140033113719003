<div [dialogRef]="dialog" (appClickOutside)="dialogRef.close()">
  <kendo-dialog-titlebar (close)="dialogRef.close()">
    <div class="modal-title">
      <span>{{ data.config?.singleInvestigator ? (data.config.singleInvestigator | fullName: true ) : data.certification.title }}</span>
    </div>
  </kendo-dialog-titlebar>
  <kendo-panelbar [expandMode]="0" *ngIf="userCertifications?.length">
    <kendo-panelbar-item *ngFor="let cert of userCertifications; let i = index;" [expanded]="i === 0 ? true : false">
      <ng-template kendoPanelBarItemTitle>
        <span>{{ data.config?.singleInvestigator ? (data.config.singleInvestigator | fullName: true ) : data.certification.title }}</span> |
        <span>Issued: {{ cert.issuedAt | customDate: 'yyyy-MM-dd'}} </span> |
        <span>Expires: {{ cert.expiresAt | customDate: 'yyyy-MM-dd' }}
          <i *ngIf="cert | showCertificationError" class="fa-solid fa-circle-exclamation" style="color:#f31700;"></i>
          <i *ngIf="cert | showCertificationError : 6 : 0" class="fa-solid fa-triangle-exclamation" style="color:#ffc000;"></i>
        </span>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <kendo-gridlayout class="p-24" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
          <kendo-gridlayout-item>
            <div class="kendo-formfield">
                <kendo-label text="Issuing Agency"></kendo-label>
                <p>{{ cert.Type?.CertificationAgency?.name || '-' }}</p>
              </div>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <div class="kendo-formfield">
                <kendo-label text="State"></kendo-label>
                <p>{{ cert.Type?.state || '-' }}</p>
              </div>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <div class="kendo-formfield">
              <kendo-label text="Issue Date"></kendo-label>
              <kendo-datepicker
                  id="issueDate"
                  name="issueDate"
                  calendarType="classic"
                  [fillMode]="'outline'"
                  placeholder="Enter date"
                  format="MM/dd/yyyy"
                  [(ngModel)]="cert.issuedAt"
                  (ngModelChange)="updateCertification(cert)"
                  formatPlaceholder="formatPattern"
                  #issueDate="ngModel"
              ></kendo-datepicker>
            </div>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <div class="kendo-formfield">
              <kendo-label text="Expiration Date"></kendo-label>
              <kendo-datepicker
                  id="expireDate"
                  name="expireDate"
                  calendarType="classic"
                  [fillMode]="'outline'"
                  placeholder="Enter date"
                  format="MM/dd/yyyy"
                  [(ngModel)]="cert.expiresAt"
                  (ngModelChange)="updateCertification(cert)"
                  formatPlaceholder="formatPattern"
                  #expireDate="ngModel"
              ></kendo-datepicker>
            </div>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item *ngIf="!cert.Type?.isRestriction">
            <div class="kendo-formfield">
              <kendo-label class="asterisk" text="Status"></kendo-label>
              <kendo-dropdownlist [data]="statuses | keyvalue" textField="value" valueField="value" fillMode="outline"
                [valuePrimitive]="true" name="status"
                [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
                [(ngModel)]="cert.status" #status="ngModel" required
                (ngModelChange)="updateCertification(cert)">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span [ngClass]="dataItem?.value === 'Select Status' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.value | quiet}} </span>
                </ng-template>
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  <span [ngClass]="dataItem?.value === 'Select Status' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.value | quiet }} </span>
                </ng-template>
              </kendo-dropdownlist>
              <kendo-formerror *ngIf="!status?.valid && (status?.dirty || status?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="status?.errors?.required">
                  Status cannot be left blank
                </span>
              </kendo-formerror>
            </div>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item *ngIf="cert.Document; else upload">
            <kendo-chip class="fw600" style="margin-top: 20px;" [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'"
              [removable]="true" (remove)="removeDocument(cert)">
              {{ cert.Document?.title }}
            </kendo-chip>
            <button kendoButton class="mt-8" themeColor="primary" fillMode="link" iconClass="fa-solid fa-cloud-arrow-down"
              (click)="donwloadCertificate(cert?.Document?.s3Uri)">
              Download Certification
            </button>
          </kendo-gridlayout-item>
          <ng-template #upload>
            <kendo-gridlayout-item>
              <button kendoButton class="mt-16" themeColor="primary" fillMode="link" iconClass="fa-solid fa-cloud-arrow-up"
                (click)="fileSelector(cert)">
                Upload Certification
              </button>
            </kendo-gridlayout-item>
          </ng-template>

          <!-- START comment Add / Delete certification -->
          <kendo-gridlayout-item [colSpan]="2">
            <kendo-formfield>
              <kendo-label text="Comment"></kendo-label>
              <kendo-textarea name="Comments" #comments="ngModel" [(ngModel)]="comment" placeholder="Comments" fillMode="outline"
                class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
              <kendo-formerror *ngIf="comments?.errors">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="comments?.errors.required">Comments cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item [colSpan]="2" class="text-right">
            <button kendoButton class="mr-8" themeColor="error" fillMode="link" iconClass="fa-solid fa-trash"
              (click)="deleteStaffCertification(cert?.id)"></button>
            <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" [disabled]="!comment?.trim()"
              (click)="addNewComment(cert, comment?.trim())">
              Save
            </button>
          </kendo-gridlayout-item>
          <!-- END comment Add / Delete certification -->
        </kendo-gridlayout>
      </ng-template>

      <kendo-panelbar-item>
        <ng-template kendoPanelBarItemTitle>
          <span>Comment Log</span>
        </ng-template>
        <ng-template kendoPanelBarContent>
          <kendo-grid
            class="certifications-sidebar-comment-list mt-0 m-16"
            [data]="cert.Comments"
            [pageable]="false"
            [navigatable]="false"
          >
            <kendo-grid-column
              field="DATE"
              title="Date"
              [width]="10"
              [sortable]="false"
              [headerClass]="'nocursor'"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span [innerHTML]="dataItem.createdAt | customDate: 'MM-dd-yyyy'"></span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="COMMENT"
              title="Comment"
              [width]="30"
              [sortable]="false"
              [headerClass]="'nocursor'"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span [innerHTML]="dataItem.text"></span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="USER"
              title="User"
              [width]="15"
              [sortable]="false"
              [headerClass]="'nocursor'"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.CreatedBy | fullName : true}}</span>
              </ng-template>
            </kendo-grid-column>
             
            <!-- No record -->
            <ng-template kendoGridNoRecordsTemplate>
              <p class="mt-24 mb-24">There are no results to display.</p>
            </ng-template>
          </kendo-grid>
        </ng-template>
      </kendo-panelbar-item>
    </kendo-panelbar-item>
  </kendo-panelbar>
</div>