import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IState } from '../../interfaces/state.interfaces';
import { StateService } from '../../services';

@Component({
  selector: 'app-state-select-kendo',
  templateUrl: './state-select-kendo.component.html',
  styleUrls: ['./state-select-kendo.component.scss']
})
export class StateSelectKendoComponent implements OnInit {

  @Input() placeholder = "All States";
  @Input() label = "";
  @Input() disabled = false;
  @Input() selectedId = null;

  @Output() selectionChanged = new EventEmitter<string | null>();

  public stateList: Array<IState>;
  constructor(
    private stateService: StateService,
  ) {
    this.stateList = this.stateService.allStates;
  }

  ngOnInit(): void {
    if (!this.selectedId) {
      this.selectedId = null;
    }
  }

  public select($event) {
    this.selectedId = $event?.value;
    this.selectionChanged.emit(this.selectedId);
  }

}
