<kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']"
  class="content-part mb-24" *ngFor="let user of users; let i = index">
  <kendo-gridlayout-item>
    <kendo-formfield>
      <div class="alpha-index-show">{{i | alphaIndex }}</div>
      <div class="kendo-radio">
        <input type="radio" id="selected" kendoRadioButton [checked]="selection.isSelected(user)"
          (change)="handleInvestigatorSelection($event.target.checked, user)" />
        <label class="k-radio-label ml-0" for="selected">
          {{ user?.driveDuration || user?.driveDistance ? "Drive " : ""
          }}{{ user?.driveDistance ? user.driveDistance.text : ""
          }}{{ user?.driveDuration && user?.driveDistance ? ", " : ""
          }}{{ user?.driveDuration ? user.driveDuration?.text : ""
          }}{{
          !user?.driveDuration && !user?.driveDistance
          ? "Unable to get directions"
          : ""
          }}
        </label>
      </div>
    </kendo-formfield>
    <div class="user-info">
      <div>
        <a class="linkmd mb-0 mr-16" href="javascript:void(0)" (click)="nameClicked(user)"><i class="fa-solid fa-ban"
            *ngIf="user.Restrictions.length"></i><u [innerHTML]="user | fullName"></u></a>
      </div>
      <div>
        <a class="linkmd" href="{{ 'tel:' + (user.Phones | primaryAssociation)?.number }}"><i
            class="fa-solid fa-phone fa-sm"></i> <u
            [innerHTML]="(user.Phones | primaryAssociation)?.number | phoneFormat : 'US'"></u>
        </a>
      </div>
    </div>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item class="text-right">
    <p *ngIf="messegeSentToUsers?.[user?.id]">Message Sent: {{messegeSentToUsers?.[user?.id]?.createdAt | customDate:
      'MM/dd/yyyy'}} at
      {{messegeSentToUsers?.[user?.id]?.createdAt | customDate: 'h:mm a'}}</p>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item class="mb-0">
    <div class="hour-restriction-section">
      <div class="hour-remaining" *ngIf="user?.UserDetail?.fullTime">
        <h6>Hours Remaining</h6>
        <p class="percent mb-0">{{ (remainingHoursDecimal(user.UserDetail) | percent) || "N/A" }}</p>
      </div>
      <div class="hour-remaining" *ngIf="!user?.UserDetail?.fullTime">
        <h6>Hours Worked</h6>
        <p class="percent mb-0">{{ (user.UserDetail?.remainingHoursQuarterly) }}</p>
      </div>
      <div class="restrictions ml-24" *ngIf="user.Restrictions.length">
        <h6>Restrictions</h6>
        <p class="note text-overflow mb-0">
          <span
            *ngIf="user?.Restrictions?.length === 1 && (user?.Restrictions[0]?.Type.title?.length + user?.Restrictions[0]?.Type.notes?.length) <= 20 ">
            {{user?.Restrictions[0]?.Type.title}} {{user?.Restrictions[0]?.Type.notes ? ' - ' : '' }}
            {{user?.Restrictions[0]?.Type.notes}}
          </span>
          <span kendoTooltip position="bottom"
            *ngIf="user?.Restrictions?.length > 1 || (user?.Restrictions[0]?.Type.title?.length + user?.Restrictions[0]?.Type.notes?.length) > 20"
            [tooltipTemplate]="restrictionTooltip" filter="a[href]" tooltipClass="tooltipwidthauto">
            <a href="javascript:void(0)" class="more-assigne primary-color">{{user?.Restrictions[0]?.Type.title}}
              {{user?.Restrictions[0]?.Type.notes ? ' - ' : '' }} {{user?.Restrictions[0]?.Type.notes}}</a>
          </span>
          <ng-template #restrictionTooltip>
            <span class="dflex" *ngFor="let restriction of user?.Restrictions; let index = index; let last = last">
              {{index + 1}}.&nbsp;{{restriction.Type.title}} {{restriction.Type.notes ? ' - ' : '' }}
              {{restriction.Type.notes}}
            </span>
          </ng-template>
        </p>
      </div>
    </div>
  </kendo-gridlayout-item>

  <kendo-gridlayout-item class="text-right mb-0">
    <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" class="mr-16"
      (click)="sendMessage(user)"
      [iconClass]="(currentQueueUserId === user?.id && messageSendLoading) ? 'fa-solid fa-spinner fa-spin-pulse' : (messegeSentToUsers?.[user?.id] && messageSendSuccessLoading) ? 'fa-solid fa-circle-check' : ''">
      {{(currentQueueUserId === user?.id && messageSendLoading) ? 'Sending Message' : (messegeSentToUsers?.[user?.id] &&
      messageSendSuccessLoading) ? 'Message Sent' : 'Send Message'}}
    </kendo-button>
    <kendo-button kendoButton themeColor="base" fillMode="solid" size="medium"
      (click)="drawerUserChange.emit(user)">View
      Details</kendo-button>
  </kendo-gridlayout-item>
</kendo-gridlayout>
