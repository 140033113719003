import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "src/app/shared/shared.module";

import {
  TimeAndExpManagementComponent
} from './';
import { TimeExpExpensesComponent } from './time-exp-expenses/time-exp-expenses.component';
import { TimeExpHoursKendoComponent } from './time-exp-hours-kendo/time-exp-hours-kendo.component';
import { OopExpenseReportComponent } from './oop-expense-report/oop-expense-report.component';
import { NefcoCardExpenseReportComponent } from './nefco-card-expense-report/nefco-card-expense-report.component';

@NgModule({
  declarations: [TimeAndExpManagementComponent, TimeExpExpensesComponent, TimeExpHoursKendoComponent, OopExpenseReportComponent, NefcoCardExpenseReportComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class TimeAndExpModule { }
