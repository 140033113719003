import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { saveAs } from 'file-saver';
import b64ToBlob from "b64-to-blob";
import { LoaderService } from "../modules/loader/loader.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable()
export class DownloadHelper {

  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    private httpClient: HttpClient,
    private loader: LoaderService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public download(data: string, filename: string) {
    const link = this.renderer.createElement("a");
    // '#' was causing anything after '#' to cut off and not download in CSV
    link.href = "data:text/csv;charset=utf-8," + data.replace(/#/g, '');
    link.download = filename;
    this.renderer.appendChild(this.document.body, link);
    link.click();
    this.renderer.removeChild(this.document.body, link);
  }

  public externalDownload(url: string, fileName: string) {
    this.loader
    .show$(this.httpClient.get(`${environment.cloudFrontS3}/${encodeURIComponent(url)}`, { responseType: "blob" }))
    .subscribe((blob) => {
      const link = document?.createElement("a");
      link.href = window?.URL?.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window?.URL?.revokeObjectURL(link.href);
    });
  }

  public externalDownloadZip(zipString: string, fileName = 'zipdownload') {
    const blob = b64ToBlob(zipString, "application/zip");
    saveAs(blob, `${fileName}.zip`);
  }

  public openURL(url: string): void {
    window?.open(`${environment.cloudFrontS3}/${encodeURIComponent(url)}`, "_blank");
  }

}