import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IPortalUserDetail } from '../../../components/portal/interfaces/portal.interfaces';
import { PortalRequestService } from './portal-base.service';

@Injectable()
export class PortalUserService {
  
  constructor(private request: PortalRequestService) {}

  public get(id: string) {
      return this.request.get(`/users/${id}`);
  }

  public update(data: Partial<IPortalUserDetail>) {
      return this.request.put(`/users/${data.id}`, data);
  }


}
