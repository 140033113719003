import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IApiEmail, IApiInvestigation, IApiPhone, IApiUser } from '../../modules/graphql/types/types';
import dayjs from 'dayjs';
import { InsuredPartyService } from '../../services';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-email-phone-view',
  templateUrl: './email-phone-view.component.html',
  styleUrls: ['./email-phone-view.component.scss']
})
export class EmailPhoneViewComponent {

  @Input() email: IApiEmail;
  @Input() phone: IApiPhone;
  @Input() investigation: IApiInvestigation;
  @Input() currentUser: IApiUser;
  @Input() copyEmail: boolean = false;
  @Output() reload = new EventEmitter<boolean>();
  constructor(
    private insuredPartyService: InsuredPartyService,
    private clipboard: Clipboard
  ) { }

  addInsuredContacted() {
    if(this.investigation?.id && this.currentUser?.id){
      const date = dayjs().tz(this.investigation.timezone, true).toISOString();
      const input = {
        InvestigationId: this.investigation.id,
        UserId: this.currentUser.id,
        date: date
      };
      this.insuredPartyService.addInsuredContact(input).subscribe((result) => {
        this.reload.emit(false);
      }, error => {
      })
    }
  }

  copyText(text){
    if(this.copyEmail === true){
      this.clipboard.copy(text)
    }
  }

}
