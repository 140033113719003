<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageEvidenceLocationsCreate"
  #manageEvidenceLocationsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageEvidenceLocationsUpdate"
  #manageEvidenceLocationsUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageEvidenceLocationsDelete"
  #manageEvidenceLocationsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageEvidenceLocationsDeactivate"
  #manageEvidenceLocationsDeactivate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div class="content-wrapper">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <app-state-select-kendo [label]="'State'" [placeholder]="'Select state'"
          [selectedId]="filterValue(filterTypes.State)"
          (selectionChanged)="setFilters($event, filterTypes.State)"></app-state-select-kendo>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <app-evidence-storage-location-multi-select-kendo label="Location" placeholder="Search location"
          [selectedId]="filterValue(filterTypes.Locations)"
          (selectionChanged)="setFilters($event, filterTypes.Locations)"></app-evidence-storage-location-multi-select-kendo>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="d-flex">
        <div class="mr-16 applied-filter">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="load()">Apply
              Filters</kendo-button>
          </div>
        </div>
        <div class="mt-5 clear-filter">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearAll()">Clear
              All</kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [colSpan]="2" class="filter-actions right-group">
        <form #form="ngForm">
          <kendo-buttongroup selection="single" name="status" class="ml-3">
            <button kendoButton [toggleable]="true" [value]="null" [selected]="selectedStatus === null"
              (click)="setSelectedStatus(null)" title="All">All</button>
            <button kendoButton [toggleable]="true" [value]="1" [selected]="selectedStatus === 1"
              (click)="setSelectedStatus(1)" title="Active">Active</button>
            <button kendoButton [toggleable]="true" [value]="0" [selected]="selectedStatus === 0"
              (click)="setSelectedStatus(0)" title="Inactive">Inactive</button>
          </kendo-buttongroup>
        </form>
        <kendo-button *ngIf="manageEvidenceLocationsCreate?.enabled" kendoButton [themeColor]="'primary'"
          fillMode="solid" iconClass="fas fa-plus" (click)="openAddNewLocationModal()">
          New Location</kendo-button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-grid class="evidence-storage-location-list-grid" [data]="{
      data: evidenceStorageLocationList,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }" (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)">
      <kendo-grid-column field="NAME" title="Location Name" [width]="35" class="id-column" [sortable]="true"
        class="font-weight600" [headerClass]="'sort-header'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a (click)="edit(dataItem)" appEventLink class="text-decoration word-break"
            *ngIf="manageEvidenceLocationsUpdate?.enabled else onlyName">
            {{dataItem?.name}}
          </a>
          <ng-template #onlyName>
            <span [innerHTML]="dataItem?.name"></span>
          </ng-template>
        </ng-template>
      </kendo-grid-column>
      <!-- Category -->
      <kendo-grid-column field="TYPE" title="Type" [width]="25" class="id-column" [sortable]="true"
        [headerClass]="'sort-header'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [innerHTML]="dataItem.type"></span>
        </ng-template>
      </kendo-grid-column>
      <!-- Default Title  -->
      <kendo-grid-column field="ADDRESS" title="Company Name/ Address" [width]="35" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [innerHTML]="dataItem?.companyName"></span>
          <br />
          <app-address-view [address]="dataItem?.Address"></app-address-view>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="CONTACT_INFO" title="Contact/Phone" [width]="35" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [innerHTML]="dataItem?.contactName"></span>
          <br />
          <app-email-phone-view [phone]="dataItem.Phone"></app-email-phone-view>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="DETAILS" title="Details" [width]="35" class="id-column" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [innerHTML]="dataItem.hasDetails ? 'Yes' : 'No'"></span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="ADDITIONAL_DETAILS" title="Additional Details" [width]="35" class="id-column"
        [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [innerHTML]="dataItem.additionalDetails"></span>
        </ng-template>
      </kendo-grid-column>

      <!-- Action -->
      <kendo-grid-column field="id" title="Actions" [width]="10" [sortable]="false" [headerClass]="'nocursor'"
        *ngIf="manageEvidenceLocationsUpdate?.enabled || manageEvidenceLocationsDelete?.enabled">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-dropdownbutton [data]="getActionItem(dataItem)" themeColor="primary" fillMode="link"
            buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis"
            (itemClick)="itemSelected($event, dataItem)" [popupSettings]="{
            appendTo: 'component',
            animate: false,
            width: 250,
            popupClass: 'grid-action-items-menu'
          }">
          </kendo-dropdownbutton>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</section>
