import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapComponent } from './google-map.component';
import { GoogleMapService } from './google-map.service';

@NgModule({
  declarations: [GoogleMapComponent],
  imports: [
    CommonModule,
  ],
  providers: [GoogleMapService],
  exports: [
    GoogleMapComponent
  ]
})
export class GoogleMapsModule {static forRoot(): ModuleWithProviders<GoogleMapsModule> {
  return {
    ngModule: GoogleMapsModule,
    providers: [
      GoogleMapService
    ]
  };
} }
