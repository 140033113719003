<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationStatusCreate" #investigationStatusCreate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div
    class="page-filters"
    *ngIf="config.type !== type.investigations && loaded"
  >
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(6,minmax(0,1fr))']">
    <kendo-gridlayout-item>
      <div class="kendo-formfield left-search-icon">
          <kendo-label [for]="search" text="Search"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox
              #search
              [value]="filterValue(filterTypes.Search)"
              (valueChange)="setFilters($event, filterTypes.Search)"
              placeholder="Search"
              fillMode="outline"
              [clearButton]="false">
            </kendo-textbox>
          </div>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <app-user-select-kendo
        label=""
        [userViewFilter]="userViewFilter"
        placeholder="Search by staff member"
        [userId]="filterValue(filterTypes.User)"
        [includeInactive]="false"
        (selectionChanged)="setFilters($event?.id, filterTypes.User)"
      ></app-user-select-kendo>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <div class="kendo-formfield">
        <kendo-label [for]="Status" text="Status"></kendo-label>
        <kendo-dropdownlist #Status [data]="InvestigationStatuses | keyvalue" textField="value" valueField="value"  fillMode="outline"
            [valuePrimitive]="true" [defaultItem]="{key: null, value: 'All'}" [value]="filterValue(filterTypes.Status) || null"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required
            (selectionChange)="setFilters($event?.value !== 'All' ? $event?.value : null , filterTypes.Status)">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem?.value === 'All'" class="placeholder"> {{ dataItem?.value }} </span>
                <span *ngIf="dataItem?.value !== 'All'" class="not-placeholder"> {{ dataItem?.value }} </span>
            </ng-template>
        </kendo-dropdownlist>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <div class="kendo-formfield">
          <kendo-label [for]="dropdownlist" text="Risk Type"></kendo-label>
          <kendo-dropdownlist #dropdownlist [data]="riskTypes" textField="name" valueField="name" fillMode="outline"
              [valuePrimitive]="true" [defaultItem]="{name: 'All' }" [value]="filterValue(filterTypes.RiskType)"
              [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
              (selectionChange)="setFilters(($event.name !== 'All') ? $event.name : null, filterTypes.RiskType)">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="dataItem?.name === 'All'" class="placeholder"> {{ dataItem.name }} </span>
                  <span *ngIf="dataItem?.name !== 'All'" class="not-placeholder"> {{ dataItem.name }} </span>
              </ng-template>
          </kendo-dropdownlist>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <div class="kendo-formfield">
        <kendo-label [for]="Priority" text="Priority"></kendo-label>
        <kendo-dropdownlist #Priority [data]="InvestigationPriorities | keyvalue" textField="value" valueField="value"  fillMode="outline"
          [valuePrimitive]="true" [defaultItem]="{key: null, value: 'All'}" [value]="filterValue(filterTypes.Priority)"
          [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
          (selectionChange)="setFilters($event?.value === 'All' ? null : $event?.value, filterTypes.Priority)">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="dataItem?.value === 'All'" class="placeholder"> {{ dataItem?.value }} </span>
              <span *ngIf="dataItem?.value !== 'All'" class="not-placeholder"> {{ dataItem?.value }} </span>
          </ng-template>
        </kendo-dropdownlist>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item class="filter-actions" [colSpan]="1" [col]="0" [row]="0">
      <div class="applied-filter mr-16">
        <kendo-label text=""></kendo-label>
        <div>
          <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="filterTable()">Apply Filters</kendo-button>
        </div>
      </div>
      <div class="clear-filter mt-5">
        <kendo-label text=""></kendo-label>
        <div>
          <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearFilter()">Clear All</kendo-button>
        </div>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  </div>

  <hr class="divider-line" />

  <kendo-grid
    class="investigation-list"
    [data]="{
      data: tableData,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageChange($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
    [kendoGridExpandDetailsBy]="'id'"
    [(expandedDetailKeys)]="expandedDetailKeys"
  >

    <kendo-grid-column
      field="CREATED_AT"
      title="Created"
      [width]="20"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="kendo-span">{{dataItem.createdAt | customDate:'MM/dd/yyyy'}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="NEFCO_NUMBER"
      title="ID#"
      [width]="20"
      class="id-column"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          target="_blank"
          [routerLink]="['/investigations/', dataItem?.id]"
          tel=""
          class="text-decoration"
        >
          {{ dataItem?.nefcoNumber || "Unassigned" }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="PRIORITY"
      title="Priority"
      [width]="15"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-badge
          class="{{
            'fw600 ' +
              (dataItem?.Priority?.name === 'Low' ||
              dataItem?.Priority?.name === 'Medium'
                ? 'fcolor'
                : '')
          }}"
          position="none"
          size="medium"
          rounded="full"
          themeColor="{{
            dataItem?.Priority?.name === 'Low'
              ? 'tertiary'
              : dataItem?.Priority?.name === 'Medium'
              ? 'warning'
              : dataItem?.Priority?.name === 'High'
              ? 'error'
              : ''
          }}"
          >{{ dataItem?.Priority?.name }}</kendo-badge
        >
				<ng-container *ngIf="!dataItem?.Priority?.name">-</ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="LOSS_DATE"
      title="Loss Date"
      [width]="20"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.lossDate | customDate : "MMM d, y" : true }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="RiskType"
      title="Risk Type"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span style="display: flex">
          <img
            class="risk-type-img"
            src="{{ getIcon(dataItem?.RiskType?.name) }}"
          />
          <span class="text-overflow">{{ dataItem?.RiskType?.name }}</span>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="InvestigationAddress"
      title="Location"
      [width]="30"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.InvestigationAddress; else locationElse">
          <span class="text-overflow" *ngIf="dataItem?.InvestigationAddress?.address1">{{ dataItem?.InvestigationAddress?.address1 }}<br /></span>
          {{ dataItem?.InvestigationAddress?.city }},
          {{ dataItem?.InvestigationAddress?.state }}
          {{ dataItem?.InvestigationAddress?.postal }}
        </ng-container>
        <ng-template #locationElse>-</ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="Insured"
      title="Insured"
      [width]="35"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ (dataItem?.Insured | insuredName) }}<br /></span>
        <span class="phone-number"*ngIf="dataItem?.Insured?.Phones?.length else showDesk">
          <app-email-phone-view [phone]="(dataItem?.Insured?.Phones | primaryAssociation)"></app-email-phone-view>
        </span>
        <ng-template #showDesk>-</ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="Client"
      title="Client"
      [width]="33"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="!dataItem?.Client?.firstName && !dataItem?.Client?.lastName && !dataItem?.ClientBranch else cliendInfo">-</ng-container>
        <ng-template #cliendInfo>
          <span class="text-overflow">{{ dataItem?.Client | fullName }}</span
            ><br />
            <span class="text-overflow">
              {{ dataItem?.ClientBranch?.Company?.name }}</span
            ><br />
            <span class="text-overflow"> {{ dataItem?.ClientBranch?.name }}</span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="InvestigationStaff"
      title="Assigned"
      [width]="27"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.InvestigationStaff">
          <span
            class="d-block text-overflow"
            *ngFor="
              let staff of orderInvestigationStaff(
                dataItem?.InvestigationStaff
              );
              let i = index
            "
            >{{ staff.User | fullName }}</span
          >
        </ng-container>
        <ng-container *ngIf="dataItem?.InvestigationStaff?.length === 0"
          >-</ng-container
        >
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Action"
      [width]="10"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <!-- <button kendoButton class="mr-8" themeColor="primary" fillMode="link" iconClass="fa-solid fa-calendar" (click)="openStaffSidebar(dataItem)" style="padding: 2px"></button>
        <button kendoButton themeColor="primary" fillMode="link" (click)="moveToInProgress($event, dataItem)" *ngIf="investigationStatusCreate?.enabled">Move to In Progress</button> -->
        <kendo-dropdownbutton
            [data]="investigationStatusCreate?.enabled ?[{ text: 'Move to In Progress' },{ text: 'View Calendar' },] : [{ text: 'View Calendar' }]"
            themeColor="primary" fillMode="link" buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis"
            (itemClick)="itemSelected($event, dataItem)" [popupSettings]="{
                              appendTo: 'component',
                              animate: false,
                              width: 250,
                              popupClass: 'grid-action-items-menu'
                            }">
          </kendo-dropdownbutton>
      </ng-template>
    </kendo-grid-column>

    <ng-template
      kendoGridDetailTemplate
      let-dataItem
      [kendoGridDetailTemplateShowIf]="displayComments"
    >
      <kendo-grid
        [data]="dataItem?.Comments.slice(0, 5)"
        [pageable]="false"
        [scrollable]="'none'"
        [className]="'detail-items'"
      >
        <kendo-grid-column
          field="createdAt"
          title="Date/Time"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span style="text-transform: lowercase">
              {{ dataItem?.createdAt | customDate : "MM/dd/yy, h:mma" }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="text"
          title="Comment"
          [width]="120"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="text-overflow">{{ dataItem?.text }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="CreatedBy"
          title="User"
          [width]="50"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem?.CreatedBy | fullName }}
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </ng-template>
    <ng-template kendoGridNoRecordsTemplate>
      <p class="mt-24 mb-24">There are no results to display.</p>
    </ng-template>
  </kendo-grid>
</section>