import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiServiceTypeFilterType, IApiServiceTypesConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  serviceTypesConnectionQuery, addServiceTypeMutation,
  removeServiceTypeMutation, getServiceTypeByIdQuery,
  updateServiceTypeMutation
} from './service-type.operations';
import {
  IApiServiceTypeFilter, IApiAddServiceTypeInput, IApiUpdateServiceTypeInput,
  IApiRemoveServiceTypeInput, IApiServiceType
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlServiceTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiServiceTypeFilter[], options: IGqlBaseOptions): Observable<IApiServiceTypesConnection> {
    const view = filters.find(filter => filter.type === IApiServiceTypeFilterType.ViewListName);
    return this.apollo.query(connectionQuery(serviceTypesConnectionQuery(false, view), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddServiceTypeInput): Observable<IApiServiceType> {
    return this.apollo.mutate({
      mutation: addServiceTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(input: IApiRemoveServiceTypeInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeServiceTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateServiceTypeInput): Observable<IApiServiceType> {
    return this.apollo.mutate({
      mutation: updateServiceTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getServiceTypeById(id: string): Observable<IApiServiceType> {
    return this.apollo.query({
      query: getServiceTypeByIdQuery,
      variables: { id: id }
    }).pipe(
      safeResolve(true)
    );
  }
}
