<section>
    <app-mobile-investigation-metrics-filters
      (applyFilter)="applyFilter($event)"
      (clearFilter)="applyFilter($event)"
    ></app-mobile-investigation-metrics-filters>
    <app-mobile-investigation-metrics-table
      [tabIndex]="tabIndex"
      [dataSource]="dataSource"
      [data]="data"
      [sort]="sort"
      [pageOptions]="pageOptions"
      (pageChange)="pageChange($event)"
      (sortChange)="sortChange($event)"
    ></app-mobile-investigation-metrics-table>
</section>
  