<div [dialogRef]="isClosable && dialog" (appClickOutside)="close('closed')">
  <kendo-dialog-titlebar (close)="close('closed')" [ngClass]="!isClosable && 'hide-titlebar-actions'">
    <i *ngIf="headerIcon" class="fa-solid fa-circle-exclamation fa-xl"></i>
    <div class="modal-title ml-8">
      <span>{{title}}</span>
      <div class="modal-subtitle" *ngIf="subTitle" [innerHTML]="subTitle"></div>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">
    <p *ngIf="bodyMessage" class="warning-message mb-16 mt-16" [innerHTML]="bodyMessage">
    </p>
  </div>
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']" *ngIf="leftBtn || rightBtn">
    <hr class="divider-line mb-12" />
    <kendo-gridlayout-item class="mb-16" *ngIf="leftBtn">
      <button kendoButton class="full-btn white-bk" [size]="'medium'" themeColor="light"
        (click)="close('no')">{{leftBtn}}</button>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="mb-0" *ngIf="rightBtn">
      <button kendoButton class="full-btn" [size]="'medium'" themeColor="primary"
        (click)="close('yes')">{{rightBtn}}</button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</div>
