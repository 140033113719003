import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/enums/permissions.enums";

// Contacts Auth Configs

export const contacts: IEnforcePermissionConfig = {
  category: PermissionCategories.CONTACTS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const contactsProfileContactInformationViewAssigned: IEnforcePermissionConfig = {
  category: PermissionCategories.CONTACTS_PROFILE_CONTACT_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [PermissionActions.VIEW],
    Own: []
  }
};

export const contactsCreateConfig: IEnforcePermissionConfig = {
 ...contacts,
 appliedPermissions: {
   All: [PermissionActions.CREATE],
   AllAssigned: [],
   Assigned: [],
   Own: []
 } 
};

export const contactsDelete: IEnforcePermissionConfig = {
  ...contacts,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  } 
 };

 export const contactsInvestigations: IEnforcePermissionConfig = {
  category: PermissionCategories.CONTACTS_INVESTIGATIONS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.LIST],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const contactsInvestigationsView: IEnforcePermissionConfig = {
  category: PermissionCategories.CONTACTS_INVESTIGATIONS,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.VIEW],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const contactsProfileContactInformationUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.CONTACTS_PROFILE_CONTACT_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const contactsProfileCompanyInformationView: IEnforcePermissionConfig = {
  category: PermissionCategories.CONTACTS_PROFILE_COMPANY_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const contactsProfileCompanyInformationUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.CONTACTS_PROFILE_COMPANY_INFORMATION,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};