<kendo-dialog-titlebar (close)="preventClose($event)">
    <div class="modal-title">
        <span>{{data?.multiple ? files?.length ? 'Select file(s) to upload ('+files.length+')' : 'Select file(s) to upload' : 'Select file to upload'}}</span>
    </div>
</kendo-dialog-titlebar>
<section class="section-content">
    <div class="file-container">
        <ng-container *ngIf="selectedFileForCrop else fileUpload">
            <image-cropper [imageFile]="selectedFileForCrop.file" [maintainAspectRatio]="false"
                (imageCropped)="imageCropped($event)" [format]="'jpeg'" [imageQuality]="90"></image-cropper>
        </ng-container>

        <ng-template #fileUpload>
            <div class="file-select" [appDnd]="!isProcessing" (fileDropped)="uploadFile($event)">
                <i class="fa-solid fa-upload fa-4x"></i>
                <h3>Drag and drop file here</h3>
                <h3>or</h3>
                <input type="file" [accept]="data.restrictions" #fileUpload hidden (click)="fileUpload.value = null"
                    (change)="uploadFile($event?.target?.files)" [multiple]="data.multiple" [disabled]="isProcessing" />
                <button kendoButton [size]="'medium'" themeColor="light" (click)="fileUpload.click()">Browse for file</button>
            </div>

            <div class="file-list">
                <ul *ngIf="files?.length">
                    <cdk-virtual-scroll-viewport itemSize="56" class="scroll-viewport" (scrolledIndexChange)="onScroll()">
                    <li *cdkVirtualFor="let file of files; trackBy: trackBy;" [ngClass]="file?.errorMessage ? 'inValid': 'valid'">
                        <div class="file-info-container">
                            <div class="file-info" [ngSwitch]="file?.file?.type">
                                <ng-container *ngSwitchCase="getType('image', file?.file?.type)">
                                    <img [src]="file?.cropFile?.objectUrl || file?.preview" alt="image preview" (error)="onError($event, file)" />
                                </ng-container>
                                <ng-container *ngSwitchCase="getType('pdf', file?.file?.type)">
                                    <i class="fa-solid fa-file-pdf"></i>
                                </ng-container>
                                <ng-container *ngSwitchCase="getType('word', file?.file?.type)">
                                    <i class="fa-solid fa-file-word"></i>
                                </ng-container>
                                <ng-container *ngSwitchCase="getType('excel', file?.file?.type)">
                                    <i class="fa-solid fa-file-excel"></i>
                                </ng-container>
                                <ng-container *ngSwitchCase="getType('csv', file?.file?.type)">
                                    <i class="fa-solid fa-file-csv"></i>
                                </ng-container>
                                <ng-container *ngSwitchCase="getType('zip', file?.file?.type)">
                                    <i class="fa-solid fa-file-zipper"></i>
                                </ng-container>
                                <ng-container *ngSwitchCase="getType('video', file?.file?.type)">
                                    <i class="fa-solid fa-file-video"></i>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <i class="fa-solid fa-file"></i>
                                </ng-container>
                                <div class="ml-8 text-overflow">
                                    {{ file.file.name }}
                                    <br />
                                    {{ (file?.cropFile?.blob?.size || file?.file?.size) | formatFileSize: false }} <span style="color: red;" *ngIf="file?.errorMessage">{{file?.errorMessage}}</span>
                                </div>
                            </div>

                            <div class="file-action" *ngIf="!isProcessing">
                                <button *ngIf="file?.cropFile?.objectUrl && file.isImage && iscropFeatureEnabled" class="mr-8" kendoButton themeColor="primary"
                                    fillMode="link" (click)="revertCrop(file)">Revert</button>
                                <button *ngIf="!file?.cropFile?.objectUrl && file.isImage && iscropFeatureEnabled" class="mr-8" kendoButton themeColor="primary"
                                    fillMode="link" (click)="edit(file)">Edit</button>
                                <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-times"
                                    (click)="remove(file)"></button>
                            </div>
                        </div>
                        <kendo-progressbar *ngIf="uploadProgress[file.id]" [value]="uploadProgress[file.id] || 0" [label]="label"></kendo-progressbar>
                    </li>
                </cdk-virtual-scroll-viewport>
            </ul>
            <p class="loading-processing" *ngIf="!isProcessing && isScrolling && files?.length > 7">Loading...</p>
            <p class="loading-processing" *ngIf="isProcessing && files?.length">Processing...</p>
        </div>
        </ng-template>
    </div>
</section>
<kendo-dialog-actions layout="end">
    <ng-container *ngIf="selectedFileForCrop else cropAction">
        <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="resetCrop()">
            Crop Cancel
        </button>
        <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="saveCrop()">
            Crop
        </button>
    </ng-container>
    <ng-template #cropAction>
        <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="onClose(false)">
            Cancel
        </button>
        <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSave()" [disabled]="isValid || isProcessing">
            Save
        </button>
    </ng-template>
</kendo-dialog-actions>
