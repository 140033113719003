import { IApiAddPhotoFolderInput, IApiPhotoFolder, IApiUpdatePhotoFolderInput, IApiPhotoFolderFilter } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlPhotoFolderService } from "src/app/shared/modules/graphql/services";

@Injectable()
export class PhotoFolderService {

  constructor(
    private photoFolders: GqlPhotoFolderService,
  ) { }

  public get(filters: IApiPhotoFolderFilter[] = [], options = {}): Observable<IApiPhotoFolder[]> {
    return this.photoFolders.getPhotoFolders(filters, options);
  }

  public add(input: IApiAddPhotoFolderInput): Observable<IApiPhotoFolder> {
    return this.photoFolders.addPhotoFolder(input);
  }

  public update(input: IApiUpdatePhotoFolderInput): Observable<IApiPhotoFolder> {
    return this.photoFolders.updatePhotoFolder(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.photoFolders.removePhotoFolder(id);
  }
}
