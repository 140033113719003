import { IApiInvestigationRoleNames } from '../../modules/graphql/types/types';
import { map } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { StandardNamePipe } from '../../pipes/name.pipe';
import { Component, OnInit, forwardRef, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { UserService } from 'src/app/shared/services';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { IApiUser } from "../../modules/graphql/types/types";
import { unwrapConnection } from '../../rxjs.pipes';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import {
  staff
} from "src/app/shared/helpers/auth-config/staff.config";
@Component({
  selector: 'app-user-select-kendo',
  templateUrl: './user-select-kendo.component.html',
  styleUrls: ['./user-select-kendo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectKendoComponent),
      multi: true
    }
  ]
})
export class UserSelectKendoComponent implements OnInit, ControlValueAccessor, OnChanges {
  public authConfig = {
    staff
  };
  @ViewChild("autoCompleteUser", { static: false }) public autoCompleteUser: AutoCompleteComponent;
  @Input() placeholder = "Search for a User";
  @Input() label = "";
  @Input() staticUsers: IApiUser[];
  @Input() investigationRole: IApiInvestigationRoleNames = null;
  @Input() disabled = false;
  @Input() includeInactive = false;
  @Input() userViewFilter = null;
  @Input() formSubmitted = false;
  @Input() validate = false;
  @Input() excludeLoginUserId = null;
  @Input() userId: string = null;
  public users: any[] = [];

  @Output() selectionChanged = new EventEmitter<IApiUser | null>();

  public selected: any = null;
  public loading: boolean = false;
  public searchString: string = '';
  public selectedUserName: string = '';

  @Input() public set user(val: IApiUser) {
    this.users.push(val);
    this.selectUser(val?.id);
  }

  constructor(
    private userService: UserService,
    private fullNamePipe: StandardNamePipe,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
    if (this.userId) {
      this.userService.getByIdOnlyNefco(this.userId).pipe(
        this.notifications.catchAlertPipe(`Error loading user: ${this.userId}`)
      ).subscribe((user) => {
        user['itemFullName'] = `${user?.firstName} ${user?.lastName}`;
        this.users = [user];
        this.selectedUserName = user['itemFullName'];
      });
    }
    if (this.staticUsers) this.users.push(...this.staticUsers);
  }

  public ngOnChanges() {
    if (!this.userId) {
      this.selected = null;
      this.selectedUserName = null;
    }else{
      this.userService.getByIdOnlyNefco(this.userId).pipe(
        this.notifications.catchAlertPipe(`Error loading user: ${this.userId}`)
      ).subscribe((user) => {
        user['itemFullName'] = `${user?.firstName} ${user?.lastName}`;
        this.users = [user];
        this.selected = user;
        this.selectedUserName = user?.itemFullName;
      });
    }
  }

  public selectUser(selected: any) {
    const selecteduser = this.users?.find(a => a.id === selected);
    this.selected = selecteduser;
    this.selectedUserName = selecteduser?.itemFullName;
    this.selectionChanged.emit(selecteduser);
    this._propagateChange(this.selected);
  }

  public onSearchChange(search: string) {
    this.searchString = search;
    this.users = [];
    this.selectedUserName = null;
    this.autoCompleteUser.toggle(false);
    if (search?.length < 3) return;

    const filters = [{
      type: IApiUserFilterType.Search,
      value: search
    }];

    if (this.investigationRole) filters.push({
      type: IApiUserFilterType.Position,
      value: JSON.stringify(this.investigationRole.valueOf())
    });

    if (this.includeInactive) filters.push({
      type: IApiUserFilterType.IsInactive,
      value: 'true'
    });

    if(this.excludeLoginUserId) filters.push({
      type: IApiUserFilterType.NotUserId,
      value: JSON.stringify([this.excludeLoginUserId])
    }); 
    
    if (this.userViewFilter) {
      filters.push({
        type: this.userViewFilter,
        value: 'true'
      });
    }
    this.loading = true;
    this.autoCompleteUser.toggle(false)
    this.userService.get(filters).pipe(
      unwrapConnection(),
      map((item) => {
        const newContact = item.map((i) => {
          return {
            ...i,
            itemFullName: `${i.firstName} ${i.lastName}`
          }
        });
        if (this.staticUsers) newContact.push(...this.staticUsers);
        return newContact;
      })
    ).subscribe(results => {
      this.loading = false;
      this.autoCompleteUser.toggle(true);
      this.users = results;
    });
  }

  private _propagateChange = (_: any) => { };

  // Control Value accessor members
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.userId = value;
    }
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }

  public selectedDisplay(val: IApiUser) {
    return this.fullNamePipe.transform(val);
  }

  public closed() {
    if (typeof this.selected === "string") this.selectUser(null);
  }

  public blur() {
    if (typeof this.selected === "string" && (this.selected as string).length < 3) this.selectUser(null);
  }

}
