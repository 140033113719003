import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlNotificationMethodSettingService } from 'src/app/shared/modules/graphql/services/notification/notification-method-setting/notification-method-setting.service';
import {
  IApiAddNotificationMethodSettingInput, IApiNotificationMethodSetting,
  IApiUpdateNotificationMethodSettingInput, IApiNotificationMethodSettingFilter, IApiNotificationMethodSettingsConnection, IApiUpdateNotificationMethodSettingInputV2
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";


@Injectable()
export class UserNotificationMethodSettingService {

  constructor(
    private gqlNotificationMethodSettingService: GqlNotificationMethodSettingService
  ) { }

  public get(filters: IApiNotificationMethodSettingFilter[] = [], options?: IGqlBaseOptions): Observable<IApiNotificationMethodSettingsConnection> {
    return this.gqlNotificationMethodSettingService.get(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddNotificationMethodSettingInput): Observable<IApiNotificationMethodSetting> {
    return this.gqlNotificationMethodSettingService.add(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlNotificationMethodSettingService.remove(id);
  }

  public update(input: IApiUpdateNotificationMethodSettingInput): Observable<IApiNotificationMethodSetting> {
    return this.gqlNotificationMethodSettingService.update(input);
  }

  public updateV2(input: IApiUpdateNotificationMethodSettingInputV2): Observable<boolean> {
    return this.gqlNotificationMethodSettingService.updateV2(input);
  }

  public getById(id: string): Observable<IApiNotificationMethodSetting> {
    return this.gqlNotificationMethodSettingService.getById(id);
  }
}
