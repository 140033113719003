import { Component, Output, EventEmitter } from '@angular/core';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsFilterType, IApiInvestigationRoleNames, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { isArray } from 'lodash';
import moment from 'moment';

@Component({
  selector: 'app-mobile-investigation-metrics-filters',
  templateUrl: './mobile-investigation-metrics-filters.component.html',
  styleUrls: ['./mobile-investigation-metrics-filters.component.scss']
})
export class MobileInvestigationMetricsFiltersComponent {
  @Output() clearFilter = new EventEmitter<IApiInvestigationMetricsFilter[]>();
  @Output() applyFilter = new EventEmitter<IApiInvestigationMetricsFilter[]>();
  
  private _filters: IApiInvestigationMetricsFilter[] = [];
  public filterTypes = IApiInvestigationMetricsFilterType;

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  public range = { startDate: null, endDate: null };
  public userRoleName = IApiInvestigationRoleNames;
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  constructor(
  ) { }

  public customRangeChange() {
    if (moment(this.range.startDate).isValid() && moment(this.range.endDate).isValid()) {
      const dates = {
        startDate: NefcoDateHelper.toUtcStartOfDay(this.range.startDate, true),
        endDate: NefcoDateHelper.toUtcStartOfDay(this.range.endDate, false),
      };
      this.setFilters(
        JSON.stringify({
          startDate: dates.startDate,
          endDate: dates.endDate,
        }),
        IApiInvestigationMetricsFilterType.ReceivedDate
      );
    } else {
      this.setFilters(null, IApiInvestigationMetricsFilterType.ReceivedDate);
    }
  }

  public setFilters(value: string | undefined, type: IApiInvestigationMetricsFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value
    }] : filtersCopy;
  }

  public filterValue(filter: IApiInvestigationMetricsFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
      ? value
      : null;
  }

  public appliedFilters(): void {
    this.applyFilter.emit(this.filters);
  }

  public clearAll(): void {
    this.filters = [];
    this.range = { startDate: null, endDate: null };
    this.clearFilter.emit(this.filters);
  }

  public filterByIdValue(filter: IApiInvestigationMetricsFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }


  public setVal(event: any, type: IApiInvestigationMetricsFilterType) {
    let val = null;
    if (event?.length > 0) {
      val = JSON.stringify(event);
    }
    this.setFilters(val, type);
  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }

}
