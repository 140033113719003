<div [dialogRef]="dialog" (appClickOutside)="close()">
<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationStatusListView"
  #investigationStatusListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationStatusUpdate" #investigationStatusUpdate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationCauseListView" #investigationCauseListView="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationCauseUpdate" #investigationCauseUpdate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationCommentsView" #investigationCommentsView="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationInformationUpdate" #investigationInformationUpdate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationInformationListView" #investigationInformationListView="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationCommentsCreate"
  #investigationCommentsCreate="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-dialog-titlebar (close)="close()">
  <div class="modal-title">
    <span>ID: {{ data?.investigation?.nefcoNumber || 'Unassigned' }}</span>
  </div>
</kendo-dialog-titlebar>

<kendo-card width="100%" class="mb-24 mt-24"
  *ngIf="(investigationStatusListView?.enabled || investigationCauseListView?.enabled) && data?.from === 'INVESTIGATION_DETAILS'">
  <!-- <kendo-card-body class="p-0"> -->
  <!-- Investigation Status and Comments -->
  <kendo-panelbar [expandMode]="2">
    <kendo-panelbar-item title="Investigation Status: {{ statusName[data.investigation.status] }}" [expanded]="true">
      <ng-template kendoPanelBarContent>
        <form #form="ngForm" (ngSubmit)="updateStatus()">
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']"
            class="mb-16">
            <kendo-gridlayout-item class="mb-0">
              <kendo-formfield class="kendo-formfield" *ngIf="investigationStatusListView?.enabled">
                <kendo-label class="asterisk" text="Status"></kendo-label>
                <kendo-dropdownlist [data]="statuses" fillMode="outline" [disabled]="!investigationStatusUpdate.enabled"
                  textField="text" valueField="value" name="status" #statusField="ngModel" [valuePrimitive]="true"
                  [defaultItem]="{text: 'All Status', value: null}" [(ngModel)]="status"
                  [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required>
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem?.text }} </span>
                    <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem?.text }} </span>
                  </ng-template>
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="!statusField?.valid && (statusField?.dirty || statusField?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="statusField?.errors?.required">
                    Status cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0">
              <kendo-formfield class="kendo-formfield" *ngIf="investigationCauseListView?.enabled">
                <kendo-label text="Cause of Fire"></kendo-label>
                <kendo-dropdownlist [data]="causes" fillMode="outline" [disabled]="!investigationCauseUpdate.enabled"
                  textField="value" valueField="text" name="cause" [valuePrimitive]="true"
                  [defaultItem]="{text: '', value: 'All causes'}" [(ngModel)]="cause"
                  [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="!dataItem?.text" class="placeholder"> {{ dataItem?.value }} </span>
                    <span *ngIf="dataItem?.text" class="not-placeholder"> {{ dataItem?.value }} </span>
                  </ng-template>
                </kendo-dropdownlist>
              </kendo-formfield>
            </kendo-gridlayout-item>

          </kendo-gridlayout>

          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="asterisk" text="Please provide comments about this change."></kendo-label>
              <kendo-textarea #comment="ngModel" required name="description" [(ngModel)]="statusComment"
                placeholder="Enter comment" fillMode="outline" resizable="both">
              </kendo-textarea>
              <kendo-formerror *ngIf="!comment?.valid && (comment?.dirty || comment?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="comment?.errors?.required">
                  Comment cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <div class="mt-16">
            <button type="submit" class="save-btn" kendoButton themeColor="primary" fillMode="solid">Save</button>
          </div>
        </form>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
  <!-- </kendo-card-body> -->
</kendo-card>
<kendo-card width="100%" class="mb-24" *ngIf="data?.from === 'INVESTIGATION_LIST'">
  <kendo-panelbar [expandMode]="2">
    <kendo-panelbar-item title="Investigation Priority" [expanded]="true">
      <ng-template kendoPanelBarContent>
        <form #priorityForm="ngForm" (ngSubmit)="savePriority()" *ngIf="investigationInformationUpdate?.enabled;else viewPriority">
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
            class="mb-16">
            <kendo-gridlayout-item>
              <app-investigation-priorities-kendo [(ngModel)]="priorityId"
                name="priorityId"></app-investigation-priorities-kendo>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0 text-right">
              <button kendoButton class="mr-16" themeColor="light" type="button" fillMode="solid"
                (click)="resetPriorityForm()">Discard Edits</button>
              <button type="submit" kendoButton themeColor="primary" fillMode="solid">Save Edits</button>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </form>
        <ng-template #viewPriority>
          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 12, cols: 0}" [cols]="['repeat(4,minmax(0,1fr))']"
          class="p-12">
          <kendo-gridlayout-item class="mb-0">
            <h6 class="mb-0">
              <small>Priority</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item [colSpan]="3" class="mb-0">
            <p class="font-weight600 mb-0">{{ investigation?.Priority?.name || '-' }}</p>
          </kendo-gridlayout-item>
          </kendo-gridlayout>
        </ng-template>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</kendo-card>
<kendo-card width="100%" class="mb-24 investigation-info-sidebar" *ngIf="data?.from === 'INVESTIGATION_LIST' && investigationInformationListView?.enabled">
  <kendo-panelbar [expandMode]="2">
    <kendo-panelbar-item title="Investigation Information" [expanded]="true">
      <ng-template kendoPanelBarContent>
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 12, cols: 0}" [cols]="['repeat(4,minmax(0,1fr))']"
          class="p-12">
          <kendo-gridlayout-item class="mb-0">
            <h6>
              <small>Loss Date</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item [colSpan]="3" class="mb-0">
            <p class="font-weight600">{{ (investigation?.lossDate | customDate : "MM/dd/yyyy": true) || '-' }}</p>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0">
            <h6>
              <small>Risk Type</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0" [colSpan]="3">
            <p class="font-weight600" [innerHTML]="investigation?.RiskType?.name || '-'"></p>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0">
            <h6>
              <small>Loss Address</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0" [colSpan]="3">
            <p class="font-weight600">
              <app-address-view [address]="investigation?.LossAddress"></app-address-view>
            </p>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0">
            <h6>
              <small>Policy#</small>
              <br>
              <small>Claim#</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0" [colSpan]="3">
            <p class="font-weight600 mb-0" [innerHTML]="investigation?.Detail?.policyNumber || '-'">
            </p>
            <p class="font-weight600" [innerHTML]="investigation?.Detail?.claimNumber || '-'">
            </p>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0">
            <h6>
              <small>Insured</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item [colSpan]="3" class="mb-0">
            <p class="font-weight600 mb-0">{{ investigation?.Insured?.contactName || investigation?.Insured?.insuredName
              ||
              '-'}}</p>
            <p><app-email-phone-view *ngFor="let phone of investigation?.Insured?.Phones" [phone]="phone">
              </app-email-phone-view></p>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0">
            <h6>
              <small>Assigned</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item [colSpan]="3" class="mb-0">
            <p class="font-weight600" [ngClass]="{'mb-0': !last}"
              *ngFor="let person of investigation?.InvestigationStaff; let last = last">{{
              person?.User |
              fullName}} <span class="no-font-weight600">{{(person.Role?.title | quiet) || '-'}}</span></p>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0 no-border">
            <h6>
              <small>Status</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item [colSpan]="3" class="mb-0 no-border">
            <p class="font-weight600 mb-0">{{ ((investigation?.status || '') | quiet)|| '-'}}</p>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</kendo-card>
<kendo-card width="100%" class="mb-24 investigation-info-sidebar" *ngIf="data?.from === 'INVESTIGATION_LIST' && investigationInformationListView?.enabled">
  <kendo-panelbar [expandMode]="2">
    <kendo-panelbar-item title="Client Information" [expanded]="true">
      <ng-template kendoPanelBarContent>
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 12, cols: 0}" [cols]="['repeat(4,minmax(0,1fr))']"
          class="p-12">
          <kendo-gridlayout-item class="mb-0">
            <h6>
              <small>Client Name</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item [colSpan]="3" class="mb-0">
            <p class="font-weight600">{{ investigation?.Client | fullName }}</p>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0">
            <h6>
              <small>Client Phone Number</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0" [colSpan]="3">
            <p *ngIf="investigation?.Client?.Phones?.length;else noData"> <app-email-phone-view
                *ngFor="let clientPhone of investigation?.Client?.Phones" [phone]="clientPhone"></app-email-phone-view>
            </p>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0">
            <h6>
              <small>Company Name</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0" [colSpan]="3">
            <p class="font-weight600" [innerHTML]="investigation?.ClientBranch?.Company?.name || '-'">
            </p>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0 no-border">
            <h6>
              <small>Branch Address</small>
            </h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="mb-0 no-border" [colSpan]="3">
            <p class="font-weight600 mb-0" *ngIf="investigation?.ClientBranch?.Addresses?.length;else noData">
              <app-address-view *ngFor="let address of investigation?.ClientBranch?.Addresses"
                [address]="address"></app-address-view>
              <ng-template *ngIf="!investigation?.ClientBranch?.Addresses?.length">-</ng-template>
            </p>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</kendo-card>
<kendo-card width="100%" class="mb-24" *ngIf="data?.from === 'INVESTIGATION_DETAILS'">
  <!-- History table -->
  <kendo-panelbar [expandMode]="2">
    <kendo-panelbar-item title="History" [expanded]="true">
      <ng-template kendoPanelBarContent>
        <kendo-grid class="investigation-list" [data]="data.investigation?.History" [sortable]="true"
          [navigatable]="false">

          <kendo-grid-column title="Date/Time" [width]="18" [sortable]="true" [headerClass]="'nocursor'"
            class="id-column font-weight600">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.createdAt | customDate: 'MM/dd/yyyy' }}</span> <br />
              <span class="time">{{ dataItem?.createdAt | customDate: 'hh:mm a' }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Category" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.updateCategory || '-' }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Type" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.updateType || '-' }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Comment" [width]="25" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <p>
                {{(data.investigation?.History[rowIndex]?.showMore) ? (dataItem?.comment || '-') : dataItem?.comment |
                slice:0:showMoreChar}}
                <span
                  *ngIf="!data.investigation?.History[rowIndex]?.showMore && dataItem?.comment?.length > showMoreChar">...</span>
                <a href="javascript:;" class="primary-color"
                  *ngIf="!data.investigation?.History[rowIndex]?.showMore && dataItem?.comment?.length > showMoreChar"
                  (click)="data.investigation?.History[rowIndex]['showMore']=true">Show More</a>
                <a href="javascript:;" class="primary-color" *ngIf="data.investigation?.History[rowIndex]?.showMore"
                  (click)="data.investigation?.History[rowIndex]['showMore']=false">Show Less</a>
              </p>

            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="User" [width]="18" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.CreatedBy | fullName }}</span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</kendo-card>
<kendo-card width="100%" class="mb-24" *ngIf="data?.from === 'INVESTIGATION_DETAILS'">
  <!-- Request Log table -->
  <kendo-panelbar [expandMode]="2" class="">
    <kendo-panelbar-item title="Request Log">
      <ng-template kendoPanelBarContent>
        <kendo-grid class="investigation-list" [data]="data.investigation?.Requests" [sortable]="true"
          [navigatable]="false">

          <kendo-grid-column title="Date/Time" [width]="18" [sortable]="true" [headerClass]="'nocursor'"
            class="id-column font-weight600">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.createdAt | customDate: 'MM/dd/yyyy' }}</span> <br />
              <span class="time">{{ dataItem?.createdAt | customDate: 'hh:mm a' }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Type" [width]="18" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.RequestType?.name || '-' }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Status" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.RecentHistory?.name || '-' }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Comment" [width]="25" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <p>
                {{(data.investigation?.Requests[rowIndex]?.showMore) ? (dataItem?.RecentHistory.comment || '-') :
                dataItem?.RecentHistory.comment | slice:0:showMoreChar}}
                <span
                  *ngIf="!data.investigation?.Requests[rowIndex]?.showMore && dataItem?.RecentHistory.comment?.length > showMoreChar">...</span>
                <a href="javascript:;" class="primary-color"
                  *ngIf="!data.investigation?.Requests[rowIndex]?.showMore && dataItem?.RecentHistory.comment?.length > showMoreChar"
                  (click)="data.investigation?.Requests[rowIndex]['showMore']=true">Show More</a>
                <a href="javascript:;" class="primary-color" *ngIf="data.investigation?.Requests[rowIndex]?.showMore"
                  (click)="data.investigation?.Requests[rowIndex]['showMore']=false">Show Less</a>
              </p>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="User" [width]="18" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.RecentHistory?.CreatedBy | fullName }}</span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</kendo-card>
<kendo-card width="100%" class="mb-8" *ngIf="investigationCommentsView?.enabled">
  <!-- Comment log -->
  <kendo-panelbar [expandMode]="2">
    <kendo-panelbar-item title="Comment Log" [expanded]="data?.from === 'INVESTIGATION_LIST'">
      <ng-template kendoPanelBarContent>
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
          class="mb-16 p-12">
          <!-- Add comment section -->
          <kendo-gridlayout-item *ngIf="investigationCommentsCreate?.enabled">
            <kendo-formfield>
              <kendo-label [for]="description" text=""></kendo-label>
              <kendo-textarea #description [(ngModel)]="comment" placeholder="Enter comment" fillMode="outline"
                resizable="both">
              </kendo-textarea>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <!-- Add Comment Button -->
          <div class="d-flex flex-row-reverse mt-16">
            <button kendoButton themeColor="primary" fillMode="solid" (click)="addComment();"
              *ngIf="investigationCommentsCreate?.enabled">Add Comment</button>
            <button kendoButton themeColor="light" class="mr-12" fillMode="solid" (click)="close()">Cancel</button>
          </div>
        </kendo-gridlayout>
        <kendo-grid class="investigation-list p-12" [sortable]="true"
        [data]="commentsData"
        [pageSize]="pageOptions?.pageSize"
        [skip]="pageOptions?.skip"
        [pageable]="{
          buttonCount: 6,
          type: 'numeric',
          pageSizes: [5, 10, 25, 100],
          previousNext: true,
          responsive: true,
          position: 'bottom'
        }"
        (pageChange)="pageChange($event)"
        [sortable]="false"
          [navigatable]="false">

          <kendo-grid-column title="Date/Time" [width]="18" [sortable]="true" [headerClass]="'nocursor'"
            class="id-column font-weight600">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.createdAt | customDate: 'MM/dd/yyyy' }}</span> <br />
              <span class="time">{{ dataItem?.createdAt | customDate: 'hh:mm a' }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Comment" [width]="25" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <p>
                {{(data.investigation?.Comments[rowIndex]?.showMore) ? (dataItem?.text || '-') : dataItem?.text |
                slice:0:showMoreChar}}
                <span
                  *ngIf="!data.investigation?.Comments[rowIndex]?.showMore && dataItem?.text?.length > showMoreChar">...</span>
                <a href="javascript:;" class="primary-color"
                  *ngIf="!data.investigation?.Comments[rowIndex]?.showMore && dataItem?.text?.length > showMoreChar"
                  (click)="data.investigation?.Comments[rowIndex]['showMore']=true">Show More</a>
                <a href="javascript:;" class="primary-color" *ngIf="data.investigation?.Comments[rowIndex]?.showMore"
                  (click)="data.investigation?.Comments[rowIndex]['showMore']=false">Show Less</a>
              </p>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="User" [width]="18" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.CreatedBy | fullName }}</span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</kendo-card>
<ng-template #noData>
  <p class="font-weight600">-</p>
</ng-template>
</div>