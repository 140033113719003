<section>
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0">
      <h4 class="page-title"> {{ id ? "Modify" : "Create" }} Investigation {{investigation?.nefcoNumber ? ( '#' +
        investigation?.nefcoNumber): ''}}
      </h4>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <div class="content-wrapper mt-12">
    <form #form="ngForm" appDebouncedChange [appDirtyFormNotify]="form">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
        <kendo-gridlayout-item class="mb-0">
          <p class="date-time secondary">
            Received:
            {{ model?.receivedDate | customDate : "MM-dd-yyyy hh:mm:ss a" }}
          </p>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="mb-0">
          <app-investigation-priorities-select name="priorities" [(ngModel)]="model.PriorityId"
            required></app-investigation-priorities-select>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="mb-0 right-grid">
          <kendo-formfield>
            <kendo-label>
              <kendo-switch [(ngModel)]="model.onHold" class="mr-5" name="onHold" title="On Hold"></kendo-switch>
              On Hold
            </kendo-label>
          </kendo-formfield>
          <span>
            <i class="fa-solid fa-solid fa-triangle-exclamation fa-lg" style="color: red;"></i>
            Not Saved
          </span>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-card [width]="'100%'" class="mt-16">
        <kendo-card-body>
          <div class="section-header">
            <h2>Investigation Information</h2>
          </div>
          <section>
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
              <!-- LEFT -->
              <kendo-gridlayout-item>
                <!-- client and billing info-->
                <app-investigation-client [investigation]="this.baseModel" (selected)="setClientInfo($event)"
                  [resetForm]="resetForm"></app-investigation-client>
                <input type="hidden" name="clientId" [(ngModel)]="this.model.ClientId" />
                <input type="hidden" name="ClientBranchId" [(ngModel)]="this.model.ClientBranchId" />
                <input type="hidden" name="billToId" [(ngModel)]="this.model.BillToId" required />
                <input type="hidden" name="billToBranchId" [(ngModel)]="this.model.BillToBranchId" />
                <input type="hidden" name="companyId" [(ngModel)]="this.model.CompanyId" />
                <kendo-gridlayout class="mt-12" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
                  [cols]="['repeat(2,minmax(0,1fr))']">
                  <kendo-gridlayout-item>
                    <!-- lossDate -->
                    <kendo-formfield>
                      <kendo-label text="Loss Date"></kendo-label>
                      <kendo-datepicker calendarType="classic" [fillMode]="'outline'" [name]="'lossDate'"
                        [(ngModel)]="lossDate" placeholder="Enter date" format="MM/dd/yyyy"
                        formatPlaceholder="formatPattern"></kendo-datepicker>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <!-- lossTime -->
                    <kendo-formfield>
                      <kendo-label text="Loss Time"></kendo-label>
                      <kendo-timepicker format="hh:mm a" [name]="'lossTime'" [disabled]="!lossDate"
                        [(ngModel)]="lossTime" placeholder="Loss time"></kendo-timepicker>
                      <kendo-formhint>(ie, HH:MM)</kendo-formhint>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label [for]="description" text="Description"></kendo-label>
                      <kendo-textarea #description="ngModel" [(ngModel)]="model.Detail.riskDescription"
                        name="description" placeholder="Risk description" fillMode="outline"></kendo-textarea>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <app-risk-types-select name="riskTypeId" [(ngModel)]="model.RiskTypeId"
                      [isMat]="false"></app-risk-types-select>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label text="Policy #"></kendo-label>
                      <kendo-textbox name="policyNumber" #policyNumber="ngModel" name="policyNumber"
                        [(ngModel)]="model.Detail.policyNumber" placeholder="Enter policy #" fillMode="outline"
                        resizable="both" appNoWhiteSpace></kendo-textbox>
                      <span class="text-danger" *ngIf="!model.Detail.policyNumber && !model.Detail.claimNumber">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span>
                          A Policy # OR Claim # must be entered
                        </span>
                      </span>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label text="Claim #"></kendo-label>
                      <kendo-textbox name="claimNumber" #claimNumber="ngModel" name="claimNumber"
                        [(ngModel)]="model.Detail.claimNumber" placeholder="Enter claim #" fillMode="outline"
                        resizable="both" appNoWhiteSpace></kendo-textbox>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <app-notes name="detailNotes" title="Investigation Notes:"
                      [(ngModel)]="model.Detail.notes">message</app-notes>
                  </kendo-gridlayout-item>
                </kendo-gridlayout>
              </kendo-gridlayout-item>
              <!-- Right -->
              <kendo-gridlayout-item>
                <app-address-autocomplete *ngIf="!!mapsLoaded" [address]="model.LossAddress"
                  (setAddress)="setLossAddress($event)" (setTimezone)="setTimezone($event)" [loadAPI]="false"
                  [resetForm]="resetForm"></app-address-autocomplete>
                <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-label class="asterisk" [for]="timezone" text="Timezone"></kendo-label>
                      <kendo-dropdownlist #timezone [data]="timeZones" name="timezone" textField="key"
                        valueField="value" [valuePrimitive]="true" [(ngModel)]="model.timezone" fillMode="outline"
                        [defaultItem]="{value: '', key: 'Select timezone'}"
                        [popupSettings]="{appendTo: 'component', animate: false}">
                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                          <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.key }} </span>
                          <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.key }} </span>
                        </ng-template>
                      </kendo-dropdownlist>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <div class="notes-button">
                      <p class="mb-0">
                        <span class="font-weight-bold">Note:</span> Mapping features
                        only apply when the address search is used. Custom addresses
                        will not return proximity results.
                      </p>
                      <button kendoButton
                        [iconClass]="addressCheckComplete ? 'fa-solid fa-circle-check fa-lg': 'fa-solid fa-triangle-exclamation fa-lg'"
                        (click)="checkLossAddress()" [disabled]="!model.LossAddress || !model.LossAddress.longitude"
                        [themeColor]="addressCheckComplete ? 'primary' : 'error'" fillMode="solid">Check</button>
                    </div>
                  </kendo-gridlayout-item>
                  <!-- Investigation Address / Scene Exam Address -->
                  <kendo-gridlayout-item>
                    <div style="display: flex;">
                      <p> Scene Exam Address:</p>
                      <span class="k-checkbox-wrap ml-16">
                        <input #isLossSameAddress type="checkbox" kendoCheckBox name="isLossSameAddress"
                          [(ngModel)]="isInvestigationAddressSameAsLoss" />
                        <kendo-label [for]="isLossSameAddress" class="k-checkbox-label font-weight-600"
                          style="margin-bottom: 0" text="Same as loss address"></kendo-label>
                      </span>
                    </div>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <ng-container *ngIf="!isInvestigationAddressSameAsLoss">
                      <app-address-autocomplete *ngIf="!!mapsLoaded" [address]="model.InvestigationAddress"
                        (setAddress)="setInvestigationAddress($event)" [loadAPI]="false"></app-address-autocomplete>
                      <br />
                    </ng-container>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <div style="display: flex;">
                      <p> Docusketch Imaging:</p>
                      <span class="k-checkbox-wrap ml-16">
                        <input #docusketch type="checkbox" kendoCheckBox name="docusketch"
                          [(ngModel)]="model.docusketchRequested" />
                        <kendo-label [for]="docusketch" class="k-checkbox-label font-weight-600"
                          style="margin-bottom: 0" text="Does the client require Docusketch imaging?"></kendo-label>
                      </span>
                    </div>
                    <kendo-formhint class="mt-0" style="margin-left: 164px">* Additional fees apply</kendo-formhint>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item *ngIf="model?.docusketchRequested">
                    <div style="display: flex;">
                      <p> Please Indicate:</p>
                      <span class="k-checkbox-wrap ml-34">
                        <input #docusketch type="checkbox" kendoCheckBox name="isXactimate"
                          [(ngModel)]="model.isXactimate" />
                        <kendo-label [for]="docusketch" class="k-checkbox-label font-weight-600"
                          style="margin-bottom: 0" text="Xactimate"></kendo-label>
                      </span>
                      <span class="k-checkbox-wrap ml-16">
                        <input #symbility type="checkbox" kendoCheckBox name="isSymbility"
                          [(ngModel)]="model.isSymbility" />
                        <kendo-label [for]="symbility" class="k-checkbox-label font-weight-600" style="margin-bottom: 0"
                          text="Symbility"></kendo-label>
                      </span>
                    </div>
                    <kendo-formhint
                      *ngIf="(!model.isXactimate && !model.isSymbility) || (model.isXactimate && model.isSymbility)"
                      class="mt-0 text-danger" style="margin-left: 164px">Please fill out either the Xactimate or
                      Symbility</kendo-formhint>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item>
                    <kendo-formfield>
                      <kendo-textarea #specialInstructions="ngModel" [(ngModel)]="model.Detail.specialInstructions"
                        name="specialInstructions" placeholder="Enter special billing instructions" rows="3"
                        fillMode="outline"></kendo-textarea>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                </kendo-gridlayout>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </section>
        </kendo-card-body>
      </kendo-card>
      <kendo-card width="100%" *ngIf="!id">
        <kendo-card-body>
          <h2 class="mb-24">Vehicles</h2>
          <h6 class="mb-24" *ngIf="vehiclesModel?.length > 0">New Vehicle:</h6>

          <div class="text-center" *ngIf="!vehiclesModel?.length">
            <button kendoButton iconClass="fa-regular fa-plus" themeColor="light" fillMode="solid"
              (click)="addVehicle()">Add Vehicle</button>
          </div>

          <section *ngIf="vehiclesModel?.length > 0">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
              [cols]="['18%', '18%', '18%', '18%', '18%', '3%']">
              <ng-container *ngFor="let item of vehiclesModel; let index = index">
                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Make"></kendo-label>
                    <kendo-textbox name="make_{{index}}" #make="ngModel" [(ngModel)]="item.make" fillMode="outline"
                      placeholder="Enter make" required appNoWhiteSpace></kendo-textbox>
                    <kendo-formerror *ngIf="!make?.valid && (make?.dirty || make?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="make?.errors?.required">Make cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Model"></kendo-label>
                    <kendo-textbox name="model_{{index}}" #model="ngModel" [(ngModel)]="item.model" fillMode="outline"
                      placeholder="Enter model" required appNoWhiteSpace></kendo-textbox>
                    <kendo-formerror *ngIf="!model?.valid && (model?.dirty || model?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="model?.errors?.required">model cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Year"></kendo-label>
                    <kendo-textbox name="year_{{index}}" #year="ngModel" [(ngModel)]="item.year" fillMode="outline"
                      placeholder="Enter year" required appNoWhiteSpace></kendo-textbox>
                    <kendo-formerror *ngIf="!year?.valid && (year?.dirty || year?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="year?.errors?.required">Year cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Vin #"></kendo-label>
                    <kendo-textbox name="vinNumber_{{index}}" #vinNumber="ngModel" [(ngModel)]="item.vinNumber"
                      fillMode="outline" placeholder="Enter vin #" required appNoWhiteSpace></kendo-textbox>
                    <kendo-formerror *ngIf="!vinNumber?.valid && (vinNumber?.dirty || vinNumber?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="vinNumber?.errors?.required">Vin cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label text="Stock"></kendo-label>
                    <kendo-textbox name="stockNumber_{{index}}" #stockNumber="ngModel" [(ngModel)]="item.stockNumber"
                      fillMode="outline" placeholder="Enter stock #" appNoWhiteSpace></kendo-textbox>
                    <kendo-formerror *ngIf="!stockNumber?.valid && (stockNumber?.dirty || stockNumber?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="stockNumber?.errors?.required">Stock cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="actions">
                  <span class="k-icon k-i-minus-outline" style="margin-top: 30px;"
                    (click)="removeVehicle(index)"></span>
                </kendo-gridlayout-item>
              </ng-container>
            </kendo-gridlayout>
            <button kendoButton class="mr-24" icon="plus-circle" themeColor="primary" fillMode="link"
              (click)="addVehicle()">Add Additional Vehicle</button>
            <br />
            <br />
            <app-notes name="vehicleNotes" title="Vehicle Notes:"
              [(ngModel)]="model.Detail.vehicleNotes">message</app-notes>
          </section>
        </kendo-card-body>
      </kendo-card>

      <kendo-card width="100%" *ngIf="!id">
        <kendo-card-body>
          <h2 class="mb-24">Party</h2>
          <h6 class="mb-24" *ngIf="enableAddParty">Add Party</h6>

          <div class="text-center" *ngIf="!enableAddParty">
            <button kendoButton iconClass="fa-regular fa-plus" themeColor="light" fillMode="solid"
              (click)="enableAddParty = true">Add Party</button>
          </div>
          <section *ngIf="enableAddParty">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
              [cols]="['25%', '16%', '16%', '16%', '16%', '5%']">
              <kendo-gridlayout-item [colSpan]="1">
                <kendo-formfield>
                  <kendo-label text="Contact Role"></kendo-label>
                  <kendo-dropdownlist name="role" #role="ngModel" [data]="roles" textField="name" valueField="id"
                    fillMode="outline" [valuePrimitive]="true" [defaultItem]="{id: '', name: 'Select contact role'}"
                    [(ngModel)]="roleId" [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span *ngIf="dataItem?.name === 'Select contact role'" class="placeholder"> {{ dataItem?.name }}
                      </span>
                      <span *ngIf="dataItem?.name !== 'Select contact role'" class="not-placeholder"> {{ dataItem?.name
                        }} </span>
                    </ng-template>
                  </kendo-dropdownlist>
                </kendo-formfield>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item [colSpan]="5">
                <div>
                  <button class="mt-24" kendoButton themeColor="primary" fillMode="solid" (click)="addContact()"
                    style="width: 110px;" [disabled]="!roleId">Add Contact</button>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>

            <app-notes name="partyNotes" title="Party Notes:" [(ngModel)]="model.Detail.partyNotes">message</app-notes>

            <form #frm="ngForm" *ngIf="parties?.length" class="mt-16">
              <kendo-panelbar [expandMode]="2">
                <kendo-panelbar-item [expanded]="true" *ngFor="let party of parties; let index = index;">
                  <ng-template kendoPanelBarItemTitle>
                    <kendo-gridlayout style="width: 100%;" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
                      [cols]="['repeat(3,minmax(0,1fr))']">
                      <kendo-gridlayout-item class="mb-0">
                        <h6 class="panelbar-title mb-0"> {{ party.Role?.name }}</h6>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <p class="mb-0"> {{ party.Contact?.insuredName }} {{ party?.Contact | fullName }}</p>
                      </kendo-gridlayout-item>
                    </kendo-gridlayout>
                  </ng-template>
                  <ng-template kendoPanelBarContent>
                    <section class="p-16">
                      <div class="float-right">
                        <button kendoButton iconClass="fa-solid fa-minus-circle" themeColor="error" fillMode="solid"
                          (click)="removeParty(index)">Remove</button>
                        <button kendoButton iconClass="fa-solid fa-pencil" themeColor="primary" class="ml-3"
                          fillMode="solid" (click)="editContact(party.Contact.id)">Edit Contact</button>
                      </div>
                      <kendo-card class="accordion-body" style="display: inline-block;" [width]="'100%'">
                        <kendo-card-body class="p-0">
                          <h4> Contact Information </h4>
                          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 26, cols: 5}"
                            [cols]="['repeat(5,minmax(0,1fr))']">
                            <kendo-gridlayout-item *ngIf="party.Contact?.insuredName" class="mb-0">
                              <p class="kendo-caption">
                                Insured Name
                              </p>
                              <p class="kendo-caption-body" [innerHTML]="party?.Contact?.insuredName || '-'">
                              </p>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                First Name
                              </p>
                              <p class="kendo-caption-body" [innerHTML]="party?.Contact?.firstName || '-'">
                              </p>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                Last Name
                              </p>
                              <p class="kendo-caption-body" [innerHTML]="party?.Contact?.lastName || '-'">
                              </p>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                Nickname
                              </p>
                              <p class="kendo-caption-body" [innerHTML]="party?.Contact?.nickName || '-'">
                              </p>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                Suffix
                              </p>
                              <p class="kendo-caption-body" [innerHTML]="party?.Contact?.suffix || '-'">
                              </p>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0" *ngIf="party?.Contact?.policyNumber">
                              <p class="kendo-caption">
                                Policy #
                              </p>
                              <p class="kendo-caption-body" [innerHTML]="party?.Contact?.policyNumber || '-'">
                              </p>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0" *ngIf="party?.Contact?.claimNumber">
                              <p class="kendo-caption">
                                Claim #
                              </p>
                              <p class="kendo-caption-body" [innerHTML]="party?.Contact?.claimNumber || '-'">
                              </p>
                            </kendo-gridlayout-item>
                            <!-- Displaying Contact Information -->
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                Phones
                              </p>
                              <ng-container *ngFor="let phone of party?.Contact?.Phones else noData">
                                <app-email-phone-view [phone]="phone"></app-email-phone-view>
                              </ng-container>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                Emails
                              </p>
                              <ng-container *ngFor="let email of party.Contact.Emails else noData">
                                <app-email-phone-view [email]="email"></app-email-phone-view>
                              </ng-container>
                            </kendo-gridlayout-item>
                          </kendo-gridlayout>
                        </kendo-card-body>
                      </kendo-card>
                      <!-- Displaying Addresses -->
                      <kendo-card class="accordion-body" style="display: inline-block;" [width]="'100%'"
                        *ngIf="party?.Contact?.Addresses?.length">
                        <kendo-card-body class="p-0">
                          <h4> Address </h4>
                          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 26, cols: 5}"
                            [cols]="['repeat(5,minmax(0,1fr))']">
                            <kendo-gridlayout-item *ngFor="let address of party?.Contact?.Addresses else noData"
                              class="mb-0">
                              <app-address-view [address]="address"></app-address-view>
                            </kendo-gridlayout-item>
                          </kendo-gridlayout>
                        </kendo-card-body>
                      </kendo-card>
                      <!-- Displaying Company/Branch -->
                      <kendo-card class="accordion-body" style="display: inline-block;" [width]="'100%'"
                        *ngIf="party?.Contact?.Addresses?.length">
                        <kendo-card-body class="p-0">
                          <h4> Company Information </h4>
                          <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 26, cols: 5}"
                            [cols]="['repeat(5,minmax(0,1fr))']" *ngFor="let branch of party?.Contact?.Branches">
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                Company Name
                              </p>
                              <p class="kendo-caption-body" [innerHTML]="branch?.Company?.name || '-'">
                              </p>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                Branch Name
                              </p>
                              <p class="kendo-caption-body" [innerHTML]="branch?.name || '-'">
                              </p>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                Branch Phones
                              </p>
                              <ng-container *ngFor="let phone of branch?.Phones else noData">
                                <app-email-phone-view [phone]="phone"></app-email-phone-view>
                              </ng-container>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                Branch Emails
                              </p>
                              <ng-container *ngFor="let email of branch?.Emails else noData">
                                <app-email-phone-view [email]="email"></app-email-phone-view>
                              </ng-container>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0">
                              <p class="kendo-caption">
                                Branch Addresses
                              </p>
                              <ng-container *ngFor="let address of branch?.Addresses else noData">
                                <app-address-view [address]="address"></app-address-view>
                              </ng-container>
                            </kendo-gridlayout-item>
                          </kendo-gridlayout>
                        </kendo-card-body>
                      </kendo-card>
                    </section>
                  </ng-template>
                </kendo-panelbar-item>
              </kendo-panelbar>
            </form>
          </section>
        </kendo-card-body>
      </kendo-card>

      <kendo-card width="100%" *ngIf="!id">
        <kendo-card-body>
          <h2 class="mb-24">Linked Investigation</h2>
          <h6 class="mb-24" *ngIf="linkedInvestigationModel?.length > 0">Add Linked Investigation</h6>

          <div class="text-center" *ngIf="!linkedInvestigationModel?.length">
            <button kendoButton iconClass="fa-regular fa-plus" themeColor="light" fillMode="solid"
              (click)="addLinkedInvestigation()">Add Linked Investigation</button>
          </div>

          <section *ngIf="linkedInvestigationModel?.length > 0">
            <form #formLinedInv="ngForm">
              <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
                [cols]="['25%', '16%', '16%', '16%', '16%', '5%']">
                <ng-container *ngFor="let item of linkedInvestigationModel; let index = index">
                  <kendo-gridlayout-item [colSpan]="1">
                    <kendo-formfield>
                      <kendo-label text="Investigation ID"></kendo-label>
                      <kendo-textbox name="id_{{index}}" #id="ngModel" [(ngModel)]="item.id" fillMode="outline"
                        (ngModelChange)="updateInvalid(index)"></kendo-textbox>
                      <kendo-formerror *ngIf="!id?.valid && (id?.dirty || id?.touched)">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="id?.errors?.isInvalidId">Looks like this ID is invalid. Please select a different
                          ID.</span>
                      </kendo-formerror>
                    </kendo-formfield>
                  </kendo-gridlayout-item>
                  <kendo-gridlayout-item style="display: flex;" [colSpan]="5">
                    <div>
                      <button class="mt-24" kendoButton themeColor="primary" fillMode="solid"
                        (click)="createLinkInvestigation(item.id, index)" style="width: 110px;"
                        [disabled]="!item.id">Validate ID</button>
                    </div>
                    <div>
                      <button class="mt-24 ml-16" kendoButton themeColor="light" fillMode="solid"
                        (click)="removeLinkedInvestigation(index)" style="width: 110px;">Remove</button>
                    </div>
                  </kendo-gridlayout-item>
                </ng-container>
              </kendo-gridlayout>
            </form>

            <button kendoButton class="mr-24" icon="plus-circle" themeColor="primary" fillMode="link"
              (click)="addLinkedInvestigation()">Add Additional Linked Investigation</button>
            <br />
            <br />
            <app-notes name="linkedNotes" title="Linked Investigation Notes:"
              [(ngModel)]="model.Detail.linkedInvestigationNotes">message</app-notes>
          </section>
        </kendo-card-body>
      </kendo-card>
    </form>
  </div>
</section>
<footer>
  <div class="right">
    <button kendoButton class="mr-24" themeColor="primary" fillMode="link" (click)="clearPage()">Clear Page</button>
    <button kendoButton class="mr-16" themeColor="primary" fillMode="solid" (click)="save(true)"
      [disabled]="validateForm">Create Investigation</button>
  </div>
</footer>
<ng-template #noData>
  <p class="kendo-caption-body">-</p>
</ng-template>
