<section>
    <h4 class="page-title">Company Investigation and Evidence Report</h4>
    <kendo-card width="100%">
        <div class="content-wrapper">
            <section>
                <app-investigations-report-filter
                    [filterColumns]="filterColumns"
                    [reportPage]="'evidencereport'"
                    [colSpan]="1"
                    (applyFilter)="applyFilter($event)"
                    (filters)="filterReport($event)"
                    (clearFilter)="clearFilter($event)"
                >
                </app-investigations-report-filter>
                <div class="pt-0 mb-16 ml-24">
                  <button kendoButton themeColor="light" fillMode="solid" iconClass="fa-solid fa-file-export" (click)="exportCsv()"
                    class="mr-10">Export as CSV</button>
                </div>

                <kendo-grid
                    class="report-list"
                    [data]="{
                        data: tableData,
                        total: (dataSource?.pageInfo$ | async)?.totalCount
                    }"
                    [pageSize]="pageOptions?.limit"
                    [skip]="pageOptions?.offset"
                    [pageable]="{
                        buttonCount: 6,
                        type: 'numeric',
                        pageSizes: [5, 10, 25, 100],
                        previousNext: true,
                        responsive: true,
                        position: 'bottom'
                    }"
                    (pageChange)="pageChange($event)"
                    [sortable]="true"
                    [sort]="sort"
                    (sortChange)="sortChange($event)"
                    [navigatable]="false"
                    [resizable]="true"
                    [scrollable]="true"
                >

                    <!-- nefco number -->
                    <kendo-grid-column
                        field="NEFCO_NUMBER"
                        title="NEFCO Number"
                        [width]="150"
                        class="id-column"
                        [locked]="true"
                        [resizable]="false"
                        [headerClass]="'sort-header'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <a
                                target="_blank"
                                [routerLink]="['/investigations/', dataItem?.id]"
                                tel=""
                                class="text-decoration"
                            >
                                {{ dataItem?.nefcoNumber || "Unassigned" }}
                            </a>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Status -->
                    <kendo-grid-column
                        field="status"
                        title="Status"
                        [width]="150"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="text-overflow">
                                {{
                                dataItem?.status === "PENDING"
                                    ? "Pending"
                                    : dataItem?.status === "" || !dataItem?.status
                                    ? "-"
                                    : investigationStatusNames[dataItem?.status]
                                }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Risk Date -->
                    <kendo-grid-column
                        field="LOSS_DATE"
                        title="Risk Date"
                        [width]="150"
                        [sortable]="true"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{ dataItem?.lossDate | customDate : "MM/dd/yyyy" : true }}<br /></span>
                            <span class="phone-number" *ngIf="dataItem?.lossDate">
                                {{ dataItem?.lossDate | customDate : "hh:mma" : true }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Insured -->
                    <kendo-grid-column
                        field="Insured"
                        title="Insured"
                        [width]="200"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{ (dataItem?.Insured | insuredName) || '-' }}<br /></span>
                            <span class="phone-number" *ngIf="dataItem?.Insured?.Phones">{{
                                (dataItem?.Insured?.Phones | primaryAssociation)?.number
                                | phoneFormat : "US"
                            }}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Received Date -->
                    <kendo-grid-column
                        field="receivedDate"
                        title="Received Date"
                        [width]="150"
                        [sortable]="true"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{ dataItem?.receivedDate | customDate : "MM/dd/yyyy" }}<br /></span>
                            <span class="phone-number" *ngIf="dataItem?.receivedDate">
                                {{ dataItem?.receivedDate | customDate : "hh:hha" }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- riskType -->
                    <kendo-grid-column
                        field="RiskType"
                        title="Risk Type"
                        [width]="200"
                        [sortable]="false"
                        [resizable]="false"
                        class="font-weight600"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="risk-type">
                                <img
                                class="risk-type-img"
                                src="{{ getIcon(dataItem?.RiskType?.name) }}"
                                />
                                <span class="text-overflow mt-5">{{ dataItem?.RiskType?.name }}</span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Address 1 -->
                    <kendo-grid-column
                        field="LossAddress"
                        title="Address 1"
                        [width]="200"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span>{{ dataItem?.LossAddress?.address1 || '-'}}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- City -->
                    <kendo-grid-column
                        field="city"
                        title="City"
                        [width]="150"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span>{{ dataItem?.LossAddress?.city || '-'}}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- State -->
                    <kendo-grid-column
                        field="state"
                        title="State"
                        [width]="150"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span>{{ dataItem?.LossAddress?.state || '-'}}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Postal -->
                    <kendo-grid-column
                        field="postal"
                        title="Postal"
                        [width]="150"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span>{{ dataItem?.LossAddress?.postal || '-'}}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Claim Number -->
                    <kendo-grid-column
                        field="Detail"
                        title="Claim Number"
                        [width]="150"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                        <span>{{ dataItem?.Detail?.claimNumber || "-" }}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Policy Number -->
                    <kendo-grid-column
                        field="policyNumber"
                        title="Policy Number"
                        [width]="150"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                        <span>{{ dataItem?.Detail?.policyNumber || "-" }}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Bill To -->
                    <kendo-grid-column
                        field="BillTo"
                        title="Bill To"
                        [width]="150"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span>{{ (dataItem?.BillTo | fullName) || '-' }} </span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Evidence Status Date -->
                    <kendo-grid-column
                        field="receivedDate"
                        title="Evidence Status Date"
                        [width]="150"
                        [sortable]="true"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{ dataItem?.Evidence?.[0]?.StatusHistory?.[0]?.createdAt | customDate : "MM/dd/yyyy" }}<br /></span>
                            <span class="phone-number" *ngIf="dataItem?.Evidence?.[0]?.StatusHistory?.[0]?.createdAt">
                                {{ dataItem?.Evidence?.[0]?.StatusHistory?.[0]?.createdAt | customDate : "hh:hha" }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Evidence Status -->
                    <kendo-grid-column
                        field="billedInvoiceTotal"
                        title="Evidence Status"
                        [width]="200"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{dataItem?.Evidence?.[0]?.Status || '-'}}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Evidence Number -->
                    <kendo-grid-column
                        class="text-center"
                        field="billedInvoiceTotal"
                        title="Evidence Number"
                        [width]="200"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{dataItem?.Evidence?.[0]?.evidenceNumber || '-'}}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Description -->
                    <kendo-grid-column
                        field="Evidence"
                        title="Description"
                        [width]="400"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{dataItem?.Evidence?.[0]?.description || '-'}}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Evidence Location -->
                    <kendo-grid-column
                        field="billedInvoiceTotal"
                        title="Evidence Location"
                        [width]="200"
                        [sortable]="false"
                        [resizable]="false"
                        [headerClass]="'nocursor'"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="location-name">{{dataItem?.Evidence?.[0]?.EvidenceStorageLocation?.name || '-'}}<br /></span>
                        <span class="location-city">{{dataItem?.Evidence?.[0]?.EvidenceStorageLocation?.Address?.city }}</span>
                        </ng-template>
                    </kendo-grid-column>

                  <ng-template kendoGridNoRecordsTemplate>
                    <p class="mt-24 mb-24">There are no results to display.</p>
                  </ng-template>
                </kendo-grid>
            </section>
        </div>
    </kendo-card>
</section>
