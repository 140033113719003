import { Injectable } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private subject = new Subject<any>();
  private createContact = new Subject<any>();
  private appInfoSubject = new BehaviorSubject<AppInfo | null>(null);

  constructor() {
    if (Capacitor.isNativePlatform()) {
      this.loadAppInfo();
    }
  }

  sendClickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  afterCreateContact() {
    this.createContact.next();
  }

  loadContactList(): Observable<any> {
    return this.createContact.asObservable();
  }

  public setLocalStorageByKey(key: string, val: any) {
    return localStorage.setItem(
      key,
      JSON.stringify(val, (k, v) => (v === undefined ? null : v))
    );
  }

  public getLocalStorageByKey(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  public removeLocalStorageByKey(key: string) {
    return localStorage.removeItem(key);
  }

  public clearLocalStorage() {
    return localStorage.clear();
  }

  private async loadAppInfo(): Promise<void> {
    try {
      const info: AppInfo = await App.getInfo();
      this.appInfoSubject.next(info);
    } catch (error) {
      console.error("Failed to load app info", error);
      this.appInfoSubject.next(null);
    }
  }

  public getAppInfo$(): Observable<AppInfo | null> {
    return this.appInfoSubject.asObservable();
  }

}