import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "src/app/shared/shared.module";
import { SettingsComponent, SettingsModalComponent } from "./";

@NgModule({
  declarations: [SettingsComponent, SettingsModalComponent],
  imports: [CommonModule, SharedModule],
})
export class SettingsModule {}
