import { connectionQuery } from './../../../helpers.class';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { IGqlBaseOptions } from '../../../interfaces';
import { safeResolve } from '../../../rxjs';
import { IApiPhoneType, IApiPhoneTypeFilter } from '../../../types/types';
import { phoneTypeQuery } from './phone-type.operation';

@Injectable()
export class GqlPhoneTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getPhoneTypes(filters: IApiPhoneTypeFilter[], options: IGqlBaseOptions): Observable<IApiPhoneType[]> {
    return this.apollo.query(connectionQuery(phoneTypeQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }
}