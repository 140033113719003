import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { connectionQuery } from './../../helpers.class';

import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { take, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IAddressResults } from 'src/app/shared/interfaces/address.interfaces';
import { updateAddressMutation } from './address.operations';
import {
  IApiAddressFilter,
  IApiAddAddressInput,
  IApiAddressConnection,
  IApiUpdateAddressInput,
  IApiRemoveAddressInput
} from 'src/app/shared/modules/graphql/types/types';
import {
  addressConnectionQuery,
  addAddressMutation,
  removeAddressMutation
} from './address.operations';

@Injectable()
export class GqlAddressService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getAddresses(filters: IApiAddressFilter[], options: IGqlBaseOptions): Observable<IAddressResults> {
    return this.apollo.query(connectionQuery(addressConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addAddress(input: IApiAddAddressInput) {
    return this.apollo.mutate({
      mutation: addAddressMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeAddress(input: IApiRemoveAddressInput) {
    return this.apollo.mutate({
      mutation: removeAddressMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateAddress(input: IApiUpdateAddressInput) {
    return this.apollo.mutate({
      mutation: updateAddressMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
