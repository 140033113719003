import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { getInvestigationEditorTimelogQuery, getInvestigationEditorTimelogTotal } from "./investigation-report-timelog.operations";
import { IApiInvestigationEditorTimelogAverage, IApiInvestigationEditorTimelogConnection, IApiInvestigationMetricsFilter } from 'src/app/shared/modules/graphql/types/types';
import { IGqlBaseOptions } from "../../../interfaces";
import { connectionQuery } from "../../../helpers.class";

@Injectable()
export class GqlInvestigationReportTimelogService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiInvestigationMetricsFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationEditorTimelogConnection> {
    return this.apollo.query(connectionQuery(getInvestigationEditorTimelogQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getTotal(filters: IApiInvestigationMetricsFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationEditorTimelogAverage> {
    return this.apollo.query(connectionQuery(getInvestigationEditorTimelogTotal, filters, options)).pipe(
      safeResolve(true)
    );
  }

}
