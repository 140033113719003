<div [appEnforcePermission]="authConfig.timeEntryUnlinkedHoursCreate"
  #timeEntryUnlinkedHoursCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursCreate"
  #timeEntryInvestigationHoursCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursList"
  #timeEntryInvestigationHoursList="appEnforcePermission"></div>
<kendo-drawer #drawer [width]="-1" [mini]="false" [mode]="'overlay'" [position]="'end'" [animation]="true">
  <ng-template kendoDrawerTemplate>
    <section class="content-part safe-area-padding-top">
      <div class="header mb-16"  *ngIf="fromTab else noTab">
        <h6 class="mb-0 notab">Add Hours</h6>
        <button class="close-action" kendoButton themeColor="primary" fillMode="link"
        [iconClass]="drawer.expanded ? 'fa-solid fa-times fa-xl' : 'fa-solid fa-bars'"
        (click)="drawer.toggle(false); backToStepOne()"></button>
      </div>
      <ng-template #noTab>
        <div class="header mb-16">
          <button class="back-action" kendoButton themeColor="base" fillMode="link"
            [iconClass]="drawer.expanded && 'fa-solid fa-arrow-left-long fa-lg'" (click)="backToStepOne()"></button>
          <h6 class="mb-0">My Hours</h6>
        </div>
      </ng-template>
      <hr class="divider-line mb-16" />
      <form #hoursForm="ngForm" style="margin-bottom: 110px;">
        <h6 class="form-title mb-24" *ngIf="!fromTab">Add Hours</h6>
        <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 20 }" [cols]="['repeat(1,minmax(0,1fr))']">
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="{{ unlinked ? '' : 'asterisk' }}" text="Investigation ID"></kendo-label>
              <kendo-dropdownlist #investigationID="ngModel" [(ngModel)]="timeEntry.InvestigationId"
                name="InvestigationId"
                [disabled]="investigation?.id ? true :( timeEntryInvestigationHoursCreate?.enabled ? (unlinked) : true)"
                [data]="investigations" textField="name" valueField="id" fillMode="outline" [valuePrimitive]="true"
                [defaultItem]="{ id: '', name: 'Select Investigation' }"
                [popupSettings]="{ appendTo: 'component', animate: false }" required="{{ !unlinked }}">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="!dataItem?.id" class="placeholder">
                    {{ dataItem.name }}
                  </span>
                  <span *ngIf="dataItem?.id" class="not-placeholder">
                    {{ dataItem.name }}
                  </span>
                </ng-template>
              </kendo-dropdownlist>
              <kendo-formerror *ngIf="
                !investigationID?.valid &&
                (investigationID?.dirty || investigationID?.touched)
              ">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="investigationID?.errors?.required">
                  Investigation id cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item *ngIf="!investigation?.id">
            <kendo-formfield>
              <div class="dflex align-items-center">
                <input type="checkbox" #showAll kendoCheckBox
                  [(ngModel)]="unlinked"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="(!timeEntryUnlinkedHoursCreate?.enabled || !timeEntryInvestigationHoursCreate?.enabled) ? true : false" />
                <kendo-label class="k-checkbox-filter-label ml-12" [for]="showAll" text="Unlinked"></kendo-label>
              </div>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="asterisk" text="Date"></kendo-label>
              <kendo-datepicker name="startDate" calendarType="classic" [fillMode]="'outline'"
                placeholder="month/day/year" format="MM/dd/yyyy" [(ngModel)]="timeEntry.workday"
                formatPlaceholder="formatPattern" required="true" #workday="ngModel"
                [disabledDates]="disabledDates"></kendo-datepicker>
              <kendo-formerror *ngIf="!workday?.valid && (workday?.dirty || workday?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="
                    workday?.errors?.required ||
                    workday?.errors?.minError ||
                    workday?.errors?.maxError
                  ">
                  Date cannot be left blank
                </span>
                <span *ngIf="workday?.errors?.disabledDate">
                  Date cannot be future
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item>
            <kendo-formfield class="left-search-icon">
              <kendo-label class="asterisk" [for]="typeSelect" text="Type"></kendo-label>
              <div style="position: relative;">
                <i class="fa fa-search" aria-hidden="true"></i>
                <kendo-autocomplete #autoCompleteTypeId #typeSelect="ngModel" [(ngModel)]="selectedTypeName"
                  name="TypeId" (valueChange)="typeChange($event); typeIdsearch = ''" [filterable]="true"
                  (filterChange)="typeIdsearch = $event; !(typeIdsearch) ? autoCompleteTypeId?.toggle(true) : false "
                  [data]="timeEntryTypes | visibleTimeEntryItems : unlinked : typeIdsearch" textField="name"
                  valueField="id" fillMode="outline" [placeholder]="'Select Type'"
                  (click)="autoCompleteTypeId?.toggle(true)"
                  [popupSettings]="{ appendTo: 'component', animate: false, width: 250 }" required>
                  <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                    <span class="not-placeholder">
                      {{dataItem.name}}
                    </span>
                  </ng-template>
                </kendo-autocomplete>
              </div>
              <kendo-formerror *ngIf="
                  !typeSelect?.valid && (typeSelect?.dirty || typeSelect?.touched)
                ">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="typeSelect?.errors?.required">Type cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item>
            <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 20 }" [cols]="['repeat(2,minmax(0,1fr))']">
              <kendo-gridlayout-item [colSpan]="1" *ngIf="!unlinked">
                <kendo-formfield class="kendo-formfield">
                  <kendo-label [text]="'Billable Hours'"></kendo-label>
                  <div class="numeric-text-suffix">
                    <i class="fa-solid fa-clock"></i>
                    <kendo-numerictextbox name="hours" fillMode="outline" [autoCorrect]="false" placeholder="Hours"
                      [(ngModel)]="timeEntry.hours" #hours="ngModel">
                    </kendo-numerictextbox>
                  </div>
                  <kendo-formerror *ngIf="!hours?.valid && (hours?.dirty || hours?.touched)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="hours?.errors?.required">
                      Billable hours cannot be left blank
                    </span>
                  </kendo-formerror>
                </kendo-formfield>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item [colSpan]="!unlinked ? 1 : 2">
                <kendo-formfield class="kendo-formfield">
                  <kendo-label [text]="'Non-Billable Hours'"></kendo-label>
                  <div class="numeric-text-suffix">
                    <i class="fa-solid fa-clock"></i>
                    <kendo-numerictextbox name="nonBillableHours" fillMode="outline" placeholder="Hours"
                      [autoCorrect]="false" [(ngModel)]="timeEntry.nonBillableHours" #nonBillableHours="ngModel">
                    </kendo-numerictextbox>
                  </div>
                  <kendo-formerror *ngIf="
                      !nonBillableHours?.valid &&
                      (nonBillableHours?.dirty || nonBillableHours?.touched)
                    ">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="nonBillableHours?.errors?.required">
                      Non billable hours cannot be left blank
                    </span>
                  </kendo-formerror>
                </kendo-formfield>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label [for]="descriptionField" text="Description"></kendo-label>
              <kendo-textarea #descriptionField [(ngModel)]="timeEntry.description" name="description"
                placeholder="Enter description" fillMode="outline" class="min-height-96" resizable="both"
                #description="ngModel" appNoWhiteSpace>
              </kendo-textarea>
              <kendo-formerror *ngIf="
                  !description?.valid && (description?.dirty || description?.touched)
                ">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="description?.errors?.required">
                  Description cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
        </kendo-gridlayout>

        <kendo-gridlayout class="pl-16 pr-16 text-center" [rows]="['auto']" [gap]="{rows: 16, cols: 16}"
          [cols]="['repeat(1,minmax(0,1fr))']"
          style="position: absolute; left:0; right:0; bottom: 0; z-index: 1; background: white;">
          <hr class="divider-line" />
          <kendo-gridlayout-item class="mb-48">
            <kendo-button kendoButton themeColor="primary" type="submit" fillMode="solid" size="large" style="width: 100%;"
              (click)="addTime()">
              Add Hours
            </kendo-button>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </form>
    </section>
  </ng-template>
</kendo-drawer>
