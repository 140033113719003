<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageCarriersCreate" #manageCarriersCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageCarriersUpdate" #manageCarriersUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageCarriersDelete" #manageCarriersDelete="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div class="page-header">
    <h4 class="mb-0 page-title">Manage Transfer Carriers</h4>
    <div *ngIf="manageCarriersCreate?.enabled">
      <kendo-button kendoButton [themeColor]="'primary'" fillMode="solid" iconClass="fas fa-plus" (click)="edit()"> New
        Carrier</kendo-button>
    </div>
  </div>
</section>
<kendo-grid class="permission-role-list pl-24 pr-24" [data]="{
  data: carrierList,
  total: (dataSource?.pageInfo$ | async)?.totalCount
}" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="{
  buttonCount: 6,
  type: 'numeric',
  pageSizes: [5, 10, 25, 100],
  previousNext: true,
  responsive: true,
  position: 'bottom'
}" (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)">
  <kendo-grid-column field="NAME" title="Name" [width]="90" class="id-column" [sortable]="true" class="font-weight600"
    [headerClass]="'sort-header'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <a (click)="edit(dataItem)" appEventLink class="text-decoration word-break"
        *ngIf="manageCarriersUpdate?.enabled else onlyName">
        {{dataItem?.name}}
      </a>
      <ng-template #onlyName>
        <span [innerHTML]="dataItem?.name"></span>
      </ng-template>
    </ng-template>
  </kendo-grid-column>

  <!-- Action -->
  <kendo-grid-column field="id" title="Actions" [width]="10" [sortable]="false" [headerClass]="'nocursor'"
    *ngIf="manageCarriersUpdate?.enabled || manageCarriersDelete?.enabled">
    <ng-template kendoGridCellTemplate let-dataItem>
      <kendo-dropdownbutton [data]="(manageCarriersUpdate?.enabled && manageCarriersDelete?.enabled)
    ? [{ text: 'Edit' }, { text: 'Delete' }] : manageCarriersUpdate?.enabled
    ? [{ text: 'Edit' }] : manageCarriersDelete?.enabled
    ? [{ text: 'Delete' }] : []" themeColor="primary" fillMode="link" buttonClass="grid-action-items"
        iconClass="fa-solid fa-ellipsis" (itemClick)="itemSelected($event, dataItem)" [popupSettings]="{
        appendTo: 'component',
        animate: false,
        width: 250,
        popupClass: 'grid-action-items-menu'
      }">
      </kendo-dropdownbutton>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
