<kendo-gridlayout *ngIf="selectedTab === 1" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
  class="pl-15 pr-15">
  <kendo-gridlayout-item class="month-view nefco-card-view" [col]="0" [row]="0">
    <div class="kendo-formfield mr-16" *ngIf="selectedTab === 1">
      <kendo-dropdownlist [data]="filterOptions" textField="text" valueField="slug" fillMode="outline"
        [valuePrimitive]="true" name="filterType" (selectionChange)="filterChange($event)" [value]="currentFilter"
        style="width: 190px;"
        [popupSettings]="{appendTo: 'component', animate: false, popupClass: 'nodata', width: 250 }">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <span class="placeholder"> {{ dataItem?.text}} </span>
        </ng-template>
        <ng-template kendoDropDownListNoDataTemplate>
        </ng-template>
      </kendo-dropdownlist>
    </div>
    <app-date-range-next-prev-filter-kendo (dateRangeChange)="dateRangeChange($event)"
      [allowChangeFilter]="selectedTab === 1 ? true : false" [mode]="'month'"></app-date-range-next-prev-filter-kendo>
  </kendo-gridlayout-item>
</kendo-gridlayout>
<kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="columnDisplay" class="pl-15 pr-15">
  <kendo-gridlayout-item class="month-view" [col]="0" [row]="0">
    <app-date-range-next-prev-filter-kendo *ngIf="selectedTab !== 1" (dateRangeChange)="dateRangeChange($event)"
      [allowChangeFilter]="selectedTab === 1 ? true : false" [mode]="'month'"></app-date-range-next-prev-filter-kendo>
  </kendo-gridlayout-item>
  <kendo-gridlayout-item class="right-section" [col]="0" [row]="0">
    <div class="user-select pt-0">
      <app-user-multi-select-kendo label="" placeholder="Select staff" [userViewFilter]="userViewFilter"
        [includeInactive]="false" (selectionChanged)="setSelectedUser($event)"></app-user-multi-select-kendo>
    </div>
    <div class="pt-0">
      <button kendoButton themeColor="base" fillMode="solid" iconClass="fa-solid fa-download"
        (click)="downloadXlsxAction()" [disabled]="!isUsers" class="mr-10">Download</button>
    </div>
    <div class="pt-0">
      <button kendoButton themeColor="light" fillMode="solid" class="mr-10" (click)="markSelectedPaidAction()"
        [disabled]="!isUsers || !isMarkSelectedAsPaid">Mark selected as Paid</button>
    </div>
    <div class="pt-0 pr-0">
      <button kendoButton [size]="'medium'" themeColor="primary" class="mr-10" (click)="markAllAsPaidAction()"
        [disabled]="!isUsers">Mark all as Paid</button>
    </div>
  </kendo-gridlayout-item>
</kendo-gridlayout>
