import { TitleCasePipe } from "@angular/common";
import { PipeTransform, Pipe } from '@angular/core';
import moment from "moment";
import { IApiCertification } from "../modules/graphql/types/types";

@Pipe({ name: 'quiet' })
export class QuietPipe implements PipeTransform {
  constructor(
    private titleCase: TitleCasePipe
  ) { }
  transform(val: string): string {
    return this.titleCase.transform(val)?.replace(/_/g, " ");
  }
}

@Pipe({ name: 'showCertificationError' })
export class CertificationErrorPipe implements PipeTransform {
  public transform(cert: IApiCertification, monthCountMax = 0, monthCountMin = null): boolean {
    if (!cert?.expiresAt) return false;
    const months = moment(cert.expiresAt).diff(moment(), "months");
    const timeVal = moment(cert.expiresAt).diff(moment());
    // Check against null because we allow 0 to be used here
    // If within range, inclusive of minimum
    if (monthCountMin !== null) { // Use for calculating warnings
      // Must have a positive time, then it expires in the future
      return (timeVal > 0  && months <= monthCountMax && months >= monthCountMin);
    }
    // must be less than the max month count, then it is expired
    else { // Use for calculating errors
      return timeVal < 0 && months <= monthCountMax;
    }
  }
}

@Pipe({ name: 'timeNameShort' })
export class TimeEntryNamePipe implements PipeTransform {
  transform(timeName?: string): string {
    if (!timeName) return '';
    let val = timeName;
    val = val.replace(' Hours: ', ': ');
    val = val.replace('Hours', '');
    return val;
  }
}

@Pipe({ name: 'moreThan' })
export class MoreThanPipe implements PipeTransform {
  transform(val: number, max: number): string {
    if (val > max) return `${max}+`;
    else return `${val}`;
  }
}