import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  isPrimary
  isDefault
  clientRequested
  scheduledSceneExamDate
  User {
    id
    firstName
    lastName
    twilioPhoneNumber
    OnSceneUserHistory {
      id
      status
    }
  }
  Role {
    id
    title
  }
  Investigation {
    id
    nefcoNumber
    InvestigationAddress {
      address1
      address2
      address3
      city
      state
      postal
      country
    }
  }
  LatestOnSceneStatus {
    id
    status
  }
  createdAt
  updatedAt
`;

export const investigationStaffConnectionQuery = gql`${buildConnectionQuery("InvestigationStaff", "investigationStaffConnection", nodeFragment)}`;

export const addInvestigationStaffMutation = gql`mutation AddInvestigationStaff($input: AddInvestigationStaffInput!) {
  addInvestigationStaff(input: $input) { ${nodeFragment} }
}`;

export const updateInvestigationStaffMutation = gql`mutation UpdateInvestigationStaff($input: UpdateInvestigationStaffInput!) {
  updateInvestigationStaff(input: $input) { ${nodeFragment} }
}`;

export const removeInvestigationStaffMutation = gql`mutation RemoveInvestigationStaff($id: ID!) {
  removeInvestigationStaff(id: $id)
}`;

export const assignInvestigatorMutation = gql`mutation AssignInvestigator($input: AssignInvestigatorInput!) {
  assignInvestigator(input: $input) { ${nodeFragment} }
}`;

export const sendSMSToInvestigatorMutation = gql`mutation SendSMSToInvestigator($input: SendSMSToInvestigatorInput!) {
  sendSMSToInvestigator(input: $input) {
    success
    message
  }
}`;
