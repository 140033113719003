import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";
import { IApiServiceTypeFilterType } from '../../../types/types';

const nodeFragment = `
  id
  name
  Services {
    id
  }
  SubServiceTypes {
    id
    name
  }
  RequiredDocuments{
    id
  }
  Services {
    id
  }
  nefcoApprovalRequired
  clientApprovalRequired
  createdAt
  updatedAt
`;

const viewListNameFragment = `
  id
  name
`;

export const serviceTypesConnectionQuery = (isNode = false, view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiServiceTypeFilterType.ViewListName:
        return gql`${buildConnectionQuery("ServiceType", "serviceTypesConnection", viewListNameFragment)}`;
      default:
        return gql`${buildConnectionQuery("ServiceType", "serviceTypesConnection", nodeFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("ServiceType", "serviceTypesConnection", nodeFragment)}`;
  }
};

export const addServiceTypeMutation = gql`mutation createServiceType($input: AddServiceTypeInput!) {
  addServiceType(input: $input) { ${nodeFragment} }
  }`;

export const removeServiceTypeMutation = gql`mutation removeServiceType($input: RemoveServiceTypeInput!) {
  removeServiceType(input: $input) { ${nodeFragment} }
}`;

export const updateServiceTypeMutation = gql`mutation updateServiceType($input: UpdateServiceTypeInput!) {
  updateServiceType(input: $input) { ${nodeFragment} }
}`;

export const getServiceTypeByIdQuery = gql`query getServiceTypeById($id: ID!) {
  getServiceTypeById(id: $id) {${nodeFragment}}
}`;
