import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer, NgForm } from '@angular/forms';
import { IState } from 'src/app/shared/interfaces/state.interfaces';
import { StateService } from 'src/app/shared/services/state.service';

@Component({
  selector: 'app-states-select-form-kendo',
  templateUrl: './states-select-form-kendo.component.html',
  styleUrls: ['./states-select-form-kendo.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatesSelectFormKendoComponent),
      multi: true
    }
  ]
})
export class StatesSelectFormKendoComponent implements OnInit, ControlValueAccessor {

  @Input() disabled = false;
  @Input() stateValue;
  @Input() hideNone = false;
  @Input() name;
  @Input() required = true;
  public states: IState[];

  constructor(private _statesService: StateService) { }

  ngOnInit(): void {
    this.states = this._statesService.allStates;
  }

  public handleSelectionChange(event) {
    this._propagateChange(event.value);
  }

  private _propagateChange = (_: any) => { };

  // Control Value accessor members
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.stateValue = value;
    }
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }

}
