import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const userFragment = `
  id
  email
  firstName
  lastName
`;

const nodeFragment = `
  id
  notes
  authorizedBy
  Investigation {
    id
    nefcoNumber
    lossDate
    LossAddress {
      id
      address1
      address2
      address3
      city
      state
      postal
      country
      TypeId
      Type {
        id
        name
      }
    }
    status
    Client {
      id
    }
    Priority {
      id
      name
    }
    InvestigationStaff {
      id
      User {
        id
        firstName
        lastName
        email
      }
      Role {
        id
        title
      }
    }
    Comments {
      id
      text
      createdAt
      CreatedBy { ${userFragment} }
    }
  }
  Status
  StatusHistory {
    id
    name
    comment
    CreatedBy {
      id
      firstName
      lastName
    }
    createdAt
  }
  Contact {
    id
  }
  CreatedBy {
    id
    firstName
    lastName
  }
  RequestType {
    id
    name
  }
  Address {
    id
    address1
    address2
    city
    state
    postal
    country
  }
  createdAt
  updatedAt
`;

export const requestsConnectionQuery = gql`${buildConnectionQuery("Request", "requestsConnection", nodeFragment)}`;

export const addRequestMutation = gql`mutation createRequest($input: AddRequestInput!) {
  addRequest(input: $input) { ${nodeFragment} }
  }`;

export const removeRequestMutation = gql`mutation RemoveRequest($id: ID!) {
  removeRequest(id: $id)
}`;

export const updateRequestMutation = gql`mutation updateRequest($input: UpdateRequestInput!) {
  updateRequest(input: $input) { ${nodeFragment} }
}`;

export const getRequestByIdQuery = gql`query getRequestById($id: ID!) {
  getRequestById(id: $id) {${nodeFragment}}
}`;
