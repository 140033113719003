import gql from "graphql-tag";
import { buildConnectionQuery } from "../../../helpers.class";

export const nodeFragment = `
  id
  title
  state
  requiresRenewal
  isRestriction
  notes
  Certifications {
    id
    title
    expiresAt
  }
  CertificationAgency {
    id
    name
  }
  CompaniesRequiring {
    id
    name
    description
  }
`;

export const certificationTypeConnectionQuery = gql`${buildConnectionQuery("CertificationType", "certificationTypeConnection", nodeFragment)}`;

export const getCertificationTypeByIdQuery = gql`query getCertificationTypeById($id: String!) {
  getCertificationTypeById(id: $id) { ${ nodeFragment } }
}`;

export const addCertificationTypeMutation = gql`mutation AddCertificationType($input: AddCertificationTypeInput!) {
  addCertificationType(input: $input) { ${ nodeFragment } }
}`;

export const updateCertificationTypeMutation = gql`mutation UpdateCertificationType($input: UpdateCertificationTypeInput!) {
  updateCertificationType(input: $input) { ${ nodeFragment } }
}`;

export const removeCertificationTypeMutation = gql`mutation RemoveCertificationType($id: ID!) {
  removeCertificationType(id: $id)
}`;