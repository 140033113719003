import { IApiDocumentTypeConnection } from './../../../modules/graphql/types/types';
import { DocumentTypeService } from './document-type.service';
import { ApiConnection } from '../../../decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiDocumentType, IApiDocumentTypeFilter } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class DocumentTypeDataSource extends NefcoPagedDataSource<IApiDocumentType, DocumentTypeService, IApiDocumentTypeConnection> implements DataSource<IApiDocumentType> {
  @ApiConnection()
  public load(filters?: IApiDocumentTypeFilter[]): Promise<IApiDocumentTypeConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
