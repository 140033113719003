import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiNotificationMethodSettingsConnection, IApiUpdateNotificationMethodSettingInputV2 } from 'src/app/shared/modules/graphql/types/types';
import {
  notificationMethodSettingsConnectionQuery, addNotificationMethodSettingMutation,
  removeNotificationMethodSettingMutation, getNotificationMethodSettingByIdQuery,
  updateNotificationMethodSettingMutation,
  updateV2NotificationMethodSettingMutation
} from './notification-method-setting.operations';
import {
  IApiNotificationMethodSettingFilter, IApiAddNotificationMethodSettingInput, IApiUpdateNotificationMethodSettingInput,
  IApiNotificationMethodSetting
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlNotificationMethodSettingService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiNotificationMethodSettingFilter[], options: IGqlBaseOptions): Observable<IApiNotificationMethodSettingsConnection> {
    return this.apollo.query(connectionQuery(notificationMethodSettingsConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddNotificationMethodSettingInput): Observable<IApiNotificationMethodSetting> {
    return this.apollo.mutate({
      mutation: addNotificationMethodSettingMutation,
      variables: { input }
    }).pipe(safeResolve(true));
  }

  public remove(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeNotificationMethodSettingMutation,
      variables: { id }
    }).pipe(safeResolve(true));
  }

  public update(input: IApiUpdateNotificationMethodSettingInput): Observable<IApiNotificationMethodSetting> {
    return this.apollo.mutate({
      mutation: updateNotificationMethodSettingMutation,
      variables: { input }
    }).pipe(safeResolve(true));
  }

  public updateV2(input: IApiUpdateNotificationMethodSettingInputV2): Observable<boolean> {
    return this.apollo.mutate({
      mutation: updateV2NotificationMethodSettingMutation,
      variables: { input }
    }).pipe(safeResolve(true));
  }

  public getById(id: string): Observable<IApiNotificationMethodSetting> {
    return this.apollo.query({
      query: getNotificationMethodSettingByIdQuery,
      variables: { id }
    }).pipe(safeResolve(true));
  }
}
