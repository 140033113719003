<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>{{data?.id ? 'Update' : 'Create'}} Certification Type</span>
    </div>
  </kendo-dialog-titlebar>

  <section>
    <form #form="ngForm" (submit)="onSubmit()">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Name"></kendo-label>
            <kendo-textbox
              name="title"
              #title="ngModel"
              [(ngModel)]="model.title"
              placeholder="Name"
              fillMode="outline"
              appNoWhiteSpace
              [clearButton]="false"
              [required]="true">
            </kendo-textbox>
            <kendo-formerror *ngIf="title?.invalid && (title?.dirty || title?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="title?.errors.required">Name cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item style="display: flex; align-items: center; justify-content: left;">
          <kendo-formfield>
            <div class="k-checkbox-wrap">
              <input
                #isRestricationFor
                type="checkbox"
                kendoCheckBox
                name="isRestriction"
                [(ngModel)]="model.isRestriction"
                (change)="isRestrictionChanged(model.isRestriction)"
              />
              <kendo-label
                [for]="isRestricationFor"
                class="k-checkbox-label font-weight-600 checkbox-margin"
                style="margin-bottom: 0"
                text="Restriction"
              ></kendo-label>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <ng-container *ngIf="!model.isRestriction">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Issuing Agency"></kendo-label>
            <kendo-dropdownlist [data]="issuingAgencies" name="issuingAgenciesName" #issuingAgenciesId="ngModel" [(ngModel)]="model.CertificationAgencyId"
              [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
              [defaultItem]="{id: null, name: 'Select Issuing Agency'}" [popupSettings]="{appendTo: 'component', animate: false}" required>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!issuingAgenciesId?.valid && (issuingAgenciesId?.dirty || issuingAgenciesId?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="issuingAgenciesId?.errors?.required">
                Issuing Agency cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-states-select-form-kendo [(ngModel)]="model.state" [name]="'state'"></app-states-select-form-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
              <kendo-label class="font-weight-600 mb-15 asterisk" text="Requires Renewal"></kendo-label>
              <div class="kendo-radio">
                  <input type="radio" id="Yes" kendoRadioButton name="rRenewal" #rRenewal="ngModel" [(ngModel)]="model.requiresRenewal"
                    [value]="true" required />
                  <label class="k-radio-label mr-24" for="Yes">Yes</label>
                  <input type="radio" id="No" kendoRadioButton name="rRenewal" #rRenewal="ngModel" [(ngModel)]="model.requiresRenewal"
                    [value]="false" required />
                  <label class="k-radio-label" for="No">No</label>
              </div>
              <kendo-formerror *ngIf="!rRenewal?.valid && (rRenewal?.dirty || rRenewal?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="rRenewal?.errors?.required">
                  Requires Renewal cannot be left blank
                </span>
              </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        </ng-container>

        <kendo-gridlayout-item [colSpan]="2">
          <kendo-formfield>
            <kendo-label text="Comments"></kendo-label>
            <kendo-textarea name="comments" #comments="ngModel" [(ngModel)]="model.notes"
            placeholder="Comments" fillMode="outline"
            class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
            <kendo-formerror *ngIf="comments?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="comments?.errors.required">Comments cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

      </kendo-gridlayout>
    </form>
  </section>

  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSubmit()">
      Save
    </button>
  </kendo-dialog-actions>
</div>
