<section class="mt-12">
  <ng-container *ngIf="investigations.length === 0; else investigationsList">
    <p class="text-center">There are no investigations within the given distance</p>
  </ng-container>
  <ng-template #investigationsList>
    <kendo-grid class="permission-list-grid" [data]="dataSource" [pageable]="false" [sortable]="false"
      [navigatable]="false">
      <kendo-grid-column field="LOSS_DATE" title="Loss Date" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ (dataItem?.lossDate | customDate:'short' : true) || '-' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="NEFCO_NUMBER" title="ID#" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a appEventLink queryParamsHandling="preserve" (click)="openInvestigation(dataItem)"
            class="text-decoration word-break" [innerHTML]="dataItem.nefcoNumber | nefcoNumber : dataItem.id"></a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="LOCATION" title="Loss Location" [width]="20" [sortable]="false"
        [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ (dataItem?.LossAddress | addressFormat) || '-' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="RISK_TYPE" title="Risk Type" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ (dataItem?.RiskType?.name) || '-' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="INSURED" title="Insured" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ (dataItem.Insured | insuredName) || '-' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="ACTION" title="Action" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton [iconClass]="dataItem?.selected ?'fa-solid fa-link' : 'fa-solid fa-link-slash'"
            [themeColor]="dataItem.selected ? 'primary' : 'light'" fillMode="solid"
            (click)="dataItem.selected = !dataItem.selected">{{ dataItem.selected ? 'Linked' : 'Not Linked'}}</button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </ng-template>
</section>
