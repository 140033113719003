import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nefcoNumber' })
export class NefcoNumberPipe implements PipeTransform {
  transform(nefcoNumber: string | undefined, investigationId): string {
    return nefcoNumber ? nefcoNumber : investigationId.substring(investigationId.length - 10);
  }
}

@Pipe({ name: 'evidenceNumber' })
export class EvidenceNumberPipe implements PipeTransform {
  transform(evidenceNumber: number | undefined): string {
    return evidenceNumber.toString().padStart(4, "0");
  }
}
