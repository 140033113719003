import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DateRangePopupComponent, DateRangeService } from '@progress/kendo-angular-dateinputs';
import moment from 'moment';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';

interface filterOption { text: string; slug: string };

@Component({
  selector: 'app-mobile-time-exp-date-kendo-filter',
  templateUrl: './mobile-time-exp-date-kendo-filter.component.html',
  styleUrls: ['./mobile-time-exp-date-kendo-filter.component.scss']
})

export class MobileTimeExpDateKendoFilterComponent {

  @ViewChild('startDate', { static: false }) startDate: any;
  @ViewChild('endDate', { static: false }) endDate: any;
  @Input() showSelection = true;
  @Input() getRange = false;

  public _mode = 'week';
  @Input() set mode(val: string) {
    this._mode = val;
    if (this._mode === 'week') {
      if (this.displayAllOption === false) this.currentFilter = 'EXPENSE_WEEK';
      this.filterOptions = [...[{ text: 'Week View', slug: 'EXPENSE_WEEK' }], ...this.filterOptions];
    } else {
      if (this.displayAllOption === false) this.currentFilter = 'EXPENSE_MONTH';
      this.filterOptions = [...[{ text: 'Month View', slug: 'EXPENSE_MONTH' }], ...this.filterOptions];
    }
  };
  get mode() {
    return this._mode;
  };

  @Input() smallScreen = false;
  @Input() investigationView: boolean = false;

  /* Display All Option */
  public _displayAllOption: boolean = false;
  @Input() set displayAllOption(val: boolean) {
    this._displayAllOption = val;
    if (this._displayAllOption) {
      this.currentFilter = 'ALL';
      this.filterOptions = [...[{ text: 'All Hours View', slug: 'ALL' }], ...this.filterOptions];
    }
  }
  public get displayAllOption() {
    return this._displayAllOption;
  }
  /* Display All Option */

  public _filterOptions: filterOption[] = [
    { text: "Custom Date Range", slug: "DATE_RANGE" },
  ];
  @Input() set filterOptions(val: filterOption[]) {
    this._filterOptions = val;
  }
  public get filterOptions() {
    return this._filterOptions;
  }

  @Output() WeekMonthRangeChange = new EventEmitter<string>();
  @Output() dateChange = new EventEmitter<moment.Moment>();
  @Output() investigationChange = new EventEmitter<string>();

  @Output() dateRangeChange = new EventEmitter<string>();
  @ViewChild("dateRangePopup", { static: false })
  public dateRangePopup: DateRangePopupComponent;
  @ViewChild("dateRange", { read: DateRangeService })
  public service: DateRangeService;
  @ViewChild("anchor", { static: false }) public anchor: any;

  private _selectedWeek: moment.Moment = moment().endOf("week");
  private _selectedInvestigationId: string = null;

  public get selectedWeek() {
    return this._selectedWeek;
  }

  public set selectedWeek(val: moment.Moment) {
    this._selectedWeek = val;
    this.dateChange.emit(val);
  }

  public range = { startDate: null, endDate: null };

  private _selectedMonth: moment.Moment = moment().endOf("month");

  public get selectedMonth() {
    return this._selectedMonth;
  }

  public set selectedMonth(val: moment.Moment) {
    this._selectedMonth = val;
    this.dateChange.emit(val);
  }

  private _selectedMonthRange = {
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
  };

  public get selectedMonthRange() {
    return this._selectedMonthRange;
  }

  public set selectedMonthRange(val: any) {
    this._selectedMonthRange = val;
    const nefoDateRange = val ? NefcoDateHelper.dateFilterStringUTCFromLocal(val?.startDate, val?.endDate) : null;
    this.WeekMonthRangeChange.emit(nefoDateRange ? nefoDateRange : null);
  }


  public _currentFilter = 'ALL';

  public set currentFilter(val: string) {
    this._currentFilter = val;
  }

  public get currentFilter() {
    return this._currentFilter;
  }

  constructor() {
  }

  public changeWeek(direction) {
    switch (direction) {
      case "forward":
        if (!moment(this.selectedWeek).isBefore(moment())) return;
        this.selectedWeek = moment(this.selectedWeek)
          .add(7, "days")
          .endOf("week");
        break;
      case "backward":
        this.selectedWeek = moment(this.selectedWeek)
          .subtract(7, "days")
          .endOf("week");
        break;
      default:
        return;
    }
  }

  public changeWeekMonth(direction) {
    if (this.mode === "week") {
      this.changeWeek(direction);
    } else {
      this.changeMonth(direction);
    }
  }

  public changeMonth(direction) {
    switch (direction) {
      case "forward":
        if (!moment(this.selectedMonth).isBefore(moment())) return;
        if (!moment(this.selectedMonthRange.endDate).isBefore(moment()) && this.getRange) return;
        this.selectedMonth = moment(this.selectedMonth)
          .add(1, "months")
          .endOf("month");
        this.selectedMonthRange = {
          startDate: moment(this.selectedMonthRange.startDate).add(1, 'month').startOf('month').toDate(),
          endDate: moment(this.selectedMonthRange.endDate).add(1, 'month').endOf('month').toDate(),
        }
        break;
      case "backward":
        this.selectedMonth = moment(this.selectedMonth)
          .subtract(1, "months")
          .endOf("month");
        this.selectedMonthRange = {
          startDate: moment(this.selectedMonthRange.startDate).subtract(1, 'month').startOf('month').toDate(),
          endDate: moment(this.selectedMonthRange.endDate).subtract(1, 'month').endOf('month').toDate(),
        }
        break;
      default:
        return;
    }
  }

  customRangeChange() {
    if (this.startDate.valid && this.endDate.valid) {
      const dates = {
        startDate: NefcoDateHelper.toUtcStartOfDay(this.range.startDate, true),
        endDate: NefcoDateHelper.toUtcStartOfDay(this.range.endDate, false),
      };
      this.dateRangeChange.emit(JSON.stringify(dates));
    }
  }

  public filterChange(event) {
    this.currentFilter = event.slug;
    if (event.slug === "DATE_RANGE") {
      this.range = { startDate: null, endDate: null };
    } else if (event.slug === 'EXPENSE_WEEK') {
      this.range = { startDate: null, endDate: null };
      this.dateChange.emit(this._selectedWeek);
    } else if (event.slug === 'EXPENSE_MONTH') {
      this.range = { startDate: null, endDate: null };
      this.dateChange.emit(this.selectedMonth);
    } else {
      this.dateChange.emit(null);
    }
  }
}
