import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { DrawerComponent } from '@progress/kendo-angular-layout';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { timeEntryInvestigationHoursCreate, timeEntryInvestigationHoursList, timeEntryUnlinkedHoursCreate } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { IInvestigation } from 'src/app/shared/interfaces/investigation.interfaces';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiAddTimeEntryInput, IApiInvestigation, IApiInvestigationFilterType, IApiInvestigationOrderBy, IApiTimeEntryType, IApiUpdateTimeEntryInput } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { AuthService, InvestigationService, TimeEntryService, TimeEntryTypeService, UserService } from 'src/app/shared/services';

@Component({
  selector: 'app-mobile-hours-form',
  templateUrl: './mobile-hours-form.component.html',
  styleUrls: ['./mobile-hours-form.component.scss']
})
export class MobileHoursFormComponent implements OnInit, AfterViewInit {
  @ViewChild("autoCompleteTypeId", { static: false }) public autoCompleteTypeId: AutoCompleteComponent;
  @ViewChild('drawer') drawer: DrawerComponent;
  @ViewChild("hoursForm") form: NgForm;
  @Output() stepTo = new EventEmitter<number>();
  @Output() reloadPage = new EventEmitter<boolean>(false);
  @Input() fromTab = false;
  @Input() goBackOnSubmit = false;
  @Input() investigation: IApiInvestigation = null;
  public authConfig = {
    timeEntryUnlinkedHoursCreate,
    timeEntryInvestigationHoursList,
    timeEntryInvestigationHoursCreate
  }

  public _unlinked: boolean = false;
  public get unlinked(): boolean {
    return this._unlinked;
  }

  public set unlinked(val: boolean) {
    this._unlinked = val;
    this.timeEntry.TypeId = null;
    this.selectedTypeName = '';
    if (val === true) {
      this.timeEntry.InvestigationId = null;
      this.timeEntry.hours = 0;
    } else {
      this.timeEntry.hours = 0;
    }
  }

  public investigations: IInvestigation[] = [];
  public selectedTypeName: string;
  public typeIdsearch: string;
  public timeEntryTypes: IApiTimeEntryType[] = [];
  private timeEntryTypesCopy: IApiTimeEntryType[] = [];
  private customTechDescription =
    "Technical Review in accordance with NFPA 921 section 4.6.2 Technical Review";

  public timeEntry: IApiUpdateTimeEntryInput = {
    id: null,
    TypeId: null,
    workday: new Date(),
    hours: null,
    nonBillableHours: null,
    UserId: null,
    InvestigationId: null,
    description: "",
    paidDate: null,
  };


  constructor(
    private cdr: ChangeDetectorRef,
    private loader: LoaderService,
    private userService: UserService,
    private timeEntryTypeService: TimeEntryTypeService,
    private auth: AuthService,
    private location: Location,
    private investigationService: InvestigationService,
    private timeEntryService: TimeEntryService,
    private notificationService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getTimeEntryTypesAndInvestigationList();
  }

  public disabledDates = (date: Date): boolean => {
    return moment(date).isAfter();
  };


  ngAfterViewInit() {
    this.drawer.toggle(true);
    this.addBodyClass(true);
    this.cdr.detectChanges();
  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }

  public backToStepOne() {
    this.drawer.toggle(false);
    this.stepTo.emit(1);
    this.addBodyClass(false);
  }

  public close(): void {
    this.drawer.toggle(false);
    if (this.goBackOnSubmit) {
      this.location.back();
    } else {
      this.stepTo.emit(1);
      this.reloadPage.emit(true);
    }
    this.addBodyClass(false);
  }

  // Set custom description on type change
  public typeChange(selectedId: string) {
    this.cdr.detectChanges();
    this.autoCompleteTypeId?.toggle(false);
    this.timeEntry.TypeId = selectedId || null;
    const foundType = this.timeEntryTypes.find(({ id }) => id === this.timeEntry.TypeId);
    this.selectedTypeName = foundType?.name || '';
    if (!this.timeEntry.id) {
      if (
        foundType?.name === "Investigation Hours: Tech Review Engineer" ||
        foundType?.name === "Investigation Hours: Tech Review Investigator"
      ) {
        this.timeEntry.description = this.customTechDescription;
      } else {
        this.timeEntry.description = "";
      }
    }
  }

  private findMatchingString(type, types) {
    const words = new Set(type?.split(' '));

    for (const str of types) {
      const strWords = new Set(str.split(' '));

      const isMatch = [...words].every(word => strWords.has(word));

      if (isMatch) {
        return str;
      }
    }

    return null;
  }

  private getTimeEntryTypesAndInvestigationList() {
    this.auth.authenticatedUser.subscribe((u) => {
      this.timeEntry.UserId = u.id;
      this.setDefaultTimeEntryTypeForUser(u);
      this.loader.show$(forkJoin([
        this.timeEntryTypeService.get([]).pipe(unwrapConnection()),
        this.investigationService
        .get(
          [
            { type: IApiInvestigationFilterType.User, value: u.id },
            {
              type: IApiInvestigationFilterType.ViewOnlyNefconumber,
              value: "true",
            },
          ],
          { limit: -1, sortOrder: SortOrder.DESC, orderBy: IApiInvestigationOrderBy.CreatedAt}
        )
        .pipe(unwrapConnection())
      ])).subscribe(([timeEntryTypes, investigations]) => {
        this.timeEntryTypes = timeEntryTypes;
        this.timeEntryTypesCopy = timeEntryTypes;
        if (this.timeEntry?.TypeId) {
          this.typeChange(this.timeEntry.TypeId);
        }

        if (investigations && investigations?.length) {
          investigations = investigations?.filter(i => i.nefcoNumber);
          investigations.map((obj) => {
            obj["name"] = obj?.nefcoNumber ? obj.nefcoNumber : obj.id;
          });
          this.investigations = investigations;
        }
        if (this.investigation?.id) {
          this.timeEntry.InvestigationId = this.investigation.id;
        }
      });
    });
  }

  sortTimeEntries(userRoleEntries) {
    this.timeEntryTypes = this.timeEntryTypesCopy;
    if (userRoleEntries?.length) {
      const userRoleEntriesArray = this.timeEntryTypes?.filter(entry => userRoleEntries?.includes(entry.name)) || [];
      const tempTimeEntries = this.timeEntryTypes?.filter(entry => !userRoleEntries?.includes(entry.name)) || [];
      this.timeEntryTypes = [...userRoleEntriesArray, ...tempTimeEntries];
    }
  }

  setDefaultTimeEntryTypeForUser(u) {
    const userEntryType = u?.PermissionRoles[0]?.TimeEntryType;
    const states = u?.States;
    const userRoleEntries = u?.PermissionRoles?.map(role => role?.TimeEntryType?.name);
    this.sortTimeEntries(userRoleEntries);
    if (!this.timeEntry?.id && userEntryType?.id) {
      const match = this.findMatchingString(userEntryType?.name, this.timeEntryTypes?.filter(type => type.id !== userEntryType?.id)?.map(type => type.name));
      if (states?.includes('CA') && match) {
        const matchType = this.timeEntryTypes?.find(t => t.name === match);
        this.timeEntry.TypeId = matchType?.id;
        this.typeChange(this.timeEntry.TypeId);
      } else {
        this.timeEntry.TypeId = userEntryType?.id;
        this.typeChange(this.timeEntry.TypeId);
      }
    }
  }

  public getStaffWithPermissionRole(id) {
    this.loader.show$(this.userService.getByIdWithPermissionRoleAndState(id)).subscribe(user => {
      this.setDefaultTimeEntryTypeForUser(user);
    })
  }

  public isFormValid(): boolean {
    this.form.control.markAllAsTouched();
    return this.form.form.valid;
  }

  public addTime() {
    if (!this.isFormValid()) {
      return;
    }
    const input = {
      ...this.timeEntry,
      hours: this.timeEntry.hours || 0,
      nonBillableHours: this.timeEntry.nonBillableHours || 0
    };
    delete input.id;
    this.loader.show$(
      this.timeEntryService.add(input as IApiAddTimeEntryInput).pipe(
        this.notificationService.snackbarPipe("Time entry added."),
        this.notificationService.snackbarErrorPipe("Error adding time entry.")
      )
    ).subscribe(() => {
      this.form.form.reset();
      this.close();
    });
  }

}
