<div [dialogRef]="dialog" (appClickOutside)="dialog.close(false)">
  <kendo-dialog-titlebar (close)="dialog.close(false)">
    <div class="modal-title">
      <span>Add Certification</span>
    </div>
  </kendo-dialog-titlebar>

  <section>
    <form #certificateForm="ngForm">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
      <kendo-gridlayout-item [colSpan]="3">
        <kendo-formfield>
          <kendo-label [ngClass]="'asterisk'" text="Certification Name"></kendo-label>
          <kendo-dropdownlist [data]="certifications" textField="title" valueField="id" fillMode="outline" [valuePrimitive]="true"
            [(ngModel)]="newCertification.TypeId" name="certificationName"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" #certificationName="ngModel" required
            [defaultItem]="{id: null, title: 'Select Certification Name'}">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span [ngClass]="dataItem?.id ? 'not-placeholder' : 'placeholder'"> {{ dataItem?.title}} </span>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror *ngIf="!certificationName?.valid && (certificationName?.dirty || certificationName?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="certificationName?.errors?.required">
              Certification Name cannot be left blank
            </span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item class="text-right" [colSpan]="3">
        <ng-container *ngIf="s3Doc">
          <kendo-chip class="fw600" style="margin-top: 20px;" [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'"
            [removable]="true" (remove)="removeDocument()">
            {{ s3Doc.filename }}
          </kendo-chip>
        </ng-container>
        <ng-container *ngIf="!s3Doc">
            <button kendoButton class="mt-16" themeColor="primary" fillMode="link" iconClass="fa-solid fa-cloud-arrow-up"
              (click)="fileSelector()">
              Add Certification
            </button>
        </ng-container>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label [ngClass]="'asterisk'" text="Issue Date"></kendo-label>
          <kendo-datepicker
              name="issueDate"
              #issueDate="ngModel"
              [(ngModel)]="newCertification.issuedAt"
              calendarType="classic"
              [fillMode]="'outline'"
              placeholder="Enter date"
              format="MM/dd/yyyy"
              formatPlaceholder="formatPattern"
              [required]="true"
          ></kendo-datepicker>
          <kendo-formerror *ngIf="!issueDate?.valid && (issueDate?.dirty || issueDate?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="issueDate?.errors?.required">
              Issue Date cannot be left blank
            </span>
          </kendo-formerror>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label [ngClass]="'asterisk'" text="Expiration Date"></kendo-label>
          <kendo-datepicker
              id="expireDate"
              name="expireDate"
              [(ngModel)]="newCertification.expiresAt"
              #expireDate="ngModel"
              calendarType="classic"
              [fillMode]="'outline'"
              placeholder="Enter date"
              format="MM/dd/yyyy"
              formatPlaceholder="formatPattern"
              [required]="true"
          ></kendo-datepicker>
          <kendo-formerror *ngIf="!expireDate?.valid && (expireDate?.dirty || expireDate?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="expireDate?.errors?.required">
              Expiration Date cannot be left blank
            </span>
          </kendo-formerror>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label class="asterisk" text="Status"></kendo-label>
          <kendo-dropdownlist [data]="statusTypes | keyvalue" textField="key" valueField="value" fillMode="outline"
            [valuePrimitive]="true" name="status" [defaultItem]="{key: 'Select Status', value: null}"
            [(ngModel)]="newCertification.status" #status="ngModel" required
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span [ngClass]="!dataItem?.value ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.key | quiet}} </span>
            </ng-template>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span [ngClass]="!dataItem?.value ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.key | quiet }} </span>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror *ngIf="!status?.valid && (status?.dirty || status?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="status?.errors?.required">
              Status cannot be left blank
            </span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item [colSpan]="3">
        <kendo-formfield>
          <kendo-label text="Comment"></kendo-label>
          <kendo-textarea name="Comments" #comments="ngModel" [(ngModel)]="newCertification.Comments" placeholder="Comments" fillMode="outline"
            class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
          <kendo-formerror *ngIf="comments?.errors">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="comments?.errors.required">Comments cannot be left blank</span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    </form>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="dialog.close(false)">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" [disabled]="!certificateForm.valid" class="mr-16"
      (click)="saveCertificationSelection()">
      Save
    </button>
  </kendo-dialog-actions>
</div>