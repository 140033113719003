<ng-container *ngIf="step === 1">
  <kendo-drawer #drawer [width]="-1"  [mini]="false" [mode]="'overlay'" [position]="'end'" [animation]="true">
      <ng-template kendoDrawerTemplate>
        <section class="content-part safe-area-padding-top">
          <div class="header mb-16">
            <button class="back-button" kendoButton themeColor="base" fillMode="link"
              [iconClass]="'fa-solid fa-arrow-left-long fa-lg'" (click)="close()"></button>
            <span>
              <h6>Time and Expenses</h6>
              <p>ID: {{investigation?.nefcoNumber || 'Unassigned'}}</p>
            </span>
          </div>
          <hr class="divider-line mb-16" />
          <p class="info-text">In order to create hour, expense, or mileage entries, please select a time and expense option and tap Next.</p>

          <form #timeAndExpenseForm="ngForm" style="margin-bottom: 110px;">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-label class="asterisk" text="Time and Expense"></kendo-label>
                  <kendo-dropdownlist [data]="expenseTypeOption" name="expenseType" #expenseType="ngModel" textField="text" valueField="val"
                    [(ngModel)]="expenseTypeSelected" fillMode="outline"
                    [valuePrimitive]="true" [defaultItem]="{ val: null, text: 'Select option' }"
                    [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required>
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span [ngClass]="dataItem?.val? 'not-placeholder' : 'placeholder'"> {{ dataItem?.text }} </span>
                    </ng-template>
                  </kendo-dropdownlist>
                  <kendo-formerror *ngIf="!expenseType?.valid && (expenseType?.dirty || expenseType?.touched)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="expenseType?.errors?.required">
                      Time and Expense cannot be left blank
                    </span>
                  </kendo-formerror>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>

            <kendo-gridlayout class="pl-16 pr-16 text-center" [rows]="['auto']" [gap]="{rows: 16, cols: 16}"
              [cols]="['repeat(1,minmax(0,1fr))']" style="position: absolute; left:0; right:0; bottom: 0;z-index: 1;">
              <hr class="divider-line" />
              <kendo-gridlayout-item class="mb-48">
                <kendo-button kendoButton themeColor="primary" type="submit" fillMode="solid" size="large" style="width: 100%;" (click)="next()" >
                  Next
                </kendo-button>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </form>
        </section>
      </ng-template>
  </kendo-drawer>
</ng-container>
<ng-container *ngIf="step === 2 && expenseTypeSelected === 'MY-HOUR'">
  <app-mobile-hours-form [investigation]="investigation" (stepTo)="stepTo($event)" [goBackOnSubmit]="true"></app-mobile-hours-form>
</ng-container>
<ng-container *ngIf="step === 2 && expenseTypeSelected === 'MY-EXPENSES'">
  <app-mobile-expense-form [investigation]="investigation" (stepTo)="stepTo($event)" [goBackOnSubmit]="true"></app-mobile-expense-form>
</ng-container>
<ng-container *ngIf="step === 2 && expenseTypeSelected === 'MY-MILEAGE'">
  <app-mobile-mileage-form [investigation]="investigation" (stepTo)="stepTo($event)" [goBackOnSubmit]="true"></app-mobile-mileage-form>
</ng-container>
