import { Component, Input, Output, EventEmitter, OnInit, OnChanges, forwardRef, ViewChild } from '@angular/core';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Expense, ExpensePurpose } from 'src/app/shared/modules/graphql/interfaces/expense.interfaces';
import { ExpenseDataSource, ExpenseService } from 'src/app/shared/services/expenses';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IApiDocument, IApiDocumentType, IApiExpense, IApiExpenseFilter, IApiExpenseFilterType, IApiExpenseItem, IApiExpenseOrderBy, IApiExpenseReportTotal, IApiInvestigation, IApiInvestigationFilterType } from 'src/app/shared/modules/graphql/types/types';
import { InvestigationTimeAndExpModalKendoComponent } from '../../../investigations';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { IInvestigationTimeExpenseModalData } from '../../../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { filter, switchMap } from 'rxjs/operators';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { ExpenseActionType } from 'src/app/shared/modules/graphql/enums/expense.enums';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthService, DownloadHelper } from 'src/app/shared/services';
import { expenseInvestigationExpensesDelete, expenseInvestigationExpensesDeleteOwn, expenseInvestigationExpensesUpdate, expenseInvestigationExpensesUpdateOwn, expenseInvestigationMileageDelete, expenseInvestigationMileageDeleteOwn, expenseInvestigationMileageUpdate, expenseInvestigationMileageUpdateOwn, expenseUnlinkeExpenseDelete, expenseUnlinkeExpenseDeleteOwn, expenseUnlinkedExpenseUpdate, expenseUnlinkedExpenseUpdateOwn, expenseUnlinkedMileageDelete, expenseUnlinkedMileageDeleteOwn, expenseUnlinkedMileageUpdate, expenseUnlinkedMileageUpdateOwn } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { ReimbursementRatePipe } from 'src/app/shared/pipes';

export interface IConfig {
  admin: boolean;
  reimbursable?: boolean;
}

export interface ISelectEvent {
  selected: boolean;
  data: Expense[]; // TODO Add expense interface
}

@Component({
  selector: 'app-expense-report-details',
  templateUrl: './expense-report-details.component.html',
  styleUrls: ['./expense-report-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExpenseReportDetailsComponent),
      multi: true
    }
  ]
})

export class ExpenseReportDetailsComponent implements OnInit, OnChanges {
  public authConfig = {
    expenseUnlinkedMileageUpdate,
    expenseUnlinkedMileageUpdateOwn,
    expenseInvestigationMileageUpdate,
    expenseInvestigationMileageUpdateOwn,
    expenseInvestigationExpensesUpdate,
    expenseInvestigationExpensesUpdateOwn,
    expenseUnlinkedExpenseUpdate,
    expenseUnlinkedExpenseUpdateOwn,
    expenseUnlinkedMileageDelete,
    expenseUnlinkedMileageDeleteOwn,
    expenseInvestigationMileageDelete,
    expenseInvestigationMileageDeleteOwn,
    expenseInvestigationExpensesDelete,
    expenseInvestigationExpensesDeleteOwn,
    expenseUnlinkeExpenseDelete,
    expenseUnlinkeExpenseDeleteOwn
  }

  @ViewChild('expenseInvestigationMileageUpdate') expenseInvestigationMileageUpdatePermission;
  @ViewChild('expenseInvestigationMileageUpdateOwn') expenseInvestigationMileageUpdateOwnPermission;
  @ViewChild('expenseUnlinkedMileageUpdate') expenseUnlinkedMileageUpdatePermission;
  @ViewChild('expenseUnlinkedMileageUpdateOwn') expenseUnlinkedMileageUpdateOwnPermission;
  @ViewChild('expenseInvestigationExpensesUpdate') expenseInvestigationExpensesUpdatePermission;
  @ViewChild('expenseInvestigationExpensesUpdateOwn') expenseInvestigationExpensesUpdateOwnPermission;
  @ViewChild('expenseUnlinkedExpenseUpdate') expenseUnlinkedExpenseUpdatePermission;
  @ViewChild('expenseUnlinkedExpenseUpdateOwn') expenseUnlinkedExpenseUpdateOwnPermission;
  @ViewChild('expenseUnlinkedMileageDelete') expenseUnlinkedMileageDeletePermission;
  @ViewChild('expenseUnlinkedMileageDeleteOwn') expenseUnlinkedMileageDeleteOwnPermission;
  @ViewChild('expenseInvestigationMileageDelete') expenseInvestigationMileageDeletePermission;
  @ViewChild('expenseInvestigationMileageDeleteOwn') expenseInvestigationMileageDeleteOwnPermission;
  @ViewChild('expenseInvestigationExpensesDelete') expenseInvestigationExpensesDeletePermission;
  @ViewChild('expenseInvestigationExpensesDeleteOwn') expenseInvestigationExpensesDeleteOwnPermission;
  @ViewChild('expenseUnlinkeExpenseDelete') expenseUnlinkeExpenseDeletePermission;
  @ViewChild('expenseUnlinkeExpenseDeleteOwn') expenseUnlinkeExpenseDeleteOwnPermission;

  @Input() public expenses: IApiExpense[] = [];
  @Input() public reloadUserId: string = '';
  @Input() public expenseItems: IApiExpenseItem[];
  @Input() public expensePurposes: ExpensePurpose[];
  @Input() public documentTypes: IApiDocumentType[] = [];
  @Input() public config: IConfig;
  @Input() public userId?: string;
  @Input() public expenseFilters?: IApiExpenseFilter[] = [];
  public currentExpenseFilters?: IApiExpenseFilter[] = [];
  @Input() public userSelect: boolean = false;
  // Let parent know of selection changes
  @Input() public selectedExpenseTotal;

  @Output() public selection = new EventEmitter<ISelectEvent>();
  @Output() public expenseSelectionChange = new EventEmitter<any>();

  @Output() public selectedExpenseTotalChange = new EventEmitter<any>();
  @Output() public reloadTotalChange = new EventEmitter<any>();

  public actionType: typeof ExpenseActionType = ExpenseActionType;
  public displayedColumns = ["date", "invId", "expense", "purpose", "description", "qty", "auth"];
  public footerCols: string[] = null;
  public columnDisplay = [`repeat(${2},minmax(0,1fr))`];
  public selectAll: boolean = false;

  private _selected: any;
  public get selected() {
    return this._selected;
  }
  public set selected(val) {
    this._selected = val;
    this.selection.emit(val);
  }

  public expenseTotal: IApiExpenseReportTotal;

  public showColumn = true;

  public sort: SortDescriptor[] = [{
    field: IApiExpenseOrderBy.CreatedAt,
    dir: 'desc'
  }];


  private _dataSource: ExpenseDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public selectedExpense = [];
  public authenticatedUserId = null;

  constructor(
    private dialogService: DialogService,
    private expenseService: ExpenseService,
    private notifications: NotificationsService,
    private loader: LoaderService,
    public auth: AuthService,
    private downloadHelper: DownloadHelper,
    private reimbursementRate: ReimbursementRatePipe
  ) {
    this.dataSource = new ExpenseDataSource(this.expenseService);
    this.loader.attachObservable(this.dataSource.loading$);
    this.dataSource.listPage.limit = 25;
  }

  ngOnInit() {
    this.auth.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
    if (this.expenseFilters.length > 0 && this.userId) {
      this.currentExpenseFilters = [...this.expenseFilters, ...[{ type: IApiExpenseFilterType.User, value: this.userId }, { type: IApiExpenseFilterType.ViewBookkeepingReport, value: 'true' }]];
      this.getExpenses();
    }
  }

  ngOnChanges(data) {
    if (data?.reloadUserId?.currentValue === this.userId) {
      this.currentExpenseFilters = [...this.expenseFilters, ...[{ type: IApiExpenseFilterType.User, value: this.reloadUserId }]];
      this.getExpenses();
    }

    const notPaidExpensesLength = this.expenses.filter(i => !i.paidDate)?.length
    if (data?.userSelect?.currentValue === true && this.selectedExpense?.length !== notPaidExpensesLength) {
      this.selectedExpense = this.expenses.filter(expense => !expense.paidDate).map(expense => expense.id);
      this.selectedExpenseTotal[this.userId] = 0;
      this.expenses.forEach((expense) => {
        if(!expense.paidDate) {
          this.addSelectedExpenseToTotal(expense);
        }
      });
      const metadata = {
        selectAll: true
      };
      this.emitExpenseSelection(this.selectedExpense, metadata);
      setTimeout(() => {
        this.selectedExpenseTotalChange.emit(this.selectedExpenseTotal);
      }, 1)
    } else if (data?.userSelect?.currentValue === false && this.selectedExpense?.length === notPaidExpensesLength) {
      this.selectedExpense = [];
      this.selectedExpenseTotal[this.userId] = 0;
      setTimeout(() => {
        this.selectedExpenseTotalChange.emit(this.selectedExpenseTotal);
      }, 1)
    }
  }

  getExpenses() {
    this.currentExpenseFilters.map(item => {
      this.dataSource.applyFilter(item.type, item.value);
    });
    this.dataSource.load().then(() => {
      this.dataSource?.contents$.subscribe((res) => {
        this.expenses = res;
        this.selectedExpense = [];
        this.emitExpenseSelection(this.selectedExpense, { selectAll: false });
      });
    })
    this.getExpenseTotal();
  }

  private getExpenseTotal() {
    this.expenseService.getBookkeepingExpenseReportTotal(this.dataSource.lastFilters, this.pageOptions.getPageOptions())
      .subscribe(response => this.expenseTotal = response);
  }

  public selectChange(event: MatCheckboxChange, row: any) {
    const selectData: ISelectEvent = {
      selected: event.checked,
      data: row
    };
    this.selection.emit(selectData);
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiExpenseOrderBy.CreatedAt;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.getExpenses();
  }

  public expenseAction(item?: IApiExpense, actionType?: ExpenseActionType) {
    if (actionType === ExpenseActionType.DUPLICATE && item?.ExpenseItem?.name !== 'Mileage') {
      if ((item.billableQuantity + item.nonBillableQuantity) > 100) {
        return this.notifications.kendoConfirm(
          "You are not able to duplicate this expense entry as it exceeds $100.00. Please add a new expense entry.",
          "Duplicate Expense Entry",
          "",
          "Okay"
        )
          .pipe(
            filter((v) => !!v),
          )
          .subscribe(() => {
            return;
          });
      }
    }
    const expenseItem = (actionType === ExpenseActionType.UPDATE) ? { ...item } : (actionType === ExpenseActionType.DUPLICATE) ? { ...item, id: null } : {
      id: null,
      nefcoVehicle: false,
      expenseDate: new Date(),
      outOfPocket: false,
      billableQuantity: null,
      nonBillableQuantity: null,
      ExpenseItem: { id: null, name: null },
      ExpensePurpose: { id: null, name: null }
    };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: item.Investigation?.id,
      nefcoNumber: item.Investigation?.nefcoNumber,
      expense: expenseItem as IApiExpense,
      time: null,
      staff: null,
      adminView: false,
      showStaffMember: false,
      actionType
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = [];
    dialogInstance.expenseItems = this.expenseItems;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.getExpenses();
        this.selectedExpense = [];
        this.selectedExpenseTotal[this.userId] = 0;
        setTimeout(() => {
          this.selectedExpenseTotalChange.emit(this.selectedExpenseTotal);
          this.reloadTotalChange.emit(Math.random());
        }, 1)
      };
    })
  }

  public deleteExpense(entry) {

    this.notifications.kendoConfirm(
      "Are you sure you want to delete this expense entry?",
      "Delete Expense Entry?",
      "No, Don’t Delete",
      "Yes, Delete"
    )
      .pipe(
        filter((v) => !!v),
        switchMap(() => this.expenseService.remove(entry.id))
      )
      .subscribe(() => {
        this.getExpenses();
        this.reloadTotalChange.emit(Math.random());
      });
  }

  private getExpenseAmount(expense) {
    return expense?.ExpenseItem?.name === 'Mileage' ? (this.reimbursementRate.transform(expense)) : +(expense?.billableQuantity + expense?.nonBillableQuantity)?.toFixed(2);
  };

  private addSelectedExpenseToTotal(expense) {
    this.selectedExpenseTotal[this.userId] += this.getExpenseAmount(expense);
  }

  private removeSelectedExpenseToTotal(expense) {
    this.selectedExpenseTotal[this.userId] -= this.getExpenseAmount(expense);
  }

  onExpenseCheckboxChange(e) {
    if (e?.selectedRows?.length) {
      e?.selectedRows?.forEach(expense => {
        if (!expense?.dataItem?.padiDate) {
          this.selectedExpense.push(expense?.dataItem?.id);
          this.addSelectedExpenseToTotal(expense?.dataItem);
        }
      });
    }
    if (e?.deselectedRows?.length) {
      e?.deselectedRows?.forEach(expense => {
        this.selectedExpense = this.selectedExpense.filter(id => id != expense?.dataItem?.id);
        this.removeSelectedExpenseToTotal(expense?.dataItem);
      });
    }
    const metadata = {
      selectAll: this.selectedExpense.length === this.expenses.filter(i => !i.paidDate).length ? true : false
    };
    this.emitExpenseSelection(this.selectedExpense, metadata)
    this.selectedExpenseTotalChange.emit(this.selectedExpenseTotal);
  }

  onExpenseCheckboxChecked(checked, dataItem) {
    if (checked) {
        if (!this.selectedExpense.includes(dataItem?.id)) {
          this.selectedExpense.push(dataItem?.id);
          this.addSelectedExpenseToTotal(dataItem);
        }
    } else {
      if (this.selectedExpense.includes(dataItem?.id)) {
        this.selectedExpense = this.selectedExpense.filter(id => id != dataItem?.id);
        this.removeSelectedExpenseToTotal(dataItem);
      }
    }
    const metadata = {
      selectAll: this.selectedExpense.length === this.expenses.filter(i => !i.paidDate).length ? true : false
    };
    this.emitExpenseSelection(this.selectedExpense, metadata)
    this.selectedExpenseTotalChange.emit(this.selectedExpenseTotal);
  }

  private emitExpenseSelection(value, metadata) {
    const obj = {
      [this.userId]: {
        values: value,
        metadata
      }
    }
    this.expenseSelectionChange.emit(obj);
  }

  public downloadReciept (row: IApiDocument): void {
    this.downloadHelper.externalDownload(row?.s3Uri, row?.title);
  }

  // check permission for edit
  public checkEditPermission = (row) => {
    if (row?.ExpenseItem?.name === 'Mileage') {
      if (row?.Investigation) {
        if (this.expenseInvestigationMileageUpdatePermission?.enabled || (this.expenseInvestigationMileageUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      } else {
        if (this.expenseUnlinkedMileageUpdatePermission?.enabled || (this.expenseUnlinkedMileageUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      }
    } else {
      if (row?.Investigation) {
        if (this.expenseInvestigationExpensesUpdatePermission?.enabled || (this.expenseInvestigationExpensesUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      } else {
        if (this.expenseUnlinkedExpenseUpdatePermission?.enabled || (this.expenseUnlinkedExpenseUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      }
    }
  }

  // check permission for delete
  public checkDeletePermission = (row) => {
    if (row?.ExpenseItem?.name === 'Mileage') {
      if (row?.Investigation) {
        if (this.expenseInvestigationMileageDeletePermission?.enabled || (this.expenseInvestigationMileageDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      } else {
        if (this.expenseUnlinkedMileageDeletePermission?.enabled || (this.expenseUnlinkedMileageDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      }
    } else {
      if (row?.Investigation) {
        if (this.expenseInvestigationExpensesDeletePermission?.enabled || (this.expenseInvestigationExpensesDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      } else {
        if (this.expenseUnlinkeExpenseDeletePermission?.enabled || (this.expenseUnlinkeExpenseDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
        else return false;
      }
    }
  }
}
