import { DataSource } from "@angular/cdk/collections";
import { IApiCertification, IApiCertificationFilter, IApiCertificationOrderBy, IApiCertificationFilterType, IApiDocumentConnection, IApiCertificationConnection } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { CertificationService } from './certification.service';
import { ApiConnection } from '../../decorators/@ApiConnection.decorator';

export class CertificationDataSource extends NefcoPagedDataSource<IApiCertification, CertificationService, IApiDocumentConnection> implements DataSource<IApiCertification> {
  @ApiConnection()
  public load(filters: IApiCertificationFilter[] = []): Promise<IApiCertificationConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
