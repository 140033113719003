import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortDescriptor } from '@progress/kendo-data-query';
import _, { forOwn, groupBy, uniq, uniqBy, values } from 'lodash';
import { keyAscOrder, keyDescOrder } from 'src/app/shared/helpers/helper';
import { IApiNotificationMethodSetting, IApiNotificationMethodSettingFilterType, IApiNotificationType, IApiNotificationTypeFilterType, IApiUpdateNotificationMethodSettingInputV2, IApiUpdateNotificationSettingForRole } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { UserNotificationMethodSettingService, UserNotificationTypeService } from 'src/app/shared/services';
import { manageUserRoleNotificationsUpdate } from "../../../../shared/helpers/auth-config/manage.config";
@Component({
  selector: "app-notification-settings",
  templateUrl: "./notification-settings.component.html",
  styleUrls: ["./notification-settings.component.scss"],
})
export class NotificationSettingsComponent implements OnInit {
  
  public authConfig = {
    manageUserRoleNotificationsUpdate
  }

  @Output() reload = new EventEmitter<boolean>();
  public sort: SortDescriptor[] = [
    {
      field: "key",
      dir: "asc",
    },
  ];

  public expandedDetailKeys: any[] = [];
  public entity: string[] = [];
  public notificationTypesList: any = [];
  public notificationTypes: any = [];
  public selectedEntity = [];
  public settings: IApiNotificationMethodSetting[] = [];
  public permissionRoleId = null;

  constructor(
    private route: ActivatedRoute,
    private loader: LoaderService,
    private userNotificationTypeService: UserNotificationTypeService,
    private userNotificationMethodSettingService: UserNotificationMethodSettingService,
    private notification: NotificationsService
  ) {}

  ngOnInit(): void {
    this.permissionRoleId = this.route.snapshot.params.id;
    this.loader.show();
    this.userNotificationTypeService.get([{type: IApiNotificationTypeFilterType.PermissionRoleId, value: this.permissionRoleId}]).pipe(
      unwrapConnection()
    ).subscribe(types => {
      this.notificationTypesList = groupBy(types.sort((a, b) => a.entity < b.entity ? -1 : 1), 'entity');
      this.notificationTypes = groupBy(types.sort((a, b) => a.entity < b.entity ? -1 : 1), 'entity');
      this.entity = Object.keys(this.notificationTypesList);
      this.loader.hide();
    });
  }

  public filterTable() {
    if (this.selectedEntity?.length)
      this.notificationTypes = Object.keys(this.notificationTypesList).filter(i => this.selectedEntity.includes(i)).reduce((obj, key) => {
        obj[key] = this.notificationTypesList[key];
        return obj;
      }, {});
    else
    this.notificationTypes = this.notificationTypesList;
  }

  public clearFilter() {
    this.selectedEntity = [];
    this.notificationTypes = this.notificationTypesList;
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 2 ? tags : [tags];
  }

  public keyDescOrder = keyDescOrder;
  public keyAscOrder = keyAscOrder;
  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
  };

  public isEnabled(item: any, key: string) {
    return item?.value?.length && key && item?.value.some(item => !!item[key]);
  }

  public getCheckedByKey(value: any, key: string): boolean {
    if (key === "isEmail") {
      return value.filter(item => item.emailTemplate && key === 'isEmail').every(
        (item) =>
            item?.MethodSettings?.length &&
            item?.MethodSettings[0][key] === true)
    } else if (key === 'isSms') {
      return value.filter(item => item.smsTemplate && key === 'isSms').every(
        (item) =>
            item?.MethodSettings?.length &&
            item?.MethodSettings[0][key] === true)
    } else if (key === 'isSystem') {
      return value.every(
        (item) =>
            item?.MethodSettings?.length &&
            item?.MethodSettings[0][key] === true)
    } else {
      return value.every(
        (item) =>
            item?.MethodSettings?.length &&
            item?.MethodSettings[0][key] === true)
    }
  }

  public checkedMainNotificationByKey(checked: boolean, mainItem: any, key: string, entity: string) {
    let current = this.notificationTypes[entity];
    let global = this.notificationTypesList[entity];
    current.map(item => {
        const setMethodSettings = {
          ...item?.MethodSettings[0],
          PermissionRole: {
            id: this.permissionRoleId
          },
          NotificationType: {
            id: item?.id
          },
          [key]: item?.emailTemplate && key === 'isEmail' ? checked : (item?.smsTemplate && key === 'isSms') ? checked : key === 'isSystem' ? checked : key === 'sendAll' ? checked : false
        };
        if (item?.MethodSettings?.length) {
          item.MethodSettings[0] = setMethodSettings;
        } else {
          item.MethodSettings.push(setMethodSettings);
        }
    });
    global.map(item => {
        const setMethodSettings = {
          ...item?.MethodSettings[0],
          PermissionRole: {
            id: this.permissionRoleId
          },
          NotificationType: {
            id: item?.id
          },
          [key]: item?.emailTemplate && key === 'isEmail' ? checked : (item?.smsTemplate && key === 'isSms') ? checked : key === 'isSystem' ? checked : key === 'sendAll' ? checked : false
        };
        if (item?.MethodSettings?.length) {
          item.MethodSettings[0] = setMethodSettings;
        } else {
          item.MethodSettings.push(setMethodSettings);
        }
    })
  }

  public checkedNotification(checked: boolean, key: string, item: any, entity: string) {
      let currentIndex = this.notificationTypes[entity].findIndex((x) => x.id === item.id);
      let globalIndex = this.notificationTypesList[entity].findIndex((x) => x.id === item.id);
      let indexObject = this.notificationTypes[entity].find((x) => x.id === item.id);
      let setMethodSettings = {
        ...indexObject.MethodSettings[0],
        PermissionRole: {
          id: this.permissionRoleId
        },
        NotificationType: {
          id: item?.id
        },
        [key]: checked
      };
     
      if (this.notificationTypes[entity][currentIndex].MethodSettings?.length > 0 && this.notificationTypesList[entity][globalIndex].MethodSettings?.length > 0) {
        this.notificationTypes[entity][currentIndex].MethodSettings[0] = setMethodSettings;
        _.cloneDeep(this.notificationTypesList)[entity][globalIndex].MethodSettings[0] = setMethodSettings;
      } else {
        this.notificationTypes[entity][currentIndex].MethodSettings.push(setMethodSettings);
        _.cloneDeep(this.notificationTypesList)[entity][globalIndex].MethodSettings.push(setMethodSettings);
      }
  }

  public saveChanges(notificationTypes) {
    const body: IApiUpdateNotificationSettingForRole[] = [];
    for (const item of notificationTypes) {
      if (item.MethodSettings?.length) {
        body.push({
          NotificationTypeId: item.MethodSettings[0].NotificationType?.id,
          PermissionRoleId: item.MethodSettings[0].PermissionRole?.id,
          isEmail: item.MethodSettings[0].isEmail,
          isSms: item.MethodSettings[0].isSms,
          isSystem: item.MethodSettings[0].isSystem,
          sendAll: item.MethodSettings[0].sendAll
        })
      }
    };
    this.loader.show$(this.userNotificationMethodSettingService.updateV2({data: body})).pipe(
      this.notification.catchAlertPipe("Error updating notification settings"),
    ).subscribe(() => {
      this.notification.notify("Notification settings updated.");
      this.reload.emit(true);
    });
  }

}
