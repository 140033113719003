import { IApiEvidenceTransfer, IApiEvidenceTransferConnection, IApiEvidenceTransferFilter } from './../../../modules/graphql/types/types';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { EvidenceTransferService } from "./evidence-transfer.service";
import { NefcoPagedDataSource } from '../../../helpers/nefco-data-source.class';

export class EvidenceTransferDataSource extends NefcoPagedDataSource<IApiEvidenceTransfer, EvidenceTransferService, IApiEvidenceTransferConnection> implements DataSource<IApiEvidenceTransfer> {
  @ApiConnection()
  public load(filters: IApiEvidenceTransferFilter[] = []): Promise<IApiEvidenceTransferConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
