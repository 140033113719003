import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IPayPeriod } from 'src/app/shared/interfaces/time-entry.interfaces';
import { IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { IPayRollDay } from '../time-kendo-card/time-kendo-card.component';

@Component({
  selector: 'app-bookkeeping-payroll-filter',
  templateUrl: './bookkeeping-payroll-filter.component.html',
  styleUrls: ['./bookkeeping-payroll-filter.component.scss']
})
export class BookkeepingPayrollFilterComponent {
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public _selectedTimeEntries = new SelectionModel<IPayRollDay>(true, []);

  @Input() selectedTab: string;
  @Input() payPeriod: IPayPeriod;
  @Input() maxDate: Date = null;
  @Input() set selectedTimeEntries(value: any) {
    this._selectedTimeEntries = value;
  }

  get selectedTimeEntries() {
    return this._selectedTimeEntries;
  }

  @Output() dateRangeChange = new EventEmitter<string>();
  @Output() userSelectionChange = new EventEmitter<string>();
  @Output() downloadCsv = new EventEmitter<boolean>();
  @Output() markAsPaid = new EventEmitter<boolean>();
  @Output() markAllAsPaid = new EventEmitter<boolean>();

  
  constructor() { }

  onDateRangeChanged(direction) {
    if (this.maxDate.toDateString() === this.payPeriod?.payDate?.toDateString() && direction === 'forward') {
      return;
    }
    this.dateRangeChange.emit(direction);
  }

  setUser(id){
    this.userSelectionChange.emit(id);
  }

  downloadCsvAction(value){
    this.downloadCsv.emit(value);
  }

  markAsPaidAction(){
    this.markAsPaid.emit(true);
  }

  markAllAsPaidAction(){
    this.markAllAsPaid.emit(true);
  }

}
