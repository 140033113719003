<!-- Permissions -->
<div [appEnforcePermission]="authConfig.evidence" #evidence="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceDownload" #evidenceDownload="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceView" #evidenceView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceUpdate" #evidenceUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceUpdateOwn" #evidenceUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceDelete" #evidenceDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceTransferCreate" #evidenceTransferCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceTransferUpdate" #evidenceTransferUpdate="appEnforcePermission"></div>
<!-- Permissions -->
<section *ngIf="evidence?.enabled">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
    <kendo-gridlayout-item>
      <h4 class="page-title">Evidence</h4>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item style="display: flex; align-items: center; text-align: right; justify-content: flex-end;">
      <div class="kendo-formfield left-search-icon mr-16" style="width: 350px;">
        <div style="position: relative;">
          <i class="fa fa-search" aria-hidden="true"></i>
          <kendo-textbox
            name="search"
            [(ngModel)]="searchValue"
            (appDebouncedInputChange)="onSearchChange()"
            (ngModelChange)="!searchValue ? onSearchChange() : null"
            placeholder="Search By Description"
            fillMode="outline"
            [clearButton]="true">
          </kendo-textbox>
        </div>
      </div>
      <kendo-button *ngIf="evidenceDownload?.enabled" kendoButton themeColor="primary" fillMode="solid" size="medium"
        (click)="downloadEvidenceItems()">Download</kendo-button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
<div class="content-wrapper">
  <div class="page-filters">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">

      <kendo-gridlayout-item>
        <app-state-select-kendo [label]="'State'" [placeholder]="'Select state'"
          [selectedId]="filterValue(filterTypes.State)"
          (selectionChanged)="setFilters($event, filterTypes.State)">
        </app-state-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon multi">
          <kendo-label text="Location"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-multiselect
                [checkboxes]="true"
                [autoClose]="false"
                [valuePrimitive]="true"
                [data]="evidenceStorageLocations"
                [value]="filterByIdValue(filterTypes.Locations)"
                [rounded]="'full'"
                (valueChange)="setVal($event, filterTypes.Locations)"
                valueField="id"
                textField="name"
                [tagMapper]="tagMapper"
                [placeholder]="'Select location'"
                [fillMode]="'outline'"
                [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                  <span style="padding-left: 5px;">{{ dataItem?.name }}</span>
                </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon multi">
          <kendo-label text="Status"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-multiselect
                [checkboxes]="true"
                [autoClose]="false"
                [valuePrimitive]="true"
                [data]="evidenceStatus"
                [value]="filterByIdValue(filterTypes.Statuses)"
                [rounded]="'full'"
                (valueChange)="setVal($event, filterTypes.Statuses)"
                valueField="value"
                textField="text"
                [tagMapper]="tagMapper"
                [placeholder]="'Select status'"
                [fillMode]="'outline'"
                [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                  <span style="padding-left: 5px;">{{ dataItem?.text }}</span>
                </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item [colSpan]="2">
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
          <kendo-gridlayout-item class="mb-0">
            <div class="kendo-formfield">
              <kendo-label text="Arrival Start Date"></kendo-label>
              <kendo-datepicker
                  id="startDate"
                  name="startDate"
                  calendarType="classic"
                  [fillMode]="'outline'"
                  placeholder="Start arrival date"
                  format="MM/dd/yyyy"
                  [(ngModel)]="startDateFilter"
                  (valueChange)="setDateFilter()"
                  formatPlaceholder="formatPattern"
                  #startDate="ngModel"
              ></kendo-datepicker>
            </div>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item class="mb-0">
            <div class="kendo-formfield">
              <kendo-label text="Arrival End Date"></kendo-label>
              <kendo-datepicker
                  id="endDate"
                  name="endDate"
                  calendarType="classic"
                  [fillMode]="'outline'"
                  placeholder="End arrival date"
                  format="MM/dd/yyyy"
                  [(ngModel)]="endDateFilter"
                  (valueChange)="setDateFilter()"
                  formatPlaceholder="formatPattern"
                  #endDate="ngModel"
              ></kendo-datepicker>
            </div>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-investigation-multi-select-kendo
          label="NEFCO #"
          placeholder="Select NEFCO #"
          [selectedIds]="filterByIdValue(filterTypes.InvestigationIds)"
          (selectionChanged)="setVal($event, filterTypes.InvestigationIds)"
        ></app-investigation-multi-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-uenumber-multi-select-kendo
          label="UE #"
          placeholder="Select UE #"
          [selectedId]="filterValue(filterTypes.Ids)"
          (selectionChanged)="setFilters($event, filterTypes.Ids)"
        ></app-uenumber-multi-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-company-select-kendo
          label="Company"
          placeholder="Select company"
          [selectedId]="filterByIdValue(filterTypes.CompanyIds)"
          (selectionChanged)="setFilters($event, filterTypes.CompanyIds)"
        ></app-company-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-user-multi-select-kendo
          label="Investigator"
          [userViewFilter]="userViewFilter"
          placeholder="Select investigator"
          [selectedId]="filterByIdValue(filterTypes.InvestigatorIds)"
          [investigationRole]="userRoleName.Investigator"
          [includeInactive]="true"
          (selectionChanged)="setFilters($event, filterTypes.InvestigatorIds)"
        ></app-user-multi-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item [colSpan]="4" style="position: relative; display: flex; align-items: center;">
        <div>
          <ng-container  *ngIf="evidenceTransferCreate?.enabled
          || evidenceDownload?.enabled
          || evidenceDelete?.enabled
          || evidenceUpdate?.enabled
          || evidenceUpdateOwn?.enabled">
            <kendo-dropdownbutton 
              [data]="getEvidenceActions"
              themeColor="light"
              fillMode="solid"
              buttonClass="grid-action-items-reverse"
              [iconClass]="isOpenMoreActions ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up'"
              (open)="isOpenMoreActions = true"
              (close)="isOpenMoreActions = false"
              (itemClick)="itemSelected($event)" 
              [disabled]="selectedEvidence.selected.length <= 0"
              [popupSettings]="{
                  appendTo: 'component',
                  animate: false,
                  width: 250,
                  popupClass: 'grid-action-items-menu mt-12'
              }">
                Actions
                <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                  <span class="gray-item">{{ dataItem.text }}</span>
                </ng-template>
            </kendo-dropdownbutton>
          </ng-container>
        </div>
        <div style="display: flex;">
          <div class="kendo-formfield checkbox-field">
            <kendo-label text=""></kendo-label>
            <div>
              <input type="checkbox"
                class="ml-24 mr-16"
                #isDisposed
                kendoCheckBox
                [(ngModel)]="isDisposedChecked"
                (change)="onCheckboxChange($event?.target?.checked, filterTypes.Disposed)"/>
              <kendo-label
                class="k-checkbox-filter-label mr-24"
                [for]="isDisposed"
                text="Is Disposed"
              ></kendo-label>
            </div>
          </div>

          <div class="kendo-formfield checkbox-field">
            <kendo-label text=""></kendo-label>
            <div>
              <input type="checkbox"
                class="mr-16"
                #isDistroyed
                kendoCheckBox
                [(ngModel)]="isDestroyedChecked"
                (change)="onCheckboxChange($event?.target?.checked, filterTypes.Destroyed)"/>
              <kendo-label
                class="k-checkbox-filter-label mr-24"
                [for]="isDistroyed"
                text="Is Destroyed"
              ></kendo-label>
            </div>
          </div>

          <div class="kendo-formfield checkbox-field">
            <kendo-label text=""></kendo-label>
            <div>
              <input type="checkbox"
                class="mr-16"
                #isBillable
                kendoCheckBox
                [(ngModel)]="isIsBillableChecked"
                (change)="onCheckboxChange($event?.target?.checked, filterTypes.IsBillable)"/>
              <kendo-label
                class="k-checkbox-filter-label mr-24"
                [for]="isBillable"
                text="Is Billable"
              ></kendo-label>
            </div>
          </div>

          <div class="kendo-formfield checkbox-field">
            <kendo-label text=""></kendo-label>
            <div>
              <input type="checkbox"
                class="mr-16"
                #isNonBillable
                kendoCheckBox
                [(ngModel)]="isIsNonBillableChecked"
                (change)="onCheckboxChange($event?.target?.checked, filterTypes.IsNonBillable)"/>
              <kendo-label
                class="k-checkbox-filter-label mr-24"
                [for]="isNonBillable"
                text="Is Non-Billable"
              ></kendo-label>
            </div>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item class="filter-actions">
        <div class="mr-16" style="display: flex; align-items: center;">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="applyFilters()">Apply
              Filters</kendo-button>
          </div>
        </div>
        <div class="mt-0" style="display: flex; align-items: center;">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearAll()">Clear
              All</kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>

    </kendo-gridlayout>
  </div>

  <kendo-grid
    class="evidence-list"
    [data]="{
      data: (dataSource?.contents$ | async) || [],
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageOptions?.paginate($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
  >
    <kendo-grid-column
      [field]="orderByOptions.Investigation"
      title="Nefco #"
      [width]="15"
      class="id-column"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          [routerLink]="['/investigations/', dataItem?.Investigation?.id]"
          tel=""
          class="text-decoration"
        >
          {{ dataItem?.Investigation?.nefcoNumber || "Unassigned" }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [field]="orderByOptions.EvidenceNumber"
      title="UE #"
      [width]="10"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.ueNumber || "-" }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="Evidence"
      title="Evidence #"
      [width]="18"
      [sortable]="false"
    > 
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <input type="checkbox"
          class="mr-16"
          #isMainChecked
          kendoCheckBox
          (change)="selectEvidence($event?.target, null, true)" 
          [checked]="(selectedEvidence.selected.length === evidenceVisible.length) && evidenceVisible.length > 0"/>
          <span class="k-cell-inner">
            <span class="k-link">
              <span class="k-column-title">
                {{column.title}}
              </span>
            </span>
          </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <input 
        type="checkbox"
        class="mr-16"
        kendoCheckBox
        (change)="selectEvidence($event?.target, dataItem, false)" 
        [checked]="selectedEvidence.isSelected(dataItem)"
        [disabled]="!(evidenceUpdate?.enabled || (isOwn(dataItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled))"/>
          {{dataItem?.evidenceNumber || '-'}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="Description"
        title="Description"
        [width]="40"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="text-overflow">
            {{ dataItem?.description || "-" }}
          </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="Location"
        title="Location"
        [width]="20"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.EvidenceStorageLocation?.name || "-" }}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="Section"
        title="Section"
        [width]="10"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.section || "-" }}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="Row"
        title="Row"
        [width]="10"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.row || "-" }}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="Status"
        title="Status"
        [width]="20"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ (dataItem?.Status | quiet) || "-" }}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        [field]="orderByOptions.CreatedAt"
        title="Arrived"
        [width]="15"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.createdAt | customDate: 'MM-dd-yyyy' }}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="Transferred"
        title="Transferred"
        [width]="15"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ (dataItem?.Transfers) ? dataItem?.Transfers[dataItem?.Transfers.length-1]?.receivedDate ? (dataItem?.Transfers[dataItem?.Transfers.length-1]?.receivedDate | customDate: 'MM-dd-yyyy' ) : '-' : '-' }}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="Action"
        title="Action"
        [width]="15"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            *ngIf="evidenceTransferUpdate?.enabled && dataItem?.CurrentTransfer"
            class="mr-8"
            kendoButton
            themeColor="primary"
            fillMode="link"
            iconClass="fa-solid fa-circle-arrow-right fa-lg"
            (click)="receiveItem(dataItem)"
          ></button>

          <button
            kendoButton
            themeColor="primary"
            fillMode="link"
            iconClass="fa-solid fa-up-right-from-square fa-lg"
            (click)="openSidebar(dataItem)"
            *ngIf="evidenceView?.enabled"
          ></button>
        </ng-template>
    </kendo-grid-column>

    <!-- No record -->
    <ng-template kendoGridNoRecordsTemplate>
      <p class="mt-24 mb-24">There are no results to display.</p>
    </ng-template>
  </kendo-grid>
</div>
</section>
