import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const nodeFragment = `
  id
  text
  commentableId
  commentableType
  CreatedBy {
    id
    firstName
    lastName
    email
  }
  createdAt
  UpdatedBy {
    id
    firstName
    lastName
    email
  }
  updatedAt
`;

export const CommentConnectionQuery = gql`${buildConnectionQuery("Comment", "commentConnection", nodeFragment)}`;

export const getCommentByIdQuery = gql`query getCommentById($id: String!) {
  getCommentById(id: $id) { ${ nodeFragment } } 
}`;

export const addCommentMutation = gql`mutation AddComment($input: AddCommentInput!) {
  addComment(input: $input) { ${ nodeFragment } }
}`;

export const updateCommentMutation = gql`mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) { ${ nodeFragment } }
}`;

export const removeCommentMutation = gql`mutation RemoveComment($id: ID!) {
  removeComment(id: $id)
}`;