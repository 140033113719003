<!-- Permissions -->
<div [appEnforcePermission]="authConfig.expenseReportNefcoCardCreate" #expenseReportNefcoCardCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseReportNefcoCardUpdate" #expenseReportNefcoCardUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseReportNefcoCardDelete" #expenseReportNefcoCardDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseReportNefcoCardDeleteOwn" #expenseReportNefcoCardDeleteOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseReportNefcoCardUpdateOwn" #expenseReportNefcoCardUpdateOwn="appEnforcePermission"></div>
<!-- Permissions -->
<section>
    <kendo-grid
    class="expense-list"
    [data]="{
      data: expenses,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageOptions?.paginate($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
  >
      <kendo-grid-column
        field="CREATED_AT"
        title="Entry Date"
        [width]="10"
        [sortable]="true"
        class="font-weight600"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.createdAt | customDate : "MM/dd/yyyy" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="INVESTIGATION"
        title="Investigation ID"
        [width]="12"
        class="id-column"
        [sortable]="false"
        class="font-weight600"
        [headerClass]="'nocursor'"
      >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          target="_blank"
          [routerLink]="['/investigations/', dataItem?.Investigation?.id]"
          tel=""
          class="text-decoration"
          *ngIf="dataItem?.Investigation else Unlinked"
        >
          {{ dataItem?.Investigation?.nefcoNumber || "Unlinked" }}
        </a>
        <ng-template #Unlinked>
          <span>Unlinked</span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Expense Item"
      [width]="17"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem?.ExpenseItem?.name || '-'}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Purpose"
      [width]="17"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem?.ExpensePurpose?.name || '-'}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Description"
      [width]="17"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="text-overflow">{{dataItem?.description || '-'}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Cost"
      [width]="9"
      [sortable]="false"
      class="text-right"
      [headerClass]="'nocursor text-right mr-12'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name !== 'Mileage'">${{(dataItem?.billableQuantity + dataItem?.nonBillableQuantity) | number: '1.2-2'}}</span>
        <span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name === 'Mileage'">${{(dataItem | reimbursementRate) | number: '1.2-2'}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Authorized"
      [width]="12"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="!dataItem?.authorizedBy && !dataItem?.authorizedDate">-</span>
        <span class="text-overflow" *ngIf="dataItem?.authorizedBy">{{dataItem?.authorizedBy}} <br /></span>
        <span *ngIf="dataItem?.authorizedDate" style="color: rgba(1, 24, 98, 0.6)">on {{dataItem?.authorizedDate | customDate : 'MM/dd/yyyy'}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="EXPENSE_DATE"
        title="Expense Date"
        [width]="10"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.expenseDate | customDate : "MM/dd/yyyy" }}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="tab === 'OUT_OF_POCKET_EXPENSE_REPORT'"
      class="text-center"
      field="PAID_DATE"
      title="Paid"
      [width]="7"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <i *ngIf=" dataItem?.paidDate" class="right-circle-icon fa-solid fa-circle-check"></i>
        <i *ngIf="!dataItem?.paidDate" class="minus-circle-icon fa-solid fa-circle-minus"></i>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
    *ngIf="tab === 'NEFCO_CARD_EXPENSE_REPORT'"
      field="id"
      title="Status"
      [width]="12"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-badge *ngIf="dataItem?.status !== 0" class="fw600 assigned-badge" position="none" size="medium" rounded="full" themeColor="primary">
              <span >Submitted</span>
            </kendo-badge>
            <kendo-badge *ngIf="dataItem?.status === 0" class="fw600 assigned-badge" position="none" size="medium" rounded="full" themeColor="tertiary">
              <span >Not Submitted</span>
            </kendo-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="id" title="Action" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil"
          (click)="expenseAction(dataItem, actionType.UPDATE);" *ngIf="expenseReportNefcoCardUpdate?.enabled || expenseReportNefcoCardUpdateOwn?.enabled"
          [disabled]="(tab === 'NEFCO_CARD_EXPENSE_REPORT' && dataItem?.status !== 0 ) || (dataItem?.paidDate && (auth.isPermissionRoles(['Admin']) | async) === false) || dataItem?.InvoiceItems?.length > 0"></button>
        <button kendoButton class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-regular fa-copy"
          (click)="expenseAction(dataItem, actionType.DUPLICATE);" *ngIf="expenseReportNefcoCardCreate?.enabled"></button>
        <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-trash"
          (click)="deleteExpense(dataItem)" *ngIf="expenseReportNefcoCardDelete?.enabled || expenseReportNefcoCardDeleteOwn?.enabled"
          [disabled]="(tab === 'NEFCO_CARD_EXPENSE_REPORT' && dataItem?.status !== 0) || (dataItem?.paidDate && (auth.isPermissionRoles(['Admin']) | async) === false) || dataItem?.InvoiceItems?.length > 0"></button>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid>
</section>