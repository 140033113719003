import { Component, Inject } from '@angular/core';
import { IApiDocument, IApiDocumentFilterType, IApiInvestigationReportStatus } from 'src/app/shared/modules/graphql/types/types';
import { InvestigationTemplatDownloadService, DocumentService, DownloadHelper, DocumentShareService } from 'src/app/shared/services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DocumentCategories } from 'src/app/shared/modules/graphql/constants/document.constants';

// Interface to allow document to be selected
interface ICheckableDocument extends IApiDocument {
  download: boolean;
}

@Component({
  selector: 'app-investigation-download-documents-modal',
  templateUrl: './investigation-download-documents-modal.component.html',
  styleUrls: ['./investigation-download-documents-modal.component.scss']
})
export class InvestigationDownloadDocumentsModalComponent {

  public error: string = null;

  public downloads: ICheckableDocument[];

  public get uploads(): ICheckableDocument[] {
    return this.filterDocuments(DocumentCategories.UPLOAD) as ICheckableDocument[];
  }

  public get reports() {
    return this.filterDocuments(DocumentCategories.REPORT) as ICheckableDocument[];
  }

  public get checkedUploads() {
    return this.uploads.filter(d => d.download);
  }

  public get checkedReports() {
    return this.reports.filter(d => d.download);
  }

  constructor(
    public dialogRef: MatDialogRef<InvestigationDownloadDocumentsModalComponent>,
    private loader: LoaderService,
    private templateService: InvestigationTemplatDownloadService,
    private documentService: DocumentService,
    private downloadHelper: DownloadHelper,
    private documentShareService: DocumentShareService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Get documents
    this.loader.show$(
      this.documentService.get([{
          type: IApiDocumentFilterType.Investigation,
          value: data.investigation.id
        }]).pipe(
          unwrapConnection()
        )
    ).subscribe((docs) => {
      this.downloads = docs;
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.uploads.map(a => a.download = false);
      this.reports.map(a => a.download = false);
    });
   }

  // Filter documents by "Uploads" or "Reports" (if "Reports" are status of final)
  private filterDocuments(category: DocumentCategories) {
    if (!this.downloads) return [];
    return (this.downloads || []).filter(({ Type, Status }) => (Type.Category.name === category.valueOf()) && (Type.Category.name === DocumentCategories.REPORT ? Status.status === IApiInvestigationReportStatus.Final : true));
  }

  // "Select All" documents checkbox
  public checkAll(event: MatCheckboxChange ): void {
    event.checked ? this.uploads.map(a => a.download = true) : this.uploads.map(a => a.download = false);
    event.checked ? this.reports.map(a => a.download = true) : this.reports.map(a => a.download = false);
  }

  // Toggle "Select All" checkbox if all are selected
  public allSelected() {
    const download = this.checkedUploads.length + this.checkedReports.length;
    const all = this.uploads.length + this.reports.length;
    return !!download && download === all;
  }

  public download() {
    const documentIds = [...this.checkedUploads.map(a => a.id), ...this.checkedReports.map(a => a.id)];
    this.loader.show$(
      this.documentShareService.downloadZip({
        InvestigationId: this.data.investigation.id,
        DocumentIds: documentIds
      })
    ).subscribe(downloadString => {
      this.downloadHelper.externalDownloadZip(downloadString as string, `${this.data.investigation.nefcoNumber}_download`);
      this.dialogRef.close();
    });
  }

}
