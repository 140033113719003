<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar [ngClass]="{'hide-header': config?.hideModalContent}" (close)="close()">
    <div class="modal-title" *ngIf="!config?.hideModalContent">
      <span>Add Branch</span>
      <div class="modal-subtitle" *ngIf="data?.companyName" [innerHTML]="data.companyName"></div>
    </div>
  </kendo-dialog-titlebar>
  <kendo-card class="m-2" width="100%">
    <kendo-card-header>
      Branch Information
    </kendo-card-header>
    <kendo-card-body>
      <form #branchForm="ngForm">

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
          <kendo-gridlayout-item [colSpan]="1">
            <kendo-formfield>
              <kendo-label class="asterisk" [for]="branchName" text="Branch Name"></kendo-label>
              <kendo-textbox #branchName="ngModel" [(ngModel)]="branch.name" name="branchName"
                placeholder="Enter branch name" fillMode="outline" [clearButton]="false" required appNoWhiteSpace></kendo-textbox>
              <kendo-formerror *ngIf="!branchName?.valid && (branchName?.dirty || branchName?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="branchName?.errors?.required">Branch name cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
          <!-- Address -->
          <ng-container *ngFor="let address of branch?.Addresses; let i = index;">
            <app-address [ngModel]="branch?.Addresses[i]" [address]="branch.Addresses[i]" [name]="'address' + i"
              (addressChange)="updateAddress($event, i)" [config]="{columnCount: 2, showRemove: true}"
              (remove)="removeAddress(i)"></app-address>
          </ng-container>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
           <!-- Phone -->
           <ng-container *ngFor="let phone of branch.Phones; let i = index">
            <app-phone [ngModel]="branch.Phones[i]" [name]="'phone' + i" (remove)="removePhone(i)"
              (change)="emitBranchChange()" (isPrimary)="isPrimaryToggle($event, i)"></app-phone>
          </ng-container>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
          <!-- Email -->
          <ng-container class="mb-0" *ngFor="let email of branch.Emails; let i = index;">
            <app-email [ngModel]="branch.Emails[i]" [name]="'email' + i" (change)="emitBranchChange()"
              (remove)="removeEmail(i)"></app-email>
          </ng-container>
        </kendo-gridlayout>

        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
          <kendo-gridlayout-item>
            <button kendoButton class="mr-24" themeColor="primary" fillMode="link" iconClass="fas fa-plus" (click)="addAddress();">Add Address</button>
            <button kendoButton class="mr-24" themeColor="primary" fillMode="link" iconClass="fas fa-plus" (click)="addPhone();">Add Phone</button>
            <button kendoButton themeColor="primary" fillMode="link" iconClass="fas fa-plus" (click)="addEmail();">Add Email</button>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label text="Additional Information"></kendo-label>
              <kendo-textarea name="notes" #additionalInformation="ngModel" [(ngModel)]="branch.additionalInformation"
                placeholder="Please provide a brief explanation about this request." fillMode="outline"
                class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
              <kendo-formerror *ngIf="!additionalInformation?.valid && (additionalInformation?.dirty || additionalInformation?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="additionalInformation?.errors?.required">Additional information cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </form>
    </kendo-card-body>
  </kendo-card>
  <kendo-dialog-actions [ngClass]="{'hide-header': config?.hideModalContent}" layout="end" class="p-24">
    <button kendoButton [size]="'medium'" themeColor="light" (click)="close(false)">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" class="ml-2" themeColor="primary" (click)="save()" [disabled]="branchForm?.invalid">
      Save
    </button>
  </kendo-dialog-actions>
</div>
