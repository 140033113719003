<!-- Permissions -->
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesUpdate" #expenseInvestigationExpensesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesUpdateOwn" #expenseInvestigationExpensesUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesDelete" #expenseInvestigationExpensesDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesDeleteOwn" #expenseInvestigationExpensesDeleteOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageUpdate" #expenseInvestigationMileageUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageUpdateOwn" #expenseInvestigationMileageUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageDelete" #expenseInvestigationMileageDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageDeleteOwn" #expenseInvestigationMileageDeleteOwn="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-card class="kendocard" [width]="'100%'">
    <kendo-card-header class="pt-0 pb-0">
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" class="expense-counter-wrapper" [cols]="investigationView ? ['70%', '5%'] : ['repeat(3,minmax(0,1fr))']">
            <kendo-gridlayout-item class="mb-0" *ngIf="!isMileage">
              <div class="expense-div">
                <div>
                  <h4>Total Expenses</h4>
                  <p>${{expenseMileageCount?.totalExpense || 0}}</p>
                </div>
                <div>
                  <h4>Billable Expenses</h4>
                  <p>${{expenseMileageCount?.billableExpense || 0}}</p>
                </div>
                <div>
                  <h4>Non-Billable Expenses</h4>
                  <p>${{expenseMileageCount?.nonBillableExpense || 0}}</p>
                </div>
              </div>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0" *ngIf="isMileage">
                <div class="expense-div">
                    <div>
                    <h4>Total Mileage</h4>
                    <p>{{expenseMileageCount?.totalMileage || 0}} mi</p>
                    </div>
                    <div>
                    <h4>Billable Mileage</h4>
                    <p>{{expenseMileageCount?.billableMileage || 0}} mi</p>
                    </div>
                    <div>
                    <h4>Non-Billable Mileage</h4>
                    <p>{{expenseMileageCount?.nonBillableMileage || 0}} mi</p>
                    </div>
                </div>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0"></kendo-gridlayout-item>
        </kendo-gridlayout>
    </kendo-card-header>
    <kendo-card-body class="pt-0">
        <kendo-grid
            class="expense-list"
            [data]="{
            data: expenses,
            total: (dataSource?.pageInfo$ | async)?.totalCount
            }"
            [pageSize]="pageOptions?.limit"
            [skip]="pageOptions?.offset"
            [pageable]="{
            buttonCount: 6,
            type: 'numeric',
            pageSizes: [5, 10, 25, 100],
            previousNext: true,
            responsive: true,
            position: 'bottom'
            }"
            (pageChange)="pageChange($event)"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
        >
            <kendo-grid-column
                field="EXPENSE_DATE"
                title="Date"
                [width]="10"
                [sortable]="true"
                class="font-weight600"
                [headerClass]="'sort-header'"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem?.expenseDate | customDate : "MM/dd/yyyy" }}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
                field="INVESTIGATION"
                title="Investigation"
                [width]="15"
                class="id-column"
                [sortable]="true"
                class="font-weight600"
                [headerClass]="'sort-header'"
                *ngIf="!investigationView"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <a
                target="_blank"
                [routerLink]="['/investigations/', dataItem?.Investigation?.id]"
                tel=""
                class="text-decoration"
                *ngIf="dataItem?.Investigation else Unlinked"
                >
                {{ dataItem?.Investigation?.nefcoNumber || "Unlinked" }}
                </a>
                <ng-template #Unlinked>
                <span>Unlinked</span>
                </ng-template>
            </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            field="id"
            title="Expense Item"
            [width]="20"
            [sortable]="false"
            [headerClass]="'nocursor'"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem?.ExpenseItem?.name || '-'}}</span>
            </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            field="id"
            title="Purpose"
            [width]="20"
            [sortable]="false"
            [headerClass]="'nocursor'"
            *ngIf="!investigationView"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem?.ExpensePurpose?.name || '-'}}</span>
            </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            field="id"
            title="Cost/Miles"
            [width]="9"
            [sortable]="false"
            class="text-right"
            [headerClass]="'nocursor text-right'"
            *ngIf="!investigationView"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name !== 'Mileage'">{{(dataItem?.billableQuantity + dataItem?.nonBillableQuantity) ? ('$' + ((dataItem?.billableQuantity + dataItem?.nonBillableQuantity) | number : '1.2-2')) : '-'}}</span>
                <span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name === 'Mileage'">{{(dataItem?.billableQuantity + + dataItem?.nonBillableQuantity) ? (dataItem?.billableQuantity + + dataItem?.nonBillableQuantity) + ' mi': '-' }}</span>
            </ng-template>
            </kendo-grid-column>


            <kendo-grid-column
            field="id"
            title="Bill"
            [width]="9"
            [sortable]="false"
            class="text-right"
            [headerClass]="'nocursor text-right'"
            *ngIf="investigationView"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name !== 'Mileage'">{{dataItem?.billableQuantity ? ('$' + (dataItem?.billableQuantity | number: '1.2-2')) : '-'}}</span>
                <span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name === 'Mileage'">{{dataItem?.billableQuantity ? dataItem?.billableQuantity + ' mi' : '-'}}</span>
            </ng-template>
            </kendo-grid-column>


            <kendo-grid-column
            field="id"
            title="Non-Bill"
            [width]="9"
            [sortable]="false"
            class="text-right"
            [headerClass]="'nocursor text-right'"
            *ngIf="investigationView"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name !== 'Mileage'">{{dataItem?.nonBillableQuantity ? ('$' + (dataItem?.nonBillableQuantity | number: '1.2-2')) : "-"}}</span>
                <span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name === 'Mileage'">{{dataItem?.nonBillableQuantity ? dataItem?.nonBillableQuantity + ' mi' : '-'}}</span>
            </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            field="id"
            title="Type"
            [width]="15"
            [sortable]="false"
            [headerClass]="'nocursor'"
            *ngIf="!investigationView"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem?.ExpenseItem?.name !== 'Mileage'">{{dataItem?.outOfPocket === 1 ? 'Out of Pocket' : (dataItem?.outOfPocket === 2 ? 'Vendor/Other':'NEFCO Card')}}</span>
                <span *ngIf="dataItem?.ExpenseItem?.name === 'Mileage'">{{dataItem?.nefcoVehicle === 1 ? 'NEFCO Vehicle' : (dataItem?.nefcoVehicle === 2 ? 'Vendor/Other':'My Vehicle')}}</span>
            </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            field="id"
            title="Receipt"
            [width]="7"
            [sortable]="false"
            class="text-center"
            [headerClass]="'nocursor'"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem?.Document?.id"><i class="fa-solid fa-receipt"></i></span>
                <span *ngIf="!dataItem?.Document?.id">-</span>
            </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            field="id"
            title="Authorized"
            [width]="15"
            [sortable]="false"
            [headerClass]="'nocursor'"
            *ngIf="!investigationView"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="text-overflow" *ngIf="dataItem?.authorizedBy">{{dataItem?.authorizedBy}} <br /></span>
                <span *ngIf="dataItem?.authorizedDate" style="color: rgba(1, 24, 98, 0.6)">on {{dataItem?.authorizedDate | customDate : 'MM/dd/yyyy'}}</span>
            </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            class="text-center"
            field="PAID_DATE"
            title="Paid"
            [width]="7"
            [sortable]="true"
            [headerClass]="'sort-header'"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <i *ngIf=" dataItem?.paidDate" class="right-circle-icon fa-solid fa-circle-check"></i>
                <i *ngIf="!dataItem?.paidDate" class="minus-circle-icon fa-solid fa-circle-minus"></i>
            </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            field="id"
            title="Actions"
            [width]="15"
            [sortable]="false"
            [headerClass]="'nocursor'"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
                <button kendoButton class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil"
                    (click)="editExpense(dataItem);" *ngIf="checkEditPermission(dataItem); else noPermission"
                    [disabled]="(dataItem?.paidDate && (auth.isPermissionRoles(['Admin']) | async) === false) || dataItem?.InvoiceItems?.length > 0"></button>
                    <ng-template #noPermission>
                        <button kendoButton class="mr-8 visibility-hidden" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil"></button>
                    </ng-template>
                <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-trash" (click)="deleteExpense(dataItem)"
                    *ngIf="checkDeletePermission(dataItem)"
                    [disabled]="(dataItem?.paidDate && (auth.isPermissionRoles(['Admin']) | async) === false) || dataItem?.InvoiceItems?.length > 0"></button>
            </ng-template>
            </kendo-grid-column>

        </kendo-grid>
    </kendo-card-body>
</kendo-card>