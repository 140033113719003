<section>
  <h4 mat-dialog-title>o365 Authentication Required</h4>
  <hr class="mt-3" />
  <div mat-dialog-content>
    <p>
      Launching this report will open the document directly in o365. When prompted, please enter the public token shown below to confirm your identity within this system.
    </p>
    <h5 class="mt-2">
      <mat-icon>lock</mat-icon>
      Your public token:
    </h5>
    <code [innerHTML]="user.msGraphToken"></code>
    <button mat-flat-button [cdkCopyToClipboard]="user.msGraphToken">
      <mat-icon>content_copy</mat-icon>
      Copy to Clipboard
    </button>
    <!-- Hidden iFrame to pass info into Office o365 addin for cookie -->
    <iframe [src]="cookieUrl | domSafe: 'resourceUrl'" width="1" height="1" class="hidden-iframe"></iframe>
  </div>
  <hr class="mt-3" />
  <div class="pb-2">
    <mat-checkbox>Don't show me again this session.</mat-checkbox>
    <button mat-raised-button color="primary" class="float-right" (click)="launchReport()">
      <mat-icon>check_circle</mat-icon>
      Open Report
    </button>
  </div>
</section>