import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { take, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IUserDetailResults } from 'src/app/shared/interfaces/user.interfaces';
import { userDetailConnectionQuery, addUserDetailMutation, removeUserDetailMutation, updateUserDetailMutation } from './user-detail.operations';
import {
  IApiUserDetailFilter,
  IApiAddUserDetailInput,
  IApiUpdateUserDetailInput,
  IApiRemoveUserDetailInput
} from 'src/app/shared/modules/graphql/types/types';
import { safeResolve } from '../../../rxjs';

@Injectable()
export class GqlUserDetailService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getUserDetail(filters: IApiUserDetailFilter[], options: IGqlBaseOptions): Observable<IUserDetailResults> {
    return this.apollo.query(connectionQuery(userDetailConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addUserDetail(input: IApiAddUserDetailInput) {
    return this.apollo.mutate({
      mutation: addUserDetailMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeUserDetail(input: IApiRemoveUserDetailInput) {
    return this.apollo.mutate({
      mutation: removeUserDetailMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateUserDetail(input: IApiUpdateUserDetailInput) {
    return this.apollo.mutate({
      mutation: updateUserDetailMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
