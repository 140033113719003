import { NotificationsService } from '../../../../shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { StateService } from '../../../../shared/services/state.service';
import { CertificationAgencyService, CertificationTypeService } from '../../../../shared/services';
import { IApiAddCertificationTypeInput, IApiCertificationAgency, IApiUpdateCertificationTypeInput, IApiCertificationType } from 'src/app/shared/modules/graphql/types/types';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { NgForm } from '@angular/forms';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';

@Component({
  selector: 'app-certification-create-modal',
  templateUrl: './certification-modal.component.html',
  styleUrls: ['./certification-modal.component.scss']
})
export class CertificationModalComponent extends DialogContentBase implements OnInit {
  @ViewChild('form', { static: false }) form: NgForm;
  @Input() public data: IApiCertificationType;
  public model: IApiUpdateCertificationTypeInput = {
    id: "",
    title: "",
    state: "",
    requiresRenewal: false,
    CertificationAgencyId: "",
    notes: "",
    isRestriction: false
  };
  public issuingAgencies: IApiCertificationAgency[];

  constructor(
    private dialogRef: DialogRef,
    private certificationAgencyService: CertificationAgencyService,
    private certificationTypes: CertificationTypeService,
    public stateService: StateService,
    private notifications: NotificationsService,
    private loaderService: LoaderService,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.loaderService.show$(this.certificationAgencyService
      .get())
      .pipe(unwrapConnection())
      .subscribe((res) => {
        this.issuingAgencies = res;
        const {
          id = null,
          title = "",
          state = "",
          requiresRenewal,
          CertificationAgency,
          notes = "",
          isRestriction = false,
        } = this.data || { requiresRenewal: null, CertificationAgency: { id: null } };

        this.model = {
          id,
          title,
          state,
          requiresRenewal,
          notes,
          isRestriction,
        };

        if (CertificationAgency?.id)
          this.model.CertificationAgencyId = CertificationAgency.id;
      });
  }

  public isRestrictionChanged(isRestriction: boolean) {
    if (isRestriction) {
      this.model = {
        ...this.model,
        CertificationAgencyId: null,
        requiresRenewal: null,
        state: "",
      }
    }
  }

  onSubmit() {
    if (this.form?.invalid) {
      this.form.form.markAllAsTouched();
      return;
    };
    this.model.title = this.model.title.trim();
    const { id = null } = this.data || {};
    if (id) this.save();
    else this.add();
  }

  public close() {
    this.dialogRef.close();
  }

  private save() {
    this.loaderService.show$(this.certificationTypes.update({
      id: this.data.id,
      ...this.model
     })).pipe(
      this.notifications.catchAlertPipe("Error updating certificate type"),
      this.notifications.snackbarPipe("Certificate type updated")
    ).subscribe((res) => this.dialogRef.close(res));
  }

  private add() {
    const createData = { ...this.model };
    delete createData.id;
    this.loaderService.show$(this.certificationTypes.add(createData as IApiAddCertificationTypeInput)).pipe(
      this.notifications.catchAlertPipe("Error adding certificate type"),
      this.notifications.snackbarPipe("Certificate type created")
    ).subscribe((res) => this.dialogRef.close(res));
  }
}
