import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  createdAt
  updatedAt
`;

export const ExpenseApplicationConnectionQuery = gql`${buildConnectionQuery("ExpenseApplication", "expenseApplicationConnection", nodeFragment)}`;

export const getExpenseApplicationByIdQuery = gql`query getExpenseApplicationById($id: String!) {
  getExpenseApplicationById(id: $id) { ${ nodeFragment } }
}`;

export const addExpenseApplicationMutation = gql`mutation AddExpenseApplication($input: AddExpenseApplicationInput!) {
  addExpenseApplication(input: $input) { ${ nodeFragment } }
}`;

export const updateExpenseApplicationMutation = gql`mutation UpdateExpenseApplication($input: UpdateExpenseApplicationInput!) {
  updateExpenseApplication(input: $input) { ${ nodeFragment } }
}`;

export const removeExpenseApplicationMutation = gql`mutation RemoveExpenseApplication($id: ID!) {
  removeExpenseApplication(id: $id)
}`;