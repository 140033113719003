import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { BranchService, CompanyService } from '../../../../shared/services';
import { IApiBranch, IApiCompany } from 'src/app/shared/modules/graphql/types/types';
import { MatDialog } from '@angular/material/dialog';
import { CompanyBranchCreateModalComponent } from '../company-branch-create-modal/company-branch-create-modal.component';
import { CompanyBranchContactSelectComponent, ICompanyBranchContactSelectData } from '../company-branch-contact-select/company-branch-contact-select.component';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { of, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';
import { SharedService } from 'src/app/common/shared.service';
import {
  companiesBranchesUpdate
} from "src/app/shared/helpers/auth-config/companies.config";

import {
  contactsCreateConfig
} from "src/app/shared/helpers/auth-config/contacts.config";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import _ from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-company-branch-information',
  templateUrl: './company-branch-information.component.html',
  styleUrls: ['./company-branch-information.component.scss']
})
export class CompanyBranchInformationComponent implements OnInit {
  public authConfig = {
    companiesBranchesUpdate,
    contactsCreateConfig
  };
  public toggleAddresses = false;
  public branch: IApiBranch;

  public company: IApiCompany;

  public contacts = [{
    fullName: 'Benny Thejet',
    role: 'Indpendent Adjuster',
    officePhone: '',
    officeEmail: '',
    primaryPhone: { type: 'Cell', number: '603-123-4567' },
    primaryEmail: { type: 'Personal', email: 'wingwongwish@theatomgroup.com' },
    startDate: '06/18/2016',
    endDate: ''
  }, {
    fullName: 'Benny Thejet2',
    role: 'Indpendent Adjuster',
    officePhone: '',
    officeEmail: '',
    primaryPhone: { type: 'Cell', number: '603-123-4567' },
    primaryEmail: { type: 'Personal', email: 'wingwongwish@theatomgroup.com' },
    startDate: '06/18/2016',
    endDate: ''
  }];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private branchService: BranchService,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private loader: LoaderService,
    public sharedService: SharedService,
    private dialogService: DialogService
  ) { }

  public ngOnInit() {
    this.activatedRoute.params.pipe(
      untilDestroyed(this),
      switchMap((params) => this.loadData(params.companyId, params.branchId))
    ).subscribe();

    this.sharedService.loadContactList().pipe(
      untilDestroyed(this),
      switchMap(() => this.loadData(this.company.id, this.branch.id))
    ).subscribe();
  }

  private loadData(companyId: string, branchId: string): Observable<any> {
    return this.loader.show$(forkJoin([
      this
        .branchService
        .getById(branchId)
        .pipe(
          take(1),
          tap((branch) => this.branch = branch)
        ),
      this.companyService.getById(companyId).pipe(
        tap((company) => this.company = company)
      )
    ]));
  }

  public getPrimary(list: any[]) {
    return list?.find((v) => v.isPrimary) || {};
  }

  public getOffice(list: any[]) {
    return list?.find((v) => {
      if (v.Type.name) return v.Type.name.toLowerCase() === "work" || v.Type.name.toLowerCase() === "office";
      else return {};
    });
  }

  public goBack() {
    const { companyId } = this.activatedRoute.snapshot.params;
    if (companyId) {
      return this.router.navigate([`/admin/companies/management/${companyId}`]);
    }
  }
  public editBranch() {
    const dialog: DialogRef = this.dialogService.open({
      content: CompanyBranchCreateModalComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });
    const dialogInstance = dialog.content.instance as CompanyBranchCreateModalComponent;
    dialogInstance.data = { branchId: this.branch.id };
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) ? false : !!v;
        }),
        switchMap(() => this.loadData(this.company.id, this.branch.id))
      )
      .subscribe();
  }

  public addContact() {
    const dialog: DialogRef = this.dialogService.open({
      content: CompanyBranchContactSelectComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });
    const data: ICompanyBranchContactSelectData = {
      company: this.company,
      branchId: this.branch.id
    };
    const dialogInstance = dialog.content.instance as CompanyBranchContactSelectComponent;
    dialogInstance.data = data;
    dialog.result
      .pipe(
        filter((v: DialogCloseResult) => {
          return _.isEmpty(v) ? false : !!v;
        }),
        switchMap(() => this.loadData(this.company.id, this.branch.id))
      )
      .subscribe();
  }
}
