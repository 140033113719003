import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "src/app/shared/shared.module";

import { EvidenceListComponent } from './evidence-list/evidence-list.component';
import { EvidenceManagementComponent } from './evidence-management/evidence-management.component';
import { EvidenceStorageLocationModalComponent } from './evidence-storage-location-modal/evidence-storage-location-modal.component';
import { EvidenceStorageLocationComponent } from './evidence-storage-location/evidence-storage-location.component';

@NgModule({
  declarations: [
    EvidenceListComponent,
    EvidenceManagementComponent,
    EvidenceStorageLocationComponent,
    EvidenceManagementComponent,
    EvidenceStorageLocationModalComponent,
    ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
  ]
})
export class EvidenceModule { }
