import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsInvestigator, IApiInvestigationMetricsInvestigatorConnection } from 'src/app/shared/modules/graphql/types/types';
import { InvestigationMetricsInvestigatorService } from './investigation-metrics-investigator.service';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class InvestigationMetricsInvestigatorDatasource extends NefcoPagedDataSource<IApiInvestigationMetricsInvestigator, InvestigationMetricsInvestigatorService, IApiInvestigationMetricsInvestigatorConnection> implements DataSource<IApiInvestigationMetricsInvestigator> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsInvestigatorConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
