import { IGqlBaseOptions } from './../../../modules/graphql/interfaces/base.interfaces';
import { IApiUpdateDocumentTemplateInput, IApiDocumentTemplate, IApiAddDocumentTemplateInput, IApiDocumentTemplateFilter, IApiDocumentTemplateConnection } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { GqlDocumentTemplateService } from '../../../modules/graphql/services';
import { Injectable } from '@angular/core';
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class DocumentTemplateService {

  constructor(
    private documentTemplates: GqlDocumentTemplateService,
  ) { }

  public get(filters?: IApiDocumentTemplateFilter[], options?: IGqlBaseOptions): Observable<IApiDocumentTemplateConnection> { 
    return this.documentTemplates.getTemplates(filters, options || new ListPage());
  }

  public add(typeInput: IApiAddDocumentTemplateInput): Observable<IApiDocumentTemplate> {
    return this.documentTemplates.addTemplate(typeInput);
  }

  public update(typeInput: IApiUpdateDocumentTemplateInput): Observable<IApiDocumentTemplate> {
    return this.documentTemplates.updateTemplate(typeInput);
  }

  public remove(typeId: string): Observable<boolean> {
    return this.documentTemplates.removeTemplate(typeId);
  }
}
