import { DataSource } from "@angular/cdk/collections";
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { IApiQuarterlyHourReport, IApiQuarterlyHourReportConnection, IApiQuarterlyUtilizationReportFilter } from "src/app/shared/modules/graphql/types/types";
import { QuarterlyHourReportService } from "./quarterly-hour-report.service";

export class QuarterlyHourReportDataSource extends NefcoPagedDataSource<IApiQuarterlyHourReport, QuarterlyHourReportService, IApiQuarterlyHourReportConnection> implements DataSource<IApiQuarterlyHourReport> {
  @ApiConnection()
  public load(filters: IApiQuarterlyUtilizationReportFilter[] = []): Promise<IApiQuarterlyHourReportConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
