<div class="report-page-filters" >
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="filterColumns">
    <!--START date range Filter -->
    <kendo-gridlayout-item [colSpan]="2" [col]="0" [row]="0">
      <div class="kendo-formfield date-range-wrapper">
        <kendo-daterange #dateRangePicker >
            <kendo-label text="Received Date (Start)" class="mr-16">
              <kendo-dateinput #startDateRange calendarType="classic" class="mr-16 dateinput" placeholder="Start Date"
              [fillMode]="'outline'" kendoDateRangeStartInput [(ngModel)]="range.startDate" (ngModelChange)="updateDateFilters($event)"
              (click)="focusDateInput(true)" ></kendo-dateinput>
            </kendo-label>
            <kendo-label text="Received Date (End)">
              <kendo-dateinput #endDateRange calendarType="classic" class="mr-0 dateinput" placeholder="End Date"
              [fillMode]="'outline'" kendoDateRangeEndInput [(ngModel)]="range.endDate" (ngModelChange)="updateDateFilters($event)"
              (click)="focusDateInput(false)"></kendo-dateinput>
            </kendo-label>
              <kendo-daterange-popup #dateRangePickerPopup autoCorrectOn="blur"></kendo-daterange-popup>
        </kendo-daterange>
      </div>
    </kendo-gridlayout-item>

    <!-- END date range Filter -->

    <!--START Company Filter -->
    <kendo-gridlayout-item>
      <div>
        <app-company-select-kendo
          label="Client (Company)"
          placeholder="Search Client (Company)"
          [includeInactive]="true"
          [selectedId]="selectedId"
          (selectionChanged)="setCompany($event)"
        ></app-company-select-kendo>
      </div>
    </kendo-gridlayout-item>
    <!-- END Company Filter -->

    <!--START Company Filter -->
    <kendo-gridlayout-item *ngIf="reportPage === 'investigationReport'">
      <div>
        <app-company-select-kendo
          label="Billing Rule (Company)"
          placeholder="Billing Rule (Company)"
          [includeInactive]="true"
          [selectedId]="selectedBillingRuleCompanyId"
          (selectionChanged)="setBillingRuleCompany($event)"
        ></app-company-select-kendo>
      </div>
    </kendo-gridlayout-item>
    <!-- END Company Filter -->

     <!-- START Evidence Status Filter -->
  <kendo-gridlayout-item *ngIf="reportPage === 'evidencereport'">
    <kendo-formfield class="kendo-formfield">
        <kendo-label [for]="dropdownlist" text="Evidence Status"></kendo-label>
        <kendo-dropdownlist #dropdownlist
          [data]="evidenceStatusArray"
          textField="name"
          valueField="name"
          fillMode="outline"
          [valuePrimitive]="true"
          [defaultItem]="{name: 'Select status' }"
          [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
          [(ngModel)]="selectedStatus"
          name="selectedStatus"
          (selectionChange)="setEvidenceStatus($event)">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem?.name === 'Select status'" class="placeholder"> {{ dataItem.name }} </span>
                <span *ngIf="dataItem?.name !== 'Select status'" class="not-placeholder"> {{ dataItem.name }} </span>
            </ng-template>
        </kendo-dropdownlist>
    </kendo-formfield>
  </kendo-gridlayout-item>
  <!-- END Evidence Status Filter -->


    <kendo-gridlayout-item class="filter-actions" [colSpan]="colSpan" [col]="0" [row]="0">
      <div class="applied-filter mr-16">
        <kendo-label text=""></kendo-label>
        <div>
          <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="filterTable()">Apply Filters</kendo-button>
        </div>
      </div>
      <div class="clear-filter mt-5">
        <kendo-label text=""></kendo-label>
        <div>
          <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearFilters()">Clear All</kendo-button>
        </div>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</div>
