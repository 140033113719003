import { Injectable } from '@angular/core';
import { IPortalTokenResponse } from '../../../components/portal/interfaces/portal.interfaces';

@Injectable()
export class LocalStorageService {
    constructor() { }

    private localName = "nefUser";

    public get user(): IPortalTokenResponse | null {
        return JSON.parse(localStorage.getItem(this.localName));
    }

    public savePortalUser(user: IPortalTokenResponse | Partial<IPortalTokenResponse>): void {
        if (!user) return this.removePortalUser();
        localStorage.setItem(this.localName, JSON.stringify(user));
    }

    public removePortalUser(): void {
        localStorage.removeItem(this.localName);
    }

    public getValue(key) {;
        return localStorage.getItem(key);
    }

    public getValueFromSession(key) {
        const val = sessionStorage.getItem(key);
        return val ? JSON.parse(val) : val;
    }

    public setValueFromSession(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    public removeValueFromSession(key) {
        sessionStorage.removeItem(key);
    }

    public removePortalEmail(): void {
        sessionStorage.removeItem('clientemail');
        sessionStorage.removeItem('formemail');
    }

}
