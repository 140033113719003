import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { timeEntryInvestigationHoursUpdateAll, expenseInvestigationMileageUpdateAll, expenseInvestigationExpensesDeleteAll, expenseInvestigationExpensesUpdateAll, expenseInvestigationMileageDeleteAll, timeEntryInvestigationHoursDeleteAll } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { IApiTimeEntry } from 'src/app/shared/modules/graphql/types/types';

@Component({
  selector: 'app-outstanding-admin',
  templateUrl: './outstanding-admin.component.html',
  styleUrls: ['./outstanding-admin.component.scss']
})
export class OutstandingAdminComponent implements OnChanges {

  public authConfig = {
    timeEntryInvestigationHoursUpdateAll,
    expenseInvestigationMileageUpdateAll,
    expenseInvestigationExpensesDeleteAll,
    expenseInvestigationExpensesUpdateAll,
    expenseInvestigationMileageDeleteAll,
    timeEntryInvestigationHoursDeleteAll
  };

  @ViewChild("timeEntryInvestigationHoursUpdateAll") timeEntryInvestigationHoursUpdateAllPermission;
  @ViewChild("timeEntryInvestigationHoursDeleteAll") timeEntryInvestigationHoursDeleteAllPermission;
  @ViewChild("expenseInvestigationMileageUpdateAll") expenseInvestigationMileageUpdateAllPermission;
  @ViewChild("expenseInvestigationMileageDeleteAll") expenseInvestigationMileageDeleteAllPermission;
  @ViewChild("expenseInvestigationExpensesDeleteAll") expenseInvestigationExpensesDeleteAllPermission;
  @ViewChild("expenseInvestigationExpensesUpdateAll") expenseInvestigationExpensesUpdateAllPermission;

  public _timeVisible: IApiTimeEntry[];
  @Input() set timeVisible(val) {
    this._timeVisible = val;
  }
  @Input() pageOptions;
  @Input() selectedTime;
  @Input() selectedExpenses;
  @Input() type: string = 'time';
  @Input() pageTitle: string = 'Hours';
  @Input() showDateEntered: boolean;
  @Input() clearSelection: boolean;
  @Output() sortChange = new EventEmitter<SortDescriptor[]>();
  @Output() pageChange = new EventEmitter<any>();
  @Output() removeEntry = new EventEmitter<IApiTimeEntry>();
  @Output() editEntry = new EventEmitter<IApiTimeEntry>();

  public sort: SortDescriptor[] = [{
    field: '',
    dir: 'desc'
  }];
  public kendoGridRowSelection: number[] = [];

  constructor() { }

  public pageUpdate(event) {
    this.pageChange.emit(event);
  }

  public sortField = (e: SortDescriptor[]) => {
    this.sort = e;
    this.sortChange.emit(e);
  }

  onExpenseCheckboxChange(e) {
    
    if (e?.selectedRows?.length) {
      
      e?.selectedRows?.forEach(obj => {
        if (this.type === 'time') {
          this.selectedTime.push(obj?.dataItem?.id);
        } else {
          this.selectedExpenses.push(obj?.dataItem?.id);
        }
      });
    }
    if (e?.deselectedRows?.length) {
      e?.deselectedRows?.forEach(obj => {
        if (this.type === 'time') {
          let index = this.selectedTime.findIndex(a => a === obj?.dataItem?.id);
          if (index !== -1) {
            this.selectedTime.splice(index, 1);
          }
        } else {
          let index = this.selectedExpenses.findIndex(a => a === obj?.dataItem?.id);
          if (index !== -1) {
            this.selectedExpenses.splice(index, 1);
          }
        }
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    const { clearSelection } = changes;
    if (clearSelection && clearSelection.currentValue) {
      this.kendoGridRowSelection = [];
    }
  }

  public deleteTime(item?: IApiTimeEntry) {
    this.removeEntry.emit(item);
  }

  public editTime(item?: IApiTimeEntry) {
    this.editEntry.emit(item);
  }

  // check permission for edit
  public checkEditPermission = (row) => {
    if (this.type === 'time') {
      if (this.timeEntryInvestigationHoursUpdateAllPermission?.enabled) return true;
      else return false;
    } else {
      if (row?.ExpenseItem?.name === 'Mileage') {
        if (this.expenseInvestigationMileageUpdateAllPermission?.enabled) return true;
        else return false;
      } else {
        if (this.expenseInvestigationExpensesUpdateAllPermission?.enabled) return true;
        else return false;
      }
    }
  }

  // check permission for delete
  public checkDeletePermission = (row) => {
    if (this.type === 'time') {
      if (this.timeEntryInvestigationHoursDeleteAllPermission?.enabled) return true;
      else return false;
    } else {
      if (row?.ExpenseItem?.name === 'Mileage') {
        if (this.expenseInvestigationMileageDeleteAllPermission?.enabled) return true;
        else return false;
      } else {
        if (this.expenseInvestigationExpensesDeleteAllPermission?.enabled) return true;
        else return false;
      }
    }
  }

}
