import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  details
  ExpensePurposes {
    id
    name
  }
  createdAt
  updatedAt
`;

export const ExpenseCategoryConnectionQuery = gql`${buildConnectionQuery("ExpenseCategory", "expenseCategoryConnection", nodeFragment)}`;

export const getExpenseCategoryByIdQuery = gql`query getExpenseCategoryById($id: String!) {
  getExpenseCategoryById(id: $id) { ${ nodeFragment } }
}`;

export const addExpenseCategoryMutation = gql`mutation AddExpenseCategory($input: AddExpenseCategoryInput!) {
  addExpenseCategory(input: $input) { ${ nodeFragment } }
}`;

export const updateExpenseCategoryMutation = gql`mutation UpdateExpenseCategory($input: UpdateExpenseCategoryInput!) {
  updateExpenseCategory(input: $input) { ${ nodeFragment } }
}`;

export const removeExpenseCategoryMutation = gql`mutation RemoveExpenseCategory($id: ID!) {
  removeExpenseCategory(id: $id)
}`;