<section class="clearfix" *ngIf="snapshot">
  <div class="slices" [ngSwitch]="snapshot.status" [ngClass]="{ 'rejected': !approved }">
      <!-- <img src="/assets/svg/circle-outline.svg" *ngSwitchCase="'not started'" /> -->
      <img src="/assets/svg/circle-slice-2.svg" *ngSwitchCase="'DRAFT'" />
      <img src="/assets/svg/circle-slice-4.svg" *ngSwitchCase="'TECH_REVIEW'" />
      <img src="/assets/svg/circle-slice-4.svg" *ngSwitchCase="'TECH_REVIEW_2'" />
      <img src="/assets/svg/circle-slice-4.svg" *ngSwitchCase="'READY_FOR_EDITOR'" />
      <img src="/assets/svg/circle-slice-6.svg" *ngSwitchCase="'EDITOR_REVIEW'" />
      <img src="/assets/svg/circle-slice-8.svg" *ngSwitchCase="'FINAL'" />
      <span *ngSwitchDefault></span>
  </div>
  <span>{{ InvestigationReportStatus[snapshot?.status] || "N/A" }}</span>
</section>
