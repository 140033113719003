<!-- Permissions -->
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseCreate" #expenseUnlinkedExpenseCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesCreate" #expenseInvestigationExpensesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageCreate" #expenseInvestigationMileageCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageCreate" #expenseUnlinkedMileageCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageCreate" #expenseUnlinkedMileageCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageUpdate" #expenseUnlinkedMileageUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageUpdateOwn" #expenseUnlinkedMileageUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageUpdate" #expenseInvestigationMileageUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageUpdateOwn" #expenseInvestigationMileageUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesUpdate" #expenseInvestigationExpensesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesUpdateOwn" #expenseInvestigationExpensesUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseUpdate" #expenseUnlinkedExpenseUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedExpenseUpdateOwn" #expenseUnlinkedExpenseUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageDelete" #expenseUnlinkedMileageDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkedMileageDeleteOwn" #expenseUnlinkedMileageDeleteOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageDelete" #expenseInvestigationMileageDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageDeleteOwn" #expenseInvestigationMileageDeleteOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesDelete" #expenseInvestigationExpensesDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesDeleteOwn" #expenseInvestigationExpensesDeleteOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkeExpenseDelete" #expenseUnlinkeExpenseDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseUnlinkeExpenseDeleteOwn" #expenseUnlinkeExpenseDeleteOwn="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <kendo-gridlayout [rows]="['auto']" [cols]="['repeat(2,minmax(0,1fr))']">
    <kendo-gridlayout-item class="grid-item" *ngIf="expenseUnlinkedExpenseCreate?.enabled || expenseInvestigationExpensesCreate?.enabled">
      <kendo-card width="100%">
        <kendo-card-header>
          <h3 class="kendo-h3">Add Expense</h3>
        </kendo-card-header>
        <kendo-card-body>
          <app-ind-expense-form-kendo [expense]="expenseForm" [userId]="userId" [expenseItems]="expenseItems" [investigations]="investigations" (formSubmitted)="addExpense($event)">
          </app-ind-expense-form-kendo>
        </kendo-card-body>
      </kendo-card>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="grid-item" *ngIf="expenseUnlinkedMileageCreate?.enabled || expenseInvestigationMileageCreate?.enabled">
      <kendo-card width="100%">
        <kendo-card-header>
          <h3 class="kendo-h3">Add Mileage</h3>
        </kendo-card-header>
        <kendo-card-body>
          <app-ind-mileage-form-kendo [userId]="userId" [mileage]="mileageForm" [investigations]="investigations" (formSubmitted)="addExpense($event, true)"></app-ind-mileage-form-kendo>
        </kendo-card-body>
      </kendo-card>
    </kendo-gridlayout-item>
  </kendo-gridlayout>

  <app-time-exp-date-kendo-filter [mode]="'month'" [getRange]="true" (WeekMonthRangeChange)="dateChange($event)"
    (dateRangeChange)="dateRangeChange($event)" (investigationChange)="investigationChanged($event)"
    [investigations]="investigations">
  </app-time-exp-date-kendo-filter>

  <hr kendoCardSeparator />

  <div class="pl-24 pr-24">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" class="expense-counter-wrapper" [cols]="['repeat(3,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0">
      <div class="expense-div">
        <div>
          <h4>Total Expenses</h4>
          <p>${{expenseMileageCount?.totalExpense || 0}}</p>
        </div>
        <div>
          <h4>Billable Expenses</h4>
          <p>${{expenseMileageCount?.billableExpense || 0}}</p>
        </div>
        <div>
          <h4>Non-Billable Expenses</h4>
          <p>${{expenseMileageCount?.nonBillableExpense || 0}}</p>
        </div>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="mb-0"><div class="expense-div">
        <div>
          <h4>Total Mileage</h4>
          <p>{{expenseMileageCount?.totalMileage || 0}} mi</p>
        </div>
        <div>
          <h4>Billable Mileage</h4>
          <p>{{expenseMileageCount?.billableMileage || 0}} mi</p>
        </div>
        <div>
          <h4>Non-Billable Mileage</h4>
          <p>{{expenseMileageCount?.nonBillableMileage || 0}} mi</p>
        </div>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="mb-0"></kendo-gridlayout-item>
  </kendo-gridlayout>

  <kendo-grid
    class="expense-list"
    [data]="{
      data: expenses,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageChange($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
  >
      <kendo-grid-column
        field="CREATED_AT"
        title="Entry Date"
        [width]="10"
        [sortable]="true"
        class="font-weight600"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.createdAt | customDate : "MM/dd/yyyy" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="INVESTIGATION"
        title="Investigation"
        [width]="10"
        class="id-column"
        [sortable]="true"
        class="font-weight600"
        [headerClass]="'sort-header'"
      >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          target="_blank"
          [routerLink]="['/investigations/', dataItem?.Investigation?.id]"
          tel=""
          class="text-decoration"
          *ngIf="dataItem?.Investigation else Unlinked"
        >
          {{ dataItem?.Investigation?.nefcoNumber || "Unlinked" }}
        </a>
        <ng-template #Unlinked>
          <span>Unlinked</span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Expense"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem?.ExpenseItem?.name || '-'}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Purpose"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem?.ExpensePurpose?.name || '-'}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Cost/Miles"
      [width]="9"
      [sortable]="false"
      class="text-right"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name !== 'Mileage'">${{(dataItem?.billableQuantity + dataItem?.nonBillableQuantity) | number: '1.2-2'}}</span>
        <span class="mr-12" *ngIf="dataItem?.ExpenseItem?.name === 'Mileage'">{{(dataItem?.billableQuantity + + dataItem?.nonBillableQuantity) }} mi</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Type"
      [width]="10"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.ExpenseItem?.name !== 'Mileage'">{{dataItem?.outOfPocket === 1 ? 'Out of Pocket' : (dataItem?.outOfPocket === 2 ? 'Vendor/Other':'NEFCO Card')}}</span>
        <span *ngIf="dataItem?.ExpenseItem?.name === 'Mileage'">{{dataItem?.nefcoVehicle === 1 ? 'NEFCO Vehicle' : (dataItem?.nefcoVehicle === 2 ? 'Vendor/Other':'My Vehicle')}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Receipt"
      [width]="7"
      [sortable]="false"
      class="text-center"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.Document?.id"><i class="fa-solid fa-receipt"></i></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Authorized"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="text-overflow" *ngIf="dataItem?.authorizedBy">{{dataItem?.authorizedBy}} <br /></span>
        <span *ngIf="dataItem?.authorizedDate" style="color: rgba(1, 24, 98, 0.6)">on {{dataItem?.authorizedDate | customDate : 'MM/dd/yyyy'}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="EXPENSE_DATE"
        title="Expense Date"
        [width]="10"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.expenseDate | customDate : "MM/dd/yyyy" }}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-center pr-15"
      field="PAID_DATE"
      title="Paid"
      [width]="7"
      [sortable]="true"
      [headerClass]="'sort-header text-center pl-0'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <i *ngIf=" dataItem?.paidDate" class="right-circle-icon fa-solid fa-circle-check"></i>
        <i *ngIf="!dataItem?.paidDate" class="minus-circle-icon fa-solid fa-circle-minus"></i>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
    field="id"
    title="Actions"
    [width]="10"
    [sortable]="false"
    [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil"
          [disabled]="(dataItem?.paidDate && (auth.isPermissionRoles(['Admin']) | async) === false) || dataItem?.InvoiceItems?.length > 0"
          (click)="editExpense(dataItem);" *ngIf="checkEditPermission(dataItem); else noPermission"></button>
          <ng-template #noPermission>
            <button kendoButton class="mr-8 visibility-hidden" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil"></button>
          </ng-template>
        <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-trash"
          [disabled]="(dataItem?.paidDate && (auth.isPermissionRoles(['Admin']) | async) === false) || dataItem?.InvoiceItems?.length > 0"
          (click)="deleteExpense(dataItem)" *ngIf="checkDeletePermission(dataItem)"></button>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid>
  </div>
</section>