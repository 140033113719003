import { Directive, Output, EventEmitter, Input, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective implements AfterContentInit {
  @Output() appClickOutside = new EventEmitter<void>();
  @Input() dialogRef = null;

  ngAfterContentInit(): void {
    if (this.dialogRef?.wrapper?.nativeElement?.querySelector('.k-overlay')) {
      const clickFn = () => {
        this.dialogRef?.wrapper?.nativeElement?.querySelector('.k-overlay')?.removeEventListener('click', clickFn);
        this.appClickOutside.emit();
      }
      this.dialogRef?.wrapper?.nativeElement?.querySelector('.k-overlay')?.addEventListener('click', clickFn);
    } else {
      const clickWrapperFn = () => {
        this.dialogRef?.dialog?.location?.nativeElement?.querySelector('.k-overlay')?.removeEventListener('click', clickWrapperFn);
        this.appClickOutside.emit();
      }
      this.dialogRef?.dialog?.location?.nativeElement?.querySelector('.k-overlay')?.addEventListener('click', clickWrapperFn);
    }
  }
}
