import { Component, Input } from '@angular/core';
import { IApiComment } from '../../modules/graphql/types/types';

@Component({
  selector: 'app-comment-log-table',
  templateUrl: './comment-log-table.component.html',
  styleUrls: ['./comment-log-table.component.scss']
})
export class CommentLogTableComponent {

  @Input() comments: IApiComment[];

  constructor() { }

}
