<div [appEnforcePermission]="authConfig.investigationScheduledDateUpdateOwn"
  #investigationScheduledDateUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationScheduledDateUpdateAll"
  #investigationScheduledDateUpdateAll="appEnforcePermission"></div>
<kendo-drawer #drawerScheduleDate [width]="-1" [mini]="false" [mode]="'overlay'" [position]="'end'" [animation]="true">
  <ng-template kendoDrawerTemplate>
    <section class="content-part safe-area-padding-top">
      <div class="header mb-16">
        <span>
          <h6>Schedule On Scene Exam Date</h6>
          <p> Investigation ID: {{investigation?.nefcoNumber}}</p>
        </span>
        <button class="close-action" kendoButton themeColor="light" fillMode="solid"
          [iconClass]="drawerScheduleDate.expanded && 'fa-solid fa-times'" (click)="close()"></button>
      </div>
      <hr class="divider-line mb-16" />
      <kendo-stepper [(currentStep)]="currentStep" (activate)="noAction($event)" (currentStepChange)="noAction($event)"
        stepType="full" [steps]="steps" [linear]="false">
      </kendo-stepper>
      <section class="contact-info-wrapper mt-16 mb-24"
        *ngIf="investigation?.InsuredContacted?.id && currentStep === 1">
        <div class="mb-0 p-16 pr-0">
          <h6>Staff Member</h6>
          <p class="staff-name">{{ investigation?.InsuredContacted?.User | fullName : false }}</p>
        </div>
        <div class="mb-0 p-16">
          <h6>Contact Date & Time</h6>
          <p class="date-time">
            {{ investigation?.InsuredContacted?.date | dateWithTimezone : "MM-DD-YYYY" : investigation?.timezone }} at
            {{ investigation?.InsuredContacted?.date | dateWithTimezone : 'hh:mm:ss A' : investigation?.timezone }}
          </p>
        </div>
      </section>
      <section class="contact-info-wrapper-insured mt-16 mb-24"
        *ngIf="!investigation?.InsuredContacted?.id && currentStep === 0">
        <form #insuredFrm="ngForm" autocomplete="off">
          <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" class="p-16"
            [cols]="['repeat(1,minmax(0,1fr))'] ">
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label class="asterisk" text="Staff Member"></kendo-label>
                <kendo-dropdownlist [name]="'staffMemberSelect'" #staffMemberSelect="ngModel" [data]="staffList"
                  textField="userName" valueField="userId" fillMode="outline"
                  [disabled]="!investigationScheduledDateUpdateAll?.enabled"
                  [defaultItem]="{userName:  'Select staff member', userId: null}" [valuePrimitive]="true"
                  [(ngModel)]="model.staff" [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250
              }" required>
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="dataItem?.userName === 'Select staff member'" class="placeholder">
                      {{ dataItem?.userName }}
                    </span>
                    <span *ngIf="dataItem?.userName !== 'Select staff member'" class="not-placeholder">
                      {{ dataItem?.userName }}
                    </span>
                  </ng-template>
                </kendo-dropdownlist>
                <kendo-formerror
                  *ngIf="!staffMemberSelect?.valid && (staffMemberSelect?.dirty || staffMemberSelect?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="staffMemberSelect?.errors?.required">
                    Staff member cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item>
              <kendo-formfield>
                <kendo-label class="asterisk" text="Contact Date"></kendo-label>
                <kendo-datetimepicker calendarType="classic" [fillMode]="'outline'" name="contactedDate"
                  #contactedDate="ngModel" [(ngModel)]="model.contactedDate" placeholder="Enter contact date"
                  format="MM-dd-yyyy hh:mm a" formatPlaceholder="formatPattern" [max]="currentDateTime" [min]="receivedDate"
                  required></kendo-datetimepicker>
                <kendo-formerror>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span
                    *ngIf="!contactedDate?.valid && contactedDate.errors.required && (contactedDate?.dirty || contactedDate?.touched)">Date
                    cannot be
                    left
                    blank</span>
                  <span
                    *ngIf="!contactedDate?.valid && contactedDate.errors.maxError && (contactedDate?.dirty || contactedDate?.touched)">Please
                    select a date that is not in the future.</span>
                  <span
                    *ngIf="!contactedDate?.valid && contactedDate.errors.minError && (contactedDate?.dirty || contactedDate?.touched)">Please
                    select a date that is not before received date.</span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout class="pl-16 pr-16 text-center" [rows]="['auto']" [gap]="{rows: 16, cols: 16}"
              [cols]="['repeat(1,minmax(0,1fr))']"
              style="position: absolute; left:0; right:0; bottom: 0; z-index: 1; background: white;">
              <hr class="divider-line" />
              <kendo-gridlayout-item class="mb-48">
                <kendo-button kendoButton themeColor="primary" [disabled]="!insuredFrm?.valid" type="submit"
                  fillMode="solid" size="large" style="width: 100%;" (click)="insuredSubmit()">
                  Next
                </kendo-button>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-gridlayout>
        </form>
      </section>
      <p *ngIf="currentStep === 1" class="info">Enter the scheduled date and time of when you will be arriving on scene
        to the investigation.</p>
      <form #frm="ngForm" autocomplete="off" *ngIf="currentStep === 1">
        <section>
          <kendo-gridlayout *ngIf="scheduledFormEntry?.length" [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }"
            [cols]="['repeat(1,minmax(0,1fr))'] ">
            <ng-container
              *ngFor="let item of scheduledFormEntry; let index = index; let first = first; let last = last trackBy: trackByIndex">
              <kendo-gridlayout-item *ngIf="investigationScheduledDateUpdateAll?.enabled">
                <kendo-formfield>
                  <kendo-label class="asterisk" text="Staff Member"></kendo-label>
                  <kendo-dropdownlist [name]="'staffMemberSelect_' + index" #staffMemberSelect="ngModel"
                    [data]="staffList" textField="userName" valueField="userId" fillMode="outline"
                    [defaultItem]="{userName:  'Select staff member', userId: null}" [valuePrimitive]="true"
                    [(ngModel)]="scheduledFormEntry[index].UserId" [popupSettings]="{
                    appendTo: 'component',
                    animate: false,
                    width: 250
                  }" required>
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span *ngIf="dataItem?.userName === 'Select staff member'" class="placeholder">
                        {{ dataItem?.userName }}
                      </span>
                      <span *ngIf="dataItem?.userName !== 'Select staff member'" class="not-placeholder">
                        {{ dataItem?.userName }}
                      </span>
                    </ng-template>
                  </kendo-dropdownlist>
                  <kendo-formerror
                    *ngIf="!staffMemberSelect?.valid && (staffMemberSelect?.dirty || staffMemberSelect?.touched)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="staffMemberSelect?.errors?.required">
                      Staff member cannot be left blank
                    </span>
                  </kendo-formerror>
                </kendo-formfield>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item>
                <kendo-formfield>
                  <kendo-label class="asterisk" text="Scheduled Date"></kendo-label>
                  <kendo-datepicker [min]="currentDate" calendarType="classic" [fillMode]="'outline'"
                    [name]="'date_' + index" #scheduledDate="ngModel"
                    [(ngModel)]="scheduledFormEntry[index].scheduledDate" placeholder="Enter date" format="MM/dd/yyyy"
                    required="true" formatPlaceholder="formatPattern"></kendo-datepicker>
                  <kendo-formerror>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span
                      *ngIf="!scheduledDate?.valid && (scheduledDate?.dirty || scheduledDate?.touched) && scheduledDate.errors.required">
                      Date cannot
                      be
                      left
                      blank</span>
                    <span
                      *ngIf="!scheduledDate?.valid && (scheduledDate?.dirty || scheduledDate?.touched) && scheduledDate.errors.minError">
                      Date should not be in the past</span>
                  </kendo-formerror>
                </kendo-formfield>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item>
                <kendo-formfield>
                  <kendo-label class="asterisk" text="Scheduled Time"></kendo-label>
                  <kendo-timepicker required="true" format="hh:mm a" [name]="'time_' + index" #scheduledTime="ngModel"
                    [(ngModel)]="scheduledFormEntry[index].scheduledTime" placeholder="Enter time"></kendo-timepicker>
                  <kendo-formhint>(ie, HH:MM)</kendo-formhint>
                  <kendo-formerror>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span
                      *ngIf="!scheduledTime?.valid && (scheduledTime?.dirty || scheduledTime?.touched) && scheduledTime.errors.required">Time
                      cannot
                      be
                      left blank</span>
                  </kendo-formerror>
                </kendo-formfield>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item *ngIf="!scheduledFormEntry[index]?.id && index !== 0">
                <kendo-formfield>
                  <div class="k-checkbox-wrap" style="align-items: flex-start;">
                    <input [(ngModel)]="scheduledFormEntry[index].notifyClient" type="checkbox" kendoCheckBox />
                    <kendo-label class="ml-12 mr-24 k-checkbox-filter-label"
                      text="Do you want to notify the client of this additional scene investigation date?"></kendo-label>
                  </div>
                </kendo-formfield>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item>
                <button kendoButton class="trash-btn" iconClass="fas fa-trash" fillMode="link"
                  (click)="removeScheduledEntry(index)" themeColor="primary">Remove</button>
              </kendo-gridlayout-item>
              <hr class="divider-line mb-16" />
            </ng-container>
          </kendo-gridlayout>
          <button kendoButton class="mr-24 mb-24" iconClass="fa-solid fa-circle-plus" themeColor="primary"
            fillMode="link" (click)="addScheduledEntry()">Add Another Scene Investigation
            Date</button>
          <hr class="divider-line" style="margin-bottom: 110px;" />
          <kendo-gridlayout class="pl-16 pr-16 text-center" [rows]="['auto']" [gap]="{rows: 16, cols: 16}"
            [cols]="['repeat(1,minmax(0,1fr))']"
            style="position: absolute; left:0; right:0; bottom: 0; z-index: 1; background: white;">
            <hr class="divider-line" />
            <kendo-gridlayout-item class="mb-48">
              <kendo-button kendoButton themeColor="primary" [disabled]="!frm?.valid" type="submit" fillMode="solid"
                size="large" style="width: 100%;" (click)="submit()">
                Save Edits
              </kendo-button>
            </kendo-gridlayout-item>
          </kendo-gridlayout>
        </section>
      </form>
    </section>
  </ng-template>
</kendo-drawer>
