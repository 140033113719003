import { DocumentShareService } from './document-share.service';
import { ApiConnection } from '../../../decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiDocumentShare, IApiDocumentShareFilter, IApiPermissionRole } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class DocumentShareDataSource extends NefcoPagedDataSource<IApiDocumentShare, DocumentShareService> implements DataSource<IApiDocumentShare> {
  @ApiConnection()
  public load(filters?: IApiDocumentShareFilter[]): Promise<any> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
