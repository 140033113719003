<div [dialogRef]="dialog" (appClickOutside)="close()">
<kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
        <span>Mark as Sent</span>
        <div class="modal-subtitle" >
            #{{data?.investigation?.nefcoNumber}}
        </div>
    </div>
  </kendo-dialog-titlebar>
  <section class="section-content">
    <form #form="ngForm" name="form">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">

        <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="asterisk" text="Client (Contact)"></kendo-label>
              <kendo-multiselect
                #clientContactmultiselect="ngModel"
                [autoClose]="false"
                [data]="groupedDataClientContactList"
                [rounded]="'full'"
                [fillMode]="'outline'"
                [(ngModel)]="selectedContact"
                name="selectedContact"
                [placeholder]="'Select client'"
                textField="text"
                [tagMapper]="tagMapper"
                (ngModelChange)="valueChange($event)"
                class="border-radius"
                valueField="id"
                required>
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                  <input type="checkbox" kendoCheckBox [checked]="selectedContactIds.includes(dataItem?.id)" />
                  <div class="pl-12">
                    <span class="">{{ dataItem?.text }}</span>
                    <span *ngIf="dataItem?.companyName" class="company-name"><br/>{{ dataItem?.companyName }}</span>
                  </div>
                </ng-template>
              </kendo-multiselect>
            <kendo-formerror *ngIf="!clientContactmultiselect?.valid && (clientContactmultiselect?.dirty || clientContactmultiselect?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="clientContactmultiselect?.errors?.required">
                Client (Contact) cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
            <kendo-formfield>
                <kendo-label class="asterisk" text="Send Date"></kendo-label>
                <kendo-datepicker
                    calendarType="classic"
                    [disabledDates]="disabledDates"
                    [fillMode]="'outline'"
                    placeholder="Enter date"
                    format="MM/dd/yyyy"
                    [(ngModel)]="sendDate"
                    name="sendDate"
                    #senddate="ngModel"
                    formatPlaceholder="formatPattern"
                    required
                ></kendo-datepicker>
                <kendo-formerror>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="senddate?.errors?.required">Send Date cannot be left blank</span>
                    <span *ngIf="senddate?.errors?.disabledDate">Send Date cannot be future</span>
                </kendo-formerror>
            </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
            <div class="{{'special-inst' + (openInstructions ? ' open-inst' : '')}}">
                <i class="fa-solid fa-caret-down cursor" *ngIf="!openInstructions" (click)="toggleInst()"></i>
                <i class="fa-solid fa-caret-up cursor" *ngIf="openInstructions" (click)="toggleInst()"></i>
                <span class="ml-8">Special Billing Instructions</span>
            </div>
            <div class="special-inst special-desc" *ngIf="openInstructions">
                {{data?.investigation?.Detail?.specialInstructions}}
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
            <kendo-formfield>
                <kendo-label class="" text="Send Note"></kendo-label>
                <kendo-textarea
                    [(ngModel)]="sendNote"
                    name="sendNote"
                    placeholder="Enter note"
                    fillMode="outline"
                    class="minheight180"
                    rows="7"
                    maxlength="500"
                    resizable="both">
                </kendo-textarea>
            </kendo-formfield>
        </kendo-gridlayout-item>

      </kendo-gridlayout>
    </form>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="markSent()">
        Mark as Sent
    </button>
  </kendo-dialog-actions>
</div>