<p class="d-flex">
  <a routerLink="/">Dashboard</a>
  <ng-container *ngIf="investigationId">
    <i class="fa-solid fa-arrow-right"></i>
    <a [routerLink]="['/investigations', investigationId]">Investigation Detail</a>
  </ng-container>
  <ng-container *ngIf="investigationId">
    <i class="fa-solid fa-arrow-right"></i>
    Edit Investigation
  </ng-container>
</p>
