<div [appEnforcePermission]="authConfig.investigationInformationListView"
  #investigationInformationListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationLinkedInvestigationView"
  #investigationLinkedInvestigationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationPartiesListView"
  #investigationPartiesListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationAssignStaffListView"
  #investigationAssignStaffListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationRequestsCreate"
  #investigationRequestsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationScheduledDateView"
  #investigationScheduledDateView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardInvestigatorsOnSceneUpdate"
  #dashboardInvestigatorsOnSceneUpdate="appEnforcePermission"></div>


<kendo-drawer #drawer [width]="-1" [mini]="false" [mode]="'overlay'" [position]="'end'" [animation]="true">
  <ng-template kendoDrawerTemplate>
    <section class="content-part safe-area-padding-top">
      <div class="header mb-16">
        <button class="back-action" kendoButton themeColor="base" fillMode="link"
          [iconClass]="drawer.expanded && 'fa-solid fa-arrow-left-long fa-lg'" (click)="close()"></button>
        <h6 class="mb-0">ID: {{investigation?.nefcoNumber}}</h6>
        <kendo-dropdownbutton class="close-action" [data]="actionItems | showMobileChangeStatus: investigation"
          themeColor="light" fillMode="solid" buttonClass="close-action-btn" (itemClick)="actionSelected($event?.text)"
          [popupSettings]="{
          appendTo: 'root',
          animate: false,
          width: 250,
          popupClass: 'grid-action-items-menu mt-12'
      }" [iconClass]="'fa-solid fa-ellipsis fa-lg'">
        </kendo-dropdownbutton>
      </div>
    </section>
    <hr class="divider-line" />
    <section class="detail-wrapper">
      <!-- Scene Exam Date Panel bar -->
      <kendo-panelbar class="p-16" [expandMode]="2" *ngIf="dashboardInvestigatorsOnSceneUpdate?.enabled && investigation?.NearOnScene">
        <kendo-panelbar-item [expanded]="true">
          <ng-template kendoPanelBarItemTitle>
            <h6 class="panelbar-title mb-0">Scene Exam Date</h6>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}">
              <kendo-card-body>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption"><small> Scene Exam Date</small></h6>
                    <h6 class="kendo-caption-body">
                      {{investigation?.NearOnScene?.scheduledDate | dateWithTimezone : "MM/DD/YYYY" :
                      investigation?.timezone}} at
                      {{investigation?.NearOnScene?.scheduledDate | dateWithTimezone : "hh:mm a" :
                      investigation?.timezone}}
                    </h6>
                  </div>
                  <div class="equal mb-0">
                    <h6 class="kendo-caption"><small>Status</small></h6>
                    <h6 class="kendo-caption-body">
                      <kendo-badge *ngIf="investigation?.NearOnScene else noValue" [ngClass]="(investigation?.NearOnScene?.status === investigationOnSceneStatus.EN_ROUTE ||
                      investigation?.NearOnScene?.status === investigationOnSceneStatus.OFF_SCENE) ? 'fw600 fcolor' : 'fw600'
                      " position="none" size="medium" rounded="full" themeColor="{{
                      investigation?.NearOnScene?.status === investigationOnSceneStatus.SCHEDULED
                          ? 'primary'
                          : investigation?.NearOnScene?.status === investigationOnSceneStatus.EN_ROUTE
                          ? 'warning'
                          : investigation?.NearOnScene?.status === investigationOnSceneStatus.ON_SCENE
                          ? 'success'
                          : investigation?.NearOnScene?.status === investigationOnSceneStatus.OFF_SCENE
                          ? 'tertiary'
                          : ''
                      }}">{{investigation?.NearOnScene?.status}}</kendo-badge>
                      <ng-template #noValue>-</ng-template>

                    </h6>
                  </div>
                </div>
              </kendo-card-body>
            </kendo-card>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
      <!-- Investigation Information panelbar -->
      <kendo-panelbar class="p-16" [expandMode]="2" *ngIf="investigationInformationListView?.enabled">
        <kendo-panelbar-item [expanded]="true">
          <ng-template kendoPanelBarItemTitle>
            <h6 class="panelbar-title mb-0">Investigation Information</h6>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <!-- Client Card -->
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}">
              <kendo-card-header>
                Client
              </kendo-card-header>
              <kendo-card-body>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Contact</small></h6>
                    <h6 class="kendo-caption-body">
                      {{(investigation?.Client | fullName) || '-'}}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Role</small></h6>
                    <h6 class="kendo-caption-body">
                      {{investigation.Client?.Role?.name || '-'}}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Phone</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.Client?.Phones?.length; else noValue">
                        <app-email-phone-view *ngFor="let clientPhone of investigation?.Client?.Phones"
                          [phone]="clientPhone"></app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Email</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.Client?.Emails?.length; else noValue">
                        <app-email-phone-view [copyEmail]="true"
                          *ngFor="let clientEmail of investigation?.Client?.Emails"
                          [email]="clientEmail"></app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Additional Information</small></h6>
                    <h6 class="kendo-caption-body white-space-normal">
                      {{investigation.Client?.additionalInformation || '-'}}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Company</small></h6>
                    <h6 class="kendo-caption-body">
                      {{ investigation?.ClientBranch?.Company?.name || '-' }}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Branch</small></h6>
                    <h6 class="kendo-caption-body">
                      {{
                      investigation?.ClientBranch
                      ? investigation?.ClientBranch?.name
                      : "-"
                      }}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Address</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.ClientBranch; else ClientAddress">
                        <app-address-view *ngFor="
                          let address of investigation?.ClientBranch?.Addresses
                        " [address]="address"></app-address-view>
                      </ng-container>
                      <ng-template #ClientAddress>
                        <app-address-view *ngFor="let address of investigation.Client?.Addresses"
                          [address]="address"></app-address-view>
                      </ng-template>
                    </h6>
                  </div>
                </div>
                <ng-template #noValue>-</ng-template>
              </kendo-card-body>
            </kendo-card>
            <!-- Bill To Card -->
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}">
              <kendo-card-header>
                Bill To
              </kendo-card-header>
              <kendo-card-body>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Contact</small></h6>
                    <h6 class="kendo-caption-body">
                      {{(investigation?.BillTo | fullName) || '-'}}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Role</small></h6>
                    <h6 class="kendo-caption-body">
                      {{investigation.BillTo?.Role?.name || '-'}}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Phone</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.BillTo?.Phones?.length; else noValue">
                        <app-email-phone-view *ngFor="let billToPhone of investigation?.BillTo?.Phones"
                          [phone]="billToPhone"></app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Email</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.BillTo?.Emails?.length; else noValue">
                        <app-email-phone-view *ngFor="let billToEmail of investigation?.BillTo?.Emails"
                          [email]="billToEmail"></app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Additional Information</small></h6>
                    <h6 class="kendo-caption-body white-space-normal">
                      {{investigation.BillTo?.additionalInformation || '-'}}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Company</small></h6>
                    <h6 class="kendo-caption-body">
                      {{ investigation?.BillToBranch?.Company?.name || '-' }}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Branch</small></h6>
                    <h6 class="kendo-caption-body">
                      {{
                      investigation?.BillToBranch
                      ? investigation?.BillToBranch?.name
                      : "-"
                      }}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Address</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="!investigation?.BillToBranch; else billToBranchInfo">
                        <app-address-view *ngIf="investigation?.BillTo?.BillingAddress"
                          [address]="investigation?.BillTo?.BillingAddress"></app-address-view>
                      </ng-container>
                      <ng-template #billToBranchInfo>
                        <app-address-view *ngIf="investigation?.BillToBranch?.BillingAddress"
                          [address]="investigation?.BillToBranch?.BillingAddress"></app-address-view>
                      </ng-template>
                    </h6>
                  </div>
                  <hr />
                  <h5 class="card-title mb-24">Billing Rules</h5>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Company</small></h6>
                    <h6 class="kendo-caption-body">
                      {{ investigation.Company?.name || '-' }}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Address</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngFor="let branch of investigation.Company?.Branches">
                        <app-address-view *ngIf="branch?.isPrimary; else noValue"
                          [address]="branch?.Addresses | primaryAssociation"></app-address-view>
                      </ng-container>
                    </h6>
                  </div>
                </div>
              </kendo-card-body>
            </kendo-card>
            <!-- Loss Address Card -->
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}">
              <kendo-card-header>
                Loss Address
              </kendo-card-header>
              <kendo-card-body>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Address</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.LossAddress; else noValue">
                        <app-address-view [address]="investigation.LossAddress"></app-address-view>
                        <button kendoButton class="view-map" themeColor="primary" fillMode="link"
                          (click)="openMap(investigation.LossAddress)">View Map</button>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Timezone</small></h6>
                    <h6 class="kendo-caption-body">
                      {{ (investigation?.timezone | timezonePipe)|| '-' }}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Contact</small></h6>
                    <h6 class="kendo-caption-body">
                      {{(investigation?.SceneContact | fullName) || '-'}}
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Phone</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.SceneContact?.Phones?.length else noValue">
                        <app-email-phone-view *ngFor="
                            let scenePhone of investigation.SceneContact?.Phones
                          " [phone]="scenePhone"></app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Email</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.SceneContact?.Emails?.length else noValue">
                        <app-email-phone-view *ngFor="
                            let sceneEmail of investigation?.SceneContact?.Emails
                          " [phone]="sceneEmail"></app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                </div>
              </kendo-card-body>
            </kendo-card>
            <!-- Loss Details Card -->
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}">
              <kendo-card-header>
                Loss Details
              </kendo-card-header>
              <kendo-card-body>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Loss Date</small></h6>
                    <h6 class="kendo-caption-body">{{ investigation?.lossDate | customDate : "MMMM d, y": true }}</h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Risk Type</small></h6>
                    <h6 class="kendo-caption-body" [innerHTML]="investigation?.RiskType?.name"></h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Loss time</small></h6>
                    <h6 class="kendo-caption-body">{{ investigation?.lossDate | customDate : "h:mm:ss a" : true }}</h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Claim Number</small></h6>
                    <h6 class="kendo-caption-body" *ngIf="investigation?.Detail?.claimNumber else noValue"
                      [innerHTML]="investigation?.Detail?.claimNumber"></h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Policy Number</small></h6>
                    <h6 class="kendo-caption-body" *ngIf="investigation?.Detail?.policyNumber else noValue"
                      [innerHTML]="investigation?.Detail?.policyNumber"></h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Description</small></h6>
                    <h6 class="kendo-caption-body" *ngIf="investigation?.Detail?.riskDescription else noValue"
                      [innerHTML]="investigation?.Detail?.riskDescription"></h6>
                  </div>
                </div>
              </kendo-card-body>
            </kendo-card>
            <!-- Scene Exam Address Card -->
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}">
              <kendo-card-header>
                Scene Exam Address
              </kendo-card-header>
              <kendo-card-body>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Address</small></h6>
                    <h6 class="kendo-caption-body"><app-address-view
                        [address]="investigation.InvestigationAddress"></app-address-view></h6>
                  </div>
                  <hr />
                  <h5 class="card-title mb-24">Scene Contact</h5>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Contact</small></h6>
                    <h6 class="kendo-caption-body">
                      {{(investigation?.SceneContact | fullName) || '-'}}</h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Phone</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.SceneContact?.Phones else noValue">
                        <app-email-phone-view *ngFor="let phone of investigation?.SceneContact?.Phones" [phone]="phone">
                        </app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Email</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.SceneContact?.Emails else noValue">
                        <app-email-phone-view *ngFor="let email of investigation?.SceneContact?.Emails"
                          [email]="email"></app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Address</small></h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="investigation?.SceneContact?.Addresses?.length; else noValue">
                        <app-address-view *ngFor="let address of investigation?.SceneContact?.Addresses"
                          [address]="address"></app-address-view>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Special Instructions</small></h6>
                    <h6 class="kendo-caption-body white-space-normal"
                      [innerHTML]="investigation?.Detail?.specialInstructions || '-'"></h6>
                  </div>
                </div>
              </kendo-card-body>
            </kendo-card>
            <!-- Notes Card -->
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}">
              <kendo-card-header>
                Notes
              </kendo-card-header>
              <kendo-card-body>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption white-space-normal"><small>{{investigation.Detail?.notes || '-'}}</small>
                    </h6>
                  </div>
                </div>
              </kendo-card-body>
            </kendo-card>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
      <!-- Linked Investigation Drawer-->
      <kendo-panelbar class="p-16" [expandMode]="2"
        *ngIf="investigation.LinkedInvestigations?.length && investigationLinkedInvestigationView?.enabled">
        <kendo-panelbar-item [expanded]="false">
          <ng-template kendoPanelBarItemTitle>
            <h6 class="panelbar-title mb-0">Linked Investigations</h6>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <!-- Investigation Card -->
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}">
              <kendo-card-header>
                Investigation
              </kendo-card-header>
              <kendo-card-body>
                <div class="column">
                  <div class="equal" *ngFor="let linkedInvestigation of investigation?.LinkedInvestigations">
                    <h6 class="kendo-caption"><small>Investigation ID</small></h6>
                    <h6 class="kendo-caption-body">
                      {{ linkedInvestigation?.nefcoNumber }}
                    </h6>
                  </div>
                </div>
                <ng-template #noValue>-</ng-template>
              </kendo-card-body>
            </kendo-card>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
      <!-- Investigation Parties Card -->
      <kendo-panelbar class="p-16" [expandMode]="2" *ngIf="investigationPartiesListView?.enabled">
        <kendo-panelbar-item [expanded]="false">
          <ng-template kendoPanelBarItemTitle>
            <h6 class="panelbar-title mb-0">Parties</h6>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <!-- Insured Card -->
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}">
              <kendo-card-header>
                Insured
              </kendo-card-header>
              <kendo-card-body>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Type</small></h6>
                    <h6 class="kendo-caption-body">
                      <kendo-badge class="fw600" position="none" size="medium" rounded="full" themeColor="primary">{{
                        investigation?.Insured?.contactName
                        ? "Business"
                        : "Individual"
                        }}</kendo-badge>
                    </h6>
                  </div>
                  <!-- Company Name -->
                  <div class="equal" *ngIf="investigation?.Insured?.contactName">
                    <h6 class="kendo-caption"><small>Company Name</small></h6>
                    <h6 class="kendo-caption-body">
                      <span [innerHTML]="investigation?.Insured.insuredName || '-'"></span>
                    </h6>
                  </div>

                  <!-- Contact Name -->
                  <div class="equal" *ngIf="investigation?.Insured?.contactName">
                    <h6 class="kendo-caption"><small>Contact Name</small></h6>
                    <h6 class="kendo-caption-body">
                      <span [innerHTML]="investigation?.Insured?.contactName || '-'"></span>
                    </h6>
                  </div>

                  <!-- Name -->
                  <div class="equal" *ngIf="!investigation?.Insured?.contactName">
                    <h6 class="kendo-caption"><small>Insured Name</small></h6>
                    <h6 class="kendo-caption-body">
                      <span [innerHTML]="investigation?.Insured?.insuredName || '-'"></span>
                    </h6>
                  </div>

                  <!-- Insured Relationship/Title -->
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Relationship / Title</small></h6>
                    <h6 class="kendo-caption-body" [innerHTML]="investigation?.Insured?.title || '-'"></h6>
                  </div>

                  <!-- Email -->
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Email</small></h6>
                    <h6 class="kendo-caption-body">
                      <app-email-phone-view *ngFor="let email of investigation?.Insured?.Emails"
                        [email]="email"></app-email-phone-view>
                      <span *ngIf="!investigation?.Insured?.Emails?.length">-</span>
                    </h6>
                  </div>
                </div>

                <div class="column">
                  <!-- Phone -->
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Phone</small></h6>
                    <h6 class="kendo-caption-body">
                      <app-email-phone-view [investigation]="investigation" (reload)="reload($event)"
                        [currentUser]="currentUser" *ngFor="let phone of investigation?.Insured?.Phones"
                        [phone]="phone">
                      </app-email-phone-view>
                      <span *ngIf="!investigation?.Insured?.Phones?.length">-</span>
                    </h6>
                  </div>
                  <!-- Address -->
                  <div class="equal">
                    <h6 class="kendo-caption"><small>Address</small></h6>
                    <h6 class="kendo-caption-body">
                      <app-address-view *ngFor="let address of investigation?.Insured?.Addresses"
                        [address]="address"></app-address-view>
                    </h6>
                  </div>

                  <div class="equal">
                    <h6 class="kendo-caption"><small>Suffix</small></h6>
                    <h6 class="kendo-caption-body">
                      {{ investigation?.Insured?.suffix || "-" }}
                    </h6>
                  </div>
                </div>
                <ng-template #noValue>-</ng-template>
              </kendo-card-body>
            </kendo-card>
            <!-- parties card -->
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}" *ngFor="let party of investigation?.Parties">
              <kendo-card-header>
                {{ party?.Role?.name }}
              </kendo-card-header>
              <kendo-card-body>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption">Name</h6>
                    <h6 class="kendo-caption-body"> {{(party.Contact | fullName) || '-' }} </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption">Phone</h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="party?.Contact?.Phones?.length else noValue">
                        <app-email-phone-view *ngFor="let phone of party.Contact?.Phones" [phone]="phone">
                        </app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption">Email</h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="party?.Contact?.Emails?.length else noValue">
                        <app-email-phone-view *ngFor="let email of party.Contact?.Emails"
                          [email]="email"></app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption">Address</h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="party?.Contact?.Addresses?.length else noValue">
                        <app-address-view *ngFor="let address of party.Contact?.Addresses"
                          [address]="address"></app-address-view>
                      </ng-container>
                    </h6>
                  </div>
                </div>
                <ng-template #noValue>-</ng-template>
              </kendo-card-body>
            </kendo-card>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
      <!-- Staff Card -->
      <kendo-panelbar class="p-16" [expandMode]="2" *ngIf="investigationAssignStaffListView?.enabled">
        <kendo-panelbar-item [expanded]="false">
          <ng-template kendoPanelBarItemTitle>
            <h6 class="panelbar-title mb-0">Staff</h6>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <kendo-card [width]="''" [ngStyle]="{'border-radius': '10px'}"
              *ngFor="let person of investigation.InvestigationStaff">
              <kendo-card-header>
                {{person?.Role?.title | quiet}} <ng-container *ngIf="person?.isPrimary">(Primary)</ng-container>
              </kendo-card-header>
              <kendo-card-body>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption">Contact</h6>
                    <h6 class="kendo-caption-body">
                      <h6 class="kendo-caption-body">{{ person.User | fullName }}</h6>
                    </h6>
                  </div>
                  <div class="equal">
                    <h6 class="kendo-caption">Email</h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="person.User?.Emails?.length else noValue">
                        <app-email-phone-view *ngFor="let userEmail of person.User?.Emails"
                          [email]="userEmail"></app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                </div>
                <div class="column">
                  <div class="equal">
                    <h6 class="kendo-caption">Phone</h6>
                    <h6 class="kendo-caption-body">
                      <ng-container *ngIf="person?.User?.Phones?.length else noValue">
                        <app-email-phone-view *ngFor="let personPhone of person.User?.Phones"
                          [phone]="personPhone"></app-email-phone-view>
                      </ng-container>
                    </h6>
                  </div>
                </div>
                <ng-template #noValue>-</ng-template>
              </kendo-card-body>
            </kendo-card>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
    </section>
  </ng-template>
</kendo-drawer>
