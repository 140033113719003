import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { GqlPhoneTypeService } from 'src/app/shared/modules/graphql/services';
import { IApiPhoneTypeFilter } from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class PhoneTypeService {

  constructor(
    private phoneTypes: GqlPhoneTypeService,
  ) { }

  public get(filters?: IApiPhoneTypeFilter[], options?: IGqlBaseOptions) {
    return this.phoneTypes.getPhoneTypes(filters, options || new ListPage());
  }

}
