import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import {
  IApiInvestigationMetricsFilter,
  IApiInvestigationMetricsFilterType,
} from "src/app/shared/modules/graphql/types/types";
import { IEnforcePermissionConfig } from "src/app/shared/directives/enforce-permission.directive";
import {
  PermissionActions,
  PermissionCategories,
} from "src/app/shared/modules/graphql/enums/permissions.enums";
import { UntilDestroy } from "@ngneat/until-destroy";
import moment from "moment";
import {
  DateRangePopupComponent,
  DateRangeService,
} from "@progress/kendo-angular-dateinputs";
import { NefcoDateHelper } from "src/app/shared/helpers/nefco-date.class";

@UntilDestroy()
@Component({
  selector: "app-investigations-report-filter",
  templateUrl: "./investigations-report-filter.component.html",
  styleUrls: ["./investigations-report-filter.component.scss"],
})
export class InvestigationsReportFilterComponent implements AfterViewInit {
  @ViewChild('startDateRange', { static: false }) startDateRange: any;
  @ViewChild('endDateRange', { static: false }) endDateRange: any;

  @Output() filters = new EventEmitter<IApiInvestigationMetricsFilter[]>();
  @Output() clearFilter = new EventEmitter<IApiInvestigationMetricsFilter[]>();
  @Output() applyFilter = new EventEmitter<IApiInvestigationMetricsFilter[]>();
  @Input() filterColumns: string;
  @Input() reportPage: string;
  @Input() colSpan: number = 2;

  @ViewChild("dateRangePickerPopup", { static: true })
  public dateRangePickerPopup: DateRangePopupComponent;

  @ViewChild("dateRangePicker", { read: DateRangeService })
  public service: DateRangeService;

  private _filters: IApiInvestigationMetricsFilter[] = [];
  public searchCompanies = "";
  public selectedId: Array<string> = [];
  public selectedBillingRuleCompanyId: Array<string> = [];

  public today: Date = new Date();

  private defaults = {
    dateFilter: {
      startDate: "",
      endDate: "",
    },
  };

  public dateFilter: Record<string, any> = { ...this.defaults.dateFilter };

  // Toggle filter select visibility
  public showFilterSelect = false;
  public range = { startDate: null, endDate: null };

  public permissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION,
    appliedPermissions: {
      All: [PermissionActions.VIEW],
      AllAssigned: [PermissionActions.VIEW],
      Assigned: [PermissionActions.VIEW],
      Own: [PermissionActions.VIEW],
    },
  };

  public showAllPermissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION_CLOSED,
    appliedPermissions: {
      All: [PermissionActions.VIEW],
      AllAssigned: [],
      Assigned: [],
      Own: [],
    },
  };

  public filterValue(filter: IApiInvestigationMetricsFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this._filters.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" ? JSON.parse(value) : value;
  }

  public companyList = [];
  public evidenceStatusArray: Array<{id: string; name:string}> = [
    {id: 'STORED', name: 'Stored' },
    {id: 'SHIPPED', name: 'Shipped'},
    {id: 'DISPOSAL_REQUESTED', name: 'Disposal Requested'},
    {id: 'AWAITING_DISPOSAL', name: 'Awaiting Disposal'},
    {id: 'DISPOSED', name: 'Disposed'},
  ];
  public selectedStatus: string;

  constructor() {}

  ngAfterViewInit() {
    this.service.activeRangeEnd$.subscribe((data) => {
      if (data === "start") {
        setTimeout(() => {
          if (this.range.startDate) {
            const dates = {
              startDate: NefcoDateHelper.toUtcStartOfDay(this.range.startDate, true),
              endDate: NefcoDateHelper.toUtcStartOfDay(this.range.endDate, false),
            };
            this.setFilters(
              JSON.stringify({
                startDate: dates.startDate,
                endDate: dates.endDate,
              }),
              IApiInvestigationMetricsFilterType.ReceivedDate
            );
            this.dateRangePickerPopup.toggle(false);
          }
        }, 100);
      }
    });
  }

  public updateDateFilters(event) {
    if (this.range.startDate && this.range.endDate) {
      const dates = {
        startDate: NefcoDateHelper.toUtcStartOfDay(this.range.startDate, true),
        endDate: NefcoDateHelper.toUtcStartOfDay(this.range.endDate, false),
      };
      this.setFilters(
        JSON.stringify({
          startDate: dates.startDate,
          endDate: dates.endDate,
        }),
        IApiInvestigationMetricsFilterType.ReceivedDate
      );
    }
  }

  public focusDateInput(start = true) {
    if (this.startDateRange && start) {
      setTimeout(() => {
        this.startDateRange?.focus();
      }, 1);
    } else {
      setTimeout(() => {
        this.endDateRange?.focus();
      }, 1);
    }
  }

  public filterTable() {
    this.filters.emit(this._filters);
  }

  public setDateFilter(value: Date, prop: "startDate" | "endDate") {
    this.dateFilter[prop] = moment(value).format("YYYY-MM-DD HH:mm:ss");
  }

  public setCompanySearch(event) {
    this.searchCompanies = event;
    this.setFilters(
      this.searchCompanies,
      IApiInvestigationMetricsFilterType.CurrentClient
    );
  }

  public clearFilters() {
    this.clearFilter.emit([...this._filters]);
    this._filters = [];
    this.selectedId = [];
    this.selectedBillingRuleCompanyId = [];
    this.selectedStatus = null;
    this.range = { startDate: null, endDate: null };
  }

  public setFilters(
    value: string | undefined,
    type: IApiInvestigationMetricsFilterType
  ) {
    const hasValue = (val: any) => val !== undefined || val !== null; // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this._filters.filter((f) => f.type !== type);
    this._filters = hasValue(value)
      ? [...filtersCopy, { type: type, value: value }]
      : filtersCopy;
    this.applyFilter.emit(this._filters);
  }

  public setCompany(items) {
    this.selectedId = items;
    let value = null;
    if (items?.length > 0) {
      value = JSON.stringify(items);
    }
    this.setFilters(value, IApiInvestigationMetricsFilterType.CurrentClient);
  }

  public setBillingRuleCompany(items) {
    this.selectedBillingRuleCompanyId = items;
    let value = null;
    if (items?.length > 0) {
      value = JSON.stringify(items);
    }
    this.setFilters(value, IApiInvestigationMetricsFilterType.BillingRuleCompany);
  }

  public setEvidenceStatus(val) {
    this.setFilters(val?.id, IApiInvestigationMetricsFilterType.EvidenceStatus);
  }
}
