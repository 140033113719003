import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../shared/shared.module';
import { DocumentTypeListComponent } from "./document-type-list/document-type-list.component";
import { DocumentTypeModalComponent } from "./document-type-modal/document-type-modal.component";

@NgModule({
  declarations: [
    DocumentTypeListComponent,
    DocumentTypeModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: []
})
export class DocumentsModule {}
