import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  isActive
  notes
  Contact {
    id
    firstName
    lastName
    nickName
    suffix
    insuredName
    policyNumber
    claimNumber
    jobTitle
    Branches {
      id
      name
      Addresses {
        id
        isPrimary
        Type {
          id
          name
        }
        address1
        address2
        address3
        city
        state
        postal
        country
      }
      Emails {
        id
        Type {
          id
          name
        }
        TypeId
        address
        isPrimary
      }
      Phones {
        id
        Type {
          id
          name
        }
        TypeId
        number
        isPrimary
      }
      Company {
        id
        name
      }
    }
    Emails {
      id
      Type {
        id
        name
      }
      TypeId
      address
      isPrimary
    }
    Phones {
      id
      Type {
        id
        name
      }
      TypeId
      number
      isPrimary
    }
    Addresses {
      id
      isPrimary
      Type {
        id
        name
      }
      address1
      address2
      address3
      city
      state
      postal
      country
    }
  }
  Investigation {
    id
  }
  Role {
    id
    name
    ContactType {
      id
      name
    }
  }
  createdAt
  updatedAt
`;

export const investigationPartyConnectionQuery = gql`${buildConnectionQuery("InvestigationParty", "investigationPartyConnection", nodeFragment)}`;

export const addInvestigationPartyMutation = gql`mutation AddInvestigationParty($input: AddInvestigationPartyInput!) {
  addInvestigationParty(input: $input) { ${nodeFragment} }
}`;

export const updateInvestigationPartyMutation = gql`mutation UpdateInvestigationParty($input: UpdateInvestigationPartyInput!) {
  updateInvestigationParty(input: $input) { ${nodeFragment} }
}`;

export const removeInvestigationPartyMutation = gql`mutation RemoveInvestigationParty($id: ID!) {
  removeInvestigationParty(id: $id)
}`;
