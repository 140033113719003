<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>{{ data?.id ? "Edit" : "Add" }} Transfer Carrier</span>
    </div>
  </kendo-dialog-titlebar>
  <section class="section-content">
    <form #form="ngForm" name="form">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Carrier Name"></kendo-label>
            <kendo-textbox appNoWhiteSpace name="name" fillMode="outline" #carrierName="ngModel" [clearButton]="false"
              placeholder="Enter carrier name" [(ngModel)]="model.name" required cdkFocusInitial></kendo-textbox>
            <kendo-formerror *ngIf="!carrierName?.valid && (carrierName?.dirty || carrierName?.touched) && carrierName?.errors?.required">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span>
                Carrier name cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSubmit()"
      [disabled]="!form.valid && (form?.dirty || form?.touched)">{{ data?.id ? "Save" : "Insert" }}
    </button>
  </kendo-dialog-actions>
</div>
