import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IApiPhotoFolder } from 'src/app/shared/modules/graphql/types/types';

@Component({
  selector: 'app-investigation-photos-modal',
  templateUrl: './investigation-photos-modal.component.html',
  styleUrls: ['./investigation-photos-modal.component.scss']
})
export class InvestigationPhotosModalComponent {

  selectedFolder: IApiPhotoFolder = null;
  newFolderName = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IApiPhotoFolder[],
    private dialogRef: MatDialogRef<InvestigationPhotosModalComponent>,
  ) { }

  public selectFolder() {
    this.dialogRef.close({
      ...this.selectedFolder,
      name: !this.selectedFolder.id ? this.newFolderName : this.selectedFolder.name,
    });
  }

}
