import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IApiInvestigation, IApiPhoto, IApiPhotoFilterType, IApiPhotoFolder, IApiPhotoFolderFilterType } from 'src/app/shared/modules/graphql/types/types';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { PhotoFolderService, PhotoService } from 'src/app/shared/services';

export interface IInvestigationEvidencePhotosModalData {
  investigation: IApiInvestigation;
}

export interface IEvidencePhotosModalFolder extends IApiPhotoFolder {
  EvidencePhotos: IApiPhoto[];
}

@UntilDestroy()
@Component({
  selector: 'app-investigation-evidence-photos-modal',
  templateUrl: './investigation-evidence-photos-modal.component.html',
  styleUrls: ['./investigation-evidence-photos-modal.component.scss']
})
export class InvestigationEvidencePhotosModalComponent implements OnInit {

  public folders: IEvidencePhotosModalFolder[] = [];

  public loading = new BehaviorSubject<boolean>(true);

  public get nefcoNumber() {
    return this.data?.investigation?.nefcoNumber;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IInvestigationEvidencePhotosModalData,
    // private photoFolderService: PhotoFolderService,
    private photoService: PhotoService

  ) {
    // this.loading.pipe(untilDestroyed(this)).subscribe((v) => this.loading = v);
   }

  ngOnInit(): void {
    const filters = [
      { type: IApiPhotoFilterType.Investigation, value: this.data.investigation.id },
      { type: IApiPhotoFilterType.Evidence, value: "true" }
    ];
    this.photoService.get(filters).pipe(
      unwrapConnection(),
      tap(() => this.loading.next(false)),
      map((photos: IApiPhoto[]) => {
        // consolidate all individual photos into folders
        return photos.reduce((p, c) => {
          const folder = c.Folder as IEvidencePhotosModalFolder;
          const found = p.find((v) => v.id === folder.id);
          if (found) {
            found.EvidencePhotos.push(c);
            return p;
          } else {
            folder.EvidencePhotos = [c];
            return [...p, folder];
          }
        }, []);
      })
    ).subscribe((f) => {
      this.folders = f;
    });
  }

}
