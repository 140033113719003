import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { IApiExpenseApplicationConnection, IApiExpenseApplicationFilter } from "../../../types/types";
import { ExpenseApplicationConnectionQuery } from "./expense-application.operations";

@Injectable()
export class GqlExpenseApplicationService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getExpenseApplications(filters: IApiExpenseApplicationFilter[], options: IGqlBaseOptions): Observable<IApiExpenseApplicationConnection> {
    return this.apollo.query(connectionQuery(ExpenseApplicationConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }
}
