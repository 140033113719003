import { NotificationsService } from '../../../../shared/modules/notifications/notifications.service';
import { IApiAddSettingsInput, IApiSettings, IApiUpdateSettingsInput } from '../../../../shared/modules/graphql/types/types';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { NgForm } from '@angular/forms';
import { SettingsService } from 'src/app/shared/services';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})
export class SettingsModalComponent extends DialogContentBase implements OnInit{
  public model: IApiAddSettingsInput | IApiUpdateSettingsInput;
  @Input() data: IApiSettings;
  @ViewChild('form', { static: false }) form: NgForm;
  constructor(
    private settingsService: SettingsService,
    private notifications: NotificationsService,
    private loaderService: LoaderService,
    public dialogRef: DialogRef
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    const {
      id,
      key = "",
      value = ""
    } = this.data || {};

    this.model = {
      id,
      key,
      value
    };
  }
  onSubmit() {
    if (this.form?.invalid) {
      this.form.form.markAllAsTouched();
      return;
    };
    const { id = null } = this.data || {};
    if (id) this.save();
    else this.add();
  }

  public close(action = false) {
    return this.dialogRef.close(action);
  }

  public save() {
    this.loaderService.show$(this.settingsService.update({
      id: this.data.id,
      key: this.model?.key?.trim(),
      value: this.model.value?.trim()
    })).pipe(
      this.notifications.snackbarPipe("Setting updated"),
      this.notifications.snackbarErrorPipe("Error updating setting")
    ).subscribe(() => this.close(true));
  }
  public add() {
    this.loaderService.show$(this.settingsService.add(this.model as IApiAddSettingsInput)).pipe(
      this.notifications.snackbarPipe("Setting added"),
      this.notifications.snackbarErrorPipe("Error adding setting")
    ).subscribe(() => this.close(true));
  }
}
