import { Component, Input, OnInit } from '@angular/core';
import { IApiInvestigationOnScene, IApiInvestigationOnSceneFilterType, IApiInvestigationOnSceneOrderBy, IApiInvestigationStaff } from '../../modules/graphql/types/types';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { InvestigationOnSceneService } from '../../services';
import { InvestigationOnSceneDataSource } from '../../services/investigation/investigation-on-scene/investigation-staff.datasource';
import { LoaderService } from '../../modules/loader/loader.service';
import { SortOrder } from '../../modules/graphql/enums/generic.enums';
import { InvestigationOnSceneStatus } from '../../modules/graphql/enums/investigation.enums';

@Component({
  selector: 'app-investigation-on-scene-activity-modal',
  templateUrl: './investigation-on-scene-activity-modal.component.html',
  styleUrls: ['./investigation-on-scene-activity-modal.component.scss']
})
export class InvestigationOnSceneActivityModalComponent extends DialogContentBase implements OnInit {

  @Input() public dataItem: IApiInvestigationOnScene;

  private _dataSource: InvestigationOnSceneDataSource;

  public get dataSource() {
    return this._dataSource;
  }

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public investigationOnSceneStatus = InvestigationOnSceneStatus;
  public orderBy = IApiInvestigationOnSceneOrderBy;
  public sort: any[] = [{
    field: IApiInvestigationOnSceneOrderBy.CreatedAt,
    dir: 'desc'
  }];

  public data: IApiInvestigationOnScene[] = [];

  constructor(
    public dialog: DialogRef,
    private investigationOnSceneService: InvestigationOnSceneService,
    private loader: LoaderService,
  ) {
    super(dialog);

  }

  ngOnInit(): void {
    this._dataSource = new InvestigationOnSceneDataSource(this.investigationOnSceneService);
    this.loader.attachObservable(this._dataSource.loading$);
    this._dataSource.listPage.orderBy = IApiInvestigationOnSceneOrderBy.CreatedAt;
    this._dataSource.listPage.sortOrder = SortOrder.DESC;
    this._dataSource.listPage.limit = -1;
    this.load();
  }

  private async load() {
    this.dataSource.applyFilter(IApiInvestigationOnSceneFilterType.UserId, this.dataItem?.User?.id);
    this.dataSource.applyFilter(IApiInvestigationOnSceneFilterType.InvestigationId, this.dataItem?.Investigation?.id);
    this.dataSource.applyFilter(IApiInvestigationOnSceneFilterType.ScheduledDateExact, this.dataItem?.scheduledDate);
    await this.dataSource.load().then(() => {
      this.dataSource?.contents$.subscribe((res) => {
        this.data = res;
      });
    });
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiInvestigationOnSceneOrderBy.CreatedAt;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  }

  public close() {
    this.dialog.close(null);
  }

}
