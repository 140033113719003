<div [dialogRef]="dialog" (appClickOutside)="cancel()">
  <kendo-dialog-titlebar (close)="cancel()">
    <div class="modal-title">
      <span>Select Staff</span>
      <div class="modal-subtitle" [innerHTML]="data.user | fullName"></div>
    </div>
  </kendo-dialog-titlebar>
  <section>
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label text="Position"></kendo-label>
          <kendo-dropdownlist [data]="positions" textField="title" valueField="title" fillMode="outline"
            [valuePrimitive]="true" [defaultItem]="{ title: 'All Position' }"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
            (selectionChange)="positionSelected($event)">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span [ngClass]="dataItem?.title === 'All Position' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.title | quiet }} </span>
            </ng-template>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span [ngClass]="dataItem?.title === 'All Position' ? 'placeholder' : 'not-placeholder'"> {{ dataItem?.title | quiet }} </span>
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon">
          <kendo-label text="Search"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox
              name="search"
              [(ngModel)]="search"
              (appAfterValueChanged)="onSearchChange()"
              placeholder="Search"
              fillMode="outline"
              [clearButton]="true">
            </kendo-textbox>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
      <kendo-gridlayout-item *ngFor="let id of selection.selected">
        <kendo-chip class="fw600 mr-16" [size]="'medium'" [rounded]="'full'" [fillMode]="'solid'" [themeColor]="'base'"
          [removable]="true" (remove)="selection.deselect(id)" style="width: 180px;">
          <span class="text-overflow">{{ findUser(id) | fullName }}</span>
        </kendo-chip>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-grid
      class="staff-list-grid"
      [data]="{
        data: (dataSource?.contents$ | async) || [],
        total: (dataSource?.pageInfo$ | async)?.totalCount
      }"
      [pageSize]="pageOptions?.limit"
      [skip]="pageOptions?.offset"
      [pageable]="{
        buttonCount: 6,
        type: 'numeric',
        pageSizes: [5, 10, 25, 100],
        previousNext: true,
        responsive: true,
        position: 'bottom'
      }"
      (pageChange)="pageOptions?.paginate($event)"
      [sortable]="true"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      [navigatable]="false"
    >

      <kendo-grid-column field="Select" title="Select" [width]="5" [sortable]="false">
        <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
          <input type="checkbox" class="mr-16" kendoCheckBox [checked]="allSelected" (change)="masterToggle($event?.target)"
          [indeterminate]="selection.hasValue() && !allSelected" />
        </ng-template>
      
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" class="mr-16" kendoCheckBox [checked]="selection.isSelected(dataItem.id)"
            (change)="select($event?.target, dataItem)" />
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="orderByOptions.Lastname" title="Name" [width]="15" [sortable]="true"
        [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem | fullName }}</span>
          </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="'Positions'" title="Position(s)" [width]="10" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.Positions?.length else noValue">
            <div class="mt-5" *ngFor="let position of dataItem.Positions" [innerHTML]="position.title | quiet"></div>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="'Address'" title="Address" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.Addresses?.length else noValue">
            <app-address-view [address]="primary(dataItem.Addresses)"></app-address-view>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="PHONE" title="Phone" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem?.Phones?.length; else noValue">
            <app-email-phone-view [phone]="primary(dataItem.Phones)"></app-email-phone-view>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="EMAIL" title="Email" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem?.Emails?.length; else noValue">
            <app-email-phone-view [email]="primary(dataItem.Emails)"></app-email-phone-view>
          </ng-container>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="cancel()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="save()">
      Save
    </button>
  </kendo-dialog-actions>
</div>
<ng-template #noValue>
  <span>-</span>
</ng-template>