<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffViewAll" #adminPerm="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-grid
  class="report-list odd-even-background td-p-0 td-border-left"
  [data]="{
    data: data,
    total: (dataSource?.pageInfo$ | async)?.totalCount
  }"
  [pageSize]="pageOptions?.limit"
  [skip]="pageOptions?.offset"
  [pageable]="
    pageOptions
      ? {
          buttonCount: 6,
          type: 'numeric',
          pageSizes: [5, 10, 25, 100],
          previousNext: true,
          responsive: true,
          position: 'bottom'
        }
      : false
  "
  (pageChange)="pageChange.emit($event)"
  [sortable]="true"
  [sort]="sort"
  (sortChange)="sortChange.emit($event)"
  [navigatable]="false"
  [resizable]="true"
  [scrollable]="true"
>
  <kendo-grid-column
    *ngIf="tabIndex === 0"
    field="RECEIVED_DATE"
    title="Year"
    [width]="20"
    [resizable]="false"
    [headerClass]="'sort-header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="font-weight-600">
        {{ dataItem?.year }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngIf="tabIndex === 2"
    field="LOSS_STATE"
    title="Name"
    [width]="20"
    class="id-column"
    [resizable]="false"
    [headerClass]="'sort-header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="font-weight-600">
        {{ getStateName(dataItem?.state)?.text }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngIf="tabIndex === 6"
    field="CONTACT_NAME"
    title="Name"
    [width]="20"
    class="id-column"
    [resizable]="false"
    [headerClass]="'sort-header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <a
        [routerLink]="['/contacts/', dataItem?.id]"
        target="_blank"
        class="text-decoration"
        >{{ dataItem?.firstName || "" }} {{ dataItem?.lastName || "" }}</a
      >
      <br />
      <span class="phone-number">{{ dataItem?.companyName || "" }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngIf="tabIndex === 3 || tabIndex === 4"
    field="COMPANY_NAME"
    title="Company"
    [width]="20"
    class="id-column"
    [resizable]="false"
    [headerClass]="'sort-header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <a
      [routerLink]="['/admin/companies/management/', dataItem?.id]"
      target="_blank"
      class="text-decoration"
      >{{ dataItem?.name || "" }}</a
    >
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    *ngIf="tabIndex === 1 || tabIndex === 5"
    field="USER_NAME"
    title="Name"
    [width]="20"
    class="id-column"
    [resizable]="false"
    [headerClass]="'sort-header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="user-name">
        <a
          *ngIf="adminPerm.enabled; else nonadmin"
          [routerLink]="['/staff/', dataItem?.id]"
          class="text-decoration"
          target="_blank"
        >{{ dataItem?.lastName || "" }}
        {{ dataItem?.middleName ? (dataItem?.middleName[0] | titlecase) + "." : ""
        }}&nbsp;{{ dataItem?.firstName || "" }}</a>
        <ng-template #nonadmin>
          <span class="font-weight-600">{{ dataItem?.lastName || "" }}
        {{ dataItem?.middleName ? (dataItem?.middleName[0] | titlecase) + "." : ""
        }}&nbsp;{{ dataItem?.firstName || "" }}</span>
        </ng-template>
        
        <br />
        <span class="phone-number"
          >{{ (dataItem?.firstName[0] | titlecase) || ""
          }}{{
            dataItem?.middleName ? (dataItem?.middleName[0] | titlecase) : ""
          }}{{ (dataItem?.lastName[0] | titlecase) || "" }}</span
        >
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    *ngIf="tabIndex === 7"
    field="RISK_TYPE"
    title="Name"
    [width]="20"
    class="id-column"
    [resizable]="false"
    [headerClass]="'sort-header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="kendo-span">{{ dataItem?.name || "" }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngIf="tabIndex === 8"
    field="CAUSE_OF_FIRE"
    title="Name"
    [width]="30"
    class="id-column"
    [resizable]="false"
    [headerClass]="'sort-header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="kendo-span">{{ dataItem?.determination || "Null" }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngIf="tabIndex !== 0"
    field="YEAR"
    title="Year"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [class]="'border-set'"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="
          let item of dataItem?.data | keyvalue : keyDescOrder;
          let index = index
        "
      >
        {{ item?.key }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Jan"
    title="Jan"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="
          let item of dataItem?.data | keyvalue : keyDescOrder;
          let index = index
        "
      >
        {{ item?.value?.['1'] || '&nbsp;'}}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Feb"
    title="Feb"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['2'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Mar"
    title="Mar"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['3'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Apr"
    title="Apr"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['4'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="May"
    title="May"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['5'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Jun"
    title="Jun"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['6'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Jul"
    title="Jul"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['7'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Aug"
    title="Aug"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['8'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Sep"
    title="Sep"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['9'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Oct"
    title="Oct"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['10'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Nov"
    title="Nov"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['11'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="Dec"
    title="Dec"
    [width]="10"
    [resizable]="false"
    [sortable]="false"
    [headerClass]="'no-cursor'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        {{ item?.value?.['12'] || '&nbsp;' }}
      </p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="TOTAL"
    title="Total"
    [width]="10"
    [resizable]="false"
    [sortable]="true"
    [headerClass]="'sort-header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="cell-item"
        *ngFor="let item of dataItem?.data | keyvalue : keyDescOrder"
      >
        <span class="font-weight-600">{{
          item?.value["total"] ? item?.value["total"] : "-"
        }}</span>
      </p>
    </ng-template>
  </kendo-grid-column>
  <ng-template kendoGridNoRecordsTemplate>
    <p class="mt-24 mb-24">There are no results to display.</p>
  </ng-template>
</kendo-grid>
