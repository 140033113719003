<section>
  <h4>Invoice List</h4>
  <div class="content-wrapper">
    <form #filterForm="ngForm">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon mr-16">
          <kendo-label text="Search By Invoice Number"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox
              name="invoiceNumber"
              [(ngModel)]="invoiceNumber"
              placeholder="Search By Invoice Number"
              fillMode="outline"
              [clearButton]="true">
            </kendo-textbox>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon mr-16">
          <kendo-label text="Search By Investigation ID"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox
              name="investigationId"
              [(ngModel)]="investigationId"
              placeholder="Search By Investigation ID"
              fillMode="outline"
              [clearButton]="true">
            </kendo-textbox>
          </div>
        </div>
      </kendo-gridlayout-item>
      
      <kendo-gridlayout-item>
        <app-user-select-kendo [userViewFilter]="userViewFilter" label="Created By" placeholder="Search ..."
          [userId]="createdBy" [includeInactive]="true"
          (selectionChanged)="createdBy = $event?.id || null"></app-user-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label [ngClass]="endDate ? 'asterisk' : ''" text="Start Date"></kendo-label>
          <kendo-datepicker
              id="sDate"
              name="sDate"
              calendarType="classic"
              [fillMode]="'outline'"
              placeholder="Enter date"
              format="MM/dd/yyyy"
              [(ngModel)]="startDate"
              formatPlaceholder="formatPattern"
              #sDate="ngModel"
              [required]="endDate"
              [max]="endDate"
          ></kendo-datepicker>
          <kendo-formerror *ngIf="!sDate?.valid && (sDate?.dirty || sDate?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="sDate?.errors?.required">
              StartDate cannot be left blank
            </span>
            <span *ngIf="!sDate?.errors?.required && sDate?.errors">
               StartDate is invalid
            </span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label [ngClass]="startDate ? 'asterisk' : ''" text="End Date"></kendo-label>
          <kendo-datepicker
              id="eDate"
              name="eDate"
              calendarType="classic"
              [fillMode]="'outline'"
              placeholder="Enter date"
              format="MM/dd/yyyy"
              [(ngModel)]="endDate"
              formatPlaceholder="formatPattern"
              #eDate="ngModel"
              [required]="startDate"
              [min]="startDate"
          ></kendo-datepicker>
          <kendo-formerror *ngIf="!eDate?.valid && (eDate?.dirty || eDate?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="eDate?.errors?.required">
              EndDate cannot be left blank
            </span>
            <span *ngIf="!eDate?.errors?.required && eDate?.errors">
              EndDate is invalid
            </span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon mr-16">
          <kendo-label text="Search"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox
              name="search"
              [(ngModel)]="searchValue"
              (appAfterValueChanged)="filterTable()"
              placeholder="Search"
              fillMode="outline"
              [clearButton]="true">
            </kendo-textbox>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item [colSpan]="4" class="text-right">
        <div class="mr-16">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="filterTable()">Apply Filters</kendo-button>
            <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearFilters()" class="ml-16">Clear All</kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    </form>

    <kendo-grid
      class="evidence-list"
      [data]="{
        data: (dataSource?.contents$ | async) || [],
        total: (dataSource?.pageInfo$ | async)?.totalCount
      }"
      [pageSize]="pageOptions?.limit"
      [skip]="pageOptions?.offset"
      [pageable]="{
        buttonCount: 6,
        type: 'numeric',
        pageSizes: [5, 10, 25, 100],
        previousNext: true,
        responsive: true,
        position: 'bottom'
      }"
      (pageChange)="pageOptions?.paginate($event)"
      [sortable]="true"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      [navigatable]="false"
    >
      
      <kendo-grid-column
        [field]="orderByOptions.InvoiceNumber"
        title="Invoice"
        [width]="8"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.invoiceNumber || "-" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="'Investigation'"
        title="Investigation"
        [width]="8"
        class="id-column"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            [routerLink]="['/investigations/', dataItem?.Investigation?.id]"
            tel=""
            class="text-decoration"
          >
            {{ dataItem?.Investigation?.nefcoNumber || "Unassigned" }}
          </a>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="'BillTo'"
        title="Bill To"
        [width]="15"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="font-weight-bold">Name:</span> {{dataItem.Investigation.BillTo | fullName}}<br />
          <span class="font-weight-bold">Company:</span> {{dataItem.Investigation.BillToBranch?.Company?.name}}<br />
          <span class="font-weight-bold">Branch:</span> {{dataItem.Investigation.BillToBranch?.name}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="'client'"
        title="Client"
        [width]="15"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="font-weight-bold">Name:</span> {{dataItem.Investigation.Client | fullName}}<br />
          <span class="font-weight-bold">Company:</span> {{dataItem.Investigation.ClientBranch?.Company?.name}}<br />
          <span class="font-weight-bold">Branch:</span> {{dataItem.Investigation.ClientBranch?.name}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="'createdBy'"
        title="Created By"
        [width]="10"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>
            {{ dataItem.createdBy | fullName }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="orderByOptions.CreatedAt"
        title="Created At"
        [width]="8"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [innerHTML]="dataItem.createdAt | customDate: 'MM/dd/yyyy'"></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="orderByOptions.Status"
        title="Status"
        [width]="6"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <!-- Complete -->
          <kendo-badge *ngIf="dataItem.qbExported && !dataItem.QbErrors.length" class="fw600"
          position="none" size="medium" rounded="full" themeColor="success">Complete</kendo-badge>
          <!-- Pending -->
          <kendo-badge
            *ngIf="(!dataItem.qbExported && !dataItem.qbTicketId) || (!dataItem.qbExported && dataItem.qbTicketId && !dataItem.QbErrors.length)"
            class="fw600" position="none" size="medium" rounded="full" themeColor="warning">Pending</kendo-badge>
          <!-- Error -->
          <kendo-badge *ngIf="dataItem.qbTicketId && dataItem.QbErrors.length" class="fw600"
          position="none" size="medium" rounded="full" themeColor="error">Failed</kendo-badge>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="'importErrors'"
        title="Import Errors"
        [width]="15"
        [sortable]="true"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem?.QbErrors?.length else noValue">
            <p *ngFor="let error of dataItem.QbErrors">
              <strong>{{error.statusCode}}</strong>: {{error.statusMessage}}
            </p>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [field]="'actions'"
        title="Actions"
        [width]="10"
        [sortable]="true"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="!dataItem.qbExported">
            <button kendoButton themeColor="primary" fillMode="link" size="medium" iconClass="fa-solid fa-rotate-right"
              (click)="resubmitInvoice(dataItem)" [appEnforcePermission]="authConfig.bookKeepingInvoiceListUpdate"></button>
            <button kendoButton themeColor="primary" fillMode="link" size="medium" iconClass="fa-solid fa-pen"
              (click)="openQbDialog(dataItem)" [appEnforcePermission]="authConfig.bookKeepingInvoiceListUpdate"></button>
          </ng-container>
          <button *ngIf="dataItem.qbExported" kendoButton themeColor="primary" fillMode="link" size="medium"
            iconClass="fa-solid fa-list" (click)="openQbDialog(dataItem, true)"></button>
          <button kendoButton themeColor="error" fillMode="link" size="medium" iconClass="fa-solid fa-trash"
            (click)="deleteInvoice(dataItem)" [appEnforcePermission]="authConfig.bookKeepingInvoiceListDelete"></button>
        </ng-template>
      </kendo-grid-column>
      <ng-template #noValue>
        <p class="kendo-caption-body">-</p>
      </ng-template>
    </kendo-grid>
  </div>
</section>
