import { isEqual } from 'lodash';
import { Directive, Output, EventEmitter, Input, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { NgForm } from "@angular/forms";
import { debounceTime, filter, map, tap } from "rxjs/operators";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { ElementRefEventListener } from "../rxjs.pipes";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@Directive({
  selector: 'form[appDebouncedChange]'
})
export class DebouncedChangeDirective {
  private _timeout = 250;
  @Input() set timeout(value: number) {
    this._timeout = value;
  }

  private _original: any;
  @Input() public set original(value: any) {
    this._original = value ? this.distillObject(value) : null;
  }
  // if this directive is grabbed by @ViewChild within a component, this can be used
  // to get the original dataset to provide undo functionality
  public get original(): any {
    return this._original;
  }

  private _valueChanges$ = new ReplaySubject<any>(1);
  public get valueChanges$(): ReplaySubject<any> {
    return this._valueChanges$;
  }

  @Output() appDebouncedChange = new EventEmitter<{}>();

  constructor(
    private form: NgForm
  ) {
    this.form.valueChanges.pipe(
      debounceTime(this._timeout),
      filter(() => !this.form.pristine),
      map(this.distillObject),
      // if an "original" object is provided, use deep checking to see if it's unaltered
      filter(data => this._original ? !isEqual(data, this._original) : true),
      tap(data => this._valueChanges$.next(data)),
    ).subscribe((formData) => this.appDebouncedChange.emit(formData));
  }

  private distillObject(obj: any) {
    // strip out anything that's explicitly `undefined` then flatten back into an object
    return Object.keys(obj).filter(k => obj[k] !== undefined).reduce((prev, next) => {
      prev[next] = obj[next];
      return prev;
    }, {});
  }
}

@UntilDestroy()
@Directive({
  selector: '[appDebouncedInputChange]'
})
export class DebouncedInputChangeDirective {
  @Input() timeout = 250;
  @Input() event = 'input';

  @Output() appDebouncedInputChange = new EventEmitter<{}>();

  constructor(
    private element: ElementRef
  ) {
    const { listener$ } = new ElementRefEventListener(this.element, this.event, this.timeout);
    listener$.pipe(
      untilDestroyed(this)
    ).subscribe((val) => this.appDebouncedInputChange.emit(val));
  }
}


@Directive({
  selector: "[appAfterValueChanged]",
})
export class AppAfterValueChangedDirective implements OnDestroy {
  @Output()
  public appAfterValueChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  public valueChangeDelay = 300;

  private stream: Subject<number> = new Subject<number>();
  private subscription: Subscription;

  constructor() {
    this.subscription = this.stream
      .pipe(debounceTime(this.valueChangeDelay))
      .subscribe((value: number) => this.appAfterValueChanged.next(value));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener("valueChange", ["$event"])
  public onValueChange(value: number): void {
    this.stream.next(value);
  }
}
