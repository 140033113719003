import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const nodeFragment = `
  id
  name
  additionalInformation
  Addresses {
    id
    isPrimary
    TypeId
    Type {
      id
      name
    }
    address1
    address2
    address3
    city
    state
    country
    latitude
    postal
    longitude
    addressableType
    addressableTypeId
  }
  Phones {
    id
    isPrimary
    Type {
      id
      name
    }
    number
    extension
    label
    phoneableId
    phoneableType
    createdAt
    updatedAt
  }
  Emails {
    id
    Type {
      id
      name
    }
    isPrimary
    address
    emailableId
    emailableType
    createdAt
    updatedAt
  }
  Company {
    id
    name
  }
  Contacts {
    id
    firstName
    lastName
  }
  ContactAssignments {
    startDate
    endDate
    Contact {
      id
      firstName
      lastName
      jobTitle
      Role {
        id
        name
      }
      Emails {
        id
        Type {
          id
          name
        }
        isPrimary
        address
      }
      Phones {
        id
        isPrimary
        Type {
          id
          name
        }
        number
        extension
        label
      }
    }
  }
  createdAt
  updatedAt
`;

export const branchesConnectionQuery = gql`${buildConnectionQuery("Branch", "branchesConnection", nodeFragment)}`;

export const addBranchMutation = gql`mutation createBranch($input: AddBranchInput!) {
  addBranch(input: $input) { ${nodeFragment} }
  }`;

export const removeBranchMutation = gql`mutation removeBranch($id: ID!) {
  removeBranch(id: $id)
}`;

export const updateBranchMutation = gql`mutation updateBranch($input: UpdateBranchInput!) {
  updateBranch(input: $input) { ${nodeFragment} }
}`;

export const getBranchByIdQuery = gql`query getBranchById($id: ID!) {
  getBranchById(id: $id) {${nodeFragment}}
}`;
