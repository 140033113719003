<div [dialogRef]="dialog" (appClickOutside)="dialog.close(false)">
  <kendo-dialog-titlebar (close)="dialog.close(false)">
    <div class="modal-title">
      <span>Select Contact / Company</span>
    </div>
  </kendo-dialog-titlebar>
  <section>
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label text="First Name"></kendo-label>
          <kendo-textbox appNoWhiteSpace name="firstName" fillMode="outline" #fName="ngModel" [clearButton]="true"
            placeholder="Enter First Name" [(ngModel)]="firstName" [maxlength]="255">
          </kendo-textbox>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label text="Last Name"></kendo-label>
          <kendo-textbox appNoWhiteSpace name="lastName" fillMode="outline" #lName="ngModel" [clearButton]="true"
            placeholder="Enter Last Name" [(ngModel)]="lastName" [maxlength]="255">
          </kendo-textbox>
        </kendo-formfield>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-state-select-kendo [label]="'State'" [placeholder]="'All'" (selectionChanged)="selectedCompanyState = $event">
        </app-state-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-company-select-single-kendo
          label="Company"
          placeholder="Search companies"
          [companyId]="selectedCompany"
          (selectionChanged)="selectedCompany = $event?.id"
        >
        </app-company-select-single-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item class="text-right" style="display: flex; align-items: center; justify-content: flex-end;">
        <div>
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="searchContacts()">Apply Filters</kendo-button>
          </div>
        </div>
        <div class="ml-16">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearFilter()">Clear All</kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-grid class="contact-company-list" 
    [data]="{
      data: (dataSource?.contents$ | async) || [],
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }" 
    [pageSize]="pageOptions?.limit" 
    [skip]="pageOptions?.offset" 
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }" 
    (pageChange)="pageOptions?.paginate($event)" 
    [sortable]="false"
    [resizable]="false"
    >
      <kendo-grid-column field="NAME" title="Name" [width]="10" [sortable]="false" [resizable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.lastName }} {{ dataItem.firstName }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="Company" title="Position / Company" [width]="20" [sortable]="false" [resizable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngFor="let branch of dataItem.BranchAssignments">
            <div *ngIf="!branch?.endDate" style="display: flex;">
              <button class="mr-16" kendoButton themeColor="primary" fillMode="link" iconClass="fas fa-plus"
              (click)="selectContact(dataItem, branch.Branch);"></button>
              <span>{{findCompany(dataItem, branch.Branch.id) || '-'}}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="!dataItem.BranchAssignments.length">
            <button class="mr-16" kendoButton themeColor="primary" fillMode="link" iconClass="fas fa-plus"
            (click)="selectContact(dataItem);"></button>
            <span>-</span>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="Branch" title="Branch" [width]="10" [sortable]="false" [resizable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem?.BranchAssignments?.length else noValue">
            <ng-container *ngFor="let branch of dataItem.BranchAssignments">
              <p class="mb-0" *ngIf="!branch?.endDate">{{branch?.Branch?.name || '-'}}</p>
            </ng-container>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="Role" title="Role" [width]="10" [sortable]="false" [resizable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem?.Role?.name}}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="Emails" title="Emails" [width]="15" [sortable]="false" [resizable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.Emails?.length else noValue">
            <app-email-phone-view *ngFor="let email of dataItem.Emails" [email]="email"></app-email-phone-view>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="Phones" title="Phones" [width]="15" [sortable]="false" [resizable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="dataItem.Phones?.length else noValue">
            <app-email-phone-view *ngFor="let phone of dataItem.Phones" [phone]="phone"></app-email-phone-view>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="id" title="Actions" [width]="5" [sortable]="false" [resizable]="false" [headerClass]="'nocursor'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-dropdownbutton [data]="[{ text: 'Edit' }]" themeColor="primary" fillMode="link" buttonClass="grid-action-items"
            iconClass="fa-solid fa-ellipsis" (itemClick)="itemSelected($event, dataItem)" [popupSettings]="{
            appendTo: 'component',
            animate: false,
            width: 250,
            popupClass: 'grid-action-items-menu'
          }">
          </kendo-dropdownbutton>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridNoRecordsTemplate>
        <p class="mt-24 mb-24">There are no results to display.</p>
      </ng-template>
    </kendo-grid>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="dialog.close()"
      *ngIf="!this.data?.disableCancel">
      Cancel
    </button>
    <button kendoButton iconClass="fas fa-plus" [size]="'medium'" themeColor="primary" class="mr-16" (click)="openContactModal()">
      Create New Contact
    </button>
  </kendo-dialog-actions>
</div>
<ng-template #noValue>
  <p>-</p>
</ng-template>