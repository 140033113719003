<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationServicesCreate" #investigationServicesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationServicesCreateOwn" #investigationServicesCreateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationRequestsCreate" #investigationRequestsCreate="appEnforcePermission"></div>
<!-- Permissions -->
<div class="flexContainer dataOptions">
  <!-- Service/Select toggle-->
  <div class="equal">
    <mat-button-toggle-group #investSelect="matButtonToggleGroup" value="services" [(ngModel)]="servicesRequestsToggle">
      <mat-button-toggle value="services">
        <mat-icon color="accent">assignment_turned_in</mat-icon>
        Services
      </mat-button-toggle>
      <mat-button-toggle value="requests">
        <mat-icon color="accent">assignment_return</mat-icon>
        Requests
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <!-- AssignedTo select -->
  <div class="equal" *ngIf="servicesRequestsToggle === 'services'">
    <mat-form-field>
      <mat-label>Assigned to</mat-label>
      <mat-select name="assignedTo" [(ngModel)]="assignedToUser">
        <mat-option>All</mat-option>
        <mat-option *ngFor="let item of investigation.InvestigationStaff" [value]="item.User.id"
          [innerHTML]="item.User | fullName"></mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex2">

  </div>

  <!-- content buttons -->
  <div class="equal textRight" [ngSwitch]="investSelect.value">
    <ng-container *ngSwitchCase="'services'">
      <button mat-raised-button class="actionButton" (click)="openAddService() " *ngIf="investigationServicesCreate?.enabled || investigationServicesCreateOwn?.enabled">
        <mat-icon color="accent">add</mat-icon>
        Add Service
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'requests'">
      <button mat-raised-button class="actionButton" (click)="openAddRequest()" *ngIf="investigationRequestsCreate?.enabled">
        <mat-icon color="accent">add</mat-icon>
        New Request
      </button>
    </ng-container>
  </div>
</div>

<!-- Content -->
<section [ngSwitch]="investSelect.value">
  <app-investigation-requests *ngSwitchCase="'requests'" [(requests)]="requests" (addCommentEvent)="updateRequest($event)" (deleteItemEvent)="deleteRequest($event)"></app-investigation-requests>
  <app-investigation-services *ngSwitchCase="'services'" (reload)="reloadInvestigation()" [assignedToUser]="assignedToUser" [investigation]="investigation"></app-investigation-services>
</section>