import { Pipe, PipeTransform } from '@angular/core';
import { IAssociation } from '../interfaces/association.interface';

@Pipe({
  name: 'primaryAssociation'
})
export class PrimaryAssociationPipe implements PipeTransform {

  transform(associations: IAssociation[]): any {
    if (!associations) return {};
    return associations.find(a => a.isPrimary) || associations[0];
  }
}

@Pipe({
  name: 'primarySort'
})
export class PrimarySortPipe implements PipeTransform {

  transform(associations: any[]): any {
    if (!associations) return [];
    return associations.sort((a, b) => b.isPrimary - a.isPrimary);
  }

}
