import gql from "graphql-tag";
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  title
  createdAt
  updatedAt
`;
export const investigationRoleConnectionQuery = gql`${buildConnectionQuery("InvestigationRole", "investigationRoleConnection", nodeFragment)}`;

export const addInvestigationRoleMutation = gql`mutation AddInvestigationRole($input: AddInvestigationRoleInput!) {
  addInvestigationRole(input: $input) { ${nodeFragment} }
}`;

export const updateInvestigationRoleMutation = gql`mutation UpdateInvestigationRole($input: UpdateInvestigationRoleInput!) {
  updateInvestigationRole(input: $input) { ${nodeFragment} }
}`;

export const removeInvestigationRoleMutation = gql`mutation RemoveInvestigationRole($id: ID!) {
  removeInvestigationRole(id: $id)
}`;

