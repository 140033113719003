import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortByDate"
})
export class ArraySortByDatePipe  implements PipeTransform {
  transform(array: Array<any>, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    const endDateNull = array.filter(a => a[field] === null);
    const notNullRecord = array.filter(a => a[field] !== null);
    notNullRecord.sort((a,b) => new Date(b[field]).valueOf() - new Date(a[field]).valueOf());
    return endDateNull.concat(notNullRecord);
  }
}