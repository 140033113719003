<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>{{model?.id ? 'Update' : 'Create'}} Expense Purpose</span>
    </div>
  </kendo-dialog-titlebar>
  
  <section>
    <form #form="ngForm" (submit)="onSubmit()">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <!-- <mat-form-field class="flex-grow-1" appearance="legacy">
            <mat-label>Expense Purpose</mat-label>
            <input matInput appNoWhiteSpace name="name" type="text" [(ngModel)]="model.name" required cdkFocusInitial #name="ngModel" />
            <mat-error *ngIf="!name?.valid && (name?.dirty || name?.touched) && model?.name?.length">Expense Purpose is invalid</mat-error>
          </mat-form-field> -->

          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Expense Purpose"></kendo-label>
            <kendo-textbox
              name="expensePurpose"
              #expensePurpose="ngModel"
              [(ngModel)]="model.name"
              placeholder="Expense Purpose"
              fillMode="outline"
              appNoWhiteSpace
              [clearButton]="false"
              [required]="true">
            </kendo-textbox>
            <kendo-formerror *ngIf="expensePurpose?.invalid && (expensePurpose?.dirty || expensePurpose?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="expensePurpose?.errors.required">Expense purpose cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>

        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Applies To"></kendo-label>
            <kendo-multiselect 
            #appliedTo="ngModel" 
            [(ngModel)]="model.ExpenseCategoryIds"
            name="appliedTo"
            [data]="expenseCategories"
            [checkboxes]="true"
            [valuePrimitive]="true"
            [autoClose]="false"
            textField="name" 
            valueField="id" 
            fillMode="outline"
            [tagMapper]="tagMapper"
            [placeholder]="'Applied To'"
            [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}"
            required>
              <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <span style="padding-left: 5px;">{{ dataItem?.name }}</span>
              </ng-template>
            </kendo-multiselect>
            <kendo-formerror *ngIf="!appliedTo?.valid && (appliedTo?.dirty || appliedTo?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="appliedTo?.errors?.required">
                Applies To cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>

        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="2">
          <kendo-formfield>
            <kendo-label text="Additional Details"></kendo-label>
            <kendo-textarea name="details" #details="ngModel" [(ngModel)]="model.details" 
            placeholder="Additional Details" fillMode="outline"
            class="min-height-96" resizable="both" appNoWhiteSpace></kendo-textarea>
            <kendo-formerror *ngIf="details?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="details?.errors.required">Additional Details cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </section>

  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSubmit()">
      Save
    </button>
  </kendo-dialog-actions>
</div>