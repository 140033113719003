import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiInvestigationMetricsClientCompanyConnection, IApiInvestigationMetricsFilter } from 'src/app/shared/modules/graphql/types/types';
import { GqlInvestigationMetricsClientCompanyService } from 'src/app/shared/modules/graphql/services/reports/investigation-metrics/investigation-metrics-client-company/investigation-metrics-client-company.service';

@Injectable()
export class InvestigationMetricsClientCompanyService {
  constructor(
    private reports: GqlInvestigationMetricsClientCompanyService
  ) { }

  public get(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationMetricsClientCompanyConnection> {
    return this.reports.get(filters, options || new ListPage());
  }

}
