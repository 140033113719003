import { StateService } from 'src/app/shared/services/state.service';
import { Component, Input, OnInit } from '@angular/core';
import { ContactService } from "src/app/shared/services";
import { IApiContact, IApiContactFilterType, IApiContactOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { ContactDataSource } from 'src/app/shared/services/contact/contact.datasource';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { CreateUpdateContactComponent } from '../../contacts/create-update-contact/create-update-contact.component';
import { filter } from 'rxjs/operators';
import _ from 'lodash';
import { DialogContentBase, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@UntilDestroy()
@Component({
  templateUrl: './investigation-select-contact-company-modal.component.html',
  styleUrls: ['./investigation-select-contact-company-modal.component.scss']
})
export class InvestigationSelectContactCompanyModalComponent extends DialogContentBase implements OnInit {
  @Input() data: any = null;
  private _dataSource: ContactDataSource;
  // filter values
  public firstName = "";
  public lastName = "";
  public selectedCompany = "";
  public selectedCompanyState = "";

  public get dataSource(): ContactDataSource {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public modal: any = {
    partyAdd: false
  };
  public recentContactCreatedId: string = null;

  constructor(
    private contactService: ContactService,
    public stateService: StateService,
    private loader: LoaderService,
    private dialogService: DialogService,
    public dialogRef: DialogRef
  ) {
    super(dialogRef);
    this._dataSource = new ContactDataSource(this.contactService);
    this.loader.attachObservable(this._dataSource.loading$);
    this.modal = this.data;
  }

  public ngOnInit() {
    this.searchContacts();
  }

  public searchContacts() {
    this.selectedCompanyState ? this.dataSource.applyFilter(IApiContactFilterType.CompanyState, this.selectedCompanyState) : this.dataSource.removeFilter(IApiContactFilterType.CompanyState);
    this.firstName ? this.dataSource.applyFilter(IApiContactFilterType.FirstName, this.firstName) : this.dataSource.removeFilter(IApiContactFilterType.FirstName);
    this.lastName ? this.dataSource.applyFilter(IApiContactFilterType.LastName, this.lastName) : this.dataSource.removeFilter(IApiContactFilterType.LastName);
    this.selectedCompany ? this.dataSource.applyFilter(IApiContactFilterType.Company, this.selectedCompany) : this.dataSource.removeFilter(IApiContactFilterType.Company);
    this.dataSource.listPage.orderBy = IApiContactOrderBy.LastName;
    this.dataSource.listPage.sortOrder = SortOrder.ASC;
    this.dataSource.listPage.limit = 10;
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public clearFilter() {
    this.selectedCompanyState = "";
    this.firstName = "";
    this.lastName = "";
    this.selectedCompany = "";
    this.searchContacts();
  }

  public selectContact(contact, branch = null) {
    let newContact = false
    /* trigger notification when contact is created from investigation and attached to investigation */ 
    if (this.recentContactCreatedId === contact?.id && this.modal.partyAdd) {
      newContact = true;
    }
    this.dialogRef.close({ contact, branch, newContact });
  }

  public findCompany({ Branches }, branchId) {
    const foundBranch = Branches.find((obj) => obj.id === branchId);
    return foundBranch ? foundBranch?.Company?.name : null;
  }

  public openContactModal(contact: IApiContact | null = null) {
    const dialog: DialogRef = this.dialogService.open({
      content: CreateUpdateContactComponent,
      width: '80%',
      maxWidth: '90%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as CreateUpdateContactComponent;
    dialogInstance.data = { 
      contactId: contact ? contact.id : null,
      partyAdd: this.modal?.partyAdd || false,
    }
    dialog.result
      .pipe(filter((v) => !_.isEmpty(v)))
      .subscribe((response: any) => {
        if (this.modal?.partyAdd) {
          this.recentContactCreatedId = response?.id || null
        }
        this.dataSource.load()
      });

  }

  public itemSelected(event, dataItem) {
    switch (event?.text) {
      case 'Edit':
        this.openContactModal(dataItem);
        break;
      default:
        break;
    }
  }

}
