<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageCertificationsCreate" #manageCertificationsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageCertificationsUpdate" #manageCertificationsUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageCertificationsDelete" #manageCertificationsDelete="appEnforcePermission"></div>
<!-- Permissions -->
<section class="content-wrapper">
  <kendo-gridlayout *ngIf="!selectableMode && manageCertificationsCreate?.enabled" [rows]="['auto']"
    [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
    <kendo-gridlayout-item class="text-right mb-0">
      <kendo-button class="mr-16" kendoButton themeColor="primary" iconClass="fa fa-plus" fillMode="solid" size="medium"
        (click)="addEdit(null)">
        New Certification Type
      </kendo-button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>

  <h4 class="page-title" *ngIf="selectableMode">Select certificate</h4>

  <app-certifications-filter (filterChange)="filterChange($event)" [certView]="true"></app-certifications-filter>

  <ng-container *ngIf="selectableMode">
    <kendo-chip *ngFor="let sel of selectionModel.selected" class="mr-16 mb-16 fw600" [size]="'medium'" [rounded]="'full'"
      [fillMode]="'solid'" [themeColor]="'base'" [removable]="true" (remove)="removeChip(sel)">
      {{sel?.title}}
    </kendo-chip>
  </ng-container>

  <kendo-grid
    class="certifications-certification-type-list mt-16"
    [data]="{
      data: data,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageOptions?.paginate($event);"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
    [selectable]="selectableMode ? { checkboxOnly: true, mode: 'multiple' } : false"
  >
    <kendo-grid-checkbox-column [width]="2" showSelectAll="true" *ngIf="selectableMode">
      <ng-template kendoGridHeaderTemplate="" let-dataItem let-rowIndex="rowIndex">
        <input type="checkbox" kendoCheckBox [checked]="selectionModel.selected.length > 0"
        [indeterminate]="selectionModel.selected.length > 0 && selectionModel.selected.length < data.length"
        (change)="onCheckAllChange($event)" />
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <input type="checkbox" kendoCheckBox [checked]="selectionModel.isSelected(dataItem)"
        (change)="onCheckRowChange(dataItem)" />
      </ng-template>
    </kendo-grid-checkbox-column>

    <kendo-grid-column
      field="TITLE"
      title="Certification Type Name"
      [width]="15"
      [sortable]="true"
      [headerClass]="'sort-header'"
      *ngIf="columnsToDisplay?.includes('TITLE')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          *ngIf="manageCertificationsUpdate?.enabled else onlyName"
          (click)="addEdit(dataItem)"
          tel=""
          class="text-decoration"
        >
          {{ dataItem?.title || '-' }}
        </a>
        <ng-template #onlyName>
          <span>{{ dataItem?.title || '-' }}</span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="AGENCY"
      title="Issuing Agency"
      [width]="30"
      [sortable]="true"
      [headerClass]="'sort-header'"
      *ngIf="columnsToDisplay?.includes('AGENCY')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem?.CertificationAgency?.name || '-' }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="STATE"
      title="State"
      [width]="15"
      [sortable]="true"
      [headerClass]="'sort-header'"
      *ngIf="columnsToDisplay?.includes('STATE')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ getStateName(dataItem?.state) }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="investigatorCount"
      title="Investigators"
      [width]="10"
      [sortable]="false"
      [headerClass]="'nocursor'"
      *ngIf="columnsToDisplay?.includes('investigatorCount')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.Certifications?.length }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="ALERTS"
      title="Alerts"
      [width]="10"
      [sortable]="false"
      [headerClass]="'nocursor'"
      *ngIf="columnsToDisplay?.includes('alerts')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <!-- <span class="dflex"> -->
          <kendo-badge-container *ngIf="expiredCount(dataItem.Certifications) > 0">
            <i class="fa-solid fa-circle-exclamation fa-xl" style="color:#f31700;"></i>
            <kendo-badge
              class="fw600"
              position="edge"
              size="medium"
              rounded="full"
              themeColor="primary"
              >
              {{expiredCount(dataItem.Certifications)}}
            </kendo-badge>
          </kendo-badge-container>
          <kendo-badge-container *ngIf="expiresSoonCount(dataItem.Certifications) > 0" >
            <i class="fa-solid fa-triangle-exclamation fa-xl ml-12" style="color:#ffc000;"> </i>
            <kendo-badge
              class="fw600"
              position="edge"
              size="medium"
              rounded="full"
              themeColor="primary"
              >
              {{expiresSoonCount(dataItem.Certifications)}}
            </kendo-badge>
          </kendo-badge-container>
        <!-- </span> -->
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="id"
        title="Action"
        [width]="8"
        [sortable]="false"
        [headerClass]="'nocursor'"
        *ngIf="actionItem?.length && columnsToDisplay?.includes('more')"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-dropdownbutton
            [data]="filterActionItem(dataItem)"
            themeColor="primary" fillMode="link" buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis"
            (itemClick)="itemSelected($event, dataItem)"
            [popupSettings]="{
              appendTo: 'component',
              animate: false,
              width: 250,
              popupClass: 'grid-action-items-menu'
            }">
          </kendo-dropdownbutton>
        </ng-template>
    </kendo-grid-column>

    <!-- No record -->
    <ng-template kendoGridNoRecordsTemplate>
      <p class="mt-24 mb-24">There are no results to display.</p>
    </ng-template>

  </kendo-grid>

  <kendo-gridlayout *ngIf="selectableMode" class="mt-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
    [cols]="['repeat(1,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-16 text-right">
      <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="done.emit([])">
        Cancel
      </button>
      <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="save()">
        Save
      </button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</section>
