<section class="clearfix" *ngIf="snapshot">
  <div class="slices" [ngSwitch]="snapshot.status" [ngClass]="{ 'rejected': !approved }">
    <span *ngSwitchDefault>{{ InvestigationReportStatus[snapshot?.status] || "N/A" }}</span>
    <kendo-badge class="fw600 mr-16 Low" position="none" size="small" rounded="full" themeColor="error"
      *ngSwitchCase="'DRAFT'">{{ InvestigationReportStatus[snapshot?.status] || "N/A" }}</kendo-badge>
    <kendo-badge class="fw600 mr-16 Low" position="none" size="small" rounded="full" themeColor="success"
      *ngSwitchCase="'TECH_REVIEW'">{{ InvestigationReportStatus[snapshot?.status] || "N/A" }}</kendo-badge>
    <kendo-badge class="fw600 mr-16 Low" position="none" size="small" rounded="full" themeColor="tertiary"
      *ngSwitchCase="'TECH_REVIEW_2'">{{ InvestigationReportStatus[snapshot?.status] || "N/A" }}</kendo-badge>
    <kendo-badge class="fw600 mr-16 Low" position="none" size="small" rounded="full" themeColor="tertiary"
      *ngSwitchCase="'READY_FOR_EDITOR'">{{ InvestigationReportStatus[snapshot?.status] || "N/A" }}</kendo-badge>
    <kendo-badge class="fw600 mr-16 Low" position="none" size="small" rounded="full" themeColor="success"
      *ngSwitchCase="'EDITOR_REVIEW'">{{ InvestigationReportStatus[snapshot?.status] || "N/A" }}</kendo-badge>
    <kendo-badge class="fw600 mr-16 Low" position="none" size="small" rounded="full" themeColor="success"
      *ngSwitchCase="'CLIENT_REVIEW'">{{ InvestigationReportStatus[snapshot?.status] || "N/A" }}</kendo-badge>
    <kendo-badge class="fw600 mr-16 Low" position="none" size="small" rounded="full" themeColor="success"
      *ngSwitchCase="'FINAL'">{{ InvestigationReportStatus[snapshot?.status] || "N/A" }}</kendo-badge>
  </div>
</section>
