<ng-container *ngIf="selectedTab === 'payroll-review'">
<kendo-gridlayout
  [rows]="['auto']"
  [gap]="{ rows: 5, cols: 16 }"
  [cols]="['repeat(12,minmax(0,1fr))']"
>
  <kendo-gridlayout-item class="month-view" [colSpan]="6" [col]="0" [row]="0">
    <div class="arrow" (click)="onDateRangeChanged('reverse')">
      <i class="fa-solid fa-angle-left"></i>
    </div>
    <div class="week-ending">
      <ng-container>
        {{ payPeriod?.start | customDate : "MM-dd-yyyy" }} -
        {{ payPeriod?.end | customDate : "MM-dd-yyyy" }}
      </ng-container>
    </div>
    <div
      class="arrow"
      (click)="onDateRangeChanged('forward')"
      [ngClass]="{
        'disabled': maxDate?.toDateString() === payPeriod?.payDate?.toDateString()
      }"
    >
      <i class="fa-solid fa-angle-right"></i>
    </div>
    <div style="padding: 0px 8px">
      <span>Paid Date: </span>
      <span>
        {{ payPeriod?.payDate | customDate : "MM-dd-yyyy" }}
      </span>
    </div>
    <div style="padding: 0px 16px; width: 250px;">
      <app-user-select-kendo
        label=""
        [userViewFilter]="userViewFilter"
        placeholder="Search staff      "
        [includeInactive]="false"
        (selectionChanged)="setUser($event)"
      ></app-user-select-kendo>
    </div>
  </kendo-gridlayout-item>
  <kendo-gridlayout-item
    class="right-section"
    [colSpan]="6"
    [col]="0"
    [row]="0"
  >
    <div>
      <button
        kendoButton
        themeColor="base"
        fillMode="solid"
        iconClass="fa-solid fa-download"
        class="mr-10"
        (click)="downloadCsvAction(true)"
      >
        Preview Unpaid
      </button>
    </div>
    <div>
      <button
        kendoButton
        themeColor="base"
        fillMode="solid"
        iconClass="fa-solid fa-download"
        class="mr-10"
        (click)="downloadCsvAction(false)"
      >
        Approved to Pay
      </button>
    </div>
    <div>
      <button kendoButton themeColor="light" fillMode="solid" class="mr-10" (click)="markAsPaidAction()" [disabled]="selectedTimeEntries?.selected?.length <= 0">
        Mark selected as Paid
      </button>
    </div>
    <div>
      <button kendoButton [size]="'medium'" themeColor="primary" class="mr-10" (click)="markAllAsPaidAction()">
        Mark all as Paid
      </button>
    </div>
  </kendo-gridlayout-item>
</kendo-gridlayout>
</ng-container>
<ng-container *ngIf="selectedTab === 'payroll-entry-list'">
  <kendo-gridlayout
  [rows]="['auto']"
  [gap]="{ rows: 5, cols: 16 }"
  [cols]="['repeat(12,minmax(0,1fr))']"
  
>
  <kendo-gridlayout-item class="month-view" [colSpan]="6" [col]="0" [row]="0">
    <div class="arrow" (click)="onDateRangeChanged('reverse')">
      <i class="fa-solid fa-angle-left"></i>
    </div>
    <div class="week-ending">
      <ng-container>
        {{ payPeriod?.start | customDate : "MM-dd-yyyy" }} -
        {{ payPeriod?.end | customDate : "MM-dd-yyyy" }}
      </ng-container>
    </div>
    <div
      class="arrow"
      (click)="onDateRangeChanged('forward')"
      [ngClass]="{
        'disabled': maxDate?.toDateString() === payPeriod?.payDate?.toDateString()
      }"
    >
      <i class="fa-solid fa-angle-right"></i>
    </div>
    <div style="padding: 0px 8px">
      <span>Paid Date: </span>
      <span>
        {{ payPeriod?.payDate | customDate : "MM-dd-yyyy" }}
      </span>
    </div>
  </kendo-gridlayout-item>
  <kendo-gridlayout-item
    class="right-section"
    [colSpan]="6"
    [col]="0"
    [row]="0"
  >
    <div class="p-0" style="width: 250px;">
      <app-user-select-kendo
        label=""
        [userViewFilter]="userViewFilter"
        placeholder="Search staff      "
        [includeInactive]="false"
        (selectionChanged)="setUser($event)"
      ></app-user-select-kendo>
    </div>
  </kendo-gridlayout-item>
</kendo-gridlayout>
</ng-container>