import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { take, map } from "rxjs/operators";
import { Observable, ReplaySubject } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { investigationRoleConnectionQuery, addInvestigationRoleMutation, removeInvestigationRoleMutation, updateInvestigationRoleMutation } from './investigation-role.operations';
import { IApiInvestigationRoleConnection } from '../../../types/types';
import { safeResolve } from '../../../rxjs';

import {
  IApiInvestigationRoleFilter,
  IApiAddInvestigationRoleInput,
  IApiUpdateInvestigationRoleInput,
  IApiRemoveInvestigationRoleInput,
  IApiInvestigationRole
} from '../../../types/types';

@Injectable()
export class GqlInvestigationRoleService {

  public constructor(
    private apollo: Apollo
  ) { }

  public getInvestigationRoles(filters: IApiInvestigationRoleFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationRoleConnection> {
    return this.apollo.query(connectionQuery(investigationRoleConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addInvestigationRole(input: IApiAddInvestigationRoleInput) {
    return this.apollo.mutate({
      mutation: addInvestigationRoleMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeInvestigationRole(input: IApiRemoveInvestigationRoleInput) {
    return this.apollo.mutate({
      mutation: removeInvestigationRoleMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateInvestigationRole(input: IApiUpdateInvestigationRoleInput) {
    return this.apollo.mutate({
      mutation: updateInvestigationRoleMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
