import { IApiEvidenceTransferConnection } from './../../../modules/graphql/types/types';
import { GqlEvidenceTransferService } from 'src/app/shared/modules/graphql/services/evidence/evidence-transfer/evidence-transfer.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import {
  IApiAddEvidenceTransferInput,
  IApiEvidenceTransferFilter,
  IApiUpdateEvidenceTransferInput,
  IApiRemoveEvidenceTransferInput
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class EvidenceTransferService {

  constructor(
    private gqlEvidenceTransferService: GqlEvidenceTransferService
  ) { }

  public get(filters?: IApiEvidenceTransferFilter[], options?: IGqlBaseOptions): Observable<IApiEvidenceTransferConnection> {
    return this.gqlEvidenceTransferService.getEvidenceTransfer(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddEvidenceTransferInput) {
    return this.gqlEvidenceTransferService.addEvidenceTransfer(input);
  }

  public remove(input: IApiRemoveEvidenceTransferInput) {
    return this.gqlEvidenceTransferService.removeEvidenceTransfer(input);
  }

  public update(input: IApiUpdateEvidenceTransferInput) {
    return this.gqlEvidenceTransferService.updateEvidenceTransfer(input);
  }

}
