import { connectionQuery } from './../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiBranchConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  branchesConnectionQuery, addBranchMutation,
  removeBranchMutation, getBranchByIdQuery,
  updateBranchMutation
} from './branch.operations';
import {
  IApiBranchFilter, IApiAddBranchInput, IApiUpdateBranchInput, IApiBranch
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlBranchService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiBranchFilter[], options: IGqlBaseOptions): Observable<IApiBranchConnection> {
    return this.apollo.query(connectionQuery(branchesConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(branchInput: IApiAddBranchInput) {
    return this.apollo.mutate({
      mutation: addBranchMutation,
      variables: { input: branchInput }
    }).pipe(safeResolve(true));
  }

  public remove(id: string) {
    return this.apollo.mutate({
      mutation: removeBranchMutation,
      variables: { id }
    }).pipe(safeResolve(true));
  }

  public update(branchInput: IApiUpdateBranchInput) {
    return this.apollo.mutate({
      mutation: updateBranchMutation,
      variables: { input: branchInput }
    }).pipe(safeResolve(true));
  }

  public getById(id: string): Observable<IApiBranch> {
    return this.apollo.query({
      query: getBranchByIdQuery,
      variables: { id: id }
    }).pipe(
      safeResolve(true)
    );
  }
}
