<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageUserRoleNotificationsUpdate" #manageUserRoleNotificationsUpdate="appEnforcePermission"></div>
<!-- Permissions -->
<section class="mt-16 mr-16 ml-24">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
        <kendo-gridlayout-item>
            <kendo-formfield class="kendo-formfield">
                  <kendo-label #advancedFilter text="Category"></kendo-label>
                  <kendo-multiselect
                    #advancedFilter
                    [checkboxes]="true"
                    [autoClose]="false"
                    textField="text" 
                    valueField="val"
                    [valuePrimitive]="true"
                    [data]="entity"
                    [rounded]="'full'"
                    [(ngModel)]="selectedEntity"
                    [placeholder]="'Select a category'"
                    [fillMode]="'outline'"
                    [tagMapper]="tagMapper"
                    [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}"
                  >
                    <ng-template kendoMultiSelectItemTemplate let-dataItem>
                      <span style="padding-left: 5px;">{{ dataItem }}</span>
                    </ng-template>
                  </kendo-multiselect>
              </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="filter-actions" style="justify-content: left;">
            <div class="applied-filter mr-16">
              <kendo-label text=""></kendo-label>
              <div>
                <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="filterTable()">Apply Filters</kendo-button>
              </div>
            </div>
            <div class="clear-filter mt-5">
              <kendo-label text=""></kendo-label>
              <div>
                <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearFilter()">Clear All</kendo-button>
              </div>
            </div>
          </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-grid
      class="notification-category td-pl-0 td-20-16"
      [data]="notificationTypes | keyvalue: sort?.[0]?.dir === 'asc' ? keyAscOrder : keyDescOrder"
      [sortable]="true"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      [navigatable]="false"
      [kendoGridExpandDetailsBy]="'key'"
      [(expandedDetailKeys)]="expandedDetailKeys"
    >

      <kendo-grid-column
        field="key"
        title="Category"
        [width]="95"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="font-weight600">{{ dataItem?.key }}</span>
        </ng-template>
      </kendo-grid-column>

      <ng-template
        kendoGridDetailTemplate
        let-item
      >
        <kendo-grid
            class="detail-items"
            [data]="item.value | sort: 'title'"
            [pageable]="false"
            [scrollable]="'none'"
            [selectable]="{enabled: true, checkboxOnly: true, cell: true}"
        > 
            <kendo-grid-column title="Notifications" [width]="50" [sortable]="false" [headerClass]="'nocursor'">
              <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="font-weight600">{{ dataItem?.title || '-' }}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Email" [width]="10" [sortable]="false">
              <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                <ng-container *ngIf="isEnabled(item, 'emailTemplate') else emailTemplate">
                  <input kendoCheckBox type="checkbox" [checked]="getCheckedByKey(item.value, 'isEmail')"
                    (click)="checkedMainNotificationByKey($event?.target?.checked, item.value, 'isEmail', item.key)"
                    [disabled]="!manageUserRoleNotificationsUpdate?.enabled" /> 
                    <span class="ml-2">{{column.title}}</span>
                </ng-container>
                <ng-template #emailTemplate>
                  <span>{{column.title}}</span>
                </ng-template>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <ng-container *ngIf="dataItem?.emailTemplate else emailCellTemplate">
                  <input class="ml-2" kendoCheckBox type="checkbox" [checked]="dataItem?.MethodSettings[0]?.isEmail"
                    (click)="checkedNotification($event?.target?.checked, 'isEmail', dataItem, item.key)"
                    [disabled]="!manageUserRoleNotificationsUpdate?.enabled" />
                </ng-container>
                <ng-template #emailCellTemplate>
                  <span>N/A</span>
                </ng-template>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="SMS" [width]="10" [sortable]="false">
              <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                <ng-container *ngIf="isEnabled(item, 'smsTemplate') else smsTemplate">
                  <input kendoCheckBox type="checkbox" [checked]="getCheckedByKey(item.value, 'isSms')"
                    (click)="checkedMainNotificationByKey($event?.target?.checked, item.value, 'isSms', item.key)"
                    [disabled]="!manageUserRoleNotificationsUpdate?.enabled" />
                    <span class="ml-2">{{column.title}}</span>
                </ng-container>
                <ng-template #smsTemplate>
                  <span>{{column.title}}</span>
                </ng-template>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <ng-container *ngIf="dataItem?.smsTemplate else smsCellTemplate">
                  <input class="ml-2" kendoCheckBox type="checkbox" [checked]="dataItem?.MethodSettings[0]?.isSms"
                    (click)="checkedNotification($event?.target?.checked, 'isSms', dataItem, item.key)"
                    [disabled]="!manageUserRoleNotificationsUpdate?.enabled" />
                </ng-container>
                <ng-template #smsCellTemplate>
                  <span>N/A</span>
                </ng-template>
              </ng-template>
            </kendo-grid-column>


            <kendo-grid-column title="System" [width]="10" [sortable]="false">
              <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                <ng-container>
                  <input kendoCheckBox type="checkbox" [checked]="getCheckedByKey(item.value, 'isSystem')"
                    (click)="checkedMainNotificationByKey($event?.target?.checked, item.value, 'isSystem', item.key)"
                    [disabled]="!manageUserRoleNotificationsUpdate?.enabled" />
                    <span class="ml-2">{{column.title}}</span>
                </ng-container>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                  <input class="ml-2" kendoCheckBox type="checkbox" [checked]="dataItem?.MethodSettings[0]?.isSystem"
                    (click)="checkedNotification($event?.target?.checked, 'isSystem', dataItem, item.key)"
                    [disabled]="!manageUserRoleNotificationsUpdate?.enabled" />
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="All" [width]="10" [sortable]="false">
              <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                <ng-container>
                  <input kendoCheckBox type="checkbox" [checked]="getCheckedByKey(item.value, 'sendAll')"
                    (click)="checkedMainNotificationByKey($event?.target?.checked, item.value, 'sendAll', item.key)"
                    [disabled]="!manageUserRoleNotificationsUpdate?.enabled" />
                    <span class="ml-2">{{column.title}}</span>
                    <span
                      kendoTooltip
                      position="left"
                      [tooltipTemplate]="templateInfo"
                      filter="i"
                      tooltipClass="tooltipwidth300">
                      <i class="fa-solid fa-circle-info ml-2"></i>
                    </span>
                    <ng-template #templateInfo>
                      Enabling this will send a notification to all selected permission role members. If not enabled this will send a notification to investigation staff members only.
                    </ng-template>
                </ng-container>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                  <input class="ml-2" kendoCheckBox type="checkbox" [checked]="dataItem?.MethodSettings[0]?.sendAll"
                    (click)="checkedNotification($event?.target?.checked, 'sendAll', dataItem, item.key)"
                    [disabled]="!manageUserRoleNotificationsUpdate?.enabled" />
              </ng-template>
            </kendo-grid-column>

            <ng-template kendoGridToolbarTemplate [position]="'bottom'" *ngIf="manageUserRoleNotificationsUpdate?.enabled">
              <kendo-grid-spacer></kendo-grid-spacer>
              <button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="saveChanges(item.value)">Save Changes</button>
            </ng-template>

        </kendo-grid>
      </ng-template>
    </kendo-grid>
</section>