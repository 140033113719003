import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  comment
  Service {
    id
  }
  CreatedBy {
    id
    firstName
    lastName
  }
  createdAt
  updatedAt
`;

export const serviceStatusConnectionQuery = gql`${buildConnectionQuery("ServiceStatus", "serviceStatusConnection", nodeFragment)}`;

export const addServiceStatusMutation = gql`mutation createServiceStatus($input: AddServiceStatusInput!) {
  addServiceStatus(input: $input) { ${nodeFragment} }
  }`;

export const removeServiceStatusMutation = gql`mutation removeServiceStatus($input: RemoveServiceStatusInput!) {
  removeServiceStatus(input: $input) { ${nodeFragment} }
}`;

export const updateServiceStatusMutation = gql`mutation updateServiceStatus($input: UpdateServiceStatusInput!) {
  updateServiceStatus(input: $input) { ${nodeFragment} }
}`;

export const getServiceStatusByIdQuery = gql`query getServiceStatusById($id: ID!) {
  getServiceStatusById(id: $id) {${nodeFragment}}
}`;


