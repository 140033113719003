import { Component, Input, ElementRef, Output, AfterViewInit, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IMapUser } from './google-map.interfaces';

interface IMapMarker {
  marker: google.maps.Marker;
  entityId: string;
}

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, AfterViewInit {
  @Input() lat: number;
  @Input() lon: number;

  @Input() set mapUsers(users: IMapUser[]) {
    this._mapMarkers.forEach(m => m.setMap(null));
    this._mapMarkers = [];

    this._renderers.forEach(r => r.setMap(null));
    this._renderers = [];

    if (users?.length === 0 && this._map) this._resetBounds();

    users?.filter(({ status }) => status === google.maps.DirectionsStatus.OK).forEach((mapUser, i) => {
      const renderer = new google.maps.DirectionsRenderer();

      renderer.setDirections(mapUser.response);
      renderer.setOptions({ routeIndex: i, suppressMarkers: true });
      renderer.setMap(this._map);

      this._renderers.push(renderer);

      mapUser.marker.setMap(this._map);
      this._mapMarkers.push(mapUser.marker);
    });
  }

  private _markers: IMapMarker[] = [];
  @Input() set markers(markers: [number, number, string][]) {
    if (!markers) return;

    this._markers = markers.map(m => {
      const marker = new google.maps.Marker({
        position: { lat: m[0], lng: m[1] },
        icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
      });

      marker.setMap(this._map);
      marker.setIcon('http://maps.google.com/mapfiles/ms/icons/green-dot.png');

      google.maps.event.addListener(marker, 'click', () => {
        this._iconClick.next(m[2]);
        this.selectedLocation = m[2];
      });

      return { marker, entityId: m[2] };
    });
  }

  private _selectedLocationId: string;
  @Input() set selectedLocation(id) {
    if (!id) return;
    this._selectedLocationId = id;
    this._handleSelectedLocationChange();
  }

  private _iconClick = new Subject<string>();
  @Output() iconClick: Observable<string> = this._iconClick;

  private _mapEl: HTMLElement;
  private _map?: google.maps.Map;
  private _renderers: google.maps.DirectionsRenderer[] = [];
  private _mapMarkers: google.maps.Marker[] = [];

  constructor(private readonly _elementRef: ElementRef) { }

  public ngOnInit() {
    this._mapEl = this._elementRef.nativeElement.querySelector('.map-container');
    this._map = new google.maps.Map(this._mapEl, {
      center: { lat: this.lat, lng: this.lon }
    });
  }

  ngAfterViewInit() {
    google.maps.event.addDomListener(window, "resize", () => {
      google.maps.event.trigger(this._map, "resize");
      this._resetBounds();
    });

    this._setMarker();
    this._resetBounds();
  }

  private _setMarker() {
    const marker = new google.maps.Marker({
      position: { lat: this.lat, lng: this.lon },
    });
    marker.setMap(this._map);
  }

  private _handleSelectedLocationChange() {
    this._markers.map(m => {
      // TODO: this sets display of selected marker,
      // display of selection could be enhanced (via better icon or animation)
      if (m.entityId === this._selectedLocationId) {
        m.marker.setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png');
      }
      else { m.marker.setIcon('http://maps.google.com/mapfiles/ms/icons/green-dot.png'); }
    });
  }

  private _resetBounds() {
    const bounds = { north: this.lat, east: this.lon, south: this.lat, west: this.lon };
    this._map.fitBounds(bounds);

    // NOTE: dislike using timeouts with an arbitrary value but GoogleMaps needs time to update/paint apparently before it can apply zoom :(
    setTimeout(() => {
      const center = this._map.getCenter();
      this._map.setCenter(center);
      this._map.setZoom(14);
    }, 250);
  }

}
