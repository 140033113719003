import { Component, Input, OnInit } from '@angular/core';
import { UserDataSource, UserService } from '../../services/user';
import { SelectionModel } from '@angular/cdk/collections';
import { IApiInvestigationRole, IApiUser, IApiUserFilterType, IApiUserOrderBy } from '../../modules/graphql/types/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InvestigationRoleService } from '../../services';
import { LoaderService } from '../../modules/loader/loader.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from '../../modules/graphql/enums/generic.enums';

export interface ISelectStaffModalData {
  user: IApiUser;
  staffType: 'TECH_REVIEWER' | 'REGIONAL_MANAGER',
  excludeUserId: string | null
}

@UntilDestroy()
@Component({
  selector: 'app-select-staff',
  templateUrl: './select-staff.component.html',
  styleUrls: ['./select-staff.component.scss']
})
export class SelectStaffComponent extends DialogContentBase implements OnInit {

  @Input() public data: ISelectStaffModalData;
  public dataSource: UserDataSource;
  // Need to use select as an ID so we can toggle existing users passed into object. Otherwise, didn't work.
  public selection = new SelectionModel<string>(true, []);
  // Still need to retain their info to show user name in the UI and properly save in next step.
  public selectionUserInfo: Partial<IApiUser[]> = [];

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public content: IApiUser[] = [];

  public get allSelected() {
    return this.content.length && (this.selection.selected.length === this.content.length);
  }

  public selectedPosition = null;
  public search = '';

  public positions: IApiInvestigationRole[] = [];

  public displayedColumns = [
    'select',
    'name',
    'position',
    'address',
    'email',
    'phone'
  ];

  public orderByOptions = IApiUserOrderBy;
  public sort: SortDescriptor[] = [
    {
      field: IApiUserOrderBy.Lastname,
      dir: "asc",
    },
  ];
  constructor(
    public dialogRef: DialogRef,
    private userService: UserService,
    private roleService: InvestigationRoleService,
    private loaderService: LoaderService,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.selection.clear();
    this.selectionUserInfo = [];
    if (this.data.user.AssignedStaff?.length && this.data.staffType === 'TECH_REVIEWER') {
      this.data.user.AssignedStaff.forEach((e) => {
        this.selection.select(e.id);
        this.selectionUserInfo.push(e);
      })
    };
    if (this.data.user.AssignedRegionalStaff?.length && this.data.staffType === 'REGIONAL_MANAGER') {
      this.data.user.AssignedRegionalStaff.forEach((e) => {
        this.selection.select(e.id);
        this.selectionUserInfo.push(e);
      })
    }

    this.roleService.investigationRoles.subscribe((roles) => this.positions = roles);
    this.dataSource = new UserDataSource(this.userService);
    this.dataSource.applyFilter(IApiUserFilterType.ViewListView, "true");
    this.loaderService.attachObservable(this.dataSource.loading$);
    if (this.data.staffType === 'TECH_REVIEWER') {
      if (this.data.user.AssignedStaff?.length)
        this.dataSource.applyFilter(IApiUserFilterType.SelectedTechReviwerStaff, JSON.stringify(this.selection.selected));
      else
        this.dataSource.applyFilter(IApiUserFilterType.ExcludeAssignedToTechReviewer, "true");
    }
    if (this.data.staffType === 'REGIONAL_MANAGER') {
      if (this.data?.user?.AssignedRegionalStaff?.length)
        this.dataSource.applyFilter(IApiUserFilterType.SelectedRegionalStaff, JSON.stringify(this.selection.selected));
      else
        this.dataSource.applyFilter(IApiUserFilterType.ExcludeAssignedToRegionalManager, "true");
    }
    if (this.data.excludeUserId) {
      this.dataSource.applyFilter(IApiUserFilterType.NotUserId, JSON.stringify([this.data.excludeUserId]));
    }
    this.dataSource.contents$.pipe(untilDestroyed(this)).subscribe((content) => this.content = content);
    this.load();
  }

  public masterToggle({ checked }) {
    checked ? this.content.forEach((e) => this.selection.select(e.id)) : this.selection.clear();
    this.content.forEach((e) => this.selectionUserInfo.push(e));
  }

  public select({ checked }, staff: IApiUser) {
    checked ? this.selection.select(staff.id) : this.selection.deselect(staff.id);
    // Only add user once
    if (!this.selectionUserInfo.filter(e => e.id === staff.id).length) {
      this.selectionUserInfo.push(staff);
    }
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiUserOrderBy.Lastname;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  };

  public positionSelected({ title }) {
    this.selectedPosition = title === 'All Position' ? null : title;
    if (this.selectedPosition) this.dataSource.applyFilter(IApiUserFilterType.Position, JSON.stringify([this.selectedPosition]));
    else this.dataSource.removeFilter(IApiUserFilterType.Position);
    this.load();
  }

  public onSearchChange() {
    this.dataSource.applyFilter(IApiUserFilterType.Search, this.search);
    this.load();
  }

  public primary(arr: { isPrimary: boolean }[]) {
    const found = arr.find((v) => v.isPrimary);
    return found ? found : arr[0];
  }

  public findUser(id) {
    return this.selectionUserInfo.find(e => e.id === id);
  }

  public cancel() {
    this.dialogRef.close(null);
  }

  public save() {
    this.dialogRef.close(this.selectionUserInfo.filter(e => this.selection.selected.includes(e.id)));
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }
}
