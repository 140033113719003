import { IApiQueryGenerateInvestigationMetricsCsvArgs, IApiInvestigationMetricsFilter, IApiInvestigationMetricsConnection } from '../../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { GqlEvidencenReportService } from 'src/app/shared/modules/graphql/services/evidence/evidence-report/evidence-report.service';

@Injectable()
export class EvidenceReportService {
  constructor(
    private reports: GqlEvidencenReportService
  ) { }

  public csvReport(input: IApiQueryGenerateInvestigationMetricsCsvArgs): Observable<string> {
    return this.reports.getCsvReport(input);
  }

  public get(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationMetricsConnection> {
    return this.reports.getEvidenceReport(filters, options || new ListPage());
  }

}
