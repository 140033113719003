import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessRestrictedComponent } from './access-restricted.component';

const routes: Routes = [
  {
    path: "",
    component: AccessRestrictedComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccessRestrictedRoutingModule { }
