<div [dialogRef]="dialog" (appClickOutside)="close()">
<kendo-dialog-titlebar (close)="close()">
  <div class="modal-title ">
    <span>Why are you modifying this investigation?</span>
  </div>
</kendo-dialog-titlebar>
<div class="model-body">
  <form #form="ngForm">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
      <ng-container *ngIf="!noteOnly">

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [for]="category" text="Category"></kendo-label>
            <kendo-dropdownlist #category [data]="categories" name="category" [valuePrimitive]="true"
              [(ngModel)]="model.updateCategory" fillMode="outline" [filterable]="false"
              [popupSettings]="{appendTo: 'component', animate: false}">\
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span class="not-placeholder"> {{ (dataItem | titlecase) }} </span>
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span class="not-placeholder"> {{ (dataItem | titlecase) }} </span>
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [for]="Type" text="Type"></kendo-label>
            <kendo-dropdownlist #Type [data]="types" name="Type" [valuePrimitive]="true" [(ngModel)]="model.updateType"
              fillMode="outline" [filterable]="false" [popupSettings]="{appendTo: 'component', animate: false}">\
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span class="not-placeholder"> {{ (dataItem | titlecase) }} </span>
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span class="not-placeholder"> {{ (dataItem | titlecase) }} </span>
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </ng-container>
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label [for]="comment" text="Reason for this change" class="asterisk"></kendo-label>
          <kendo-textarea #comment name="comment" [(ngModel)]="model.comment" placeholder="Enter Reason" appNoWhiteSpace
            fillMode="outline" class="min-height-96" resizable="both" required></kendo-textarea>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="form?.controls?.comment?.errors?.required">Reason cannot be
              left blank</span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </form>
</div>
<kendo-dialog-actions layout="end">
  <button kendoButton [size]="'medium'" themeColor="light" class="mr-16" (click)="close()" *ngIf="!noteOnly">I'm not
    done
    editing...</button>
  <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" [disabled]="!form.valid"
    (click)="save()">Done</button>
</kendo-dialog-actions>
</div>