import { from } from "rxjs";
import { catchError, finalize, tap } from 'rxjs/operators';
export const ApiConnection = (limit?: number) => (target: object, propertyKey: string, descriptor: PropertyDescriptor) => {
  const originalMethod = descriptor.value;

  descriptor.value = function(filters = [], ...rest) {
    this.isLoading = true;
    if (limit) this.listPage.limit = limit;
    return new Promise<void>((resolve, reject) => {
      from(originalMethod.apply(this, [filters.length ? filters : (this.lastFilters || []), ...rest])).pipe(
        tap(({ edges, totalCount, pageInfo: info }) => {
          this.pageInfoSubject.next({ totalCount, info });
          this.dataSubject.next(edges.map(({ node }) => node));
        }),
        catchError(err => {
          reject(err);
          return Promise.resolve();
        }),
        finalize(() => this.isLoading = false)
      ).subscribe(() => resolve());
    });
  };

  return descriptor;
};
