import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  key
  eventType
  entity
  action
  detail
  title
  MethodSettings {
    id
    isEmail
    isSms
    isSystem
    sendAll
    PermissionRole {
      id
    }
    NotificationType {
      id
    }
  }
  smsTemplate
  emailTemplate
  template
  description
  createdAt
  updatedAt
`;

export const notificationTypesConnectionQuery = gql`${buildConnectionQuery("NotificationType", "notificationTypesConnection", nodeFragment)}`;

export const addNotificationTypeMutation = gql`mutation createNotificationType($input: AddNotificationTypeInput!) {
  addNotificationType(input: $input) { ${nodeFragment} }
  }`;

export const removeNotificationTypeMutation = gql`mutation removeNotificationType($input: RemoveNotificationTypeInput!) {
  removeNotificationType(input: $input) { ${nodeFragment} }
}`;

export const updateNotificationTypeMutation = gql`mutation updateNotificationType($input: UpdateNotificationTypeInput!) {
  updateNotificationType(input: $input) { ${nodeFragment} }
}`;

export const getNotificationTypeByIdQuery = gql`query getNotificationTypeById($id: ID!) {
  getNotificationTypeById(id: $id) {${nodeFragment}}
}`;
