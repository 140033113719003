<section class="mat-typography">

    <h2 class="mat-h2 modalHeader">Investigation Services</h2>
    <mat-accordion multi="true">
        <mat-expansion-panel *ngFor="let table of tables" expanded="true">
            <!-- Not sure how the data will return but this is intended to create n number of tables, 
                one per service with the corresponding history displayed in this table  -->
            <mat-expansion-panel-header>
                <!-- This should be the name of the service -->
                {{ table[0].service }}
            </mat-expansion-panel-header>
            <table mat-table [dataSource]="table" class="mat-elevation-z4">
                <ng-container matColumnDef="update">
                    <th mat-header-cell *matHeaderCellDef>Updated</th>
                    <td mat-cell *matCellDef="let table" [innerHTML]="table.updatedAt | customDate: 'MM-dd-yyyy'" ></td>
                </ng-container>
                <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef>Service</th>
                    <td mat-cell *matCellDef="let table" [innerHTML]="table.service"> </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let table" [innerHTML]="table.status"> </td>
                </ng-container>
                <ng-container matColumnDef="comment">
                    <th mat-header-cell *matHeaderCellDef>Comment</th>
                    <td mat-cell *matCellDef="let table" [innerHTML]="table.comment"> </td>
                </ng-container>
                <ng-container matColumnDef="updatedBy">
                    <th mat-header-cell *matHeaderCellDef>Updated By</th>
                    <td mat-cell *matCellDef="let table" [innerHTML]="table.updatedBy"> </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
            </table>
        </mat-expansion-panel>

    </mat-accordion>
</section>