<div [dialogRef]="dialog" (appClickOutside)="close()">
  <form #form="ngForm">
    <!-- Modal Header -->
    <kendo-dialog-titlebar (close)="close()">
      <div class="modal-title">
        <span>{{ !data?.id ? 'Create' : 'Update' }} Evidence Storage Location</span>
      </div>
    </kendo-dialog-titlebar>
    <section>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
        <!-- name -->
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Location Name"></kendo-label>
            <kendo-textbox name="locationName" #locationName="ngModel" [(ngModel)]="model.name"
              placeholder="Location Name" fillMode="outline" appNoWhiteSpace [clearButton]="false" [required]="true"
              cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror *ngIf="locationName?.invalid && (locationName?.dirty || locationName?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="locationName?.errors.required">Location Name
                cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Location Type"></kendo-label>
            <kendo-dropdownlist [data]="locationTypeNames | keyvalue" name="type" #type="ngModel"
              [(ngModel)]="model.type" [valuePrimitive]="true" fillMode="outline" textField="key" valueField="value"
              [defaultItem]="{value: null, key: 'Select Location Type'}"
              [popupSettings]="{appendTo: 'component', animate: false}" required>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.value ? 'not-placeholder': 'placeholder'">{{ dataItem?.value ? dataItem?.key
                  +
                  " Locations" : dataItem?.key }} </span>
              </ng-template>
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span> {{ dataItem?.value ? dataItem?.key + " Locations" : dataItem?.key }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!type?.valid && (type?.dirty || type?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="type?.errors?.required">
                Location Type cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="checkbox-item">
          <div class="kendo-formfield">
            <input type="checkbox" kendoCheckBox #hasDetails name="hasdetails" [(ngModel)]="model.hasDetails" />
            <kendo-label [for]="hasDetails" class="k-checkbox-label cursor font-weight-600 checkbox-margin mb-0"
              [text]="'Has Details (section/row)'"></kendo-label>
          </div>
        </kendo-gridlayout-item>
        <!-- companyDetails -->
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Storage Company Name"></kendo-label>
            <kendo-textbox name="companyName" #companyName="ngModel" [(ngModel)]="model.companyName"
              placeholder="Storage Company Name" fillMode="outline" appNoWhiteSpace [clearButton]="false"
              [required]="true" cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror *ngIf="companyName?.invalid && (companyName?.dirty || companyName?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="companyName?.errors.required">Storage Company Name
                cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!-- contact name -->
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="'asterisk'" text="Contact Name"></kendo-label>
            <kendo-textbox name="contactName" #contactName="ngModel" [(ngModel)]="model.contactName"
              placeholder="Contact Name" fillMode="outline" appNoWhiteSpace [clearButton]="false" [required]="true"
              cdkFocusInitial>
            </kendo-textbox>
            <kendo-formerror *ngIf="contactName?.invalid && (contactName?.dirty || contactName?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="contactName?.errors.required">Contact Name
                cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!-- phone -->
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Phone"></kendo-label>
            <kendo-textbox name="phone" #phone="ngModel" [(ngModel)]="model.Phone.number" placeholder="Phone"
              fillMode="outline" appNoWhiteSpace [clearButton]="false" [required]="false" cdkFocusInitial>
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item colSpan="3">
          <app-address-kendo [name]="'address0'" [config]="{hideType: true, columnCount: 3}" [(ngModel)]="model.Address" [(address)]="model.Address"
            (addressChanged)="model.Address = $event"></app-address-kendo>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [colSpan]="3">
          <kendo-formfield>
            <kendo-label text="Additional Details"></kendo-label>
            <kendo-textarea name="additionalDetails" #additionalDetails="ngModel" [(ngModel)]="model.additionalDetails"
              placeholder="Additional Details" fillMode="outline" class="min-height-96" resizable="both"
              appNoWhiteSpace></kendo-textarea>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-dialog-actions layout="end">
        <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
          Cancel
        </button>
        <button kendoButton [size]="'medium'" themeColor="primary" [disabled]="!form.valid" class="mr-16"
          (click)="onSubmit()">
          {{data?.id ? 'Update' : 'Add' }}
        </button>
      </kendo-dialog-actions>
    </section>
  </form>
</div>
