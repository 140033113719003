import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsRiskTypeConnection, IApiInvestigationMetricsTechReviewerConnection } from '../../../../types/types';
import { IGqlBaseOptions } from '../../../../interfaces';
import { connectionQuery } from '../../../../helpers.class';
import { investigationMetricsTechReviewerConnection } from './investigation-metrics-tech-reviewer.operations';

@Injectable()
export class GqlInvestigationMetricsTechReviewerService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiInvestigationMetricsFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationMetricsTechReviewerConnection> {
    return this.apollo.query(connectionQuery(investigationMetricsTechReviewerConnection, filters, options)).pipe(
      safeResolve(true)
    );
  }

}
