<section class="content-part" id="time-and-expense">
    <kendo-tabstrip #tabstrip [scrollable]="true" class="mobile-tabs" (tabSelect)="changeTab($event)">
        <kendo-tabstrip-tab [title]="TABS.MY_HOURS" [selected]="selectedTab === TABS.MY_HOURS">
            <ng-template kendoTabTitle>
                <span>My Hours</span>
            </ng-template>
            <ng-template kendoTabContent>
                <div appScrollNearEnd [threshold]="1" (nearEnd)="onNearEndScrollTimeEntry()">
                    <app-mobile-time-exp-date-kendo-filter [mode]="'week'" (dateChange)="dateChangeWeek($event)"
                    (dateRangeChange)="dateRangeChangeTimeEntry($event)"></app-mobile-time-exp-date-kendo-filter>

                    <hr class="divider-line" />
                    
                    <kendo-gridlayout class="p-16" [rows]="['auto']" [gap]="{rows: 0, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
                        <kendo-gridlayout-item class="mb-0">
                            <button kendoButton themeColor="primary" fillMode="solid" [iconClass]="'fa-solid fa-fa-plus'"
                            style="width: 100%;" [size]="'large'" (click)="addExpenses('MY-HOUR')">Add Hours</button>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>

                    <hr class="divider-line" />

                    <section class="list p-16">
                        <kendo-gridlayout class="display-total" [rows]="['auto']" [gap]="{rows: 5, cols: 24}" [cols]="['auto auto auto']">
                            <kendo-gridlayout-item class="mb-0 text-center">
                                  <h6 class="fs-12">Total Expenses</h6>
                                  <h6 class="total">{{timeEntriesCount?.totalHours || 0}}</h6>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0 text-center">
                                <h6 class="fs-12">Billable Expenses</h6>
                                <h6 class="total">{{timeEntriesCount?.billableHours || 0}}</h6>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0 text-center">
                                <h6 class="fs-12">Non-Billable Expenses</h6>
                                <h6 class="total">{{timeEntriesCount?.nonBillableHours || 0}}</h6>
                            </kendo-gridlayout-item>
                        </kendo-gridlayout>
                     
                        <p class="mt-24 mb-24 text-center" *ngIf="timeEntries?.length === 0">There are no results to display.</p>

                        <ng-container *ngFor="let item of timeEntries; let first = first; trackBy: trackByIndex" >
                            <kendo-card [ngClass]="first ? 'mt-16' : 'mt-16 mb-16'" [width]="'100%'">
                                <kendo-card-header>
                                    <div class="header-id-action mb-8">
                                        <a *ngIf="item?.Investigation?.id else unlinked" [routerLink]="['/investigations', item?.Investigation?.id]"
                                            class="mobile-id-link" style="padding-left: 42px;">
                                            ID: {{ item?.Investigation?.nefcoNumber }}
                                        </a>
                                        <button class="actions" kendoButton themeColor="secondary" fillMode="link" [iconClass]="'fa-solid fa-trash'"
                                        [size]="'large'" (click)="deleteTimeEntry(item)"></button>
                                    </div>
                                    <div class="header-date text-center">
                                        <h6><small>Date</small> {{item?.workday | customDate : "MM/dd/yyyy"}}</h6>
                                    </div>
                                </kendo-card-header>
                                <kendo-card-body>
                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Type</small></h6>
                                        <h6 class="kendo-caption-body text-overflow">{{item?.Type?.name}}</h6>
                                    </div>
                                    
                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Bill</small></h6>
                                        <h6 class="kendo-caption-body">{{item?.hours || '-'}}</h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Non-Bill</small></h6>
                                        <h6 class="kendo-caption-body">{{item?.nonBillableHours || '-'}}</h6>
                                    </div>

                                    <div class="equal mb-0">
                                        <h6 class="kendo-caption"><small>Paid</small></h6>
                                        <h6 class="kendo-caption-body">{{item?.paidDate ? 'Paid' : 'Not Paid'}}</h6>
                                    </div>
                                </kendo-card-body>
                            </kendo-card>
                        </ng-container>
                    </section>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="TABS.MY_EXPENSES" [selected]="selectedTab === TABS.MY_EXPENSES">
            <ng-template kendoTabTitle>
                <span>My Expenses</span>
            </ng-template>
            <ng-template kendoTabContent>
                <div appScrollNearEnd [threshold]="1" (nearEnd)="onNearEndScrollExpensesMileage()">

                    <app-mobile-time-exp-date-kendo-filter [mode]="'month'" [getRange]="true" (WeekMonthRangeChange)="dateChangeMonth($event)"
                        (dateRangeChange)="dateRangeChangeExpenseMileage($event)">
                    </app-mobile-time-exp-date-kendo-filter>

                    <hr class="divider-line" />

                    <kendo-gridlayout class="p-16" [rows]="['auto']" [gap]="{rows: 0, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
                        <kendo-gridlayout-item class="mb-0">
                            <button kendoButton themeColor="primary" fillMode="solid" [iconClass]="'fa-solid fa-fa-plus'"
                            style="width: 100%;" [size]="'large'" (click)="addExpenses('MY-EXPENSES')">Add Expenses</button>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item class="mb-0">
                            <button kendoButton themeColor="primary" fillMode="solid" [iconClass]="'fa-solid fa-fa-plus'"
                            style="width: 100%;" [size]="'large'" (click)="addExpenses('MY-MILEAGE')">Add Mileage</button>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>

                    <hr class="divider-line" />

                    <section class="list p-16">
                        <kendo-gridlayout class="display-total" [rows]="['auto']" [gap]="{rows: 5, cols: 24}" [cols]="['auto auto auto']">
                            <kendo-gridlayout-item class="mb-0 text-center">
                                  <h6 class="fs-12">Total Expenses</h6>
                                  <h6 class="total">{{expenseMileageCount?.totalExpense || 0}}</h6>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0 text-center">
                                <h6 class="fs-12">Billable Expenses</h6>
                                <h6 class="total">{{expenseMileageCount?.billableExpense || 0}}</h6>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0 text-center">
                                <h6 class="fs-12">Non-Billable Expenses</h6>
                                <h6 class="total">{{expenseMileageCount?.nonBillableExpense || 0}}</h6>
                            </kendo-gridlayout-item>
                        </kendo-gridlayout>

                        <kendo-gridlayout class="display-total mt-8" [rows]="['auto']" [gap]="{rows: 5, cols: 24}" [cols]="['auto auto auto']">
                            <kendo-gridlayout-item class="mb-0 text-center">
                                  <h6 class="fs-12">Total Expenses</h6>
                                  <h6 class="total">{{expenseMileageCount?.totalMileage || 0}}</h6>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0 text-center">
                                <h6 class="fs-12">Billable Expenses</h6>
                                <h6 class="total">{{expenseMileageCount?.billableMileage || 0}}</h6>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0 text-center">
                                <h6 class="fs-12">Non-Billable Expenses</h6>
                                <h6 class="total">{{expenseMileageCount?.nonBillableMileage || 0}}</h6>
                            </kendo-gridlayout-item>
                        </kendo-gridlayout>

                        <p class="mt-24 mb-24 text-center" *ngIf="expenses?.length === 0">There are no results to display.</p>

                        <ng-container *ngFor="let item of expenses; let first = first; trackBy: trackByIndex" >
                            <kendo-card [ngClass]="first ? 'mt-16' : 'mt-16 mb-16'" [width]="'100%'">
                                <kendo-card-header>
                                    <div class="header-id-action mb-8">
                                        <a *ngIf="item?.Investigation?.id else unlinked" [routerLink]="['/investigations', item?.Investigation?.id]"
                                            class="mobile-id-link" style="padding-left: 42px;">
                                            ID: {{ item?.Investigation?.nefcoNumber }}
                                        </a>
                                        <button class="actions" kendoButton themeColor="secondary" fillMode="link" [iconClass]="'fa-solid fa-trash'"
                                        [size]="'large'" (click)="deleteExpenses(item)"></button>
                                    </div>
                                    <div class="header-date text-center">
                                        <h6><small>Entry Date</small> {{item?.createdAt | customDate : "MM/dd/yyyy"}}</h6>
                                    </div>
                                </kendo-card-header>
                                <kendo-card-body>
                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Expense</small></h6>
                                        <h6 class="kendo-caption-body">{{item?.ExpenseItem?.name || '-'}}</h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Purpose</small></h6>
                                        <h6 class="kendo-caption-body">{{item?.ExpensePurpose?.name || '-'}}</h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Cost/Miles</small></h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.ExpenseItem?.name !== 'Mileage'">{{(item?.billableQuantity + item?.nonBillableQuantity) | number: '1.2-2' | currency: 'USD'}}</h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.ExpenseItem?.name === 'Mileage'">{{(item?.billableQuantity + + item?.nonBillableQuantity) }} mi</h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Type</small></h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.ExpenseItem?.name !== 'Mileage'">{{item?.outOfPocket === 1 ? 'Out of Pocket' : (item?.outOfPocket === 2 ? 'Vendor/Other' : 'NEFCO Card')}}</h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.ExpenseItem?.name === 'Mileage'">{{item?.nefcoVehicle === 1 ? 'NEFCO Vehicle' : (item?.nefcoVehicle === 2 ? 'Vendor/Other' : 'My Vehicle')}}</h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Receipt</small></h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.Document?.id else noValue"><i class="fa-solid fa-receipt"></i></h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Authorized</small></h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.authorizedBy else noValue">{{item?.authorizedBy}}</h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.authorizedDate">on {{item?.authorizedDate | customDate : 'MM/dd/yyyy'}}</h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Expense Date</small></h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.expenseDate else noValue">{{item?.expenseDate | customDate : 'MM/dd/yyyy'}}</h6>
                                    </div>

                                    <div class="equal mb-0">
                                        <h6 class="kendo-caption"><small>Paid</small></h6>
                                        <h6 class="kendo-caption-body">{{item?.paidDate ? 'Paid' : 'Not Paid'}}</h6>
                                    </div>
                                </kendo-card-body>
                            </kendo-card>
                        </ng-container>
                    </section>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="TABS.OUT_OF_POCKET" [selected]="selectedTab === TABS.OUT_OF_POCKET">
            <ng-template kendoTabTitle>
                <span>Out of Pocket</span>
            </ng-template>
            <ng-template kendoTabContent>
                <div appScrollNearEnd [threshold]="1" (nearEnd)="onNearEndScrollOutOfExpenses()">
                    <app-mobile-time-exp-date-kendo-filter [showSelection]="false" [mode]="'month'" [getRange]="true" (WeekMonthRangeChange)="dateChangeOutOfExpensesMonth($event)">
                    </app-mobile-time-exp-date-kendo-filter>
                    
                    <hr class="divider-line" />

                    <section class="list p-16">
                        <kendo-gridlayout class="display-total" [rows]="['auto']" [gap]="{rows: 5, cols: 24}" [cols]="['auto auto auto']">
                            <kendo-gridlayout-item class="mb-0 text-center">
                                  <h6 class="fs-12">Total Expenses</h6>
                                  <h6 class="total">{{(outOfExpenseCount?.totalExpense || 0) | number: '1.0-0'}}</h6>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0 text-center">
                                <h6 class="fs-12">Billable Expenses</h6>
                                <h6 class="total">{{(outOfExpenseCount?.billableExpense || 0) | number: '1.0-0'}}</h6>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item class="mb-0 text-center">
                                <h6 class="fs-12">Non-Billable Expenses</h6>
                                <h6 class="total">{{(outOfExpenseCount?.nonBillableExpense || 0) | number: '1.0-0'}}</h6>
                            </kendo-gridlayout-item>
                        </kendo-gridlayout>

                        <p class="mt-24 mb-24 text-center" *ngIf="outOfExpenses?.length === 0">There are no results to display.</p>

                        <ng-container *ngFor="let item of outOfExpenses; let first = first; trackBy: trackByIndex" >
                            <kendo-card [ngClass]="first ? 'mt-16' : 'mt-16 mb-16'" [width]="'100%'">
                                <kendo-card-header>
                                    <div class="header-id-action mb-8">
                                        <a *ngIf="item?.Investigation?.id else unlinked" [routerLink]="['/investigations', item?.Investigation?.id]"
                                            class="mobile-id-link" style="padding-left: 42px;">
                                            ID: {{ item?.Investigation?.nefcoNumber }}
                                        </a>
                                        <button class="actions" kendoButton themeColor="secondary" fillMode="link" [iconClass]="'fa-solid fa-trash'"
                                        [size]="'large'" (click)="deleteExpenses(item)"></button>
                                    </div>
                                    <div class="header-date text-center">
                                        <h6><small>Entry Date</small> {{item?.createdAt | customDate : "MM/dd/yyyy"}}</h6>
                                    </div>
                                </kendo-card-header>
                                <kendo-card-body>
                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Expense</small></h6>
                                        <h6 class="kendo-caption-body">{{item?.ExpenseItem?.name || '-'}}</h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Purpose</small></h6>
                                        <h6 class="kendo-caption-body">{{item?.ExpensePurpose?.name || '-'}}</h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Cost</small></h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.ExpenseItem?.name !== 'Mileage'">{{(item?.billableQuantity + item?.nonBillableQuantity) | number: '1.2-2' | currency: 'USD'}}</h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.ExpenseItem?.name === 'Mileage'">{{(item | reimbursementRate) | number: '1.2-2' | currency: 'USD'}} </h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Type</small></h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.ExpenseItem?.name !== 'Mileage'">{{item?.outOfPocket === 1 ? 'Out of Pocket' : (item?.outOfPocket === 2 ? 'Vendor/Other' : 'NEFCO Card')}}</h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.ExpenseItem?.name === 'Mileage'">{{item?.nefcoVehicle === 1 ? 'NEFCO Vehicle' : (item?.nefcoVehicle === 2 ? 'Vendor/Other' : 'My Vehicle')}}</h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Receipt</small></h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.Document?.id else noValue"><i class="fa-solid fa-receipt"></i></h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Authorized</small></h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.authorizedBy else noValue">{{item?.authorizedBy}}</h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.authorizedDate">on {{item?.authorizedDate | customDate : 'MM/dd/yyyy'}}</h6>
                                    </div>

                                    <div class="equal">
                                        <h6 class="kendo-caption"><small>Expense Date</small></h6>
                                        <h6 class="kendo-caption-body" *ngIf="item?.expenseDate else noValue">{{item?.expenseDate | customDate : 'MM/dd/yyyy'}}</h6>
                                    </div>

                                    <div class="equal mb-0">
                                        <h6 class="kendo-caption"><small>Paid</small></h6>
                                        <h6 class="kendo-caption-body">{{item?.paidDate ? 'Paid' : 'Not Paid'}}</h6>
                                    </div>
                                </kendo-card-body>
                            </kendo-card>
                        </ng-container>
                    </section>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
    <ng-container *ngIf="expenseTypeSelected === 'MY-HOUR'">
        <app-mobile-hours-form [fromTab]="true" (stepTo)="resetExpenseTypeSelected()" (reloadPage)="changeTab();"></app-mobile-hours-form>
    </ng-container>
    <ng-container *ngIf="expenseTypeSelected === 'MY-EXPENSES'">
        <app-mobile-expense-form [fromTab]="true" (stepTo)="resetExpenseTypeSelected()" (reloadPage)="changeTab();"></app-mobile-expense-form>
    </ng-container>
    <ng-container *ngIf="expenseTypeSelected === 'MY-MILEAGE'">
        <app-mobile-mileage-form [fromTab]="true" (stepTo)="resetExpenseTypeSelected()" (reloadPage)="changeTab();"></app-mobile-mileage-form>
    </ng-container>
</section>
<ng-template #noValue>-</ng-template>
<ng-template #unlinked><span class="mobile-id-link" style="padding-left: 42px; text-decoration: none;">Unlinked</span></ng-template>