import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { IApiPermissionRole, IApiTimeEntryType } from 'src/app/shared/modules/graphql/types/types';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PermissionRoleService, TimeEntryTypeService } from 'src/app/shared/services';
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { NgForm } from '@angular/forms';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  templateUrl: './permission-role-modal.component.html',
  styleUrls: ['./permission-role-modal.component.scss']
})
export class PermissionRoleModalComponent extends DialogContentBase implements OnInit{
  @ViewChild("frm", { static: false }) frm: NgForm;
  @Input() public data: any;
  public isSubmitted = false;
  public timeEntryTypes: IApiTimeEntryType[] = [];

  private _role: IApiPermissionRole;
  public get role() {
    return this._role;
  }

  constructor(
    private permissionRoles: PermissionRoleService,
    public dialogRef: DialogRef,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private timeEntryTypeService: TimeEntryTypeService
  ) {
    super(dialogRef)
  }

  ngOnInit(): void {
    this._role = this.data || {
      name: '',
      TimeEntryTypeId: null,
    };
    this.loader.show$(this.timeEntryTypeService.get()).pipe(unwrapConnection()).subscribe((result)=>{
      this.timeEntryTypes = result;
    })
  }

  onSubmit() {
    if (!this.frm.valid) {
      this.frm.form.markAllAsTouched();
      return;
    }
    if (this.role.id) {
      this.save();
    } else {
      this.add();
    }
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  private save() {
    const { id, name, TimeEntryTypeId } = this.role;
    this.loader.show$(this.permissionRoles.update({
      id,
      name: name?.trim(),
      TimeEntryTypeId: TimeEntryTypeId
    })).pipe(
      this.notifications.snackbarPipe("Permission Role updated"),
      this.notifications.snackbarErrorPipe("Error updating role; please try again")
    ).subscribe((res) => {
      this.dialogRef.close(res);
    });
  }

  private add() {
    const { name, TimeEntryTypeId } = this.role;
    this.loader.show$(this.permissionRoles.add({
      name: name?.trim(),
      TimeEntryTypeId: TimeEntryTypeId
    })).pipe(
      this.notifications.snackbarPipe("Permission Role added"),
      this.notifications.snackbarErrorPipe("Error adding role; please try again")
    ).subscribe((res) => {
      this.dialogRef.close(res);
    });
  }
}
