import { DataSource } from "@angular/cdk/collections";
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { InvestigationMetricsStateService } from './investigation-metrics-state.service';
import { IApiInvestigationMetrics, IApiInvestigationMetricsStateConnection, IApiInvestigationMetricsFilter, IApiInvestigationMetricsState } from "src/app/shared/modules/graphql/types/types";

export class InvestigationMetricsStateDataSource extends NefcoPagedDataSource<IApiInvestigationMetricsState, InvestigationMetricsStateService, IApiInvestigationMetricsStateConnection> implements DataSource<IApiInvestigationMetricsState> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsStateConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
