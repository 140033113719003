<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationServicesUpdate" #investigationServicesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationServicesDelete" #investigationServicesDelete="appEnforcePermission"></div>
<!-- Permissions -->
<section class="mat-typography">
  <!-- Reusable card for each service -->
  <mat-card class="mainCard" *ngFor="let service of staffServices">

    <!-- Card header options -->
    <div class="flexContainer">
      <div class="flex2">
        <mat-checkbox (change)="serviceCheckboxClicked($event, service)" color="primary" [checked]="mainServiceCompleted(service)" *ngIf="investigationServicesUpdate?.enabled else serviceName">
          <h2 class="mat-h2" [innerHTML]="service.MainService.Type.name | titlecase"></h2>
        </mat-checkbox>
        <ng-template #serviceName>
          <h2 class="mat-h2" [innerHTML]="service.MainService.Type.name | titlecase"></h2>
        </ng-template>
      </div>
      <div class="textRight">
        <button mat-icon-button color="warn" (click)="deleteService(service.MainService)" *ngIf="investigationServicesDelete?.enabled">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </div>

    <!-- Info -->
    <div class="flexContainer">
      <!-- Assigned Users Dropdown -->
      <div class="equal">
        <mat-form-field>
          <mat-label>Assigned to</mat-label>
          <mat-select name="assignedUsers" [ngModel]="service.User.id"
            (selectionChange)="onAssignedToChange($event, service)">
            <mat-option *ngFor="let staff of investigation.InvestigationStaff" [value]="staff.User.id" [innerHTML]="staff.User | fullName">
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Authorized By -->
      <div class="equal" *ngIf="service.MainService?.authorizedBy">
        <p class="mat-caption">
          Authorized by:
        </p>
        <p [innerHTML]="service.MainService?.authorizedBy"></p>
      </div>

      <!-- Note -->
      <div class="equal">
        <p class="mat-caption">
          Notes / Instructions:
        </p>
        <p [innerHTML]="service.MainService.notes"> </p>
      </div>
    </div>

    <!-- Sub Services-->
    <div class="flexContainer"
      *ngIf="((service?.SubServices?.length && service?.SubServices[0].Type?.name !== service?.MainService?.Type?.name && service?.SubServices?.length === 1) || (service?.SubServices?.length > 1))">
      <!-- Individual service items -->
      <div class="half" *ngFor="let subService of service.SubServices">
        <mat-card class="service">
          <div class="flexContainer">

            <!-- checkbox -->
            <div class="flex9">
              <mat-checkbox (change)="checkboxSelect($event, subService.id)" [checked]="subService.isComplete" *ngIf="investigationServicesUpdate?.enabled else subServiceName"
                color="primary">
                <h2 class="mat-h2" [innerHTML]="subService.Type.name"> </h2>
              </mat-checkbox>
              <ng-template #subServiceName>
                <h2 class="mat-h2" [innerHTML]="subService.Type.name"> </h2>
              </ng-template>
            </div>

            <!-- Delete button -->
            <div class="equal textRight">
              <button mat-icon-button (click)="onDeleteSubServiceClick(subService)" *ngIf="investigationServicesDelete?.enabled">
                <mat-icon color="warn">delete_forever</mat-icon>
              </button>
            </div>

          </div>
        </mat-card>
      </div>
    </div>

  </mat-card>
</section>
