import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';
import { NgForm } from "@angular/forms";
import { UntilDestroy } from '@ngneat/until-destroy';
import { NotificationsService } from "../modules/notifications/notifications.service";

@UntilDestroy()
@Directive({
  selector: '[appDirtyFormNotify]'
})
export class DirtyFormNotifyDirective implements AfterViewInit {
  @Input() appDirtyFormNotify: NgForm;
  constructor(
    private element: ElementRef,
    private notifications: NotificationsService
  ) {
  }

  ngAfterViewInit() {
    this.notifications.registerForm(this.appDirtyFormNotify);
  }
}
