<!-- Permissions -->
<div [appEnforcePermission]="authConfig.timeEntryUnlinkedHoursCreate" #timeEntryUnlinkedHoursCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursCreate" #timeEntryInvestigationHoursCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursList" #timeEntryInvestigationHoursList="appEnforcePermission"></div>
<!-- Permissions -->
<form #timeEntryTypeForm="ngForm">
  <kendo-gridlayout
    [rows]="['auto']"
    [gap]="{ rows: 5, cols: 20 }"
    [cols]="['repeat(2,minmax(0,1fr))']"
  >
    <kendo-gridlayout-item class="mb-4" *ngIf="!investigationView">
      <kendo-formfield>
        <kendo-label
          class="{{ unlinked ? '' : 'asterisk' }}"
          text="Investigation ID"
        ></kendo-label>
        <kendo-dropdownlist
          #investigationID="ngModel"
          [(ngModel)]="timeEntry.InvestigationId"
          name="InvestigationId"
          [disabled]="timeEntryInvestigationHoursCreate?.enabled ? (unlinked || editQtyOnly) : true"
          [data]="investigations"
          textField="name"
          valueField="id"
          fillMode="outline"
          [valuePrimitive]="true"
          [defaultItem]="{ id: '', name: 'Select Investigation' }"
          [popupSettings]="{ appendTo: 'component', animate: false }"
          required="{{ !unlinked }}"
        >
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="!dataItem?.id" class="placeholder">
              {{ dataItem.name }}
            </span>
            <span *ngIf="dataItem?.id" class="not-placeholder">
              {{ dataItem.name }}
            </span>
          </ng-template>
        </kendo-dropdownlist>
        <kendo-formerror
          *ngIf="
            !investigationID?.valid &&
            (investigationID?.dirty || investigationID?.touched)
          "
        >
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="investigationID?.errors?.required">
            Investigation id cannot be left blank
          </span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="mb-4" [colSpan]="!investigationView ? 1 : 2">
      <kendo-formfield>
        <kendo-label class="asterisk" text="Date"></kendo-label>
        <kendo-datepicker
          name="startDate"
          calendarType="classic"
          [fillMode]="'outline'"
          placeholder="month/day/year"
          format="MM/dd/yyyy"
          [(ngModel)]="timeEntry.workday"
          formatPlaceholder="formatPattern"
          required="true"
          #workday="ngModel"
          [disabled]="editQtyOnly"
          [disabledDates]="disabledDates"
        ></kendo-datepicker>
        <kendo-formerror
          *ngIf="!workday?.valid && (workday?.dirty || workday?.touched)"
        >
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span
            *ngIf="
              workday?.errors?.required ||
              workday?.errors?.minError ||
              workday?.errors?.maxError
            "
          >
            Date cannot be left blank
          </span>
          <span *ngIf="workday?.errors?.disabledDate">
            Date cannot be future
          </span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item [colSpan]="2" *ngIf="!investigationView">
      <kendo-formfield>
        <div class="dflex">
          <input
            type="checkbox"
            #showAll
            kendoCheckBox
            [(ngModel)]="!timeEntryUnlinkedHoursCreate?.enabled ? false : unlinked "
            [ngModelOptions]="{ standalone: true }"
            [disabled]="(!timeEntryUnlinkedHoursCreate?.enabled || !timeEntryInvestigationHoursCreate?.enabled) ? true : false"
          />
          <kendo-label
            class="k-checkbox-filter-label ml-12"
            [for]="showAll"
            text="Unlinked"
          ></kendo-label>
        </div>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <kendo-formfield class="left-search-icon">
        <kendo-label
          class="asterisk"
          [for]="typeSelect"
          text="Type"
        ></kendo-label>
        <div style="position: relative;">
          <i class="fa fa-search" aria-hidden="true"></i>
          <kendo-autocomplete
            #autoCompleteTypeId
            #typeSelect="ngModel"
            [(ngModel)]="selectedTypeName"
            name="TypeId"
            (valueChange)="typeChange($event); typeIdsearch = ''"
            [filterable]="true"
            (filterChange)="typeIdsearch = $event; !(typeIdsearch) ? autoCompleteTypeId?.toggle(true) : false "
            [data]="timeEntryTypes | visibleTimeEntryItems : unlinked : typeIdsearch"
            textField="name"
            valueField="id"
            fillMode="outline"
            [placeholder]="'Select Type'"
            (click)="autoCompleteTypeId?.toggle(true)"
            [popupSettings]="{ appendTo: 'component', animate: false, width: 250 }"
            required
          >
            <ng-template kendoAutoCompleteItemTemplate let-dataItem>
              <span class="not-placeholder">
                {{dataItem.name}}
              </span>
            </ng-template>
          </kendo-autocomplete>
        </div>
        <kendo-formerror
          *ngIf="
            !typeSelect?.valid && (typeSelect?.dirty || typeSelect?.touched)
          "
        >
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="typeSelect?.errors?.required"
            >Type cannot be left blank</span
          >
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <kendo-gridlayout
        [rows]="['auto']"
        [gap]="{ rows: 5, cols: 20 }"
        [cols]="['repeat(2,minmax(0,1fr))']"
      >
        <kendo-gridlayout-item [colSpan]="1" *ngIf="!unlinked">
          <kendo-formfield class="kendo-formfield">
            <kendo-label [text]="'Billable Hours'"></kendo-label>
            <div class="numeric-text-suffix">
              <i class="fa-solid fa-clock"></i>
              <kendo-numerictextbox
                name="hours"
                fillMode="outline"
                [autoCorrect]="false"
                placeholder="Hours"
                [(ngModel)]="timeEntry.hours"
                #hours="ngModel"
              >
              </kendo-numerictextbox>
            </div>
            <kendo-formerror
              *ngIf="!hours?.valid && (hours?.dirty || hours?.touched)"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="hours?.errors?.required">
                Billable hours cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [colSpan]="!unlinked ? 1 : 2">
          <kendo-formfield class="kendo-formfield">
            <kendo-label [text]="'Non-Billable Hours'"></kendo-label>
            <div class="numeric-text-suffix">
              <i class="fa-solid fa-clock"></i>
              <kendo-numerictextbox
                name="nonBillableHours"
                fillMode="outline"
                placeholder="Hours"
                [autoCorrect]="false"
                [(ngModel)]="timeEntry.nonBillableHours"
                #nonBillableHours="ngModel"
              >
              </kendo-numerictextbox>
            </div>
            <kendo-formerror
              *ngIf="
                !nonBillableHours?.valid &&
                (nonBillableHours?.dirty || nonBillableHours?.touched)
              "
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="nonBillableHours?.errors?.required">
                Non billable hours cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item [colSpan]="2">
      <kendo-formfield>
        <kendo-label [for]="descriptionField" text="Description"></kendo-label>
        <kendo-textarea
          #descriptionField
          [(ngModel)]="timeEntry.description"
          name="description"
          [disabled]="editQtyOnly"
          placeholder="Enter description"
          fillMode="outline"
          class="min-height-96"
          resizable="both"
          #description="ngModel"
          appNoWhiteSpace
        >
        </kendo-textarea>
        <kendo-formerror
          *ngIf="
            !description?.valid && (description?.dirty || description?.touched)
          "
        >
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="description?.errors?.required">
            Description cannot be left blank
          </span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item [colSpan]="2" *ngIf="timeEntry?.id && investigationView">
      <kendo-formfield class="kendo-formfield">
        <div style="margin-bottom: 6px;">
          <input type="checkbox" kendoCheckBox #paidCheck [value]="false" [(ngModel)]="paidCheckbox"
            [ngModelOptions]="{standalone: true}" (ngModelChange)="changePaid($event)"/>
          <kendo-label class="k-checkbox-filter-label ml-12 mr-24" [for]="paidCheck" text="Paid"></kendo-label>
        </div>
      </kendo-formfield>
      <div class="kendo-formfield" *ngIf="paidCheckbox">
        <kendo-label class="asterisk" text="Paid Date"></kendo-label>
        <kendo-datepicker id="paidDate" name="paidDate" calendarType="classic" [fillMode]="'outline'"
          placeholder="month/day/year" format="MM/dd/yyyy" [(ngModel)]="timeEntry.paidDate"
          formatPlaceholder="formatPattern" #paidDate="ngModel" [required]="true"></kendo-datepicker>
        <kendo-formerror *ngIf="!paidDate?.valid && (paidDate?.dirty || paidDate?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="paidDate?.errors?.required || paidDate?.errors?.minError || paidDate?.errors?.maxError">
            Paid Date cannot be left blank
          </span>
          <span *ngIf="paidDate?.errors?.disabledDate">
            Paid Date cannot be future
          </span>
        </kendo-formerror>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <kendo-card-footer class="footer" *ngIf="!adminView && !modalView">
    <button
      kendoButton
      type="button"
      class=""
      themeColor="primary"
      fillMode="solid"
      (click)="onSubmit()"
    >
      Add
    </button>
  </kendo-card-footer>
</form>
