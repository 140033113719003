import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { switchMap, tap } from 'rxjs/operators';
import { GqlCommentService } from 'src/app/shared/modules/graphql/services';
import { EvidenceService } from 'src/app/shared/services';
import { IApiCommentableType, IApiEvidence } from '../../../../shared/modules/graphql/types/types';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';

@Component({
  selector: 'app-investigation-evidence-sidebar',
  templateUrl: './investigation-evidence-sidebar.component.html',
  styleUrls: ['./investigation-evidence-sidebar.component.scss']
})
export class InvestigationEvidenceSidebarComponent extends DialogContentBase {
  @ViewChild('commentForm', { static: false }) commentForm: NgForm;
  @Input() evidence: IApiEvidence = null;
  public comment: string = null;

  constructor(
    public dialogRef: DialogRef,
    private evidenceService: EvidenceService,
    private commentService: GqlCommentService,
    private loaderService: LoaderService,
  ) { 
    super(dialogRef);
  }

  public addComment() {
    if (this.commentForm?.invalid) {
      this.commentForm.form.markAllAsTouched();
      return;
    };
    this.loaderService
      .show$(
        this.commentService.addComment({
          text: this.comment,
          commentableId: this.evidence.id,
          commentableType: IApiCommentableType.Evidence,
        })
      )
      .pipe(switchMap(() => this.getEvidence()))
      .subscribe(() => {
        this.comment = null;
      });
  }

  private getEvidence() {
    return this.evidenceService.getById(this.evidence.id).pipe(
      tap((val) => this.evidence = val)
    );
  }

}
