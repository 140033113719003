import { connectionQuery } from '../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../interfaces/base.interfaces';
import { addSettingsMutation, removeSettingsMutation, SettingsConnectionQuery, updateSettingsMutation } from "./settings.operations";
import { IApiAddSettingsInput, IApiSettings, IApiSettingsConnection, IApiSettingsFilter, IApiUpdateSettingsInput } from "../../types/types";

@Injectable()
export class GqlSettingsService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getAll(filters: IApiSettingsFilter[], options: IGqlBaseOptions): Observable<IApiSettingsConnection> {
    return this.apollo.query(connectionQuery(SettingsConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddSettingsInput): Observable<IApiSettings> {
    return this.apollo.mutate({
      mutation: addSettingsMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public update(input: IApiUpdateSettingsInput): Observable<IApiSettings> {
    return this.apollo.mutate({
      mutation: updateSettingsMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeSettingsMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
