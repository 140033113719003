import { IApiUser, IApiAddress } from '../../modules/graphql/types/types';
import { bindCallback } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationsService } from '../../modules/notifications/notifications.service';
import { IMapUser } from '../../modules/google-maps/google-map.interfaces';
import { AlphabeticalIndexPipe } from '../../pipes';
@Injectable()
export class UserDirectionService {

  public delayFactor: number = 0;
  private _directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();
  private _getRoute$ = bindCallback(this._directionsService.route);

  constructor(
    private notifications: NotificationsService,
    private _alphaIndexPipe: AlphabeticalIndexPipe,
  ) { }

  private _toLatLng(address: IApiAddress): google.maps.LatLngLiteral {
    return { lat: address.latitude, lng: address.longitude };
  }

  private filterHomeAddress(user: IApiUser, i: number, arr: IApiUser[]): boolean {
    const home = user.Addresses?.find(({ Type }) => Type.name === "Home");
    return home && (home.latitude && home.longitude) ? true : false;
  }

  private _toDirectionRequest(destinationCoordinates: google.maps.LatLngLiteral, userCoordinates: google.maps.LatLngLiteral): google.maps.DirectionsRequest {
    return {
      origin: userCoordinates,
      destination: destinationCoordinates,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
    };
  }

  private async mGetDirectionsRoute(requestObservable) {
    return new Promise((resolve) => {
      requestObservable.subscribe(res => {
        const result = res as [google.maps.DirectionsResult, google.maps.DirectionsStatus];
        const [directionResult, directionStatus] = result;
        resolve({directionResult, directionStatus});
      });
    });
  }

  private async directionsRouteRec(request) {
    if (this.delayFactor >= 3) {
      return null;
    }
    const directionRequest = this._getRoute$(request);
    let directionRes: any = await this.mGetDirectionsRoute(directionRequest);
    if (directionRes?.directionStatus === google.maps.DirectionsStatus.OK) {
     return directionRes;
    } else if (directionRes?.directionStatus === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
      this.delayFactor++;
      setTimeout(async () => {
        await this.directionsRouteRec(request)
      }, this.delayFactor * 500);
    } else {
      return directionRes;
    }
  }

  private _toMapMarker(user, result: google.maps.DirectionsResult, userIndex) {
    if (user?.marker) {
      return user?.marker;
    }
    const loc = result?.routes?.[0]?.legs?.[0]?.start_location;
    const pos: google.maps.LatLngLiteral = { lat: loc?.lat() || 0, lng: loc?.lng() || 0 };
    return new google.maps.Marker({
      position: pos,
      title: `${user.firstName} ${user.lastName}`,
      icon: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue${this._alphaIndexPipe.transform(userIndex)}.png`,
    });
  }

  public async getUserDirection(users: Array<any>, _state, existUsers = []) {
    if (users.length === 0) {
      return [];
    }

    const destinationCoordinates: google.maps.LatLngLiteral = this._toLatLng(_state.address);
    if (!destinationCoordinates.lat || !destinationCoordinates.lng) {
      this.notifications.notify("No valid Lat/Lon could be found for this investigation address");
      return users;
    }
    const usersWithAddress = users;
    let mapUsers: IMapUser[] = [];

    for (let i = 0; i < usersWithAddress.length; i++) {
      const existUser = await existUsers.find(a => a.id === usersWithAddress[i].id);
      if (!existUser) {
        const userDetails = usersWithAddress[i];
        const homeAddress = userDetails.Addresses.find(address => (address.Type?.name === "Home" && address.latitude && address.longitude));
        const userCoordinates: google.maps.LatLngLiteral = this._toLatLng(homeAddress);
        const directionRequest = this._toDirectionRequest(destinationCoordinates, userCoordinates);
        this.delayFactor = 0;
        let directionRes: any = await this.directionsRouteRec(directionRequest);
        mapUsers.push(
          directionRes ?
            {
              ...usersWithAddress[i],
              response: directionRes?.directionResult,
              driveDistance: directionRes?.directionResult.routes[0].legs[0].distance,
              driveDuration: directionRes?.directionResult.routes[0].legs[0].duration,
              status: directionRes?.directionStatus,
            }
          :
            {...usersWithAddress[i]});
      } else {
        mapUsers.push(
          existUser?.response ?
            {
              ...usersWithAddress[i],
              response: existUser?.response,
              driveDistance: existUser?.response.routes[0].legs[0].distance,
              driveDuration: existUser?.response.routes[0].legs[0].duration,
              status: existUser?.status,
            }
          :
            {...existUser});
      }
    }
    mapUsers.sort((a, b) => a.driveDuration?.value < b.driveDuration?.value ? -1 : 1);
    mapUsers.map((user, i) => { user['marker'] = this._toMapMarker(user, user.response, i) });
    return  mapUsers?.length <= 0 ? [] : mapUsers;
  }

}
