
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlContactRoleService } from 'src/app/shared/modules/graphql/services/contact/contact-role/contact-role.service';
import {
  IApiAddContactRoleInput, IApiContactRole, IApiUpdateContactRoleInput, IApiContactRoleFilter, IApiContactRoleConnection
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";


@Injectable()
export class ContactRoleService {

  constructor(
    private gqlContactRoleService: GqlContactRoleService
  ) { }

  public get(filters: IApiContactRoleFilter[] = [], options?: IGqlBaseOptions): Observable<IApiContactRoleConnection> {
    return this.gqlContactRoleService.get(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddContactRoleInput): Observable<IApiContactRole> {
    return this.gqlContactRoleService.add(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlContactRoleService.remove(id);
  }

  public update(input: IApiUpdateContactRoleInput) {
    return this.gqlContactRoleService.update(input);
  }

  public getById(id: string): Observable<IApiContactRole> {
    return this.gqlContactRoleService.getContactRoleById(id);
  }
}
