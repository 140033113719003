import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { DateRangePopupComponent, DateRangeService } from '@progress/kendo-angular-dateinputs';
import moment from "moment";
import { NefcoDateHelper } from '../../helpers/nefco-date.class';

@Component({
  selector: 'app-date-range-next-prev-filter-kendo',
  templateUrl: './date-range-next-prev-filter-kendo.component.html',
  styleUrls: ['./date-range-next-prev-filter-kendo.component.scss']
})

export class DateRangeNextPrevFilterKendoComponent implements OnChanges, AfterViewInit {
  @ViewChild('startDateRange', { static: false }) startDateRange: any;
  @ViewChild('endDateRange', { static: false }) endDateRange: any;

  public _mode = 'quarter';
  @Input() set mode(val: string) {
    this._mode = val;
    if (val === 'quarter') {
      this.currentFilter = 'QUARTERLY';
      this.setDefaultDateRange(this.currentFilter);
    } else if (val === 'month') {
      this.currentFilter = 'MONTH';
      this.setDefaultDateRange(this.currentFilter);
      this.filterOptions = [
        { text: "Custom Date Range", slug: "DATE_RANGE" },
        { text: "Month View", slug: "MONTH" }
      ];
    }
  };
  get mode() {
    return this._mode;
  };
  @Input() selectedId: string;
  @Input() allowChangeFilter: boolean = false;


  @Output() dateRangeChange = new EventEmitter<string>();

  @ViewChild("dateRangePopup", { static: false })
  public dateRangePopup: DateRangePopupComponent;

  noNextRange: boolean = true;
  firstLoad: boolean = true;

  private _selectedDateRange = {
    startDate: null,
    endDate: null,
  }

  public get selectedDateRange() {
    return this._selectedDateRange;
  }

  public set selectedDateRange(val: any) {
    this._selectedDateRange = val;
    const nefoDateRange = val ? NefcoDateHelper.dateFilterStringUTCFromLocal(val?.startDate, val?.endDate) : null;
    this.dateRangeChange.emit(nefoDateRange ? nefoDateRange : null);
  }

  public _filterOptions = [
    { text: "Custom Date Range", slug: "DATE_RANGE" },
    { text: "Quarterly", slug: "QUARTERLY" }
  ];
  @Input() set filterOptions(val) {
    this._filterOptions = val;
  }
  public get filterOptions() {
    return this._filterOptions;
  }

  public _currentFilter = 'QUARTERLY';

  public set currentFilter(val: string) {
    this._currentFilter = val;
  }

  public get currentFilter() {
    return this._currentFilter;
  }

  public range = { startDate: null, endDate: null };
  currentQuarter = NefcoDateHelper.dateFilterStringUTCFromLocal(moment().startOf("quarter").toDate(), moment().endOf("quarter").toDate());

  @ViewChild("dateRange", { read: DateRangeService })
  public service: DateRangeService;

  constructor() { }

  public ngOnChanges(change) {
    if (change?.selectedId?.currentValue === null && change?.selectedId?.firstChange === false) {
      if (this.currentFilter === 'DATE_RANGE')
        this.range = { startDate: null, endDate: null };
      if (this.currentFilter !== 'DATE_RANGE') {
        this.noNextRange = true;
        this.setDefaultDateRange(this.currentFilter);
      }
    }
  }

  setDefaultDateRange(currentFilter) {
    switch (currentFilter) {
      case "QUARTERLY":
        this.selectedDateRange = {
          startDate: moment().startOf("quarter"),
          endDate: moment().endOf("quarter"),
        }
        break;
      case "MONTH":
        this.selectedDateRange = {
          startDate: moment().startOf("month"),
          endDate: moment().endOf("month"),
        }
        break;
      default:
        this.selectedDateRange = {
          startDate: null,
          endDate: null
        }
        break;
    }
  }

  changeRange(direction) {
    const unit = this.currentFilter === 'QUARTERLY' ? 'quarter' : "month";
    switch (direction) {
      case "forward":
        if (this.noNextRange) return;
        this.selectedDateRange = {
          startDate: moment(this.selectedDateRange.startDate).add(1, unit).startOf(unit).toDate(),
          endDate: moment(this.selectedDateRange.endDate).add(1, unit).endOf(unit).toDate(),
        }
        this.noNextRange = !moment(this.selectedDateRange.endDate).isBefore(moment()) ? true : false;
        break;
      case "backward":
        this.noNextRange = false;
        this.selectedDateRange = {
          startDate: moment(this.selectedDateRange.startDate).subtract(1, unit).startOf(unit).toDate(),
          endDate: moment(this.selectedDateRange.endDate).subtract(1, unit).endOf(unit).toDate(),
        }
        break;
      default:
        return;
    }
  }

  public focusDateInput(start = true) {
    if (this.startDateRange && start) {
      setTimeout(() => {
        this.startDateRange?.focus();
      }, 1);
    } else {
      setTimeout(() => {
        this.endDateRange?.focus();
      }, 1);
    }
  }

  ngAfterViewInit() {
    // this.selectedDateRange = this.currentQuarter;
    this.service.activeRangeEnd$.subscribe((data) => {
      if (data === "start") {
        setTimeout(() => {
          this.dateRangePopup.toggle(false);
        }, 200)
        setTimeout(() => {
          this.selectedDateRange = {
            startDate: this.range.startDate,
            endDate: moment(this.range.endDate).endOf('day').toDate(),
          };
        }, 100);
      }
    });
  }

  public updateDateFilters(data) {
    if (this.range.startDate && this.range.endDate) {
      this.selectedDateRange = {
        startDate: this.range.startDate,
        endDate: moment(this.range.endDate).endOf('day').toDate(),
      };;
    }
  }

  public filterChange(event) {
    this.currentFilter = event.slug;
    this.dateRangePopup.toggle(false);
    if (event?.slug === 'DATE_RANGE') {
      this.range = { startDate: null, endDate: null };
      this.dateRangePopup.toggle(true);
      this.selectedDateRange = '';
      this.focusDateInput();
    } else if (event?.slug === 'QUARTERLY' || event?.slug === 'MONTH') {
      this.setDefaultDateRange(event?.slug);
    }
  }

}
