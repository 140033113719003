<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <i class="fa-solid fa-circle-exclamation"></i>
      <div class="ml-16">
        <span>Update Scene Status</span>
        <p class="secondary mb-0">ID: {{this.investigation?.nefcoNumber}}</p>
      </div>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body mt-24">
    <p class="mb-16">Are you sure you want to change the status of these investigators?</p>
    <form #filterForm="ngForm">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item class="mb-24">
          <kendo-formfield class="kendo-formfield">
            <kendo-label class="asterisk" text="Status"></kendo-label>
            <kendo-dropdownlist [data]="investigationOnSceneStatusAll" fillMode="outline" textField="text"
              valueField="value" name="status" #status="ngModel" [valuePrimitive]="true"
              [defaultItem]="{text: 'All Status', value: ''}" [(ngModel)]="selectedStatus"
              [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem?.text }} </span>
                <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem?.text }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!status?.valid && (status?.dirty || status?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="status?.errors?.required">
                Status cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <hr class="divider-line mb-12" />
        <kendo-gridlayout-item class="mb-16">
          <button kendoButton class="full-btn white-bk" [size]="'medium'" themeColor="light" (click)="close()">Cancel</button>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="mb-0">
          <button kendoButton class="full-btn" [size]="'medium'" themeColor="primary" (click)="saveStatus()">Save</button>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </div>
</div>
