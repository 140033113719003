import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces/base.interfaces';
import { IApiAddShippingCarrierInput, IApiShippingCarrier, IApiShippingCarrierConnection, IApiShippingCarrierFilter, IApiUpdateShippingCarrierInput } from "src/app/shared/modules/graphql/types/types";
import { ListPage } from "src/app/shared/helpers/list-page.class";
import { GqlShippingCarrierService } from "../../modules/graphql/services";

@Injectable()
export class ShippingCarrierService {

  constructor(
    private gqlShippingCarrierService: GqlShippingCarrierService
  ) { }

  public get(filters?: IApiShippingCarrierFilter[], options?: IGqlBaseOptions): Observable<IApiShippingCarrierConnection> {
    return this.gqlShippingCarrierService.getShippingCarriers(filters, options || new ListPage());
  }

  public add(input: IApiAddShippingCarrierInput): Observable<IApiShippingCarrier> {
    return this.gqlShippingCarrierService.addShippingCarrier(input);
  }

  public update(input: IApiUpdateShippingCarrierInput): Observable<IApiShippingCarrier> {
    return this.gqlShippingCarrierService.updateShippingCarrier(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlShippingCarrierService.removeShippingCarrier(id);
  }
}
