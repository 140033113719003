import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";

const nodeFragment = `
  id
  Investigation {
    id
    nefcoNumber
    BillTo {
      id
      firstName
      middleName
      lastName
      suffix
      BillingAddress {
        id
        isPrimary
        address1
        address2
        address3
        city
        state
        postal
        country
        latitude
        longitude
        Type {
          id
          name
        }
      }
      Branches {
        id
        name
        Company {
          id
          name
        }
      }
    }
    BillToBranch {
      id
      name
      BillingAddress {
        id
        isPrimary
        address1
        address2
        address3
        city
        state
        postal
        country
        latitude
        longitude
        Type {
          id
          name
        }
      }
      Company {
        id
        name
        billingInstructions
        qbListId
      }
    }
    Client {
      id
      firstName
      middleName
      lastName
      suffix
      Branches {
        id
        name
        Company {
          id
          name
        }
      }
    }
    Company {
      id
      name
      Branches {
        id
        name
        isPrimary
      }
    }
  }
  InvoiceLines {
    id
    quantity
    description
    expenseType
    rate
    qbAccount
    displayOrder
    staffNames
    createdAt
  }
  QbErrors {
    statusCode
    statusSeverity
    statusMessage
    createdAt
  }
  invoiceNumber
  billDate
  details
  qbExported
  qbTicketId
  createdBy {
    firstName
    lastName
  }
  BillTo {
    id
    firstName
    middleName
    lastName
    suffix
    BillingAddress {
      id
      isPrimary
      address1
      address2
      address3
      city
      state
      postal
      country
      latitude
      longitude
      Type {
        id
        name
      }
    }
    Branches {
      id
      name
      Company {
        id
        name
      }
    }
  }
  BillToBranch {
    id
    name
    Company {
      id
      name
      billingInstructions
      qbListId
    }
    BillingAddress {
      id
      isPrimary
      address1
      address2
      address3
      city
      state
      postal
      country
      latitude
      longitude
      Type {
        id
        name
      }
    }
  }
  createdById
  createdAt
  updatedAt
`;

export const InvoiceConnectionQuery = gql`${buildConnectionQuery("Invoice", "InvoiceConnection", nodeFragment)}`;

export const getInvoiceByIdQuery = gql`query getInvoiceById($id: String!) {
  getInvoiceById(id: $id) { ${ nodeFragment } }
}`;

export const addInvoiceMutation = gql`mutation AddInvoice($input: AddInvoiceInput!) {
  addInvoice(input: $input)
}`;

export const updateInvoiceMutation = gql`mutation UpdateInvoice($input: UpdateInvoiceInput!) {
  updateInvoice(input: $input)
}`;

export const removeInvoiceMutation = gql`mutation RemoveInvoice($id: ID!) {
  removeInvoice(id: $id)
}`;

export const resubmitInvoiceMutation = gql`mutation ResubmitInvoice($id: ID!) {
  resubmitInvoice(id: $id)
}`;