import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { DialogContentBase, DialogRef } from "@progress/kendo-angular-dialog";
import { uniqueId } from "lodash";
import { finalize } from "rxjs/operators";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { CanDeactivateService, S3Service } from "../../services";
import {
  FileInterface,
  inputDataFileSelect,
  uploadFilesPayLoad,
} from "../../services/s3/s3-service";
import { IApiUploadTypes } from "../../modules/graphql/types/types";
import { Subscription } from "rxjs";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { NotificationsService } from "../../modules/notifications/notifications.service";
import { csvMimeTypes, excelMimeTypes, imageMimeType, pdfMimeType, videoMimeType, wordMimeTypes, zipMimeType } from "../../helpers/helper";
import { FormatFileSizePipe } from "../../pipes";

@Component({
  selector: "app-file-select-modal",
  templateUrl: "./file-select-modal.component.html",
  styleUrls: ["./file-select-modal.component.scss"],
})
export class FileSelectModalComponent extends DialogContentBase implements OnDestroy {
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  @Input() data: inputDataFileSelect = {
    type: IApiUploadTypes.Photos,
    InvestigationId: null, /* Document type like Documents, DocumentsDownloads, Photos, Expense, Evidence required InvestigationId */
    FolderId: null, /* Document type like Photos requied FolderId */
    UserId: null, /* Document type like Certificates, Unlink Expense, Singnature required UserId */
    restrictions: ["image/jpeg", "image/png"],
    multiple: true,
    limit: 750,
    restrictionsSize: [ { zip: null, video: null } ] /* zip / video file size validation */ 
  };

  public label = {
    visible: false,
    position: "start",
    format: "percent",
  };
  public files: FileInterface[] = [];
  public selectedFileForCrop: FileInterface;
  public maxParallelUploads = 3;
  public uploadProgress: { [key: string]: number } = {};
  public isProcessing = false;
  public isScrolling = false;
  private scrollTimeout: any;
  private s3Subscription: Subscription;
  public iscropFeatureEnabled: boolean = false; /* Crop feature Disabled */
  private destroy = false;

  constructor(
    public dialog: DialogRef,
    public sanitizer: DomSanitizer,
    private s3Service: S3Service,
    private changeDetectorRef: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private canDeactivateService: CanDeactivateService,
    private formatFileSizePipe: FormatFileSizePipe,
  ) {
    super(dialog);
    this.s3Service.fileProgressSubject.subscribe((progress) => {
      if (progress?.id) {
        this.uploadProgress[progress.id] = progress.percentage;
        const maxId = Number(Math.max(...Object.keys(this.uploadProgress).map(i => !isNaN(+(i)) && Number(i))));
        const maxIndex = this.files.findIndex(i => Number(i.id) === maxId);
        if (maxIndex !== -1) {
          this.viewport.scrollToIndex((maxIndex - 1), 'smooth');
        }
      }
    })
  }

  public async uploadFile(filesUploaded: File[] = []) {
    if (!this.data.multiple && ((this.files?.length + filesUploaded?.length) > 1)) {
      this.notificationsService.notify(
        `The limit for uploading 1 file, please select 1 file and try again.`,
        { icon: false, style: "warning" }
      );
      return;
    }
    if (this.data.multiple && ((this.files?.length + filesUploaded?.length) > this.data.limit)) {
      this.notificationsService.notify(
        `The limit for uploading ${this.data?.limit} files, please select fewer files and try again.`,
        { icon: false, style: "warning" }
      );
      return;
    }
    const files = Object.values(filesUploaded).map((file: File) => {
      const isImage = file.type.startsWith("image/");
      file = new File([file], file.name, { type: file?.type || 'application/octet-stream' }); /* default mimetype set if no mimetype found */
      return {
        id: uniqueId(),
        file: file,
        objectURL: isImage ? URL.createObjectURL(file) : null,
        preview: isImage
          ? this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
          : null,
        cropFile: null,
        isImage: isImage,
        width: null,
        height: null,
        errorMessage: this.getErrorMessage(file),
      };
    });

    if (this.data.multiple) {
      this.files = [...this.files, ...files];
    } else {
      this.files = [...[], ...files];
    }

    const processFile = this.files.filter(item => item.isImage && !item.width && !item.height) || [];
    this.isProcessing = processFile.length ? true : false;
    let processFileIncresed = 0;
    for (const fileObj of processFile) {
      try {
        const dimensions = await this.getImageDimensions(fileObj.file);
        fileObj.width = dimensions.width;
        fileObj.height = dimensions.height;
        processFileIncresed++
        if (processFileIncresed === processFile.length) {
          this.isProcessing = false;
        }
      } catch (error) {
        processFileIncresed++
        this.isProcessing = false;
        console.error('Error getting image dimensions:', error);
      }
    }
  }

  private getErrorMessage(file: File) {
    const zip = this.data?.restrictionsSize?.find((item) => item?.zip < file.size);
    const video = this.data?.restrictionsSize?.find((item) => item?.video < file.size);
    if (!this.data?.restrictions.includes(file.type)) {
      return `(Invalid file format.)`;
    } else if (zip && zipMimeType.includes(file.type)) {
      return `(Invalid file size. Maximum allowed zip size is ${this.formatFileSizePipe.transform(zip.zip, false)}.)`
    } else if (video && videoMimeType.includes(file.type)) {
      return `(Invalid file size. Maximum allowed video size is ${this.formatFileSizePipe.transform(video.video, false)}.)`
    } else {
      return null;
    }
  }

  private getImageDimensions(file: File): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };

  get isValid() {
    return this.files.some((i) => i.errorMessage);
  }

  public edit(file: FileInterface): void {
    this.selectedFileForCrop = file;
  }

  public remove(file: FileInterface): void {
    URL.revokeObjectURL(file.objectURL);
    this.files = this.files.filter((item) => item.id !== file.id);
  }

  imageCropped(event: ImageCroppedEvent) {
    const find = this.files.find((i) => i.id === this.selectedFileForCrop.id);
    const croppedFile = {
      ...event,
      file: new File([event.blob], find.file.name, {
        type: event.blob.type,
        lastModified: Date.now(),
      }),
      objectUrl: this.sanitizer.bypassSecurityTrustUrl(
        event.objectUrl
      ) as string,
    };
    find.cropFile = croppedFile;
  }

  public revertCrop(file: FileInterface) {
    const find = this.files.find((i) => i.id === file.id);
    find.cropFile = null;
  }

  public resetCrop() {
    const find = this.files.find((i) => i.id === this.selectedFileForCrop.id);
    find.cropFile = null;
    this.selectedFileForCrop = null;
  }

  public saveCrop() {
    this.selectedFileForCrop = null;
  }

  ngOnDestroy(): void {
    this.destroy = true;
    this.files.forEach((file: FileInterface) => {
      URL.revokeObjectURL(file.objectURL);
    });
    if (this.s3Subscription) {
      this.s3Subscription.unsubscribe();
    }
    this.canDeactivateService.setCanDeactivate(true);
  }

  getType(type: string, fileType: string): string | null {
    const mimeTypesMap = {
      image: imageMimeType,
      pdf: pdfMimeType,
      word: wordMimeTypes,
      excel: excelMimeTypes,
      csv: csvMimeTypes,
      zip: zipMimeType,
      video: videoMimeType
    };

    for (const [key, value] of Object.entries(mimeTypesMap)) {
      if (key === type && value.includes(fileType)) {
        return fileType;
      }
    }
    return null;
  }

  onError(event: Event, file: FileInterface) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = "./assets/images/file.png";
    if (file) {
      file.isImage = false;
    }
  }

  onScroll() {
    this.isScrolling = true;
    this.changeDetectorRef.detectChanges(); // Ensure change detection

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    // Set a timeout to remove the loading indicator after scrolling ends
    this.scrollTimeout = setTimeout(() => {
      this.isScrolling = false;
      this.changeDetectorRef.detectChanges();
    }, 200); // Adjust the timeout duration as needed
  }

  trackBy(index: number, item: FileInterface) {
    return item.id;
  }

  public onSave(): void {
    this.isProcessing = true;
    this.canDeactivateService.setCanDeactivate(false);
    const uploadPayLoad: uploadFilesPayLoad = {
      type: this.data.type,
      InvestigationId: this.data.InvestigationId,
      FolderId: this.data.FolderId,
      UserId: this.data.UserId,
      files: this.files,
      maxParallelUploads: this.maxParallelUploads,
    };
    this.s3Subscription = this.s3Service
      .uploadFiles(uploadPayLoad)
      .pipe(
        finalize(() => {
          if (!this.destroy) {
            this.isProcessing = false;
            this.canDeactivateService.setCanDeactivate(true);
            if (this.files.some(file => !file.key)) {
              this.files = [];
              return this.notificationsService.error('We are experiencing challenges with processing files on the client side. Please try again later.');
            } else {
              return this.dialog.close(this.files);
            }
          }
        })
      ).subscribe({
        error: (error) => {
          this.canDeactivateService.setCanDeactivate(true);
          console.error("Error uploading files", error);
        }
      });
  }

  public preventClose(e){
    e.prevented = true;
    this.onClose(false);
  }

  public onClose(closed): void {
    if (this.isProcessing === true) {
      this.notificationsService.kendoConfirm(
        'Are you sure you want to cancel this process? Any uploaded file(s) will not be saved?',
        "Confirm Close?",
        "No",
        "Yes",
        550
      ).subscribe((res) => {
        if (res) {
          this.dialog.close(closed);
        }
      });
    } else {
      this.dialog.close(closed);
    }
  }

}
