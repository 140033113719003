<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationAssignStaffUpdate"
  #investigationAssignStaffUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationAssignStaffDelete"
  #investigationAssignStaffDelete="appEnforcePermission"></div>
<!-- Permissions -->
<section class="mt-16">
  <kendo-grid [data]="data" [pageable]="false" [scrollable]="'none'"
    [selectable]="{enabled: true, checkboxOnly: true, cell: true}">
    <!-- Name Definition -->
    <kendo-grid-column title="Name" [field]="'NAME'" [width]="50" [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <p class="mb-0">{{ (dataItem?.User | fullName )|| '-' }}</p>
      </ng-template>
    </kendo-grid-column>
    <!-- Primary Definition -->
    <kendo-grid-column title="Primary" [field]="'PRIMARY'" [width]="50" [sortable]="false" [headerClass]="'nocursor'"
      *ngIf="title === 'INVESTIGATOR' || title === 'TECH_REVIEWER'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <input kendoCheckBox type="checkbox" [(ngModel)]="dataItem.isPrimary" (ngModelChange)="update(dataItem)"
          *ngIf="investigationAssignStaffUpdate?.enabled" />
      </ng-template>
    </kendo-grid-column>
    <!-- Client Requested Definition -->
    <kendo-grid-column title="Client Requested" [field]="'CLIENT_REQUESTED'" [width]="50" [sortable]="false"
      [headerClass]="'nocursor'" *ngIf="title === 'INVESTIGATOR'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <input kendoCheckBox type="checkbox" [(ngModel)]="dataItem.clientRequested" (ngModelChange)="update(dataItem)"
          *ngIf="investigationAssignStaffUpdate?.enabled" />
      </ng-template>
    </kendo-grid-column>
    <!-- Role definition -->
    <kendo-grid-column title="Role" [field]="'ROLE'" [width]="50" [sortable]="false" [headerClass]="'nocursor'"
      *ngIf="title === 'OTHER'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <p class="mb-0" [innerHTML]="dataItem?.Role?.title ||  '-'"></p>
      </ng-template>
    </kendo-grid-column>
    <!-- Actions -->
    <kendo-grid-column title="" [field]="'ACTION'" [width]="50" [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton themeColor="error" fillMode="link" class="p-0" [iconClass]="'fa-solid fa-trash'"
          *ngIf="investigationAssignStaffDelete?.enabled" (click)="delete(dataItem?.id)">
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</section>
