import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { IApiAddExpenseItemInput, IApiExpenseItem, IApiExpenseItemConnection, IApiExpenseItemFilter, IApiExpenseItemFilterType, IApiUpdateExpenseItemInput } from "../../../types/types";
import { ExpenseItemConnectionQuery, addExpenseItemMutation, updateExpenseItemMutation, removeExpenseItemMutation, deactivateExpenseItemMutation, activateExpenseItemMutation, accountSummaryExpenseReport } from './expense-item.operations';

@Injectable()
export class GqlExpenseItemService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getExpenseItems(filters: IApiExpenseItemFilter[], options: IGqlBaseOptions): Observable<IApiExpenseItemConnection> {
    const includeSummary = filters.find(obj => (obj.type === IApiExpenseItemFilterType.Summary));
    return this.apollo.query(connectionQuery(ExpenseItemConnectionQuery(includeSummary ? true : false), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addExpenseItem(input: IApiAddExpenseItemInput): Observable<IApiExpenseItem> {
    return this.apollo.mutate({
      mutation: addExpenseItemMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateExpenseItem(input: IApiUpdateExpenseItemInput): Observable<IApiExpenseItem> {
    return this.apollo.mutate({
      mutation: updateExpenseItemMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeExpenseItem(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeExpenseItemMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public deactivateExpenseItem(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: deactivateExpenseItemMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public activateExpenseItem(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: activateExpenseItemMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public accountSummaryExpenseReport(filters: IApiExpenseItemFilter[], options: IGqlBaseOptions): Observable<string> {
    return this.apollo.query(connectionQuery(accountSummaryExpenseReport, filters, options)).pipe(
      safeResolve(true)
    );
  }
}
