import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IPortalActivate, IPortalCredentials, IPortalSignUp, IPortalTokenResponse, IPortalUserDetail } from '../../../components/portal/interfaces/portal.interfaces';
import { LocalStorageService } from './local.service';
import { tap } from 'rxjs/operators';
import moment from "moment";
import { Router } from '@angular/router';

@Injectable()
export class PortalAuthService {

    public get user(): IPortalTokenResponse | null {
        if (!this.local.user || this.local.user.expires <= moment().millisecond()) {
            return null;
        }
        return this.local.user;
    }

    constructor(
        private http: HttpClient,
        private local: LocalStorageService,
        private router: Router
    ) { }

    private baseUrl = environment.portal.base_url;

    public login(credentials: IPortalCredentials) {
        return this.http.post(`${this.baseUrl}/auth/login`, credentials).pipe(
            tap((user: IPortalTokenResponse) => {
                this.local.savePortalUser(user);
            })
        );
    }

    public logout() {
        this.local.removePortalUser();
        this.router.navigate(["portal", "login"]);
    }

    public signup(data: IPortalSignUp) {
        return this.http.post(`${this.baseUrl}/users/signup`, data).pipe(
            tap((user: IPortalUserDetail) => this.local.savePortalUser({ userId: user.id }))
        );
    }

    public activate(data: IPortalActivate) {
        return this.http.put(`${this.baseUrl}/users/activate`, data);
    }

    public tokenRefresh() {
        return this.http.get(`${this.baseUrl}/auth/exchange`).pipe(
            tap((user) => this.local.savePortalUser({}))
        );
    }

}
