import { Component, forwardRef, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { IApiCompany, IApiCompanyFilterType } from '../../modules/graphql/types/types';
import { CompanyService } from 'src/app/shared/services';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { unwrapConnection } from '../../rxjs.pipes';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { debounceTime } from 'rxjs/operators';
import {
  companies
} from "src/app/shared/helpers/auth-config/companies.config";
import _ from 'lodash';
@Component({
  selector: 'app-company-select-kendo',
  templateUrl: './company-select-kendo.component.html',
  styleUrls: ['./company-select-kendo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanySelectKendoComponent),
      multi: true
    }
  ]
})
export class CompanySelectKendoComponent implements OnChanges {

  public authConfig = {
    companies
  };

  @ViewChild("multiSelect", { static: false }) public multiSelect: MultiSelectComponent;
  @Input() placeholder = "Client (Company)";
  @Input() label = "";
  @Input() disabled = null;
  @Input() includeInactive = false;
  @Input() selectedId: Array<string> = [];

  public companies: IApiCompany[];

  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public loading: boolean = false;
  public searchString: string = '';
  public selectedValue = [];
  private selectedCompanies = [];

  constructor(
    private companyService: CompanyService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
   if (!_.isEqualWith(this.selectedValue, changes?.selectedId?.currentValue)) {
      this.selectedId = changes?.selectedId?.currentValue || [];
      if (this.selectedId?.length) {
        const filters = [{
          type: IApiCompanyFilterType.Ids,
          value: JSON.stringify(this.selectedId)
        }, {
          type: IApiCompanyFilterType.ViewOnlyName,
          value: 'true'
        }];
        this.loading = true;
        this.companyService.get(filters).pipe(
          unwrapConnection()
        ).subscribe((results) => {
          this.loading = false;
          this.selectedCompanies = results;
          this.companies = this.selectedCompanies;
          this.selectedValue = this.selectedId
        });
      } else {
        this.selectedValue = [];
        this.selectedCompanies = [];
        this.companies = [];
      }
    }
  }

  public onSearchFocus() {
    if (this.selectedCompanies) {
      this.companies = this.selectedCompanies;
    }
  }

  public selectCompany(selected: any) {
    this.selectedCompanies = this.companies.filter(usr => selected?.includes(usr.id));
    this.selectionChanged.emit(this.selectedValue);
    this._propagateChange(this.selectedValue);
  }

  public onSearchChange(search: string) {
    this.searchString = search?.trim();
    this.multiSelect.toggle(false);
    if (this.selectedCompanies) {
      this.companies = this.selectedCompanies;
    } else {
      this.companies = [];
    }
    if ((search?.trim())?.length < 3) return;

    const filters = [{
      type: IApiCompanyFilterType.Name,
      value: search?.trim()
    }, {
      type: IApiCompanyFilterType.ViewOnlyName,
      value: 'true'
    }];

    this.loading = true;
    this.multiSelect.toggle(false)
    this.companyService.get(filters).pipe(
      debounceTime(800),
      unwrapConnection()
    ).subscribe(results => {
      this.loading = false;
      this.multiSelect.toggle(true);
      const newResult = results?.filter((res) => !this.selectedValue?.includes(res.id))
      this.companies = [...this.selectedCompanies, ...newResult];
    });
  }

  private _propagateChange = (_: any) => { };

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

}
