import { IApiEvidenceBilling, IApiEvidenceBillingConnection, IApiEvidenceBillingFilter } from './../../../modules/graphql/types/types';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { EvidenceBillingService } from "./evidence-billing.service";
import { NefcoPagedDataSource } from '../../../helpers/nefco-data-source.class';

export class EvidenceBillingDataSource extends NefcoPagedDataSource<IApiEvidenceBilling, EvidenceBillingService, IApiEvidenceBillingConnection> implements DataSource<IApiEvidenceBilling> {
  @ApiConnection()
  public load(filters: IApiEvidenceBillingFilter[] = []): Promise<IApiEvidenceBillingConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
