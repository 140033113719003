import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { addExpensePurposeMutation, ExpensePurposeConnectionQuery, removeExpensePurposeMutation, updateExpensePurposeMutation, toggleExpensePurposeActivationMutation } from "./expense-purpose.operations";
import { IApiAddExpensePurposeInput, IApiExpensePurpose, IApiExpensePurposeConnection, IApiExpensePurposeFilter, IApiUpdateExpensePurposeInput } from "../../../types/types";

@Injectable()
export class GqlExpensePurposeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getExpensePurposes(filters: IApiExpensePurposeFilter[], options: IGqlBaseOptions): Observable<IApiExpensePurposeConnection> {
    return this.apollo.query(connectionQuery(ExpensePurposeConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addExpensePurpose(input: IApiAddExpensePurposeInput): Observable<IApiExpensePurpose> {
    return this.apollo.mutate({
      mutation: addExpensePurposeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public updateExpensePurpose(input: IApiUpdateExpensePurposeInput): Observable<IApiExpensePurpose> {
    return this.apollo.mutate({
      mutation: updateExpensePurposeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeExpensePurpose(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeExpensePurposeMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public toggleExpensePurposeActivation(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: toggleExpensePurposeActivationMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}

