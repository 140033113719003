import { Component, Input, OnInit } from '@angular/core';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiDocumentType, IApiExpense, IApiExpenseFilter, IApiExpenseFilterType, IApiExpenseItem, IApiExpenseOrderBy, IApiExpenseReportTotal, IApiInvestigation, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { ExpenseDataSource, ExpenseService } from 'src/app/shared/services/expenses';
import moment from 'moment';
import { ExpenseItem, ExpensePurpose } from 'src/app/shared/modules/graphql/interfaces';
import { IConfig } from '../../expense-items-view/expense-items-view.component';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { IInvestigationTimeExpenseModalData, InvestigationTimeAndExpModalKendoComponent } from '../../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { filter, take } from 'rxjs/operators';
import { ExpenseActionType } from 'src/app/shared/modules/graphql/enums/expense.enums';
import { AuthService } from 'src/app/shared/services';
import { expenseOutOfPocketCreate } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';

@Component({
  selector: 'app-oop-expense-report',
  templateUrl: './oop-expense-report.component.html',
  styleUrls: ['./oop-expense-report.component.scss']
})
export class OopExpenseReportComponent implements OnInit {

  public authConfig = {
    expenseOutOfPocketCreate
  }

  @Input() public expenseItems: IApiExpenseItem[];
  @Input() public expensePurposes?: ExpensePurpose[] = [];
  @Input() public investigations: IApiInvestigation[];
  @Input() public documentTypes: IApiDocumentType[];

  public config: IConfig = { admin: false, reimbursable: true };;
  public _oopSelectedMonth = {
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
  };

  public get oopSelectedMonth() {
    return this._oopSelectedMonth;
  }

  public set oopSelectedMonth(val: any) {
    this._oopSelectedMonth = val;
    const nefoDateRange = val ? NefcoDateHelper.dateFilterStringUTCFromLocal(val?.startDate, val?.endDate) : null;
    this.setExpenseFilters(nefoDateRange || null, IApiExpenseFilterType.EntryDateRange);
  }
  public noNextRange: boolean = true;
  private _expenseFilter: IApiExpenseFilter[] = [];

  public oopExpenseTotal: IApiExpenseReportTotal
  private _dataSource: ExpenseDataSource;

  private _expenses: IApiExpense[] = [];

  public get expenses() {
    return this._expenses;
  }
  public set expenses(val) {
    this._expenses = val;
  }

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  private user: IApiUser;

  constructor(
    private expenseService: ExpenseService,
    private loader: LoaderService,
    private dialogService: DialogService,
    private auth: AuthService
  ) {
    this.dataSource = new ExpenseDataSource(this.expenseService);
    this.loader.attachObservable(this.dataSource.loading$);
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
    this.dataSource.listPage.orderBy = IApiExpenseOrderBy.CreatedAt;
  }

  ngOnInit(): void {
    this.auth.authenticatedUser.pipe(
      take(1)
    ).subscribe(user => {
      this.user = user
      this.setExpenseFilters(this.user.id, IApiExpenseFilterType.User);
      this.oopSelectedMonth = {
        startDate: moment().startOf("month").toDate(),
        endDate: moment().endOf("month").toDate(),
      };
      this.getExpenses();
    });
  }

  public onMonthDecrease() {
    this.noNextRange = false;
    this.oopSelectedMonth = {
      startDate: moment(this.oopSelectedMonth.startDate).subtract(1, 'month').startOf('month').toDate(),
      endDate: moment(this.oopSelectedMonth.endDate).subtract(1, 'month').endOf('month').toDate(),
    };
    this.getExpenses();
  }

  public onMonthIncrease() {
    if (this.noNextRange) return;
    this.oopSelectedMonth = {
      startDate: moment(this.oopSelectedMonth.startDate).add(1, 'month').startOf('month').toDate(),
      endDate: moment(this.oopSelectedMonth.endDate).add(1, 'month').endOf('month').toDate(),
    };
    this.noNextRange = !moment(this.oopSelectedMonth.endDate).isBefore(moment()) ? true : false;
    this.getExpenses();
  }

  private setExpenseFilters(value: string | undefined, type: IApiExpenseFilterType) {
    const filtersCopy = this._expenseFilter.filter(f => f.type !== type);
    this._expenseFilter = value ? [...filtersCopy, {
      type: type,
      value: value
    }] : filtersCopy;
    this._expenseFilter.map(item => {
      this.dataSource.applyFilter(item.type, item.value);
    });
  }

  public getExpenses() {
    this.setExpenseFilters("true", IApiExpenseFilterType.ExpenseFilterOutForUser);
    this.setExpenseFilters('1', IApiExpenseFilterType.PocketExpenseMileage);
    this.dataSource.load().then(() => {
      this.dataSource?.contents$.subscribe((res) => {
        this.expenses = res;
      });
    });

    this.expenseService.getExpenseReportTotal(this._expenseFilter, this.pageOptions.getPageOptions())
      .subscribe(response => this.oopExpenseTotal = response);
  }


  public itemSelected($event) {
    let action: any = ExpenseActionType.ADD_EXPENSE;
    switch ($event?.text) {
      case 'Add Expense':
        action = ExpenseActionType.ADD_EXPENSE;
        break;
      case 'Add Mileage':
        action = ExpenseActionType.ADD_MILEAGE;
        break;
      default:
        action = '';
        break;
    }

    if (!action) {
      return
    }
    const expenseItem = {
      id: null,
      nefcoVehicle: 0,
      expenseDate: new Date(),
      outOfPocket: action === ExpenseActionType.ADD_EXPENSE ? 1 : 0,
      billableQuantity: null,
      nonBillableQuantity: null,
      description: null,
      User: this.user,
      ExpenseItem: { id: null, name: null },
      ExpensePurpose: { id: null, name: null }
    };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: null,
      nefcoNumber: null,
      expense: expenseItem as IApiExpense,
      time: null,
      staff: null,
      adminView: false,
      showStaffMember: false,
      actionType: action
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = this.investigations;
    dialogInstance.expenseItems = this.expenseItems;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) this.getExpenses()
    })
  }

}
