import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlRequestStatusService } from 'src/app/shared/modules/graphql/services';
import {
  IApiAddRequestStatusInput, IApiRequestStatus, IApiRemoveRequestStatusInput,
  IApiUpdateRequestStatusInput, IApiRequestStatusFilter, IApiRequestStatusConnection
} from 'src/app/shared/modules/graphql/types/types';


@Injectable()
export class RequestStatusService {

  constructor(
    private gqlRequestStatusService: GqlRequestStatusService
  ) { }

  public get(filters: IApiRequestStatusFilter[], options: IGqlBaseOptions): Observable<IApiRequestStatusConnection> {
    return this.gqlRequestStatusService.get(filters, options);
  }

  public list(filters?: IApiRequestStatusFilter[], options?: IGqlBaseOptions): Observable<IApiRequestStatus[]> {
    return this.get(filters, options).pipe(
      map(results => results.edges.map(e => e.node))
    );
  }

  public add(input: IApiAddRequestStatusInput): Observable<IApiRequestStatus> {
    return this.gqlRequestStatusService.add(input);
  }

  public remove(input: IApiRemoveRequestStatusInput): Observable<boolean> {
    return this.gqlRequestStatusService.remove(input);
  }

  public update(input: IApiUpdateRequestStatusInput): Observable<IApiRequestStatus> {
    return this.gqlRequestStatusService.update(input);
  }

  public getRequestStatusById(id: string): Observable<IApiRequestStatus> {
    return this.gqlRequestStatusService.getRequestStatusById(id);
  }
}
