import { Component, Input, OnInit } from '@angular/core';
import { SelectEvent } from "@progress/kendo-angular-layout";

@Component({
  selector: 'app-bookkeeping-expense-reports-tabs',
  templateUrl: './bookkeeping-expense-reports-tabs.component.html',
  styleUrls: ['./bookkeeping-expense-reports-tabs.component.scss']
})
export class BookkeepingExpenseReportsTabsComponent {

  selectedTab: number = 0;

  constructor() {}

  onTabChanged(event: SelectEvent) {
    this.selectedTab = event?.index;
  }

}