<div [dialogRef]="dialog" (appClickOutside)="onClose('Closed')">
<kendo-dialog-titlebar (close)="onClose('Closed')">
  <div class="modal-title">
    <span>{{isAdd ? 'Add' : 'Edit'}} Insured</span>
  </div>
</kendo-dialog-titlebar>
<section class="section-content">
  <form [formGroup]="form" autocomplete="off">

    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label class="font-weight-600 mb-15 asterisk" text="Select Type"></kendo-label>
          <div class="kendo-radio">
            <input type="radio" id="Individual" kendoRadioButton formControlName="insuredType" [value]="true"
              (change)="changedInsuredType(true)" />
            <label class="k-radio-label mr-24" for="Individual">Individual</label>
            <input type="radio" id="Business" kendoRadioButton formControlName="insuredType" [value]="false"
              (change)="changedInsuredType(false)" />
            <label class="k-radio-label" for="Business">Business</label>
          </div>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="form?.controls?.insuredType?.errors?.required">Select Type cannot be left blank</span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <hr />

    <ng-container *ngIf="form?.controls?.insuredType?.value === true">
      <h3 class="field-info mb-20">Contact Information</h3>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['49%', '48%']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" [for]="insuredName" text="Insured Name"></kendo-label>
            <kendo-textbox #insuredName formControlName="insuredName" placeholder="Enter name" fillMode="outline"
              [clearButton]="true"></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.insuredName?.errors?.required">Insured Name cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [for]="suffix" text="Suffix"></kendo-label>
            <kendo-textbox #suffix formControlName="suffix" placeholder="Enter suffix" fillMode="outline"
              [clearButton]="true"></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.suffix?.errors?.required">Suffix cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [for]="nickName" text="Relationship/Title"></kendo-label>
            <kendo-textbox #nickName formControlName="nickName" placeholder="relationship/title" fillMode="outline"
              [clearButton]="true"></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.nickName?.errors?.required">Relationship/Title cannot be left
                blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <ng-container *ngIf="form?.controls?.insuredType?.value === false">
      <h3 class="field-info mb-20">Contact Information</h3>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['31%', '31%', '32%']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" [for]="companyName" text="Insured Company Name"></kendo-label>
            <kendo-textbox #companyName formControlName="companyName" placeholder="Enter company name"
              fillMode="outline" [clearButton]="true"></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.companyName?.errors?.required">Insured Company Name cannot be left
                blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" [for]="contactName" text="Contact Name"></kendo-label>
            <kendo-textbox #contactName formControlName="contactName" placeholder="Enter contact name"
              fillMode="outline" [clearButton]="true"></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.contactName?.errors?.required">Contact Name cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [for]="suffix" text="Suffix"></kendo-label>
            <kendo-textbox #suffix formControlName="suffix" placeholder="Enter suffix" fillMode="outline"
              [clearButton]="true"></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.suffix?.errors?.required">Suffix cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [for]="nickName" text="Relationship/Title"></kendo-label>
            <kendo-textbox #nickName formControlName="nickName" placeholder="Enter relationship/title"
              fillMode="outline" [clearButton]="true"></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.nickName?.errors?.required">Relationship/Title cannot be left
                blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <hr />

    <ng-container *ngFor="let control of  AddressesFieldAsFormArray.controls ;let i = index;" formArrayName='Addresses'>
      <h3 class="field-info mb-20">Address</h3>
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
          <div [formGroupName]="i">
            <kendo-formfield>
              <kendo-label class="asterisk" [for]="dropdownlist" text="Type"></kendo-label>
              <kendo-dropdownlist #dropdownlist [data]="addressTypes" textField="name" valueField="id"
                fillMode="outline" [valuePrimitive]="true" [defaultItem]="{id: '', name: 'Select type' }"
                [popupSettings]="{appendTo: 'component', animate: false}" formControlName="TypeId">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem.name }} </span>
                  <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem.name }} </span>
                </ng-template>
              </kendo-dropdownlist>
              <kendo-formerror>
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="control?.controls?.TypeId?.errors?.required">Type cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['48%', '49%']">
        <kendo-gridlayout-item>
          <div [formGroupName]="i">
            <kendo-formfield>
              <kendo-label class="asterisk" [for]="address1" text="Address 1 (or Department)"></kendo-label>
              <kendo-textbox #address1 formControlName="address1" placeholder="Enter address" fillMode="outline"
                [clearButton]="true"></kendo-textbox>
              <kendo-formerror>
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="control?.controls?.address1?.errors?.required">Address 1 (or Department) cannot be left
                  blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <div [formGroupName]="i">
            <kendo-formfield>
              <kendo-label [for]="address2" text="Address2"></kendo-label>
              <kendo-textbox #address2 formControlName="address2" placeholder="Enter address" fillMode="outline"
                [clearButton]="true"></kendo-textbox>
              <kendo-formerror>
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="control?.controls?.address2?.errors?.required">Address2 cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['31%', '31%', '32%']">
        <kendo-gridlayout-item>
          <div [formGroupName]="i">
            <kendo-formfield>
              <kendo-label class="asterisk" [for]="city" text="City"></kendo-label>
              <kendo-textbox #city formControlName="city" placeholder="Enter city" fillMode="outline"
                [clearButton]="true"></kendo-textbox>
              <kendo-formerror>
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="control?.controls?.city?.errors?.required">City cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <div [formGroupName]="i">
            <kendo-formfield>
              <kendo-label class="asterisk" [for]="state" text="State"></kendo-label>
              <kendo-dropdownlist #state [data]="groupedDataStates" textField="text" valueField="value"
                formControlName="state" [valuePrimitive]="true" fillMode="outline" [filterable]="false"
                (filterChange)="handleFilter($event)" [defaultItem]="{value: '', text: 'Select state', type: ''}"
                [popupSettings]="{appendTo: 'component', animate: false}">
                <ng-template kendoDropDownListGroupTemplate let-groupName>
                  <strong>{{ groupName }}</strong>
                </ng-template>
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.text }} </span>
                  <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.text }} </span>
                </ng-template>
              </kendo-dropdownlist>
              <kendo-formerror>
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="control?.controls?.state?.errors?.required">State cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <div [formGroupName]="i">
            <kendo-formfield>
              <kendo-label class="asterisk" [for]="insuredZipcode" text="Zip Code"></kendo-label>
              <kendo-textbox #insuredZipcode formControlName="postal" placeholder="Enter zip code" fillMode="outline"
                [clearButton]="true"></kendo-textbox>
              <kendo-formerror>
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="control?.controls?.postal?.errors?.required">Zip Code cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <hr />

    <h3 class="field-info mb-20">Phone Number</h3>

    <ng-container *ngFor="let control of  PhonesFieldAsFormArray.controls ;let i = index;" formArrayName='Phones'>
      <kendo-gridlayout class="mb-0" [rows]="['auto']" [gap]="{rows: 0, cols: 24}" [cols]="['47%', '38%', '4%']">
        <kendo-gridlayout-item>
          <kendo-formfield [formGroupName]="i">
            <kendo-label [class.asterisk]="control?.controls?.number?.errors" [for]="number"
              text="Phone Number"></kendo-label>
            <kendo-maskedtextbox #number formControlName="number" fillMode="outline" placeholder="Enter phone number"
              mask="(000) 000-0000"
              (valueChange)="setPhoneListValidator('number', $event, control?.controls?.TypeId?.value, i)"></kendo-maskedtextbox>
            <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="control?.controls?.number?.errors?.required">Phone number cannot be left blank</span>
              <span *ngIf="control?.controls?.number?.errors?.patternError">Invalid phone number</span>
              <span *ngIf="control?.controls?.number?.errors?.pattern">Invalid phone number</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield [formGroupName]="i">
            <kendo-label [class.asterisk]="control?.controls?.TypeId?.errors" [for]="TypeId" text="Type"></kendo-label>
            <kendo-dropdownlist #TypeId [data]="phoneTypes" textField="name" valueField="id" fillMode="outline"
              [valuePrimitive]="true" [defaultItem]="{id: '', name: 'Select Type' }"
              [popupSettings]="{appendTo: 'component', animate: false}" formControlName="TypeId"
              (selectionChange)="setPhoneListValidator('TypeId', $event?.id, control?.controls?.number?.value, i)">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem.name }} </span>
                <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="control?.controls?.TypeId?.errors?.required">Type Id required!</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
    
        <kendo-gridlayout-item class="remove">
          <span *ngIf="i > 0" class="k-icon k-i-minus-outline" (click)="removeaddAdditionalContact(i)"></span>
        </kendo-gridlayout-item>
    
        <kendo-gridlayout-item>
          <kendo-formfield [formGroupName]="i">
            <kendo-label [for]="extension" text="Extension"></kendo-label>
            <kendo-textbox #extension formControlName="extension" placeholder="Enter Extension" fillMode="outline"
              [clearButton]="true"></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="control?.controls?.extension?.errors?.required">Extension cannot be left blank</span>
              <span *ngIf="control?.controls?.extension?.errors?.numberOnly">Please enter only number</span>
              <span *ngIf="!control?.controls?.extension?.errors?.required 
              && !control?.controls?.extension?.errors?.numberOnly 
              && control?.controls?.extension?.errors?.maxlength">Extension should be maximum
                {{control?.controls?.extension?.errors?.maxlength?.requiredLength}} number.</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield [formGroupName]="i">
            <kendo-label [for]="typeDescription" text="Type Description"></kendo-label>
            <kendo-textbox #typeDescription formControlName="type" placeholder="Enter Type Description" fillMode="outline"
              [clearButton]="true"></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="control?.controls?.type?.errors?.required">Type description cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <kendo-gridlayout>
      <kendo-gridlayout-item>
        <button kendoButton class="mr-24" icon="plus-circle" themeColor="primary" fillMode="link"
          (click)="addAdditionalContactToList()">Add Additional Phone Number</button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <hr />

    <h3 class="field-info mb-20">Email Address</h3>
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 24}" [cols]="['47%', '38%', '4%']"
      style="margin-bottom: 0;">
      <ng-container *ngFor="let control of  EmailsFieldAsFormArray.controls ;let i = index;" formArrayName='Emails'>
        <kendo-gridlayout-item>
          <div>
            <div [formGroupName]="i">
              <kendo-formfield>
                <kendo-label [class.asterisk]="control?.controls?.address?.errors" [for]="address" text="Email Address"></kendo-label>
                <kendo-textbox #address formControlName="address" fillMode="outline"
                  placeholder="Enter email address" (valueChange)="setEmailListValidator('address', $event, control?.controls?.TypeId?.value, i)"></kendo-textbox>
                <kendo-formerror>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="control?.controls?.address?.errors?.required">Invalid Email Address</span>
                  <span *ngIf="control?.controls?.address?.errors?.email">Invalid Email Address</span>
                </kendo-formerror>
              </kendo-formfield>
            </div>
          </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <div>
            <div [formGroupName]="i">
              <kendo-formfield>
                <kendo-label [class.asterisk]="control?.controls?.TypeId?.errors" [for]="TypeId" text="Type"></kendo-label>
                <kendo-dropdownlist #TypeId [data]="emailTypes" textField="name" valueField="id" fillMode="outline"
                  [valuePrimitive]="true" [defaultItem]="{id: '', name: 'Select Type' }"
                  [popupSettings]="{appendTo: 'component', animate: false}" formControlName="TypeId"
                  (selectionChange)="setEmailListValidator('TypeId', $event?.id, control?.controls?.address?.value, i)">
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem.name }} </span>
                    <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem.name }} </span>
                  </ng-template>
                </kendo-dropdownlist>
                <kendo-formerror>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="control?.controls?.TypeId?.errors?.required">Type Id required!</span>
                </kendo-formerror>
              </kendo-formfield>
            </div>
          </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="remove">
          <span *ngIf="i > 0" class="k-icon k-i-minus-outline" (click)="removeaddAdditionalEmails(i)"></span>
        </kendo-gridlayout-item>
      </ng-container>
      <kendo-gridlayout-item>
        <button kendoButton class="mr-24" icon="plus-circle" themeColor="primary" fillMode="link"
          (click)="addAdditionalEmailsToList()">Add Additional Email Address</button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

  </form>
</section>
<kendo-dialog-actions layout="end">
  <button kendoButton [size]="'medium'" themeColor="light" class="mr-16" (click)="onClose('Cancel')">Cancel</button>
  <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)=" isAdd ? createInsured(): updateInsured()">Save</button>
</kendo-dialog-actions>
</div>