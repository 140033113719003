import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiSubServicesConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  subServicesConnectionQuery, addSubServiceMutation,
  removeSubServiceMutation, getSubServiceByIdQuery,
  updateSubServiceMutation
} from './sub-service.operations';
import {
  IApiSubServiceFilter, IApiAddSubServiceInput, IApiUpdateSubServiceInput,
  IApiRemoveSubServiceInput, IApiSubService
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlSubServiceService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiSubServiceFilter[], options: IGqlBaseOptions): Observable<IApiSubServicesConnection> {
    return this.apollo.query(connectionQuery(subServicesConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddSubServiceInput): Observable<IApiSubService> {
    return this.apollo.mutate({
      mutation: addSubServiceMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeSubServiceMutation,
      variables: { id: id }
    }).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateSubServiceInput): Observable<IApiSubService> {
    return this.apollo.mutate({
      mutation: updateSubServiceMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getSubServiceById(id: string): Observable<IApiSubService> {
    return this.apollo.query({
      query: getSubServiceByIdQuery,
      variables: { id: id }
    }).pipe(
      safeResolve(true)
    );
  }
}
