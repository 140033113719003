import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  palletCount
  override
  billable
  rate
  startDate
  Investigation {
    id
  }
  BillingFrequency {
    id
  }
  createdAt
  updatedAt
`;

export const evidenceBillingConnectionQuery = gql`${buildConnectionQuery("EvidenceBilling", "evidenceBillingConnection", nodeFragment)}`;

export const addEvidenceBillingMutation = gql`mutation createEvidenceBilling($input: AddEvidenceBillingInput!) {
  addEvidenceBilling(input: $input) { ${ nodeFragment } }
}`;

export const removeEvidenceBillingMutation = gql`mutation removeEvidenceBilling($id: ID!) {
  removeEvidenceBilling(id: $id) { ${ nodeFragment } }
}`;

export const updateEvidenceBillingMutation = gql`mutation updateEvidenceBilling($input: UpdateEvidenceBillingInput!) {
  updateEvidenceBilling(input: $input) { ${ nodeFragment } }
}`;
