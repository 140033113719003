import { Component } from '@angular/core';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent {

  constructor() { }

  public headerIcon: string = 'assets/svg/warning.svg';

}
