import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsTechReviewer, IApiInvestigationMetricsTechReviewerConnection } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { InvestigationMetricsTechReviewerService } from './investigation-metrics-tech-reviewer.service';

export class InvestigationMetricsTechReviewerDatasource extends NefcoPagedDataSource<IApiInvestigationMetricsTechReviewer, InvestigationMetricsTechReviewerService, IApiInvestigationMetricsTechReviewerConnection> implements DataSource<IApiInvestigationMetricsTechReviewer> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsTechReviewerConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
