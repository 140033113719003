import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { IApiAddDocumentSentInput, IApiDocumentConnection, IApiDocumentFilter, IApiUpdateDocumentInput } from '../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { GqlDocumentService, GqlDocumentTypeService, GqlDocumentCategoryService } from '../../modules/graphql/services';
import { Injectable } from '@angular/core';
import { IApiAddDocumentInput, IApiDocument } from "../../modules/graphql/types/types";

@Injectable()
export class DocumentService {

  constructor(
    private documents: GqlDocumentService,
  ) { }

  public get(filters: IApiDocumentFilter[] = [], options?: IGqlBaseOptions): Observable<IApiDocumentConnection> {
    return this.documents.getDocuments(filters, options || new ListPage(-1));
  }

  public add(documentInput: IApiAddDocumentInput): Observable<IApiDocument> {
    return this.documents.addDocument(documentInput);
  }

  public sentDocument(documentInput: IApiAddDocumentSentInput): Observable<IApiDocument> {
    return this.documents.sentDocument(documentInput);
  }

  public update(documentInput: IApiUpdateDocumentInput): Observable<IApiDocument> {
    return this.documents.updateDocument(documentInput);
  }

  public remove(documentId: string): Observable<boolean> {
    return this.documents.removeDocument(documentId);
  }
}
