import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsRiskTypeConnection } from '../../../../types/types';
import { IGqlBaseOptions } from '../../../../interfaces';
import { connectionQuery } from '../../../../helpers.class';
import { investigationMetricsRiskTypesConnection } from './investigation-metrics-risk-type.operations';

@Injectable()
export class GqlInvestigationMetricsRiskTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiInvestigationMetricsFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationMetricsRiskTypeConnection> {
    return this.apollo.query(connectionQuery(investigationMetricsRiskTypesConnection, filters, options)).pipe(
      safeResolve(true)
    );
  }

}
