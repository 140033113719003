import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { InvestigationPriorityService } from 'src/app/shared/services';
import { IApiInvestigationPriority } from './../../../../shared/modules/graphql/types/types';
import { Component, forwardRef, Input, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@Component({
  selector: 'app-investigation-priorities-kendo',
  templateUrl: './investigation-priorities-kendo.component.html',
  styleUrls: ['./investigation-priorities-kendo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InvestigationPrioritiesKendoComponent),
      multi: true
    }
  ]
})
export class InvestigationPrioritiesKendoComponent implements ControlValueAccessor {

  @Input() priorityId = '';
  public priorities: IApiInvestigationPriority[];

  constructor(private prioritiesService: InvestigationPriorityService) {
    this.prioritiesService.get().pipe(
      unwrapConnection()
    ).subscribe((priorities) => {
      this.priorities = priorities;

      // This needs to be conditional so we don't override the input value on load
      if (!this.priorityId) {
        this.priorityId = this.priorities.find(({ name }) => name === "Low").id;
        this._propagateChange(this.priorityId);
      }
    });
  }

  public handleSelectionChange(event) {
    this.priorityId = event;
    this._propagateChange(this.priorityId);
  }

  private _propagateChange = (_: any) => { };

  // Control Value accessor members
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.priorityId = value;
    } else if (this.priorities) {
      // check this here in case we get an undefined value but have already loaded the priorities
      this.priorityId = this.priorities.find(({ name }) => name === "Low").id;
      this._propagateChange(this.priorityId);
    }
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }
}
