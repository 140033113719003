import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlServiceService } from 'src/app/shared/modules/graphql/services';
import {
  IApiAddServiceInput, IApiService, IApiRemoveServiceInput,
  IApiUpdateServiceInput, IApiServiceFilter, IApiServicesConnection
} from 'src/app/shared/modules/graphql/types/types';


@Injectable()
export class ServiceService {

  constructor(
    private gqlServiceService: GqlServiceService
  ) { }

  public get(filters: IApiServiceFilter[], options?: IGqlBaseOptions): Observable<IApiServicesConnection> {
    return this.gqlServiceService.get(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddServiceInput): Observable<IApiService> {
    return this.gqlServiceService.add(input);
  }

  public remove(id: string) {
    return this.gqlServiceService.remove(id);
  }

  public update(input: IApiUpdateServiceInput): Observable<IApiService> {
    return this.gqlServiceService.update(input);
  }

  public getServiceById(id: string): Observable<IApiService> {
    return this.gqlServiceService.getServiceById(id);
  }
}
