import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsInvestigator, IApiInvestigationMetricsRiskTypeConnection, IApiInvestigationRiskTypeMetricsNode } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { InvestigationMetricsRiskTypeService } from './investigation-metrics-risk-type.service';

export class InvestigationMetricsRiskTypeDatasource extends NefcoPagedDataSource<IApiInvestigationRiskTypeMetricsNode, InvestigationMetricsRiskTypeService, IApiInvestigationMetricsRiskTypeConnection> implements DataSource<IApiInvestigationRiskTypeMetricsNode> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsRiskTypeConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
