import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
`;

export const billingRuleCategoryConnectionQuery = gql`${buildConnectionQuery("BillingRuleCategory", "billingRuleCategoryConnection", nodeFragment)}`;

export const addBillingRuleCategory = gql`mutation addbillingRuleCategory($input: AddbillingRuleCategoryInput!) {
  addbillingRuleCategory(input: $input) { ${nodeFragment} }
  }`;

export const removeBillingRuleCategoryMutation = gql`mutation removebillingRuleCategory($input: RemovebillingRuleCategoryInput!) {
  removebillingRuleCategory(input: $input) { ${nodeFragment} }
}`;

export const updateBillingRuleCategoryMutation = gql`mutation updatebillingRuleCategory($input: UpdatebillingRuleCategoryInput!) {
  updatebillingRuleCategory(input: $input) { ${nodeFragment} }
}`;

export const getBillingRuleCategoryByIdQuery = gql`query getbillingRuleCategoryById($id: ID!) {
  getbillingRuleCategoryById(id: $id) {${nodeFragment}}
}`;
