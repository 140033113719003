import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/enums/permissions.enums";

export const manageDocumentTypes: IEnforcePermissionConfig = {
  category: PermissionCategories.MANAGE_DOCUMENT_TYPES,
  appliedPermissions: {
    All: Object.values(PermissionActions),
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - Document Types
export const manageDocumentTypesList = {
  category: PermissionCategories.MANAGE_DOCUMENT_TYPES,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageDocumentTypesCreate = {
  category: PermissionCategories.MANAGE_DOCUMENT_TYPES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageDocumentTypesUpdate = {
  category: PermissionCategories.MANAGE_DOCUMENT_TYPES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageDocumentTypesDelete = {
  category: PermissionCategories.MANAGE_DOCUMENT_TYPES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - User Roles
export const manageUserRolesList = {
  category: PermissionCategories.MANAGE_USER_ROLES,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRolesCreate = {
  category: PermissionCategories.MANAGE_USER_ROLES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRolesUpdate = {
  category: PermissionCategories.MANAGE_USER_ROLES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRolesDelete = {
  category: PermissionCategories.MANAGE_USER_ROLES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRolesView = {
  category: PermissionCategories.MANAGE_USER_ROLES,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - User Role Permissions
export const manageUserRolePermissionsList = {
  category: PermissionCategories.MANAGE_USER_ROLE_PERMISSIONS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRolePermissionsView = {
  category: PermissionCategories.MANAGE_USER_ROLE_PERMISSIONS,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRolePermissionsCreate = {
  category: PermissionCategories.MANAGE_USER_ROLE_PERMISSIONS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRolePermissionsDelete = {
  category: PermissionCategories.MANAGE_USER_ROLE_PERMISSIONS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - User Role Staff Members
export const manageUserRoleStaffMembersList = {
  category: PermissionCategories.MANAGE_USER_ROLE_STAFF,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRoleStaffMembersCreate = {
  category: PermissionCategories.MANAGE_USER_ROLE_STAFF,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRoleStaffMembersDelete = {
  category: PermissionCategories.MANAGE_USER_ROLE_STAFF,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - Document Templating
export const manageDocumentTemplatingView = {
  category: PermissionCategories.MANAGE_DOCUMENT_TEMPLATING,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manage = { ...manageDocumentTypes, category: PermissionCategories.MANAGE };
export const manageExpenseItems = { ...manageDocumentTypes, category: PermissionCategories.MANAGE_EXPENSE_ITEMS };
export const manageServices = { ...manageDocumentTypes, category: PermissionCategories.MANAGE_SERVICES };
export const manageSubServices = { ...manageDocumentTypes, category: PermissionCategories.MANAGE_SUB_SERVICES };
export const manageUserRoles = { ...manageDocumentTypes, category: PermissionCategories.MANAGE_USER_ROLES };
export const manageUserRolesPermissions = { ...manageDocumentTypes, category: PermissionCategories.MANAGE_USER_ROLE_PERMISSIONS };

// Manage - Expense Report Categories
export const manageExpenseReportCategoriesList = {
  category: PermissionCategories.MANAGE_EXPENSE_REPORT_CATEGORIES,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpenseReportCategoriesCreate = {
  category: PermissionCategories.MANAGE_EXPENSE_REPORT_CATEGORIES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpenseReportCategoriesUpdate = {
  category: PermissionCategories.MANAGE_EXPENSE_REPORT_CATEGORIES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpenseReportCategoriesDelete = {
  category: PermissionCategories.MANAGE_EXPENSE_REPORT_CATEGORIES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - Expense Items
export const manageExpenseItemsList = {
  category: PermissionCategories.MANAGE_EXPENSE_ITEMS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpenseItemsCreate = {
  category: PermissionCategories.MANAGE_EXPENSE_ITEMS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpenseItemsUpdate = {
  category: PermissionCategories.MANAGE_EXPENSE_ITEMS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpenseItemsDelete = {
  category: PermissionCategories.MANAGE_EXPENSE_ITEMS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpenseItemsDeactivat = {
  category: PermissionCategories.MANAGE_EXPENSE_ITEMS,
  appliedPermissions: {
    All: [PermissionActions.DEACTIVATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - Expense Purposes
export const manageExpensePurposesList = {
  category: PermissionCategories.MANAGE_EXPENSE_PURPOSES,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpensePurposesCreate = {
  category: PermissionCategories.MANAGE_EXPENSE_PURPOSES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpensePurposesUpdate = {
  category: PermissionCategories.MANAGE_EXPENSE_PURPOSES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpensePurposesDelete = {
  category: PermissionCategories.MANAGE_EXPENSE_PURPOSES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageExpensePurposesDeactivate = {
  category: PermissionCategories.MANAGE_EXPENSE_PURPOSES,
  appliedPermissions: {
    All: [PermissionActions.DEACTIVATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - Time Entry Types
export const manageTimeEntryTypesList = {
  category: PermissionCategories.MANAGE_TIME_ENTRY_TYPES,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageTimeEntryTypesCreate = {
  category: PermissionCategories.MANAGE_TIME_ENTRY_TYPES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageTimeEntryTypesUpdate = {
  category: PermissionCategories.MANAGE_TIME_ENTRY_TYPES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageTimeEntryTypesDelete = {
  category: PermissionCategories.MANAGE_TIME_ENTRY_TYPES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageTimeEntryTypesDeactivate = {
  category: PermissionCategories.MANAGE_TIME_ENTRY_TYPES,
  appliedPermissions: {
    All: [PermissionActions.DEACTIVATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - Evidence Locations
export const manageEvidenceLocationsList = {
  category: PermissionCategories.MANAGE_EVIDENCE_LOCATIONS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageEvidenceLocationsCreate = {
  category: PermissionCategories.MANAGE_EVIDENCE_LOCATIONS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageEvidenceLocationsUpdate = {
  category: PermissionCategories.MANAGE_EVIDENCE_LOCATIONS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageEvidenceLocationsDelete = {
  category: PermissionCategories.MANAGE_EVIDENCE_LOCATIONS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageEvidenceLocationsDeactivate = {
  category: PermissionCategories.MANAGE_EVIDENCE_LOCATIONS,
  appliedPermissions: {
    All: [PermissionActions.DEACTIVATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - Carriers
export const manageCarriersList = {
  category: PermissionCategories.MANAGE_CARRIERS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageCarriersCreate = {
  category: PermissionCategories.MANAGE_CARRIERS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageCarriersUpdate = {
  category: PermissionCategories.MANAGE_CARRIERS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageCarriersDelete = {
  category: PermissionCategories.MANAGE_CARRIERS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Manage - Certifications
export const manageCertificationsList = {
  category: PermissionCategories.MANAGE_CERTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageCertificationsCreate = {
  category: PermissionCategories.MANAGE_CERTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageCertificationsUpdate = {
  category: PermissionCategories.MANAGE_CERTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageCertificationsDelete = {
  category: PermissionCategories.MANAGE_CERTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRoleNotificationsList = {
  category: PermissionCategories.MANAGE_USER_ROLE_NOTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRoleNotificationsView = {
  category: PermissionCategories.MANAGE_USER_ROLE_NOTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRoleNotificationsUpdate = {
  category: PermissionCategories.MANAGE_USER_ROLE_NOTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRoleNotificationsCreate = {
  category: PermissionCategories.MANAGE_USER_ROLE_NOTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const manageUserRoleNotificationsDelete = {
  category: PermissionCategories.MANAGE_USER_ROLE_NOTIFICATIONS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};
