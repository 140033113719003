import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { IApiEvidenceStorageLocation, IApiEvidenceStorageLocationFilterType } from '../../modules/graphql/types/types';
import { EvidenceStorageLocationService } from '../../services';
import { debounceTime } from 'rxjs/operators';
import { unwrapConnection } from '../../rxjs.pipes';

@Component({
  selector: 'app-evidence-storage-location-multi-select-kendo',
  templateUrl: './evidence-storage-location-multi-select-kendo.component.html',
  styleUrls: ['./evidence-storage-location-multi-select-kendo.component.scss']
})
export class EvidenceStorageLocationMultiSelectKendoComponent implements OnInit, OnChanges {

  @ViewChild("multiSelect", { static: false }) public multiSelect: MultiSelectComponent;
  @Input() placeholder = "Location";
  @Input() label = "";
  @Input() disabled = false;
  @Input() includeInactive = false;
  @Input() selectedId: Array<string> = [];

  public locations: IApiEvidenceStorageLocation[];

  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public loading: boolean = false;
  public searchString: string = '';
  public selectedValue = [];
  private selectedLocations = [];

  constructor(
    private evidenceStorageLocationService: EvidenceStorageLocationService,
  ) { }

  ngOnInit(): void {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    } else {
      const filters = [{
        type: IApiEvidenceStorageLocationFilterType.ViewListView,
        value: "true"
      }];
      this.loading = true;
      this.evidenceStorageLocationService.get(filters).pipe(
        debounceTime(800),
        unwrapConnection()
      ).subscribe(results => {
        this.loading = false;
        this.selectedLocations = results;
        this.locations = this.selectedLocations;
        this.selectedValue = this.selectedId
      });
    }
  }
  public ngOnChanges() {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
      this.selectedLocations = [];
    }
  }


  public selectLocation(selected: any) {
    this.selectedLocations = this.locations.filter(usr => selected?.includes(usr.id));
    this.selectionChanged.emit(this.selectedValue);
    this._propagateChange(this.selectedValue);
  }

  public onSearchFocus(event){
    if(this.selectedLocations){
      this.locations = this.selectedLocations;
    }
  }

  public onSearchChange(search: string) {
    this.searchString = search?.trim();
    this.multiSelect.toggle(false);
    if (this.selectedLocations) {
      this.locations = this.selectedLocations;
    } else {
      this.locations = [];
    }
    if ((search?.trim())?.length < 3) return;

    const filters = [{
      type: IApiEvidenceStorageLocationFilterType.Name,
      value: search?.trim()
    },{
      type: IApiEvidenceStorageLocationFilterType.ViewListView,
      value: "true"
    }];

    this.loading = true;
    this.multiSelect.toggle(false)
    this.evidenceStorageLocationService.get(filters).pipe(
      debounceTime(800),
      unwrapConnection()
    ).subscribe(results => {
      this.loading = false;
      this.multiSelect.toggle(true);
      const newResult = results?.filter((res) => !this.selectedValue?.includes(res.id))
      this.locations = [...this.selectedLocations, ...newResult];
    });
  }

  private _propagateChange = (_: any) => { };

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

}
