import gql from 'graphql-tag';

export const quarterlyUtilizationReportConnectionQuery = gql`query QuarterlyUtilizationReportConnection($filters: [QuarterlyUtilizationReportFilter], $orderBy: QuarterlyUtilizationReportOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  quarterlyUtilizationReportConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    pageInfo {
      limit
      offset
    }
    totalCount
    edges {
      cursor
      node {
        id
        firstName
        lastName
        hoursWorked
        quarterlyGoal
        quarterlyGoalPercentage
        Addresses {
          id
          state
          isPrimary
          Type {
            id
            name
          }
        }
      }
    }
  }
}`;
