import { IApiExpenseCategory, IApiExpenseCategoryConnection, IApiExpenseCategoryFilter } from './../../../modules/graphql/types/types';
import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { ExpenseCategoryService } from "./expense-category.service";
import { NefcoPagedDataSource } from '../../../helpers/nefco-data-source.class';

export class ExpenseCategoryDataSource extends NefcoPagedDataSource<IApiExpenseCategory, ExpenseCategoryService, IApiExpenseCategoryConnection> implements DataSource<IApiExpenseCategory> {
  @ApiConnection()
  public load(filters: IApiExpenseCategoryFilter[] = []): Promise<IApiExpenseCategoryConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
