import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiInvestigationMetricsBillToCompanyConnection, IApiInvestigationMetricsFilter } from 'src/app/shared/modules/graphql/types/types';
import { GqlInvestigationMetricsBillToCompanyService } from 'src/app/shared/modules/graphql/services';

@Injectable()
export class InvestigationMetricsBilltoCompanyService {
  constructor(
    private reports: GqlInvestigationMetricsBillToCompanyService
  ) { }

  public get(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationMetricsBillToCompanyConnection> {
    return this.reports.get(filters, options || new ListPage());
  }

}
