import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlRequestService } from 'src/app/shared/modules/graphql/services';
import {
  IApiAddRequestInput, IApiRequest, IApiRemoveRequestInput,
  IApiUpdateRequestInput, IApiRequestFilter, IApiRequestsConnection
} from 'src/app/shared/modules/graphql/types/types';


@Injectable()
export class RequestService {

  constructor(
    private gqlRequestService: GqlRequestService
  ) { }

  public get(filters: IApiRequestFilter[], options: IGqlBaseOptions): Observable<IApiRequestsConnection> {
    return this.gqlRequestService.get(filters, options);
  }

  public list(filters?: IApiRequestFilter[], options?: IGqlBaseOptions): Observable<IApiRequest[]> {
    return this.get(filters, options).pipe(
      map(results => results.edges.map(e => e.node))
    );
  }

  public add(input: IApiAddRequestInput): Observable<IApiRequest> {
    return this.gqlRequestService.add(input);
  }

  public remove(id: string) {
    return this.gqlRequestService.remove(id);
  }

  public update(input: IApiUpdateRequestInput): Observable<IApiRequest> {
    return this.gqlRequestService.update(input);
  }

  public getRequestById(id: string): Observable<IApiRequest> {
    return this.gqlRequestService.getRequestById(id);
  }
}
