import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/enums/permissions.enums";

export const expenseReportNefcoCard: IEnforcePermissionConfig = {
  category: PermissionCategories.EXPENSES_NEFCO_CARD_REPORT,
  appliedPermissions: {
    All: Object.values(PermissionActions),
    AllAssigned: [],
    Assigned: Object.values(PermissionActions),
    Own: Object.values(PermissionActions)
  },
  displayMode: EnforcePermissionDisplayModes.Hidden

};

export const expenseInvestigationExpensesAll: IEnforcePermissionConfig = { 
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: Object.values(PermissionActions),
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};
export const expenseInvestigationExpensesAllAssigned: IEnforcePermissionConfig = {
  ...expenseInvestigationExpensesAll,
  appliedPermissions : {
    AllAssigned: Object.values(PermissionActions)
  }
};
export const expenseInvestigationExpensesOwn: IEnforcePermissionConfig = {
  ...expenseInvestigationExpensesAll,
  appliedPermissions : {
    Own: Object.values(PermissionActions)
  }
};
export const expenseInvestigationMileage: IEnforcePermissionConfig = { ...expenseReportNefcoCard, category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE };
export const expenseInvestigationHours: IEnforcePermissionConfig = { ...expenseReportNefcoCard, category: PermissionCategories.EXPENSES_INVESTIGATION_HOURS };

// Time Entry - Unlinked Hours
export const timeEntryUnlinkedHoursList: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_UNLINKED_HOURS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryUnlinkedHoursCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_UNLINKED_HOURS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryUnlinkedHoursUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_UNLINKED_HOURS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryUnlinkedHoursUpdateOwn: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_UNLINKED_HOURS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryUnlinkedHoursDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_UNLINKED_HOURS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryUnlinkedHoursDeleteOwn: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_UNLINKED_HOURS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.DELETE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Time Entry - Investigation Hours
export const timeEntryInvestigationHoursListAll: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursListOwnAssign: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursList: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursCreate: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursCreateAll: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursCreateOwnAssign: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursUpdate: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursUpdateAll: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursUpdateOwn: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursDeleteAll: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursDelete: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const timeEntryInvestigationHoursDeleteOwn: IEnforcePermissionConfig = {
  category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.DELETE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Expenses - Unlinked Expense
export const expenseUnlinkedExpenseList = {
  category: PermissionCategories.EXPENSES_UNLINKED_EXPENSE,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseUnlinkedExpenseCreate = {
  category: PermissionCategories.EXPENSES_UNLINKED_EXPENSE,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseUnlinkedExpenseUpdate = {
  category: PermissionCategories.EXPENSES_UNLINKED_EXPENSE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseUnlinkedExpenseUpdateOwn = {
  category: PermissionCategories.EXPENSES_UNLINKED_EXPENSE,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseUnlinkeExpenseDelete = {
  category: PermissionCategories.EXPENSES_UNLINKED_EXPENSE,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseUnlinkeExpenseDeleteOwn = {
  category: PermissionCategories.EXPENSES_UNLINKED_EXPENSE,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.DELETE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Expenses - Unlinked Mileage
export const expenseUnlinkedMileageList = {
  category: PermissionCategories.EXPENSES_UNLINKED_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseUnlinkedMileageCreate = {
  category: PermissionCategories.EXPENSES_UNLINKED_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseUnlinkedMileageUpdate = {
  category: PermissionCategories.EXPENSES_UNLINKED_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseUnlinkedMileageUpdateOwn = {
  category: PermissionCategories.EXPENSES_UNLINKED_MILEAGE,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseUnlinkedMileageDelete = {
  category: PermissionCategories.EXPENSES_UNLINKED_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseUnlinkedMileageDeleteOwn = {
  category: PermissionCategories.EXPENSES_UNLINKED_MILEAGE,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.DELETE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Expenses -  Investigation Mileage
export const expenseInvestigationMileageListAll = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageList = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageListOwnAssign = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageCreate = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageCreateAll = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageCreateOwnAssign = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageUpdateAll = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageUpdate = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageUpdateOwn = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageDelete = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageDeleteAll = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationMileageDeleteOwn = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_MILEAGE,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.DELETE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Expenses - Investigation Expenses
export const expenseInvestigationExpensesListAll = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesList = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesListOwnAssign = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesCreateOwnAssign = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesCreate = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesCreateAll = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesUpdateAll = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesUpdate = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesUpdateOwn = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesDelete = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesDeleteAll = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseInvestigationExpensesDeleteOwn = {
  category: PermissionCategories.EXPENSES_INVESTIGATION_EXPENSES,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.DELETE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Expense Reports - Out of pocket
export const expenseOutOfPocketList = {
  category: PermissionCategories.EXPENSES_OUT_OF_POCKET_REPORT,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseOutOfPocketCreate = {
  category: PermissionCategories.EXPENSES_OUT_OF_POCKET_REPORT,
  appliedPermissions: {
    All: [PermissionActions.CREATE],
    AllAssigned: [],
    Assigned: [PermissionActions.CREATE],
    Own: [PermissionActions.CREATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseOutOfPocketUpdate = {
  category: PermissionCategories.EXPENSES_OUT_OF_POCKET_REPORT,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [PermissionActions.UPDATE],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseOutOfPocketUpdateOwn = {
  category: PermissionCategories.EXPENSES_OUT_OF_POCKET_REPORT,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.UPDATE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseOutOfPocketDelete = {
  category: PermissionCategories.EXPENSES_OUT_OF_POCKET_REPORT,
  appliedPermissions: {
    All: [PermissionActions.DELETE],
    AllAssigned: [],
    Assigned: [PermissionActions.DELETE],
    Own: []
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const expenseOutOfPocketDeleteOwn = {
  category: PermissionCategories.EXPENSES_OUT_OF_POCKET_REPORT,
  appliedPermissions: {
    All: [],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.DELETE]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

// Expense Reports - NEFCO Card 
  export const expenseReportNefcoCardList = {
    category: PermissionCategories.EXPENSES_NEFCO_CARD_REPORT,
    appliedPermissions: {
      All: [PermissionActions.LIST],
      AllAssigned: [],
      Assigned: [PermissionActions.LIST],
      Own: [PermissionActions.LIST]
    },
    displayMode: EnforcePermissionDisplayModes.Hidden
  };
  
  export const expenseReportNefcoCardCreate = {
    category: PermissionCategories.EXPENSES_NEFCO_CARD_REPORT,
    appliedPermissions: {
      All: [PermissionActions.CREATE],
      AllAssigned: [],
      Assigned: [PermissionActions.CREATE],
      Own: [PermissionActions.CREATE]
    },
    displayMode: EnforcePermissionDisplayModes.Hidden
  };
  
  export const expenseReportNefcoCardUpdate = {
    category: PermissionCategories.EXPENSES_NEFCO_CARD_REPORT,
    appliedPermissions: {
      All: [PermissionActions.UPDATE],
      AllAssigned: [],
      Assigned: [PermissionActions.UPDATE],
      Own: []
    },
    displayMode: EnforcePermissionDisplayModes.Hidden
  };
  
  export const expenseReportNefcoCardUpdateOwn = {
    category: PermissionCategories.EXPENSES_NEFCO_CARD_REPORT,
    appliedPermissions: {
      All: [],
      AllAssigned: [],
      Assigned: [],
      Own: [PermissionActions.UPDATE]
    },
    displayMode: EnforcePermissionDisplayModes.Hidden
  };
  
  export const expenseReportNefcoCardDelete = {
    category: PermissionCategories.EXPENSES_NEFCO_CARD_REPORT,
    appliedPermissions: {
      All: [PermissionActions.DELETE],
      AllAssigned: [],
      Assigned: [PermissionActions.DELETE],
      Own: []
    },
    displayMode: EnforcePermissionDisplayModes.Hidden
  };
  
  export const expenseReportNefcoCardDeleteOwn = {
    category: PermissionCategories.EXPENSES_NEFCO_CARD_REPORT,
    appliedPermissions: {
      All: [],
      AllAssigned: [],
      Assigned: [],
      Own: [PermissionActions.DELETE]
    },
    displayMode: EnforcePermissionDisplayModes.Hidden
  };

export const timeEntryInvestigationHours: IEnforcePermissionConfig = { ...expenseReportNefcoCard, category: PermissionCategories.TIME_ENTRY_INVESTIGATION_HOURS };
