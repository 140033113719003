import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlServiceTypeService } from 'src/app/shared/modules/graphql/services';
import {
  IApiAddServiceTypeInput, IApiServiceType, IApiRemoveServiceTypeInput,
  IApiUpdateServiceTypeInput, IApiServiceTypeFilter, IApiServiceTypesConnection
} from 'src/app/shared/modules/graphql/types/types';


@Injectable()
export class ServiceTypeService {

  constructor(
    private gqlServiceTypeService: GqlServiceTypeService
  ) { }

  public get(filters: IApiServiceTypeFilter[] = [], options?: IGqlBaseOptions): Observable<IApiServiceTypesConnection> {
    return this.gqlServiceTypeService.get(filters, options || new ListPage(-1));
  }

  public getById(id: string): Observable<IApiServiceType> {
    return this.gqlServiceTypeService.getServiceTypeById(id);
  }
  
  public add(input: IApiAddServiceTypeInput): Observable<IApiServiceType> {
    return this.gqlServiceTypeService.add(input);
  }

  public remove(input: IApiRemoveServiceTypeInput): Observable<boolean> {
    return this.gqlServiceTypeService.remove(input);
  }

  public update(input: IApiUpdateServiceTypeInput): Observable<IApiServiceType> {
    return this.gqlServiceTypeService.update(input);
  }

}
