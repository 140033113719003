import { connectionQuery } from './../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../interfaces/base.interfaces';
import { addCertificationMutation, CertificationConnectionQuery, removeCertificationMutation, updateCertificationMutation } from "./certification.operations";
import { IApiAddCertificationInput, IApiCertification, IApiCertificationConnection, IApiCertificationFilter, IApiUpdateCertificationInput } from "../../types/types";

@Injectable()
export class GqlCertificationService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getCertifications(filters: IApiCertificationFilter[], options: IGqlBaseOptions): Observable<IApiCertificationConnection> {
    return this.apollo.query(connectionQuery(CertificationConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addCertification(input: IApiAddCertificationInput): Observable<IApiCertification> {
    return this.apollo.mutate({
      mutation: addCertificationMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }


  public updateCertification(input: IApiUpdateCertificationInput): Observable<IApiCertification> {
    return this.apollo.mutate({
      mutation: updateCertificationMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeCertification(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeCertificationMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
