import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";
import { IApiDocumentTypeFilterType } from '../../../types/types';

const nodeFragment = `
  id
  name,
  notes,
  defaultTitle,
  isCourtDocument
  Template {
    id
    oneDriveItemId
  }
  Category {
    id
    name
  }
  createdAt
  updatedAt
`;

const viewListNameFragment = `
id
name
`;

export const documentTypeConnectionQuery = (isNode = false, view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiDocumentTypeFilterType.ViewListName:
        return gql`${buildConnectionQuery("DocumentType", "documentTypeConnection", viewListNameFragment)}`;
      default:
        return gql`${buildConnectionQuery("DocumentType", "documentTypeConnection", nodeFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("DocumentType", "documentTypeConnection", nodeFragment)}`;
  }
};
export const addDocumentTypeMutation = gql`mutation AddDocumentType($input: AddDocumentTypeInput!) {
  addDocumentType(input: $input) { ${ nodeFragment } }
}`;

export const updateDocumentTypeMutation = gql`mutation UpdateDocumentType($input: UpdateDocumentTypeInput!) {
  updateDocumentType(input: $input) { ${ nodeFragment } }
}`;

export const removeDocumentTypeMutation = gql`mutation RemoveDocumentType($id: ID!) {
  removeDocumentType(id: $id)
}`;
