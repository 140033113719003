import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiAddBillingRuleTypeInput, IApiBillingRuleType, IApiBillingRuleTypeConnection, IApiBillingRuleTypeFilter, IApiUpdateBillingRuleTypeInput } from 'src/app/shared/modules/graphql/types/types';

import {
  billingRuleTypesConnectionQuery,
  addBillingRuleType,
  updateBillingRuleTypeMutation,
  removeBillingRuleTypeMutation,
  getBillingRuleTypeByIdQuery
} from "./billing-rule-type.operation";

@Injectable()
export class GqlBillingRuleTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiBillingRuleTypeFilter[], options: IGqlBaseOptions): Observable<IApiBillingRuleTypeConnection> {
    return this.apollo.query(connectionQuery(billingRuleTypesConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddBillingRuleTypeInput) {
    return this.apollo.mutate({
      mutation: addBillingRuleType,
      variables: { input }
    }).pipe(safeResolve(true));
  }

  public remove(id: string) {
    return this.apollo.mutate({
      mutation: removeBillingRuleTypeMutation,
      variables: { id }
    }).pipe(safeResolve(true));
  }

  public update(input: IApiUpdateBillingRuleTypeInput) {
    return this.apollo.mutate({
      mutation: updateBillingRuleTypeMutation,
      variables: { input }
    }).pipe(safeResolve(true));
  }

  public getById(id: string): Observable<IApiBillingRuleType> {
    return this.apollo.query({
      query: getBillingRuleTypeByIdQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
