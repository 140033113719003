import { connectionQuery } from './../../helpers.class';
import { CommentConnectionQuery, addCommentMutation, updateCommentMutation, removeCommentMutation } from './comment.operations';
import { IApiAddCommentInput, IApiComment, IApiCommentFilter, IApiUpdateCommentInput } from './../../types/types';

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { IGqlBaseOptions } from "../../interfaces";
import { safeResolve } from "../../rxjs";

@Injectable()
export class GqlCommentService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getComments(filters: IApiCommentFilter[], options: IGqlBaseOptions): Observable<IApiComment[]> {
    return this.apollo.query(connectionQuery(CommentConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addComment(input: IApiAddCommentInput): Observable<IApiComment> {
    return this.apollo.mutate({
      mutation: addCommentMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateComment(input: IApiUpdateCommentInput): Observable<IApiComment> {
    return this.apollo.mutate({
      mutation: updateCommentMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeComment(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeCommentMutation,
      variables: {
        id
      }
    }).pipe(
      safeResolve(true)
    );
  }
}