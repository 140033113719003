import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access-restricted',
  templateUrl: './access-restricted.component.html',
  styleUrls: ['./access-restricted.component.scss']
})
export class AccessRestrictedComponent {

  constructor(
    private router: Router
  ) { }

  public goToDashboard() {
    this.router.navigate(['/']);
  }

}
