<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageExpenseItemsCreate" #manageExpenseItemsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpenseItemsUpdate" #manageExpenseItemsUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpenseItemsDelete" #manageExpenseItemsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpenseItemsDeactivat" #manageExpenseItemsDeactivat="appEnforcePermission"></div>
<!-- Permissions -->
<section>

  <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']" *ngIf="manageExpenseItemsCreate?.enabled">
    <kendo-gridlayout-item class="text-right mb-0">
      <kendo-button class="mr-16" kendoButton themeColor="primary" iconClass="fa fa-plus" fillMode="solid" size="medium" (click)="addExpenseItem()">
        New Expense Item
      </kendo-button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>

  <kendo-grid
    class="expense-report-category-list mt-16"
    [data]="{
      data: data,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageOptions?.paginate($event);"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
  >
    <kendo-grid-column
      field="NAME"
      title="Category"
      [width]="20"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          *ngIf="manageExpenseItemsUpdate?.enabled else onlyName"
          (click)="editItem(dataItem)"
          tel=""
          class="text-decoration"
          [ngClass]="{'text-line-through': !dataItem.active}"
        >
          {{ dataItem?.name }}
        </a>
        <ng-template #onlyName>
          <span [ngClass]="{'text-line-through': !dataItem.active}">{{dataItem.name}}</span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="EXPENSE_TYPE"
      title="Type"
      [width]="8"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [ngClass]="{'text-line-through': !dataItem.active}" [innerHTML]="dataItem.ExpenseType.name"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="APPLIES_TO"
      title="Applies To"
      [width]="8"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [ngClass]="{'text-line-through': !dataItem.active}" [innerHTML]="dataItem.AppliesTo.name"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="description"
      title="description"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [ngClass]="{'text-line-through': !dataItem.active}" [innerHTML]="dataItem.description"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="EXPENSE_CATEGORY"
      title="Expense Report Category"
      [width]="20"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="text-overflow" [ngClass]="{'text-line-through': !dataItem.active}" [innerHTML]="dataItem.ExpenseCategory.name"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="Purposes"
      title="Purposes"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [ngClass]="{'text-line-through': !dataItem.active}" *ngFor="let p of dataItem.ExpenseCategory.ExpensePurposes" class="purposes" [innerHTML]="p.name"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Action"
      [width]="8"
      [sortable]="false"
      [headerClass]="'nocursor'"
      *ngIf="actionItem?.length"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-dropdownbutton
          [data]="getFilteredActionItems(dataItem)"
          themeColor="primary" fillMode="link" buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis"
          (itemClick)="itemSelected($event, dataItem)" 
          [popupSettings]="{
            appendTo: 'component',
            animate: false,
            width: 250,
            popupClass: 'grid-action-items-menu'
          }">
        </kendo-dropdownbutton>
      </ng-template>
    </kendo-grid-column>

    <!-- No record -->
    <ng-template kendoGridNoRecordsTemplate>
      <p class="mt-24 mb-24">There are no results to display.</p>
    </ng-template>
  </kendo-grid>
</section>
