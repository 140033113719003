<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationRequestsCreate" #investigationRequestsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentsCreate" #investigationDocumentsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationReportsCreate" #investigationReportsCreate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div class="page-filters">
    <kendo-gridlayout
      [rows]="['auto']"
      [gap]="{ rows: 5, cols: 16 }"
      [cols]="['repeat(5,minmax(0,1fr))']"
    >
      <kendo-gridlayout-item>
        <app-user-select-kendo
          label="Search"
          [userViewFilter]="userViewFilter"
          placeholder="Search staff member"
          [userId]="filterValue(filterTypes.User)"
          [includeInactive]="false"
          (selectionChanged)="setFilters($event?.id, filterTypes.User)"
        ></app-user-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label [for]="rType" text="Request Type"></kendo-label>
          <kendo-dropdownlist
            #rType
            [data]="requestTypes"
            textField="name"
            valueField="id"
            fillMode="outline"
            [valuePrimitive]="true"
            [defaultItem]="{ id: null, name: 'All' }"
            [value]="filterValue(filterTypes.Type)"
            [popupSettings]="{
              appendTo: 'component',
              animate: false,
              width: 250
            }"
            (selectionChange)="setFilters($event?.id, filterTypes.Type)"
          >
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="dataItem?.name === 'All'" class="placeholder">
                {{ dataItem.name }}
              </span>
              <span *ngIf="dataItem?.name !== 'All'" class="not-placeholder">
                {{ dataItem.name }}
              </span>
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label [for]="Priority" text="Priority"></kendo-label>
          <kendo-dropdownlist
            #Priority
            [data]="investigationPriorities"
            textField="name"
            valueField="id"
            fillMode="outline"
            [valuePrimitive]="true"
            [defaultItem]="{ key: null, name: 'All' }"
            [value]="filterValue(filterTypes.Priority)"
            [popupSettings]="{
              appendTo: 'component',
              animate: false,
              width: 250
            }"
            (selectionChange)="
              setFilters(
                $event?.name === 'All' ? undefined : $event?.id,
                filterTypes.Priority
              )
            "
          >
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="dataItem?.name === 'All'" class="placeholder">
                {{ dataItem?.name }}
              </span>
              <span *ngIf="dataItem?.name !== 'All'" class="not-placeholder">
                {{ dataItem?.name }}
              </span>
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item
        class="filter-actions"
        [colSpan]="2"
        [col]="0"
        [row]="0"
      >
        <div class="comments-filter mr-16">
          <kendo-label text=""></kendo-label>
          <div class="comment-toggle-group">
            <kendo-button
              kendoButton
              [themeColor]="showComments === true ? 'light' : 'base'"
              fillMode="solid"
              iconClass="fa-solid fa-comment"
              (click)="toggleCommentExpand(true)"
            ></kendo-button>
            <kendo-button
              kendoButton
              [themeColor]="showComments === false ? 'light' : 'base'"
              fillMode="solid"
              iconClass="fa-solid fa-comment-slash"
              (click)="toggleCommentExpand(false)"
            ></kendo-button>
          </div>
        </div>
        <div class="applied-filter mr-16">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button
              kendoButton
              themeColor="primary"
              fillMode="solid"
              size="medium"
              (click)="filterTable()"
              >Apply Filters</kendo-button
            >
          </div>
        </div>
        <div class="clear-filter mt-5">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button
              kendoButton
              themeColor="primary"
              fillMode="link"
              size="medium"
              (click)="clearFilter()"
              >Clear All</kendo-button
            >
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </div>

  <kendo-grid
    class="pending-request"
    [data]="{
      data: tableData,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageChange($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
    [kendoGridExpandDetailsBy]="'id'"
    [(expandedDetailKeys)]="expandedDetailKeys"
  >
    <kendo-grid-column
      field="CREATED_AT"
      title="Submitted"
      [width]="20"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.createdAt | customDate : "MM/dd/yyyy" }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="nefcoNumber"
      title="ID"
      [width]="20"
      class="id-column"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          [routerLink]="['/investigations/', dataItem?.Investigation?.id]"
          tel=""
          class="text-decoration"
        >
          {{ dataItem?.Investigation?.nefcoNumber || "Unassigned" }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="name"
      title="Priority"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-badge
          class="{{
            'fw600 ' +
              (dataItem?.Investigation?.Priority?.name === 'Low' ||
              dataItem?.Investigation?.Priority?.name === 'Medium'
                ? 'fcolor'
                : '')
          }}"
          position="none"
          size="medium"
          rounded="full"
          themeColor="{{
            dataItem?.Investigation?.Priority?.name === 'Low'
              ? 'tertiary'
              : dataItem?.Investigation?.Priority?.name === 'Medium'
              ? 'warning'
              : dataItem?.Investigation?.Priority?.name === 'High'
              ? 'error'
              : ''
          }}"
          >{{ dataItem?.Investigation?.Priority?.name }}</kendo-badge
        >
        <ng-container *ngIf="!dataItem?.Investigation?.Priority?.name"
          >-</ng-container
        >
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="Status"
      title="Inv. Status"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{
          dataItem?.Investigation?.status
            ? investigationStatusNames[dataItem?.Investigation?.status]
            : "-"
        }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="LossAddress"
      title="Loss Address"
      [width]="40"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container
          *ngIf="dataItem?.Investigation?.LossAddress; else locationElse"
        >
          <ng-container *ngIf="dataItem?.Investigation?.LossAddress?.address1">
            <span class="text-overflow">{{
              dataItem?.Investigation?.LossAddress?.address1
            }}</span>
            <br />
          </ng-container>
          {{ dataItem?.Investigation?.LossAddress?.city }},
          {{ dataItem?.Investigation?.LossAddress?.state }}
          {{ dataItem?.Investigation?.LossAddress?.postal }}
        </ng-container>
        <ng-template #locationElse>-</ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="InvestigationStaff"
      title="Assigned"
      [width]="40"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container
          *ngIf="
            dataItem?.Investigation?.InvestigationStaff?.length;
            else noStaff
          "
        >
          <ng-container
            *ngFor="let person of dataItem.Investigation?.InvestigationStaff"
          >
            <span class="text-overflow"
              >{{ person.User | fullName }} ({{ person.Role.title }})</span
            >
            <br />
          </ng-container>
        </ng-container>
        <ng-template #noStaff>-</ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="name"
      title="Request Type"
      [width]="30"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="text-overflow">{{
          dataItem?.RequestType?.name || "-"
        }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="Status"
      title="Request Status"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ RequestStatuses[dataItem?.Status] || "-" }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="Action"
      title="Action"
      [width]="25"
      [sortable]="false"
      [headerClass]="'nocursor'"
      *ngIf="investigationRequestsCreate?.enabled && investigationDocumentsCreate?.enabled && investigationReportsCreate?.enabled"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoButton
          themeColor="primary"
          fillMode="link"
          (click)="updateRequest(dataItem)"
        >
          Update Request
        </button>
      </ng-template>
    </kendo-grid-column>

    <!-- Comments -->
    <ng-template
      kendoGridDetailTemplate
      let-dataItem
      [kendoGridDetailTemplateShowIf]="displayComments"
    >
      <kendo-grid
        [data]="dataItem?.Investigation?.Comments.slice(0, 5)"
        [pageable]="false"
        [scrollable]="'none'"
        [className]="'detail-items'"
      >
        <kendo-grid-column
          field="createdAt"
          title="Date/Time"
          [width]="30"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span style="text-transform: lowercase">
              {{ dataItem?.createdAt | customDate : "MM/dd/yy, h:mma" }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="text"
          title="Comment"
          [width]="120"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="text-overflow">{{ dataItem?.text }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="CreatedBy"
          title="User"
          [width]="50"
          [sortable]="false"
          [headerClass]="'nocursor'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem?.CreatedBy | fullName }}
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </ng-template>

    <!-- No record -->
    <ng-template kendoGridNoRecordsTemplate>
      <p class="mt-24 mb-24">There are no results to display.</p>
    </ng-template>
  </kendo-grid>
</section>
