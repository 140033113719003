import { ApiConnection } from '../../../decorators/@ApiConnection.decorator';
import { TimeEntryTypeService } from './time-entry-type.service';
import { DataSource } from "@angular/cdk/collections";
import { IApiTimeEntryType, IApiTimeEntryTypeConnection, IApiTimeEntryTypeFilter } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class TimeEntryTypeDataSource extends NefcoPagedDataSource<IApiTimeEntryType, TimeEntryTypeService, IApiTimeEntryTypeConnection> implements DataSource<IApiTimeEntryType> {
  @ApiConnection(-1)
  public load(filters?: IApiTimeEntryTypeFilter[]): Promise<IApiTimeEntryTypeConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
