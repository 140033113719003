import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
} from "@angular/core";

@Directive({
  selector: "[appDnd]",
})
export class DndDirective {
  @HostBinding("class.fileover") fileOver: boolean;
  @Input() appDnd: boolean = true;
  @Output() fileDropped = new EventEmitter<any>();

  // Dragover listener
  @HostListener("dragover", ["$event"]) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.appDnd) {
      this.fileOver = true;
    }
  }

  // Dragleave listener
  @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.appDnd) {
      this.fileOver = false;
    }
  }

  // Drop listener
  @HostListener("drop", ["$event"]) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.appDnd) {
      this.fileOver = false;
      let files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.fileDropped.emit(files);
      }
    }
  }
}
