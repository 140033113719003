import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { GqlInvestigationMetricsBasicService } from 'src/app/shared/modules/graphql/services';
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsBasicConnection } from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class InvestigationMetricsBasicService {
  constructor(
    private gqlInvestigationMetricsStateService: GqlInvestigationMetricsBasicService
  ) { }

  public get(filters?: IApiInvestigationMetricsFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationMetricsBasicConnection> {
    return this.gqlInvestigationMetricsStateService.get(filters, options || new ListPage());
  }

}
