import { GqlExpenseTypeService } from '../../../modules/graphql/services/expenses/expense-type/expense-type.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../../modules/graphql/interfaces/base.interfaces';
import { IApiExpenseTypeConnection, IApiExpenseTypeFilter } from "src/app/shared/modules/graphql/types/types";
import { ListPage } from "src/app/shared/helpers/list-page.class";


@Injectable()
export class ExpenseTypeService {

  constructor(
    private gqlExpenseTypeService: GqlExpenseTypeService
  ) { }

  public get(filters?: IApiExpenseTypeFilter[], options?: IGqlBaseOptions): Observable<IApiExpenseTypeConnection> {
    return this.gqlExpenseTypeService.getExpenseTypes(filters, options || new ListPage(-1));
  }

}
