<div [dialogRef]="dialog" (appClickOutside)="dialogRef.close(null)">
  <kendo-dialog-titlebar (close)="dialogRef.close(null)">
    <div class="ml-8 modal-title">
      <span>Loss Proximity Check</span>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">
    <kendo-gridlayout style="width: 100%;" class="p-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
      [cols]="['repeat(3,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <span>Loss Address:</span>
        <p [innerHTML]="(data.LossAddress | addressFormat) || '-'"></p>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [colSpan]="2">
        <span>Insured:</span>
        <p [innerHTML]="(insured | fullName) || '-'"></p>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <span>Loss Date:</span>
        <p [innerHTML]="data?.lossDate | customDate: 'MM/dd/YY' : true"></p>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <span>Loss Time:</span>
        <p [innerHTML]="data?.lossDate | customDate: 'h:mm a' : true"></p>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <span>Risk Type:</span>
        <p [innerHTML]="riskTypeName || '-'"></p>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <app-investigation-loss-address-check-table #lossAddressTable [input]="data" (markersChanged)="markers = $event"
      (rowClick)="selectedInvestigationId = $event"></app-investigation-loss-address-check-table>
    <div class="centered-map-container">
      <app-google-map [lon]="data.LossAddress.longitude" [lat]="data.LossAddress.latitude" [markers]="markers"
        [selectedLocation]="selectedInvestigationId"
        (iconClick)="lossAddressTable.selectedInvestigationId = $event"></app-google-map>
    </div>
  </div>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-16" (click)="reject()">Close</button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="accept()">{{ data?.id ? 'Save' :
      'Add'}}</button>
  </kendo-dialog-actions>
</div>
