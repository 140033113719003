import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlSubServiceTypeService } from 'src/app/shared/modules/graphql/services';
import {
  IApiAddSubServiceTypeInput, IApiSubServiceType,
  IApiUpdateSubServiceTypeInput, IApiSubServiceTypeFilter, IApiSubServiceTypesConnection
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";


@Injectable()
export class SubServiceTypeService {

  constructor(
    private gqlSubServiceTypeService: GqlSubServiceTypeService
  ) { }

  public get(filters?: IApiSubServiceTypeFilter[], options?: IGqlBaseOptions): Observable<IApiSubServiceTypesConnection> {
    return this.gqlSubServiceTypeService.get(filters, options || new ListPage(-1));
  }

  public add(input: IApiAddSubServiceTypeInput): Observable<IApiSubServiceType> {
    return this.gqlSubServiceTypeService.add(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlSubServiceTypeService.remove(id);
  }

  public update(input: IApiUpdateSubServiceTypeInput): Observable<IApiSubServiceType> {
    return this.gqlSubServiceTypeService.update(input);
  }

  public getSubServiceTypeById(id: string): Observable<IApiSubServiceType> {
    return this.gqlSubServiceTypeService.getSubServiceTypeById(id);
  }
}
