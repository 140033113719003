import { Component, forwardRef, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { IApiInvestigation, IApiInvestigationFilterType } from '../../modules/graphql/types/types';
import { InvestigationService } from 'src/app/shared/services';
import { unwrapConnection } from '../../rxjs.pipes';
import { debounceTime } from 'rxjs/operators';
import {
  investigation
} from "src/app/shared/helpers/auth-config/investigations.config";
@Component({
  selector: 'app-investigation-multi-select-kendo',
  templateUrl: './investigation-multi-select-kendo.component.html',
  styleUrls: ['./investigation-multi-select-kendo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InvestigationMultiSelectKendoComponent),
      multi: true
    }
  ]
})
export class InvestigationMultiSelectKendoComponent implements OnChanges {
  public authConfig = {
    investigation
  };
  @ViewChild("multiSelect", { static: false }) public multiSelect: AutoCompleteComponent;
  @Input() placeholder = "Search for nefco number";
  @Input() label = "";
  @Input() disabled = false;
  @Input() selectedIds: Array<string> = [];
  selectedValue: string[] = [];

  @Output() selectionChanged = new EventEmitter<string[] | []>();
  
  public investigations: IApiInvestigation[] = [];
  public selectedInvestigation = [];
  public loading: boolean = false;
  public searchString: string = '';

  constructor(
    private investigationService: InvestigationService,
  ) { }

  public ngOnChanges() {
    if (!this.selectedIds || this.selectedIds?.length === 0) {
      this.selectedInvestigation = [];
      this.selectedValue = []
    }
  }

  public onSearchFocus(event){
    if (this.selectedInvestigation){
      this.investigations = this.selectedInvestigation;
    }
  }

  public selectInvestigation(selected: string[]): void {
    this.selectedInvestigation = this.investigations?.filter(i => selected?.includes(i.id));
    this.selectionChanged.emit(this.selectedValue);
    this._propagateChange(this.selectedValue);
  }

  public onSearchChange(search: string) {
    this.searchString = search?.trim();
    this.multiSelect.toggle(false);
    if (this.selectedInvestigation) {
      this.investigations = this.selectedInvestigation;
    } else {
      this.investigations = [];
    }
    if ((search?.trim())?.length < 3) return;

    const filters = [ {
      type: IApiInvestigationFilterType.ViewOnlyNefconumber,
      value: "true",
    },{
      type: IApiInvestigationFilterType.SearchNefcoNumber,
      value: search?.trim()
    }];

    this.loading = true;
    this.multiSelect.toggle(false)
      this.investigationService
        .get(filters)
        .pipe(debounceTime(800),unwrapConnection())
        .subscribe((results: IApiInvestigation[]) => {
          const newResult = results?.filter((res) => !this.selectedValue?.includes(res.id))
          this.investigations = [...this.selectedInvestigation, ...newResult];
          this.loading = false;
          this.multiSelect.toggle(true);
        });

  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

  private _propagateChange = (_: any) => { };

}
