import { IApiDocument, IApiInvestigation } from "src/app/shared/modules/graphql/types/types";

export enum DashboardDialogType {
    INVESTIGATION_COMMENT = "investigation comment",
    SENDBACK = "send back",
    INDICATE_CAUSE = "indicate cause",
    APPROVE = "approve case",
    ASSIGN = "assign",
    APPROVE_REPORT = "approve report",
    APPROVE_FINAL = "approve final",
    REQUEST_UPDATE = "request update",
    UNASSIGN = "UNASSIGN"
}

export interface IDashboardModalData {
    dialogType: DashboardDialogType;
    nefcoNumber: string;
    investigationId: string;
    comment?: string;
    assignedUser?: { id: string; firstName: string; lastName: string };
    updatedAt?: any; // Should be a date value
    request?: { type: string };
    submittedBy?: { firstName: string, lastName: string };
    authorizedBy?: string;
    requestHistory?: any[];
    submitted?: any; // should be a date
    document?: IApiDocument;
}