import { IApiPermissionScope, } from '../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlPermissionScopeService } from "src/app/shared/modules/graphql/services";

@Injectable()
export class PermissionScopeService {

  constructor(
    private scopes: GqlPermissionScopeService,
  ) { }

  public get(): Observable<IApiPermissionScope[]> {
    return this.scopes.getPermissionScopes();
  }
}
