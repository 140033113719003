import { connectionQuery } from './../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiContactConnection, IApiContactFilterType, IApiEmailContactInput, IApiRejectContactInput, IApiUpdateAndReviewContactInput } from 'src/app/shared/modules/graphql/types/types';
import {
  contactsConnectionQuery, addContactMutation,
  removeContactMutation, getContactByIdQuery,
  updateContactMutation,
  emailContactMutation,
  approvedMutation,
  contactsConnectionQuerySearch,
  rejectMutation
} from './contact.operations';
import {
  IApiContactFilter, IApiAddContactInput, IApiUpdateContactInput,
  IApiRemoveContactInput, IApiContact
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlContactService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiContactFilter[], options: IGqlBaseOptions): Observable<IApiContactConnection> {
    const viewType = filters.find(obj => obj.type === IApiContactFilterType.ViewListView) || null;
    return this.apollo.query(connectionQuery(contactsConnectionQuery(viewType), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddContactInput) {
    return this.apollo.mutate({
      mutation: addContactMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(input: IApiRemoveContactInput) {
    return this.apollo.mutate({
      mutation: removeContactMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateContactInput) {
    return this.apollo.mutate({
      mutation: updateContactMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getContactById(id: string): Observable<IApiContact> {
    return this.apollo.query({
      query: getContactByIdQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public email(input: IApiEmailContactInput, fileInput: File) {
    return this.apollo.mutate({
      mutation: emailContactMutation,
      variables: { input, fileInput },
      context: {
        useMultipart: true
      }
    }).pipe(
      safeResolve(true)
    );
  }


  public approved(input: IApiUpdateAndReviewContactInput) {
    return this.apollo.mutate({
      mutation: approvedMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public reject(input: IApiRejectContactInput) {
    return this.apollo.mutate({
      mutation: rejectMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public searchBy(filters: IApiContactFilter[], options: IGqlBaseOptions): Observable<IApiContactConnection> {
    return this.apollo.query(connectionQuery(contactsConnectionQuerySearch(), filters, options)).pipe(
      safeResolve(true)
    );
  }

}
