import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { investigationDetailConnectionQuery, addInvestigationDetailMutation, removeInvestigationDetailMutation, updateInvestigationDetailMutation } from './investigation-detail.operations';
import { IApiInvestigationDetailConnection } from '../../../types/types';
import { safeResolve } from '../../../rxjs';

import {
  IApiInvestigationDetailFilter,
  IApiAddInvestigationDetailInput,
  IApiUpdateInvestigationDetailInput,
  IApiRemoveInvestigationDetailInput
} from '../../../types/types';

@Injectable()
export class GqlInvestigationDetailService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getInvestigationDetail(filters: IApiInvestigationDetailFilter[], options: IGqlBaseOptions): Observable<IApiInvestigationDetailConnection> {
    return this.apollo.query(connectionQuery(investigationDetailConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addInvestigationDetail(input: IApiAddInvestigationDetailInput) {
    return this.apollo.mutate({
      mutation: addInvestigationDetailMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeInvestigationDetail(input: IApiRemoveInvestigationDetailInput) {
    return this.apollo.mutate({
      mutation: removeInvestigationDetailMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateInvestigationDetail(input: IApiUpdateInvestigationDetailInput) {
    return this.apollo.mutate({
      mutation: updateInvestigationDetailMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
