import { Directive, ElementRef, OnDestroy, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appSkipClearButtonTabindex]",
})
export class SkipClearButtonTabindexDirective implements OnDestroy {
  private observer: MutationObserver;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.observer = new MutationObserver(() => this.setTabIndex());
    this.observeChanges();
  }

  private setTabIndex() {
    const clearButton = this.el.nativeElement.querySelector(".k-clear-value");
    if (clearButton) {
      this.renderer.setAttribute(clearButton, "tabindex", "-1");
    }
  }

  private observeChanges() {
    const config = { childList: true, subtree: true };
    this.observer.observe(this.el.nativeElement, config);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
