import { FormControl, FormGroup } from "@angular/forms";
import moment from "moment";

export abstract class ReactiveFormHelper {

  public static emailValidator(control: FormControl): { [key: string]: any } {
    var emailRegexp = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && control.value.trim().length > 0 && !emailRegexp.test(control.value)) {
      return { email: true };
    }
    return null;
  }

  public static checkMaxTimeWithDate = (first: string, second: string) => {
    return (formGroup: FormGroup) => {
      const firstField = formGroup.controls[first];
      const secondField = formGroup.controls[second];
      if (!firstField.invalid && !secondField.invalid) {
        const lossDate = moment(firstField.value).format('MM-DD-yyyy');
        const lossTime = moment(secondField.value).format('HH:mm');
        const dateAndTime = moment(`${lossDate} ${lossTime}`, ['MM-DD-yyyy HH:mm', 'MM-DD-yyyy HH:mm'], true).isAfter();
        if (lossDate && lossTime && dateAndTime) {
          secondField.setErrors({ invalidTime: true });
        } else {
          secondField.setErrors(null);
        }
      }
      return null;
    }
  }

  public static checkMaxTime(control: FormControl): { [key: string]: any } {
    const isValid = moment(control.value, ['hh:mm', 'h:mm'], true).isValid();
    if (control.value?.trim()?.length > 0 && !isValid) {
      return { invalidTime: true };
    }
    return null;
  }

  public static noWhitespaceValidator(control: FormControl) {
    const isOnlySpace = (control.value || '').trim().length === 0;
    return control.value && isOnlySpace ? { required: true } : null;
  }

  public static numberOnly(control: FormControl) {
    return (control.value || '').trim().length > 0 && !(/^\d+$/.test((control.value).trim())) ? { numberOnly: true } : null;
  }

  public static atLeastOneHasValue = (first: string, second: string) => {
    return (formGroup: FormGroup) => {
      const firstField = formGroup.controls[first];
      const secondField = formGroup.controls[second];
      const firstTrim = (firstField.value || '').trim();
      const secondTrim = (secondField.value || '').trim();
      if ((firstTrim?.length === 0 && firstField.value) || (secondTrim?.length === 0 && secondField.value)) {
        if (firstTrim?.length === 0 && firstField.value) {
          firstField.setErrors({ required: true });
        } else {
          firstField.setErrors(null);
        }
        if (secondTrim?.length === 0 && secondField.value) {
          secondField.setErrors({ required: true });
        } else {
          secondField.setErrors(null);
        }
        return;
      }
      if (firstField.value || secondField.value) {
        firstField.setErrors(null);
        secondField.setErrors(null);
        return null;
      }
      firstField.setErrors({ atLeastOneRequired: true });
      secondField.setErrors({ atLeastOneRequired: true });
    };
  };

  public static atLeastOneChecked = (checked: string, first: string, second: string) => {
    return (formGroup: FormGroup) => {
      const docusketchRequested = formGroup.controls[checked];
      const firstField = formGroup.controls[first];
      const secondField = formGroup.controls[second];
      if (docusketchRequested?.value) {
        if (firstField.value && secondField.value) {
          firstField.setErrors({ atLeastOneRequired: true });
          secondField.setErrors({ atLeastOneRequired: true });
          return;
        } 
        if(!firstField.value && !secondField.value) {
          firstField.setErrors({ atLeastOneRequired: true });
          secondField.setErrors({ atLeastOneRequired: true });
          return;
        } 
        if (firstField.value || secondField.value) {
          firstField.setErrors(null);
          secondField.setErrors(null);
          return null;
        }
      } else {
        firstField.setErrors(null);
        secondField.setErrors(null);
        return null;
      }
    };
  };

  public static findCountryByStateCode = (countries, code) => {
    const country = countries?.find((ct) => ct.value === code);
    switch (country?.type) {
      case "US States":
      case "US Territories":
        return 'United States';
      case "Canadian Provinces":
        return "Canada";
      default:
        return 'United States';
    }
  }



}
export function getRiskTypeIcon(RiskTypeId: string) {
  switch (RiskTypeId) {
    case 'Auto':
      return 'assets/svg/loss-type/Auto.svg';
    case 'Apartment Building':
      return 'assets/svg/loss-type/Apartment.svg';
    case 'Barn':
      return 'assets/svg/loss-type/Barn.svg';
    case 'Boat':
      return 'assets/svg/loss-type/Boat.svg';
    case 'Commercial':
      return 'assets/svg/loss-type/Commercial.svg';
    case 'Evidence Storage Only':
      return 'assets/svg/loss-type/Evidence-Storage.svg';
    case 'Garage':
      return 'assets/svg/loss-type/Garage.svg';
    case 'Heavy Equipment':
      return 'assets/svg/loss-type/Heavy-Equipment.svg';
    case 'HVAC Investigation':
      return 'assets/svg/loss-type/HVAC.svg';
    case 'Lightning Strike Investigation':
      return 'assets/svg/loss-type/Lightning-Strike.svg';
    case 'Multi-Family Home':
      return 'assets/svg/loss-type/Multi-Family-Home.svg';
    case 'Other':
      return 'assets/svg/loss-type/Other.svg';
    case 'Single-Family Home':
      return 'assets/svg/loss-type/Single-Family-Home.svg';
    case 'Private Case':
      return 'assets/svg/loss-type/Private-Case.svg';
    case 'Liability':
      return 'assets/svg/loss-type/Liability.svg';
    default:
      return 'assets/svg/loss-type/Other.svg';
  }


}
