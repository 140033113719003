import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { DrawerComponent } from '@progress/kendo-angular-layout';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { expenseInvestigationExpensesCreate, expenseUnlinkedExpenseCreate } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiAddExpenseInput, IApiExpenseItem, IApiExpenseItemOrderBy, IApiExpensePurpose, IApiInvestigation, IApiInvestigationFilterType, IApiInvestigationOrderBy, IApiUpdateExpenseInput, IApiUser, IApiUserDetail } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { AuthService, ExpenseItemService, ExpenseService, InvestigationService } from 'src/app/shared/services';

@Component({
  selector: 'app-mobile-expense-form',
  templateUrl: './mobile-expense-form.component.html',
  styleUrls: ['./mobile-expense-form.component.scss']
})
export class MobileExpenseFormComponent implements OnInit, AfterViewInit {
  public authConfig = {
    expenseUnlinkedExpenseCreate,
    expenseInvestigationExpensesCreate
  }
  @ViewChild('drawer') drawer: DrawerComponent;
  @ViewChild("autoCompleteExpenseItemId", { static: false }) public autoCompleteExpenseItemId: AutoCompleteComponent;
  @ViewChild("expenseForm") form: NgForm;
  @ViewChild("expenseUnlinkedExpenseCreate") expenseUnlinkedExpenseCreatePermission;
  @ViewChild("expenseInvestigationExpensesCreate") expenseInvestigationExpensesCreatePermission;
  @Output() stepTo = new EventEmitter<number>();
  @Output() reloadPage = new EventEmitter<boolean>(false);
  @Input() fromTab = false;
  @Input() goBackOnSubmit = false;
  @Input() set investigation(val: IApiInvestigation) {
    if (val?.id) {
      this.investigationView = true;
      this.expense.InvestigationId = val.id;
      this.nefcoNumber = val?.nefcoNumber;
    } else {
      this.unlinked = false;
    }
  };

  private _expense: IApiUpdateExpenseInput = {
    id: null,
    expenseDate: new Date(),
    billableQuantity: null,
    nonBillableQuantity: null,
    ExpenseItemId: null,
    ExpensePurposeId: null,
    outOfPocket: 1,
    authorizedBy: null,
    InvestigationId: null,
    nefcoVehicle: 0,
    authorizedDate: null,
    description: '',
    paidDate: null
  };
  public get expense() {
    return this._expense;
  }

  public set expense(val) {
    this._expense = val;
    if (val?.paidDate) {
      this._expense.paidDate = NefcoDateHelper.toLocal(
        this._expense.paidDate
      );
      this.paidCheckbox = true;
    }
  }

  private _expenseItems: IApiExpenseItem[] = null;
  public get expenseItems() {
    return this._expenseItems;
  }
  public set expenseItems(val: IApiExpenseItem[]) {
    this._expenseItems = val;
    if (this._expenseItems?.length && this._expense.ExpenseItemId) {
      this.typeChange(this._expense.ExpenseItemId)
    }
  }


  /** PRIVATE */
  private _unlinked = false;
  private user: IApiUser = null;

  /** PUBLIC */
  public paidCheckbox: boolean = false;
  public typeArray: Array<{name?: string; value?: number}> = [];
  public investigationView = false;
  public nefcoNumber = '';

  public get unlinked(): boolean {
    return this._unlinked;
  }
  public set unlinked(val: boolean) {
    this._unlinked = val;
    // Reset expense items on unlink change
    this.expense.ExpenseItemId = null;
    this.selectedTypeName = '';
    if (val === true) {
      this.expense.InvestigationId = null;
      this.expense.billableQuantity = 0;
    } else {
      this.expense.billableQuantity = 0;
    }
  }

  public get expensePurposes(): IApiExpensePurpose[] {
    return this.expenseItems?.find(expenseItem => expenseItem.id === this.expense.ExpenseItemId)?.ExpenseCategory?.ExpensePurposes || [];
  }

  public get requiresAuthorization(): boolean {
    if (!this.expense.billableQuantity && !this.expense.nonBillableQuantity && !this.expense.authorizedBy && !this.expense.authorizedDate) return false;
    return ((this.expense.billableQuantity || 0) + (this.expense.nonBillableQuantity || 0)) > 100;
  }

  public get requiresAuthorizationFilled(): boolean {
    if (this.expense.authorizedBy && this.expense.authorizedDate) return false;
    return (!this.expense.authorizedBy || !this.expense.authorizedDate);
  }

  public isFormValid(): boolean {
    this.form.control.markAllAsTouched();
    return this.form.form.valid;
  }

  public selectedTypeName:string;
  public typeIdsearch: string;

  private _expenseForm: IApiAddExpenseInput;
  public get expenseForm() {
    return this._expenseForm;
  }
  public set expenseForm(val) {
    this._expenseForm = val;
  }

  private _investigations: IApiInvestigation[] = null;
  public get investigations() {
    return this._investigations;
  }
  public set investigations(value: IApiInvestigation[]) {
    this._investigations = value;
  }

  constructor(
    private authService: AuthService,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private investigationService: InvestigationService,
    private expenseItemService: ExpenseItemService,
    private loaderService: LoaderService,
    private expenseService: ExpenseService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.authService.authenticatedUser.subscribe((user) => {
      this.user = user
      this.expense.UserId = this.user.id;
      if (this.expense?.outOfPocket === undefined || this.expense.outOfPocket === null) {
        this.expense.outOfPocket = this.user?.UserDetail?.hasNefcoCreditCard ? 0 : 1;
      }
      if (this.user?.UserDetail?.hasNefcoCreditCard) {
        this.typeArray = [{value: 1, name: 'Out of Pocket'}, {value: 0, name: 'NEFCO Card'}, {value: 2, name: 'Vendor/Other'}];
      } else {
        this.typeArray = [{value: 1, name: 'Out of Pocket'}, {value: 2, name: 'Vendor/Other'}];
        this.expense.outOfPocket = 1;
      }
      const ob = [
        this.expenseItemService.get([], { limit: -1, sortOrder: SortOrder.ASC, orderBy: IApiExpenseItemOrderBy.Name }).pipe(
          unwrapConnection()
        )
      ];
      if (!this.investigationView) {
        ob.push(
          this.investigationService
          .get(
            [
              { type: IApiInvestigationFilterType.User, value: this.user.id },
              {
                type: IApiInvestigationFilterType.ViewOnlyNefconumber,
                value: "true",
              },
            ],
            { limit: -1, sortOrder: SortOrder.DESC, orderBy: IApiInvestigationOrderBy.CreatedAt}
          )
          .pipe(unwrapConnection()))
      }
      this.loaderService.show$(forkJoin(ob)).subscribe(([expenseItems, investigations]) => {
        this.expenseItems = expenseItems;
        if (investigations && investigations?.length) {
          investigations = investigations?.filter(i => i.nefcoNumber);
          investigations.map((obj) => {
            obj["name"] = obj?.nefcoNumber ? obj.nefcoNumber : obj.id;
          });
        }
        this.investigations = investigations || [];
      });
    });
  }

  ngAfterViewInit() {
    this.drawer.toggle(true);
    this.addBodyClass(true);
    if (!this.expenseInvestigationExpensesCreatePermission?.enabled || !this.expenseUnlinkedExpenseCreatePermission?.enabled) {
      if (!this.expenseInvestigationExpensesCreatePermission?.enabled && this.expenseUnlinkedExpenseCreatePermission?.enabled) {
        this._unlinked = true;
      }
    }
    this.cdr.detectChanges();
  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }
  public backToStepOne() {
    this.drawer.toggle(false);
    this.stepTo.emit(1);
    this.addBodyClass(false);
  }

  public close(): void {
    this.drawer.toggle(false);
    if (this.goBackOnSubmit) {
      this.location.back();
    } else {
      this.stepTo.emit(1);
      this.reloadPage.emit(true);
    }
    this.addBodyClass(false);
  }

  public disabledDates = (date: Date): boolean => {
    return moment(date).isAfter();
  };

  public onSubmit() {
    if (!this.isFormValid()) {
      return;
    }
    const data = {
      ...this.expense,
      billableQuantity: this.expense.billableQuantity || 0,
      nonBillableQuantity: this.expense.nonBillableQuantity || 0,
    };
    if (!data?.id) delete data.id
    this.loaderService.show$(
      this.expenseService.add(data as IApiAddExpenseInput).pipe(
        this.notificationsService.snackbarPipe("Expense item added.")
      )
    ).subscribe(() => {
      this.form.form.reset();
      this.close();
    }, (err) => {
      let errorMessage = "Error adding expense item.";
      if (err?.message?.includes('First evidence expense has already been created and invoiced')) {
        errorMessage = "Expense has already been created and invoiced. Unable to create another initial evidence expense.";
      }
      this.notificationsService.snackbarError(errorMessage);
    });
  }

  // Set custom description on type change
  public typeChange(selectedId: string) {
    this.cdr.detectChanges();
    this.autoCompleteExpenseItemId?.toggle(false);
    this._expense.ExpenseItemId = selectedId || null;
    this.selectedTypeName = this.expenseItems.find(({ id }) => id === this._expense.ExpenseItemId)?.name || '';
  }


  public resetPurposes() {
    this.expense.ExpensePurposeId = null;
  }

  public changedBillableNonBillableField() {
    if ((this.expense.billableQuantity + this.expense.nonBillableQuantity) < 100) {
      this.expense.authorizedBy = '';
      this.expense.authorizedDate = null;
    }
  }

  public changePaid(e) {
    if (!e) {
      this._expense.paidDate = null;
    }
  }
}
