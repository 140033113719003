import { IApiEvidenceBillingConnection, IApiEvidenceBillingFrequencyConnection } from './../../../modules/graphql/types/types';
import { GqlEvidenceBillingFrequencyService } from 'src/app/shared/modules/graphql/services/evidence/evidence-billing-frequency/evidence-billing-frequency.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import {
  IApiAddEvidenceBillingFrequencyInput,
  IApiEvidenceBillingFrequencyFilter,
  IApiUpdateEvidenceBillingFrequencyInput,
  IApiRemoveEvidenceBillingFrequencyInput
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class EvidenceBillingFrequencyService {

  constructor(
    private gqlEvidenceBillingFrequencyService: GqlEvidenceBillingFrequencyService
  ) { }

  public get(filters?: IApiEvidenceBillingFrequencyFilter[], options?: IGqlBaseOptions): Observable<IApiEvidenceBillingFrequencyConnection> {
    return this.gqlEvidenceBillingFrequencyService.getEvidenceBillingFrequency(filters, options || new ListPage());
  }

  public add(input: IApiAddEvidenceBillingFrequencyInput) {
    return this.gqlEvidenceBillingFrequencyService.addEvidenceBillingFrequency(input);
  }

  public remove(input: IApiRemoveEvidenceBillingFrequencyInput) {
    return this.gqlEvidenceBillingFrequencyService.removeEvidenceBillingFrequency(input);
  }

  public update(input: IApiUpdateEvidenceBillingFrequencyInput) {
    return this.gqlEvidenceBillingFrequencyService.updateEvidenceBillingFrequency(input);
  }

}
