import { Component, forwardRef, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { IApiInsuredParty, IApiInsuredPartyFilterType } from '../../modules/graphql/types/types';
import { InsuredPartyService } from 'src/app/shared/services';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { unwrapConnection } from '../../rxjs.pipes';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { debounceTime, map } from 'rxjs/operators';
import {
  investigationPartiesList
} from "src/app/shared/helpers/auth-config/investigations.config";
import _ from 'lodash';
@Component({
  selector: 'app-insured-multi-select-kendo',
  templateUrl: './insured-multi-select-kendo.component.html',
  styleUrls: ['./insured-multi-select-kendo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InsuredMultiSelectKendoComponent),
      multi: true
    }
  ]
})
export class InsuredMultiSelectKendoComponent implements OnChanges  {
  public authConfig = {
    investigationPartiesList
  };
  @ViewChild("multiSelect", { static: false }) public multiSelect: MultiSelectComponent;
  @Input() placeholder = "Search insured name";
  @Input() label = "";
  @Input() disabled = false;
  @Input() includeInactive = false;
  @Input() selectedId: Array<string> = [];

  public insuredParties: IApiInsuredParty[];

  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public loading: boolean = false;
  public searchString: string = '';
  public selectedValue = [];
  private selectedParties = [];

  constructor(
    private insuredPartyService: InsuredPartyService
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!_.isEqualWith(this.selectedValue, changes?.selectedId?.currentValue)) {
      this.selectedId = changes?.selectedId?.currentValue || [];
      if (this.selectedId?.length) {
        const filters = [{
          type: IApiInsuredPartyFilterType.Ids,
          value: JSON.stringify(this.selectedId)
        }];
        this.loading = true;
        this.insuredPartyService.get(filters).pipe(
          debounceTime(800),
          unwrapConnection()
        ).subscribe(results => {
          this.loading = false;
          this.selectedParties = results;
          this.insuredParties = this.selectedParties;
          this.selectedValue = this.selectedId
        });
      } else {
        this.selectedValue = [];
        this.selectedParties = [];
        this.insuredParties = [];
      }
    }
  }

  public onSearchFocus() {
    if(this.selectedParties){
      this.insuredParties = this.selectedParties;
    }
  }

  public selectCompany(selected: any) {
    this.selectedParties = this.insuredParties.filter(usr => selected?.includes(usr.id));
    this.selectionChanged.emit(this.selectedValue);
    this._propagateChange(this.selectedValue);
  }

  public onSearchChange(search: string) {
    this.searchString = search?.trim();
    this.multiSelect.toggle(false);
    if (this.selectedParties) {
      this.insuredParties = this.selectedParties;
    } else {
      this.insuredParties = [];
    }
    if ((search?.trim())?.length < 3) return;

    const filters = [{
      type: IApiInsuredPartyFilterType.InsuredName,
      value: search?.trim()
    }];

    this.loading = true;
    this.multiSelect.toggle(false)
    this.insuredPartyService.get(filters).pipe(
      debounceTime(800),
      unwrapConnection()
    ).subscribe(results => {
      this.loading = false;
      this.multiSelect.toggle(true);
      const newResult = results?.filter((res) => !this.selectedValue?.includes(res.id))
      this.insuredParties = [...this.selectedParties, ...newResult];
    });
  }

  private _propagateChange = (_: any) => { };

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

}
