import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsBillToCompanyConnection, IApiInvestigationMetricsBillToCompany } from 'src/app/shared/modules/graphql/types/types';
import { InvestigationMetricsBilltoCompanyService } from './investigation-metrics-billto-company.service';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class InvestigationMetricsBillToCompanyDatasource extends NefcoPagedDataSource<IApiInvestigationMetricsBillToCompany, InvestigationMetricsBilltoCompanyService, IApiInvestigationMetricsBillToCompanyConnection> implements DataSource<IApiInvestigationMetricsBillToCompany> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsBillToCompanyConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
