<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffViewAll" #adminPerm="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <h4 class="page-title">Quarterly Utilization Report</h4>
  <p class="k-column-title">
    This is a report for all Billable and Non Billable hours, excluding training
    hours.
  </p>
  <kendo-card width="100%">
    <div class="content-wrapper">
      <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(5,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <app-user-multi-select-kendo label="Staff Name" [userViewFilter]="userViewFilter" placeholder="Select Staff"
            [selectedId]="filterByIdValue(filterTypes.Users)" [investigationRole]="" [includeInactive]="true"
            (selectionChanged)="setFilters($event, filterTypes.Users)"></app-user-multi-select-kendo>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <app-state-multi-select-kendo placeholder="Select state" label="State"
            [selectedId]="filterByIdValue(filterTypes.State)" (selectionChanged)="setFilters($event, filterTypes.State)">
          </app-state-multi-select-kendo>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <app-staff-type-multi-select-kendo label="Staff Type" placeholder="Select Staff Type" [excludeTitles]="excludeTitles"
            [selectedId]="filterValue(filterTypes.StaffType)"
            (selectionChanged)="setFilters($event, filterTypes.StaffType)">
          </app-staff-type-multi-select-kendo>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <app-date-range-next-prev-filter-kendo [mode]="'quarter'" [selectedId]="filterValue(filterTypes.WorkDayRange)"
            (dateRangeChange)="setFilters($event, filterTypes.WorkDayRange)"></app-date-range-next-prev-filter-kendo>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <div class="filter-actions">
            <div class="mr-16 applied-filter">
              <kendo-label text=""></kendo-label>
              <div>
                <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium"
                  (click)="applyFilter()">Apply Filters</kendo-button>
              </div>
            </div>
            <div class="mt-5 clear-filter">
              <kendo-label text=""></kendo-label>
              <div>
                <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium"
                  (click)="clearFilter()">Clear All</kendo-button>
              </div>
            </div>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-grid class="quarterly-list td-20-16 last-col-background" [data]="{
          data: data,
          total: (dataSource?.pageInfo$ | async)?.totalCount
        }" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="
          pageOptions
            ? {
                buttonCount: 6,
                type: 'numeric',
                pageSizes: [5, 10, 25, 100],
                previousNext: true,
                responsive: true,
                position: 'bottom'
              }
            : false
        " (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)"
        [navigatable]="false" [resizable]="true" [scrollable]="true">
        <kendo-grid-column field="USER_NAME" title="Staff Name" [width]="25" [sortable]="true" [resizable]="false"
          [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="font-weight-600"> </span>
            <div class="user-name">
              <a
                *ngIf="adminPerm.enabled; else nonadmin"
                [routerLink]="['/staff/', dataItem?.id]"
                class="text-decoration"
                target="_blank"
              >
                {{ dataItem?.lastName ? dataItem?.lastName + ", " : "" }}{{ dataItem?.firstName || "" }}
              </a>
              <ng-template #nonadmin>
                <span class="font-weight-600">
                  {{ dataItem?.lastName ? dataItem?.lastName + ", " : "" }}{{ dataItem?.firstName || "" }}
                </span>
              </ng-template>
              <br />
              <span class="phone-number">{{
                dataItem?.Addresses ? getStateName(dataItem?.showAddress?.state)?.text : ""
                }}</span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="HOURS_WORKED" title="Hours Worked" [width]="25" [sortable]="true" [resizable]="false"
          [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="font-weight-600">
              {{ dataItem?.hoursWorked || "-" }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="QUARTERLY_GOAL" title="Quarterly Goal" [width]="25" [sortable]="true"
          [resizable]="false" [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="font-weight-600">
              {{ dataItem?.quarterlyGoal || (rowIndex === 0 ? "" : "-") }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="QUARTERLY_GOAL_PERCENTAGE" title="% TO Goal" [width]="25" [sortable]="true"
          [resizable]="false" [headerClass]="'sort-header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="font-weight-600">
              {{  dataItem?.quarterlyGoalPercentage > 1 ? ((-(dataItem?.quarterlyGoalPercentage - 1) * 100) | number : '1.2-2') : (dataItem?.quarterlyGoalPercentage * 100) | number : "1.2-2" || "0" }}%
            </span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </kendo-card>
</section>
