import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IApiAddTimeEntryInput, IApiInvestigation, IApiTimeEntry, IApiTimeEntryFilter, IApiTimeEntryFilterType, IApiTimeEntryOrderBy, IApiTimeEntryStatusNames, IApiTimeEntryTotal, IApiUpdateTimeEntryInput } from 'src/app/shared/modules/graphql/types/types';
import { TimeEntryDataSource, TimeEntryService } from 'src/app/shared/services/time-entry';
import moment from "moment";
import { SortDescriptor } from '@progress/kendo-data-query';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { InvestigationTimeAndExpModalKendoComponent } from '../../investigations';
import { filter, switchMap } from 'rxjs/operators';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { timeEntryInvestigationHoursDelete, timeEntryInvestigationHoursDeleteOwn, timeEntryInvestigationHoursUpdateOwn, timeEntryInvestigationHoursUpdate } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { AuthService } from 'src/app/shared/services';
import { IInvestigationTimeExpenseModalData } from '../../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';

@Component({
  selector: 'app-time-exp-hours-table-kendo',
  templateUrl: './time-exp-hours-table-kendo.component.html',
  styleUrls: ['./time-exp-hours-table-kendo.component.scss']
})
export class TimeExpHoursTableKendoComponent implements OnInit, OnChanges {

  public authConfig = {
    timeEntryInvestigationHoursUpdate,
    timeEntryInvestigationHoursUpdateOwn,
    timeEntryInvestigationHoursDelete,
    timeEntryInvestigationHoursDeleteOwn
  };

  @Input() investigations: IApiInvestigation[];
  @Input() userId: string;
  @Input() investigationId: string;
  @Input() investigationView: boolean = false;
  @Input() expenseInvestigationExpensesAll;
  @Input() refresh: boolean = false;
  @Input() employeeFilter = "";
  public _displayAllOption = false;
  @Input() set displayAllOption(val: boolean) {
    this._displayAllOption = val;
  }
  get displayAllOption() {
    return this._displayAllOption;
  }

  @Output() changedFilter = new EventEmitter<{dateRangeFilter: string, dateFilterYear: string, dateFilterWeek: string}>();

  private _dataSource: TimeEntryDataSource;
  public get dataSource(): TimeEntryDataSource {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  // Variables for filters
  public dateFilterType = "work";
  private dateFilterWeek: string = moment().isoWeek().toString();
  private dateFilterYear: string = (moment().year()).toString();

  private dateRangeFilter: string;
  private visibleTimeEntries: IApiTimeEntry[];

  public timeEntries: IApiTimeEntry[];
  public timeEntriesCount: IApiTimeEntryTotal;

  public displayedColumns = [
    "date",
    "invId",
    "type",
    "hours",
    "paid",
    "actions",
  ];
  public footerCols = ["date", "invId", "type", "hours", "paid", "actions"];
  public selectedInvestigation: IApiInvestigation = null;
  public totalHours: number = null;

  private _timeEntryForm = {
    id: null,
    TypeId: null,
    workday: new Date(),
    hours: null,
    UserId: null,
    InvestigationId: null,
    description: "",
  };

  // Setters/getters for above
  public get timeEntryForm() {
    return this._timeEntryForm;
  }

  public sort: SortDescriptor[] = [{
    field: 'WORKDAY',
    dir: 'desc'
  }];
  public authenticatedUserId = null;

  constructor(
    public loader: LoaderService,
    private timeEntryService: TimeEntryService,
    private notifications: NotificationsService,
    private dialogService: DialogService,
    public auth: AuthService,
  ) { }

  public applyFilters() {
    this.dataSource.removeFilter(IApiTimeEntryFilterType.PayPeriod);
    if (this.dateRangeFilter) {
      this.dataSource.removeFilter(IApiTimeEntryFilterType.Week);
      this.dataSource.removeFilter(IApiTimeEntryFilterType.Year);
      this.dataSource.applyFilter(IApiTimeEntryFilterType.DateRange, this.dateRangeFilter);
    } else {
      this.dataSource.removeFilter(IApiTimeEntryFilterType.DateRange);
      this.dataSource.applyFilter(IApiTimeEntryFilterType.Year, this.dateFilterYear)
      this.dataSource.applyFilter(IApiTimeEntryFilterType.Week, this.dateFilterWeek);
    }
    this.load();
  }


  public dateRangeChange(event) {
    this.dateRangeFilter = event;
    this.changedFilter.emit({dateRangeFilter: this.dateRangeFilter, dateFilterWeek: null, dateFilterYear: null});
    this.applyFilters();
  }

  public dateChange(event) {
    this.dateRangeFilter = null;
    this.dateFilterWeek = event ? (event.isoWeek()).toString() : null;
    this.dateFilterYear = event ? (event.year()).toString() : null;
    this.changedFilter.emit({dateRangeFilter: null, dateFilterWeek: this.dateFilterWeek, dateFilterYear: this.dateFilterYear});
    this.applyFilters();
  }

  private getTimeEntryTotal() {
    this.timeEntryService.getTimeEntryTotal(this.dataSource.lastFilters, this.pageOptions.getPageOptions())
      .subscribe(response => this.timeEntriesCount = response);

  }

  public investigationChanged(event) {
    this.dataSource.applyFilter(IApiTimeEntryFilterType.Investigation, event);
    this.load();
  }

  public formSubmitted(timeEntry) {
    if (timeEntry.id) this.updateTimeEntry(timeEntry);
  }

  public get timeEntryTotal() {
    if (this.visibleTimeEntries) {
      return this.visibleTimeEntries.reduce(
        (p, c) => p + c.hours + c.nonBillableHours,
        0
      );
    } else {
      return 0;
    }
  }

  public showPaid(name: string) {
    return name === IApiTimeEntryStatusNames.Approved;
  }

  private updateTimeEntry(timeEntry: IApiUpdateTimeEntryInput) {
    this.loader
      .show$(this.timeEntryService.update(timeEntry))
      .pipe(
        this.notifications.alertPipe("Time entry updated"),
        this.notifications.catchAlertPipe("Error updating Time entry")
      )
      .subscribe(() => this.load());
  }

  public editTime(item?: IApiTimeEntry) {
    const timeItem = (item as IApiTimeEntry)?.workday
      ? { ...item }
      : {
        id: null,
        Type: null,
        workday: new Date(),
        hours: null,
        nonBillableHours: null,
        User: null,
      };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: this.investigationId ? this.investigationId : item?.Investigation?.id,
      nefcoNumber: item?.Investigation?.nefcoNumber,
      expense: null,
      time: timeItem as IApiTimeEntry,
      staff: null,
      adminView: false,
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = [];
    dialogInstance.investigations = this.investigations;
    dialogInstance.expenseItems = [];
    dialogInstance.investigationView = this.investigationView;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) this.load();
    })
  }

  public deleteTime(entry) {

    this.notifications.kendoConfirm(
      "Are you sure you want to delete this hours entry?",
      "Delete Hours Entry?",
      "No, Don’t Delete",
      "Yes, Delete"
    )
      .pipe(
        filter((v) => !!v),
        switchMap(() => this.timeEntryService.remove(entry.id))
      )
      .subscribe(() => {
        this.load();
      });
  }

  ngOnInit() {
    this.auth.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
    this._dataSource = new TimeEntryDataSource(this.timeEntryService);
    this.loader.attachObservable(this._dataSource.loading$);
    /* Not ALL Default */
    if (!this.displayAllOption) this.dataSource.applyFilter(IApiTimeEntryFilterType.Week, this.dateFilterWeek);
    
    if (this.investigationId) {
      this.dataSource.applyFilter(IApiTimeEntryFilterType.Investigation, this.investigationId);
    }
    if (this.investigationView === false)
      this.dataSource.applyFilter(IApiTimeEntryFilterType.Employee, "");
    else
      this.dataSource.applyFilter(IApiTimeEntryFilterType.Employee, this.employeeFilter);


    this._timeEntryForm.UserId = this.userId;
    this.pageOptions.orderBy = IApiTimeEntryOrderBy.Workday;

    this.load();
  }

  ngOnChanges(data) {
    if (data?.refresh?.previousValue === false && data?.refresh?.currentValue === true) {
      this.load();
    }
    if(data?.employeeFilter?.currentValue){
      this.dataSource?.applyFilter(IApiTimeEntryFilterType.Employee, data?.employeeFilter?.currentValue);
      this.load();
    }
  }

  public load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
    this.getTimeEntryTotal();
    this.dataSource.contents$.subscribe((data) => {
      this.visibleTimeEntries = data;
      this.timeEntries = data;
      setTimeout(() => {
        try {
          document.getElementsByTagName('body')?.[0].click();
        } catch (error) { }
      }, 100);
    });
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'WORKDAY';
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  }

}
