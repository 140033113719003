import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { groupBy } from '@progress/kendo-data-query';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { ReactiveFormHelper } from 'src/app/shared/helpers/reactive-form.class';
import { IState } from 'src/app/shared/interfaces/state.interfaces';
import { IApiPhoneType } from 'src/app/shared/modules/graphql/types/types';
import { removeTypename } from 'src/app/shared/rxjs.pipes';
import { PortalService, StateService } from 'src/app/shared/services';

@Component({
  selector: 'app-insured-information',
  templateUrl: './insured-information.component.html',
  styleUrls: ['./insured-information.component.scss']
})
export class InsuredInformationComponent implements OnInit {

  @Input() formGroupName: string;
  insuredInformation: FormGroup;
  @Input() public phoneTypes: IApiPhoneType[] = [];
  public stateList: Array<IState>;
  public stateFiltered: Array<IState>;
  public groupedDataStates: any;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private fb: FormBuilder,
    private portalService: PortalService,
    private stateService: StateService
    ) { }

  ngOnInit(): void {
    this.insuredInformation = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.addAdditionalContactToList();
    this.stateList = this.stateService.allStates;
    this.stateFiltered = this.stateService.allStates;
    this.groupedDataStates = groupBy(this.stateFiltered, [
      { field: "type" },
    ]);
  }

  public handleFilter(value) {
    this.stateFiltered = this.stateList.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.groupedDataStates = groupBy(this.stateFiltered, [
      { field: "type" },
    ]);
  }

  get additionalContactsFieldAsFormArray(): FormArray {
    return this.insuredInformation.get('additionalContacts') as FormArray;
  }

  public addAdditionalContact(): any {
    return this.fb.group({
      phoneNumber: [
        "",
        [Validators.required, ReactiveFormHelper.noWhitespaceValidator],
      ],
      typeId: ["", [Validators.required]],
      extension: [
        "",
        [
          Validators.minLength(0),
          Validators.maxLength(5),
          ReactiveFormHelper.noWhitespaceValidator,
          ReactiveFormHelper.numberOnly,
        ],
      ],
    });
  }

  public addAdditionalContactToList(): void {
    this.additionalContactsFieldAsFormArray.push(this.addAdditionalContact());
  }

  public removeaddAdditionalContact(i: number): void {
    this.additionalContactsFieldAsFormArray.removeAt(i);
  }

  public changedInsuredType(type: boolean) {
    if (type) {
      this.insuredInformation.get('insuredName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.insuredInformation.patchValue({companyName: ''});
      this.insuredInformation.patchValue({contactName: ''});
      this.insuredInformation.get('companyName').clearValidators();
      this.insuredInformation.get('contactName').clearValidators();
    } else {
      this.insuredInformation.patchValue({insuredName: ''});
      this.insuredInformation.get('insuredName').clearValidators();
      this.insuredInformation.get('companyName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.insuredInformation.get('contactName').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
    }
    this.insuredInformation.get('insuredName').updateValueAndValidity();
    this.insuredInformation.get('companyName').updateValueAndValidity();
    this.insuredInformation.get('contactName').updateValueAndValidity();
  }

  public changedInsuredIs(type: string) {
    if (type === 'Other') {
      this.insuredInformation.get('other').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
    } else {
      this.insuredInformation.patchValue({other: ''});
      this.insuredInformation.get('other').clearValidators();
    }
    this.insuredInformation.get('other').updateValueAndValidity();
  }

  public changedinsuredSameAsLossAddress(type: boolean) {
    if (type) {
      this.insuredInformation.patchValue({insuredAddress: ''});
      this.insuredInformation.patchValue({insuredCity: ''});
      this.insuredInformation.patchValue({insuredState: ''});
      this.insuredInformation.patchValue({insuredZipcode: ''});
      this.insuredInformation.patchValue({insuredContactNumber: ''});
      this.insuredInformation.get('insuredAddress').clearValidators();
      this.insuredInformation.get('insuredCity').clearValidators();
      this.insuredInformation.get('insuredState').clearValidators();
      this.insuredInformation.get('insuredZipcode').clearValidators();
      this.insuredInformation.get('insuredContactNumber').clearValidators();
    } else {
      this.insuredInformation.get('insuredAddress').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.insuredInformation.get('insuredCity').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.insuredInformation.get('insuredState').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.insuredInformation.get('insuredZipcode').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.insuredInformation.get('insuredContactNumber').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
    }
    this.insuredInformation.get('insuredAddress').updateValueAndValidity();
    this.insuredInformation.get('insuredCity').updateValueAndValidity();
    this.insuredInformation.get('insuredState').updateValueAndValidity();
    this.insuredInformation.get('insuredZipcode').updateValueAndValidity();
    this.insuredInformation.get('insuredContactNumber').updateValueAndValidity();
  }

}
