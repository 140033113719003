import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiRequestStatusConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  requestStatusConnectionQuery, addRequestStatusMutation,
  removeRequestStatusMutation, getRequestStatusByIdQuery,
  updateRequestStatusMutation
} from './request-status.operations';
import {
  IApiRequestStatusFilter, IApiAddRequestStatusInput, IApiUpdateRequestStatusInput,
  IApiRemoveRequestStatusInput, IApiRequestStatus
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlRequestStatusService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiRequestStatusFilter[], options: IGqlBaseOptions): Observable<IApiRequestStatusConnection> {
    return this.apollo.query(connectionQuery(requestStatusConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddRequestStatusInput): Observable<IApiRequestStatus> {
    return this.apollo.mutate({
      mutation: addRequestStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public remove(input: IApiRemoveRequestStatusInput): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeRequestStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateRequestStatusInput): Observable<IApiRequestStatus> {
    return this.apollo.mutate({
      mutation: updateRequestStatusMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public getRequestStatusById(id: string): Observable<IApiRequestStatus> {
    return this.apollo.query({
      query: getRequestStatusByIdQuery,
      variables: { id: id }
    }).pipe(
      safeResolve(true)
    );
  }
}
