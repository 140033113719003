import gql from 'graphql-tag';

export const investigationMetricsClientCompanyConnection = gql`query InvestigationMetricsClientCompanyConnection($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
investigationMetricsClientCompanyConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder)
{
    pageInfo
      {
        limit
        offset
      }
      totalCount
      edges
      {
        cursor
        node
      {
        id
        name
        data
      }
    }
  }
}`
