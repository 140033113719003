import { connectionQuery } from './../../../helpers.class';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { IApiNotificationTypesConnection } from 'src/app/shared/modules/graphql/types/types';
import {
  notificationTypesConnectionQuery, getNotificationTypeByIdQuery } from './notification-type.operations';
import {
  IApiNotificationTypeFilter, IApiNotificationType
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class GqlNotificationTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiNotificationTypeFilter[], options: IGqlBaseOptions): Observable<IApiNotificationTypesConnection> {
    return this.apollo.query(connectionQuery(notificationTypesConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getById(id: string): Observable<IApiNotificationType> {
    return this.apollo.query({
      query: getNotificationTypeByIdQuery,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
