import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpOptions } from '@apollo/client/core';
import { PortalRequestService } from './portal-base.service';

@Injectable()
export class PortalAssignmentService {
  constructor(
    private request: PortalRequestService,
    ) {}

  public get() {
      return this.request.get("/assignments/list");
  }

  public create() {
      throw new Error("Not implemented yet");
  }

}
