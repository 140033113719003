import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment, UrlSegmentGroup, UrlTree } from "@angular/router";
import moment from "moment";
import { Apollo, QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { NotificationsService } from '../../../../shared/modules/notifications/notifications.service';
import { UserNotificationService } from "src/app/shared/services";
import { tap } from 'rxjs/operators';
import { IApiNotification, IApiNotificationFilterType, IApiNotificationOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { UserNotificationDataSource } from 'src/app/shared/services/user-notification';
import { getNotificationIcon } from 'src/app/shared/helpers/helper'
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
interface IFilteredArrays {
  today: any[];
  previous: any[];
}

@Component({
  selector: "app-notifications-management",
  templateUrl: "./notifications-management.component.html",
  styleUrls: ["./notifications-management.component.scss"],
})
export class NotificationsManagementComponent implements OnInit {
  public notificationsToday: IApiNotification[] = [];
  public notificationsPrevious: IApiNotification[] = [];

  public selectedTab = 0;
  public commentsQuery: QueryRef<any>;
  public comments: Observable<any>;
  public params: any;

  public selection = new SelectionModel<IApiNotification>(true, []);

  public selectAll = false;
  public notifications: IApiNotification[] = [];
  private _dataSource: UserNotificationDataSource;

  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  constructor(
    public apollo: Apollo,
    private userNotificationService: UserNotificationService,
    public loader: LoaderService,
    private router: Router
  ) {}

  ngOnInit() {
    this._dataSource = new UserNotificationDataSource(
      this.userNotificationService
    );
    this.loader.attachObservable(this._dataSource.loading$);

    this._dataSource.applyFilter(IApiNotificationFilterType.IsViewed, 'false');
    this._dataSource.listPage.orderBy = IApiNotificationOrderBy.CreatedAt;
    this._dataSource.listPage.sortOrder = SortOrder.DESC;
    this._dataSource.listPage.limit = 10;

    // populate the data
    this.load();
  }

  private load() {
    this.dataSource.load().then(() => {
      this.dataSource.contents$.subscribe((res) => {
        this.notifications = res;
      });
    });
  }

  public getIcon = (key) => {
    return getNotificationIcon(key);
  };

  public viewPage(data: IApiNotification) {
    this.userNotificationService.markNotificationsAsReadOrUnread({ids: [data?.id], viewed: true}).subscribe();
    return this.router.navigateByUrl(data?.redirectUrl);
  }

  public isAllSelected() {
    return this.selection?.selected?.length === this.notifications?.length;
  }

  public handleMasterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.notifications.forEach((row) => this.selection.select(row));
  }

  public handleSelection(isSelected: boolean, notification: IApiNotification) {
    isSelected
      ? this.selection.select(notification)
      : this.selection.deselect(notification);
  }

  public pageChange(event) {
    this.selection.clear();
    this.pageOptions?.paginate(event);
  }

  public get getCount() {
    return this.pageOptions?.totalCount - this.notifications.filter(item => item.viewed === true).length;
  }

  public markNotificationsAsReadOrUnread(read = true) {
    this.notifications.map(item => {
      item.viewed = this.selection.selected.find(x => x.id === item.id) ? read : item?.viewed;
      return item;
    });

    const readNotification = this.selection.selected.filter(x => x.viewed === read).map(item => item.id);
    this.userNotificationService.markNotificationsAsReadOrUnread({ids: readNotification, viewed: read}).subscribe(() => this.selection.clear());
  }
}
