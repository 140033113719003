<div [dialogRef]="dialog" (appClickOutside)="close('closed')">
<kendo-dialog-titlebar (close)="close('closed')">
  <i *ngIf="headerIcon" class="fa-solid fa-circle-exclamation fa-xl"></i>
  <div class="modal-title ml-8">
      <span>{{title}}</span>
      <div class="modal-subtitle" *ngIf="subTitle" [innerHTML]="subTitle"></div>
    </div>
</kendo-dialog-titlebar>
<div class="model-body">
  <p *ngIf="bodyMessage" class="warning-message mb-16 mt-16" [innerHTML]="bodyMessage">
  </p>
</div>
<kendo-dialog-actions layout="end">
  <button kendoButton [size]="'medium'" themeColor="light" class="mr-16" (click)="close('no')"
    *ngIf="leftBtn">{{leftBtn}}</button>
  <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="close('yes')"
    *ngIf="rightBtn">{{rightBtn}}</button>
</kendo-dialog-actions>
</div>
