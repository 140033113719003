import { Component, OnInit, ViewChild } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { isArray } from 'lodash';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiDocumentSentFilter, IApiDocumentSentFilterType, IApiDocumentSentOrderBy, IApiInvestigationRoleNames, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { ReportSentMetricService } from 'src/app/shared/services/reports';
import { ReportSentMetricDataSource } from 'src/app/shared/services/reports/report-sent-metric/report-sent-metric.datasource';

@Component({
  selector: 'app-report-sent-metric-report',
  templateUrl: './report-sent-metric-report.component.html',
  styleUrls: ['./report-sent-metric-report.component.scss']
})
export class ReportSentMetricReportComponent implements OnInit {
  @ViewChild('startDateRange', { static: false }) startDateRange: any;
  @ViewChild('endDateRange', { static: false }) endDateRange: any;
  
  private _filters: IApiDocumentSentFilter[] = [];
  public filterTypes = IApiDocumentSentFilterType;

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public range = { startDate: null, endDate: null };
  public userRoleName = IApiInvestigationRoleNames;

  private _dataSource: ReportSentMetricDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public data = [];
  public sort: SortDescriptor[] = [
    {
      field: IApiDocumentSentOrderBy.SendDate,
      dir: "desc",
    },
  ];
  constructor(
    private loaderService: LoaderService,
    private reportSentMetricService: ReportSentMetricService

  ) { }

  ngOnInit(): void {
    this.loaderService.isMatLoader = false;
    this.dataSource = new ReportSentMetricDataSource(this.reportSentMetricService);
    // Table Initialization / Setup
    this.loaderService.attachObservable(this.dataSource.loading$);
    this.dataSource.listPage.orderBy = IApiDocumentSentOrderBy.SendDate;
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
    this.load();
  }

  public setFilters(value: string | undefined, type: IApiDocumentSentFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value
    }] : filtersCopy;
  }

  public filterValue(filter: IApiDocumentSentFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
        ? value
        : null;
  }

  public filterByIdValue(filter: IApiDocumentSentFilterType) {
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }

  public updateDateFilters(event) {
    if (this.range.startDate && this.range.endDate) {
      const dates = {
        startDate: NefcoDateHelper.toUtcStartOfDay(this.range.startDate, true),
        endDate: NefcoDateHelper.toUtcStartOfDay(this.range.endDate, false),
      };
      this.setFilters(
        JSON.stringify({
          startDate: dates.startDate,
          endDate: dates.endDate,
        }),
        IApiDocumentSentFilterType.SendDateRange
      );
    }
  }

  public focusDateInput(start = true) {
    if (this.startDateRange && start) {
      setTimeout(() => {
        this.startDateRange?.focus();
      }, 1);
    } else {
      setTimeout(() => {
        this.endDateRange?.focus();
      }, 1);
    }
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiDocumentSentOrderBy.SendDate;
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  private load(): void {
    this.dataSource.load().then(() => {
      this.dataSource?.contents$.subscribe((res) => {
        this.data = res;
      });
    });
  }

  applyFilter() {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.filters?.map(filter => this.dataSource.applyFilter(filter?.type, filter?.value));
    this.dataSource.pagingReset();
    this.load();
  }

  clearFilter() {
    this.filters = [];
    this.range = { startDate: null, endDate: null };
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.pagingReset();
    this.load();
  }


}
