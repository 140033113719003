<!-- Permissions -->
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursUpdate" #timeEntryInvestigationHoursUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursUpdateOwn" #timeEntryInvestigationHoursUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursDelete" #timeEntryInvestigationHoursDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursDeleteOwn" #timeEntryInvestigationHoursDeleteOwn="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-card class="kendocard">
  <kendo-card-header class="pt-0 pb-0">
      <app-time-exp-date-kendo-filter
        [mode]="'week'"
        [displayAllOption]="displayAllOption"
        (dateChange)="dateChange($event)"
        (dateRangeChange)="dateRangeChange($event)"
        (investigationChange)="investigationChanged($event)"
        [investigationView]="investigationView"
        [investigations]="investigations">
      </app-time-exp-date-kendo-filter>
    </kendo-card-header>
    <kendo-card-body class="pt-0">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" class="expense-counter-wrapper" [cols]="investigationView? ['70%', '20%'] : ['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <div class="expense-div">
            <div>
              <h4>Total Hours</h4>
              <p>{{timeEntriesCount?.totalHours || 0}}</p>
            </div>
            <div>
              <h4>Billable Hours</h4>
              <p>{{timeEntriesCount?.billableHours || 0}}</p>
            </div>
            <div>
              <h4>Non-Billable Hours</h4>
              <p>{{timeEntriesCount?.nonBillableHours || 0}}</p>
            </div>
          </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item></kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-grid
      class="hours-list"
      [data]="{
        data: timeEntries,
        total: (dataSource?.pageInfo$ | async)?.totalCount
      }"
      [pageSize]="pageOptions?.limit"
      [skip]="pageOptions?.offset"
      [pageable]="{
        buttonCount: 6,
        type: 'numeric',
        pageSizes: [5, 10, 25, 100],
        previousNext: true,
        responsive: true,
        position: 'bottom'
      }"
      (pageChange)="pageChange($event)"
      [sortable]="true"
      [sort]="sort"
      (sortChange)="sortChange($event)"
    >
        <kendo-grid-column
          field="WORKDAY"
          title="Date"
          [width]="17"
          [sortable]="true"
          class="font-weight600"
          [headerClass]="'sort-header'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem?.workday | customDate : "MM/dd/yyyy" }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          field="INVESTIGATION"
          title="Investigation"
          [width]="17"
          class="id-column"
          [sortable]="true"
          class="font-weight600"
          [headerClass]="'sort-header'"
          *ngIf="!investigationView"
        >
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            target="_blank"
            [routerLink]="['/investigations/', dataItem?.Investigation?.id]"
            tel=""
            class="text-decoration"
            *ngIf="dataItem?.Investigation else Unlinked"
          >
            {{ dataItem?.Investigation?.nefcoNumber || "Unlinked" }}
          </a>
          <ng-template #Unlinked>
            <span>Unlinked</span>
          </ng-template>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="id"
        title="Type"
        [width]="25"
        [sortable]="false"
        [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="text-overflow">
          {{dataItem?.Type?.name}}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="BILL"
        title="Bill"
        [width]="9"
        [sortable]="false"
        class="text-right"
        [headerClass]="'nocursor text-right'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem?.hours || '-'}}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="NON_BILL"
        title="Non-Bill"
        [width]="12"
        [sortable]="false"
        [headerClass]="'nocursor text-right'"
        [class]="'text-right'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem?.nonBillableHours || '-'}}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        class="text-center"
        field="PAID_DATE"
        title="Paid"
        [width]="9"
        [sortable]="true"
        [headerClass]="'sort-header'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <i *ngIf="dataItem?.paidDate" class="right-circle-icon fa-solid fa-circle-check"></i>
          <i *ngIf="!dataItem?.paidDate" class="minus-circle-icon fa-solid fa-circle-minus"></i>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
      field="id"
      title="Actions"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton class="mr-8" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil" [disabled]="investigationView && dataItem?.paidDate" (click)="editTime(dataItem);"
            *ngIf="timeEntryInvestigationHoursUpdate?.enabled || (timeEntryInvestigationHoursUpdateOwn?.enabled && dataItem?.User?.id === authenticatedUserId)"></button>
          <ng-template #noPermission>
            <button kendoButton class="mr-8 visibility-hidden" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pencil"></button>
        </ng-template>
          <button kendoButton *ngIf="timeEntryInvestigationHoursDelete?.enabled || (timeEntryInvestigationHoursDeleteOwn?.enabled && dataItem?.User?.id === authenticatedUserId)" themeColor="error" fillMode="link" iconClass="fa-solid fa-trash" [disabled]="investigationView && dataItem?.paidDate"  (click)="deleteTime(dataItem)"></button>
        </ng-template>
      </kendo-grid-column>

    </kendo-grid>
    </kendo-card-body>
</kendo-card>