import gql from 'graphql-tag';

export const PermissionCategoriesQuery = gql`query getPermissionCategories {
  getPermissionCategories {
    id
    name
    AvailableScopes {
      id
      name
      AvailableActions {
        id
        name
      }
    }
    Roles {
      id
      name
    }
  }
}`;

export const PermissionScopesQuery = gql`query getPermissionScopes {
  getPermissionScopes {
    id
    name
  }
}`;

export const PermissionActionsQuery = gql`query getPermissionActions {
  getPermissionActions {
    id
    name
  }
}`;