import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { forkJoin } from 'rxjs';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiAddInvestigationHistoryInput, IApiInvestigation, IApiInvestigationDetail, IApiInvestigationDetailCause, IApiInvestigationDetailFilterType, IApiInvestigationDetailOrderBy, IApiInvestigationFilterType, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { AuthService, InvestigationDetailService, InvestigationReportService, InvestigationService } from 'src/app/shared/services';
import { DashboardDialogType, IDashboardModalData } from "../interfaces";


@Component({
  selector: 'app-dashboard-modal-view',
  templateUrl: './dashboard-modal-view.component.html',
  styleUrls: ['./dashboard-modal-view.component.scss'],
})
export class DashboardModalViewComponent implements OnInit {

  // Select lists
  public causes = IApiInvestigationDetailCause;
  public statuses = [];
  public cause = null;
  public comment: string = null;

  public displayedColumns = ["updated", "updatedBy", "comment", "status"];

  public investigationData: IApiInvestigation;

  public user: IApiUser;

  public dialogTypes = DashboardDialogType;

  public investigationDetail: IApiInvestigationDetail = null;

  constructor(
    public dialogRef: MatDialogRef<DashboardModalViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDashboardModalData, // Can be a couple of data types depending on who triggers it
    private loader: LoaderService,
    private auth: AuthService,
    private investigationService: InvestigationService,
    private investigationDetailService: InvestigationDetailService,
    private notification: NotificationsService,
    private reportService: InvestigationReportService
  ) { }

  public ngOnInit() {
    this.auth.authenticatedUser.subscribe(user => this.user = user);

    this.investigationDetailService.get([{ type: IApiInvestigationDetailFilterType.InvestigationId, value: this.data.investigationId }], { orderBy: IApiInvestigationDetailOrderBy.CreatedAt, sortOrder: SortOrder.ASC }).pipe(
      unwrapConnection()
      // We only expect one result from this
    ).subscribe(data => this.investigationDetail = data.length ? data[0] : null);
  }

  public closeModal(val: boolean) {
    this.dialogRef.close(val);
  }

  private updateInvestigationHistory(): Observable<boolean> {
    if (this.cause && (this.cause !== this.investigationDetail.cause)) {
      const invId = this.data.investigationId;
      const history: IApiAddInvestigationHistoryInput = {
        comment: this.comment,
        InvestigationId: invId,
        updateCategory: IApiInvestigationUpdateCategories.Detail,
        updateType: IApiInvestigationUpdateTypes.Update,
      };
      // Returns a boolean if the cause was updated
      return this.investigationDetailService.update({ id: this.investigationDetail.id, InvestigationId: invId, cause: this.cause }).pipe(
        switchMap(() => this.investigationService.update({ id: invId, History: history })),
        map(() => true)
      );
    } else {
      return of(false);
    }
  }

  public advanceReport(reject = false) {
    this.loader.show$(
      forkJoin([
        this.updateInvestigationHistory(),
        this.reportService.advance({ reject, documentId: this.data.document.id, comment: this.comment, sendToFinal: this.data.dialogType === DashboardDialogType.APPROVE_FINAL ? true : false })
      ]).pipe(
        this.notification.catchAlertPipe("Unable to modify report")
      )
    ).subscribe(() => this.closeModal(true));
  }

  public indicateCause() {
    this.updateInvestigationHistory().subscribe(() => this.closeModal(true));
  }

  public updateRequest() {
    this.updateInvestigationHistory();
    // Do something then close the modal
    this.closeModal(true);
  }

}
