export enum InvestigationPriorities {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH"
}

export enum InvestigationStatuses {
  NEW = "PENDING",
  ASSIGNED = "ASSIGNED",
  SCHEDULED = "SCHEDULED",
  IN_PROGRESS = "IN_PROGRESS",
  READY_TO_BILL = "READY_TO_BILL",
  HOLD_FOR_BILLING = "HOLD_FOR_BILLING",
  READY_TO_CLOSE = "READY_TO_CLOSE",
  CLOSED = "CLOSED"
}

export enum InvestigationStatusNames {
  NEW = "New",
  ASSIGNED = "Assigned",
  SCHEDULED = "Scheduled",
  IN_PROGRESS = "In Progress",
  READY_TO_BILL = "Ready to Bill",
  HOLD_FOR_BILLING = "Hold for Billing",
  READY_TO_CLOSE = "Ready to Close",
  CLOSED = "Closed"
}

export enum InvestigationRiskTypes {
  AUTO = "AUTO",
  SINGLE_FAMILY_HOME = "SINGLE_FAMILY_HOME",
  MULTI_FAMILY_HOME = "MULTI_FAMILY_HOME",
  APARTMENT_BUILDING = "APARTMENT_BUILDING",
  COMMERCIAL = "COMMERCIAL",
  BOAT = "BOAT",
  HEAVY_EQUIPMENT = "HEAVY_EQUIPMENT",
  EVIDENCE_STORAGE_ONLY = "EVIDENCE_STORAGE_ONLY",
  GARAGE = "GARAGE",
  BARN = "BARN",
  HVAC_INVESTIGATION = "HVAC_INVESTIGATION",
  LIGHTNING_STRIKE_INVESTIGATION = "LIGHTNING_STRIKE_INVESTIGATION",
  OTHER = "OTHER"
}

export enum InvestigationServiceStatuses {
  DOCUMENTS_MISSING = "DOCUMENTS_MISSING",
  EVIDENCE_MISSING = "EVIDENCE_MISSING",
  HI_RES_PHOTOS_MISSING = "HI_RES_PHOTOS_MISSING",
  PHOTOS_MISSING = "PHOTOS_MISSING",
  REPORT_MISSING = "REPORT_MISSING",
  DOCUMENTS_COMPLETE = "DOCUMENTS_COMPLETE",
  EVIDENCE_COMPLETE = "EVIDENCE_COMPLETE",
  PHOTOS_COMPLETE = "PHOTOS_COMPLETE",
  REPORTS_COMPLETE = "PHOTOS_COMPLETE"
}

export enum InvestigationOrderBy {
  LOSS_DATE = "LOSS_DATE",
  CREATED_AT = "CREATED_AT"
}

export enum InvestigationFilter {
  RISK_TYPE = "RISK_TYPE",
  PRIORITY = "PRIORITY",
  STATUS = "STATUS",
  USER = "USER",
  LOSS_STATE = "LOSS_STATE",
  IS_INCENDIARY = "IS_INCENDIARY",
  IS_ON_HOLD = "IS_ON_HOLD",
  IS_ONGOING = "IS_ONGOING",
  SERVICE_STATUS = "SERVICE_STATUS",
  IS_PROXIMAL = "IS_PROXIMAL"
}

export enum InvestigationOnSceneStatus {
  ON_SCENE = "On Scene",
  OFF_SCENE = "Off Scene",
  EN_ROUTE = "En Route",
  SCHEDULED = "Scheduled"
}

export enum InvestigationOnSceneSource {
  NEFCO_ADMIN = "NEFCO Admin",
  PHONE = "Phone"
}
