<div [dialogRef]="dialog" (appClickOutside)="onClose('closed')">
<kendo-dialog-titlebar (close)="onClose('closed')">
  <div class="modal-title">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <span class="ml-8">Review Client Details</span>
  </div>
</kendo-dialog-titlebar>
<span id="error-invalid-branch">
  <div class="notification-error" *ngIf="submitted">
    <div class="icon">
      <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i>
    </div>
    <div class="message">
      <span>Please select insurance carrier.</span>
    </div>
  </div>
</span>
<section class="section-content">
  <P>
    Please review the client details and make any edits as needed. Once the
    information has been approved, click the submit button below.
  </P>
  <form autocomplete="off" [formGroup]="form">
    <kendo-gridlayout
      [rows]="['auto']"
      [gap]="{ rows: 5, cols: 24 }"
      [cols]="['67%', '27%']"
      class="align-items-center"
      style="margin-bottom: 0"
    >
      <kendo-gridlayout-item *ngIf="!existingContactSelection">
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="email"
            text="Client Email Address"
          >
            <span class="copy-icon">
              <i
                class="fa-copy"
                [ngClass]="{
                  'fa-regular': !emailAddressCopied,
                  fa: emailAddressCopied
                }"
                (click)="copyEmailAddress()"
              ></i>
              {{ emailAddressCopied ? "Copied!" : "" }}
            </span>
          </kendo-label>
          <kendo-textbox
            #email
            formControlName="email"
            fillMode="outline"
            [clearButton]="false"
            [disabled]="true"
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="form?.controls?.email?.errors?.required"
              >Email cannot be left blank</span
            >
            <span *ngIf="form?.controls?.email?.errors?.email"
              >Invalid email address</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item *ngIf="!existingContactSelection">
        <kendo-label text=""></kendo-label>
        <button
          kendoButton
          size="large"
          themeColor="base"
          fillMode="solid"
          (click)="switchToExistingContactSearch(true)"
        >
          Find a Contact
        </button>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item *ngIf="existingContactSelection">
        <app-contact-select-kendo
          label="Search Contact"
          placeholder="Search contacts"
          [showAdditionalDetails]="true"
          [contactId]=""
          (selectionChanged)="updateContactSelection($event)"
        ></app-contact-select-kendo>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item *ngIf="existingContactSelection">
        <kendo-label text=""></kendo-label>
        <button
          kendoButton
          size="large"
          themeColor="base"
          fillMode="solid"
          (click)="switchToExistingContactSearch(false)"
        >
          Reset original
        </button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <hr />

    <h1>Company & Contact Information</h1>
    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="firstName"
            text="Name"
          ></kendo-label>
          <kendo-textbox
            #firstName
            formControlName="firstName"
            placeholder="Enter Name"
            [disabled]="existingContactSelection"
            fillMode="outline"
            [clearButton]="true"
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="form?.controls?.firstName?.errors?.required"
              >Name cannot be left blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-gridlayout
      *ngIf="!existingContactSelection"
      [rows]="['auto']"
      [gap]="{ rows: 0, cols: 24 }"
      [cols]="['47%', '47%']"
    >
      <kendo-gridlayout-item *ngIf="!existingContactSelection">
        <kendo-formfield>
          <kendo-label
            [class.asterisk]="form?.controls?.contactExtension?.value"
            [for]="contactNumber"
            text="Contact Number"
          >
            <span class="copy-icon">
              <i
                class="fa-copy"
                [ngClass]="{
                  'fa-regular': !phoneNumberCopied,
                  fa: phoneNumberCopied
                }"
                (click)="copyPhoneNumber()"
              ></i>
              {{ phoneNumberCopied ? "Copied!" : "" }}
            </span>
          </kendo-label>
          <kendo-maskedtextbox
            #contactNumber
            formControlName="contactNumber"
            [disabled]="existingContactSelection"
            fillMode="outline"
            placeholder="Enter contact number"
            mask="(000) 000-0000"
          ></kendo-maskedtextbox>
          <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="form?.controls?.contactNumber?.errors?.required"
              >Phone number cannot be left blank</span
            >
            <span
              *ngIf="
                form?.controls?.contactNumber?.errors?.patternError ||
                form?.controls?.contactNumber?.errors?.pattern
              "
              >Invalid phone number</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              [for]="contactExtension"
              text="Extension"
            ></kendo-label>
            <kendo-textbox
              #contactExtension
              formControlName="contactExtension"
              (valueChange)="
                setContactPhoneValidator('contactExtension', $event)
              "
              placeholder="Enter Extension"
              fillMode="outline"
              [clearButton]="true"
            ></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span
                *ngIf="
                  form?.controls?.ContactPhone?.controls?.contactExtension
                    ?.errors?.required
                "
                >Extension cannot be left blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="jobTitle"
            text="Job Title"
          ></kendo-label>
          <kendo-textbox
            #jobTitle
            formControlName="jobTitle"
            placeholder="Enter job title"
            [disabled]="existingContactSelection"
            [maxlength]="255"
            fillMode="outline"
            [clearButton]="false"
          ></kendo-textbox>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="form?.controls?.jobTitle?.errors?.required"
              >Job Title cannot be left blank</span
            >
            <span *ngIf="form?.controls?.jobTitle?.errors?.maxlength"
              >Job Title is allowed up to 255 characters</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label
            class="asterisk"
            [for]="companyList"
            text="Company Type"
          ></kendo-label>
          <kendo-dropdownlist
            #companyList
            [data]="companyTypes"
            textField="name"
            [disabled]="existingContactSelection"
            valueField="id"
            fillMode="outline"
            [valuePrimitive]="true"
            [defaultItem]="{ id: '', name: 'Select company type' }"
            [popupSettings]="{ appendTo: 'component', animate: false }"
            (selectionChange)="companyTypeChange($event)"
            formControlName="companyTypeId"
          >
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="!dataItem?.id" class="placeholder">
                {{ dataItem.name }}
              </span>
              <span *ngIf="dataItem?.id" class="not-placeholder">
                {{ dataItem.name }}
              </span>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="form?.controls?.companyTypeId?.errors?.required"
              >Company Type cannot be left blank</span
            >
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <ng-container
      *ngIf="showCompanyInformation"
      [formGroup]="form?.controls?.company"
    >
      <hr />
      <h3 class="page-sub-label">Enter Company Information</h3>
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="companyName"
              text="Company Name"
            ></kendo-label>
            <kendo-textbox
              #companyName
              formControlName="companyName"
              placeholder="Enter company Name"
              [disabled]="existingContactSelection"
              fillMode="outline"
              [clearButton]="true"
            ></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span
                *ngIf="
                  form?.controls?.company?.controls?.companyName?.errors
                    ?.required
                "
                >Company Name cannot be left blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="companyAddress"
              [text]="'Address'"
            ></kendo-label>
            <kendo-textbox
              #companyAddress
              formControlName="companyAddress"
              [disabled]="existingContactSelection"
              placeholder="Enter address"
              fillMode="outline"
              [clearButton]="true"
            ></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span
                *ngIf="
                  form?.controls?.company?.controls?.companyAddress?.errors
                    ?.required
                "
                >Address cannot be left blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout
        [rows]="['auto']"
        [gap]="{ rows: 5, cols: 24 }"
        [cols]="['47%', '47%']"
      >
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="companyCity"
              [text]="'City'"
            ></kendo-label>
            <kendo-textbox
              #companyCity
              formControlName="companyCity"
              [disabled]="existingContactSelection"
              placeholder="Enter city"
              fillMode="outline"
              [clearButton]="true"
            ></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span
                *ngIf="
                  form?.controls?.company?.controls?.companyCity?.errors
                    ?.required
                "
                >City cannot be left blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="companyState"
              [text]="'State'"
            ></kendo-label>
            <kendo-dropdownlist
              #companyState
              [data]="groupedDataStates"
              textField="text"
              valueField="value"
              formControlName="companyState"
              [disabled]="existingContactSelection"
              [valuePrimitive]="true"
              fillMode="outline"
              [filterable]="false"
              (filterChange)="handleFilter($event)"
              [defaultItem]="{ value: '', text: 'Select state', type: '' }"
              [popupSettings]="{ appendTo: 'component', animate: false }"
            >
              <ng-template kendoDropDownListGroupTemplate let-groupName>
                <strong>{{ groupName }}</strong>
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.value" class="placeholder">
                  {{ dataItem.text }}
                </span>
                <span *ngIf="dataItem?.value" class="not-placeholder">
                  {{ dataItem.text }}
                </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span
                *ngIf="
                  form?.controls?.company?.controls?.companyState?.errors
                    ?.required
                "
                >State cannot be left blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="companyZip"
              [text]="'Zip Code'"
            ></kendo-label>
            <kendo-textbox
              #companyZip
              formControlName="companyZip"
              [disabled]="existingContactSelection"
              placeholder="Enter zip code"
              fillMode="outline"
              [clearButton]="true"
            ></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span
                *ngIf="
                  form?.controls?.company?.controls?.companyZip?.errors
                    ?.required
                "
              >
                Zip code cannot be left blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="companyPhone"
              [text]="'Phone Number'"
            ></kendo-label>
            <kendo-maskedtextbox
              #companyPhone
              formControlName="companyPhone"
              [disabled]="existingContactSelection"
              placeholder="Enter phone number"
              fillMode="outline"
              mask="(000) 000-0000"
            ></kendo-maskedtextbox>
            <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span
                *ngIf="
                  form?.controls?.company?.controls?.companyPhone?.errors
                    ?.required
                "
              >
                Phone number cannot be left blank</span
              >
              <span
                *ngIf="
                  form?.controls?.company?.controls?.companyPhone?.errors
                    ?.patternError
                "
                >Invalid phone number</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>
    <ng-container *ngIf="showInsuranceCarrier">
      <hr />
      <h3 class="page-sub-label">Enter Insurance Carrier Information</h3>
      <kendo-gridlayout
        [rows]="['auto']"
        [gap]="{ rows: 5, cols: 24 }"
        [cols]="['67%', '27%']"
        class="align-items-center"
      >
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="insuranceCarrierName"
              text="Insurance Carrier"
            ></kendo-label>
            <kendo-textbox
              #insuranceCarrierName
              formControlName="insuranceCarrierName"
              placeholder="Enter Name"
              fillMode="outline"
              [clearButton]="true"
              [disabled]="
                existingContactSelection &&
                form.get('companyTypeText')?.value === 'Insurance Company'
              "
              clearButtonIcon="k-icon k-i-close-circle"
              (valueChange)="clearInsuredCarrier()"
            ></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span
                *ngIf="form?.controls?.insuranceCarrierName?.errors?.required"
                >Insurance Carrier cannot be left blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-label text=""></kendo-label>
          <button
            kendoButton
            size="large"
            themeColor="base"
            fillMode="solid"
            [disabled]="
              existingContactSelection &&
              form.get('companyTypeText')?.value === 'Insurance Company'
            "
            (click)="findCarrier()"
          >
            Find a Carrier
          </button>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout
        [rows]="['auto']"
        [cols]="['100%']"
        *ngIf="!isHiddenCarrier && isSearching"
      >
        <kendo-gridlayout-item *ngIf="carrierList?.length > 0">
          <kendo-listview
            [data]="carrierList"
            [height]="384"
            class="carrier-list"
            containerClass="k-d-flex k-flex-col k-flex-nowrap"
          >
            <ng-template kendoListViewHeaderTemplate>
              <div class="header">
                We have found {{ carrierList?.length }} matches
              </div>
            </ng-template>
            <ng-template
              kendoListViewItemTemplate
              let-dataItem="dataItem"
              let-isFirst="isFirst"
            >
              <div class="content-details">
                <div class="content" (click)="selectedCarrier(dataItem)">
                  {{ dataItem?.name }}, {{ dataItem?.branchName }}
                </div>
                <div class="sub-content" (click)="selectedCarrier(dataItem)">
                  {{ dataItem?.address1 ? dataItem?.address1 + "," : "" }}
                  {{ dataItem?.address2 ? dataItem?.address2 + "," : "" }}
                  {{ dataItem?.city ? dataItem?.city + "," : "" }}
                  {{
                    dataItem?.postal && dataItem?.state
                      ? dataItem?.state + " " + dataItem?.postal + ","
                      : ""
                  }}
                  {{ dataItem?.country ? dataItem?.country : "" }}
                </div>
              </div>
            </ng-template>
          </kendo-listview>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="address"
              [text]="newCarrier ? 'Carrier Address' : 'Address'"
            ></kendo-label>
            <kendo-textbox
              #address
              formControlName="address"
              [disabled]="
                existingContactSelection &&
                form.get('companyTypeText')?.value === 'Insurance Company'
              "
              placeholder="Enter address"
              fillMode="outline"
              [clearButton]="true"
            ></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.address?.errors?.required"
                >{{ newCarrier ? "Carrier address" : "Address" }} cannot be left
                blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout
        [rows]="['auto']"
        [gap]="{ rows: 5, cols: 24 }"
        [cols]="['47%', '47%']"
      >
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="city"
              [text]="newCarrier ? 'Carrier City' : 'City'"
            ></kendo-label>
            <kendo-textbox
              #city
              formControlName="city"
              placeholder="Enter city"
              [disabled]="
                existingContactSelection &&
                form.get('companyTypeText')?.value === 'Insurance Company'
              "
              fillMode="outline"
              [clearButton]="true"
            ></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.city?.errors?.required"
                >{{ newCarrier ? "Carrier city" : "City" }} cannot be left
                blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="state"
              [text]="newCarrier ? 'Carrier State' : 'State'"
            ></kendo-label>
            <kendo-dropdownlist
              #state
              [data]="groupedDataStates"
              textField="text"
              valueField="value"
              [disabled]="
                existingContactSelection &&
                form.get('companyTypeText')?.value === 'Insurance Company'
              "
              formControlName="state"
              [valuePrimitive]="true"
              fillMode="outline"
              [filterable]="false"
              (filterChange)="handleFilter($event)"
              [defaultItem]="{ value: '', text: 'Select state', type: '' }"
              [popupSettings]="{ appendTo: 'component', animate: false }"
            >
              <ng-template kendoDropDownListGroupTemplate let-groupName>
                <strong>{{ groupName }}</strong>
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.value" class="placeholder">
                  {{ dataItem.text }}
                </span>
                <span *ngIf="dataItem?.value" class="not-placeholder">
                  {{ dataItem.text }}
                </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.state?.errors?.required"
                >{{ newCarrier ? "Carrier state" : "State" }} cannot be left
                blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="zip"
              [text]="newCarrier ? 'Carrier Zip Code' : 'Zip Code'"
            ></kendo-label>
            <kendo-textbox
              #zip
              formControlName="zip"
              placeholder="Enter zip code"
              [disabled]="
                existingContactSelection &&
                form.get('companyTypeText')?.value === 'Insurance Company'
              "
              fillMode="outline"
              [clearButton]="true"
            ></kendo-textbox>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.zip?.errors?.required"
                >{{ newCarrier ? "Carrier Zip code" : "Zip code" }} cannot be
                left blank</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item *ngIf="form?.controls?.phone?.value">
          <kendo-formfield>
            <kendo-label
              class="asterisk"
              [for]="phone"
              [text]="newCarrier ? 'Carrier Contact Number' : 'Contact Number'"
            ></kendo-label>
            <kendo-maskedtextbox
              #phone
              formControlName="phone"
              [disabled]="
                existingContactSelection &&
                form.get('companyTypeText')?.value === 'Insurance Company'
              "
              placeholder="Enter contact number"
              fillMode="outline"
              mask="(000) 000-0000"
            ></kendo-maskedtextbox>
            <kendo-formhint>(ie, (xxx) xxx-xxxx)</kendo-formhint>
            <kendo-formerror>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="form?.controls?.phone?.errors?.required"
                >{{ newCarrier ? "Carrier phone" : "Phone" }} number cannot be
                left blank</span
              >
              <span *ngIf="form?.controls?.phone?.errors?.patternError"
                >Invalid contact number</span
              >
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item *ngIf="form?.controls?.extension?.value">
          <kendo-formfield>
            <kendo-label
              [for]="carrierextension"
              text="Extension"
            ></kendo-label>
            <kendo-textbox
              #carrierextension
              formControlName="extension"
              placeholder="Enter Extension"
              fillMode="outline"
              [disabled]="
                existingContactSelection &&
                form.get('companyTypeText')?.value === 'Insurance Company'
              "
              [clearButton]="false"
            ></kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>
  </form>
</section>
<kendo-dialog-actions layout="stretched">
  <div class="left">
    <a
      class="ml-16 kendo-anchor-tag"
      target="_blank"
      [routerLink]="['/admin/companies/list']"
    >
      <i
        class="fa fa-external-link"
        style="margin-right: 6px"
        aria-hidden="true"
      ></i>
      Open Companies in New Tab
    </a>
  </div>
  <div class="right" [ngClass]="{ 'flex-unset': existingContactSelection }">
    <button
      kendoButton
      [size]="'medium'"
      *ngIf="!existingContactSelection"
      themeColor="light"
      class="mr-16"
      (click)="reject()"
    >
      Reject
    </button>
    <button
      style="flex:auto"
      kendoButton
      [size]="'medium'"
      themeColor="primary"
      class="mr-16"
      (click)="approved()"
    >
      {{
        existingContactSelection
          ? "Continue with above contact"
          : "Approve and Add to Contacts"
      }}
    </button>
  </div>
</kendo-dialog-actions>
</div>