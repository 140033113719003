import { Injectable } from '@angular/core';
import { IIAmRespondingPayload } from '../../../components/portal/interfaces/portal.interfaces';
import { IAmRespondingTypes } from '../../../components/portal/enums/portal.enums';
import { PortalRequestService } from './portal-base.service';

@Injectable()
export class IAmRespondingService {
  constructor(
    private request: PortalRequestService,
    ) {}

  public twilioRequest({ phoneNumber, type}: IIAmRespondingPayload) {
    return this.request.post("/i-am-responding", { phoneNumber, type: IAmRespondingTypes.TEXT});
  }

}
