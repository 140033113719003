import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import {
  IApiEvidenceStorageLocationFilter,
  IApiAddEvidenceStorageLocationInput,
  IApiEvidenceStorageLocationConnection,
  IApiUpdateEvidenceStorageLocationInput,
  IApiEvidenceStorageLocationFilterType
} from '../../../types/types';
import {
  getEvidenceStorageLocationsQuery,
  addEvidenceStorageLocationMutation,
  removeEvidenceStorageLocationMutation,
  updateEvidenceStorageLocation,
  toggleEvidenceStorageLocationActivationMutation
} from './evidence-storage-location.operations';

@Injectable()
export class GqlEvidenceStorageLocationService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getEvidenceStorageLocations(filters: IApiEvidenceStorageLocationFilter[], options: IGqlBaseOptions): Observable<IApiEvidenceStorageLocationConnection> {
    const viewType = filters?.find(obj => obj.type === IApiEvidenceStorageLocationFilterType.ViewListView || obj.type === IApiEvidenceStorageLocationFilterType.ViewWithoutTransfers) || null;
    return this.apollo.query(connectionQuery(getEvidenceStorageLocationsQuery(viewType), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addEvidenceStorageLocation(input: IApiAddEvidenceStorageLocationInput) {
    return this.apollo.mutate({
      mutation: addEvidenceStorageLocationMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public toggleEvidenceStorageLocationActivation(id: string) {
    return this.apollo.mutate({
      mutation: toggleEvidenceStorageLocationActivationMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeEvidenceStorageLocation(id: string) {
    return this.apollo.mutate({
      mutation: removeEvidenceStorageLocationMutation,
      variables: { id}
    }).pipe(
      safeResolve(true)
    );
  }

  public updateEvidenceStorageLocation(input: IApiUpdateEvidenceStorageLocationInput) {
    return this.apollo.mutate({
      mutation: updateEvidenceStorageLocation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
