<!-- Permissions -->
<div [appEnforcePermission]="authConfig.companiesBranchesList" #companiesBranchesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companiesBranchesCreate" #companiesBranchesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsCreateConfig" #contactsCreateConfig="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contacts" #contacts="appEnforcePermission"></div>
<!-- Permissions -->

<section *ngIf="company">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}"
    [cols]="['repeat(2,minmax(0,1fr))']">
    <kendo-gridlayout-item class="mb-0">
      <h4 class="page-title" [innerHTML]="company.name"></h4>

    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="text-right mb-0">
      <button kendoButton themeColor="light" (click)="goBack()" fillMode="solid"
        [iconClass]="'fa-solid fa-arrow-left-long fa-xl'">BACK</button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <div class="content-wrapper">
    <div class="buttonContainer">
      <button kendoButton themeColor="primary" (click)="addContact()" fillMode="solid"
        *ngIf="contactsCreateConfig?.enabled" [iconClass]="'fa-solid fa-plus'">Add Contact</button>
      <button kendoButton themeColor="primary" (click)="addBranch()" class="ml-2" fillMode="solid"
        *ngIf="companiesBranchesCreate?.enabled" [iconClass]="'fa-solid fa-plus'">Add Branch</button>
    </div>
    <div class="tabs">
      <kendo-tabstrip #tabstrip [scrollable]="{ buttonScrollSpeed: 500 }" class="bookkeeping-manage-tabs">
        <kendo-tabstrip-tab [selected]="selectedTab === 0">
          <ng-template kendoTabTitle>
            <span>Company</span>
          </ng-template>
          <ng-template kendoTabContent>
            <div>
              <app-company-information [company]="company" (reload)="reload()"></app-company-information>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [selected]="selectedTab === 1" *ngIf="companiesBranchesList?.enabled">
          <ng-template kendoTabTitle>
            <span (click)="branchList?.load()">Branches</span>
          </ng-template>
          <ng-template kendoTabContent>
            <div>
              <app-company-branch-list [company]="company" #branchList></app-company-branch-list>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [selected]="selectedTab === 2" *ngIf="contacts?.enabled">
          <ng-template kendoTabTitle>
            <span (click)="contactList?.load()">Contacts</span>
          </ng-template>
          <ng-template kendoTabContent>
            <div class="contact-list-wrap">
              <app-contacts-list [config]="contactListConfig" #contactList></app-contacts-list>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </div>
</section>
