import { IApiEvidenceListDownloadByIdInput, IApiPayrollDownloadInput, IApiPhotoDownloadInput, IApiTemplateDownloadGenerateInput } from '../../../types/types';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { generateTemplateDownloadMutation, generatePhotoDownloadMutation, generateEvidenceListQuery, generateEvidenceListCSVbyId, generatePayrollListCSV, templateDataQuery } from "./investigation-template-downloads.operations";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';

@Injectable()
export class GqlInvestigationTemplateDownloadService {
  public constructor(
    private apollo: Apollo
  ) { }
  public generateTemplateDownload(templateDownloadInput: IApiTemplateDownloadGenerateInput): Observable<string> {
    return this.apollo.query({
      query: generateTemplateDownloadMutation,
      variables: { input: templateDownloadInput }
    }).pipe(
      safeResolve(true)
    );
  }
  public generatePhotoDownload(photoDownloadInput: IApiPhotoDownloadInput): Observable<string> {
    return this.apollo.query({
      query: generatePhotoDownloadMutation,
      variables: { input: photoDownloadInput }
    }).pipe(
      safeResolve(true)
    );
  }
  public generateEvidenceListCSV(investigationId: string): Observable<string> {
    return this.apollo.query({
      query: generateEvidenceListQuery,
      variables: { input: investigationId }
    }).pipe(
      safeResolve(true)
    );
  }
  public generateEvidenceListCSVbyId(templateDownloadInput: IApiEvidenceListDownloadByIdInput): Observable<string> {
    return this.apollo.query({
      query: generateEvidenceListCSVbyId,
      variables: { input: templateDownloadInput }
    }).pipe(
      safeResolve(true)
    );
  }
  public generatePayrollListCSV(templateDownloadInput: IApiPayrollDownloadInput): Observable<string> {
    return this.apollo.query({
      query: generatePayrollListCSV,
      variables: { input: templateDownloadInput }
    }).pipe(
      safeResolve(true)
    );
  }
  public templateData(): Observable<string> {
    return this.apollo.query({
      query: templateDataQuery
    }).pipe(
      safeResolve(true)
    );
  }
}
