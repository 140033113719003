<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigation" #investigationPerm="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-formfield class="kendo-formfield">
  <kendo-label [for]="" text="{{label}}"></kendo-label>
  <div>
    <kendo-multiselect 
      #multiSelect 
      [checkboxes]="true" 
      [autoClose]="false" 
      [loading]="loading" 
      [valuePrimitive]="true"
      [data]="investigations" 
      [(ngModel)]="selectedValue" 
      [rounded]="'full'" 
      (valueChange)="selectInvestigation($event)" 
      valueField="id"
      textField="name"
      [tagMapper]="tagMapper"
      [placeholder]="placeholder" 
      [fillMode]="'outline'"
      [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}" 
      (filterChange)="onSearchChange($event)"
      (focus)="onSearchFocus($event)"
      [disabled]="!investigationPerm?.enabled">
      <ng-template kendoMultiSelectItemTemplate let-dataItem>
        <span style="padding-left: 5px;">{{ dataItem?.nefcoNumber }}</span>
      </ng-template>
      <ng-template kendoMultiSelectNoDataTemplate *ngIf="!searchString?.trim()?.length">
        PLEASE SEARCH SOMETHING...
      </ng-template>
      <ng-template kendoMultiSelectNoDataTemplate *ngIf="searchString?.trim()?.length">
        NO DATA FOUND
      </ng-template>
    </kendo-multiselect>
  </div>
</kendo-formfield>
