import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  name
  Investigations {
    id
  }
  createdAt
  updatedAt
`;
const investigationPriorityFragment = `
  id
  name
  createdAt
  updatedAt
`;

export const investigationPriorityConnectionQuery = gql`${buildConnectionQuery("InvestigationPriority", "investigationPriorityConnection", investigationPriorityFragment)}`;

export const addInvestigationPriorityMutation = gql`mutation AddInvestigationPriority($input: AddInvestigationPriorityInput!) {
  addInvestigationPriority(input: $input) { ${nodeFragment} }
}`;

export const updateInvestigationPriorityMutation = gql`mutation UpdateInvestigationPriority($input: UpdateInvestigationPriorityInput!) {
  updateInvestigationPriority(input: $input) { ${nodeFragment} }
}`;

export const removeInvestigationPriorityMutation = gql`mutation RemoveInvestigationPriority($id: ID!) {
  removeInvestigationPriority(id: $id)
}`;
