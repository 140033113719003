import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IApiCertificationFilter, IApiCertificationFilterType, IApiCertificationStatusType, IApiCertificationType, IApiUserFilter } from 'src/app/shared/modules/graphql/types/types';
import { CertificationTypeService } from 'src/app/shared/services';
import { StateService } from 'src/app/shared/services/state.service';
import { IState } from 'src/app/shared/interfaces/state.interfaces';
import { groupBy } from '@progress/kendo-data-query';

// Properly declare these wih interfaces
export interface ISelectConfig {
  selectedState: string;
  selectedCert: string;
  selectedStatus: string;
  searchValue: string;
}

@UntilDestroy()
@Component({
  selector: 'app-certifications-filter',
  templateUrl: './certifications-filter.component.html',
  styleUrls: ['./certifications-filter.component.scss']
})
export class CertificationsFilterComponent implements OnInit {

  @Output() filterChange = new EventEmitter<IApiCertificationFilter | IApiUserFilter>();

  public search = "";

  private _filters: IApiCertificationFilter[] = [];

  public get filters() {
    return this._filters;
  }

  public set filters(val) {
    this._filters = val;
  }

  @Input() certView = false;

  public certificationTypes: IApiCertificationType[] = [];
  public statuses = IApiCertificationStatusType;

  public stateList: Array<IState>;
  public stateFiltered: Array<IState>;
  public groupedDataStates: any;

  constructor(
    public stateService: StateService,
    private certificationTypeService: CertificationTypeService
  ) { }

  public searchChanged() {
    // Searches by title - should it search by more than this field?
    if (this.certView) {
      this.filterChange.emit({ value: this.search, type: IApiCertificationFilterType.Title });
    } else {
      // Investigators assigned certificate search by certificateType, investigation firstName, investigation lirstName
      this.filterChange.emit({ value: (this.search || '').trim(), type: IApiCertificationFilterType.Search });
    }
  }

  public handleFilter(value) {
    this.stateFiltered = this.stateList.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.groupedDataStates = groupBy(this.stateFiltered, [
      { field: "type" },
    ]);
  }

  public stateChange(event: any) {
    this.filterChange.emit({ value: event.value, type: IApiCertificationFilterType.State });
  }

  public certificationChange(event: any) {
    this.filterChange.emit({ value: event.id, type: IApiCertificationFilterType.Type });
  }
  public statusChange(event: any) {
    this.filterChange.emit({ value: event, type: IApiCertificationFilterType.Status });
  }
  public restrictionChange({ checked }: any) {
    this.filterChange.emit({ value: checked ? "" : JSON.stringify(checked), type: IApiCertificationFilterType.Restriction });
  }

  public getCertTypes() {
    this.certificationTypeService.get().pipe(
      unwrapConnection()
    ).subscribe((data) => this.certificationTypes = data);
  }

  public load() {
    this.getCertTypes();
  }

  public ngOnInit() {
    if (!this.certView) this.getCertTypes();
    this.stateList = this.stateService.allStates;
    this.stateFiltered = this.stateService.allStates;
    this.groupedDataStates = groupBy(this.stateFiltered, [
      { field: "type" },
    ]);
  }

}
