<app-loader></app-loader>
<span
  [ngClass]="{'safe-area-padding-top-android': isAndroid, 'safe-area-padding-top-ios': isIos, 'safe-area-padding-top-web': isMobile === false}"
  *ngIf="(authService.online$ | async) === false">
  <app-kendo-alert [showIcon]="false"
    [message]="'Please check your internet connection, then try again'"></app-kendo-alert>
</span>
<ng-container *ngIf="!portal && !isMobile; else portalLayout">
  <section>
    <app-header *ngIf="portal === false"></app-header>
    <app-navigation></app-navigation>
    <section *ngIf="portal === false" class="outlet-content content-part">
      <router-outlet></router-outlet>
      <div id="dialog-div">
        <div kendoDialogContainer></div>
      </div>
    </section>
    <app-footer *ngIf="portal === false"></app-footer>
  </section>
</ng-container>
<ng-template #portalLayout>
  <router-outlet></router-outlet>
  <div id="dialog-div">
    <div kendoDialogContainer></div>
  </div>
</ng-template>