import { IApiPermissionScope } from './../../types/types';
import { PermissionScopesQuery } from './permissions.operations';

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { safeResolve } from "../../rxjs";

@Injectable()
export class GqlPermissionScopeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getPermissionScopes(): Observable<IApiPermissionScope[]> 
  {
    return this.apollo.query({
      query: PermissionScopesQuery,
    }).pipe(
      safeResolve(true),
      map(records => records.sort((a, b) => a.name < b.name ? -1 : 1))
    );
  }
}