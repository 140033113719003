export enum DocumentCategories {
  UPLOAD = "Uploads",
  DOWNLOAD = "Downloads",
  REPORT = "Reports",
  OTHER = "Other"
}

export enum DocumentStatuses {
  NOT_STARTED = "Not Started",
  DRAFT = "Draft",
  TECH_REVIEW = "Tech Review",
  EDITOR_REVIEW = "Editor Review",
  CLIENT_REVIEW = "Client Review",
  FINAL = "Final"
}

