
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiQuarterlyUtilizationReportFilter, IApiQuarterlyUtilizationReportConnection, IApiQuarterlyHourReportConnection, IApiQueryQuarterlyHourReportCsvArgs } from 'src/app/shared/modules/graphql/types/types';
import { GqlQuarterlyHourReportService } from 'src/app/shared/modules/graphql/services/reports/quarterly-hour-report/quarterly-hour-report.service';

@Injectable()
export class QuarterlyHourReportService {
  constructor(
    private gqlQuarterlyHourReportService: GqlQuarterlyHourReportService
  ) { }

  public get(filters?: IApiQuarterlyUtilizationReportFilter[], options?: IGqlBaseOptions): Observable<IApiQuarterlyHourReportConnection> {
    return this.gqlQuarterlyHourReportService.get(filters, options || new ListPage());
  }

  public csvReport(input: IApiQueryQuarterlyHourReportCsvArgs): Observable<string> {
    return this.gqlQuarterlyHourReportService.csvReport(input);
  }

}
