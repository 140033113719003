import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlInvestigationStaffService } from 'src/app/shared/modules/graphql/services';
import { IApiInvestigationStaffConnection, IApiAssignInvestigatorInput, IApiInvestigationStaff, IApiSendSmsToInvestigator, IApiSendSmsToInvestigatorInput } from '../../../modules/graphql/types/types';
import {
  IApiAddInvestigationStaffInput,
  IApiInvestigationStaffFilter,
  IApiUpdateInvestigationStaffInput
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";


@Injectable()
export class InvestigationStaffService {

  constructor(
    private gqlInvestigationStaffService: GqlInvestigationStaffService
  ) { }

  public get(filters?: IApiInvestigationStaffFilter[], options?: IGqlBaseOptions): Observable<IApiInvestigationStaffConnection> {
    return this.gqlInvestigationStaffService.getInvestigationStaff(filters, options || new ListPage());
  }

  public add(input: IApiAddInvestigationStaffInput): Observable<IApiInvestigationStaff> {
    return this.gqlInvestigationStaffService.addInvestigationStaff(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlInvestigationStaffService.removeInvestigationStaff(id);
  }

  public update(input: IApiUpdateInvestigationStaffInput): Observable<IApiInvestigationStaff> {
    return this.gqlInvestigationStaffService.updateInvestigationStaff(input);
  }

  public assignInvestigator(input: IApiAssignInvestigatorInput) {
    return this.gqlInvestigationStaffService.assignInvestigator(input);
  }

  public sendSMSToInvestigator(input: IApiSendSmsToInvestigatorInput): Observable<IApiSendSmsToInvestigator> {
    return this.gqlInvestigationStaffService.sendSMSToInvestigator(input);
  }

}
