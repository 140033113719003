import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IApiAddCompanyTypeInput, IApiCompaniesConnection, IApiCompanyType, IApiCompanyTypeConnection, IApiCompanyTypeFilter, IApiUpdateCompanyTypeInput } from '../../../modules/graphql/types/types';
import { IGqlBaseOptions } from '../../../modules/graphql/interfaces/base.interfaces';
import { GqlCompanyTypeService } from 'src/app/shared/modules/graphql/services';

@Injectable()
export class CompanyTypeService {

  constructor(
    private companyTypeService: GqlCompanyTypeService
  ) {}

  public get(filters: IApiCompanyTypeFilter[] = [], options?: IGqlBaseOptions): Observable<IApiCompanyTypeConnection> {
    return this.companyTypeService.getCompanyTypes(filters, options || new ListPage(-1));
  }

  // public getById(id: string): Observable<IApiCompanyType> {
  //   return this.companyTypeService.getCompanyTypeById(id);
  // }

  public add(input: IApiAddCompanyTypeInput): Observable<IApiCompanyType> {
    return this.companyTypeService.addCompanyType(input);
  }

  public update(input: IApiUpdateCompanyTypeInput): Observable<IApiCompanyType> {
    return this.companyTypeService.updateCompanyType(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.companyTypeService.removeCompanyType(id);
  }

}
