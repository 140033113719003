import { ApiConnection } from '../../decorators/@ApiConnection.decorator';
import { UserNotificationService } from './user-notification.service';
import { DataSource } from "@angular/cdk/collections";
import { IApiNotification, IApiNotificationFilter, IApiNotificationsConnection } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';

export class UserNotificationDataSource extends NefcoPagedDataSource<IApiNotification, UserNotificationService, IApiNotificationsConnection> implements DataSource<IApiNotification> {
  @ApiConnection()
  public load(filters?: IApiNotificationFilter[]): Promise<IApiNotificationsConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
