import { connectionQuery } from './../../../helpers.class';
import { IApiAddCompanyTypeInput, IApiCompanyType, IApiCompanyTypeConnection, IApiCompanyTypeFilter, IApiUpdateCompanyTypeInput } from './../../../types/types';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from '../../../interfaces/base.interfaces';
import { safeResolve } from "../../../rxjs";
import { companyTypeConnectionQuery, removeCompanyTypeMutation } from './company-type.operations';
import { addCompanyMutation, updateCompanyMutation } from '../company.operations';

@Injectable()
export class GqlCompanyTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getCompanyTypes(filters: IApiCompanyTypeFilter[], options: IGqlBaseOptions): Observable<IApiCompanyTypeConnection> {
    return this.apollo.query(connectionQuery(companyTypeConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addCompanyType(input: IApiAddCompanyTypeInput): Observable<IApiCompanyType> {
    return this.apollo.mutate({
      mutation: addCompanyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true),
    );
  }

  public updateCompanyType(input: IApiUpdateCompanyTypeInput): Observable<IApiCompanyType> {
    return this.apollo.mutate({
      mutation: updateCompanyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true),
    );
  }

  public removeCompanyType(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeCompanyTypeMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }

}
