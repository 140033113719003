import { ErrorHandler } from '@angular/core';
import { Notifier } from '@airbrake/browser';
import { environment } from 'src/environments/environment';

export class AirbrakeErrorHandler implements ErrorHandler {
  airbrake: Notifier;

  constructor() {
    this.airbrake = new Notifier({
      projectId: environment.AirBreakProjectId || null,
      projectKey: environment.AirBreakProjectAPIKey || '',
      environment: environment.env || 'LOCAL',
    });
  }

  handleError(error: any): void {
    if (environment.env == 'PROD') {
      this.airbrake.notify(error);
    } else {
      // log errors in console if any
      console.error(error);
    }
  }
}
