import { connectionQuery } from './../../../helpers.class';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { updateEvidenceBillingFrequencyMutation } from './evidence-billing-frequency.operations';
import {
  IApiEvidenceBillingFrequencyFilter,
  IApiAddEvidenceBillingFrequencyInput,
  IApiEvidenceBillingFrequencyConnection,
  IApiUpdateEvidenceBillingFrequencyInput,
  IApiRemoveEvidenceBillingFrequencyInput
} from 'src/app/shared/modules/graphql/types/types';
import {
  evidenceBillingFrequencyConnectionQuery,
  addEvidenceBillingFrequencyMutation,
  removeEvidenceBillingFrequencyMutation
} from './evidence-billing-frequency.operations';

@Injectable()
export class GqlEvidenceBillingFrequencyService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getEvidenceBillingFrequency(filters: IApiEvidenceBillingFrequencyFilter[], options: IGqlBaseOptions): Observable<IApiEvidenceBillingFrequencyConnection> {
    return this.apollo.query(connectionQuery(evidenceBillingFrequencyConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addEvidenceBillingFrequency(input: IApiAddEvidenceBillingFrequencyInput) {
    return this.apollo.mutate({
      mutation: addEvidenceBillingFrequencyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeEvidenceBillingFrequency(input: IApiRemoveEvidenceBillingFrequencyInput) {
    return this.apollo.mutate({
      mutation: removeEvidenceBillingFrequencyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateEvidenceBillingFrequency(input: IApiUpdateEvidenceBillingFrequencyInput) {
    return this.apollo.mutate({
      mutation: updateEvidenceBillingFrequencyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
