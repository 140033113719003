import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { GqlEmailTypeService } from 'src/app/shared/modules/graphql/services';
import { IApiEmailTypeFilter } from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "src/app/shared/helpers/list-page.class";

@Injectable()
export class EmailTypeService {

  constructor(
    private emailTypes: GqlEmailTypeService,
  ) { }

  public get(filters: IApiEmailTypeFilter[] = [], options?: IGqlBaseOptions) {
    return this.emailTypes.getEmailTypes(filters, options || new ListPage());
  }

}
