<section class="p-3">
  <p>This page will fetch a random investigation and display the information available for templating in Documents and Reports</p>
  <p>You can use the below options as filters on data in the templates</p>
  <ul>
    <li>upper: Converts input to uppercase</li>
    <li>lower: Converts input to lowercase</li>
    <li>date: Converts date to formatted string. Defaults to MM/DD/YYYY</li>
    <li>dateTime: Converts  to formatted string. Defaults to MM/DD/YYYY hh:mm a</li>
    <li>time: Converts date to formatted string. Defaults to hh:mm a</li>
    <li>address: Received an address object and returns it formatted with all available data</li>
    <li>index: Selects a specific index of a list of items in an array</li>
    <li>primary: Finds the item marked as primary in an array, if none are found, defaults to the first item in the list</li>
  </ul>
  <mat-divider class="my-5"></mat-divider>
  <pre>{{ investigation }}</pre>
</section>