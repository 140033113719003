import { AfterViewInit, EventEmitter, Inject, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { IApiAddAddressInput, IApiAddBranchInput, IApiAddEmailInput, IApiAddPhoneInput, IApiAddress, IApiBranch, IApiEmail, IApiPhone, IApiUpdateBranchInput } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { BranchService } from 'src/app/shared/services';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

export interface ICompanyBranchConfig {
  branch?: IApiBranch;
  hideModalContent: boolean;
}

export interface ICompanyBranchCreateUpdateData {
  branchId?: string;
  companyId?: string;
  companyName?: string;
}

interface IAddContactInformation {
  emails: IApiAddEmailInput[];
  phones: IApiAddPhoneInput[];
  addresses: IApiAddAddressInput[];
}

@UntilDestroy()
@Component({
  selector: 'app-company-branch-create-modal',
  templateUrl: './company-branch-create-modal.component.html',
  styleUrls: ['./company-branch-create-modal.component.scss']
})
export class CompanyBranchCreateModalComponent extends DialogContentBase implements OnInit, AfterViewInit, OnChanges {

  @ViewChild("branchForm", { static: false }) branchForm: NgForm;
  @Input() data: ICompanyBranchCreateUpdateData;

  @Input() config: ICompanyBranchConfig;

  @Output() branchChange: EventEmitter<IApiUpdateBranchInput | IApiAddBranchInput> = new EventEmitter();

  public branch: IApiBranch = {
    id: null,
    name: '',
    Phones: [],
    Emails: [],
    Addresses: [],
    isPrimary: false,
    additionalInformation: ""
  };

  constructor(
    private branchService: BranchService,
    public dialog: DialogRef,
    private loader: LoaderService,
    private notifications: NotificationsService
  ) {
    super(dialog);
  }

  public emitBranchChange() {
    this.branchChange.emit(this.branch.id ? this.branchUpdateObject : this.branchCreateObject);
  }

  public isPrimaryToggle(isPrimary: boolean, index: number) {
    if (isPrimary) {
      this.branch.Phones.forEach(obj => obj.isPrimary = false);
      this.branch.Phones[index].isPrimary = true;
    }
  }

  public ngOnInit() {
    this.addAddress();
    if (this.data?.branchId) {
      this.branchService.getById(this.data.branchId).subscribe((branch) => this.branch = branch);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.config?.currentValue?.branch) {
      this.branch = changes.config.currentValue.branch;
    }
  }

  public ngAfterViewInit() {
    this.branchForm.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(({ branchName, additionalInformation }) => {
      // Need to set these hear because ngModel is lagging behind
      this.branch.name = branchName;
      this.branch.additionalInformation = additionalInformation || this.branch.additionalInformation;
      this.emitBranchChange();
    });
  }

  public addPhone() {
    this.branch.Phones.push({
      id: null,
      isPrimary: !this.branch.Phones.length,
      extension: "",
      number: null,
      Type: {
        id: null,
      },
      TypeId: null
    });
  }

  public addEmail() {
    this.branch.Emails.push({
      id: null,
      address: null,
      isPrimary: false,
      Type: {
        id: null
      },
      TypeId: null
    });
  }

  public addAddress() {
    this.branch.Addresses.push({
      id: null,
      address1: null,
      address2: null,
      address3: null,
      city: null,
      postal: null,
      country: "United States",
      Type: {
        id: null
      }
    });
  }

  public updateAddress(event, i) {
    this.branch.Addresses[i] = event;
    this.emitBranchChange();
  }

  public removePhone(index: number) {
    this.branch.Phones.splice(index, 1);
    this.emitBranchChange();
  }
  public removeEmail(index: number) {
    this.branch.Emails.splice(index, 1);
    this.emitBranchChange();
  }
  public removeAddress(index: number) {
    this.branch.Addresses.splice(index, 1);
    this.emitBranchChange();
  }

  private compileContactInformation(copy: IApiBranch): IAddContactInformation {
    const emails: IApiAddEmailInput[] = copy.Emails.map((v: IApiEmail) => ({ TypeId: v.Type.id, isPrimary: v.isPrimary, address: v.address }));
    const phones: IApiAddPhoneInput[] = copy.Phones.map((v: IApiPhone) => ({ TypeId: v.Type.id, isPrimary: v.isPrimary, number: v.number, extension: v.extension }));
    const addresses: IApiAddAddressInput[] = copy.Addresses.map((v: IApiAddress) => {
      delete v.Type;
      delete v.addressableType;
      delete v.addressableTypeId;
      delete v.__typename;
      delete v.id;
      return {
        ...v,
      } as IApiAddAddressInput;
    });

    return {
      emails,
      phones,
      addresses
    };
  }

  private createBranch(): Observable<any> {

    return this.loader.show$(
      this.branchService.add(this.branchCreateObject).pipe(
        this.notifications.catchAlertPipe('Please fill all valid fields')
      )
    );
  }

  private get branchUpdateObject() {
    const copy: IApiBranch = cloneDeep(this.branch);

    const { emails, phones, addresses } = this.compileContactInformation(copy);

    delete copy.__typename;
    delete copy.ContactAssignments;

    const payload: IApiUpdateBranchInput = {
      id: copy.id,
      name: copy.name,
      additionalInformation: copy.additionalInformation,
      Emails: emails,
      Phones: phones,
      Addresses: addresses,
    };
    return payload;
  }

  private get branchCreateObject() {
    const copy = cloneDeep(this.branch);

    const { emails, phones, addresses } = this.compileContactInformation(copy);

    const payload: IApiAddBranchInput = {
      name: copy.name,
      additionalInformation: copy.additionalInformation,
      CompanyId: this.data?.companyId || null,
      Addresses: addresses,
      Emails: emails,
      Phones: phones,

    };
    return payload;
  }

  private updateBranch(): Observable<any> {

    return this.loader.show$(
      this.branchService.update(this.branchUpdateObject)
    );
  }

  public save() {
    if (this.data && this.data.branchId) {
      this.updateBranch().subscribe(() => this.close(true));
    } else {
      this.createBranch().subscribe(() => this.close(true));
    }
  }

  public close(val: boolean = false) {
    this.dialog.close(val);
  }

}
