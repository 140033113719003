<div class="p-16">
  <button kendoButton themeColor="base" fillMode="solid" [iconClass]="'fa-solid fa-sliders'" style="width: 100%;"
    (click)="drawerFilter.toggle(true); addBodyClass(true)">Filters</button>
</div>
<kendo-drawer #drawerFilter [mini]="false" [mode]="'overlay'" [animation]="true">
  <ng-template kendoDrawerTemplate>
    <section class="content-part safe-area-padding-top">
      <div class="header mb-16">
        <h6>Filters</h6>
        <button class="close-action" kendoButton themeColor="primary" fillMode="link"
          [iconClass]="drawerFilter.expanded && 'fa-solid fa-times fa-xl'"
          (click)="drawerFilter.toggle(); addBodyClass(false)"></button>
      </div>

      <hr class="divider-line mb-16" />

      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 0, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']" 
      style="margin-bottom: 162px;">
        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label text="Recived Date (Start)"></kendo-label>
            <kendo-datepicker id="startDate" name="startDate" calendarType="classic" [fillMode]="'outline'"
              placeholder="Start date" format="MM/dd/yyyy" (valueChange)="customRangeChange()" [max]="range.endDate"
              [ngClass]="{'ng-invalid ng-touched': (!startDate?.valid && (startDate?.dirty || startDate?.touched))}"
              [(ngModel)]="range.startDate" formatPlaceholder="formatPattern" required="true"
              #startDate="ngModel"></kendo-datepicker>
            <kendo-formerror *ngIf="!startDate?.valid && (startDate?.dirty || startDate?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="startDate?.errors?.required">
                Start date cannot be left blank
              </span>
              <span *ngIf="startDate?.errors?.minError">
                Invalid Start date
              </span>
              <span *ngIf="startDate?.errors?.maxError">
                Start date should be less than end date
              </span>
            </kendo-formerror>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label text="Recived Date (End)"></kendo-label>
            <kendo-datepicker id="endDate" name="endDate" calendarType="classic" [fillMode]="'outline'"
              placeholder="End date" [min]="range.startDate" (valueChange)="customRangeChange()"
              [ngClass]="{'ng-invalid ng-touched': !endDate?.valid && (endDate?.dirty || endDate?.touched)}"
              format="MM/dd/yyyy" [(ngModel)]="range.endDate" formatPlaceholder="formatPattern" required="true"
              #endDate="ngModel"></kendo-datepicker>
            <kendo-formerror *ngIf="!endDate?.valid && (endDate?.dirty || endDate?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="endDate?.errors?.required">
                End date cannot be left blank
              </span>
              <span *ngIf="endDate?.errors?.minError">
                End date should be greater than start date
              </span>
              <span *ngIf="endDate?.errors?.maxError">
                Invalid End date
              </span>
            </kendo-formerror>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-company-select-kendo label="Client (Company)" placeholder="Search Client (Company)"
            [selectedId]="filterByIdValue(filterTypes.Client)"
            (selectionChanged)="setFilters($event, filterTypes.Client)"></app-company-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-contact-multi-select-kendo placeholder="Select contact" label="Client (Contact)"
            [selectedId]="filterByIdValue(filterTypes.ClientContact)"
            (selectionChanged)="setFilters($event, filterTypes.ClientContact)">
          </app-contact-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-state-multi-select-kendo placeholder="Select state" label="Loss State"
            [selectedId]="filterByIdValue(filterTypes.LossState)"
            (selectionChanged)="setFilters($event, filterTypes.LossState)">
          </app-state-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-company-select-kendo label="Bill To (Company)" placeholder="Select bill to"
            [selectedId]="filterByIdValue(filterTypes.BillToCompany)"
            (selectionChanged)="setFilters($event, filterTypes.BillToCompany)"></app-company-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-user-multi-select-kendo label="Investigator" [userViewFilter]="userViewFilter"
            placeholder="Select investigator" [selectedId]="filterByIdValue(filterTypes.Investigator)"
            [investigationRole]="userRoleName.Investigator" [includeInactive]="true"
            (selectionChanged)="setFilters($event, filterTypes.Investigator)"></app-user-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-user-multi-select-kendo label="Tech Reviewer" [userViewFilter]="userViewFilter"
            placeholder="Select reviewer" [selectedId]="filterByIdValue(filterTypes.TechReviewer)"
            [investigationRole]="userRoleName.TechReviewer" [includeInactive]="true"
            (selectionChanged)="setFilters($event, filterTypes.TechReviewer)"></app-user-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-causes-multi-select-kendo placeholder="Select cause" label="Cause of Fire"
            [selectedId]="filterByIdValue(filterTypes.CauseOfFire)"
            (selectionChanged)="setFilters($event, filterTypes.CauseOfFire)">
          </app-causes-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-risk-type-multi-select-kendo placeholder="Select type" label="Risk Type"
            [selectedId]="filterByIdValue(filterTypes.RiskType)"
            (selectionChanged)="setFilters($event, filterTypes.RiskType)">
          </app-risk-type-multi-select-kendo>
        </kendo-gridlayout-item>


        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label text="View By"></kendo-label>
            <kendo-dropdownlist
              [data]="[{text: 'Active Investigations', value: 'ACTIVE'}, {text: 'Closed Investigations', value: 'CLOSED'}]"
              textField="text" valueField="value" fillMode="outline" [valuePrimitive]="true"
              [defaultItem]="{text: 'All Investigations', value: null }" [value]="filterValue(filterTypes.ViewBy)"
              [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
              (selectionChange)="setFilters($event?.value, filterTypes.ViewBy)">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.text }} </span>
                <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.text }} </span>
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout class="pl-16 pr-16 text-center" [rows]="['auto']" [gap]="{rows: 16, cols: 16}"
        [cols]="['repeat(1,minmax(0,1fr))']" style="position: absolute; left:0; right:0; bottom: 0;z-index: 1; background: white">

        <hr class="divider-line mb-24" />

        <kendo-gridlayout-item class="mb-0">
          <kendo-button kendoButton themeColor="primary" fillMode="solid" size="large"
            (click)="appliedFilters(); drawerFilter.toggle(); addBodyClass(false)" style="width: 100%;">
            Apply Filters
          </kendo-button>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="mb-24">
          <kendo-button kendoButton themeColor="primary" fillMode="link" size="large"
            (click)="clearAll(); drawerFilter.toggle(false); addBodyClass(false)" style="width: 100%;">
            Clear All
          </kendo-button>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

    </section>
  </ng-template>
</kendo-drawer>