import { connectionQuery } from './../../../helpers.class';
import { IApiDocumentTypeConnection, IApiDocumentTypeFilterType } from './../../../types/types';
import { IApiUpdateDocumentTypeInput, IApiAddDocumentTypeInput, IApiDocumentTypeFilter } from '../../../types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import { IApiDocumentType } from "../../../types/types";
import { IGqlBaseOptions } from "../../../interfaces";

import { documentTypeConnectionQuery, addDocumentTypeMutation, updateDocumentTypeMutation, removeDocumentTypeMutation } from "./document-type.operations";
import { safeResolve } from "../../../rxjs";

@Injectable()
export class GqlDocumentTypeService {
  public constructor(
    private apollo: Apollo
  ) { }

  public getTypes(filters: IApiDocumentTypeFilter[], options: IGqlBaseOptions): Observable<IApiDocumentTypeConnection> {
    const view = filters?.find(filter => filter.type === IApiDocumentTypeFilterType.ViewListName);
    return this.apollo.query(connectionQuery(documentTypeConnectionQuery(false, view), filters, options)).pipe(
      safeResolve(true)
    );
  }

  public addType(input: IApiAddDocumentTypeInput): Observable<IApiDocumentType> {
    return this.apollo.mutate({
      mutation: addDocumentTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public updateType(input: IApiUpdateDocumentTypeInput): Observable<IApiDocumentType> {
    return this.apollo.mutate({
      mutation: updateDocumentTypeMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public removeType(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: removeDocumentTypeMutation,
      variables: { id }
    }).pipe(
      safeResolve(true)
    );
  }
}
