import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { DocumentService, DocumentTypeService, RequestService, RequestStatusService, RequestTypeService } from 'src/app/shared/services';
import { IApiAddRequestInput, IApiAddress, IApiInvestigation, IApiRequestType, IApiRequestStatusNames, IApiAddRequestStatusInput, IApiRequest, IApiDocument, IApiDocumentType, IApiDocumentTypeOrderBy, IApiDocumentFilterType, IApiDocumentOrderBy, IApiUploadTypes, IApiDocumentTypeFilterType } from 'src/app/shared/modules/graphql/types/types';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { DocumentCategories } from 'src/app/shared/modules/graphql/constants/document.constants';
import { DialogContentBase, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FileSelectModalComponent } from 'src/app/shared/components/file-select-modal/file-select-modal.component';
import { csvMimeTypes, excelMimeTypes, imageMimeType, wordMimeTypes } from 'src/app/shared/helpers/helper';
import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';

export interface IInvestigationRequestModalData {
  id?: string;
  InvestigationId?: string;
  authorizedBy?: string;
  notes?: string;
  Address?: IApiAddress;
  RequestTypeId?: string;
  investigation?: IApiInvestigation;
}

@UntilDestroy()
@Component({
  templateUrl: './investigation-request-modal.component.html',
  styleUrls: ['./investigation-request-modal.component.scss']
})
export class InvestigationRequestModalComponent extends DialogContentBase implements OnInit {

  @ViewChild('requestForm', { static: false }) requestForm: NgForm;
  @ViewChild('requestStatusForm', { static: false }) requestStatusForm: NgForm;

  @Input() data: IApiRequest;

  // Creating a new Request
  private _request: IApiAddRequestInput = {
    InvestigationId: null,
    RequestTypeId: null,
    Address: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      postal: null,
      country: "United States"
    },
    notes: null,
    authorizedBy: null
  };

  public get request() {
    return this._request;
  }

  public set request(val) {
    this._request = val;
  }

  public get requiresAddress() {
    if (!this._request.RequestTypeId) return false;
    else return this.requestTypes?.find((r) => r.id === this._request.RequestTypeId)?.name?.toLowerCase().includes("evidence retention letter");
  }

  public requestTypes: IApiRequestType[];
  private _requestStatus: IApiAddRequestStatusInput = {
    name: null,
    comment: null,
    RequestId: null
  };

  public get requestStatus() {
    return this._requestStatus;
  }

  public set requestStatus(val) {
    this._requestStatus = val;
  }

  public uploadTypes: IApiDocumentType[] = [];
  public requestStatusNames = IApiRequestStatusNames;
  public documents: IApiDocument[];
  public docModel = {
    documentTypeId: null,
    documentTitle: '',
    s3Doc: {
      key: '',
      filename: ''
    }
  }
  constructor(
    private requestTypeService: RequestTypeService,
    private requestSatusService: RequestStatusService,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private documentTypeService: DocumentTypeService,
    private documentService: DocumentService,
    private dialogService: DialogService,
    public dialog: DialogRef
  ) {
    super(dialog);
  }

  public ngOnInit(): void {
    if (this.data?.id) {
      // Receiving a request to update
      this.requestStatus.name = this.data?.Status;
    } else {
      // receiving an investigation that we are adding an investigation to
      this.request.InvestigationId = this.data.Investigation.id; 
    }
    this.loader.show$(forkJoin([
      this.getDocumentType(),
      this.requestType(),
      this.getDocuments()
    ])).subscribe(([documentTypes, requestTypes, documents]) => {  
      this.uploadTypes = documentTypes;
      this.requestTypes = requestTypes;
      this.documents = documents;

    });
  }

  public getDocumentType() {
    return this.documentTypeService.get([{ type: IApiDocumentTypeFilterType.Categories, value: JSON.stringify([DocumentCategories.UPLOAD.valueOf()]) }], {
      sortOrder: SortOrder.ASC,
      orderBy: IApiDocumentTypeOrderBy.Name,
      limit: 1000
    }).pipe(
      unwrapConnection(),
      map<IApiDocumentType[], IApiDocumentType[]>(types => types.sort((a, b) => a.name < b.name ? -1 : 1))
    );
  }

  public requestType() {
    return this.requestTypeService.list([], {}).pipe(
      filter(r => !!r),
    );
  }

  public getDocuments() {
    return this.documentService.get([{
      type: IApiDocumentFilterType.Investigation,
      value: this.data.Investigation?.id
    }], { orderBy: IApiDocumentOrderBy.Title, sortOrder: SortOrder.ASC, limit: -1 }).pipe(
      unwrapConnection()
    );
  }

  public fileSelector() {
    const dialog: DialogRef = this.dialogService.open({
      content: FileSelectModalComponent,
      width: 600,
      maxWidth: 600,
      maxHeight: 670,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as FileSelectModalComponent;
    dialogInstance.data = {
      InvestigationId: this.data.Investigation?.id,
      FolderId: null,
      type: IApiUploadTypes.Documents,
      restrictions: [...wordMimeTypes, ...excelMimeTypes, ...csvMimeTypes, ...imageMimeType],
      multiple: false
    };
    dialog.result.subscribe((res: any) => {
      if (res?.length && res) {
        this.docModel = {
          ...this.docModel,
          s3Doc: {
            key: res[0].key,
            filename: res[0].file.name || ''
          }
        };
      }
    });
  }
  
  public removeDocument() {
    this.notifications.kendoConfirm(
      "Are you sure you want to remove this document?",
      "Are you sure you want to remove?",
      "No",
      "Yes"
    ).pipe(
        filter((v) => !!v),
      ) .subscribe(() => {
        this.docModel = {
          ...this.docModel,
          s3Doc: {
            key: '',
            filename: ''
          }
        };
      });
  }

  public autofillDocTitleChange(event: any) {
    if (event) {
      const documentName = this.uploadTypes.find(item => item.id === event)?.name || '';
      const docNum = this.documents.filter((doc) => doc?.Type.name === documentName).length + 1;
      this.docModel.documentTitle = `${documentName} ${docNum}`;;
    } else {
      this.docModel.documentTitle = '';
    }
  }

  public async save() {
    if (this.data?.id) {
      if (this.requestStatusForm?.invalid) {
        this.requestStatusForm.form.markAllAsTouched();
        return;
      };
      
      if (this.docModel?.s3Doc?.key && this.docModel?.s3Doc?.filename) {
        await this.documentService.add({
          uri: '',
          s3Uri: this.docModel?.s3Doc?.key,
          title: this.docModel.documentTitle || "",
          caption: this.docModel.documentTitle || "",
          InvestigationId: this.data.Investigation?.id,
          TypeId: this.docModel?.documentTypeId
        }).toPromise();
      };
  
      const input = { 
        RequestId: this.data.id,
        name: this.requestStatus.name,
        comment: this.requestStatus.comment
      };
  
      this.loader.show$(this.requestSatusService.add(
        input as IApiAddRequestStatusInput
      )).pipe(
        this.notifications.snackbarPipe("Request status was updated successfully"),
        this.notifications.catchAlertPipe("Error updating status request; please try again")
      ).subscribe((res) => {
        this.dialog.close(res);
      });
    } else {
      if (this.requestForm?.invalid) {
        this.requestForm.form.markAllAsTouched();
        return;
      };

      if (!this.requiresAddress) delete this._request.Address;
      this.dialog.close(this._request as IApiAddRequestInput);
    }
  }

  public close() {
    this.dialog.close();
  }
}


