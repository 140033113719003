import { Component, OnInit, ViewChild } from "@angular/core";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
// import { employees } from "./employees";
// import { images } from "./images";

@Component({
  selector: "app-typo-graphy",
  templateUrl: "./typo-graphy.component.html",
  styleUrls: ["./typo-graphy.component.scss"],
})
export class TypoGraphyComponent implements OnInit {
  constructor() {}

  public areaList: Array<string> = [
    "Boston",
    "Chicago",
    "Houston",
    "Los Angeles",
    "Miami",
    "New York",
    "Philadelphia",
    "San Francisco",
    "Seattle",
  ];
  public onTabSelect(e: SelectEvent): void {
    console.log(e);
  }
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  public gridData: unknown[] = employees;
  public gridView: unknown[];


  public ngOnInit(): void {
    this.gridView = this.gridData;
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;

    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
         
          {
            field: "date",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "idd",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "full_name",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "job_title",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "location",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "assigned",
            operator: "contains",
            value: inputValue,
          },
         
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }
  public itemSelected(event, dataItem) {
    switch (event?.text) {
      case 'Indicate Cause':
        
        break;
      case 'Send Back':
        
        break;
      case 'Approve as Final':
        
        break;
      case 'Approve':
        
        break;
      case 'Unassign':
        
        break;
      default:
        break;
    }
  }


}
export const employees = [
  {
    id: "848e6002-8a92-447d-951b-1ffd5e695578",
    date: "03/22/2023",
    idd: "List Item",
    full_name: "Sig Jeannel",
    location: "1234 Main Street Avenue Chicago, IL 60614",
    assigned: "Anthony Thompson",
    "is_online":true, 
  },
  {
    id: "848e6002-8a92-447d-951b-1ffd5e695578",
    date: "03/22/2023",
    idd: "List Item",
    full_name: "Sig Jeannel",
    location: "1234 Main Street Avenue Chicago, IL 60614",
    assigned: "Anthony Thompson",
    "is_online":false, 
  },
  {
    id: "848e6002-8a92-447d-951b-1ffd5e695578",
    date: "03/22/2023",
    idd: "List Item",
    full_name: "Sig Jeannel",
    location: "1234 Main Street Avenue Chicago, IL 60614",
    assigned: "Anthony Thompson",
    "is_online":true,   
  },
  {
    id: "848e6002-8a92-447d-951b-1ffd5e695578",
    date: "03/22/2023",
    idd: "List Item",
    full_name: "Sig Jeannel",
    location: "1234 Main Street Avenue Chicago, IL 60614",
    assigned: "Anthony Thompson",
    "is_online":true,   
  },

  
]