<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageDocumentTypesCreate" #manageDocumentTypesCreate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.manageDocumentTypesUpdate" #manageDocumentTypesUpdate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.manageDocumentTypesDelete" #manageDocumentTypesDelete="appEnforcePermission">
</div>
<!-- Permissions -->
<!-- Screen 38.1 -->
<section>
  <div class="page-header">
    <h4 class="mb-0 page-title">Manage Document Types</h4>
    <div *ngIf="manageDocumentTypesCreate?.enabled">
      <kendo-button kendoButton [themeColor]="'primary'" fillMode="solid" iconClass="fas fa-plus" (click)="edit()"> New
        Document Type</kendo-button>
    </div>
  </div>
</section>
<kendo-grid class="document-type-list-grid" [data]="{
  data: documentTypeList,
  total: (dataSource?.pageInfo$ | async)?.totalCount
}" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="{
  buttonCount: 6,
  type: 'numeric',
  pageSizes: [5, 10, 25, 100],
  previousNext: true,
  responsive: true,
  position: 'bottom'
}" (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)">
  <kendo-grid-column field="NAME" title="Document Type" [width]="35" class="id-column" [sortable]="true"
    class="font-weight600" [headerClass]="'sort-header'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <a (click)="edit(dataItem)" appEventLink class="text-decoration word-break"
        *ngIf="manageDocumentTypesUpdate?.enabled else onlyName">
        {{dataItem?.name}}
      </a>
      <ng-template #onlyName>
        <span [innerHTML]="dataItem?.name"></span>
      </ng-template>
    </ng-template>
  </kendo-grid-column>
  <!-- Category -->
  <kendo-grid-column field="CATEGORY" title="Category" [width]="25" class="id-column" [sortable]="true"
    [headerClass]="'sort-header'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [innerHTML]="dataItem?.Category?.name"></span>
    </ng-template>
  </kendo-grid-column>
  <!-- Default Title  -->
  <kendo-grid-column field="DEFAULT_TITLE" title="Default Title" [width]="35" class="id-column" [sortable]="false"
    [headerClass]="'nocursor'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [innerHTML]="dataItem?.defaultTitle"></span>
    </ng-template>
  </kendo-grid-column>

  <!-- Action -->
  <kendo-grid-column field="id" title="Actions" [width]="10" [sortable]="false" [headerClass]="'nocursor'"
    *ngIf="manageDocumentTypesUpdate?.enabled || manageDocumentTypesDelete?.enabled">
    <ng-template kendoGridCellTemplate let-dataItem>
      <kendo-dropdownbutton [data]="(manageDocumentTypesUpdate?.enabled && manageDocumentTypesDelete?.enabled)
    ? [{ text: 'Edit' }, { text: 'Delete' }] : manageDocumentTypesUpdate?.enabled
    ? [{ text: 'Edit' }] : manageDocumentTypesDelete?.enabled
    ? [{ text: 'Delete' }] : []" themeColor="primary" fillMode="link" buttonClass="grid-action-items"
        iconClass="fa-solid fa-ellipsis" (itemClick)="itemSelected($event, dataItem)" [popupSettings]="{
        appendTo: 'component',
        animate: false,
        width: 250,
        popupClass: 'grid-action-items-menu'
      }">
      </kendo-dropdownbutton>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
