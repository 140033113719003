import { IApiPermissionAction, } from '../../modules/graphql/types/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlPermissionActionService } from "src/app/shared/modules/graphql/services";

@Injectable()
export class PermissionActionService {

  constructor(
    private actions: GqlPermissionActionService,
  ) { }

  public get(): Observable<IApiPermissionAction[]> {
    return this.actions.getPermissionActions();
  }
}
