import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IMapUser } from 'src/app/shared/modules/google-maps/google-map.interfaces';
import { IApiUser, IApiUserDetail } from 'src/app/shared/modules/graphql/types/types';
import { IDrawerUser } from 'src/app/shared/interfaces/user.interfaces';
import { InvestigationService } from 'src/app/shared/services';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';

@Component({
  selector: 'app-investigation-find-investigator-results',
  templateUrl: './investigation-find-investigator-results.component.html',
  styleUrls: ['./investigation-find-investigator-results.component.scss'],
})
export class InvestigationFindInvestigatorResultsComponent {
  @Input() drawerUser: IDrawerUser;

  @Output() nameClick = new EventEmitter<IApiUser>();

  private _users: IMapUser[];
  @Input() set users(users: IMapUser[]) {
    if (this._users && this._users.length > 0) {
      this.selection.clear();
      this.selectedUserChange.emit(null);
    }
    this._users = users;
  }
  get users() { return this._users; }

  @Input() investigationId: string = null;

  @Output() selectedUserChange = new EventEmitter<IApiUser>();
  public selection = new SelectionModel<IApiUser>(false, []);

  @Output() drawerUserChange = new EventEmitter<IDrawerUser>();

  public messageSendLoading = false;
  public messageSendSuccessLoading = true;
  public currentQueueUserId: string = null;
  public messegeSentToUsers = {};

  constructor(
    private investigationService: InvestigationService,
    private notifications: NotificationsService,
  ) { }

  public handleInvestigatorSelection(isSelected: boolean, user: IApiUser) {
    isSelected ? this.selection.select(user) : this.selection.deselect(user);
    this.selectedUserChange.emit(isSelected ? user : null);
  }

  public nameClicked(user: IApiUser) {
    this.nameClick.emit(user);
  }

  public remainingHoursDecimal(val: IApiUserDetail) {
    if (!val) return null;
    return val?.remainingHoursQuarterly / val?.requiredHoursQuarterly;
  }

  public sendMessage(user: IMapUser): void {
    if (this.investigationId && user?.id) {
      this.messageSendLoading = true;
      this.currentQueueUserId = user?.id;
      this.investigationService
        .investigationAssignmentSMS({ InvestigationId: this.investigationId, UserId: this.currentQueueUserId }).pipe(
          this.notifications.catchAlertPipe("SMS was not sent due to technical issues"),
        ).subscribe((res) => {
          if (res) {
            if (this.messegeSentToUsers?.[this.currentQueueUserId]) {
              this.messegeSentToUsers[this.currentQueueUserId]["createdAt"] = res?.createdAt;
            } else {
              this.messegeSentToUsers[this.currentQueueUserId] = { "createdAt": res?.createdAt };
            }
          }
          this.messageSendSuccessLoading = true;
          setTimeout(() => {
            this.messageSendSuccessLoading = false;
          }, 5000);
          this.messageSendLoading = false;
        }, (err) => {
          this.messageSendLoading = false;
        });
    }
  }

}
