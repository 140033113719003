import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  Document {
    id
    uri
    s3Uri
    uploadError
    title
    caption
  }
  User {
    id
    firstName
    lastName
  }
  Client {
    id
    firstName
    lastName
  }
  BillTo {
    id
    firstName
    lastName
  }
  Insured {
    id
    firstName
    lastName
  }
  createdAt
  updatedAt
`;

const sharedReportFragment = `query DocumentSentConnection($filters: [DocumentSentFilter], $orderBy: DocumentSentOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  documentSentConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    pageInfo {
      limit
      offset
      __typename
    }
    totalCount
    edges {
      cursor
      node {
        id
        sendDate
        specialInstructions
        note
        Document {
            id
            title
        }
        DocumentType {
          id
          name
          isCourtDocument
        }
    Contact {
        id
        firstName
        lastName
        BranchAssignments { startDate endDate createdAt Branch { Company { name } } }
    }
    Client {
        id
        firstName
        lastName
        BranchAssignments { Branch { Company { name } } }
    }
    BillTo{
        id
        firstName
        lastName
        BranchAssignments { Branch { Company { name } } }
    }
    Insured {
        id
        insuredName
        contactName
    }
    createdAt
    updatedAt

      }
      __typename
    }
    __typename
  }
}`;

export const documentShareConnectionQuery = gql`${buildConnectionQuery("DocumentShare", "documentShareConnection", nodeFragment)}`;

export const addDocumentShareMutation = gql`mutation AddDocumentShare($input: AddDocumentShareInput!) {
  addDocumentShare(input: $input) { ${nodeFragment} }
}`;

export const updateDocumentShareMutation = gql`mutation UpdateDocumentShare($input: UpdateDocumentShareInput!) {
  updateDocumentShare(input: $input) { ${nodeFragment} }
}`;

export const removeDocumentShareMutation = gql`mutation RemoveDocumentShare($id: String) {
  removeDocumentShare(id: $id)
}`;

export const documentZipDownloadMutation = gql`query DocumentZipDownload($input: DocumentZipDownload!) {
  documentZipDownload(input: $input)
}`;

export const sharedReportConnectionQuery = gql`${sharedReportFragment}`;

export const refreshInvestigationDocumentShareSubscription = gql`subscription onRefreshInvestigationDocumentShare {
  refreshInvestigationDocumentShare
}`;
