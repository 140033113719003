<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationMetricBasicCharts" #investigationMetricBasicCharts="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricInvestigator" #investigationMetricInvestigator="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricState" #investigationMetricState="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricBillToCompany" #investigationMetricBillToCompany="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricClientCompany" #investigationMetricClientCompany="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricTechReviewer" #investigationMetricTechReviewer="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricContact" #investigationMetricContact="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricRisktype" #investigationMetricRisktype="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricDetermination" #investigationMetricDetermination="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricDocusketchRequested" #investigationMetricDocusketchRequested="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div class="page-header">
    <h1 class="mb-0">Investigation Metrics</h1>
  </div>
</section>

<kendo-tabstrip
  #tabstrip
  [scrollable]="false"
  (tabSelect)="onTabChanged($event)"
>
  <kendo-tabstrip-tab [title]="0" [selected]="selectedTab === 0" *ngIf="investigationMetricBasicCharts?.enabled">
    <ng-template kendoTabTitle><span>Basic Charts</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-investigation-metrics-basic
          [tabIndex]="0"
        ></app-investigation-metrics-basic>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="1" [selected]="selectedTab === 1" *ngIf="investigationMetricInvestigator?.enabled">
    <ng-template kendoTabTitle><span>Investigator</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-investigation-metrics-investigator
          [tabIndex]="1"
        ></app-investigation-metrics-investigator>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="2" [selected]="selectedTab === 2" *ngIf="investigationMetricState?.enabled">
    <ng-template kendoTabTitle><span>State</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-investigation-metrics-state
          [tabIndex]="2"
        ></app-investigation-metrics-state>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="3" [selected]="selectedTab === 3" *ngIf="investigationMetricBillToCompany?.enabled">
    <ng-template kendoTabTitle><span>Bill to Company</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-investigation-matrics-billto-company
          [tabIndex]="3"
        ></app-investigation-matrics-billto-company>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="4" [selected]="selectedTab === 4" *ngIf="investigationMetricClientCompany?.enabled">
    <ng-template kendoTabTitle><span>Client Company</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-investigation-metrics-client-company
          [tabIndex]="4"
        ></app-investigation-metrics-client-company>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="5" [selected]="selectedTab === 5" *ngIf="investigationMetricTechReviewer?.enabled">
    <ng-template kendoTabTitle><span>Tech Reviewer</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-investigation-metrics-tech-reviewer
          [tabIndex]="5"
        ></app-investigation-metrics-tech-reviewer>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="6" [selected]="selectedTab === 6" *ngIf="investigationMetricContact?.enabled">
    <ng-template kendoTabTitle><span>Contact</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-investigation-metrics-contact
          [tabIndex]="6"
        ></app-investigation-metrics-contact>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="7" [selected]="selectedTab === 7" *ngIf="investigationMetricRisktype?.enabled">
    <ng-template kendoTabTitle><span>Risk Type</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-investigation-metrics-risk-type
          [tabIndex]="7"
        ></app-investigation-metrics-risk-type>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="8" [selected]="selectedTab === 8" *ngIf="investigationMetricDetermination?.enabled">
    <ng-template kendoTabTitle><span>Determination</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-investigation-metrics-determination
          [tabIndex]="8"
        ></app-investigation-metrics-determination>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="9" [selected]="selectedTab === 9" *ngIf="investigationMetricDocusketchRequested?.enabled">
    <ng-template kendoTabTitle><span>Docusketch Requested</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-investigation-metrics-docusketch-requested
          [tabIndex]="9"
        ></app-investigation-metrics-docusketch-requested>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
