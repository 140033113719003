<kendo-card width="100%" class="mb-16">
  <kendo-card-header style="padding: 5px 16px;">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
          <kendo-gridlayout-item class="report-total mb-0">
              <div>
                  <span class="total-expenses">Total Hours: {{(overallTimeTotal?.hours || 0) | number: '1.2-2'}}</span> <br/>
                  <span><a href="javascript:void(0)" (click)="viewTotalDetailsOpen(true)">View Total Details</a></span>
              </div>
          </kendo-gridlayout-item>
      </kendo-gridlayout>
  </kendo-card-header>
</kendo-card>
<section>
  <kendo-grid
    class="payroll-entry-list td-top-bottom-20"
    [data]="{
      data: payrollEntryList,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageOptions?.paginate($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
  >
    <kendo-grid-column
      field="EMPLOYEE"
      title="Name"
      [width]="20"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.User | fullName: true }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="'ID#'"
      [width]="15"
      class="id-column"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          target="_blank"
          [routerLink]="['/investigations/', dataItem?.Investigation?.id]"
          tel=""
          class="text-decoration"
        >
          {{ dataItem?.Investigation?.nefcoNumber || "Unassigned" }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Type"
      [width]="30"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem?.Type?.name || "-" }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Quantity"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ ((dataItem?.hours || 0) + (dataItem?.nonBillableHours || 0) | number: '1.1-1')}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Date Worked"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.workday | customDate: 'MM/dd/yyyy'}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Date Entered"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.createdAt | customDate: 'MM/dd/yyyy'}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Paid Date"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.paidDate else showDesk">{{dataItem?.paidDate | customDate: 'MM/dd/yyyy'}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Invoiced"
      [width]="15"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem.InvoiceItems.length else showDesk">
          {{ dataItem.InvoiceItems[0].createdAt | customDate: 'MM/dd/yyyy' }}
        </ng-container>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <ng-template #showDesk>-</ng-template>
  <!-- View Details -->
  <kendo-dialog #totalDetails *ngIf="showViewTotalDetails" [minWidth]="600" [width]="600"
  [dialogRef]="totalDetails" (appClickOutside)="viewTotalDetailsOpen(false);">
    <kendo-dialog-titlebar (close)="viewTotalDetailsOpen(false)">
      <div class="modal-title">
        <span>Total Details</span>
      </div>
    </kendo-dialog-titlebar>
    <div class="model-body total-details">
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']" [gap]="{rows: 5, cols: 16}"
        [cols]="['repeat(1,minmax(0,1fr))']" class="total mt-8">
        <kendo-gridlayout-item class="text-right mb-8 mr-16 mt-8">
          <h6 class="mb-0"><small class="mr-8">Total</small><span>{{overallTimeTotal?.hours}}</span></h6>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
  
      <hr />
  
      <kendo-gridlayout [rows]="['auto']" [cols]="['100%']" [gap]="{rows: 12, cols: 16}"
        [cols]="['repeat(2,minmax(0,1fr))']" class="mt-8">
        <ng-container *ngFor="let timeTotal of timeTotals">
          <kendo-gridlayout-item class="mb-0 ml-16">
            <h6 class="mb-0"><small [innerHTML]="timeTotal.name | timeNameShort"></small></h6>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item class="text-right mb-0 mr-16">
            <h6 class="mb-0" [innerHTML]="timeTotal.hours"></h6>
          </kendo-gridlayout-item>
        </ng-container>
      </kendo-gridlayout>
    </div>
  </kendo-dialog>
</section>
