<div [appEnforcePermission]="authConfig.investigationVehiclesUpdate"
  #investigationVehiclesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationVehiclesCreate"
  #investigationVehiclesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationVehiclesDelete"
  #investigationVehiclesDelete="appEnforcePermission"></div>
<form #form="ngForm" appDebouncedChange>
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['18%', '18%', '18%', '18%', '18%', '4%']">
    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label class="asterisk" text="Make"></kendo-label>
        <kendo-textbox name="make" type="text" [(ngModel)]="vehicle.make" minlength="3" #make="ngModel"
          fillMode="outline" placeholder="Enter make" required appNoWhiteSpace cdkFocusInitial></kendo-textbox>
        <kendo-formerror *ngIf="!make?.valid && (make?.dirty || make?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="make?.errors?.required">Make cannot be left blank</span>
          <span *ngIf="make?.errors?.minlength">Make should be minimum 3 character/number</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label class="asterisk" text="Model"></kendo-label>
        <kendo-textbox name="model" #model="ngModel" [(ngModel)]="vehicle.model" minlength="3" fillMode="outline"
          placeholder="Enter model" required appNoWhiteSpace></kendo-textbox>
        <kendo-formerror *ngIf="!model?.valid && (model?.dirty || model?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="model?.errors?.required">Model cannot be left blank</span>
          <span *ngIf="model?.errors?.minlength">Model should be minimum 3 character/number</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label class="asterisk" text="Year"></kendo-label>
        <kendo-textbox name="year" #year="ngModel" [(ngModel)]="vehicle.year" minlength="4" fillMode="outline"
          placeholder="Enter year" required appNoWhiteSpace></kendo-textbox>
        <kendo-formerror *ngIf="!year?.valid && (year?.dirty || year?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="year?.errors?.required">Year cannot be left blank</span>
          <span *ngIf="year?.errors?.minlength">Year should be minimum 4 number</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label class="asterisk" text="Vin #"></kendo-label>
        <kendo-textbox name="vinNumber" #vinNumber="ngModel" [(ngModel)]="vehicle.vinNumber" fillMode="outline"
          placeholder="Enter vin #" pattern="[a-zA-Z0-9]{17}" required appNoWhiteSpace></kendo-textbox>
        <kendo-formerror *ngIf="!vinNumber?.valid && (vinNumber?.dirty || vinNumber?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="vinNumber?.errors?.required">Vin cannot be left blank</span>
          <span *ngIf="vinNumber?.errors?.pattern">Please enter valid Vin</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label text="Stock"></kendo-label>
        <kendo-textbox name="stockNumber" #stockNumber="ngModel" [(ngModel)]="vehicle.stockNumber" fillMode="outline"
          placeholder="Enter stock #" appNoWhiteSpace></kendo-textbox>
        <kendo-formerror *ngIf="!stockNumber?.valid && (stockNumber?.dirty || stockNumber?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span *ngIf="stockNumber?.errors?.required">Stock cannot be left blank</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <kendo-label text=""></kendo-label>
      <div class="actions">
        <button kendoButton iconClass="fa-solid fa-xmark fa-lg" themeColor="error" fillMode="link"
          *ngIf="vehicle?.id && investigationVehiclesDelete?.enabled" (click)="delete()"></button>
        <button kendoButton iconClass="fa-solid fa-check fa-lg" [disabled]="!form.dirty || !form.valid" themeColor="primary"
          fillMode="link"
          *ngIf="(!vehicle?.id && form.dirty && investigationVehiclesCreate?.enabled) || (vehicle?.id && investigationVehiclesUpdate?.enabled)"
          (click)="save()"></button>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</form>
