import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces/base.interfaces';
import { GqlVehicleService } from '../../modules/graphql/services/vehicle';
import {
  IApiAddVehicleInput, IApiVehicle,
  IApiUpdateVehicleInput,
  IApiVehicleFilter, IApiVehicleConnection
} from 'src/app/shared/modules/graphql/types/types';
import { ListPage } from "../../helpers/list-page.class";


@Injectable()
export class VehicleService {

  constructor(
    private vehicleService: GqlVehicleService
  ) { }

  public get(filters?: IApiVehicleFilter[], options?: IGqlBaseOptions): Observable<IApiVehicleConnection> {
    return this.vehicleService.getVehicles(filters, options || new ListPage(-1));
  }

  public create(input: IApiAddVehicleInput): Observable<IApiVehicle> {
    return this.vehicleService.addVehicle(input);
  }

  public update(input: IApiUpdateVehicleInput): Observable<IApiVehicle> {
    return this.vehicleService.updateVehicle(input);
  }

  public delete(id: string): Observable<boolean> {
    return this.vehicleService.removeVehicle(id);
  }
}
