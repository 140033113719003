import gql from 'graphql-tag';
import { buildConnectionQuery, buildWithoutConnectionQuery, buildWithoutConnectionQueryAndReturn } from "../../helpers.class";

const nodeFragment = `
  id
  evidenceNumber
  description
  takenFrom
  ueNumber
  Investigation {
    id
    nefcoNumber
  }
  SubService {
    id
  }
  Transfers {
    id
    trackingNumber
    comment
    FromLocation{
      id
      name
    }
    ToLocation {
      id
      name
    }
    ShippingCarrier {
      id
      name
    }
    ShippingDocument {
      id
      title
      uri
      s3Uri
    }
    receivedDate
    receivedComment
    createdAt
    updatedAt
  }
  CurrentTransfer {
    id
    trackingNumber
    comment
    FromLocation{
      id
    }
    ToLocation {
      id
    }
    ShippingCarrier {
      id
      name
    }
    ShippingDocument {
      id
      title
      uri
      s3Uri
    }
    receivedDate
    receivedComment
    createdAt
    updatedAt
  }
  Photos {
    id
    uri
    s3Uri
    width
    height
    fileSizeKB
    fileName
    caption
    isEvidence
    Folder {
      id
    }

  }
  Status
  StatusHistory {
    id
    name
    comment
    createdAt
    updatedAt
    CreatedBy {
      id
      firstName
      lastName
    }
  }
  Comments {
    id
    text
    createdAt
    updatedAt
    CreatedBy {
      id
      firstName
      lastName
    }
  }
  EvidenceStorageLocation {
    id
    name
    Phone {
      id
      number
    }
    Address {
      id
      Type {
        id
        name
      }
      address1
      city
      state
      postal
    }
  }
  CreatedBy {
    id
    email
    firstName
    lastName
  }
  section
  row
  createdAt
  updatedAt
`;

export const evidenceConnectionQuery = gql`${buildConnectionQuery("Evidence", "evidenceConnection", nodeFragment)}`;

export const getEvidenceById = gql`query getEvidence($id: ID!) {
  getEvidenceById(id: $id) { ${nodeFragment} }
}`;

export const addEvidenceMutation = gql`mutation createEvidence($input: AddEvidenceInput!) {
  addEvidence(input: $input) { ${nodeFragment} }
}`;

export const updateEvidenceMutation = gql`mutation updateEvidence($input: UpdateEvidenceInput!) {
  updateEvidence(input: $input)  { ${nodeFragment} }
}`;

export const removeEvidenceMutation = gql`mutation removeEvidence($input: RemoveEvidenceInput!) {
  removeEvidence(input: $input)
}`;

export const generateEvidenceListCSV = gql`${buildWithoutConnectionQueryAndReturn("evidenceListCSV", "Evidence", "evidenceListCSV")}`;
