<div [appEnforcePermission]="authConfig.investigationServicesCreate" #investigationServicesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationServicesCreateOwn" #investigationServicesCreateOwn="appEnforcePermission"></div>
<!-- Additional Service Card -->
<section>

  <!-- Modal Header -->
  <div class="header">
    <h2 class="text-center">Service</h2>
    <h2 class="text-center">Investigation #{{ data.Investigation?.nefcoNumber }}</h2>
  </div>

  <!-- Service Form -->
  <div class="body mt-4">
    <form #addServiceForm="ngForm">
      <div class="row">
        <div class="col-4">
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select name="type" [(ngModel)]="service.TypeId" required>
              <mat-option *ngFor="let type of serviceTypes" [value]="type.id" [innerHTML]="type.name"></mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field>
            <mat-label>Assigned to</mat-label>
            <mat-select name="assignedTo" [(ngModel)]="service.AssignedUserIds" required>
              <mat-option *ngFor="let user of getStaff" [value]="user.User.id" [innerHTML]="user.User | fullName">
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field>
            <mat-label>Authorized by</mat-label>
            <input matInput name="authorizedBy" [(ngModel)]="service.authorizedBy">
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Notes / Instructions</mat-label>
              <input
                matInput
                [(ngModel)]="service.notes"
                name="notes"
                required
                appNoWhiteSpace
              />
          </mat-form-field>
        </div>
      </div>
    </form>

    <!-- Submit Service Button -->
    <div mat-dialog-actions class="d-flex flex-row-reverse">
      <button mat-raised-button color="primary" (click)="onSubmit()"
      [disabled]="!addServiceForm.valid">Add Service</button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </div>
  </div>
</section>
