import { DataSource } from "@angular/cdk/collections";
import { IApiContact, IApiContactConnection, IApiContactFilter, IApiContactOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { ContactService } from './contact.service';
import { ApiConnection } from '../../decorators/@ApiConnection.decorator';

export class ContactDataSource extends NefcoPagedDataSource<IApiContact, ContactService, IApiContactConnection> implements DataSource<IApiContact> {
  @ApiConnection()
  public load(filters?: IApiContactFilter[]): Promise<IApiContactConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
