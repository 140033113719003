<!-- Permissions -->
<div [appEnforcePermission]="authConfig.contactsCreateConfig" #contactsCreateConfig="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsProfileContactInformationUpdate"
  #contactsProfileContactInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsProfileCompanyInformationUpdate"
  #contactsProfileCompanyInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsDelete" #contactsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationPartiesCreate" #investigationPartiesCreate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationPartiesUpdate" #investigationPartiesUpdate="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationPartiesDelete" #investigationPartiesDelete="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.investigationInformationUpdate"
  #investigationInformationUpdate="appEnforcePermission"></div>
<!-- Permissions -->
<!-- <kendo-card class="plain-kendo-card mt-16" [width]="'100%'">
  <kendo-card-body> -->
    <div class="section-header">
      <h2>Parties</h2>
    </div>
    <section>
      <ng-container *ngIf="sceneContact">
        <p>
          <strong>Scene Contact: &nbsp;</strong> {{ sceneContact | fullName }}
        </p>
      </ng-container>
      <kendo-gridlayout *ngIf="parties?.length && parties[0]?.id && investigationInformationUpdate?.enabled"
        [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield class="kendo-formfield">
            <kendo-label [for]="sceneContact" text="Change Scene Contact"></kendo-label>
            <kendo-dropdownlist #sceneContact [data]="contacts" textField="name" valueField="id" fillMode="outline"
              (ngModelChange)="updateSceneContact($event)" [valuePrimitive]="true"
              [defaultItem]="{id: null, name: 'Choose ...'}" name="scene"
              [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" [(ngModel)]="sceneContactId"
              #scene="ngModel">
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span *ngIf="!dataItem?.id"> {{ dataItem?.name }} </span>
                <span *ngIf="dataItem?.id">
                  {{dataItem?.Role?.name || "Client"}} - {{( dataItem | fullName)}}
                </span>
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.name }} </span>
                <span *ngIf="dataItem?.id" class="not-placeholder">
                  {{dataItem?.Role?.name || "Client"}} - {{( dataItem | fullName)}}
                </span>
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <hr />
      <form #frm="ngForm" *ngIf="parties?.length || insured?.id">
        <kendo-panelbar [expandMode]="2" *ngIf="insured?.id">
          <kendo-panelbar-item *ngIf="insured?.id">
            <ng-template kendoPanelBarItemTitle>
              <kendo-gridlayout style="width: 100%;" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
                [cols]="['repeat(3,minmax(0,1fr))']">
                <kendo-gridlayout-item class="mb-0">
                  <h6 class="panelbar-title mb-0"> Insured</h6>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <p class="mb-0"> {{ insured?.insuredName }} {{ insured?.contactName }}</p>
                </kendo-gridlayout-item>
              </kendo-gridlayout>
            </ng-template>
            <ng-template kendoPanelBarContent>
              <section class="p-16">
                <div class="float-right">
                  <button kendoButton iconClass="fa-solid fa-pencil" themeColor="primary" fillMode="solid" class="ml-3"
                    (click)="editInsured(insured)">Edit Contact</button>
                </div>
                <kendo-card class="accordion-body mt-16" style="display: inline-block;" width="100%">
                  <h4>Contact Information</h4>
                  <kendo-gridlayout [rows]="['auto']" [cols]="[
                          { width: '20%' },
                          { width: '20%' },
                          { width: '20%' },
                          { width: '20%' },
                          { width: '20%' }
                        ]" [gap]="{ rows: 26, cols: 5 }">
                    <kendo-gridlayout-item>
                      <p class="kendo-caption">Type</p>
                      <kendo-badge class="fw600" position="none" size="medium" rounded="full" themeColor="primary">{{
                        insured?.contactName ? "Business" : "Individual"
                        }}</kendo-badge>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item *ngIf="!insured?.contactName">
                      <p class="kendo-caption">Insured Name</p>
                      <p class="kendo-caption-body">
                        <span [innerHTML]="insured?.insuredName || '-'"></span>
                      </p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item *ngIf="insured.contactName">
                      <p class="kendo-caption">Insured Company Name</p>
                      <p class="kendo-caption-body">
                        <span [innerHTML]="insured?.insuredName || '-'"></span>
                      </p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item *ngIf="insured.contactName">
                      <p class="kendo-caption">Contact Name</p>
                      <p class="kendo-caption-body">
                        <span [innerHTML]="insured?.contactName || '-'"></span>
                      </p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                      <p class="kendo-caption">Relationship/Title</p>
                      <p class="kendo-caption-body">{{ insured?.title || '-' }}</p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                      <p class="kendo-caption">Email</p>
                      <p class="kendo-caption-body">
                        <app-email-phone-view *ngFor="let email of insured?.Emails"
                          [email]="email"></app-email-phone-view>
                        <span *ngIf="insured?.Emails?.length === 0">-</span>
                      </p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                      <p class="kendo-caption">Phone Number</p>
                      <p class="kendo-caption-body">
                        <app-email-phone-view *ngFor="let phone of insured.Phones"
                          [phone]="phone"></app-email-phone-view>
                      </p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                      <p class="kendo-caption">Suffix</p>
                      <p class="kendo-caption-body">
                        {{ insured.suffix || "-" }}
                      </p>
                    </kendo-gridlayout-item>
                  </kendo-gridlayout>
                </kendo-card>
                <!-- Displaying Addresses -->
                <kendo-card class="accordion-body" [width]="'100%'" *ngIf="insured.Addresses?.length">
                  <kendo-card-body class="p-0">
                    <h4> Address </h4>
                    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 26, cols: 5}"
                      [cols]="['repeat(5,minmax(0,1fr))']">
                      <kendo-gridlayout-item *ngFor="let address of insured?.Addresses else noData" class="mb-0">
                        <app-address-view [address]="address"></app-address-view>
                      </kendo-gridlayout-item>
                    </kendo-gridlayout>
                  </kendo-card-body>
                </kendo-card>
              </section>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
        <kendo-panelbar [expandMode]="2" *ngIf="parties?.length">
          <kendo-panelbar-item *ngFor="let party of parties; index as i">
            <ng-template kendoPanelBarItemTitle>
              <kendo-gridlayout style="width: 100%;" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
                [cols]="['repeat(3,minmax(0,1fr))']">
                <kendo-gridlayout-item class="mb-0">
                  <h6 class="panelbar-title mb-0"> {{ party.Role?.name }} </h6>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="mb-0">
                  <p class="mb-0"> {{ party.Contact?.insuredName }} {{ party.Contact | fullName }} </p>
                </kendo-gridlayout-item>
              </kendo-gridlayout>
            </ng-template>
            <ng-template kendoPanelBarContent>
              <section class="p-16">
                <div class="float-right">
                  <button kendoButton iconClass="fa-solid fa-minus-circle" themeColor="error" fillMode="solid"
                    *ngIf="!isInsured(party.Contact.Role) && investigationPartiesDelete?.enabled"
                    (click)="removeParty(party)">Remove</button>
                  <button kendoButton iconClass="fa-solid fa-pencil" themeColor="primary" fillMode="solid" class="ml-3"
                    *ngIf="isInsured(party.Contact.Role) && (contactsProfileContactInformationUpdate?.enabled || contactsProfileCompanyInformationUpdate?.enabled) && investigationPartiesUpdate?.enabled"
                    (click)="editInsured(party)">Edit Contact</button>
                  <button kendoButton iconClass="fa-solid fa-pencil" themeColor="primary" fillMode="solid" class="ml-3"
                    *ngIf="!isInsured(party.Contact.Role) && (contactsProfileContactInformationUpdate?.enabled || contactsProfileCompanyInformationUpdate?.enabled) && investigationPartiesUpdate?.enabled"
                    (click)="editContact(party.Contact.id)">Edit Contact</button>
                </div>
                <kendo-card class="accordion-body" style="display: inline-block;" width="100%"
                  *ngIf="isInsured(party.Contact.Role)">
                  <h4>Contact Information</h4>
                  <kendo-gridlayout [rows]="['auto']" [cols]="[
                      { width: '20%' },
                      { width: '20%' },
                      { width: '20%' },
                      { width: '20%' },
                      { width: '20%' }
                    ]" [gap]="{ rows: 26, cols: 5 }">
                    <kendo-gridlayout-item>
                      <p class="kendo-caption">Type</p>
                      <kendo-badge class="fw600" position="none" size="medium" rounded="full" themeColor="primary">{{
                        party.Contact?.insuredName ? "Business" : "Individual"
                        }}</kendo-badge>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item *ngIf="!party.Contact?.insuredName">
                      <p class="kendo-caption">Insured Name</p>
                      <p class="kendo-caption-body">
                        <span [innerHTML]="party.Contact | fullName"></span>
                      </p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item *ngIf="party.Contact?.insuredName">
                      <p class="kendo-caption">Insured Company Name</p>
                      <p class="kendo-caption-body">
                        <span [innerHTML]="party.Contact.insuredName"></span>
                      </p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item *ngIf="party.Contact?.insuredName">
                      <p class="kendo-caption">Contact Name</p>
                      <p class="kendo-caption-body">
                        <span [innerHTML]="party.Contact | fullName"></span>
                      </p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                      <p class="kendo-caption">Relationship/Title</p>
                      <p class="kendo-caption-body">{{ party.Contact.nickName }}</p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                      <p class="kendo-caption">Email</p>
                      <p class="kendo-caption-body">
                        <app-email-phone-view *ngFor="let email of party.Contact?.Emails"
                          [email]="email"></app-email-phone-view>
                        <span *ngIf="party.Contact?.Emails?.length === 0">-</span>
                      </p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                      <p class="kendo-caption">Phone Number</p>
                      <p class="kendo-caption-body">
                        <app-email-phone-view *ngFor="let phone of party.Contact.Phones"
                          [phone]="phone"></app-email-phone-view>
                      </p>
                    </kendo-gridlayout-item>

                    <kendo-gridlayout-item>
                      <p class="kendo-caption">Suffix</p>
                      <p class="kendo-caption-body">
                        {{ party.Contact.suffix || "-" }}
                      </p>
                    </kendo-gridlayout-item>
                  </kendo-gridlayout>
                </kendo-card>
                <!-- Displaying Contact Information -->
                <kendo-card class="accordion-body" style="display: inline-block;" *ngIf="!isInsured(party.Contact.Role)"
                  [width]="'100%'">
                  <kendo-card-body class="p-0">
                    <h4> Contact Information </h4>
                    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 26, cols: 5}"
                      [cols]="['repeat(5,minmax(0,1fr))']">
                      <kendo-gridlayout-item *ngIf="party.Contact?.insuredName" class="mb-0">
                        <p class="kendo-caption">
                          Insured Name
                        </p>
                        <p class="kendo-caption-body" [innerHTML]="party?.Contact?.insuredName || '-'">
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          First Name
                        </p>
                        <p class="kendo-caption-body" [innerHTML]="party?.Contact?.firstName || '-'">
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          Last Name
                        </p>
                        <p class="kendo-caption-body" [innerHTML]="party?.Contact?.lastName || '-'">
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          Nickname
                        </p>
                        <p class="kendo-caption-body" [innerHTML]="party?.Contact?.nickName || '-'">
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          Suffix
                        </p>
                        <p class="kendo-caption-body" [innerHTML]="party?.Contact?.suffix || '-'">
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0" *ngIf="party?.Contact?.policyNumber">
                        <p class="kendo-caption">
                          Policy #
                        </p>
                        <p class="kendo-caption-body" [innerHTML]="party?.Contact?.policyNumber || '-'">
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0" *ngIf="party?.Contact?.claimNumber">
                        <p class="kendo-caption">
                          Claim #
                        </p>
                        <p class="kendo-caption-body" [innerHTML]="party?.Contact?.claimNumber || '-'">
                        </p>
                      </kendo-gridlayout-item>
                      <!-- Displaying Contact Information -->
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          Phones
                        </p>
                        <ng-container *ngFor="let phone of party?.Contact?.Phones else noData">
                          <app-email-phone-view [phone]="phone"></app-email-phone-view>
                        </ng-container>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          Emails
                        </p>
                        <ng-container *ngFor="let email of party.Contact.Emails else noData">
                          <app-email-phone-view [email]="email"></app-email-phone-view>
                        </ng-container>
                      </kendo-gridlayout-item>
                    </kendo-gridlayout>
                  </kendo-card-body>
                </kendo-card>
                <!-- Displaying Addresses -->
                <kendo-card class="accordion-body" style="display: inline-block;" [width]="'100%'"
                  *ngIf="party?.Contact?.Addresses?.length">
                  <kendo-card-body class="p-0">
                    <h4> Address </h4>
                    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 26, cols: 5}"
                      [cols]="['repeat(5,minmax(0,1fr))']">
                      <kendo-gridlayout-item *ngFor="let address of party?.Contact?.Addresses else noData" class="mb-0">
                        <app-address-view [address]="address"></app-address-view>
                      </kendo-gridlayout-item>
                    </kendo-gridlayout>
                  </kendo-card-body>
                </kendo-card>
                <!-- Displaying Company/Branch -->
                <kendo-card class="accordion-body" style="display: inline-block;" [width]="'100%'"
                  *ngIf="party?.Contact?.Addresses?.length">
                  <kendo-card-body class="p-0">
                    <h4> Company Information </h4>
                    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 26, cols: 5}" [cols]="['repeat(5,minmax(0,1fr))']"
                      *ngFor="let branch of party?.Contact?.Branches">
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          Company Name
                        </p>
                        <p class="kendo-caption-body" [innerHTML]="branch?.Company?.name || '-'">
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          Branch Name
                        </p>
                        <p class="kendo-caption-body" [innerHTML]="branch?.name || '-'">
                        </p>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          Branch Phones
                        </p>
                        <ng-container *ngFor="let phone of branch?.Phones else noData">
                          <app-email-phone-view [phone]="phone"></app-email-phone-view>
                        </ng-container>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          Branch Emails
                        </p>
                        <ng-container *ngFor="let email of branch?.Emails else noData">
                          <app-email-phone-view [email]="email"></app-email-phone-view>
                        </ng-container>
                      </kendo-gridlayout-item>
                      <kendo-gridlayout-item class="mb-0">
                        <p class="kendo-caption">
                          Branch Addresses
                        </p>
                        <ng-container *ngFor="let address of branch?.Addresses else noData">
                          <app-address-view [address]="address"></app-address-view>
                        </ng-container>
                      </kendo-gridlayout-item>
                    </kendo-gridlayout>
                  </kendo-card-body>
                </kendo-card>
              </section>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </form>
      <hr />
      <div class="mt-16" *ngIf="investigationPartiesCreate?.enabled && contactsCreateConfig?.enabled">
        <h6>Add another Party</h6>
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
          [cols]="['25%', '16%', '16%', '16%', '16%', '5%']">
          <kendo-gridlayout-item [colSpan]="1">
            <kendo-formfield>
              <kendo-label text="Contact Role"></kendo-label>
              <kendo-dropdownlist name="role" #role="ngModel" [data]="roles" textField="name" valueField="id"
                fillMode="outline" [valuePrimitive]="false" [defaultItem]="{id: '', name: 'Select contact role'}"
                [(ngModel)]="contactRole" [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="dataItem?.name === 'Select contact role'" class="placeholder"> {{ dataItem?.name }}
                  </span>
                  <span *ngIf="dataItem?.name !== 'Select contact role'" class="not-placeholder"> {{ dataItem?.name
                    }} </span>
                </ng-template>
              </kendo-dropdownlist>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item [colSpan]="5">
            <div>
              <button class="mt-24" kendoButton themeColor="primary" fillMode="solid" (click)="openModal(contactRole)"
                style="width: 110px;" [disabled]="!contactRole?.id">Add Contact</button>
            </div>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </div>
    </section>
  <!-- </kendo-card-body>
</kendo-card> -->
