import { filter, switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SettingsDataSource } from 'src/app/shared/services/settings/settings.datasource';
import { SettingsService } from 'src/app/shared/services';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { SettingsModalComponent } from '../settings-modal/settings-modal.component';
import { IApiSettings, IApiSettingsOrderBy } from 'src/app/shared/modules/graphql/types/types';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  private _dataSource: SettingsDataSource;
  public get dataSource(): SettingsDataSource {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public sort: SortDescriptor[] = [{
    field: 'NAME',
    dir: 'asc'
  }];

  constructor(
    private loaderService: LoaderService,
    private notifications: NotificationsService,
    private settingsService: SettingsService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this._dataSource = new SettingsDataSource(this.settingsService);
    this._dataSource.listPage.orderBy = IApiSettingsOrderBy.CreatedAt;
    this._dataSource.listPage.sortOrder = SortOrder.DESC;
    this.loaderService.attachObservable(this._dataSource.loading$);
    this.load();
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'NAME';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public delete(id: string) {
    this.notifications.kendoConfirm("Really delete this setting?").pipe(
      filter(v => !!v),
      switchMap(() => this.settingsService.remove(id)),
      this.notifications.snackbarPipe("Setting deleted"),
      this.notifications.snackbarErrorPipe("Error deleting setting")
    ).subscribe(() => this.load());
  }

  public edit(data?: IApiSettings | null) {
    const dialog: DialogRef = this.dialogService.open({
      content: SettingsModalComponent,
      width: 651,
      maxWidth: 651,
      autoFocusedElement: 'key',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as SettingsModalComponent;
    dialogInstance.data = data;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.load();
      }
    })
  }

  public itemSelected(event, dataItem) {
    switch (event?.text) {
      case 'Edit':
        this.edit(dataItem);
        break;
      case 'Delete':
        this.delete(dataItem?.id)
        break;
      default:
        break;
    }
  }
}
