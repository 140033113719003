import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-investigation-services-sidebar',
  templateUrl: './investigation-services-sidebar.component.html',
  styleUrls: ['./investigation-services-sidebar.component.scss']
})
export class InvestigationServicesSidebarComponent {

  public columnsToDisplay = ["update", "service", "status", "comment", "updatedBy"];

  constructor(@Inject(MAT_DIALOG_DATA) public tables: any) { }


}
