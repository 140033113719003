<mat-dialog-content>
  <section class="printable-only-content">
    <h4 class="text-center">View Evidence Photos</h4>
    <h5 class="muted text-center">Investigation #{{ nefcoNumber }}</h5>
    <div class="d-flex justify-content-center" *ngIf="loading | async; else photoTemplate">
      <mat-spinner diameter="48"></mat-spinner>
    </div>
    <ng-template #photoTemplate>
      <ng-container *ngFor="let folder of folders">
        <p><span class="font-weight-bold">{{ folder.name }} </span><span class="muted"> Uploaded {{ folder.createdAt | customDate: 'MM/dd/yyyy' }}</span></p>
        <section class="row align-items-stretch">
          <div class="col-4 image-container" *ngFor="let photo of folder.EvidencePhotos">
            <mat-card>
              <p class="mx-2">{{ photo.fileName }}</p>
              <img [src]="photo.s3Uri | s3" [alt]="photo.caption" />
              <p class="muted mt-2">{{ photo.caption }} </p>
            </mat-card>
          </div>
        </section>
      </ng-container>
      <h5 class="text-center my-5" *ngIf="!folders?.length">No Evidence Photos to Display</h5>
    </ng-template>
  </section>
  <div class="d-flex justify-content-end">
    <button mat-raised-button color="primary" [mat-dialog-close]>Ok</button>
  </div>
</mat-dialog-content>