<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageTimeEntryTypesCreate" #manageTimeEntryTypesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageTimeEntryTypesUpdate" #manageTimeEntryTypesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageTimeEntryTypesDelete" #manageTimeEntryTypesDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageTimeEntryTypesDeactivate" #manageTimeEntryTypesDeactivate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']" *ngIf="manageTimeEntryTypesCreate?.enabled">
    <kendo-gridlayout-item class="text-right mb-0">
      <kendo-button class="mr-16" kendoButton themeColor="primary" iconClass="fa fa-plus" fillMode="solid" size="medium" (click)="add()">
        New Entry Type
      </kendo-button>
    </kendo-gridlayout-item>
  </kendo-gridlayout>

  <kendo-grid
    class="expense-report-category-list mt-16"
    [data]="{
      data: data,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="false"
    (pageChange)="pageOptions?.paginate($event);"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
  >
    <kendo-grid-column
      field="NAME"
      title="Category"
      [width]="20"
      [sortable]="true"
      [headerClass]="'sort-header'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          *ngIf="manageTimeEntryTypesUpdate?.enabled else onlyName"
          (click)="edit(dataItem)"
          tel=""
          class="text-decoration"
          [ngClass]="{'text-line-through': !dataItem.activated}"
        >
          {{ dataItem?.name }}
        </a>
        <ng-template #onlyName>
          <span [ngClass]="{'text-line-through': !dataItem.activated}">{{dataItem.name}}</span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="APPLIES_TO"
      title="Applies To"
      [width]="10"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [ngClass]="{'text-line-through': !dataItem.activated}" [innerHTML]="dataItem.appliesTo"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="qbAccount"
      title="Quick Book Account Name"
      [width]="20"
      [sortable]="false"
      [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [ngClass]="{'text-line-through': !dataItem.activated}" [innerHTML]="dataItem?.qbAccount || '-'"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="Action"
      [width]="8"
      [sortable]="false"
      [headerClass]="'nocursor'"
      *ngIf="actionItem?.length"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-dropdownbutton
          [data]="getFilteredActionItems(dataItem)"
          themeColor="primary" fillMode="link" buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis"
          (itemClick)="itemSelected($event, dataItem)" 
          [popupSettings]="{
            appendTo: 'component',
            animate: false,
            width: 250,
            popupClass: 'grid-action-items-menu'
          }">
        </kendo-dropdownbutton>
      </ng-template>
    </kendo-grid-column>

    <!-- No record -->
    <ng-template kendoGridNoRecordsTemplate>
      <p class="mt-24 mb-24">There are no results to display.</p>
    </ng-template>
  </kendo-grid>
</section>