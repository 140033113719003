import { ApiConnection } from 'src/app/shared/decorators/@ApiConnection.decorator';
import { DataSource } from "@angular/cdk/collections";
import { IApiInvestigationMetricsClientCompany, IApiInvestigationMetricsClientCompanyConnection, IApiInvestigationMetricsFilter } from 'src/app/shared/modules/graphql/types/types';
import { NefcoPagedDataSource } from 'src/app/shared/helpers/nefco-data-source.class';
import { InvestigationMetricsClientCompanyService } from './investigation-metrics-client-company.service';

export class InvestigationMetricsClientCompanyDatasource extends NefcoPagedDataSource<IApiInvestigationMetricsClientCompany, InvestigationMetricsClientCompanyService, IApiInvestigationMetricsClientCompanyConnection> implements DataSource<IApiInvestigationMetricsClientCompany> {
  @ApiConnection()
  public load(filters: IApiInvestigationMetricsFilter[] = []): Promise<IApiInvestigationMetricsClientCompanyConnection> {
    return this.service.get(filters, this.listPage).toPromise();
  }
}
