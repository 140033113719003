import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListPage } from "src/app/shared/helpers/list-page.class";
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces/base.interfaces';
import { GqlInvestigationPartyService } from 'src/app/shared/modules/graphql/services';
import {
  IApiAddInvestigationPartyInput, IApiInvestigationParty, IApiInvestigationPartyConnection,
  IApiInvestigationPartyFilter, IApiUpdateInvestigationPartyInput
} from 'src/app/shared/modules/graphql/types/types';

@Injectable()
export class InvestigationPartyService {

  constructor(
    private gqlInvestigationPartyService: GqlInvestigationPartyService
  ) { }

  public get(filters: IApiInvestigationPartyFilter[] = [], options?: IGqlBaseOptions): Observable<IApiInvestigationPartyConnection> {
    return this.gqlInvestigationPartyService.getInvestigationParties(filters, options || new ListPage());
  }

  public add(input: IApiAddInvestigationPartyInput): Observable<IApiInvestigationParty> {
    return this.gqlInvestigationPartyService.addInvestigationParty(input);
  }

  public remove(id: string): Observable<boolean> {
    return this.gqlInvestigationPartyService.removeInvestigationParty(id);
  }

  public update(input: IApiUpdateInvestigationPartyInput): Observable<IApiInvestigationParty> {
    return this.gqlInvestigationPartyService.updateInvestigationParty(input);
  }

}
