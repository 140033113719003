import { Component, Input } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent extends DialogContentBase {

  public get modified(): boolean {
    return this.data.notes !== this.data.original;
  }

  @Input() public data: { notes: string, title: string, original: string };

  constructor(
    public dialog: DialogRef
  ) {
    super(dialog);
  }

  public revert() {
    this.data.notes = this.data.original;
  }

  public close(result: any = false) {
    this.dialog.close(result);
  }

}
